import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: '#142740',
    color: '#FFFFFF',
    fontSize: 12,
    fontFamily: 'Montserrat',
    padding: 8,
    fontWeight: 500,
  },
  tooltipArrow: {
    color: '#142740',
  },
}));

const CustomTooltip = (props) => {
  const classes = useStyles();
  const { children, title, placement, disabled, ownClasses } = props;

  if (disabled)
    return children;

  return (
    <Tooltip
      title={title}
      placement={placement}
      interactive
      arrow
      classes={ownClasses ? ownClasses : {
        tooltip: classes.tooltip,
        arrow: classes.tooltipArrow,
      }}
    >
      {children}
    </Tooltip>
  );
};

CustomTooltip.propTypes = {
  placement: PropTypes.string,
};

CustomTooltip.defaultProps = {
  placement: 'top',
};

export default CustomTooltip;