import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { CameraShake } from '@react-three/drei';

import { getStreamer } from '../../apollo/queries';

import ThreeDWrapper from '../../components/3DCard/3DWrapper';
import BoosterAnimation from '../../components/3DCard/BoosterAnimation';
import Scene from '../../components/3DCard/Scene';
import BoosterObject from '../../components/3DCard/BoosterObject';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import Loading from '../../components/Loading/Loading';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import VideoScene from '../../components/3DCard/VideoScene';
import VideoGroup from '../../components/3DCard/VideosGroup';
import Particles from '../../components/3DCard/Particles';
import Lines from '../../components/3DCard/Lines';
import Lights from '../../components/3DCard/Lights';
import { getMatchData } from '../../utils/helper';

const AnimationScene = (props) => {
  const { cardInformation } = props;
  const [startLight, setStartLight] = useState(false);

  /*const videoUrls = [
    'https://streamheroes-image-cdn.s3.amazonaws.com/3d-objects/Background_rip_open (2).webm',
    'https://streamheroes-image-cdn.s3.amazonaws.com/3d-objects/card reveal.webm',
  ];*/

  const { avatar } = getMatchData(cardInformation.card_template_metadata, 300, 300);

  const card = {
    image: cardInformation.front_layers[0].physical_img_url,
    backImage: cardInformation.back_layers && cardInformation.back_layers[0].physical_img_url,
    frontEffect: cardInformation.front_layers.length > 1 && cardInformation.front_layers[1].physical_img_url,
    backEffect: (cardInformation.back_layers && cardInformation.back_layers.length > 1) && cardInformation.back_layers[1].physical_img_url,
    noEffectLayer: cardInformation.quality !== null && cardInformation.quality < 0,
  };
  const boosterMetaData = cardInformation && cardInformation.redeemed_booster && cardInformation.redeemed_booster.generic_booster_metadata;

  return (
    <>
      <Lights startLight={startLight} setStartLight={setStartLight} />
      <BoosterAnimation
        frontImage={
          boosterMetaData ? boosterMetaData.front_image : '/booster-packs/new/sh-base-booster-pack-04.png'
        }
        backImage={
          boosterMetaData ? boosterMetaData.back_image : '/booster-packs/new/sh-base-booster-pack-04-back.png'
        }
        userLogo={avatar ? avatar : 'https://static-cdn.jtvnw.net/jtv_user_pictures/5d4e48a1-6f4a-4b32-9def-1aaed5b3e6f9-profile_image-300x300.jpg'}
        startAnimation={startLight}
        isCustomPack={boosterMetaData ? boosterMetaData.is_custom_booster : false}
      />
      {/*<VideoGroup urls={videoUrls} />*/}
      {/*<BoosterObject
        boosterImage="3d-objects/booster-pack-knossi_back.png"
        // boosterImage="booster-packs/new/sh-base-booster-pack-11.png"
        userLogo="https://static-cdn.jtvnw.net/jtv_user_pictures/5d4e48a1-6f4a-4b32-9def-1aaed5b3e6f9-profile_image-300x300.jpg"
      />*/}

      <group position={[0.2, 0, 0]}>
        <Scene card={card} noControls />
      </group>
    </>
  );
};

export default AnimationScene;