import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { getBoosterContent } from '../../apollo/queries';

import Loading from '../../components/Loading/Loading';
import PagePopup from '../../components/Dialog/PagePopup';
import BoosterView from '../../components/Booster/BoosterView';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

const BoosterViewContainer = (props) => {
  const { handleClose, booster, redeemBooster } = props;

  const { data, loading } = useQuery(getBoosterContent, {
    variables: { id: booster._id },
    fetchPolicy: useCacheWithExpiration('getBoosterContent'),
  });

  const boosterData = data && data.getBoosterContent;
  if (boosterData) {
    boosterData.match = booster.match;
    boosterData.user_energy = booster.user_energy;
    boosterData.user_coins = booster.user_coins;
  }

  return (
    <PagePopup>
      {loading ?
        <Loading />
        :
        <BoosterView booster={boosterData} handleClose={handleClose}
                     redeemBooster={redeemBooster} />
      }
    </PagePopup>
  );
};

export default BoosterViewContainer;