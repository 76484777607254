import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import Loading from '../Loading/Loading';
import StorageBox from './StorageBox';
import InnerHeader from '../Layout/InnerHeader';
import { isUserPremium } from '../../utils/helper';

import conf from '../../conf.json';

const useStyles = makeStyles((theme) => ({
  gridArea: {
    display: 'grid',
    gap: 18,
    gridTemplateColumns: 'repeat(5, 1fr)',

    [theme.breakpoints.down('md')]: {
      ...Helpers.fillColCenter,
      gap: 8,
    },
  },
}));

const StorageBoxes = (props) => {
  const classes = useStyles();
  const { myViewer, cardDecks, loading, t } = props;
  const { isPremiumActive } = isUserPremium(myViewer);

  return (
    <div style={{ color: Colors.white, position: 'relative' }}>

      <InnerHeader
        showAd={conf.ad_area.storage_boxes && myViewer && !isPremiumActive}
        title={t('storage_boxes')}
      />

      <div className="inner-wrapper">
        {loading ?
          <Loading />
          :
          <div className={classes.gridArea}>
            {cardDecks.map(box => (
              <StorageBox key={box._id} storageBox={box} t={t} notMy={false} />
            ))}
          </div>
        }

      </div>
    </div>
  );
};

export default StorageBoxes;