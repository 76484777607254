import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Helpers from '../../theme/Helpers';
import PagePopup from '../Dialog/PagePopup';
import Colors from '../../theme/Colors';
import AnimationButton from '../Button/AnimationButton';

const useStyles = makeStyles({
  container: {
    ...Helpers.fillColCenter,
    height: '100%',
    maxWidth: 1440,
    margin: '0 auto',
    position: 'relative',
  },
  content: {
    ...Helpers.fillColCenter,
    textAlign: 'center'
  },
  title: {
    marginTop: 0,
    marginBottom: 20,
    fontSize: 26,
    fontWeight: 600,
    textTransform: 'uppercase',
    color: 'white'
  },
  body: {
    fontSize: 16,
    color: Colors.halfWhite,
    margin: 0,
    maxWidth: 560
  },
  buttonContainer: {
    display: 'flex', 
    alignItems: 'center', 
    gap: 10, 
    marginTop: 60
  },
  button: {
    fontSize: 14,
    padding: '11px 32px',
  },
  capsuleImage: {
    width: '100%',
    maxWidth: 440
  }
});

const FirstAlertCapsuleDialog = ({ t, onClose, streamerName, capsuleImageUrl }) => {
  const classes = useStyles();

  return (
    <PagePopup>
      <div className={classes.container}>
        <div className={classes.content}>
          <img src={capsuleImageUrl} alt="Capsule" className={classes.capsuleImage} />

          <h2 className={classes.title}>{t('first_capsule_dialog_title')}</h2>

          <p className={classes.body}>
            {t('first_capsule_dialog_body', {streamerName})}
          </p>
        </div>
        
        <div className={classes.buttonContainer}>
          <AnimationButton
            status="whiteTransparency"
            onClick={onClose}
            className={classes.button}
          >
            {t('continue')}
          </AnimationButton>
          
          <AnimationButton
            status="neonRed"
            href="/inventory/alert-capsules"
            onClick={onClose}
            className={classes.button}
          >
            {t('go_to_inventory')}
          </AnimationButton>
        </div>
      </div>
    </PagePopup>
  );
};

export default FirstAlertCapsuleDialog;
