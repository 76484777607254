import React, { useEffect, useState } from 'react';
import imageExists from 'image-exists';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { fabric } from 'fabric';
import { useMutation } from '@apollo/react-hooks';

import { uploadBigLevelCard as uploadBigLevelCardMutation } from '../../apollo/mutations';

import CheckPhysicalCard from '../../components/Admin/CheckPhysicalCard';
import { dataURItoFile, uploadImageToS3 } from '../../utils/helper';

import conf from '../../conf.json';

const CheckPhysicalCardContainer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [canvas, setCanvas] = useState(null);
  const [bigCardFile, setBigCardFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const printSize = { width: 768, height: 1146 };
  const digitalSize = { width: 324, height: 500 };

  useEffect(() => {
    if (!canvas)
      setCanvas(new fabric.Canvas('canvas'));
  }, [canvas]);

  const [uploadBigLevelCard] = useMutation(uploadBigLevelCardMutation, {
    onCompleted: async (data) => {
      if (data && data.uploadBigLevelCard) {
        const signedURL = data.uploadBigLevelCard;

        uploadImageToS3(signedURL, bigCardFile, {
          'Content-Type': 'image/png',
        }, false);
      }
      setLoading(false);
      enqueueSnackbar('Uploaded successful', { variant: 'success' });
    },
    onError: (data) => {
      setLoading(false);
      enqueueSnackbar(data.message, { variant: 'error' });
    },
  });

  const handleChange = (event) => {
    const { target } = event;

    setValue(target.value);
  };

  const checkCard = () => {
    imageExists(`${conf.cdn_url}/cards/self-init/physical/${value}.png`, (exists) => {
      if (exists) {
        enqueueSnackbar(t('image_already_exists'), { variant: 'error' });
      } else
        loadSerialization();
    });
  };

  const loadSerialization = () => {
    setLoading(true);
    fetch(`${conf.no_cors_cdn_url}/cards/self-init-serialization/${value}.txt`, {
      headers: {
        'cache-control': 'no-store',
        pragma: 'no-cache',
      },
    })
    .then(response => response.json())
    .then(jsonResponse => {
      enqueueSnackbar('Serialization loaded', { variant: 'success' });
      if (jsonResponse) {
        canvas.loadFromJSON(jsonResponse, () => {
          saveIdsForObjects();
        });
      }
    })
    .catch(error => {
      enqueueSnackbar('Error, please check console', { variant: 'error' });
      console.error(error);
      console.log("If the serializations file does not exist, please check in the DB if _id and filename of card_layer_image is different in the CardTemplate Collection. --> If yes, then enter filename without .png/.jpg as value.");
      setLoading(false);
    });
  };

  /**
   * Fit the size of the card design image to the card size
   *
   * @param object
   */
  const fitSizeOfCardDesign = (object) => {
    object.scaleToWidth(digitalSize.width);
    object.scaleToHeight(digitalSize.height);
  };

  /**
   * Set an id for every object in canvas object (important for assignments and reviews)
   */
  const saveIdsForObjects = async () => {
    const canvasObjects = canvas.getObjects();
    let userImage = 0;

    await canvasObjects.map((obj, idx) => {
      if (idx === 0) {
        canvasObjects[idx].id = 'card-background';

      } else {
        if (obj.type === 'text') { // user-text
          canvasObjects[idx].id = 'user-text';
        } else { // effect, card-design-theme, user-image, name-badge
          if (obj.src.includes('user-uploads')) {
            const tmpData = userImage;
            canvasObjects[idx].id = `user-image-${tmpData}`;
            userImage += 1;
          } else if (obj.src.includes('logo-uploads')) {
            canvasObjects[idx].id = 'user-logo';
          } else if (obj.src.includes('/heroes/')) {
            canvasObjects[idx].id = 'active-hero';
          } else {

            let objId = '';
            if (obj.src.includes('/effects')) {
              objId = 'effect';
            } else if (obj.src.includes('/01-frames/')) {
              objId = 'card-design-theme';
            } else if (obj.src.includes('/rarities/')) {
              objId = 'level-badge';
            } else if (obj.src.includes('/02-namebadge/')) {
              objId = 'name-badge';
            }

            canvasObjects[idx].id = objId;
            fitSizeOfCardDesign(canvasObjects[idx]);
          }
        }
      }
    });

    createPhysicalCard();
  };

  const createPhysicalCard = () => {
    const canvasObjs = canvas.getObjects();

    canvas.clone((canvasObj) => {
      canvasObj.setZoom(2.15);
      canvasObj.setWidth(canvasObj.width * canvasObj.getZoom());
      canvasObj.setHeight(canvasObj.height * canvasObj.getZoom());

      // important, otherwise all objects are moved
      canvasObj.getObjects()
      .map((obj, index) => {
        if (canvasObjs[index].id.includes('-text') || canvasObjs[index].id.includes('user-') || canvasObjs[index].id === 'active-hero') { // because no ids was given of canvas clone
          canvasObj.getObjects()[index].top = obj.top + 17;
          canvasObj.getObjects()[index].left = obj.left + 17;
        } else { // width and height of the images scaled to the new printed size
          canvasObj.getObjects()[index].cropX = 0;
          canvasObj.getObjects()[index].cropY = 0;
          canvasObj.getObjects()[index].width = printSize.width;
          canvasObj.getObjects()[index].height = printSize.height;
          canvasObj.getObjects()[index].scaleToHeight(printSize.height)
          .scaleToWidth(printSize.width);
        }
      });

      canvasObj.setWidth(printSize.width);
      canvasObj.setHeight(printSize.height);

      canvasObj.requestRenderAll();

      const bigFile = dataURItoFile(canvasObj.toDataURL(), 'image.png');
      setBigCardFile(bigFile);
      enqueueSnackbar('Created physical card', { variant: 'success' });
      uploadBigLevelCard({
        variables: {
          directory: 'cards/self-init/physical',
          fileName: value + '.png',
        },
      });
    });
  };

  return (
    <CheckPhysicalCard
      value={value}
      handleChange={handleChange}
      onCheck={checkCard}
      loading={loading}
    />
  );
};

export default CheckPhysicalCardContainer;