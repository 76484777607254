import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { Avatar } from '@material-ui/core';
import { connect } from 'react-redux';
import HRNumbers from 'human-readable-numbers';
import Link from '@material-ui/core/Link';

import { getLiveStreamsByGameNameWithCount, getOfferWithData } from '../../apollo/mergedQueries';

import LiveStreamsByGame from '../../components/LiveStreamsByGame/LiveStreamsByGame';
import TopViewHeader from '../../components/TopViewHeader/TopViewHeader';
import Loading from '../../components/Loading/Loading';
import PageTitle from '../../components/PageTitle/PageTitle';
import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import HeaderLine from '../../components/TopViewHeader/HeaderLine';
import MyGameEnergy from './MyGameEnergy';
import { replaceWidthAndHeight } from '../../utils/helper';
import GameCards from '../../components/LiveStreamsByGame/GameCards';
import GameLeaderBoard from '../LeaderBoard/GameLeaderBoard';
import CategoryCardsContainer from '../Cards/CategoryCards';
import SpecificRankingContainer from '../Ranking/SpecificRanking';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import OfferInfo from '../../components/Offer/OfferInfo';

import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  wrapper: {
    color: Colors.white,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 300px',
    backgroundColor: 'transparent',
  },
  gameBanner: {
    position: 'absolute',
    zIndex: -1,
    backgroundSize: 'cover',
    height: '100%',
    width: '100%',
  },
  gameLogo: {
    width: 80,
    height: 120,
  },
  headerLine: {
    ...Helpers.fillRow,
    ...Helpers.mainSpaceBetween,
    position: 'relative',
    zIndex: 10,
  },
  topLine: {
    backgroundColor: '#000000',
    '& .MuiBreadcrumbs-root': {
      marginBottom: '0 !important',
    },
  },
  innerWrapper: {
    maxWidth: 1440,
  },
  avatar: {
    border: '1px solid #010204',
    borderRadius: 0,
    width: 180,
    height: 240,
    marginRight: 30,
  },
  showMoreCards: {
    ...Helpers.fillRowCenter,
    ...Helpers.textCenter,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: Colors.white,
    margin: 5,
    width: 110,
    height: 170,
    fontWeight: 'bold',
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
  },
  contentHeaderLine: {
    border: '1px solid black',
    background: 'linear-gradient(to bottom, #051329, #000101 2px, #03080F, #0A1527)',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    height: 65,
    paddingLeft: 265,
    paddingRight: 15,
    ...Helpers.fillRowCross,
    ...Helpers.mainSpaceBetween,
  },
  headerTab: {
    color: '#2C3849',
    textTransform: 'uppercase',
    padding: '0 14px',
    height: '100%',
    borderBottom: '2px solid transparent',
    cursor: 'pointer',
    marginRight: 20,
    ...Helpers.fillRowCross,
    fontWeight: 'bold',
    '&:hover': {
      color: Colors.white,
    },
  },
  statsKey: {
    color: '#5C79A8',
    textTransform: 'uppercase',
    fontSize: 15,
  },
  statsValue: {
    color: Colors.white,
    fontSize: 26,
    fontFamily: 'Bebas Neue',
    ...Helpers.textCenter,
  },
  activeTab: {
    borderBottomColor: '#9D232D',
    color: Colors.white,
  },
  circle: {
    borderRadius: '50%',
    backgroundColor: '#A5252E',
    width: 7,
    height: 7,
    marginRight: 7,
  },
}));

const LiveStreamsBySpecificGame = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const gameName = decodeURIComponent(props.match.params.name);
  const trimmedGameName = gameName.toLowerCase()
  .replace(/ /g, '');
  // const eventConf = conf.event_games && conf.event_games[trimmedGameName];
  const eventConf = false;
  const isEvent = !!eventConf;
  const [page, setPage] = useState(1);
  const [tab, setTab] = useState(isEvent ? 1 : 0);
  const perPage = 24;

  const variables = {
    game: gameName,
    sorting: { field: 'viewer_count', order: 'DESC' },
    pagination: { perPage, page: 1 },
  };
  const getLiveStreamsByGameNameWithCountQuery = useQuery(getLiveStreamsByGameNameWithCount, {
    variables,
    fetchPolicy: useCacheWithExpiration('getLiveStreamsByGameNameWithCount', variables),
    pollInterval: conf.poll_intervals.long_refresh,
  });

  const offerId = conf.offer_id && conf.offer_id[trimmedGameName];
  const getOfferWithDataQuery = useQuery(getOfferWithData, {
    variables: { offerId },
    fetchPolicy: useCacheWithExpiration('getOfferWithData', { offerId }),
    skip: !conf.offer_exists || !isEvent,
  });

  const streams = (getLiveStreamsByGameNameWithCountQuery.data) && getLiveStreamsByGameNameWithCountQuery.data.liveStreamsByGameName;
  const game = (getLiveStreamsByGameNameWithCountQuery.data) && getLiveStreamsByGameNameWithCountQuery.data.gameWithLiveStreamsCount;
  const isOrderPossible = (getOfferWithDataQuery.data) && getOfferWithDataQuery.data.isOrderPossible;
  const offer = (getOfferWithDataQuery.data) && getOfferWithDataQuery.data.getOffer;

  const background = isEvent ?
    `url("${conf.cdn_url}/${eventConf.bg}")`
    :
    'linear-gradient(to bottom, rgba(4, 16, 34, 0.75) 0, rgba(4, 16, 34, 1) 290px), url("https://static-cdn.jtvnw.net/categorydb-production-game-banners/32399/en-us/34c8eea7-7eee-43fe-aa50-4f3407ac3760.png")';

  const tabs = [
    { title: t('streams') },
    { title: t('category_cards') },
    { title: t('ladders') },
  ];

  const hasCards = false;
  const totalCards = 0;

  return (
    <TopViewHeader>
      <PageTitle title={game && game.name} />
      <div
        className={classes.wrapper}
        style={{ backgroundImage: background, ...(isEvent ? { backgroundSize: 'auto' } : {}) }}
      >
        <div
          className={classes.innerWrapper}
          style={{ margin: '45px auto 0', padding: 0, }}
        >
          <div
            className={classes.headerLine}
            style={{ paddingBottom: 20 }}
          >
            <div style={{ ...Helpers.fillRow, marginLeft: 35, height: 240 }}>
              {game &&
              <Avatar
                alt="Game logo"
                src={replaceWidthAndHeight(game.box_art_url, 180, 240)}
                className={classes.avatar}
              />
              }
              {!isEvent &&
              <div style={{ marginTop: 35 }}>
                {game &&
                <HeaderLine
                  title={game.name}
                  subTitle={t('online_streams', { count: game ? game.count_streams : 0 })}
                />
                }
              </div>
              }
            </div>
          </div>
          {(isEvent && game) &&
          <div style={{
            ...Helpers.fillRow,
            marginTop: 32,
            marginLeft: 35,
            marginBottom: 50,
            position: 'relative',
          }}>
            <img
              src={`${conf.cdn_url}/${eventConf.char}`}
              alt="Character"
              style={{ position: 'absolute', right: 20, bottom: -50 }}
            />

            <Link href={`https://twitch.tv/directory/game/${game.name}`} target="_blank">
              <img
                src={`${process.env.PUBLIC_URL}/icons-png/twitch_square.png`}
                alt="Twitch"
                width={50}
                style={{ marginRight: 15 }}
              />
            </Link>
            <div>
              <div style={{
                fontFamily: 'Bebas Neue',
                fontSize: 36,
                lineHeight: 1,
                ...Helpers.fillRow,
              }}>
                {game.name}
                <div style={{ ...Helpers.fillRowCenter, marginLeft: 8 }}>
                  <div className={classes.circle} />
                  <div style={{ fontSize: 20 }}>
                    {t('online_streams', { count: streams && streams.length })}
                  </div>
                </div>
              </div>
              <div style={{
                color: '#5C79A8',
                fontSize: 15,
                lineHeight: 1,
                textTransform: 'uppercase',
              }}>
                Game
              </div>
            </div>
            {(totalCards && totalCards > 0) ?
              <div style={{ marginLeft: 150 }}>
                <div
                  className={classes.statsValue}>{totalCards && HRNumbers.toHumanString(totalCards)}</div>
                <div className={classes.statsKey}>{t('collected_cards')}</div>
              </div>
              :
              <></>
            }
          </div>
          }
          <div style={{
            backgroundColor: '#020812',
            marginTop: isEvent ? 0 : -50,
            minHeight: '100vh',
            borderRadius: 20,
          }}>
            <div
              className={classes.contentHeaderLine}
              style={{
                ...(isEvent ? { padding: '0 50px' } : {}),
              }}
            >
              <div style={{
                ...Helpers.fillRowCross,
                height: '100%',
                position: 'relative',
                zIndex: 100,
              }}>
                {game && tabs.map((tabParam, index) => (
                  ((index === 1 && (hasCards || isEvent)) || (index !== 1)) ?
                    <div
                      key={`ContentHeaderTab${index}`}
                      className={[classes.headerTab, index === tab ? classes.activeTab : ''].join(' ')}
                      onClick={() => setTab(index)}
                    >
                      {tabParam.title}
                    </div>
                    : null
                ))}
              </div>
              {(game) &&
              <MyGameEnergy game={game._id} />
              }
            </div>

            <div style={{ padding: 30 }}>
              {tab === 0 &&
              <>
                {getLiveStreamsByGameNameWithCountQuery.loading ?
                  <Loading />
                  :
                  <LiveStreamsByGame
                    streams={streams}
                    hasMore={(game) && game.count_streams > streams.length}
                    onLoadMore={async () => {
                      try {
                        await getLiveStreamsByGameNameWithCountQuery.fetchMore({
                          variables: {
                            pagination: {
                              perPage,
                              page: page + 1,
                            },
                          },
                          updateQuery: (prev, { fetchMoreResult }) => {
                            if (!fetchMoreResult) return prev;
                            setPage(page + 1);
                            return Object.assign({}, prev, {
                              liveStreamsByGameName: [...prev.liveStreamsByGameName, ...fetchMoreResult.liveStreamsByGameName],
                            });
                          },
                        });
                      } catch {
                      }
                    }}
                  />
                }
              </>
              }
              {tab === 1 &&
              <>
                {(offer && eventConf) &&
                <OfferInfo offer={offer} isOrderPossible={isOrderPossible} eventConfig={eventConf} />
                }
                {!game ?
                  <Loading />
                  :
                  <CategoryCardsContainer game={game} />
                }
              </>
              }
              {tab === 2 &&
              <>
                <div
                  style={{
                    ...Helpers.fillRowCenter,
                    background: '#051329',
                    borderRadius: 20,
                  }}
                >
                  <div style={{ flex: 1 }}>
                    {game &&
                    <GameLeaderBoard game={game} />
                    }
                  </div>
                  <div style={{ flex: 1, margin: '0 20px' }}>
                    <GameLeaderBoard game={game} isMonthly />
                  </div>
                  <div style={{ flex: 1 }}>
                    {game &&
                    <GameLeaderBoard game={game} isWeekly />
                    }
                  </div>
                </div>
                <div style={{ background: '#051329', borderRadius: 20, marginTop: 35 }}>
                  {game &&
                  <SpecificRankingContainer game={game} />
                  }
                </div>
              </>
              }
            </div>
          </div>
        </div>
      </div>
    </TopViewHeader>
  );
};

const mapStateToProps = (state) => {
  const { extra } = state;
  return { extra };
};

export default connect(mapStateToProps)(LiveStreamsBySpecificGame);