import React, { useRef, useState } from 'react';
import { useLoader, extend, useFrame } from '@react-three/fiber';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { useTexture, useGLTF } from '@react-three/drei';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';

import conf from '../../conf.json';

// important, otherwise the text object will not work
extend({ TextGeometry });

const BoosterObject = (props) => {
  const { frontImage, backImage, userLogo, isRotating, isOblique, isCustomPack } = props;
  const [clicked, setClicked] = useState(false);

  const fixTexture = (planeWidth, planeHeight) => {
    return function (texture) {
      const planeAspect = planeWidth / planeHeight;
      const imageAspect = texture.image.width / texture.image.height;
      const aspect = imageAspect / planeAspect;

      texture.offset.x = aspect > 1 ? (1 - 1 / aspect) / 2 : 0;
      texture.repeat.x = aspect > 1 ? 1 / aspect : 1;

      texture.offset.y = aspect > 1 ? 0 : (1 - aspect) / 2;
      texture.repeat.y = aspect > 1 ? 1 : aspect;
    };
  };

  // load all textures
  const [frontTexture, backTexture, userTexture] = useTexture([
    `${conf.cdn_url}/${frontImage}`,
    `${conf.cdn_url}/${backImage}`,
    userLogo,
  ]);

  const group = useRef();
  const { nodes, materials } = useGLTF(`${conf.cdn_url}/3d-objects/Pack_Preview4.glb`);

  // Load obj file
  // const obj = useLoader(OBJLoader, 'https://cdn.streamheroes.gg/3d-objects/card.obj');
  const font = useLoader(FontLoader, `${conf.cdn_url}/3d-objects/helvetiker_regular.json`);

  useFrame(() => {
    if (isRotating && !clicked)
      group.current.rotation.y += 0.01;
  });

  const textOptions = {
    font,
    size: 0.3,
    height: 0.01,
    curveSegments: 21,
    bevelThickness: 0,
    bevelSize: 1,
    bevelSegments: 10,
  };

  fixTexture(frontTexture);
  fixTexture(backTexture);

  frontTexture.flipY = false;
  backTexture.flipY = false;
  userTexture.flipY = false;

  return (
    <group
      ref={group}
      {...props}
      dispose={null}
      scale={3.4}
      rotation={[isOblique ? -0.4 : 0, 0, 0]}
      onClick={(e) => setClicked(true)}
      onPointerLeave={(e) => setClicked(false)}
    >
      <group scale={[0.5, 0.65, 0.02]}>
        <mesh geometry={nodes.Pack_1.geometry} material={materials.Back}>
          <meshBasicMaterial map={backTexture} />
        </mesh>
        <mesh geometry={nodes.Pack_2.geometry} material={materials.Front}>
          <meshBasicMaterial map={frontTexture} />
        </mesh>
      </group>
      {!isCustomPack &&
      <>
        <mesh
          geometry={nodes.Profile.geometry}
          material={materials.Mt_Profile}
          position={[0.034, 0, 0.022]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.36}
        >
          <meshBasicMaterial map={userTexture} />
        </mesh>
        <mesh position={[-0.26, -0.52, 0.022]} scale={0.3}>
          <textGeometry attach="geometry" args={['Base Pack', textOptions]} />
          <meshBasicMaterial color="white" />
        </mesh>
      </>
      }
    </group>
  );
};

export default BoosterObject;