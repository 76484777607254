import React, { useEffect, useRef, useState } from 'react';
import { useLoader, extend, useFrame } from '@react-three/fiber';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { useAnimations, useGLTF, useTexture, shaderMaterial } from '@react-three/drei';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { LoopOnce } from 'three';
// import glsl from "babel-plugin-glsl/macro";

import conf from '../../conf.json';

// important, otherwise the text object will not work
extend({ TextGeometry });

const BoosterAnimation = (props) => {
  const { frontImage, userLogo, isCustomPack } = props;
  const group = useRef();
  const [showPack, setShowPack] = useState(true);

  // load all textures
  const [frontTexture, userTexture] = useTexture([
    `${conf.cdn_url + frontImage}`,
    userLogo,
  ]);
  // Load obj file
  const {
    nodes,
    animations,
  } = useGLTF(`${conf.cdn_url}/3d-objects/booster_v1.glb`);
  // } = useGLTF('https://cdn.streamheroes.gg/3d-objects/Card_Preview_ShapeKey_test.glb');
  // } = useGLTF('https://cdn.streamheroes.gg/3d-objects/Pack_Preview_exploding_animation.glb');
  const font = useLoader(FontLoader, `${conf.cdn_url}/3d-objects/helvetiker_regular.json`);
  const { actions } = useAnimations(animations, group);

  useEffect(() => {
    // actions['ArmatureAction'].play();

    for (const key of Object.keys(actions)) {
      // If clampWhenFinished is set to true the animation will automatically be paused on its last frame.
      actions[key].clampWhenFinished = true;

      actions[key].setLoop(LoopOnce, 1)
      .play();
      // .halt();

      /*if (startAnimation) {*/
      // Sets the loop mode and the number of repetitions. This method can be chained.
      // LoopOnce - playing the clip once
      /*actions[key].setLoop(LoopOnce, 1)
      .reset()
      .play();*/
      // }
      setTimeout(() => setShowPack(false), 3500);
    }
  });

  frontTexture.flipY = false;
  userTexture.flipY = false;

  const textOptions = {
    font,
    size: 0.3,
    height: 0.01,
    curveSegments: 21,
    bevelThickness: 0,
    bevelSize: 1,
    bevelSegments: 10,
  };
  const scale = 3.5;

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Pack" position={[-0.02, 0, 0]}>
        {showPack &&
        <>
          <mesh
            name="Pack_1"
            geometry={nodes.Pack_1.geometry}
            morphTargetDictionary={nodes.Pack_1.morphTargetDictionary}
            morphTargetInfluences={nodes.Pack_1.morphTargetInfluences}
            scale={scale}
          >
            <meshStandardMaterial map={frontTexture} />
          </mesh>
          <mesh
            name="Pack_2"
            geometry={nodes.Pack_2.geometry}
            morphTargetDictionary={nodes.Pack_2.morphTargetDictionary}
            morphTargetInfluences={nodes.Pack_2.morphTargetInfluences}
            scale={scale}
          >
            <meshStandardMaterial map={frontTexture} />
          </mesh>
        </>
        }
        {!isCustomPack &&
        <>
          <mesh
            name="Profile"
            geometry={nodes.Profile.geometry}
            morphTargetDictionary={nodes.Profile.morphTargetDictionary}
            morphTargetInfluences={nodes.Profile.morphTargetInfluences}
            scale={scale + 0.1}
            position={[0.11, -0.025, 0.08]}
            rotation={[Math.PI / 2, 0, 0]}
          >
            <meshStandardMaterial map={userTexture} />
          </mesh>
          <mesh
            name="Text"
            geometry={nodes.Text.geometry}
            position={[-1.15, -1.9, 0.075]}
            scale={scale - 2.2}
          >
            <textGeometry attach="geometry" args={['Base Pack', textOptions]} />
            <meshStandardMaterial color="red" />
          </mesh>
          <mesh
            name="Text001"
            geometry={nodes.Text001.geometry}
            position={[-1.15, -1.9, 0.075]}
            scale={scale - 2.2}
          >
            <textGeometry attach="geometry" args={['Base Pack', textOptions]} />
            <meshStandardMaterial color="white" />
          </mesh>
        </>
        }
      </group>
      <group position={[0.05, 2.4, 0]} rotation={[-Math.PI, 0, 0]} scale={scale}>
        <mesh
          name="Rip_off001_1"
          geometry={nodes.Rip_off001_1.geometry}
          morphTargetDictionary={nodes.Rip_off001_1.morphTargetDictionary}
          morphTargetInfluences={nodes.Rip_off001_1.morphTargetInfluences}
        >
          <meshStandardMaterial map={frontTexture} />
        </mesh>
        <mesh
          name="Rip_off001_2"
          geometry={nodes.Rip_off001_2.geometry}
          morphTargetDictionary={nodes.Rip_off001_2.morphTargetDictionary}
          morphTargetInfluences={nodes.Rip_off001_2.morphTargetInfluences}
        >
          <meshStandardMaterial map={frontTexture} />
        </mesh>
      </group>
    </group>
  );

  /*return (
    <group ref={group} {...props} dispose={null} scale={3.5}>
      <group name="Pack_main" position={[-0.074, 0, 0]} scale={[0.5, 0.65, 0.02]}>
        <mesh geometry={nodes.Cube004.geometry} material={nodes.Cube004.material}>
          <meshStandardMaterial map={boosterTexture} />
        </mesh>
        <mesh geometry={nodes.Cube004_1.geometry} material={nodes.Cube004_1.material}>
          <meshStandardMaterial map={boosterTexture} />
        </mesh>
        <mesh
          geometry={nodes.Profile.geometry}
          material={materials.Mt_profile}
          position={[0.09, 0, 1.15]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.71, 18.02, 0.55]}
        >
          <meshStandardMaterial map={userTexture} />
        </mesh>
        <mesh
          geometry={nodes.Text.geometry}
          position={[-0.4, 0.65, 1.74]}
          scale={0.5}
        >
          <textGeometry attach="geometry" args={['Base Pack', textOptions]} />
          <meshStandardMaterial color="white" />
        </mesh>
      </group>
      <group name="Pack_Rip_off" position={[-0.07, -2.37, 0]}
             scale={[0.5, 0.65, 0.02]}>
        <mesh
          name="Cube001"
          geometry={nodes.Cube001.geometry}
          material={nodes.Cube001.material}
          morphTargetDictionary={nodes.Cube001.morphTargetDictionary}
          morphTargetInfluences={nodes.Cube001.morphTargetInfluences}
        >
          <meshStandardMaterial map={boosterTexture} />
        </mesh>
        <mesh
          name="Cube001_1"
          geometry={nodes.Cube001_1.geometry}
          material={nodes.Cube001_1.material}
          morphTargetDictionary={nodes.Cube001_1.morphTargetDictionary}
          morphTargetInfluences={nodes.Cube001_1.morphTargetInfluences}
        >
          <meshStandardMaterial map={boosterTexture} />
        </mesh>
      </group>
      <mesh
        name="glow_animation"
        geometry={nodes.glow_animation.geometry}
        material={materials['Material.001']}
        morphTargetDictionary={nodes.glow_animation.morphTargetDictionary}
        morphTargetInfluences={nodes.glow_animation.morphTargetInfluences}
        position={[-0.07, 0.81, 0]}
        scale={[0.5, 0.65, 0.02]}
      >
        <meshStandardMaterial color="red" />
      </mesh>




      <group scale={[0.5, 0.65, 0.02]}>
        <mesh
          name="Cube004"
          geometry={nodes.Cube004.geometry}
          material={materials.Front}
          morphTargetDictionary={nodes.Cube004.morphTargetDictionary}
          morphTargetInfluences={nodes.Cube004.morphTargetInfluences}
        />
        <mesh
          name="Cube004_1"
          geometry={nodes.Cube004_1.geometry}
          material={materials.Back}
          morphTargetDictionary={nodes.Cube004_1.morphTargetDictionary}
          morphTargetInfluences={nodes.Cube004_1.morphTargetInfluences}
        />
      </group>
      <group rotation={[Math.PI / 2, 0, 0]}>
        <mesh
          name="Card_1"
          geometry={nodes.Card_1.geometry}
          material={materials.Front}
          morphTargetDictionary={nodes.Card_1.morphTargetDictionary}
          morphTargetInfluences={nodes.Card_1.morphTargetInfluences}
        />
        <mesh
          name="Card_2"
          geometry={nodes.Card_2.geometry}
          material={materials.Back}
          morphTargetDictionary={nodes.Card_2.morphTargetDictionary}
          morphTargetInfluences={nodes.Card_2.morphTargetInfluences}
        >
          <meshStandardMaterial attach="material" map={boosterTexture} />
        </mesh>
        <mesh
          name="Card_3"
          geometry={nodes.Card_3.geometry}
          material={materials.Border}
          morphTargetDictionary={nodes.Card_3.morphTargetDictionary}
          morphTargetInfluences={nodes.Card_3.morphTargetInfluences}
        />
      </group>
    </group>
    <group ref={group} dispose={null}>
      <group name="Pack_version_2001" position={[-0.074000001, 0, 0]} scale={[0.5005837083, 0.6479726434, 0.019600993]}>
        <mesh geometry={nodes.Cube004.geometry} material={nodes.Cube004.material} />
        <mesh geometry={nodes.Cube004_1.geometry} material={nodes.Cube004_1.material} />
      </group>
      <group position={[-0.0742810965, 0, 0]} scale={[0.5005837083, 0.6479726434, 0.019600993]}>
        <mesh geometry={nodes.Cube001.geometry} material={nodes.Cube001.material} />
        <mesh geometry={nodes.Cube001_1.geometry} material={nodes.Cube001_1.material} />
      </group>
    </group>
  );*/
};

export default BoosterAnimation;