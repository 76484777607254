import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import AnimationButton from '../../components/Button/AnimationButton';
import CustomTooltip from '../../components/Tooltip/CustomTooltip';
import HoverIcon from '../Icon/HoverIcon';

import conf from '../../conf';

const useStyles = makeStyles(() => ({
  hoverText: {
    color: Colors.white,
    '& span:first-child': {
      color: '#F306FC',
    },
    '& span:last-child': {
      color: '#FFFE47',
    },
  },
}));

const SetFooterRow = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { costs, booster, redeemBooster } = props;

  const isEnergy = !!booster.energy_costs;
  const isInactive = isEnergy ? booster.energy_costs > booster.user_energy : booster.coin_costs > booster.user_coins;

  return (
    <>
      <div style={{
        ...Helpers.fillRowCross,
        ...Helpers.mainSpaceBetween,
        position: 'relative',
        marginTop: 22,
      }}>
        <div style={Helpers.fillRowCross}>
          <img
            src={`${conf.cdn_url}/assets/template/${isEnergy ? 'heroMaker/big_energy' : 'sh_coin'}.png?height=37`}
            alt="Energy" />
          <div style={{
            fontFamily: 'Montserrat',
            fontSize: 30,
            fontWeight: 500,
            color: Colors.white,
            marginLeft: 8,
          }}>
            {costs}
          </div>
        </div>

        <div>
          <CustomTooltip
            title={
              <p className={classes.hoverText}
                 dangerouslySetInnerHTML={{ __html: t('booster_explanation') }}
              />
            }
          >
            <div style={{ borderRadius: 50 }}>
              <HoverIcon
                icon="fa-light fa-circle-info" hoverIcon="fa-solid fa-circle-info"
                color={Colors.halfWhite} hoverColor={Colors.white}
                style={{ fontSize: 18 }}
              />
            </div>
          </CustomTooltip>
        </div>
      </div>

      <AnimationButton
        status={isEnergy ? 'neonPurple' : 'gold'}
        onClick={() => redeemBooster(booster._id, 1)}
        disabled={isInactive}
        style={{ marginTop: 10, fontFamily: 'Montserrat', fontSize: 14, width: '100%' }}
      >
        <i
          className="fa-light fa-plus"
          style={{ fontSize: 18, marginRight: 10, verticalAlign: 'bottom' }}
        />
        {t('redeem')}
      </AnimationButton>
    </>
  );
};

export default SetFooterRow;