import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import FlagWithTooltip from '../FlagIcon/FlagWithTooltip';

const useStyles = makeStyles(() => ({
  container: {
    ...Helpers.fillRow,
    ...Helpers.mainSpaceBetween,
    marginBottom: 10,
    marginTop: 40,
  },
  place: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainStart,
    background: Colors.subTextColor,
    padding: 18,
    margin: '10px 0',
    borderRadius: 10,
    position: 'relative',
    width: '29%',
  },
  name: {
    fontSize: 30,
    fontFamily: 'Bebas Neue',
    color: Colors.white,
    lineHeight: 1,
  },
  avatar: {
    borderWidth: 2,
    borderColor: '#ba2044',
    width: 90,
    height: 90,
    marginRight: 20,
  },
  value: {
    color: Colors.white,
    lineHeight: 1,
    width: 150,
    fontWeight: 700,
  },
  pos: {
    fontSize: 90,
    lineHeight: 1,
    fontWeight: 700,
    position: 'absolute',
    bottom: -15,
    right: 45,
    opacity: 0.5,
  },
  rightWrapper: {
    ...Helpers.fillCol,
    marginLeft: 110,
  },
}));

const RankingLeaders = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  let { entries } = props;

  if (entries.length === 0)
    return null;

  entries = entries.map(entry => {
    if (!entry.total_points)
      entry.total_points = entry.seq;
    return entry;
  });

  const bgGradients = [
    '#E9A840, #DF9600',
    '#D0D0D0, #878787',
    '#975026, #92471B',
  ];
  const colors = ['#82411D', '#646464', '#241A17'];

  return (
    <div className={classes.container}>
      {entries.map((entry, index) => {
        const premiumFrame = entry.viewer.subscription_tier && entry.viewer.premium_frame;

        return (
          <div
            className={classes.place}
            style={{ background: `linear-gradient(to top, ${bgGradients[index]})` }}
            key={`RankingLeaderKey#${entry.viewer._id}`}
          >
            <div style={{ position: 'absolute', top: -25, left: 20 }}>
              {premiumFrame &&
              <div>
                <img src={`${entry.viewer.premium_frame}?height=130`}
                     style={{ position: 'absolute', top: -18, left: -24 }} alt="Frame" />
              </div>
              }
              <Link href={`/profile/${entry.viewer.login}`}>
                <Avatar
                  className={classes.avatar}
                  alt={entry.viewer.login}
                  src={entry.viewer.profile_image_url}
                  style={premiumFrame ? { borderColor: 'transparent' } : {}}
                />
              </Link>
              {(entry.viewer.country) &&
              <FlagWithTooltip
                code={entry.viewer.country}
                style={{ position: 'absolute', left: 0, bottom: 0 }}
              />
              }
            </div>
            <div className={classes.rightWrapper}>
              <div className={classes.name}>
                <Link href={`/profile/${entry.viewer.login}`}>
                  {entry.viewer.display_name}
                </Link>
              </div>
              <div className={classes.value} style={{ color: colors[index] }}>
                {entry.total_points.toLocaleString()} {t('points_rank')}
              </div>
            </div>
            <div className={classes.pos} style={{ color: colors[index] }}>
              #{index + 1}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default withRouter(RankingLeaders);