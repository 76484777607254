import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';

import { getRedeemedCardObjects } from '../../apollo/mergedQueries';

import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import InventoryAllCardsSelectOne from '../../components/Marketplace/InventoryAllCardsSelectOne';

const InventoryAllCardsSelectOneContainer = (props) => {
  const { collectorsView } = props;

  const [isSeasonal, setIsSeasonal] = useState(false);
  const [sorting, setSorting] = useState({
    field: 'created_at',
    order: 'DESC',
  });
  const [quality, setQuality] = useState({
    min: 0,
    max: 100,
  });
  const [rarity, setRarity] = useState([]);
  const [page, setPage] = useState(2);
  const perPage = 25;

  const variables = {
    pagination: {
      page: 1,
      perPage,
    },
    rarity,
    min_quality: quality.min,
    max_quality: quality.max,
    sorting,
    match: null,
  };

  const { data, loading, fetchMore } = useQuery(getRedeemedCardObjects, {
    variables,
    fetchPolicy: useCacheWithExpiration('getRedeemedCardObjects'),
    skip: !collectorsView.showAll,
  });

  const allData = data && data.getRedeemedCardObjects;
  const cardDecks = data && data.getCardDecks;
  const myViewer = data && data.getMyViewer;

  return (
    <InventoryAllCardsSelectOne
      loading={loading}
      cards={allData && allData.cards}
      cardDecks={cardDecks}
      setSorting={setSorting}
      sorting={sorting}
      setRarity={setRarity}
      rarity={rarity}
      setQuality={setQuality}
      quality={quality}
      setIsSeasonal={setIsSeasonal}
      isSeasonal={isSeasonal}
      totalCount={allData && allData.count}
      myViewer={myViewer}
      onLoadMore={async () => {
        setPage(page + 1);
        try {
          await fetchMore({
            variables: { ...variables, ...{ pagination: { perPage, page } } },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              return {
                getRedeemedCardObjects: {
                  cards: [...prev.getRedeemedCardObjects.cards, ...fetchMoreResult.getRedeemedCardObjects.cards],
                  count: prev.getRedeemedCardObjects.count,
                  __typename: prev.getRedeemedCardObjects.__typename,
                },
              };
            },
          });
        } catch {
        }
      }}
    />
  );
};

const mapStateToProps = (state) => {
  const { collectorsView } = state;
  return { collectorsView };
};

export default connect(mapStateToProps)(InventoryAllCardsSelectOneContainer);