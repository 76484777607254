import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import HeroMakerFooter from './HeroMakerFooter';
import Helpers from '../../theme/Helpers';
import { resetHeroMaker } from '../../redux/actions';

import conf from '../../conf';

const useStyles = makeStyles({
  activeHeroArea: {
    background: `url("${conf.cdn_url}/assets/template/heroMaker/bg_placeholder.png?height=360")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: 8,
    height: 360,
    width: 234,
    ...Helpers.fillRowCenter,
    position: 'relative',
  },
});

const CheckHero = (props) => {
  const classes = useStyles();
  const {
    character,
    body,
    head,
    nextTab,
    backTab,
    footerValue,
    heroMaker,
    viewer,
    heroMakerCategories,
    serviceURL,
    selectedHero,
  } = props;
  const [disabled, setDisabled] = useState(false);

  const saveHero = async () => {
    setDisabled(true);

    const heroMakerInput = {
      character,
      assets: [
        {
          asset: '15_bodys',
          colors: [heroMaker.bodyColor],
          type: body,
          sorting: heroMakerCategories['15_bodys'].sorting,
        },
        {
          asset: '11_heads',
          colors: [heroMaker.bodyColor],
          type: head,
          sorting: heroMakerCategories['11_heads'].sorting,
        },
        ...heroMaker.assets,
      ],
    };

    if (selectedHero)
      heroMakerInput.id = selectedHero;

    await axios.post(`${serviceURL}/create-heroMaker-images`, { viewer, heroMakerInput })
    .then(() => {
      nextTab();
      props.resetHeroMaker();
    })
    .catch((error) => {
      console.log(error);
    });
  };

  return (
    <>
      <div style={{ marginBottom: 15, ...Helpers.fillRowCenter }}>
        <div className={classes.activeHeroArea}>
          <img src={heroMaker.currentHero} alt="My hero" height={360} />
        </div>
      </div>
      <HeroMakerFooter
        onClick={saveHero}
        value={footerValue}
        backTab={backTab}
        disabled={disabled}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { heroMaker } = state;
  return { heroMaker };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    resetHeroMaker,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(CheckHero);