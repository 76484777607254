import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faBullhorn,
  faPlus,
  faEye,
  faTrash,
  faFlagCheckered,
  faBan,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import {
  deletePoll as deletePollMutation,
  updatePoll as updatePollMutation,
} from '../../apollo/mutations';
import { getPolls } from '../../apollo/queries';
import { firstCharUpperCase, subToLongString } from '../../utils/helper';

import Badge from '../Badge/Badge';
import CustomTooltip from '../Tooltip/CustomTooltip';

const useStyles = makeStyles(theme => ({
  table: {
    maxWidth: '100%',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
  },
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    color: theme.palette.common.black,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    '&:nth-of-type(odd)': {
      backgroundColor: '#dcdcdc',
    },
    '&:hover': {
      backgroundColor: '#c0c0c0',
    },
  },
}))(TableRow);

const PollsList = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { polls, setStatus, setPoll } = props;

  const [deletePoll] = useMutation(deletePollMutation, {
    onCompleted: (data) => {
      enqueueSnackbar(t('delete_poll_success'), { variant: 'success' });
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{ query: getPolls }],
  });
  const [updatePoll] = useMutation(updatePollMutation, {
    onCompleted: (data) => {
      enqueueSnackbar(t('update_poll_success'), { variant: 'success' });
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{ query: getPolls }],
  });

  const actionPoll = (status, poll) => {
    setPoll(poll);
    setStatus(status);
  };

  const changePollState = (poll, isPublish, isFinish) => {
    const finishedAt = isFinish ? moment() : poll.finished_at;

    const saveObject = {
      title: poll.title,
      answers: poll.answers,
      is_published: isPublish === null ? poll.is_published : isPublish,
      published_at: isPublish === null ? poll.published_at : null,
      finished_at: finishedAt,
      type: poll.type,
      target: poll.target,
    };

    updatePoll({ variables: { poll: poll._id, poll_input: saveObject } });
  };

  return (
    <React.Fragment>
      <TableContainer>
        <Table className={classes.table} aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell width={300}>{t('polls_table_question')}</StyledTableCell>
              <StyledTableCell align="center">{t('polls_table_target')}</StyledTableCell>
              <StyledTableCell align="center">{t('polls_table_is_published')}</StyledTableCell>
              <StyledTableCell align="center">{t('polls_table_published_at')}</StyledTableCell>
              <StyledTableCell align="center">{t('polls_table_finished_at')}</StyledTableCell>
              <StyledTableCell align="center">{t('polls_table_answers')}</StyledTableCell>
              <StyledTableCell align="center">{t('polls_table_created_at')}</StyledTableCell>
              <StyledTableCell align="center">{t('polls_table_actions')}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {polls.map(row => {
              const createdAt = moment.unix(row.created_at / 1000)
              .format('DD.MM.YY HH:mm');
              const publishedAt = row.published_at ? moment.unix(row.published_at / 1000)
              .format('DD.MM.YY HH:mm') : '';
              const finishedAt = row.finished_at ? moment.unix(row.finished_at / 1000)
              .format('DD.MM.YY HH:mm') : t('polls_table_endless');

              const isPollFinished = row.finished_at ? moment()
              .isAfter(moment.unix(row.finished_at / 1000)) : false;

              let badgeStatus, badgeMsg;
              if (row.is_published) {
                badgeStatus = isPollFinished ? 'info' : 'success';
                badgeMsg = isPollFinished ? t('polls_table_finished') : t('polls_table_published');
              } else {
                badgeStatus = 'danger';
                badgeMsg = t('polls_table_not_published');
              }

              return (
                <StyledTableRow key={row._id}>
                  <StyledTableCell component="th" scope="row">
                    {subToLongString(row.title, 60)}
                  </StyledTableCell>
                  <StyledTableCell align="center">{firstCharUpperCase(row.target)}</StyledTableCell>
                  <StyledTableCell align="center">
                    <Badge status={badgeStatus}>{badgeMsg}</Badge>
                  </StyledTableCell>
                  <StyledTableCell align="center">{publishedAt}</StyledTableCell>
                  <StyledTableCell align="center">{finishedAt}</StyledTableCell>
                  <StyledTableCell align="center">
                    {t('polls_table_answers_x', { count: row.count_answers })}
                  </StyledTableCell>
                  <StyledTableCell align="center">{createdAt}</StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomTooltip title={t('view_poll')}>
                      <IconButton aria-label="view" onClick={() => actionPoll('view', row)}>
                        <FontAwesomeIcon icon={faEye} />
                      </IconButton>
                    </CustomTooltip>
                    {((!row.is_published || (row.is_published && row.count_answers === 0)) && !isPollFinished) &&
                    <CustomTooltip title={row.is_published ? t('poll_hide') : t('poll_publish')}>
                      <IconButton
                        aria-label="publish"
                        onClick={() => changePollState(row, !row.is_published, null)}
                      >
                        <FontAwesomeIcon icon={row.is_published ? faBan : faBullhorn} />
                      </IconButton>
                    </CustomTooltip>
                    }
                    {(row.is_published && !row.finished_at) &&
                    <CustomTooltip title={t('poll_finish')}>
                      <IconButton
                        aria-label="finish"
                        onClick={() => changePollState(row, null, true)}
                      >
                        <FontAwesomeIcon icon={faFlagCheckered} />
                      </IconButton>
                    </CustomTooltip>
                    }
                    {(row.count_answers === 0 && (!isPollFinished || (!row.is_published && isPollFinished))) &&
                    <CustomTooltip title={t('edit_poll')}>
                      <IconButton aria-label="edit" onClick={() => actionPoll('edit', row)}>
                        <FontAwesomeIcon icon={faEdit} />
                      </IconButton>
                    </CustomTooltip>
                    }
                    {row.count_answers === 0 &&
                    <CustomTooltip title={t('delete_poll')}>
                      <IconButton
                        aria-label="delete"
                        onClick={() => deletePoll({ variables: { id: row._id } })}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </IconButton>
                    </CustomTooltip>
                    }
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomTooltip title={t('new_poll')}>
        <Fab
          color="secondary"
          aria-label="add"
          className={classes.fab}
          onClick={() => actionPoll('new', null)}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Fab>
      </CustomTooltip>
    </React.Fragment>
  );
};

export default PollsList;