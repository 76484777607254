import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import SVGIcon from '../SVGIcon/SVGIcon';
import { onKeyPress } from '../../utils/helper';
import AnimationButton from '../Button/AnimationButton';

import conf from '../../conf.json';

const useStyles = makeStyles({
  gemItem: {
    width: 46,
    height: 46,
    background: 'rgba(255, 255, 255, 0.04)',
    ...Helpers.fillRowCenter,
    marginRight: 3,
  },
  formInput: {
    width: 400,
    marginRight: 4,
    background: 'rgba(255, 255, 255, 0.04)',
    borderColor: 'transparent',
    borderRadius: 0,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
      top: 0,
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '& .MuiFormLabel-root': {
      color: Colors.halfWhite,
    },
    '& .MuiOutlinedInput-input': {
      padding: '14px 12px',
    },
  },
  amountArea: {
    background: 'rgba(255, 255, 255, 0.04)',
    height: 18,
    width: 'calc(100% - 32px)',
    padding: '14px 16px',
    fontSize: 14,
  },
});

const MarketplaceBuySellDialogInput = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { avgPrice, sellLoading, sellOffer, listingCoupons } = props;
  const [receiveValue, setReceiveValue] = useState(t('input_amount'));
  const [buyerValue, setBuyerValue] = useState('');

  const onInput = (e) => {
    let value = parseInt(e.currentTarget.textContent);
    if (value < 1)
      value = 1;
    else if (value > 8000)
      value = 8000;
    else if (!value)
      value = '';

    setValue(e.currentTarget.id, value);
    setCursorToEnd(e.currentTarget.id);
  };

  const setValue = (id, value) => {
    console.log('setValue', value);

    if (id === 'receive-amount') {
      setReceiveValue(value);
      if (parseInt(value))
        setBuyerValue(Math.round(value * 100 / 80));
    } else if (id === 'buyer-amount') {
      setBuyerValue(value);
      if (parseInt(value))
        setReceiveValue(Math.round(value - ((value * 20) / 100)));
      else {
        setReceiveValue(t('input_amount'));
        setBuyerValue('');
      }
    }
  };

  const setCursorToEnd = (id) => {
    const el = document.getElementById(id);
    const selection = window.getSelection();
    const range = document.createRange();
    selection.removeAllRanges();
    range.selectNodeContents(el);
    range.collapse(false);
    selection.addRange(range);
    el.focus();
  };

  const checkIntValue = (e) => {
    const { id, textContent } = e.currentTarget;
    const value = textContent;
    if (value === t('input_amount'))
      setValue(id, '');
    else if (value === '') {
      setValue(id, t('input_amount'));
      setBuyerValue('');
    }
  };

  return (
    <>
      <div style={{ width: '100%', ...Helpers.fillRowCross, paddingTop: 16 }}>
        <div style={{ flex: 1, marginRight: 7.5 }}>
          <div style={{ fontSize: 14, marginBottom: 6 }}>{t('you_receive')}</div>
          <div style={Helpers.fillRow}>
            <div className={classes.gemItem}>
              <SVGIcon
                src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
                height={22}
                width={16}
              />
            </div>

            <div
              id="receive-amount"
              className={classes.amountArea}
              style={receiveValue === t('input_amount') ? { color: Colors.halfWhite } : {}}
              contentEditable
              onKeyPress={(e) => onKeyPress(e, 3)}
              onInput={onInput}
              onClick={checkIntValue}
              onBlur={checkIntValue}
              suppressContentEditableWarning
            >
              {receiveValue}
            </div>
          </div>
        </div>
        <div style={{ flex: 1, marginRight: 7.5 }}>
          <div style={{ fontSize: 14, marginBottom: 6 }}>{t('sell_price')}</div>
          <div style={Helpers.fillRow}>
            <div className={classes.gemItem}>
              <SVGIcon
                src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
                height={22}
                width={16}
              />
            </div>

            <div
              id="buyer-amount"
              className={classes.amountArea}
              style={{ cursor: 'default' }}
            >
              {buyerValue}
            </div>
          </div>
        </div>
      </div>

      {avgPrice &&
      <div style={{ ...Helpers.fillRow, fontSize: 12, marginTop: 4 }}>
        <div style={{ color: Colors.halfWhite }}>{t('proposed_price')}:</div>
        <SVGIcon
          src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
          height={19}
          width={14}
          style={{ margin: '0 4px' }}
        />
        <div style={{ color: Colors.white }}>
          &Oslash; {t('x_gems', { count: avgPrice })}
        </div>
      </div>
      }

      <div style={{ ...Helpers.fillRow, fontSize: 12, marginTop: 4 }}>
        <div style={{ color: Colors.halfWhite }}>{t('listing_price')}:</div>
        <SVGIcon
          src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
          height={19}
          width={14}
          style={{ margin: '0 4px' }}
        />
        <div style={{
          color: listingCoupons > 0 ? 'red' : Colors.white,
          textDecoration: listingCoupons > 0 ? 'line-through' : 'none',
        }}>
          5
        </div>
        {listingCoupons > 0 &&
        <div style={{ color: Colors.white, marginLeft: 4 }}>
          0 - <span style={{
          color: Colors.halfWhite,
          fontSize: 10,
        }}>{t('x_listings_free', { count: listingCoupons })}</span>
        </div>
        }
      </div>

      <AnimationButton
        status="neonPink"
        style={{ width: '100%', fontSize: 14, fontWeight: 500, marginTop: 20, padding: 14 }}
        disabled={sellLoading || !Number.isInteger(receiveValue) || receiveValue < 20 || receiveValue > 10000}
        onClick={() => sellOffer(receiveValue)}
      >
        {sellLoading ?
          <i className="fa-light fa-loader fa-spin" style={{ fontSize: 18 }} />
          :
          t('place_on_marketplace')
        }
      </AnimationButton>
    </>
  );
};

export default MarketplaceBuySellDialogInput;
