import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import { Avatar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { getLoggedInOrganisationEventHeader } from '../../apollo/mergedQueries';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import TopBG from '../TopViewHeader/TopBG';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import { getMaximalLevelOfCards } from '../../utils/helper';
import CardsOpenGraphHeader from '../Cards/CardsOpenGraphHeader';
import Cards from '../Cards/Cards';
import EventStreamerCards from '../Streamer/EventStreamerCards';
import OrganisationMember from './OrganisationMember';

import conf from '../../conf.json';

const useStyles = makeStyles({
  headTrio: {
    ...Helpers.fillCol,
    color: Colors.white,
    marginBottom: -5,
  },
  bigAvatar: {
    position: 'absolute',
    top: 35,
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: 170,
    height: 170,
    borderColor: '#1a071d',
    borderWidth: 2,
    boxShadow: '0 0 0 13px #B60B28',
    background: 'linear-gradient(to left bottom, #f3133b, #781023)',
  },
  circle: {
    borderRadius: '50%',
    backgroundColor: '#A5252E',
    width: 7,
    height: 7,
    marginRight: 7,
  },
  container: {
    background: Colors.containerBGColor,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    minHeight: 500,
  },
  campFeature: {
    background: '#370A10',
    color: Colors.white,
    width: '100%',
    ...Helpers.fillCol,
  },
  checkedRow: {
    ...Helpers.fillRowCenter,
    margin: '14px 0',
    fontSize: 15,
    '& > img': {
      marginRight: 14,
    },
  },
  headText: {
    fontFamily: 'Bebas Neue',
    fontSize: 20,
    lineHeight: 1,
    ...Helpers.fillRowCenter,
  },
  bodyContainer: {
    padding: 20,
    backgroundColor: '#051329',
    color: Colors.white,
    minHeight: 300,
  },
  eventButton: {
    backgroundColor: '#09B71180',
    border: '1px solid #1FE325',
    borderRadius: 4,
    fontFamily: 'Bebas Neue',
    fontSize: 20,
    padding: '15px 50px',
    width: 'fit-content',
    cursor: 'pointer',
    margin: '0 auto',
    '&:hover': {
      backgroundColor: '#09B711BF',
    },
  },
  expandButton: {
    backgroundColor: Colors.wrapperLight,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    padding: 5,
    ...Helpers.textCenter,
    cursor: 'pointer',
    marginBottom: 20,
    '&:hover': {
      backgroundColor: Colors.wrapperDark,
    },
  },
});

const MobileEventOrganisation = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { organisation, eventConf, memberStreams } = props;
  const [expandMembers, setExpandMembers] = useState(false);

  const variables = {
    organisation: organisation._id,
    offerId: conf.offer_id && conf.offer_id[organisation.code],
  };
  const { data } = useQuery(getLoggedInOrganisationEventHeader, {
    variables,
    fetchPolicy: useCacheWithExpiration('getLoggedInOrganisationEventHeader', variables),
    pollInterval: 240000,
  });

  const tab = organisation.edition_to_show ? organisation.edition_to_show : 'official';
  const myCollectedPoints = (data) && data.viewerRedeemedPoints;
  const currentOffer = (data) && data.getOffer;
  const isOrderPossibleData = (data) && data.isOrderPossible;
  let cards = (data) && data.getTypeCards;
  const isOrderPossible = isOrderPossibleData && isOrderPossibleData.possible;
  const ownedCards = isOrderPossibleData && isOrderPossibleData.owned_card_ids.length;
  const energy = myCollectedPoints ? (myCollectedPoints.points_season - myCollectedPoints.redeemed_points_season) : 0;
  const maxLevelCard = getMaximalLevelOfCards(cards);
  const isSpecial = tab !== 'official';

  const coinsInformation = {
    maxLevelCard,
    viewerRedeemedPoints: myCollectedPoints,
  };

  if (coinsInformation && !coinsInformation.viewerRedeemedPoints)
    coinsInformation.viewerRedeemedPoints = {
      points_season: 0,
      redeemed_points: 0,
    };

  if (cards) {
    cards = cards.filter(card => {
      if (isSpecial)
        if (tab)
          return card.match_card.edition === tab;
        else
          return card.matchCardModel === 'SpecialCard';
      else
        return card.matchCardModel === 'Card';
    });

    if (isSpecial)
      cards.sort((a, b) => (a.match_card.sorting - b.match_card.sorting));
    else
      cards.sort((a, b) => (a.match_card.level - b.match_card.level));
  }

  return (
    <TopBG
      background={`${conf.cdn_url}/${eventConf.bg}`}
      disableBreadcrumbs
      style={{
        padding: 0,
        minHeight: 958,
        backgroundSize: 'auto',
        backgroundColor: '#041022',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <div className={classes.headTrio}>
        <div>
          <div style={{ position: 'relative' }}>
            <Avatar
              className={classes.bigAvatar}
              alt={organisation.code}
              src={organisation.logo_url}
            />
          </div>
        </div>
        <div style={{ ...Helpers.fillColCross, marginTop: 250, position: 'relative' }}>
          <img
            src={`${conf.cdn_url}/${eventConf.logo}`} alt="Logo"
            style={{ width: '67%', position: 'relative', maxWidth: 512 }}
          />
        </div>
      </div>

      <div style={{ width: '100%', position: 'relative' }}>
        {(currentOffer && tab === currentOffer.edition_to_show) &&
        <div className={classes.campFeature}>
          <div style={{ padding: 40, ...Helpers.textCenter }}>
            <div style={{ fontSize: 30, fontFamily: 'Bebas Neue', lineHeight: 0.9 }}>
              Stream schauen,<br />Karten sammeln, <br />Heft bestellen.
            </div>
            <div style={{ fontSize: 20, fontFamily: 'Bebas Neue', lineHeight: 1 }}>
              Nur erhältlich vom 22. November bis zum 10. Jänner 2022
            </div>
            <div style={{ marginTop: 25 }}>
              <div className={classes.checkedRow}>
                <img
                  src={`${conf.cdn_url}/assets/event/checkmark_${ownedCards > 0 ? 'filled' : 'empty'}.png`}
                  alt="Checker circle"
                />
                Sammel deine erste Karte
              </div>
              <div className={classes.checkedRow}>
                <img
                  src={`${conf.cdn_url}/assets/event/checkmark_${ownedCards === currentOffer.required_cards.length ? 'filled' : 'empty'}.png`}
                  alt="Checker circle"
                />
                <div>
                  Sammel alle
                  Karten {ownedCards ? ownedCards : 0}/{currentOffer.required_cards.length}
                </div>
              </div>
            </div>
            <div
              className={classes.eventButton}
              style={isOrderPossible ? {} : { filter: 'grayscale(100)', cursor: 'not-allowed' }}
              onClick={() => isOrderPossible ? props.history.push(`/card-shop?offer=${currentOffer._id}`) : null}
            >
              {currentOffer.order_button_text}
            </div>
          </div>

        </div>
        }
        <div className={classes.bodyContainer}>
          {conf.event_raffle_on_page &&
          <div
            style={{ cursor: 'pointer', margin: '-20px -20px 20px' }}
            onClick={() => props.history.push('/raffle')}
          >
            <img
              src={`${conf.cdn_url}/assets/event/trilluxe-rapidlan/raffle-banner-TRILLUXE-mobile.jpg`}
              alt="Raffle"
              style={{ width: '100%', height: '100%' }}
            />
          </div>
          }
          <div style={{ ...Helpers.fillColCenter }}>
            <div
              style={{
                background: '#020812',
                padding: '3px 18px',
                // marginTop: 10,
                ...Helpers.fillColCenter,
              }}
            >
              {(currentOffer && tab === currentOffer.edition_to_show) &&
              <div className={classes.headText}>
                <img
                  src={`${process.env.PUBLIC_URL}/icons-png/cardredicon.png`}
                  alt="Watch"
                  style={{ marginRight: 7, height: 25 }}
                />
                {ownedCards ? ownedCards : 0} / {currentOffer.required_cards.length} Karten
                freigeschalten
              </div>
              }
              <div className={classes.headText} style={{ marginTop: 5 }}>
                <img
                  src={`${process.env.PUBLIC_URL}/icons-png/energy_solo.png`}
                  alt="Watch"
                  style={{ marginRight: 11, height: 25 }}
                />
                {energy} / 100
              </div>
            </div>
          </div>

          <p>
            Wir müssen euch sagen, es weihnachtet sehr!<br />
            Und das auch bei unseren Partnern, denn in Zusammenarbeit mit ihnen gibt es zwischen
            dem 22.11. und 10.01.2022 den exklusiven Streamheroes Adventskalender. Du willst ihn
            dir noch rechtzeitig sichern? Ganz einfach: Schaue einem beliebigen Streamer der
            Organisation "Christmas-Calendar" zu. Alle Teilnehmer findest du ganz unten auf der
            Seite. Schalte mit der Organisation Energie alle 24 Karten frei und bestelle dir
            eines von 1.000 limitierten Heften direkt nach Hause. Zwischen dem 01.12. und 24.12.
            wird täglich eine neue Karte digital revealed, welche du auch Zuhause dann auspacken
            kannst.
          </p>

          <div style={{ padding: '30px 0' }}>
            {(isSpecial/* && currentOffer && tab === currentOffer.edition_to_show*/) ?
              <EventStreamerCards
                cards={cards}
                coinsInformation={coinsInformation}
                isTabletOrMobile
              />
              :
              <>
                <CardsOpenGraphHeader cards={cards} organisation={organisation} />
                <Cards
                  cards={cards}
                  coinsInformation={coinsInformation}
                  maxLevelCard={maxLevelCard}
                />
              </>
            }
          </div>

          <div className={classes.bodyContainer}
               style={{ marginTop: 14, ...Helpers.fillRow, flexWrap: 'wrap' }}>
            {memberStreams.map(memberStream =>
              <OrganisationMember
                key={`OrganisationMember#${memberStream._id}`}
                memberStream={memberStream}
                hasOwnDesign={organisation.hasOwnDesign}
              />,
            )}
          </div>
          {memberStreams.length > 5 &&
          <div
            className={classes.expandButton}
            onClick={() => setExpandMembers(!expandMembers)}
          >
            {expandMembers ? t('shrink_area') : t('expand_area')}
          </div>
          }
        </div>
      </div>
    </TopBG>
  );
};

export default withRouter(MobileEventOrganisation);