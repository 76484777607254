import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

import {
  createIllustration as createIllustrationMutation,
  getPresignedURLsForIllustationJob as getPresignedURLsForIllustationJobMutation,
} from '../../apollo/mutations';

import Helpers from '../../theme/Helpers';
import CardToolServicesDesignerFormDetails from './CardToolServicesDesignerFormDetails';
import CardToolServicesDesignerFormPickStyle from './CardToolServicesDesignerFormPickStyle';
import CardToolServicesDesignerFormPictures from './CardToolServicesDesignerFormPictures';
import Loading from '../Loading/Loading';
import Colors from '../../theme/Colors';
import CustomButton from '../Button/CustomButton';

const conf = require('../../conf');

const useStyles = makeStyles(() => ({
  container: {
    ...Helpers.fillCol,
    width: '100%',
    color: Colors.white,
    backgroundColor: '#171717',
    marginTop: 20,
  },
  description: {
    margin: '20px 0',
  },
  tabsHead: {
    backgroundColor: '#020202',
    ...Helpers.fillRow,
    flex: 1,
  },
  tab: {
    padding: '20px 30px',
    textTransform: 'uppercase',
    cursor: 'pointer',
    color: '#555555',
    ...Helpers.fillRowCenter,
    borderBottom: '2px solid transparent',
    fontWeight: 'bold',
    '&:hover': {
      color: '#CBC16C',
      textShadow: '0px 0px 6px #CBC16C',
      '& $activeCount': {
        textShadow: 'none',
        color: Colors.white,
        backgroundColor: '#BB2E3D',
        borderColor: '#BB2E3D',
      },
    },
  },
  selectedTab: {
    color: '#CBC16C',
    textShadow: '0px 0px 6px #CBC16C',
    borderColor: '#A5262F',
    '& $activeCount': {
      textShadow: 'none',
      color: Colors.white,
      backgroundColor: '#BB2E3D',
      borderColor: '#BB2E3D',
    },
  },
  activeCount: {
    border: '1px solid #373737',
    color: '#373737',
    padding: '5px 10px',
    borderRadius: '50%',
    marginRight: 10,
  },
  instructions: {
    ...Helpers.textCenter,
  },
}));

const CardToolServicesDesignerForm = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [cdnImages, setCDNImages] = useState(null);
  const [activeStyle, setActiveStyle] = useState(null);
  const [values, setValues] = useState({});
  const [disableNext, setDisableNext] = useState(true);
  const [complete, setComplete] = useState(false);
  const steps = [
    t('check_designer_form_step_pick_style'),
    t('check_designer_form_step_cards_view'),
    t('check_designer_form_step_upload_pictures'),
  ];

  useEffect(() => {
    checkDisableStatus();

    if (activeStep === steps.length && !complete) {
      if (uploadedImages.length > 0) {
        const fileNames = uploadedImages.map(file => file.name);
        getPresignedURLsForIllustationJob({ variables: { files: fileNames } });
      } else
        setComplete(true);
    }
  }, [activeStep, values, activeStyle]);

  const [createIllustration, { loading }] = useMutation(createIllustrationMutation, {
    onCompleted: () => {
      enqueueSnackbar(t('success'), { variant: 'success' });
      props.history.push('/design-job');
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
  });

  const [getPresignedURLsForIllustationJob] = useMutation(getPresignedURLsForIllustationJobMutation, {
    onCompleted: async (data) => {
      const preSignedUrls = data && data.getPresignedURLsForIllustationJob;

      await preSignedUrls.map(async (signedUrl, index) => {
        await uploadImageToS3(signedUrl, uploadedImages[index], {
          'Content-Type': uploadedImages[index].type,
        });
      });

      const imageUrls = preSignedUrls.map(img => {
        const urlParts = img.split('?');
        return urlParts.shift()
        .replace(conf.no_cors_cdn_url, conf.cdn_url);
      });

      setCDNImages(imageUrls);
      setComplete(true);
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
  });

  /**
   * Upload image to s3 directly, because load balancer allow file max size of 1 MB only
   *
   * @param signedUrl
   * @param file
   * @param headers
   */
  const uploadImageToS3 = (signedUrl, file, headers) => {
    console.log('uploadImageToS3');
    axios.put(signedUrl, file, { headers })
    .then(() => {})
    .catch(error => {
      console.log('ERROR ' + error);
    });
  };

  const checkDisableStatus = () => {
    let disableStatus;
    if (activeStep === 1 && (JSON.stringify(values) === '{}' || ((!values.request_description || values.request_description === '') || (!values.request_primary_color_info || values.request_primary_color_info === '')))) {
      disableStatus = true;
    } else
      disableStatus = activeStep === 0 && activeStyle === null;

    if (disableStatus !== disableNext)
      setDisableNext(disableStatus);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const completeJob = () => {
    const illustration = {
      ...values,
      illustration_style: activeStyle,
      file_uploads_user: cdnImages,
    };

    if (!illustration.card_request_info)
      illustration.card_request_info = [];

    createIllustration({
      variables: {
        illustration,
      },
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.tabsHead}>
        {steps.map((step, index) => (
          <div
            className={[classes.tab, index === activeStep ? classes.selectedTab : ''].join(' ')}
            onClick={() => index <= activeStep ? setActiveStep(index) : null}
            key={`StepKey${index}`}
          >
            <div className={classes.activeCount}>{index + 1}</div>
            {step}
          </div>
        ))}
      </div>

      <div style={{ padding: 20 }}>
        {activeStep === steps.length ? (
          <div>
            <div style={Helpers.fillColCenter}>
              <img src={`${process.env.PUBLIC_URL}/cardswap.png`} alt="Cardswap" />
              <Typography className={classes.instructions}>
                {t('check_designer_complete_hint')}
              </Typography>
            </div>
            <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween, marginTop: 20 }}>
              <CustomButton onClick={handleReset}>
                {t('reset')}
              </CustomButton>
              {(loading || !complete) ?
                <Loading />
                :
                <CustomButton onClick={completeJob} disabled={!complete}>
                  {t('complete')}
                </CustomButton>
              }
            </div>
          </div>
        ) : (
          <div>
            <div className={classes.description}>
              {activeStep === 0 && t('create_design_job_description_style')}
              {activeStep === 1 && t('create_design_job_description_desc')}
              {activeStep === 2 && t('create_design_job_description_upload')}
            </div>
            {activeStep === 0 &&
            <CardToolServicesDesignerFormPickStyle
              setActiveStyle={setActiveStyle}
              activeStyle={activeStyle}
            />
            }
            {activeStep === 1 &&
            <CardToolServicesDesignerFormDetails
              setValues={setValues}
              values={values}
            />
            }
            {activeStep === 2 &&
            <CardToolServicesDesignerFormPictures
              setUploadedImages={setUploadedImages}
              uploadedImages={uploadedImages}
            />
            }
            <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween, marginTop: 20 }}>
              <CustomButton disabled={activeStep === 0} onClick={handleBack}>
                {t('back')}
              </CustomButton>
              <CustomButton disabled={disableNext} onClick={handleNext}>
                {activeStep === steps.length - 1 ? t('finish') : t('next')}
              </CustomButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(CardToolServicesDesignerForm);