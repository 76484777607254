import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import HoverIcon from '../Icon/HoverIcon';

const useStyles = makeStyles(() => ({
  countArea: {
    width: 88,
    height: 31,
    marginLeft: 14,
    background: 'rgba(255, 255, 255, 0.06)',
    fontSize: 12,
    fontWeight: 500,
    ...Helpers.fillColCenter,
    borderRadius: 15,
  },
  hoverText: {
    color: Colors.white,
    transition: 'all 0.3s ease-out',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const TopBarBackInfo = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { pathname, alertTool, extra } = props;
  const { inventoryGroup } = extra;

  const urlParts = pathname.split('/');

  let returnUrl;
  if (pathname.includes('streamer-settings/alerts/'))
    returnUrl = '/streamer-settings/alerts';
  else if (pathname.includes('inventory/cards') || pathname.includes('inventory/streamer'))
    returnUrl = '/inventory/groups';
  else if (pathname.includes('card-shop/streamer/') && (pathname.includes('/card/') || pathname.includes('/album/')))
    returnUrl = '/card-shop/streamer/' + urlParts[3];
  else if (pathname.includes('card-shop/streamer/'))
    returnUrl = '/card-shop';

  if (!returnUrl)
    return null;

  const typeTranslations = {
    BoosterOpen: 'booster_redeem',
    CardPurchase: 'card_purchase',
    CommunityRankUp: 'community_rank_up',
  };

  return (
    <div style={{ ...Helpers.fillRowCross }}>
      <HoverIcon
        icon="fa-regular fa-chevron-left"
        color={Colors.white}
        hoverColor={Colors.halfWhite}
        style={{ fontSize: 17, marginRight: 16 }}
        onClick={() => props.history.push(returnUrl)}
      />
      {pathname.includes('card-shop/streamer/') &&
      <Link to={returnUrl} className={classes.hoverText}>
        {t(returnUrl.includes('/streamer/') ? 'back_to_card_overview' : 'back_to_streamer_overview')}
      </Link>
      }
      {pathname.includes('streamer-settings/alerts/') &&
      <div style={{ color: alertTool.type ? Colors.white : Colors.halfWhite }}>
        {t(alertTool.type ? typeTranslations[alertTool.type] : 'no_type_selected')}
      </div>
      }
      {(pathname.includes('inventory/cards/') || pathname.includes('inventory/streamer')) && inventoryGroup &&
      <div style={{ ...Helpers.fillRowCross }}>
        <div style={{ color: Colors.white }}>{inventoryGroup.name}</div>
        <div className={classes.countArea}>
          {inventoryGroup.count}
        </div>
      </div>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  const { alertTool, extra } = state;
  return { alertTool, extra };
};

export default connect(mapStateToProps)(withRouter(TopBarBackInfo));