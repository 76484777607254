import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

import Helpers from '../../theme/Helpers';
import { rangeArray } from '../../utils/helper';
import ProfileOverviewBadgeVideo from './ProfileOverviewBadgeVideo';
import BadgePopup from '../Dialog/BadgePopup';
import CustomTooltip from '../Tooltip/CustomTooltip';

const useStyles = makeStyles(() => ({
  badgeBox: {
    background: 'rgba(255, 255, 255, 0.02)',
    borderRadius: 6,
    padding: 8,
    alignItems: 'center',
    display: 'inline-flex',
    gap: 12,
    marginTop: 6,
    fontSize: 14,
  },
  addButton: {
    ...Helpers.fillRowCenter,
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    background: 'rgba(255, 255, 255, 0.02)',
    color: 'rgba(255, 255, 255, 0.5)',
    borderRadius: 6,
    width: 38,
    height: 38,

    '&:hover': {
      background: 'rgba(255, 255, 255, 0.08)',
    },
  },
}));

const ProfileHeaderBadges = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    isMe,
    viewer,
    viewerShowcaseBadges,
    viewerBadges,
    viewerSeasonBadges,
    badgeTemplates,
  } = props;
  const [showBadges, setShowBadges] = useState({
    idx: 0,
    badge: null,
  });

  if (!viewerShowcaseBadges)
    return null;

  const getNextFreeIndex = viewerShowcaseBadges && viewerShowcaseBadges.length < 6 ? viewerShowcaseBadges.length + 1 : -1;

  const checkAndRenderBadge = (index) => {
    const foundBadge = viewerShowcaseBadges[index];

    if (!foundBadge)
      return null;

    const badge = foundBadge.badge_template_id;

    return (
      <CustomTooltip
        key={`BadgeKey${index}`}
        title={foundBadge.metadata ?
          t('season_badge_desc', foundBadge.metadata && {
            name: foundBadge.metadata.streamer.display_name,
            league: t(`rank_${foundBadge.metadata.season.league}`),
          }) :
          t(badge.description)
        }
      >
        <div
          onClick={isMe ? () => setShowBadges({
            idx: index + 1,
            badge: { ...badge, vId: foundBadge._id, isSeason: !!foundBadge.metadata },
          }) : null}
          style={{ cursor: isMe ? 'pointer' : 'default' }}
        >
          <ProfileOverviewBadgeVideo badge={badge} />
        </div>
      </CustomTooltip>
    );
  };

  const categoryBadges = [];
  if (viewerBadges) {
    badgeTemplates.sort((a, b) => a.name.replace(/\D/g, '') - b.name.replace(/\D/g, ''))
    .map(badge => {
      const findIdx = categoryBadges.findIndex(catBadge => catBadge.group === badge.group);
      const reachedBadge = viewerBadges.find(viewerBadge => viewerBadge.badge_template_id._id === badge._id);
      const isActive = reachedBadge !== undefined;
      const isSelected = isActive && reachedBadge.showcase_position !== null;
      if (findIdx === -1) {
        if (!badge.group.includes('season_'))
          categoryBadges.push({
            group: badge.group,
            badges: [
              {
                ...badge,
                active: isActive,
                isSet: isSelected,
                vId: reachedBadge && reachedBadge._id,
              },
            ],
          });
      } else
        categoryBadges[findIdx].badges.push({
          ...badge,
          active: isActive,
          isSet: isSelected,
          vId: reachedBadge && reachedBadge._id,
        });

      return null;
    });
  }

  return (
    <>
      <div className={classes.badgeBox}>
        {rangeArray(1, 6)
        .map(index => checkAndRenderBadge(index - 1))}

        {(isMe && getNextFreeIndex !== -1) &&
        <div
          onClick={() => setShowBadges({ idx: getNextFreeIndex + 1, badge: null })}
        >
          <div className={classes.addButton}>
            <i className="fa-solid fa-plus" style={{ fontSize: 16 }} />
          </div>
        </div>
        }
      </div>

      {showBadges.idx > 0 &&
      <BadgePopup
        handleClose={() => setShowBadges({
          idx: 0,
          badge: null,
        })}
        viewer={viewer}
        showBadges={showBadges}
        seasonBadges={viewerSeasonBadges}
        categoryBadges={categoryBadges.sort((a, b) => b.badges.length - a.badges.length)}
      />
      }
    </>
  );
};

export default ProfileHeaderBadges;