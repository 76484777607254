import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { getRedeemedCardObjectInformation } from '../../apollo/queries';

import Loading from '../../components/Loading/Loading';
import TopViewHeader from '../../components/TopViewHeader/TopViewHeader';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import MyOldCardContainer from './MyOldCard';
import CardViewDialog from '../../components/Cards/CardViewDialog';
import PagePopup from '../../components/Dialog/PagePopup';

import conf from '../../conf.json';

const MyCardContainer = (props) => {
  const cardId = props.match.params.id;

  const { loading, data } = useQuery(getRedeemedCardObjectInformation, {
    variables: { id: cardId },
    fetchPolicy: useCacheWithExpiration('getRedeemedCardObjectInformation', { id: cardId }),
  });

  const card = (data) && data.getRedeemedCardObjectInformation;

  return (
    <TopViewHeader
      backgroundImage={`${conf.cdn_url}/assets/template/dark_blue_bg.png`}
      style={{ backgroundPosition: 'center', minHeight: 'auto' }}
    >
      <PageTitle title="My card" />
      <PagePopup>

        {loading ?
          <Loading />
          :
          card ?
            <CardViewDialog information={card} />
            :
            <MyOldCardContainer cardId={cardId} />
        }
      </PagePopup>
    </TopViewHeader>
  );
};

export default MyCardContainer;