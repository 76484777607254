import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import HubFavouriteStreamerItem from './HubFavouriteStreamerItem';

const useStyles = makeStyles(() => ({
  container: {
    width: 'calc(100% - 32px)',
    background: 'rgba(255, 255, 255, 0.04)',
    padding: 16,
    borderRadius: 6,
  },
  arrowArea: {
    width: 34,
    height: 233,
    ...Helpers.fillRowCenter,
    borderRadius: 4,
    fontSize: 16,
    color: Colors.white,
    background: 'rgba(255, 255, 255, 0.04)',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.12)',
      color: Colors.white,
    },
    '&:before': {
      content: 'none',
    },
  },
}));

const HubFavouriteStreamer = (props) => {
  const classes = useStyles();
  const { t, favoriteStreamer } = props;

  const ArrowArea = (props) => {
    const { className, styling, onClick, icon } = props;
    return (
      <div
        className={[className, classes.arrowArea].join(' ')}
        style={styling}
        onClick={onClick}
      >
        {icon}
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    arrows: true,
    prevArrow: <ArrowArea styling={{ left: 0 }}
                          icon={<i className="fa-light fa-chevron-left" />} />,
    nextArrow: <ArrowArea styling={{ right: 0 }}
                          icon={<i className="fa-light fa-chevron-right" />} />,
  };

  return (
    <div className={classes.container}>
      <Slider {...settings} style={{ width: '100%', padding: '0 38px' }}>
        {favoriteStreamer && favoriteStreamer
        .map(favItem => (
          <HubFavouriteStreamerItem
            t={t}
            favStreamer={favItem}
            key={`FavouriteStreamerKey${favItem.streamer._id}`}
          />
        ))}
      </Slider>
    </div>
  );
};

export default HubFavouriteStreamer;