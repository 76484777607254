import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import PagePopup from './PagePopup';
import Helpers from '../../theme/Helpers';
import AlertCapsuleImage from '../AlertCapsules/AlertCapsuleImage';
import AlertCapsuleDetails from '../AlertCapsules/AlertCapsuleDetails';
import CustomTooltip from '../Tooltip/CustomTooltip';
import Colors from '../../theme/Colors';
import HoverIcon from '../Icon/HoverIcon';
import SetHeadRow from '../Booster/SetHeadRow';
import { getMatchData } from '../../utils/helper';
import AlertCapsuleRedeemButton from '../AlertCapsules/AlertCapsuleRedeemButton';

const useStyles = makeStyles({
  container: {
    height: '100%',
    maxWidth: 1440,
    margin: '0 auto',
  },
  content: {
    ...Helpers.fillRowCenter,
    marginTop: 193,
    position: 'relative',
  },
  contentColumn: {
    ...Helpers.fillColCenter,
    flex: 1,
  },
  detailsHeader: {
    fontSize: 36,
    textAlign: 'left',
    fontFamily: 'Bebas Neue',
    textTansform: 'uppercase',
  },
  details: {
    width: 371,
    alignSelf: 'flex-end',
  },
  id: {
    margin: '6px 0',
    opacity: 0.5,
    fontSize: 12,
  },
  sendAlert: {
    background: 'rgba(225, 33, 56, 0.17)',
    color: '#E12138',
    padding: '5px 8px',
    borderRadius: 4,
    fontFamily: 'Montserrat',
    fontSize: 12,
    cursor: 'pointer',
    '&:hover': {
      background: '#E12138',
      color: Colors.white,
    },
  },
  info: {
    ...Helpers.fillRowCross,
    ...Helpers.mainSpaceBetween,
    margin: '20px 0 10px',
  },
  alertCapsuleImage: {
    position: 'absolute',
    top: -130,
  },
  blurredItem: {
    background: 'rgba(26, 45, 88, 0.24)',
    filter: 'blur(50px)',
    width: 646,
    height: 646,
    position: 'absolute',
    borderRadius: '50%',
    top: -150,
    zIndex: -1,
  },
});

const AlertCapsuleDialog = (
  {
    capsuleId,
    created_at,
    streamer,
    t,
    onClose,
    capsuleType,
    level,
    card,
    order,
    community,
    allowTwitch,
    allowDiscord,
    allowSpeech,
    onRedeem,
    redeemLockedUntil,
    now,
  },
) => {
  const classes = useStyles();
  const isAllowed = allowTwitch || allowDiscord;

  const collected = `Collected ${moment
  .unix(created_at / 1000)
  .format('DD.MM.YYYY')}`;
  const matchData = getMatchData({ match: streamer }, 300, 300);

  return (
    <PagePopup>
      <div className={classes.container}>
        <SetHeadRow name={matchData.name} logoUrl={matchData.avatar} disableLink
                    handleClose={onClose} url={matchData.url} subTitle={collected} />

        <div className={classes.content}>
          <div className={classes.contentColumn} />
          <div className={classes.contentColumn} style={{ top: 100 }}>
            <div className={classes.blurredItem} />
            <AlertCapsuleImage className={classes.alertCapsuleImage} type={capsuleType}
                               level={level} t={t} height={600} isAllowed />
          </div>
          <div
            className={classes.contentColumn}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'baseline',
            }}
          >
            <div className={classes.details}>
              <div className={classes.detailsHeader}>{t('alert_capsule')}</div>
              <AlertCapsuleDetails
                t={t}
                streamer={streamer}
                card={card}
                order={order}
                community={community}
                allowTwitch={allowTwitch}
                allowDiscord={allowDiscord}
                allowSpeech={allowSpeech}
                style={{ width: '100%' }}
                levelWord={level}
              />

              <div className={classes.info}>
                <CustomTooltip title={t('send_alert_description')}>
                  <div className={classes.sendAlert}>{t('send_alert')}</div>
                </CustomTooltip>

                <CustomTooltip title={t('capsule_order_explanation')}>
                  <div style={{ borderRadius: 50 }}>
                    <HoverIcon
                      icon="fa-light fa-circle-info"
                      hoverIcon="fa-solid fa-circle-info"
                      color={Colors.halfWhite}
                      hoverColor={Colors.white}
                      style={{ fontSize: 18 }}
                    />
                  </div>
                </CustomTooltip>
              </div>

              <AlertCapsuleRedeemButton
                t={t}
                handleClick={onRedeem}
                isAllowed={isAllowed}
                redeemLockedUntil={redeemLockedUntil}
                now={now}
                fontSize={18}
              />
              <div className={classes.id}>{`ID: ${capsuleId}`}</div>
            </div>
          </div>
        </div>
      </div>
    </PagePopup>
  );
};

export default AlertCapsuleDialog;
