import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { fabric } from 'fabric';
import { useMutation } from '@apollo/react-hooks';
import FontFaceObserver from 'fontfaceobserver';
import { useSnackbar } from 'notistack';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { zeroPad } from 'react-countdown';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faPowerOff, faReply } from '@fortawesome/free-solid-svg-icons';

import {
  uploadBigLevelCard as uploadBigLevelCardMutation,
  uploadCardTemplate as uploadCardTemplateMutation,
  userImagePlaceholderUpload as userImagePlaceholderUploadMutation,
} from '../../apollo/mutations';
import { getMyStreamerCards, getUnapprovedBoosterTemplate } from '../../apollo/queries';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import Loading from '../Loading/Loading';
import CardToolFabricFilters from './CardToolFabricFilters';
import { dataURItoFile, getRandomNumber, getStringBetween } from '../../utils/helper';
import { addLastStep, removeLastStep, resetLastSteps } from '../../redux/actions';
import CardToolFabricObjectFilters from './CardToolFabricObjectFilters';
import CustomButton from '../Button/CustomButton';
import CustomTooltip from '../Tooltip/CustomTooltip';
import CardToolSelectFrame from './CardToolSelectFrame';
import CardToolSelectNameBadge from './CardToolSelectNameBadge';
import CardToolUserImages from './CardToolUserImages';
import CardToolSelectEffect from './CardToolSelectEffect';
import CardToolImageUpload from './CardToolImageUpload';
import CardToolHeroMaker from './CardToolHeroMaker';
import CardToolSelectSpecialCustomCard from './CardToolSelectSpecialCustomCard';

const conf = require('../../conf');

const CardToolFabric = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {
    card,
    myViewer,
    level,
    handleNext,
    previousCard,
    cardTool,
    resetLastSteps,
    activeStep,
    customCardFrame,
    setCustomCardFrame,
    set,
    setLevel,
    webFonts,
    isSpecialCard,
  } = props;
  const [canvas, setCanvas] = useState(null);
  const [bigCardFile, setBigCardFile] = useState(null);
  const [canvasLoading, setCanvasLoading] = useState(true);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [togglePicker, setTogglePicker] = useState(false);
  const [started, setStarted] = useState(false);
  const [hasEvents, setHasEvents] = useState(false);
  const [hasBackgroundAndText, setHasBackgroundAndText] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadedLogo, setUploadedLogo] = useState(null);
  const [activeHero, setActiveHero] = useState(null);
  const [lastImage, setLastImage] = useState(null);
  const [imageDPI, setImageDPI] = useState(null);
  const [cardFrame, setCardFrame] = useState(0);
  const [nameBadge, setNameBadge] = useState(0);
  const [selectedStyle, setSelectedStyle] = useState(1);
  const [activeEffect, setActiveEffect] = useState(0);
  const [activeUserKey, setActiveUserKey] = useState(null);
  const [directory, setDirectory] = useState(`${conf.no_cors_cdn_url}/cards/maker/sets/set${zeroPad(set)}/Level-${setLevel}`);
  const { boosterId } = cardTool;
  const [cardRdyForFileLoad, setCardRdyForFileLoad] = useState(!!card);
  let filtersRef = useRef(null);
  const { lastSteps } = cardTool;

  const fonts = [
    'Arial',
    'Avenir Next Cyr',
    'Gotham Pro',
    'Oswald',
    ...webFonts,
  ];

  const printSize = { width: 768, height: 1146 };
  const cuttedSize = { width: 697, height: 1075 };
  const digitalSize = { width: 324, height: 500 };

  const cardBackground = `${directory}/00-background/00.png`;
  const cardLevelDesign = `${directory}/01-frames/${zeroPad(cardFrame)}.png`;
  const nameBadgeDesign = `${directory}/02-namebadge/${zeroPad(nameBadge)}.png`;
  const levelBadgeDesign = level ? `${conf.no_cors_cdn_url}/cards/maker/rarities/carddesign-rarity-${zeroPad(level)}.png` : '';

  const nameBadgePositions = [
    { top: 428, left: 90, textAlign: 'left' },
    { top: 436, left: 125, textAlign: 'center' },
    { top: 436, left: 125, textAlign: 'center' },
    { noText: true },
    { noText: true },
    { top: 436, left: 125, textAlign: 'center' },
    { noText: true },
  ];

  useEffect(() => {
    let currentSet = set;
    let currentLevel = level;

    if (customCardFrame) {
      currentSet = customCardFrame.set;
      currentLevel = customCardFrame.setLevel;
    }

    const newDir = `${conf.no_cors_cdn_url}/cards/maker/sets/set${zeroPad(currentSet)}/Level-${currentLevel}`;

    if (newDir !== directory) {
      setDirectory(newDir);

      if (isSpecialCard) {
        setHasBackgroundAndText(false);
        setCanvasLoading(true);

        const designTheme = checkIfObjectExists('card-design-theme');
        if (designTheme)
          canvas.remove(designTheme);
        const background = checkIfObjectExists('card-background');
        if (background)
          canvas.remove(background);
        const badge = checkIfObjectExists('name-badge');
        if (badge)
          canvas.remove(badge);
        const userText = checkIfObjectExists('user-text');
        if (userText)
          canvas.remove(userText);

        drawCardBackgroundAndText();
      } else if (canvas)
        redraw();
    }
  }, [myViewer, set, level, customCardFrame]);

  useEffect(() => {
    // Update the document title using the browser API
    if (!canvas) {
      setCanvas(new fabric.Canvas('canvas'));
      resetLastSteps();
    }

    if (canvas) {
      const allowedKeys = ['ArrowDown', 'ArrowUp', 'ArrowRight', 'ArrowLeft'];

      const onKeyDown = ({ key }) => {
        if (allowedKeys.includes(key)) {
          const x = window.scrollX;
          const y = window.scrollY;
          // disable scrolling with arrow keys
          window.onscroll = function () {
            window.scrollTo(x, y);
          };
          keyHandler(key);
        }
      };

      const onKeyUp = () => {
        setTimeout(() => {
          window.onscroll = function () {
          };
        }, 500);
      };

      document.addEventListener('keydown', onKeyDown);
      document.addEventListener('keyup', onKeyUp);

      return () => {
        document.removeEventListener('keydown', onKeyDown);
        document.removeEventListener('keyup', onKeyUp);
      };
    }
  }, [canvas, resetLastSteps]);

  const [userImagePlaceholderUpload] = useMutation(userImagePlaceholderUploadMutation, {
    onCompleted: async (data) => {
      const { value, key } = lastImage;

      const signedURL = data.userImagePlaceholderUpload;
      await uploadImageToS3(signedURL, value, {
        'Content-Type': value.type,
      }, true, key);
    },
    onError: (data) => {
      setCanvasLoading(false);
      enqueueSnackbar(data.message, { variant: 'error' });
    },
  });
  const [uploadCardTemplate] = useMutation(uploadCardTemplateMutation, {
    onCompleted: async (data) => {
      if (data && data.uploadCardTemplate) {
        const { uploadCardTemplate } = data;
        const fileParts = uploadCardTemplate.filename.split('/');
        const fileName = fileParts.pop();

        const uploadBigLevelCardData = await uploadBigLevelCard({
          variables: {
            directory: 'cards/self-init/physical',
            fileName: fileName,
          },
        });
        if (uploadBigLevelCardData) {
          const signedURL = uploadBigLevelCardData.data.uploadBigLevelCard;

          uploadImageToS3(signedURL, bigCardFile, {
            'Content-Type': 'image/png',
          }, false);
        }
      }
      setUploadLoading(false);
      redraw();
      handleNext();
      setUploadedImages([]);
      enqueueSnackbar(t('saved_card'), { variant: 'success' });
    },
    onError: (data) => {
      setUploadLoading(false);
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [
      { query: getMyStreamerCards },
      { query: getUnapprovedBoosterTemplate, variables: { id: boosterId } },
    ],
  });
  const [uploadBigLevelCard] = useMutation(uploadBigLevelCardMutation);

  /**
   * Upload image to s3 directly, because load balancer allow file max size of 1 MB only
   *
   * @param signedUrl
   * @param file
   * @param headers
   * @param updateUserData
   * @param uploadedIndexKey
   */
  const uploadImageToS3 = (signedUrl, file, headers, updateUserData, uploadedIndexKey = null) => {
    axios.put(signedUrl, file, { headers })
    .then(() => {
      if (updateUserData) {
        const urlParts = signedUrl.split('?');
        const uploadedImageUrl = urlParts.shift();
        let isLogo = false;

        // get query with v for caching problem
        const random = getRandomNumber();
        const fileName = uploadedImageUrl + '?v=' + random;

        if (uploadedImageUrl.includes('-logo-uploads')) {
          isLogo = true;
          setUploadedLogo(fileName);
        } else {
          const tmpUploadedImages = [...uploadedImages];
          tmpUploadedImages.push({
            value: fileName,
            key: uploadedIndexKey,
          });
          setUploadedImages(tmpUploadedImages);
        }
        addActionStep({
          type: isLogo ? 'addUserLogo' : 'addUserImage',
          data: isLogo ? null : { key: uploadedIndexKey },
        });

        setCanvasLoading(false);
      }
    })
    .catch(error => {
      console.log('ERROR ' + error);
    });
  };

  /**
   * Checks if a object with a specific id is in canvas objects
   *
   * @param objectID
   * @returns {boolean|object}
   */
  const checkIfObjectExists = (objectID) => {
    let answer = false;

    if (canvas) {
      canvas.getObjects()
      .forEach((obj) => {
        if (obj.id && obj.id.toString() === objectID.toString())
          answer = obj;
      });
    }

    return answer;
  };

  const addActionStep = (object) => {
    props.addLastStep(object);
  };

  const getRightObjectWithPosition = (compareData) => {
    let answer = null;

    if (canvas) {
      canvas.getObjects()
      .forEach((obj) => {
        if (obj.id && obj.id.toString() === compareData.id.toString())
          answer = obj;
      });
    }

    return answer;
  };

  const removeSpecificIndexOfImages = (index) => {
    const tmpUploadedImages = [...uploadedImages];
    tmpUploadedImages.splice(index, 1);
    setUploadedImages(tmpUploadedImages);
  };

  const restartWithNothing = () => {
    redraw();
    setCardRdyForFileLoad(false);
  };

  const removeLastActionStep = async () => {
    const lastStep = lastSteps[lastSteps.length - 1];

    let object;
    if (lastStep.type === 'addUserImage') {
      const key = lastStep.data.key;
      object = checkIfObjectExists(`user-image-${key}`);

      removeSpecificIndexOfImages(key);
      canvas.remove(object);
      calculateAllImagesDPI();
    } else if (lastStep.type === 'addUserLogo') {
      object = checkIfObjectExists('user-logo');
      setUploadedLogo(null);
      canvas.remove(object);
      calculateAllImagesDPI();
    } else if (lastStep.type === 'addActiveHero') {
      object = checkIfObjectExists('active-hero');
      setActiveHero(null);
      canvas.remove(object);
    } else if (lastStep.type === 'moveObject') {
      object = getRightObjectWithPosition(lastStep.data);

      object.left = lastStep.data.old.left;
      object.top = lastStep.data.old.top;
      object.setCoords();
    } else if (lastStep.type === 'font-family' || lastStep.type === 'font-weight' || lastStep.type === 'font-style' || lastStep.type === 'text-color') {
      let old = lastStep.data.old;
      if (lastStep.type === 'font-weight') {
        old = lastStep.data.old === 'bold';
      } else if (lastStep.type === 'font-style') {
        old = lastStep.data.old === 'italic';
      }

      filtersRef.current.updateText({
        value: old,
        type: lastStep.type,
      });
    } else if (lastStep.type === 'setIsSaturation' || lastStep.type === 'setIsBlendColorChecked' || lastStep.type === 'updateSaturation' || lastStep.type === 'updateBlendColor') {
      // imageRef.current.resetLastStep(lastStep);

      // TODO: change current filter of object
    } else if (lastStep.type === 'deleteObject') {
      const { data } = lastStep;

      await fabric.Image.fromURL(data.old._originalElement.src, (image) => {
        image.id = data.id;
        canvas.add(image);
        image.left = data.old.left;
        image.top = data.old.top;
        image.angle = data.old.angle;
        image.width = data.old.width;
        image.height = data.old.height;
        image.setCoords();
        canvas.setActiveObject(image);

        if (data.id.includes('user-image')) {
          checkSortingOfObjects(image);
          const tmpUploadedImages = [...uploadedImages];
          tmpUploadedImages.push({
            value: data.old.src,
            key: data.key,
          });
          setUploadedImages(tmpUploadedImages);

          calculateAllImagesDPI({
            height: image.height,
            width: image.width,
          });
        } else if (data.id.includes('user-logo')) {
          checkSortingOfObjects(image);
          setUploadedImages(image);

          calculateAllImagesDPI({
            height: image.height,
            width: image.width,
          });
        } else if (data.id.includes('active-hero')) {
          checkSortingOfObjects(image);
          setActiveHero(image);
        }
      }, {
        crossOrigin: 'Anonymous',
      });
    }

    canvas.requestRenderAll();
    props.removeLastStep();
  };

  /**
   * Reset the whole canvas object for new card
   */
  const redraw = () => {
    setUploadedImages([]);
    setBigCardFile(null);
    setUploadedLogo(null);
    setStarted(false);
    setHasBackgroundAndText(false);
    setCanvasLoading(true);
    setActiveUserKey(null);
    setActiveEffect(0);
    setActiveHero(null);

    if (filtersRef.current !== null)
      filtersRef.current.updateFilters({
        text: {
          isBold: false,
          isItalic: false,
          fontFamily: 'Oswald',
          color: '#ffffff',
        },
      });
    canvas.discardActiveObject();
    canvas.getObjects()
    .map(object => {
      canvas.remove(object);
      return null;
    });
    canvas.requestRenderAll();
  };

  /**
   * Gets factor of size in max size
   *
   * @param size
   * @param maxSize
   * @returns {number}
   */
  const getFactorToFitSize = (size, maxSize) => {
    const onePercent = size / 100;
    return Math.floor(maxSize / onePercent);
  };

  /**
   * Fit the size of the uploaded user image to the card size
   *
   * @param object
   */
  const fitSizeOfUploadedImage = (object) => {
    const maxWidth = digitalSize.width, maxHeight = digitalSize.height;
    let realWidth = 0, realHeight = 0;
    if (object.width > maxWidth || object.height > maxHeight) {
      const factor = getFactorToFitSize(object.width, maxWidth);
      realWidth = Math.round((object.width / 100) * factor);
      realHeight = Math.round((object.height / 100) * factor);

      if (realHeight > maxHeight) {
        const factor = getFactorToFitSize(realHeight, maxHeight);
        realWidth = Math.round((realWidth / 100) * factor);
        realHeight = Math.round((realHeight / 100) * factor);
      }

      object.scaleToWidth(realWidth);
      object.scaleToHeight(realHeight);
    }
  };

  /**
   * Fit the size of the card design image to the card size
   *
   * @param object
   */
  const fitSizeOfCardDesign = (object) => {
    object.scaleToWidth(digitalSize.width);
    object.scaleToHeight(digitalSize.height);
  };

  /**
   * Draws uploaded user image if it doesn't exist
   * Anonymous cross Origin is because of CORS problems
   *
   * @returns {Promise<void>}
   */
  const drawUserImages = async () => {
    await uploadedImages.map(async (obj) => {
      const { key, value } = obj;

      if (!checkIfObjectExists(`user-image-${key}`)) {
        await fabric.Image.fromURL(value, (userImage) => {
          if (!checkIfObjectExists(`user-image-${key}`)) {
            userImage.id = `user-image-${key}`;
            userImage.onSelect = () => setActiveUserKey(key);
            fitSizeOfUploadedImage(userImage);
            canvas.add(userImage);
            canvas.sendToBack(userImage);
            canvas.setActiveObject(userImage);
            checkSortingOfObjects();
            calculateAllImagesDPI();
          }
        }, {
          crossOrigin: 'Anonymous',
        });
      }
    });
  };

  /**
   * Draws uploaded user logo if it doesn't exist
   * Anonymous cross Origin is because of CORS problems
   *
   * @returns {Promise<void>}
   */
  const drawUserLogo = async () => {
    if (!checkIfObjectExists('user-logo')) {
      await fabric.Image.fromURL(uploadedLogo, (userLogo) => {
        if (!checkIfObjectExists('user-logo')) {
          userLogo.id = 'user-logo';
          userLogo.onSelect = () => setActiveUserKey(2);
          fitSizeOfUploadedImage(userLogo);
          canvas.add(userLogo);
          canvas.sendToBack(userLogo);
          canvas.setActiveObject(userLogo);
          checkSortingOfObjects();
          calculateAllImagesDPI();
        }
      }, {
        crossOrigin: 'Anonymous',
      });
    }
  };

  /**
   *
   * @param frame
   */
  const checkSortingOfObjects = (frame = null) => {
    const allObjects = canvas.getObjects();
    const currentFrame = frame !== null ? frame : cardFrame;

    let sorting = {
      'card-background': 0,
      'user-image-0': 1,
      'user-image-1': 2,
      'card-design-theme': 3,
      'level-badge': 4,
      'effect': 5,
      'active-hero': 6,
      'user-logo': 7,
      'name-badge': 8,
      'user-text': 9,
    };

    if (currentFrame === 0)
      sorting = {
        'card-background': 0,
        'card-design-theme': 1,
        'level-badge': 2,
        'effect': 3,
        'user-image-0': 4,
        'user-image-1': 5,
        'active-hero': 6,
        'user-logo': 7,
        'name-badge': 8,
        'user-text': 9,
      };

    const hasEffect = checkIfObjectExists('effect');
    const hasLogo = checkIfObjectExists('user-logo');
    const hasUserImage0 = checkIfObjectExists('user-image-0');
    const hasUserImage1 = checkIfObjectExists('user-image-1');
    const hasHero = checkIfObjectExists('active-hero');

    allObjects.map(object => {
      let newIndex = sorting[object.id];
      let additional = 0;

      if (currentFrame === 0 && newIndex > 2) {
        if (newIndex > 2 && isSpecialCard)
          additional += 1;
        if (newIndex > 3 && !hasEffect)
          additional += 1;
        if (newIndex > 4 && !hasUserImage0)
          additional += 1;
        if (newIndex > 5 && !hasUserImage1)
          additional += 1;
        if (newIndex > 6 && !hasHero)
          additional += 1;
        if (newIndex > 7 && !hasLogo)
          additional += 1;
      } else if (currentFrame > 0 && newIndex > 0) {
        if (newIndex > 1 && !hasUserImage0)
          additional += 1;
        if (newIndex > 2 && !hasUserImage1)
          additional += 1;
        if (newIndex > 4 && isSpecialCard)
          additional += 1;
        if (newIndex > 5 && !hasEffect)
          additional += 1;
        if (newIndex > 6 && !hasHero)
          additional += 1;
        if (newIndex > 7 && !hasLogo)
          additional += 1;
      }

      if (additional)
        newIndex -= additional;

      object.moveTo(newIndex);
    });

    canvas.requestRenderAll();
    setCanvasLoading(false);
  };

  /**
   * Calculates the diagonal of the rectangle with height and width
   *
   * @param height
   * @param width
   * @returns {number}
   */
  const calculateDiagonal = (height, width) => {
    return Math.hypot(height, width);
  };

  /**
   * Calculate the dpi of all images and show the lowest DPI
   *
   * @param newObj
   * @returns {Promise<void>}
   */
  const calculateAllImagesDPI = async (newObj) => {
    let dpi = null;

    const activeHero = checkIfObjectExists('active-hero');
    if (activeHero) {
      const objDpi = calculateDPI(activeHero.height, activeHero.width);
      if (!dpi || (dpi && dpi > objDpi))
        dpi = objDpi;
    }

    if (uploadedImages.length > 0) {
      await uploadedImages.map(obj => {
        const object = checkIfObjectExists(`user-image-${obj.key}`);

        if (object) {
          const objDpi = calculateDPI(object.height, object.width);
          if (!dpi || (dpi && dpi > objDpi))
            dpi = objDpi;
        }
      });
    }
    const userLogo = checkIfObjectExists('user-logo');
    if (userLogo) {
      const objDpi = calculateDPI(userLogo.height, userLogo.width);
      if (!dpi || (dpi && dpi > objDpi))
        dpi = objDpi;
    }

    if (newObj) {
      const objDpi = calculateDPI(newObj.height, newObj.width);
      if (!dpi || (dpi && dpi > objDpi))
        dpi = objDpi;
    }

    setImageDPI(dpi);
  };

  /**
   * Calculate the dpi for printer
   *
   * @param height
   * @param width
   * @returns {number}
   */
  const calculateDPI = (height, width) => {
    const imgDiagonal = calculateDiagonal(height, width);
    const printDiagonal = calculateDiagonal(9.1, 5.9);

    return (imgDiagonal * 2.54) / printDiagonal;
  };

  /**
   * Draw card background and user name text if they don't exist
   *
   * @returns {Promise<void>}
   */
  const drawCardBackgroundAndText = async () => {
    await loadAllFonts();

    if (!hasBackgroundAndText) {
      setHasBackgroundAndText(true);

      const defaultOptions = {
        crossOrigin: 'Anonymous',
        cropX: 35,
        cropY: 35,
      };

      const random = getRandomNumber();
      await fabric.Image.fromURL(`${cardBackground}?v=${random}`, (background) => {
        if (!checkIfObjectExists('card-background')) {

          background.id = 'card-background';
          // card background is not selectable
          background.selectable = false;
          background.width = cuttedSize.width;
          background.height = cuttedSize.height;
          fitSizeOfCardDesign(background);
          canvas.moveTo(background, 0);
          checkAllObjectsAreLoaded();
        }
      }, defaultOptions);

      await fabric.Image.fromURL(cardLevelDesign, async (oImg) => {
        if (!checkIfObjectExists('card-design-theme')) {
          oImg.id = 'card-design-theme';
          // card background is not selectable
          oImg.selectable = false;
          oImg.width = cuttedSize.width;
          oImg.height = cuttedSize.height;
          fitSizeOfCardDesign(oImg);
          canvas.moveTo(oImg, 4);

          checkAllObjectsAreLoaded();
        }

        if (!isSpecialCard)
          await fabric.Image.fromURL(levelBadgeDesign, async (levelBadge) => {
            if (!checkIfObjectExists('level-badge')) {
              levelBadge.id = 'level-badge';
              levelBadge.selectable = false;
              levelBadge.width = cuttedSize.width;
              levelBadge.height = cuttedSize.height;
              fitSizeOfCardDesign(levelBadge);
              canvas.moveTo(levelBadge, 5);
              checkAllObjectsAreLoaded();
            }
          }, defaultOptions);

        await fabric.Image.fromURL(`${nameBadgeDesign}?v=${random}`, (badge) => {
          if (!checkIfObjectExists('name-badge')) {
            badge.id = 'name-badge';
            // name badge is not selectable
            badge.selectable = false;
            badge.width = cuttedSize.width;
            badge.height = cuttedSize.height;
            fitSizeOfCardDesign(badge);
            canvas.moveTo(badge, 7);

            const text = new fabric.Text(myViewer.display_name, {
              id: 'user-text',
              fontFamily: 'Oswald',
              fill: Colors.white,
              left: nameBadgePositions[nameBadge].left,
              selectable: false,
              textAlign: nameBadgePositions[nameBadge].textAlign,
            });
            canvas.moveTo(text, 8);
            checkTextWidthLength(text);
            checkAllObjectsAreLoaded();
          }
        }, defaultOptions);
      }, defaultOptions);
    }
  };

  const checkAllObjectsAreLoaded = () => {
    if (canvas.getObjects().length >= (isSpecialCard ? 4 : 5))
      checkSortingOfObjects();
  };

  /**
   * First - reset to the default values for the font position
   * Second - check the text width length
   * If the text width length is longer than 170px then reduce in while loop for minus 1 font size
   * and top + 0.5px
   *
   * @param obj
   * @param givenNameBadge
   */
  const checkTextWidthLength = (obj, givenNameBadge = null) => {
    const defaultValues = nameBadgePositions[givenNameBadge !== null ? givenNameBadge : nameBadge];

    // default values
    obj.set('fontSize', 22);
    obj.set('top', defaultValues.top);

    while (obj.width > 170 && obj.text) {
      obj.set('fontSize', obj.fontSize - 1);
      obj.set('top', obj.top + 0.5);
      canvas.requestRenderAll();
    }

    if (defaultValues.textAlign === 'center') { // change left position for different texts
      const restWidth = 170 - obj.width;
      obj.set('left', 75 + restWidth / 2);
      canvas.requestRenderAll();
    }
  };

  /**
   * Load all custom web fonts to prevent font loading error at the first time of a font call
   */
  const loadAllFonts = async () => {
    await fonts.map(async (font) => {
      if (font !== 'Arial')
        await loadAllFontTypes(font);
    });
  };

  /**
   * Load the normal, italic bold, italic and bold fonts of one font family
   *
   * @param fontFamily
   */
  const loadAllFontTypes = async (fontFamily) => {
    await new FontFaceObserver(fontFamily).load()
    .then(() => {
    }, () => {
      console.error(`Font ${fontFamily} is not available`);
    });
    await new FontFaceObserver(fontFamily, {
      weight: 'bold',
      style: 'italic',
    }).load();
    await new FontFaceObserver(fontFamily, {
      style: 'italic',
    }).load();
    await new FontFaceObserver(fontFamily, {
      weight: 'bold',
    }).load();
  };

  const checkSameCardDesignUrl = async () => {
    const cardDesign = checkIfObjectExists('card-design-theme');
    const background = checkIfObjectExists('card-background');
    const nameBadge = checkIfObjectExists('name-badge');

    if (cardDesign && background && nameBadge) {
      let preDir = '';

      if (!cardRdyForFileLoad) {
        if (!isSpecialCard)
          preDir = `set${zeroPad(set)}/Level-${activeStep}`;
        else if (isSpecialCard && customCardFrame)
          preDir = `set${zeroPad(customCardFrame.set)}/Level-${customCardFrame.setLevel}`;
      }

      if (preDir === '')
        return;

      if (!cardDesign.getSrc()
      .includes(`${preDir}/01-frames/`) || !background.getSrc()
      .includes(`${preDir}/00-background/`) || !nameBadge.getSrc()
      .includes(`${preDir}/02-namebadge/`))
        await redraw();
    }
  };

  /**
   * Draw the canvas with all objects
   *
   * @returns {Promise<void>}
   */
  const drawCanvas = async () => {
    if (canvas) {
      // to redraw if user will create another card
      const levelBadge = checkIfObjectExists('level-badge');
      if (level && levelBadge && levelBadge.getSrc() && !levelBadge.getSrc()
      .includes(`carddesign-rarity-0${level}.png`))
        redraw();

      await checkSameCardDesignUrl();
      if (!checkIfObjectExists('card-background'))
        await drawCardBackgroundAndText();

      if (canvas.getObjects().length > 0) {
        if (uploadedImages.length > 0)
          await drawUserImages();

        if (!checkIfObjectExists('user-logo') && uploadedLogo)
          await drawUserLogo();
      }
      addCanvasEvents();
    }
  };

  /**
   * Upload and save canvas object with serialization
   */
  const save = async () => {
    setUploadLoading(true);
    const jsonCanvas = canvas.toJSON();
    const canvasObjs = canvas.getObjects();

    canvas.clone((canvasObj) => {
      canvasObj.setZoom(2.15);
      canvasObj.setWidth(canvasObj.width * canvasObj.getZoom());
      canvasObj.setHeight(canvasObj.height * canvasObj.getZoom());

      // important, otherwise all objects are moved
      canvasObj.getObjects()
      .map((obj, index) => {
        if (canvasObjs[index].id.includes('-text') || canvasObjs[index].id.includes('user-') || canvasObjs[index].id === 'active-hero') { // because no ids was given of canvas clone
          canvasObj.getObjects()[index].top = obj.top + 17;
          canvasObj.getObjects()[index].left = obj.left + 17;
        } else { // width and height of the images scaled to the new printed size
          canvasObj.getObjects()[index].cropX = 0;
          canvasObj.getObjects()[index].cropY = 0;
          canvasObj.getObjects()[index].width = printSize.width;
          canvasObj.getObjects()[index].height = printSize.height;
          canvasObj.getObjects()[index].scaleToHeight(printSize.height)
          .scaleToWidth(printSize.width);
        }
      });

      canvasObj.setWidth(printSize.width);
      canvasObj.setHeight(printSize.height);

      canvasObj.requestRenderAll();

      const bigFile = dataURItoFile(canvasObj.toDataURL(), 'image.png');
      setBigCardFile(bigFile);
    });

    const file = dataURItoFile(canvas.toDataURL(), 'image.png');
    const serialization = new File([JSON.stringify(jsonCanvas)], 'serialization.txt', { type: 'text/plain' });

    const dpi = imageDPI ? Math.round(imageDPI) : 0;

    console.log({
      level,
      dpi,
      card_id: card ? card._id : null,
      booster_id: boosterId,
    });

    uploadCardTemplate({
      variables: {
        image: file,
        serialization,
        metaData: {
          level,
          dpi,
          card_id: card ? card._id : null,
          booster_id: boosterId,
        },
      },
    });
  };

  /**
   * Set an id for every object in canvas object (important for assignments and reviews)
   */
  const saveIdsForObjects = () => {
    const canvasObjects = canvas.getObjects();
    let userImage = 0;
    const tmpUserImages = [];
    setStarted(true);

    canvasObjects.map((obj, idx) => {
      if (idx === 0) {
        canvasObjects[idx].id = 'card-background';
        canvasObjects[idx].selectable = false;

        if (isSpecialCard) {
          const set = parseInt(getStringBetween(obj.src, 'sets/set', '/Level'));
          const setLevel = parseInt(getStringBetween(obj.src, '/Level-', '/00-background'));

          setCustomCardFrame({ set, setLevel });
        }

      } else {
        if (obj.type === 'text') { // user-text
          const filter = {
            text: {
              isBold: canvasObjects[idx].fontWeight === 'bold',
              isItalic: canvasObjects[idx].fontStyle === 'italic',
              fontFamily: canvasObjects[idx].fontFamily,
              color: canvasObjects[idx].fill,
            },
          };

          canvasObjects[idx].id = 'user-text';

          if (filtersRef.current !== null)
            filtersRef.current.updateFilters(filter);

          canvasObjects[idx].selectable = false;
        } else { // effect, card-design-theme, user-image, name-badge
          if (obj.src.includes('user-uploads')) {
            const tmpData = userImage;
            canvasObjects[idx].id = `user-image-${tmpData}`;
            canvasObjects[idx].onSelect = () => setActiveUserKey(tmpData);
            tmpUserImages.push({
              value: obj.src,
              key: tmpData,
            });
            userImage += 1;
          } else if (obj.src.includes('logo-uploads')) {
            canvasObjects[idx].id = 'user-logo';
            canvasObjects[idx].onSelect = () => setActiveUserKey(2);
            setUploadedLogo(obj.src);
          } else if (obj.src.includes('/heroes/')) {
            canvasObjects[idx].id = 'active-hero';
            canvasObjects[idx].onSelect = () => setActiveUserKey(3);
            setActiveHero(obj.src);
          } else {
            const urlParts = obj.src.split('/');

            let objId = '';
            if (obj.src.includes('/effects')) {
              objId = 'effect';

              urlParts.pop();
              const effectNr = urlParts.pop()
              .replace('effect-', '');
              const styleNr = urlParts.pop()
              .replace('style-', '');

              setActiveEffect(parseInt(effectNr));
              setSelectedStyle(parseInt(styleNr));
            } else if (obj.src.includes('/01-frames/')) {
              objId = 'card-design-theme';

              const frameNr = urlParts.pop()
              .replace('.png', '');

              setCardFrame(parseInt(frameNr));
            } else if (obj.src.includes('/rarities/')) {
              objId = 'level-badge';
            } else if (obj.src.includes('/02-namebadge/')) {
              objId = 'name-badge';

              const badgeNr = urlParts.pop()
              .replace('.png', '');

              setNameBadge(parseInt(badgeNr));
            }

            canvasObjects[idx].id = objId;
            canvasObjects[idx].selectable = false;
            fitSizeOfCardDesign(canvasObjects[idx]);
          }
        }
      }
    });
    setUploadedImages(tmpUserImages);
    setTimeout(() => {
      setCanvasLoading(false);
      calculateAllImagesDPI();
    }, 500);
  };

  const checkDuplicateObjects = (objects) => {
    const objTypes = {
      bg: false,
      userImages: 0,
      cardDesign: false,
      text: false,
      levelBadge: false,
      nameBadge: false,
      effect: false,
      userLogo: false,
      activeHero: false,
    };
    const deleteIndexes = [];

    objects.map((object, index) => {
      let deleteIndex = true;
      if (object.type === 'text') {
        const type = 'text';
        if (!objTypes[type]) {
          objTypes[type] = true;
          deleteIndex = false;
        }
      } else {
        if (object.src.includes('00-background/00.png')) {
          if (!objTypes.bg) {
            objTypes.bg = true;
            if (object.top !== 0)
              object.top = 0;
            deleteIndex = false;
          }
        } else if (object.src.includes(`/01-frames/`)) {
          if (!objTypes.cardDesign) {
            objTypes.cardDesign = true;
            if (object.top !== 0)
              object.top = 0;
            deleteIndex = false;
          }
        } else if (object.src.includes(`/rarities/`)) {
          if (!objTypes.levelBadge) {
            objTypes.levelBadge = true;
            if (object.top !== 0)
              object.top = 0;
            deleteIndex = false;
          }
        } else if (object.src.includes(`/effect-`)) {
          if (!objTypes.effect) {
            objTypes.effect = true;
            if (object.top !== 0)
              object.top = 0;
            deleteIndex = false;
          }
        } else if (object.src.includes(`/02-namebadge/`)) {
          if (!objTypes.nameBadge) {
            objTypes.nameBadge = true;
            if (object.top !== 0)
              object.top = 0;
            deleteIndex = false;
          }
        } else if (object.src.includes('/heroes/')) {
          objTypes.activeHero = true;
          deleteIndex = false;
        } else if (object.src.includes('user-uploads')) {
          objTypes.userImages += 1;
          deleteIndex = false;

          // TODO: check if background is not 0
          // FIX: If background top is not 0, than the objects are moved wrong
          /*if (objects[index + 1] && objects[index + 1].top !== 0)
            object.top = object.top - objects[index + 1].top;*/
        } else if (object.src.includes('logo-uploads')) {
          if (!objTypes.userLogo) {
            objTypes.userLogo = true;
            deleteIndex = false;
          }
        }
      }

      if (deleteIndex)
        deleteIndexes.push(index);

      return null;
    });

    for (const deleteIndex of deleteIndexes)
      objects[deleteIndex] = null;

    return {
      objects,
      types: objTypes,
    };
  };

  /**
   * Load the serialization from the current or previous card
   */
  const loadCanvasFromFile = async () => {
    await checkSameCardDesignUrl();
    if (started)
      return null;

    const headers = {
      'cache-control': 'no-store',
      pragma: 'no-cache',
    };

    // Load the serialization from the current card and set ids for the objects
    if (canvas && canvas.getObjects().length === 0 && card) {
      const specificFilename = card._id;

      fetch(`${conf.no_cors_cdn_url}/cards/self-init-serialization/${specificFilename}.txt`, { headers })
      .then(response => response.json())
      .then(jsonResponse => {
        if (jsonResponse) {
          const checkObjs = checkDuplicateObjects(jsonResponse.objects);

          if (checkObjs.types.bg && checkObjs.types.text && checkObjs.types.cardDesign && checkObjs.types.nameBadge && ((!isSpecialCard && checkObjs.types.levelBadge) || isSpecialCard)) {
            jsonResponse.objects = [...checkObjs.objects];
            canvas.loadFromJSON(jsonResponse, () => {
              saveIdsForObjects();
            });
            addCanvasEvents();
          } else { // rare case when the serialization is saved without objects
            drawCanvas();
          }
        }
      })
      .catch(error => {
        console.log(error);
        loadCanvasFromFile();
      });
    } else if (canvas && !card && previousCard) { // load the serialization from the previous card and set text and object filters
      setCanvasLoading(true);

      const specificFilename = previousCard._id;

      fetch(`${conf.no_cors_cdn_url}/cards/self-init-serialization/${specificFilename}.txt`, { headers })
      .then(response => response.json())
      .then(async (jsonResponse) => {
        const filters = {};
        const checkObjs = checkDuplicateObjects(jsonResponse.objects);

        jsonResponse.objects = [...checkObjs.objects];
        const { objects } = jsonResponse;
        const textIdx = objects.findIndex(object => object && object.type === 'text' && object.text.length > 1);
        if (textIdx !== -1) {
          filters.text = {
            isBold: objects[textIdx].fontWeight === 'bold',
            isItalic: objects[textIdx].fontStyle === 'italic',
            fontFamily: objects[textIdx].fontFamily,
            color: objects[textIdx].fill,
          };
          // set filters for text children (only state)
          if (filtersRef.current !== null)
            filtersRef.current.updateFilters(filters);
        }

        objects.map((obj, index) => {
          if (obj && index > 0 && obj.type === 'image') {
            const urlParts = obj.src.split('/');

            if (obj.src.includes('/effects')) {
              urlParts.pop();
              /*const effectNr = */
              urlParts.pop()
              .replace('effect-', '');
              const styleNr = urlParts.pop()
              .replace('style-', '');

              // setActiveEffect(parseInt(effectNr));
              setSelectedStyle(parseInt(styleNr));
            }/* else if (obj.src.includes('/01-frames/')) {
              const frameNr = urlParts.pop()
              .replace('.png', '');

              setCardFrame(parseInt(frameNr));
            } else if (obj.src.includes('/02-namebadge/')) {
              const badgeNr = urlParts.pop()
              .replace('.png', '');

              setNameBadge(parseInt(badgeNr));
            }*/
          }
        });

        addCanvasEvents();

        // set filters for card design theme object
        const cardDesignTheme = checkIfObjectExists('card-design-theme');
        if (cardDesignTheme) {
          canvas.renderAll();

          setCanvasLoading(false);
          setStarted(true);
        }
      })
      .catch(error => {
        console.log(error);
        loadCanvasFromFile();
      });
    }
  };

  const keyHandler = (key) => {
    if (canvas) {
      const STEP = 1;
      // prevent scrolling
      const activeGroup = canvas.getActiveObjects();

      if (Array.isArray(activeGroup)) {
        activeGroup.forEach(obj => {
          switch (key) {
            case 'ArrowLeft': // left
              obj.left = obj.left - STEP;
              break;
            case 'ArrowUp': // up
              obj.top = obj.top - STEP;
              break;
            case 'ArrowRight': // right
              obj.left = obj.left + STEP;
              break;
            case 'ArrowDown': // down
              obj.top = obj.top + STEP;
              break;
            default:
              break;
          }
          obj.setCoords();
        });

        canvas.renderAll();
      }
    }
  };

  const setActiveObjectInactive = () => {
    if (canvas && canvas.getActiveObject()) {
      canvas.discardActiveObject();
      canvas.requestRenderAll();
    }
  };

  const addCanvasEvents = () => {
    if (canvas && !hasEvents) {
      canvas.on({
        'mouse:down': (e) => {
          if (e.target && e.target.selectable) {
            e.target.opacity = 0.5;
            canvas.renderAll();
            if (togglePicker)
              setTogglePicker(false);
          }
        },
        'mouse:up': (e) => {
          if (e.target && e.target.selectable) {
            e.target.opacity = 1;
            canvas.renderAll();
          }
        },
        'object:moved': async (e) => {
          e.target.opacity = 0.5;
          addActionStep({
            type: 'moveObject',
            data: {
              old: { top: e.transform.original.top, left: e.transform.original.left },
              new: { top: e.target.top, left: e.target.left },
              id: e.target.id,
            },
          });
        },
        'object:modified': (e) => {
          e.target.opacity = 1;
        },
      });
      setHasEvents(true);
    }
  };

  // if card is created ---> show edit mode
  if (cardRdyForFileLoad && !started) {
    loadCanvasFromFile();
    if (canvas) {
      if (uploadedImages.length > 0)
        drawUserImages();

      if (!checkIfObjectExists('user-logo') && uploadedLogo)
        drawUserLogo();
    }
  } else { // if card is created  --> show create mode
    if (!isSpecialCard || (isSpecialCard && customCardFrame))
      drawCanvas();

    if (previousCard && !canvasLoading)
      loadCanvasFromFile();

    /*if (!canvasLoading) {
      if (card && card.isApproved && card.version === 3) {
        const momentNow = moment()
        .valueOf();
        const add60Days = 60 * 24 * 60 * 60 * 1000;
        const cardCreationDate = parseInt(card.created_at) + add60Days;
        if (previousCard && previousCard.isApproved && (
          (previousCard.migrationImage && momentNow > cardCreationDate) || // previous card is new too
          (!previousCard.migrationImage && momentNow < cardCreationDate))) { // previous card is younger than 60 days, but not created new
          loadCanvasFromFile();
        }
      } else if (!card && previousCard) {
        // wait do draw all objects on canvas (otherwise there is an error message because it loads too quickly)
        loadCanvasFromFile();
      }
    }*/
  }

  return (
    <div className={classes.root}>
      {(canvasLoading || (isSpecialCard && !customCardFrame && !started)) &&
      <div className={classes.absoluteLoading}>
        {(isSpecialCard && !customCardFrame) ?
          <>
            <FontAwesomeIcon icon={faExclamationTriangle} size="10x" color={Colors.primary} />
            <div style={{ marginTop: 10, fontWeight: 'bold' }}>
              {t('no_custom_card_frame_selected')}
            </div>
          </>
          :
          <Loading />
        }
      </div>
      }
      <div style={{ ...Helpers.fillRow, ...Helpers.mainSpaceBetween, ...Helpers.crossStart }}>
        <div className={classes.partLayout}>

          <CardToolHeroMaker
            setCanvasLoading={setCanvasLoading}
            canvas={canvas}
            checkIfObjectExists={checkIfObjectExists}
            setActiveHero={setActiveHero}
            fitSizeOfUploadedImage={fitSizeOfUploadedImage}
            checkSortingOfObjects={checkSortingOfObjects}
            setActiveUserKey={setActiveUserKey}
            addActionStep={addActionStep}
            calculateAllImagesDPI={calculateAllImagesDPI}
          />

          <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween, marginBottom: 10 }}>
            <div style={{ marginRight: 5, width: '100%' }}>
              <CardToolImageUpload
                disabled={uploadedImages.length === 2}
                setCanvasLoading={setCanvasLoading}
                setLastImage={setLastImage}
                userImagePlaceholderUpload={userImagePlaceholderUpload}
                level={level}
                uploadedImages={uploadedImages}
              />
            </div>
            <div style={{ marginLeft: 5, width: '100%' }}>
              <CardToolImageUpload
                disabled={uploadedLogo}
                setCanvasLoading={setCanvasLoading}
                setLastImage={setLastImage}
                userImagePlaceholderUpload={userImagePlaceholderUpload}
                level={level}
                isLogo
              />
            </div>
          </div>

          <div className={classes.area}>
            <CardToolUserImages
              canvas={canvas}
              uploadedImages={uploadedImages}
              checkIfObjectExists={checkIfObjectExists}
              removeSpecificIndexOfImages={removeSpecificIndexOfImages}
              addActionStep={addActionStep}
              imageDPI={imageDPI}
              calculateAllImagesDPI={calculateAllImagesDPI}
              setActiveUserKey={setActiveUserKey}
              activeUserKey={activeUserKey}
              uploadedLogo={uploadedLogo}
              setUploadedLogo={setUploadedLogo}
              setActiveHero={setActiveHero}
              activeHero={activeHero}
            />
          </div>

          <div className={classes.area} style={{ marginTop: 10 }}>
            {canvas &&
            <CardToolFabricObjectFilters
              canvas={canvas}
              checkIfObjectExists={checkIfObjectExists}
              activeUserKey={activeUserKey}
              addActionStep={addActionStep}
            />
            }
          </div>
        </div>

        <div style={{ flex: 1 }}>
          <ClickAwayListener onClickAway={setActiveObjectInactive}>
            <div
              className={classes.area}
              style={{ ...Helpers.fillRowCenter, position: 'relative', padding: '60px 0 32px' }}
            >
              <div
                style={{ ...Helpers.fillCol, position: 'absolute', left: 10, top: 10, zIndex: 6 }}>
                <CustomTooltip title={t('card_tool_restart_info')}>
                  <div>
                    <CustomButton
                      onClick={restartWithNothing}
                      style={{ padding: 10, borderRadius: 0 }}
                    >
                      <FontAwesomeIcon icon={faPowerOff} color={Colors.white} />
                    </CustomButton>
                  </div>
                </CustomTooltip>
              </div>
              <canvas
                id="canvas"
                width={digitalSize.width}
                height={digitalSize.height}
                style={{ borderRadius: 4 }}
              />
              <div style={{ ...Helpers.fillCol, position: 'absolute', right: 10, top: 10 }}>
                <CustomTooltip title={t('undo')}>
                  <div>
                    <CustomButton
                      onClick={removeLastActionStep}
                      disabled={lastSteps.length === 0}
                      style={{ padding: 10, borderRadius: 0 }}
                    >
                      <FontAwesomeIcon icon={faReply} color={Colors.white} />
                    </CustomButton>
                  </div>
                </CustomTooltip>
              </div>
            </div>
          </ClickAwayListener>
          <div className={classes.area} style={{ marginTop: 16 }}>
            {uploadLoading ?
              <Loading />
              :
              <CustomButton
                onClick={save}
                disabled={(uploadedImages.length === 0 && !activeHero && !uploadedLogo)}
                style={{ borderRadius: 3, width: '100%', background: '#52b51b' }}
              >
                {t('card_tool_save_next')}
              </CustomButton>
            }
          </div>
        </div>

        <div className={classes.partLayout}>
          {isSpecialCard &&
          <CardToolSelectSpecialCustomCard
            cardFrame={customCardFrame} setCustomCardFrame={setCustomCardFrame} />
          }

          <CardToolSelectFrame
            activeFrame={cardFrame}
            checkIfObjectExists={checkIfObjectExists}
            setCardFrame={setCardFrame}
            directory={directory}
            canvas={canvas}
            setCanvasLoading={setCanvasLoading}
            checkSortingOfObjects={checkSortingOfObjects}
            fitSizeOfCardDesign={fitSizeOfCardDesign}
            cuttedSize={cuttedSize}
            selectedStyle={selectedStyle}
            activeEffect={activeEffect}
          />
          <div className={classes.area}>
            <CardToolSelectNameBadge
              activeBadge={nameBadge}
              checkIfObjectExists={checkIfObjectExists}
              setNameBadge={setNameBadge}
              directory={directory}
              canvas={canvas}
              nameBadgePositions={nameBadgePositions}
              checkTextWidthLength={checkTextWidthLength}
              myViewer={myViewer}
              setCanvasLoading={setCanvasLoading}
              checkSortingOfObjects={checkSortingOfObjects}
              fitSizeOfCardDesign={fitSizeOfCardDesign}
              cuttedSize={cuttedSize}
            />
            {canvas &&
            <CardToolFabricFilters
              ref={comp => filtersRef.current = comp}
              canvas={canvas}
              checkIfObjectExists={checkIfObjectExists}
              checkTextWidthLength={checkTextWidthLength}
              addActionStep={addActionStep}
              objectId="user-text"
              label={t('name_font_type')}
              disabled={nameBadgePositions[nameBadge].noText}
              fonts={fonts}
            />
            }
          </div>
          <div className={classes.area} style={{ marginTop: 10 }}>
            {canvas &&
            <CardToolSelectEffect
              canvas={canvas}
              checkIfObjectExists={checkIfObjectExists}
              selectedStyle={selectedStyle}
              setSelectedStyle={setSelectedStyle}
              activeEffect={activeEffect}
              setActiveEffect={setActiveEffect}
              cardFrame={cardFrame}
              setCanvasLoading={setCanvasLoading}
              cuttedSize={cuttedSize}
              fitSizeOfCardDesign={fitSizeOfCardDesign}
              checkSortingOfObjects={checkSortingOfObjects}
            />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    color: Colors.white,
    backgroundColor: Colors.containerBGColor,
    padding: '20px 10px',
    position: 'relative',
  },
  absoluteLoading: {
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,.6)',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 5,
    cursor: 'not-allowed',
    ...Helpers.fillColCenter,
    color: Colors.primary,
  },
  area: {
    backgroundColor: '#010F22',
    padding: 20,
    borderRadius: 8,
  },
  partLayout: {
    flex: 1,
    padding: '0 10px',
  },
}));

const mapStateToProps = (state) => {
  const { cardTool } = state;
  return { cardTool };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    addLastStep,
    removeLastStep,
    resetLastSteps,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(CardToolFabric);