import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';

import { zeroPad } from 'react-countdown';

import conf from '../../conf.json';

import { getRandomInt } from '../../utils/helper';

const useStyles = makeStyles({
  bigAvatar: {
    position: 'absolute',
    width: '50%',
    border: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
});

const PlainBoosterView = (props) => {
  const classes = useStyles();
  const { booster, height, avatarUrl } = props;

  const boosterDesign = booster.front_image ? booster.front_image.replace(/\D/g, '') : getRandomInt(19);

  return (
    <div style={{position: 'relative', width: '100%'}}>
        {booster.is_starter_pack ?
        <img
            src={`${conf.cdn_url}${booster.front_image}?width=${height}`}
            alt="Starter pack"
            height={{height}}
            style={{maxWidth: '100%', height: 'auto'}}
        />
        :
        <img
            src={`${conf.cdn_url}${booster.is_custom_booster ? booster.front_image : `/booster-packs/new/sh-base-booster-pack-${zeroPad(boosterDesign)}.png`}?height=${height}`}
            alt="Booster pack"
            height={height}
            style={{maxWidth: '100%', height: 'auto'}}
        />
        }
        {!booster.is_custom_booster &&
        <Avatar
            className={classes.bigAvatar}
            alt="Avatar"
            src={avatarUrl}
        />
        }
    </div>
  );
};

PlainBoosterView.defaultProps = { height: 100 };

export default PlainBoosterView;