import React from 'react';
import { useLocation } from 'react-router-dom';

import MobileMenuBar from './MobileMenuBar';
import MobileSubFooterBar from './MobileSubFooterBar';

const MobileFooterBar = (props) => {
  const { pathname } = useLocation();
  const { streamer } = props;

  const menu = [
    {
      icon: 'fa-home',
      text: 'home',
      link: '/hq/hub',
      subMenu: [
        {
          icon: 'fa-home',
          text: 'home',
          link: '/hq/hub',
        },
        {
          icon: 'fa-tv-retro',
          text: 'submenu_streams',
          link: '/hq/game-streams',
        },
      ],
    },
    {
      icon: 'fa-cards',
      text: 'cards',
      link: '/inventory/groups',
      subMenu: [
        {
          icon: 'fa-rectangle-vertical-history',
          text: 'submenu_booster',
          link: '/item-store/booster',
        },
        {
          icon: 'fa-cards',
          text: 'submenu_my_cards',
          link: '/inventory/groups',
        },
        {
          icon: 'fa-inboxes',
          text: 'submenu_storage_boxes',
          link: '/inventory/boxes',
        },
        {
          icon: 'fa-sensor-triangle-exclamation',
          text: 'submenu_alert_capsules',
          link: '/inventory/alert-capsules',
        },
        {
          icon: 'fa-droplet',
          text: 'submenu_drops',
          link: '/inventory/drops',
        },
      ],
    },
    {
      icon: 'fa-store',
      text: 'menu_market',
      link: '/marketplace/home',
      subMenu: [
        {
          icon: 'fa-home',
          text: 'home',
          link: '/marketplace/home',
        },
        {
          icon: 'fa-store',
          text: 'menu_market',
          link: '/marketplace/marketplace',
        },
        {
          icon: 'fa-cart-shopping-fast',
          text: 'active_offers',
          link: '/marketplace/active-offers',
        },
        {
          icon: 'fa-cart-circle-arrow-up',
          text: 'purchased_offers',
          link: '/marketplace/purchased-offers',
        },
        {
          icon: 'fa-cart-circle-arrow-down',
          text: 'sold_offers',
          link: '/marketplace/sold-offers',
        },
      ],
    },
    {
      icon: 'fa-trophy',
      text: 'ranking',
      link: '/ranking/streamer',
      subMenu: [
        {
          icon: 'fa-trophy-star',
          text: 'streamer',
          link: '/ranking/streamer',
        },
        {
          icon: 'fa-trophy-star',
          text: 'viewer',
          link: '/ranking/viewer',
        },
      ],
    },
    {
      icon: 'fa-cart-shopping',
      text: 'shop',
      link: '/card-shop',
    },
    {
      subMenu: [
        {
          icon: 'fa-rectangle-vertical-history',
          text: 'submenu_booster',
          link: `/profile/${streamer && streamer.login}/booster`,
        },
        {
          icon: 'fa-house-user',
          text: 'overview',
          link: `/profile/${streamer && streamer.login}/overview`,
        },
        {
          icon: 'fa-boxes-stacked',
          text: 'showcase',
          link: `/profile/${streamer && streamer.login}/showcase`,
        },
        {
          icon: 'fa-trophy-star',
          text: 'submenu_leaderboard',
          link: `/profile/${streamer && streamer.login}/leaderboard`,
        },
        {
          icon: 'fa-trophy-star',
          text: 'submenu_ranked',
          link: `/profile/${streamer && streamer.login}/ranked`,
        },
      ],
    },
  ];

  const subMenu = menu.find(menuItem => {
    if (menuItem.subMenu)
      return menuItem.subMenu.find(subItem => subItem.link === pathname);

    return null;
  });

  return (
    <>
      {subMenu && <MobileSubFooterBar subMenu={subMenu.subMenu} />}
      <MobileMenuBar menu={menu} />
    </>
  );
};

export default MobileFooterBar;