import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Colors from '../../theme/Colors';

const useStyles = makeStyles({
  item: {
    color: '#5C7291',
    padding: 5,
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.08)',
    },
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
  unread: {
    color: Colors.primary,
  },
});

const NotificationsItem = (props) => {
  const { t } = useTranslation();
  const { notification } = props;
  const classes = useStyles();

  return (
    <div className={classes.item} style={notification.is_read ? { opacity: 0.5 } : {}}>
      <a href={notification.web_rel_url}>
        <div style={{
          color: Colors.white,
          textTransform: 'uppercase',
        }}>
          {notification.type === 'badge' ? t(notification.title) : notification.title}
        </div>
        <div>
          {notification.type === 'badge' ? t(notification.body) : notification.body}
        </div>
      </a>
    </div>
  );
};

export default NotificationsItem;