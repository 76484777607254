import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';

import CardShopSHSupportChart from './CardShopSHSupportChart';
import CardShopPhysicalCard from './CardShopPhysicalCard';
import CardShopMatchCardLeftSideMenuDetails from './CardShopMatchCardLeftSideMenuDetails';
import CardShopMatchCardLeftSideMenuHistory from './CardShopMatchCardLeftSideMenuHistory';
import CardShopMatchCardLeftSideMenuCards from './CardShopMatchCardLeftSideMenuCards';

const useStyles = makeStyles(() => ({
  tabMenu: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
    ...Helpers.fillRow,
    fontSize: 14,
    color: Colors.halfWhite,

    '& > div': {
      marginRight: 32,
      paddingBottom: 10.5,
      borderBottom: '1px solid transparent',
      marginBottom: -1,
      cursor: 'pointer',
      transition: 'all 0.3s ease-out',

      '&.active': {
        borderColor: Colors.white,
        color: Colors.white,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
}));

const CardShopMatchCardLeftSideMenu = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { loading, card, offer, isLegacy } = props;
  const [activeTab, setActiveTab] = useState(0);

  const tabs = card ? [
    'how_you_support_streamer',
    'the_physical_card',
    'card_details',
    'card_history',
  ] : [
    'how_you_support_streamer',
    'which_cards_inside',
  ];

  let marginTop = 70;
  if (activeTab === 1)
    marginTop = offer ? 32.5 : 64.5;
  if (activeTab >= 2)
    marginTop = 16;

  return (
    <div style={{ marginTop: 3, background: 'rgba(255, 255, 255, 0.04)', padding: 32 }}>

      <div className={classes.tabMenu}>
        {tabs.map((tab, index) => (
          <div
            className={activeTab === index ? 'active' : ''}
            onClick={() => setActiveTab(index)}
          >
            {t(tab)}
          </div>
        ))}
      </div>

      <div style={{ marginTop }}>
        {activeTab === 0 &&
        <CardShopSHSupportChart />
        }
        {(activeTab === 1 && card) &&
        <div style={{ ...Helpers.fillRowMain, ...Helpers.mainSpaceBetween }}>
          <CardShopPhysicalCard isSmall />
        </div>
        }
        {(activeTab === 1 && offer) &&
        <CardShopMatchCardLeftSideMenuCards offer={offer} loading={loading} />
        }
        {activeTab === 2 &&
        <CardShopMatchCardLeftSideMenuDetails card={card} loading={loading} />
        }
        {activeTab === 3 &&
        <CardShopMatchCardLeftSideMenuHistory card={card} loading={loading} />
        }
      </div>

    </div>
  );
};

export default CardShopMatchCardLeftSideMenu;