import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import { getStripeInformation } from '../../apollo/mergedQueries';
import {
  createStripeExpressAccount as createStripeExpressAccountMutation,
  createStripeExpressDashboardLink as createStripeExpressDashboardLinkMutation,
  createStripePayout as createStripePayoutMutation,
} from '../../apollo/mutations';

import Stripe from '../../components/Stripe/Stripe';

const StripeContainer = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isTabletOrMobile } = props;
  const [page, setPage] = useState(2);
  const [payLoading, setPayLoading] = useState(false);
  const perPage = 25;

  const { data, loading, fetchMore } = useQuery(getStripeInformation, {
    variables: {
      pagination: { page: 1, perPage },
    },
  });

  const [createStripeExpressAccount] = useMutation(createStripeExpressAccountMutation, {
    onCompleted: (data) => {
      const { createStripeExpressAccount } = data;

      window.location.replace(createStripeExpressAccount);
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{
      query: getStripeInformation,
      variables: {
        pagination: { page: 1, perPage },
      },
    }],
  });

  const [createStripeExpressDashboardLink] = useMutation(createStripeExpressDashboardLinkMutation, {
    onCompleted: (data) => {
      const { createStripeExpressDashboardLink } = data;
      window.open(createStripeExpressDashboardLink, '_blank');
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
  });

  const [createStripePayout] = useMutation(createStripePayoutMutation, {
    onCompleted: () => {
      setPayLoading(false);
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
      setPayLoading(false);
    },
    refetchQueries: () => [{
      query: getStripeInformation,
      variables: {
        pagination: { page: 1, perPage },
      },
    }],
  });

  const onCreate = () => {
    createStripeExpressAccount();
  };

  const redirectToPayouts = () => {
    createStripeExpressDashboardLink();
  };

  const payOut = () => {
    setPayLoading(true);
    createStripePayout();
  };

  const myViewer = (data) && data.getMyViewer;
  const myStreamer = (data) && data.getMyStreamer;
  const cards = (data) && data.getMyStreamerCards;
  const stripeData = (data) && data.getStripeTransferData;

  return (
    <Stripe
      myViewer={myViewer}
      myStreamer={myStreamer}
      onCreate={onCreate}
      cards={cards}
      stripeData={stripeData}
      redirectToPayouts={redirectToPayouts}
      isTabletOrMobile={isTabletOrMobile}
      loading={loading}
      payLoading={payLoading}
      payOut={payOut}
      totalCount={stripeData && stripeData.orders_count}
      onLoadMore={async () => {
        try {
          await fetchMore({
            variables: {
              pagination: { perPage, page },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              setPage(page + 1);
              return {
                getStripeTransferData: {
                  card_orders: [
                    ...prev.getStripeTransferData.card_orders,
                    ...fetchMoreResult.getStripeTransferData.card_orders,
                  ],
                  digital_sales: [
                    ...prev.getStripeTransferData.digital_sales,
                    ...fetchMoreResult.getStripeTransferData.digital_sales,
                  ],
                  __typename: prev.getStripeTransferData.__typename,
                },
              };
            },
          });
        } catch {
        }
      }}
    />
  );
};

export default StripeContainer;