import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';

const useStyles = makeStyles(() => ({
  itemContent: {
    color: '#FFFFFF80',
    flex: 1,
    lineHeight: 1,
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 300,
    '& span': {
      color: Colors.white,
      fontWeight: 400,
    },
  },
  roundedIcon: {
    marginRight: 8,
    borderRadius: 50,
    width: 32,
    height: 32,
    ...Helpers.fillRowCenter,
  },
  verticalLine: {
    marginTop: 10,
    width: 2,
    height: 112,
    background: 'rgba(255, 255, 255, 0.14)',
    marginLeft: 16,
  },
  horizontalLine: {
    background: 'rgba(255, 255, 255, 0.14)',
    marginLeft: 16,
    height: 2,
    width: 24,
  },
}));

const CardViewTimeline = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { information, isLegacy } = props;

  const currentMoment = moment.unix(information.created_at / 1000);

  information.events.unshift({
    type: 'creation',
    key: isLegacy ? 'timeline_creation_craft' : 'timeline_creation_open',
    icon: {
      icon: 'fa-solid fa-bolt fa-sixteen',
      color: '#E12138',
      bg: 'rgba(255, 255, 255, 0.14)',
    },
    params: [
      information.viewer.display_name,
      currentMoment.format('ll'),
      currentMoment.format('LT'),
      information.opened_as_number ? information.opened_as_number.toLocaleString() : '',
    ],
  });

  return (
    <>
      {
        information.events.map((event, index) => (
          <>
            <div
              key={`TimelineItemKey#${index}`}
              style={{ ...Helpers.fillRowCross, width: '100%' }}
            >
              <div className={classes.roundedIcon} style={{ background: event.icon.bg }}>
                <i className={event.icon.icon} style={{ color: event.icon.color }} />
              </div>
              <div className={classes.itemContent}>
                <div style={{ fontSize: 16 }}
                     dangerouslySetInnerHTML={{ __html: t(event.key, Object.assign({}, event.params)) }} />
                <div style={{ fontSize: 12, marginTop: 1, fontWeight: 300 }}>
                  {t('timeline_date', Object.assign({}, event.params))}
                </div>
              </div>
            </div>
            <div key={`TimelineVerticalLineKey#${index}`} className={classes.verticalLine} />
          </>
        ))
      }
      <div style={{ ...Helpers.fillRowCross }}>
        <div className={classes.horizontalLine} />
        <div
          className={classes.roundedIcon}
          style={{ background: 'rgba(255, 255, 255, 0.14)', width: 24, height: 24, marginLeft: 5 }}>
          <i className="fa-solid fa-timer" style={{ color: '#E12138', fontSize: 13 }} />
        </div>
        <div style={{ color: '#E12138', marginLeft: 6, fontFamily: 'Montserrat', fontSize: 16 }}>
          {t('no_more_activities')}
        </div>
      </div>
    </>
  );
};

export default CardViewTimeline;