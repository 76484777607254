import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const PollFormAnswerList = (props) => {
  const { answers, deleteAnswer } = props;

  return (
    <List>
      {answers.map((value, index) => {
        return (
          <ListItem key={value} role={undefined} dense button>
            <ListItemText id={`list-label-${value}`} primary={value} />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="delete"
                color="secondary"
                onClick={() => deleteAnswer(index)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

export default PollFormAnswerList;