import React from 'react';
import Helpers from '../../theme/Helpers';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    ...Helpers.fillColCenter,
    fontSize: 12,
    textAlign: 'center',
    width: 58,
    minWidth: 58,
    height: 58,
    minHeight: 58,
    background: 'rgb(255, 255, 255, 0.04)',
    borderRadius: '50%',
    boxSizing: 'border-box',
    position: 'relative',
  },
  innerCircle: {
    border: '1px solid white',
    position: 'absolute',
    width: 36,
    minWidth: 36,
    height: 36,
    minHeight: 36,
    borderRadius: '50%',
  },
  status: {
    ...Helpers.fillColCenter,
    background: '#1BBC2A',
    width: 18,
    minWidth: 18,
    height: 18,
    minHeight: 18,
    position: 'absolute',
    bottom: 0,
    right: 0,
    borderRadius: '50%',
    fontSize: 12,
    color: '1BBC2A',
  },
});

const CircleButton = ({ children, innerCircle, status, containerStyle }) => {
  const classes = useStyles();

  return (
    <div className={classes.container} style={containerStyle && containerStyle}>
      {innerCircle && <div className={classes.innerCircle} />}
      {children}
      {status && (
        <div
          className={classes.status}
          style={{
            background: status === 'ok' ? '#1BBC2A' : '#E12138',
          }}
        >
          <i className={`fa-solid fa-${status === 'ok' ? 'check' : 'exclamation'}`} />
        </div>
      )}
    </div>
  );
};

export default CircleButton;
