import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { withRouter } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Colors from '../../theme/Colors';
import { toggleSearch, } from '../../redux/actions';

const useStyles = makeStyles(() => ({
  optionItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: 6,
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.16)',
    },
  },
  listbox: {
    maxHeight: 'calc(100vh - 135px)',
  },
  option: {
    padding: '0 !important',
  },
  optionAvatar: {
    marginRight: 10,
    borderColor: Colors.roundedBG,
  },
  textInput: {
    border: '2px solid #607B40',
    backgroundColor: Colors.searchFieldBG,
    padding: '6px 10px',
    width: 210,
    marginLeft: -10,
    fontSize: 14,
    borderRadius: 10,
  },
  textInputUnderline: {
    '&:before': {
      borderBottom: 'none !important',
    },
    '&:after': {
      borderBottom: 'none !important',
    },
  },
  input: {
    padding: '4px 0 !important',
    fontSize: 14,
    fontWeight: 'normal',
    color: Colors.searchFieldColor,
  },
  paper: {
    color: Colors.white,
    margin: '10px -10px 0',
    background: 'transparent',
  },
  groupLabel: {
    color: Colors.white,
    background: Colors.gray5,
  },
}));

const HeaderSearch = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { results, loading, search, setSearch } = props;

  const options = (results) ? results : [];

  const onClick = (option) => {
    props.toggleSearch();
    props.history.push(option.link);
  }

  return (
    <Autocomplete
      id="search-field"
      freeSolo
      open
      options={options}
      groupBy={option => option.type}
      onChange={(event) => setSearch(event.target.value)}
      value={search}
      getOptionLabel={option => option.login ? option.login : option}
      classes={{
        paper: classes.paper,
        groupLabel: classes.groupLabel,
        option: classes.option,
        listbox: classes.listbox,
      }}
      renderOption={option => (
        <div
          className={classes.optionItem}
          onClick={() => onClick(option)}
        >
          <Avatar
            className={classes.optionAvatar}
            alt={option.name}
            src={option.profile_image_url}
          />
          {option.name}
        </div>
      )}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={`${t('search')} ...`}
          fullWidth
          size="small"
          classes={{
            root: classes.textInput,
          }}
          inputProps={{
            ...params.inputProps,
            onChange: e => {
              setSearch(e.target.value || '');
              params.inputProps.onChange(e);
            },
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
                {loading &&
                <CircularProgress color="secondary" size={20} />
                }
              </>
            ),
            classes: {
              input: classes.input,
              underline: classes.textInputUnderline,
            },
          }} />
      )}
    />
  );
};const mapDispatchToProps = dispatch => (
  bindActionCreators({
    toggleSearch,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(withRouter(HeaderSearch));