import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/react-hooks';
import moment from 'moment';

import { getEnergyDashboard } from '../../apollo/mergedQueries';
import { setActivitiesPrivacySettings as setActivitiesPrivacySettingsMutation } from '../../apollo/mutations';

import PageTitle from '../../components/PageTitle/PageTitle';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import EnergyComponent from '../../components/Energy/Energy';

const EnergyContainer = () => {
  const { t } = useTranslation();

  const startDate = moment()
  .subtract(1, 'months')
  .format('YYYY-MM-DD');
  const variables = {
    pagination: { page: 1, perPage: 10 },
    startDate,
  };
  const { data, loading, refetch } = useQuery(getEnergyDashboard, {
    variables,
    fetchPolicy: useCacheWithExpiration('getEnergyDashboard', variables),
  });

  const [setActivitiesPrivacySettings] = useMutation(setActivitiesPrivacySettingsMutation, {
    refetchQueries: () => [{ query: getEnergyDashboard, variables }],
  });

  const myViewer = (data) && data.getMyViewer;
  const todayCollectedStreamer = (data) && data.getTodayCollectedStreamer;
  const favoriteStreamer = (data) && data.getFavoriteStreamer;
  const activitiesPrivacySettings = (data) && data.getActivitiesPrivacySettings;
  const activities = (data) && data.getActivities;
  const status = (data) && data.workerStatus;
  const timeBasedData = (data) && data.timeBasedStreamViewers;

  return (
    <>
      <PageTitle title={t('energy')} />

      <EnergyComponent
        loading={loading}
        t={t}
        refetch={refetch}
        myViewer={myViewer}
        todayCollectedStreamer={todayCollectedStreamer}
        favoriteStreamer={favoriteStreamer}
        activitiesPrivacySettings={activitiesPrivacySettings}
        activities={activities}
        setActivitiesPrivacySettings={setActivitiesPrivacySettings}
        workerStatus={status}
        timeBasedData={timeBasedData}
      />
    </>
  );
};

export default EnergyContainer;