import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { togglePlayingBoosterAnimation } from '../../redux/actions';

import conf from '../../conf.json';

const VideoScene = (props) => {
  const { route, extra } = props;

  useEffect(() => {
    if (!extra.playedAnimation) {
      props.togglePlayingBoosterAnimation(true);
      setTimeout(() => props.history.push(route), 5250);
    }
  }, [extra]);

  return (
    <video autoPlay muted style={{ position: 'absolute', top: 0, left: 0 }}>
      <source
        src={`${conf.cdn_url}/3d-objects/bg_v3.webm`}
        type="video/webm"
      />
    </video>
  );
};

const mapStateToProps = (state) => {
  const { extra } = state;
  return { extra };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    togglePlayingBoosterAnimation,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VideoScene));