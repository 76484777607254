import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';

import { getMarketPlaceStats } from '../../apollo/mergedQueries';

import PageTitle from '../../components/PageTitle/PageTitle';
import MarketplaceHome from '../../components/Marketplace/MarketplaceHome';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

const MarketplaceHomeContainer = () => {
  const { t } = useTranslation();
  const [timeframe, setTimeframe] = useState(1);
  const [rarity, setRarity] = useState(null);
  const perPage = 25;

  const { data, loading } = useQuery(getMarketPlaceStats, {
    variables: { timeframe, pagination: { page: 1, perPage }, rarity },
    fetchPolicy: useCacheWithExpiration('getMarketPlaceStats'),
  });

  const marketPlaceStats = data && data.marketPlaceStatsByTimeFrame;
  const listedItems = data && data.marketPlaceOffersListed;
  const soldItems = data && data.marketPlaceOffersSold;
  const myViewer = data && data.getMyViewer;

  return (
    <>
      <PageTitle title={t('marketplace')} />
      <MarketplaceHome
        timeframe={timeframe}
        setTimeframe={setTimeframe}
        marketPlaceStats={marketPlaceStats}
        loading={loading}
        rarity={rarity}
        setRarity={setRarity}
        listedItems={listedItems}
        soldItems={soldItems}
        myViewer={myViewer}
      />
    </>
  );
};

export default MarketplaceHomeContainer;