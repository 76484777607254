import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import Colors from '../../theme/Colors';
import AnimationButton from '../Button/AnimationButton';
import Helpers from '../../theme/Helpers';
import Symplr from '../AdSense/Symplr';
import SeasonCountdown from '../Countdown/SeasonCountdown';

import conf from '../../conf.json';

const useStyles = makeStyles((theme) => ({
  container: {
    background: 'linear-gradient(20deg, #0B192D00 0%, #0B192D00 0%, #0A182C00 23%, #0A182C00 62%, #0A182B94 79%, #0A182BE8 100%)',
    borderRadius: '6px 6px 0px 0px',
    boxSizing: 'border-box',
    padding: 32,
    position: 'relative',

    [theme.breakpoints.down('md')]: {
      padding: 16,
    },
  },
  badge: {
    ...Helpers.fillRowCenter,
    padding: '10px 20px',
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 5,
  },

  metaDataWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'start',

    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
    },
  },

  countdownWrapper: {
    width: '100%',
    marginLeft: 0,

    [theme.breakpoints.up('md')]: {
      width: 'auto',
      marginLeft: 'auto',
    },
  },

  seasonInfo: {
    ...Helpers.fillRowCross,

    [theme.breakpoints.down('md')]: {
      ...Helpers.fillCol,
    },
  }
}));

const HubSeasonInfo = (props) => {
  const classes = useStyles();
  const { t, seasonData, myViewer, isPremiumActive } = props;

  return (
    <div className={classes.container}>
      <div style={{
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        borderRadius: '6px 6px 0px 0px',
        backgroundImage: seasonData && `url("${seasonData.bg_image}")`,
        backgroundSize: 'cover',
        zIndex: -1,
        backgroundPosition: 'center',
        opacity: 0.8,
      }} />

      {(conf.ad_area.hub_2 && myViewer && !isPremiumActive) &&
      <div
        style={{
          position: 'absolute',
          right: 58,
          bottom: 13,
          zIndex: 0,
        }}
      >
        <Symplr id="streamheroes.gg_mr_1" />
      </div>
      }

      <div>
        <div className={classes.seasonInfo}>
          <div>
            <div style={{ fontSize: 36, fontWeight: 600 }}>
              {t('season_x', { season: seasonData && seasonData.season })}
            </div>
            <div style={{ color: Colors.halfWhite, fontSize: 20, marginBottom: 22 }}>
              {seasonData && seasonData.name}
            </div>
          </div>

          {/*<div style={{ paddingLeft: 20, zIndex: 1, position: 'relative' }}>
            <div style={{
              background: 'rgba(255, 255, 255, 0.04)',
              padding: '20px 16px',
              borderRadius: 6,
              ...Helpers.fillRowCenter,
              ...Helpers.mainSpaceBetween,
            }}>
              <div style={{ marginRight: 43, marginBottom: 2 }}>
                {t('season_ends_in')}:
              </div>

              <div>
                <SeasonCountdown t={t} seasonData={seasonData} />
              </div>
            </div>
          </div>*/}
        </div>
        <div style={{ marginBottom: 32, maxWidth: 598, lineHeight: 1.6 }}>
          {seasonData && seasonData.description}
        </div>
        <AnimationButton href="/ranking/streamer" status="neonRed">
          {t('view_leaderboards')}
        </AnimationButton>
      </div>
    </div>
  );
};

export default withRouter(HubSeasonInfo);