import React from 'react';
import Slider from 'react-slick';
import zeropad from 'zeropad';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import 'slick-carousel/slick/slick.css';

import TopBG from '../TopViewHeader/TopBG';
import Colors from '../../theme/Colors';
import CyberMeetsFirePrices from './CyberMeetsFirePrices';

import conf from '../../conf.json';

const useStyles = makeStyles((theme) => ({
  slider: {
    '& .slick-dots': {
      position: 'relative',

      '& li': {
        width: 'auto',
        height: 'auto',
        opacity: '40%',
        filter: 'grayscale(1) brightness(2.5)',
        transition: 'all 300ms ease',

        '& img': {
          borderRadius: 4,
        },

        '& p': {
          margin: '8px 0 0 0',
        },

        '&.slick-active,&:hover': {
          opacity: '100%',
          filter: 'none',
        },

        '&.slick-active:before': {
          content: '""',
          width: '80%',
          height: 3,
          borderRadius: 5,
          backgroundColor: 'white',
          margin: '0 auto 6px auto',
          display: 'block',
        },
      },
    },
  },
  ctaButton: {
    backgroundColor: '#E12138',
    display: 'inline-block',
    borderRadius: 6,
    padding: '18px 30px',
    border: 0,
    fontWeight: 500,
    fontSize: 16,
    color: 'white',
    transition: 'all 300ms ease',

    '&:hover': {
      backgroundColor: '#FF2F5E',
      boxShadow: '0px 0px 35px #FF2F5E',
    },
  },
  priceItem: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    width: '100%',
    position: 'relative',
  },
  charactersGrid: {
    display: 'grid',
    textAlign: 'left',
    gap: '52px 23px',
    gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
    marginBottom: 59,

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    },

    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    },

    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    },

    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: 'repeat(5, minmax(0, 1fr))',
    },
  },
}));

const CyberMeetsFire = (props) => {
  const classes = useStyles();

  const { t } = props;

  const viewerSlideTexts = t('landing_pages.cyber_meets_fire.viewers.slides', { returnObjects: true });

  const viewerSlides = [
    {
      image: `${conf.cdn_url}/assets/seasons/landingpage/SH_Season0_LP_ViewerBanner_01_Collection_1440x700.jpg`,
      ...viewerSlideTexts[0],
    },
    {
      image: `${conf.cdn_url}/assets/seasons/landingpage/SH_Season0_LP_ViewerBanner_02_Badges_1440x700.jpg`,
      ...viewerSlideTexts[1],
    },
    {
      image: `${conf.cdn_url}/assets/seasons/landingpage/SH_Season0_LP_ViewerBanner_03_ProfileFrames_1440x700.jpg`,
      ...viewerSlideTexts[2],
    },
    {
      image: `${conf.cdn_url}/assets/seasons/landingpage/SH_Season0_LP_ViewerBanner_04_Leaderboard_1440x700.jpg`,
      ...viewerSlideTexts[3],
    },
    {
      image: `${conf.cdn_url}/assets/seasons/landingpage/SH_Season0_LP_ViewerBanner_05_QualityLayers_1440x700.jpg`,
      ...viewerSlideTexts[4],
    },
  ];

  const streamerSlideTexts = t('landing_pages.cyber_meets_fire.streamers.slides', { returnObjects: true });

  const streamerSlides = [
    {
      image: `${conf.cdn_url}/assets/seasons/landingpage/SH_Season0_LP_StreamerBanner_01_Frames_1440x700.jpg`,
      ...streamerSlideTexts[0],
    },
    {
      image: `${conf.cdn_url}/assets/seasons/landingpage/SH_Season0_LP_StreamerBanner_02_Effects_1440x700.jpg`,
      ...streamerSlideTexts[1],
    },
    {
      image: `${conf.cdn_url}/assets/seasons/landingpage/SH_Season0_LP_StreamerBanner_03_BoosterPacks_1440x700.jpg`,
      ...streamerSlideTexts[2],
    },
  ];

  const sliderSettings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    className: classes.slider,
  };

  return (
    <TopBG
      style={{
        padding: '30px 0 20px',
        backgroundSize: 'auto',
        color: Colors.white,
      }}
    >
      <section style={{
        position: 'relative',
        overflow: 'hidden',
        textAlign: 'center',
        padding: '178px 0',
        marginBottom: 100,
        borderRadius: 6,
      }}>
        <video
          src={`${conf.cdn_url}/assets/seasons/landingpage/SH-Season0-Banner-v3.webm`}
          autoPlay
          loop
          muted
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: -1,
            objectFit: 'cover',
          }}
        />

        <p style={{
          fontSize: 18,
          color: 'rgba(255, 255, 255, 0.5)',
          fontWeight: 500,
          margin: '0 0 10px 0',
        }}>
          {t('landing_pages.cyber_meets_fire.hero.sub_title')}
        </p>

        <h1 style={{
          fontFamily: 'Bebas Neue',
          fontSize: 100,
          fontWeight: 'bold',
          margin: 0,
          lineHeight: 1,
        }}>
          {t('landing_pages.cyber_meets_fire.hero.title')}
        </h1>

        <p style={{
          fontSize: 20,
          color: 'rgba(255, 255, 255, 0.5)',
          margin: 0,
          textTransform: 'uppercase',
        }}>
          {t('landing_pages.cyber_meets_fire.hero.time')}
        </p>
      </section>

      <section style={{ marginBottom: 100 }}>
        <h2 style={{ textAlign: 'center', fontSize: 36, fontWeight: 600, margin: '0 0 26px 0' }}>
          {t('landing_pages.cyber_meets_fire.viewers.title')}
        </h2>

        <Slider
          {...sliderSettings}
          customPaging={function (index) {
            return (
              <div>
                <img src={viewerSlides[index].image} alt="Sliding graphic" style={{ width: 152 }} />

                <p>{viewerSlides[index].title}</p>
              </div>
            );
          }}
        >
          {viewerSlides.map(slide => (
            <div>
              <div style={{ position: 'relative', borderRadius: 6, overflow: 'hidden' }}>
                <div style={{ position: 'absolute', top: 19, left: 32 }}>
                  <h3 style={{ fontFamily: 'Bebas Neue', fontSize: 42, margin: 0 }}>
                    {slide.title}
                  </h3>

                  <p style={{ fontWeight: 600, margin: 0 }}>{slide.text}</p>
                </div>

                <img src={slide.image} alt={slide.title} />
              </div>
            </div>
          ))}
        </Slider>
      </section>

      <section>
        <h2 style={{ textAlign: 'center', fontSize: 36, fontWeight: 600, margin: '0 0 26px 0' }}>
          {t('landing_pages.cyber_meets_fire.streamers.title')}
        </h2>

        <Slider
          {...sliderSettings}
          customPaging={function (index) {
            return (
              <div>
                <img src={streamerSlides[index].image} alt="Sliding graphic"
                     style={{ width: 152 }} />

                <p>{streamerSlides[index].title}</p>
              </div>
            );
          }}
        >
          {streamerSlides.map(slide => (
            <div>
              <div style={{ position: 'relative', borderRadius: 6, overflow: 'hidden' }}>
                <div style={{ position: 'absolute', top: 19, left: 32 }}>
                  <h3 style={{ fontFamily: 'Bebas Neue', fontSize: 42, margin: 0 }}>
                    {slide.title}
                  </h3>

                  <p style={{ fontWeight: 600, margin: 0 }}>{slide.text}</p>
                </div>

                <img src={slide.image} alt={slide.title} />
              </div>
            </div>
          ))}
        </Slider>
      </section>

      <CyberMeetsFirePrices t={t} />

      <section style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
        margin: '100px 0',
        alignItems: 'center',
        gap: 50,
      }}>
        <div>
          <h2 style={{ fontSize: 36, fontWeight: 600, lineHeight: 1.2, margin: 0 }}>
            {t('landing_pages.cyber_meets_fire.leaderboard.title')}
          </h2>

          <p
            style={{ fontSize: 24, fontWeight: 500, color: 'rgba(255, 255, 255, 0.5)', margin: 0 }}>
            {t('landing_pages.cyber_meets_fire.leaderboard.sub_title')}
          </p>

          <p style={{ fontWeight: 600, margin: '23px 0 29px 0' }}>
            {t('landing_pages.cyber_meets_fire.leaderboard.text')}
          </p>

          <Link
            to={'/ranking/streamer'}
            className={classes.ctaButton}
          >
            {t('landing_pages.cyber_meets_fire.leaderboard.cta')}
          </Link>
        </div>

        <div>
          <img
            src={`${conf.cdn_url}/assets/seasons/landingpage/SH_Season0_LP_Leaderboard_800x800.png`}
            alt={t('landing_pages.cyber_meets_fire.leaderboard.title')}
          />
        </div>
      </section>

      <section
        style={{
          textAlign: 'center',
          borderRadius: 6,
          padding: '120px 16px',
          marginBottom: 82,
          backgroundImage: `url(${conf.cdn_url}/assets/seasons/landingpage/SH_Season0_LP_FooterBanner_1440x811.jpg)`,
          backgroundSize: 'cover',
        }}
      >
        <p style={{
          fontSize: 20,
          color: 'rgba(255, 255, 255, 0.5)',
          textTransform: 'uppercase',
          margin: '20px 0',
        }}>
          {t('landing_pages.cyber_meets_fire.story.season_number')}
        </p>

        <h2 style={{
          fontSize: 66,
          fontWeight: 800,
          textTransform: 'uppercase',
          lineHeight: 1.2,
          margin: '0 0 22px 0',
        }}>
          {t('landing_pages.cyber_meets_fire.story.title')}
        </h2>

        <p
          style={{
            maxWidth: 1000,
            margin: '0 auto 25px auto',
          }}
          dangerouslySetInnerHTML={{ __html: t('landing_pages.cyber_meets_fire.story.text') }}
        />

        <Link
          to={'/ranking/streamer'}
          className={classes.ctaButton}
        >
          {t('landing_pages.cyber_meets_fire.story.cta')}
        </Link>
      </section>

      <section style={{ textAlign: 'center' }}>
        <h2 style={{ fontWeight: 600, fontSize: 36 }}>
          {t('landing_pages.cyber_meets_fire.characters.title')}
        </h2>

        <div className={classes.charactersGrid}>
          {t('landing_pages.cyber_meets_fire.characters.characters', { returnObjects: true })
          .map((character, index) => {
            return (
              <div key={character.name}>
                <img
                  src={`${conf.cdn_url}/assets/seasons/landingpage/character-${zeropad(index + 1)}.png?width=300`}
                  alt={character.name}
                  style={{
                    borderRadius: 6,
                    overflow: 'hidden',
                    backgroundColor: 'rgba(255, 255, 255, 0.06)',
                    width: '100%',
                    marginBottom: 17,
                  }}
                />

                <h3 style={{
                  fontFamily: 'Bebas Neue',
                  fontWeight: 'bold',
                  fontSize: 30,
                  marginTop: 0,
                  marginBottom: 0,
                  lineHeight: 1.2,
                }}>{character.name}</h3>

                <p style={{ margin: 0 }}>{character.text}</p>
              </div>
            );
          })}
        </div>

        <Link
          to={'/item-store/booster'}
          className={classes.ctaButton}
        >
          {t('landing_pages.cyber_meets_fire.characters.cta')}
        </Link>
      </section>
    </TopBG>
  );
};

export default CyberMeetsFire;