import React from 'react';
import { Query } from 'react-apollo';

import { getPolls } from '../../apollo/queries';

import Loading from '../../components/Loading/Loading';
import Polls from '../../components/Polls/Polls';

const PollsContainer = () => {
  return (
    <Query query={getPolls}>
      {({ data, error, loading }) => {
        if (loading) return <Loading />;
        if (error) return error.message;

        const polls = (data) && data.getPolls;

        return (
          <Polls polls={polls} />
        );
      }}
    </Query>
  );
};

export default PollsContainer;