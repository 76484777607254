import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import {
  makeMeAStreamhero as makeMeAStreamHeroMutation,
  testCardsSocket as testCardsSocketMutation,
  updateMyData as updateMyDataMutation,
  updateStreamerStreamConfig as updateStreamerStreamConfigMutation,
} from '../../apollo/mutations';
import { getMyStreamer, getMyViewer } from '../../apollo/queries';

import { getLastCharOfSourceUrl } from '../../utils/helper';
import Colors from '../../theme/Colors';
import OnBoardingSocialMedia from './OnBoardingSocialMedia';
import OnBoardingStreamAlert from './OnBoardingStreamAlert';
import OnBoardingStart from './OnBoardingStart';
import OnBoardingChatBot from './OnBoardingChatBot';
import OnBoardingStepProgress from './OnBoardingStepProgress';
import OnBoardingPanel from './OnBoardingPanel';
import OnBoardingEnd from './OnBoardingEnd';
import OnBoardingCardBuilder from './OnBoardingCardBuilder';

const conf = require('../../conf');

const useStyles = makeStyles(() => ({
  header: {
    textTransform: 'none',
    marginBottom: 10,
  },
  text: {
    color: Colors.onBoardingTextColor,
    marginBottom: 20,
  },
  cancel: {
    color: Colors.onBoardingTextColor,
    textDecoration: 'underline',
    fontSize: 12,
    cursor: 'pointer',
    '&:hover': {
      color: Colors.white,
    },
  },
  chip: {
    border: '2px solid #343a42',
    borderRadius: 50,
    backgroundColor: 'rgba(0,0,0,0.1)',
    fontSize: 14,
    marginRight: 20,
    padding: '10px 15px',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '#5e5e5e',
      backgroundColor: 'rgba(0,0,0,0.2)',
    },
  },
  onBoardingTextField: {
    backgroundColor: '#fcfdff',
    padding: '5px 20px',
    borderRadius: 50,
    minWidth: 320,
    '& input': {
      color: Colors.subTextColor,
      fontSize: 14,
    },
  },
}));

const OnBoarding = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [step, setStep] = React.useState(0);
  const { viewer, streamer, maxLevelCard, cards } = props;
  let stream_config;

  if (streamer) {
    stream_config = streamer.stream_config;
  } else {
    stream_config = {
      min_level_alerts: null,
      min_level_bot: null,
      sound: null,
      animation: null,
      custom_msg: null,
      custom_msg_twitch: null,
      should_whisper: null,
    };
  }

  const [streamConfigState, setStreamConfigState] = useState({
    showMinLevelAlerts: !!(stream_config && stream_config.min_level_alerts),
    showMinLevelBot: !!(stream_config && stream_config.min_level_bot),
    showSound: !!(stream_config && stream_config.sound),
    showAnimation: !!(stream_config && stream_config.animation),
    minLevel: stream_config && stream_config.min_level_alerts ? stream_config.min_level_alerts : 2,
    minLevelBot: stream_config && stream_config.min_level_bot ? stream_config.min_level_bot : 2,
    customMsg: stream_config && stream_config.custom_msg ? stream_config.custom_msg : '',
    customMsgTwitch: stream_config && stream_config.custom_msg_twitch ? stream_config.custom_msg_twitch : '',
    sound: stream_config && stream_config.sound ? getLastCharOfSourceUrl(stream_config.sound) : 1,
    animation: stream_config && stream_config.animation ? getLastCharOfSourceUrl(stream_config.animation) : 1,
    shouldWhisper: stream_config && stream_config.should_whisper ? stream_config.should_whisper : false,
  });

  const [updateMyData] = useMutation(updateMyDataMutation, {
    refetchQueries: () => [{ query: getMyViewer }],
  });

  const [makeMeAStreamHero] = useMutation(makeMeAStreamHeroMutation, {
    refetchQueries: () => [{ query: getMyStreamer }],
  });

  const [updateStreamerStreamConfig] = useMutation(updateStreamerStreamConfigMutation, {
    refetchQueries: () => [{ query: getMyStreamer }],
  });

  const [testCardsSocket] = useMutation(testCardsSocketMutation, {
    onCompleted: (data) => {
      if (data.testCardsSocket) {
        enqueueSnackbar(t('test_is_started_successfully'), { variant: 'success' });
      } else {
        enqueueSnackbar(t('test_start_error'), { variant: 'error' });
      }
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
  });

  const saveStreamConfig = () => {
    const { minLevel, showMinLevelAlerts, customMsg, customMsgTwitch, showSound, showAnimation, sound, animation, showMinLevelBot, minLevelBot, shouldWhisper } = streamConfigState;

    const soundUrl = showSound ? `${conf.cdn_url}/assets/dashboard/profile/sounds/alert-sound-${sound}.mp3` : null;
    const animationUrl = showAnimation ? `${conf.cdn_url}/assets/dashboard/profile/animations/streamheroes-twitchalert${animation}.webm` : null;

    const streamConfigObj = {
      sound: soundUrl,
      animation: animationUrl,
      custom_msg: customMsg !== '' ? customMsg : null,
      custom_msg_twitch: customMsgTwitch !== '' ? customMsgTwitch : null,
      min_level_alerts: showMinLevelAlerts ? minLevel : null,
      min_level_bot: showMinLevelBot ? minLevelBot : null,
      should_whisper: shouldWhisper ? shouldWhisper : false,
    };

    updateStreamerStreamConfig({ variables: { stream_config: streamConfigObj } });

    nextStep();
  };

  const handleStreamConfigChange = (event, name, newValue) => {
    if (name === 'showMinLevelAlerts' && newValue === false) {
      setStreamConfigState({
        ...streamConfigState,
        showMinLevelAlerts: false,
        showSound: false,
        showAnimation: false,
      });
    } else {
      setStreamConfigState({ ...streamConfigState, [name]: newValue });
    }
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  return (
    <>
      {(step === 0) &&
      <OnBoardingStart
        viewer={viewer}
        nextStep={nextStep}
        updateMyData={updateMyData}
        classes={classes}
        makeMeAStreamHero={makeMeAStreamHero}
      />
      }

      {(step > 0 && step < 6) &&
      <OnBoardingStepProgress stepCount={step} />
      }

      {(step === 1) &&
      <OnBoardingCardBuilder
        viewer={viewer}
        cards={cards}
        classes={classes}
        nextStep={nextStep}
      />
      }

      {(step === 2) &&
      <OnBoardingSocialMedia
        viewer={viewer}
        updateMyData={updateMyData}
        classes={classes}
        nextStep={nextStep}
      />
      }
      {(step === 3) &&
      <OnBoardingStreamAlert
        streamer={streamer}
        classes={classes}
        cards={cards}
        nextStep={nextStep}
        maxLevelCard={maxLevelCard}
        testCardsSocket={testCardsSocket}
        state={streamConfigState}
        handleChange={handleStreamConfigChange}
        save={saveStreamConfig}
      />
      }
      {(step === 4) &&
      <OnBoardingChatBot
        classes={classes}
        nextStep={nextStep}
        maxLevelCard={maxLevelCard}
        testCardsSocket={testCardsSocket}
        state={streamConfigState}
        handleChange={handleStreamConfigChange}
        save={saveStreamConfig}
      />
      }
      {(step === 5) &&
      <OnBoardingPanel streamer={streamer} classes={classes} nextStep={nextStep} />
      }
      {(step === 6) &&
      <OnBoardingEnd classes={classes} updateMyData={updateMyData} />
      }
    </>
  );
};

export default OnBoarding;