import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import ShinyButton from '../Button/ShinyButton';
import DropdownMenu from './DropdownMenu';

const DropdownMenuWithButton = (props) => {
  const { tabs, startTab, style, onClick } = props;
  const [isCardMenuOpen, setIsCardMenuOpen] = useState(false);
  const [tab, setTab] = useState(startTab);

  useEffect(() => {
    if (startTab !== tab)
      setTab(startTab);
  }, [startTab]);

  const activeTab = tabs.find(findTabs => findTabs.key === tab);

  return (
    <div style={{ position: 'relative' }}>
      <ShinyButton onClick={() => setIsCardMenuOpen(!isCardMenuOpen)}
                   style={{ ...style, paddingRight: 20 }}>
        {activeTab.value}
        <FontAwesomeIcon
          icon={isCardMenuOpen ? faChevronUp : faChevronDown}
          size="sm"
          style={{ position: 'absolute', right: 7, top: 25 }}
        />
      </ShinyButton>
      {isCardMenuOpen &&
      <DropdownMenu
        rows={tabs}
        title={activeTab.value}
        onClickAway={() => setIsCardMenuOpen(!isCardMenuOpen)}
        onClick={(key) => {
          setTab(key);
          setIsCardMenuOpen(!isCardMenuOpen);
          onClick(key);
        }}
      />
      }
    </div>
  );
};

DropdownMenuWithButton.propTypes = {
  style: PropTypes.object,
};

DropdownMenuWithButton.defaultProps = {
  style: {},
};

export default DropdownMenuWithButton;