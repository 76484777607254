import React from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { packIns } from '../../apollo/queries';

import ShopAccessories from '../../components/Layout/ShopAccessories';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

const ShopAccessoriesContainer = (props) => {
  const { pathname } = props.location;

  const isCardShopRoute = pathname.includes('/card-shop') && !pathname.includes('/card-shop-pay');

  const { data, loading } = useQuery(packIns, {
    fetchPolicy: useCacheWithExpiration('packIns'),
    skip: !isCardShopRoute,
  });

  if (!isCardShopRoute || loading)
    return null;

  const accessories = data && data.packIns;

  return (
    <ShopAccessories accessories={accessories} />
  );
};

export default withRouter(ShopAccessoriesContainer);