import React from 'react';
import { OrbitControls } from '@react-three/drei';

import CardObject from './CardObject';

import conf from '../../conf.json';

const Scene = (props) => {
  const {
    card,
    isTestMode,
    isRotating,
    isOblique,
    noControls,
    noWhiteCard,
    withLight,
    noAbrasion,
    noAnimation,
    loopAnimation,
  } = props;

  return (
    <>
      <CardObject
        frontImage={card ? (card.physical_card_layer_image ? card.physical_card_layer_image : card.image).replace(conf.cdn_url, '') : '/cards/partnered/print/60acf31e3074350008daf36c.jpg'}
        backImage={card && card.backImage ? card.backImage.replace(conf.cdn_url, '') : '/cards/back.png'}
        frontEffect={card && card.frontEffect ?
          `https://s3.eu-central-1.wasabisys.com/streamheroes-card-manager/${card.frontEffect}`
          :
          `${conf.cdn_url}/cards/card_effect.png`
        }
        backEffect={card && card.backEffect ?
          `https://s3.eu-central-1.wasabisys.com/streamheroes-card-manager/${card.backEffect}`
          :
          `${conf.cdn_url}/cards/card_effect.png`
        }
        isTestMode={isTestMode}
        isRotating={isRotating}
        isOblique={isOblique}
        noWhiteCard={noWhiteCard}
        noAbrasion={noAbrasion}
        noAnimation={noAnimation}
        loopAnimation={loopAnimation}
        noEffectLayer={(card && card.noEffectLayer) && card.noEffectLayer}
      />
      {withLight && <ambientLight />}
      {!noControls &&
      <OrbitControls minDistance={3} maxDistance={8} makeDefault />
      }
    </>
  );
};

export default Scene;