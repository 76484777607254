export const CHANGE_TOKEN = 'CHANGE_TOKEN';
export const CHANGE_TOKEN_AND_REDIRECT = 'CHANGE_TOKEN_AND_REDIRECT';
export const ADD_ACTIVITY_FEED_LIST = 'ADD_ACTIVITY_FEED_LIST';
export const ADD_ACTIVITY_FEED_ITEM = 'ADD_ACTIVITY_FEED_ITEM';
export const RESET_CARD_TOOL_LAST_STEPS = 'RESET_CARD_TOOL_LAST_STEPS';
export const ADD_CARD_TOOL_LAST_STEP = 'ADD_CARD_TOOL_LAST_STEP';
export const REMOVE_CARD_TOOL_LAST_STEP = 'REMOVE_CARD_TOOL_LAST_STEP';
export const CHANGE_HAS_ADMIN_RIGHTS = 'CHANGE_HAS_ADMIN_RIGHTS';
export const HANDLE_VALUE_CHANGE = 'HANDLE_VALUE_CHANGE';
export const HANDLE_OBJECT_VALUE_CHANGE = 'HANDLE_OBJECT_VALUE_CHANGE';
export const ADD_POPUP_EVENT = 'ADD_POPUP_EVENT';
export const REMOVE_POPUP_EVENT = 'REMOVE_POPUP_EVENT';
export const SET_LAST_LOGIN = 'SET_LAST_LOGIN';
export const CHANGE_CARD_VERIFICATION = 'CHANGE_CARD_VERIFICATION';
export const CHANGE_SWIPE_VERIFICATION = 'CHANGE_SWIPE_VERIFICATION';
export const SET_CLIENT_SECRET = 'SET_CLIENT_SECRET';
export const UPDATE_CARD_IN_CART = 'UPDATE_CARD_IN_CART';
export const SET_ORDER_CART = 'SET_ORDER_CART';
export const INCREASE_COUNT = 'INCREASE_COUNT';
export const SET_PRODUCTS_CART = 'SET_PRODUCTS_CART';
export const UPDATE_AMOUNT_IN_PRODUCT = 'UPDATE_AMOUNT_IN_PRODUCT';
export const RESET_CARDS_IN_SHOP = 'RESET_CARDS_IN_SHOP';
export const UPDATE_AMOUNT_IN_STATIC = 'UPDATE_AMOUNT_IN_STATIC';
export const UPDATE_AMOUNT_IN_ALBUM = 'UPDATE_AMOUNT_IN_ALBUM';
export const ADD_HERO_MAKER_ITEM = 'ADD_HERO_MAKER_ITEM';
export const UPDATE_HERO_MAKER_COLORED_IMAGES = 'UPDATE_HERO_MAKER_COLORED_IMAGES';
export const REMOVE_HERO_MAKER_COLORED_IMAGE = 'REMOVE_HERO_MAKER_COLORED_IMAGE';
export const SET_HERO_MAKER_CURRENT_IMAGE = 'SET_HERO_MAKER_CURRENT_IMAGE';
export const SET_HERO_MAKER_ASSETS = 'SET_HERO_MAKER_ASSETS';
export const SET_HERO_MAKER_ITEMS = 'SET_HERO_MAKER_ITEMS';
export const RESET_HERO_MAKER = 'RESET_HERO_MAKER';
export const SET_BODY_COLOR_HM = 'SET_BODY_COLOR_HM';
export const SET_LIGHT_DARK_MODE_HM = 'SET_LIGHT_DARK_MODE_HM';
export const SET_TYPE_ASSETS_HM = 'SET_TYPE_ASSETS_HM';
export const TOGGLE_HIDE_PAGE_SCROLLBAR = 'TOGGLE_HIDE_PAGE_SCROLLBAR';
export const TOGGLE_NEW_CARD_IN_SHOP = 'TOGGLE_NEW_CARD_IN_SHOP';
export const TOGGLE_PLAYING_BOOSTER_ANIMATION = 'TOGGLE_PLAYING_BOOSTER_ANIMATION';
export const SET_BOOSTER_DATA = 'SET_BOOSTER_DATA';
export const SET_ACTIVE_CARD = 'SET_ACTIVE_CARD';
export const SET_ALL_CARDS = 'SET_ALL_CARDS';
export const SET_BOOSTER_ID = 'SET_BOOSTER_ID';
export const SET_BOOSTER_ALL_DATA = 'SET_BOOSTER_ALL_DATA';
export const TOGGLE_EXPANDED_MENU = 'TOGGLE_EXPANDED_MENU';
export const TOGGLE_RIGHT_SIDEBAR = 'TOGGLE_RIGHT_SIDEBAR';
export const TOGGLE_SHOP_CART = 'TOGGLE_SHOP_CART';
export const TOGGLE_SEARCH = 'TOGGLE_SEARCH';
export const TOGGLE_NOTIFICATIONS = 'TOGGLE_NOTIFICATIONS';
export const TOGGLE_COLLECTING_CHECKER = 'TOGGLE_COLLECTING_CHECKER';
export const RESET_BOOSTER_DATA = 'RESET_BOOSTER_DATA';
export const SET_ALERT_TOOL_TYPE_DATA = 'SET_ALERT_TOOL_TYPE_DATA';
export const SET_ALERT_TOOL_ALL_DATA = 'SET_ALERT_TOOL_ALL_DATA';
export const RESET_ALERT_TOOL_DATA = 'RESET_ALERT_TOOL_DATA';
export const SET_BOOSTER_ACTION_DATA = 'SET_BOOSTER_ACTION_DATA';
export const RESET_BOOSTER_ACTION_DATA = 'RESET_BOOSTER_ACTION_DATA';
export const SET_CARD_ID_SHOWN = 'SET_CARD_ID_SHOWN';
export const SET_COUPON_CODE = 'SET_COUPON_CODE';
export const SET_INVENTORY_GROUP = 'SET_INVENTORY_GROUP';
export const TOGGLE_STORAGE_BOX_SELECTION_CARD = 'TOGGLE_STORAGE_BOX_SELECTION_CARD';
export const SET_STORAGE_BOX_ID = 'SET_STORAGE_BOX_ID';
export const SET_STORAGE_BOX_SELECTION_MODE = 'SET_STORAGE_BOX_SELECTION_MODE';
export const RESET_STORAGE_BOX_DATA = 'RESET_STORAGE_BOX_DATA';
export const SET_COLLECTORS_VIEW_BOOSTER = 'SET_COLLECTORS_VIEW_BOOSTER';
export const SET_COLLECTORS_VIEW_CARD = 'SET_COLLECTORS_VIEW_CARD';
export const SET_COLLECTORS_VIEW_MARKETPLACE = 'SET_COLLECTORS_VIEW_MARKETPLACE';
export const SET_COLLECTORS_VIEW_ALL_CARDS = 'SET_COLLECTORS_VIEW_ALL_CARDS';
export const SET_COLLECTORS_VIEW_SHOW_CARD_INFO = 'SET_COLLECTORS_VIEW_SHOW_CARD_INFO';
export const SET_COLLECTORS_VIEW_SELECT_SELL_INFO = 'SET_COLLECTORS_VIEW_SELECT_SELL_INFO';
export const RESET_COLLECTORS_VIEW_DATA = 'RESET_COLLECTORS_VIEW_DATA';
export const SET_MARKETPLACE_ALL_DATA = 'SET_MARKETPLACE_ALL_DATA';
export const RESET_MARKETPLACE_DATA = 'RESET_MARKETPLACE_DATA';
export const SET_BOOSTER_LAST_REDEEM = 'SET_BOOSTER_LAST_REDEEM';