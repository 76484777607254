import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import { collectReferralReward as collectReferralRewardMutation } from '../../apollo/mutations';
import { getReferrals } from '../../apollo/mergedQueries';

import Helpers from '../../theme/Helpers';
import AnimationButton from '../Button/AnimationButton';

const useStyles = makeStyles(() => ({
  avatar: {
    border: 'none',
    width: 42,
    height: 42,
    marginRight: 12,
  },
}));

const ReferAFriendLine = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { referral } = props;

  const [collectReferralReward, { loading: collectLoading }] = useMutation(collectReferralRewardMutation, {
    onCompleted: () => {
      enqueueSnackbar(t('success'), { variant: 'success' });
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [
      { query: getReferrals },
    ],
  });

  const collectReward = () => {
    collectReferralReward({ variables: { id: referral._id } });
  };

  const isClaimed = referral.reward_one_collected && referral.reward_two_collected;
  const isRewardCollectible = (referral.reward_one_fulfilled && !referral.reward_one_collected) || (referral.reward_two_fulfilled && !referral.reward_two_collected);

  return (
    <tr>
      <td>{moment.unix(referral.created_at / 1000)
      .format('LL')}</td>
      <td style={Helpers.fillRowCross}>
        <Avatar
          className={classes.avatar}
          alt="Avatar"
          src={referral.viewer.profile_image_url}
        />
        {referral.viewer.display_name}
      </td>
      <td align="center">
        <i
          className={`fa-solid fa-circle-${referral.is_at_least_one_month_twitch_user ? 'check' : 'xmark'}`}
          style={{
            fontSize: 18,
            color: referral.is_at_least_one_month_twitch_user ? '#15CC90' : '#FF2D57',
          }}
        />
      </td>
      <td align="center">
        <i
          className={`fa-solid fa-circle-${referral.reward_one_fulfilled ? 'check' : 'xmark'}`}
          style={{
            fontSize: 18,
            color: referral.reward_one_fulfilled ? '#15CC90' : '#FF2D57',
            opacity: referral.reward_one_collected ? 0.2 : 1,
          }}
        />
      </td>
      <td align="center">
        <i
          className={`fa-solid fa-circle-${referral.reward_two_fulfilled ? 'check' : 'xmark'}`}
          style={{
            fontSize: 18,
            color: referral.reward_two_fulfilled ? '#15CC90' : '#FF2D57',
            opacity: referral.reward_two_collected ? 0.2 : 1,
          }}
        />
      </td>
      <td align="center">
        <AnimationButton
          status="neonRed"
          style={{ fontSize: 14, fontWeight: 600, padding: 8, width: 180 }}
          onClick={collectReward}
          disabled={isClaimed || !isRewardCollectible || collectLoading}
        >
          <i
            className={`fa-${collectLoading ? 'light' : 'regular'} fa-${collectLoading ? 'loader fa-spin' : (isClaimed ? 'circle-check' : 'circle-arrow-up')}`}
            style={{ fontSize: 16, marginRight: 8 }} />
          {!collectLoading && t(isClaimed ? 'claimed' : 'claim_reward')}
        </AnimationButton>
      </td>
    </tr>
  );
};

export default ReferAFriendLine;