import React, { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';

import {
  setStripeOnboardingFinished as setStripeOnboardingFinishedMutation,
} from '../../apollo/mutations';

import Helpers from '../../theme/Helpers';
import TopViewHeader from '../TopViewHeader/TopViewHeader';
import { useQueryParams } from '../../utils/helper';

const useStyles = makeStyles(() => ({
  thankYouText: {
    '& span:first-child': {
      color: '#ffc726',
    },
    '& span:last-child': {
      color: '#9d56e2',
    },
  },
}));

const OrderThankYou = () => {
  const classes = useStyles();
  const [updateStripe, setUpdateStripe] = useState(false);
  const [isStripeAnswer, setIsStripeAnswer] = useState(false);
  const queryParams = useQueryParams();
  const { t } = useTranslation();

  const successful = queryParams.get('successfull');

  useEffect(() => {
    if (isStripeAnswer && !updateStripe && (successful && successful === 'true'))
      setStripeOnboardingFinished();
  }, [isStripeAnswer]);

  const [setStripeOnboardingFinished] = useMutation(setStripeOnboardingFinishedMutation, {
    onCompleted: () => {
      setUpdateStripe(true);
    },
    onError: () => {
      setUpdateStripe(true);
    },
  });

  if (successful !== null && !isStripeAnswer)
    setIsStripeAnswer(true);

  return (
    <TopViewHeader>
      <div style={{ ...Helpers.textCenter, paddingTop: 30, color: 'white' }}>
        {successful === 'true' &&
        <img
          src={process.env.PUBLIC_URL + '/custom/sh-thankyou.png'}
          alt="Thank you!"
        />
        }
        <Typography
          variant="h2"
          className={classes.thankYouText}
          dangerouslySetInnerHTML={{ __html: successful === 'true' ? t('stripe_connect_success') : t('stripe_connect_error') }}
        />
      </div>
    </TopViewHeader>
  );
};

export default OrderThankYou;