import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { isTabletOrMobileObj } from '../../utils/responsive';
import EmptyPopup from '../../components/Dialog/EmptyPopup';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import CharacterSelection from '../../components/HeroMaker/CharacterSelection';
import AppearanceSelection from '../../components/HeroMaker/AppearanceSelection';
import BodySelection from '../../components/HeroMaker/BodySelection';
import HeadSelection from '../../components/HeroMaker/HeadSelection';
import CheckHero from '../../components/HeroMaker/CheckHero';
import HeroGeneration from '../../components/HeroMaker/HeroGeneration';
import Loading from '../../components/Loading/Loading';

import conf from '../../conf';

const useStyles = makeStyles(() => ({
  tabArea: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    backgroundColor: '#010814',
    color: '#41537B',
    padding: '0 16px',
    fontSize: 18,
    fontFamily: 'Bebas Neue',
    margin: '-22px -22px 22px -22px',
    '& > div': {
      padding: 16,
      cursor: 'pointer',
      '&:hover': {
        color: Colors.white,
      },
    },
  },
  activeTab: {
    color: '#FBFA7F',
    textShadow: '0px 0px 12px #FF8900',
    borderBottom: '2px solid #B60B28',
  },
  doneTab: {
    color: '#00FF08',
    textShadow: '0px 0px 12px #00FF08',
    borderBottom: '2px solid #00FF08',
  },
}));

const HeroMakerController = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    handleClose,
    selectedHero,
    viewer,
    refetchHeroes,
    tab,
    setTab,
    character,
    setCharacter,
    body,
    setBody,
    head,
    setHead,
    serviceURL,
    heroMakerCategories,
    tabs,
    nextTab,
    backTab,
    loading,
    posesCount,
  } = props;

  const isTabletOrMobile = useMediaQuery(isTabletOrMobileObj);

  const activeIdx = tabs.findIndex(tabItem => tabItem.key === tab);
  const activeTab = activeIdx !== -1 && tabs[activeIdx];

  return (
    <EmptyPopup
      handleClose={handleClose}
      title={t('hero_maker')}
      isTabletOrMobile={isTabletOrMobile}
      bodyStyle={{
        background: '#01122B',
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15,
        padding: 22,
      }}
      style={{ width: isTabletOrMobile ? '90%' : 992 }}
      headerStyle={{
        backgroundImage: `url("${conf.cdn_url}/assets/template/heroMaker/hero-maker-card-editor-popup-banner.png")`,
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
      }}
    >
      <div className={classes.tabArea}>
        {tabs.map((item, index) => (
          <div
            key={item.key}
            onClick={() => index <= activeIdx && activeIdx !== (tabs.length - 1) ? setTab(item.key) : null}
            className={activeIdx === index ? classes.activeTab : (activeIdx > index ? classes.doneTab : '')}
          >
            {(index + 1)}. {t(item.value)}
          </div>
        ))}
      </div>

      {(loading && selectedHero) &&
      <div>
        <Loading />
      </div>
      }

      {(activeTab && activeTab.key === 'choose_style') &&
      <CharacterSelection
        character={character}
        setCharacter={setCharacter}
        footerValue={activeTab.value}
        nextTab={nextTab}
      />
      }
      {(activeTab && activeTab.key === 'choose_body') &&
      <BodySelection
        serviceURL={serviceURL}
        character={character}
        body={body}
        setBody={setBody}
        footerValue={activeTab.value}
        nextTab={nextTab}
        backTab={backTab}
      />
      }
      {(activeTab && activeTab.key === 'choose_head') &&
      <HeadSelection
        serviceURL={serviceURL}
        character={character}
        body={body}
        head={head}
        setHead={setHead}
        footerValue={activeTab.value}
        nextTab={nextTab}
        backTab={backTab}
      />
      }
      {(activeTab && activeTab.key === 'choose_appearance') &&
      <AppearanceSelection
        character={character}
        body={body}
        head={head}
        footerValue={activeTab.value}
        nextTab={nextTab}
        backTab={backTab}
        heroMakerCategories={heroMakerCategories}
        serviceURL={serviceURL}
      />
      }
      {(activeTab && activeTab.key === 'check_hero') &&
      <CheckHero
        character={character}
        viewer={viewer}
        body={body}
        head={head}
        footerValue={activeTab.value}
        nextTab={nextTab}
        backTab={backTab}
        heroMakerCategories={heroMakerCategories}
        serviceURL={serviceURL}
        selectedHero={selectedHero}
      />
      }
      {(activeTab && activeTab.key === 'generation') &&
      <HeroGeneration
        handleClose={handleClose}
        refetchHeroes={refetchHeroes}
        onlyOne={!!selectedHero}
        posesCount={posesCount}
      />
      }

    </EmptyPopup>
  );
};

export default HeroMakerController;