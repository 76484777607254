import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getProfileHeaderData } from '../../apollo/mergedQueries';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import ProfileHeaderLevel from './ProfileHeaderLevel';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import Loading from '../Loading/Loading';
import ProfileHeaderBadges from './ProfileHeaderBadges';
import Badge from '../Badge/Badge';
import CustomAvatar from '../Avatar/CustomAvatar';
import BorderSelectionContainer from '../../containers/Profile/BorderSelection';
import { isUserPremium } from '../../utils/helper';
import { toggleStreamerPrivacy as toggleStreamerPrivacyMutation } from '../../apollo/mutations';
import { getMyViewer } from '../../apollo/queries';
import { useSnackbar } from 'notistack';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1440,
    margin: '0 auto',
    position: 'relative',
  },
  incognito: {
    backgroundColor: '#0c0e2e',
    padding: '1px 10px',
    borderRadius: 4,
    cursor: 'pointer',
    color: Colors.white,
    ...Helpers.fillRowCenter,
    '& > img': {
      width: 50,
      height: 50,
    },
    '&:hover': {
      backgroundColor: '#090b24',
    },
  },
  templateArea: {
    ...Helpers.fillRowCenter,
    paddingTop: 31,
    paddingBottom: 31,

    [theme.breakpoints.down('md')]: {
      ...Helpers.fillCol,
      paddingTop: 0,
    },
  }
}));

const ProfileHeader = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { viewer, streamer } = props;
  const [showBorderEdit, setShowBorderEdit] = useState(false);

  const { data, loading } = useQuery(getProfileHeaderData, {
    variables: { viewer: viewer._id, login: viewer.login, season: [0, 1] },
    fetchPolicy: useCacheWithExpiration('getBadgeLevels', { viewer: viewer._id }),
  });

  const [toggleStreamerPrivacy, { loading: mutationLoading }] = useMutation(toggleStreamerPrivacyMutation, {
    onCompleted: () => {
      enqueueSnackbar(t('success'), { variant: 'success' });
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{
      query: getMyViewer,
    }],
  });

  if (loading)
    return <Loading />;

  const changePrivacy = () => {
    toggleStreamerPrivacy({ variables: { streamer: streamer._id } });
  };

  const badgeLevels = data && data.getBadgeLevels;
  const liveStream = data && data.liveStreamByStreamer;
  const myViewer = data && data.getMyViewer;
  const viewerShowcaseBadges = data && data.getAllViewerShowcaseBadges;
  const viewerBadges = data && data.getAllViewerBadges;
  const viewerSeasonBadges = data && data.getAllViewerSeasonBadges;
  const badgeTemplates = data && data.badgeTemplates;

  const isMe = myViewer && myViewer.login === viewer.login;
  const { isPremiumActive } = isUserPremium(viewer);
  const hiddenState = myViewer && streamer && myViewer.muted_streamers && myViewer.muted_streamers.includes(streamer._id);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.templateArea}>
          <CustomAvatar
            seasonBorder={viewer.season_border}
            rootSize={255}
            avatarSize={134}
            leagueSize={28}
            src={viewer.profile_image_url}
            alt={viewer.display_name}
            top={{ avatar: 57, league: 192 }}
            href={`https://www.twitch.tv/${viewer.login}`}
            target="_blank"
            isPulse={liveStream && liveStream.started_at}
            isEditable={isMe}
            onEdit={() => setShowBorderEdit(true)}
            isPremiumActive={isPremiumActive}
          />

          <div style={{ marginLeft: isMobile ? 0 : 32, width: '100%' }}>
            <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween }}>
              <div>
                <div style={{ ...Helpers.fillRowCross }}>
                  <div
                    style={{ fontWeight: 500, fontSize: 26, color: Colors.white, marginBottom: 4 }}>
                    {viewer.display_name}
                  </div>
                  {streamer && streamer.streamheroes_type === 'partnered' &&
                  <Badge status="success" style={{ marginLeft: 10 }}>
                    {t('partner')}
                  </Badge>
                  }
                  {liveStream && liveStream.started_at &&
                  <Badge status="danger" style={{ marginLeft: 10, textTransform: 'uppercase' }}>
                    {t('live')}
                  </Badge>
                  }
                </div>
                <div
                  style={{
                    color: 'rgba(255, 255, 255, 0.5)',
                    fontSize: 12,
                    textTransform: 'uppercase',
                    marginBottom: 18,
                    fontWeight: 300,
                  }}
                >
                  {t(streamer ? 'sh_streamer_since' : 'member_since', {
                    date: moment.unix((streamer ? (streamer.sh_viewer_since || streamer.created_at) : viewer.created_at) / 1000)
                    .format('DD MMM YYYY'),
                  })}
                </div>
              </div>

              {streamer &&
              <div className={classes.incognito} onClick={changePrivacy}>
                {mutationLoading ?
                  <CircularProgress color="secondary" />
                  :
                  <>
                    <img
                      src={`${process.env.PUBLIC_URL}/orange_neon_circle.png`}
                      alt="Circle"
                      style={hiddenState ? {} : { filter: 'grayscale(100%)' }}
                    />
                    {t(hiddenState ? 'deactivate_incognito' : 'activate_incognito')}
                  </>
                }
              </div>
              }
            </div>

            <ProfileHeaderBadges
              viewer={viewer}
              isMe={isMe}
              viewerShowcaseBadges={viewerShowcaseBadges}
              viewerBadges={viewerBadges}
              viewerSeasonBadges={viewerSeasonBadges}
              badgeTemplates={badgeTemplates}
            />
            <ProfileHeaderLevel viewerBadges={badgeLevels} />
          </div>
        </div>
      </div>
      {showBorderEdit &&
      <BorderSelectionContainer
        onClose={() => setShowBorderEdit(false)}
        currentBorder={myViewer.season_border}
        viewer={myViewer}
      />
      }
    </>
  );
};

export default ProfileHeader;