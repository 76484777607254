import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { faCheck, faMoon, faSun, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import HeroMakerFooter from './HeroMakerFooter';
import Helpers from '../../theme/Helpers';
import AppearanceItemSelection from './AppearanceItemSelection';
import Loading from '../Loading/Loading';
import {
  setHeroMakerCurrentImage,
  setLightDarkMode,
  updateHeroMakerColoredImages,
} from '../../redux/actions';

import conf from '../../conf';

const useStyles = makeStyles({
  iconTabsBar: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
  },
  tabIcon: {
    borderRadius: 8,
    background: '#475872',
    border: '1px solid #5D6A81',
    width: 38,
    height: 38,
    opacity: 0.25,
    position: 'relative',
    '&.active': {
      opacity: 1,
    },
    '&:hover': {
      opacity: 1,
      cursor: 'pointer',
    },
    '& img': {
      userSelect: 'none',
    },
  },
  activeHeroArea: {
    background: `url("${conf.cdn_url}/assets/template/heroMaker/bg_placeholder.png?height=360")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: 8,
    height: 360,
    width: 234,
    ...Helpers.fillRowCenter,
    position: 'relative',
    '& img': {
      userSelect: 'none',
    },
  },
  darkArea: {
    position: 'absolute',
    width: '100%',
    height: 375,
    zIndex: 100,
    background: 'rgba(0, 0, 0, 0.5)',
    ...Helpers.fillRowCenter,
  },
  modeChange: {
    position: 'absolute',
    top: 10,
    right: 10,
    backgroundColor: '#12233D',
    borderRadius: 50,
    width: 37,
    cursor: 'pointer',
    transition: '0.3s ease-in-out',
    height: 37,
    ...Helpers.fillRowCenter,
    '&:hover svg': {
      color: 'white',
    },
  },
  mustHaveIcon: {
    position: 'absolute',
    right: -5,
    top: -5,
    backgroundColor: '#00FF08',
    borderRadius: 50,
    padding: 2,
    boxShadow: '0px 0px 17px #00FF80',
    '&.not': {
      backgroundColor: '#B00B28',
      boxShadow: '0px 0px 17px #FF0000',
    },
  },
});

const AppearanceSelection = (props) => {
  const classes = useStyles();
  const {
    character,
    body,
    head,
    nextTab,
    backTab,
    footerValue,
    heroMaker,
    heroMakerCategories,
    serviceURL,
  } = props;
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [activeIcon, setActiveIcon] = useState('02_hairs');

  const icons = [
    '02_hairs',
    character.includes('female') ? '16_hair-back' : '08_beards',
    '05_eyebrows',
    '06_eyes',
    '07_noses',
    '09_mouths',
    '10_ears',
    '12_clothes',
    '13_pants',
    '14_shoes',
    '01_glasses',
    '03_headsets',
    '00_specials',
    '04_piercings',
  ];

  const mustHaves = ['06_eyes', '07_noses', '09_mouths', '12_clothes', '13_pants'];

  const saveAndNextTab = () => {
    nextTab();
  };

  useEffect(() => {
    if (!loading) {
      setLoading(true);
      fetchHero();
    }
  }, [heroMaker.assets]);

  const checkMustHaveAssetExists = (type) => {
    if (!mustHaves.includes(type))
      return false;

    for (let i = 0; i < heroMaker.assets.length; i++) {
      const asset = heroMaker.assets[i];
      if (asset.asset === type)
        return true;
    }

    return false;
  };

  const checkMustHaveAssetsCount = () => {
    return heroMaker.assets.reduce((acc, asset) => acc + (mustHaves.includes(asset.asset) ? 1 : 0), 0);
  };

  const fetchHero = async () => {
    const heroMakerInput = {
      character,
      assets: [
        {
          asset: '15_bodys',
          colors: [heroMaker.bodyColor],
          type: body,
          sorting: heroMakerCategories['15_bodys'].sorting,
        },
        {
          asset: '11_heads',
          colors: [heroMaker.bodyColor],
          type: head,
          sorting: heroMakerCategories['11_heads'].sorting,
        },
        ...heroMaker.assets,
      ],
      cachedImages: heroMaker.cachedColoredImages,
      isSmall: true,
    };

    await axios.post(`${serviceURL}/generate-hero`, { heroMakerInput })
    .then((response) => {
      if (checkMustHaveAssetsCount() >= mustHaves.length)
        setDisabled(false);
      setLoading(false);
      props.setHeroMakerCurrentImage(response.data[0]);
      props.updateHeroMakerColoredImages(response.data[1]);
    })
    .catch((error) => {
      console.log(error);
    });
  };

  return (
    <>
      {loading &&
      <div className={classes.darkArea}>
        <Loading />
      </div>
      }
      <div style={{ marginBottom: 15, ...Helpers.fillRow }}>
        <div
          className={classes.activeHeroArea}
          style={!heroMaker.isDarkMode ? { background: `url("${conf.cdn_url}/assets/template/heroMaker/bg-placeholder-light.png?height=360")` } : {}}
        >
          <div className={classes.modeChange}
               onClick={() => props.setLightDarkMode(!heroMaker.isDarkMode)}>
            <FontAwesomeIcon icon={heroMaker.isDarkMode ? faSun : faMoon} size="lg"
                             color="#FAE376" />
          </div>
          {heroMaker.currentHero &&
          <img src={heroMaker.currentHero} alt="My hero" height={360} unselectable="on" />
          }
        </div>
        <div style={{ marginLeft: 18, width: 700 }}>
          <div className={classes.iconTabsBar}>
            {icons.map(icon => {
              const mustHaveExists = checkMustHaveAssetExists(icon);
              const iconClasses = [classes.mustHaveIcon];
              const mustHaveIcon = mustHaveExists ? faCheck : faTimes;
              if (!mustHaveExists)
                iconClasses.push('not');

              return (
                <div
                  className={[classes.tabIcon, icon === activeIcon ? 'active' : ''].join(' ')}
                  onClick={() => setActiveIcon(icon)}
                >
                  {mustHaves.includes(icon) &&
                  <FontAwesomeIcon icon={mustHaveIcon} color="#FFFFFF" size="xs"
                                   className={iconClasses.join(' ')} />
                  }
                  <img
                    src={`${conf.cdn_url}/heromaker/icons/hero-maker-icon-${icon}.png`}
                    alt={icon}
                  />
                </div>
              );
            })}
          </div>
          <AppearanceItemSelection
            character={character}
            category={activeIcon}
            heroMakerCategories={heroMakerCategories}
          />
        </div>
      </div>
      <HeroMakerFooter
        onClick={saveAndNextTab}
        value={footerValue}
        backTab={backTab}
        disabled={disabled}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { heroMaker } = state;
  return { heroMaker };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    updateHeroMakerColoredImages,
    setHeroMakerCurrentImage,
    setLightDarkMode,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(AppearanceSelection);