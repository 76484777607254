import React from 'react';
import { connect } from 'react-redux';
import LoadingBar from 'react-top-loading-bar';
import { useKeyPress } from 'react-use';

import { getMatchData } from '../../utils/helper';

const CardViewSpaceAction = (props) => {
  const { boosterAction, information } = props;
  const { availableBoosters } = boosterAction;

  const { name, type } = getMatchData(information.card_template_metadata, 75, 75);
  const hasBoosterAvailable = availableBoosters && availableBoosters.length > 0;

  const callback = () => {
    if (hasBoosterAvailable && name === boosterAction.name && type === boosterAction.matchType) {
      boosterAction.openFunction({
        variables: {
          ids: [availableBoosters[0]],
        },
      });
    }
  };

  const keyPressed = useKeyPress(' ')[0];

  return (
    (keyPressed && hasBoosterAvailable) &&
    <LoadingBar color="#1FE325" progress={100} loaderSpeed={3000}
                containerStyle={{ bottom: 0, top: 'initial' }} shadow={false} waitingTime={2400}
                onLoaderFinished={callback} />
  );
};

const mapStateToProps = (state) => {
  const { boosterAction } = state;
  return { boosterAction };
};

export default connect(mapStateToProps)(CardViewSpaceAction);