import React from 'react';

const TermsAndConditionsEN = () => {
  return (
    <>
      <p dir="ltr">
        These terms and conditions ("<b>Terms</b>") govern the contractual relationship between the
        customer and the
      </p>

      <p dir="ltr" style={{ textAlign: 'center' }}>
        Streamheroes GmbH ("<b>Streamheroes</b>")<br />
        Triererstraße 5, 66839 Schmelz, Germany<br />
        Commercial Register Saarbrücken, HRB 106787<br />
        Tel.: +43 650 4989 426<br />
        E-mail: <a href="mailto:support@streamheroes.gg">support@streamheroes.gg</a><br />
        UID No.: DE335845977
      </p>

      <p dir="ltr">
        for products or services offered, created, delivered or provided by Streamheroes under the
        webshop ("<b>Webshop</b>") available at <a
        href="https://app.streamheroes.gg/card-shop">https://app.streamheroes.gg/card-shop</a>. The
        version of the Terms valid for the order is the one published on <a
        href="https://app.streamheroes.gg/card-shop">https://app.streamheroes.gg/card-shop</a> at
        the time of the order. Any terms and conditions of the customer deviating therefrom shall
        not apply unless Streamheroes agrees to their application in writing.
      </p>

      <p dir="ltr">
        It is pointed out that the Webshop at <a
        href="https://app.streamheroes.gg/card-shop">https://app.streamheroes.gg/card-shop</a> is
        part of an online platform which Streamheroes makes available to users at <a
        href="https://app.streamheroes.gg">https://app.streamheroes.gg</a> (the "<b>Platform</b>").
        For the use of the Platform, in addition to these Terms, the general terms and conditions of
        use shall apply, as they are available at <a
        href="https://app.streamheroes.gg/page/terms-of-use">https://app.streamheroes.gg/page/terms-of-use</a> in
        the version applicable from time to time and which the customer must accept when registering
        on the Platform for the first time. In case of contradictions between the Terms and the
        General Terms of Use, the provisions of these Terms shall prevail.
      </p>

      <h1 dir="ltr">
        SUBJECT MATTER
      </h1>

      <p dir="ltr">
        Streamheroes offers its users on its Platform the possibility to collect digital cards,
        whose design was created by cooperation partners of the Platform. The users - as customers
        of the Webshop - have the possibility to request virtual cards, which they have collected on
        the Platform, to be printed as physical cards via the Webshop. In such a case, Streamheroes
        will make and print the corresponding cards upon the customer's request. It is clarified
        that the customer cannot make physical cards that are not acquired virtually.
      </p>

      <p dir="ltr">
        The offers in the Webshop are aimed exclusively at consumers within the meaning of the
        applicable law. An acquisition of the offered goods for commercial purposes (e.g. a
        commercial resale of such cards) is expressly prohibited.
      </p>

      <h1 dir="ltr">
        OFFERS AND CONCLUSION OF CONTRACTS
      </h1>

      <p dir="ltr">
        All offers in the Webshop are non-binding.
      </p>

      <p dir="ltr">
        The prices listed in the Webshop are in EUR and include all taxes, but not shipping costs.
        If the customer resides in a country where EUR is not the national currency, the price will
        be converted to the national currency of the customer at the time of the order. Shipping
        costs are specified in detail in the section "<i>Shipping costs and delivery conditions</i>"
        on the Webshop website. The amounts indicated at the time of the order shall apply.
        Streamheroes will again inform the customer about the prices, any fees or taxes and shipping
        costs in the order summary before finalizing the order.
      </p>

      <p dir="ltr">
        The payment methods accepted by Streamheroes and any associated fees are detailed in the
        section "<i>Payment methods</i>" on the Webshop website.
      </p>

      <p dir="ltr">
        Unless otherwise agreed with the customer, the invoice amount is due for payment immediately
        upon conclusion of the contract. The purchase contract arises in the case of acceptance by
        actual correspondence with the receipt of the goods or in the case of acceptance by e-mail
        with receipt of the e-mail. In case of default, Streamheroes shall be entitled to claim
        statutory default interest. In addition, Streamheroes shall be reimbursed for any overdue
        and collection expenses incurred, insofar as they are necessary for the appropriate
        prosecution.
      </p>

      <h1 dir="ltr">
        RETENTION OF TITLE
      </h1>

      <p dir="ltr">
        All goods delivered by Streamheroes shall remain the property of Streamheroes until full
        payment by the customer.
      </p>

      <h1 dir="ltr">
        RIGHT OF WITHDRAWAL AND ELIMINATION OF THE RIGHT OF WITHDRAWAL
      </h1>

      <p dir="ltr">
        The customer may withdraw his consent to the contractual relationship with Streamheroes
        informally (such as by letter or e-mail) without stating reasons. The revocation is to be
        addressed to
      </p>

      <p dir="ltr" style={{ textAlign: 'center' }}>
        Streamheroes GmbH<br />
        Triererstraße 5, 66839 Schmelz, Germany<br />
        Commercial Register Saarbrücken, HRB 106787<br />
        E-mail: <a href="mailto:support@streamheroes.gg">support@streamheroes.gg</a>
      </p>

      <p dir="ltr">
        The withdrawal period is fourteen days from the day on which the customer or a third party
        named by the customer, who is not the carrier, has or has taken possession of the goods. To
        comply with the withdrawal period, it is sufficient that the customer sends the notification
        of the exercise of the right of withdrawal before the expiry of the withdrawal period.
      </p>

      <p dir="ltr">
        The exercise of the right of withdrawal in accordance with this section 7 can be made by
        means of the model withdrawal form, which can be found at the end of the Terms.
      </p>

      <p dir="ltr">
        If the customer revokes his contractual declaration or an already concluded contract,
        Streamheroes shall repay all payments made by the customer to Streamheroes without undue
        delay and - subject to the next sentence - no later than within fourteen days from the day
        on which the notification of the revocation of the contract has been received by
        Streamheroes. Streamheroes may refuse repayment until the delivered goods have been received
        back or the customer has provided proof that the goods have been returned to Streamheroes,
        whichever is earlier.
      </p>

      <p dir="ltr">
        For the repayment Streamheroes uses the same means of payment which were used in the
        original transaction, unless otherwise expressly agreed with the customer.
      </p>

      <p dir="ltr">
        The customer shall return the goods immediately and, in any case, no later than within
        fourteen days from the day on which the customer notifies Streamheroes of the revocation of
        this contract to
      </p>

      <p dir="ltr" style={{ textAlign: 'center' }}>
        Streamheroes GmbH<br />
        Triererstraße 5, 66839 Schmelz, Germany
      </p>

      <p dir="ltr">
        to return or hand over the goods. The deadline is met if the customer sends the goods before
        the expiry of the period of fourteen days. The return costs are to be borne by the customer.
      </p>

      <p dir="ltr">
        The customer must pay for any loss in value of the goods if this loss in value is due to the
        customer's handling of the goods which is not necessary for checking the quality and
        characteristics of the goods.
      </p>

      <p dir="ltr" style={{ fontWeight: 'bold' }}>
        The customer acknowledges that a right of withdrawal does not apply to contracts for the
        delivery of goods that have been manufactured according to customer specifications or are
        clearly tailored to the customer's personal needs. The physical cards that are sold via the
        Webshop are one-off productions that are exclusively tailored to the respective customer by
        means of a QR code and the link to the collected virtual card. For this reason, such
        physical cards are goods made according to customer specifications or clearly tailored to
        the personal needs of the customer and the customer has no right of withdrawal when
        purchasing such physical cards through the Webshop.
      </p>

      <h1 dir="ltr">
        RIGHT OF CANCELLATION OF STREAMHEROES AND UNAUTHORIZED WITHDRAWAL OF THE BUYER
      </h1>

      <p dir="ltr">
        In the event of default of acceptance (see Section 4) or other important reasons, such as in
        particular default of payment by the customer, Streamheroes shall be entitled to rescind the
        contract after setting a reasonable grace period of at least 2 weeks, provided that the
        contract has not yet been fully performed by both parties.
      </p>

      <p dir="ltr">
        If the customer - without being entitled to do so - withdraws from the contract or requests
        its cancellation, Streamheroes shall have the choice to insist on the fulfillment of the
        contract or to agree to the cancellation of the contract.
      </p>

      <h1 dir="ltr">
        WARRANTY, TECHNICAL DEVIATIONS AND LIABILITY
      </h1>

      <p dir="ltr">
        The warranty is governed by the statutory rules.
      </p>

      <p dir="ltr">
        The customer acknowledges that, due to production processes customary in the industry or the
        nature of the materials used in the performance of the contract, minor deviations from the
        specifications of the product characteristics in the Webshop may occur. To the extent that
        such deviations are state of the art, customary in the industry and/or reasonable for the
        customer, such as with regard to paper thickness, dimensions, design, color or similar
        characteristics, such deviations shall be deemed expressly accepted and not a "defect"
        within the meaning of the applicable warranty provisions. A detailed description of such
        industry standard deviations can be found in the section "Card Properties" on the website of
        the Webshop.
      </p>

      <p dir="ltr">
        Subject to Section 9.4,Streamheroes' liability based on slight negligence, provided that no
        material contractual obligations have been breached, is generally excluded. Streamheroes
        shall be liable exclusively for damages based on intentional or grossly negligent conduct of
        Streamheroes.
      </p>

      <p dir="ltr">
        The limitations of liability set forth in Section 9.3 shall not apply to damages resulting
        from injury to life, body or health of persons or in the event that mandatory statutory
        provisions to the contrary apply.
      </p>

      <h1 dir="ltr">
        IMATERIAL RIGHTS OF THIRD PARTIES TO THE CARD MOTIFS
      </h1>

      <p dir="ltr">
        The customer acknowledges that the creation of the virtual cards, which are the basis for
        the physical cards to be purchased in the Webshop, is the sole responsibility of the
        cooperation partners of the Platform. In this context, Streamheroes shall not be obliged to
        check for any infringements of copyrights, other commercial property rights or other rights
        of third parties, and Streamheroes shall not be liable in the event of such infringements.
      </p>

      <p dir="ltr">
        If, prior to fulfillment of the order, the customer or Streamheroes has reason to suspect
        that the print motif and/or the execution of the order violates the rights of third parties
        or statutory provisions, each party shall immediately inform the other thereof and may -
        after prior examination of the factual situation by Streamheroes within a reasonable period
        of time - withdraw from the order with immediate effect. Streamheroes may suspend
        performance until the situation has been clarified, whereby the delivery periods shall be
        extended accordingly until clarification.
      </p>

      <h1 dir="ltr">
        DATA PROTECTION, ADDRESS CHANGES
      </h1>

      <p dir="ltr">
        Streamheroes is obligated to comply with the provisions of the General Data Protection
        Regulation (<i>Datenschutzgrundverordnung</i> "DSGVO") as well as any other statutory
        confidentiality obligations.
      </p>

      <p dir="ltr">
        Furthermore, Streamheroes processes the personal data required for the purpose of fulfilling
        the contract. The detailed data protection information (data protection notice) pursuant to
        Art 13 ff DSGVO is available at <a
        href="https://www.streamheroes.gg/privacy">https://www.streamheroes.gg/privacy</a>.
      </p>

      <p dir="ltr">
        The customer is obligated to notify changes in his residential or delivery address as long
        as the contractual legal transaction has not been completely fulfilled by both parties. If
        the notification is omitted, declarations shall also be deemed to have been received if they
        are sent to the address last notified by the customer. Deliveries shall be deemed to have
        been received with discharge of debt if they have been delivered to the delivery address
        notified by the customer or have been deposited with the delivery service for lack of
        deliverability.
      </p>

      <h1 dir="ltr">
        ONLINE DISPUTE RESOLUTION
      </h1>

      <p dir="ltr">
        An online dispute resolution (ODR) platform has been set up at the European Commission to
        resolve disputes arising from online sales contracts and online service contracts. This
        gives consumers the opportunity to resolve disputes out of court first. This can be accessed
        at the link: <a href="https://webgate.ec.europa.eu/odr">https://webgate.ec.europa.eu/odr</a>.
        Streamheroes does not participate in a dispute resolution procedure before a consumer
        arbitration board.
      </p>

      <h1 dir="ltr">
        PAYMMENT & TERMINATION
      </h1>

      <h2 dir="ltr">
        1. Payment
      </h2>

      <p dir="ltr">
        1.1 For the rights granted herein you will be charged a subscription fee at the beginning of each 
        Subscription Period, in the amount stated in the subscription section on the website. Streamheroes 
        will not refund any subscription fees paid, except as explicitly provided in this Agreement.
      </p>

      <p dir="ltr">
        1.2 The subscription fee is inclusive of VAT.
      </p>

      <p dir="ltr">
        1.3 Streamheroes may change the subscription fee from time to time and will communicate any such 
        changes to you in advance. Price changes will take effect no earlier than at the start of the 
        Subscription Period following the date of the change. If you do not accept the price change, 
        you may terminate your subscription at any time prior to such price change.
      </p>

      <h2 dir="ltr">
        2. Termination
      </h2>

      <p dir="ltr">
        2.1 This Agreement shall enter into force upon your acceptance of the terms set forth herein and will be automatically 
        prolonged for periods as designated by you when purchasing a Subscription, (each such period a "Subscription Period"), 
        until terminated by you or by Streamheroes prior to the end of the then-current Subscription Period.
      </p>

      <p dir="ltr">
        2.2 To the fullest extent permitted under applicable law and except as otherwise stated herein, you agree that Streamheroes 
        will have no liability or responsibility to you and that Streamheroes will not refund any amounts that you have already paid, 
        if you terminate this Agreement, or if Streamheroes terminates this Agreement or suspends your access in accordance with the 
        provisions herein.
      </p>

      <p dir="ltr">
        2.3 If you reside in a country which is part of the European Union and have purchased a Subscription, you have the right to 
        terminate the Subscription, by completing the withdrawal form found here and sending it through this webform and receive a 
        full refund within fourteen (14) days of purchase (the "Cooling-off Period"), but only if you have not used any service/feauture 
        of the product. Meaning that you have started to consume the Subscription.
      </p>

      <p dir="ltr">
        2.4 For the avoidance of doubt, if you terminate this Agreement after the Cooling-off Period is over (where applicable), 
        Streamheroes will not refund any Subscription fees already paid by you.
      </p>

      <h1 dir="ltr">
        FINAL PROVISIONS
      </h1>

      <p dir="ltr">
        If any provision of these Terms is or becomes invalid or this contract contains a loophole,
        this shall not affect the validity of the remaining provisions.
      </p>

      <p dir="ltr">
        These Terms and the entire legal relationship and disputes between the customer and
        Streamheroes shall be governed exclusively by Austrian law, with the exception of the
        reference standards and the UN Convention on Contracts for the International Sale of Goods.
        With respect to consumers, this choice of law shall apply only to the extent that it does
        not restrict any mandatory statutory provisions of the state in which he has his habitual
        residence.
      </p>

      <p dir="ltr">
        The place of jurisdiction for all disputes arising from or in connection with these Terms
        shall be the competent court in Steyr, Austria. If the customer is a consumer within the
        meaning of the applicable law and has his domicile or habitual residence in any other
        jurisdiction or is employed in any other jurisdiction, he may only be sued in deviation to
        the courts in whose jurisdiction his domicile, habitual residence or place of employment is
        located.
      </p>

      <p dir="ltr">
        The text of the contract will not be saved. Streamheroes recommends archiving the order
        confirmation and these Terms.
      </p>

      <p dir="ltr">
        If these Terms are translated into several languages, the German version shall be the
        authoritative version.
      </p>

      <p dir="ltr" style={{ textAlign: 'center', fontWeight: 'bold' }}>
        <i>Sample cancellation form</i>
      </p>

      <p dir="ltr">
        If you want to cancel the contract, please fill out this form and send it back to
      </p>

      <p dir="ltr" style={{ textAlign: 'center' }}>
        Streamheroes GmbH<br />
        Triererstraße 5, 66839 Schmelz, Germany<br />
        Commercial Register Saarbrücken, HRB 106787<br />
        E-mail: <a href="mailto:support@streamheroes.gg">support@streamheroes.gg</a>
      </p>

      <p dir="ltr">
        I hereby revoke the contract concluded by me for the purchase of the following goods offered
        at https://app.streamheroes.gg/card-shop : [description of the goods]
      </p>

      <p dir="ltr">
        Ordered on/received on:<br />
        Name of the consumer:<br />
        Date:<br />
        Signature of the consumer (only in case of notification on paper)
      </p>
    </>
  );
};

export default TermsAndConditionsEN;