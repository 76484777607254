import * as React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import lookup from 'country-code-lookup';

import FlagIcon from './FlagIcon';
import CustomTooltip from '../Tooltip/CustomTooltip';

const useStyles = makeStyles(() => ({
  flag: {
    border: '1px solid #a90b27',
  },
}));

const FlagWithTooltip = (props) => {
  const classes = useStyles();
  const { code, size, style } = props;

  if (!code || code.length > 3)
    return null;

  const country = lookup.byIso(code);

  return (
    <CustomTooltip title={country ? country.country : '...'}>
      <div className={classes.flag} style={style}>
        <FlagIcon code={code.toLowerCase()} size={size} />
      </div>
    </CustomTooltip>
  );
};

FlagWithTooltip.propTypes = {
  code: PropTypes.string.isRequired,
  size: PropTypes.string,
  style: PropTypes.object,
};

FlagWithTooltip.defaultProps = {
  size: 'lg',
  style: {},
};

export default FlagWithTooltip;