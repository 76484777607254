import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ClickAwayListener } from '@material-ui/core';

import { toggleHidePageScrollBar } from '../../redux/actions';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';

const useStyles = makeStyles((theme) => ({
  allPage: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 10000,
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    animationName: '$popup',
    animationDuration: '0.5s',

    [theme.breakpoints.down('md')]: {
      '& > div': {
        height: '100vh',
        width: '100vw',
        overflow: 'auto',
      },
    },
  },
  blurContainer: {
    height: '100%',
  },
  '@keyframes popup': {
    '0%': {
      top: '-100%',
    },
    '100%': {
      top: '0%',
    },
  },
}));

const PagePopup = (props) => {
  const classes = useStyles();
  const { children, toggleHidePageScrollBar, withBlurred, handleClose = null, style = {} } = props;

  // useEffect(() => {
  //   toggleHidePageScrollBar();

  //   return () => toggleHidePageScrollBar();
  // });

  return (
    <div className={classes.allPage} style={style}>
      <ClickAwayListener onClickAway={handleClose}>
        {withBlurred ?
          <CustomBackdropFilter
            className={classes.blurContainer}
            filter={'blur(25px)'}
            canvasFallback={true}
            html2canvasOpts={{
              allowTaint: true,
            }}
          >
            {children}
          </CustomBackdropFilter>
          :
          children
        }
      </ClickAwayListener>
    </div>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    toggleHidePageScrollBar,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(PagePopup);