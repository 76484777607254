import React from 'react';
import DuotoneIconBase from './DuotoneIconBase';

const SettingsIcon = (props) => {
  const { forceActive = false } = props;

  return (
    <DuotoneIconBase
      forceActive={forceActive}

      DefaultIcon={
        <svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="-2 -2 26 26">
          <g>
            <g>
              <path fill="#FFFFFF" d="M12.367,22H9.631c-1.085,0-1.992-0.849-2.065-1.932l-0.089-1.332c-0.496-0.226-0.968-0.5-1.413-0.817
                                l-1.201,0.589c-0.277,0.137-0.588,0.207-0.899,0.207c-0.747,0-1.438-0.395-1.806-1.03l-1.368-2.37
                                c-0.542-0.939-0.262-2.148,0.639-2.754l1.112-0.746C2.514,11.542,2.5,11.269,2.5,11c0-0.27,0.014-0.542,0.041-0.815L1.429,9.438
                                C0.528,8.834,0.247,7.624,0.79,6.686l1.368-2.37c0.367-0.636,1.059-1.031,1.806-1.031c0.312,0,0.622,0.071,0.899,0.207l1.201,0.59
                                c0.445-0.318,0.917-0.593,1.413-0.818L7.566,1.93C7.639,0.848,8.546,0,9.631,0h2.736c1.086,0,1.992,0.848,2.066,1.93l0.091,1.333
                                c0.495,0.227,0.967,0.5,1.412,0.818l1.2-0.59c0.276-0.135,0.588-0.207,0.898-0.207c0.746,0,1.438,0.395,1.807,1.031l1.369,2.37
                                c0.542,0.938,0.262,2.148-0.641,2.753l-1.111,0.747c0.026,0.272,0.04,0.546,0.04,0.815c0,0.268-0.014,0.542-0.04,0.814
                                l1.111,0.746c0.9,0.605,1.182,1.814,0.639,2.754l-1.367,2.37c-0.367,0.636-1.061,1.03-1.807,1.03c-0.311,0-0.622-0.07-0.898-0.207
                                l-1.2-0.589c-0.445,0.317-0.917,0.592-1.412,0.817l-0.091,1.332C14.359,21.151,13.453,22,12.367,22z M6.183,15.746
                                c0.231,0,0.459,0.08,0.643,0.234c0.584,0.49,1.24,0.869,1.95,1.128c0.371,0.136,0.628,0.478,0.654,0.872l0.133,1.955
                                C9.563,19.958,9.606,20,9.631,20h2.736c0.025,0,0.069-0.042,0.07-0.064l0.133-1.955c0.026-0.395,0.283-0.736,0.655-0.872
                                c0.708-0.259,1.364-0.638,1.948-1.128c0.303-0.254,0.728-0.307,1.083-0.131l1.762,0.863c0.054,0.002,0.085-0.018,0.091-0.027
                                l1.368-2.37c0.012-0.021-0.003-0.081-0.022-0.094l-1.628-1.093c-0.328-0.221-0.495-0.613-0.428-1.003
                                c0.067-0.379,0.1-0.758,0.1-1.125c0-0.369-0.032-0.749-0.1-1.127c-0.067-0.389,0.1-0.781,0.428-1.002l1.63-1.093
                                c0.02-0.013,0.033-0.073,0.021-0.093l-1.369-2.371c-0.006-0.012-0.039-0.03-0.074-0.03L16.257,6.15
                                c-0.355,0.175-0.78,0.123-1.083-0.131c-0.584-0.492-1.24-0.87-1.948-1.128c-0.372-0.136-0.629-0.479-0.655-0.872l-0.133-1.954
                                C12.437,2.041,12.393,2,12.367,2H9.631C9.606,2,9.563,2.041,9.562,2.065L9.429,4.02C9.403,4.413,9.146,4.756,8.773,4.892
                                C8.065,5.149,7.409,5.528,6.825,6.02C6.523,6.273,6.099,6.326,5.743,6.15L3.981,5.287C3.928,5.284,3.896,5.303,3.89,5.314
                                L2.521,7.685c-0.012,0.02,0.001,0.08,0.021,0.093l1.629,1.093C4.5,9.092,4.667,9.484,4.6,9.873C4.534,10.251,4.5,10.63,4.5,11
                                c0,0.369,0.034,0.749,0.099,1.125c0.068,0.39-0.099,0.782-0.427,1.003l-1.629,1.093c-0.02,0.013-0.033,0.072-0.021,0.094
                                l1.369,2.37c0.006,0.01,0.038,0.029,0.073,0.029l1.779-0.865C5.882,15.779,6.032,15.746,6.183,15.746z" />
            </g>
            <g opacity="0.5">
              <path fill="#FFFFFF" d="M11,15.75c-2.62,0-4.751-2.131-4.751-4.75c0-2.62,2.131-4.75,4.751-4.75c2.619,0,4.749,2.131,4.749,4.75
                                C15.749,13.619,13.619,15.75,11,15.75z M11,8.25c-1.518,0-2.75,1.234-2.75,2.75c0,1.515,1.233,2.75,2.75,2.75
                                c1.515,0,2.749-1.234,2.749-2.75C13.749,9.484,12.516,8.25,11,8.25z" />
            </g>
          </g>
        </svg>
      }
      HoverIcon={
        <svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="-2 -2 26 26">
          <g>
            <g>
              <path fill="#FFFFFF" d="M12.368,21.999H9.631c-1.085,0-1.991-0.848-2.064-1.93l-0.09-1.333c-0.495-0.226-0.968-0.5-1.413-0.818
                                l-1.2,0.589c-0.278,0.137-0.588,0.208-0.899,0.208c-0.747,0-1.438-0.396-1.806-1.03l-1.368-2.37
                                c-0.543-0.939-0.262-2.148,0.639-2.753l1.112-0.746C2.514,11.542,2.5,11.269,2.5,11c0-0.27,0.014-0.542,0.041-0.816L1.429,9.438
                                c-0.9-0.605-1.182-1.814-0.639-2.754l1.368-2.37c0.367-0.635,1.059-1.03,1.806-1.03c0.312,0,0.621,0.071,0.899,0.207l1.2,0.59
                                c0.445-0.318,0.918-0.592,1.413-0.818l0.09-1.332c0.073-1.082,0.979-1.93,2.064-1.93h2.737c1.085,0,1.992,0.848,2.065,1.93
                                l0.091,1.333c0.495,0.225,0.967,0.499,1.412,0.817l1.201-0.59c0.275-0.136,0.587-0.206,0.898-0.206
                                c0.745,0,1.438,0.395,1.805,1.029l1.369,2.37c0.543,0.939,0.262,2.15-0.639,2.754l-1.113,0.746
                                c0.027,0.274,0.041,0.547,0.041,0.816c0,0.268-0.014,0.542-0.041,0.815l1.113,0.746c0.9,0.604,1.181,1.813,0.637,2.752
                                l-1.367,2.371c-0.365,0.635-1.06,1.03-1.805,1.03c-0.311,0-0.623-0.071-0.898-0.208l-1.201-0.589
                                c-0.445,0.318-0.917,0.593-1.412,0.818l-0.091,1.333C14.36,21.151,13.453,21.999,12.368,21.999z M6.183,15.746
                                c0.231,0,0.459,0.081,0.643,0.234c0.584,0.489,1.239,0.869,1.949,1.128c0.371,0.136,0.628,0.477,0.654,0.871l0.133,1.955
                                c0.002,0.022,0.045,0.064,0.069,0.064h2.737c0.025,0,0.068-0.042,0.07-0.064l0.132-1.955c0.026-0.395,0.284-0.735,0.655-0.871
                                c0.709-0.259,1.365-0.639,1.949-1.128c0.303-0.255,0.727-0.307,1.082-0.132l1.762,0.864c0.055,0.003,0.085-0.018,0.092-0.029
                                l1.367-2.369c0.012-0.021-0.002-0.079-0.021-0.093l-1.629-1.094c-0.328-0.221-0.496-0.612-0.428-1.002
                                c0.067-0.379,0.1-0.758,0.1-1.125c0-0.369-0.032-0.748-0.1-1.127c-0.068-0.389,0.1-0.781,0.428-1.002l1.629-1.093
                                c0.021-0.013,0.034-0.072,0.022-0.093L18.11,5.315c-0.008-0.012-0.041-0.03-0.074-0.03l-1.779,0.867
                                c-0.355,0.175-0.779,0.123-1.082-0.132c-0.584-0.491-1.24-0.87-1.949-1.128c-0.371-0.135-0.629-0.478-0.655-0.872l-0.132-1.954
                                C12.437,2.042,12.394,2,12.368,2H9.631C9.606,2,9.563,2.042,9.562,2.065L9.429,4.02C9.402,4.415,9.146,4.756,8.773,4.892
                                C8.064,5.149,7.409,5.528,6.825,6.02C6.522,6.274,6.099,6.326,5.742,6.151L3.981,5.287C3.928,5.285,3.896,5.305,3.891,5.315
                                L2.521,7.686C2.51,7.706,2.523,7.765,2.543,7.778l1.629,1.093C4.5,9.092,4.668,9.484,4.6,9.873C4.534,10.251,4.5,10.63,4.5,11
                                c0,0.37,0.034,0.749,0.1,1.125c0.068,0.39-0.1,0.781-0.428,1.002l-1.629,1.094c-0.02,0.014-0.033,0.072-0.021,0.093l1.369,2.37
                                c0.006,0.011,0.037,0.031,0.073,0.031l1.778-0.867C5.883,15.78,6.032,15.746,6.183,15.746z" />
            </g>
            <g>
              <path fill="#E12138" d="M11,15.75c-2.619,0-4.75-2.13-4.75-4.75S8.381,6.25,11,6.25c2.62,0,4.749,2.131,4.749,4.75
                                S13.62,15.75,11,15.75z M11,8.25c-1.517,0-2.75,1.234-2.75,2.75c0,1.516,1.233,2.749,2.75,2.749s2.749-1.232,2.749-2.749
                                C13.749,9.484,12.517,8.25,11,8.25z" />
            </g>
          </g>
        </svg>
      }
      ActiveIcon={
        <svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="-2 -2 26 26">
          <g>
            <g>
              <path fill="#FFFFFF" d="M20.571,12.561l-1.113-0.744c0.027-0.273,0.041-0.548,0.041-0.816c0-0.269-0.014-0.542-0.041-0.815
                                l1.113-0.746c0.9-0.605,1.182-1.814,0.639-2.753l-1.369-2.37c-0.367-0.636-1.06-1.031-1.805-1.031
                                c-0.312,0-0.623,0.072-0.898,0.208l-1.201,0.589c-0.445-0.318-0.917-0.591-1.412-0.818l-0.091-1.332
                                C14.36,0.849,13.453,0,12.368,0H9.632C8.546,0,7.64,0.849,7.566,1.932L7.478,3.263c-0.496,0.226-0.969,0.5-1.414,0.818l-1.2-0.589
                                C4.585,3.356,4.275,3.285,3.964,3.285c-0.747,0-1.438,0.395-1.806,1.031L0.79,6.686C0.247,7.625,0.528,8.833,1.429,9.439
                                l1.112,0.746C2.514,10.458,2.5,10.731,2.5,11c0,0.269,0.014,0.542,0.041,0.816l-1.112,0.744c-0.9,0.607-1.182,1.814-0.639,2.754
                                l1.368,2.371c0.367,0.635,1.059,1.029,1.806,1.029c0.312,0,0.621-0.07,0.899-0.207l1.2-0.59c0.445,0.318,0.918,0.592,1.414,0.818
                                l0.089,1.332C7.64,21.152,8.546,22,9.632,22h2.736c1.085,0,1.992-0.848,2.065-1.932l0.091-1.332
                                c0.495-0.227,0.967-0.5,1.412-0.818l1.201,0.59c0.275,0.137,0.587,0.207,0.898,0.207l0,0c0.745,0,1.439-0.395,1.805-1.029
                                l1.367-2.371C21.752,14.375,21.472,13.168,20.571,12.561z M11.001,15.75c-2.619,0-4.75-2.131-4.75-4.75
                                c0-2.62,2.131-4.749,4.75-4.749S15.749,8.38,15.749,11C15.749,13.619,13.62,15.75,11.001,15.75z" />
            </g>
            <path fill="#E12138" d="M11.001,6.251c-2.619,0-4.75,2.129-4.75,4.749c0,2.619,2.131,4.75,4.75,4.75s4.748-2.131,4.748-4.75
                            C15.749,8.38,13.62,6.251,11.001,6.251z M11.001,13.75c-1.517,0-2.75-1.234-2.75-2.75c0-1.517,1.233-2.751,2.75-2.751
                            c1.516,0,2.748,1.234,2.748,2.751C13.749,12.516,12.517,13.75,11.001,13.75z" />
          </g>
        </svg>
      }
    />
  );
};

export default SettingsIcon;