import React, { useEffect } from 'react';
import WebFont from 'webfontloader';
import { useQuery } from '@apollo/react-hooks';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getMyViewer } from '../../apollo/queries';

import CardToolFabricController from '../../components/CardTool/CardToolFabricController';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import Loading from '../../components/Loading/Loading';
import TopBG from '../../components/TopViewHeader/TopBG';

const CardToolFabricContainer = (props) => {
  const { cardTool } = props;
  const { cards, activeCardId, cardToolLevel } = cardTool;

  const { data, loading } = useQuery(getMyViewer, {
    fetchPolicy: useCacheWithExpiration('getMyViewer'),
  });

  if (cardToolLevel === null)
    props.history.push('/streamer-settings/booster-management');

  const myViewer = data && data.getMyViewer;

  const fonts = [
    'Reggae One',
    'Playfair Display',
    'Bebas Neue',
    'Risque',
    'Lobster',
    'Righteous',
    'Alfa Slab One',
    'Luckiest Guy',
    'Squada One',
    'Press Start 2P',
    'Bangers',
    'Sigmar One',
    'Bungee',
    'Audiowide',
    'Knewave',
    'Cinzel Decorative',
    'Ceviche One',
  ];

  useEffect(() => {
    WebFont.load({
      google: {
        families: fonts,
      },
    });
  }, [fonts]);

  const card = cards && cards.find(card => card._id === activeCardId);

  return (
    <TopBG disableBreadcrumbs>
      {loading ?
        <Loading />
        :
        <CardToolFabricController
          card={card && card}
          myViewer={myViewer}
          fonts={fonts}
          level={cardToolLevel}
          isSpecial={cardToolLevel === 0}
          handleNext={() => props.history.push(`/streamer-settings/booster-management/update/${cardTool.boosterId}`)}
        />
      }
    </TopBG>
  );
};

const mapStateToProps = (state) => {
  const { cardTool } = state;
  return { cardTool };
};

export default connect(mapStateToProps)(withRouter(CardToolFabricContainer));