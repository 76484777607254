import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputAdornment, TextField } from '@material-ui/core';

import CreateAlertGroup from './CreateAlertGroup';

const useStyles = makeStyles(() => ({
  textInput: {
    background: 'transparent',
    padding: 0,
    borderRadius: 4,
    width: 'calc(100% - 24px)',
    marginTop: 6,
    fontSize: 14,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FFFFFF29',
      top: 0,
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FFFFFF29',
    },
    '& .MuiOutlinedInput-input': {
      padding: 12,
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingTop: 5,
    },
  },
}));

const CreateAlertCardPurchase = (props) => {
  const classes = useStyles();
  const { t, alertTool, setAlertToolTypeData } = props;
  const { cardsAmount } = alertTool;

  const handleChange = (event) => {
    const { target } = event;

    setAlertToolTypeData('cardsAmount', target.value);
  };

  return (
    <CreateAlertGroup
      title={t('cards_amount')}
      t={t}
      isRequired
      description={t('cards_amount_description')}
    >
      <TextField
        id="cards-amount"
        name="cards-amount"
        value={cardsAmount}
        onChange={handleChange}
        variant="outlined"
        classes={{
          root: classes.textInput,
        }}
        inputProps={{
          type: 'number',
          min: 1,
          max: 100,
        }}
      />
    </CreateAlertGroup>
  );
};

export default CreateAlertCardPurchase;