import React from 'react';
import { makeStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import HRNumbers from 'human-readable-numbers';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import CustomAvatar from '../Avatar/CustomAvatar';
import { isUserPremium } from '../../utils/helper';

import conf from '../../conf.json';

const useStyles = makeStyles({
  leaderItem: {
    padding: 16,
    position: 'relative',
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    transition: 'all 0.3s ease-out',

    '&:hover': {
      background: 'rgba(255, 255, 255, 0.07)',
    },
  },
  bgHoverItem: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    marginTop: 8,
    transition: 'all 0.3s ease-out',
    overflow: 'hidden',

    '&:hover': {
      background: 'rgba(255, 255, 255, 0.07)',
    },
  },
  badge: {
    background: 'rgba(255, 255, 255, 0.08)',
    borderRadius: 4,
    padding: 12,
    minWidth: 'calc(102px - 24px)',
    ...Helpers.textCenter,
    fontSize: 14,
    fontWeight: 500,
    marginRight: 8,

    '&:last-child': {
      marginRight: 0,
    },
  },
  avatarBlur: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    width: '50%',
    background: 'rgba(255, 255, 255, 0.04)',
    zIndex: -1, filter: 'blur(8px)',
  },
  aHover: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

const ProfileCommunityRankedBodyLeaders = (props) => {
  const classes = useStyles();
  const { seasonData, currentLeague } = props;

  if (!seasonData || (seasonData && seasonData.length === 0))
    return null;

  const getPremiumState = (viewer) => {
    const { isPremiumActive } = isUserPremium(viewer);

    return isPremiumActive;
  };

  const leaders = seasonData.slice(0, 3);
  const first = seasonData[0];
  const colors = ['#E7C370', '#CFCFCF', '#C58855', Colors.white, Colors.white];
  const bgColors = ['rgba(231, 195, 112, 0.32)', 'rgba(207, 207, 207, 0.32)', 'rgba(197, 136, 85, 0.32)', 'rgba(255, 255, 255, 0.06)', 'rgba(255, 255, 255, 0.06)'];
  const displayGatheredEnergy = !!first?.gathered_energy;
  const displayCollectors = !!first?.unique_collectors;

  return (
    <div style={{
      marginTop: 8,
      width: '100%',
    }}>
      {leaders && leaders.map((leader, index) => (
        <div
          key={`League${currentLeague}Key${index}`}
          className={classes.bgHoverItem}
          style={{
            padding: '41.5px 16px',
            position: 'relative',
            ...Helpers.fillRowCross,
          }}
        >
          <div className={classes.avatarBlur}>
            <div style={{
              width: '100%',
              height: '100%',
              background: 'linear-gradient(90deg, #0B192D 0%, #0B192DB8 53%, #0B192D00 100%)',
              zIndex: 1,
              position: 'absolute',
              top: 0,
              left: 0,
            }} />
            <div style={{
              ...Helpers.insetZero,
              backgroundImage: `url("${(leader.viewer || leader.streamer).profile_image_url}")`,
              backgroundSize: '100%',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              zIndex: 0,
              position: 'relative',
            }} />
          </div>

          <div style={{
            background: bgColors[index + 1],
            color: colors[index + 1],
            fontSize: 24,
            fontWeight: 600,
            textDecoration: 'underline',
            width: 62,
            height: 62,
            ...Helpers.fillColCenter,
            marginRight: 32,
            borderRadius: 4,
          }}>
            {index + 1}
          </div>
          <CustomAvatar
            seasonBorder={leader.viewer && leader.viewer.season_border}
            rootSize={95}
            avatarSize={53}
            leagueSize={13}
            src={(leader.viewer || leader.streamer).profile_image_url}
            alt={(leader.viewer || leader.streamer).display_name}
            top={{ avatar: 20, league: 71 }}
            href={`/profile/${(leader.viewer || leader.streamer).login}`}
            isPremiumActive={leader.viewer && getPremiumState(leader.viewer)}
          />

          <div style={{ marginLeft: 22 }}>
            <div style={{ marginBottom: 8, fontSize: 24, fontWeight: 600 }}>
              {(leader.viewer || leader.streamer).display_name}
            </div>
            <div style={{ ...Helpers.fillRowCross }}>
              {displayCollectors &&
              <div className={classes.badge}>
                <i className="fa-light fa-user" style={{ fontSize: 18, marginRight: 8 }} />
                {leader.unique_collectors.toLocaleString()}
              </div>
              }
              {displayGatheredEnergy &&
              <div className={classes.badge}>
                <img
                  src={`${conf.cdn_url}/assets/template/heroMaker/energy.png?height=18`}
                  alt="Energy"
                  style={{ marginRight: 8, verticalAlign: 'text-bottom' }} />
                {HRNumbers.toHumanString(leader.gathered_energy)}
              </div>
              }
              <div className={classes.badge}>
                <img
                  src={`${conf.cdn_url}/assets/seasons/season_1/SH_Season1_BoosterPack_Icon_18x26.png?height=18`}
                  alt="Booster pack"
                  style={{ marginRight: 8, verticalAlign: 'text-bottom' }} />

                {leader.redeemed_booster.toLocaleString()}
              </div>
              <div className={classes.badge} style={{ minWidth: 'calc(187px - 24px)' }}>
                {(leader.points || leader.redeemed_booster * 5).toLocaleString()}
              </div>
            </div>
          </div>
        </div>
      ))}


      {/*<div style={{
        marginTop: 8,
        display: 'grid',
        gap: 8,
        gridTemplateColumns: 'repeat(4, 1fr)',
      }}>
        {leaders && leaders.map((leader, index) => (
          <div className={classes.leaderItem} key={`League${currentLeague}Key${index}`}>

            <div style={{ ...Helpers.fillRowCross, marginBottom: 12 }}>
              <div
                style={{
                  background: bgColors[index + 1],
                  color: colors[index + 1],
                  fontSize: 18,
                  fontWeight: 600,
                  width: 42,
                  height: 42,
                  ...Helpers.fillColCenter,
                  marginRight: 12,
                  borderRadius: 4,
                }}
              >
                {index + 2}
              </div>
              <CustomAvatar
                seasonBorder={leader.viewer && leader.viewer.season_border}
                rootSize={42}
                avatarSize={23}
                leagueSize={6}
                src={(leader.viewer || leader.streamer).profile_image_url}
                alt={(leader.viewer || leader.streamer).display_name}
                top={{ avatar: 10, league: 32 }}
                href={`/profile/${(leader.viewer || leader.streamer).login}`}
                isPremiumActive={leader.viewer && getPremiumState(leader.viewer)}
              />
              <div
                className={classes.aHover}
                style={{ marginLeft: 12, fontSize: 14, fontWeight: 500, cursor: 'pointer' }}
                onClick={() => props.history.push(`/profile/${(leader.viewer || leader.streamer).login}`)}
              >
                {(leader.viewer || leader.streamer).display_name}
              </div>
            </div>
            <div style={{ ...Helpers.fillRowCross, marginBottom: 8 }}>
              {displayCollectors &&
              <div className={classes.badge}>
                <i className="fa-light fa-user" style={{ fontSize: 18, marginRight: 8 }} />
                {leader.unique_collectors.toLocaleString()}
              </div>
              }
              {displayGatheredEnergy &&
              <div className={classes.badge}>
                <img
                  src={`${conf.cdn_url}/assets/template/heroMaker/energy.png?height=18`}
                  alt="Energy"
                  style={{ marginRight: 8, verticalAlign: 'text-bottom' }} />
                {HRNumbers.toHumanString(leader.gathered_energy)}
              </div>
              }
              <div className={classes.badge}>
                <img
                  src={`${conf.cdn_url}/assets/seasons/season_1/SH_Season1_BoosterPack_Icon_18x26.png?height=18`}
                  alt="Booster pack"
                  style={{ marginRight: 8, verticalAlign: 'text-bottom' }} />

                {leader.redeemed_booster.toLocaleString()}
              </div>
            </div>
            <div className={classes.badge} style={{ width: 'calc(100% - 24px)' }}>
              {(leader.points || leader.redeemed_booster * 5).toLocaleString()}
            </div>
          </div>
        ))}
      </div>*/}

    </div>
  );
};

export default withRouter(ProfileCommunityRankedBodyLeaders);