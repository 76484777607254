import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PageTitle from '../../components/PageTitle/PageTitle';
import BoosterManagementUpdateBooster
  from '../../components/BoosterManagement/BoosterManagementUpdateBooster';

const BoosterManagementUpdateContainer = (props) => {
  const { t } = useTranslation();
  const { cardTool } = props;

  if (cardTool.boosterName === '')
    props.history.push('/streamer-settings/booster-management');

  // 0 is special
  const isStandard = cardTool.cards && cardTool.cards.length > 0 ? cardTool.cards[0].rarity > 0 : !cardTool.startDate;

  return (
    <>
      <PageTitle title={t('update_booster')} />
      <BoosterManagementUpdateBooster booster={cardTool} isStandard={isStandard} />
    </>
  );
};

const mapStateToProps = (state) => {
  const { cardTool } = state;
  return { cardTool };
};

export default connect(mapStateToProps)(withRouter(BoosterManagementUpdateContainer));