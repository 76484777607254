import React from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CardPopup from '../Dialog/CardPopup';
import Helpers from '../../theme/Helpers';

/**
 * @return {null}
 */
function TransparentPopup(props) {
  const { handleClose, children } = props;

  return (
    <CardPopup
      handleClose={handleClose}
      style={{
        backgroundColor: 'transparent',
        width: 'auto',
        maxHeight: 'auto',
        overflow: 'visible',
        padding: 0,
        ...Helpers.fillColCenter,
      }}
    >
      <FontAwesomeIcon
        icon={faTimes}
        size="2x"
        style={{
          position: 'absolute',
          top: -25,
          right: -25,
          cursor: 'pointer',
        }}
        onClick={handleClose}
      />
      {children}
    </CardPopup>
  );
}

export default TransparentPopup;