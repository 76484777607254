import React from 'react';

import Scene from './Scene';
import ThreeDWrapper from './3DWrapper';
import Helpers from '../../theme/Helpers';

const ThreeDTest = () => {
  return (
    <div style={{ background: 'black', ...Helpers.fillRowCenter, height: '100vh', width: '100vw' }}>
      <div style={{ height: 1080, position: 'relative', width: 1920 }}>
        <ThreeDWrapper frameLoop="always" noBackdrop>
          <Scene isTestMode withLight />
        </ThreeDWrapper>
      </div>
    </div>
  );
};

export default ThreeDTest;