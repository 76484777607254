import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';

const useStyles = makeStyles(() => ({
  avatar: {
    border: 'none',
    height: 82,
    width: 82,
    marginRight: 16,
  },
  streamerItem: {
    background: 'rgba(255, 255, 255, 0.04)',
    width: 'calc(100% - 64px)',
    padding: '22px 32px',
    ...Helpers.fillRowCross,
    marginRight: 3,
    position: 'relative',
    overflow: 'hidden',
  },
  descText: {
    fontSize: 14,
    color: Colors.halfWhite,
    marginTop: 2,
    '& span': {
      color: Colors.white,
    },
  },
  specialTextArea: {
    fontSize: 24,
    '& span': {
      fontWeight: 600,
    },
  },
}));

const CardShopMatchCardRightSideStreamerItem = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { loading, offer, avatar, name, isBigger, betweenText, style } = props;

  return (
    <div
      className={classes.streamerItem}
      style={isBigger ? { padding: '42px 32px', ...style } : {}}
    >
      {!loading ?
        <>
          <div style={{
            backgroundImage: `url("${avatar}")`,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            mixBlendMode: 'luminosity',
            position: 'absolute',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            backgroundPosition: 'center',
            opacity: 0.12,
            filter: 'blur(8px)',
          }} />

          <Avatar
            className={classes.avatar}
            alt="Avatar"
            src={avatar}
            style={isBigger ? { width: 116, height: 116 } : {}}
          />
          <div>
            {(isBigger && betweenText) ?
              <>
                <div className={classes.specialTextArea}
                     dangerouslySetInnerHTML={{ __html: t('you_can_support', { streamer: name }) }} />
                <div className={classes.descText}
                     dangerouslySetInnerHTML={{ __html: betweenText }} />
              </>
              :
              <div className={classes.specialTextArea}>
                {name}
              </div>
            }
            <div style={{ fontSize: 14, color: '#1FE325', marginTop: isBigger ? 15 : 9 }}>
              <i className="fa-light fa-circle-check"
                 style={{ fontSize: 16, marginRight: 8 }} />
              {t(offer ? 'every_album_purchase_supports_streamer' : 'every_card_purchase_supports_streamer')}
            </div>
          </div>
        </>
        :
        <div style={{ height: 'calc(126px - 44px)', width: '100%' }} />
      }
    </div>
  );
};

export default CardShopMatchCardRightSideStreamerItem;