import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks';

import { myCollectedPointsForTypeWithMyViewer } from '../../apollo/mergedQueries';

import Helpers from '../../theme/Helpers';

import conf from '../../conf.json';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import { isUserPremium } from '../../utils/helper';

const useStyles = makeStyles(() => ({
  wrapper: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    position: 'relative',
    padding: '7px 20px 7px 50px',
    fontSize: 14,
  },
  icon: {
    position: 'absolute',
    left: 15,
    height: 30,
    width: 17,
    background: `url(${process.env.PUBLIC_URL}/icons-png/energy.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 400%',
  },
  energy: {
    marginRight: 2,
    fontSize: 20,
  },
}));

const MyGameEnergy = (props) => {
  const classes = useStyles();
  const { game } = props;

  const { data } = useQuery(myCollectedPointsForTypeWithMyViewer, {
    variables: { game },
    fetchPolicy: useCacheWithExpiration('myCollectedPointsForTypeWithMyViewer', { game }),
    pollInterval: conf.poll_intervals.long_refresh,
  });

  const energyData = (data) && data.viewerRedeemedPoints;
  const myViewer = (data) && data.getMyViewer;
  const energy = energyData ? (energyData.points_season - energyData.redeemed_points_season) : 0;
  const { isPremiumActive } = isUserPremium(myViewer);
  const maxCoinsPerItem = isPremiumActive ? conf.premium_tank_size : 100;
  const limitReached = energy >= maxCoinsPerItem;
  let energyStep = limitReached ? 4 : Math.ceil(energy / 33);
  if (energyStep > 0)
    energyStep -= 1;

  return (
    <div className={classes.wrapper}>
      <div className={classes.icon} style={{ backgroundPosition: `0 ${energyStep * -30}px` }} />
      <span className={classes.energy}>{energy}</span>/{maxCoinsPerItem}
    </div>
  );
};

export default MyGameEnergy;