import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import { removePopupEvent } from '../../redux/actions';
import PopupButton from '../Button/PopupButton';

const useStyles = makeStyles(() => ({
  allPage: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 10,
    backgroundColor: 'rgba(0, 0, 0, .5)',
  },
  container: {
    position: 'absolute',
    zIndex: 150,
    width: 1200,
    height: 1200,
    ...Helpers.fillColCenter,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  button: {
    background: `url(${process.env.PUBLIC_URL}/buttons/popup.png)`,
    backgroundSize: '100% 200%',
    backgroundRepeat: 'no-repeat',
    padding: '10px 100px',
    color: Colors.white,
    textDecoration: 'none',
    fontSize: 15,
    ...Helpers.textCenter,
    fontFamily: 'Bebas Neue',
    cursor: 'pointer',
    '&:hover': {
      backgroundPosition: '0 -44px',
    },
  },
}));

const EventPopup = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { children, removePopupEvent, handleClose, hideConfirm } = props;

  const handleClosePopup = () => {
    removePopupEvent();
    handleClose();
  };

  return (
    <div className={classes.allPage}>
      <ClickAwayListener onClickAway={handleClosePopup}>
        <div className={classes.container}>
          {children}
          {!hideConfirm &&
          <PopupButton
            onClick={handleClosePopup}
            style={{ width: 260, padding: '17.5px 20px', zIndex: 4 }}
            backgroundPosition="0 -64px"
          >
            {t('okay')}
          </PopupButton>
          }
        </div>
      </ClickAwayListener>
    </div>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    removePopupEvent,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(EventPopup);