import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { zeroPad } from 'react-countdown';

import AnimationButton from '../Button/AnimationButton';

const AlertCapsuleRedeemButton = (props) => {
  const { redeemLockedUntil, handleClick, isAllowed, t, now, fontSize = 14 } = props;

  const untilSeconds = redeemLockedUntil ? moment(parseInt(redeemLockedUntil)).diff(now, 'seconds') : 0;
  
  const [counter, setCounter] = useState(untilSeconds);

  useEffect(() => {
    setCounter(untilSeconds)
  }, [ untilSeconds ])

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const minutes = Math.floor(counter / 60);
  const seconds = counter - (minutes * 60);

  return (
    <AnimationButton
      status={'neonRed'}
      onClick={handleClick}
      style={{
        fontFamily: 'Montserrat',
        fontSize,
        width: '100%',
        padding: 14,
      }}
      disabled={!isAllowed || counter > 0}
    >
      <i
        className={`fa-light fa-${counter > 0 ? 'loader fa-spin' : 'plus'}`}
        style={{ fontSize: 18, marginRight: 10, verticalAlign: 'bottom' }}
      />
      {counter > 0 ? `${zeroPad(minutes)}:${zeroPad(seconds)}` : t('redeem')}
    </AnimationButton>
  );
};

export default AlertCapsuleRedeemButton;
