import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { withRouter } from 'react-router-dom';

import Helpers from '../../theme/Helpers';
import QualityLine from '../QualityLine/QualityLine';
import Colors from '../../theme/Colors';
import { capitalize, getLevelWord, getMatchData } from '../../utils/helper';
import CardViewDialogContainer from '../../containers/Cards/CardViewDialog';
import MergedImage from '../MergedImage/MergedImage';

import conf from '../../conf.json';

const useStyles = makeStyles({
  container: {
    margin: 8,

    '&:hover': {
      '& $itemArea': {
        background: 'rgba(255, 255, 255, 0.08)',
      },
      '& $imageArea > div': {
        display: 'initial',
      },
    },
  },
  itemArea: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    width: 'calc(100% - 40px)',
    cursor: 'pointer',
    position: 'relative',
    padding: 20,
    transition: 'all 0.3s ease-out',
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
  },
  midAvatar: {
    width: 48,
    height: 48,
    border: 'none',
    marginRight: 8,
  },
  coloredBGLine: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: 3,
    borderRadius: '6px 6px 0px 0px',
  },
  smallBox: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    padding: 14,
    fontSize: 14,
    fontWeight: 300,
    height: 50,
  },
});

const InventoryColumnCard = (props) => {
  const classes = useStyles();
  const { t, card, isLegacy } = props;
  const [showPopup, setShowPopup] = useState(false);

  if (isLegacy) {
    card.rarity = card.match_card.level ? card.match_card.level : 0;
  }

  const levelWord = getLevelWord(null, card.rarity);
  const levelColor = Colors.level[levelWord];

  const {
    avatar,
    name,
    url,
  } = getMatchData(isLegacy ? card.match_card : card.card_template_metadata, 48, 48);

  return (
    <>
      <div className={classes.container}>
        <div
          className={classes.itemArea}
          onClick={() => setShowPopup(true)}
        >
          {!isLegacy &&
          <div className={classes.coloredBGLine} style={{
            background: `linear-gradient(0deg, ${levelColor}00 0%, ${levelColor} 49%, ${levelColor}00 100%)`,
          }} />
          }

          <div style={{ marginLeft: isLegacy ? 0 : 3, position: 'relative' }}>
            <MergedImage isLegacy={isLegacy} card={isLegacy ? card.match_card : card} height={102}
                         borderRadius={6} />
          </div>

          <div
            className={classes.smallBox}
            style={{ ...Helpers.fillRowCross, width: 202 }}
            onClick={() => props.history.push(url)}
          >
            <Avatar
              className={classes.midAvatar}
              alt="Avatar"
              src={avatar}
            />
            <div>
              <div style={{
                fontSize: 16,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                width: 150,
              }}>
                {name}
              </div>
              {!isLegacy &&
              <div style={{ fontSize: 14, color: Colors.halfWhite, fontWeight: 300 }}>
                {t(card.rarity >= 1 ? 'base_card' : 'edition_to_show')}
              </div>
              }
            </div>
          </div>

          <div className={classes.smallBox} style={{ ...Helpers.fillRowCross, width: 158 }}>
            <div style={{ marginRight: 9 }}>
              <img
                src={`${conf.cdn_url}/badges/card-level/${isLegacy ? 1 : card.rarity}.png?height=34`}
                alt="Level badge"
                style={{ verticalAlign: 'bottom' }}
              />
            </div>
            <div>
              <div
                style={{ fontSize: 16 }}>{isLegacy ? t('legacy') : capitalize(t(levelWord))}</div>
            </div>
          </div>

          <div className={classes.smallBox}>
            {isLegacy || (card.quality && card.quality < 0) ?
              <div
                style={{
                  width: 162, ...Helpers.fillRowCenter,
                  color: Colors.halfWhite,
                  height: '100%',
                }}>
                {t('no_quality_available')}
              </div>
              :
              <QualityLine
                quality={card.quality}
                width={[11, 47, 68, 23, 8]}
                spaceBetween={1}
                condition={card.condition}
              />
            }
          </div>

          <div className={classes.smallBox}
               style={{ width: 245, ...Helpers.fillRowCenter, color: Colors.halfWhite }}>
            {t('coming_soon')}
          </div>
        </div>
      </div>
      {showPopup &&
      <CardViewDialogContainer card={card} handleClose={() => setShowPopup(false)}
                               isLegacy={isLegacy} />
      }
    </>
  );
};

export default withRouter(InventoryColumnCard);