import React from 'react';
import { withRouter } from 'react-router-dom';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

import { changeUserTokenAndRedirect } from '../../redux/actions';
import { handleTwitchLogin } from '../../apollo/queries';
import { parseQueryOfUrl, useQueryParams } from '../../utils/helper';
import Loading from '../Loading/Loading';
import { logAmplitudeEvent } from '../../utils/amplitude';

const conf = require('../../conf');

/**
 * @return {null}
 */
function HandleLogin(props) {
  const { location } = props;
  const queryParams = useQueryParams();
  const referral = queryParams && queryParams.get('state');

  // As a precaution, no query is executed if no parameters are specified.
  if (!location.search)
    return null;

  const params = parseQueryOfUrl(location.search);
  const { code/*, scope*/ } = params;
  const platform = 'web-app';
  const variables = {
    code,
    platform,
    isPreview: !window.location.origin.includes('app.'),
  };
  if (referral && referral.length > 4) {
    variables.referrer = referral;
    if (conf.track_amplitude) logAmplitudeEvent('Registration by referral', {
      streamer: referral,
    });
  }

  return (
    <Query query={handleTwitchLogin} variables={variables}>
      {({ loading, error, data }) => {
        if (loading) return <Loading />;
        if (error) return error.message;

        if (data && data.handleTwitchLogin) {
          let route = '/hq/hub';
          const { token, first_login, error, broadcaster_type } = data.handleTwitchLogin;

          if (error)
            props.history.push('/limit');

          if (broadcaster_type === 'partner' && first_login)
            props.history.push('/schedule-partner-call');

          if (first_login) {
            window.localStorage.setItem(conf.storage_keys.first_login, 'true');
            ReactGA.event({ category: 'signup', action: 'registrierung' }, ['tracker1']);
            window.dataLayer.push({
              event: 'Registrierung',
            });
            if (global.gtag)
              global.gtag('event', 'conversion', { 'send_to': 'AW-659756760/06-sCIKA8MkBENitzLoC' });
            ReactPixel.track('CompleteRegistration', null);
            route = '/accept-terms';
          }

          if (token) {
            localStorage.removeItem(conf.storage_keys.sidekick_token);
            localStorage.setItem(conf.storage_keys.user_token, token);
            props.changeUserTokenAndRedirect(token, props.history, route);
          }
        }

        return null;
      }}
    </Query>
  );
}

const mapStateToProps = (state) => {
  const { main } = state;
  return { main };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    changeUserTokenAndRedirect,
  }, dispatch)
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HandleLogin));