import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitch } from '@fortawesome/free-brands-svg-icons';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: '#171153',
    border: '1px solid #7F42F6',
    width: 172,
    borderRadius: 50,
    padding: '12px 10px',
    ...Helpers.textCenter,
    color: Colors.white,
    textDecoration: 'none',
    fontSize: 18,
    fontFamily: 'Bebas Neue',
    zIndex: 100,
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: '#7F42F6',
    },
  },
}));

const TwitchButton = (props) => {
  const { onClick, text } = props;

  const classes = useStyles();

  return (
    <div className={classes.button} onClick={onClick}>
      <FontAwesomeIcon icon={faTwitch} color={Colors.white} style={{ marginRight: 10 }} />
      {text}
    </div>
  );
};

export default TwitchButton;