import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';
import AnimationButton from '../Button/AnimationButton';
import Colors from '../../theme/Colors';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';

const useStyles = makeStyles(() => ({
  infoAlert: {
    background: 'rgba(255, 49, 65, 0.2)',
    padding: '14px 26px',
    fontSize: 14,
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    color: Colors.white,
  },
  yellowBG: {
    background: 'rgba(255, 213, 49, 0.2)',
  }
}));

const GrantDiscordAccess = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {discordSetupProgress } = props;

  return (
    <>
      {discordSetupProgress && !discordSetupProgress.streamer_auth &&
      <CustomBackdropFilter
        className={classes.infoAlert}
        filter={'blur(50px)'}
        canvasFallback={true}
        html2canvasOpts={{
          allowTaint: true,
        }}
      >
        <div>
          <i className="fa-light fa-info-circle" style={{ marginRight: 8, fontSize: 17 }} />
          {t('give_discord_access')}
        </div>

        <AnimationButton
          status="whiteTransparency"
          onClick={() => window.location.href = 'https://discord.com/api/oauth2/authorize?client_id=981084807230525460&redirect_uri=https%3A%2F%2Fapp.streamheroes.gg%2Fstreamer-settings%2Falerts&response_type=code&scope=identify%20guilds'}
        >
          {t('grant_access')}
        </AnimationButton>
      </CustomBackdropFilter>
      }
      {discordSetupProgress && !discordSetupProgress.bot_invited &&
      <CustomBackdropFilter
        className={[classes.infoAlert, classes.yellowBG].join(' ')}
        filter={'blur(50px)'}
        canvasFallback={true}
        html2canvasOpts={{
          allowTaint: true,
        }}
      >
        <div>
          <i className="fa-light fa-info-circle" style={{ marginRight: 8, fontSize: 17 }} />
          {t('invite_discord_bot_desc')}
        </div>

        <AnimationButton
          status="whiteTransparency"
          onClick={() => window.open(
            'https://discord.com/api/oauth2/authorize?client_id=981084807230525460&permissions=268438528&scope=bot',
            '_blank',
          )}
        >
          {t('invite_discord_bot')}
        </AnimationButton>
      </CustomBackdropFilter>
      }
    </>
  );
};

export default GrantDiscordAccess;