import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { zeroPad } from 'react-countdown';

import PageTitle from '../PageTitle/PageTitle';
import Game from '../Minesweeper/Game';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import TopViewHeader from '../TopViewHeader/TopViewHeader';

const useStyles = makeStyles({
  limitText: {
    textAlign: 'center',
    color: Colors.primary,
    textTransform: 'none',
    padding: 5,
  },
  box: {
    ...Helpers.boxBasics,
    padding: 10,
    width: '50%',
    margin: '0 auto 0',
  },
});

const Limit = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [counter, setCounter] = useState(20);

  useEffect(() => {
    if (counter === 0) {
      props.history.push('/login');
    }
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return (
    <TopViewHeader>
      <PageTitle title="Heavy workload" />
      <div style={{ padding: 10 }}>
        <div className={classes.box}>
          <Typography variant="h3" className={classes.limitText}>
            <span>{t('limit_heavy_load')}</span>
          </Typography>
          <Typography variant="h3" className={classes.limitText}>
            <span>{t('limit_auto_login')} {zeroPad(counter)}</span>
          </Typography>
        </div>

        <Game />
      </div>
    </TopViewHeader>
  );
};

export default withRouter(Limit);