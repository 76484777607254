import React from 'react';
import { useTranslation } from 'react-i18next';

import OnBoardingStepFooter from './OnBoardingStepFooter';
import OnBoardingStepHeader from './OnBoardingStepHeader';
import CardToolFabricController from '../CardTool/CardToolFabricController';

const OnBoardingCardBuilder = (props) => {
  const { t } = useTranslation();
  const { viewer, cards, classes, nextStep } = props;

  return (
    <>
      <OnBoardingStepHeader
        header={t('on_boarding_card_head')}
        body={t('on_boarding_card_text')}
        classes={classes}
      />

      <CardToolFabricController cards={cards} myViewer={viewer} />

      <OnBoardingStepFooter
        nextStep={nextStep}
        save={nextStep}
        classes={classes}
        hide={!cards || (cards && cards.length < 5)}
      />
    </>
  );
};

export default OnBoardingCardBuilder;