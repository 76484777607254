import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import Helpers from '../../theme/Helpers';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';
import AnimationButton from '../Button/AnimationButton';
import Avatar from '@material-ui/core/Avatar';
import Colors from '../../theme/Colors';

const useStyles = makeStyles(() => ({
  container: {
    padding: '32px 16px',
    background: 'rgba(255, 255, 255, 0.08)',
    borderRadius: 4,
    margin: '0 4px',
    position: 'relative',
    overflow: 'hidden',
    '&.supported': {
      background: 'rgba(31, 227, 37, 0.08)',
    },
  },
  avatar: {
    width: 81,
    height: 81,
    border: 'none',
    marginRight: 12,
  },
  supportedText: {
    color: Colors.halfWhite,
    fontSize: 14,
    '& span': {
      color: Colors.white,
    },
  },
}));

const HubFavouriteStreamerItem = (props) => {
  const classes = useStyles();
  const { t, favStreamer } = props;
  const { streamer } = favStreamer;

  const hours = Math.round(favStreamer.watchtimeinminutes / 60);
  const hasSupported = favStreamer.amount > 0;

  return (
    <div className={[classes.container, hasSupported ? 'supported' : ''].join(' ')}>
      <div style={{
        backgroundImage: `url("${streamer.profile_image_url}")`,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        mixBlendMode: 'luminosity',
        position: 'absolute',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundPosition: 'center',
        opacity: 0.08,
        filter: 'grayscale(1) blur(6px)',
      }} />

      <div style={{ ...Helpers.fillRowCross }}>
        <Avatar
          className={classes.avatar}
          alt="Avatar"
          src={streamer.profile_image_url}
        />
        <div>
          <div>{streamer.display_name}</div>
          <div style={{ color: Colors.halfWhite, fontSize: 14, marginTop: 2 }}>
            {t('watchtime')} <span
            style={{ color: Colors.white }}>{hours} {t('hours_short')}</span> {t('this_month')}
          </div>
          {hasSupported &&
          <div style={{ color: '#1FE325', fontSize: 14, marginTop: 7 }}>
            <i className="fa-light fa-circle-check" style={{ marginRight: 8, fontSize: 16 }} />
            {t('supported_this_season')}
          </div>
          }
        </div>
      </div>
      <div style={{ margin: '32px 0', height: 1, background: 'rgba(255, 255, 255, 0.08)' }} />
      <div style={!hasSupported ? { ...Helpers.fillRowReverse } : {...Helpers.fillRowCross}}>
        {hasSupported ?
          <div style={Helpers.fillRowCross}>
            <i className="fa-light fa-hand-holding-heart"
               style={{ fontSize: 22, marginRight: 10 }} />
            <div className={classes.supportedText} dangerouslySetInnerHTML={{
              __html: t('you_supported_streamer_with_x', {
                streamer: streamer.display_name,
                amount: favStreamer.amount && favStreamer.amount.toFixed(2) / 100
                .toString()
                .replace('.', ','),
              }),
            }} />
          </div>
          :
          <AnimationButton
            status="whiteTransparency"
            style={{ marginTop: -10, marginBottom: -12 }}
            // onClick={() => props.history.push(`/card-shop/streamer/${streamer._id}`)}
            onClick={() => props.history.push(`/profile/${streamer.login}/overview`)}
          >
            {t('go_to_profile')}
          </AnimationButton>
        }
      </div>
    </div>
  );
};

export default withRouter(HubFavouriteStreamerItem);