import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  numberInput: {
    padding: 14,
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    backdropFilter: 'blur(50px)',
    borderRadius: 6,
    border: 0,
    color: 'white',
    textAlign: 'center',

    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      appearance: 'none',
      margin: 0,
    },
  },

  button: {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    backdropFilter: 'blur(50px)',
    borderRadius: 4,
    border: 0,
    color: 'white',
    flexGrow: 1,
    cursor: 'pointer',
    transition: 'all 300ms ease',

    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },

    '&:focus-visible': {
      outline: 'none',
    },
  },
});

const NumberInput = (props) => {
  const { max = 10, value = 1, onChange, disabled = false } = props;

  const [number, setNumber] = useState(value);

  const classes = useStyles();

  const updateNumber = (newNumber) => {
    if (isNaN(newNumber) || newNumber < 0) return;

    if (newNumber > max)
      newNumber = max;

    setNumber(parseInt(newNumber));

    onChange(newNumber);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'stretch', gap: 4 }}>
      <input
        type="number"
        min={1}
        max={max}
        className={classes.numberInput}
        value={number}
        onChange={(e) => updateNumber(e.target.value)}
        disabled={disabled}
      />

      <div style={{ display: 'flex', gap: 4, flexDirection: 'column' }}>
        <button className={classes.button} onClick={() => updateNumber(number + 1)}>
          {disabled && number === 3 ? <i className="fa-thin fa-lock" style={{ fontSize: 12 }} /> : '+'}
        </button>

        <button className={classes.button} onClick={() => updateNumber(number - 1)}>-</button>
      </div>
    </div>
  );
};

export default NumberInput;