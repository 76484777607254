import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import conf from '../../conf';

const useStyles = makeStyles({
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const Loading = (props) => {
  const classes = useStyles();
  const { style } = props;

  return (
    <div className={classes.centered}>
      <video width="100" height="120" loop autoPlay muted style={style}>
        <source src={conf.cdn_url + '/assets/animations/LoadingAnim_V2.webm'} type="video/webm" />
        Video tag not supported. Download the video <a
        href={conf.cdn_url + '/assets/animations/LoadingAnim_V2.webm'}>here</a>.
      </video>
    </div>
  );
};

Loading.propTypes = {
  style: PropTypes.object,
};

Loading.defaultProps = {
  style: {},
};

export default Loading;