import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useQuery } from '@apollo/react-hooks';

import { getAnswersByPoll } from '../../apollo/queries';

import PollViewDiagram from './PollViewDiagram';
import Loading from '../Loading/Loading';
import PollViewAnnotations from './PollViewAnnotations';

const PollViewAnswers = (props) => {
  const { t } = useTranslation();
  const { poll } = props;
  const { data, loading, error } = useQuery(getAnswersByPoll, {
    variables: { poll: poll._id },
  });

  if (error) return error.message;

  const answers = (data && data.getAnswersByPoll) && data.getAnswersByPoll;

  return (
    loading ?
      <Loading />
      :
      answers.length > 0 ?
        <React.Fragment>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom style={{ marginBottom: 0 }}>
              {t('diagram')}
            </Typography>

            <PollViewDiagram poll={poll} answers={answers} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom style={{ marginBottom: 0 }}>
              {t('annotations')}
            </Typography>

            <PollViewAnnotations answers={answers} />
          </Grid>
        </React.Fragment>
        :
        <div>{t('no_data_available')}</div>
  );
};

export default PollViewAnswers;