import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

import Colors from '../../theme/Colors';

import conf from '../../conf.json';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  redBadge: {
    background: '#E12138',
    borderRadius: 4,
    padding: '10px 12px',
    position: 'absolute',
    fontSize: 14,
    fontWeight: 500,
    '& i': {
      fontSize: 18,
      marginRight: 10,
    },
  },
}));

const CardShopPhysicalCard = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isSmall } = props;

  return (
    <>
      <div style={{ marginTop: isSmall ? 65 : 80, marginBottom: isMobile ? 20 : 0 }}>
        <div style={{ maxWidth: 652, fontSize: isMobile ? 18 : 36, fontWeight: 600, lineHeight: 1.25 }}>
          {t('what_special_about_card')}
        </div>
        <div
          style={{ maxWidth: 645, lineHeight: isMobile ? 1 : 2, color: Colors.halfWhite, marginTop: 32 }}
          dangerouslySetInnerHTML={{__html: t('what_special_about_card_desc')}}
        />
      </div>
      {!isMobile &&
      <div style={{
        position: 'relative', ...(isSmall ? {
          marginRight: -32,
          marginBottom: -32
        } : {})
      }}>
        <img
          src={`${conf.cdn_url}/assets/template/shop/flying_card.png${isSmall ? '?height=447' : ''}`}
          alt="Flying card"
        />
        <div className={classes.redBadge}
             style={{ top: isSmall ? 47 : 79, left: isSmall ? 11 : 23 }}>
          <i className="fa-light fa-fingerprint" />
          {t('every_card_is_unique')}
        </div>
        <div className={classes.redBadge}
             style={{ bottom: isSmall ? 129 : 229, left: isSmall ? -77 : -125 }}>
          <i className="fa-light fa-barcode-read" />
          {t('special_edding_code')}
        </div>
        <div className={classes.redBadge}
             style={{ bottom: isSmall ? 70 : 108, right: isSmall ? 32 : 117 }}>
          <i className="fa-light fa-circle-star" />
          {t('high_quality_print')}
        </div>
      </div>
      }
    </>
  );
};

export default CardShopPhysicalCard;