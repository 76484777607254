import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Helpers from '../../theme/Helpers';
import PagePopup from '../Dialog/PagePopup';
import CircleButton from '../Button/CircleButton';
import Colors from '../../theme/Colors';
import AnimationButton from '../Button/AnimationButton';

const useStyles = makeStyles({
  container: {
    ...Helpers.fillColCenter,
    height: '100%',
    maxWidth: 1440,
    margin: '0 auto',
    position: 'relative',
  },
  content: {
    ...Helpers.fillColCenter,
  },
  navRow: {
    ...Helpers.fillRowCenter,
    marginTop: 40,
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  navArrowIcon: {
    ...Helpers.fillColCenter,
    background: '#FFFFFF14',
    transition: 'all 0.3s ease-out',
    borderRadius: 6,
    marginRight: 12,
    height: 40,
    width: 40,
    '&:hover': {
      background: '#FFFFFF3D',
    }
  },
  discordIcon: {
    ...Helpers.fillColCenter,
    background: '#4F67EE',
    borderRadius: 22,
    height: 72,
    width: 72,
    fontSize: 30,
    marginBottom: 32,
  },
  title: {
    marginTop: 0,
    marginBottom: 10,
    fontSize: 26,
  },
  subTitle: {
    fontSize: 16,
    color: Colors.halfWhite,
    margin: 0,
  },
  indicatorsContainer: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    padding: '10px 20px',
    margin: '32px 0 28px',
    background: 'rgba(255, 255, 255, 0.04)',
    width: 754,
    borderRadius: 6,
  },
  circleLabeled: {
    ...Helpers.fillRowCross,
    width: '25%',
    minWidth: 160,
    fontSize: 12,
    whiteSpace: 'nowrap',
    '& > div': {
      marginRight: 10,
      fontSize: 18,
      width: 44,
      height: 44,
      minWidth: 44,
      minHeight: 44,
    },
  },
  suppressDiscord: {
    fontSize: 14,
    color: Colors.halfWhite,
    transition: 'all 0.3s ease-out',
    cursor: 'pointer',
    '&:hover': {
      color: Colors.white,
    },
  },
});

const DiscordAuthDialog = ({ t, onClose, onSuppressDiscord }) => {
  const classes = useStyles();
  return (
    <PagePopup>
      <div className={classes.container}>
        <div onClick={onClose} className={classes.navRow}>
          <div className={classes.navArrowIcon}>
            <i className="fa-light fa-arrow-left" />
          </div>
          <p>{t('back_to_overview')}</p>
        </div>

        <div className={classes.content}>
          <div className={classes.discordIcon}>
            <i className="fa-brands fa-discord" />
          </div>
          <h2 className={classes.title}>{t('grant_discord_access')}</h2>
          <p className={classes.subTitle}>
            {t('grant_discord_access_description')}
          </p>
        </div>

        <div className={classes.indicatorsContainer}>
          <div className={classes.circleLabeled}>
            <CircleButton status={'ok'}>
              <i className="fa-brands fa-twitch" />
            </CircleButton>
            {t('twitch_alert')}
          </div>
          <div className={classes.circleLabeled}>
            <CircleButton status={'ok'}>
              <i className="fa-light fa-waveform" />
            </CircleButton>
            {t('text_to_speech')}
          </div>
          <div className={classes.circleLabeled}>
            <CircleButton status={'err'} containerStyle={{ background: 'rgba(225, 33, 56, 0.18)' }}>
              <i className="fa-brands fa-discord" />
            </CircleButton>
            {t('discord_alert')}
          </div>
          <div className={classes.circleLabeled}>
            <CircleButton status={'ok'}>
              <i className="fa-light fa-font-case" />
            </CircleButton>
            {t('max_symbols')}
          </div>
        </div>
        <AnimationButton
          status="neonGreen"
          onClick={() => window.location.href = 'https://discord.com/api/oauth2/authorize?client_id=981084807230525460&redirect_uri=https%3A%2F%2Fapp.streamheroes.gg%2Finventory%2Falert-capsules&response_type=code&scope=identify'}
          style={{
            fontFamily: 'Montserrat',
            fontSize: 14,
            padding: 14,
            width: 236,
          }}
        >
          {t('grant_discord_access_button')}
        </AnimationButton>
        <p className={classes.suppressDiscord} onClick={onSuppressDiscord}>
          {t('use_without_discord')}
        </p>
      </div>
    </PagePopup>
  );
};

export default DiscordAuthDialog;
