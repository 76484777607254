import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';
import AnimationButton from '../Button/AnimationButton';

const OrderFormCheck = (props) => {
  const { t } = useTranslation();
  const { formValues, setEditMode, showButton } = props;

  return (
    <div style={Helpers.fillColCross}>
      <div>{formValues.forename} {formValues.surname}</div>
      <div>{formValues.street} {formValues.house_number}</div>
      <div>{formValues.address_suffix}</div>
      <div>{formValues.postal_code}-{formValues.country}, {formValues.city}</div>
      <div>{formValues.email}</div>

      {showButton &&
      <AnimationButton onClick={() => setEditMode(true)} style={{ marginTop: 20 }}>
        {t('edit')}
      </AnimationButton>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  const { formValues } = state;
  return { formValues };
};

export default connect(mapStateToProps)(OrderFormCheck);