import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Route } from 'react-router-dom';
import PageTitle from '../PageTitle/PageTitle';

function Status({ code, children }) {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.status = code;
        return children;
      }}
    />
  );
}

const useStyles = makeStyles({
  centerText: {
    marginTop: 64,
    margin: 10,
    textAlign: 'center',
  },
});

const NotFoundPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Status code={404}>
      <PageTitle title="Not found" />
      <Typography variant="h5" gutterBottom className={classes.centerText} color="primary">
        {t('not_exist')}
      </Typography>
    </Status>
  );
};

export default NotFoundPage;