import { useQuery } from '@apollo/react-hooks';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getMyDrops } from '../../apollo/mergedQueries';

import PageTitle from '../../components/PageTitle/PageTitle';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import Drops from '../../components/Drops/Drops';

const DropsContainer = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(2);
  const perPage = 10;

  const variables = {
    pagination: {
      page: 1,
      perPage,
    },
  };

  const { data, loading, fetchMore } = useQuery(getMyDrops, {
    variables,
    fetchPolicy: useCacheWithExpiration('getMyDrops'),
  });

  const myDrops = data && data.getMyDrops;
  const dropsCount = data && data.getMyDropsCount;

  return (
    <>
      <PageTitle title={t('drops')} />
      <Drops
        t={t}
        loading={loading}
        drops={myDrops && myDrops}
        hasMore={dropsCount && dropsCount > myDrops.length}
        onLoadMore={async () => {
          setPage(page + 1);
          try {
            await fetchMore({
              variables: { ...variables, ...{ pagination: { perPage, page } } },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                  getMyDrops: [...prev.getMyDrops, ...fetchMoreResult.getMyDrops],
                });
              },
            });
          } catch {
          }
        }}
      />
    </>
  );
};

export default DropsContainer;
