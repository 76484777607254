import React from 'react';
import { Query } from 'react-apollo';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { getCardsToApprove } from '../../apollo/queries';

import Loading from '../../components/Loading/Loading';
import ReviewCardsOverview from '../../components/ReviewCards/ReviewCardsOverview';
import Helpers from '../../theme/Helpers';
import { getRandomNumber } from '../../utils/helper';

const useStyles = makeStyles(theme => ({
  container: {
    ...Helpers.boxBasics,
    minWidth: 1400,
  },
  noCards: {
    padding: 50,
    textAlign: 'center',
    fontSize: 24,
  },
}));

const ReviewCardsContainer = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const randomNumber = getRandomNumber();

  return (
    <Query query={getCardsToApprove}>
      {({ data, loading, error }) => {
        if (loading) return <Loading />;
        if (error) return error.message;

        const cardsToApprove = (data) && data.getCardsToApprove;

        return (
          <div className={classes.container}>
            {cardsToApprove && cardsToApprove.length > 0 ?
              <ReviewCardsOverview cardsToApprove={cardsToApprove} randomNumber={randomNumber} />
              :
              <Typography
                variant="h6"
                color="secondary"
                gutterBottom
                className={classes.noCards}
              >
                {t('no_cards_to_review')}
              </Typography>
            }
          </div>
        );
      }}
    </Query>
  );
};

export default ReviewCardsContainer;