import React from 'react';

import Helpers from '../../theme/Helpers';

const Competition = () => {
  return (
     <>
        <p style={{ ...Helpers.textCenter }}>
           <h1>Giveway Guidelines</h1>
        </p>

        <p>
           <h2>1. Eligibility</h2>
        </p>
        <p>
           To enter the Streamheroes giveaway you need to create a Streamheroes
           account. OWN3D media GmbH and Streamheroes GmbH employees,
           contractors as well as their family members are excluded from taking
           part. Only citizens of the EU, Switzerland, or Liechtenstein may
           participate. EMP vouchers are only valid in German territory and can
           not be claimed and used in any other country.
        </p>

        <p>
           <h2>2. Agreement to Rules</h2>
        </p>
        <p>
           By participating, the Contestant (”You”) agree to be fully
           unconditionally bound by these Rules, and You represent and warrant
           that You meet the eligibility requirements. In addition, you agree to
           accept the decisions of Streamheroes GmbH as final and binding as it
           relates to the content of this campaign.
        </p>

        <p>
           <h2>3. Campaign Period</h2>
        </p>
        <p>
           Entries will be accepted starting on Thursday, September 1, 2022, at
           12 pm, and ending on December 1, 2022, at 11:59 pm.
        </p>

        <p>
           <h2>4. How to enter</h2>
        </p>
        <p>
           To enter the giveaway, you need to be registered to Streamheroes and
           must have crafted at least one card of the respective streamers
           booster packs. If a streamer decides to give away the prize on
           another way than through Streamheroes, he is solely responsible for
           this. The entry must fulfill the Campaign requirements, as specified,
           to be eligible to win the prize. Entries that do not adhere to the
           rules or specifications may be disqualified at the sole discretion of
           Streamheroes GmbH. You may enter only once. You may not enter more
           times than indicated by using multiple email addresses, identities,
           or devices in an attempt to circumvent the rules. If you use
           fraudulent methods or otherwise attempt to circumvent the rules, your
           submission may be removed from eligibility at the sole discretion of
           Streamheroes GmbH.
        </p>

        <p>
           <h2>5. Prizes</h2>
        </p>
        <p>
           The winner(s) will receive the prize a streamer has chosen from the
           prize pool announced here:
           https://app.streamheroes.gg/hq/neon-meets-ice No cash or other prize
           substitution shall be permitted except at Streamheroes GmbHs
           discretion. The prize is non-transferable. Any and all prize-related
           expenses, including without limitation any and all federal, state,
           and/or local taxes, shall be the sole responsibility of the Winner.
           No substitution of prize or transfer/assignment of prize to others or
           request for the cash equivalent by Winner is permitted. Acceptance of
           prize constitutes permission for Streamheroes GmbH to use Winner’s
           name, likeness, and entry for purposes of advertising and trade
           without further compensation, unless prohibited by law.
        </p>

        <p>
           <h2>6. Winner selection and notification</h2>
        </p>
        <p>
           Winner will be chosen at random under the supervision of Streamheroes
           GmbH or through the respective streamer. Winner will be notified by
           e-mail within 72 hours following selection of the Winner(s).
           Streamheroes GmbH shall have no liability for Winner’s failure to
           receive notices due to spam, junk e-mail or other security settings
           or for Winner’s provision of incorrect or otherwise non-functioning
           contact information. If Winner cannot be contacted, is ineligible,
           fails to claim the prize within 30 days from the time award
           notification was sent, or fails to timely return a completed and
           executed declaration and release as required, the prize may be
           forfeited and an alternate Winner selected. Receipt by Winner of the
           prize offered in this Campaign is conditioned upon compliance with
           any and all federal, state, and local laws and regulations. ANY
           VIOLATION OF THESE OFFICIAL RULES BY WINNER (at Streamheroes GmbH
           sole discretion) WILL RESULT IN WINNER’S DISQUALIFICATION AS WINNER
           OF THE CAMPAIGN, AND ALL PRIVILEGES AS WINNER WILL BE IMMEDIATELY
           TERMINATED.
        </p>

        <p>
           <h2>7. Rights granted by you</h2>
        </p>
        <p>
           By entering this giveaway (e.g., photo, video, text, etc.), You
           understand and agree that Streamheroes GmbH, anyone acting on behalf
           of Streamheroes GmbH, and Streamheroes GmbH licensees, successors,
           and assigns, shall have the right, where permitted by law, to print,
           publish, broadcast, distribute, and use in any media now known or
           hereafter developed, in perpetuity and throughout the World, without
           limitation, your entry, name, portrait, picture, voice, likeness,
           image, statements about the Campaign, and biographical information
           for news, publicity, information, trade, advertising, public
           relations, and promotional purposes. without any further
           compensation, notice, review, or consent.
        </p>

        <p>
           <h2>8. Privacy Policy</h2>
        </p>
        <p>
           Information submitted with an entry is subject to the Privacy Policy
           stated on the streamheroes.gg website.
        </p>
     </>
  );
};

export default Competition;