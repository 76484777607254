import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Helpers from '../../theme/Helpers';
import CardToolCardDesignItem from './CardToolCardDesignItem';

const CardToolSelectCardDesignItem = (props) => {
  const classes = useStyles();
  const { cardDesign, isActive, setActive } = props;
  const card = 1;

  let styles = classes.selectItem;
  if (isActive) styles += ' ' + classes.activeItem;

  return (
    <div className={styles} onClick={() => setActive()} style={Helpers.fillRow}>
      <CardToolCardDesignItem level={card} cardDesign={cardDesign} />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  selectItem: {
    border: '1px solid #421622',
    borderRadius: 4,
    padding: 5,
    backgroundColor: '#230B13',
    margin: 5,
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.text.fourth,
      border: '1px solid ' + theme.palette.text.fourth,
    },
  },
  activeItem: {
    color: theme.palette.text.third,
    border: '1px solid ' + theme.palette.text.third,
  },
}));

export default CardToolSelectCardDesignItem;