import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

const conf = require('../../conf');

class GAListenerActive extends React.Component {
  constructor (props) {
    super(props);

    const GACode = conf.google_analytics;
    const GADSCode = conf.google_ads;
    const FBCode = conf.fb_analytics;
    this.loadGoogleTag(GACode);
    this.loadGoogleTag(GADSCode);

    ReactGA.initialize([
      {
        trackingId: GACode,
        gaOptions: { name: 'tracker1', titleCase: false },
      }, {
        trackingId: GADSCode,
        gaOptions: { name: 'tracker2' },
      },
    ], { alwaysSendToDefaultTracker: false });

    const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
    const options = {
      autoConfig: true, 	// set pixel's autoConfig
      debug: false, 		// enable logs
    };
    ReactPixel.init(FBCode, advancedMatching, options);
  }

  componentDidMount () {
    this.sendPageView(this.props.history.location);
    this.props.history.listen(this.sendPageView);

    ReactGA.set({ PageLanguage: this.props.language }, ['tracker1']);
  }

  componentDidUpdate (prevProps) {
    if (prevProps.language !== this.props.language) {
      ReactGA.set({ PageLanguage: this.props.language }, ['tracker1']);
    }
  }

  sendPageView (location) {
    ReactGA.set({ page: location.pathname }, ['tracker1']);
    ReactGA.pageview(location.pathname, ['tracker1']);
    ReactPixel.pageView();
  }

  loadGoogleTag (GACode) {
    // Global site tag (gtag.js) - Google Analytics
    global.dataLayer = global.dataLayer || [];
    global.gtag = function () {
      global.dataLayer.push(arguments);
    };

    global.gtag('js', new Date());
    global.gtag('config', GACode);

    // Load GTag script async
    setTimeout(() => {
      let scriptTag = document.createElement('script');
      scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${GACode}`;
      document.body.appendChild(scriptTag);
    }, 1);
  }

  removeGA () {
    this._ga = ReactGA.ga;
    ReactGA.ga = null;
    global.gtag = [];
    global.dataLayer = [];
  }

  render () {
    return this.props.children;
  }
}

GAListenerActive.defaultProps = {
  language: 'en-en',
};

GAListenerActive.propTypes = {
  language: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(GAListenerActive);