import React from 'react';
import { withRouter } from 'react-router-dom';

import Colors from '../../theme/Colors';
import { getLevelWord, getMatchData } from '../../utils/helper';
import CardViewDialogBody from './CardViewDialogBody';

const CardViewDialog = (props) => {
  const { handleClose, information, isLegacy, isMyCard, myViewer } = props;

  const levelColor = information.rarity !== null ? Colors.level[getLevelWord(null, information.rarity)] : Colors.white;

  const { name, avatar, url } = getMatchData(information.card_template_metadata, 75, 75);

  return (
    <CardViewDialogBody
      logoUrl={avatar}
      information={information}
      levelColor={levelColor}
      handleClose={handleClose}
      name={name}
      price={information.price / 100}
      isLegacy={isLegacy}
      matchURL={url}
      isMyCard={isMyCard}
      myViewer={myViewer}
    />
  );
};

export default withRouter(CardViewDialog);