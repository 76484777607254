import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const Countdown = (props) => {
  const { t } = useTranslation();
  const { type, lastTime, isPremiumActive } = props;
  let countdown = moment();

  if (type === 'daily')
    countdown.add(1, 'days');
  else if (type === 'weekly')
    countdown.startOf('week')
    .add(1, 'week')
    .add(1, 'day');
  else if (type === 'coins')
    countdown = moment.unix(lastTime / 1000)
    .add(isPremiumActive ? 24 : 8, 'hour');

  if (type !== 'coins')
    countdown.hour(0)
    .minute(0)
    .second(0);

  return (
    <span>{t('countdown_refresh')} {countdown.fromNow()}</span>
  );
};

export default Countdown;