import React from 'react';

const TermsOfUseEN = () => {
  return (
    <>
      <p dir="ltr">
        Diese Nutzungsbedingungen regeln das Vertragsverhältnis für die Nutzung der Dienste die
        unter <a
        href="https://www.streamheroes.gg/">https://www.streamheroes.gg</a> oder über eine mobile
        Anwendung ("<b>App</b>") von der Streamheroes GmbH ("<b>Streamheroes</b>"), Triererstraße 5,
        66839 Schmelz, Deutschland, eingetragen im Handelsregister des Handelsgerichts Saarbrücken
        unter HRB 106787, Tel.: +43 650 4989 426, E-Mail: <a
        href="mailto:support@streamheroes.gg">support@streamheroes.gg</a>, UID-Nr.: DE 335845977,
        angeboten werden.
      </p>

      <h1 dir="ltr">
        GEGENSTAND DES VERTRAGS
      </h1>

      <p dir="ltr">
        STREAMHEROES bietet seinen Nutzern unter der Website ("<b>Platform</b>") under the
        website <a
        href="https://www.streamheroes.gg/">https://www.streamheroes.gg</a> bzw über die APP eine
        Online-Plattform, mit denen Nutzer die Möglichkeit haben digitale Karten zu sammeln
        ("<b>PLATTFORM</b>"). Die PLATTFORM bietet den Nutzern die Möglichkeit, Belohnungen beim
        Zusehen von bestimmten Streamern, welche Live-Streams via dem Online-Streamingdienst
        "<i>twitch.tv</i>" ("<b>TWITCH</b>") veröffentlichen, zu sammeln.
      </p>

      <p dir="ltr">
        Die Nutzer loggen sich hierbei mit den Benutzerdaten ihres TWITCH-Benutzerkontos über die
        Website oder APP auf der PLATTFORM ein und können bei bestimmten Streamern für jeweils fünf
        gesehene Minuten virtuelle Energie sammeln, die in virtuelle Karten umgetauscht werden
        können. Die Energiekosten für eine virtuelle Karte variieren je nach Level der Karte – je
        höher ein Level, desto teurer die Karte. Darüber hinaus kann Energie auch für virtuelle
        Münzen ("<i>Coins</i>") eingetauscht werden, mit denen bestimmte Sammelobjekte erworben
        werden können.
      </p>

      <p dir="ltr">
        Daneben hat der Nutzer die Möglichkeit, sein unter der PLATTFORM zur Verfügung gestelltes
        Profil durch bestimmte Informationen, Fotos oder andere digitale Inhalte anzupassen.
        Außerdem stellt STREAMHEROES auf der PLATTFORM Ranglisten bereit, bei denen Nutzer gemäß der
        Anzahl der gesehenen Minuten platziert werden.
      </p>

      <p dir="ltr">
        Eine nähere Beschreibung der PLATTFORM und ihrer Features kann unter <a
        href="https://app.streamheroes.gg/page/faq">https://app.streamheroes.gg/page/faq</a> eingesehen
        werden.
      </p>

      <h1 dir="ltr">
        NUTZUNG DER PLATTFORM
      </h1>

      <p dir="ltr">
        Der Zugang zur Nutzung der PLATTFORM setzt eine Anmeldung über TWITCH voraus. Hierzu meldet
        sich der Nutzer auf der PLATTFORM über den Button "<i>Twitch-Login</i>" mit den
        Benutzerdaten an, die er im Zuge der Erstellung eines Benutzerkontos auf TWITCH erhalten
        hat. Die PLATTFORM kann unmittelbar nach Durchführung der Anmeldung genutzt werden.
      </p>

      <p dir="ltr">
        Mit der Anmeldung zur PLATTFORM gemäß Abschnitt 2.1 stimmt der Nutzer diesen
        Nutzungsbedingungen und einem Vertragsverhältnis mit STREAMHEROES zu. Dies wird vom Nutzer
        ausdrücklich beim Einloggen durch Anklicken des Buttons „<i>Akzeptieren der
        Nutzungsbedingungen</i>“ bestätigt. Der Nutzer kann jederzeit die geltende Fassung dieser
        Nutzungsbedingungen auf unserer Homepage unter <a
        href="https://www.streamheroes.gg/page/terms-of-use">https://www.streamheroes.gg/page/terms-of-use</a> aufrufen,
        diese dauerhaft speichern und ausdrucken. Die Nutzung der PLATTFORM setzt die Akzeptanz
        dieser Nutzungsbedingungen voraus.
      </p>

      <p dir="ltr">
        STREAMHEROES räumt dem Nutzer das nicht-exklusive, beschränkbare, nicht übertragbare und
        nicht unterlizenzierbare, zeitlich auf die Laufzeit des Nutzungs- bzw Vertragsverhältnisses
        begrenzte Recht ein, die PLATTFORM in der jeweils aktuellen Fassung samt Updates und anderen
        Bestandteilen in Übereinstimmung mit den vorliegenden Nutzungsbedingungen zu verwenden.
      </p>

      <p dir="ltr">
        Der Nutzer ist selbst dafür verantwortlich, die notwendigen Voraussetzungen für die Nutzung
        der PLATTFORM, wie etwa einen ausreichend schnellen Internetanschluss oder sonstige
        Hardware- oder Softwarevoraussetzungen, zu schaffen.
      </p>

      <p dir="ltr">
        Der Nutzer nimmt zustimmend zur Kenntnis, dass diese Nutzungsbedingungen nur für die Nutzung
        der PLATTFORM gelten und STREAMHEROES keinerlei Einfluss darüber hat, welche Bedingungen der
        (Dritt-)Anbieter von TWITCH den Nutzern in Verbindung mit dem TWITCH-Benutzerkonto und der
        Nutzung von TWITCH, etwa beim Zuschauen von Streamern, auferlegt. Es obliegt allein dem
        Nutzer sich zu entscheiden, ob er ein entsprechendes TWITCH-Benutzerkonto zur Nutzung der
        PLATTFORM anlegt. Tut er dies, liegt es in seiner alleinigen Verantwortlichkeit die damit
        verbundenen Bestimmungen, wie insbesondere über die Nutzung von TWITCH, einzuhalten.
      </p>

      <p dir="ltr">
        Sofern der Zugang zur PLATTFORM über die APP erfolgt, ist diese nur für die Besitzer von iOS
        oder Android-Smartphones verfügbar. Die Einhaltung der allgemeinen Geschäftsbedingungen von
        Anbietern, deren Services für die Nutzung der APP benötigt werden (wie etwa Apple oder
        google), obliegt allein dem Nutzer.
      </p>

      <p dir="ltr">
        Streamheroes kann jeden Nutzer ohne Grund oder Ankündigung sperren, um unsere Partner und
        die Qualität unserer Plattform zu schützen.
      </p>

      <h1 dir="ltr">
        NUTZUNGSBESCHRÄNKUNGEN
      </h1>

      <p dir="ltr">
        Die Nutzung der PLATTFORM steht nur Personen über 14 Jahren zur Verfügung. Wenn das Alter
        des Nutzers zwischen 14 und dem Alter der Volljährigkeit in dem Rechtsgebiet seines
        Wohnsitzes liegt, kann die PLATTFORM unter Umständen nur nach Zustimmung der gesetzlichen
        Vertreter (wie zB eines Elternteils) genutzt werden.
      </p>

      <p dir="ltr">
        Der Nutzer darf die PLATTFORM nur für rechtmäßige und zulässige Zwecke zugreifen bzw sie für
        solche nutzen. Der Nutzer wird durch die Nutzung der PLATTFORM insbesondere nicht:
        <ul>
          <li>
            die Rechte von STREAMHEROES, anderen Nutzern oder dritten Personen (wie insbesondere,
            aber nicht ausschließlich, Datenschutz- und Veröffentlichungsrechte, Rechte am geistigen
            Eigentum bzw. sonstige Eigentumsrechte) verletzen, widerrechtlich verwenden oder gegen
            sie verstoßen;
          </li>
          <li>
            rechtswidrige, obszöne, beleidigende, bedrohende, belästigende, hasserfüllte,
            rassistische, sexistische, homophobe oder ethisch anstößige Inhalte teilen oder zu einer
            solchen Verhaltensweise anstiften oder ermuntern;
          </li>
          <li>
            Unwahrheiten, Falschdarstellungen oder irreführenden Aussagen tätigen, insbesondere wenn
            diese das Ansehen von STREAMHEROES schädigen sollten;
          </li>
          <li>
            illegale, unerwünschte oder unzulässige Mitteilungen verbreiten, wie insbesondere
            unzulässige Werbung, Spam-Mails oder Kettenbriefe;
          </li>
          <li>
            Informationen anderer Nutzer auf unzulässige oder unberechtigte Art und Weise erfassen
            oder sammeln; sowie
          </li>
          <li>
            direkt oder indirekt STREAMHEROES, deren Nutzer oder dritten Personen belasten,
            beeinträchtigen oder schädigen.
          </li>
        </ul>
      </p>

      <p dir="ltr">
        Soweit gesetzlich nicht ausdrücklich erlaubt, darf der Nutzer darf die PLATTFORM nicht
        verändern, verbreiten, kopieren, dekompilieren, neu anordnen, veröffentlichen, nachbauen,
        vervielfältigen oder abgeleitete Werke auf ihrer Grundlage anfertigen (oder andere
        unterstützen, dies zu tun). Insbesondere ist es dem Nutzer verboten (i) den Code der
        PLATTFORM einem reverse-engineering zu unterziehen oder sonst zu modifizieren (ii) Viren
        oder sonstige schädliche Software in die PLATTFORM einzuspielen; (iii) unberechtigten
        Zugriff auf die PLATTFORM zu erlangen oder diesen Dritten zu gewähren sowie (iv) Software
        oder APIs zu entwickeln und/oder bereitzustellen, die im Wesentlichen wie die PLATTFORM
        funktionieren.
      </p>

      <p dir="ltr">
        Es ist dem Nutzer lediglich gestattet, die PLATTFORM für seine eigenen, privaten Zwecke
        (ausgeschlossen sind kommerzielle oder gewerbliche Zwecke) zu nutzen, es sei denn der Nutzer
        ist gleichzeitig Kooperationspartner der PLATTFORM und hat die entsprechenden Bedingungen
        für eine solche Kooperation unter <a
        href="https://app.streamheroes/page/cooperation-agreement">https://app.streamheroes/page/cooperation-agreement</a> akzeptiert.
        Eine Weitergabe des Zugangs zur PLATTFORM an Dritte ist ausgeschlossen.
      </p>

      <h1 dir="ltr">
        PREIS
      </h1>

      <p dir="ltr">
        Die Nutzung der PLATTFORM erfolgt kostenlos. Kosten, die im Zusammenhang mit der Nutzung der
        PLATTFORM – wie etwa Kosten für den Datentransfer oder die Schaffung der Voraussetzungen
        nach Abschnitt 2.4 – anfallen, sind vom Nutzer zu tragen.
      </p>

      <p dir="ltr">
        Klarstellenderweise wird festgehalten, dass digitale Inhalte, die der Nutzer sich durch
        gesehene Minuten von TWITCH Streams verdient - wie etwa gesammelte Energie - keinen reellen
        wirtschaftlichen Wert darstellen. Diese Inhalte können und dürfen weder übertragen noch in
        FIAT-Währungen (oder sonstige geldwerte Leistungen) umgetauscht werden.
      </p>

      <p dir="ltr">
        STREAMHEROES bietet dem Nutzer die Möglichkeit, virtuelle Karten – auf Anfrage des Nutzers –
        als physische Karten anzufertigen und die physischen Karten entgeltlich zu erwerben. Für
        solche Bestellungen gelten gesondert die Bedingungen über den Kauf solcher physischen Karten
        (abrufbar unter <a
        href="https://www.streamheroes.gg/page/terms-and-conditions">https://www.streamheroes.gg/page/terms-and-conditions</a>)
        zwischen STREAMHEROES und dem Nutzer.
      </p>

      <h1 dir="ltr">
        DAUER UND KÜNDIGUNG
      </h1>

      <p dir="ltr">
        Das Vertragsverhältnis wird auf unbestimmte Dauer geschlossen.
      </p>

      <p dir="ltr">
        Der Nutzer hat das Recht, das Vertragsverhältnis jederzeit ohne Einhaltung einer
        Kündigungsfrist durch das Löschen seines Accounts zu kündigen. Die Kündigung erfolgt hierbei
        über die Bestätigung des Buttons "<i>Account Löschen</i>" in der Profilverwaltung (<a
        href="https://www.streamheroes.gg/settings">https://www.streamheroes.gg/settings</a>) oder
        über die Benutzereinstellungen in der APP. Alternativ kann eine Kündigung über das
        Kontaktformular oder per E-Mail unter <a
        href="mailto:support@streamheroes.gg">support@streamheroes.gg</a> erfolgen.
      </p>

      <p dir="ltr">
        STREAMHEROES hat das Recht, das Vertragsverhältnis unter Einhaltung einer Kündigungsfrist
        von einem Monat zu kündigen. Darüber hinaus ist STREAMHEROES berechtigt, das
        Vertragsverhältnis aus wichtigem Grund jederzeit mit sofortiger Wirkung aufzulösen. Als
        wichtiger Grund gilt insbesondere ein Verstoß gegen die Nutzungsbedingungen oder die
        Unzumutbarkeit einer weiteren Vertragsbeziehung mit dem Nutzer.
      </p>

      <h1 dir="ltr">
        WIDERRUFSRECHT UND ENTFALL DES RÜCKTRITTSRECHTS
      </h1>

      <p dir="ltr">
        Der Nutzer kann seine Zustimmung zum Vertragsverhältnis mit STREAMHEROES formlos (etwa per
        E-Mail oder Brief) innerhalb von 14 Tagen nach Vertragsabschluss ohne Angabe von Gründen
        widerrufen. Der Widerruf ist zu richten an
      </p>

      <p dir="ltr" style={{ textAlign: 'center' }}>
        Streamheroes GmbH<br />
        HRB 106787<br />
        Handelsgericht Saarbrücken<br />
        Triererstraße 5, 66839 Schmelz, Deutschland<br />
        E-Mail: <a href="mailto:support@streamheroes.gg">support@streamheroes.gg</a>
      </p>

      <p dir="ltr">
        Die Ausübung des Widerrufsrechts gemäß diesem Abschnitt 6 kann mittels des
        Muster-Widerrufsformulars erfolgen, welches sich am Ende der Nutzungsbedingungen befindet.
      </p>

      <p dir="ltr">
        Im Falle eines wirksamen Widerrufs sind die beiderseits empfangenen Leistungen
        zurückzugewähren und etwaige wirtschaftliche Vorteile sind herauszugeben. Verpflichtungen
        zur Rückerstattung müssen innerhalb von 30 Tagen erfüllt werden. Die Frist beginnt für den
        Nutzer mit der Absendung der Widerrufserklärung, für STREAMHEROES mit deren Empfang.
      </p>

      <p dir="ltr">
        Verbraucher haben kein Rücktrittsrecht, wenn mit der Ausführung einer Dienstleistung auf
        Verlangen des Verbrauchers vor Ablauf der Rücktrittsfrist begonnen wird; in einem solchen
        Fall entfällt das Rücktrittsrecht nach rechtlicher Belehrung über diese Folgen der
        vorzeitigen Ausführung.
      </p>

      <p dir="ltr" style={{ fontWeight: 'bold' }}>
        Der Nutzer nimmt hiermit zur Kenntnis und stimmt ausdrücklich zu, dass mit der erstmaligen
        Nutzung der PLATTFORM die Leistungsausführung von STREAMHEROES vor Ablauf der
        Rücktrittsfrist erfolgt und damit der Verlust des Rücktrittsrechts verbunden ist.
      </p>

      <h1 dir="ltr">
        SUPPORT UND UPDATES
      </h1>

      <p dir="ltr">
        STREAMHEROES bietet Support für die PLATTFORM über das Kontaktformular (<a
        href="https://www.streamheroes.gg/contact">https://www.streamheroes.gg/contact</a>) an.
      </p>

      <p dir="ltr">
        STREAMHEROES wird Updates und Fehlerkorrekturen nach eigenem Ermessen zur Verfügung stellen.
        STREAMHEROES ist nicht verpflichtet, bestimmte Updates zu entwickeln oder Wartungsleistungen
        zu erbringen.
      </p>

      <h1 dir="ltr">
        VERFÜGBARKEIT UND GEWÄHRLEISTUNG
      </h1>

      <p dir="ltr">
        Der Nutzer hat das Recht, Gewährleistungsansprüche im Rahmen der gesetzlichen
        Gewährleistungsbestimmungen geltend zu machen. STREAMHEROES leistet jedoch keine Gewähr
        <ul>
          <li>
            für eine ständige Verfügbarkeit der PLATTFORM. Ausfallszeiten durch Wartungen,
            Software-Updates und aufgrund von Umständen (wie etwa technischer Probleme Dritter,
            höherer Gewalt), die nicht in STREAMHEROESs Einflussbereich liegen und daher von
            STREAMHEROES auch nicht zu vertreten sind und durch die die Services von STREAMHEROES
            nicht erreichbar sind, können nicht ausgeschlossen werden;
          </li>

          <li>
            für Fehler, Störungen oder Schäden, die auf unsachgemäße Bedienung oder die Eingabe
            von Fehlinformationen durch den Nutzer zurückzuführen sind; sowie
          </li>

          <li>
            dafür, dass die Leistungen den Erwartungen des Nutzers entsprechen, insbesondere dass
            Erfolge aus der Nutzung der PLATTFORM sowie aus der Inanspruchnahme der PLATTFORM
            erzielt werden.
          </li>
        </ul>
      </p>

      <p dir="ltr">
        Die PLATTFORM wird im Ist-Zustand ("<i>as is</i>") gemäß dem aktuellen Stand der Technik
        und je nach Verfügbarkeit ("<i>as available</i>") bereitgestellt. Die Nutzung der PLATTFORM
        erfolgt auf Gefahr und Kosten des Nutzers.
      </p>

      <h1 dir="ltr">
        ENTFERNEN VON VIRTUELLEN KARTEN
      </h1>

      <p dir="ltr">
        Klarstellend wird festgehalten, dass die virtuellen Karten, die auf der PLATTFORM gegen
        virtuelle Energie erworben werden können, von den Kooperationspartnern der PLATTFORM
        entworfen wurden und STREAMHEROES von solchen Kooperationspartnern unter separaten
        Vereinbarungen das Recht eingeräumt wurde, die virtuellen Karten auf der PLATTFORM zum
        Sammeln anzubieten.
      </p>

      <p dir="ltr">
        Unter bestimmten Umständen kann es STREAMHEROES rechtlich nicht mehr möglich sein, bestimmte
        virtuelle Karten zum Sammeln anzubieten. In solch einem Fall behält sich STREAMHEROES das
        Recht vor, bestimmte virtuelle Karten auf der PLATTFORM zu entfernen und bereits gesammelte
        Karten zu löschen und dem Nutzer die entsprechenden virtuellen Energiekosten, die der Nutzer
        für die gelöschten Karten auf der PLATTFORM ausgegeben hat, zurückzuerstatten. STREAMHEROES
        leistet daher keine Gewährleistung dafür, dass derzeit angebotene oder erworbene virtuellen
        Karten auch in Zukunft verfügbar sein werden.
      </p>

      <h1 dir="ltr">
        HAFTUNG
      </h1>

      <p dir="ltr">
        Vorbehaltlich Abschnitt 10.2, ist STREAMHEROES Haftung aufgrund leichter Fahrlässigkeit,
        sofern keine wesentlichen Vertragspflichten verletzt werden, generell ausgeschlossen.
        STREAMHEROES haftet ausschließlich für Schäden, die auf vorsätzlichem oder grob fahrlässigem
        Verhalten von STREAMHEROES basieren.
      </p>

      <p dir="ltr">
        Die in Abschnitt 10.1 festgelegten Haftungsbeschränkungen gelten nicht für Schäden aus
        Verletzung des Lebens, des Körpers oder der Gesundheit an Personen sowie für den Fall, dass
        anders lautende zwingende gesetzliche Regelungen entgegenstehen.
      </p>

      <h1 dir="ltr">
        NUTZUNGSRECHTE AN INHALTEN DER NUTZER
      </h1>

      <p dir="ltr">
        Der Nutzer gewährt STREAMHEROES eine auf die Vertragsdauer befristete, weltweite,
        nicht-exklusive, unentgeltliche, unterlizenzierbare und übertragbare Lizenz zur Nutzung,
        Reproduktion, Verbreitung, Erstellung abgeleiteter Werke, Darstellung und Aufführung der
        Informationen, Daten und Inhalte, die der Nutzer auf der PLATTFORM übermittelt,
        veröffentlicht oder generiert ("<b>NUTZERINHALTE</b>"). Die im Rahmen dieser Lizenz
        gewährten Rechte beschränken sich auf den Zweck, das STREAMHEROES die PLATTFORM betreiben
        und bereitstellen kann. Davon umfasst sind etwa die Darstellung von Inhalten, die der Nutzer
        in seinem Profil hochlädt oder die Verknüpfung des Profils und des Fortschritts des Nutzers
        mit den Ranglisten auf der PLATTFORM.
      </p>

      <p dir="ltr">
        Für die NUTZERINHALTE ist der Nutzer alleine verantwortlich; insbesondere trifft
        STREAMHEROES keine Pflicht die Rechtmäßigkeit solcher Inhalte ständig zu kontrollieren. Der
        Nutzer hat unter anderem darauf zu achten, dass die NUTZERINHALTE keine Rechte Dritter, wie
        insbesondere Urheberrechte oder sonstige gewerbliche Schutzrechte, verletzen.
      </p>

      <p dir="ltr">
        STREAMHEROES behält sich das Recht vor, NUTZERINHALTE zu löschen, sofern STREAMHEROES
        Kenntnis von der Unrechtmäßigkeit von NUTZERINHALTEN erhält oder die NUTZERINHALTE gegen
        diese Nutzungsbedingungen (wie etwa die Nutzungsbeschränkungen gemäß Abschnitt 3) verstoßen.
      </p>

      <h1 dir="ltr">
        IMMATERIALGÜTERRECHTE
      </h1>

      <p dir="ltr">
        Mit Ausnahme der dem Nutzer in Abschnitt 2.3 eingeräumten Lizenz behält sich STREAMHEROES
        alle Rechte an der PLATTFORM einschließlich aller weltweiten Technologie-, Immaterialgüter-
        und Eigentumsrechte daran ausdrücklich vor. Dem Nutzer ist es nicht gestattet,
        Urheberrechtsvermerke oder sonstige Eigentumsvermerke von STREAMHEROES von der PLATTFORM zu
        entfernen, zu verbergen oder unkenntlich zu machen.
      </p>

      <h1 dir="ltr">
        LINKS
      </h1>

      <p dir="ltr">
        Die PLATTFORM kann Links zu Websites Dritter enthalten. Diese unterliegen nicht der
        Kontrolle und Verantwortung von STREAMHEROES. Zudem übernimmt STREAMHEROES keine Haftung für
        verlinkte Inhalte und externe Angebote.
      </p>

      <h1 dir="ltr">
        DATENSCHUTZ
      </h1>

      <p dir="ltr">
        STREAMHEROES ist verpflichtet, die Bestimmungen der Datenschutz-Grundverordnung (DSGVO)
        sowie allfällige weitere gesetzliche Geheimhaltungsverpflichtungen einzuhalten.
      </p>

      <p dir="ltr">
        Ferner verarbeitet STREAMHEROES zum Zweck der Vertragserfüllung die dafür erforderlichen
        personenbezogenen Daten. Die detaillierten datenschutzrechtlichen Informationen
        (Datenschutzmitteilung) gemäß Art 13 ff DSGVO sind unter <a
        href="https://www.streamheroes.gg/privacy">https://www.streamheroes.gg/privacy</a> verfügbar.
      </p>

      <h1 dir="ltr">
        ÄNDERUNG DER NUTZUNGSBEDINGUNGEN
      </h1>

      <p dir="ltr">
        STREAMHEROES behält sich das Recht vor, diese Nutzungsbedingungen von Zeit zu Zeit
        anzupassen, um insbesondere aber nicht ausschließlich, die gesetzlichen Bestimmungen oder
        Anforderungen einzuhalten, der Wirtschaftlichkeit zu entsprechen oder den Benutzerinteressen
        entgegenzukommen. Die Nutzungsbedingungen sind in der jeweiligen gültigen Fassung auf der
        PLATTFORM einsehbar.
      </p>

      <p dir="ltr">
        Bei Änderungen an diesen Nutzungsbedingungen wird STREAMHEROES entweder durch entsprechende
        Mitteilung auf der PLATTFORM die ausdrückliche Einwilligung zu den Änderungen durch den
        Nutzer einholen oder den Nutzer mindestens zwei Wochen vor Inkrafttreten der Änderungen per
        E-Mail an die letzte bekannte E-Mail-Adresse informieren.
      </p>

      <p dir="ltr">
        Die Mitteilung über die Änderung beinhaltet (i) die aktualisierten Nutzungsbedingungen, (ii)
        das Datum des Inkrafttretens der Änderungen, (iii) eine zweiwöchige Widerspruchsfrist sowie
        (iv) Informationen über die Folgen des Wegfalls eines Widerspruchs.
      </p>

      <p dir="ltr">
        Wenn der Nutzer den Änderungen nicht widerspricht, gelten die aktualisierten
        Nutzungsbedingungen als akzeptiert.
      </p>

      <p dir="ltr">
        Sollte der Nutzer den Änderungen widersprechen, ist STREAMHEROES berechtigt, das
        Vertragsverhältnis über die Nutzung der PLATTFORM mit sofortiger Wirkung zu kündigen, wenn
        eine Fortsetzung des Vertragsverhältnisses im Rahmen der geänderten Nutzungsbedingungen
        nicht möglich oder für STREAMHEROES unzumutbar ist.
      </p>

      <h1 dir="ltr">
        ONLINE-STREITBEILEGUNG
      </h1>

      <p dir="ltr">
        Zur Beilegung von Streitigkeiten aus Online-Kaufverträgen und
        Online-Dienstleistungsverträgen ist bei der Europäischen Kommission eine
        Online-Streitbeilegungs(OS)-Plattform eingerichtet. Verbrauchern gibt dies die Möglichkeit,
        Streitigkeiten zunächst außergerichtlich zu klären. Diese ist unter dem Link: <a
        href="https://webgate.ec.europa.eu/odr">https://webgate.ec.europa.eu/odr</a> aufrufbar.
        STREAMHEROES nimmt nicht an einem Streitbeilegungsverfahren vor einer
        Verbraucherschlichtungsstelle teil.
      </p>

      <h1 dir="ltr">
        SCHLUSSBESTIMMUNGEN
      </h1>

      <p dir="ltr">
        Wenn eine Bestimmung dieser Nutzungsbedingungen unwirksam ist oder wird oder dieser Vertrag
        eine Lücke enthält, berührt dies nicht die Gültigkeit der übrigen Bestimmungen.
      </p>

      <p dir="ltr">
        Diese Nutzungsbedingungen und die gesamten Rechtsbeziehungen und Streitigkeiten zwischen dem
        Nutzer und STREAMHEROES unterliegen ausschließlich österreichischem Recht mit Ausnahme der
        Verweisungsnormen und des UN-Kaufrechts. Gegenüber Verbrauchern gilt diese Rechtswahl nur
        insoweit, als dadurch keine zwingenden gesetzlichen Bestimmungen des Staates, in dem er
        seinen gewöhnlichen Aufenthalt hat, eingeschränkt werden.
      </p>

      <p dir="ltr">
        Gerichtsstand für alle Streitigkeiten aus oder in Zusammenhang mit diesen
        Nutzungsbedingungen ist das sachlich zuständige Gericht in Steyr, Österreich. Ist der Nutzer
        Verbraucher im Sinne des anwendbaren Rechts und hat er seinen Wohnsitz oder gewöhnlichen
        Aufenthalt im Inland oder ist im Inland beschäftigt, kann er nur abweichend vor jenen
        Gerichten geklagt werden, in deren Sprengel sein Wohnsitz, sein gewöhnlicher Aufenthalt oder
        sein Beschäftigungsort liegt.
      </p>

      <p dir="ltr">
        Sofern diese Nutzungsbedingungen in mehrere Sprachen übersetzt werden sollten, ist die
        deutsche Version die maßgebende Version.
      </p>

      <p dir="ltr" style={{ textAlign: 'center', fontWeight: 'bold' }}>
        <i>Muster-Widerrufsformular</i>
      </p>

      <p dir="ltr">
        Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden
        Sie es zurück an<br />
      </p>

      <p dir="ltr" style={{ textAlign: 'center' }}>
        Streamheroes GmbH<br />
        HRB 106787<br />
        Handelsgericht Saarbrücken<br />
        Triererstraße 5, 66839 Schmelz, Deutschland<br />
        E-mail: <a href="mailto:support@streamheroes.gg">support@streamheroes.gg</a>
      </p>

      <p dir="ltr">
        Hiermit widerrufe ich den von mir abgeschlossenen Vertrag über die Nutzung der
        Online-Dienstleistungen die unter https://www.streamheroes.gg angeboten werden.
      </p>

      <p dir="ltr">
        Bestellt am/ erhalten am:<br />
        Name des Verbrauchers:<br />
        Datum:<br />
        Unterschrift des Verbrauchers (nur bei Mitteilung auf Papier)
      </p>
    </>
  );
};

export default TermsOfUseEN;