import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PageTitle from '../../components/PageTitle/PageTitle';
import ViewerRankingContainer from './ViewerRanking';
import StreamerRankingContainer from './StreamerRanking';
import TopStreamerContainer from '../TopStreamer/TopStreamer';
import ViewerSeasonRankingContainer from './ViewerSeasonRanking';

const RankingsContainer = () => {
  const { t } = useTranslation();
  const { tab } = useParams();

  return (
    <>
      <PageTitle title={t('ranking')} />
      {tab === 'streamer' &&
      <StreamerRankingContainer />
      }
      {tab === 'viewer' &&
      <ViewerSeasonRankingContainer />
      }
      {tab === 'legacy-streamer' &&
      <TopStreamerContainer />
      }
      {tab === 'legacy-viewer' &&
      <ViewerRankingContainer />
      }
    </>
  );
};

export default RankingsContainer;