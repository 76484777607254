import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { withRouter } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import PageTitle from '../../components/PageTitle/PageTitle';
import TopViewHeader from '../../components/TopViewHeader/TopViewHeader';
import { isTabletOrMobileObj } from '../../utils/responsive';
import EmptyPopup from '../../components/Dialog/EmptyPopup';
import AnimationButton from '../../components/Button/AnimationButton';
import Colors from '../../theme/Colors';
import moment from 'moment';
import TermsOfUseDE from '../../components/OnePages/TermsOfUseDE';
import TermsOfUseEN from '../../components/OnePages/TermsOfUseEN';

const useStyles = makeStyles(() => ({
  container: {
    border: '1px solid #315AA5',
    borderRadius: 10,
    padding: 10,
    paddingRight: 0,
    marginBottom: 10,
  },
  termsBox: {
    height: 400,
    overflow: 'auto',
    paddingRight: 10,
    color: '#4D77AB',
    '& a': {
      color: Colors.white,
      '&:hover': {
        color: Colors.subTextColor,
      },
    },
  },
}));

const UserAcceptTermsContainer = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [accept, setAccept] = useState(false);

  const handleChange = (event) => {
    setAccept(event.target.checked);
  };

  const acceptTerms = () => {
    props.history.push('/on-tour');
  };

  const isTabletOrMobile = useMediaQuery(isTabletOrMobileObj);
  const lang = moment.locale();
  const isGerman = lang.includes('de');

  return (
    <TopViewHeader>
      <PageTitle title={t('terms_of_use')} />
      <EmptyPopup title={t('terms_of_use')} isTabletOrMobile={isTabletOrMobile}>
        <div className={classes.container}>
          <div className={classes.termsBox}>
            {isGerman ? <TermsOfUseDE /> : <TermsOfUseEN />}
          </div>
        </div>
        <FormControlLabel
          control={<Checkbox
            checked={accept}
            onChange={handleChange}
            name="acceptTerms"
            style={{ color: '#315AA5' }}
          />}
          label={<span dangerouslySetInnerHTML={{ __html: t('accept_conditions') }} />}
          style={{ color: '#4D77AB' }}
        />
        <br />
        <AnimationButton
          onClick={acceptTerms}
          disabled={!accept}
          style={{ marginTop: 20, padding: '10px 36px' }}
        >
          {t('accept')}
        </AnimationButton>
      </EmptyPopup>
    </TopViewHeader>
  );
};

export default withRouter(UserAcceptTermsContainer);