import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';

import { getAllBoosterWithShippingInformation } from '../../apollo/mergedQueries';

import PageTitle from '../../components/PageTitle/PageTitle';
import ErrorMessage from '../../components/ErrorBoundary/ErrorMessage';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import BoosterManagement from '../../components/BoosterManagement/BoosterManagement';

const BoosterManagementContainer = () => {
  const { t } = useTranslation();

  const { data, loading, error } = useQuery(getAllBoosterWithShippingInformation, {
    fetchPolicy: useCacheWithExpiration('getAllBoosterWithShippingInformation'),
  });

  if (error)
    return <ErrorMessage error={error} />;

  const allBoosters = (data) && data.getAllBooster;
  const countryCosts = (data) && data.getShippingInformation;
  let boosters = [];

  if (allBoosters)
    boosters = [
      ...allBoosters.approvedBooster.map(obj => ({ ...obj, isApproved: true })),
      ...allBoosters.unapprovedBooster.map(obj => ({ ...obj, isApproved: false })),
    ];

  return (
    <>
      <PageTitle title={t('booster_management')} />
      <BoosterManagement
        loading={loading}
        boosters={boosters}
        approvedBooster={allBoosters && allBoosters.approvedBooster}
        countryCosts={countryCosts}
      />
    </>
  );
};

export default BoosterManagementContainer;