import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import CardViewRedeemedCards from '../Cards/CardViewRedeemedCards';
import { returnMobileObj } from '../../utils/responsive';
import CardPopup from './CardPopup';
import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';

const useStyles = makeStyles(() => ({
  header: {
    background: '#1B325B',
    padding: 20,
    color: Colors.white,
    position: 'relative',
    paddingRight: 60,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    ...Helpers.textCenter,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 22,
  },
  body: {
    padding: 40,
    background: '#05132A',
    color: Colors.white,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
}));

function SelectCardShopPopup(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { handleClose, card, shopCard, updateQuantity } = props;

  return (
    <CardPopup
      handleClose={handleClose}
      style={{
        backgroundColor: 'transparent',
        width: 'auto',
        maxHeight: 'auto',
        overflow: 'auto',
        padding: 0,
      }}
    >
      <div className={classes.header}>
        {t('select_your_version')}
      </div>
      <div className={classes.body}>
        <CardViewRedeemedCards
          card={card}
          isShred={false}
          defaultSelectedCards={shopCard && shopCard.rCards ? shopCard.rCards : []}
          updateQuantity={updateQuantity}
          closePopup={handleClose}
        />
      </div>
    </CardPopup>
  );
}

export default SelectCardShopPopup;