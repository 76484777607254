import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PagePopup from '../Dialog/PagePopup';
import { useTranslation } from 'react-i18next';
import Avatar from '@material-ui/core/Avatar';
import Slider from 'react-slick';

import AnimationButton from '../Button/AnimationButton';
import Helpers from '../../theme/Helpers';
import SVGIcon from '../SVGIcon/SVGIcon';
import Colors from '../../theme/Colors';
import Loading from '../Loading/Loading';
import ClosePopupButton from '../Button/ClosePopupButton';
import { getMatchData } from '../../utils/helper';
import QualityLine from '../QualityLine/QualityLine';
import CardShopMatchCardLeftSideMenuDetails from '../CardShop/CardShopMatchCardLeftSideMenuDetails';
import MarketplaceHomeListItem from './MarketplaceHomeListItem';
import MarketplaceBuySellDialogInput from './MarketplaceBuySellDialogInput';
import CardImageDecision from '../Cards/CardImageDecision';

import conf from '../../conf.json';

const useStyles = makeStyles({
  container: {
    height: '100vh',
    maxWidth: 645,
    margin: '0 auto',
    position: 'relative',
    color: Colors.white,
    background: '#142134',
    borderRadius: 4,
    boxShadow: '0px 10px 36px #0000003D',
  },
  footerArea: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    padding: '0 16px 16px',
    background: '#1D2A3C',
    borderRadius: '0px 0px 4px 4px',
    borderTop: '1px solid rgba(255, 255, 255, 0.08)',
  },
  avatar: {
    width: 26,
    height: 26,
    border: 'none',
  },
  bigAvatar: {
    width: 41,
    height: 41,
    border: 'none',
  },
  fixWidth: {
    '& .slick-track': {
      height: 'auto !important',
    },

    '& .slick-vertical .slick-slide': {
      width: '100% !important',
    },
  },
  arrowArea: {
    background: 'rgba(255, 255, 255, 0.02)',
    width: '100%',
    ...Helpers.fillRowCenter,
    height: 38,
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.08)',
    },
  },
  tabItem: {
    padding: '10px 16px',
    color: Colors.halfWhite,
    transition: 'all 0.3s ease-out',
    borderBottom: '1px solid transparent',
    marginBottom: -1,
    fontSize: 14,
    cursor: 'pointer',
    '&.active': {
      color: Colors.white,
      borderBottomColor: Colors.white,
    },
    /*'&:hover': {
      color: Colors.white,
    },*/
  },
});

const MarketplaceBuySellDialog = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const slider = useRef(null);
  const {
    offerData,
    buyOffer,
    buyLoading,
    salesHistory,
    loading,
    price,
    avgPrice,
    resetData,
    myViewer,
    sellLoading,
    sellOffer,
    listingCoupons,
  } = props;
  const [activeTab, setActiveTab] = useState(1);

  const cardData = offerData && offerData.offer_match_model_meta_data;
  const card = cardData && {
    physical_card_layer_image: cardData.front_layers[0].physical_img_url,
    backImage: cardData.back_layers[0].physical_img_url,
    frontEffect: cardData.front_layers[1].physical_img_url,
    backEffect: cardData.back_layers[1].physical_img_url,
  };

  const {
    avatar,
    name,
  } = getMatchData(cardData && cardData.card_template_metadata.match_meta_data ? { match: cardData.card_template_metadata.match_meta_data } : {}, 36, 36);

  const getArrowArea = (isTop) => (
    (salesHistory && salesHistory.offers && salesHistory.offers.length > 3) ?
      <div className={classes.arrowArea} onClick={() => onSliderHandle(!isTop)}>
        <i
          className={`fa-light fa-chevron-${isTop ? 'up' : 'down'}`}
          style={{ fontSize: 10 }}
        />
      </div>
      : null
  );

  const onSliderHandle = (isNext) => {
    if (isNext)
      slider.current.slickNext();
    else
      slider.current.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 2,
    speed: 500,
    arrows: false,
    vertical: true,
  };
  const tabs = ['sales_statistics', 'sales_history'];

  return (
    <PagePopup handleClose={resetData} style={{ zIndex: 1000000 }}>
      <div className={classes.container}>
        <ClosePopupButton handleClose={resetData}
                          style={{ right: 16, top: 16, width: 30, height: 30 }} />

        <div style={{
          fontSize: 24,
          fontWeight: 600,
          padding: '17px 16px 16px',
          marginBottom: 3,
          background: '#1D2A3C',
        }}>
          {t('sell_on_market')}
        </div>

        <div className={classes.fixWidth}>
          <div style={{
            ...Helpers.fillRow,
            width: '100%',
          }}>

            <div style={{
              marginRight: 1.5,
              width: 'calc(100% - 32px)',
              background: 'rgba(255, 255, 255, 0.04)',
              padding: '10px 16px',
              position: 'relative',
            }}>
              {cardData &&
              <CardImageDecision
                card={card}
                information={cardData}
                style={{
                  height: 427,
                  width: 321,
                }}
                zoom={3.5}
                size={34}
              />
              }
            </div>

            <div style={{
              marginLeft: 1.5,
              width: 'calc(100% - 32px)',
            }}>
              {cardData ?
                <div style={{
                  position: 'relative',
                  height: 41,
                  width: 'calc(100% - 32px)',
                  marginBottom: 3,
                  background: 'rgba(255, 255, 255, 0.04)',
                  ...Helpers.fillRowCross,
                  padding: 16,
                }}>
                  <div style={{
                    backgroundImage: `url("${avatar}")`,
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    mixBlendMode: 'luminosity',
                    position: 'absolute',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100%',
                    backgroundPosition: 'center',
                    opacity: 0.12,
                    filter: 'blur(8px)',
                    ...Helpers.fillRowCross,
                  }} />

                  <Avatar
                    className={classes.bigAvatar}
                    alt="Avatar"
                    src={avatar}
                  />
                  <div style={{ marginLeft: 8 }}>
                    {name}
                  </div>
                </div>
                :
                <div style={{ height: 121, width: '100%' }} />
              }

              <div style={{
                background: 'rgba(255, 255, 255, 0.04)',
                padding: 16,
                position: 'relative',
              }}>
                {cardData &&
                <CardShopMatchCardLeftSideMenuDetails
                  loading={loading}
                  card={{ ...offerData, ...cardData }}
                  sm
                />
                }
                <div style={{ marginTop: 57 }}>
                  <QualityLine quality={cardData && cardData.quality} />
                </div>
              </div>
            </div>
          </div>

          <div style={{
            width: '100%', ...Helpers.fillRowCross,
            borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
          }}>
            {tabs.map((tab, index) => (
              <div
                className={[classes.tabItem, index === activeTab ? 'active' : ''].join(' ')}
                // onClick={() => setActiveTab(index)}
                style={index === 0 ? { cursor: 'not-allowed' } : {}}
              >
                {t(tab)}
              </div>
            ))}
          </div>

          {getArrowArea(true)}
          {loading ?
            <div style={{ ...Helpers.fillColCenter, width: '100%' }}>
              <Loading />
            </div>
            :
            salesHistory && salesHistory.offers && salesHistory.offers.length > 0 ?
              <Slider ref={slider} {...settings}>
                {salesHistory.offers.map(item => (
                  <MarketplaceHomeListItem key={item._id} item={item} isSold />
                ))}
              </Slider>
              :
              <div style={{ ...Helpers.fillColCenter, marginTop: 50 }}>
                <div
                  style={{ fontSize: 24 }}
                  dangerouslySetInnerHTML={{ __html: t('sales_history_no_sales') }}
                />
              </div>
          }
          {getArrowArea(false)}

        </div>

        <div className={classes.footerArea}>
          {(offerData && offerData._id) ?
            <>
              <div style={{ ...Helpers.fillRowCross }}>
                <div style={{ flex: 1 }}>
                  <div
                    style={{ ...Helpers.fillRowCross, fontSize: 24, fontWeight: 600 }}>
                    <SVGIcon
                      src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
                      height={30}
                      width={22}
                      style={{ marginRight: 5 }}
                    />
                    {price}
                  </div>
                  <div style={{ ...Helpers.fillRow, fontSize: 12, marginTop: 7 }}>
                    <div style={{ color: Colors.halfWhite }}>{t('proposed_price')}:</div>
                    <SVGIcon
                      src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
                      height={19}
                      width={14}
                      style={{ margin: '0 4px' }}
                    />
                    <div style={{ color: Colors.white }}>
                      &Oslash; {t('x_gems', { count: avgPrice })}
                    </div>
                  </div>
                </div>

                <div style={{
                  flex: 1,
                  padding: '21px 0 24px 16.25px',
                  borderLeft: '1px solid rgba(255, 255, 255, 0.08)',
                }}>
                  <div style={{ color: Colors.halfWhite, fontSize: 12, marginBottom: 6 }}>
                    {t('seller')}:
                  </div>
                  {loading ?
                    <Loading />
                    :
                    offerData &&
                    <div style={{ ...Helpers.fillRowCross, fontSize: 14, fontWeight: 500 }}>
                      <Avatar
                        className={classes.avatar}
                        alt="Avatar"
                        src={offerData.viewer.profile_image_url}
                        style={{ marginRight: 6 }}
                      />
                      {offerData.viewer.display_name}
                    </div>
                  }
                </div>
              </div>

              <AnimationButton
                status="neonPink"
                style={{ width: '100%', fontSize: 14, fontWeight: 500 }}
                disabled={buyLoading || !myViewer || (myViewer && (myViewer.premium_currency < price || myViewer._id === offerData.viewer._id))}
                onClick={() => buyOffer(offerData._id)}
              >
                {buyLoading ?
                  <i className="fa-light fa-loader fa-spin" style={{ fontSize: 18 }} />
                  :
                  <div style={Helpers.fillRowCenter}>
                    {t('buy_now_with')}
                    <SVGIcon
                      src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
                      height={25}
                      width={19}
                      style={{ margin: '0 8px' }}
                    />
                    {t('gems')}
                  </div>
                }
              </AnimationButton>
            </>
            :
            <MarketplaceBuySellDialogInput
              avgPrice={avgPrice}
              sellLoading={sellLoading}
              listingCoupons={listingCoupons}
              sellOffer={(value) => sellOffer({
                card_id: cardData._id,
                price: value,
              })}
            />
          }
        </div>
      </div>
    </PagePopup>
  );
};

export default MarketplaceBuySellDialog;
