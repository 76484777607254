import React from 'react';
import ImageUploader from 'react-images-upload';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Helpers from '../../theme/Helpers';

const CardToolImageUpload = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    disabled,
    setCanvasLoading,
    setLastImage,
    userImagePlaceholderUpload,
    level,
    uploadedImages,
    isLogo,
  } = props;

  const getFirstIndexOfUploadedImages = () => {
    let newIndex = null;
    const hasUploadedImages = [false, false, false];
    if (uploadedImages.length > 0) {
      uploadedImages.map(imgObj => {
        hasUploadedImages[imgObj.key] = true;
      });

      hasUploadedImages.map((bool, index) => {
        if (!bool && newIndex === null)
          newIndex = index;
      });
    }

    return newIndex === null ? 0 : newIndex;
  };

  /**
   * If user uploaded a image
   *
   * @param image
   */
  const onDrop = async (image) => {
    if (image.length > 0) {
      setCanvasLoading(true);
      const file = image[image.length - 1];
      const fileType = file.name.split('.')
      .pop();
      const uploadUserImageKey = isLogo ? 0 : getFirstIndexOfUploadedImages();
      setLastImage({
        value: image[image.length - 1],
        key: uploadUserImageKey,
      });
      const directory = isLogo ? 'user-logo-uploads' : 'user-uploads';
      const fileName = isLogo ? 'logo' : `i${uploadUserImageKey}`;

      await userImagePlaceholderUpload({
        variables: {
          directory,
          fileName: `${level}_${fileName}.${fileType}`,
        },
      });
    }
  };

  return (
    <div className={classes.wrapper}>
      <img
        src={process.env.PUBLIC_URL + '/icons-png/upload_image.png'}
        alt="upload"
        style={{ marginBottom: -30, padding: 30 }}
        width={80}
      />
      <div style={{ color: '#5D5D5D', textTransform: 'uppercase', fontWeight: 'bold' }}>
        {isLogo ? t('logo') : t('motive')}
      </div>
      {disabled &&
      <div className={classes.blackBox} />
      }
      <ImageUploader
        withIcon={false}
        buttonText={t(isLogo ? 'choose_logo' : 'choose_image')}
        onChange={disabled ? null : onDrop}
        fileSizeError={t('file_size_error')}
        fileTypeError={t('file_extension_not_supported')}
        imgExtension={isLogo ? ['.png'] : ['.jpg', '.png', '.jpeg']}
        maxFileSize={5000000}
        singleImage
        errorStyle={{ color: 'red' }}
        buttonStyles={{
          backgroundColor: '#cc1e31',
          borderRadius: 3,
          padding: '5px 20px',
          fontFamily: 'Bebas Neue',
          fontSize: 20,
          width: '100%',
        }}
        labelStyles={{
          color: '#6f6f6f',
          fontSize: 12,
          textTransform: 'uppercase',
        }}
        fileContainerStyle={{ backgroundColor: 'transparent' }}
      />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundColor: '#0d0d0d',
    ...Helpers.selfStretch,
    ...Helpers.textCenter,
    padding: 10,
    position: 'relative',
  },
  blackBox: {
    position: 'absolute',
    cursor: 'not-allowed',
    zIndex: 5,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
}));

export default CardToolImageUpload;