import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Helpers from '../../theme/Helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles(() => ({
  limitWarn: {
    backgroundColor: '#3e1c1f',
    color: '#cc1e31',
    padding: '10px 30px',
    ...Helpers.fillRowCross,
    whiteSpace: 'normal',
    fontWeight: 400,
  },
}));

const Alert = (props) => {
  const classes = useStyles();
  const { children, style } = props;

  return (
    <div className={classes.limitWarn} style={style}>
      <div style={{ marginRight: 20 }}>
        <FontAwesomeIcon icon={faExclamationTriangle} size="2x" />
      </div>
      {children}
    </div>
  );
};

Alert.propTypes = {
  style: PropTypes.object,
};

Alert.defaultProps = {
  style: {},
};

export default Alert;