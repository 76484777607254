import React from 'react';
import { withRouter } from 'react-router-dom';
import { MobileView, AndroidView, IOSView } from 'react-device-detect';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { useTranslation } from 'react-i18next';

function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };
}

const MobileViewComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  return (
      <MobileView>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={true}>
          <div style={modalStyle} className={classes.paper}>
            <h2 className={classes.modalHeader}>
              {t('mobile_open_app_title')}
            </h2>
            <p>
              {t('mobile_open_app_text')}
            </p>
            <AndroidView>
              <div>
                <a href="https://play.google.com/store/apps/details?id=gg.streamheroes.mobile.app">
                  <img
                    width={175}
                    src={process.env.PUBLIC_URL + '/google_play.png'}
                    alt="Google Play" />
                </a>
              </div>
            </AndroidView>
            <IOSView>
              <div>
                <a href="https://apps.apple.com/us/app/streamheroes/id1510956134">
                  <img
                    width={175}
                    src={process.env.PUBLIC_URL + '/apple-app-store-icon.png'}
                    alt="iOS App Store" />
                </a>
              </div>
            </IOSView>
          </div>
        </Modal>
      </MobileView>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '90%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid ' + theme.palette.text.third,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modalHeader: {
    color: theme.palette.text.third,
  },
}));

export default withRouter(MobileViewComponent);