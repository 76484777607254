import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation, useQuery } from '@apollo/react-hooks';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSnackbar } from 'notistack';
import { faLock, faTrash } from '@fortawesome/free-solid-svg-icons';

import { getInformationAboutCard } from '../../apollo/queries';
import { getAllMyRedeemableCardsOfCard } from '../../apollo/mergedQueries';
import { shredCard as shredCardMutation } from '../../apollo/mutations';

import Colors from '../../theme/Colors';
import Loading from '../Loading/Loading';
import Helpers from '../../theme/Helpers';
import PopupButton from '../Button/PopupButton';
import CustomButton from '../Button/CustomButton';
import { getMatchName } from '../../utils/helper';
import { returnMobileObj } from '../../utils/responsive';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import { logAmplitudeEvent } from '../../utils/amplitude';
import conf from '../../conf';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#051122',
    padding: 15,
    color: Colors.white,
    ...Helpers.fillCol,
    maxHeight: 260,
    overflow: 'auto',
  },
  iconClickable: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.9,
    },
  },
  row: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    paddingRight: 10,
    '&:hover': {
      backgroundColor: '#162233',
    },
  },
}));

const CardViewRedeemedCards = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    card,
    isShred,
    defaultSelectedCards,
    updateQuantity,
    closePopup,
    isTabletOrMobile,
  } = props;
  const [updateLoading, setUpdateLoading] = useState(false);
  const [openCard, setOpenCard] = useState(null);
  const [page, setPage] = useState(1);
  const [selectedCards, setSelectedCards] = useState([...defaultSelectedCards]);
  const perPage = 25;
  const cardId = card._id;

  const [shredCard] = useMutation(shredCardMutation, {
    onCompleted: () => {
      setUpdateLoading(false);
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
      setUpdateLoading(false);
    },
    refetchQueries: () => [
      {
        query: getAllMyRedeemableCardsOfCard,
        variables: { cardId, pagination: { page: 1, perPage } },
      },
      { query: getInformationAboutCard, variables: { id: cardId } },
    ],
  });

  const variables = { cardId, pagination: { page: 1, perPage } };
  const { data, loading, fetchMore } = useQuery(getAllMyRedeemableCardsOfCard, {
    variables,
    fetchPolicy: useCacheWithExpiration('getAllMyRedeemableCardsOfCard', variables),
  });

  const redeemedCards = (data) && data.getAllMyRedeemableCardsOfCard;
  const count = (data) && data.getAllMyRedeemableCardsOfCardCount;
  const cardObj = (data) && data.card;

  const deleteCard = () => {
    shredCard({
      variables: {
        cardId: openCard,
      },
    });
    handleClose();
  };

  const handleClickOpen = (redeemedCardId) => {
    setOpenCard(redeemedCardId);
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;

    const tmpCards = [...selectedCards];
    if (checked)
      tmpCards.push(name);
    else {
      const index = tmpCards.indexOf(name);
      if (index !== -1)
        tmpCards.splice(index, 1);
    }

    setSelectedCards(tmpCards);
  };

  const handleClose = () => {
    setOpenCard(null);
  };

  const confirm = (e) => {
    try {
      let matchType;
      let match;

      if (cardObj.matchModel === null) {
        matchType = 'StreamHeroes';
        match = 'StreamHeroes';
      } else if (cardObj.matchModel === 'Game') {
        matchType = 'Game';
        match = cardObj.match.name;
      } else {
        matchType = 'Streamer';
        match = cardObj.match.display_name;
      }

      const lowestCraftingNumber = redeemedCards[0].crafted_as_number;
      const checkSelectedCards = selectedCards.map(cardId => redeemedCards.find(rCard => rCard._id === cardId));
      const cardWithLowestCraftedNumber = checkSelectedCards.find(card => card.crafted_as_number === lowestCraftingNumber);

      if (conf.track_amplitude)
        logAmplitudeEvent('Add Product to Shopping Cart', {
          'Product ID': cardObj._id,
          //"Product Name": el.name,
          'Product Quantity': selectedCards.length,
          //"Product Price": el.price,
          'Product Match': match,
          'Product Match Type': matchType,
          'Product Type': cardObj.__typename,
          'Is card lowest crafted number': cardWithLowestCraftedNumber ? 'Yes' : 'No',
        });
    } catch (err) {
    }

    updateQuantity(e, selectedCards, cardObj);
    closePopup();
  };

  if (loading)
    return <Loading />;
  const isSpecial = !!card.specialcard;
  const matchCard = isSpecial ? card.specialcard : card.card;

  let energyRefund = 0;
  if (!isSpecial) {
    const refundPerCard = 3; // 6 / 2
    const minCardsToCraft = 5;
    const cardNeeds = [1, minCardsToCraft, (minCardsToCraft * minCardsToCraft), (minCardsToCraft * minCardsToCraft * minCardsToCraft), (minCardsToCraft * minCardsToCraft * minCardsToCraft * minCardsToCraft)]; // level 1 card needs


    //TODO fix by flo, clcik on wheel in card shop makes it crash

    //energyRefund = refundPerCard * cardNeeds[matchCard.level - 1];
  } else if (isSpecial) {
    //energyRefund = Math.floor(matchCard.costs / 2);
  }

  return (
    <>
      <div
        style={{ ...Helpers.fillRow, flexWrap: 'wrap', width: isTabletOrMobile ? '100%' : 'auto' }}>
        {!isTabletOrMobile &&
        <div style={{ marginRight: 20, ...Helpers.fillColCenter }}>
          <img src={`${cardObj.image}?height=300`} alt="Card" style={{ borderRadius: 4 }} />
          <div style={{ textTransform: 'uppercase', fontWeight: 'bold', margin: '10px 0 5px' }}>
            {getMatchName(cardObj)}
          </div>
          <div style={{ fontSize: 14, color: '#8190A7' }}>
            {isSpecial ? cardObj.card_name : `Level ${cardObj.level}`}
          </div>
        </div>
        }
        <div style={{
          marginLeft: isTabletOrMobile ? 0 : 20,
          width: isTabletOrMobile ? '100%' : 'auto',
        }}>
          <div
            className={classes.container}
            style={returnMobileObj(isTabletOrMobile, { padding: 5 })}
            id="scrollableDiv"
          >
            <InfiniteScroll
              dataLength={redeemedCards && redeemedCards.length}
              next={async () => {
                try {
                  await fetchMore({
                    variables: {
                      cardId,
                      pagination: { perPage, page: page + 1 },
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev;
                      setPage(page + 1);
                      return Object.assign({}, prev, {
                        getAllMyRedeemableCardsOfCard: [...prev.getAllMyRedeemableCardsOfCard, ...fetchMoreResult.getAllMyRedeemableCardsOfCard],
                      });
                    },
                  });
                } catch {
                }
              }}
              hasMore={(count && redeemedCards) && count > redeemedCards.length}
              loader={<Loading />}
              scrollableTarget="scrollableDiv"
            >
              {redeemedCards && redeemedCards.map(card => {
                const isLocked = isShred || card.vendor_lock || card.user_lock;

                return (
                  <div key={card._id} className={classes.row}>
                    {!isShred &&
                    <Checkbox
                      checked={selectedCards.includes(card._id)}
                      onChange={handleChange}
                      name={card._id}
                      color="default"
                      disabled={isLocked}
                    />
                    }
                    <div style={{ width: 60 }}>
                      #{card.crafted_as_number}
                    </div>
                    <div style={{ marginLeft: 5 }}>
                      {moment.unix(card.created_at / 1000)
                      .format('DD.MM.YYYY HH:mm')}
                    </div>
                    {isShred ?
                      updateLoading ?
                        <CircularProgress />
                        :
                        <FontAwesomeIcon
                          icon={isLocked ? faLock : faTrash}
                          size="sm"
                          className={isLocked ? '' : classes.iconClickable}
                          onClick={() => handleClickOpen(card._id)}
                        />
                      : null
                    }
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>

          {!isShred &&
          <CustomButton status="green" onClick={confirm} style={{ marginTop: 20, width: '100%' }}>
            {t('confirm')}
          </CustomButton>
          }
        </div>
      </div>

      <Dialog
        open={!!openCard}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('shred_card_title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('shred_card_text', { count: energyRefund })}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ ...Helpers.mainSpaceBetween }}>
          <PopupButton onClick={handleClose} style={{ padding: '10px 50px' }}>
            {t('cancel')}
          </PopupButton>
          <PopupButton onClick={deleteCard} style={{ padding: '10px 50px' }}>
            {t('shred')}
          </PopupButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CardViewRedeemedCards;