import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { zeroPad } from 'react-countdown';
import { fabric } from 'fabric';

import Helpers from '../../theme/Helpers';
import { rangeArray } from '../../utils/helper';
import CardToolSelectEffectStyle from './CardToolSelectEffectStyle';

const conf = require('../../conf');

const CardToolSelectEffect = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    canvas,
    checkIfObjectExists,
    selectedStyle,
    setSelectedStyle,
    activeEffect,
    setActiveEffect,
    cardFrame,
    setCanvasLoading,
    cuttedSize,
    fitSizeOfCardDesign,
    checkSortingOfObjects,
  } = props;
  const maxEffects = 28;
  const directory = `${conf.no_cors_cdn_url}/cards/maker/effects`;

  const updateEffect = (style, effect) => {
    setSelectedStyle(style);
    setActiveEffect(effect);
    const url = `${directory}/style-${zeroPad(style)}/effect-${zeroPad(effect)}/${zeroPad(cardFrame)}.png`;

    const effectObj = checkIfObjectExists('effect');
    if (effectObj)
      canvas.remove(effectObj);

    if (effect > 0) {
      setCanvasLoading(true);
      fabric.Image.fromURL(url, (effectObj) => {
        effectObj.id = 'effect';
        // effect is not selectable
        effectObj.selectable = false;
        effectObj.width = cuttedSize.width;
        effectObj.height = cuttedSize.height;
        fitSizeOfCardDesign(effectObj);
        canvas.add(effectObj);
        canvas.sendToBack(effectObj);
        checkSortingOfObjects();
      }, {
        crossOrigin: 'Anonymous',
        cropX: 35,
        cropY: 35,
      });
    }
  };

  return (
    <div>
      <div className={classes.wrapper}>
        <div style={{ width: 130, ...Helpers.textLeft }}>
          {t('select_effect')}
        </div>
        <CardToolSelectEffectStyle
          selectedStyle={selectedStyle}
          activeEffect={activeEffect}
          updateEffect={updateEffect}
        />
      </div>
      <div className={classes.effectsContainer}>
        {rangeArray(0, maxEffects)
        .map(effect => (
          effect === 0 ?
            <div
              className={[classes.borderPlace, classes.noEffect].join(' ')}
              key={`EffectKey${effect}`}
              style={activeEffect === effect ? { borderColor: 'red' } : {}}
              onClick={() => updateEffect(selectedStyle, effect)}
            />
            :
            <img
              src={`${directory.replace(conf.no_cors_cdn_url, conf.cdn_url)}/style-${zeroPad(selectedStyle)}/effect-${zeroPad(effect)}/00.png?height=80`}
              key={`EffectKey${effect}`}
              alt={`Effect ${effect}`}
              onClick={() => updateEffect(selectedStyle, effect)}
              className={classes.borderPlace}
              style={activeEffect === effect ? { borderColor: 'red' } : {}}
            />
        ))}
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    color: '#747474',
    marginBottom: 10,
  },
  effectsContainer: {
    height: 180,
    ...Helpers.fillRowCenter,
    flexWrap: 'wrap',
    overflow: 'auto',
  },
  noEffect: {
    width: 54,
    height: 80,
  },
  borderPlace: {
    margin: 5,
    border: '1px solid white',
    borderRadius: 4,
    cursor: 'pointer',
    '&:hover': {
      borderColor: 'orange',
    },
  },
}));

export default CardToolSelectEffect;