import React from 'react';
import { makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';

import Helpers from '../../theme/Helpers';

const useStyles = makeStyles(() => ({
  item: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    padding: 8,
    transition: 'all 0.3s ease-out',
    margin: '0 8px',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.08)',
    },
  },
  avatar: {
    width: 46,
    height: 46,
    border: 'none',
    marginRight: 12,
  },
}));

const CardShopTweet = (props) => {
  const classes = useStyles();
  const { tweet } = props;

  return (
    <div className={classes.item}>
      <img src={tweet.images[0]} alt="Tweet"
           style={{ width: 332, height: 332, objectFit: 'cover', borderRadius: 6 }} />

      <div style={{ marginTop: 14, marginBottom: 6, ...Helpers.fillRowCross }}>
        <Avatar
          className={classes.avatar}
          alt="Avatar"
          src={tweet.user_profile_img}
        />
        <div style={{
          height: 98,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}>
          {tweet.text}
        </div>
      </div>
    </div>
  );
};

export default CardShopTweet;