import React from 'react';
import DuotoneIconBase from './DuotoneIconBase';

const InventoryIcon = (props) => {
  const { forceActive = false } = props;

  return (
    <DuotoneIconBase
      forceActive={forceActive}

      DefaultIcon={
        <svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="-2 -2 26 26">
          <g>
            <g>
              <path fill="#FFFFFF" d="M19,22H3c-1.654,0-3-1.347-3-3.001C0,18.447,0.448,18,1,18c0.553,0,1,0.447,1,0.999S2.449,20,3,20h16
                                c0.551,0,1-0.449,1-1.001S20.448,18,21,18s1,0.447,1,0.999C22,20.653,20.653,22,19,22z" />
            </g>
            <g>
              <path fill="#FFFFFF" d="M21,20c-0.552,0-1-0.448-1-1.001C20,12.934,15.963,8,11,8s-9,4.934-9,10.999C2,19.552,1.553,20,1,20
                                c-0.552,0-1-0.448-1-1.001C0,11.832,4.934,6,11,6c6.064,0,11,5.833,11,13C22,19.552,21.552,20,21,20z" />
            </g>
            <g opacity="0.5">
              <path fill="#FFFFFF" d="M13,8c-0.191,0-0.384-0.055-0.555-0.168c-0.459-0.307-0.584-0.928-0.277-1.387L15.132,2H6.869l2.963,4.445
                                c0.306,0.459,0.182,1.08-0.278,1.387c-0.46,0.307-1.081,0.182-1.387-0.277l-4-6c-0.205-0.307-0.224-0.702-0.05-1.027
                                C4.292,0.204,4.632,0,5,0h12c0.369,0,0.707,0.204,0.881,0.528c0.175,0.326,0.156,0.72-0.049,1.027l-4,6C13.64,7.844,13.322,8,13,8
                                z" />
            </g>
          </g>
        </svg>
      }
      HoverIcon={
        <svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="-2 -2 26 26">
          <g>
            <g>
              <g>
                <path fill="#E12138" d="M12.999,8c-0.191,0-0.384-0.054-0.554-0.167c-0.459-0.306-0.584-0.928-0.277-1.387L15.131,2H6.869
                                    l2.963,4.445c0.307,0.459,0.182,1.081-0.277,1.387c-0.46,0.305-1.081,0.182-1.387-0.278l-4-6
                                    C3.964,1.248,3.945,0.854,4.119,0.528c0.173-0.324,0.513-0.527,0.882-0.527H17c0.369,0,0.707,0.203,0.881,0.527
                                    c0.174,0.325,0.156,0.72-0.05,1.026l-3.999,6C13.639,7.843,13.322,8,12.999,8z" />
              </g>
            </g>
            <path fill="#FFFFFF" d="M11,6C4.935,6,0,11.832,0,18.999c0,1.654,1.346,3,3,3h16c1.653,0,3-1.346,3-3C22,11.832,17.064,6,11,6z
                            M19,19.999H3c-0.551,0-1-0.449-1-1C2,12.935,6.038,8,11,8c4.963,0,9,4.935,9,10.999C20,19.55,19.551,19.999,19,19.999z" />
          </g>
        </svg>
      }
      ActiveIcon={
        <svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="-2 -2 26 26">
          <g>
            <g>
              <path fill="#E12138" d="M17.881,0.529C17.708,0.205,17.368,0,17.001,0h-12C4.632,0,4.292,0.205,4.119,0.529
                                c-0.174,0.326-0.155,0.72,0.049,1.027l4,6C8.354,7.833,8.666,8,9.001,8H13c0.335,0,0.647-0.168,0.832-0.445l3.999-6
                                C18.036,1.249,18.056,0.855,17.881,0.529z" />
            </g>
            <path fill="#FFFFFF" d="M11,6C4.935,6,0,11.832,0,19c0,1.654,1.346,3,3,3H19c1.653,0,2.999-1.346,2.999-3
                            C21.999,11.832,17.065,6,11,6z" />
          </g>
        </svg>
      }
    />
  );
};

export default InventoryIcon;