import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setCardIDShown } from '../../redux/actions';
import CardViewDialogContainer from '../../containers/Cards/CardViewDialog';
import StarterPackCardsDialog from '../Cards/StarterPackCardsDialog';
import StorageBoxCardsContainer from '../../containers/Inventory/StorageBoxCards';
import GemsOrderCompleteDialog from '../Gems/GemsOrderCompleteDialog';
import { useQueryParams } from '../../utils/helper';
import MarketplaceBuySellDialogContainer
  from '../../containers/Marketplace/MarketplaceBuySellDialogContainer';
import InventoryMatchMarketplaceContainer
  from '../../containers/Marketplace/InventoryMatchMarketplace';
import InventoryAllCardsSelectOneContainer
  from '../../containers/Marketplace/InventoryAllCardsSelectOne';
import MarketplaceSelectRedeemedCardToSellContainer
  from '../../containers/Marketplace/MarketplaceSelectRedeemedCardToSell';

const PopupArea = (props) => {
  const { boosterAction, setCardIDShown, pathname, marketplace } = props;
  const queryParams = useQueryParams();
  const gems = queryParams && queryParams.get('gems');
  const redirectStatus = queryParams && queryParams.get('redirect_status');
  const { cardIdShown, isAlbum } = boosterAction;

  return (
    <>
      <InventoryMatchMarketplaceContainer />
      <InventoryAllCardsSelectOneContainer />
      <MarketplaceSelectRedeemedCardToSellContainer />
      <StorageBoxCardsContainer />
      {(redirectStatus && gems) &&
      <GemsOrderCompleteDialog gems={parseInt(gems)} pathname={pathname} />
      }
      {(marketplace.offerId || marketplace.cardId) &&
      <MarketplaceBuySellDialogContainer marketplace={marketplace} />
      }
      {cardIdShown &&
      <>
        {Array.isArray(cardIdShown) ?
          <StarterPackCardsDialog
            cards={cardIdShown}
            handleClose={() => setCardIDShown(null)}
            isAlbum={isAlbum}
          />
          :
          <CardViewDialogContainer
            card={{ _id: cardIdShown }}
            handleClose={() => setCardIDShown(null)}
          />
        }
      </>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  const { boosterAction, marketplace } = state;
  return { boosterAction, marketplace };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setCardIDShown,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(PopupArea);