import React from 'react';
import { makeStyles } from '@material-ui/core';

import Helpers from '../../theme/Helpers';

const useStyles = makeStyles(() => ({
  bottomArea: {
    marginTop: 10,
    ...Helpers.fillRowCross,
    '& > div': {
      background: 'rgba(255, 255, 255, 0.06)',
      borderRadius: 2,
      padding: '3.5px 6px',
      marginRight: 10,
      cursor: 'pointer',
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.16)',
      },
    },
  },
}));

const InsertTextVariables = (props) => {
  const classes = useStyles();
  const { t, insertVariable } = props;

  return (
    <div className={classes.bottomArea} style={{ marginTop: 10, ...Helpers.fillRowCross }}>
      <div onClick={() => insertVariable('viewer')}>{t('viewer')}</div>
      <div onClick={() => insertVariable('rarity')}>{t('rarity')}</div>
      <div onClick={() => insertVariable('quality')}>{t('quality')} (%)</div>
      <div onClick={() => insertVariable('amount_in_cent')}>{t('order_amount')}</div>
      <div onClick={() => insertVariable('league')}>{t('league')}</div>
      <div onClick={() => insertVariable('league_string')}>{t('league_string')}</div>
    </div>
  );
};

export default InsertTextVariables;