import React from 'react';
import ReactDOM from 'react-dom';
import './fonts.css';
import './index.css';
import './utils/i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module';
// import * as Sentry from '@sentry/browser';

const conf = require('./conf.json');

const tagManagerArgs = {
  gtmId: conf.google_gtm,
};

TagManager.initialize(tagManagerArgs);
if (window.location.href.indexOf('localhost') === -1) {
  // Sentry.init({ dsn: 'https://e9b74c8a4ad948ffa903c0a9960764eb@sentry.io/2859082' });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
