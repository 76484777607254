import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Helpers from '../../theme/Helpers';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';
import AlertCapsuleDetails from './AlertCapsuleDetails';
import Colors from '../../theme/Colors';

const useStyles = makeStyles({
  container: {
    padding: '16px 16px 12px',
  },
  capsuleDetails: {
    marginTop: 8,
    width: '100%',
    fontSize: 12,
    fontWeight: 300,
    ...Helpers.fillRowCenter,
  },
  switchLeftRight: {
    left: 'initial !important',
    right: 'calc(100% - 20px)',
  },
});

const AlertCapsuleTooltip = (props) => {
  const classes = useStyles();
  const { className, t, switchLastHover } = props;

  return (
    <CustomBackdropFilter
      className={[className, switchLastHover ? classes.switchLeftRight : ''].join(' ')}
      filter={'blur(25px)'}
      canvasFallback={true}
      html2canvasOpts={{
        allowTaint: true,
      }}
    >
      <div className={classes.container}>
        <div style={{ fontSize: 18, fontWeight: 600 }}>
          {t('alert_capsule')}
        </div>
        <div style={{ color: Colors.halfWhite, marginTop: 12, fontSize: 14, marginBottom: 18 }}>
          {t('alert_capsule_description')}
        </div>
        <AlertCapsuleDetails {...props} />
        <div className={classes.capsuleDetails}>{t('inspect_capsule')}</div>
      </div>
    </CustomBackdropFilter>
  );
};

export default AlertCapsuleTooltip;
