import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'slick-carousel/slick/slick.css';

import Colors from '../../theme/Colors';

import conf from '../../conf.json';
import { rangeArray } from '../../utils/helper';

const useStyles = makeStyles(() => ({
  priceItem: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    position: 'relative',
  },
  pricesArea: {
    display: 'grid',
    margin: '32px 0',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: 26,
    '& > div:first-child': {
      gridColumnStart: 1,
      gridColumnEnd: 5,
    },
    '& > div:nth-child(2)': {
      gridColumnStart: 1,
      gridColumnEnd: 3,
    },
    '& > div:nth-child(3)': {
      gridColumnStart: 3,
      gridColumnEnd: 5,
    },
  },
}));

const CyberMeetsFirePrices = (props) => {
  const classes = useStyles();

  const { t } = props;

  const prices = t('landing_pages.cyber_meets_fire.prices.prices', { returnObjects: true });
  const priceGraphics = [
    `${conf.cdn_url}/assets/seasons/landingpage/prices/SH_Season0_Landingpage_Price_01_HightechPC.png`,
    `${conf.cdn_url}/assets/seasons/landingpage/prices/SH_Season0_Landingpage_Price_02_AOCMonitor.png`,
    `${conf.cdn_url}/assets/seasons/landingpage/prices/SH_Season0_Landingpage_Price_03_AOCMonitor.png`,
    `${conf.cdn_url}/assets/seasons/landingpage/prices/SH_Season0_Landingpage_Price_04_NintendoSwitch.png`,
    `${conf.cdn_url}/assets/seasons/landingpage/prices/SH_Season0_Landingpage_Price_05_06_NintendoSwitch.png`,
    `${conf.cdn_url}/assets/seasons/landingpage/prices/SH_Season0_Landingpage_Price_05_06_NintendoSwitch.png`,
    `${conf.cdn_url}/assets/seasons/landingpage/prices/SH_Season0_Landingpage_Price_07_Fridge.png`,
    `${conf.cdn_url}/assets/seasons/landingpage/prices/SH_Season0_Landingpage_Price_08_Fridge.png`,
    `${conf.cdn_url}/assets/seasons/landingpage/prices/SH_Season0_Landingpage_Price_09_XboxController.png`,
    `${conf.cdn_url}/assets/seasons/landingpage/prices/SH_Season0_Landingpage_Price_10_TwitchSubgifts.png`,
    `${conf.cdn_url}/assets/seasons/landingpage/prices/SH_Season0_Landingpage_Price_11_TwitchSubgifts.png`,
  ];

  return (
    <section style={{ textAlign: 'center', marginTop: 127 }}>
      <h1 style={{
        fontSize: 36,
        fontWeight: 500,
        margin: '0 0 4px 0',
        lineHeight: 1,
      }}>
        {t('landing_pages.cyber_meets_fire.prices.title')}
      </h1>

      <p style={{
        fontSize: 24,
        color: 'rgba(255, 255, 255, 0.5)',
        margin: '0 0 23px 0',
      }}>
        {t('landing_pages.cyber_meets_fire.prices.sub_title')}
      </p>

      <p style={{
        margin: '0 auto',
        maxWidth: 720,
      }}>
        {t('landing_pages.cyber_meets_fire.prices.description')}
      </p>

      <div className={classes.pricesArea}>

        <div className={classes.priceItem}>
          <div style={{
            background: `url("${conf.cdn_url}/assets/seasons/landingpage/prices/background_price1.png")`,
            height: 301,
            borderRadius: '6px 6px 0px 0px',
            backgroundSize: 'contain',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            zIndex: -1,
          }} />

          <div style={{ margin: '13px 0 47px' }}>
            <img src={priceGraphics[0]} alt="Price" />
          </div>

          <div style={{ padding: 32 }}>
            <h3 style={{
              fontSize: 18,
              fontWeight: 600,
              margin: '0 0 5px 0',
              lineHeight: 1,
              textTransform: 'uppercase',
            }}>
              {prices[0].title}
            </h3>

            <p style={{
              fontSize: 14,
              color: '#E12138',
              margin: '0 0 18px 0',
              fontWeight: 500,
            }}>
              {prices[0].promotion}
            </p>

            <p style={{
              margin: '0 auto',
              maxWidth: 308,
              fontSize: 14,
              color: Colors.halfWhite,
            }}>
              {prices[0].text}
            </p>
          </div>
        </div>

        {rangeArray(1, 2)
        .map(item => (
          <div className={classes.priceItem}>
            <div style={{
              background: `url("${conf.cdn_url}/assets/seasons/landingpage/prices/background_price2.png")`,
              height: 301,
              borderRadius: '6px 6px 0px 0px',
              backgroundSize: 'contain',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: -1,
            }} />

            <div style={{ margin: '25px 0 23px' }}>
              <img src={priceGraphics[item]} alt="Price" />
            </div>

            <div style={{ padding: 44 }}>
              <h3 style={{
                fontSize: 18,
                fontWeight: 600,
                margin: '0 0 5px 0',
                lineHeight: 1,
                textTransform: 'uppercase',
              }}>
                {prices[item].title}
              </h3>

              <p style={{
                fontSize: 14,
                color: '#E12138',
                fontWeight: 500,
              }}>
                {prices[item].promotion}
              </p>
            </div>
          </div>
        ))}

        {rangeArray(3, 10)
        .map(item => (
          <div className={classes.priceItem}>
            <div style={{ margin: '48px 0 20px' }}>
              <img src={priceGraphics[item]} alt="Price" />
            </div>

            <div style={{ padding: 32 }}>
              <h3 style={{
                fontSize: 18,
                fontWeight: 600,
                margin: '0 0 5px 0',
                lineHeight: 1,
                textTransform: 'uppercase',
              }}>
                {prices[item].title}
              </h3>

              <p style={{
                fontSize: 14,
                color: '#E12138',
                fontWeight: 500,
              }}>
                {prices[item].promotion}
              </p>
            </div>
          </div>
        ))}

      </div>
    </section>
  );
};

export default CyberMeetsFirePrices;