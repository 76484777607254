import React from 'react';
import { useTranslation } from 'react-i18next';

import PageTitle from '../../components/PageTitle/PageTitle';

import CyberMeetsFire from '../../components/Seasons/CyberMeetsFire';

const CyberMeetsFireContainer = () => {
  const { t } = useTranslation();  

  return (
    <>
      <PageTitle title={t('cyber_meets_fire')} />

      <CyberMeetsFire t={t} />
    </>
  );
};

export default CyberMeetsFireContainer;