import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Helpers from '../../theme/Helpers';
import { ordinal, replaceWidthAndHeight } from '../../utils/helper';
import Colors from '../../theme/Colors';
import CustomTooltip from '../Tooltip/CustomTooltip';

import conf from '../../conf.json';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';
import AnimationButton from '../Button/AnimationButton';

const useStyles = makeStyles(() => ({
  headText: {
    fontSize: 36,
    lineHeight: 1.35,
    fontWeight: 500,
    ...Helpers.textCenter,
  },
  streamArea: {
    background: 'rgba(255, 255, 255, 0.06)',
    ...Helpers.fillColMain,
    ...Helpers.crossStart,
    padding: 32,
    height: 'calc(292px - 64px)',
    width: 'calc(602px - 64px)',
    position: 'relative',
  },
  avatar: {
    width: 36,
    height: 36,
    border: '2px solid #EB233B',
  },
  viewerArea: {
    background: 'rgba(255, 255, 255, 0.06)',
    borderRadius: 5,
    padding: 6,
    fontSize: 12,
  },
}));

const RaffleEventOverview = (props) => {
  const classes = useStyles();
  const { t, matches, totalTickets } = props;
  const [matchIdx, setMatchIdx] = useState(0);

  const activeMatch = matches[matchIdx];
  const isLive = activeMatch.isLive;

  const changeMatch = (isLeft) => {
    if (isLeft && matchIdx > 0)
      setMatchIdx(matchIdx - 1);
    if (!isLeft && matchIdx < matches.length - 1)
      setMatchIdx(matchIdx + 1);
  };

  return (
    <div style={{ marginTop: 100 }}>
      <div className={classes.headText}>
        {t('the_event_overview')}
      </div>

      <div style={{ marginTop: 26, ...Helpers.fillRowCross }}>
        <div style={{
          background: 'rgba(255, 255, 255, 0.04)',
          borderRadius: 6,
          ...Helpers.fillColCenter,
          height: 292,
          width: 292,
        }}>
          <img
            src={`${conf.cdn_url}/Events/Dhalucard-Rapidlan2022/ticket_icon_100x100.png`}
            alt="Ticket icon"
            style={{ marginBottom: 6 }}
          />
          <div>{t('collected_tickets')}</div>
          <div style={{
            background: 'rgba(255, 255, 255, 0.06)',
            borderRadius: 15,
            padding: 8,
            width: 72,
            fontSize: 12,
            marginTop: 16,
            ...Helpers.textCenter,
          }}>
            {totalTickets}
          </div>
          <CustomTooltip title={t('raffle_how_to_get_tickets_explain')}>
            <div style={{
              fontSize: 12,
              color: Colors.halfWhite,
              textDecoration: 'underline',
              marginTop: 44,
              cursor: 'pointer',
            }}>
              {t('how_to_collect_tickets')}
            </div>
          </CustomTooltip>
        </div>

        <div
          style={{
            ...Helpers.fillRowCenter,
            marginLeft: 26,
            borderRadius: 6,
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          {isLive &&
          <div style={{ position: 'absolute', left: 12, bottom: 12, ...Helpers.fillRowCenter }}>
            <div style={{
              background: '#E12138',
              borderRadius: 5,
              padding: 6,
              fontSize: 12,
              textTransform: 'uppercase',
              marginRight: 6,
              ...Helpers.fillRowCenter,
            }}>
              <div style={{
                background: Colors.white,
                height: 10,
                width: 10,
                marginRight: 4,
                borderRadius: '50%',
              }} />
              <div>{t('live')}</div>
            </div>
            <CustomBackdropFilter
              className={classes.viewerArea}
              filter={'blur(30px)'}
              canvasFallback={true}
              html2canvasOpts={{
                allowTaint: true,
              }}
            >
              {activeMatch.viewer_count.toLocaleString()} {t('viewer')}
            </CustomBackdropFilter>
          </div>
          }

          <img
            src={isLive ? replaceWidthAndHeight(activeMatch.thumbnail_url, 520, 292) : 'https://via.placeholder.com/520x292'}
            style={isLive ? {} : { filter: 'contrast(0)' }}
            alt="Preview" />

          <CustomBackdropFilter
            className={classes.streamArea}
            filter={'blur(16px)'}
            canvasFallback={true}
            html2canvasOpts={{
              allowTaint: true,
            }}
          >
            <div style={{ position: 'absolute', bottom: 10, right: 10, ...Helpers.fillRowCenter }}>
              <AnimationButton
                status="whiteTransparency"
                style={{
                  width: 44,
                  height: 44, ...Helpers.fillColCenter,
                  borderRadius: 4,
                  marginRight: 3,
                }}
                disabled={matchIdx === 0}
                onClick={() => changeMatch(true)}
              >
                <i className="fa-light fa-chevron-left" style={{ fontSize: 18 }} />
              </AnimationButton>
              <AnimationButton
                status="whiteTransparency"
                style={{
                  width: 44,
                  height: 44, ...Helpers.fillColCenter,
                  borderRadius: 4,
                  marginLeft: 3,
                }}
                disabled={matchIdx === matches.length - 1}
                onClick={() => changeMatch(false)}
              >
                <i className="fa-light fa-chevron-right" style={{ fontSize: 18 }} />
              </AnimationButton>
            </div>

            <div style={{ ...Helpers.fillRowCenter }}>
              <Avatar
                className={classes.avatar}
                alt={activeMatch.name}
                src={activeMatch.avatar}
                style={!isLive ? { borderColor: Colors.gray } : {}}
              />
              <div style={{ marginLeft: 10 }}>
                <div>{activeMatch.name}</div>
                {isLive &&
                <div style={{ fontSize: 12, color: Colors.halfWhite }}>
                  {activeMatch.game.name}
                </div>
                }
              </div>
            </div>

            {isLive &&
            <div style={{ fontSize: 14, marginTop: 22 }}>
              {activeMatch.title}
            </div>
            }

            <AnimationButton
              status="whiteTransparency"
              style={{ padding: 8, width: 191, marginTop: 22 }}
              onClick={() => props.history.push(`/profile/${activeMatch.login}/booster`)}
            >
              {t('go_to_profile')}
            </AnimationButton>
          </CustomBackdropFilter>
        </div>
      </div>
    </div>
  );
};

export default withRouter(RaffleEventOverview);