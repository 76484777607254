import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import ProfileCommunityRankedBodyLeaders
  from '../../components/Profile/ProfileCommunityRankedBodyLeaders';
import ProfileCommunityRankedBodyTable
  from '../../components/Profile/ProfileCommunityRankedBodyTable';
import RankingTop from './RankingTop';
import Colors from '../../theme/Colors';
import { isUserPremium, rangeArray } from '../../utils/helper';
import Helpers from '../../theme/Helpers';
import Loading from '../Loading/Loading';
import Symplr from '../AdSense/Symplr';
import { isMobile } from 'react-device-detect';

import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  formInput: {
    width: 'fit-content',
    zIndex: 100000,
    background: 'transparent',
    borderColor: '#FFFFFF1F',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FFFFFF1F',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FFFFFF1F',
    },
    '& .MuiFormLabel-root': {
      color: Colors.halfWhite,
    },
  },
  filled: {
    fill: Colors.white,
    zIndex: 100000,
  },
  selectRoot: {
    zIndex: 100000,
  },
}));

const StreamerRanking = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    seasonCommunities,
    currentSeason,
    onLoadMore,
    season,
    setSeason,
    totalCount,
    loading,
    myViewer,
  } = props;

  const { isPremiumActive } = isUserPremium(myViewer);

  return (
    <div style={{ color: Colors.white }}>
      <RankingTop currentSeason={currentSeason} season={season} loading={loading} />

      <div className="inner-wrapper">
        <div style={{
          ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween,
          padding: isMobile ? '0 8px' : 0,
        }}>
          <div style={{ fontSize: 24, fontWeight: 600 }}>
            {t('leaderboard')}
          </div>

          {(conf.ad_area.ranking && myViewer && !isPremiumActive) &&
          <Symplr id="streamheroes.gg_lb_3" />
          }

          <FormControl variant="outlined" className={classes.formInput} required>
            <Select
              labelId="season-select-label"
              id="season-select"
              name="season"
              value={season}
              onChange={(event) => setSeason(parseInt(event.target.value))}
              classes={{
                icon: classes.filled,
                root: classes.selectRoot,
              }}
            >
              {rangeArray(0, 2)
              .map(season => (
                <MenuItem value={season} key={`SeasonKey${season}`}>
                  {t('season_x', { season })}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {loading ?
          <Loading />
          :
          <>
            {!isMobile &&
            <ProfileCommunityRankedBodyLeaders seasonData={seasonCommunities} />
            }
            <ProfileCommunityRankedBodyTable
              seasonData={seasonCommunities}
              onLoadMore={onLoadMore}
              totalCount={totalCount}
              isMobile={isMobile}
            />
          </>
        }
      </div>
    </div>
  );
};

export default StreamerRanking;