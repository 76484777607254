import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';

const useStyles = makeStyles({
  container: {
    marginTop: 10,
    color: Colors.halfWhite,
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 300,
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
  },
  textArea: {
    color: Colors.halfWhite,
    '& > span': {
      color: Colors.white,
    },
  },
});

const CardShopDiscount = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { discount, total } = props;

  if (!discount)
    return null;

  const couponString = discount.percent === 0 ? t('free_shipping_code') : `${discount.percent}% ${t('coupon_code')}`;

  return (
    <div className={classes.container}>
      <div
        className={classes.textArea}
        dangerouslySetInnerHTML={{ __html: t('order_discount_code', { coupon: couponString }) }}
      />

      <div>
        {((!discount.minAmount || total >= (discount.minAmount / 100)) && discount.amount_saved) ?
          <>
            {(discount.amount_saved / 100).toFixed(2)
            .toString()
            .replace('.', ',')} €
          </>
          :
          t(discount.amount_saved || total < (discount.minAmount / 100) ? 'requirements_not_reached' : 'calculate_shipping_costs')
        }
      </div>
    </div>
  );
};

export default CardShopDiscount;