import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import 'moment/min/locales';

import Helpers from '../../theme/Helpers';
import Loading from '../Loading/Loading';
import SpecialRedeemButton from '../Button/SpecialRedeemButton';

const useStyles = makeStyles({
  specialBox: {
    margin: '20px auto 0',
  },
  specialInnerBox: {
    padding: 10,
    ...Helpers.textCenter,
  },
  specialTextBox: {
    fontSize: 16,
    color: '#b60e2e',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2, /* number of lines to show */
    WebkitBoxOrient: 'vertical',
  },
});

function CardEventSpecialProgress(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { card, coinsInformation, redeemCard, loading } = props;
  const { match_card } = card;

  if (!coinsInformation || !coinsInformation.viewerRedeemedPoints)
    return null;

  const publicURL = process.env.PUBLIC_URL;
  const { viewerRedeemedPoints } = coinsInformation;

  const totalRedeemedPoints = viewerRedeemedPoints.points_season - viewerRedeemedPoints.redeemed_points;

  let matchCardModel = card.matchCardModel;
  if (!card.matchCardModel)
    matchCardModel = match_card.__typename;

  let momentStart = null, momentEnd = null, specialStarted = null, specialFinished = null;
  if (matchCardModel === 'SpecialCard') {
    momentStart = moment(parseInt(match_card.collectible_from));
    if (match_card.collectible_until) {
      momentEnd = moment(parseInt(match_card.collectible_until));
      specialFinished = momentEnd.isBefore();
    }
    specialStarted = momentStart.isBefore();
  }

  return (
    <>
      <div className={[classes.specialBox, 'special-center-button'].join(' ')}>
        <div className={classes.specialInnerBox}>
          {/*<div className={classes.specialTextBox}>
            {match_card.card_name}
          </div>*/}
          <div style={{
            fontFamily: 'Bebas Neue',
            fontSize: 26, ...Helpers.fillRowCenter,
            marginBottom: 20,
          }}>
            {specialStarted ?
              specialFinished ?
                <div style={{ color: '#F11035' }}>
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    size="lg"
                    style={{ marginBottom: 10 }}
                  />
                  <div style={{ textShadow: '0 0 5px black' }}>
                    {t('special_no_longer_available')}
                  </div>
                </div>
                :
                specialFinished === null ?
                  <></>
                  :
                  <div>
                  <span style={{ color: '#F11035' }}>
                    {momentEnd && momentEnd.toNow(true)}
                  </span><br />
                    {t('special_time_to_collect')}
                  </div>
              :
              <div>
                {t('special_available_at')}<br />
                <span style={{ color: '#F11035' }}>
                  {momentStart && momentStart.format('DD.MM.YYYY HH:mm')}
                </span>
              </div>
            }
          </div>
        </div>
        <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween, padding: 10 }}>
          {(match_card && match_card.max_count_per_viewer === 1) &&
          <div style={{
            color: '#ffc827',
            fontSize: 10,
            fontWeight: 600,
            textTransform: 'uppercase',
            ...Helpers.fillRowCenter,
          }}>
            <img
              src={publicURL + '/icons-png/unique.png'}
              alt="Unique star icon"
              style={{ marginRight: 5 }}
            />
            {t('special_unique_card')}
          </div>
          }
          {(match_card && match_card.limit !== null && match_card.limit !== undefined) &&
          <div style={{ fontSize: 12, fontWeight: 600, ...Helpers.fillRowCenter }}>
            <img
              src={publicURL + '/icons-png/card_special.png'}
              alt="Card icon"
              style={{ marginRight: 5 }}
            />
            {match_card.limit}
          </div>
          }
        </div>
      </div>
      {(specialStarted && !specialFinished) ?
        (!match_card.max_count_per_viewer || (match_card.max_count_per_viewer && match_card.max_count_per_viewer > 0 && card.count < match_card.max_count_per_viewer)) ?
          (loading) ?
            <Loading />
            :
            <div className="special-redeem-button" style={{ ...Helpers.fillRowCenter }}>
              <SpecialRedeemButton
                onClick={redeemCard}
                costs={match_card.costs}
                disabled={totalRedeemedPoints < match_card.costs}
              />
            </div>
          : null
        : null
      }
    </>
  );
}

CardEventSpecialProgress.propTypes = {
  card: PropTypes.object.isRequired,
};

export default CardEventSpecialProgress;