import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import FrequentLine from './FrequentLine';
import { getLevelWord, rangeArray } from '../../utils/helper';

import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  valueBox: {
    minWidth: 131,
    width: 131,
    fontWeight: 300,
    height: 86,
    borderRadius: 6,
    background: 'rgba(255, 255, 255, 0.1)',
    ...Helpers.fillColCenter,
    position: 'relative',
  },
}));

const CardExplanationRareness = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const specialCardColor = Colors.level.special;

  return (
    <>
      <div style={{ fontSize: 20, fontWeight: 500, color: Colors.white, marginBottom: 12 }}>
        2. {t('the_rarities')}
      </div>
      <div style={{
        fontSize: 16,
        fontWeight: 200,
        color: Colors.halfWhite,
        marginBottom: 42,
      }}>
        {t('the_rarities_body')}
      </div>
      <div style={{ fontSize: 16, fontWeight: 400, color: Colors.white, marginBottom: 14 }}>
        2.1 {t('listing_of_rarities')}
      </div>
      <div style={{
        ...Helpers.fillRowCenter,
        ...Helpers.mainSpaceBetween,
        marginBottom: 6,
      }}>

        {rangeArray(1, 5)
        .map(level => {
          const levelColor = Colors.level[getLevelWord('Card', level)];

          return (
            <div
              className={classes.valueBox}
              key={`RarenessLevel${level}`}
              style={{ color: levelColor }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  height: 3,
                  background: `linear-gradient(90deg, ${levelColor}00 0%, ${levelColor}BD 49%, ${levelColor}00 100%)`,
                }}
              />
              <img
                src={`${conf.cdn_url}/badges/card-level/${level}.png?height=29`}
                alt="Level badge"
                style={{ marginBottom: 6 }}
              />
              {t(`rareness_level.${level}`)}
            </div>
          );
        })}
      </div>

      <div style={{ color: Colors.halfWhite, ...Helpers.textCenter, fontSize: 14, fontWeight: 300 }}>
        {t('the_rarities_order')}
      </div>

      <FrequentLine />

      <div style={{
        fontSize: 16,
        fontWeight: 400,
        color: Colors.white,
        marginBottom: 14,
        marginTop: 58,
      }}>
        2.2 {t('event_cards')}
      </div>

      <div style={Helpers.fillRowCross}>
        <div className={classes.valueBox} style={{ color: specialCardColor }}>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: 3,
              background: `linear-gradient(90deg, ${specialCardColor}00 0%, ${specialCardColor}BD 49%, ${specialCardColor}00 100%)`,
            }}
          />
          <img
            src={`${conf.cdn_url}/badges/card-level/0.png?height=29`}
            alt="Special card badge"
            style={{ marginBottom: 6 }}
          />
          {t('rareness_level.0')}
        </div>
        <div style={{
          fontSize: 16,
          fontWeight: 200,
          color: Colors.halfWhite,
          marginLeft: 16,
        }}>
          {t('event_cards_body')}
        </div>
      </div>
    </>
  );
};

export default CardExplanationRareness;