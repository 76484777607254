import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';

import { transformCoins as transformCoinsMutation } from '../../apollo/mutations';
import { getAlertInformation, getTypeAndTimeBasedCoins } from '../../apollo/mergedQueries';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import Loading from '../Loading/Loading';
import { divideEnergyCoins, multiplyEnergyCoins, roundTo10Below } from '../../utils/helper';
import EmptyPopup from './EmptyPopup';
import AnimationButton from '../Button/AnimationButton';
import { returnMobileObj } from '../../utils/responsive';

const useStyles = makeStyles(() => ({
  textField: {
    background: '#0e0e0e',
    color: Colors.white,
    borderRadius: 5,
    width: 100,
    borderColor: '#4D5C73 !important',
  },
  selectBorder: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#4D5C73 !important',
    },
  },
  nextArrow: {
    margin: '0 20px',
    width: 12,
    '& > img': {
      height: '100%',
      width: '100%',
    },
  },
  innerContainer: {
    marginBottom: 20,
    ...Helpers.fillRowCenter,
    backgroundColor: '#010F22',
    padding: 50,
    borderRadius: 10,
  },
  attention: {
    borderRadius: 10,
    padding: 20,
    backgroundColor: '#E99A22',
    color: '#5B3005',
    margin: -40,
    marginTop: 40,
    ...Helpers.textCenter,
    fontWeight: 'bold',
  },
}));

/**
 * @return {null}
 */
function TransferPopup(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();
  const { handleClose, maxEnergy, type, id, myViewer, isTabletOrMobile } = props;
  const [value, setValue] = useState(0);
  const startDate = moment()
  .subtract(1, 'months')
  .format('YYYY-MM-DD');
  const maxCoinsTank = myViewer ? multiplyEnergyCoins(myViewer.max_coins_size, true) : 0;
  const currentCoins = myViewer ? multiplyEnergyCoins(myViewer.sh_coins, true) : 0;
  const maxCoinsToFill = maxCoinsTank - currentCoins;
  const checkingValue = multiplyEnergyCoins(10, true);
  const rightMaxEnergy = multiplyEnergyCoins(maxEnergy, true);

  const [transformCoins, { loading }] = useMutation(transformCoinsMutation, {
    onCompleted: (data) => {
      enqueueSnackbar(t('transfer_success'), { variant: 'success' });
      handleClose();
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [
      {
        query: getTypeAndTimeBasedCoins,
        variables: { pagination: { perPage: 25, page: 1 }, startDate },
      },
      { query: getAlertInformation },
    ],
  });

  const handleChange = (event) => {
    let newValue = event.target.value ? parseInt(event.target.value) : 0;
    if (newValue > rightMaxEnergy)
      newValue = rightMaxEnergy;
    if (newValue > checkingValue)
      newValue = roundTo10Below(newValue);
    if (Math.floor(newValue / checkingValue) > maxCoinsToFill)
      newValue = maxCoinsToFill * checkingValue;

    setValue(newValue);
  };

  const setMaxCoins = () => {
    if (rightMaxEnergy < checkingValue || maxCoinsToFill === 0)
      return;

    setValue(roundTo10Below(Math.floor(rightMaxEnergy / checkingValue) > maxCoinsToFill ? maxCoinsToFill * checkingValue : rightMaxEnergy));
  };

  const transfer = () => {
    const variables = {
      coins: divideEnergyCoins(value),
    };
    variables[type] = id;

    transformCoins({ variables });
  };

  return (
    <EmptyPopup
      handleClose={handleClose}
      title={t('transfer_title')}
      isTabletOrMobile={isTabletOrMobile}
    >
      <div>
        <div style={{ ...Helpers.textCenter, paddingBottom: 40 }}>
          <div style={{ fontFamily: 'Bebas Neue', fontSize: 26, color: Colors.white }}>
            {t('transfer_question')}
          </div>
          <div style={{ color: '#778FAF', fontWeight: 'bold', marginTop: 2 }}>
            {t('transfer_explain')}
          </div>
        </div>
        <div style={{
          marginBottom: 20,
          ...Helpers.fillRowCenter,
          backgroundColor: '#010F22',
          padding: isTabletOrMobile ? 5 : 50,
        }}>
          <img
            src={`${process.env.PUBLIC_URL}/icons-png/energy_solo.png`}
            alt="Energy"
            height={40}
            style={{ marginRight: 5 }}
          />
          <TextField
            id="value"
            value={value}
            onChange={handleChange}
            variant="outlined"
            className={classes.selectBorder}
            inputProps={{
              step: checkingValue,
              type: 'number',
              min: 0,
            }}
            classes={{ root: classes.textField }}
          />
          <div className={classes.nextArrow}>
            <img src={`${process.env.PUBLIC_URL}/icons-png/triangle.png`} alt="Next" />
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/icons-png/sh_coins.png`}
            alt="SH Coins"
            height={40}
            style={{ marginRight: 3 }}
          />
          <TextField
            value={value === 0 ? 0 : multiplyEnergyCoins(Math.floor(value / checkingValue))}
            variant="outlined"
            className={classes.selectBorder}
            classes={{ root: classes.textField }}
            inputProps={{ readOnly: true }}
          />
          {(rightMaxEnergy >= checkingValue && maxCoinsToFill > 0) &&
          <AnimationButton
            onClick={() => setMaxCoins()}
            style={{ marginLeft: 10, padding: '7px 25px' }}
          >
            {t('set_maximum')}
          </AnimationButton>
          }
        </div>
        <div style={Helpers.fillRowCenter}>
          {loading ?
            <Loading />
            :
            <AnimationButton
              onClick={transfer}
              disabled={value < checkingValue}
              style={{ padding: '7px 30px' }}
            >
              {t('transfer')}
            </AnimationButton>
          }
        </div>
      </div>
      <div
        className={classes.attention}
        style={returnMobileObj(isTabletOrMobile, { padding: 5 })}
        dangerouslySetInnerHTML={{ __html: t('transfer_attention') }}
      />
    </EmptyPopup>
  );
}

export default TransferPopup;