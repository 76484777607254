import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { faChevronDown, faChevronUp, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from '@material-ui/core/Link';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import FlagWithTooltip from '../FlagIcon/FlagWithTooltip';
import CustomTooltip from '../Tooltip/CustomTooltip';
import SupporterRank from '../Ranking/SupporterRank';

const useStyles = makeStyles(() => ({
  item: {
    padding: '15px 5px',
    color: Colors.white,
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    fontWeight: 'bold',
    fontSize: 15,
    borderBottom: '1px solid #353535',
  },
  listItemLastRefresh: {
    fontSize: 10,
    color: '#D3D3D3',
    fontWeight: 400,
  },
  flag: {
    border: '1px solid #a90b27',
  },
}));

function LeaderBoardListItem(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  let { entry, index, itemValue, isTabletOrMobile } = props;

  if (entry.entry)
    entry = entry.entry;

  if (entry.match)
    entry[entry.match.__typename.toLowerCase()] = entry.match;

  let isAnonymous = false;

  let itemText, link, country = null;

  if (entry.viewer) {
    const { viewer } = entry;
    itemText = viewer.display_name ? viewer.display_name : viewer.login;
    link = '/profile/' + viewer.login;
    country = viewer.country ? viewer.country : null;
    isAnonymous = viewer.login === 'anonymous' && viewer.profile_image_url === '';
  } else if (entry.streamer) {
    const { streamer } = entry;
    itemText = streamer.display_name;
    link = '/profile/' + streamer.login;
    isAnonymous = entry.is_anonymous;
  } else if (entry.game) {
    const { game } = entry;
    itemText = game.name;
    link = '/hq/game-streams/' + encodeURIComponent(game.name);
  }

  let value;
  if (entry.seq) value = t('points_x', { count: entry.seq.toLocaleString() });
  else if (entry.created_at) value = moment.unix(entry.created_at / 1000)
  .from();
  else if (entry.total_points !== undefined) value = t('points_x', { count: entry.total_points.toLocaleString() });
  else if (entry.points_season) value = t('points_x', { count: entry.points_season.toLocaleString() });

  if (itemValue !== undefined) value = t(itemValue, { count: entry.seq.toLocaleString() });
  const pos = index + 1;

  return (
    <div className={classes.item}>
      <div style={{ ...Helpers.fillRowCenter }}>
        <div style={{
          width: isTabletOrMobile ? 20 : 60,
          fontSize: 20,
          fontFamily: 'Bebas Neue',
          fontWeight: 400,
        }}>
          #{pos}
        </div>
        {((country && country.length === 2) || isAnonymous) ?
          <div style={{ margin: '0 10px' }}>
            {(country && country.length === 2 && !isAnonymous) &&
            <FlagWithTooltip code={country} />
            }
            {isAnonymous &&
            <CustomTooltip title={t('muted_streamer_of_viewer')}>
              <div style={{ cursor: 'pointer' }}>
                <FontAwesomeIcon icon={faInfoCircle} size="lg" />
              </div>
            </CustomTooltip>
            }
          </div>
          :
          <div style={{ marginRight: 48 }} />
        }
        <div>
          {isAnonymous ?
            'Anonymous' :
            <Link href={link}>
              {itemText}
            </Link>
          }
        </div>
      </div>
      <div style={{
        ...Helpers.fillRowCenter,
        fontSize: 16,
        fontWeight: 'normal',
        ...Helpers.textCenter,
        color: '#79859a',
      }}>
        {entry.overall_level &&
        <>
          {entry.viewer &&
          <div style={{ width: isTabletOrMobile ? 50 : 100 }}>
            <SupporterRank subscription={entry.viewer.subscription} showNone />
          </div>
          }
          <div style={{ width: isTabletOrMobile ? 50 : 100 }}>
            {pos === entry.last_ranking_position ?
              '-'
              :
              pos > entry.last_ranking_position ?
                <>
                  <FontAwesomeIcon icon={faChevronDown} color="#d21c35"
                                   style={{ marginRight: 5 }} />
                  {pos - entry.last_ranking_position}
                </>
                :
                <>
                  <FontAwesomeIcon icon={faChevronUp} color="#9ae251"
                                   style={{ marginRight: 5 }} />
                  {entry.last_ranking_position - pos}
                </>
            }
          </div>
          <div style={{ width: isTabletOrMobile ? 50 : 100 }}>
            {entry.overall_level}
          </div>
          <div style={{ width: isTabletOrMobile ? 60 : 120 }}>
            {entry.card_count && entry.card_count.toLocaleString()}
          </div>
        </>
        }
        <div
          style={{ width: isTabletOrMobile ? 60 : 120, ...Helpers.textRight, color: Colors.white }}>
          {value}
        </div>
      </div>
    </div>
  );
}

LeaderBoardListItem.propTypes = {
  entry: PropTypes.object,
  index: PropTypes.number,
};

export default withRouter(LeaderBoardListItem);