import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router-dom';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import SupporterRank from './SupporterRank';

const useStyles = makeStyles(() => ({
  container: {
    ...Helpers.fillCol,
    marginBottom: 10,
  },
  place: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainStart,
    background: Colors.subTextColor,
    padding: '10px 30px',
    margin: '10px 0',
  },
  value: {
    color: Colors.white,
    lineHeight: 1,
    width: 120,
    ...Helpers.textCenter,
  },
  pos: {
    padding: 20,
  },
  rightWrapper: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainEnd,
    flexWrap: 'wrap',
    flex: 1,
  },
}));

const MyRanking = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { entry, isTabletOrMobile } = props;

  if (!entry.total_points)
    entry.total_points = entry.seq;

  return (
    <div className={classes.container}>
      <div
        className={classes.place}
        key={`RankingLeaderKey#${entry.viewer._id}`}
      >
        <div className={classes.pos} style={{ color: Colors.white, padding: 5 }}>
          {t('my_ranking')}: #{entry.position}
        </div>
        <div className={classes.rightWrapper}>
          <div className={classes.value} style={{ width: isTabletOrMobile ? 50 : 100 }}>
            <SupporterRank subscription={entry.viewer.subscription} showNone />
          </div>
          <div className={classes.value} style={{ width: isTabletOrMobile ? 50 : 100 }}>
            {entry.position === entry.last_ranking_position ?
              '-'
              :
              entry.position > entry.last_ranking_position ?
                <>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    color="#d21c35"
                    style={{ marginRight: 5 }}
                  />
                  {entry.position - entry.last_ranking_position}
                </>
                :
                <>
                  <FontAwesomeIcon
                    icon={faChevronUp}
                    color="#9ae251"
                    style={{ marginRight: 5 }}
                  />
                  {entry.last_ranking_position - entry.position}
                </>
            }
          </div>
          <div className={classes.value} style={{ width: isTabletOrMobile ? 50 : 100 }}>
            {entry.overall_level}
          </div>
          <div className={classes.value} style={{ width: isTabletOrMobile ? 60 : 120 }}>
            {entry.card_count && entry.card_count.toLocaleString()}
          </div>
          <div className={classes.value}
               style={{ width: isTabletOrMobile ? 60 : 120, ...Helpers.textRight }}>
            {t('points_x', { count: entry.total_points.toLocaleString() })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(MyRanking);