import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';

import conf from '../../conf.json';
import { ProgressBar } from 'react-step-progress-bar';
import Countdown from '../Countdown/Countdown';
import { copyToClipboard } from '../../utils/helper';
import { useSnackbar } from 'notistack';
import AnimationButton from '../Button/AnimationButton';

const useStyles = makeStyles(() => ({
  rootItem: {
    '&:not(.isCollected):hover $overArea': {
      display: 'initial',
    },

    '&.isCollected $belowArea': {
      boxShadow: 'inset 0 -7px 36px 21px rgb(42 200 61 / 36%)',
    },
  },
  topArea: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: '6px 6px 0px 0px',
    width: '100%',
    height: '100%',
    position: 'relative',
    ...Helpers.fillRowCenter,
    filter: 'grayscale(100%)',
    '&.finished': {
      filter: 'none',
    },
  },
  belowArea: {
    background: 'rgba(255, 255, 255, 0.04)',
    overflow: 'initial',
    borderRadius: '0px 0px 6px 6px',
    boxSizing: 'border-box',
    height: 'calc(176px)',
    position: 'relative',
    padding: 16,
    ...Helpers.fillCol,
    ...Helpers.mainSpaceBetween,
  },
  overArea: {
    background: 'rgba(255, 255, 255, 0.04)',
    position: 'absolute',
    display: 'none',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    borderRadius: '0px 0px 6px 6px',
    zIndex: 1,
  },
  transparentBG: {
    height: 190,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  questBadge: {
    fontSize: 12,
    fontWeight: 500,
    margin: '-32px auto 0',
    zIndex: 2,
    padding: '8px 30px',
    borderRadius: 16,
    width: 'fit-content',
    background: 'rgba(255, 255, 255, 0.14)',
    '&.finished': {
      background: 'rgba(31, 227, 37, 0.8)',

      '&.coins': {
        background: 'rgba(255, 64, 103, 0.8)',
      },
    },
  },
}));

const HubQuestItem = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t, questProgress, type, loading, completeQuest, myViewer, isPremiumActive } = props;

  const onQuestClick = () => {
    if (questProgress[type + '_reward_collected'])
      return null;

    if (isFinished)
      completeQuest(type);
    else {
      if (myViewer && type === 'weekly' && questProgress.weekly_title === 'friend_weekly_quest_title') {
        copyToClipboard(`https://app.streamheroes.gg/referral/${myViewer.login}`);

        enqueueSnackbar(t('copy_success'), { variant: 'success' });
      } else if (questProgress[`${type}_game`] || questProgress[`${type}_streamer`]) {
        if (questProgress[`${type}_game`])
          props.history.push(`/hq/game-streams/${encodeURIComponent(questProgress[`${type}_game`].name)}`);
        else if (questProgress[`${type}_streamer`])
          props.history.push(`/profile/${questProgress[`${type}_streamer`].login}`);
      }
    }
  };

  const isFinished = questProgress[type] >= 100;
  const isCollected = questProgress[type + '_reward_collected'];
  const image = type === 'coins' ? `${process.env.PUBLIC_URL}/icons-png/sh_coins.png` : `${conf.cdn_url}/icons/quest/star_icon.png`;
  const mainColor = type === 'coins' ? '#FF4067' : '#1FE325';

  const badgeText = isCollected ? t('quest_collected') : t(questProgress[type + '_text'] + '_reward', { count: questProgress[type + '_count'] && questProgress[type + '_count'] });

  return (
    <div
      className={[classes.rootItem, isCollected ? 'isCollected' : '', isFinished ? 'isFinished' : ''].join(' ')}
    >
      <div className={classes.transparentBG}
           style={{ background: `url("${image}?height=106")` }}>
        <CustomBackdropFilter
          className={[classes.topArea, isFinished ? 'finished' : ''].join(' ')}
          filter={'blur(50px)'}
          canvasFallback={true}
          html2canvasOpts={{
            allowTaint: true,
          }}
        >
          <img src={`${image}?height=106`} alt="Star quest" />
        </CustomBackdropFilter>
      </div>
      <div
        className={classes.belowArea}
      >
        <CustomBackdropFilter
          className={classes.overArea}
          filter={'blur(50px)'}
          canvasFallback={true}
          html2canvasOpts={{
            allowTaint: true,
          }}
        >
          <div style={{ width: '100%', height: '100%', ...Helpers.fillColCenter }}>
            <AnimationButton
              status="whiteTransparency"
              style={{ padding: 11, width: 257 }}
              disabled={isCollected}
              onClick={onQuestClick}
            >
              {loading ?
                <i className="fa-light fa-loader fa-spin" style={{ fontSize: 18 }} />
                :
                t(isFinished ? 'task_collect_reward' : (questProgress[`${type}_game`] || questProgress[`${type}_streamer`] ? 'go_to_page' : (type === 'weekly' && questProgress.weekly_title === 'friend_weekly_quest_title' ? 'copy_invite_url' : '')))
              }
            </AnimationButton>
          </div>
        </CustomBackdropFilter>

        <div>
          <CustomBackdropFilter
            className={[classes.questBadge, isFinished ? 'finished' : '', type === 'coins' ? 'coins' : ''].join(' ')}
            filter={'blur(50px)'}
            canvasFallback={true}
            html2canvasOpts={{
              allowTaint: true,
            }}
          >
            <i className="fa-light fa-trophy" style={{ marginRight: 8 }} />

            {badgeText}
          </CustomBackdropFilter>

          <div style={{ marginTop: 24, fontWeight: 500 }}>
            {t(questProgress[type + '_title'])}
          </div>
          <div
            style={{ marginTop: 6, fontSize: 14, color: '#FFFFFF80', lineHeight: 1.5 }}
            dangerouslySetInnerHTML={{ __html: t(questProgress[type + '_text']) }}
          />
        </div>

        <div>
          <ProgressBar
            percent={isFinished ? 100 : questProgress[type]}
            filledBackground={isFinished ? mainColor : Colors.white}
            unfilledBackground={'rgba(255, 255, 255, 0.06)'}
            height={4}
          />
          <div style={{
            ...Helpers.fillRow,
            ...Helpers.mainSpaceBetween,
            marginTop: 6,
            fontSize: 12,
            color: Colors.halfWhite,
          }}>
            <div style={isFinished ? { color: mainColor } : {}}>
              {questProgress[type]}%
            </div>
            {(type !== 'coins' || (type === 'coins' && !isFinished)) &&
            <Countdown
              type={type}
              lastTime={questProgress[`${type}_last_time`]}
              isPremiumActive={isPremiumActive}
            />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(HubQuestItem);