import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import PremiumItem from './PremiumItem';
import Helpers from '../../theme/Helpers';
import PremiumFeatureList from './PremiumFeatureList';
import PremiumBuyDialogContainer from '../../containers/Premium/PremiumBuyDialog';
import Colors from '../../theme/Colors';
import CardShopMatchLineItems from '../CardShop/CardShopMatchLineItems';

import conf from '../../conf.json';

const useStyles = makeStyles((theme) => ({
  headerArea: {
    width: 'calc(100% - 173px - 173px)',
    padding: '75px 173px',
    backgroundImage: `url("${conf.cdn_url}/assets/template/premium/head_bg.png")`,
    backgroundColor: 'rgba(255, 255, 255, 0.04)',
    backgroundSize: 'cover',

    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 32px)',
      padding: 16,
    },
  },
  itemRow: {
    width: '100%',
    display: 'grid',
    gap: 9,
    gridTemplateColumns: 'repeat(1, 1fr)',
    marginTop: 18,

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  headerLineRow: {
    width: '100%',
    marginTop: 3,
    ...Helpers.fillRow,
    '& > div': {
      width: '100%',
      '& > div > div> div': {
        maxWidth: '100% !important',
      },
    },

    [theme.breakpoints.down('md')]: {
      ...Helpers.fillCol,
    },
  },
}));

const Premium = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isPremiumActive, viewer } = props;
  const [showBuy, setShowBuy] = useState(null);

  const items = [
    {
      bg: `${conf.cdn_url}/assets/template/premium/feature_bg1.png`,
      image: `${conf.cdn_url}/assets/template/premium/icons/icon_gems.png?height=110`,
      headText: 'premium_feature_1',
      desc: 'premium_feature_1_desc',
    },
    {
      bg: `${conf.cdn_url}/assets/template/premium/feature_bg2.png`,
      image: `${conf.cdn_url}/assets/template/premium/icons/icon_banner.png?height=110`,
      headText: 'premium_feature_2',
      desc: 'premium_feature_2_desc',
    },
    {
      bg: `${conf.cdn_url}/assets/template/premium/feature_bg3.png`,
      image: `${conf.cdn_url}/assets/template/premium/icons/icon_premium_border.png?height=110`,
      headText: 'premium_feature_3',
      desc: 'premium_feature_3_desc',
    },
  ];

  return (
    <>
      <div style={{ color: Colors.white, position: 'relative' }}>
        <div className={classes.headerArea}>
          <div style={{ fontSize: 36, fontWeight: 600 }}>
            {t('get_sh_premium')}
          </div>

          <div style={{ marginTop: 32 }}>
            {t('get_sh_premium_desc', { username: viewer && viewer.display_name })}
          </div>
        </div>

        <div className="inner-wrapper" style={{ marginTop: 32 }}>
          <div className={classes.itemRow}>
            {/*<PremiumItem index={0} setShowBuy={setShowBuy} isSubscriber={isPremiumActive} />*/}
            <PremiumItem index={1} setShowBuy={setShowBuy} isSubscriber={isPremiumActive} />
            {/*<PremiumItem index={2} setShowBuy={setShowBuy} isSubscriber={isPremiumActive} />
            <PremiumItem index={3} setShowBuy={setShowBuy} isSubscriber={isPremiumActive} />*/}
          </div>

          <div style={{
            padding: '15.5px 0',
            background: 'rgba(255, 255, 255, 0.04)',
            marginTop: 7,
            borderRadius: 6,
          }}>
            <PremiumFeatureList isSubscriber={isPremiumActive} setShowBuy={setShowBuy} />
          </div>
          <div className={classes.headerLineRow}>
            <CardShopMatchLineItems items={items} />
          </div>
        </div>
      </div>
      {showBuy &&
      <PremiumBuyDialogContainer status={showBuy} handleClose={() => setShowBuy(null)} />
      }
    </>
  );
};

export default Premium;