import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import PagePopup from '../../components/Dialog/PagePopup';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import CardExplanationCompact from './CardExplanationCompact';
import CardExplanationRareness from './CardExplanationRareness';
import CardExplanationQuality from './CardExplanationQuality';
import VerticalPageScrollMenu from '../VerticalPageScrollMenu/VerticalPageScrollMenu';
import HoverIcon from '../Icon/HoverIcon';
import ClosePopupButton from '../Button/ClosePopupButton';

const useStyles = makeStyles(() => ({
  body: {
    maxWidth: 720,
    fontFamily: 'Montserrat',
    flex: 1,
  },
  headRow: {
    width: '100%',
    maxWidth: 1440,
    margin: '0 auto',
    ...Helpers.fillColCenter,
    marginBottom: 139,
    marginTop: 74,
    position: 'relative',
  },
  footer: {
    ...Helpers.fillRowCross,
    ...Helpers.mainSpaceBetween,
    maxWidth: 720,
    width: '100%',
    marginBottom: 32,
    color: Colors.white,
    fontFamily: 'Montserrat',
    fontSize: 16,
  },
  roundedIcon: {
    width: 40,
    height: 40,
    border: '1px solid #ffffff',
    borderRadius: 50,
    ...Helpers.fillRowCenter,
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '& i': {
      fontSize: 18,
      color: Colors.white,
    },
    '&:hover': {
      background: Colors.white,
      '& i': {
        color: '#010F22',
      },
    },
  },
}));

const CardExplanationContainer = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { handleClose } = props;
  const [step, setStep] = useState(1);

  useEffect(() => {
    const scrollableElement = document.body;
    scrollableElement.addEventListener('wheel', checkWheel);
    return () => scrollableElement.removeEventListener('wheel', checkWheel);
  });

  const steps = [
    {
      title: t('card_values'),
      subTitle: t('how_does_the_card_stats_works'),
      footerKey: t('card_value'),
      component: <CardExplanationCompact />,
    },
    {
      title: t('rareness'),
      subTitle: t('what_is_rareness'),
      footerKey: t('rarities'),
      component: <CardExplanationRareness />,
    },
    /*{
      title: t('condition'),
      subTitle: t('what_is_condition'),
      footerKey: t('condition'),
      component: <CardExplanationCondition />,
    },*/
    {
      title: t('quality'),
      subTitle: t('what_is_quality'),
      footerKey: t('quality'),
      component: <CardExplanationQuality />,
    },
  ];

  const getArrowButton = (direction, text, nextStep) => {
    return (
      <div
        style={{
          ...Helpers.fillRowCross,
          ...(direction === 'left' ? Helpers.rowReverse : {}),
          cursor: 'pointer',
        }}
        onClick={() => setStep(nextStep)}
      >
        <div style={{
          marginLeft: direction === 'left' ? 12 : 0,
          marginRight: direction === 'right' ? 12 : 0,
          fontWeight: 300,
        }}>
          {text}
        </div>
        <div className={classes.roundedIcon}>
          <i className={`fa-light fa-chevron-${direction === 'right' ? 'right' : 'left'}`} />
        </div>
      </div>
    );
  };

  const checkWheel = (event) => {
    if (event.wheelDelta > 0) {
      if (step > 1)
        setStep(step - 1);
    } else {
      if (step < steps.length)
        setStep(step + 1);
    }
  };

  const activeStep = steps[step - 1];

  return (
    <PagePopup>
      <VerticalPageScrollMenu length={steps.length} activeIdx={step}
                              setIdx={(idx) => setStep(idx)} />
      <div style={{ ...Helpers.fillColCenter, ...Helpers.mainSpaceBetween, height: '100vh' }}>
        <div className={classes.headRow}>
          <ClosePopupButton handleClose={handleClose} style={{ top: 'auto' }} />

          <div style={{ fontFamily: 'Bebas Neue', fontSize: 36, color: Colors.white }}>
            {activeStep.title}
          </div>
          <div
            style={{
              fontFamily: 'Montserrat',
              fontSize: 16,
              color: Colors.halfWhite,
              fontWeight: 300,
            }}
          >
            {activeStep.subTitle}
          </div>
        </div>
        <div className={classes.body}>
          {activeStep.component}
        </div>
        <div className={classes.footer}>
          <div>
            {1 < step &&
            getArrowButton('left', t(steps[(step - 2)].footerKey), step - 1)
            }
          </div>
          <div>
            {steps.length > step &&
            getArrowButton('right', t(steps[step].footerKey), step + 1)
            }
          </div>
        </div>
      </div>
    </PagePopup>
  );
};

export default CardExplanationContainer;