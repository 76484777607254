import React, { useState } from 'react';
import { makeStyles, List, Typography, Avatar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import ActionButtons from './ActionButtons';

const useStyles = makeStyles({
  listContainer: {
    ...Helpers.fillCol,
    flexWrap: 'wrap',
    width: '100%',
    marginTop: 25,
    marginBottom: 100,
  },
  itemContainer: {
    margin: 10,
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    padding: 10,
    position: 'relative',
    background: Colors.wrapperDark,
    borderRadius: 5,
  },
  avatarRoot: {
    height: 50,
    width: 50,
  },
  showBigImage: {
    position: 'absolute',
    zIndex: 10000,
    top: '50%',
    right: 0,
    transform: 'translate(0,-50%)',
  },
});

const ReviewBooster = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { booster, randomNumber } = props;
  const [showCardIndex, setShowCardIndex] = useState(null);

  return (
    <List className={classes.listContainer}>
      {booster.sort((a, b) => a._id >= b._id ? -1 : 1)
      .map((item) =>
        <div className={classes.itemContainer} key={`ItemContainerKey#${item.match._id}`}>
          <div style={{
            display: 'grid',
            gap: 10,
            gridTemplateColumns: 'repeat(5, 1fr)',
          }}>
            {item.generic_cards.sort((cardA, cardB) => cardA.rarity - cardB.rarity)
            .map((card) => (
              <div style={Helpers.fillColCenter} key={`CardIDKey#${card._id}`}>
                <img
                  style={{ padding: 5, borderRadius: 4 }}
                  src={`${card.card_layer_image}?height=160&v=${randomNumber}`}
                  alt={`Card level ${card.level}`}
                  onMouseEnter={() => setShowCardIndex(card._id)}
                  onMouseLeave={() => setShowCardIndex(null)}
                />
                <div>{t(`rareness_level.${card.rarity}`)}</div>
                {showCardIndex === card._id &&
                <div className={classes.showBigImage}>
                  <img
                    src={`${card.physical_card_layer_image}?height=500&v=${randomNumber}`}
                    alt="Big card"
                    style={{ borderRadius: 4 }}
                  />
                </div>
                }
              </div>
            ))}
          </div>
          <div>
            <div style={{ ...Helpers.fillRowCross, ...Helpers.mainSpaceEvenly, marginBottom: 10 }}>
              <Avatar
                alt="Avatar"
                src={item.match.profile_image_url}
                classes={{ root: classes.avatarRoot }}
              />
              <Typography variant="h4">
                {item.match.display_name}
              </Typography>
            </div>
            <ActionButtons booster={item} streamer={item.match} cards={item.generic_cards} />
          </div>
        </div>,
      )}
    </List>
  );
};

export default ReviewBooster;