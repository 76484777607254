import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import AlertGroup from './AlertGroup';
import GrantDiscordAccess from './GrantDiscordAccess';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';
import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import CopyClipboardPopup from '../Dialog/CopyClipboardPopup';

import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  itemsLine: {
    background: 'rgba(255, 255, 255, 0.04)',
    padding: '8px 28px',
    fontSize: 14,
    color: Colors.halfWhite,
    marginBottom: 2,
  },
  hoverText: {
    cursor: 'pointer',
    fontWeight: 500,
    transition: 'all 0.3s ease-out',
    '&:hover': {
      opacity: 0.5,
    },
  },
}));

const AlertSettings = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { loading, alertSettings, discordSetupProgress, changeSettings, webSourceUrls } = props;
  const [selectedSettings, setSelectedSettings] = useState([]);
  const [leagueKeys, setLeagueKeys] = useState(null);
  const [showPopup, setShowPopup] = useState(null);

  useEffect(() => {
    if (alertSettings && selectedSettings.length > alertSettings.length)
      clearAll();
  }, [selectedSettings, alertSettings]);

  useEffect(() => {
    if (!leagueKeys)
      setLeagueKeys([...conf.league_keys].reverse());
  }, [leagueKeys]);

  const clearAll = () => {
    setSelectedSettings([]);
  };

  const changeSelectedSettings = (type) => {
    changeSettings(selectedSettings, type);
  };

  return (
    <>
      <GrantDiscordAccess discordSetupProgress={discordSetupProgress} />

      <CustomBackdropFilter
        className={classes.itemsLine}
        filter={'blur(50px)'}
        canvasFallback={true}
        html2canvasOpts={{
          allowTaint: true,
        }}
      >
        <div style={{ ...Helpers.fillRowCross }}>
          <div
            style={{
              color: selectedSettings.length > 0 ? Colors.white : Colors.halfWhite,
            }}
          >
            {t('x_items_selected', { count: selectedSettings.length })}
          </div>
          {(selectedSettings.length > 0) &&
          <>
            <div
              className={classes.hoverText}
              onClick={clearAll}
              style={{ marginLeft: 26, color: Colors.white }}
            >
              {t('clear_selection')}
            </div>
            <div
              className={classes.hoverText}
              onClick={() => changeSelectedSettings('setActive')}
              style={{ marginLeft: 24, color: '#15CC90' }}
            >
              {t('activate')}
            </div>
            <div
              className={classes.hoverText}
              onClick={() => changeSelectedSettings('setInactive')}
              style={{ marginLeft: 24, color: '#FF3141' }}
            >
              {t('deactivate')}
            </div>
            <div
              className={classes.hoverText}
              onClick={() => changeSelectedSettings('delete')}
              style={{ marginLeft: 24, color: Colors.white }}
            >
              {t('delete')}
            </div>
          </>
          }
        </div>
      </CustomBackdropFilter>

      <AlertGroup
        title={'booster_redeem'}
        isRedeem
        loading={loading}
        generateWebSource={() => setShowPopup('boosterRedeem')}
        alertSettings={alertSettings && alertSettings.filter(setting => setting.type === 'BoosterOpen')}
        selectedSettings={selectedSettings}
        setSelectedSettings={setSelectedSettings}
      />
      <AlertGroup
        title={'card_purchase'}
        isPurchase
        loading={loading}
        generateWebSource={() => setShowPopup('cardPurchase')}
        alertSettings={alertSettings && alertSettings.filter(setting => setting.type === 'CardPurchase')}
        selectedSettings={selectedSettings}
        setSelectedSettings={setSelectedSettings}
      />
      <AlertGroup
        title={'community_rank_up'}
        isRankUp
        loading={loading}
        generateWebSource={() => setShowPopup('communityRankUp')}
        alertSettings={alertSettings && alertSettings.filter(setting => setting.type === 'CommunityRankUp')}
        selectedSettings={selectedSettings}
        setSelectedSettings={setSelectedSettings}
        leagueKeys={leagueKeys}
      />
      {showPopup &&
      <CopyClipboardPopup handleClose={() => setShowPopup(null)} type={showPopup}
                          webSourceUrls={webSourceUrls} />
      }
    </>
  );
};

export default AlertSettings;