import React, { useState } from 'react';
import { makeStyles, List, Typography, Avatar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import ActionButtons from './ActionButtons';

const useStyles = makeStyles({
  listContainer: {
    ...Helpers.fillCol,
    flexWrap: 'wrap',
    width: '100%',
    marginTop: 25,
    marginBottom: 100,
  },
  itemContainer: {
    margin: 10,
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    position: 'relative',
    padding: 10,
    background: Colors.wrapperDark,
    borderRadius: 5,
  },
  avatarRoot: {
    height: 50,
    width: 50,
  },
  showBigImage: {
    position: 'absolute',
    zIndex: 10000,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
});

const ReviewCardsOverview = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { cardsToApprove, randomNumber } = props;
  const [showCardIndex, setShowCardIndex] = useState(null);

  return (
    <List className={classes.listContainer}>
      {cardsToApprove.map((item) =>
        <div className={classes.itemContainer} key={`ItemContainerKey#${item.streamer._id}`}>
          <div style={Helpers.fillRowCenter}>
            {item.cards.map((card) => {
                const dpi = card.migrationDpi ? card.migrationDpi : card.dpi;
                const image = card.migrationImage ? card.migrationImage : card.image;

                return (
                  <div style={Helpers.fillColCenter} key={`CardIDKey#${card._id}`}>
                    <img
                      style={{ padding: 5, borderRadius: 4 }}
                      src={`${image}?height=160&v=${randomNumber}`}
                      alt={`Card level ${card.level}`}
                      onMouseEnter={() => setShowCardIndex(card._id)}
                      onMouseLeave={() => setShowCardIndex(null)}
                    />
                    <div>{t('level_x', { level: card.level })}</div>
                    {dpi ?
                      <div style={{
                        fontSize: 12,
                        color: dpi >= 300 ? Colors.success : (dpi > 160 ? Colors.gold : Colors.error),
                      }}>
                        {dpi} DPI
                      </div>
                      : null
                    }
                    {showCardIndex === card._id &&
                    <div className={classes.showBigImage}>
                      <img
                        src={`${image.replace('self-init/', 'self-init/physical/')}?height=500&v=${randomNumber}`}
                        alt="Big card"
                        style={{ borderRadius: 4 }}
                      />
                    </div>
                    }
                  </div>
                );
              },
            )}
          </div>
          <div>
            <div
              style={{ ...Helpers.fillRowCross, ...Helpers.mainSpaceEvenly, marginBottom: 10 }}>
              <Avatar
                alt="Avatar"
                src={item.streamer.profile_image_url}
                classes={{ root: classes.avatarRoot }}
              />
              <Typography variant="h4">
                {item.streamer.display_name}
              </Typography>
            </div>
            <ActionButtons streamer={item.streamer} cards={item.cards} />
          </div>
        </div>,
      )}
    </List>
  );
};

export default ReviewCardsOverview;