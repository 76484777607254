import React from 'react';

import GemsPayList from '../../components/Gems/GemsPayList';
import { useQuery } from '@apollo/react-hooks';
import { getPremiumOffers } from '../../apollo/queries';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

const GemsPayListContainer = (props) => {
  const { gems, onClose } = props;

  const { data } = useQuery(getPremiumOffers, {
    fetchPolicy: useCacheWithExpiration('getPremiumOffers'),
  });

  const gemList = data && data.getPremiumOffers;

  return (
    <GemsPayList gemList={gemList} onClose={onClose} gems={gems} />
  );
};

export default GemsPayListContainer;