import React from 'react';
import { StickyContainer } from 'react-sticky';
import { makeStyles } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Colors from '../../theme/Colors';
import CardShopMatchFilters from '../CardShop/CardShopMatchFilters';
import Loading from '../Loading/Loading';
import { setCollectorsViewAllCards } from '../../redux/actions';
import SlideInOut from '../Layout/SlideInOut';
import Helpers from '../../theme/Helpers';
import HoverIcon from '../Icon/HoverIcon';
import InventoryCards from '../Inventory/InventoryCards';

const useStyles = makeStyles(() => ({
  topArea: {
    background: 'rgba(255, 255, 255, 0.04)',
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    height: 62,
  },
}));

const InventoryAllCardsSelectOne = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    cards,
    loading,
    onLoadMore,
    totalCount,
    myViewer,
    sorting, setSorting,
    quality, setQuality,
    rarity, setRarity,
    isSeasonal, setIsSeasonal,
  } = props;

  const onClose = () => {
    props.setCollectorsViewAllCards(false);
  };

  return (
    <SlideInOut
      isOpen={!!cards}
      additionalLeft={31}
      onClose={onClose}
      myViewer={myViewer}
    >
      <div style={{ color: Colors.white, position: 'relative' }}>
        <div className={classes.topArea}>
          <div style={{ ...Helpers.fillRowCenter }}>
            <HoverIcon
              icon="fa-thin fa-xmark"
              color={Colors.white}
              hoverColor={Colors.halfWhite}
              style={{ fontSize: 17, marginRight: 16, marginLeft: 24 }}
              onClick={onClose}
            />
            <div style={{ lineHeight: 1.6875 }}>
              {t('inventory')}
            </div>
          </div>
        </div>

        <StickyContainer>

          <CardShopMatchFilters
            setSorting={setSorting}
            sorting={sorting}
            setRarity={setRarity}
            rarity={rarity}
            setQuality={setQuality}
            quality={quality}
            setIsSeasonal={setIsSeasonal}
            isSeasonal={isSeasonal}
          />

          <div
            className="inner-wrapper hide-scrollbar"
            style={{ margin: '0 auto', overflowY: 'auto', maxHeight: 'calc(100vh - 144px)' }}
          >
            {loading ?
              <Loading />
              :
              <InventoryCards
                totalCount={totalCount}
                onLoadMore={onLoadMore}
                cards={cards}
                sellOnly
                notMy
                t={t}
              />
            }
          </div>

        </StickyContainer>

      </div>
    </SlideInOut>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setCollectorsViewAllCards,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(InventoryAllCardsSelectOne);