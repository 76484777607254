import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import amplitude from 'amplitude-js';
import { useLocation, withRouter } from 'react-router-dom';
import moment from 'moment';

import { getMobileMenuData } from '../../apollo/mergedQueries';
import { getStreamer } from '../../apollo/queries';

import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import { logAmplitudeEvent } from '../../utils/amplitude';
import MobileMenuComponent from '../../components/Menu/MobileMenu';

import conf from '../../conf';

const MobileMenuContainer = () => {
  const { pathname } = useLocation();
  const [isAmplitudeSend, setIsAmplitudeSend] = useState(false);
  const urlParts = pathname.split('/');
  const isProfileRoute = urlParts.includes('profile');
  const login = urlParts && urlParts[2];

  const { data } = useQuery(getMobileMenuData, {
    fetchPolicy: useCacheWithExpiration('getMobileMenuData'),
  });

  const variables = { login };
  const { data: streamerData } = useQuery(getStreamer, {
    variables,
    fetchPolicy: useCacheWithExpiration('getStreamer', variables),
    skip: !isProfileRoute,
  });

  useEffect(() => {
    if (isAmplitudeSend) {
      const amplitudeInfo = (window.localStorage) && window.localStorage.getItem(conf.storage_keys.amplitude);
      const todayFormat = moment()
      .format('DD.MM.YYYY');
      let sendEvent = false;

      if (amplitudeInfo) {
        if (todayFormat !== amplitudeInfo)
          sendEvent = true;
      } else
        sendEvent = true;

      if (sendEvent) {
        window.localStorage.setItem(conf.storage_keys.amplitude, todayFormat);
        if (conf.track_amplitude) logAmplitudeEvent('Active on Streamheroes');
      }
    }
  }, [pathname]);

  const viewer = data && data.getMyViewer;
  const streamer = streamerData && streamerData.streamer;
  const redeemableBoosterCount = data && data.getRedeemableBoosterCount;

  if (viewer && !isAmplitudeSend) {
    setIsAmplitudeSend(true);
    amplitude.getInstance()
    .init('f41da54b9bec9e2e8e11778e893ba860', viewer._id, { apiEndpoint: 'data.streamheroes.gg' });
    if (conf.track_amplitude) logAmplitudeEvent('Visit StreamHeroes');

    const firstLogin = localStorage.getItem(conf.storage_keys.first_login);
    if (firstLogin && firstLogin === 'true')
      localStorage.removeItem(conf.storage_keys.first_login);
  }

  return (
    <MobileMenuComponent
      viewer={viewer}
      streamer={streamer || { login }}
      redeemableBoosterCount={redeemableBoosterCount}
    />
  );
};

export default withRouter(MobileMenuContainer);