export const isDesktopOrLaptopObj = { minDeviceWidth: 1224 };
export const isLaptopObj = { minWidth: 1200, maxWidth: 1600 };
export const isBigScreenObj = { minDeviceWidth: 1824 };
export const isTabletOrMobileObj = { maxWidth: 1224 };
export const isTabletOrMobileDeviceObj = { maxDeviceWidth: 1224 };
export const isPortraitObj = { orientation: 'portrait' };
export const isRetinaObj = { minResolution: '2dppx' };

export const tabletOrMobile = 1224;

export const returnLaptopObj = (isLaptop, obj) => {
  return isLaptop ? obj : {};
};

export const returnMobileObj = (isMobile, obj) => {
  return isMobile ? obj : {};
};