import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import { updateDeckVisibility as updateDeckVisibilityMutation } from '../../apollo/mutations';
import { getCardDecks } from '../../apollo/mergedQueries';

import Helpers from '../../theme/Helpers';
import AnimationButton from '../Button/AnimationButton';
import Colors from '../../theme/Colors';
import RarityBadges from './RarityBadges';
import { setStorageBoxId, setStorageBoxSelectionMode } from '../../redux/actions';
import RenameStorageBoxDialog from './RenameStorageBoxDialog';

import conf from '../../conf.json';
import { preventEvent } from '../../utils/helper';
import SVGIcon from '../SVGIcon/SVGIcon';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    '& #hover-storage-box': {
      display: 'none',
    },

    '&:hover': {
      '& #hover-storage-box': {
        display: 'initial',
      },
      '& #normal-storage-box': {
        display: 'none',
      },

      '& $rowBelow': {
        opacity: 1,
        marginTop: 5,
        marginBottom: 10,
      },
      '& $itemArea': {
        marginTop: 0,
      },
    },
  },
  itemArea: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    height: 450,
    width: 250,
    padding: 12,
    position: 'relative',
    marginTop: 25,
    transition: 'all 0.3s ease-out',
    cursor: 'pointer',
    color: Colors.white,
    ...Helpers.textCenter,
    ...Helpers.fillColCenter,

    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
  rowBelow: {
    ...Helpers.fillRowCross,
    gap: 4,
    marginTop: -41,
    marginBottom: 30,
    opacity: 0,
    transition: 'all 0.3s ease-out',

    [theme.breakpoints.down('md')]: {
      opacity: 1,
      marginTop: 5,
      marginBottom: 10,
    },
  },
  badge: {
    margin: '16px auto 26px',
    background: 'rgba(255, 255, 255, 0.06)',
    borderRadius: 15,
    padding: '8px 17px',
    fontSize: 12,
    fontWeight: 500,
    width: 'fit-content',
    '& span': {
      color: Colors.halfWhite,
    },
  },
  badgesArea: {
    height: 58,
    position: 'relative',
    '& > div': {
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  menu: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    background: '#142134',
    borderRadius: '0px 0px 6px 6px',
    zIndex: 100,
  },
  menuItem: {
    width: 'calc(100% - 24px)',
    padding: 12,
    fontSize: 14,
    fontWeight: 500,
    ...Helpers.textCenter,
    transition: 'all 0.3s ease-out',

    '&:last-child': {
      borderRadius: '0px 0px 6px 6px',
    },
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.08)',
    },
  },
}));

const StorageBox = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { storageBox, t, notMy, viewer } = props;
  const [openMenu, setOpenMenu] = useState(false);
  const [isRename, setIsRename] = useState(false);

  const [updateDeckVisibility, { loading }] = useMutation(updateDeckVisibilityMutation, {
    onCompleted: () => {
      enqueueSnackbar(t('success'), { variant: 'success' });
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [
      { query: getCardDecks },
    ],
  });

  const setDataForStorageBox = () => {
    props.setStorageBoxId(storageBox._id, !notMy, viewer && viewer._id);
  };

  const viewCards = (withSelection = false) => {
    setOpenMenu(false);
    setDataForStorageBox();
    if (withSelection)
      props.setStorageBoxSelectionMode(true);
  };

  const togglePublicStatus = () => {
    setOpenMenu(false);
    updateDeckVisibility({
      variables: {
        deck: storageBox._id,
        is_public: !storageBox.is_public,
      },
    });
  };

  const isInactive = !storageBox.active;

  return (
    <>
      <div className={classes.container} onMouseLeave={() => openMenu ? setOpenMenu(false) : null}>
        <div
          className={classes.itemArea}
          onClick={() => setDataForStorageBox()}
          style={isInactive ? { background: 'rgba(255, 255, 255, 0.02)' } : {}}
        >

          {openMenu &&
          <div style={{
            background: 'rgba(0, 0, 0, 0.5)',
            borderRadius: 6,
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 10,
          }} />
          }

          <div style={{ marginTop: 10 }}>
            <div style={{ fontWeight: 500 }}>
              {isInactive &&
              <i className="fa-regular fa-lock" style={{ fontSize: 13, marginRight: 8 }} />
              }

              {isInactive ? t('locked') : storageBox.name}
            </div>
            <div style={{ fontSize: 12, color: Colors.halfWhite, marginTop: 2 }}>
              {isInactive ? t('unlock_storage_box_premium') : t('last_updated_x', {
                date: moment.unix(storageBox.updated_at / 1000)
                .format('L'),
              })}
            </div>

            <div
              className={classes.badge}
              style={isInactive ? { background: 'rgba(255, 255, 255, 0.02)' } : {}}
            >
              <span>{storageBox.card_count}</span> / {storageBox.max_cards}
            </div>

            <div style={{ marginBottom: 34 }}>
              <img
                src={`${conf.cdn_url}/assets/template/storage_boxes/SH_StorageBox_Design_01_Normal.png?height=239`}
                id="normal-storage-box"
                alt="Storage box"
                style={isInactive ? { mixBlendMode: 'luminosity', opacity: 0.2 } : {}}
              />

              <img
                src={`${conf.cdn_url}/assets/template/storage_boxes/SH_StorageBox_Design_01_Hover.png?height=239`}
                id="hover-storage-box"
                alt="Storage box"
                style={isInactive ? { mixBlendMode: 'luminosity', opacity: 0.2 } : {}}
              />
            </div>

            <div className={classes.badgesArea}>
              <RarityBadges count={4}
                            data={{ ...storageBox, streamer: { login: storageBox._id } }} />
            </div>
          </div>

          {openMenu &&
          <div className={classes.menu} onClick={preventEvent}>
            <div className={classes.menuItem} onClick={viewCards}>
              {t('edit_cards')}
            </div>
            <div className={classes.menuItem} onClick={() => {
              setOpenMenu(false);
              setIsRename(true);
            }}>
              {t('rename_box')}
            </div>
            {storageBox.is_public !== undefined &&
            <div className={classes.menuItem} onClick={loading ? null : togglePublicStatus}>
              {t(storageBox.is_public ? 'hide_box' : 'show_box')}
            </div>
            }
          </div>
          }

        </div>
        {!notMy &&
        <div className={classes.rowBelow}>
          <AnimationButton
            status="whiteTransparency"
            onClick={() => isInactive ? props.history.push('/premium') : viewCards()}
            style={{
              fontFamily: 'Montserrat',
              fontSize: 14,
              fontWeight: 500,
              width: '100%',
              padding: 14,
            }}
          >
            <div style={Helpers.fillRowCenter}>
              {isInactive &&
              <SVGIcon
                src={`${conf.cdn_url}/icons/logo.svg`}
                style={{ marginRight: 10 }}
                height={19}
                width={17}
              />
              }

              {t(isInactive ? 'unlock_with_premium' : 'view_box')}
            </div>
          </AnimationButton>

          {!isInactive &&
          <AnimationButton
            status="whiteTransparency"
            onClick={() => setOpenMenu(!openMenu)}
            style={{ padding: '14px 21px' }}
          >
            <i className="fa-light fa-ellipsis-vertical" style={{ fontSize: 18 }} />
          </AnimationButton>
          }
        </div>
        }
      </div>

      {isRename &&
      <RenameStorageBoxDialog
        storageBox={storageBox}
        t={t}
        onClose={() => setIsRename(false)}
      />
      }
    </>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setStorageBoxId,
    setStorageBoxSelectionMode,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(withRouter(StorageBox));