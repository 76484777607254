import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';

import { getMyViewer } from '../../apollo/queries';

import PageTitle from '../../components/PageTitle/PageTitle';
import Premium from '../../components/Premium/Premium';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import { isUserPremium } from '../../utils/helper';

const PremiumContainer = () => {
  const { t } = useTranslation();

  const { data } = useQuery(getMyViewer, {
    fetchPolicy: useCacheWithExpiration('getMyViewer'),
  });

  const viewer = data && data.getMyViewer;
  const { isPremiumActive } = isUserPremium(viewer);

  return (
    <>
      <PageTitle title={t('premium_hq')} />
      <Premium viewer={viewer} isPremiumActive={isPremiumActive} />
    </>
  );
};

export default PremiumContainer;