import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCardDecks } from '../../apollo/mergedQueries';

import PageTitle from '../../components/PageTitle/PageTitle';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import StorageBoxes from '../../components/Inventory/StorageBoxes';
import { resetStorageBoxData } from '../../redux/actions';

const StorageBoxesContainer = ({ resetStorageBoxData }) => {
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      resetStorageBoxData();
    };
  });

  const { data, loading } = useQuery(getCardDecks, {
    fetchPolicy: useCacheWithExpiration('getCardDecks'),
  });

  const cardDecks = data && data.getCardDecks;
  const myViewer = data && data.getMyViewer;

  return (
    <>
      <PageTitle title={t('storage_boxes')} />
      <StorageBoxes cardDecks={cardDecks} t={t} loading={loading} myViewer={myViewer} />
    </>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    resetStorageBoxData,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(StorageBoxesContainer);