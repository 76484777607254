import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import imageExists from 'image-exists';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import { createStripeCardShopBanner as createStripeCardShopBannerMutation } from '../../apollo/mutations';

import Helpers from '../../theme/Helpers';
import AnimationButton from '../Button/AnimationButton';
import Colors from '../../theme/Colors';
import Loading from '../Loading/Loading';
import { copyToClipboard, getRandomNumber } from '../../utils/helper';

import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  typeHeader: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceEvenly,
    fontSize: 20,
    fontFamily: 'Bebas Neue',
    color: '#2C3849',
    lineHeight: 1,
    marginTop: 10,

    '& > div:hover': {
      color: Colors.white,
      cursor: 'pointer',
      transition: '0.3s ease-in-out',
    },
  },
  activeType: {
    color: '#FFFE93',
  },
}));

const StripeReferImage = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { myViewer, cards } = props;
  const [type, setType] = useState(0);
  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState(null);
  const [hasRefreshed, setHasRefreshed] = useState(false);
  const random = getRandomNumber();

  const [createStripeCardShopBanner] = useMutation(createStripeCardShopBannerMutation, {
    onCompleted: (data) => {
      const { createStripeCardShopBanner } = data;
      setLoading(false);
      if (createStripeCardShopBanner)
        setImg(`${conf.no_cors_cdn_url}/card_shop_banners/${myViewer._id}_v${type + 1}.jpg`);
      else
        enqueueSnackbar('Error', { variant: 'error' });
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
      setLoading(false);
    },
  });

  const types = ['portrait', 'rect', 'square'];

  useEffect(() => {
    const bannerImg = `${conf.no_cors_cdn_url}/card_shop_banners/${myViewer._id}_v${type + 1}.jpg`;

    imageExists(bannerImg, (exists) => {
      if (exists) {
        setImg(bannerImg + `?v=${random}`);
      } else if (cards.length >= 3) {
        setLoading(true);
        setImg(null);
        createStripeCardShopBanner({ variables: { version: type + 1 } });
      }
    });
  }, [type]);

  const copyForUser = () => {
    copyToClipboard(`https://app.streamheroes.gg/profile/${myViewer.login}`);
    if (img)
      window.open(img.replace(conf.no_cors_cdn_url, conf.cdn_url)
      .split('?')[0], '_blank');

    enqueueSnackbar(t('copy_success'), { variant: 'success' });
  };

  const refreshImages = () => {
    setHasRefreshed(true);
    setImg(null);
    setLoading(true);

    createStripeCardShopBanner({ variables: { version: 1 } });
    createStripeCardShopBanner({ variables: { version: 2 } });
    createStripeCardShopBanner({ variables: { version: 3 } });
  };

  if (cards.length < 3)
    return (
      <p style={{ color: '#37568B', ...Helpers.textLeft }}>
        {t('stripe_to_less_cards')}
      </p>
    );

  return (
    <>
      <div className={classes.typeHeader}>
        {types.map((typ, index) => (
          <div
            key={typ}
            className={index === type ? classes.activeType : ''}
            onClick={() => setType(index)}
          >
            {t(`stripe_types_${typ}`)}
          </div>
        ))}
      </div>

      {loading &&
      <Loading style={{ marginTop: 35, marginBottom: 25 }} />
      }
      {img &&
      <img
        src={img}
        alt="Banner"
        style={{ marginTop: 35, marginBottom: 25 }}
      />
      }

      <p style={{ color: '#37568B', ...Helpers.textLeft }}>
        {t('stripe_copy_panel')}
      </p>

      <AnimationButton
        onClick={copyForUser}
        style={{ marginTop: 5, padding: '10px 50px' }}
      >
        {t('copy_alert_url_button')}
      </AnimationButton>

      {img &&
      <div>
        <p style={{ color: '#37568B', ...Helpers.textLeft }}>
          {t('refresh_all_images_text')}
        </p>
        <AnimationButton
          onClick={refreshImages}
          status="red"
          style={{ marginTop: 5, padding: '10px 50px' }}
          disabled={hasRefreshed}
        >
          {t('refresh_all_images')}
        </AnimationButton>
      </div>
      }
    </>
  );
};

export default StripeReferImage;