import gql from 'graphql-tag';

export const createRedeemedCard = gql`
    mutation($streamer: ID, $game: ID, $organisation: ID) {
        createRedeemedCard(streamer: $streamer, game: $game, organisation: $organisation) {
            _id
            first_for_user
            matchCardModel
            created_at
            viewer {
                _id
            }
            match_card {
                ... on Card {
                    _id
                    image
                    level
                    matchModel
                    created_at
                    match {
                        ... on Streamer {
                            _id
                            login
                            display_name
                        }
                        ... on Game {
                            _id
                            name
                        }
                        ... on Organisation {
                            _id
                            name
                        }
                    }
                }
            }
        }
    }
`;

export const craftCard = gql`
    mutation($streamer: ID, $game: ID, $level: Int!, $organisation: ID) {
        craftRedeemedCard(streamer: $streamer, game: $game, level: $level, organisation: $organisation) {
            _id
            first_for_user
            matchCardModel
            viewer {
                _id
            }
            created_at
            match_card {
                ... on Card {
                    _id
                    image
                    level
                    matchModel
                    created_at
                    match {
                        ... on Streamer {
                            _id
                            login
                            display_name
                        }
                        ... on Game {
                            _id
                            name
                        }
                        ... on Organisation {
                            _id
                            name
                        }
                    }
                }
            }
            viewer {
                _id
            }
        }
    }
`;

export const redeemSpecialCard = gql`
    mutation($cardId: ID!) {
        redeemSpecialCard(cardId: $cardId) {
            _id
            first_for_user
            matchCardModel
            created_at
            viewer {
                _id
            }
            match_card {
                ... on SpecialCard {
                    _id
                    image
                    costs
                    matchModel
                    created_at
                    card_name
                    edition
                    match {
                        ... on Streamer {
                            _id
                            login
                            display_name
                        }
                        ... on Game {
                            _id
                            name
                        }
                        ... on Organisation {
                            _id
                            name
                        }
                    }
                }
            }
        }
    }
`;

export const toggleShowcaseCard = gql`
    mutation($card: ID!, $index: Int) {
        toggleShowcaseCard(card: $card, index: $index) {
            _id
            is_showcase
        }
    }
`;

export const updateMyData = gql`
    mutation($update: ViewerUpdate) {
        updateMyData(update: $update) {
            _id
        }
    }
`;

export const markMyNotificationsRead = gql`
    mutation {
        markMyNotificationsRead
    }
`;

export const makeMeAStreamhero = gql`
    mutation {
        makeMeAStreamhero {
            _id
            twitch_id
        }
    }
`;

export const userImagePlaceholderUpload = gql`
    mutation($directory: String!, $fileName: String!) {
        userImagePlaceholderUpload(directory: $directory, fileName: $fileName)
    }
`;

export const uploadCardTemplate = gql`
    mutation($image: Upload!, $serialization: Upload!, $metaData: MetaData) {
        uploadCardTemplate(image: $image, serialization: $serialization, meta_data: $metaData) {
            filename
            mimetype
            encoding
        }
    }
`;

export const uploadBigLevelCard = gql`
    mutation($directory: String!, $fileName: String!) {
        uploadBigLevelCard(directory: $directory, fileName: $fileName)
    }
`;

export const testCardsSocket = gql`
    mutation {
        testCardsSocket
    }
`;

export const updateStreamerStreamConfig = gql`
    mutation($stream_config: StreamConfigInput!) {
        updateStreamerStreamConfig(stream_config: $stream_config) {
            _id
        }
    }
`;

export const approveOrDenyCardSet = gql`
   mutation($id: ID!, $approve: Boolean!, $detailReasons: [DenyDetailReason]) {
        approveOrDenyCardSet(id: $id, approve: $approve, detailReasons: $detailReasons)
    }
`;

export const createPoll = gql`
   mutation($poll: PollInput!) {
        createPoll(poll: $poll) {
            _id
            title
            answers
            is_published
            type
            target
            created_at
            published_at
            finished_at
            count_answers
        }
    }
`;

export const updatePoll = gql`
   mutation($poll: ID!, $poll_input: PollInput!) {
        updatePoll(poll: $poll, poll_input: $poll_input) {
            _id
            title
            answers
            is_published
            type
            target
            created_at
            published_at
            finished_at
            count_answers
        }
    }
`;

export const deletePoll = gql`
   mutation($id: ID!) {
        deletePoll(id: $id) {
            deletedCount
        }
    }
`;

export const savePollAnswerAndGetNextPoll = gql`
   mutation($poll_answer: PollAnswerInput!, $poll: ID!) {
        savePollAnswerAndGetNextPoll(poll_answer: $poll_answer, poll: $poll) {
            count
        }
    }
`;

export const collectQuestReward = gql`
   mutation($type: String!) {
        collectQuestReward(type: $type) {
            coins
            cards {
                match_card {
                    ... on Card {
                        _id
                    }
                }
                viewer {
                    _id
                }
            }
        }
    }
`;

export const collectTaskReward = gql`
   mutation($internal_id: Int!) {
      collectTaskReward(internal_id: $internal_id) {
          coins
          cards {
              match_card {
                  ... on Card {
                      _id
                  }
              }
              viewer {
                  _id
              }
          }
      }
   }
`;

export const updateMyToken = gql`
    mutation($token: String!) {
        setWebPushToken(token: $token)
    }
`;

export const getXSollaToken = gql`
    mutation {
        getXSollaToken
    }
`;

export const cancelSubscription = gql`
    mutation {
        cancelSubscription
    }
`;

export const uploadFile = gql`
    mutation($file: Upload!) {
        uploadFile(file: $file) {
            filename
            mimetype
            encoding
        }
    }
`;

export const toggleStreamerPrivacy = gql`
    mutation($streamer: ID!) {
        toggleStreamerPrivacy(streamer: $streamer)
    }
`;

export const getCardsSendToApprove = gql`
    mutation($sendToApprove: [Boolean]!, $cardUpdateMethod: String) {
        getCardsSendToApprove(sendToApprove: $sendToApprove, cardUpdateMethod: $cardUpdateMethod)
    }
`;

export const getSpecificUserToken = gql`
    mutation($userId: ID!) {
        getSpecificUserToken(userId: $userId)
    }
`;

export const completeGleamAction = gql`
    mutation($event_id: Int!, $campaign_key: String!, $campaign_name: String!) {  
        completeGleamAction(event_id: $event_id, campaign_key: $campaign_key, campaign_name: $campaign_name)
    }
`;

export const deleteStreamer = gql`
    mutation($id: ID!) {  
        deleteStreamer(id: $id)
    }
`;

export const setCardModification = gql`
    mutation($id: ID!) {  
        setCardModification(id: $id)
    }
`;

export const checkCoinsCollectingProcess = gql`
    mutation($streamer: String!) {
        checkCoinsCollectingProcess(streamer: $streamer) {
            code
            name
        }
    }
`;

export const createOfferOrder = gql`
    mutation($order: OrderInput!, $offer: ID!, $amount: [Int], $clientSecret: String) {
        createOfferOrderPaymentIntent(order: $order, offer: $offer, amount: $amount, clientSecret: $clientSecret) {
            _id
            amount
            client_secret
        }
    }
`;

export const getSearchResults = gql`
    mutation($filter: Filter, $pagination: Pagination) {
        getSearchResults(filter: $filter, pagination: $pagination) {
            viewers {
                _id
                login
                display_name
                twitch_id
                profile_image_url
            }
            streamers {
                _id
                login
                display_name
                twitch_id
                profile_image_url
            }
            games {
                _id
                name
                box_art_url
            }
            organisations {
                _id
                name
                code
                logo_url
            }
        }
    }
`;

export const createPaymentIntent = gql`
    mutation($cardOrder: CardOrder!, $country: String!, $clientSecret: String) {
        createPaymentIntent(cardOrder: $cardOrder, country: $country, clientSecret: $clientSecret) {
            _id
            amount
            client_secret
        }
    }
`;

export const setActivitiesPrivacySettings = gql`
    mutation setActivityPrivacy($categories: [String!]) {
        setActivityPrivacy(categories: $categories) 
    }
`;

export const swipeCard = gql`
    mutation($card: ID!, $model: String!, $image: String!, $status: String!) {
        swipeCard(card: $card, model: $model, image: $image, status: $status)
    }
`;

export const collectLoginStreakReward = gql`
    mutation {
        collectLoginStreakReward
    }
`;

export const handleDevLogin = gql`
    mutation($login: String!, $password: String!) {
        handleDevLogin(login: $login, password: $password)
    }
`;

export const transformCoins = gql`
    mutation($coins: Int!, $streamer: ID, $game: ID, $organisation: ID) {
        transformCoins(coins: $coins, streamer: $streamer, game: $game, organisation: $organisation)
    }
`;

export const transformAllEnergy = gql`
    mutation($ids: [ID]!) {
        transformAllEnergy(ids: $ids)
    }
`;

export const setUserBadge = gql`
    mutation($isStreamer: Boolean, $unique_id: Int!, $index: Int!) {
        setUserBadge(isStreamer: $isStreamer, unique_id: $unique_id, index: $index)
    }
`;

export const upgradeTankSize = gql`
    mutation {
        upgradeTankSize
    }
`;

export const setNotificationRead = gql`
    mutation($id: ID!) {
        setNotificationRead(id: $id) 
    }
`;

export const removeUserBadge = gql`
    mutation($isStreamer: Boolean, $index: Int!) {
        removeUserBadge(isStreamer: $isStreamer, index: $index) 
    }
`;

export const setBoosterDesign = gql`
    mutation($boosterId: ID!, $designId: Int!) {
        setBoosterDesign(boosterId: $boosterId, designId: $designId) 
    }
`;

export const spinCardWheel = gql`
    mutation($groupId: ID!) {
        spinCardWheel(groupId: $groupId) {
            level
            effect
        }
    }
`;

export const addLayer = gql`
    mutation($id: ID!, $name: String!) {
        addLayer(id: $id, name: $name) 
    }
`;

export const checkAddress = gql`
    mutation($country: String!, $postCode: String!, $cityName: String!, $street: String!, $houseNumber: String!) {
        checkAddress(country: $country, postCode: $postCode, cityName: $cityName, street: $street, houseNumber: $houseNumber) {
            status
            predictions {
                postCode
                cityName
                street
                houseNumber
            }
        }
    }
`;

export const requestAccountDeletionEmail = gql`
    mutation {
        requestAccountDeletionEmail
    }
`;

export const changeStreamerForEnergyCollection = gql`
    mutation($streamerId: ID!) {
        changeStreamerForEnergyCollection(streamerId: $streamerId)
    }
`;

export const confirmAccountDeletion = gql`
    mutation($token: String!) {
        confirmAccountDeletion (token: $token)
    }
`;

export const shredCard = gql`
    mutation($cardId: ID!) {
        shredCard(cardId: $cardId)
    }
`;

export const addIllustrationChatMessage = gql`
    mutation($id: ID!, $msg: String, $type: String, $images: [String], $createdAt: String) {
        addIllustrationChatMessage(id: $id, msg: $msg, type: $type, images: $images, created_at: $createdAt)
    }
`;

export const createIllustration = gql`
    mutation($illustration: Illustration!) {
        createIllustration(illustration: $illustration) {
            _id
        }
    }
`;

export const updateIllustrationState = gql`
    mutation($id: ID!, $status: String!, $metadata: IllustrationMetadata) {
        updateIllustrationState(id: $id, status: $status, metadata: $metadata) 
    }
`;

export const uploadIllustrationImage = gql`
    mutation($id: ID!, $level: Int!, $file: String!) {
        uploadIllustrationImage(id: $id, level: $level, file: $file)
    }
`;

export const deleteIllustrationImage = gql`
    mutation($id: ID!, $level: Int!) {
        deleteIllustrationImage(id: $id, level: $level)
    }
`;

export const updateUserRole = gql`
    mutation($userId: ID!, $role: String!) {
        updateUserRole(userId: $userId, role: $role)
    }
`;

export const toggleStreamerSHPartnerStatus = gql`
    mutation($id: ID!)  {
        toggleStreamerSHPartnerStatus(id: $id) {
            login
            streamheroes_type
        }
    }
`;

export const toggleIllustrationJobVIP = gql`
    mutation($id: ID!) {
        toggleIllustrationJobVIP(id: $id) {
            _id
        }
    }
`;

export const removeUserRole = gql`
    mutation($userId: ID!, $role: String!) {
        removeUserRole(userId: $userId, role: $role)
    }
`;

export const getPresignedURLsForIllustationJob = gql`
    mutation($files: [String]!) {
        getPresignedURLsForIllustationJob(files: $files)
    }
`;

export const getPresignedURLsForLayers = gql`
    mutation($layers: [LayerInput]!) {
        getPresignedURLsForLayers(layers: $layers) {
            _id
            signedUrl
            type
        }
    }
`;

export const createLayeredCard = gql`
    mutation($ids: [ID]!, $level: Int!, $group_id: ID) {
        createLayeredCard(ids: $ids, level: $level, group_id: $group_id)
    }
`;

export const setCardSetStatus = gql`
    mutation($status: Boolean!) {
        setCardSetStatus(status: $status)
    }
`;

export const updateOneCardSet = gql`
    mutation($set: Int!, $setLevel: Int!, $cardLevel: Int!) {
        updateOneCardSet(set: $set, setLevel: $setLevel, cardLevel: $cardLevel)
    }
`;

export const createStripeExpressAccount = gql`
    mutation {
        createStripeExpressAccount
    }
`;

export const createLicense = gql`
    mutation($name: String!, $logo: String, $address: String, $shares: Int, $login: String, $password: String) {
        createLicense(name: $name, logo: $logo, address: $address, shares: $shares, login: $login, password: $password)
    }
`;

export const createTypeCard = gql`
    mutation($cardType: String!, $match: String!, $matchModel: String!, $level: Int, $imageParams: ImageParams!, $specialCardParams: SpecialCardParams) {
        createTypeCard(cardType: $cardType, match: $match, matchModel: $matchModel, level: $level, imageParams: $imageParams, specialCardParams: $specialCardParams)
    }
`;

export const setOrderPending = gql`
    mutation($clientSecret: String!) {
        setOrderPending(clientSecret: $clientSecret)
    }
`;

export const createStripeExpressDashboardLink = gql`
    mutation {
        createStripeExpressDashboardLink
    }
`;

export const createStripeCardShopBanner = gql`
    mutation ($version: Int!) {
        createStripeCardShopBanner(version: $version)
    }
`;

export const setStripeOnboardingFinished = gql`
    mutation {
        setStripeOnboardingFinished
    }
`;

export const submitStreamAlertCredits = gql`
    mutation ($streamAlertCredits: [StreamAlertCreditInput]!) {
        submitStreamAlertCredits(streamAlertCredits: $streamAlertCredits)
    }
`;

export const createStripePayout = gql`
    mutation {
        createStripePayout
    }
`;

export const setPremiumFrame = gql`
    mutation setPremiumFrame($frame: String!) {
        setPremiumFrame(frame: $frame) 
    }
`;

export const setPremiumBanner = gql`
    mutation setPremiumBanner($banner: String!) {
        setPremiumBanner(banner: $banner) 
    }
`;

export const createGenericOrder = gql`
    mutation createGenericOrder ($offerOrder: [OfferOrderInput], $cardOrder: CardOrderInput, $subscription: SubscriptionInput, $billingAddress: AddressInput, $clientSecret: String, $acceptedWrongAddress: Boolean, $discount_code: String)  {
         createGenericOrder(offerOrder: $offerOrder, cardOrder: $cardOrder, subscription: $subscription, billingAddress: $billingAddress, clientSecret: $clientSecret, acceptedWrongAddress: $acceptedWrongAddress, discount_code: $discount_code) {
            _id
            amount
            client_secret
            plan_id
            stripe_url
            ship_cost
            vat_percent
            status_code
            discount {
                code
                percent
                amount_saved
            }
        }
    }
`;

export const redeemSidekickToken = gql`
    mutation redeemSidekickToken($token: String!) {
        redeemSidekickToken(token: $token)
    }
`;

export const removeHeroMakerImage = gql`
    mutation removeHeroMakerImage($id: ID!) {
        removeHeroMakerImage(id: $id)
    }
`;

export const duplicateHeroMakerImage = gql`
    mutation duplicateHeroMakerImage($id: ID!) {
        duplicateHeroMakerImage(id: $id)
    }
`;

export const createCardEvent = gql`
    mutation createCardEvent($name: String!, $started_at: String!, $finished_at: String!) {
        createCardEvent(name: $name, started_at: $started_at, finished_at: $finished_at) {
            _id
        }
    }
`;

export const updateCardEvent = gql`
    mutation updateCardEvent($name: String, $started_at: String, $finished_at: String, $id: ID!, $status: String, $reject_reason: String) {
        updateCardEvent(name: $name, started_at: $started_at, finished_at: $finished_at, id: $id, status: $status, reject_reason: $reject_reason)
    }
`;

export const uploadSpecialCard = gql`
    mutation uploadSpecialCard($image: Upload!, $serialization: Upload!, $event_id: ID, $dpi: Int, $costs: Int, $card_id: ID) {
        uploadSpecialCard(image: $image, serialization: $serialization, event_id: $event_id, dpi: $dpi, costs: $costs, card_id: $card_id) {
            filename
            mimetype
            encoding
        }
    }
`;

export const deleteSpecialCard = gql`
    mutation deleteSpecialCard($event_id: ID!, $card_id: ID!) {
        deleteSpecialCard(event_id: $event_id, card_id: $card_id)
    }
`;

export const updateSpecialCard = gql`
    mutation updateSpecialCard($card_id: ID!, $card_name: String) {
        updateSpecialCard(card_id: $card_id, card_name: $card_name) {
            _id
            card_name
        }
    }
`;

export const updateCardMetaData = gql`
    mutation updateCardMetaData($card: CardMetaData, $special_card: SpecialCardMetaData) {
        updateCardMetaData(card: $card, special_card: $special_card)
    }
`;

export const redeemBooster = gql`
    mutation redeemBooster($id: ID!, $quantity: Int, $open_booster: Boolean) {
        redeemBooster(id: $id, quantity: $quantity, open_booster: $open_booster) {
            cards {
                _id
                rarity
                condition
                quality
                front_image_url
                back_image_url
                front_layers {
                    img_url
                    physical_img_url
                    type
                }
                back_layers {
                    img_url
                    physical_img_url
                    type
                }
                card_template_metadata {
                    match {
                        ... on Streamer {
                            _id
                            login
                            display_name
                            profile_image_url
                        }
                        ... on Game {
                            _id
                            name
                            box_art_url
                        }
                        ... on Organisation {
                            _id
                            name
                            logo_url
                        }
                    }
                }
            }
            remaining_energy
            remaining_coins
            booster_animation
        }
    }
`;

export const createBoosterTemplate = gql`
    mutation createBoosterTemplate($booster_meta_data: BoosterMetaData) {
        createBoosterTemplate(booster_meta_data: $booster_meta_data) {
            _id
            edition
            redeemable_from
            redeemable_until
            energy_costs
            generic_cards {
                _id
                card_layer_image
                rarity
            }
        }
    }
`;

export const updateBoosterTemplate = gql`
    mutation updateBoosterTemplate($id: ID!, $booster_meta_data: BoosterMetaData) {
        updateBoosterTemplate(id: $id, booster_meta_data: $booster_meta_data) {
            _id
            front_image
            edition
            redeemable_from
            redeemable_until
            energy_costs
            ready_to_approve
            generic_cards {
                _id
                card_layer_image
                rarity
            }
        }
    }
`;

export const sendBoosterToApproval = gql`
    mutation sendBoosterToApproval($id: ID!) {
        sendBoosterToApproval(id: $id) {
            success
            error_code
        }
    }
`;

export const archiveBooster = gql`
    mutation archiveBooster($id: ID!) {
        archiveBooster(id: $id)
    }
`;

export const deleteUnapprovedBooster = gql`
    mutation deleteUnapprovedBooster($id: ID!) {
        deleteUnapprovedBooster(id: $id)
    }
`;

export const setCardPublicStatus = gql`
    mutation setCardPublicStatus($id: ID!) {
        setCardPublicStatus(id: $id)
    }
`;

export const orderOwnCardSet = gql`
    mutation orderOwnCardSet($address: AddressInput!, $boosters: [ID]!) {
        orderOwnCardSet(address: $address, boosters: $boosters)
    }
`;

export const approveOrDenyBooster = gql`
    mutation($id: ID!, $approve: Boolean!, $rejectReason: String) {
        approveOrDenyBooster(id: $id, approve: $approve, rejectReason: $rejectReason){
            success
            error_code
        }
    }
`;

export const awardStreamerEnergyToUser = gql`
    mutation($id: ID!, $amount: Int!)  {
        awardStreamerEnergyToUser(id: $id, amount: $amount) {
            _id
        }
    }
`;

export const removeCardFromUnapprovedBooster = gql`
    mutation removeCardFromUnapprovedBooster($booster: ID!, $card: ID!) {
        removeCardFromUnapprovedBooster(booster: $booster, card: $card)
    }
`;

export const openBooster = gql`
    mutation openBooster($ids: [ID]!) {
        openBooster(ids: $ids) {
            cards {
                _id
                rarity
                condition
                quality
                front_image_url
                back_image_url
                front_layers {
                    img_url
                    physical_img_url
                    type
                }
                back_layers {
                    img_url
                    physical_img_url
                    type
                }
                card_template_metadata {
                    match_meta_data {
                        ... on Streamer {
                            _id
                            login
                            display_name
                            profile_image_url
                        }
                        ... on Game {
                            _id
                            name
                            box_art_url
                        }
                        ... on Organisation {
                            _id
                            name
                            logo_url
                        }
                    }
                }
            }
            remaining_boosters
            booster_animation
        }
    }
`;

export const createAlertSetting = gql`
    mutation createAlertSetting($alert_setting: AlertSettingInput!)  {
        createAlertSetting(alert_setting: $alert_setting) {
            _id
        }
    }
`;

export const updateAlertSetting = gql`
    mutation updateAlertSetting($alert_setting: AlertSettingInput!)  {
        updateAlertSetting(alert_setting: $alert_setting) {
            _id
        }
    }
`;

export const setAlertSettingsWebSource = gql`
    mutation setAlertSettingsWebSource($type: String!)  {
        setAlertSettingsWebSource(type: $type)
    }
`;

export const authDiscordUser = gql`
    mutation authDiscordUser($streamer: ID, $viewer: ID, $code: String)  {
        authDiscordUser(streamer: $streamer, viewer: $viewer, code: $code)
    }
`;

export const updateDiscordServerRoleIds = gql`
    mutation updateDiscordServerRoleIds($discordServerRoleIds: DiscordServerRoleIdsInput)  {
        updateDiscordServerRoleIds(discordServerRoleIds: $discordServerRoleIds) {
            serverid
        }
    }
`;

export const changeAlertSettings = gql`
    mutation changeAlertSettings($ids: [ID]!, $type: String!)  {
        changeAlertSettings(ids: $ids, type: $type)
    }
`;

export const useAlertCapsule = gql`
    mutation useAlertCapsule($id: ID!, $tts_message: String) {
        useAlertCapsule(id: $id, tts_message: $tts_message)
    }
`;

export const testAlertSettings = gql`
    mutation testAlertSettings {
        testAlertSettings
    }
`;

export const putCardsInDeck = gql`
    mutation putCardsInDeck($card_ids: [ID!], $deck: ID!)  {
        putCardsInDeck(card_ids: $card_ids, deck: $deck) {
            _id
            name
            updated_at
        }
    }
`;

export const removeCardsFromDeck = gql`
    mutation removeCardsFromDeck($card_ids: [ID!])  {
        removeCardsFromDeck(card_ids: $card_ids)
    }
`;

export const updateDeckName = gql`
    mutation updateDeckName($deck: ID!, $name: String) {
        updateDeckName(deck: $deck, name: $name) {
            name
        }
    }
`;

export const updateDeckVisibility = gql`
    mutation updateDeckVisibility($deck: ID!, $is_public: Boolean) {
        updateDeckVisibility(deck: $deck, is_public: $is_public) {
            name
        }
    }
`;

export const createPremiumOrder = gql`
    mutation createPremiumOrder($premiumOfferId: ID!) {
        createPremiumOrder(premiumOfferId: $premiumOfferId) {
            _id
            amount
            client_secret
        }
    }
`;

export const purchaseCard = gql`
    mutation purchaseCard($cardTemplateId: ID!, $rarity: Int!, $quality: Int!, $gems: Int!, $anonymous: Boolean!) {
        purchaseCard(cardTemplateId: $cardTemplateId, rarity: $rarity, quality: $quality, gems: $gems, anonymous: $anonymous)
    }
`;

export const setSeasonBorder = gql`
    mutation setSeasonBorder($borderid: ID) {
        setSeasonBorder(borderid: $borderid)
    }
`;

export const toggleViewerBadge = gql`
    mutation toggleViewerBadge($id: ID!, $index: Int!) {
        toggleViewerBadge(id: $id, index: $index) {
            _id
        }
    }
`;

export const updateProfileData = gql`
    mutation updateProfileData($login: String, $profile_image_url: String) {
        updateProfileData(login: $login, profile_image_url: $profile_image_url)
    }
`;

export const deleteMarketplaceOffer = gql`
    mutation deleteMarketplaceOffer($id: ID!) {
        deleteMarketplaceOffer(id: $id)
    }
`;

export const buyMarketPlaceItem = gql`
    mutation buyMarketPlaceItem($id: ID!) {
        buyMarketPlaceItem(id: $id) {
            _id
        }
    }
`;

export const createMarketplaceOffer = gql`
    mutation createMarketplaceOffer($offer: MarketPlaceOfferInput!) {
        createMarketplaceOffer(offer: $offer) {
            _id
        }
    }
`;

export const updateMarketPlaceOffer = gql`
    mutation updateMarketPlaceOffer($offer: MarketPlaceOfferUpdateInput!) {
        updateMarketPlaceOffer(offer: $offer) {
            _id
        }
    }
`;

export const collectCardCollectionReward = gql`
    mutation collectCardCollectionReward($id: ID!) {
        collectCardCollectionReward(id: $id)
    }
`;

export const collectReferralReward = gql`
    mutation collectReferralReward($id: ID!) {
        collectReferralReward(id: $id)
    }
`;

export const deleteAlertCapsules = gql`
    mutation deleteAlertCapsules($ids: [ID!]!) {
        deleteAlertCapsules(ids: $ids)
    }
`;

export const collectCoinReward = gql`
    mutation collectCoinReward {
        collectCoinReward
    }
`;