import React from 'react';
import conf from '../../conf.json';

const colorsConfig = {
  BoosterOpen: 'orange',
  CommunityRankUp: 'red',
  CardPurchase: 'green',
};

const AlertCapsuleImage = ({ className, height, type, t, isAllowed }) => (
  <img
    src={`${conf.cdn_url}/assets/alert_capsules/capsule-${colorsConfig[type]}-transparent.png${height ? `?height=${height}` : ''}`}
    alt={t('alert_capsule')}
    className={className}
    style={{
      pointerEvents: 'none',
      filter: isAllowed ? 'none' : 'brightness(50%) grayscale(100%)',
    }}
  />
);

export default AlertCapsuleImage;
