import React from 'react';
import { useTranslation } from 'react-i18next';

import KPIDiagram from './KPIDiagram';
import KPIPercentDiagram from './KPIPercentDiagram';

const KPIs = (props) => {
  const { t } = useTranslation();
  const { stats } = props;

  return (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      <KPIDiagram
        title={t('daily_lp_visits_and_signups')}
        kpis={[
          stats.find(entry => entry._id === 'daily-landing-visitors'),
          stats.find(entry => entry._id === 'daily-new-signups'),
        ]}
      />

      <KPIDiagram
        title={t('daily_user_activity')}
        type="area"
        kpis={[
          stats.find(entry => entry._id === 'daily-active-viewers'),
          stats.find(entry => entry._id === 'daily-active-streamers'),
        ]}
      />

      <KPIDiagram
        title={t('daily_sessions')}
        kpis={[
          stats.find(entry => entry._id === 'daily-landing-sessions'),
          stats.find(entry => entry._id === 'daily-web-sessions'),
          // stats.find(entry => entry._id === 'daily-app-sessions'),
        ]}
      />

      <KPIDiagram
        title={t('daily_session_durations')}
        kpis={[
          stats.find(entry => entry._id === 'daily-landing-session-duration'),
          stats.find(entry => entry._id === 'daily-web-session-duration'),
          // stats.find(entry => entry._id === 'daily-app-session-duration'),
        ]}
      />

      <KPIDiagram
        title={t('daily_card_activity')}
        type="area"
        kpis={[
          stats.find(entry => entry._id === 'daily-redeemed-cards'),
          stats.find(entry => entry._id === 'daily-crafted-cards'),
          stats.find(entry => entry._id === 'daily-created-cardsets'),
        ]}
      />

      <KPIDiagram
        title={t('daily_reach_visibility')}
        kpis={[
          stats.find(entry => entry._id === 'daily-twitch-chatbot-visibility'),
          stats.find(entry => entry._id === 'daily-twitch-alert-visibility'),
        ]}
      />

      <KPIPercentDiagram
        title={t('daily_conversions')}
        kpis={[
          stats.find(entry => entry._id === 'daily-signups-to-dau-conversion'),
          stats.find(entry => entry._id === 'daily-signups-to-mau-conversion'),
          stats.find(entry => entry._id === 'daily-visitor-to-signup-conversion'),
        ]}
      />

      <KPIPercentDiagram
        title={t('weekly_retention_churn_rates')}
        kpis={[
          stats.find(entry => entry._id === 'weekly-retention-rate'),
          stats.find(entry => entry._id === 'weekly-churn-rate'),
        ]}
      />
    </div>
  );
};

export default KPIs;