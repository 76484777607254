import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';

import { getMyIllustrations } from '../../apollo/queries';

import Loading from '../Loading/Loading';
import Alert from '../Alert/Alert';
import DesignJobPopup from '../Dialog/DesignJobBriefingPopup';
import FinishedIllustratorJob from './FinishedIllustratorJob';
import SimpleReactLightbox from 'simple-react-lightbox';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

const useStyles = makeStyles(() => ({
  container: {
    minHeight: 500,
    backgroundColor: '#0B0B0B',
    '& > div:nth-of-type(odd)': {
      backgroundColor: '#1B1B1B',
    },
    '& > div:nth-of-type(even)': {
      backgroundColor: '#121212',
    },
    '& > div:hover': {
      backgroundColor: '#AA3A41',
    },
  },
}));

const FinishedIllustratorJobs = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [activeJobBriefing, setActiveJobBriefing] = useState(null);

  const { data, loading } = useQuery(getMyIllustrations, {
    variables: { status: 'approved' },
    fetchPolicy: useCacheWithExpiration('getMyIllustrations'),
  });

  const designJobs = (data) && data.getMyIllustrations;
  if (designJobs)
    designJobs.sort((a, b) => a.updated_at < b.updated_at ? 1 : -1);

  return (
    <>
      <div className={classes.container}>
        {loading ?
          <Loading />
          :
          !designJobs || (designJobs && designJobs.length === 0) ?
            <div><Alert>{t('no_completed_design_jobs')}</Alert></div>
            :
            designJobs && designJobs.map((job) => (
              <SimpleReactLightbox key={`CompleteJobKey${job._id}`}>
                <FinishedIllustratorJob job={job} setActiveJobBriefing={setActiveJobBriefing} />
              </SimpleReactLightbox>
            ))
        }
      </div>
      {activeJobBriefing &&
      <DesignJobPopup job={activeJobBriefing} handleClose={() => setActiveJobBriefing(null)} />
      }
    </>
  );
};

export default FinishedIllustratorJobs;