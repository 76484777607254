import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from 'react-step-progress-bar';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';

import { orderOwnCardSet as orderOwnCardSetMutation } from '../../apollo/mutations';
import { getAllBooster } from '../../apollo/queries';

import PagePopup from '../../components/Dialog/PagePopup';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';
import ClosePopupButton from '../Button/ClosePopupButton';
import AnimationButton from '../Button/AnimationButton';
import BoosterOrderItem from './BoosterOrderItem';
import OrderForm from '../Order/OrderForm';
import { getRightAddressInput } from '../../utils/helper';

const useStyles = makeStyles(() => ({
  innerContainer: {
    background: 'rgba(255, 255, 255, 0.06)',
    width: 950,
    margin: '180px auto',
    color: Colors.white,
    position: 'relative',
    borderRadius: 6,
  },
  footerArea: {
    padding: '10px 32px',
    background: 'rgba(255, 255, 255, 0.08)',
    ...Helpers.fillRowCenter,
    ...Helpers.mainEnd,
    width: '100%',
  },
  smallText: {
    color: Colors.halfWhite,
    marginBottom: 7,
    '& > span': {
      color: Colors.white,
    },
  },
  orderArea: {
    background: 'rgba(255, 255, 255, 0.04)',
    padding: '14px 16px',
    fontSize: 14,
    marginRight: 16,
    flexGrow: 1,
    borderRadius: 6,
  },
}));

const BoosterOrderPopup = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { handleClose, booster, countryCosts, formValues } = props;
  const [boosterIDs, setBoosterIDs] = useState([]);
  const [step, setStep] = useState(1);
  const [isFormFullFilled, setIsFormFullFilled] = useState(false);

  const [orderOwnCardSet, { loading }] = useMutation(orderOwnCardSetMutation, {
    onCompleted: () => {
      enqueueSnackbar(t('success'), { variant: 'success' });
      handleClose();
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{ query: getAllBooster }],
  });

  const orderSet = () => {
    const address = getRightAddressInput(formValues);

    orderOwnCardSet({ variables: { boosters: boosterIDs, address } });
  };

  const toggleBooster = (booster) => {
    const tmpBoosterIDs = [...boosterIDs];

    if (boosterIDs.length === 0 || !boosterIDs.includes(booster._id))
      tmpBoosterIDs.push(booster._id);
    else {
      const findIndex = tmpBoosterIDs.findIndex(boosterID => boosterID === booster._id);
      if (findIndex !== -1)
        tmpBoosterIDs.splice(findIndex, 1);
    }

    setBoosterIDs(tmpBoosterIDs);
  };

  return (
    <PagePopup>
      <CustomBackdropFilter
        className={classes.innerContainer}
        filter={'blur(50px)'}
        canvasFallback={true}
        html2canvasOpts={{
          allowTaint: true,
        }}
      >
        <div style={{ padding: '20px 32px' }}>
          <ClosePopupButton handleClose={handleClose} style={{ right: 10, top: 10 }} />

          {step === 1 &&
          <>
            <div style={{ color: Colors.white }}>
              {t('order_your_physical_booster')}
            </div>
            <div style={{ fontSize: 14, color: Colors.halfWhite, marginTop: 6 }}>
              {t('select_the_booster_you_want_to_order')}
            </div>

            <div style={{
              display: 'grid',
              gap: 14,
              gridTemplateColumns: 'repeat(4, 1fr)',
              height: 365,
              overflowX: 'auto',
              marginTop: 32,
            }}>
              {booster && booster.map(item => (
                <BoosterOrderItem
                  key={`BoosterItem${booster._id}`}
                  onClick={() => toggleBooster(item)}
                  booster={item}
                  isSelected={boosterIDs.includes(item._id)}
                />
              ))}
            </div>
          </>
          }
          {step === 2 &&
          <OrderForm countryCosts={countryCosts} setIsFormFullFilled={setIsFormFullFilled} isFormFullFilled={isFormFullFilled} />
          }
        </div>

        <div style={{ ...Helpers.fillRowCenter }}>
          <CustomBackdropFilter
            className={classes.footerArea}
            filter={'blur(50px)'}
            canvasFallback={true}
            html2canvasOpts={{
              allowTaint: true,
            }}
          >
            <AnimationButton
              status="neonGreen"
              style={{
                padding: '11px 42px',
                border: 'none',
                fontFamily: 'Montserrat',
                fontSize: 14,
              }}
              disabled={boosterIDs.length === 0 || loading || (step === 2 && !isFormFullFilled)}
              onClick={() => step === 1 ? setStep(2) : orderSet()}
            >
              {loading ?
                <i className="fa-light fa-loader fa-spin" />
                :
                t(step === 1 ? 'order_booster' : 'send_order')
              }
            </AnimationButton>

          </CustomBackdropFilter>
        </div>
      </CustomBackdropFilter>
    </PagePopup>
  );
};

const mapStateToProps = (state) => {
  const { formValues } = state;
  return { formValues };
};

export default connect(mapStateToProps)(BoosterOrderPopup);