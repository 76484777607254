import {
  RESET_ALERT_TOOL_DATA, SET_ALERT_TOOL_ALL_DATA,
  SET_ALERT_TOOL_TYPE_DATA,
} from './constants';

const INITIAL_STATE = {
  id: null,
  type: null,
  cardsAmount: null,
  cardRarity: null,
  communityRankUp: null,
  minQuality: null,
  maxQuality: null,
  showTwitch: false,
  showDiscord: false,
  showStyleSettings: null,
  discordSettings: null,
  twitchSettings: null,
};

const alertToolReducer = (state = INITIAL_STATE, action) => {
  if (action.type === SET_ALERT_TOOL_TYPE_DATA) {
    const obj = {};
    obj[action.dataType] = action.data;

    return { ...state, ...obj };
  } else if (action.type === SET_ALERT_TOOL_ALL_DATA) {
    return { ...state, ...action.data };
  } else if (action.type === RESET_ALERT_TOOL_DATA) {
    return INITIAL_STATE;
  } else {
    return state;
  }
};

export default alertToolReducer;