import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import PageTitle from '../PageTitle/PageTitle';
import TopViewHeader from '../TopViewHeader/TopViewHeader';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';

const conf = require('../../conf');

const useStyles = makeStyles(() => ({
  wrapper: {
    ...Helpers.textCenter,
    ...Helpers.boxBasics,
    padding: 50,
    position: 'fixed',
    top: '50%',
    left: '50%',
    /* bring your own prefixes */
    transform: 'translate(-50%, -50%)',
    color: Colors.white,
    fontSize: 30,
    fontWeight: 'bold',
    '& img': {
      marginBottom: 20,
    },
    '& span': {
      color: Colors.primary,
    },
  },
}));

const MaintenanceProvider = (props) => {
  const classes = useStyles();

  if (conf.is_maintenance) {
    // You can render any custom fallback UI
    return (
      <TopViewHeader>
        <PageTitle title="Maintenance" />
        <div className={classes.wrapper}>
          <img
            src={process.env.PUBLIC_URL + '/icons-png/sad_smiley.png'}
            alt={'Sad smiley icon'}
          />
          <div>
            <span>Streamheroes</span> is currently in maintenance mode.<br />
            Please be patient for a moment and try again later.
          </div>
        </div>
      </TopViewHeader>
    );
  }

  return props.children;
};

export default MaintenanceProvider;