import i18next from 'i18next';
import { ConnectivityStatus } from '@prismadelabs/prismaid';

import { CHANGE_SWIPE_VERIFICATION } from './constants';

const INITIAL_STATE = {
  scaleFactor: 0.5,
  networkStatus: ConnectivityStatus.ok,
  progress: 0,
  count: 0,
  errorCount: 0,
  showDot: false,
  showRedAlert: false,
  showGreenAlert: false,
  showWarningBackground: false,
  showInteractiveHelp: false,
  swipeResults: [
    {
      title: i18next.t('verification_place_title'),
      message: i18next.t('verification_place_body'),
    },
  ],
};

const swipeVerificationReducer = (state = INITIAL_STATE, action) => {
  if (action.type === CHANGE_SWIPE_VERIFICATION) {
    return { ...state, ...action.update };
  } else {
    return state;
  }
};

export default swipeVerificationReducer;