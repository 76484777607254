import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Colors from '../../theme/Colors';
import Loading from '../Loading/Loading';
import HubNewsBoard from './HubNewsBoard';
import HubSeasonInfo from './HubSeasonInfo';
import HubSeasonLeaders from './HubSeasonLeaders';
import HubNewBooster from './HubNewBooster';
import HubQuest from './HubQuest';
import HubEnergyGathering from './HubEnergyGathering';
import HubLiveChannels from './HubLiveChannels';
import HubFavouriteStreamer from './HubFavouriteStreamer';
import { isUserPremium } from '../../utils/helper';
import Helpers from '../../theme/Helpers';
import Symplr from '../AdSense/Symplr';

import conf from '../../conf.json';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 16,
    marginTop: 60,

    // otherwise the slider is placed over the heading (and buttons in it)
    position: 'relative',
    zIndex: 1,

    [theme.breakpoints.down('md')]: {
      marginLeft: 8,
    },
  },

  titleLink: {
    fontSize: 14,
    fontWeight: 500,
    color: 'white',
    textDecoration: 'none',
    border: '1px solid rgba(255, 255, 255, 0.08)',
    padding: '8px 20px',
    borderRadius: 6,
    transition: 'all 300ms ease',

    '&:hover': {
      background: 'rgba(255, 255, 255, 0.24)',
      borderColor: 'transparent',
    },
  },

  twoColumnGrid: {
    display: 'grid',
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    gap: 12,

    [theme.breakpoints.down('md')]: {
      ...Helpers.fillColCenter,
    },
  },
}));

const HubComponent = (props) => {
  const classes = useStyles();
  const {
    t,
    loading,
    seasonData,
    seasonLeaders,
    latestBooster,
    liveChannels,
    questProgress,
    myViewer,
    todayCollectedStreamer,
    refetch,
    favoriteStreamer,
    collectCoinsInfo,
    collectCoins,
    collectLoading,
  } = props;

  const { isPremiumActive } = isUserPremium(myViewer);

  return (
    <div style={{ position: 'relative', color: Colors.white }}>

      {loading ?
        <Loading />
        :
        <>
          <HubSeasonInfo t={t} seasonData={seasonData} myViewer={myViewer}
                         isPremiumActive={isPremiumActive} />
          <HubSeasonLeaders t={t} seasonLeaders={seasonLeaders} />

          <div className="inner-wrapper">

            <div className={classes.twoColumnGrid}>
              <div style={{ flex: 1 }}>
                <div className={classes.title} style={{ marginTop: 40 }}>
                  {t('daily_quest')}
                </div>

                <HubQuest
                  t={t}
                  questProgress={questProgress}
                  myViewer={myViewer}
                  collectCoinsInfo={collectCoinsInfo}
                  collectCoins={collectCoins}
                  collectLoading={collectLoading}
                  isPremiumActive={isPremiumActive}
                />
              </div>

              <div>
                <div className={classes.title} style={{ marginTop: 40 }}>
                  {t('energy_gathering')}
                </div>

                <HubEnergyGathering t={t} myViewer={myViewer}
                                    todayCollectedStreamer={todayCollectedStreamer}
                                    refetch={refetch} />
              </div>
            </div>

            <div className={classes.title}
                 style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween }}>
              <div>
                {t('live_channels')}
              </div>

              {(conf.ad_area.hub_1 && myViewer && !isPremiumActive) &&
              <Symplr id="streamheroes.gg_lb_3" />
              }
            </div>
            <HubLiveChannels t={t} liveChannels={liveChannels} />

            <div className={classes.title}
                 style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween }}>
              <div>
                {t('new_booster')}
              </div>

              <Link to="/item-store" className={classes.titleLink}>
                {t('go_to_item_store')}
              </Link>
            </div>
            <HubNewBooster t={t} latestBooster={latestBooster} myViewer={myViewer} />

            <div className={classes.title}>
              {t('news_board')}
            </div>
            <HubNewsBoard t={t} />

            {favoriteStreamer && favoriteStreamer.length > 0 &&
            <>
              <div className={classes.title}
                   style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween }}>
                <div>
                  {t('your_favourite_streamer')}
                </div>

                {(conf.ad_area.hub_1 && myViewer && !isPremiumActive) &&
                <Symplr id="streamheroes.gg_lb_3" />
                }
              </div>
              <HubFavouriteStreamer t={t} favoriteStreamer={favoriteStreamer} />
            </>
            }

          </div>

        </>
      }

    </div>
  );
};

export default HubComponent;