import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Avatar } from '@material-ui/core';
import { CircleProgress } from 'react-gradient-progress';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import Link from '@material-ui/core/Link';
import HRNumbers from 'human-readable-numbers';

import { getOrganisationHeader } from '../../apollo/mergedQueries';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import { returnMobileObj } from '../../utils/responsive';
import { isUserPremium, replaceWidthAndHeight } from '../../utils/helper';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  bigAvatar: {
    width: 200,
    height: 200,
    borderColor: '#1a071d',
    borderWidth: 4,
    background: '#03122f',
  },
  container: {
    color: Colors.white,
    ...Helpers.fillRow,
    ...Helpers.crossStart,
    flexWrap: 'wrap',
  },
  userWrapper: {
    paddingTop: 60,
  },
  nameWrapper: {
    backgroundColor: '#03122f',
    padding: '15px 0',
    paddingLeft: 135,
    width: 600,
    paddingRight: 5,
    marginRight: -10,
    textTransform: 'uppercase',
    color: Colors.white,
    marginLeft: -100,
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    borderRadius: 5,
    transform: 'skewX(-15deg)',
    zIndex: 0,
  },
  statsKey: {
    color: Colors.neonPurple,
    borderTop: '1px solid ' + Colors.neonPurple,
    textTransform: 'uppercase',
    paddingTop: 2,
    marginTop: 2,
    fontSize: 16,
  },
  statsValue: {
    color: Colors.white,
    fontSize: 30,
    fontFamily: 'Bebas Neue',
  },
  additionalWrapper: {
    paddingTop: 50,
    marginLeft: 'auto',
  },
  avatar: {
    width: 80,
    height: 80,
    borderColor: Colors.borderColor,
    marginRight: 20,
  },
  extraArea: {
    marginTop: 30,
    backgroundColor: '#1c164b',
    padding: '10px 30px',
    fontFamily: 'Bebas Neue',
    backgroundImage: `url(${process.env.PUBLIC_URL}/points_streamer_bg.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    ...Helpers.fillRowCenter,
    ...Helpers.crossEnd,
    fontSize: 18,
  },
}));

const OrganisationHeader = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { organisation, isTabletOrMobile, myCollectedPoints, myViewer } = props;

  const getOrganisationHeaderQuery = useQuery(getOrganisationHeader, {
    variables: { id: organisation._id },
    fetchPolicy: useCacheWithExpiration('getOrganisationHeader', { id: organisation._id }),
  });

  const collectedCardsNumber = (getOrganisationHeaderQuery.data) && getOrganisationHeaderQuery.data.getCollectedCardsAmountOfAnOrganisation.amountOfCardsCollected;
  const { isPremiumActive } = isUserPremium(myViewer);
  const energyLimit = isPremiumActive ? conf.premium_tank_size : 100;

  return (
    <div className={classes.container}>
      <div style={returnMobileObj(isTabletOrMobile, { width: '100%', ...Helpers.fillRowCenter })}>
        <div
          style={{
            width: 244,
            position: 'relative',
            zoom: isTabletOrMobile ? 0.5 : 1,
            zIndex: 1,
            ...returnMobileObj(isTabletOrMobile, { margin: '0 auto' }),
          }}
        >
          <div style={{ transform: 'rotate(180deg)' }}>
            <CircleProgress
              percentage={100}
              primaryColor={['#f3133b', '#781023']}
              secondaryColor="#102743"
              strokeLinecap="square"
              strokeWidth={15}
              hidePercentageText
              width={280}
            />
          </div>
          <div style={{ marginTop: -244, marginLeft: 1 }}>
            <Avatar
              className={classes.bigAvatar}
              alt={organisation.code}
              src={replaceWidthAndHeight(organisation.logo_url, 200, 200)}
            />
          </div>
        </div>
      </div>

      <div
        className={classes.userWrapper}
        style={returnMobileObj(isTabletOrMobile, { paddingTop: 0 })}
      >
        <div
          className={classes.nameWrapper}
          style={returnMobileObj(isTabletOrMobile, { padding: 10, marginLeft: 0, width: 'auto' })}
        >
          <div style={{ ...Helpers.fillRowCenter, transform: 'skewX(15deg)' }}>
            {organisation.main_channel &&
            <Link href={`https://twitch.tv/${organisation.code}`} target="_blank">
              <img
                src={`${process.env.PUBLIC_URL}/icons-png/twitch_square.png`}
                alt="Twitch"
                width={50}
                style={{ marginRight: 10 }}
              />
            </Link>
            }
            <div>
              <div style={{
                fontFamily: 'Bebas Neue',
                fontSize: isTabletOrMobile ? 25 : 40,
                lineHeight: 1,
              }}>
                {organisation.name}
              </div>
              <div style={{ color: '#79859a', fontSize: 15, lineHeight: 1 }}>
                {t('organisation')}
              </div>
            </div>
          </div>
        </div>
        <div style={{
          ...Helpers.fillRowCenter,
          ...Helpers.mainSpaceBetween,
          flexWrap: 'wrap',
          paddingLeft: isTabletOrMobile ? 0 : 35,
          paddingTop: isTabletOrMobile ? 0 : 30,
        }}>
          <div>
            <div className={classes.statsValue}>
              {organisation.follower_count ?
                HRNumbers.toHumanString(organisation.follower_count)
                : 0
              }
            </div>
            <div className={classes.statsKey}>{t('follower')}</div>
          </div>
          <div>
            <div
              className={classes.statsValue}>{collectedCardsNumber && HRNumbers.toHumanString(collectedCardsNumber)}</div>
            <div className={classes.statsKey}>{t('cards_collected')}</div>
          </div>
          <div>
            <div
              className={classes.statsValue}>{moment.unix(organisation.created_at / 1000)
            .format('DD.MM.YYYY')}</div>
            <div className={classes.statsKey}>{t('registered_since')}</div>
          </div>
        </div>
      </div>

      <div
        className={classes.additionalWrapper}
        style={returnMobileObj(isTabletOrMobile, { marginLeft: 0, paddingTop: 10, width: '100%' })}
      >
        <div>
          {/*<DashboardBadges
            user={streamer}
            allBadges={allBadges}
            isStreamer
            isMe={myViewer && myViewer.login === streamer.login}
            isTabletOrMobile={isTabletOrMobile}
            streamerLogin={streamer.login}
          />*/}
          <div
            style={returnMobileObj(isTabletOrMobile, { ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween })}
          >
            <div
              className={classes.extraArea}
              style={returnMobileObj(isTabletOrMobile, { marginTop: 10 })}
            >
              <img
                src={`${process.env.PUBLIC_URL}/icons-png/energy_solo.png`}
                alt="Watch"
                style={{ marginRight: 10, height: 46 }}
              />
              <span style={{ fontSize: 30 }}>
                {myCollectedPoints ? (myCollectedPoints.points_season - myCollectedPoints.redeemed_points_season) : 0}
              </span>/{energyLimit}
              <span style={{
                color: '#79859a',
                marginLeft: 5,
                fontSize: 16,
                fontFamily: 'Century Gothic',
                textTransform: 'uppercase',
              }}>
                {t('energy')}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganisationHeader;