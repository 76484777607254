import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import { deleteMarketplaceOffer as deleteMarketplaceOfferMutation } from '../../apollo/mutations';
import { marketPlaceMyHistory } from '../../apollo/queries';

import Helpers from '../../theme/Helpers';
import AnimationButton from '../Button/AnimationButton';
import QualityLine from '../QualityLine/QualityLine';
import Colors from '../../theme/Colors';
import { getLevelWord, getMatchData, preventEvent } from '../../utils/helper';
import { setMarketplaceAllData } from '../../redux/actions';
import MergedImage from '../MergedImage/MergedImage';
import SVGIcon from '../SVGIcon/SVGIcon';
import MarketplaceSmallBuyDialog from './MarketplaceSmallBuyDialog';

import conf from '../../conf.json';

const useStyles = makeStyles({
  itemArea: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    width: 322,
    cursor: 'pointer',
    position: 'relative',
    padding: 16,
    transition: 'all 0.3s ease-out',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.08)',
    },
  },
  midAvatar: {
    width: 30,
    height: 30,
    border: 'none',
    marginRight: 6,
  },
  coloredBGLine: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: 3,
    borderRadius: '6px 6px 0px 0px',
  },
  divider: {
    height: 1,
    background: 'rgba(255, 255, 255, 0.08)',
    width: 'calc(100% + 32px)',
    margin: '16.5px -16px',
  },
  imageArea: {
    position: 'relative',
    cursor: 'pointer',
    ...Helpers.fillRowMain,
    ...Helpers.mainSpaceBetween,
    marginBottom: 32,
  },
  avatar: {
    width: 26,
    height: 26,
    border: 'none',
  },
  menu: {
    position: 'absolute',
    bottom: 69,
    left: 16,
    right: 16,
    background: '#142134',
    borderRadius: '0px 0px 6px 6px',
    zIndex: 100,
  },
  menuItem: {
    width: 'calc(100% - 24px)',
    padding: 12,
    fontSize: 14,
    fontWeight: 500,
    ...Helpers.textCenter,
    transition: 'all 0.3s ease-out',

    '&:last-child': {
      borderRadius: '0px 0px 6px 6px',
    },
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.08)',
    },
  },
  badge: {
    width: 46,
    height: 46,
    borderRadius: 6,
    background: 'rgba(255, 255, 255, 0.06)',
    ...Helpers.fillColCenter,
    marginBottom: 8,
  },
});

const MarketplaceMarketItem = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();
  const { item, type, variables, myViewer } = props;
  const [openMenu, setOpenMenu] = useState(false);
  const [showBuy, setShowBuy] = useState(false);

  const [deleteMarketplaceOffer, { loading }] = useMutation(deleteMarketplaceOfferMutation, {
    onCompleted: () => {
      enqueueSnackbar(t('success'), { variant: 'success' });
      onClose();
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [
      { query: marketPlaceMyHistory, variables },
    ],
  });

  const deleteOffer = (id) => {
    deleteMarketplaceOffer({ variables: { id } });
  };

  const openBuyDialog = () => {
    props.setMarketplaceAllData({
      offerId: item._id,
      frontHash: item.offer_match_model_meta_data.front_hash,
      price: item.price,
      avgPrice: item.avg_price,
    });
  };

  const onClose = () => setOpenMenu(false);

  const { quality, rarity } = item.offer_match_model_meta_data;
  const levelWord = getLevelWord(null, rarity);
  const levelColor = Colors.level[levelWord];

  const {
    avatar,
    name,
    url,
  } = getMatchData({ match: item.offer_match_model_meta_data.card_template_metadata.match_meta_data }, 48, 48);

  return (
    <>
      <div
        className={classes.itemArea}
        onClick={openBuyDialog}
      >
        <div className={classes.coloredBGLine} style={{
          background: levelColor,
          borderRadius: '6px 6px 0px 0px',
        }} />

        <div>
          <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween }}>
            <div
              style={{ ...Helpers.fillRowCross, cursor: 'pointer' }}
              onClick={(e) => {
                preventEvent(e);
                props.history.push(url);
              }}
            >
              <Avatar
                className={classes.midAvatar}
                alt="Avatar"
                src={avatar}
              />
              <div>
                <div style={{
                  fontSize: 16,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  width: 150,
                  fontWeight: 500,
                }}>
                  {name}
                </div>
              </div>
            </div>

            <div style={{ fontSize: 10, color: Colors.halfWhite }}>
              {type && type !== 'active' ?
                moment.unix(item.sold_at / 1000)
                .format('L ⦁ LT')
                :
                moment.unix((item.created_at) / 1000)
                .fromNow()
              }
            </div>
          </div>

          <div className={classes.divider}
               style={{ marginBottom: 31.5, marginTop: 12.5 }} />

          <div className={classes.imageArea}>
            <div style={{ width: 46 }}>
              {item.offer_match_model_meta_data.season_id &&
              <div
                className={classes.badge}
                style={{
                  background: `url("${item.offer_match_model_meta_data.season_id.logo}?height=64") top center`,
                }}
              />
              }
            </div>

            <MergedImage
              card={item.offer_match_model_meta_data}
              height={288}
              borderRadius={6}
              id={`card-image-${item.offer_match_model_meta_data._id}`}
            />

            <div style={{ width: 46 }} />
          </div>
        </div>

        {(quality && quality < 0) ?
          <div style={{
            ...Helpers.textCenter,
            width: 'calc(100% - 40px)',
            padding: 20,
            fontSize: 14,
            color: Colors.halfWhite,
            background: 'rgba(255, 255, 255, 0.02)',
            borderRadius: 6,
          }}>
            {t('no_quality_available')}
          </div>
          :
          <QualityLine quality={quality} />
        }
        {((type && type !== 'active') || !type) &&
        <>
          <div className={classes.divider} />

          <div>
            <div style={{ color: Colors.halfWhite, fontSize: 12, marginBottom: 6 }}>
              {t(type === 'sold' ? 'buyer' : 'seller')}:
            </div>
            {((type === 'sold' && item.buyer) || item.viewer) ?
              <div style={{ ...Helpers.fillRowCross, fontSize: 14, fontWeight: 500 }}>
                <Avatar
                  className={classes.avatar}
                  alt="Avatar"
                  src={(type === 'sold' ? item.buyer : item.viewer).profile_image_url}
                  style={{ marginRight: 6 }}
                />
                {(type === 'sold' ? item.buyer : item.viewer).display_name}
              </div>
              :
              <div style={{ fontStyle: 'italic', fontSize: 14 }}>
                {t('deleted_user')}
              </div>
            }
          </div>
        </>
        }

        <div className={classes.divider} />

        {openMenu &&
        <div className={classes.menu} onClick={preventEvent}>
          <div className={classes.menuItem} onClick={onClose}>
            {t('inspect_card')}
          </div>
          <div className={classes.menuItem} onClick={onClose}>
            {t('offer_settings')}
          </div>
          <div
            className={classes.menuItem}
            onClick={() => deleteOffer(item._id)}
            style={{ color: '#E12138' }}
          >
            <i className={loading ? 'fa-light fa-loader fa-spin' : 'fa-regular fa-minus'}
               style={{ fontSize: 14, marginRight: 6 }} />
            {t('remove_offer_from_market')}
          </div>
        </div>
        }

        <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween }}>
          <div>
            {(!type || (type && type !== 'active')) &&
            <div style={{ fontSize: 10, color: Colors.halfWhite, marginBottom: 5 }}>
              {t('bought_for')}
            </div>
            }
            <div
              style={{ ...Helpers.fillRowCross, fontSize: 18, fontWeight: 600 }}>
              <SVGIcon
                src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
                height={21}
                width={16}
                style={{ marginRight: 5 }}
              />
              {item.price}
            </div>
            {(!type || (type && type === 'active')) &&
            <div style={{ fontSize: 10, color: Colors.halfWhite, marginTop: 5 }}>
              &Oslash; {t('x_gems', { count: item.avg_price })}
            </div>
            }
          </div>

          <div style={{ ...Helpers.fillRow }}>
            {!type &&
            <AnimationButton
              status="neonPink"
              onClick={(e) => {
                preventEvent(e);
                setShowBuy(true);
              }}
              style={{
                fontFamily: 'Montserrat',
                fontSize: 14,
                padding: '12px 24px',
                borderRadius: 4,
                fontWeight: 500,
              }}
              disabled={(myViewer && (myViewer.premium_currency < item.price || myViewer._id === item.viewer._id))}
            >
              <div style={{ ...Helpers.fillRowCross }}>
                <SVGIcon
                  src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
                  height={21}
                  width={16}
                  style={{ marginRight: 4 }}
                />
                {t('buy')}
              </div>
            </AnimationButton>
            }
            {(!type || (type && type !== 'active')) &&
            <AnimationButton
              status="whiteTransparency"
              style={{
                padding: '14px 13px',
                borderRadius: 4,
                marginLeft: 6,
              }}
              onClick={openBuyDialog}
            >
              <i
                className={`fa-light fa-eye`}
                style={{ fontSize: 18 }}
              />
            </AnimationButton>
            }
            {type && type === 'active' &&
            <AnimationButton
              status="whiteTransparency"
              onClick={(e) => {
                preventEvent(e);
                setOpenMenu(!openMenu);
              }}
              style={{
                padding: '14px 21px',
                borderRadius: 4,
              }}
            >
              <i className="fa-light fa-ellipsis-vertical" style={{ fontSize: 18 }} />
            </AnimationButton>
            }
          </div>
        </div>
      </div>
      {showBuy &&
      <MarketplaceSmallBuyDialog
        offerData={item}
        onClose={() => setShowBuy(false)}
        myViewer={myViewer}
      />
      }
    </>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setMarketplaceAllData,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(withRouter(MarketplaceMarketItem));