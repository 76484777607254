import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import List from '@material-ui/core/List';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';

import LeaderBoardListItem from './LeaderBoardListItem';
import Loading from '../Loading/Loading';
import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';

const useStyles = makeStyles(() => ({
  timeContainer: {
    fontSize: 16,
    textAlign: 'center',
    color: Colors.subTextColor,
    fontWeight: 500,
    marginBottom: 10,
  },
  headTable: {
    padding: '15px 5px',
    color: '#3A537D',
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    fontFamily: 'Bebas Neue',
    fontSize: 16,
    borderBottom: '1px solid #454545',
  },
}));

/**
 * @return {null}
 */
function LeaderBoardList(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    entries,
    onLoadMore,
    hasMore,
    isSecondary,
    time,
    skipPositions,
    isTime,
    isTabletOrMobile,
    onlyXP,
  } = props;

  let keyForListItem = '', subKey = '';

  if (!entries || (entries && entries.length === 0))
    return null;

  if (entries[0].viewer) {
    keyForListItem = 'viewer';
  } else if (entries[0].streamer) {
    keyForListItem = 'streamer';
  } else if (entries[0].game) {
    keyForListItem = 'game';
  } else if (entries[0].entry) {
    keyForListItem = 'entry';
    if (entries[0].entry.viewer) {
      subKey = 'viewer';
    } else if (entries[0].entry.streamer) {
      subKey = 'streamer';
    } else if (entries[0].entry.game) {
      subKey = 'game';
    }
  }

  let lastRefresh = null;
  if (time) lastRefresh = moment.unix(time / 1000)
  .format('DD.MM.YY HH:mm:ss');
  else {
    const latestTime = Math.max.apply(Math, entries.map(function (o) {
      return o.updated_at;
    }));
    if (!isNaN(latestTime))
      lastRefresh = moment.unix(latestTime / 1000)
      .format('DD.MM.YY HH:mm:ss');
  }

  return (
    <>
      {lastRefresh &&
      <div className={classes.timeContainer}>
        {t('last_updated')} {lastRefresh}
      </div>
      }
      <List style={{
        backgroundColor: Colors.containerBGColor,
        padding: isTabletOrMobile ? 5 : 20,
      }}>
        <div className={classes.headTable}>
          <div style={{ ...Helpers.fillRowCenter }}>
            <div>#</div>
            <div style={{ marginLeft: isTabletOrMobile ? 20 : 60 }}>Name</div>
          </div>
          <div style={{
            ...Helpers.fillRowCenter,
            ...Helpers.textCenter,
          }}>
            {isTime ?
              <div>
                Date
              </div>
              :
              <>
                {!onlyXP &&
                <>
                  <div style={{ width: isTabletOrMobile ? 50 : 100 }}>{t('supporter')}</div>
                  <div style={{ width: isTabletOrMobile ? 50 : 100 }}>{t('progress')}</div>
                  <div style={{ width: isTabletOrMobile ? 50 : 100 }}>{t('level')}</div>
                  <div style={{ width: isTabletOrMobile ? 60 : 120 }}>{t('cards')}</div>
                </>
                }
                <div style={{ width: isTabletOrMobile ? 60 : 120 }}>{t('xp')}</div>
              </>
            }
          </div>
        </div>
        <InfiniteScroll
          dataLength={entries.length}
          next={onLoadMore}
          hasMore={hasMore}
          loader={<Loading />}
        >
          {entries.map((item, key) =>
            item[keyForListItem] ?
              <LeaderBoardListItem
                key={keyForListItem === 'entry' ? item.entry[subKey]._id : item[keyForListItem]._id}
                entry={item}
                index={key + (skipPositions ? 3 : 0)}
                isSecondary={isSecondary}
                isTabletOrMobile={isTabletOrMobile}
              /> : null,
          )}
        </InfiniteScroll>
      </List>
    </>
  );
}

LeaderBoardList.propTypes = {
  entries: PropTypes.arrayOf(PropTypes.object),
  isSecondary: PropTypes.bool,
  isGlobalRanking: PropTypes.bool,
  skipPositions: PropTypes.bool,
  time: PropTypes.string,
  isTime: PropTypes.bool,
};

LeaderBoardList.defaultProps = {
  isSecondary: false,
  isGlobalRanking: false,
  skipPositions: false,
  isTime: false,
};

export default LeaderBoardList;