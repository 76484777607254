import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';
import RarityBadges from './RarityBadges';

const useStyles = makeStyles((theme) => ({
  itemArea: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    width: 250,
    cursor: 'pointer',
    position: 'relative',
    padding: 12,
    transition: 'all 0.3s ease-out',
    ...Helpers.fillColCenter,
    height: 450,
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.1)',
    },

    [theme.breakpoints.down('md')]: {
      height: 'fit-content !important',
      paddingBottom: 80,
    },
  },
  midAvatar: {
    width: 34,
    height: 34,
    border: 'none',
  },
  countArea: {
    width: 88,
    height: 31,
    marginTop: 14,
    background: 'rgba(255, 255, 255, 0.06)',
    fontSize: 12,
    fontWeight: 500,
    ...Helpers.fillColCenter,
    borderRadius: 15,
  },
  badge: {
    width: 58,
    height: 58,
    background: 'rgba(255, 255, 255, 0.04)',
    ...Helpers.fillColCenter,
    borderRadius: '50%',
    fontSize: 12,
  },
  badges: {
    alignSelf: 'flex-end',
    display: 'grid',
    gap: 6,
    gridTemplateColumns: 'repeat(4, 1fr)',
    position: 'absolute',
    bottom: 12,
    left: 12,
    right: 12,
  },
}));

const GroupedInventoryAllCards = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { data, streamer, rarities, isSmaller } = props;
  const { all_cards } = data;
  const shAvatar = 'https://static-cdn.jtvnw.net/jtv_user_pictures/fa77cfe3-b7dd-443f-8ba8-594c93740805-profile_image-300x300.png';

  return (
    <div
      className={classes.itemArea}
      onClick={() => props.history.push(`/inventory/cards${streamer ? '/' + streamer._id : ''}`)}
      style={isSmaller ? { width: 258, height: 456 } : {}}
    >
      <div className={classes.badge} style={{ width: 94, height: 94 }}>
        <i className="fa-light fa-rectangle-vertical-history"
           style={{ fontSize: 28, transform: 'matrix(-1, 0, 0, -1, 0, 0)' }} />
      </div>
      <div style={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: 225,
        marginTop: 16,
        ...Helpers.textCenter,
      }}>
        {t('view_all_cards')}
      </div>
      <div className={classes.countArea}>
        {all_cards.card_count}
      </div>
      {streamer ?
        <>
          <div style={{
            height: 1,
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 73.5,
            background: 'rgba(255, 255, 255, 0.08)',
          }} />
          <RarityBadges areMore count={5} data={{ ...data, rarities }} isSmaller={isSmaller} />
        </>
        :
        <div className={classes.badges}>
          {data.streamer_cards
          .slice(0, all_cards.streamer_count > 4 ? 3 : 4)
          .map(streamerCards => (
            <div className={classes.badge}
                 key={`StreamerKey${streamerCards.streamer && streamerCards.streamer.login}`}>
              <Avatar
                className={classes.midAvatar}
                alt="Avatar"
                src={streamerCards.streamer ? streamerCards.streamer.profile_image_url : shAvatar}
              />
            </div>
          ))}
          {all_cards.streamer_count > 4 &&
          <div className={classes.badge}>
            +{all_cards.streamer_count - 3}
          </div>
          }
        </div>
      }
    </div>
  );
};

export default withRouter(GroupedInventoryAllCards);