import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { Link, withRouter } from 'react-router-dom';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';

import conf from '../../conf';
import HoverIcon from '../Icon/HoverIcon';

const useStyles = makeStyles(() => ({
  wrapper: {
    margin: '0 auto',
    maxWidth: 1440,
    width: '100%',
  },
  headline: {
    height: 80,
    background: 'rgba(255, 255, 255, 0.04)',
  },
  stepLine: {
    marginTop: 3,
    marginBottom: 26,
    height: 56,
    width: '100%',
    position: 'relative',
    background: 'rgba(255, 255, 255, 0.08)',
    color: Colors.white,
    fontFamily: 'Montserrat',
    fontSize: 14,
    '& > div': {
      height: '100%',
    },
  },
  stepNumber: {
    width: 26,
    height: 26,
    ...Helpers.fillRowCenter,
    borderRadius: '50%',
    border: '1px solid #FFFFFF',
    marginRight: 12,
    fontWeight: 500,

    '&.active': {
      background: '#E12138',
      borderColor: '#E12138',
    },
  },
}));

const CardShopPayLayout = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { children, activeStep } = props;

  const steps = [
    'enter_your_address',
    'payment',
    'confirmation',
  ];

  return (
    <div>
      <CustomBackdropFilter
        className={classes.headline}
        filter={'blur(50px)'}
        canvasFallback={true}
        html2canvasOpts={{
          allowTaint: true,
        }}
      >
        <div
          className={classes.wrapper}
          style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween, height: '100%' }}
        >
          <div style={{ ...Helpers.fillRowCross }}>
            <HoverIcon
              icon="fa-regular fa-chevron-left"
              color={Colors.white}
              hoverColor={Colors.halfWhite}
              style={{ fontSize: 22, marginRight: 12 }}
              onClick={() => props.history.push('/card-shop')}
            />
            <Link to="/">
              <ReactSVG
                src={`${conf.cdn_url}/assets/template/shop/streamheroes_logo.svg`}
              />
            </Link>
          </div>
          <div>
            <img src={`${conf.cdn_url}/assets/template/shop/payment_methods.png`}
                 alt="Payment methods" />
          </div>
        </div>
      </CustomBackdropFilter>
      <CustomBackdropFilter
        className={classes.stepLine}
        filter={'blur(50px)'}
        canvasFallback={true}
        html2canvasOpts={{
          allowTaint: true,
        }}
      >
        <div style={Helpers.fillRowCenter}>
          {steps.map((step, index) => (
            <>
              <div key={`TextStep${index}`}
                   style={{ ...Helpers.fillRowCenter, opacity: index <= activeStep ? 1 : 0.5 }}>
                <div
                  className={[classes.stepNumber, index <= activeStep ? 'active' : ''].join(' ')}>
                  {index < activeStep ? <i className="fa-solid fa-check" /> : (index + 1)}
                </div>
                <div>
                  {t(step)}
                </div>
              </div>
              {(index + 1) < steps.length &&
              <div style={{ fontSize: 16 }}>
                <i className="fa-light fa-chevron-right" style={{ margin: '0 80px' }} />
              </div>
              }
            </>
          ))}
        </div>
      </CustomBackdropFilter>
      <div className={classes.wrapper} style={{ ...Helpers.fillRow }}>
        <CustomBackdropFilter
          filter={'blur(50px)'}
          canvasFallback={true}
          html2canvasOpts={{
            allowTaint: true,
          }}
        >
          <div style={{
            width: 903,
            marginRight: 8,
            background: 'rgba(255, 255, 255, 0.08)',
            borderRadius: 6,
            padding: 20,
            height: '100%',
          }}>
            {children[0]}
          </div>
        </CustomBackdropFilter>
        <CustomBackdropFilter
          filter={'blur(50px)'}
          canvasFallback={true}
          html2canvasOpts={{
            allowTaint: true,
          }}
        >
          <div style={{
            width: 441,
            marginLeft: 8,
            background: 'rgba(255, 255, 255, 0.08)',
            padding: 20,
            borderRadius: 6,
            height: '100%',
          }}>
            {children[1]}
          </div>
        </CustomBackdropFilter>
      </div>
    </div>
  );
};

export default withRouter(CardShopPayLayout);