import React from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { getInformationAboutCard } from '../../apollo/queries';

import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import Loading from '../Loading/Loading';
import CardViewDialog from '../Cards/CardViewDialog';

const MyOldCard = (props) => {
  const { card, matchParams } = props;

  const { loading, data } = useQuery(getInformationAboutCard, {
    variables: { id: card.match_card._id },
    fetchPolicy: useCacheWithExpiration('getInformationAboutCard', { id: card.match_card._id }),
  });

  const information = (data) && data.getInformationAboutCard;

  const bothInformation = {
    _id: card._id,
    rarity: null,
    overall_collected: information && information.totalCollected,
    unique_collectors: information && information.totalCollectors,
    opened_as_number: card.crafted_as_number,
    card_template_metadata: {
      match: matchParams,
    },
    viewer: {
      display_name: card.viewer.display_name,
    },
    front_layers: [{
      img_url: card.match_card.image,
      physical_img_url: card.match_card.image,
    }],
    created_at: card.created_at,
  };

  return (
    loading ?
      <Loading />
      :
      <CardViewDialog information={bothInformation} isLegacy />
  );
};

export default withRouter(MyOldCard);