import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ClickAwayListener } from '@material-ui/core';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    fontSize: 14,
    cursor: 'pointer',
    ...Helpers.textRight,
    '&:hover': {
      color: '#E12138',
    },
  },
  sortingMenu: {
    position: 'absolute',
    top: 'calc(100% + 10px)',
    right: '50%',
    transform: 'translate(50%, 0)',
    width: 202,
    borderRadius: 6,
    background: 'rgba(20, 39, 64, 0.83)',
    padding: 10,
    color: Colors.white,
    zIndex: 1,
    ...Helpers.textLeft,
    '& > div': {
      marginBottom: 6,
      transition: 'all 0.3s ease-out',
      borderRadius: 6,
      cursor: 'pointer',
      padding: 10,
      '&:hover': {
        color: '#E12138',
        background: 'rgba(255, 255, 255, 0.04)',
      },
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
});

const SortingLine = (props) => {
  const classes = useStyles();
  const { t, noPrices, noQuality, noRarity, sorting, setSorting } = props;
  const [showMenu, setShowMenu] = useState(false);

  const sortingOptions = [
    { key: 'last_added', sorting: { field: 'created_at', order: 'DESC' } },
    { key: 'first_added', sorting: { field: 'created_at', order: 'ASC' } },
  ];

  if (!noRarity)
    sortingOptions.unshift(
      { key: 'lowest_rarity', sorting: { field: 'rarity', order: 'ASC' } },
      { key: 'highest_rarity', sorting: { field: 'rarity', order: 'DESC' } },
    );

  if (!noQuality)
    sortingOptions.unshift(
      { key: 'lowest_quality', sorting: { field: 'quality', order: 'ASC' } },
      { key: 'highest_quality', sorting: { field: 'quality', order: 'DESC' } },
    );

  if (!noPrices)
    sortingOptions.unshift(
      { key: 'lowest_price', sorting: { field: 'price', order: 'ASC' } },
      { key: 'highest_price', sorting: { field: 'price', order: 'DESC' } },
    );

  const changeIndex = (index) => {
    setSorting(sortingOptions[index].sorting);
    setShowMenu(false);
  };

  const activeIndex = sortingOptions.findIndex(sort => sort.sorting.field === sorting.field && sort.sorting.order === sorting.order);

  return (
    <div className={classes.container}>
      <div onClick={() => setShowMenu(!showMenu)}>
        {t(sortingOptions[activeIndex].key)}
        <i className={`fa-light fa-chevron-${showMenu ? 'up' : 'down'}`}
           style={{ marginLeft: 10 }} />
      </div>

      {showMenu &&
      <ClickAwayListener onClickAway={() => setShowMenu(false)}>
        <CustomBackdropFilter
          className={classes.sortingMenu}
          filter={'blur(25px)'}
          canvasFallback={true}
          html2canvasOpts={{
            allowTaint: true,
          }}
        >
          {sortingOptions.map((desc, index) => (
            <div
              key={`SortingMenu${index}`}
              onClick={() => changeIndex(index)}
              style={activeIndex === index ? { color: '#E12138', fontWeight: 500 } : {}}
            >
              {t(desc.key)}
            </div>
          ))}
        </CustomBackdropFilter>
      </ClickAwayListener>
      }
    </div>
  );
};

export default SortingLine;