import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { useParams, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getRedeemableBoosters, getTrendingBoosters } from '../../apollo/mergedQueries';

import ItemStore from '../../components/ItemStore/ItemStore';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import CardViewDialogContainer from '../Cards/CardViewDialog';
import { useQueryParams } from '../../utils/helper';
import { togglePlayingBoosterAnimation } from '../../redux/actions';

const ItemStoreContainer = (props) => {
  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const cardId = queryParams && queryParams.get('cardId');
  const [showPopup, setShowPopup] = useState(!!cardId);

  let { tab } = useParams();

  tab = tab ?? 'top-10-booster';

  useEffect(() => {
    setShowPopup(!!cardId);
  }, [cardId]);

  const getRedeemableBoostersQuery = useQuery(getRedeemableBoosters, {
    fetchPolicy: useCacheWithExpiration('getRedeemableBoosters'),
    skip: tab && tab === 'top-10-booster',
  });

  const getTrendingBoostersQuery = useQuery(getTrendingBoosters, {
    fetchPolicy: useCacheWithExpiration('getTrendingBoosters'),
    skip: tab && tab !== 'top-10-booster',
  });

  const executedQuery = getRedeemableBoostersQuery && getRedeemableBoostersQuery.data ? getRedeemableBoostersQuery : getTrendingBoostersQuery;
  const { data, loading } = executedQuery;
  const allData = data && data.getRedeemableBoosters;
  const trendingBoosters = data && data.getTrendingBoosters;
  const myViewer = data && data.getMyViewer;
  const boosters = allData && allData.boosters;
  const starterPacks = boosters && tab === 'starterpack' && boosters.filter(booster => booster.is_starter_pack);

  if (trendingBoosters)
    trendingBoosters.sort((a, b) => a.user_energy >= b.user_energy ? -1 : 1);

  return (
    <>
      <PageTitle title={t('item_store')} />
      <ItemStore boosters={boosters && boosters.filter(booster => !booster.is_starter_pack)}
                 trendingBoosters={trendingBoosters} t={t} loading={loading}
                 myViewer={myViewer} starterPacks={starterPacks} tab={tab} />
      {showPopup &&
      <CardViewDialogContainer card={{ _id: cardId }} handleClose={() => {
        setShowPopup(false);
        props.history.replace({
          search: '',
        });
      }} />
      }
    </>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    togglePlayingBoosterAnimation,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(withRouter(ItemStoreContainer));