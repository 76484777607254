import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SketchPicker } from 'react-color';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import CustomTooltip from '../Tooltip/CustomTooltip';

const ColorPicker = (props) => {
  const classes = useStyles();
  const {
    color,
    handleColor,
    disableText,
    position,
    removeColor,
    hasActiveColor,
    tooltipText,
  } = props;

  const [togglePicker, setTogglePicker] = useState(false);

  const togglePickerArea = () => {
    setTogglePicker(!togglePicker);
  };

  const sketchPickerClasses = [classes.colorPicker];
  let innerCircleColor;
  if (color)
    innerCircleColor = { boxShadow: color + ' 0px 0px 0px 14px inset' };
  else
    innerCircleColor = {
      boxShadow: 'none',
      background: 'conic-gradient(red, yellow, lime, aqua, blue, magenta, red)',
    };

  if (position) {
    if (position === 'top')
      sketchPickerClasses.push(classes.topPosition);
    else if (position === 'left')
      sketchPickerClasses.push(classes.leftPosition);
  }

  let mainComp = <div
    className={classes.colorCircle}
    onClick={togglePickerArea}
    style={{ marginRight: 10 }}
  >
    <div className={classes.innerCircle} style={innerCircleColor} />
  </div>;

  if (tooltipText)
    mainComp = <CustomTooltip title={tooltipText}>
      <div
        className={classes.colorCircle}
        onClick={togglePickerArea}
        style={{ marginRight: 10 }}
      >
        <div className={classes.innerCircle} style={innerCircleColor} />
      </div>
    </CustomTooltip>;

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ ...Helpers.fillRowCenter, ...{ marginBottom: 10, position: 'relative' } }}>
        {(hasActiveColor && removeColor) &&
        <div onClick={() => removeColor()}>
          <FontAwesomeIcon icon={faTimes} color="red" size="xs" className={classes.removeColor} />
        </div>
        }
        {mainComp}
        {!disableText &&
        <div style={{ color, cursor: 'pointer' }} onClick={togglePickerArea}>
          {color}
        </div>
        }
      </div>

      {togglePicker &&
      <ClickAwayListener onClickAway={togglePickerArea}>
        <SketchPicker
          className={sketchPickerClasses.join(' ')}
          color={color ? color : '#fff'}
          onChangeComplete={(color) => handleColor(color.hex)}
          disableAlpha
        />
      </ClickAwayListener>
      }
    </div>
  );
};

const useStyles = makeStyles(() => ({
  removeColor: {
    position: 'absolute',
    top: -5,
    right: 5,
    cursor: 'pointer',
    '&:hover': {
      color: 'darkred',
    },
  },
  colorPicker: {
    backgroundColor: Colors.wrapperLight + ' !important',
    zIndex: 100,
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  colorCircle: {
    width: 28,
    height: 28,
    transform: 'scale(1)',
    transition: 'transform 100ms ease 0s',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  topPosition: {
    bottom: 45,
  },
  leftPosition: {
    left: '-100%',
  },
  innerCircle: {
    background: 'transparent',
    height: '100%',
    width: '100%',
    position: 'relative',
    outline: 'none',
    boxShadow: 'rgb(244, 67, 54) 0px 0px 0px 14px inset',
    transition: 'box-shadow 100ms ease 0s',
    borderRadius: '50%',
    cursor: 'pointer',
  },
}));

export default ColorPicker;