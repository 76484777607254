import React from 'react';
import { StickyContainer } from 'react-sticky';

import Colors from '../../theme/Colors';
import CardShopMatchFilters from '../CardShop/CardShopMatchFilters';
import Loading from '../Loading/Loading';
import LoadMore from '../Loading/LoadMore';
import MarketplaceMarketItem from './MarketplaceMarketItem';
import { bindActionCreators } from 'redux';
import { setCollectorsViewSelectSellInfo } from '../../redux/actions';
import { connect } from 'react-redux';
import SlideInOut from '../Layout/SlideInOut';
import Helpers from '../../theme/Helpers';
import HoverIcon from '../Icon/HoverIcon';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import InventoryCards from '../Inventory/InventoryCards';

const useStyles = makeStyles(() => ({
  topArea: {
    background: 'rgba(255, 255, 255, 0.04)',
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    height: 62,
  },
}));

const MarketplaceSelectRedeemedCardToSell = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    cards,
    loading,
    onLoadMore,
    totalCount,
    myViewer,
  } = props;

  const onClose = () => {
    props.setCollectorsViewSelectSellInfo(null);
  };

  return (
    <SlideInOut
      isOpen={!!cards}
      additionalLeft={96}
      bgAddLeft={63}
      bgZIndex={150000}
      onClose={onClose}
      myViewer={myViewer}
    >
      <div style={{ color: Colors.white, position: 'relative' }}>
        <div className={classes.topArea}>
          <div style={{ ...Helpers.fillRowCenter }}>
            <HoverIcon
              icon="fa-thin fa-xmark"
              color={Colors.white}
              hoverColor={Colors.halfWhite}
              style={{ fontSize: 17, marginRight: 16, marginLeft: 24 }}
              onClick={onClose}
            />
            <div style={{ lineHeight: 1.6875 }}>
              {t('place_on_marketplace')}
            </div>
          </div>
        </div>

        <div
          className="inner-wrapper hide-scrollbar"
          style={{ margin: '0 auto', overflowY: 'auto', maxHeight: 'calc(100vh - 65px)' }}
        >
          {loading ?
            <Loading />
            :
            <InventoryCards
              totalCount={totalCount}
              onLoadMore={onLoadMore}
              cards={cards}
              sellOnly
              notMy
              t={t}
            />
          }
        </div>

      </div>
    </SlideInOut>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setCollectorsViewSelectSellInfo,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(MarketplaceSelectRedeemedCardToSell);