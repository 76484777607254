import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import Loading from '../Loading/Loading';
import { getLevelWord } from '../../utils/helper';

import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  table: {
    fontSize: 14,
    width: '100%',
    color: Colors.white,
    '& tbody': {
      '& td': {
        padding: '16.5px 0px',
        borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
      },

      '& tr:last-child > td': {
        borderBottom: 'none',
        paddingBottom: 0,
      },
    },
  },
  smTable: {
    '& tbody td': {
      padding: '13px 0px',
    },
  },
}));

const CardShopMatchCardLeftSideMenuDetails = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { loading, card, sm = false } = props;

  const levelColor = card ? Colors.level[getLevelWord('Card', card.rarity)] : Colors.white;

  const momentCardCreation = card && moment(
    parseInt(card.created_at),
  )
  .format('LL');

  const tableClasses = [classes.table];
  if (sm)
    tableClasses.push(classes.smTable);

  return (
    loading ?
      <Loading />
      :
      card &&
      <table className={tableClasses.join(' ')}>
        <tbody>
        <tr>
          <td style={{ color: Colors.halfWhite }}>{t('rarity')}</td>
          <td style={{ color: levelColor, ...Helpers.fillRowCenter, ...Helpers.mainEnd }}>
            <div>
              <img
                src={`${conf.cdn_url}/badges/card-level/${card.rarity !== null ? card.rarity : 5}.png?height=21`}
                alt="Level badge"
                style={{ maxWidth: 'initial', marginRight: 6 }}
              />
            </div>
            <div>{t(card.rarity !== null ? `rareness_level.${card.rarity}` : 'legacy')}</div>
          </td>
        </tr>
        <tr>
          <td style={{ color: Colors.halfWhite }}>{t('booster_name')}</td>
          <td style={{ ...Helpers.textRight }}>
            {card.edition || ((card.redeemed_booster && card.redeemed_booster.generic_booster_metadata) ? card.redeemed_booster.generic_booster_metadata.edition : '')}
          </td>
        </tr>
        <tr>
          <td style={{ color: Colors.halfWhite }}>{t('card_total_collected')}</td>
          <td style={{ ...Helpers.textRight }}>
            {card.overall_collected && card.overall_collected.toLocaleString()}
          </td>
        </tr>
        <tr>
          <td style={{ color: Colors.halfWhite }}>{t('card_collectors')}</td>
          <td style={{ ...Helpers.textRight }}>
            {card.unique_collectors && card.unique_collectors.toLocaleString()}
          </td>
        </tr>
        <tr>
          <td style={{ color: Colors.halfWhite }}>{t('card_collectible_since')}</td>
          <td style={{ ...Helpers.textRight }}>
            {momentCardCreation}
          </td>
        </tr>
        </tbody>
      </table>
  );
};

export default CardShopMatchCardLeftSideMenuDetails;