import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from 'react-step-progress-bar';

import { getMyHeroMakerImagesCount } from '../../apollo/queries';

import HeroMakerFooter from './HeroMakerFooter';
import Helpers from '../../theme/Helpers';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import Loading from '../Loading/Loading';
import Colors from '../../theme/Colors';

const useStyles = makeStyles(() => ({
  progressBar: {
    width: '100%',
    marginBottom: 57,
    '& .RSPBprogressBarText': {
      fontSize: 16,
      fontFamily: 'Bebas Neue',
      position: 'absolute',
      color: Colors.white,
      left: '50%',
      transform: 'translate(-50%, 0)',
    },
  },
}));

const HeroGeneration = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { handleClose, refetchHeroes, onlyOne, posesCount } = props;
  const [disabled, setDisabled] = useState(true);
  const [startCount, setStartedCount] = useState(null);

  useEffect(() => {
    if (!startCount)
      setStartedCount(posesCount);
  }, [startCount, posesCount]);

  useEffect(() => {
    if (onlyOne) {
      const timer = setTimeout(() => {
        setStartedCount(posesCount - 1);
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [onlyOne, posesCount]);

  const { loading, data } = useQuery(getMyHeroMakerImagesCount, {
    pollInterval: 5000,
    fetchPolicy: useCacheWithExpiration('getMyHeroMakerImagesCount'),
  });

  const count = data && data.getMyHeroMakerImagesCount;

  const finishedPosesCount = onlyOne ? 1 : 11;
  const progress = count ? Math.round((count - startCount) * (100 / finishedPosesCount)) : 0;

  if (count && finishedPosesCount === (count - startCount) && disabled)
    setDisabled(false);

  const handleClosing = () => {
    if (!disabled)
      refetchHeroes();

    handleClose();
  };

  return (
    <>
      {loading ?
        <Loading />
        :
        <div style={{ marginTop: 54 }}>
          {progress < 100 &&
          <Loading />
          }
          <div style={Helpers.fillColCenter}>
            <div style={{
              marginTop: 6,
              color: '#FFFFFF',
              fontSize: 26,
              fontFamily: 'Bebas Neue',
              marginBottom: 17,
            }}>
              {t('generating_hero')}...
            </div>
            <div
              style={{ marginBottom: 43, color: '#6A92C7', maxWidth: 534, ...Helpers.textCenter }}>
              {t('hero_generation_text')}
            </div>
          </div>
          <div className={classes.progressBar}>
            <ProgressBar
              percent={progress}
              filledBackground={'linear-gradient(to right, #09B711, #00FF08)'}
              unfilledBackground={'#00000099'}
              height={21}
              text={`${count - startCount}/${finishedPosesCount}`}
            />
          </div>
          <HeroMakerFooter
            onClick={handleClosing}
            value={t('finish')}
            disabled={disabled}
          />
        </div>
      }
    </>
  );
};

export default HeroGeneration;