import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import AnimationButton from '../Button/AnimationButton';
import TransferAllPopup from '../Dialog/TransferAllPopup';
import CoinsTypesList from './CoinsTypesList';

const useStyles = makeStyles(() => ({
  wrapper: {
    margin: '0 0 45px',
  },
  diagramWrapper: {
    margin: '16px 0',
    padding: 20,
    backgroundColor: Colors.containerBGColor,
  },
  headerLine: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainEnd,
    marginTop: 40,
  },
  buttonArea: {
    ...Helpers.fillRowCenter,
    position: 'relative',
  },
  transferInfo: {
    fontSize: 20,
    color: Colors.white,
  },
  headerLineItem: {
    ...Helpers.fillRowCross,
    height: 200,
    background: 'rgba(255, 255, 255, 0.04)',
    transition: 'all 0.3s ease-out',
    '& div': {
      transition: 'all 0.3s ease-out',
    },
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.08)',
    },
  },
}));

const Coins = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { data, myViewer, tab, loading, fetchMore } = props;
  const [convertMode, setConvertMode] = useState(false);
  const [show, setShow] = useState(false);
  const [convertingList, setConvertingList] = useState([]);

  const streamerCoins = (data) && data.streamerCoins;
  const gameCoins = (data) && data.gameCoins;
  const organisationCoins = (data) && data.organisationCoins;

  const tabs = [
    {
      value: t('streamers'),
      key: 'streamer',
      entries: streamerCoins && streamerCoins.points,
      hasMore: streamerCoins && streamerCoins.count > streamerCoins.points.length,
      totalCount: streamerCoins && streamerCoins.count,
      type: 'Streamer',
      objKey: 'streamerCoins',
    },
    {
      value: t('categories'),
      key: 'category',
      entries: gameCoins && gameCoins.points,
      hasMore: gameCoins && gameCoins.count > gameCoins.points.length,
      totalCount: gameCoins && gameCoins.count,
      type: 'Game',
      objKey: 'gameCoins',
    },
    {
      value: t('organisations'),
      key: 'organisation',
      entries: organisationCoins && organisationCoins.points,
      hasMore: organisationCoins && organisationCoins.count > organisationCoins.points.length,
      totalCount: organisationCoins && organisationCoins.count,
      type: 'Organisation',
      objKey: 'organisationCoins',
    },
  ];

  const activeTab = tabs.find(findTabs => findTabs.key === tab);

  const toggleConvertingItem = (item) => {
    const tmpList = [...convertingList];
    const index = tmpList.findIndex(cItem => cItem._id === item._id);
    if (index === -1)
      tmpList.push({ _id: item._id, energy: item.points_season - item.redeemed_points });
    else
      tmpList.splice(index, 1);

    setConvertingList(tmpList);
  };

  const convertAllEnergy = () => {
    const tmpList = [];
    activeTab.entries.map(item => {
      tmpList.push({ _id: item._id, energy: item.points_season - item.redeemed_points });
      return null;
    });
    setConvertingList(tmpList);
  };

  const toggleEnergyButton = () => {
    if (!convertMode)
      setConvertMode(true);
    else {
      if (convertingList.length === 0)
        setConvertMode(false);
      else
        setShow(true);
    }
  };

  const updateConvertingState = () => {
    setShow(false);
    setConvertingList([]);
    setConvertMode(false);
  };

  return (
    <>
      <div className={classes.wrapper}>
        <CoinsTypesList
          tabs={tabs}
          myViewer={myViewer}
          loading={loading}
          fetchMore={fetchMore}
          convertMode={convertMode}
          toggleConvertingItem={toggleConvertingItem}
          convertingList={convertingList}
        />

        <div style={{
          ...Helpers.fillRowCenter,
          ...Helpers.mainEnd,
          marginTop: 3,
        }}>
          <div className={classes.headerLineItem}>
            <div style={{ ...Helpers.fillCol, padding: 64 }}>
              <div style={{ fontWeight: 500, marginBottom: 5 }}>
                {t('chaos_in_depot')}
              </div>
              <div style={{ marginBottom: 10 }}>
                {t('chaos_in_depot_2')}
              </div>
              {convertMode &&
              <AnimationButton
                onClick={convertAllEnergy}
                style={{ padding: '10px 20px', borderRadius: 6, marginBottom: 10 }}
                status="red"
              >
                {t('convert_all_energy_to_coins')}
              </AnimationButton>
              }
              <AnimationButton
                onClick={toggleEnergyButton}
                style={{ padding: '10px 45px', borderRadius: 6 }}
                status="purple"
              >
                {t(convertingList.length === 0 && convertMode ? 'cancel' : 'transfer_energy')}
              </AnimationButton>
            </div>
          </div>
        </div>

      </div>
      {show &&
      <TransferAllPopup
        handleClose={updateConvertingState}
        convertingList={convertingList}
      />
      }
    </>
  );
};

export default Coins;