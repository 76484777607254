import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@material-ui/core';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';
import AlertGroupLine from './AlertGroupLine';
import AnimationButton from '../Button/AnimationButton';

const useStyles = makeStyles(() => ({
  titleLine: {
    background: 'rgba(255, 255, 255, 0.04)',
    padding: '1.5px 24px',
    fontSize: 14,
    color: Colors.white,
    marginBottom: 2,
    overflow: 'initial',
    zIndex: 10,
  },
  table: {
    color: Colors.white,
    width: '100%',
    fontSize: 14,
    marginTop: -2,
    '& thead': {
      backdropFilter: 'blur(50px)',
      background: 'rgba(255, 255, 255, 0.04)',
      '& th': {
        padding: 8,
        ...Helpers.textLeft,
        color: Colors.halfWhite,
        fontWeight: 400,
      },
    },
    '& tbody': {
      '& td': {
        padding: '17px 8px',
        borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
      },
    },
  },
  editButton: {
    border: 'none',
    padding: 7,
    fontSize: 14,
    marginRight: 9,
  },
  checkboxRoot: {
    padding: 0,
    marginLeft: 18,
  },
  menuArea: {
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 6,
    padding: 10,
    position: 'absolute',
    right: 20,
    top: 31,
    zIndex: 2,
    minWidth: 202,
  },
  menuItem: {
    padding: 10,
    cursor: 'pointer',
    width: 'calc(100% - 20px)',
    transition: 'all 0.3s ease-out',
    borderRadius: 2,
    color: Colors.white,
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.04)',
    },
  },
}));

const AlertGroup = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    title,
    isRedeem,
    isPurchase,
    isRankUp,
    alertSettings,
    loading,
    generateWebSource,
    selectedSettings,
    setSelectedSettings,
    leagueKeys,
  } = props;
  const [isShown, setIsShown] = useState(true);
  const [checked, setChecked] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (checked && selectedSettings.length === 0)
      setChecked(false);
  }, [checked, selectedSettings]);

  const changeCheckbox = (event) => {
    const { checked } = event.target;
    if (checked) {
      const set = new Set(selectedSettings);
      for (let i = 0; i < alertSettings.length; i++)
        set.add(alertSettings[i]._id);

      setSelectedSettings([...set]);
    } else {
      const tmpSettings = [...selectedSettings];

      for (let i = 0; i < alertSettings.length; i++) {
        const index = tmpSettings.findIndex(cItem => cItem === alertSettings[i]._id);
        if (index !== -1)
          tmpSettings.splice(index, 1);
      }
      setSelectedSettings(tmpSettings);
    }
    setChecked(checked);
  };

  const midSetSelectedSettings = (arr) => {
    setChecked(false);
    setSelectedSettings(arr);
  };

  return (
    <>
      <CustomBackdropFilter
        className={classes.titleLine}
        filter={'blur(50px)'}
        canvasFallback={true}
        html2canvasOpts={{
          allowTaint: true,
        }}
      >
        <div
          style={{
            ...Helpers.fillRowCenter,
            ...Helpers.mainSpaceBetween,
            cursor: 'pointer',
            marginLeft: 4,
          }}
          onClick={() => setIsShown(!isShown)}
        >
          <div>{t(title)} <span style={{ color: '#FFFFFF80' }}>
          ({alertSettings ? alertSettings.length : 0})
          </span>
          </div>

          <div style={{ ...Helpers.fillRowCross, position: 'relative' }}>
            <AnimationButton
              status="transparent"
              className={classes.editButton}
              onClick={(event) => {
                event.stopPropagation();
                setShowMenu(!showMenu);
              }}
            >
              <i
                className="fa-regular fa-ellipsis"
                style={{ fontSize: 14 }}
              />
            </AnimationButton>
            {showMenu &&
            <CustomBackdropFilter
              className={classes.menuArea}
              filter={'blur(30px)'}
              canvasFallback={true}
              html2canvasOpts={{
                allowTaint: true,
              }}
            >
              <div className={classes.menuItem} onClick={(event) => {
                event.stopPropagation();
                generateWebSource();
                setShowMenu(false);
              }}>
                {t('copy_source_code')}
              </div>
            </CustomBackdropFilter>
            }
            <i
              className={`fa-regular fa-${isShown ? 'minus' : 'plus'}`}
              style={{ color: Colors.halfWhite, cursor: 'pointer' }}
              onClick={() => setIsShown(!isShown)}
            />
          </div>
        </div>
      </CustomBackdropFilter>
      {isShown &&
      <table className={classes.table}>
        <thead>
        <tr>
          <th style={{ width: 60 }}>
            <Checkbox
              checked={checked}
              onChange={changeCheckbox}
              classes={{
                root: classes.checkboxRoot,
              }}
            />
          </th>
          <th style={{ width: 40 }} />
          <th style={{ width: 130 }}>{t('stages')}</th>
          {isRedeem &&
          <>
            <th>{t('rarity')}</th>
            <th>{t('quality_from_to')}</th>
          </>
          }
          {isPurchase &&
          <th>{t('cards_amount')}</th>
          }
          {isRankUp &&
          <th>{t('league')}</th>
          }
          <th style={{ width: 150 }}>{t('twitch')}</th>
          <th style={{ width: 150 }}>{t('discord')}</th>
        </tr>
        </thead>

        <tbody>
        {loading ?
          <tr>
            <td colSpan={6}>
              <i className="fa-light fa-loader fa-spin" />
            </td>
          </tr>
          :
          alertSettings &&
          <>
            {alertSettings.length > 0 ?
              alertSettings.map(setting => (
                <AlertGroupLine
                  key={`AlertLineKey${setting._id}`}
                  alertSetting={setting}
                  selectedSettings={selectedSettings}
                  setSelectedSettings={midSetSelectedSettings}
                  leagueKeys={leagueKeys}
                />
              ))
              :
              <tr>
                <td colSpan={title === 'booster_redeem' ? 7 : 6}
                    style={{ color: Colors.halfWhite }}>
                  {t('no_alerts_configured')}
                </td>
              </tr>
            }
          </>
        }
        </tbody>
      </table>
      }
    </>
  );
};

export default AlertGroup;