import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

import Colors from '../../theme/Colors';

const useStyles = makeStyles(() => ({
  button: {
    fontSize: 20,
    fontWeight: 400,
    textTransform: 'initial',
    transition: '0.3s',
    fontFamily: 'Bebas Neue',
    padding: '6px 45px',
    borderRadius: 3,
    color: Colors.white,
    background: 'linear-gradient(to right, #ce1537 0%, #f73f61 50%, #ce1537 100%)',
    '&:hover': {
      background: 'linear-gradient(to right, #ef274c 0%, #ff738d 50%, #ef274c 100%)',
    },
  },
  disabled: {
    filter: 'grayscale(100%)',
    cursor: 'not-allowed',
  },
}));

const CraftButton = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { cards, costs, onClick, disabled } = props;

  const buttonClasses = [classes.button];
  if (disabled)
    buttonClasses.push(classes.disabled);

  return (
    <Button
      variant="contained"
      classes={{ root: buttonClasses.join(' ') }}
      onClick={disabled ? null : onClick}
    >
      {t('craft')} <img src={`${process.env.PUBLIC_URL}/icons-png/cardredicon.png`}
                         alt="Energy"
                         style={{ margin: '0 5px' }} /> ({cards}/{costs})
    </Button>
  );
};

export default CraftButton;