import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Helpers from '../../theme/Helpers';
import CardViewDialogContainer from '../../containers/Cards/CardViewDialog';
import CardProgresses from '../Cards/CardProgresses';
import Colors from '../../theme/Colors';
import moment from 'moment';
import TransparentPopup from '../Dialog/TransparentPopup';

const useStyles = makeStyles({
  levelCardContainer: {
    ...Helpers.fillRowCenter,
    flexWrap: 'wrap',
  },
  cardItem: {
    cursor: 'pointer',
    margin: 9,
    border: '2px solid transparent',
    height: 295,
    width: 191,
    borderRadius: 4,
    position: 'relative',
    transition: 'all 0.3s ease',
    '& > img': {
      transition: 'all 0.3s ease',
    },
    '&$mobileView': {
      height: 195,
      width: 126,
      margin: 0,
    },
    '&:hover,&$mobile': {
      boxShadow: 'inset 0 0 10px #6D04FB',
      borderColor: '#6D04FB',
      '& > img': {
        filter: 'brightness(50%) grayscale(100%)',
      },
      '& $shadow, & $innerArea': {
        display: 'flex',
      },
    },
  },
  itemCount: {
    background: 'linear-gradient(to bottom, #BB1716, #9E0902, #950409)',
    borderRadius: 50,
    color: Colors.white,
    padding: '8px 13px',
    position: 'absolute',
    zIndex: 1,
    fontWeight: 'bold',
    right: 0,
    top: 0,
  },
  notAvailable: {
    '&:hover,&$mobile': {
      boxShadow: 'inset 0 0 10px #F11035',
      borderColor: '#F11035',
    },
  },
  shadow: {
    transition: 'all 0.3s ease',
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'none',
    boxShadow: '0 0 30px #6D04FB, inset 0 30px 60px #6D04FB, inset 0 -30px 60px #6D04FB',
    borderRadius: 4,
    top: 0,
    left: 0,
  },
  notAvailableShadow: {
    boxShadow: '0 0 30px #F11035, inset 0 30px 60px #F11035, inset 0 -30px 60px #F11035',
  },
  innerArea: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 2,
    width: '100%',
    height: '100%',
    ...Helpers.fillColCenter,
    ...Helpers.mainEnd,
    padding: '20px 0',
    display: 'none',
  },
  customDot: {
    background: '#020812 !important',
    border: '1px solid #314070 !important',
    borderRadius: 50,
    '&:before': {
      color: '#F11035 !important',
      fontSize: '12px !important',
    },
  },
  hoverIcon: {
    transition: 'all 0.3s ease',
    '&:hover': {
      color: Colors.onBoardingTextColor,
    },
  },
  mobile: {},
  mobileView: {},
});

const EventStreamerCards = (props) => {
  const classes = useStyles();
  const { cards, coinsInformation, isTabletOrMobile } = props;
  const [cardViewOpen, setCardViewOpen] = useState(null);
  const [cardBig, setCardBig] = useState(null);
  const slider = useRef(null);

  /**
   * Check if collectible until time is over for the card
   *
   * @param card
   * @returns {boolean}
   */
  const checkSpecialCardNotAvailable = (card) => {
    if (card.matchCardModel === 'SpecialCard')
      return moment(parseInt(card.match_card.collectible_until))
      .isBefore();

    return false;
  };

  const settings = {
    className: 'center',
    centerMode: true,
    dots: true,
    speed: 500,
    initialSlide: cards % 2 ? 0 : 1,
    centerPadding: '15px',
    slidesToShow: 3,
    slidesToScroll: 3,
    rows: 2,
    arrows: false,
    variableWidth: true,
    customPaging: i => (
      <button className={classes.customDot}>
        {i + 1}
      </button>
    ),
  };

  const getCards = () => {
    return cards && cards.map((card, index) => {
      const isNotAvailable = checkSpecialCardNotAvailable(card);
      const countOfLastCard = (card.match_card.level && card.match_card.level > 1) && cards[index - 1].count;

      return (
        <div
          key={`CardID#${card.match_card._id}`}
          onClick={() => isTabletOrMobile ? setCardBig({ ...card, countOfLastCard }) : null}
        >
          <div
            className={[classes.cardItem, isTabletOrMobile ? classes.mobileView : '', isNotAvailable ? classes.notAvailable : ''].join(' ')}
          >
            {!isTabletOrMobile &&
            <div
              className={[classes.shadow, isNotAvailable ? classes.notAvailableShadow : ''].join(' ')}
            />
            }
            {(card.count > 0) &&
            <div className={classes.itemCount}>
              {card.count}
            </div>
            }
            <img
              src={`${card.match_card.image}?height=${isTabletOrMobile ? 195 : 295}`}
              alt="EventCard"
              style={{
                filter: card.count > 0 ? '' : 'brightness(50%) grayscale(100%)',
                borderRadius: 4,
              }}
            />
            {!isTabletOrMobile &&
            <div className={classes.innerArea}>
              <FontAwesomeIcon
                icon={faInfoCircle}
                size="2x"
                color={Colors.white}
                onClick={() => setCardViewOpen(card)}
                className={classes.hoverIcon}
                style={{ position: 'absolute', left: 10, top: 50 }}
              />
              <CardProgresses
                card={card}
                coinsInformation={coinsInformation}
                sorting={{}}
                hideCardsCraft={card.match_card && card.match_card.level === 1}
                countOfLastCard={countOfLastCard}
                isEventMode
              />
            </div>
            }
          </div>
        </div>
      );
    });
  };

  return (
    <>
      {isTabletOrMobile ?
        <div style={{ margin: isTabletOrMobile ? '0 -20px' : 0 }}>
          <Slider ref={slider} {...settings}>
            {getCards()}
          </Slider>
        </div>
        :
        <div className={classes.levelCardContainer}>
          {getCards()}
        </div>
      }

      {cardBig &&
      <TransparentPopup handleClose={() => setCardBig(null)}>
        <div
          className={[classes.cardItem, classes.mobile, checkSpecialCardNotAvailable(cardBig) ? classes.notAvailable : ''].join(' ')}
        >
          <div
            className={[classes.shadow, classes.mobile, checkSpecialCardNotAvailable(cardBig) ? classes.notAvailableShadow : ''].join(' ')}
          />
          <img
            src={`${cardBig.match_card.image}?height=295`}
            alt="EventCard"
            style={{
              filter: cardBig.count > 0 ? '' : 'brightness(50%) grayscale(100%)',
              borderRadius: 4,
            }}
          />
          <div className={classes.innerArea}>
            <FontAwesomeIcon
              icon={faInfoCircle}
              size="2x"
              color={Colors.white}
              onClick={() => setCardViewOpen(cardBig)}
              className={classes.hoverIcon}
              style={{ position: 'absolute', left: 10, top: 50 }}
            />
            <CardProgresses
              card={cardBig}
              coinsInformation={coinsInformation}
              sorting={{}}
              hideCardsCraft={cardBig.match_card && cardBig.match_card.level === 1}
              countOfLastCard={cardBig.countOfLastCard && cardBig.countOfLastCard}
              isEventMode
            />
          </div>
        </div>
      </TransparentPopup>
      }
      {cardViewOpen &&
      <CardViewDialogContainer card={cardViewOpen} handleClose={() => setCardViewOpen(null)} />
      }
    </>
  );
};

export default EventStreamerCards;