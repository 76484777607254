import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import CardViewDialogContainer from '../../containers/Cards/CardViewDialog';
import CardProgresses from './CardProgresses';

const useStyles = makeStyles(() => ({
  itemCount: {
    background: 'linear-gradient(to bottom, #BB1716, #9E0902, #950409)',
    borderRadius: 50,
    color: Colors.white,
    padding: '8px 13px',
    position: 'absolute',
    zIndex: 1,
    fontWeight: 'bold',
    right: 0,
    top: 0,
  },
  cardItem: {
    cursor: 'pointer',
    margin: 9,
    border: '2px solid transparent',
    height: 295,
    width: 191,
    borderRadius: 4,
    position: 'relative',
    transition: 'all 0.3s ease',
    '& > img': {
      transition: 'all 0.3s ease',
    },
    '&$mobileView': {
      height: 195,
      width: 126,
      margin: 0,
    },
    '&:hover,&$mobile': {
      boxShadow: 'inset 0 0 10px #6D04FB',
      borderColor: '#6D04FB',
      '& > img': {
        filter: 'brightness(50%) grayscale(100%)',
      },
      '& $shadow, & $innerArea': {
        display: 'flex',
      },
    },
  },
  notAvailable: {
    '&:hover,&$mobile': {
      boxShadow: 'inset 0 0 10px #F11035',
      borderColor: '#F11035',
    },
  },
  shadow: {
    transition: 'all 0.3s ease',
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'none',
    boxShadow: '0 0 30px #6D04FB, inset 0 30px 60px #6D04FB, inset 0 -30px 60px #6D04FB',
    borderRadius: 4,
    top: 0,
    left: 0,
  },
  notAvailableShadow: {
    boxShadow: '0 0 30px #F11035, inset 0 30px 60px #F11035, inset 0 -30px 60px #F11035',
  },
  smallBottomRow: {
    padding: '0 10px 10px',
    fontSize: 24,
    fontFamily: 'Bebas Neue',
    ...Helpers.fillRowCross,
    ...Helpers.mainSpaceEvenly,
  },
  hoverIcon: {
    transition: 'all 0.3s ease',
    '&:hover': {
      color: Colors.onBoardingTextColor,
    },
  },
  innerArea: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 2,
    width: '100%',
    height: '100%',
    ...Helpers.fillColCenter,
    ...Helpers.mainEnd,
    padding: '20px 0',
    display: 'none',
  },
  mobile: {},
  mobileView: {},
}));

function CardComponent(props) {
  const classes = useStyles();

  const {
    card,
    coinsInformation,
    sorting,
    countOfLastCard,
    hideCardsCraft,
    hideProgresses,
    disableInner,
    hideBadge,
    isTabletOrMobile,
  } = props;
  const { match_card } = card;
  const [cardViewOpen, setCardViewOpen] = useState(false);

  /**
   * Check if collectible until time is over for the card
   *
   * @param card
   * @returns {boolean}
   */
  const checkSpecialCardNotAvailable = (card) => {
    if (card.matchCardModel === 'SpecialCard')
      return moment(parseInt(card.match_card.collectible_until))
      .isBefore();

    return false;
  };

  if (!match_card || (!match_card.matchModel && !match_card.image.includes('/cards/sh/')) || !match_card.image)
    return null;

  const isNotAvailable = checkSpecialCardNotAvailable(card);

  return (
    <>
      <div
        key={`CardID#${match_card._id}`}
        onClick={() => (isTabletOrMobile || disableInner) ? setCardViewOpen(card) : null}
      >
        <div
          className={[classes.cardItem, isTabletOrMobile ? classes.mobileView : '', isNotAvailable ? classes.notAvailable : ''].join(' ')}
        >
          {!isTabletOrMobile &&
          <div
            className={[classes.shadow, isNotAvailable ? classes.notAvailableShadow : ''].join(' ')}
          />
          }
          {(!hideBadge && card.count > 0) &&
          <div className={classes.itemCount}>
            {card.count}
          </div>
          }
          <img
            src={`${match_card.image}?height=${isTabletOrMobile ? 195 : 295}`}
            alt="Card"
            style={{
              filter: card.count > 0 ? '' : 'brightness(50%) grayscale(100%)',
              borderRadius: 4,
            }}
          />
          {!isTabletOrMobile &&
          <div className={classes.innerArea}>
            <FontAwesomeIcon
              icon={faInfoCircle}
              size="2x"
              color={Colors.white}
              onClick={() => setCardViewOpen(card)}
              className={classes.hoverIcon}
              style={{ position: 'absolute', left: 10, top: 50 }}
            />
            <CardProgresses
              card={card}
              coinsInformation={coinsInformation}
              sorting={sorting}
              hideCardsCraft={hideCardsCraft}
              hideProgresses={hideProgresses}
              countOfLastCard={countOfLastCard}
            />
          </div>
          }
        </div>
      </div>
      {cardViewOpen &&
      <CardViewDialogContainer card={card} handleClose={() => setCardViewOpen(false)} />
      }
    </>
  );
}

CardComponent.propTypes = {
  card: PropTypes.object.isRequired,
  showMenu: PropTypes.bool,
  enableSmallMode: PropTypes.bool,
  hideBadge: PropTypes.bool,
  hideCardsCraft: PropTypes.bool,
  redirect: PropTypes.bool,
  hideProgresses: PropTypes.bool,
};

CardComponent.defaultProps = {
  showMenu: true,
  enableSmallMode: false,
  hideBadge: false,
  hideCardsCraft: false,
  redirect: false,
  hideProgresses: false,
};

const mapStateToProps = (state) => {
  const { main } = state;
  return { main };
};

export default connect(mapStateToProps)(withRouter(CardComponent));