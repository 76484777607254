import React from 'react';
import { withRouter } from 'react-router-dom';
import { StickyContainer } from 'react-sticky';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Colors from '../../theme/Colors';
import CardShopMatchFilters from '../CardShop/CardShopMatchFilters';
import Loading from '../Loading/Loading';
import LoadMore from '../Loading/LoadMore';
import MarketplaceMarketItem from './MarketplaceMarketItem';
import MarketplaceBreadcrumbs from '../Breadcrumbs/MarketplaceBreadcrumbs';
import Helpers from '../../theme/Helpers';

const useStyles = makeStyles(() => ({
  header: {
    height: 'calc(327px - 64px)',
    width: 'calc(100% - 64px)',
    backgroundSize: 'cover',
    ...Helpers.fillColMain,
    padding: 32,
    marginBottom: 3,
  },
  headTitle: {
    fontSize: 36,
    fontWeight: 600,
    marginBottom: 32,
    color: Colors.white,
    '& > span': {
      color: Colors.white,
    },
  },
}));

const MarketplaceOffers = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    offers,
    type,
    loading,
    onLoadMore,
    totalCount,
    variables,
    sorting, setSorting,
    quality, setQuality,
    rarity, setRarity,
    isSeasonal, setIsSeasonal,
  } = props;


  return (
    <div style={{ color: Colors.white, position: 'relative' }}>
      <MarketplaceBreadcrumbs tKey={t(`${type}_offers`)} route={`${type}-offers`} />

      <div className={classes.header}>
        <div
          className={classes.headTitle}
          dangerouslySetInnerHTML={{ __html: t(`${type}_offers_head`, { count: totalCount }) }}
          style={(type && type === 'active') ? { color: Colors.halfWhite } : {}}
        />
        <div style={{ maxWidth: 861, lineHeight: 2 }}>{t(`${type}_offers_desc`)}</div>
      </div>

      <StickyContainer>

        <CardShopMatchFilters
          setSorting={setSorting}
          sorting={sorting}
          setRarity={setRarity}
          rarity={rarity}
          setQuality={setQuality}
          quality={quality}
          setIsSeasonal={setIsSeasonal}
          isSeasonal={isSeasonal}
        />

        <div className="inner-wrapper" style={{ margin: '35px auto' }}>
          {loading ?
            <Loading />
            :
            <>
              <div style={{
                display: 'grid',
                gap: '26px 8px',
                gridTemplateColumns: 'repeat(4, 1fr)',
              }}>
                {offers && offers.map((offer) => (
                  <MarketplaceMarketItem item={offer} type={type} variables={variables} />
                ))}
              </div>

              <LoadMore count={offers && offers.length} totalCount={offers && totalCount}
                        loading={loading} onLoadMore={onLoadMore} />
            </>
          }
        </div>

      </StickyContainer>

    </div>
  );
};

export default withRouter(MarketplaceOffers);