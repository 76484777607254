import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { ProgressBar } from 'react-step-progress-bar';
import { withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getRedeemableBoosters,
  getRedeemedCardObjects, getShopItems, getTrendingBoosters,
  getTypeBoostersWithMyViewer, getUnopenedBoosters, getHeaderData
} from '../../apollo/mergedQueries';
import {
  redeemBooster as redeemBoosterMutation,
  openBooster as openBoosterMutation,
} from '../../apollo/mutations';

import Helpers from '../../theme/Helpers';
import BoosterViewContainer from '../../containers/Booster/BoosterView';
import AnimationButton from '../Button/AnimationButton';
import {
  resetBoosterActionData,
  setBoosterActionData, setBoosterLastRedeem, setCardIDShown,
  togglePlayingBoosterAnimation,
} from '../../redux/actions';
import {
  detectBrowser,
  getMatchData,
  isUserPremium,
  multiplyEnergyCoins,
} from '../../utils/helper';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';
import Colors from '../../theme/Colors';

import conf from '../../conf';
import ItemStoreBoosterPackRedeem from './ItemStoreBoosterPackRedeem';

const useStyles = makeStyles({
  container: {
    margin: '0 8px',

    '&:hover': {
      '& $rowBelow': {
        opacity: 1,
        marginTop: 5,
        marginBottom: 10,
      },
      '& $itemArea': {
        background: 'rgba(243, 6, 252, 0.08)',
        marginTop: 0,
        '&.shCoinsArea': {
          background: 'rgba(241, 146, 5, 0.12)',
        },
      },
      '& $cardsArea': {
        display: 'initial',
      },
      '& $inactiveItemArea': {
        background: 'rgba(255, 255, 255, 0.08)',
        '& $boosterDesign img': {
          opacity: 1,
          mixBlendMode: 'unset',
          filter: 'none',
        },
      },
    },
  },
  itemArea: {
    background: 'rgba(243, 6, 252, 0.04)',
    borderRadius: 6,
    cursor: 'pointer',
    padding: 10,
    width: 254,
    marginTop: 27,
    transition: 'all 0.3s ease-out',
    '&.shCoinsArea': {
      background: 'rgba(241, 146, 5, 0.08)',

      '& $energyArea': {
        background: 'rgba(241, 146, 5, 0.06)',
      },
    },
  },
  inactiveItemArea: {
    background: 'rgba(255, 255, 255, 0.04)',

    '& $boosterDesign img': {
      opacity: 0.24,
      height: 'auto',
      mixBlendMode: 'luminosity',
      filter: 'grayscale(1)',
    },

    '& $energyArea': {
      background: 'rgba(255, 255, 255, 0.04)',
    },
  },
  energyArea: {
    background: 'rgba(243, 6, 252, 0.06)',
    borderRadius: 6,
    padding: '12px 11px',
    ...Helpers.fillRowCross,
  },
  bigAvatar: {
    position: 'absolute',
    width: 98,
    height: 98,
    border: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  midAvatar: {
    width: 48,
    height: 48,
    border: 'none',
    marginRight: 8,
  },
  smallAvatar: {
    width: 32,
    height: 32,
    border: '1px solid #F306FC',
    marginRight: 6,
  },
  boosterDesign: {
    position: 'relative',
    cursor: 'pointer',
    margin: '23px 0',
    ...Helpers.fillRowMain,
  },
  rowBelow: {
    ...Helpers.fillRowCross,
    gap: 4,
    marginTop: -41,
    marginBottom: 30,
    opacity: 0,
    transition: 'all 0.3s ease-out',
  },
  cardsArea: {
    display: 'none',
    position: 'absolute',
    left: 'calc(100% + 26px)',
    top: 0,
    borderRadius: 6,
    width: 265,
    background: 'rgba(20, 39, 64, 0.7)',
    boxShadow: '0px 0px 49px #0000003B',
    padding: 10,
    zIndex: 100,
    overflow: 'visible',

    '& span': {
      color: 'white !important',
    },

    '&:before': {
      content: '" "',
      borderStyle: 'solid',
      borderWidth: '8px 8px 8px 0',
      borderColor: 'transparent rgba(20, 39, 64, 0.7) transparent transparent',
      position: 'absolute',
      top: 10,
      left: -8,
    },

    '&.switchHover': {
      right: 'calc(100% + 26px)',
      left: 'initial',
    },
  },
});

const ItemStoreBoosterPack = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {
    t,
    booster,
    myViewer,
    setBoosterPopup,
    inInventory,
    switchLastHover,
  } = props;
  const [showPopup, setShowPopup] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const [openBoosterFunction, openBoosterData] = useMutation(openBoosterMutation, {
    onCompleted: (data) => {
      const { cards, booster_animation, remaining_boosters } = data.openBooster;

      if (remaining_boosters)
        props.setBoosterActionData({
          openFunction: openBoosterFunction,
          matchType: matchData.type,
          name: matchData.name,
          availableBoosters: remaining_boosters,
        });
      else
        props.resetBoosterActionData();

      if (cards && cards.length === 1) {
        if (booster_animation && detectBrowser() !== 'Firefox') {
          const route = `/booster-animation?cardId=${cards[0]._id}`;
          props.togglePlayingBoosterAnimation(false);
          props.history.push(route);
        } else
          props.setCardIDShown(cards[0]._id);
      } else
        props.setCardIDShown(cards, conf.event_booster_redirect_to_shop.includes(booster._id));
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [
      { query: getTypeBoostersWithMyViewer, variables: { match: booster.match._id } },
      { query: getRedeemableBoosters },
      { query: getTrendingBoosters },
      {
        query: getRedeemedCardObjects, variables: {
          pagination: { page: 1, perPage: 25 },
          min_quality: 0, max_quality: 100, rarity: [],
          sorting: { field: 'created_at', order: 'DESC' },
        },
      },
      {
        query: getUnopenedBoosters, variables: {
          pagination: { page: 1, perPage: 25 },
        },
      },
      {
        query: getShopItems, variables: {
          pagination: { page: 1, perPage: 25 },
          min_quality: 0, max_quality: 100, rarity: [],
          sorting: { field: 'created_at', order: 'DESC' },
        },
      },
    ],
  });

  const [redeemBoosterFunction, { loading }] = useMutation(redeemBoosterMutation, {
    onCompleted: (data) => {
      const { cards, booster_animation } = data.redeemBooster;
      props.setBoosterLastRedeem(moment().unix());

      if (cards && cards.length === 1) {
        if (booster_animation && detectBrowser() !== 'Firefox') {
          const route = `/booster-animation?cardId=${cards[0]._id}`;
          props.togglePlayingBoosterAnimation(false);
          props.history.push(route);
        } else
          props.setCardIDShown(cards[0]._id);
      } else
        props.setCardIDShown(cards, conf.event_booster_redirect_to_shop.includes(booster._id));

      /*const toastContent = (
        <ToastContent
          titleColor={Colors.white}
          title={t('toasts.booster_redeemed.title')}
          text={t('toasts.booster_redeemed.text', {
            quantity,
            streamerName: matchData.name,
          })}
        />
      );
      const toastIcon = (
        <img src={`${conf.cdn_url + booster.front_image}`}
             alt={t('toasts.booster_redeemed.title')} />
      );

      toast(toastContent, {
        theme: 'dark',
        autoClose: false,
        icon: () => toastIcon,
        closeButton: CloseButton,
      });*/
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [
      { query: getTypeBoostersWithMyViewer, variables: { match: booster.match._id } },
      { query: getRedeemableBoosters },
      { query: getTrendingBoosters },
      {
        query: getUnopenedBoosters, variables: {
          pagination: { page: 1, perPage: 25 },
        },
      },
      { query: getHeaderData }
    ],
  });

  /*const CloseButton = ({ closeToast }) => (
    <AnimationButton
      onClick={() => {
        closeToast();
        props.history.push('/inventory/booster');
      }}
      status="whiteTransparency"
      style={{ padding: '5.5px 14px', alignSelf: 'center' }}
    >
      {t('open_now')}
    </AnimationButton>
  );*/

  const cards = booster.generic_cards;
  const isEnergy = !!booster.energy_costs;
  const costs = multiplyEnergyCoins(isEnergy ? booster.energy_costs : booster.coin_costs);
  const { isPremiumActive } = isUserPremium(myViewer);
  const energyMaxSize = isPremiumActive ? conf.premium_tank_size : 100;
  const isInactive = isEnergy ? booster.energy_costs > booster.user_energy : booster.coin_costs > booster.user_coins;
  const matchData = getMatchData(booster, 100, 100);

  const isEvent = booster.redeemable_from && booster.redeemable_until;
  const now = moment();
  const startedAt = moment.unix(booster.redeemable_from / 1000);
  const finishedAt = moment.unix(booster.redeemable_until / 1000);
  const eventIsRunning = isEvent && startedAt.isBefore(now) && finishedAt.isAfter(now);

  const makeAction = () => {
    if (isInactive)
      props.history.push(matchData.url);
    else
      redeemBooster(booster._id, quantity);
  };

  const openBooster = () => {
    openBoosterFunction({
      variables: {
        ids: [booster._id],
      },
    });
  };

  const redeemBooster = (id, quantity) => {
    redeemBoosterFunction({
      variables: {
        id,
        quantity: parseInt(quantity),
        open_booster: true,
      },
    });
  };

  const quantityChange = (newQuantity) => {
    setQuantity(newQuantity);
  };

  return (
    <>
      <div className={classes.container}>
        <div
          className={[
            classes.itemArea,
            !isEnergy ? 'shCoinsArea' : '',
            (isInactive || (isEvent && !eventIsRunning)) ? classes.inactiveItemArea : '',
          ].join(' ')}
          onClick={() => setBoosterPopup ? setBoosterPopup(booster) : setShowPopup(true)}
        >
          <div style={{ padding: '15px 10px' }}>
            <div
              style={{ ...Helpers.fillRowCross, ...(booster.starter_pack_logo_url ? { ...Helpers.mainCenter } : {}) }}
              onClick={() => props.history.push(matchData.url)}
            >
              {booster.starter_pack_logo_url ?
                <img
                  src={`${booster.starter_pack_logo_url}?height=48`}
                  alt="Starter pack logo"
                  height={48}
                />
                :
                <>
                  <Avatar
                    className={classes.midAvatar}
                    alt="Avatar"
                    src={matchData.avatar}
                  />
                  <div>
                    <div
                      style={{
                        fontSize: 16,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        width: 150,
                      }}
                    >
                      {matchData.name}
                    </div>
                    <div
                      style={{
                        fontSize: 14,
                        color: Colors.halfWhite,
                        fontWeight: 300,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        width: 150,
                      }}
                    >
                      {booster.edition && !booster.edition.includes('Base Set') ? booster.edition : t('base_card_set')}
                    </div>
                  </div>
                </>
              }
            </div>

            <div className={classes.boosterDesign}>
              <img
                src={`${conf.cdn_url}${booster.front_image}?width=156`}
                alt="Starter pack"
                width={156}
                height={225}
              />
              {(!booster.is_starter_pack && !booster.is_custom_booster) &&
              <Avatar
                className={classes.bigAvatar}
                alt={matchData.name}
                src={matchData.avatar}
              />
              }

              <CustomBackdropFilter
                className={[classes.cardsArea, switchLastHover ? 'switchHover' : ''].join(' ')}
                filter={'blur(25px)'}
                canvasFallback={true}
                html2canvasOpts={{
                  allowTaint: true,
                }}
              >
                <div style={{ padding: 10 }}>
                  <div style={{ marginTop: -2, fontFamily: 'Bebas Neue', fontSize: 28 }}>
                    {t(booster.is_starter_pack ? 'starterpack' : 'card_booster')}
                  </div>
                  <div
                    style={{ color: '#FFFFFF80', marginTop: 4 }}
                    dangerouslySetInnerHTML={{ __html: t(booster.is_starter_pack ? 'starterpack_includes' : 'card_booster_includes') }}
                  />
                </div>

                <div
                  style={{
                    background: 'rgba(255, 255, 255, 0.04',
                    borderRadius: 6,
                    marginTop: 10,
                    padding: 10,
                  }}
                >
                  <div style={{ fontWeight: 300 }}>{t('cards_included')}</div>
                  <div style={{ ...Helpers.fillRowCross, marginTop: 12 }}>
                    {cards && cards.slice(0, 3)
                    .map(card => (
                      <img key={card._id} src={`${card.card_layer_image}?height=88`} alt="Card"
                           style={{ marginRight: 5.5, borderRadius: 3 }} />
                    ))}
                    <div style={{
                      background: 'rgba(255, 255, 255, 0.05',
                      borderRadius: 3,
                      ...Helpers.fillColCenter,
                      width: 57,
                      height: 88,
                    }}>
                      <div style={{ fontFamily: 'Bebas Neue', fontSize: 28, lineHeight: 1 }}>
                        {cards && cards.length > 3 ? `+${cards.length - 3}` : t('no')}
                      </div>
                      <div style={{
                        color: Colors.halfWhite,
                        fontSize: 14,
                        fontWeight: 300,
                      }}>
                        {t('more')}
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ ...Helpers.textCenter, fontSize: 12, fontWeight: 200, marginTop: 8 }}>
                  {t('click_to_see_booster')}
                </div>
              </CustomBackdropFilter>
            </div>

            {!inInventory &&
            <>
              <div style={{ color: Colors.halfWhite, fontSize: 12, fontWeight: 300 }}>
                {t('costs')}
              </div>
              <div style={{ marginTop: 4, fontSize: 22, ...Helpers.fillRow }}>
                <div>{costs}</div>
                <div style={{ marginLeft: 4 }}>
                  <img
                    src={`${conf.cdn_url}/assets/template/${isEnergy ? 'heroMaker/energy.png' : 'sh_coin.png'}?height=26`}
                    alt="Energy" />
                </div>
              </div>
            </>
            }

          </div>
          {!inInventory &&
          <div className={[classes.energyArea, !isEnergy ? 'shCoinsArea' : ''].join(' ')}>
            <Avatar
              className={classes.smallAvatar}
              alt="Avatar"
              src={matchData.avatar}
              style={isEnergy ? {} : { borderColor: '#F19205' }}
            />
            <div style={{ width: '100%' }}>
              <div style={{
                fontSize: 14, ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween,
                fontWeight: 300,
              }}>
                <div>
                  {t(isEnergy ? 'energy' : 'sh_coins')}
                </div>
                <div style={{ ...Helpers.fillRow }}>
                  <div>{multiplyEnergyCoins(isEnergy ? booster.user_energy : booster.user_coins)}</div>
                  <div style={{ marginLeft: 4 }}>
                    <img
                      src={`${conf.cdn_url}/assets/template/${isEnergy ? 'heroMaker/energy.png' : 'sh_coin.png'}?height=18`}
                      alt="Value" />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: 6 }}>
                <ProgressBar
                  percent={Math.round((isEnergy ? booster.user_energy / energyMaxSize : booster.user_coins / myViewer.max_coins_size) * 100)}
                  filledBackground={isEnergy ? 'linear-gradient(90deg, #8B26E8 0%, #F306FC 100%)' : 'linear-gradient(90deg, #F19205 0%, #FFFE47 100%)'}
                  unfilledBackground={'rgba(255, 255, 255, 0.04)'}
                  height={7}
                />
              </div>
              <div style={{ ...Helpers.textRight, fontSize: 12, fontWeight: 200, marginTop: 4 }}>
                ({multiplyEnergyCoins(isEnergy ? energyMaxSize : myViewer.max_coins_size)}) max.
              </div>
            </div>
          </div>
          }
        </div>
        <div className={classes.rowBelow}>
          {inInventory ?
            <AnimationButton
              status={'neonRed'}
              onClick={openBoosterData.loading ? null : openBooster}
              style={{
                fontFamily: 'Montserrat',
                fontSize: 14,
                width: '100%',
                padding: 14,
              }}
            >
              {openBoosterData.loading ?
                <i className="fa-light fa-loader fa-spin" style={{ fontSize: 18 }} />
                :
                <>
                  <i
                    className="fa-light fa-box-open"
                    style={{ fontSize: 18, marginRight: 10, verticalAlign: 'bottom' }}
                  />
                  {t('open')}
                </>
              }
            </AnimationButton>
            :
            <ItemStoreBoosterPackRedeem
              t={t}
              booster={booster}
              makeAction={makeAction}
              loading={loading}
              quantity={quantity}
              quantityChange={quantityChange}
              eventIsRunning={eventIsRunning}
              now={now}
              isEvent={isEvent}
              isInactive={isInactive}
            />
          }
        </div>
      </div>
      {showPopup &&
      <BoosterViewContainer redeemBooster={redeemBooster} booster={booster}
                            handleClose={() => setShowPopup(false)} />
      }
    </>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    togglePlayingBoosterAnimation,
    setBoosterActionData,
    resetBoosterActionData,
    setCardIDShown,
    setBoosterLastRedeem,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(withRouter(ItemStoreBoosterPack));