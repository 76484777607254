import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from '@material-ui/pickers';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import { updateBoosterTemplate as updateBoosterTemplateMutation } from '../../apollo/mutations';
import { getAllBooster } from '../../apollo/queries';

import PagePopup from '../../components/Dialog/PagePopup';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import AnimationButton from '../Button/AnimationButton';
import EnergySlider from '../CardTool/EnergySlider';
import { multiplyEnergyCoins } from '../../utils/helper';
import { setBoosterData } from '../../redux/actions';
import ClosePopupButton from '../Button/ClosePopupButton';

import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  innerContainer: {
    ...Helpers.fillColCenter,
    width: '100vw',
    height: '100vh',
    color: Colors.white,
  },
  hoverIcon: {
    width: 40,
    height: 40,
    borderRadius: 6,
    ...Helpers.fillColCenter,
    transition: 'all 0.3s ease-out',
    background: '#FFFFFF14',
    cursor: 'pointer',
    '&:hover': {
      background: '#FFFFFF3D',
    },
  },
  textField: {
    background: 'transparent',
    color: Colors.white,
    borderRadius: 6,
    width: '100%',
    borderColor: '#FFFFFF1F !important',
  },
  selectBorder: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FFFFFF1F !important',
    },
  },
  box: {
    border: '1px solid #FFFFFF1F',
    padding: 12,
    width: 'calc(100% - 24px)',
    marginTop: 8,
    borderRadius: 6,
  },
}));

const BoosterCreatePopup = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { handleClose, isStandard, cardTool, editMode } = props;
  const [value, setValue] = useState(cardTool.boosterName ? cardTool.boosterName : '');
  const [costs, setCosts] = useState(cardTool.energyCosts ? cardTool.energyCosts : 6);
  const [startDate, setStartDate] = useState(cardTool.startDate ? cardTool.startDate : null);
  const [endDate, setEndDate] = useState(cardTool.endDate ? cardTool.endDate : null);

  const [updateBoosterTemplate, { loading }] = useMutation(updateBoosterTemplateMutation, {
    onCompleted: (data) => {
      props.setBoosterData(value, costs, startDate, endDate, cardTool.boosterId, cardTool.cards, false, data.updateBoosterTemplate);
      handleClose();
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [
      { query: getAllBooster },
    ],
  });

  const startConfiguration = () => {
    if (editMode)
      updateBoosterTemplate({
        variables: {
          id: cardTool.boosterId,
          booster_meta_data: {
            edition: value,
            energy_costs: costs,
            redeemable_from: startDate && startDate.format(),
            redeemable_until: endDate && endDate.format(),
          },
        },
      });
    else {
      props.setBoosterData(value, costs, startDate, endDate);
      props.history.push(`/streamer-settings/booster-management/create`);
    }
  };

  return (
    <PagePopup>
      <div className={classes.innerContainer}>
        {editMode ?
          <ClosePopupButton handleClose={handleClose} style={{ right: 50, top: 50 }} />
          :
          <div
            style={{ position: 'absolute', top: 58, left: 240, ...Helpers.fillRowCross }}
            onClick={handleClose}
          >
            <div className={classes.hoverIcon}>
              <i className="fa-light fa-arrow-left" />
            </div>
            <div style={{ fontSize: 14, marginLeft: 12, cursor: 'pointer' }}>
              {t('back_to_overview')}
            </div>
          </div>
        }

        <div style={{ ...Helpers.fillColCenter }}>
          <img
            src={`${conf.cdn_url}/assets/template/booster_management/booster_creation_${isStandard ? 'standard' : 'event'}_big.png`}
            alt="Create booster"
            style={{ marginBottom: -32 }}
          />

          <div style={{
            marginBottom: 20,
            fontSize: 26,
            fontWeight: 600,
            textTransform: 'uppercase',
          }}>
            {t(editMode ? 'booster_settings' : (isStandard ? 'create_standard_booster' : 'create_event_booster'))}
          </div>

          <div style={{
            color: '#FFFFFF80',
            maxWidth: 560,
            ...Helpers.textCenter,
            marginBottom: 60,
          }}>
            {t(isStandard ? 'create_standard_booster_desc' : 'create_event_booster_desc')}
          </div>

          <div style={{ width: 560 }}>
            <TextField
              value={value}
              variant="outlined"
              placeholder={t('enter_booster_name')}
              className={classes.selectBorder}
              classes={{ root: classes.textField }}
              onChange={(event) => setValue(event.target.value)}
              required
            />

            {!isStandard &&
            <>
              <div
                className={classes.box}
                style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween }}
              >
                <DatePicker
                  disablePast
                  autoOk
                  format="DD.MM.YYYY"
                  value={startDate}
                  onChange={setStartDate}
                  label={t('start_date')}
                />
                <DatePicker
                  disablePast
                  autoOk
                  format="DD.MM.YYYY"
                  value={endDate}
                  onChange={setEndDate}
                  label={t('end_date')}
                  minDate={startDate}
                  disabled={!startDate}
                />
              </div>

              <div className={classes.box}>
                <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween }}>
                  <div style={{ color: Colors.halfWhite }}>
                    {t('booster_costs')}
                  </div>
                  <div style={{ fontSize: 14, ...Helpers.fillRowCross }}>
                    <div>{multiplyEnergyCoins(costs)}</div>
                    <img
                      src={`${conf.cdn_url}/assets/template/heroMaker/energy.png?height=18`}
                      alt="Energy"
                      style={{ marginLeft: 6 }} />
                  </div>
                </div>

                <EnergySlider costs={costs} setCosts={setCosts} />
              </div>
            </>
            }
          </div>

          <AnimationButton
            status="neonGreen"
            style={{ marginTop: 22, fontFamily: 'Montserrat', fontSize: 14 }}
            disabled={(!startDate && !isStandard) || value.length < 5}
            onClick={loading ? null : startConfiguration}
          >
            {loading ?
              <i className="fa-light fa-loader fa-spin" style={{ fontSize: 18 }} />
              :
              t(editMode ? 'save' : 'start_configure')
            }
          </AnimationButton>
        </div>
      </div>
    </PagePopup>
  );
};

const mapStateToProps = (state) => {
  const { cardTool } = state;
  return { cardTool };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setBoosterData,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BoosterCreatePopup));