import React from 'react';
import { makeStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Colors from '../../theme/Colors';
import CardShopButton from '../Header/CardShopButton';
import Helpers from '../../theme/Helpers';
import menuConf from '../../menu';
import TopBarAlertSetting from './TopBarAlertSetting';
import TopBarBackInfo from './TopBarBackInfo';
import TopBarSupporter from './TopBarSupporter';
import TopBarStreamerButton from './TopBarStreamerButton';
import TopBarStorageBox from './TopBarStorageBox';
import TopBarGems from './TopBarGems';
import conf from '../../conf.json';
import AdSense from '../AdSense/AdSense';
import { disableSideBar, isUserPremium } from '../../utils/helper';
import TopBarSHButton from './TopBarSHButton';
import Symplr from '../AdSense/Symplr';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 67,
    right: 0,
    height: 62,
    background: '#0B1729',
    borderBottom: '3px solid #011024',
    color: Colors.white,
    transition: 'all 0.3s ease-out',
    paddingLeft: 28,
    overflow: 'initial',
    zIndex: 100,
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
  },
}));

const TopBar = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { location, extra, alertWebSourceUrls, cardDecks, viewer } = props;

  const { isPremiumActive } = isUserPremium(viewer);
  const isMenuExpanded = extra.expandedMenu;
  const isCardShopRoute = disableSideBar(location.pathname);
  // const isProfileRoute = location.pathname.includes('/profile/');

  const left = (isMenuExpanded ? 173 : 67) + (!isCardShopRoute ? 245 : 0);

  const urlParts = location.pathname.split('/');
  let subItem, foundItem;
  if (urlParts.length > 2) {
    subItem = urlParts.pop();
    let allSubMenus = [];
    const menuItem = menuConf[urlParts[1]];
    if (menuItem && menuItem.submenu) {
      menuItem && menuItem.submenu.map(subMenu => {
        allSubMenus = [...allSubMenus, ...subMenu.items];
        return null;
      });
      foundItem = allSubMenus.find(subMenuItem => subItem && subMenuItem.key === subItem);
    }
  } else if (isCardShopRoute)
    foundItem = menuConf[urlParts[1]];
  else
    foundItem = menuConf[urlParts[1]];

  // const getStreamerName = isProfileRoute ? urlParts[2] : null;

  return (
    <div className={classes.root} style={{ left }}>
      <div style={{ fontWeight: 500 }}>
        {foundItem && t(foundItem.name)}
        <TopBarBackInfo pathname={location.pathname} />
      </div>

      {(conf.ad_area.top_bar && viewer && !isPremiumActive) &&
      <Symplr id="streamheroes.gg_lb_1" />
      }

      <div style={{ ...Helpers.fillRowCross }}>
        <TopBarAlertSetting t={t} pathname={location.pathname}
                            alertWebSourceUrls={alertWebSourceUrls} />

        {!location.pathname.includes('streamer-settings/') &&
        <div style={{ ...Helpers.fillRowCenter }}>
          {location.pathname.includes('inventory/cards') ?
            <TopBarStorageBox t={t} cardDecks={cardDecks} />
            :
            <TopBarSHButton pathname={location.pathname} />
            /*<>
              <TopBarSupporter />
              <TopBarStreamerButton pathname={location.pathname} login={getStreamerName} />
            </>*/
          }

          <TopBarGems viewer={viewer} />

          <div style={{
            ...Helpers.fillRowCross,
            justifyContent: 'center',
            width: 64,
            height: 64,
            backgroundColor: 'rgba(255, 255, 255, 0.04)',
            backdropFilter: 'blur(50px)',
            borderLeft: '3px solid rgb(1, 16, 36)',
          }}>
            <CardShopButton />
          </div>
        </div>
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { extra } = state;
  return { extra };
};

export default connect(mapStateToProps)(withRouter(TopBar));