import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { updateAmountInAlbum } from '../../redux/actions';
import { logAmplitudeEvent } from '../../utils/amplitude';
import { addShopItem, roundToDecimal } from '../../utils/helper';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import NicePrice from '../Utils/NicePrice';
import AnimationButton from '../Button/AnimationButton';
import { useQuery } from '@apollo/react-hooks';
import { isOrderPossible } from '../../apollo/queries';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import conf from '../../conf';

const useStyles = makeStyles({
  itemArea: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    width: '100%',
    cursor: 'pointer',
    position: 'relative',
    transition: 'all 0.3s ease-out',
    marginBottom: 70,
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.08)',
    },
  },
  coloredBGLine: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: 3,
    background: '#FF0068',
    borderRadius: '6px 6px 0px 0px',
  },
  supportLine: {
    color: Colors.halfWhite,
    '& i': {
      fontSize: 22,
      marginRight: 10,
      color: Colors.white,
    },
    '& span': {
      color: Colors.white,
      marginRight: 10,
    },
    '& img': {
      marginLeft: 10,
      marginRight: 6,
      borderRadius: '50%',
      width: 30,
      height: 30,
      verticalAlign: 'text-bottom',
    },
  },
});

const CardShopOfferItem = (props) => {
  const classes = useStyles();
  const { offer, cardShop, streamer, t } = props;

  const variables = { offer: offer._id };
  const { data } = useQuery(isOrderPossible, {
    variables,
    fetchPolicy: useCacheWithExpiration('isOrderPossible', variables),
  });

  const orderPossible = (data) && data.isOrderPossible;

  const price = (offer.discounted_price ? offer.discounted_price : offer.unit_price) / 100;

  const albumProduct = cardShop.albums && cardShop.albums.find(albumProduct => albumProduct._id === offer._id);
  const amount = albumProduct && albumProduct.amount ? albumProduct.amount : 0;
  const costs = roundToDecimal(offer.products.reduce((acc, product) => acc + product.costs, 0));
  const link = `/card-shop/streamer/${streamer._id}/album/${offer._id}`;

  const updateQuantity = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (conf.track_amplitude) logAmplitudeEvent('Add Product to Shopping Cart', {
      'Product ID': offer._id,
      'Product Name': offer.title,
      'Product Count': amount,
      'Product Price': price,
    });

    if (document.getElementById('album-offer-image')) {
      const productImage = document.getElementById('album-offer-image');

      if (productImage)
        addShopItem(e, productImage);
    }

    props.updateAmountInAlbum({
      ...offer,
      amount: amount + 1,
      name: offer.title,
    });
  };

  return (
    <div className={classes.itemArea} onClick={() => props.history.push(link)}>
      <div className={classes.coloredBGLine} />

      <div style={{ ...Helpers.fillRowCross }}>
        <div style={{
          padding: '42px 0',
          height: 402,
          minWidth: 409, ...Helpers.fillColCenter,
          background: 'rgba(255, 255, 255, 0.04)',
        }}>
          <img src={`${offer.products[0].image}?height=402`} alt="Album" id="album-offer-image" />
        </div>

        <div style={{
          width: '100%',
          height: 486,
          ...Helpers.fillColCenter,
          ...Helpers.crossStart,
        }}>
          <div style={{ padding: '27px 32px', marginTop: 'auto' }}>
            <div style={{
              textTransform: 'uppercase',
              background: '#FF0068',
              padding: '8px 10px',
              fontSize: 12,
              borderRadius: 4,
              width: 'fit-content',
            }}>
              {t('special_event')}
            </div>

            <div style={{ marginTop: 14, fontSize: 24, fontWeight: 600 }}>
              {offer.title}
            </div>

            <div style={{ marginTop: 6, fontSize: 14, color: Colors.halfWhite }}>
              {offer.sub_title}
            </div>

            <div style={{ marginTop: 32, fontSize: 14, color: Colors.halfWhite }}>
              {offer.description}
            </div>
          </div>

          <div style={{
            borderTop: '1px solid rgba(255, 255, 255, 0.08)',
            padding: 16,
            ...Helpers.fillRowCenter,
            ...Helpers.mainSpaceBetween,
            marginTop: 'auto',
            width: 'calc(100% - 32px)',
          }}>
            <div
              className={classes.supportLine}
              dangerouslySetInnerHTML={{
                __html: t('support_album_with_image', {
                  streamer: streamer.display_name,
                  image: streamer.profile_image_url,
                }),
              }}
            />

            <div style={{ ...Helpers.fillRowCenter }}>
              <NicePrice price={costs / 100} />
              <AnimationButton
                status="neonGreen"
                onClick={(e) => updateQuantity(e)}
                disabled={orderPossible && !orderPossible.possible}
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: 14,
                  padding: '14px 16px',
                  borderRadius: 4,
                  fontWeight: 500,
                  marginLeft: 32,
                }}
              >
                <i
                  className="fa-light fa-cart-plus"
                  style={{ fontSize: 18, marginRight: 8, verticalAlign: 'bottom' }}
                />
                {t('add_to_cart')}
              </AnimationButton>
              <AnimationButton
                status="whiteTransparency"
                onClick={() => props.history.push(link)}
                style={{
                  padding: '14px 13px',
                  borderRadius: 4,
                  marginLeft: 6,
                }}
              >
                <i
                  className={`fa-light fa-eye`}
                  style={{ fontSize: 18 }}
                />
              </AnimationButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { cardShop } = state;
  return { cardShop };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({ updateAmountInAlbum }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardShopOfferItem));