import React, { useEffect, useState } from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as EmailValidator from 'email-validator';
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import { handleValueChange } from '../../redux/actions';
import { returnMobileObj } from '../../utils/responsive';
import statesJSON from '../../data/states.json';

const useStyles = makeStyles(() => ({
  formContainer: {
    ...Helpers.fillColCross,
  },
  formInput: {
    width: '100%',
    margin: 4,
    zIndex: 100000,
    background: 'transparent',
    borderColor: '#FFFFFF1F',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FFFFFF1F',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FFFFFF1F',
    },
    '& .MuiFormLabel-root': {
      color: Colors.halfWhite,
    },
  },
  focused: {
    borderColor: 'green',
  },
  filled: {
    fill: Colors.white,
    zIndex: 100000,
  },
  selectRoot: {
    borderColor: '#315AA5',
    zIndex: 100000,
  },
}));

const OrderForm = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    countryCosts,
    isFormFullFilled,
    setIsFormFullFilled,
    formValues,
    handleValueChange,
    parentHandleChange,
    isTabletOrMobile,
  } = props;
  const [plzError, setPLZError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [areStatesActive, setAreStatesActive] = useState(false);

  const countriesWithStates = ['IT', 'US', 'CA'];

  useEffect(() => {
    const check = checkIsFormFullFilled(formValues) && !plzError && !emailError;

    if (check !== isFormFullFilled)
      setIsFormFullFilled(check);
  }, [formValues]);

  const handleChange = (event) => {
    const { target } = event;

    if (target.name === 'postal_code' || target.name === 'country') {
      const value = target.name === 'postal_code' ? target.value : formValues.postal_code;
      const countryCode = target.name === 'country' ? target.value : formValues.country;
      if (value.length > 3) {
        const error = checkPostalCode(countryCode, value);
        if (error !== plzError)
          setPLZError(error);
      }

      if (target.name === 'country') {
        if (countriesWithStates.includes(target.value)) // countries with state selection
          setAreStatesActive(true);
        else if (areStatesActive) { // disable and remove state if existing with other country
          setAreStatesActive(false);
          handleValueChange('country_state', '');
        }
      }
    } else if (target.name === 'email') {
      const error = EmailValidator.validate(target.value);

      if (!error !== emailError)
        setEmailError(!error);
    }

    handleValueChange(target.name, target.value);

    if (parentHandleChange)
      parentHandleChange(event);
  };

  const checkPostalCode = (countryCode, value) => {
    let error = false;

    if (countryCode === 'DE' && !/^([0][1-9]|[1-9][0-9])[0-9]{3}$/.test(value))
      error = true;
    else if (countryCode === 'CH' && !/^([1-468][0-9]|[57][0-7]|9[0-6])[0-9]{2}$/.test(value))
      error = true;
    else if (!['DE', 'CH'].includes(countryCode)) {
      if (postcodeValidatorExistsForCountry(countryCode)) {
        if (!postcodeValidator(value, countryCode))
          error = true;
      }
    }

    return error;
  };

  const checkIsFormFullFilled = (newValues) => {
    for (const key in newValues) {
      if (key !== 'link' && key !== 'address_suffix' && key !== 'country_state') {
        if (newValues[key] === '')
          return false;
        if (key === 'postal_code' && checkPostalCode(newValues.country, newValues[key]) && !plzError)
          return false;
      } else if (key === 'country_state' && newValues[key] === '' && countriesWithStates.includes(newValues.country))
        return false;
    }
    return true;
  };

  countryCosts.sort((a, b) => a.code.localeCompare(b.code));

  return (
    <>
      <div
        className={classes.formContainer}
        style={returnMobileObj(isTabletOrMobile, { padding: 10 })}
      >
        <div style={{
          fontFamily: 'Montserrat',
          fontSize: 14,
          color: Colors.halfWhite,
          alignSelf: 'flex-start',
          marginBottom: 30,
        }}>
          {t('fill_in_your_data')}
        </div>
        <div style={{
          ...Helpers.fillRowCross,
          width: 'calc(100% + 10px)',
          ...returnMobileObj(isTabletOrMobile, { flexWrap: 'wrap' }),
        }}>
          <TextField
            required
            id="forename"
            name="forename"
            label={t('form_forename')}
            variant="outlined"
            value={formValues.forename}
            onChange={handleChange}
            classes={{
              root: classes.formInput,
            }}
          />
          <TextField
            required
            id="surname"
            name="surname"
            label={t('form_surname')}
            variant="outlined"
            value={formValues.surname}
            onChange={handleChange}
            classes={{
              root: classes.formInput,
            }}
          />
        </div>
        <TextField
          required
          id="email"
          name="email"
          error={emailError}
          helperText={emailError && t('form_email_error')}
          label={t('form_email')}
          variant="outlined"
          value={formValues.email}
          onChange={handleChange}
          classes={{
            root: classes.formInput,
          }}
        />
        <div style={{
          ...Helpers.fillRowCross,
          width: 'calc(100% + 10px)',
          ...returnMobileObj(isTabletOrMobile, { flexWrap: 'wrap' }),
        }}>
          <TextField
            required
            id="street"
            name="street"
            label={t('form_street')}
            variant="outlined"
            value={formValues.street}
            onChange={handleChange}
            classes={{
              root: classes.formInput,
            }}
          />
          <TextField
            required
            id="house_number"
            name="house_number"
            label={t('form_number')}
            variant="outlined"
            value={formValues.house_number}
            onChange={handleChange}
            style={{ width: isTabletOrMobile ? '100%' : '20%' }}
            classes={{
              root: classes.formInput,
            }}
          />
        </div>
        <TextField
          id="address_suffix"
          name="address_suffix"
          label={t('form_address_suffix')}
          variant="outlined"
          value={formValues.address_suffix}
          onChange={handleChange}
          classes={{
            root: classes.formInput,
          }}
        />
        <div style={{
          ...Helpers.fillRowCross,
          width: 'calc(100% + 10px)',
          ...returnMobileObj(isTabletOrMobile, { flexWrap: 'wrap' }),
        }}>
          <TextField
            required
            error={plzError}
            helperText={plzError && t('form_postal_code_error')}
            id="postal_code"
            name="postal_code"
            label={t('form_zip')}
            variant="outlined"
            value={formValues.postal_code}
            onChange={handleChange}
            style={{ width: isTabletOrMobile ? '100%' : '40%' }}
            classes={{
              root: classes.formInput,
            }}
          />
          <TextField
            required
            id="city"
            name="city"
            label={t('form_city')}
            variant="outlined"
            value={formValues.city}
            onChange={handleChange}
            classes={{
              root: classes.formInput,
            }}
          />
        </div>
        <FormControl variant="outlined" className={classes.formInput} required>
          <InputLabel id="country-select-label">{t('form_country')}</InputLabel>
          <Select
            labelId="country-select-label"
            id="country-select"
            name="country"
            value={formValues.country}
            onChange={handleChange}
            label={t('form_country')}
            classes={{
              icon: classes.filled,
              root: classes.selectRoot,
            }}
          >
            {countryCosts.map(country => (
              <MenuItem value={country.code} key={country.code}>
                {t(`country_iso_${country.code}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {(areStatesActive && statesJSON[formValues.country]) &&
        <FormControl variant="outlined" className={classes.formInput} required>
          <InputLabel id="country-state-select-label">{t('form_state')}</InputLabel>
          <Select
            labelId="country-state-select-label"
            id="country-state-select"
            name="country_state"
            value={formValues.country_state}
            onChange={handleChange}
            label={t('form_state')}
            classes={{
              icon: classes.filled,
              root: classes.selectRoot,
            }}
          >
            {statesJSON[formValues.country].map(state => (
              <MenuItem value={state.code} key={state.code}>
                {state.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        }
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { formValues } = state;
  return { formValues };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    handleValueChange,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(OrderForm);