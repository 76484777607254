import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';

import {
  createGenericOrder as createGenericOrderMutation,
  setOrderPending as setOrderPendingMutation,
} from '../../apollo/mutations';
import { getMyViewerAndShippingInformation } from '../../apollo/mergedQueries';

import PageTitle from '../../components/PageTitle/PageTitle';
import {
  handleValueChange,
  resetCardsInShop,
  setClientSecret, setCouponCode,
  toggleShoppingCart,
} from '../../redux/actions';
import { logAmplitudeEvent } from '../../utils/amplitude';
import CardShopPay from '../../components/CardShop/CardShopPay';
import { completeOrderWithAmplitude, getAllMatches, useQueryParams } from '../../utils/helper';
import CheckAddressPopup from '../../components/Order/CheckAddressPopup';

const conf = require('../../conf');

function CardShopPayContainer(props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryParams = useQueryParams();
  const redirectStatus = queryParams.get('redirect_status');
  const getClientSecret = queryParams.get('payment_intent_client_secret');
  const { cardShop, formValues, handleValueChange, setClientSecret } = props;
  const [updateOrder, setUpdateOrder] = useState(false);
  const [isFormFullFilled, setIsFormFullFilled] = useState(false);
  const [total, setTotal] = useState(0);
  const [shipCosts, setShipCosts] = useState(null);
  const [vatPercent, setVatPercent] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [paypalLoading, setPaypalLoading] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [summaryMatches, setSummaryMatches] = useState(null);
  const { clientSecret } = cardShop;
  const { editMode } = formValues;

  useEffect(() => {
    if (getClientSecret && !updateOrder)
      setOrderPending({ variables: { clientSecret: getClientSecret } });
  }, [getClientSecret, updateOrder]);

  const [setOrderPending] = useMutation(setOrderPendingMutation, {
    onCompleted: () => {
      setUpdateOrder(true);
    },
    onError: () => {
      setUpdateOrder(true);
    },
  });

  const [createGenericOrder] = useMutation(createGenericOrderMutation, {
    onCompleted: (data) => {
      const { createGenericOrder } = data;
      setClientSecret(createGenericOrder.client_secret);
      setTotal(createGenericOrder.amount);
      setShipCosts(createGenericOrder.ship_cost);
      setVatPercent(createGenericOrder.vat_percent);
      setOrderId(createGenericOrder._id);
      setDiscount(createGenericOrder.discount);

      const { status_code } = createGenericOrder;
      if (status_code) {
        let msgKey = '';
        if (status_code === 1)
          msgKey = 'error_msg.coupon_not_found';
        else if (status_code === 2)
          msgKey = 'error_msg.coupon_min_amount_not_reached';

        enqueueSnackbar(t(msgKey), { variant: 'error' });
      }

      // TODO: remove SH order id from local storage after order summmary
      localStorage.setItem('SH:ORDER:ID', createGenericOrder._id);
      localStorage.setItem('SH:SHIPPING:COSTS', createGenericOrder.ship_cost / 100); //TODO: remove and read the shipping costs in a better way
      if (conf.track_amplitude) logAmplitudeEvent('Filled In Address');
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
  });

  const { data, loading } = useQuery(getMyViewerAndShippingInformation);
  const myViewer = data && data.getMyViewer;
  const countryCosts = data && data.getShippingInformation;

  useEffect(() => {
    if (myViewer && myViewer.email)
      handleValueChange('email', myViewer.email);

    props.toggleShoppingCart(false);
  }, [myViewer]);

  useEffect(() => {
    const countCards = calculateCardsCount();

    if (countCards < 5 && cardShop.albums.length === 0 && !redirectStatus)
      props.history.push('/card-shop');
  }, [cardShop]);

  useEffect(() => {
    if (isFormFullFilled && !redirectStatus) {
      const variables = getOrderObj();
      if (clientSecret)
        variables.clientSecret = clientSecret;

      createGenericOrder({
        variables,
      });
    }
  }, [isFormFullFilled, cardShop.newCards, cardShop.cards, cardShop.static, cardShop.albums, cardShop.couponCode, formValues]);

  useEffect(() => {
    if (redirectStatus && redirectStatus === 'succeeded' && !summaryMatches) {
      setSummaryMatches(getAllMatches());

      /*try {
        setSummaryMatches(completeOrderWithAmplitude(t));
      } catch (err) {
      }*/
      localStorage.removeItem('newCardOrder');
      localStorage.removeItem('cardOrder');
      localStorage.removeItem('albumOrder');
      localStorage.removeItem('staticOrder');
      localStorage.removeItem('activated:coupon');
      props.resetCardsInShop();
    }
  }, [redirectStatus]);

  const calculateCardsCount = () => {
    return cardShop.cards.reduce((acc, card) => acc + card.count, 0) + cardShop.newCards.length;
  };

  const getOrderObj = () => {
    const billingAddress = { ...formValues };
    billingAddress.firstname = formValues.forename.trim();
    billingAddress.lastname = formValues.surname.trim();
    billingAddress.postcode = formValues.postal_code;
    billingAddress.street = formValues.street.trim();
    billingAddress.email = formValues.email.trim();
    delete billingAddress.forename;
    delete billingAddress.surname;
    delete billingAddress.postal_code;
    delete billingAddress.editMode;
    delete billingAddress.acceptWrongAddress;
    delete billingAddress.showCheckAddress;

    const products = [
      ...cardShop.newCards.map(card => ({
        _id: card.card_template,
        count: 1,
        redeemed_card_ids: [card._id],
      })),
      ...cardShop.cards.map(card => ({
        _id: card._id,
        count: card.count,
        redeemed_card_ids: card.rCards,
      })),
    ];
    const packIns = cardShop.static && cardShop.static.map(product => ({
      _id: product._id,
      quantity: product.amount,
    }));
    const cardOrder = {
      products,
      pack_ins: packIns,
    };
    const offerOrder = cardShop.albums && cardShop.albums.map(album => {
      return {
        offer: album._id,
        amount: album.amount,
      };
    });

    let coupon = cardShop.couponCode;
    if (!coupon) {
      const activatedCoupon = JSON.parse(localStorage.getItem('activated:coupon'));
      if (activatedCoupon)
        coupon = activatedCoupon.c;
    }

    return {
      cardOrder,
      offerOrder,
      billingAddress,
      acceptedWrongAddress: formValues.acceptWrongAddress,
      discount_code: coupon,
    };
  };

  const setEditMode = (value) => {
    handleValueChange('editMode', value);
  };

  const createOrderHandler = (data, actions) => {
    if (!orderId)
      return null;

    setPaypalLoading(true);

    const eventProperties = {
      'Payment Provider': 'PayPal',
    };

    if (conf.track_amplitude) logAmplitudeEvent('Continue to payment', eventProperties);

    return actions.order.create({
      payer: {
        name: {
          given_name: formValues.forename,
          surname: formValues.surname,
        },
        email_address: formValues.email,
        address: {
          address_line_1: formValues.street + ' ' + formValues.house_number,
          admin_area_2: formValues.city,
          postal_code: formValues.postal_code,
          country_code: formValues.country,
        },
      },
      redirect_urls: {
        return_url: `${conf.base_url}/card-shop-pay`,
      },
      purchase_units: [{
        amount: {
          currency_code: 'EUR',
          value: (total / 100).toFixed(2),
        },
        description: t('order_cards'),
        invoice_id: orderId,
      }],
    });
  };

  return (
    <>
      <PageTitle title={t('card_shop_pay')} />
      <CardShopPay
        formValues={formValues}
        countryCosts={countryCosts}
        createOrderHandler={createOrderHandler}
        orderId={orderId}
        total={total}
        shipCosts={shipCosts}
        vatPercent={vatPercent}
        isFormFullFilled={isFormFullFilled}
        setIsFormFullFilled={setIsFormFullFilled}
        paypalLoading={paypalLoading}
        setPaypalLoading={setPaypalLoading}
        clientSecret={clientSecret}
        editMode={editMode}
        loading={loading}
        orderCompleted={redirectStatus && redirectStatus === 'succeeded'}
        orderFailed={redirectStatus && redirectStatus !== 'succeeded'}
        summaryMatches={summaryMatches}
        discount={discount}
      />
      {formValues.showCheckAddress &&
      <CheckAddressPopup
        setShowCheckAddress={(value) => handleValueChange('showCheckAddress', value)}
        setEditMode={setEditMode}
        setAcceptWrongAddress={(value) => {
          if (conf.track_amplitude) logAmplitudeEvent('Accepted wrong address', {
            'Accepted by user': value,
          });
          
          handleValueChange('acceptWrongAddress', value);
        }}
      />
      }
    </>
  );
}

const mapStateToProps = (state) => {
  const { cardShop, formValues } = state;
  return { cardShop, formValues };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    handleValueChange,
    setClientSecret,
    resetCardsInShop,
    toggleShoppingCart,
    setCouponCode,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardShopPayContainer));