import React, { useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';

import CreateAlertGroup from './CreateAlertGroup';
import Colors from '../../theme/Colors';
import CreateAlertSelect from './CreateAlertSelect';
import AnimationButton from '../Button/AnimationButton';
import { getLevelWord, rangeArray } from '../../utils/helper';
import HoverIcon from '../Icon/HoverIcon';
import Helpers from '../../theme/Helpers';
import QualityWithText from '../Quality/QualityWithTextInput';

import conf from '../../conf.json';

const CreateAlertBoosterRedeem = (props) => {
  const { t, alertTool, setAlertToolTypeData } = props;
  const { cardRarity, minQuality, maxQuality } = alertTool;
  const [selectionCount, setSelectionCount] = useState(cardRarity && cardRarity.length > 0 ? cardRarity.length : 1);

  const rarityLevels = rangeArray(0, 5);

  const handleChange = (event, index) => {
    const { target } = event;

    const tmpRarities = cardRarity ? [...cardRarity] : [];
    if (tmpRarities[index])
      tmpRarities[index] = parseInt(target.value);
    else
      tmpRarities.push(parseInt(target.value));

    setAlertToolTypeData('cardRarity', tmpRarities);
  };

  const increaseSelections = () => {
    if (selectionCount < rarityLevels.length)
      setSelectionCount(selectionCount + 1);
  };

  const deleteSelection = (index) => {
    let tmpRarities = [...cardRarity];
    if (selectionCount <= 1) {
      tmpRarities = null;
    } else {
      tmpRarities.splice(index, 1);
      setSelectionCount(selectionCount - 1);
    }
    setAlertToolTypeData('cardRarity', tmpRarities);
  };

  const getRarityItems = (index) => (rangeArray(1, rarityLevels.length)
  .reverse()
  .map(idx => (
    cardRarity && cardRarity.includes(idx - 1) && cardRarity[index] !== idx - 1 ?
      null
      :
      <MenuItem value={idx - 1}>
        <img
          src={`${conf.cdn_url}/badges/card-level/${rarityLevels[idx - 1]}.png?height=18`}
          alt="Level badge"
          style={{ verticalAlign: 'bottom', marginRight: 6 }}
        />
        <span style={{ color: Colors.level[getLevelWord('Card', rarityLevels[idx - 1])] }}>
          {t(`rareness_level.${rarityLevels[idx - 1]}`)}
        </span>
      </MenuItem>
  )));

  const setQuality = (value) => {
    setAlertToolTypeData('minQuality', value.min);
    setAlertToolTypeData('maxQuality', value.max);
  };

  return (
    <>
      <CreateAlertGroup
        title={t('card_rarity')}
        t={t}
        isRequired
        description={t('card_rarity_description')}
      >
        {rangeArray(1, selectionCount)
        .map(idx => (
          <div style={{ ...Helpers.fillRowCross }}>
            <CreateAlertSelect
              value={(cardRarity && cardRarity[idx - 1]) && cardRarity[idx - 1]}
              handleChange={(event) => handleChange(event, idx - 1)}
              placeholder={t('select_rarity')}
              id={`rarity-${idx}`}
            >
              {getRarityItems(idx - 1)}
            </CreateAlertSelect>
            <HoverIcon
              icon="fa-light fa-xmark"
              color={Colors.halfWhite}
              hoverColor={Colors.white}
              style={{ fontSize: 14, marginLeft: 14 }}
              onClick={() => deleteSelection(idx - 1)}
            />
          </div>
        ))}

        <AnimationButton
          status="transparent"
          style={{
            padding: '9px 16px',
            border: 'none',
            fontFamily: 'Montserrat',
            fontSize: 14,
            marginTop: 14,
          }}
          onClick={increaseSelections}
          disabled={selectionCount >= rarityLevels.length}
        >
          <i className="fa-light fa-plus" style={{ fontSize: 16, marginRight: 10 }} />
          {t('add_rarity')}
        </AnimationButton>
      </CreateAlertGroup>

      <CreateAlertGroup
        title={t('card_quality')}
        t={t}
        isRequired
        description={t('card_quality_description')}
      >

        <div style={{ marginTop: 21 }}>
          <QualityWithText
            t={t}
            value={{
              min: minQuality ? minQuality : 1,
              max: maxQuality ? maxQuality : 100,
            }}
            spaceBetween={5}
            hasNoText
            setQuality={setQuality}
          />
        </div>
      </CreateAlertGroup>
    </>
  );
};

export default CreateAlertBoosterRedeem;