import {
  ADD_HERO_MAKER_ITEM,
  REMOVE_HERO_MAKER_COLORED_IMAGE, RESET_HERO_MAKER, SET_BODY_COLOR_HM, SET_HERO_MAKER_ASSETS,
  SET_HERO_MAKER_CURRENT_IMAGE, SET_HERO_MAKER_ITEMS, SET_LIGHT_DARK_MODE_HM, SET_TYPE_ASSETS_HM,
  UPDATE_HERO_MAKER_COLORED_IMAGES,
} from './constants';

const INITIAL_STATE = {
  items: [],
  assets: [],
  bodyAssets: null,
  headAssets: null,
  cachedColoredImages: {},
  currentHero: null,
  bodyColor: null,
  isDarkMode: true,
};

const cardToolReducer = (state = INITIAL_STATE, action) => {
  let newState;

  if (action.type === ADD_HERO_MAKER_ITEM) {
    const tmpItems = [...state.items];
    const itemIdx = tmpItems.findIndex(item => item.category === action.item.category);

    if (itemIdx !== -1)
      tmpItems[itemIdx] = action.item;
    else
      tmpItems.push(action.item);

    newState = { items: tmpItems };
    return { ...state, ...newState };
  } else if (action.type === UPDATE_HERO_MAKER_COLORED_IMAGES) {
    newState = { cachedColoredImages: { ...state.cachedColoredImages, ...action.cachedImages } };
    return { ...state, ...newState };
  } else if (action.type === SET_HERO_MAKER_CURRENT_IMAGE) {
    newState = { currentHero: action.currentImage };
    return { ...state, ...newState };
  } else if (action.type === SET_HERO_MAKER_ITEMS) {
    newState = { items: action.items };
    return { ...state, ...newState };
  } else if (action.type === SET_HERO_MAKER_ASSETS) {
    newState = { assets: action.assets };
    return { ...state, ...newState };
  } else if (action.type === REMOVE_HERO_MAKER_COLORED_IMAGE) {
    const cachedColoredImages = { ...state.cachedColoredImages };
    for (let i = 1; i <= action.colorsCount; i++)
      delete cachedColoredImages[`${action.cachedImageKey}_${i - 1}`];

    newState = { cachedColoredImages };
    return { ...state, ...newState };
  } else if (action.type === SET_BODY_COLOR_HM) {
    newState = { bodyColor: action.bodyColor };
    return { ...state, ...newState };
  } else if (action.type === SET_LIGHT_DARK_MODE_HM) {
    newState = { isDarkMode: action.mode };
    return { ...state, ...newState };
  } else if (action.type === SET_TYPE_ASSETS_HM) {
    newState = {};
    newState[action.catType === 'body' ? 'bodyAssets' : 'headAssets'] = action.assets;
    return { ...state, ...newState };
  } else if (action.type === RESET_HERO_MAKER) {
    newState = { items: [], assets: [], cachedColoredImages: {}, currentHero: null };
    return { ...state, ...newState };
  } else {
    return state;
  }
};

export default cardToolReducer;