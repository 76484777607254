import React from 'react';
import Helpers from '../../theme/Helpers';

const NicePrice = (props) => {
  const { price, mainFS = 26, supFS = 14, eurFS = 16, eurML = 4 } = props;

  if (!price)
    return null;

  const priceParts = price.toFixed(2)
  .toString()
  .replace('.', ',')
  .split(',');

  return (
    <div style={{ fontSize: mainFS, fontWeight: 600, ...Helpers.fillRowCenter }}>
      {priceParts[0]}<sup
      style={{
        fontSize: supFS,
        fontWeight: 400,
        alignSelf: 'flex-start',
        marginLeft: 2,
      }}>.{priceParts[1]}</sup>
      <span
        style={{ fontSize: eurFS, fontWeight: 500, marginLeft: eurML }}>€</span>
    </div>
  );
};

export default NicePrice;