import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useParams } from 'react-router-dom';

import { getOnlyTypeCoins } from '../../apollo/mergedQueries';

import PageTitle from '../../components/PageTitle/PageTitle';
import Coins from '../../components/Coins/Coins';
import CoinsBank from '../../components/Coins/CoinsBank';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import Colors from '../../theme/Colors';

import conf from '../../conf.json';

const useStyles = makeStyles({
  innerContainer: {
    position: 'relative',
    color: Colors.white,
    width: '100%',
  },
  headWrapper: {
    width: '100%',
    backgroundImage: `url("${conf.cdn_url}/assets/template/tresor.jpg")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
});

const CoinsContainer = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const energyAreaRef = useRef(null);
  const { tab } = useParams();

  const { data, loading, fetchMore } = useQuery(getOnlyTypeCoins, {
    variables: {
      pagination: { perPage: 25, page: 1 },
    },
    fetchPolicy: useCacheWithExpiration('getOnlyTypeCoins'),
    pollInterval: conf.poll_intervals.short_refresh,
  });

  const myViewer = (data) && data.getMyViewer;
  const coinsTankInfo = (data) && data.getSHCoinsTankInformation;

  return (
    <>
      <PageTitle title={t('redeemable_coins')} />

      <div className={classes.innerContainer}>
        <div className={classes.headWrapper}>
          <CoinsBank
            myViewer={myViewer}
            coinsTankInfo={coinsTankInfo}
          />
        </div>

        <div className="inner-wrapper" style={{ padding: 20 }}>
          <Coins
            data={data}
            loading={loading}
            fetchMore={fetchMore}
            myViewer={myViewer}
            energyAreaRef={energyAreaRef}
            tab={tab}
          />
        </div>
      </div>
    </>
  );
};

export default CoinsContainer;