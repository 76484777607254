import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { connect } from 'react-redux';

import { getCardsFromDeck, getPublicCardsFromDeck } from '../../apollo/mergedQueries';

import StorageBoxCards from '../../components/Inventory/StorageBoxCards';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

const StorageBoxCardsContainer = (props) => {
  const { storageBoxes } = props;
  const { isMy, id, viewerId } = storageBoxes;
  const [sorting, setSorting] = useState({
    field: 'created_at',
    order: 'DESC',
  });
  const [quality, setQuality] = useState({
    min: 0,
    max: 100,
  });
  const [rareness, setRareness] = useState([]);

  const variables = {
    min_quality: quality.min,
    max_quality: quality.max,
    rarity: rareness,
    sorting,
    deck: id,
  };

  const { data, loading } = useQuery(getCardsFromDeck, {
    variables,
    fetchPolicy: useCacheWithExpiration('getCardsFromDeck', variables),
    skip: !id || viewerId,
  });

  const { data: publicData, loading: publicLoading } = useQuery(getPublicCardsFromDeck, {
    variables: { ...variables, id: viewerId },
    fetchPolicy: useCacheWithExpiration('getCardsFromDeck', variables),
    skip: !id || !viewerId,
  });

  const myCards = data && data.getCardsFromDeck;
  const cardDecks = data && data.getCardDecks;
  const myViewer = data && data.getMyViewer;
  const publicMyViewer = publicData && publicData.getMyViewer;
  const publicCards = publicData && publicData.getCardsFromDeck;
  const publicCardDecks = publicData && publicData.getPublicDecks;
  let cardDeck;
  if (publicCardDecks)
    cardDeck = publicCardDecks && publicCardDecks.find(deck => deck._id === id);
  else if (cardDecks)
    cardDeck = cardDecks && cardDecks.find(deck => deck._id === id);

  return (
    <StorageBoxCards
      cardDecks={viewerId ? publicCardDecks : cardDecks}
      cardDeck={cardDeck}
      cards={viewerId ? publicCards : myCards}
      loading={loading || publicLoading}
      quality={quality}
      setQuality={setQuality}
      rareness={rareness}
      setRareness={setRareness}
      sorting={sorting}
      setSorting={setSorting}
      variables={variables}
      notMy={!isMy}
      myViewer={viewerId ? publicMyViewer : myViewer}
    />
  );
};

const mapStateToProps = (state) => {
  const { storageBoxes } = state;
  return { storageBoxes };
};

export default connect(mapStateToProps)(StorageBoxCardsContainer);