import React from 'react';
import DuotoneIconBase from './DuotoneIconBase';

const SearchIcon = (props) => {
  const { forceActive = false } = props;

  return (
    <DuotoneIconBase
      forceActive={forceActive}

      DefaultIcon={
        <svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="-2 -2 26 26">
          <g>
            <g>
              <path fill="#FFFFFF" d="M10,20c-2.671,0-5.182-1.04-7.071-2.93C1.04,15.183,0,12.671,0,10c0-2.67,1.04-5.183,2.929-7.071
                                C4.818,1.041,7.329,0,10,0c2.672,0,5.182,1.041,7.07,2.929C18.959,4.817,20,7.33,20,10c0,2.671-1.041,5.183-2.93,7.07
                                C15.182,18.96,12.672,20,10,20z M10,2C7.863,2,5.854,2.832,4.343,4.344C2.832,5.854,2,7.863,2,10s0.832,4.146,2.343,5.656
                                C5.854,17.168,7.863,18,10,18s4.145-0.832,5.656-2.344C17.168,14.146,18,12.137,18,10s-0.832-4.146-2.344-5.656
                                C14.145,2.832,12.137,2,10,2z" />
            </g>
            <g opacity="0.5">
              <path fill="#FFFFFF" d="M21,22c-0.256,0-0.512-0.098-0.707-0.293l-4.637-4.637c-0.391-0.391-0.391-1.022,0-1.414
                                c0.391-0.391,1.023-0.391,1.414,0l4.637,4.637c0.391,0.391,0.391,1.023,0,1.414C21.512,21.902,21.256,22,21,22z" />
            </g>
          </g>
        </svg>
      }
      HoverIcon={
        <svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="-2 -2 26 26">
          <g>
            <g>
              <g>
                <path fill="#E12138" d="M20.999,21.999c-0.256,0-0.512-0.098-0.707-0.293l-4.636-4.636c-0.392-0.39-0.392-1.023,0-1.413
                                    c0.392-0.392,1.024-0.392,1.414,0l4.636,4.635c0.391,0.391,0.391,1.023,0,1.414C21.511,21.901,21.255,21.999,20.999,21.999z" />
              </g>
            </g>
            <g>
              <path fill="#FFFFFF" d="M10,19.999c-2.671,0-5.182-1.039-7.071-2.929C1.041,15.183,0,12.671,0,10s1.04-5.181,2.929-7.071
                                C4.818,1.041,7.329,0.001,10,0.001c2.671,0,5.183,1.04,7.07,2.928c1.89,1.89,2.929,4.4,2.929,7.071s-1.039,5.183-2.929,7.07
                                C15.183,18.96,12.671,19.999,10,19.999z M10,2C7.863,2,5.854,2.833,4.344,4.343C2.832,5.854,2,7.863,2,10s0.832,4.146,2.344,5.657
                                C5.854,17.167,7.863,18,10,18s4.146-0.833,5.656-2.343c1.511-1.512,2.343-3.521,2.343-5.657s-0.832-4.146-2.343-5.657
                                C14.146,2.833,12.137,2,10,2z" />
            </g>
          </g>
        </svg>
      }
      ActiveIcon={
        <svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="-2 -2 26 26">
          <g>
            <g>
              <path fill="#E12138" d="M21.706,20.293l-4.636-4.637c-0.39-0.391-1.022-0.391-1.414,0s-0.392,1.023,0,1.414l4.636,4.637
                                C20.487,21.902,20.743,22,20.999,22s0.512-0.098,0.707-0.293C22.097,21.316,22.097,20.684,21.706,20.293z" />
            </g>
            <path fill="#FFFFFF" d="M17.07,2.929C15.183,1.042,12.671,0,10,0C7.329,0,4.818,1.042,2.929,2.929C1.041,4.819,0,7.33,0,10
                            c0,2.672,1.04,5.182,2.929,7.07C4.818,18.959,7.329,20,10,20c2.671,0,5.183-1.041,7.07-2.93c1.89-1.889,2.929-4.398,2.929-7.07
                            C19.999,7.33,18.96,4.819,17.07,2.929z M15.656,15.656C14.146,17.168,12.137,18,10,18s-4.146-0.832-5.656-2.344
                            C2.832,14.146,2,12.137,2,10s0.832-4.146,2.344-5.656C5.854,2.833,7.863,2.001,10,2.001s4.146,0.832,5.656,2.343
                            c1.511,1.51,2.343,3.519,2.343,5.656S17.167,14.146,15.656,15.656z" />
          </g>
        </svg>
      }
    />
  );
};

export default SearchIcon;