import React from 'react';
import { makeStyles } from '@material-ui/core';

import Colors from '../../theme/Colors';
import { rangeArray } from '../../utils/helper';

const useStyles = makeStyles(() => ({
  scrollMenu: {
    position: 'absolute',
    right: 16,
    top: '50%',
    transform: 'translate(0, -50%)',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: 50,
    border: '2px solid #FFFFFF',
    opacity: 0.5,
    marginBottom: 12,
    transition: 'all 0.3s ease-out',
    cursor: 'pointer',
  },
  activeCircle: {
    width: 12,
    height: 12,
    border: 'none',
    opacity: 1,
    background: Colors.white,
  },
}));

const VerticalPageScrollMenu = (props) => {
  const classes = useStyles();
  const { length, activeIdx, setIdx } = props;

  return (
    <div className={classes.scrollMenu}>
      {rangeArray(1, length)
      .map(idx => (
        <div
          key={`CircleKey${idx}`}
          className={[classes.circle, activeIdx === idx ? classes.activeCircle : ''].join(' ')}
          style={idx === length ? { marginBottom: 0 } : {}}
          onClick={() => setIdx(idx)}
        />
      ))}
    </div>
  );
};

export default VerticalPageScrollMenu;