import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import { bindActionCreators } from 'redux';
import { useSnackbar } from 'notistack';
import moment from 'moment';

import {
  sendBoosterToApproval as sendBoosterToApprovalMutation,
  archiveBooster as archiveBoosterMutation,
  deleteUnapprovedBooster as deleteUnapprovedBoosterMutation,
} from '../../apollo/mutations';
import { getAllBooster } from '../../apollo/queries';

import { setBoosterData } from '../../redux/actions';
import Helpers from '../../theme/Helpers';
import AnimationButton from '../Button/AnimationButton';

import conf from '../../conf.json';

const useStyles = makeStyles({
  hoverItem: {
    cursor: 'pointer',
    margin: '20px 8px',
    '&:hover': {
      '& $item': {
        background: 'rgba(255, 255, 255, 0.08)',
      },
      '& $belowRow': {
        opacity: 1,
        marginTop: 4,
      },
    },
  },
  menuOpen: {
    width: 45,
    height: 46,
    marginLeft: 4,
    ...Helpers.fillColCenter,
    borderRadius: 6,
    background: 'rgba(255, 255, 255, 0.08)',
    transition: 'all 0.3s ease-out',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.24)',
    },
  },
  item: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    padding: '30px 44px',
    ...Helpers.fillColCenter,
  },
  belowRow: {
    marginTop: -40,
    ...Helpers.fillRowCross,
    width: '100%',
    position: 'relative',
    opacity: 0,
    transition: 'all 0.3s ease-out',
  },
  menu: {
    position: 'absolute',
    top: -46,
    left: 0,
    right: 0,
    transition: 'all 0.3s ease-out',
    background: 'rgba(255, 255, 255, 0.1)',
    borderBottomRightRadius: 6,
    borderBottomLeftRadius: 6,
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.16)',
    },
  },
  menuItem: {
    padding: 12,
    fontSize: 14,
  },
});

const BoosterManagementBooster = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { booster } = props;
  const { generic_cards } = booster;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [sendBoosterToApproval, { loading }] = useMutation(sendBoosterToApprovalMutation, {
    onCompleted: (data) => {
      const { success, error_code } = data.sendBoosterToApproval;
      if (success)
        enqueueSnackbar(t('success'), { variant: 'success' });
      else {
        let errorMsg = '';
        if (error_code === 1)
          errorMsg = 'to_less_special_cards';
        else if (error_code === 2)
          errorMsg = 'level_and_special_mixed';
        else if (error_code === 3)
          errorMsg = 'not_all_rarities';
        else if (error_code === 4)
          errorMsg = 'rarities_not_same';

        enqueueSnackbar(t(`error_msg.${errorMsg}`), { variant: 'error' });
      }
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [
      { query: getAllBooster },
    ],
  });

  const [archiveBooster, archiveMutation] = useMutation(archiveBoosterMutation, {
    onCompleted: () => {
      enqueueSnackbar(t('success'), { variant: 'success' });
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [
      { query: getAllBooster },
    ],
  });

  const [deleteUnapprovedBooster, deleteMutation] = useMutation(deleteUnapprovedBoosterMutation, {
    onCompleted: () => {
      enqueueSnackbar(t('success'), { variant: 'success' });
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [
      { query: getAllBooster },
    ],
  });

  const sendToApproval = () => {
    sendBoosterToApproval({ variables: { id: booster._id } });
  };

  const sendToAchievement = () => {
    archiveBooster({ variables: { id: booster._id } });
  };

  const deleteBooster = () => {
    deleteUnapprovedBooster({ variables: { id: booster._id } });
  };

  const redirectToBoosterPage = () => {
    props.setBoosterData(
      booster.edition,
      booster.energy_costs,
      isStandard ? null : booster.redeemable_from && moment.unix(booster.redeemable_from / 1000),
      isStandard ? null : booster.redeemable_until && moment.unix(booster.redeemable_until / 1000),
      booster._id,
      generic_cards,
      booster.isApproved,
      booster,
    );

    props.history.push(`/streamer-settings/booster-management/update/${booster._id}`);
  };

  const archiveLoading = archiveMutation.loading;
  const deleteLoading = deleteMutation.loading;
  const isStandard = booster && !booster.redeemable_from;

  const untilTime = booster && moment.unix(booster.redeemable_until / 1000);
  const now = moment();
  const boosterIsOnline = (untilTime && untilTime.isAfter(now)) || !booster.redeemable_until;

  return (
    <div className={classes.hoverItem}>
      <div className={classes.item} onClick={redirectToBoosterPage}>
        <div style={{ marginBottom: 8 }}>
          {booster.edition}
        </div>
        {booster.isApproved ?
          <>
            {!boosterIsOnline ?
              <div style={{ color: '#87cefa', fontSize: 12 }}>
                <i className="fa-light fa-box-archive" style={{ marginRight: 6 }} />
                {t('booster_archived')}
              </div>
              :
              <div style={{ color: '#1FE325', fontSize: 12 }}>
                <i className="fa-light fa-circle-check" style={{ marginRight: 6 }} />
                {t('booster_approved')}
              </div>
            }
          </>
          :
          booster.ready_to_approve ?
            <div style={{ color: '#FAE376', fontSize: 12 }}>
              <i className="fa-light fa-spinner" style={{ marginRight: 6 }} />
              {t('booster_in_approval')}
            </div>
            :
            <div style={{ color: '#F22740', fontSize: 12 }}>
              <i className="fa-light fa-circle-xmark" style={{ marginRight: 6 }} />
              {t('booster_not_approved')}
            </div>
        }
        <div>
          <img
            src={booster.front_image ? `${conf.cdn_url}${booster.front_image}?height=227` : 'https://via.placeholder.com/157x227'}
            alt="Booster pack"
            height={227}
            style={{ marginTop: 27 }}
          />
        </div>
      </div>
      <div className={classes.belowRow}>
        <div className={classes.menu} style={{ opacity: isMenuOpen ? 1 : 0 }}>
          <div className={classes.menuItem} onClick={isMenuOpen ? () => deleteBooster() : null}>
            {t('delete_booster')}
          </div>
        </div>

        {(!booster.isApproved && !booster.ready_to_approve) &&
        <div style={{ ...Helpers.fillRow, width: '100%' }}>
          <AnimationButton
            status="neonGreen"
            style={{
              fontFamily: 'Montserrat',
              fontSize: 14,
              padding: 10,
              width: 'calc(100% - 49px)',
            }}
            disabled={isStandard ? generic_cards && (generic_cards.length === 0 || generic_cards.length % 5 !== 0) : generic_cards && (generic_cards.length < 3)}
            onClick={loading ? null : sendToApproval}
          >
            {loading ?
              <i className="fa-light fa-loader fa-spin" style={{ fontSize: 18 }} />
              :
              t('send_to_approval')
            }
          </AnimationButton>
          <div
            className={classes.menuOpen}
            onClick={deleteLoading ? null : () => setIsMenuOpen(!isMenuOpen)}
          >
            <i
              className={`fa-light fa-${deleteLoading ? 'loader fa-spin' : 'ellipsis-vertical'}`}
              style={{ fontSize: 18 }}
            />
          </div>
        </div>
        }
        {booster.ready_to_approve &&
        <AnimationButton
          status="neonGreen"
          style={{ fontFamily: 'Montserrat', fontSize: 14, width: '100%' }}
          disabled
        >
          <i className="fa-light fa-loader fa-spin" style={{ fontSize: 18 }} />
        </AnimationButton>
        }
        {booster.isApproved &&
        <AnimationButton
          status="neonRed"
          style={{ width: '100%' }}
          onClick={archiveLoading ? null : sendToAchievement}
          disabled={!boosterIsOnline}
        >
          {archiveLoading ?
            <i className="fa-light fa-loader fa-spin" style={{ fontSize: 18 }} />
            :
            t('archive_booster')
          }
        </AnimationButton>
        }
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setBoosterData,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(withRouter(BoosterManagementBooster));