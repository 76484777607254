import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useParams, withRouter } from 'react-router-dom';

import ProfileHeader from './ProfileHeader';
import ProfileBody from './ProfileBody';

const useStyles = makeStyles({
  container: {
    background: 'rgba(255, 255, 255, 0.04)',
  },
});

const ProfileComponent = (props) => {
  const classes = useStyles();
  const { profile } = props;
  const { tab } = useParams();
  const { viewer, streamer } = profile;

  return (
    <>
      {!['raffle', 'rapidlan-september-2022'].includes(tab) &&
      <div className={classes.container}>
        <ProfileHeader viewer={viewer} streamer={streamer} />
      </div>
      }

      <ProfileBody
        viewer={viewer}
        streamer={streamer}
        isStreamer={!!streamer}
      />
    </>
  );
};

export default withRouter(ProfileComponent);