import React, { useState } from 'react';
import { Avatar, CircularProgress, Link, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { faMinus, faPlus, faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SRLWrapper, useLightbox } from 'simple-react-lightbox';

import CustomButton from '../Button/CustomButton';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import CustomTooltip from '../Tooltip/CustomTooltip';
import { downloadImage } from '../../utils/helper';

const useStyles = makeStyles(() => ({
  container: {
    ...Helpers.fillCol,
  },
  wrapper: {
    margin: '5px 0',
  },
  headWrapper: {
    ...Helpers.fillRowCross,
    ...Helpers.mainSpaceBetween,
    padding: '10px 20px',
    cursor: 'pointer',
  },
  contentWrapper: {
    padding: 10,
    backgroundColor: '#080808',
    border: '3px solid #121212',
  },
  userArea: {
    ...Helpers.fillRowCross,
    width: 350,
  },
  avatar: {
    borderColor: Colors.black,
    width: 60,
    height: 60,
    marginRight: 25,
  },
  description: {
    border: '1px solid #404040',
    borderRadius: 10,
    padding: 20,
    margin: 5,
    paddingLeft: 50,
    backgroundColor: '#121212',
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
  },
  iconButton: {
    padding: 10,
    backgroundColor: '#AA3A41',
    borderRadius: 4,
  },
  colorSquare: {
    width: 30,
    height: 30,
    borderRadius: 4,
  },
}));

const OpenIllustratorJob = (props) => {
  const classes = useStyles();
  const { openLightbox } = useLightbox();
  const { t } = useTranslation();
  const { job, acceptJob, myViewer, canAccept, toggleIllustrationJobVIP } = props;
  const [updateLoading, setUpdateLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const accept = () => {
    acceptJob(job._id);
    setUpdateLoading(true);
  };

  const toggleVIP = () => {
    toggleIllustrationJobVIP({ variables: { id: job._id } });
  };

  const getStyle = (style) => {
    if (style === 0)
      return 'Photo';
    else if (style === 1)
      return 'Realistic';
    else if (style === 2)
      return 'Cartoon';
    else if (style === 3)
      return 'Logo';
  };

  const downloadAll = (upics) => {
    setDownloadLoading(true);
    for (let i in upics)
      downloadImage(upics[i]);

    setTimeout(() => setDownloadLoading(false), 3000);
  };

  const participant = job.participants[0];
  const description = [
    {
      level: 0,
      request_description: job.request_description,
      request_primary_color_info: job.request_primary_color_info,
      request_secondary_color_info: job.request_secondary_color_info,
    },
    ...job.card_request_info,
  ];

  const properties = [
    {
      key: t('reference_pics'),
      value: job.file_uploads_user ? job.file_uploads_user.length : 0,
    },
    {
      key: t('desired_style'),
      value: getStyle(job.illustration_style),
    },
    {
      key: t('language'),
      value: job.language && job.language === 'US' ? 'EN' : job.language,
    },
  ];

  const lightBoxImages = [];
  if (job.file_uploads_user)
    job.file_uploads_user.map(img => {
      if (img)
        lightBoxImages.push({
          src: img,
          thumbnail: `${img}?height=100`,
        });
    });

  return (
    <div className={classes.container}>
      <div className={classes.headWrapper} onClick={() => setShowMore(!showMore)}>
        <div className={classes.userArea}>
          <Link href={`/profile/${participant.login}`}>
            <Avatar
              alt={participant.display_name}
              src={participant.avatar}
              className={classes.avatar}
            />
          </Link>
          <div>
            <div style={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: 20 }}>
              {participant.display_name}
              {job.is_vip &&
              <FontAwesomeIcon icon={faStar} color={Colors.gold} style={{ marginLeft: 10 }} />
              }
            </div>
            <div style={{ fontSize: 12 }}>
              {moment.unix(job.created_at / 1000)
              .format('DD.MM.YY - HH:mm:ss')}
            </div>
          </div>
        </div>
        <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween }}>
          {properties.map((property, idx) => (
            <div key={`PropertyKey${idx}`} style={{ margin: '0 20px' }}>
              <div style={{
                paddingBottom: 5,
                borderBottom: '1px solid #838383',
                ...Helpers.textCenter,
              }}>
                {property.value}
              </div>
              <div style={{ color: '#838383', marginTop: 5, ...Helpers.textCenter, fontSize: 13 }}>
                {property.key}
              </div>
            </div>
          ))}
        </div>
        <div className={classes.iconButton}>
          {showMore ?
            <FontAwesomeIcon icon={faMinus} size="lg" />
            :
            <FontAwesomeIcon icon={faPlus} size="lg" />
          }
        </div>
      </div>
      {showMore &&
      <div className={classes.contentWrapper}>
        {description.map(desc => (
          <div className={classes.description} key={`JobDescriptionKey${job._id}Lvl${desc.level}`}>
            <div
              style={{ textTransform: 'uppercase', fontWeight: 'bold', width: 200, fontSize: 18 }}
            >
              {desc.level === 0 ?
                t('description')
                :
                <>
                  {t('level0_x', { level: desc.level })} <span style={{ color: '#878686' }}>
                    {t('card')}
                  </span>
                </>
              }
            </div>
            <div style={{ color: '#7A7A7A', flex: 1, margin: '0 20px', fontSize: 13 }}>
              {desc.request_description && desc.request_description}
            </div>
            <div style={Helpers.fillRowCenter}>
              {(desc.request_primary_color_info) &&
              <CustomTooltip title={desc.request_primary_color_info}>
                <div
                  className={classes.colorSquare}
                  style={{ backgroundColor: desc.request_primary_color_info }}
                />
              </CustomTooltip>
              }
              {(desc.request_secondary_color_info) &&
              <CustomTooltip title={desc.request_secondary_color_info}>
                <div
                  className={classes.colorSquare}
                  style={{ backgroundColor: desc.request_secondary_color_info }}
                />
              </CustomTooltip>
              }
            </div>
          </div>
        ))}
        <div>
          {canAccept ?
            updateLoading ?
              <CircularProgress color="secondary" />
              :
              <CustomButton
                disabled={!myViewer.streamheroes_role.includes('illustrator')}
                onClick={accept}
                style={{ margin: 5 }}
              >
                {t('accept')}
              </CustomButton>
            : null
          }
          {lightBoxImages.length > 0 &&
          <>
            <CustomButton onClick={() => openLightbox(0)} style={{ margin: 5 }}>
              {t('show_user_images')}
            </CustomButton>
            {downloadLoading ?
              <CircularProgress color="secondary" />
              :
              <CustomButton
                onClick={() => downloadAll(job.file_uploads_user)}
                style={{ margin: 5 }}
              >
                {t('download_all_user_images')}
              </CustomButton>
            }
          </>
          }
          {(myViewer.streamheroes_role.includes('admin') || myViewer.streamheroes_role.includes('marketing')) &&
          <CustomButton onClick={toggleVIP} style={{ margin: 5, padding: 10 }}>
            <FontAwesomeIcon icon={job.is_vip ? faStarHalf : faStar} />
          </CustomButton>
          }
        </div>
      </div>
      }
      <SRLWrapper elements={lightBoxImages} options={{ buttons: { showDownloadButton: false } }} />
    </div>
  );
};

export default OpenIllustratorJob;