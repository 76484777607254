import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProgressBar, Step } from 'react-step-progress-bar';
import { makeStyles } from '@material-ui/core';

import Colors from '../../theme/Colors';

const useStyles = makeStyles(() => ({
  progressBar: {
    marginBottom: 80,
    textAlign: 'center',
    padding: '0 10px',
  },
  indexedStep: {
    border: '4px solid #5d6a77',
    color: '#5d6a77',
    width: 8,
    height: 8,
    fontSize: 16,
    fontWeight: 'bold',
    backgroundColor: Colors.onBoardingColor,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    '& > div': {
      marginBottom: -40,
      position: 'absolute',
      bottom: 0,
      width: 200,
    },
  },
  accomplished: {
    '& > div': {
      color: Colors.onBoardingColor,
    },
  },
  lastAccomplished: {
    border: '4px solid ' + Colors.white,
    width: 4,
    borderRadius: '0',
  },
}));

const OnBoardingStepProgress = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { stepCount } = props;

  const steps = [
    t('on_boarding_card_head'),
    t('on_boarding_social_head'),
    t('on_boarding_alert_head'),
    t('on_boarding_chat_head'),
    t('on_boarding_panel_head'),
  ];

  return (
    <div className={classes.progressBar}>
      <ProgressBar
        percent={(100 / (steps.length - 1)) * (stepCount - 1)}
        filledBackground={Colors.onBoardingColor}
        unfilledBackground="#3b424c"
      >
        {
          steps.map((step, index) =>
            <Step key={`StepKey${step}`}>
              {({ accomplished }) => (
                <div
                  className={`${classes.indexedStep} ${accomplished ? classes.accomplished : ''} ${index === (stepCount - 1) ? classes.lastAccomplished : ''}`}
                >
                  <div>
                    {index + 1}<br />
                    {step}
                  </div>
                </div>
              )}
            </Step>,
          )
        }
      </ProgressBar>
    </div>
  );
};

export default OnBoardingStepProgress;