import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

import Colors from '../../theme/Colors';

const useStyles = makeStyles(() => ({
  item: {
    color: 'white',
    height: 67,
    marginBottom: 5,
    backgroundColor: Colors.wrapperDark,
    paddingLeft: 10,
    paddingRight: 10,
  },
  avatar: {
    backgroundColor: 'white',
    width: 56,
    height: 56,
  },
  text: {
    backgroundColor: 'white',
    height: 15,
    marginLeft: 10,
  },
}));

/**
 * @return {null}
 */
function ListSkeletonItem (props) {
  const classes = useStyles();
  const { subText } = props;

  return (
    <ListItem className={classes.item}>
      <ListItemAvatar>
        <Skeleton variant="circle" className={classes.avatar} />
      </ListItemAvatar>
      <ListItemText
        primary={<Skeleton className={classes.text} />}
        secondary={subText ? <Skeleton width="30%" className={classes.text} /> : null}
      />
    </ListItem>
  );
}

export default ListSkeletonItem;