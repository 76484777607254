import React from 'react';
import { bindActionCreators } from 'redux';
import { useQuery } from '@apollo/react-hooks';
import { connect } from 'react-redux';

import { getMyNotifications } from '../../apollo/queries';

import { addPopupEvent } from '../../redux/actions';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import ErrorMessage from '../../components/ErrorBoundary/ErrorMessage';
import Notifications from '../../components/Notifications/Notifications';

const conf = require('../../conf');

/**
 * @return {null}
 */
const NotificationsContainer = (props) => {
  const { addPopupEvent, profileImage } = props;

  const { loading, error, data } = useQuery(getMyNotifications, {
    variables: {
      sorting: {
        field: 'created_at',
        order: 'DESC',
      },
    },
    pollInterval: 240000,
    fetchPolicy: useCacheWithExpiration('getMyNotifications'),
  });

  if (error) return <ErrorMessage error={error} />;

  const notifications = (!loading && data && data.getMyNotifications) ? data.getMyNotifications : [];

  let haveUnread = false;

  for (let i = 0; i < notifications.length; i++) {
    if (!notifications[i].is_read) {
      haveUnread = true;
      break;
    }
  }

  if (notifications) {
    const notification = notifications.find(notify => (notify.type === 'badge' || notify.title === 'StreamHeroes level up!') && !notify.is_read);

    if (notification) {
      if (notification.type === 'badge') {
        addPopupEvent({
          key: 'new-badge',
          value: {
            title: notification.title,
            notificationId: notification._id,
          },
        });
      } else if (notification.title === 'StreamHeroes level up!') {
        /*const r = /\d+/;
        const lvlText = notification.body.match(r)[0];

        addPopupEvent({
          key: 'new-level',
          value: {
            userImage: profileImage,
            level: parseInt(lvlText),
            notificationId: notification._id,
          },
        });*/
      }
    }
  }

  return (
    <Notifications notifications={notifications} haveUnread={haveUnread} />
  );
};


const mapDispatchToProps = dispatch => (
  bindActionCreators({
    addPopupEvent,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(NotificationsContainer);