import React from 'react';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import CardShopMatchCardRightSide from './CardShopMatchCardRightSide';
import CardShopMatchCardLeftSide from './CardShopMatchCardLeftSide';

const CardShopMatchAlbum = (props) => {
  const { loading, offer } = props;

  return (
    <div style={{ color: Colors.white, ...Helpers.fillRow }} id="card-shop-card-view">
      <CardShopMatchCardLeftSide loading={loading} offer={offer} />

      <CardShopMatchCardRightSide loading={loading} offer={offer} />
    </div>
  );
};

export default CardShopMatchAlbum;