import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';

import conf from '../../conf.json';

const useStyles = makeStyles((theme) => ({
  headerLineItem: {
    background: 'rgba(255, 255, 255, 0.04)',
    position: 'relative',
    marginRight: 3,
    width: 306,
    ...Helpers.fillColReverse,
    ...Helpers.mainCenter,
    '&:last-child': {
      marginRight: 0,
    },

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const CardShopMatchLineItems = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { streamer, items } = props;

  const headerLineItems = items ? items : [
    {
      bg: `${conf.cdn_url}/assets/template/shop/coins_bg.png`,
      headText: 'support_streamer_buying_cards_match',
      desc: 'support_streamer_buying_cards_match_desc',
    },
    {
      bg: `${conf.cdn_url}/assets/template/shop/card_hold_bg.png`,
      headText: 'get_physical_card_home_match',
      desc: 'get_physical_card_home_match_desc',
    },
    {
      bg: `${conf.cdn_url}/assets/template/shop/alert_capsule_bg.png`,
      headText: 'get_alert_capsule_streamer_match',
      desc: 'get_alert_capsule_streamer_match_desc',
    },
  ];

  return (
    headerLineItems.map(item => (
      <div className={classes.headerLineItem} key={`MatchLineItemKey${item.headText}`}>
        <div style={{
          backgroundImage: `url("${item.bg}")`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
        }} />

        <div style={{ ...Helpers.fillRowCross, ...Helpers.mainSpaceBetween, padding: 32 }}>
          {item.image &&
          <div style={{ marginRight: 36 }}>
            <img src={item.image} alt="Icon" />
          </div>
          }

          <div style={{ ...Helpers.fillColMain, flex: 1 }}>
            <div style={{ fontWeight: 500 }}>
              {t(item.headText, { streamer: streamer && streamer.display_name })}
            </div>
            <div
              style={{ fontSize: 14, color: Colors.halfWhite, marginTop: 16, maxWidth: 242 }}>
              {t(item.desc, { streamer: streamer && streamer.display_name })}
            </div>
          </div>
        </div>
      </div>
    ))
  );
};

export default CardShopMatchLineItems;