import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

import { getLevelWord } from '../../utils/helper';
import Colors from '../../theme/Colors';
import Loading from '../Loading/Loading';
import QualityLine from '../QualityLine/QualityLine';
import Helpers from '../../theme/Helpers';
import CardShopMatchCardLeftSideMenu from './CardShopMatchCardLeftSideMenu';
import CardShopBreadcrumbs from '../Breadcrumbs/CardShopBreadcrumbs';
import CardImageDecision from '../Cards/CardImageDecision';

import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  area: {
    width: 'calc(66.6% - 3px)',
    marginRight: 3,
    position: 'relative',
    '& .slick-list': {
      padding: '0 7px 0 0 !important',
    },
    '& .slick-slide': {
      width: '362px !important',
    },
  },
}));

const CardShopMatchCardLeftSide = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { loading, card, offer, isLegacy } = props;

  const threeDCard = card && {
    image: card.front_layers && card.front_layers[0].physical_img_url,
    physical_card_layer_image: card.physical_card_layer_image,
    backImage: card.back_layers && card.back_layers[0].physical_img_url,
    frontEffect: (card.front_layers && card.front_layers.length > 1) && card.front_layers[1].physical_img_url,
    backEffect: (card.back_layers && card.back_layers.length > 1) && card.back_layers[1].physical_img_url,
    noEffectLayer: isLegacy || (card.quality && card.quality < 0),
  };
  const levelColor = card ? Colors.level[getLevelWord('Card', card.rarity)] : Colors.white;
  const streamer = card ? card.card_template_metadata.match : offer && offer.match;

  let condition = 0;
  if (card) {
    if (card.quality > 10) {
      if (card.quality >= 11 && card.quality <= 30)
        condition = 1;
      else if (card.quality >= 31 && card.quality <= 70)
        condition = 2;
      else if (card.quality >= 71 && card.quality <= 90)
        condition = 3;
      else if (card.quality >= 91 && card.quality <= 100)
        condition = 4;
    }
  }

  return (
    <div className={classes.area}>
      {((card || offer) && streamer) &&
      <CardShopBreadcrumbs streamer={streamer} cardText={
        offer ?
          offer.title
          :
          `${t(card.rarity !== null ? `rareness_level.${card.rarity}` : 'legacy')} ${t('card')} - ${t(`condition_level.${condition}`)} / ${card.quality}%`
      } />
      }

      <div style={{
        height: 'calc(690px - 44px)',
        padding: '22px 32px',
        background: 'rgba(255, 255, 255, 0.04)',
        position: 'relative',
        ...(offer ? Helpers.fillColCenter : Helpers.fillColReverse),
      }}>

        {loading ?
          <Loading />
          :
          offer ?
            <div>
              <img
                src={`${offer.products[0].image}?height=506`}
                alt="Album"
                style={{ maxHeight: 506, maxWidth: 515 }}
              />
            </div>
            :
            <CardImageDecision
              card={threeDCard}
              information={card}
              isLegacy={isLegacy}
              style={{
                height: 604,
                width: 498,
              }}
              zoom={3.5}
            />
        }

        {card &&
        <div style={{ ...Helpers.fillRowCross, width: '100%' }}>
          <div style={{ marginRight: 64 }}>
            <div style={{ ...Helpers.fillRowCross }}>
              <div style={{ marginRight: 8 }}>
                <img
                  src={`${conf.cdn_url}/badges/card-level/${card.rarity !== null ? card.rarity : 5}.png?height=39`}
                  alt="Level badge"
                  style={{ maxWidth: 'initial' }}
                />
              </div>
              <div>
                <div style={{
                  color: levelColor,
                  fontSize: 24,
                  fontWeight: 600,
                }}>
                  {t(card.rarity !== null ? `rareness_level.${card.rarity}` : 'legacy')}
                </div>
                <div style={{ color: Colors.halfWhite, fontSize: 14, width: 'max-content' }}>
                  {card.redeemed_booster && card.redeemed_booster.generic_booster_metadata.edition}
                </div>
              </div>
            </div>
          </div>
          <div style={{ width: '100%' }}>
            <QualityLine quality={card.quality} />
          </div>
        </div>
        }

      </div>

      <CardShopMatchCardLeftSideMenu card={card} offer={offer} loading={loading} />
    </div>
  );
};

export default CardShopMatchCardLeftSide;