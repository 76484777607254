import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import HRNumbers from 'human-readable-numbers';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import Helpers from '../../theme/Helpers';
import MiniCountdown from '../Countdown/MiniCountdown';
import Colors from '../../theme/Colors';

import conf from '../../conf';

const useStyles = makeStyles((theme) => ({
  smallText: {
    fontSize: 12,
    color: 'rgba(255, 255, 255, 0.5)',
  },
  countdownArea: {
    marginLeft: -3,
    '& div': {
      fontSize: 16,
      color: 'rgba(255, 255, 255, 0.5)',
      textTransform: 'inherit',
      fontFamily: 'Montserrat',
    },
    '& span': {
      color: Colors.white,
      textShadow: 'none',
    },
  },
  statsBox: {
    background: 'rgba(255, 255, 255, 0.02)',
    borderRadius: 6,
    padding: '8px 12px 10px',
    minWidth: 159,
    marginRight: 8,
  },
  templateRow: {
    ...Helpers.fillRowCross,

    [theme.breakpoints.down('md')]: {
      ...Helpers.fillCol,
    },
  },
  templateSpaceRow: {
    ...Helpers.fillRowCross,
    ...Helpers.mainSpaceBetween,

    [theme.breakpoints.down('md')]: {
      ...Helpers.fillCol,
    },
  }
}));

const SeasonInfo = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    streamer,
    button = null,
    season,
    redeemedBoosters = null,
    gatheredEnergy = null,
    collectors = null,
  } = props;

  return (
    <div style={{ width: '100%', zIndex: 1, position: 'relative' }}>
      <div className={classes.templateSpaceRow}>
        <div>
          <div className={classes.smallText} style={{ marginBottom: 4 }}>
            {t('season_start')} {moment.unix(season.available_from / 1000)
          .format('D.MM.Y ⦁ hh:mm A')}
          </div>
          <div style={{ fontSize: 26, fontWeight: 500 }}>
            {streamer ?
              <>{streamer.display_name} - {t('season_x', { season: season.season })}</>
              :
              <>{t('global_leaderboard')} {t('season_x', { season: season.season })}</>
            }
          </div>
        </div>
        {button}
      </div>

      <div style={{
        margin: '20px 0 14px',
        width: '100%',
        border: '1px solid #FFFFFF',
        opacity: 0.04,
      }} />

      <div className={classes.templateSpaceRow}>
        <div className={classes.templateRow}>
          <div className={classes.statsBox}>
            <div style={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: 12, marginBottom: 6 }}>
              {t('redeemed_boosters')}
            </div>
            <div>
              <i className="fa-light fa-glass-empty" style={{ marginRight: 8, fontSize: 14 }} />
              {HRNumbers.toHumanString(redeemedBoosters ?? season.sumBooster)}
            </div>
          </div>

          <div className={classes.statsBox}>
            <div style={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: 12, marginBottom: 6 }}>
              {t('gathered_energy')}
            </div>
            <div>
              <img
                src={`${conf.cdn_url}/assets/template/heroMaker/energy.png?height=16`}
                alt="Energy"
                style={{ marginRight: 5, verticalAlign: 'middle' }} />
              {HRNumbers.toHumanString(gatheredEnergy ?? season.sumEnergy)}
            </div>
          </div>

          {collectors &&
          <div className={classes.statsBox}>
            <div style={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: 12, marginBottom: 6 }}>
              {t('participants')}
            </div>
            <div>
              <i className="fa-light fa-user" style={{ marginRight: 8, fontSize: 14 }} />
              {HRNumbers.toHumanString(collectors)}
            </div>
          </div>
          }
        </div>

        {/*<div>
          <div className={classes.smallText} style={{ marginBottom: 6 }}>
            {t('season_ends_in')}:
          </div>
          <div className={classes.countdownArea}>
            <MiniCountdown endTime={season.available_until} />
          </div>
        </div>*/}
      </div>
    </div>
  );
};

export default withRouter(SeasonInfo);