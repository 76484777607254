import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';

import { gameLeaderBoard } from '../../apollo/queries';

import LeaderBoardRowList from '../../components/Leaderboard/LeaderBoardRowList';
import { rangeArray } from '../../utils/helper';
import RowListSkeletonItem from '../../components/Skeletons/RowListSkeletonItem';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

const GameLeaderBoardContainer = (props) => {
  const { game, isWeekly, isMonthly, isTabletOrMobile } = props;

  const pagination = {
    page: 1,
    perPage: 5,
  };

  const variables = {
    game: game._id,
    pagination,
  };

  const allTimeLeaderBoardQuery = useQuery(gameLeaderBoard, {
    variables,
    fetchPolicy: useCacheWithExpiration('gameLeaderBoard', variables),
    skip: isWeekly || isMonthly,
  });
  const weeklyLeaderBoardQuery = useQuery(gameLeaderBoard, {
    variables: { ...variables, ...{ timeframe: 'weekly' } },
    fetchPolicy: useCacheWithExpiration('gameLeaderBoard', { ...variables, ...{ timeframe: 'weekly' } }),
    skip: !isWeekly || isMonthly,
  });
  const monthlyLeaderBoardQuery = useQuery(gameLeaderBoard, {
    variables: { ...variables, ...{ timeframe: 'monthly' } },
    fetchPolicy: useCacheWithExpiration('gameLeaderBoard', { ...variables, ...{ timeframe: 'monthly' } }),
    skip: !isMonthly || isWeekly,
  });

  const allTimeEntries = (allTimeLeaderBoardQuery.data) && allTimeLeaderBoardQuery.data.getGameLeaderboard;
  const weekEntries = (weeklyLeaderBoardQuery.data) && weeklyLeaderBoardQuery.data.getGameLeaderboard;
  const monthEntries = (monthlyLeaderBoardQuery.data) && monthlyLeaderBoardQuery.data.getGameLeaderboard;

  return (
    <div>
      <>
        {allTimeLeaderBoardQuery.loading ?
          rangeArray(1, 5)
          .map(item =>
            <RowListSkeletonItem key={`AllTimeSkeletonKey${item}`} />,
          )
          :
          (allTimeEntries && allTimeEntries.count > 0) &&
          <LeaderBoardRowList
            time={allTimeEntries.time}
            entries={allTimeEntries.entries}
            type="Total"
            isTabletOrMobile={isTabletOrMobile}
          />
        }
      </>
      <>
        {monthlyLeaderBoardQuery.loading ?
          rangeArray(1, 5)
          .map(item =>
            <RowListSkeletonItem key={`MonthlySkeletonKey${item}`} />,
          )
          :
          (monthEntries && monthEntries.count > 0) &&
          <LeaderBoardRowList
            time={monthEntries.time}
            entries={monthEntries.entries}
            type="Monthly"
            isTabletOrMobile={isTabletOrMobile}
          />
        }
      </>
      <>
        {weeklyLeaderBoardQuery.loading ?
          rangeArray(1, 5)
          .map(item =>
            <RowListSkeletonItem key={`WeeklySkeletonKey${item}`} />,
          )
          :
          (weekEntries && weekEntries.count > 0) &&
          <LeaderBoardRowList
            time={weekEntries.time}
            entries={weekEntries.entries}
            type="Weekly"
            isTabletOrMobile={isTabletOrMobile}
          />
        }
      </>
    </div>
  );
};

GameLeaderBoardContainer.props = {
  isWeekly: PropTypes.bool,
  isMonthly: PropTypes.bool,
};

GameLeaderBoardContainer.defaultProps = {
  isWeekly: false,
  isMonthly: false,
};

export default GameLeaderBoardContainer;