import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Slider from 'react-slick';
import { faMars, faVenus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { rangeArray } from '../../utils/helper';
import Helpers from '../../theme/Helpers';
import Loading from '../Loading/Loading';
import Colors from '../../theme/Colors';
import BodyColorSelection from './BodyColorSelection';

import conf from '../../conf.json';

const useStyles = makeStyles({
  listRow: {
    margin: '0 33px 22px',
    '& .slick-initialized .slick-slide': {
      ...Helpers.fillRowCenter,
      '& img': {
        transform: 'scale(0.9)',
      },
      '& div': {
        outline: 'none',
      },
    },
    '& .slick-current .placeholder': {
      transform: 'scale(1.0) !important',
      boxShadow: '0px 0px 16px #E12138',
      border: '3px solid #E12138',
    },
    '& .slick-prev:before, .slick-next:before': {
      fontSize: 36,
    },
    '& .slick-prev': {
      left: -36,
    },
    '& .slick-slide .selectable-image': {
      height: 320,
      transition: '0.3s ease-in-out',
    },
    '& .slick-center .selectable-image': {
      height: 360,
    },
    '& .slick-slide > div > div': {
      position: 'relative',
    },
    '& .selectable-item': {
      position: 'absolute',
      top: '47%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  gradientBG: {
    background: 'transparent linear-gradient(180deg, #01122B00 0%, #01122BBF 35%, #01122B 100%) 0% 0% no-repeat padding-box',
    borderRadius: '0px 0px 5px 5px',
    position: 'absolute',
    bottom: 10,
    left: 16,
    right: 16,
    height: 81,
    ...Helpers.fillColCenter,
    ...Helpers.mainEnd,
    fontFamily: 'Bebas Neue',
    color: Colors.white,
    fontSize: 22,
    '& svg': {
      filter: 'drop-shadow( 0px 0px 12px #4DC4FF)',
    },
  },
});

const BaseSelection = (props) => {
  const classes = useStyles();
  const { assets, setIndex, index, styleNames, isBody } = props;

  const settings = {
    className: 'center',
    centerMode: true,
    dots: false,
    infinite: true,
    speed: 100,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: index ? index : 0,
    centerPadding: '40px',
    beforeChange: (current, next) => setIndex(next),
  };

  return (
    <div className={classes.listRow} style={{ margin: '0 33px 22px' }}>

      <Slider {...settings}>
        {(assets ? assets : rangeArray(0, 3))
        .map((item, idx) => (
          <div key={`${idx}-area`}>
            <img
              src={`${conf.cdn_url}/assets/template/heroMaker/bg_placeholder.png?height=362`}
              alt="Bg placeholder"
              className="placeholder"
              style={{ borderRadius: 8 }}
            />
            <div className="selectable-item">
              {typeof item === 'string' ?
                <>
                  <img
                    src={item}
                    alt="Item"
                    className="selectable-image"
                    style={{ maxWidth: 'inherit' }}
                  />
                  {(isBody && index === idx) &&
                  <BodyColorSelection />
                  }
                  {styleNames &&
                  <div className={classes.gradientBG}>
                    <div>
                      {styleNames[idx]} <FontAwesomeIcon icon={idx % 2 ? faVenus : faMars}
                                                         color="#4DC4FF"
                                                         style={{ marginLeft: 2 }} />
                    </div>
                  </div>
                  }
                </>
                :
                <Loading />
              }
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BaseSelection;