import React from 'react';
import { makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';

import Helpers from '../../theme/Helpers';
import CardShopMatchLineItems from '../CardShop/CardShopMatchLineItems';
import { isUserPremium } from '../../utils/helper';
import Symplr from '../AdSense/Symplr';
import InventoryBreadcrumbs from '../Breadcrumbs/InventoryBreadcrumbs';

import conf from '../../conf.json';

const useStyles = makeStyles((theme) => ({
  streamerItem: {
    background: 'rgba(255, 255, 255, 0.04)',
    width: 'calc(100% - 309px)',
    padding: '106px 32px 67px',
    ...Helpers.fillRowCross,
    marginRight: 3,
    position: 'relative',
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 16px)',
      padding: 8,
    },
  },
  avatar: {
    border: 'none',
    height: 121,
    width: 121,
    marginRight: 22,

    [theme.breakpoints.down('md')]: {
      height: 80,
      width: 80,
      marginTop: 30,
    },
  },
  specialTextArea: {
    fontSize: 24,
    '& span': {
      fontWeight: 600,
    },

    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      marginTop: 30,
    },
  },
}));

const StreamerInventoryHeader = (props) => {
  const classes = useStyles();
  const { t, streamer, myViewer, booster, card } = props;

  const { isPremiumActive } = isUserPremium(myViewer);
  const items = [
    {
      bg: null,
      headText: 'complete_your_collection',
      desc: 'complete_your_collection_desc',
    },
  ];

  return (
    <>
      {streamer &&
      <InventoryBreadcrumbs streamer={streamer} booster={booster} card={card} />
      }

      <div style={{ ...Helpers.fillRow, position: 'relative' }}>
        <div className={classes.streamerItem}>
          {streamer ?
            <>
              <div style={{
                backgroundImage: `url("${streamer.profile_image_url}")`,
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                mixBlendMode: 'luminosity',
                position: 'absolute',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100%',
                backgroundPosition: 'center',
                opacity: 0.12,
                filter: 'blur(8px)',
              }} />

              <Avatar
                className={classes.avatar}
                alt="Avatar"
                src={streamer.profile_image_url}
              />
                <div
                  className={classes.specialTextArea}
                  dangerouslySetInnerHTML={{ __html: t('your_streamer_collection', { streamer: streamer.display_name }) }}
                />
            </>
            :
            <div style={{ height: 118, width: '100%' }} />
          }
        </div>
        <CardShopMatchLineItems streamer={streamer} items={items} />

        {(conf.ad_area.inventory_streamer && myViewer && !isPremiumActive) &&
        <div
          style={{
            position: 'absolute',
            right: 300,
            bottom: 7,
          }}
        >
          <Symplr id="streamheroes.gg_mr_1" />
        </div>
        }
      </div>
    </>
  );
};

export default StreamerInventoryHeader;