import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { returnMobileObj } from '../../utils/responsive';
import Colors from '../../theme/Colors';

const useStyles = makeStyles(() => ({
  boxTitle: {
    fontSize: 34,
    fontFamily: 'Bebas Neue',
    textTransform: 'uppercase',
    color: Colors.white,
    lineHeight: 1,
    textAlign: 'left',
  },
  subTitle: {
    color: '#4F74A5',
    fontSize: 16,
    textTransform: 'uppercase',
    lineHeight: 1,
  },
}));

const HeaderLine = (props) => {
  const { isTabletOrMobile, title, subTitle } = props;

  const classes = useStyles();

  return (
    <div className={classes.topHeader}>
      <div style={{ borderLeft: '3px solid #b60e2e', paddingLeft: 10 }}>
        <div
          className={classes.boxTitle}
          dangerouslySetInnerHTML={{ __html: title }}
          style={returnMobileObj(isTabletOrMobile, { fontSize: 25 })}
        />
        <div
          className={classes.subTitle}
          dangerouslySetInnerHTML={{ __html: subTitle }}
          style={returnMobileObj(isTabletOrMobile, { fontSize: 14 })}
        />
      </div>
    </div>
  );
};

export default HeaderLine;