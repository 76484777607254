import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { getMyInventoryCardsByMatch } from '../../apollo/mergedQueries';

import PageTitle from '../../components/PageTitle/PageTitle';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import GroupedInventory from '../../components/Inventory/GroupedInventory';

const GroupedInventoryContainer = () => {
  const { t } = useTranslation();
  const [match, setMatch] = useState(null);
  const [page, setPage] = useState(2);
  const perPage = 25;

  const variables = {
    pagination: {
      page: 1,
      perPage,
    },
    match,
  };

  const { data, loading, fetchMore } = useQuery(getMyInventoryCardsByMatch, {
    variables,
    fetchPolicy: useCacheWithExpiration('getMyInventoryCardsByMatch'),
  });

  const myInventoryCards = data && data.getMyInventoryCardsByMatch;
  const myViewer = data && data.getMyViewer;

  return (
    <>
      <PageTitle title={t('inventory')} />
      <GroupedInventory
        myInventoryCards={myInventoryCards}
        t={t}
        loading={loading}
        totalCount={myInventoryCards && myInventoryCards.all_cards.streamer_count}
        setMatch={setMatch}
        myViewer={myViewer}
        onLoadMore={async () => {
          setPage(page + 1);
          try {
            await fetchMore({
              variables: { ...variables, ...{ pagination: { perPage, page } } },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return {
                  getMyInventoryCardsByMatch: {
                    streamer_cards: [...prev.getMyInventoryCardsByMatch.streamer_cards, ...fetchMoreResult.getMyInventoryCardsByMatch.streamer_cards],
                    __typename: prev.getMyInventoryCardsByMatch.__typename,
                  },
                };
              },
            });
          } catch {
          }
        }}
      />
    </>
  );
};

export default withRouter(GroupedInventoryContainer);