import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CardShopSideCart from './CardShopSideCart';
import { toggleShoppingCart } from '../../redux/actions';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    right: 0,
    top: 0,
    bottom: 0,
    height: '100vh',
    width: 481,
    background: '#091828',
    zIndex: 100000,
    padding: 20,
    animation: '$moveIn 0.3s ease-out',

    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 16px)',
      padding: 8,
      left: 0,
      overflow: 'auto',
    },
  },
  '@keyframes moveIn': {
    '0%': {
      right: -481,
    },
    '100%': {
      right: 0,
    },
  },
  wholePage: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.85)',
    zIndex: 99999,
  },
}));

const CardShopCartSideMenu = (props) => {
  const classes = useStyles();
  const { extra, toggleShoppingCart } = props;

  if (!extra.showShopCart)
    return null;

  return (
    <>
      <div className={classes.wholePage} onClick={() => toggleShoppingCart()} />

      <div className={classes.container}>
        <CardShopSideCart onlySideMenu toggleMenu={toggleShoppingCart} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { extra } = state;
  return { extra };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    toggleShoppingCart,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardShopCartSideMenu));