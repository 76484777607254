import React from 'react';
import DuotoneIconBase from './DuotoneIconBase';

const StreamerIcon = (props) => {
  const { forceActive = false } = props;

  return (
    <DuotoneIconBase
      forceActive={forceActive}

      DefaultIcon={
        <svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="-2 -2 26 26">
          <g>
            <g opacity="0.5">
              <path fill="#FFFFFF" d="M18.072,19.068c-0.255,0-0.512-0.098-0.706-0.293c-0.391-0.39-0.391-1.024,0-1.414
                            c3.509-3.51,3.509-9.219,0-12.728c-3.509-3.508-9.219-3.509-12.728,0c-1.699,1.7-2.636,3.96-2.636,6.364
                            c0,2.403,0.937,4.664,2.636,6.364c0.39,0.39,0.39,1.024,0,1.414c-0.39,0.391-1.023,0.391-1.414,0
                            c-2.078-2.077-3.222-4.841-3.222-7.778c0-2.938,1.144-5.701,3.222-7.777c4.289-4.29,11.268-4.29,15.556,0
                            c4.29,4.288,4.29,11.266,0,15.555C18.585,18.971,18.329,19.068,18.072,19.068z" />
            </g>
            <g>
              <path fill="#FFFFFF" d="M15.002,21.997h-8c-0.552,0-1-0.447-1-1c0-2.757,2.244-5,5-5c2.758,0,5,2.243,5,5
                            C16.002,21.55,15.555,21.997,15.002,21.997z M8.173,19.997h5.657c-0.412-1.164-1.523-1.999-2.828-1.999
                            C9.699,17.998,8.587,18.833,8.173,19.997z" />
            </g>
            <g>
              <path fill="#FFFFFF" d="M11.002,14.997c-2.205,0-4-1.794-4-4c0-2.205,1.795-4,4-4c2.207,0,4,1.795,4,4
                            C15.002,13.203,13.209,14.997,11.002,14.997z M11.002,8.998c-1.102,0-2,0.897-2,2c0,1.103,0.897,1.999,2,1.999
                            c1.104,0,2-0.896,2-1.999C13.002,9.895,12.105,8.998,11.002,8.998z" />
            </g>
            <g opacity="0.5">
              <path fill="#FFFFFF" d="M6.407,16.593c-0.256,0-0.513-0.097-0.708-0.292c-2.924-2.925-2.924-7.683,0-10.606
                            c2.925-2.925,7.682-2.925,10.608,0c2.923,2.923,2.923,7.681,0,10.606c-0.391,0.391-1.024,0.391-1.415,0s-0.391-1.023,0-1.414
                            c2.145-2.145,2.145-5.633,0-7.778c-2.146-2.145-5.634-2.145-7.778,0c-2.145,2.145-2.145,5.634,0,7.778
                            c0.391,0.391,0.391,1.023,0,1.414C6.918,16.496,6.663,16.593,6.407,16.593z" />
            </g>
          </g>
        </svg>

      }
      HoverIcon={
        <svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="-2 -2 26 26">
          <g>
            <g>
              <path fill="#E12138" d="M18.072,19.067c-0.255,0-0.512-0.097-0.706-0.292c-0.391-0.39-0.391-1.024,0-1.415
                                c3.508-3.51,3.508-9.218,0-12.727c-3.51-3.509-9.219-3.509-12.728,0c-1.699,1.699-2.636,3.96-2.636,6.363
                                c0,2.404,0.937,4.664,2.636,6.363c0.39,0.391,0.39,1.025,0,1.415s-1.022,0.39-1.414,0c-2.078-2.079-3.222-4.84-3.222-7.778
                                c0-2.938,1.144-5.699,3.222-7.777c4.289-4.289,11.267-4.289,15.556,0c4.289,4.289,4.289,11.267,0,15.556
                                C18.585,18.971,18.329,19.067,18.072,19.067z" />
            </g>
            <g>
              <path fill="#FFFFFF" d="M15.003,21.997h-8c-0.552,0-1-0.449-1-1c0-2.758,2.244-5,5-5s4.999,2.242,4.999,5
                                C16.001,21.548,15.555,21.997,15.003,21.997z M8.173,19.996h5.657c-0.411-1.164-1.524-1.999-2.828-1.999
                                C9.699,17.997,8.587,18.832,8.173,19.996z" />
            </g>
            <g>
              <path fill="#FFFFFF" d="M11.002,14.997c-2.205,0-4-1.794-4-4s1.795-3.999,4-3.999c2.206,0,4,1.793,4,3.999
                                S13.208,14.997,11.002,14.997z M11.002,8.997c-1.103,0-2,0.897-2,2c0,1.104,0.897,2,2,2c1.102,0,2-0.896,2-2
                                C13.003,9.895,12.104,8.997,11.002,8.997z" />
            </g>
            <g>
              <path fill="#E12138" d="M6.407,16.593c-0.256,0-0.513-0.098-0.708-0.293c-2.923-2.924-2.923-7.682,0-10.605
                                c2.925-2.924,7.682-2.924,10.607,0c2.924,2.923,2.924,7.682,0,10.605c-0.391,0.391-1.023,0.391-1.414,0
                                c-0.391-0.39-0.391-1.023,0-1.414c2.145-2.144,2.145-5.633,0-7.776c-2.145-2.145-5.634-2.145-7.778-0.001
                                c-2.145,2.145-2.145,5.634,0,7.777c0.391,0.391,0.391,1.024,0,1.414C6.918,16.495,6.663,16.593,6.407,16.593z" />
            </g>
          </g>
        </svg>
      }
      ActiveIcon={
        <svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="-2 -2 26 26">
          <g>
            <g>
              <path fill="#E12138" d="M18.072,19.068c-0.255,0-0.512-0.099-0.706-0.293c-0.391-0.391-0.391-1.022,0-1.413
                                c3.508-3.51,3.508-9.22,0-12.728c-3.51-3.51-9.219-3.51-12.728,0c-1.699,1.699-2.636,3.959-2.636,6.363s0.937,4.664,2.636,6.364
                                c0.39,0.391,0.39,1.022,0,1.413c-0.39,0.39-1.022,0.39-1.414,0c-2.078-2.077-3.222-4.84-3.222-7.777
                                c0-2.938,1.144-5.701,3.222-7.778c4.288-4.288,11.267-4.288,15.556,0c4.289,4.289,4.289,11.267,0,15.556
                                C18.585,18.97,18.329,19.068,18.072,19.068z" />
            </g>
            <path fill="#FFFFFF" d="M11.003,15.998c-2.757,0-5,2.243-5,4.999c0,0.553,0.447,1,1,1h8c0.552,0,0.998-0.447,0.998-1
                            C16.001,18.241,13.759,15.998,11.003,15.998z" />
            <path fill="#FFFFFF" d="M11.003,6.997c-2.205,0-4,1.795-4,4.001c0,2.204,1.795,3.999,4,3.999s4-1.795,4-3.999
                            C15.003,8.792,13.208,6.997,11.003,6.997z" />
            <g>
              <path fill="#E12138" d="M6.407,16.594c-0.256,0-0.514-0.097-0.709-0.293c-2.923-2.924-2.923-7.682,0-10.606
                                c2.926-2.924,7.683-2.924,10.607,0.001c2.924,2.924,2.924,7.682,0,10.605c-0.391,0.392-1.023,0.392-1.414,0
                                c-0.391-0.39-0.391-1.023,0-1.414c2.145-2.146,2.145-5.634,0-7.778s-5.634-2.145-7.777,0c-2.145,2.145-2.145,5.633,0,7.778
                                c0.391,0.391,0.391,1.024,0,1.414C6.919,16.497,6.663,16.594,6.407,16.594z" />
            </g>
          </g>
        </svg>
      }
    />
  );
};

export default StreamerIcon;