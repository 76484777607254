import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getRedeemedCardObjects } from '../../apollo/mergedQueries';

import PageTitle from '../../components/PageTitle/PageTitle';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import Inventory from '../../components/Inventory/Inventory';
import CardViewDialogContainer from '../Cards/CardViewDialog';
import { useQueryParams } from '../../utils/helper';

import conf from '../../conf.json';

const InventoryContainer = (props) => {
  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const { id } = useParams();
  const cardId = queryParams && queryParams.get('cardId');
  const { isLegacy, setIsLegacy } = props;
  const [showPopup, setShowPopup] = useState(!!cardId);
  const isSHChecked = conf.sh_id === id;
  const defaultChecked = {
    streamer: !isSHChecked,
    quality: true,
    rareness: true,
    game: false,
    sh: !!isSHChecked,
  };
  const [checked, setChecked] = useState(defaultChecked);
  const [sorting, setSorting] = useState({
    field: 'created_at',
    order: 'DESC',
  });
  const [quality, setQuality] = useState({
    min: 0,
    max: 100,
  });
  const [rareness, setRareness] = useState([]);
  const [match, setMatch] = useState(id ? id : null);
  const [page, setPage] = useState(2);
  const perPage = 25;

  const variables = {
    pagination: {
      page: 1,
      perPage,
    },
    min_quality: checked.quality ? quality.min : null,
    max_quality: checked.quality ? quality.max : null,
    rarity: checked.rareness ? rareness : null,
    sorting,
    match,
  };

  const { data, loading, fetchMore } = useQuery(getRedeemedCardObjects, {
    variables,
    fetchPolicy: useCacheWithExpiration('getRedeemedCardObjects'),
  });

  const allData = data && data.getRedeemedCardObjects;
  const hasUserLegacyCards = data && data.hasUserLegacyCards;
  const itemFilters = data && data.getShopItemFilters;
  const cardDecks = data && data.getCardDecks;
  const cards = allData && allData.cards;

  return (
    <>
      <PageTitle title={t('inventory')} />
      <Inventory
        cards={cards}
        t={t}
        loading={loading}
        hasUserLegacyCards={hasUserLegacyCards}
        defaultChecked={defaultChecked}
        checked={checked}
        setChecked={setChecked}
        quality={quality}
        setQuality={setQuality}
        rareness={rareness}
        setRareness={setRareness}
        totalCount={allData && allData.count}
        isLegacy={isLegacy}
        setIsLegacy={setIsLegacy}
        sorting={sorting}
        setSorting={setSorting}
        itemFilters={itemFilters}
        cardDecks={cardDecks}
        match={match}
        setMatch={setMatch}
        onLoadMore={async () => {
          setPage(page + 1);
          try {
            await fetchMore({
              variables: { ...variables, ...{ pagination: { perPage, page } } },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return {
                  getRedeemedCardObjects: {
                    cards: [...prev.getRedeemedCardObjects.cards, ...fetchMoreResult.getRedeemedCardObjects.cards],
                    count: prev.getRedeemedCardObjects.count,
                    __typename: prev.getRedeemedCardObjects.__typename,
                  },
                };
              },
            });
          } catch {
          }
        }}
      />
      {showPopup &&
      <CardViewDialogContainer card={{ _id: cardId }} handleClose={() => setShowPopup(false)} />
      }
    </>
  );
};

export default InventoryContainer;