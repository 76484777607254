import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import { getLevelWord } from '../../utils/helper';
import CustomBackdropFilter from './CustomBackdropFilter';

import conf from '../../conf.json';

const useStyles = makeStyles({
  cardsArea: {
    display: 'none',
    position: 'absolute',
    left: 'calc(100% + 26px)',
    top: 0,
    borderRadius: 6,
    width: 261,
    background: 'rgba(20, 39, 64, 0.7)',
    boxShadow: '0px 0px 49px #0000003B',
    padding: 10,
    zIndex: 100,
    overflow: 'visible',

    '& span': {
      color: 'white !important',
    },

    '&:before': {
      content: '" "',
      borderStyle: 'solid',
      borderWidth: '8px 8px 8px 0',
      borderColor: 'transparent rgba(20, 39, 64, 0.7) transparent transparent',
      position: 'absolute',
      top: 10,
      left: -8,
    },
  },
  switchLastHover: {
    right: 'calc(100% + 26px)',
    left: 'initial',

    '&:before': {
      right: -8,
      left: 'initial',
    },
  },
});

const HoverCardInfoArea = (props) => {
  const classes = useStyles();
  const { t, card, switchLastHover } = props;

  const levelWord = getLevelWord(null, card.rarity);
  const levelColor = Colors.level[levelWord];

  return (
    <CustomBackdropFilter
      className={[classes.cardsArea, switchLastHover ? classes.switchLastHover : ''].join(' ')}
      filter={'blur(25px)'}
      canvasFallback={true}
      html2canvasOpts={{
        allowTaint: true,
      }}
    >
      <div style={{ marginBottom: 14, ...Helpers.fillRowCross }}>
        <div style={{ marginRight: 10 }}>
          <img
            src={`${conf.cdn_url}/badges/card-level/${card.rarity}.png?height=49`}
            alt="Level badge"
          />
        </div>
        <div style={{
          color: levelColor,
          fontFamily: 'Bebas Neue',
          fontSize: 28,
          lineHeight: 1,
        }}>
          {t(levelWord)}
        </div>
      </div>
      <div style={{
        padding: 10,
        background: 'rgba(255, 255, 255, 0.04)',
        borderRadius: 6,
        marginBottom: 8,
        fontSize: 14,
      }}>
        <div style={Helpers.textCenter}>
          {t('coming_soon')}
        </div>
      </div>
      <div style={{ ...Helpers.textCenter, fontSize: 12, fontWeight: 200 }}>
        {t('click_to_see_card')}
      </div>
    </CustomBackdropFilter>
  );
};

export default HoverCardInfoArea;