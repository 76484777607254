import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 1440,
    margin: '0 auto',
    color: Colors.white,
  },
  tabsHead: {
    backgroundColor: '#020202',
    ...Helpers.fillRow,
    flex: 1,
  },
  tab: {
    padding: '20px 30px',
    textTransform: 'uppercase',
    cursor: 'pointer',
    color: '#555555',
    borderBottom: '2px solid transparent',
    fontWeight: 'bold',
    '&:hover': {
      color: '#CBC16C',
      textShadow: '0px 0px 6px #CBC16C',
    },
  },
  selectedTab: {
    color: '#CBC16C',
    textShadow: '0px 0px 6px #CBC16C',
    borderColor: '#A5262F',
  },
  tabsBody: {
    minHeight: 300,
    backgroundColor: '#363940',
  },
}));

const TabsArea = (props) => {
  const classes = useStyles();
  const { tabs, startTabIndex } = props;
  const [selectedTab, setSelectedTab] = useState(startTabIndex ? startTabIndex : 0);

  return (
    <>
      <div className={classes.tabsHead}>
        {tabs.map((tab, index) => (
          <div
            className={[classes.tab, index === selectedTab ? classes.selectedTab : ''].join(' ')}
            onClick={() => setSelectedTab(index)}
            key={`TabAreaKey${index}`}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className={classes.tabsBody}>
        {tabs[selectedTab].component}
      </div>
    </>
  );
};

export default TabsArea;