import React from 'react';
import { Redirect } from 'react-router-dom';

const conf = require('../../conf');

const GlobalRoute = () => {
  const token = window.localStorage.getItem(conf.storage_keys.user_token);
  return token ? <Redirect to='/hq/hub' /> : <span>{window.location.href = 'https://www.streamheroes.gg'}</span>;
};

export default GlobalRoute;