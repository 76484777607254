import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useMediaQuery } from 'react-responsive';

import { getOrganisationAndMembersWithLiveStream } from '../../apollo/mergedQueries';

import Loading from '../../components/Loading/Loading';
import TopViewHeader from '../../components/TopViewHeader/TopViewHeader';
import PageTitle from '../../components/PageTitle/PageTitle';
import Organisation from '../../components/Organisation/Organisation';
import ErrorMessage from '../../components/ErrorBoundary/ErrorMessage';
import { isTabletOrMobileObj } from '../../utils/responsive';
import EventOrganisation from '../../components/Organisation/EventOrganisation';
import MobileEventOrganisation from '../../components/Organisation/MobileEventOrganisation';

import conf from '../../conf.json';

const OrganisationContainer = (props) => {
  const organisationCode = props.match.params.name.toLocaleLowerCase();
  const eventConf = conf.event_organisations && conf.event_organisations[organisationCode];
  const isEvent = !!eventConf;

  const isTabletOrMobile = useMediaQuery(isTabletOrMobileObj);

  const { data, error, loading } = useQuery(getOrganisationAndMembersWithLiveStream, {
    variables: { code: organisationCode },
    fetchPolicy: 'cache-and-network',
  });

  if (error)
    return <ErrorMessage error={error} />;

  const organisation = (data) && data.organisation;
  const memberStreams = (data) && data.getOrganisationMembersWithLiveStream;

  if (memberStreams)
    memberStreams.sort((a, b) => b.viewer_count - a.viewer_count);

  if (organisation)
    organisation.hasOwnDesign = organisation.code === 'g2esports';

  return (
    <TopViewHeader>
      {loading ?
        <Loading />
        :
        <>
          <PageTitle title={organisation.name} />
          {organisation &&
          (isEvent ?
            isTabletOrMobile ?
              <MobileEventOrganisation
                organisation={organisation}
                memberStreams={memberStreams}
                isTabletOrMobile={isTabletOrMobile}
                eventConf={eventConf}
              />
              :
              <EventOrganisation
                organisation={organisation}
                memberStreams={memberStreams}
                isTabletOrMobile={isTabletOrMobile}
                eventConf={eventConf}
              />
            :
            <Organisation
              organisation={organisation}
              memberStreams={memberStreams}
              isTabletOrMobile={isTabletOrMobile}
            />)
          }
        </>
      }
    </TopViewHeader>
  );
};

export default OrganisationContainer;