import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PagePopup from '../../components/Dialog/PagePopup';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';
import ClosePopupButton from '../Button/ClosePopupButton';
import AnimationButton from '../Button/AnimationButton';
import BoosterCreatePopup from './BoosterCreatePopup';
import { resetBoosterData } from '../../redux/actions';

import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  innerContainer: {
    ...Helpers.fillColCenter,
    width: '100vw',
    height: '100vh',
    color: Colors.white,
  },
  selectionArea: {
    padding: '20px 0 38px',
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 6,
    margin: '0 25px',
    cursor: 'pointer',
    transition: 'all 0.3s ease-out',
    ...Helpers.fillColCenter,
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.2)',
    },
  },
  smallText: {
    background: 'rgba(255, 255, 255, 0.04)',
    padding: '4px 10px',
    fontSize: 12,
    color: Colors.halfWhite,
    borderRadius: 4,
    marginBottom: 30,
  },
}));

const BoosterDecisionPopup = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { handleClose } = props;
  const [createBooster, setCreateBooster] = useState(null);

  const openBooster = (type) => {
    props.resetBoosterData();
    setCreateBooster(type);
  }

  return (
    <>
      <PagePopup>
        <div className={classes.innerContainer}>
          <ClosePopupButton handleClose={handleClose} style={{ right: 50, top: 50 }} />

          <div style={{ fontWeight: 500, fontSize: 26 }}>
            {t('select_the_booster_type')}
          </div>
          <div style={{ color: Colors.halfWhite, marginTop: 10 }}>
            {t('select_the_booster_you_want')}
          </div>

          <div style={{ marginTop: 54, ...Helpers.fillRowCenter }}>
            <CustomBackdropFilter
              className={classes.selectionArea}
              filter={'blur(50px)'}
              canvasFallback={true}
              html2canvasOpts={{
                allowTaint: true,
              }}
            >
              <div className={classes.smallText}>
                {t('upload_5_to_x_cards')}
              </div>

              <img
                src={`${conf.cdn_url}/assets/template/booster_management/booster_creation_standard.png`}
                alt="Create standard booster"
              />

              <div style={{ margin: '15px 0 14px' }}>
                {t('create_standard_booster')}
              </div>

              <div style={{
                color: Colors.halfWhite,
                maxWidth: 302,
                fontSize: 14,
                ...Helpers.textCenter,
                marginBottom: 28,
              }}>
                {t('create_standard_booster_desc')}
              </div>

              <AnimationButton
                status="whiteTransparency"
                style={{ padding: '11px 48px' }}
                onClick={() => openBooster('standard')}
              >
                {t('select_standard_booster')}
              </AnimationButton>

            </CustomBackdropFilter>

            <CustomBackdropFilter
              className={classes.selectionArea}
              filter={'blur(50px)'}
              canvasFallback={true}
              html2canvasOpts={{
                allowTaint: true,
              }}
            >
              <div className={classes.smallText}>
                {t('upload_3_to_x_cards')}
              </div>

              <div style={{ width: 350, height: 179, ...Helpers.fillColCenter }}>
                <img
                  src={`${conf.cdn_url}/assets/template/booster_management/booster_creation_event.png`}
                  alt="Create standard booster"
                />
              </div>

              <div style={{ margin: '15px 0 14px' }}>
                {t('create_event_booster')}
              </div>

              <div style={{
                color: Colors.halfWhite,
                maxWidth: 302,
                fontSize: 14,
                ...Helpers.textCenter,
                marginBottom: 28,
              }}>
                {t('create_event_booster_desc')}
              </div>

              <AnimationButton
                status="whiteTransparency"
                style={{ padding: '11px 48px' }}
                onClick={() => openBooster('event')}
              >
                {t('select_event_booster')}
              </AnimationButton>

            </CustomBackdropFilter>
          </div>
        </div>
      </PagePopup>
      {createBooster &&
      <BoosterCreatePopup
        isStandard={createBooster === 'standard'}
        handleClose={() => setCreateBooster(null)}
      />
      }
    </>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    resetBoosterData,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(BoosterDecisionPopup);