import React from 'react';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import Loading from '../../components/Loading/Loading';
import { getURLByShortKey } from '../../apollo/queries';

const GoToContainer = (props) => {
  const urlKey = props.match.params.key;

  return (
    <Query query={getURLByShortKey} variables={{ key: urlKey }}>
      {({ data }) => {
        const shortLink = (data) && data.urlByKey;

        if (shortLink)
          props.history.push(shortLink.url);

        return (
          <Loading />
        );
      }}
    </Query>
  );
};

export default withRouter(GoToContainer);