import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Avatar, Link, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faFileImage, faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { AvatarGroup } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

// import { MuiAudioInput } from './MuiAudioInput';
import MuiMessage from './MuiMessage';
import { MuiTextInput } from './MuiTextInput';
import { MuiFileInput } from './MuiFileInput';
import Helpers from '../../../theme/Helpers';
import Colors from '../../../theme/Colors';
import CustomTooltip from '../../Tooltip/CustomTooltip';
// import MuiImageMessage from './MuiImageMessage';
import AllChatImages from '../AllChatImages';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      maxWidth: '100%',
    },
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  messages: {
    flex: '1 1 0%',
    maxHeight: 500,
    minHeight: 500,
    padding: '0 5px',
    overflowY: 'auto',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      maxWidth: '100%',
    },
  },
  action: {
    flex: '0 1 auto',
    display: 'flex',
    alignContent: 'flex-end',
    '& > *': {
      minWidth: 0,
    },
  },
  messageType: {
    ...Helpers.fillRow,
    ...Helpers.crossEnd,
    marginRight: 10,
    position: 'relative',
  },
  menu: {
    position: 'absolute',
    bottom: '100%',
    ...Helpers.fillColCenter,
    background: Colors.black,
    borderRadius: 50,
    zIndex: 100,
  },
  innerIcon: {
    backgroundColor: Colors.white,
    borderRadius: 50,
    padding: 10,
    margin: 5,
    cursor: 'pointer',
    width: 21.33,
    ...Helpers.fillRowCenter,
    '&:hover': {
      opacity: 0.9,
    },
  },
}));

export function MuiChat (props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { chatController, setType, participants, chatId, writeAccess } = props;
  const chatCtl = chatController;
  const [messages, setMessages] = useState(chatCtl.getMessages());
  const [actReq, setActReq] = useState(chatCtl.getActionRequest());
  const [showMenu, setShowMenu] = useState(false);

  const msgRef = useRef(null);
  const scroll = useCallback(() => {
    if (msgRef)
      msgRef.current.addEventListener('DOMNodeInserted', event => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
      });
  }, [msgRef]);
  useEffect(() => {
    const handleMassagesChanged = () => {
      setMessages([...chatCtl.getMessages()]);
      scroll();
    };

    const handleActionChanged = () => {
      setActReq(chatCtl.getActionRequest());
      scroll();
    };

    chatCtl.addOnMessagesChanged(handleMassagesChanged);
    chatCtl.addOnActionChanged(handleActionChanged);
  }, [chatCtl, scroll]);

  const unknownMsg = {
    type: 'text',
    content: 'Unknown message.',
    self: false,
  };

  const handleMenuClick = (type) => {
    setShowMenu(!showMenu);
    setType(type);
  };

  const icons = {
    'text': faComment,
    'audio': faMicrophone,
    'file': faFileImage,
  };

  return (
    <div className={classes.container}>
      <div style={{
        ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween,
        borderBottom: '1px solid #404040',
        padding: 10,
      }}>
        <AvatarGroup max={3}>
          {participants.map((participant, index) => (
            <CustomTooltip title={participant.display_name} key={`AvatarKey${participant._id}`}>
              <Avatar
                component={Link}
                href={`/${participant.role === 'customer' ? 'streamer' : 'viewer'}/${participant.login}`}
                alt={participant.display_name}
                src={participant.avatar}
                className="MuiAvatarGroup-avatar"
                style={{
                  zIndex: participants.length - index,
                  border: '1px solid black',
                  width: 60,
                  height: 60,
                }}
              />
            </CustomTooltip>
          ))}
        </AvatarGroup>
        {chatId &&
        <AllChatImages chatId={chatId} />
        }
      </div>
      <div className={classes.messages} ref={msgRef}>
        {messages.map(
          (msg) => {
            if (msg.type === 'text' || msg.type === 'jsx' || msg.type === 'image') {
              return (
                <MuiMessage
                  key={messages.indexOf(msg)}
                  id={`cu-msg-${messages.indexOf(msg) + 1}`}
                  message={msg}
                  participants={participants}
                />
              );
            }
            return (
              <MuiMessage
                key={messages.indexOf(msg)}
                id={`cu-msg-${messages.indexOf(msg) + 1}`}
                message={unknownMsg}
                participants={participants}
              />
            );
          },
        )}
      </div>
      {writeAccess ?
        <div className={classes.action}>
          {(actReq && actReq.type && icons[actReq.type]) &&
          <div className={classes.messageType}>
            <div className={classes.innerIcon} onClick={() => setShowMenu(!showMenu)}>
              <FontAwesomeIcon icon={icons[actReq.type]} size="lg" color={Colors.black} />
            </div>
            {showMenu &&
            <div className={classes.menu}>
              {/*<div className={classes.innerIcon} onClick={() => handleMenuClick('audio')}>
              <FontAwesomeIcon icon={icons.audio} size="lg" color={Colors.black} />
            </div>*/}
              <div className={classes.innerIcon} onClick={() => handleMenuClick('file')}>
                <FontAwesomeIcon icon={icons.file} size="lg" color={Colors.black} />
              </div>
              <div className={classes.innerIcon} onClick={() => handleMenuClick('text')}>
                <FontAwesomeIcon icon={icons.text} size="lg" color={Colors.black} />
              </div>
            </div>
            }
          </div>
          }
          {actReq && actReq.type === 'text' && (
            <MuiTextInput
              chatController={chatCtl}
              actionRequest={actReq}
            />
          )}
          {/*{actReq && actReq.type === 'select' && (
          <MuiSelectInput
            chatController={chatCtl}
            actionRequest={actReq}
          />
        )}
        {actReq && actReq.type === 'multi-select' && (
          <MuiMultiSelectInput
            chatController={chatCtl}
            actionRequest={actReq}
          />
        )}*/}
          {actReq && actReq.type === 'file' && (
            <MuiFileInput
              chatController={chatCtl}
              actionRequest={actReq}
            />
          )}
          {/*{actReq && actReq.type === 'audio' && (
          <MuiAudioInput
            chatController={chatCtl}
            actionRequest={actReq}
          />
        )}*/}
        </div>
        :
        <div style={{ color: Colors.gray, marginTop: 20 }}>
          {t('design_job_expired')}
        </div>
      }
    </div>
  );
}