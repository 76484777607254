import React from 'react';
import { makeStyles } from '@material-ui/core';

import Helpers from '../../theme/Helpers';
import Symplr from '../AdSense/Symplr';

import conf from '../../conf.json';

const useStyles = makeStyles((theme) => ({
  header: {
    background: `url("${conf.cdn_url}/assets/template/shop/sh_header_cardshop_v2.png")`,
    height: 'calc(300px - 64px)',
    width: 'calc(100% - 64px)',
    backgroundSize: 'cover',
    ...Helpers.fillColMain,
    padding: 32,
    position: 'relative',

    [theme.breakpoints.down('md')]: {
      padding: 8,
      height: 'fit-content',
      width: 'calc(100% - 16px)',
      ...Helpers.fillColCenter,
    },
  },
  title: {
    fontSize: 36,
    fontWeight: 600,

    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
  },
  desc: {
    maxWidth: 861,
    lineHeight: 1.6,

    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      lineHeight: 1,
    },
  }
}));

const InnerHeader = (props) => {
  const classes = useStyles();
  const { showAd, children, title, desc } = props;

  return (
    <div className={classes.header}>
      <div className={classes.title} style={{ marginBottom: desc ? 8 : 0 }}>
        {title}
      </div>
      {desc &&
      <div className={classes.desc}>{desc}</div>
      }

      {children}

      {showAd &&
      <div
        style={{
          position: 'absolute',
          right: 8,
          bottom: 8,
        }}
      >
        <Symplr id="streamheroes.gg_mr_1" />
      </div>
      }
    </div>
  );
};

export default InnerHeader;