import React from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { getRaffleWithMyTickets } from '../../apollo/queries';
import { completeGleamAction as completeGleamActionMutation } from '../../apollo/mutations';

import TopViewHeader from '../../components/TopViewHeader/TopViewHeader';
import PageTitle from '../../components/PageTitle/PageTitle';
import Raffle from '../../components/Raffle/Raffle';
import Loading from '../../components/Loading/Loading';

const RaffleContainer = (props) => {
  // const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { raffleName } = props;

  const { data, loading } = useQuery(getRaffleWithMyTickets, {
    variables: { raffle_name: raffleName },
  });

  /*const [completeGleamAction] = useMutation(completeGleamActionMutation, {
    onCompleted: (data) => {
      makeAlert(t('gleam_action_success'), 'success');
    },
    onError: (data) => {
      makeAlert(data.message, 'error');
    },
    refetchQueries: () => [{
      query: getRaffleWithMyTickets,
      variables: { raffle_name: raffleName },
    }],
  });

  const completeEvent = (id, key, name) => {
    completeGleamAction({ variables: { event_id: id, campaign_key: key, campaign_name: name } });
  };

  const makeAlert = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };*/

  const raffleWithMyTickets = (data) && data.getRaffleWithMyTickets;

  return (
    <TopViewHeader>
      <PageTitle title={t('raffle')} />
      {loading ?
        <Loading />
        :
        <Raffle raffleWithMyTickets={raffleWithMyTickets} /*completeEvent={completeEvent}*/>
          {/*<a className="e-widget no-button generic-loader"
                 href="https://gleam.io/VjavH/streamheroes1"
                 rel="nofollow">streamheroes_1</a>*/}
        </Raffle>
      }
    </TopViewHeader>
  );
};

export default RaffleContainer;