import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import { capitalize } from '../../utils/helper';
import HoverIcon from '../Icon/HoverIcon';

const useStyles = makeStyles(() => ({
  iconArea: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 2,
    width: 21,
    height: 21,
    ...Helpers.fillColCenter,
    marginRight: 6,
  },
  requiredArea: {
    background: 'rgba(255, 255, 255, 0.06)',
    borderRadius: 2,
    padding: '3.5px 6px',
  },
  groupArea: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 4,
    width: '100%',
    fontSize: 12,
    color: Colors.white,
  },
}));

const CreateAlertGroup = (props) => {
  const classes = useStyles();
  const { t, isRequired, title, description, children, showGroup = true } = props;
  const [isShown, setIsShown] = useState(showGroup);

  return (
    <div style={{ ...Helpers.fillRow, marginTop: 20 }}>
      <div className={classes.iconArea}>
        <HoverIcon
          icon={`fa-solid fa-caret-${isShown ? 'down' : 'right'}`}
          color={Colors.white}
          hoverColor={Colors.halfWhite}
          style={{ fontSize: 14, color: Colors.white }}
          onClick={() => setIsShown(!isShown)}
        />
      </div>
      <div className={classes.groupArea}>
        <div style={{ padding: '16px 12px' }}>
          <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween }}>
            <div>
              {title}
            </div>
            {isRequired &&
            <div className={classes.requiredArea}>
              {capitalize(t('required'))}
            </div>
            }
          </div>
          {description &&
          <div style={{ color: Colors.halfWhite, marginTop: 6 }}>
            {description}
          </div>
          }
          {(children && isShown) && children}
        </div>
      </div>
    </div>
  );
};

export default CreateAlertGroup;