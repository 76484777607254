import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  createBoosterTemplate as createBoosterTemplateMutation,
  updateBoosterTemplate as updateBoosterTemplateMutation,
} from '../../apollo/mutations';
import { getAllBooster } from '../../apollo/queries';

import TopBG from '../TopViewHeader/TopBG';
import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import BoosterHead from './BoosterHead';
import CardsOverview from './CardsOverview';
import { setBoosterId } from '../../redux/actions';

const useStyles = makeStyles({
  hoverIcon: {
    width: 40,
    height: 40,
    borderRadius: 6,
    ...Helpers.fillColCenter,
    transition: 'all 0.3s ease-out',
    background: '#FFFFFF14',
    cursor: 'pointer',
    '&:hover': {
      background: '#FFFFFF3D',
    },
  },
});

const BoosterManagementCreateBooster = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { cardTool } = props;
  const [boosterId, setBoosterId] = useState(null);

  const [createBoosterTemplate] = useMutation(createBoosterTemplateMutation, {
    onCompleted: (data) => {
      setBoosterId(data.createBoosterTemplate._id);
      props.setBoosterId(data.createBoosterTemplate._id);
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [
      { query: getAllBooster },
    ],
  });

  const [updateBoosterTemplate] = useMutation(updateBoosterTemplateMutation, {
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [
      { query: getAllBooster },
    ],
  });

  useEffect(() => {
    const variables = {
      booster_meta_data: {
        edition: cardTool.boosterName,
        energy_costs: cardTool.energyCosts,
        redeemable_from: cardTool.startDate && cardTool.startDate.format(),
        redeemable_until: cardTool.endDate && cardTool.endDate.format(),
      },
    };
    if (!boosterId)
      createBoosterTemplate({ variables });
    else
      updateBoosterTemplate({ variables: { ...variables, id: boosterId } });
  }, [cardTool, boosterId, createBoosterTemplate, updateBoosterTemplate]);

  const isStandard = !cardTool.startDate;

  return (
    <TopBG
      disableBreadcrumbs
      style={{
        padding: '30px 0 20px',
        backgroundSize: 'auto',
        color: Colors.white,
      }}
    >
      <div style={{ marginBottom: 30, ...Helpers.fillRowCross }}>
        <div className={classes.hoverIcon} style={{ marginRight: 10 }}
             onClick={() => props.history.push('/streamer-settings/booster-management')}>
          <i className="fa-light fa-arrow-left" />
        </div>
        <div style={{ fontFamily: 'Bebas Neue', fontSize: 36 }}>
          {t('booster_creation')}
        </div>
      </div>

      <BoosterHead isStandard={isStandard} boosterData={cardTool} />

      <CardsOverview isStandard={isStandard} isCreation />
    </TopBG>
  );
};

const mapStateToProps = (state) => {
  const { cardTool } = state;
  return { cardTool };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setBoosterId,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BoosterManagementCreateBooster));