import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import { setAlertToolTypeData } from '../../redux/actions';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';
import AnimationButton from '../Button/AnimationButton';
import HoverIcon from '../Icon/HoverIcon';
import ConfigureAlertDiscordSettings from './ConfigureAlertDiscordSettings';
import ConfigureAlertTwitchSettings from './ConfigureAlertTwitchSettings';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 377,
    right: 0,
    bottom: 0,
    background: '#011024',
    color: Colors.white,
    zIndex: 129999,
    animationName: '$popup',
    animationDuration: '0.3s',
  },
  '@keyframes popup': {
    '0%': {
      left: '100%',
    },
    '100%': {
      left: 377,
    },
  },
  topArea: {
    background: 'rgba(255, 255, 255, 0.04)',
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    height: 62,
  },
}));

const ConfigureAlertSettings = (props) => {
  const classes = useStyles();
  const { t, alertTool, setAlertToolTypeData } = props;
  const { showStyleSettings } = alertTool;

  return (
    <div className={classes.root}>
      <CustomBackdropFilter
        className={classes.topArea}
        filter={'blur(50px)'}
        canvasFallback={true}
        html2canvasOpts={{
          allowTaint: true,
        }}
      >
        <div>
          <HoverIcon
            icon="fa-thin fa-xmark"
            color={Colors.white}
            hoverColor={Colors.halfWhite}
            style={{ fontSize: 17, marginRight: 16, marginLeft: 28 }}
            onClick={() => setAlertToolTypeData('showStyleSettings', false)}
          />
          {t(showStyleSettings)} {t('settings')}
        </div>

        <AnimationButton
          status="neonRed"
          style={{ padding: '8px 10px', marginRight: 24 }}
          onClick={() => setAlertToolTypeData('showStyleSettings', false)}
          disabled={(showStyleSettings === 'discord' && !alertTool.discordSettings) || (showStyleSettings === 'twitch' && !alertTool.twitchSettings)}
        >
          {t('save')}
        </AnimationButton>
      </CustomBackdropFilter>

      <div style={{ maxWidth: 741, margin: '50px auto 0' }}>
        {showStyleSettings === 'discord' &&
        <ConfigureAlertDiscordSettings t={t} alertTool={alertTool} />
        }
        {showStyleSettings === 'twitch' &&
        <ConfigureAlertTwitchSettings t={t} alertTool={alertTool} />
        }
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setAlertToolTypeData,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(withRouter(ConfigureAlertSettings));