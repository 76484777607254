import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { changeCardVerification } from '../../redux/actions';
import { ErrorCode } from './enums/ErrorCode';
import OrientationLock from './OrientationLock';
import StartLayout from './layout/Start';
import { useInvocationCheck } from './hooks/useInvocationCheck';

/**
 * Start page when the verification process is to be started. Possible non-supports are checked.
 *
 * @param props
 */
const StartVerification = (props) => {
  const { cardVerification, changeCardVerification, history } = props;
  const [redirect, setRedirect] = useState(false);
  const {
    invocationIsValid,
    browserIsSupported,
    deviceIsSupported,
  } = cardVerification;

  useInvocationCheck(history, invocationIsValid, changeCardVerification);

  useEffect(() => {
    if (!browserIsSupported) {
      setErrorCodeAndRedirect(ErrorCode.UnsupportedBrowser);
      return;
    }

    if (!deviceIsSupported)
      setErrorCodeAndRedirect(ErrorCode.UnsupportedDevice);
  }, [invocationIsValid, browserIsSupported, deviceIsSupported]);

  const setErrorCodeAndRedirect = (code) => {
    changeCardVerification({ errorCode: code });
    setRedirect(true);
  };

  if (redirect)
    return <Redirect to='/verification/notice' />;

  return (
    <OrientationLock>
      <StartLayout />
    </OrientationLock>
  );
};

const mapStateToProps = (state) => {
  const { cardVerification } = state;
  return { cardVerification };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    changeCardVerification,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StartVerification));