import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';

const useStyles = makeStyles(() => ({
  supporterArea: {
    width: 254,
    overflow: 'hidden',
    borderRadius: 6,
    ...Helpers.fillRow,
    height: '100%',
    position: 'relative',
  },
  overflowArea: {
    overflow: 'hidden',
    height: '100%',
    transition: 'all 0.3s ease-out',
  },
  blueBGGradient: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    background: 'linear-gradient(0deg, #011024 0%, #011024C2 31%, #01102400 57%, #01102400 100%)',
  },
  bottomLine: {
    position: 'absolute',
    left: 16,
    bottom: 16,
    right: 16,
    height: 40,
    zIndex: 2,
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
  },
  roundedIcon: {
    borderRadius: '50%',
    border: '1px solid #FFFFFF',
    height: 40,
    width: 40,
    transition: 'all 0.3s ease-out',
    ...Helpers.fillColCenter,
    cursor: 'pointer',
    fontSize: 18,
    '&:hover': {
      background: Colors.white,
      color: '#010F22',
    },
  },
}));

const OrderSummarySupporter = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { matches } = props;
  const [idx, setIdx] = useState(0);

  if (!matches)
    return null;

  const updateIdx = (isLeft) => {
    if (isLeft)
      setIdx(idx === 0 ? matches.length - 1 : idx - 1);
    else
      setIdx(idx === matches.length - 1 ? 0 : idx + 1);
  };

  const maxStartIdx = matches.length > 5 ? matches.length - 5 : 0;
  const movement = idx <= maxStartIdx ? idx : maxStartIdx;
  const mainWidth = matches.length > 1 ? 149 : 254;
  const additionalItemWidth = (254 - mainWidth) / ((matches.length > 5 ? 4 : matches.length - 1));

  return (
    <div className={classes.supporterArea}>
      <div className={classes.blueBGGradient} />
      {matches.slice(idx <= maxStartIdx ? idx : maxStartIdx, 5 + movement)
      .map((match, index) => (
        <div className={classes.overflowArea}
             style={{ width: index === (idx - movement) ? mainWidth : additionalItemWidth }}>
          <img
            src={match.image}
            style={{
              marginLeft: index === (idx - movement) ? -75 : -135,
              zIndex: 5 - index,
              opacity: index === (idx - movement) ? 1 : 0.11,
              transition: 'all 0.3s ease-out',
            }}
            alt="Streamer profile avatar" />
        </div>
      ))}

      <div className={classes.bottomLine}>
        {matches.length > 1 &&
        <div className={classes.roundedIcon} onClick={() => updateIdx(true)}>
          <i className="fa-light fa-chevron-left" />
        </div>
        }

        <div style={Helpers.textCenter}>
          <div style={{ fontSize: 12, color: Colors.halfWhite, fontWeight: 300 }}>
            {t('you_supported')}
          </div>
          <div style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: 130,
          }}>
            {matches && matches[idx] && matches[idx].display_name}
          </div>
        </div>

        {matches.length > 1 &&
        <div className={classes.roundedIcon} onClick={() => updateIdx(false)}>
          <i className="fa-light fa-chevron-right" />
        </div>
        }
      </div>
    </div>
  );
};

export default OrderSummarySupporter;