import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import { getMyOrderableTypes } from '../../apollo/mergedQueries';

import ErrorMessage from '../../components/ErrorBoundary/ErrorMessage';
import CardShop from '../../components/CardShop/CardShop';
import PageTitle from '../../components/PageTitle/PageTitle';
import TopBG from '../../components/TopViewHeader/TopBG';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import Colors from '../../theme/Colors';

function LegacyCardShopContainer(props) {
  const { t } = useTranslation();
  const { isLegacy, setIsLegacy } = props;
  const [page, setPage] = useState(2);
  const defaultChecked = {
    streamer: true,
    game: false,
    sh: false,
  };
  const [checked, setChecked] = useState(defaultChecked);
  const [sorting, setSorting] = useState({
    field: 'created_at',
    order: 'DESC',
  });

  const perPage = 25;

  const variables = {
    sorting,
    pagination: { perPage, page: 1 },
  };

  const { data, loading, error, fetchMore } = useQuery(getMyOrderableTypes, {
    variables,
    fetchPolicy: useCacheWithExpiration('getMyOrderableTypes'),
  });

  const shopItems = (data) && data.getMyOrderableCards;
  const myOrderableOffers = (data) && data.getMyOrderableOffers;
  const cards = (shopItems) && shopItems.cards;
  let packIns = (shopItems) && shopItems.pack_ins;

  if (error) return <ErrorMessage error={error} />;

  if (packIns)
    packIns = packIns.map(packIn => {
      return { ...packIn, name: packIn.t_string };
    });

  if (myOrderableOffers)
    myOrderableOffers.sort((a, b) => a.collectible_until > b.collectible_until ? 1 : -1)

  return (
    <>
      <PageTitle title={t('card_shop')} />
      <TopBG
        disableBreadcrumbs
        style={{
          padding: '30px 0 20px',
          backgroundSize: 'auto',
          color: Colors.white,
        }}
      >
        <CardShop
          cards={cards}
          packIns={packIns}
          hasUserLegacyCards
          // offers={myOrderableOffers}
          loading={loading}
          hasMore={(shopItems) && shopItems.count > cards.length}
          defaultChecked={defaultChecked}
          checked={checked}
          setChecked={setChecked}
          sorting={sorting}
          setSorting={setSorting}
          isLegacy={isLegacy}
          setIsLegacy={setIsLegacy}
          onLoadMore={async () => {
            try {
              await fetchMore({
                variables: { ...variables, pagination: { perPage, page } },
                updateQuery: (prev, { fetchMoreResult }) => {
                  setPage(page + 1);
                  if (!fetchMoreResult) return prev;
                  return {
                    getMyOrderableCards: {
                      cards: [...prev.getMyOrderableCards.cards, ...fetchMoreResult.getMyOrderableCards.cards],
                      count: prev.getMyOrderableCards.count,
                      __typename: prev.getMyOrderableCards.__typename,
                    },
                  };
                },
              });
            } catch {
            }
          }}
        />
      </TopBG>
    </>
  );
}

export default LegacyCardShopContainer;