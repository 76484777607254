import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';
import ThreeDWrapper from '../3DCard/3DWrapper';
import BoosterScene from '../3DCard/BoosterScene';
import CardViewDetails from '../Cards/CardViewDetails';
import SetFooterRow from './SetFooterRow';
import IconWrapper from '../Icon/IconWrapper';
import HoverIcon from '../Icon/HoverIcon';
import Colors from '../../theme/Colors';
import CustomTooltip from '../Tooltip/CustomTooltip';
import MiniCountdown from '../Countdown/MiniCountdown';

import conf from '../../conf.json';

const useStyles = makeStyles((theme) => ({
  box: {
    padding: '16px 20px',
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    marginBottom: 8,
    fontWeight: 300,
  },
  absBox: {
    height: 604,
    width: 498,
    position: 'absolute',
    cursor: 'pointer',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    [theme.breakpoints.down('md')]: {
      height: 226.5,
      width: 186.75,
      top: 60,
    },
  },
  countdownArea: {
    marginTop: 6,
    '& > div': {
      ...Helpers.mainStart,
    },
    '& div': {
      fontFamily: 'Montserrat',
      textTransform: 'inherit',
      color: '#FFFFFF80',
      '& > span': {
        color: Colors.white,
      },
    },
  },
}));

const BoosterPackView = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { costs, matchData, frontImage, backImage, cardsLength, booster, redeemBooster, isMobile } = props;

  const card = {
    created_at: booster.created_at,
  };

  return (
    <>
      {(booster.is_starter_pack && booster.is_custom_booster) ?
        <div className={classes.absBox} style={{ cursor: 'default' }}>
          <img
            src={`${conf.cdn_url}${booster.front_image}?height=${isMobile ? 200 : 471}`}
            alt="Booster pack"
            height={isMobile ? 200 : 471}
            style={{ paddingTop: 50 }}
          />
        </div>
        :
        <IconWrapper className={classes.absBox} id="three-js-area">
          <ThreeDWrapper noBackdrop>
            <BoosterScene
              frontImage={frontImage ? frontImage : `3d-objects/booster-pack-knossi_back.png`}
              backImage={backImage ? backImage : `3d-objects/booster-pack-knossi_back.png`}
              userLogo={matchData.avatar}
              isRotating
              isOblique
              isCustomPack={booster.is_custom_booster}
            />
          </ThreeDWrapper>
        </IconWrapper>
      }

      <div style={{ width: 371, marginLeft: 'auto' }}>
        <div style={{
          marginBottom: 16, ...Helpers.fillRow,
          alignItems: booster.is_starter_pack ? 'center' : 'baseline',
        }}>
          <div style={{
            fontFamily: 'Bebas Neue',
            fontSize: 36,
            lineHeight: 1,
            marginRight: 8,
          }}>
            {t(booster.is_starter_pack ? 'starterpack' : 'boosterpack')}
          </div>

          {booster.is_starter_pack ?
            <CustomTooltip title={t('starterpack_explanation')}>
              <div style={{ borderRadius: 50 }}>
                <HoverIcon
                  icon="fa-light fa-circle-info" hoverIcon="fa-solid fa-circle-info"
                  color={Colors.halfWhite} hoverColor={Colors.white}
                  style={{ fontSize: 18 }}
                />
              </div>
            </CustomTooltip>
            :
            <div style={{ color: Colors.halfWhite, fontSize: 16, fontWeight: 300 }}>
              / {t('standard_pack')}
            </div>
          }
        </div>

        {booster.is_starter_pack &&
        <div className={classes.box}>
          <div style={{ fontSize: 12, color: Colors.halfWhite }}>
            {t('expires_in')}:
          </div>
          <div className={classes.countdownArea}>
            <MiniCountdown endTime={booster.redeemable_until} />
          </div>
        </div>
        }

        <div className={classes.box}
             style={{ ...Helpers.fillRowCross, ...Helpers.mainSpaceBetween, fontSize: 16 }}>
          <div style={{ color: Colors.halfWhite }}>
            {t(booster.is_starter_pack ? 'cards_in_starterpack' : 'cards_in_booster')}:
          </div>
          <div>
            {cardsLength}
          </div>
        </div>

        <CardViewDetails card={card} informationData={booster} />

        <SetFooterRow costs={costs} booster={booster} matchData={matchData}
                      redeemBooster={redeemBooster} />
      </div>
    </>
  );
};

export default BoosterPackView;