import React from 'react';
import { useTranslation } from 'react-i18next';

import PageTitle from '../../components/PageTitle/PageTitle';

import NeonMeetsIce from '../../components/Seasons/NeonMeetsIce';

const NeonMeetsIceContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle title={t('season_neon_ice')} />

      <NeonMeetsIce t={t} />
    </>
  );
};

export default NeonMeetsIceContainer;