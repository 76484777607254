import React from 'react';
import { makeStyles } from '@material-ui/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CardPopup from '../Dialog/CardPopup';
import Colors from '../../theme/Colors';
import { returnMobileObj } from '../../utils/responsive';
import Helpers from '../../theme/Helpers';

const useStyles = makeStyles(() => ({
  header: {
    background: '#7BB01C',
    padding: 15,
    color: Colors.white,
    position: 'relative',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  body: {
    padding: 40,
    background: '#01132B',
    color: '#5C7291',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  title: {
    fontSize: 26,
    fontFamily: 'Bebas Neue',
    lineHeight: 1,
    ...Helpers.textCenter,
  },
  subTitle: {
    ...Helpers.textCenter,
    lineHeight: 1,
  },
}));

/**
 * @return {null}
 */
function EmptyPopup(props) {
  const classes = useStyles();
  const {
    handleClose,
    title,
    subTitle,
    children,
    isTabletOrMobile,
    style,
    bodyStyle,
    headerStyle,
    handleCloseOnlyIcon,
  } = props;

  return (
    <CardPopup
      handleClose={handleCloseOnlyIcon ? null : handleClose}
      style={{
        backgroundColor: 'transparent',
        width: isTabletOrMobile ? '90%' : 'auto',
        maxHeight: 'auto',
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: 0,
        ...(style ? style : {}),
      }}
    >
      <div className={classes.header} style={headerStyle ? headerStyle : {}}>
        <FontAwesomeIcon
          icon={faTimes}
          size="2x"
          style={{ position: 'absolute', right: 15, top: 10, cursor: 'pointer', zIndex: 10 }}
          onClick={handleClose}
        />
        <div className={classes.title}>
          {title}
        </div>
        {subTitle &&
        <div className={classes.subTitle}>
          {subTitle}
        </div>
        }
      </div>
      <div
        className={classes.body}
        style={{
          ...(bodyStyle ? bodyStyle : {}),
          ...returnMobileObj(isTabletOrMobile, { padding: 10 }),
        }}
      >
        {children}
      </div>
    </CardPopup>
  );
}

export default EmptyPopup;