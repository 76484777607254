import React from 'react';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

import { setCollectorsViewCard, setCollectorsViewShowCardInfo } from '../../redux/actions';
import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import QualityLine from '../QualityLine/QualityLine';
import SVGIcon from '../SVGIcon/SVGIcon';
import MergedImage from '../MergedImage/MergedImage';
import InventoryBoosterCardPlaceholder from './InventoryBoosterCardPlaceholder';
import AnimationButton from '../Button/AnimationButton';
import MarketplaceBuySellButton from '../Button/MarketplaceBuySellButton';

import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  item: {
    width: '100%',
    transition: 'all 0.3s ease-out',
    background: 'rgba(255, 255, 255, 0.02)',
    color: 'rgba(255, 255, 255, 0.2)',
    cursor: 'pointer',
    borderRadius: 6,
    position: 'relative',
    opacity: 0.6,

    '&.active': {
      background: 'rgba(255, 255, 255, 0.04)',
      opacity: 1,

      '& #inventory-text': {
        color: Colors.halfWhite,
      },
      '& #inventory-count': {
        color: Colors.white,
      },
    },

    '&:hover': {
      opacity: 1,
      background: 'rgba(255, 255, 255, 0.08)',

      '& i': {
        color: Colors.white,
      },
    },
  },
  wrapper: {
    paddingTop: 24,
    display: 'grid',
    gap: 8,
    width: '100%',

    // Hide Scrollbars But Keep Functionality
    scrollbarWidth: 'none', // Hide scrollbar for Chrome, Safari and Opera
    '&::-webkit-scrollbar': { // Hide scrollbar for IE, Edge and Firefox
      display: 'none',
    },
  },
  badge: {
    width: 30,
    height: 30,
    borderRadius: 6,
    background: 'rgba(255, 255, 255, 0.06)',
    ...Helpers.fillColCenter,
    marginBottom: 8,
  },
}));

const InventoryBoosterCardsListSlider = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { cardCollectionProgress, cardListStyle, showCardInfo, card } = props;

  return (
    <div
      className={classes.wrapper}
      style={{
        marginRight: showCardInfo ? 3 : 0,
        gridTemplateColumns: `repeat(auto-fill, ${showCardInfo ? '352px' : '280px'})`,
      }}
    >
      {cardCollectionProgress && [...cardCollectionProgress.progress].sort((a, b) => b.quality - a.quality)
      .filter(item => (cardListStyle === 'all') || (cardListStyle === 'owned' && item.owned_count > 0) || (cardListStyle === 'missing' && item.owned_count === 0))
      .map(progress => {
        const isActive = progress.owned_count > 0;

        return (
          <div
            key={`ProgressQualityCard${progress.quality}Count${progress.owned_count}`}
            className={[classes.item, isActive ? 'active' : ''].join(' ')}
            onClick={() => props.setCollectorsViewShowCardInfo({
              card: cardCollectionProgress.card,
              progress: progress,
            })}
          >
            <div style={{
              ...Helpers.fillRowMain, ...Helpers.mainSpaceBetween,
              padding: '32px 8px',
            }}>
              <div style={{ width: 30 }}>
                {progress.bought &&
                <div
                  className={classes.badge}
                  style={{
                    background: 'rgba(255, 64, 103, 0.2)',
                  }}
                >
                  <SVGIcon
                    src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
                    height={16}
                    width={12}
                  />
                </div>
                }
                {progress.owned_seasons && progress.owned_seasons.map(season => (
                  <div
                    className={classes.badge}
                    style={{
                      background: `url("${season.logo}?height=64") top center`,
                    }}
                  />
                ))}
              </div>
              {isActive ?
                <div style={{ position: 'relative' }}>
                  <MergedImage
                    card={{
                      front_layers: [
                        { img_url: card.card_layer_image },
                        { img_url: progress.quality_layer },
                      ],
                    }}
                    height={288}
                    borderRadius={6}
                    id={`card-image-${card._id}`}
                  />
                </div>
                :
                <InventoryBoosterCardPlaceholder width={187} height={288} />
              }
              <div style={{ width: 30 }} />
            </div>

            <div style={{ padding: '0 16px', borderBottom: '1px solid rgba(255, 255, 255, 0.08)' }}>
              <QualityLine quality={progress.quality} />
            </div>
            <div
              style={{ padding: '12.5px 16px 16px', ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween }}>
              <div>
                <div
                  id="inventory-text"
                  style={{
                    color: isActive ? Colors.halfWhite : 'rgba(255, 255, 255, 0.2)',
                    fontSize: 12,
                    marginBottom: 10,
                  }}
                >
                  {t('in_inventory')}
                </div>
                <div
                  id="inventory-count"
                  style={{ fontSize: 14, fontWeight: 500 }}
                >
                  {progress.owned_count}
                </div>
              </div>
              <div style={Helpers.fillRowCross}>
                <MarketplaceBuySellButton item={{ card: cardCollectionProgress.card, progress }}
                                          withText={false} />
                <AnimationButton status="whiteTransparency"
                                 style={{ padding: '11px 13px', marginLeft: 4, borderRadius: 4 }}>
                  <SVGIcon
                    src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
                    height={25}
                    width={19}
                  />
                </AnimationButton>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setCollectorsViewCard,
    setCollectorsViewShowCardInfo,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(InventoryBoosterCardsListSlider);