import React from 'react';
import { useTranslation } from 'react-i18next';

import Colors from '../../theme/Colors';
import CustomButton from '../Button/CustomButton';
import Helpers from '../../theme/Helpers';
import CardShopPlantATree from '../CardShop/CardShopPlantATree';

const OrderSupportContact = (props) => {
  const { t } = useTranslation();
  const { style, total } = props;

  const redirectToContact = () => {
    window.location = 'https://help.streamheroes.gg/hc/de/requests/new';
  };

  return (
    <div style={{
      ...Helpers.fillColCross,
      backgroundColor: '#040D1D',
      padding: 20,
      textTransform: 'uppercase',
      color: Colors.white,
      ...style,
    }}>
      <div style={{ fontSize: 20, fontWeight: 'bold' }}>
        {t('questions?')}
      </div>
      <img
        src="https://cdn.streamheroes.gg/assets/contact_support.png"
        alt="Support"
        style={{ margin: '40px 0' }}
      />
      <div style={{ color: '#8190A7', marginBottom: 20 }}>
        {t('need_help_for_order')}
      </div>
      <CustomButton status="outline" onClick={redirectToContact}>
        {t('get_in_touch')}
      </CustomButton>
      {total !== undefined &&
      <CardShopPlantATree price={total} />
      }
    </div>
  );
};

export default OrderSupportContact;