import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useSnackbar } from 'notistack';

import OrderPay from '../../components/Order/OrderPay';
import Colors from '../../theme/Colors';
import { isTabletOrMobileObj } from '../../utils/responsive';
import CardShopSideCart from '../../components/CardShop/CardShopSideCart';
import Loading from '../Loading/Loading';
import CardShopPayLayout from './CardShopPayLayout';
import OrderForm from '../Order/OrderForm';
import OrderSummary from '../Order/OrderSummary';
import { logAmplitudeEvent } from '../../utils/amplitude';
import conf from '../../conf';

const useStyles = makeStyles(() => ({
  area: {
    color: Colors.white,
    position: 'relative',
  },
}));

const CardShopPay = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const stripe = useStripe();
  const elements = useElements();
  const {
    formValues,
    countryCosts,
    createOrderHandler,
    orderId,
    total,
    shipCosts,
    isFormFullFilled,
    setIsFormFullFilled,
    paypalLoading,
    setPaypalLoading,
    clientSecret,
    loading,
    orderCompleted,
    summaryMatches,
    discount,
  } = props;
  const [stepIdx, setStepIdx] = useState(0);
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentType, setPaymentType] = useState('paypal');
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (orderCompleted)
      setStepIdx(2);
  }, [orderCompleted]);

  useEffect(() => {
    if (isFormFullFilled && orderId && stepIdx === 0 && formValues.acceptWrongAddress && !formValues.showCheckAddress)
      setStepIdx(1);
  }, [orderId, isFormFullFilled, stepIdx, formValues]);

  const isTabletOrMobile = useMediaQuery(isTabletOrMobileObj);

  const onSuccess = (data, details) => {
    // if (isSubscription) {
    //   window.location.href = window.location.origin + window.location.pathname + '?premium=success';
    //   return;
    // }

    setProcessing(true);
    let redirect = '/card-shop-pay';

    if (data.status === 'COMPLETED')
      redirect += '?redirect_status=succeeded';

    props.history.push(redirect);
  };

  /**
   * Paypal onCancel method
   *
   * @param data
   */
  const onCancel = (data) => {
    enqueueSnackbar(t('order_canceled'), { variant: 'error' });
    setProcessing(false);
    setPaypalLoading(false);
  };

  const handleSubmit = async () => {
    /*if (isSubscription && stripeURL) {
      window.location.href = stripeURL;
      return;
    }*/

    if (!stripe || !elements)
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;

    setProcessing(true);

    const eventProperties = {
      'Payment Provider': 'Stripe',
    };

    if(conf.track_amplitude) logAmplitudeEvent('Continue to payment', eventProperties);

    const payload = await getPaymentPayload();

    if (payload.error) {
      enqueueSnackbar(`Payment failed ${payload.error.message}`, { variant: 'error' });
      setProcessing(false);
    } else {
      const { status, client_secret } = payload.paymentIntent;
      setProcessing(false);
      props.history.push(`/card-shop-pay?redirect_status=${status}&payment_intent_client_secret=${client_secret}`);
    }
  };

  const getPaymentPayload = async () => {
    // const returnUrl = `https://app.streamheroes.gg/card-shop-pay`;

    if (paymentType === 'card') {
      return stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });
    }/* else if (paymentType === 'sofort') {
      return stripe.confirmSofortPayment(clientSecret, {
        payment_method: {
          sofort: {
            country: formValues.country,
          },
        },
        // Return URL where the customer should be redirected after the authorization.
        return_url: returnUrl,
      });
    } else if (paymentType === 'eps') {
      return stripe.confirmEpsPayment(clientSecret, {
        payment_method: {
          billing_details: {
            name: `${formValues.forename} ${formValues.surname}`,
          },
        },
        // Return URL where the customer should be redirected after the authorization.
        return_url: returnUrl,
      });
    } else if (paymentType === 'giropay') {
      return stripe.confirmGiropayPayment(clientSecret, {
        payment_method: {
          billing_details: {
            name: `${formValues.forename} ${formValues.surname}`,
          },
        },
        // Return URL where the customer should be redirected after the authorization.
        return_url: returnUrl,
      });
    }*/
  };

  const steps = [
    {
      title: 'order_address',
      component:
        <OrderForm
          countryCosts={countryCosts}
          setIsFormFullFilled={setIsFormFullFilled}
          isTabletOrMobile={isTabletOrMobile}
        />,
    },
    {
      title: 'order_head_checkout',
      component: <OrderPay
        checkedTerms={checkedTerms}
        setCheckedTerms={setCheckedTerms}
        paymentType={paymentType}
        setPaymentType={setPaymentType}
        setDisabled={setDisabled}
        stripe={stripe}
        elements={elements}
      />,
    },
    {
      component: <OrderSummary summaryMatches={summaryMatches} total={total ? (total / 100) : 0} />,
    },
  ];
  const activeStep = steps[stepIdx];

  return (
    <CardShopPayLayout activeStep={stepIdx}>
      <div className={classes.area}>
        {activeStep.title &&
        <div style={{
          marginBottom: 18,
          fontFamily: 'Bebas Neue',
          fontSize: 36,
          lineHeight: 1,
        }}>
          {t(activeStep.title)}
        </div>
        }

        {loading ?
          <Loading />
          :
          activeStep.component
        }
      </div>
      <CardShopSideCart
        total={total / 100}
        setActiveStep={() => setStepIdx(stepIdx + 1)}
        shipCosts={shipCosts}
        activeStep={stepIdx}
        isFormFullFilled={isFormFullFilled && orderId}
        checkedTerms={checkedTerms}
        processing={processing}
        paymentType={paymentType}
        disabled={disabled}
        createOrderHandler={createOrderHandler}
        onSuccess={onSuccess}
        onCancel={onCancel}
        loading={paypalLoading}
        handleSubmit={handleSubmit}
        discount={discount}
      />
    </CardShopPayLayout>
  );
};

export default withRouter(CardShopPay);