import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  createRedeemedCard as createRedeemedCardMutation,
  redeemSpecialCard as redeemSpecialCardMutation,
  craftCard as craftCardMutation,
} from '../../apollo/mutations';
import {
  getCollection,
  getLoggedInEventHeader, getLoggedInMobileEventHeader,
  getViewerDashboard,
} from '../../apollo/mergedQueries';
import {
  getCurrentOffer,
  getDashboardCards,
  getTypeCards, isOrderPossible,
  myCollectedPointsForType,
} from '../../apollo/queries';

import CardCraftProgress from './CardCraftProgress';
import CardRedeemProgress from './CardRedeemProgress';
import { addPopupEvent, increaseCount } from '../../redux/actions';
import CardEventSpecialProgress from './CardEventSpecialProgress';

import conf from '../../conf.json';
import { logAmplitudeEvent } from '../../utils/amplitude';
import { capitalize } from '../../utils/helper';

function CardProgresses(props) {
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const pathLocation = capitalize(pathname.split('/')[1]);

  const {
    card,
    coinsInformation,
    sorting,
    hideCardsCraft,
    hideProgresses,
    addPopupEvent,
    countOfLastCard,
    isEventMode,
  } = props;
  const { match_card } = card;
  const [redeemLoading, setRedeemLoading] = useState(false);
  const [craftLoading, setCraftLoading] = useState(false);

  /**
   * We use an update function here to write the new value of the query.
   *
   * @param cache
   * @param card
   * @param updatePreviousCard - Previous level card must also be adjusted from the count
   */
  const updateTypeCardsCache = (cache, card, updatePreviousCard = false) => {
    try {
      const existingCards = cache.readQuery({
        query: getTypeCards,
        variables: typeVariables,
      });

      if (existingCards && card) {
        const newData = { ...existingCards };
        const matchCard = card.match_card;

        const idx = newData.getTypeCards.findIndex(card => card.match_card._id === matchCard._id);
        if (idx !== -1)
          newData.getTypeCards[idx].count += 1;

        if (updatePreviousCard) {
          const lowerIdx = newData.getTypeCards.findIndex(card => card.match_card.level === (matchCard.level - 1));
          if (lowerIdx !== -1)
            newData.getTypeCards[lowerIdx].count -= 5;
        }

        cache.writeQuery({
          query: getTypeCards,
          variables: typeVariables,
          data: newData,
        });

        props.increaseCount();
      }
    } catch (e) {
    }
  };

  /**
   * We use an update function here to write the new value of the query.
   *
   * @param cache
   * @param costs
   */
  const updateEnergyCache = (cache, costs) => {
    try {
      const existingPoints = cache.readQuery({
        query: myCollectedPointsForType,
        variables: typeVariables,
      });

      if (existingPoints && costs) {
        const newData = { ...existingPoints };
        newData.viewerRedeemedPoints.redeemed_points_season += costs;
        newData.viewerRedeemedPoints.redeemed_points += costs;

        cache.writeQuery({
          query: myCollectedPointsForType,
          variables: typeVariables,
          data: newData,
        });

        props.increaseCount();
      }
    } catch (e) {
    }
  };

  /**
   * We use an update function here to write the new value of the query.
   *
   * @param cache
   * @param card
   * @param isCraft
   */
  const updateCollectionCache = (cache, card, isCraft = false) => {
    try {
      const existingCollection = cache.readQuery({
        query: getCollection,
        variables: {
          sorting,
          pagination: { perPage: 50, page: 1 },
          unique: true,
        },
      });

      if (existingCollection && card) {
        let newData = { ...existingCollection };
        const addCount = isCraft ? -4 : 1;

        const matchModel = card.match_card.matchModel;
        const matchId = card.match_card.match._id;
        if (matchModel === 'Organisation') {
          newData.getMyRedeemedCardTypeCounts.organisation += addCount;
          const idx = newData.getMyOwnedCardsOrganisations.findIndex(organisation => organisation._id === matchId);
          if (idx === -1)
            newData.getMyOwnedCardsOrganisations.push(card.match_card.match);
        } else if (matchModel === 'Streamer') {
          newData.getMyRedeemedCardTypeCounts.streamer += addCount;
          const idx = newData.getMyOwnedCardsStreamers.findIndex(streamer => streamer._id === matchId);
          if (idx === -1)
            newData.getMyOwnedCardsStreamers.push(card.match_card.match);
        }

        newData.getMyRedeemedCardTypeCounts.total += addCount;

        // SH card has level 0
        const cardLevel = card.matchCardModel === 'Card' ? card.match_card.level : 0;
        if (!newData.getMyOwnedCardsLevels.includes(cardLevel))
          newData.getMyOwnedCardsLevels.push(cardLevel);

        const cardId = card.match_card._id;
        const idx = newData.getMyOwnedCards.findIndex(card => card._id === cardId);
        if (idx !== -1) {
          newData.getMyOwnedCards[idx].count += 1;
        } else if (idx === -1 && newData.getMyOwnedCardsCount < 50) {
          newData.getMyOwnedCards.unshift({ ...card, count: 1 });
          newData.getMyOwnedCardsCount += 1;
        }

        updateMyCards(newData, card, isCraft, cardId);

        cache.writeQuery({
          query: getCollection,
          variables: {
            sorting,
            pagination: { perPage: 50, page: 1 },
            unique: true,
          },
          data: newData,
        });
      }
    } catch (e) {
    }
  };

  const updateMyCards = (newData, card, isCraft, cardId) => {
    if (isCraft) {
      if (newData.getMyCraftableCards.length >= 1) {
        const idx = newData.getMyCraftableCards.findIndex(card => card._id === cardId);
        if (idx !== -1) {
          newData.getMyCraftableCards[idx].cards_for_craft -= 5;
          if (newData.getMyCraftableCards[idx].cards_for_craft < 6)
            newData.getMyCraftableCards.splice(idx, 1);
        }
      }
    } else {
      if (newData.getMyRedeemableCards.length >= 1) {
        const costs = card.matchCardModel === 'Card' ? 6 : card.match_card.costs;
        const removeIndexes = [];

        // Each card must be checked, as multiple cards from the same match may appear in the list
        for (let i = 0; i < newData.getMyRedeemableCards.length; i++) {
          if (newData.getMyRedeemableCards[i].matchId === card.match_card.match._id) {
            newData.getMyRedeemableCards[i].energy_for_redeem -= costs;
            if (newData.getMyRedeemableCards[i].energy_for_redeem < costs)
              removeIndexes.push(i);
          }
        }

        if (removeIndexes.length > 0)
          newData.getMyRedeemableCards = newData.getMyRedeemableCards.filter((value, index) => {
            return removeIndexes.indexOf(index) === -1;
          });

        props.increaseCount();
      }
    }
  };

  /**
   * We use an update function here to write the new value of the query.
   *
   * @param cache
   * @param card
   * @param isCraft
   */
  const updateViewerDashboardCache = (cache, card, isCraft = false) => {
    try {
      const existingViewerDashboard = cache.readQuery({
        query: getViewerDashboard,
        variables: { total: true },
      });

      if (existingViewerDashboard && card) {
        let newData = { ...existingViewerDashboard };

        newData.getHeaderKPIs.amount_cards += isCraft ? -4 : 1;
        updateMyCards(newData, card, isCraft, card.match_card._id);

        cache.writeQuery({
          query: getViewerDashboard,
          variables: { total: true },
          data: newData,
        });
      }
    } catch (e) {
    }
  };

  /**
   * We use an update function here to write the new value of the query.
   *
   * @param cache
   * @param card
   */
  const updateDashboardCardsCache = (cache, card) => {
    try {
      const existingDashboardCards = cache.readQuery({
        query: getDashboardCards,
        variables: { streamerOrgaCardsMixed: true },
      });

      if (existingDashboardCards && card) {
        const newData = { ...existingDashboardCards };

        let onlySorting = false;
        const matchCardId = card.match_card._id;
        const idx = newData.getDashboardCards.mixed.findIndex(card => card.match_card._id === matchCardId);
        // Check if the card was already created in the last cards
        if (idx !== -1) {
          // The selected card is moved to index 0
          newData.getDashboardCards.mixed.sort((x, y) => x.match_card._id === matchCardId ? -1 : y.match_card._id === matchCardId ? 1 : 0);
          onlySorting = true;
        } else // Otherwise pushed to index 0
          newData.getDashboardCards.mixed.unshift(card);

        // If a card is added and there are more than 5 cards, the last index should be removed
        if (newData.getDashboardCards.mixed.length > 5 && !onlySorting)
          newData.getDashboardCards.mixed.pop();

        if (newData.getDashboardCards.top.length > 0) {
          let pos = null;
          for (let i = 0; i < newData.getDashboardCards.top.length; i++) {
            const compareLevel = newData.getDashboardCards.top[i].match_card && newData.getDashboardCards.top[i].match_card.level;
            // Check if the new card level has a higher level than the previous top cards
            if (compareLevel && compareLevel < card.match_card.level) {
              pos = i;
              break;
            }
          }
          // If the card has a higher level, it should be inserted at this index
          if (pos !== null)
            newData.getDashboardCards.top.splice(pos, 0, card);
          else if (newData.getDashboardCards.top.length < 5)
            newData.getDashboardCards.top.push(card);
        } else
          newData.getDashboardCards.top.push(card);

        cache.writeQuery({
          query: getDashboardCards,
          variables: { streamerOrgaCardsMixed: true },
          data: newData,
        });
      }
    } catch (e) {
    }
  };

  /**
   * We use an update function here to write the new value of the query.
   * Currently only applied to SpecialCard.
   *
   * @param cache
   * @param card
   */
  const updateOfferCardsCache = (cache, card) => {
    if (!conf.offer_exists)
      return;

    try {
      const existingCurrentOffer = cache.readQuery({
        query: getCurrentOffer,
        variables: {
          matchModel: card.match_card.matchModel,
          match: card.match_card.match._id,
        },
      });

      if (existingCurrentOffer && card) {
        const requiredCards = existingCurrentOffer.getCurrentOffer.required_cards.map(card => card._id);
        const cardId = card.match_card._id;

        if (requiredCards.includes(cardId)) {
          const variables = { offer: existingCurrentOffer.getCurrentOffer._id };
          const existingIsOrderPossible = cache.readQuery({
            query: isOrderPossible,
            variables,
          });

          const newData = { ...existingIsOrderPossible };

          if (!newData.isOrderPossible.owned_card_ids.includes(cardId)) {
            newData.isOrderPossible.owned_card_ids.push(cardId);

            if (newData.isOrderPossible.owned_card_ids.length === requiredCards.length)
              newData.isOrderPossible.possible = true;

            cache.writeQuery({
              query: isOrderPossible,
              variables,
              data: newData,
            });
          }
        }
      }
    } catch (e) {
    }
  };

  /**
   * We use an update function here to write the new value of the query.
   * Currently only applied to SpecialCard.
   *
   * @param cache
   * @param card
   */
  const updateEventQueryCache = (cache, card) => {
    const { match } = card.match_card;
    if (!conf.offer_exists || match.login !== conf.event_streamer)
      return;

    try {
      const variables = {
        streamer: match._id,
        id: match._id,
        offerId: conf.offer_id,
      };
      const existingLoggedInEventHeader = cache.readQuery({
        query: getLoggedInEventHeader,
        variables,
      });

      if (existingLoggedInEventHeader && card) {
        const newData = { ...existingLoggedInEventHeader };

        const requiredCards = newData.getOffer.required_cards.map(card => card._id);
        const cardId = card.match_card._id;

        if (!newData.isOrderPossible.owned_card_ids.includes(cardId)) {
          newData.isOrderPossible.owned_card_ids.push(cardId);

          if (newData.isOrderPossible.owned_card_ids.length === requiredCards.length)
            newData.isOrderPossible.possible = true;

          cache.writeQuery({
            query: getLoggedInEventHeader,
            variables,
            data: newData,
          });
        }
      }
    } catch (e) {
    }
  };

  /**
   * We use an update function here to write the new value of the query.
   * Currently only applied to SpecialCard.
   *
   * @param cache
   * @param card
   */
  const updateMobileEventQueryCache = (cache, card) => {
    const { match } = card.match_card;
    if (!conf.offer_exists || match.login !== conf.event_streamer)
      return;

    try {
      const variables = {
        streamer: match._id,
        offerId: conf.offer_id,
      };
      const existingLoggedInMobileEventHeader = cache.readQuery({
        query: getLoggedInMobileEventHeader,
        variables,
      });

      if (existingLoggedInMobileEventHeader && card) {
        const newData = { ...existingLoggedInMobileEventHeader };

        const requiredCards = newData.getOffer.required_cards.map(card => card._id);
        const cardId = card.match_card._id;

        if (!newData.isOrderPossible.owned_card_ids.includes(cardId)) {
          newData.isOrderPossible.owned_card_ids.push(cardId);

          if (newData.isOrderPossible.owned_card_ids.length === requiredCards.length)
            newData.isOrderPossible.possible = true;

          cache.writeQuery({
            query: getLoggedInMobileEventHeader,
            variables,
            data: newData,
          });
        }
      }
    } catch (e) {
    }
  };

  const [createRedeemedCard] = useMutation(createRedeemedCardMutation, {
    update: (cache, { data }) => {
      const { createRedeemedCard } = data;
      if (createRedeemedCard) {
        updateTypeCardsCache(cache, createRedeemedCard);
        updateEnergyCache(cache, 6);
        updateCollectionCache(cache, createRedeemedCard);
      }
    },
    onCompleted: (data) => {
      const { createRedeemedCard } = data;
      makeAlert(t('redeemed_card_success'), 'success');
      setRedeemLoading(false);
      const ownerName = createRedeemedCard.match_card.matchModel === 'Streamer' ? createRedeemedCard.match_card.match.display_name : createRedeemedCard.match_card.match.name;
      // Google Tag Manager
      window.dataLayer.push({
        event: 'Card Crafted',
        variables: {
          userId: createRedeemedCard.viewer._id,
          cardId: createRedeemedCard.match_card._id,
          brandName: ownerName,
          productName: 'Level 1',
          productCategory: 'Main set',
          timestamp: createRedeemedCard.created_at,
        },
      });

      if (conf.track_amplitude)
        logAmplitudeEvent('Card craft', {
          'Card ID': createRedeemedCard.match_card._id,
          'Card Owner type': createRedeemedCard.match_card.matchModel,
          'Card Owner': ownerName,
          'Card type': 'Card',
          'Card level': 1,
          'Craft location': pathLocation,
          'Exchanged type': 'Energy',
          'Exchanged quantity': 6,
          'First crafted for user': createRedeemedCard.first_for_user ? 'Yes' : 'No',
        });
      if (createRedeemedCard.first_for_user)
        addPopupEvent({
          key: 'card',
          value: createRedeemedCard.match_card,
        });
    },
    onError: (data) => {
      makeAlert(data.message, 'error');
      setRedeemLoading(false);
    },
  });
  const [redeemSpecialCard] = useMutation(redeemSpecialCardMutation, {
    update: (cache, { data }) => {
      const { redeemSpecialCard } = data;
      if (redeemSpecialCard) {
        updateTypeCardsCache(cache, redeemSpecialCard);
        updateEnergyCache(cache, redeemSpecialCard.match_card.costs);
        updateCollectionCache(cache, redeemSpecialCard);
        //updateOfferCardsCache(cache, redeemSpecialCard);
        updateEventQueryCache(cache, redeemSpecialCard);
        updateMobileEventQueryCache(cache, redeemSpecialCard);
      }
    },
    onCompleted: (data) => {
      const { redeemSpecialCard } = data;
      makeAlert(t('redeemed_card_success'), 'success');
      setRedeemLoading(false);
      const ownerName = redeemSpecialCard.match_card.matchModel === 'Streamer' ? redeemSpecialCard.match_card.match.display_name : redeemSpecialCard.match_card.match.name;
      // Google Tag Manager
      window.dataLayer.push({
        event: 'Card Crafted',
        variables: {
          userId: redeemSpecialCard.viewer._id,
          cardId: redeemSpecialCard.match_card._id,
          brandName: ownerName,
          productName: redeemSpecialCard.match_card.card_name,
          productCategory: `Special event (${redeemSpecialCard.match_card.edition})`,
          timestamp: redeemSpecialCard.created_at,
        },
      });

      if (conf.track_amplitude)
        logAmplitudeEvent('Card craft', {
          'Card ID': redeemSpecialCard.match_card._id,
          'Card Owner type': redeemSpecialCard.match_card.matchModel,
          'Card Owner': ownerName,
          'Card type': 'SpecialCard',
          'Craft location': pathLocation,
          'Exchanged type': 'Energy',
          'Exchanged quantity': redeemSpecialCard.match_card.costs,
          'First crafted for user': redeemSpecialCard.first_for_user ? 'Yes' : 'No',
        });
      /*if (redeemSpecialCard.first_for_user)
        addPopupEvent({
          key: 'card',
          value: redeemSpecialCard.match_card,
        });*/
    },
    onError: (data) => {
      makeAlert(data.message, 'error');
      setRedeemLoading(false);
    },
  });
  const [craftCard] = useMutation(craftCardMutation, {
    update: (cache, { data }) => {
      const { craftRedeemedCard } = data;
      if (craftRedeemedCard) {
        updateTypeCardsCache(cache, craftRedeemedCard, true);
        updateCollectionCache(cache, craftRedeemedCard, true);
      }
    },
    onCompleted: (data) => {
      const { craftRedeemedCard } = data;
      makeAlert(t('crafted_card_success'), 'success');
      setCraftLoading(false);
      const ownerName = craftRedeemedCard.match_card.matchModel === 'Streamer' ? craftRedeemedCard.match_card.match.display_name : craftRedeemedCard.match_card.match.name;
      // Google Tag Manager
      window.dataLayer.push({
        event: 'Card Crafted',
        variables: {
          userId: craftRedeemedCard.viewer._id,
          cardId: craftRedeemedCard.match_card._id,
          brandName: ownerName,
          productName: `Level ${craftRedeemedCard.match_card.level}`,
          productCategory: `Main set`,
          timestamp: craftRedeemedCard.created_at,
        },
      });

      if (conf.track_amplitude)
        logAmplitudeEvent('Card craft', {
          'Card ID': craftRedeemedCard.match_card._id,
          'Card Owner type': craftRedeemedCard.match_card.matchModel,
          'Card Owner': craftRedeemedCard.match_card.matchModel === 'Streamer' ? craftRedeemedCard.match_card.match.display_name : craftRedeemedCard.match_card.match.name,
          'Card type': 'Card',
          'Card level': craftRedeemedCard.match_card.level,
          'Craft location': pathLocation,
          'Exchanged type': 'Card',
          'Exchanged quantity': 5,
          'First crafted for user': craftRedeemedCard.first_for_user ? 'Yes' : 'No',
        });
        
      if (craftRedeemedCard.first_for_user || craftRedeemedCard.match_card.level >= 3) {
        addPopupEvent({
          key: 'card',
          value: craftRedeemedCard.match_card,
        });
      }
    },
    onError: (data) => {
      makeAlert(data.message, 'error');
      setCraftLoading(false);
    },
  });

  if (!match_card.matchModel)
    return null;

  let matchId;
  if (match_card.match && match_card.match._id)
    matchId = match_card.match._id;
  else
    matchId = match_card.matchId;

  const typeVariables = {};
  typeVariables[match_card.matchModel.toLowerCase()] = matchId;

  let variables;
  if (match_card.matchModel === 'Streamer')
    variables = { streamer: matchId };
  else if (match_card.matchModel === 'Game')
    variables = { game: matchId };
  else if (match_card.matchModel === 'Organisation')
    variables = { organisation: matchId };

  const makeAlert = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  const redeemCard = () => {
    setRedeemLoading(true);

    if (match_card.__typename === 'Card')
      createRedeemedCard({ variables });
    else if (match_card.__typename === 'SpecialCard')
      redeemSpecialCard({ variables: { cardId: match_card._id } });
  };

  const craftNewLevelCard = () => {
    setCraftLoading(true);
    craftCard({ variables: { ...variables, ...{ level: match_card.level } } });
  };

  if (!coinsInformation || !coinsInformation.viewerRedeemedPoints || hideProgresses)
    return null;

  let matchCardModel = card.matchCardModel;
  if (!card.matchCardModel)
    matchCardModel = match_card.__typename;

  return (
    <div>
      {matchCardModel === 'Card' ?
        <>
          <CardRedeemProgress
            card={card}
            redeemCard={redeemCard}
            loading={redeemLoading}
            coinsInformation={coinsInformation}
          />
          {(card && card.match_card && card.match_card.__typename === 'Card' && !hideCardsCraft) &&
          <CardCraftProgress
            craftNewLevelCard={craftNewLevelCard}
            loading={craftLoading}
            countOfLastCard={countOfLastCard}
          />
          }
        </>
        :
        <CardEventSpecialProgress
          card={card}
          redeemCard={redeemCard}
          coinsInformation={coinsInformation}
          loading={redeemLoading}
        />
      }
    </div>
  );
}

CardProgresses.propTypes = {
  card: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  addPopupEvent: (event) => dispatch(addPopupEvent(event)),
  increaseCount: (event) => dispatch(increaseCount(event)),
});

export default connect(null, mapDispatchToProps)(CardProgresses);