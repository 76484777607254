import React from 'react';
import moment from 'moment';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import distinctColors from 'distinct-colors';

import { groupBy } from '../../utils/helper';
import Colors from '../../theme/Colors';

const DiagramStatistics = (props) => {
  const { t } = useTranslation();
  const { timeBasedData } = props;

  const deleteIndexes = [];
  // switch all different date entries to the same date (start of day)
  for (let i = 0; i < timeBasedData.length; i++) {
    timeBasedData[i].date = moment.unix(timeBasedData[i].date / 1000)
    .startOf('day')
    .valueOf();

    const findDuplicate = timeBasedData.findIndex((item, index) => index !== i && item.streamer.login === timeBasedData[i].streamer.login && timeBasedData[i].date === item.date);
    if (findDuplicate !== -1) {
      timeBasedData[i].seq += timeBasedData[findDuplicate].seq;
      deleteIndexes.push(findDuplicate);
    }
  }

  deleteIndexes.sort((a, b) => (b - a));
  for (const dIdx of deleteIndexes)
    timeBasedData.splice(dIdx, 1);

  const timeData = groupBy(timeBasedData, 'date');

  const daysCount = 14;
  const series = [];
  const lastDays = [];
  const categories = [];

  let startDate = moment()
  .subtract(daysCount, 'days')
  .hour(1)
  .minute(0)
  .second(0)
  .millisecond(0);

  for (let i = 0; i < daysCount; i++) {
    let foundDate = false;
    startDate.add(1, 'days');
    for (const dateProp in timeData) {
      if (Object.prototype.hasOwnProperty.call(timeData, dateProp)) {
        if (moment.unix(dateProp.slice(0, -3))
        .isSame(startDate, 'day')) {
          lastDays.push(timeData[dateProp.valueOf()]);
          foundDate = true;
          break;
        }
      }
    }

    if (!foundDate) {
      lastDays.push([]);
    }

    categories.push(startDate.format('DD. MMM'));
  }

  const streamers = {};
  for (let i = 0; i < lastDays.length; i++) {
    if (lastDays[i].length > 0) {
      for (let x = 0; x < lastDays[i].length; x++) {
        const streamerObj = lastDays[i][x].streamer;

        if (!streamers.hasOwnProperty(streamerObj.login)) {
          streamers[streamerObj.login] = streamerObj;
        }
      }
    }
  }
  const streamerArr = Object.values(streamers);
  streamerArr.sort((a, b) => a.display_name.localeCompare(b.display_name));

  let colors;
  if (streamerArr.length > 42) {
    let i = 1;
    colors = distinctColors({
      count: 42,
    });

    while (streamerArr.length > 42 * i) {
      colors = colors.concat(distinctColors({
        count: 42,
      }));
      i++;
    }
  } else {
    colors = distinctColors({
      count: streamerArr.length,
    }); // You may pass an optional config object
  }

  colors = colors.map(color => {
    const tmpColors = color._rgb._unclipped.map(value => Math.round(value));
    return `rgba(${tmpColors})`;
  });

  for (let i = 0; i < streamerArr.length; i++) {
    const data = [];
    const startDate = moment()
    .subtract(daysCount, 'days')
    .hour(1)
    .minute(0)
    .second(0)
    .millisecond(0);
    for (let x = 0; x < lastDays.length; x++) {
      startDate.add(1, 'days');
      let value = 0;
      if (lastDays[x].length > 0) {
        const found = lastDays[x].find(element => element.streamer._id === streamerArr[i]._id);
        if (found) value = found.seq;
      }

      data.push(value);
    }

    const dataObj = {
      name: streamerArr[i].display_name,
      data,
    };

    series.push(dataObj);
  }
  const mainDiagramColor = '#616161';

  const options = {
    chart: {
      type: 'bar',
      height: 300,
      stacked: true,
      toolbar: {
        show: false,
      },
      background: Colors.containerBGColor,
      zoom: {
        enabled: true,
      },
    },
    responsive: [{
      breakpoint: 680,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0,
        },
      },
    }],
    colors,
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: mainDiagramColor,
        },
      },
    },
    xaxis: {
      categories: categories,
      labels: {
        rotate: -45,
        trim: true,
        style: {
          colors: mainDiagramColor,
          cssClass: {
            color: Colors.primary,
          },
        },
      },
      axisBorder: {
        color: mainDiagramColor,
      },
      axisTicks: {
        color: mainDiagramColor,
      },
    },
    grid: {
      borderColor: '#2b2c2e',
    },
    legend: {
      position: 'right',
    },
    theme: {
      mode: 'dark',
    },
    fill: {
      opacity: 1,
    },
  };

  // fix error, if viewer watched one streamer only
  if (series.length === 1) {
    series.push({
      name: '',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    });
    colors.push('#292E34');
  }

  return (
    <>
      {timeBasedData && timeBasedData.length > 0 ?
        <Chart options={options} series={series} type="bar" height={500} />
        :
        <div style={{ color: Colors.white }}>{t('no_data_available')}</div>
      }
    </>
  );
};

export default DiagramStatistics;