import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import HRNumbers from 'human-readable-numbers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { replaceWidthAndHeight } from '../../utils/helper';
import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import TwitchButton from '../Button/TwitchButton';

const useStyles = makeStyles({
  container: {
    margin: '10px 0',
    padding: 16,
    width: 418,
    cursor: 'pointer',
    background: '#051329',
    borderRadius: 20,
    display: 'inline-block',
    transition: '0.3s',
    textDecoration: 'none',
    outline: 'none',
    '&:hover': {
      background: Colors.containerHoverBGColor,
    },
  },
  containerImage: {
    margin: '10px 0',
  },
  innerContainer: {
    ...Helpers.fillRowCross,
  },
  avatar: {
    display: 'inline-block',
    borderColor: Colors.borderColor,
    backgroundColor: Colors.streamAvatarBorder,
    borderWidth: 2,
    zIndex: 2,
    width: 80,
    height: 80,
    marginRight: 20,
  },
  title: {
    fontSize: 34,
    fontFamily: 'Bebas Neue',
    color: Colors.white,
    marginBottom: 5,
    maxWidth: 300,
    ...Helpers.textLeft,
  },
  viewer: {
    fontSize: 11,
    fontWeight: 600,
    color: Colors.subTextColor,
    display: 'flex',
    alignItems: 'center',
  },
});

const LiveStreamsByGameItem = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { stream } = props;

  const height = 227, width = 418;

  const openTwitchLink = (event) => {
    event.preventDefault();
    window.open(`https://www.twitch.tv/${stream.streamer.login}`, '_blank');
  };

  const imgSrc = replaceWidthAndHeight(stream.thumbnail_url, width, height);

  return (
    <Link
      className={[classes.container, 'stream-item'].join(' ')}
      to={'/profile/' + encodeURI(stream.streamer.login)}
    >
      <div className={classes.innerContainer}>
        <div style={{ ...Helpers.fillRowCross }}>
          <div>
            <Avatar
              className={classes.avatar}
              alt={stream.streamer.display_name}
              src={stream.streamer.profile_image_url}
            />
          </div>
          <span className={classes.title}>{stream.streamer.display_name}</span>
        </div>
      </div>
      <img src={imgSrc} alt={stream.title} className={classes.containerImage} />
      <div>
        {stream.viewer_count !== null &&
        <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween, color: Colors.white }}>
          <div
            style={{ ...Helpers.fillRowCenter, color: '#cc1e31', fontSize: 24 }}
          >
            <FontAwesomeIcon icon={faEye} color="#cc1e31" style={{ marginRight: 5 }} />
            {HRNumbers.toHumanString(stream.viewer_count)}
          </div>
          <TwitchButton onClick={openTwitchLink} text={t('stream_watch_now')} />
        </div>
        }
      </div>
    </Link>
  );
};

export default withRouter(LiveStreamsByGameItem);