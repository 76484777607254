import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { getGlobalLeaderboard } from '../../apollo/mergedQueries';

import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import StreamerRanking from '../../components/Ranking/StreamerRanking';

const StreamerRankingContainer = () => {
  const [page, setPage] = useState(2);
  const lastSeason = 2;
  const [season, setSeason] = useState(lastSeason);
  const perPage = 25;

  const { data, loading, fetchMore } = useQuery(getGlobalLeaderboard, {
    variables: { pagination: { perPage, page: 1 }, season: season === lastSeason ? null : season },
    fetchPolicy: useCacheWithExpiration('getGlobalLeaderboard'),
  });

  const seasonCommunities = data && data.getSeasonStreamerLeaderboard;
  const count = data && data.getSeasonStreamerLeaderboardCount;
  const currentSeason = data && data.getCurrentSeason;
  const myViewer = data && data.getMyViewer;

  return (
    <StreamerRanking
      seasonCommunities={seasonCommunities}
      currentSeason={currentSeason}
      season={season}
      setSeason={setSeason}
      totalCount={data && count}
      loading={loading}
      myViewer={myViewer}
      onLoadMore={async () => {
        try {
          await fetchMore({
            variables: { pagination: { perPage, page }, season: season === lastSeason ? null : season },
            updateQuery: (prev, { fetchMoreResult }) => {
              setPage(page + 1);
              if (!fetchMoreResult) return prev;
              return Object.assign({}, prev, {
                getSeasonStreamerLeaderboard: [...prev.getSeasonStreamerLeaderboard, ...fetchMoreResult.getSeasonStreamerLeaderboard],
              });
            },
          });
        } catch {
        }
      }}
    />
  );
};

export default StreamerRankingContainer;