import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { StickyContainer } from 'react-sticky';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import Loading from '../Loading/Loading';
import CardShopCard from './CardShopCard';
import CardShopMatchFilters from './CardShopMatchFilters';
import CardShopBreadcrumbs from '../Breadcrumbs/CardShopBreadcrumbs';
import CardShopOfferItem from './CardShopOfferItem';
import LoadMore from '../Loading/LoadMore';
import CardShopMatchLineItems from './CardShopMatchLineItems';

import conf from '../../conf.json';

const useStyles = makeStyles((theme) => ({
  header: {
    background: `url("${conf.cdn_url}/assets/template/shop/card_shop_header_bg.png")`,
    height: 'calc(327px - 64px)',
    width: 'calc(100% - 64px)',
    backgroundSize: 'cover',
    ...Helpers.fillColMain,
    padding: 32,
  },
  innerWrapper: {
    maxWidth: 1440,
    margin: '64px auto',
  },
  iconArea: {
    transition: 'all 0.3s ease-out',
    ...Helpers.insetZero,
    zIndex: -1,
    filter: 'blur(6px)',
    background: '#011024',
    opacity: 0.12,
  },
  streamerItem: {
    background: 'rgba(255, 255, 255, 0.04)',
    width: 'calc(100% - 927px - 64px)',
    padding: '118px 32px',
    ...Helpers.fillRowCross,
    marginRight: 3,
    position: 'relative',
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 16px)',
      padding: 8,
    },
  },
  avatar: {
    border: 'none',
    height: 121,
    width: 121,
    marginRight: 22,

    [theme.breakpoints.down('md')]: {
      height: 80,
      width: 80,
      marginTop: 30,
    },
  },
  specialTextArea: {
    fontSize: 24,
    '& span': {
      fontWeight: 600,
    },

    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      marginTop: 30,
    },
  },
  gridArea: {
    display: 'grid',
    gap: '26px 8px',
    gridTemplateColumns: 'repeat(4, 1fr)',

    [theme.breakpoints.down('md')]: {
      ...Helpers.fillColCenter,
    },
  }
}));

const CardShopMatch = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    cards,
    streamer,
    offer,
    loading,
    onLoadMore,
    totalCount,
    isLegacy, setIsLegacy,
    showPurchasedCards, setShowPurchasedCards,
    sorting, setSorting,
    quality, setQuality,
    rarity, setRarity,
    isSeasonal, setIsSeasonal,
  } = props;

  return (
    <div style={{ color: Colors.white, position: 'relative', overflow: 'hidden' }}>
      {streamer &&
      <CardShopBreadcrumbs streamer={streamer} />
      }

      <div style={{ ...Helpers.fillRow, position: 'relative' }}>
        <div className={classes.streamerItem}>
          {streamer ?
            <>
              <div style={{
                backgroundImage: `url("${streamer.profile_image_url}")`,
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                mixBlendMode: 'luminosity',
                position: 'absolute',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100%',
                backgroundPosition: 'center',
                opacity: 0.12,
                filter: 'blur(8px)',
              }} />

              <Avatar
                className={classes.avatar}
                alt="Avatar"
                src={streamer.profile_image_url}
              />
              <div>
                <div className={classes.specialTextArea}
                     dangerouslySetInnerHTML={{ __html: t('you_can_support', { streamer: streamer.display_name }) }} />
                <div style={{ fontSize: 14, color: '#1FE325', marginTop: 9 }}>
                  <i className="fa-light fa-circle-check"
                     style={{ fontSize: 16, marginRight: 8 }} />
                  {t('every_card_purchase_supports_streamer')}
                </div>
              </div>
            </>
            :
            <div style={{ height: 121, width: '100%' }} />
          }
        </div>
        <CardShopMatchLineItems streamer={streamer} />
      </div>

      <StickyContainer>

        <CardShopMatchFilters
          setIsLegacy={setIsLegacy}
          isLegacy={isLegacy}
          setShowPurchasedCards={setShowPurchasedCards}
          showPurchasedCards={showPurchasedCards}
          setSorting={setSorting}
          sorting={sorting}
          setRarity={setRarity}
          rarity={rarity}
          setQuality={setQuality}
          quality={quality}
          setIsSeasonal={setIsSeasonal}
          isSeasonal={isSeasonal}
        />

        <div className="inner-wrapper" style={{ margin: '64px auto' }}>
          {loading ?
            <Loading />
            :
            <>

              {offer && offer.map(offer => (
                <CardShopOfferItem offer={offer} streamer={streamer} t={t} />
              ))}

              <div className={classes.gridArea}>
                {cards && cards.map((card, index) => (
                  <CardShopCard
                    key={card._id}
                    card={{
                      ...card,
                      card_template_metadata: { match: streamer, matchModel: 'Streamer' },
                    }}
                    switchLastHover={(index + 1) % 4 === 0}
                    isLegacy={isLegacy}
                    t={t}
                  />
                ))}
              </div>

              <LoadMore count={cards && cards.length} totalCount={cards && totalCount}
                        loading={loading} onLoadMore={onLoadMore} />
            </>
          }
        </div>

      </StickyContainer>

    </div>
  );
};

export default CardShopMatch;