import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { zeroPad } from 'react-countdown';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { fabric } from 'fabric';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import { rangeArray } from '../../utils/helper';

const conf = require('../../conf');

const CardToolSelectNameBadge = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    canvas,
    checkIfObjectExists,
    directory,
    activeBadge,
    setNameBadge,
    nameBadgePositions,
    checkTextWidthLength,
    myViewer,
    setCanvasLoading,
    checkSortingOfObjects,
    fitSizeOfCardDesign,
    cuttedSize,
  } = props;

  const updateCard = async (badge) => {
    const nameBadge = checkIfObjectExists('name-badge');
    setCanvasLoading(true);
    setNameBadge(badge);
    canvas.remove(nameBadge);
    const url = `${directory}/02-namebadge/${zeroPad(badge)}.png`;

    await fabric.Image.fromURL(url, (badgeObj) => {
      badgeObj.id = 'name-badge';
      badgeObj.selectable = false;
      badgeObj.width = cuttedSize.width;
      badgeObj.height = cuttedSize.height;
      fitSizeOfCardDesign(badgeObj);
      canvas.add(badgeObj);

      const userText = checkIfObjectExists('user-text');
      if (nameBadgePositions[badge].noText) {
        canvas.remove(userText);
      } else {
        if (userText) {
          userText.left = nameBadgePositions[badge].left;
          userText.top = nameBadgePositions[badge].top;
          userText.textAlign = nameBadgePositions[badge].textAlign;
          checkTextWidthLength(userText, badge);
        } else {
          const text = new fabric.Text(myViewer.display_name, {
            id: 'user-text',
            fontFamily: 'Oswald',
            fill: Colors.white,
            left: nameBadgePositions[badge].left,
            selectable: false,
            textAlign: nameBadgePositions[badge].textAlign,
          });
          canvas.add(text);
          checkTextWidthLength(text, badge);
        }
      }

      checkSortingOfObjects();
    }, {
      crossOrigin: 'Anonymous',
      cropX: 35,
      cropY: 35,
    });
  };

  const badges = rangeArray(0, 6)
  .map(lvl => {
    return {
      name: t(`badge_text_${lvl}`),
    };
  });

  return (
    <>
      <div className={classes.wrapper}>
        <div style={{ width: 130, ...Helpers.textLeft }}>
          {t('preview')}
        </div>
        <div>
          <img
            src={`${directory.replace(conf.no_cors_cdn_url, conf.cdn_url)}/02-namebadge/${zeroPad(activeBadge)}.png?height=120`}
            style={{ margin: '-10px 0', height: 120 }}
            alt={`NameBadge ${activeBadge}`}
          />
        </div>
      </div>
      <div className={classes.wrapper}>
        <div style={{ width: 130, ...Helpers.textLeft }}>
          {t('badge_position')}
        </div>
        <div>
          <FormControl>
            <Select
              value={activeBadge}
              onChange={(event) => updateCard(event.target.value)}
              disableUnderline
              classes={{
                root: classes.textInput,
                icon: classes.textSelectIcon,
              }}
            >
              {badges.map((badge, index) =>
                <MenuItem value={index} key={`BadgeKey${index}`}>{badge.name}</MenuItem>,
              )}
            </Select>
          </FormControl>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    marginBottom: 10,
    color: '#747474',
  },
  textInput: {
    backgroundColor: 'transparent',
    padding: 10,
    borderRadius: 4,
    textAlign: 'left',
    border: '1px solid #cc1e31',
    width: 247,
  },
  textSelectIcon: {
    fill: '#cc1e31',
    right: 10,
    top: '50%',
    transform: 'translate(0, -50%)',
  },
}));

export default CardToolSelectNameBadge;