import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';
import { getMatchData } from '../../utils/helper';
import { setInventoryGroup } from '../../redux/actions';
import RarityBadges from './RarityBadges';

import conf from '../../conf.json';

const useStyles = makeStyles((theme) => ({
  itemArea: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    width: 250,
    cursor: 'pointer',
    position: 'relative',
    padding: 12,
    transition: 'all 0.3s ease-out',
    ...Helpers.fillColCenter,
    height: 450,
    '&:hover': {
      '& $matchArea': {
        opacity: 0.24,
      },
    },
    '&.hasSpecialEvent': {
      animation: '$pulse 2s infinite',
    },

    [theme.breakpoints.down('md')]: {
      height: 'fit-content',
      paddingBottom: 80,
    },
  },
  '@keyframes pulse': {
    '0%, 100%': {
      boxShadow: 'inset 0px 0px 46px #FF0068',
    },
    '50%': {
      boxShadow: 'inset 0px 0px 10px #FF0068',
    },
  },
  midAvatar: {
    width: 94,
    height: 94,
    border: 'none',
  },
  countArea: {
    width: 88,
    height: 31,
    marginTop: 14,
    background: 'rgba(255, 255, 255, 0.06)',
    fontSize: 12,
    fontWeight: 500,
    ...Helpers.fillColCenter,
    borderRadius: 15,
  },
  badge: {
    width: 58,
    height: 58,
    background: 'rgba(255, 255, 255, 0.04)',
    ...Helpers.fillColCenter,
    borderRadius: '50%',
  },
  badges: {
    alignSelf: 'flex-end',
    display: 'grid',
    gap: 6,
    gridTemplateColumns: 'repeat(4, 1fr)',
    position: 'absolute',
    bottom: 12,
    left: 12,
    right: 12,
  },
  matchArea: {
    transition: 'all 0.3s ease-out',
    ...Helpers.insetZero,
    zIndex: -1,
    filter: 'blur(6px)',
    background: '#011024',
    opacity: 0.12,
  },
}));

const GroupedInventoryStreamer = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { data, isBigger, isCardShop } = props;

  const {
    avatar,
    name,
  } = getMatchData({ match: data.streamer, matchModel: 'Streamer' }, 94, 94);

  const groupId = data.streamer ? data.streamer._id : conf.sh_id;
  const count = isCardShop ? 5 : 4;

  const onClick = () => {
    if (isCardShop) {
      props.history.push(`/card-shop/streamer/${groupId}`);
    } else {
      props.setInventoryGroup({
        name,
        count: data.card_count,
      });
      props.history.push(`/inventory/streamer/${groupId}`);
    }
  };

  return (
    <div
      className={[classes.itemArea, data.is_event ? 'hasSpecialEvent' : ''].join(' ')}
      onClick={onClick}
      style={isBigger ? { width: 330 } : {}}
    >
      <div className={classes.matchArea}>
        <div style={{
          ...Helpers.insetZero,
          backgroundImage: `url("${avatar}")`,
          mixBlendMode: 'luminosity',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }} />
      </div>

      {data.is_event &&
      <div style={{
        position: 'absolute',
        top: 20,
        left: '50%',
        textTransform: 'uppercase',
        transform: 'translate(-50%, 0)',
        background: '#FF0068',
        padding: '8px 10px',
        fontSize: 12,
        borderRadius: 4,
      }}>
        {t('special_event')}
      </div>
      }

      <Avatar
        className={classes.midAvatar}
        alt="Avatar"
        src={avatar}
      />
      <div style={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: 225,
        marginTop: 16,
        ...Helpers.textCenter,
      }}>
        {name}
      </div>
      <div className={classes.countArea}>
        {data.card_count}
      </div>
      <RarityBadges areMore={isCardShop} count={count} data={data} />
    </div>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({ setInventoryGroup }, dispatch)
);

export default connect(null, mapDispatchToProps)(withRouter(GroupedInventoryStreamer));