import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { Html, useProgress, Backdrop } from '@react-three/drei';

const Loader = () => {
  const { progress } = useProgress();
  return <Html center style={{ color: 'white' }}>{progress.toFixed(0)}% loaded</Html>;
};

const ThreeDWrapper = (props) => {
  // frameloop demand ==> It will render frames whenever it detects prop changes throughout the component tree.
  const { frameLoop, noBackdrop, startVideo, withVideo, zoom = 5 } = props;
  const { progress, total } = useProgress();
  if (withVideo && progress === 100 && total > 10)
    startVideo();

  return (
    <Canvas frameloop={frameLoop} camera={{ position: [0, 0, zoom] }}>
      <Suspense fallback={<Loader />}>
        {props.children}
      </Suspense>
      {!noBackdrop &&
      <Backdrop castShadow floor={2} position={[0, -4, -3]} scale={[50, 15, 4]}>
        <meshStandardMaterial color="#353540" envMapIntensity={0.1} />
      </Backdrop>
      }
    </Canvas>
  );
};

export default ThreeDWrapper;