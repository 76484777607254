import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { useMediaQuery } from 'react-responsive';

import { getTopStreamers } from '../../apollo/queries';

import PageTitle from '../../components/PageTitle/PageTitle';
import TopViewHeader from '../../components/TopViewHeader/TopViewHeader';
import Loading from '../../components/Loading/Loading';
import ErrorMessage from '../../components/ErrorBoundary/ErrorMessage';
import { isTabletOrMobileObj } from '../../utils/responsive';
import TopStreamer from '../../components/TopStreamer/TopStreamer';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

const TopStreamerContainer = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const perPage = 8;

  const isTabletOrMobile = useMediaQuery(isTabletOrMobileObj);

  const { data, loading, error, fetchMore } = useQuery(getTopStreamers, {
    variables: { pagination: { perPage, page: 1 } },
    fetchPolicy: useCacheWithExpiration('getTopStreamers'),
  });
  const topStreamer = (data && data.getTopStreamers) && data.getTopStreamers;

  return (
    <>
      <PageTitle title={t('top_streamer')} />
      <TopViewHeader>
        <div style={{ maxWidth: 1440, margin: '0 auto', padding: '20px 0' }}>
          {error && <ErrorMessage error={error} />}
          {loading ?
            <Loading />
            :
            <TopStreamer
              topStreamer={topStreamer}
              isTabletOrMobile={isTabletOrMobile}
              hasMore={(topStreamer) && topStreamer.length < 80}
              onLoadMore={async () => {
                try {
                  await fetchMore({
                    variables: {
                      pagination: {
                        perPage,
                        page: page + 1,
                      },
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev;
                      setPage(page + 1);
                      return Object.assign({}, prev, {
                        getTopStreamers: [...prev.getTopStreamers, ...fetchMoreResult.getTopStreamers],
                      });
                    },
                  });
                } catch {
                }
              }}
            />
          }
        </div>
      </TopViewHeader>
    </>
  );
};

export default TopStreamerContainer;