import React from 'react';
import { Query } from 'react-apollo';
import { makeStyles } from '@material-ui/core';
import { InlineWidget } from 'react-calendly';
import { useTranslation } from 'react-i18next';

import { getMyViewer } from '../../apollo/queries';

import Loading from '../Loading/Loading';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import ErrorMessage from '../ErrorBoundary/ErrorMessage';

const useStyles = makeStyles(theme => ({
  heroImage: {
    marginTop: -30,
    marginBottom: -190,
    width: 500,
  },
  textWrapper: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    ...Helpers.textCenter,
    color: Colors.white,
  },
}));

const DashboardCalendly = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Query query={getMyViewer}>
      {({ data, loading, error }) => {
        if (error) return <ErrorMessage error={error} />;
        if (loading) return <Loading />;

        const myViewer = (data) && data.getMyViewer;

        return (
          (myViewer) &&
          <div style={{ marginBottom: 30 }}>
            <div className={classes.textWrapper}>
              <img
                className={classes.heroImage}
                src={process.env.PUBLIC_URL + '/icons-png/superhero.png'}
                alt="Superhero"
              />
              <div style={{ color: '#ffc826', fontSize: 30 }}>
                {t('schedule_calendly_congratulations')}
              </div>
              <div style={{ fontSize: 15, marginBottom: 20 }}>
                {t('schedule_calendly_twitch_partner_text')}
              </div>
              <div style={{ color: '#9d56e2', fontSize: 30, marginBottom: -50 }}>
                {t('schedule_calendly_choose_a_date')}
              </div>
            </div>
            <InlineWidget
              pageSettings={{
                backgroundColor: '292E34',
                hideEventTypeDetails: false,
                hideLandingPageDetails: false,
                primaryColor: 'CE1537',
                textColor: 'ffffff',
                borderRadius: 10,
              }}
              prefill={{
                email: myViewer.email ? myViewer.email : '',
                name: myViewer.display_name ? myViewer.display_name : '',
              }}
              url="https://calendly.com/sebastian-ka/streamheroes-introduction"
            />
          </div>
        );
      }}
    </Query>
  );
};

export default DashboardCalendly;