import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';
import ProfileOverviewRecentActivities from './ProfileOverviewRecentActivities';
import ProfileOverviewRanks from './ProfileOverviewRanks';
import { multiplyEnergyCoins } from '../../utils/helper';

const useStyles = makeStyles((theme) => ({
  itemBox: {
    background: 'rgba(255, 255, 255, 0.04)',
    padding: '16px 20px',
    borderRadius: 6,
    width: 340,
    '& > div:first-child': {
      marginBottom: 10,
    },
  },
  statsBox: {
    background: 'rgba(255, 255, 255, 0.02)',
    borderRadius: 6,
    padding: 18,
    ...Helpers.fillColCenter,
    marginTop: 6,
  },
  smallText: {
    fontSize: 12,
    color: 'rgba(255, 255, 255, 0.5)',
  },
  bigText: {
    fontSize: 22,
    fontWeight: 500,
    marginTop: 10,
    marginBottom: 2,
    lineHeight: 1,
  },
  container: {
    ...Helpers.fillRow,
    width: '100%',
    '& > div:first-child': {
      marginRight: 19,
    },
    '& > div:last-child': {
      marginLeft: 19,
      width: '100%',
    },

    [theme.breakpoints.down('md')]: {
      ...Helpers.fillCol,

      '& > div': {
        margin: '0 !important',
      }
    },
  },
}));

const ProfileOverview = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    viewer,
    isMe,
    activities,
    stats,
    setActivitiesPrivacySettings,
    activePrivacySettings,
  } = props;

  return (
    <div className={classes.container}>
      <div>
        <div style={{ position: 'sticky', top: 100 }}>
          <div className={classes.itemBox}>
            <div>{t('top_3_ranks')}</div>

            <ProfileOverviewRanks viewer={viewer} limit={3} />
          </div>

          <div className={classes.itemBox} style={{ marginTop: 8 }}>
            <div>{t('viewer_statistics')}</div>

            <div className={classes.statsBox}>
              <div className={classes.smallText}>
                {t('viewer_kpi_global_ranking')}
              </div>
              <div className={classes.bigText}>
                {((stats ? stats.position : 0)).toLocaleString()}
              </div>
            </div>

            <div className={classes.statsBox}>
              <div className={classes.smallText}>
                {t('viewer_kpi_collected_coins')}
              </div>
              <div className={classes.bigText}>
                {(stats ? multiplyEnergyCoins(stats.gathered_energy) : 0).toLocaleString()}
              </div>
            </div>

            <div className={classes.statsBox}>
              <div className={classes.smallText}>
                {t('viewer_kpi_booster_redeemed')}
              </div>
              <div className={classes.bigText}>
                {(stats ? stats.redeemed_booster : 0).toLocaleString()}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <ProfileOverviewRecentActivities
          viewer={viewer}
          isMe={isMe}
          activities={activities}
          activePrivacySettings={activePrivacySettings}
          setActivitiesPrivacySettings={setActivitiesPrivacySettings}
        />
      </div>
    </div>
  );
};

export default ProfileOverview;