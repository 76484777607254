import React from 'react';
import { withRouter } from 'react-router-dom';
import Div100vh from 'react-div-100vh';
import { IconButton } from '@material-ui/core';
import { faArrowLeft, faQuestionCircle, faSlash, faWifi } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { ConnectivityStatus } from '@prismadelabs/prismaid';
import { bindActionCreators } from 'redux';

import OrientationLock from './OrientationLock';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import SwipeField from './SwipeField';
import { useBodyScrollLock } from './hooks/useBodyScrollLock';
import { useInvocationCheck } from './hooks/useInvocationCheck';
import { changeCardVerification } from '../../redux/actions';

/**
 * On this page all components are displayed to check the card and with the swipe method
 * the card is checked
 *
 * @param props
 */
const SwipeVerification = (props) => {
  const classes = useStyles();
  const { history, swipeVerification, cardVerification, changeCardVerification } = props;
  const { scaleFactor, progress, swipeResults, networkStatus } = swipeVerification;
  const { invocationIsValid } = cardVerification;

  useInvocationCheck(history, invocationIsValid, changeCardVerification);
  useBodyScrollLock();

  return (
    <OrientationLock>
      <div>
        <Helmet>
          <meta
            name="viewport"
            content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />

          <meta name="format-detection" content="telephone=no" />
          <meta name="msapplication-tap-highlight" content="no" />

          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-title" content="SHCheck" />
          <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        </Helmet>

        <Div100vh style={{ width: '100vw', ...Helpers.fillCol, backgroundColor: Colors.white }}>
          <div>
            <div className={classes.swipeMessageArea}>
              {swipeResults.map((result, index) => (
                <div className={classes.swipeMessage} key={`SwipeMessageKey${index}`}>
                  <div
                    className={classes.swipeMessageText}
                    style={{
                      fontSize: '1.25rem',
                      fontWeight: 'bold',
                    }}
                  >
                    {result.title}
                  </div>
                  <div className={classes.swipeMessageText}>
                    {result.message}
                  </div>
                </div>
              ))}
            </div>

            <SwipeField scaleFactor={scaleFactor} />

            <div
              className={classes.progressBar}
              style={{ height: `${progress > 5 ? progress : 0}%` }}
            />

            {(networkStatus !== ConnectivityStatus.ok) &&
            <div className={classes.wifiIcon}>
              <FontAwesomeIcon
                icon={networkStatus === ConnectivityStatus.slow ? faWifi : faSlash}
                color={networkStatus === ConnectivityStatus.slow ? 'orange' : 'red'}
                size="lg"
              />
            </div>
            }
          </div>
          <div className={classes.iconsRow}>
            <IconButton onClick={() => history.push('/verification/start')}>
              <FontAwesomeIcon icon={faArrowLeft} size="lg" />
            </IconButton>
            <IconButton onClick={() => history.push('/verification/faq')}>
              <FontAwesomeIcon icon={faQuestionCircle} size="lg" />
            </IconButton>
          </div>
        </Div100vh>

      </div>
    </OrientationLock>
  );
};

const useStyles = makeStyles(() => ({
  swipeMessageArea: {
    position: 'relative',
    width: '100vw',
    touchAction: 'none',
    pointerEvents: 'none',
  },
  swipeMessage: {
    backgroundColor: Colors.white,
    width: 'calc(100vw - 4rem)',
    position: 'absolute',
    top: 0,
    paddingLeft: '2rem',
    paddingRight: '2rem',
    marginTop: '1rem',
  },
  swipeMessageText: {
    fontSize: '1.125rem',
    lineHeight: '1.75rem',
    ...Helpers.textCenter,
    whiteSpace: 'pre-wrap',
  },
  progressBar: {
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    width: '100%',
    overflow: 'hidden',
    opacity: 0.75,
    touchAction: 'none',
    pointerEvents: 'none',
    background: 'green',
  },
  wifiIcon: {
    position: 'absolute',
    top: 15,
    right: 50,
    touchAction: 'none',
    pointerEvents: 'none',
  },
  iconsRow: {
    position: 'absolute',
    top: 0,
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    width: '100vw',
  },
}));

const mapStateToProps = (state) => {
  const { swipeVerification, cardVerification } = state;
  return { swipeVerification, cardVerification };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    changeCardVerification,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SwipeVerification));