import React from 'react';
import { withRouter } from 'react-router-dom';

import Colors from '../../theme/Colors';
import AnimationButton from '../Button/AnimationButton';

const ToastContent = (props) => {
  const {
    title,
    text,
    titleColor = '#1FE325',
    subTitleColor = Colors.halfWhite,
    ctaText = null,
    ctaLink = null,
  } = props;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginRight: 14,
        gap: 14,
      }}
    >
      <div style={{ flexGrow: 1 }}>
        <p style={{ color: titleColor, fontSize: 14, margin: '0 0 2px 0', fontWeight: 500 }}>
          {title}
        </p>

        <p style={{ margin: 0, fontSize: 12, color: subTitleColor }}>
          {text}
        </p>
      </div>

      {(ctaLink && ctaText) &&
      <AnimationButton
        status="whiteTransparency"
        onClick={() => props.history.push(ctaLink)}
        style={{
          padding: '6px 14px',
          fontSize: 12,
          borderRadius: 3,
        }}
      >
        {ctaText}
      </AnimationButton>
      }
    </div>
  );
};

export default withRouter(ToastContent);