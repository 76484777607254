import { CHANGE_CARD_VERIFICATION } from './constants';

const INITIAL_STATE = {
  invocationIsValid: false,
  deviceIsSupported: true,
  browserIsSupported: true,
  screenIsBigEnough: true,
  needsTouchSensitivity: false,
  touchSensitivityIsSet: false,
  errorCode: null,
};

const verificationReducer = (state = INITIAL_STATE, action) => {
  if (action.type === CHANGE_CARD_VERIFICATION) {
    return { ...state, ...action.update };
  } else {
    return state;
  }
};

export default verificationReducer;