import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Sticky } from 'react-sticky';

import Helpers from '../../theme/Helpers';
import AllSideFilterItem from './AllSideFilterItem';
import QualityFilter from '../Filter/QualityFilter';
import RarenessFilter from '../Filter/RarenessFilter';

const useStyles = makeStyles({
  container: {
    position: 'absolute',
    transition: 'width 0.6s ease-out',
    '& div': {
      transition: 'width 0.6s ease-out',
    },
  },
  button: {
    height: 18,
    ...Helpers.fillRowCross,
    background: '#FFFFFF14',
    padding: '11px 18px',
    fontFamily: 'Montserrat',
    fontSize: 16,
    borderRadius: 6,
    flex: 1,
    cursor: 'pointer',
    '&:hover': {
      background: '#FFFFFF3D',
    },
  },
  smallButton: {
    padding: '11px 18px',
    width: 'auto',
  },
});

const AllSideFilters = (props) => {
  const classes = useStyles();
  const {
    t,
    isActive, setIsActive,
    defaultChecked,
    checked, setChecked,
    quality, setQuality,
    rareness, setRareness,
    isLegacy, setIsLegacy,
    hasUserLegacyCards,
    streamers,
    organisations,
    games,
    generic,
    match, setMatch,
    showOnlyLive,
    setShowOnlyLive,
    type,
    setType,
    capsulesFilters,
  } = props;


  const handleChange = (type, isChecked) => {
    const tmpChecked = { ...checked };
    tmpChecked[type] = isChecked;
    setChecked(tmpChecked);
  };

  return (
    <Sticky topOffset={-85}>
      {({ style, isSticky }) =>
        <div className={classes.container}
             style={{ ...style, width: isActive ? 274 : 0, marginTop: isSticky ? 85 : 0 }}>
          <div
            style={{
              ...Helpers.fillRowCenter,
              ...Helpers.mainSpaceBetween,
              marginBottom: isActive ? 4 : 0,
            }}
          >
            <div
              className={[classes.button, isActive ? '' : classes.smallButton].join(' ')}
              onClick={() => setIsActive(!isActive)}
            >
              <i className="fa-light fa-sliders-up" style={{ marginRight: 8 }} />
              {t('filter')}
            </div>
            {isActive &&
            <div
              className={classes.button}
              style={{ opacity: 0.6, marginLeft: 8 }}
              onClick={() => {
                setChecked(defaultChecked);
                if (setRareness)
                  setRareness([]);
                if (setQuality)
                  setQuality({ min: 0, max: 100 });
              }}
            >
              <i className="fa-light fa-rotate" style={{ marginRight: 8 }} />
              {t('reset')}
            </div>
            }
          </div>
          {isActive &&
          <>
            {streamers &&
            <AllSideFilterItem
              checked={checked.streamer}
              type="streamer"
              t={t}
              handleChange={handleChange}
              list={streamers}
              setMatch={setMatch}
              value={match}
            />
            }

            {capsulesFilters &&
            <AllSideFilterItem
              checked={showOnlyLive}
              type="legacy"
              definedString={t('show_only_live')}
              t={t}
              handleChange={() => setShowOnlyLive(!showOnlyLive)}
              isLegacy
            />
            }

            {!isLegacy &&
            <>
              <QualityFilter
                checked={checked.quality}
                type="quality"
                t={t}
                value={quality}
                handleChange={handleChange}
                setQuality={setQuality}
              />

              <RarenessFilter
                checked={checked.rareness}
                type="rareness"
                t={t}
                value={rareness}
                handleChange={handleChange}
                setRareness={setRareness}
              />
            </>
            }

            {games &&
            <AllSideFilterItem
              checked={checked.game}
              type="game"
              t={t}
              handleChange={handleChange}
              list={games}
              setMatch={setMatch}
              value={match}
            />
            }
            {generic &&
            <AllSideFilterItem
              checked={checked.sh}
              type="sh"
              t={t}
              handleChange={handleChange}
              list={generic}
              setMatch={setMatch}
              value={match}
            />
            }

            {hasUserLegacyCards &&
            <AllSideFilterItem
              checked={isLegacy}
              type="legacy"
              t={t}
              handleChange={(_, checked) => setIsLegacy(checked)}
            />
            }
          </>
          }
        </div>
      }
    </Sticky>
  );
};

export default AllSideFilters;