import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { getCountdownMessage } from '../../apollo/queries';

import CountdownPage from '../../components/Countdown/CountdownPage';

const CountdownContainer = () => {
  const { data, loading } = useQuery(getCountdownMessage, {
    pollInterval: 60 * 1000,
  });

  const messages = data && data.getCountdownMessage;

  return (
    <CountdownPage loading={loading} messages={messages} />
  );
};

export default CountdownContainer;