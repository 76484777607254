import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { StickyContainer } from 'react-sticky';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import HoverIcon from '../Icon/HoverIcon';
import TopBarStorageBox from '../Menu/TopBarStorageBox';
import Loading from '../Loading/Loading';
import InventoryCards from './InventoryCards';
import CardShopMatchFilters from '../CardShop/CardShopMatchFilters';
import AnimationButton from '../Button/AnimationButton';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SlideInOut from '../Layout/SlideInOut';
import { bindActionCreators } from 'redux';
import { resetStorageBoxData } from '../../redux/actions';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 377,
    right: 0,
    bottom: 0,
    background: '#011024',
    color: Colors.white,
    zIndex: 129999,
    animationName: '$popup',
    animationDuration: '0.3s',
  },
  '@keyframes popup': {
    '0%': {
      left: '100%',
    },
    '100%': {
      left: 377,
    },
  },
  topArea: {
    background: 'rgba(255, 255, 255, 0.04)',
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    height: 62,
  },
  badge: {
    marginLeft: 16,
    background: 'rgba(255, 255, 255, 0.06)',
    borderRadius: 15,
    padding: '8px 18px',
    fontSize: 12,
    fontWeight: 500,
    '& span': {
      color: Colors.halfWhite,
    },
  },
}));

const StorageBoxCards = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    cardDecks, cardDeck, cards, loading, notMy, myViewer,
    quality, setQuality,
    rareness, setRareness,
    sorting, setSorting,
    variables,
  } = props;

  const onClose = () => {
    props.resetStorageBoxData();
  };

  return (
    <SlideInOut
      isOpen={!!cardDecks}
      additionalLeft={31}
      onClose={onClose}
      myViewer={myViewer}
    >
      <div className={classes.topArea}>
        <div style={{ ...Helpers.fillRowCenter }}>
          <HoverIcon
            icon="fa-thin fa-xmark"
            color={Colors.white}
            hoverColor={Colors.halfWhite}
            style={{ fontSize: 17, marginRight: 16, marginLeft: 28 }}
            onClick={onClose}
          />
          <div>
            {cardDeck && cardDeck.name}
          </div>
          {cardDeck &&
          <div className={classes.badge}>
            <span>{cardDeck.card_count}</span> / {cardDeck.max_cards}
          </div>
          }
        </div>

        {(!notMy && cardDecks) &&
        <TopBarStorageBox cardDecks={cardDecks} t={t} isInBox cardDeck={cardDeck} />
        }
      </div>

      <StickyContainer style={{ position: 'relative' }}>
        <CardShopMatchFilters
          setSorting={setSorting}
          sorting={sorting}
          setRarity={setRareness}
          rarity={rareness}
          setQuality={setQuality}
          quality={quality}
        />

        <div style={{
          padding: '39px 50px 0',
          height: 'calc(100vh - 175px)',
          overflow: 'auto',
        }}>
          {loading ?
            <Loading />
            :
            cards && cards.length > 0 ?
              <div style={{ marginBottom: 50 }}>
                <InventoryCards
                  totalCount={cardDeck.card_count}
                  cards={cards}
                  cardDecks={cardDecks}
                  t={t}
                  notMy={notMy}
                  variables={variables}
                />
              </div>
              :
              <div style={{ ...Helpers.fillColCenter, marginTop: 198 }}>
                <div style={{
                  width: 88,
                  height: 88,
                  borderRadius: '50%',
                  background: 'rgba(255, 255, 255, 0.09)',
                  ...Helpers.fillRowCenter,
                }}>
                  <i className="fa-thin fa-circle-xmark" style={{ fontSize: 36 }} />
                </div>
                <div
                  style={{ fontSize: 24, marginTop: 24, marginBottom: 16 }}
                  dangerouslySetInnerHTML={{ __html: t('storage_box_no_cards') }}
                />
                <div style={{
                  color: Colors.halfWhite,
                  fontSize: 14,
                  marginBottom: 32,
                  maxWidth: 572,
                  ...Helpers.textCenter,
                  lineHeight: 1.5,
                }}>
                  {t('storage_box_no_cards_desc')}
                </div>
                <AnimationButton
                  status={'neonRed'}
                  onClick={() => props.history.push('/inventory/groups')}
                  style={{
                    fontFamily: 'Montserrat',
                    fontSize: 14,
                    padding: '14px 32px',
                  }}
                >
                  {t('go_to_inventory')}
                </AnimationButton>
              </div>
          }
        </div>
      </StickyContainer>
    </SlideInOut>
  );
};

const mapStateToProps = (state) => {
  const { extra } = state;
  return { extra };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    resetStorageBoxData,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StorageBoxCards));