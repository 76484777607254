import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import PrettoSlider from '../Slider/PrettoSlider';
import Colors from '../../theme/Colors';

const useStyles = makeStyles(theme => ({
  area: {
    paddingTop: theme.spacing(3),
  },
  textField: {
    backgroundColor: '#0e0e0e',
    padding: '5px 20px',
    marginTop: 20,
    borderRadius: 5,
    border: '1px solid #931b28',
    '& input': {
      color: Colors.subTextColor,
      fontSize: 14,
      '&::placeholder': {
        color: Colors.white,
      },
    },
    '&::before, &::after': {
      display: 'none',
    },
  },
  root: {
    color: '#636363',
  },
  variableClick: {
    color: Colors.white,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
}));

const StreamConfigAlertsShoutOutSettings = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { handleChange, shoutOutsActive, shoutMsg } = props;

  const addVariable = (variable) => {
    handleChange(null, 'shoutMsg', shoutMsg + ' ' + variable);
  };

  return (
    <div className={classes.root}>
      <FormControlLabel
        labelPlacement="start"
        style={{ marginLeft: 0, marginRight: 0 }}
        control={
          <Switch
            checked={shoutOutsActive}
            onChange={() => handleChange(null, 'shoutOutsActive', !shoutOutsActive)}
            value={shoutOutsActive} />
        }
        label={t('show_shout_out_alert')}
      />
      {(shoutOutsActive) &&
      <div className={classes.area}>
        <Typography
          variant="h3"
          gutterBottom
          style={{ textTransform: 'none', color: Colors.white, fontWeight: 400 }}
        >
          {t('fit_custom_alert_msg')}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t('custom_alert_msg_variables_text')}<br />
          <span className={classes.variableClick} onClick={() => addVariable('%USER%')}>
            %USER%
          </span> = {t('custom_alert_msg_username')}
        </Typography>
        <TextField
          style={{ width: '100%' }}
          disableUnderline
          value={shoutMsg}
          InputProps={{
            classes: { root: classes.textField },
          }}
          onChange={(event) => handleChange(event, 'shoutMsg', event.target.value)}
          placeholder={t('custom_alert_shout_msg_placeholder')} />
      </div>
      }
    </div>
  );
};

export default StreamConfigAlertsShoutOutSettings;