import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ImageUploader from 'react-images-upload';
import { faImage, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import { rangeArray } from '../../utils/helper';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';

const useStyles = makeStyles(() => ({
  itemSize: {
    width: 122,
    height: 122,
    padding: 5,
  },
  basicItem: {
    backgroundColor: '#0F0F0F',
    color: '#272727',
    border: '1px solid #292929',
    borderRadius: 4,
    ...Helpers.fillRowCenter,
  },
}));

const CardToolServicesDesignerFormPictures = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { setUploadedImages, uploadedImages } = props;
  const imageUploadRef = useRef(null);

  const onDrop = async (images) => {
    if (images.length > 0) {
      setUploadedImages([...uploadedImages, ...images]);

      imageUploadRef.current.state.pictures = [];
      imageUploadRef.current.state.files = [];
    }
  };

  const removeImage = (idx) => {
    const tmpImageFiles = [...uploadedImages];
    tmpImageFiles.splice(idx, 1);
    setUploadedImages(tmpImageFiles);
  };

  const placeHolder = 10 - uploadedImages.length;

  return (
    <div className={classes.container}>
      <div style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
        {t('choose_up_10_images')}
      </div>
      <div style={{
        ...Helpers.fillRowCross,
        margin: '10px 0',
        ...Helpers.mainSpaceBetween,
        flexWrap: 'wrap',
      }}>
        {uploadedImages.map((img, index) =>
          <div
            style={{ position: 'relative' }}
            key={`PreviewUploaded${index}`}
            className={[classes.basicItem, classes.itemSize].join(' ')}
          >
            <FontAwesomeIcon
              icon={faTimes}
              size="lg"
              style={{ position: 'absolute', cursor: 'pointer', right: 10, top: 6 }}
              onClick={() => removeImage(index)}
              color={Colors.white}
            />
            <img
              src={URL.createObjectURL(img)}
              alt={`PreviewUploadedImage`}
              style={{ maxHeight: '100%', maxWidth: '100%' }}
            />
          </div>,
        )}
        {placeHolder > 0 && rangeArray(1, placeHolder)
        .map((nr) =>
          <div
            key={`PlaceholderImage${nr}`}
            className={[classes.basicItem, classes.itemSize].join(' ')}
          >
            <FontAwesomeIcon icon={faImage} size="3x" />
          </div>,
        )}
      </div>
      <div className={classes.basicItem}>
        <ImageUploader
          ref={imageUploadRef}
          withIcon={false}
          buttonText={t('choose_images')}
          onChange={onDrop}
          fileSizeError={t('file_size_error')}
          fileTypeError={t('file_extension_not_supported')}
          imgExtension={['.jpg', '.png', '.jpeg']}
          errorStyle={{ color: 'red' }}
          buttonStyles={{
            backgroundColor: '#cc1e31',
            borderRadius: 3,
            padding: '10px 20px',
            fontFamily: 'Bebas Neue',
            fontSize: 25,
          }}
          labelStyles={{
            color: '#646A75',
            fontSize: 12,
            textTransform: 'uppercase',
          }}
          fileContainerStyle={{ backgroundColor: 'transparent' }}
        />
      </div>
    </div>
  );
};

export default CardToolServicesDesignerFormPictures;