import React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import conf from '../../conf.json';

import { toast } from 'react-toastify';
import ToastContent from '../../components/Toast/ToastContent';

import {
  getRedeemableBoosters,
  getRedeemedCardObjects,
  getShopItems,
  getTypeBoostersWithMyViewer,
} from '../../apollo/mergedQueries';
import { redeemBooster as redeemBoosterMutation } from '../../apollo/mutations';

import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import ErrorMessage from '../../components/ErrorBoundary/ErrorMessage';
import MatchBoosters from '../../components/Booster/MatchBoosters';
import { togglePlayingBoosterAnimation } from '../../redux/actions';
import { detectBrowser } from '../../utils/helper';

const MatchBoostersContainer = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { matchData } = props;

  const variables = { match: matchData._id };
  const { data, loading, error } = useQuery(getTypeBoostersWithMyViewer, {
    variables,
    fetchPolicy: useCacheWithExpiration('getTypeBoostersWithMyViewer', variables),
  });

  const [redeemBooster, redeemMutationData] = useMutation(redeemBoosterMutation, {
    onCompleted: (data) => {
      const { cards, booster_animation } = data.redeemBooster;

      const toastContent = <ToastContent title={t('toasts.booster_redeemed.title')} text={t('toasts.booster_redeemed.text', { quantity: cards.length })} />

      const toastIcon = <img src={`${conf.cdn_url}/assets/streamheroes-booster-pack.png`} alt={t('toasts.booster_redeemed.title')} />
    
      /*toast(toastContent, {
        theme: "dark",
        autoClose: false,
        icon: () =>  toastIcon
      });*/

      let route = '/inventory/cards';
      if (cards && cards.length === 1)
        route = `/${booster_animation && detectBrowser() !== 'Firefox' ? 'booster-animation' : 'item-store'}?cardId=${cards[0]._id}`;

      props.togglePlayingBoosterAnimation(false);
      props.history.push(route);
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [
      { query: getTypeBoostersWithMyViewer, variables },
      { query: getRedeemableBoosters },
      {
        query: getRedeemedCardObjects, variables: {
          pagination: { page: 1, perPage: 25 },
          min_quality: 0, max_quality: 100, rarity: [],
          sorting: { field: 'created_at', order: 'DESC' },
        },
      },
      {
        query: getShopItems, variables: {
          pagination: { page: 1, perPage: 25 },
          min_quality: 0, max_quality: 100, rarity: [],
          sorting: { field: 'created_at', order: 'DESC' },
        },
      },
    ],
  });

  const redeemABooster = (id, quantity = 1) => {
    redeemBooster({
      variables: {
        id,
        quantity,
      },
    });
  };

  if (error) return <ErrorMessage error={error} />;

  const boosters = (data) && data.getTypeBoosters;
  const myViewer = (data) && data.getMyViewer;
  const redeemLoading = redeemMutationData && redeemMutationData.loading;

  return (
    <MatchBoosters boosters={boosters} loading={loading} myViewer={myViewer}
                   redeemBooster={redeemABooster} redeemLoading={redeemLoading} />
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    togglePlayingBoosterAnimation,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(withRouter(MatchBoostersContainer));