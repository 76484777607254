import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import moment from 'moment';
import { isMobile } from 'react-device-detect';

import Helpers from '../../theme/Helpers';
import PagePopup from '../Dialog/PagePopup';
import Colors from '../../theme/Colors';
import TopBarSupporter from '../Menu/TopBarSupporter';
import ClosePopupButton from '../Button/ClosePopupButton';
import SVGIcon from '../SVGIcon/SVGIcon';
import { getStringPrice } from '../../utils/helper';
import OrderPay from '../Order/OrderPay';
import GemsPay from './GemsPay';

import conf from '../../conf.json';

const useStyles = makeStyles({
  container: {
    background: '#142134',
    maxWidth: 798,
    margin: '50vh auto 0',
    transform: 'translate(0, -50%)',
    position: 'relative',
    color: Colors.white,
    borderRadius: 4,
    boxShadow: '0px 10px 36px #0000003D',

    '& > div:last-child > div': {
      border: 'none !important',

      '& > div': {
        padding: 0,
      },
    },
  },
  saveBadge: {
    background: '#E12138',
    padding: 4,
    fontSize: 12,
    fontWeight: 500,
    borderRadius: 3,
  },
});

const GemsPurchaseDialog = (props) => {
  const classes = useStyles();
  const { t, onClose, gemItem } = props;
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [paymentType, setPaymentType] = useState('paypal');
  const [disabled, setDisabled] = useState(true);
  const stripe = useStripe();
  const elements = useElements();

  const totalPrice = gemItem.costs * ((gemItem.saving / 100) + 1);
  const nowDate = moment()
  .format('ll');

  return (
    <PagePopup>
      <div className={classes.container}>

        <div style={{
          background: 'rgba(255, 255, 255, 0.04)',
          fontSize: 24,
          fontWeight: 600,
          padding: isMobile ? 8 : '16.5px 32px',
        }}>
          <ClosePopupButton
            handleClose={onClose}
            style={{ right: isMobile ? 8 : 32, top: isMobile ? 8 : 16, width: 28, height: 28 }}
          />

          {t('purchase')}
        </div>

        <div style={{
          padding: isMobile ? 8 : '18px 34px',
          borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
        }}>
          <div style={{ fontWeight: 500 }}>
            {t('payment_overview')}
          </div>

          <div style={{ ...Helpers.fillRowCross, marginTop: 14 }}>
            <SVGIcon
              src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
              height={37}
              width={28}
            />

            <div style={{ marginLeft: 16 }}>
              <div style={{ fontWeight: 500 }}>
                {t('x_gems', { count: gemItem.amount })}
              </div>
              <div style={{ marginTop: 2, color: Colors.halfWhite, fontSize: 14 }}>
                {t('one_time_debit', { date: nowDate })}
              </div>
            </div>
          </div>
        </div>

        <div
          style={{ padding: isMobile ? 8 : '10.5px 32px 28px', ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween }}
        >
          <div style={{ fontSize: 14, color: Colors.halfWhite }}>
            {t('order_total_tax_incl')}
          </div>
          <div style={Helpers.fillRowCenter}>
            <div style={{ fontSize: 22, fontWeight: 500 }}>
              € {getStringPrice(gemItem.costs / 100)}
            </div>
            {gemItem.saving &&
            <>
              <div style={{
                color: Colors.halfWhite,
                fontWeight: 500,
                textDecoration: 'line-through',
                marginLeft: 8,
                marginRight: 11,
              }}>
                {getStringPrice(totalPrice / 100)}
              </div>
              <div className={classes.saveBadge}>
                -{gemItem.saving}%
              </div>
            </>
            }
          </div>
        </div>

        <div style={{
          background: 'rgba(255, 255, 255, 0.04)',
          padding: isMobile ? 8 : '16px 32px 35px',
        }}>
          <OrderPay
            checkedTerms={checkedTerms}
            setCheckedTerms={setCheckedTerms}
            paymentType={paymentType}
            setPaymentType={setPaymentType}
            setDisabled={setDisabled}
            stripe={stripe}
            elements={elements}
            noDiscountInput
            noDeliveryInfo
          />
        </div>

        <div
          style={{
            ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween,
            padding: isMobile ? 8 : '16px 32px',
          }}
        >
          {!isMobile &&
          <TopBarSupporter />
          }
          <div />

          <GemsPay
            t={t}
            stripe={stripe}
            elements={elements}
            gemItem={gemItem}
            checkedTerms={checkedTerms}
            disabled={disabled}
            paymentType={paymentType}
            onClose={onClose}
          />
        </div>
      </div>
    </PagePopup>
  );
};

export default GemsPurchaseDialog;
