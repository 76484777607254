import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Draggable, { DraggableCore } from 'react-draggable';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import { get20StepQuality, getCondition, rangeArray } from '../../utils/helper';

const useStyles = makeStyles(() => ({
  qualityLine: {
    ...Helpers.fillRowCross,
    height: 7,
    marginTop: 6,
    '& > div': {
      height: '100%',
    },
  },
  hoverShadow: {
    '&:hover': {
      boxShadow: 'rgba(255, 255, 255, 0.25) 0px 54px 55px, rgba(255, 255, 255, 0.12) 0px -12px 30px, rgba(255, 255, 255, 0.12) 0px 4px 6px, rgba(255, 255, 255, 0.17) 0px 12px 13px, rgba(255, 255, 255, 0.09) 0px -3px 5px',
    },
  },
}));

const QualityLine = (props) => {
  const classes = useStyles();
  const dragContainer = useRef(null);
  const { t } = useTranslation();
  const { quality, hasNoText, changeValue, setQuality } = props;
  const [onePerWidth, setOnePerWidth] = useState(null);
  const [endPercentage, setEndPercentage] = useState(null);

  useEffect(() => {
    if (dragContainer && dragContainer.current)
      setOnePerWidth(dragContainer.current.offsetWidth * 0.01);
  }, []);

  if (quality === -1)
    return null;

  const onStop = (e, data, isMin) => {
    let newValue = Math.round(data.x / onePerWidth);
    if (endPercentage && !isMin)
      setEndPercentage(null);

    if (!isMin)
      newValue -= 8;

    changeValue(isMin, newValue + 2);
  };

  const onDrag = (e, data) => {
    let newValue = Math.round(data.x / onePerWidth);

    if (newValue > 107)
      newValue = 100;
    if (newValue <= quality[0])
      newValue += quality[0] + 1;

    setEndPercentage(newValue - 6);
  };

  const setQualityZone = (idx) => {
    setQuality({ min: (idx * 5 - 4), max: idx * 5 });
  };

  const hasMoreValues = Array.isArray(quality);

  const conditionColors = {};
  for (let i = 0; i <= 4; i++) {
    conditionColors[t(`condition_level.${i}`)
    .toLowerCase()] = Colors.quality[i * 25];
  }

  const condition = getCondition(quality);

  return (
    <>
      {!hasNoText &&
      <div
        style={{
          ...Helpers.fillRowCenter,
          ...Helpers.mainSpaceBetween,
          fontFamily: 'Montserrat',
          fontSize: 14,
          color: Colors.halfWhite,
          fontWeight: 500,
        }}
      >
        <div style={{ ...Helpers.fillRow }}>
          <div style={{ fontWeight: 400 }}>{t('quality')}:</div>
          <div style={{ marginLeft: 8 }}>
            <span style={{ color: Colors.white }}>{get20StepQuality(quality)}</span> / 20
          </div>
        </div>
        <div style={{
          color: Colors.newQuality[get20StepQuality(quality) - 1],
        }}>
          {t(`condition_level.${condition}`)}: {quality} %
        </div>
      </div>
      }
      <div className={classes.qualityLine}>
        {rangeArray(1, 20)
        .map(idx => (
          <div
            key={`QualityLineKeyIdx${idx}`}
            className={setQuality ? classes.hoverShadow : ''}
            style={{
              width: '5%',
              background: Colors.newQuality[idx - 1],
            }}
            onClick={setQuality ? () => setQualityZone(idx) : null}
          />
        ))}
      </div>
      <div style={{ ...Helpers.fillRow }} ref={dragContainer}>
        <Draggable
          axis="x"
          bounds={{
            right: (onePerWidth && hasMoreValues) ? (onePerWidth * (quality[1] - 2)) : 0,
            left: (onePerWidth && hasMoreValues) ? -(quality[0] > 0 ? (onePerWidth * (quality[0] - 2)) : 0) : 0,
          }}
          grid={[1, 1]}
          position={{
            x: onePerWidth ? ((hasMoreValues ? quality[0] : quality) - 2) * onePerWidth : 0,
            y: 0,
          }}
          onStop={(e, data) => onStop(e, data, true)}
          disabled={!hasMoreValues}
        >
          <div>
            <FontAwesomeIcon size="sm" icon={faCaretUp} color={Colors.white} />
          </div>
        </Draggable>
        {hasMoreValues &&
        <DraggableCore
          grid={[1, 1]}
          onDrag={onDrag}
          onStop={(e, data) => onStop(e, data, false)}
        >
          <div
            style={{ transform: `translate(${(((endPercentage ? endPercentage : quality[1]) - 2) * onePerWidth) - 8.75}px, 0px)` }}
          >
            <FontAwesomeIcon size="sm" icon={faCaretUp} color={Colors.white} />
          </div>
        </DraggableCore>
        }
      </div>
    </>
  );
};

export default QualityLine;