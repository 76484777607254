import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/react-hooks';
import { zeroPad } from 'react-countdown';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { setBoosterDesign as setBoosterDesignMutation } from '../../apollo/mutations';
import { getAllBooster } from '../../apollo/queries';

import CardPopup from '../Dialog/CardPopup';
import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import { rangeArray } from '../../utils/helper';
import AnimationButton from '../Button/AnimationButton';
import { setBoosterAllData } from '../../redux/actions';

const conf = require('../../conf');

const useStyles = makeStyles(() => ({
  header: {
    background: '#F22740',
    borderRadius: '15px 15px 0px 0px',
    padding: 12,
    color: Colors.white,
  },
  body: {
    background: '#01122B',
    position: 'relative',
  },
  bodyShadow: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 57,
    background: 'linear-gradient(180deg, #01122B00 0%, #01122B 100%)',
  },
  footer: {
    background: '#122041',
    ...Helpers.fillRowCenter,
    padding: 12,
    borderRadius: '0px 0px 15px 15px',
  },
  title: {
    fontSize: 26,
    fontFamily: 'Bebas Neue',
    lineHeight: 1,
    ...Helpers.textCenter,
  },
  container: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    flexWrap: 'wrap',
    maxWidth: 992,
    overflow: 'auto',
    height: 407,
    paddingTop: 10,
  },
  packItem: {
    margin: 10,
    position: 'relative',
    cursor: 'pointer',
    padding: 5,
    border: '3px solid transparent',
  },
  avatar: {
    position: 'absolute',
    width: 115,
    height: 115,
    border: 'none',
    top: '49.3%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

/**
 * @return {null}
 */
function BoosterPopup(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();
  const { handleClose, boosterId } = props;
  const [selected, setSelected] = useState(null);

  const [setBoosterDesign] = useMutation(setBoosterDesignMutation, {
    onCompleted: () => {
      enqueueSnackbar(t('success'), { variant: 'success' });
      props.setBoosterAllData({
        front_image: `/booster-packs/new/sh-base-booster-pack-${zeroPad(selected)}.png`,
      });
      handleClose();
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: [{ query: getAllBooster }],
  });

  const confirm = () => {
    setBoosterDesign({
      variables: {
        boosterId,
        designId: selected,
      },
    });
  };

  return (
    <CardPopup
      handleClose={handleClose}
      style={{
        backgroundColor: 'transparent',
        width: 992,
        maxHeight: 'auto',
        overflow: 'hidden',
        padding: 0,
      }}
    >
      <div className={classes.header}>
        <div className={classes.title}>
          {t('booster_title')}
        </div>
      </div>
      <div className={classes.body}>
        <div className={classes.container}>
          {rangeArray(1, conf.booster_packs_count)
          .map(item => (
            <div
              key={`BoosterPackItem${item}`}
              className={classes.packItem}
              onClick={() => setSelected(item)}
              style={selected === item ? { borderColor: '#1FE325' } : {}}
            >
              <img
                src={`${conf.cdn_url}/booster-packs/new/sh-base-booster-pack-${zeroPad(item)}.png?height=220`}
                alt="Booster pack"
              />
            </div>
          ))}
        </div>
        <div className={classes.bodyShadow} />
      </div>
      <div className={classes.footer}>
        <AnimationButton status="neonGreen" onClick={confirm} disabled={!selected}>
          {t('choose_design')}
        </AnimationButton>
      </div>
    </CardPopup>
  );
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setBoosterAllData,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(BoosterPopup);