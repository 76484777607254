import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { useParams, withRouter } from 'react-router-dom';

import { getOffer } from '../../apollo/queries';

import ErrorMessage from '../../components/ErrorBoundary/ErrorMessage';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import CardShopMatchAlbum from '../../components/CardShop/CardShopMatchAlbum';

function CardShopMatchAlbumContainer() {
  const { t } = useTranslation();
  const { albumId } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const variables = { id: albumId };
  const { data, loading, error } = useQuery(getOffer, {
    variables,
    fetchPolicy: useCacheWithExpiration('getOffer', variables),
  });

  const offer = data && data.getOffer;

  if (error) return <ErrorMessage error={error} />;

  return (
    <>
      <PageTitle title={t('card_shop')} />
      <CardShopMatchAlbum loading={loading} offer={offer} />
    </>
  );
}

export default withRouter(CardShopMatchAlbumContainer);