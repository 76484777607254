import { combineReducers } from 'redux';

import activityFeedReducer from './activityFeedReducer';
import userReducer from './userReducer';
import cardToolReducer from './cardToolReducer';
import cardShopReducer from './cardShopReducer';
import formValuesReducer from './formValuesReducer';
import popupEventReducer from './popupEventReducer';
import verificationReducer from './verificationReducer';
import swipeVerificationReducer from './swipeVerificationReducer';
import extraReducer from './extraReducer';
import heroMakerReducer from './heroMakerReducer';
import alertToolReducer from './alertToolReducer';
import boosterActionReducer from './boosterActionReducer';
import storageBoxesReducer from './storageBoxesReducer';
import collectorsViewReducer from './collectorsViewReducer';
import marketplaceReducer from './marketplaceReducer';

export default combineReducers({
  main: userReducer,
  activityFeed: activityFeedReducer,
  cardTool: cardToolReducer,
  alertTool: alertToolReducer,
  cardShop: cardShopReducer,
  formValues: formValuesReducer,
  popupEvents: popupEventReducer,
  cardVerification: verificationReducer,
  swipeVerification: swipeVerificationReducer,
  extra: extraReducer,
  heroMaker: heroMakerReducer,
  boosterAction: boosterActionReducer,
  storageBoxes: storageBoxesReducer,
  collectorsView: collectorsViewReducer,
  marketplace: marketplaceReducer,
});