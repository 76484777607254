import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import { useParams, withRouter } from 'react-router-dom';

import { setAlertToolTypeData } from '../../redux/actions';
import CreateAlertGroup from './CreateAlertGroup';
import CreateAlertCardPurchase from './CreateAlertCardPurchase';
import AnimationButton from '../Button/AnimationButton';
import CreateAlertSelect from './CreateAlertSelect';
import CreateAlertCommunityRankUp from './CreateAlertCommunityRankUp';
import CreateAlertBoosterRedeem from './CreateAlertBoosterRedeem';
import AlertDecisionPopup from './AlertDecisionPopup';
import AlertChannelLine from './AlertChannelLine.js';
import ConfigureAlertSettings from './ConfigureAlertSettings';

const CreateAlert = (props) => {
  const { t, alertTool, setAlertToolTypeData } = props;
  const { type } = alertTool;
  const [showPlatform, setShowPlatform] = useState(false);
  const params = useParams();

  if (params.type === 'update' && !alertTool.id)
    props.history.push('/streamer-settings/alerts');

  const handleChange = (event) => {
    const { target } = event;

    setAlertToolTypeData('type', target.value);
  };

  const alertActivated = alertTool.showTwitch || alertTool.showDiscord;

  return (
    <>
      <div style={{ maxWidth: 741, margin: '10px auto' }}>
        <CreateAlertGroup
          title={t('alert_type')}
          isRequired
          t={t}
          description={t('select_alert_type')}
        >
          <CreateAlertSelect
            value={type}
            handleChange={(event) => alertTool.id ? null : handleChange(event)}
            placeholder={t('type')}
            disabled={!!alertTool.id}
            id="type"
          >
            <MenuItem value="BoosterOpen">{t('booster_redeem')}</MenuItem>
            <MenuItem value="CardPurchase">{t('card_purchase')}</MenuItem>
            <MenuItem value="CommunityRankUp">{t('community_rank_up')}</MenuItem>
          </CreateAlertSelect>
        </CreateAlertGroup>

        {type === 'CardPurchase' &&
        <CreateAlertCardPurchase
          t={t}
          alertTool={alertTool}
          setAlertToolTypeData={setAlertToolTypeData}
        />
        }

        {type === 'CommunityRankUp' &&
        <CreateAlertCommunityRankUp
          t={t}
          alertTool={alertTool}
          setAlertToolTypeData={setAlertToolTypeData}
        />
        }

        {type === 'BoosterOpen' &&
        <CreateAlertBoosterRedeem
          t={t}
          alertTool={alertTool}
          setAlertToolTypeData={setAlertToolTypeData}
        />
        }

        {type &&
        <CreateAlertGroup
          title={t('alert_channel')}
          isRequired
          t={t}
          description={t('alert_channel_description')}
        >
          {alertActivated &&
          <div style={{ paddingTop: 10 }} />
          }
          {alertTool.showTwitch &&
          <AlertChannelLine isTwitch setAlertToolTypeData={setAlertToolTypeData} />
          }
          {alertTool.showDiscord &&
          <AlertChannelLine setAlertToolTypeData={setAlertToolTypeData} />
          }

          {!(alertTool.showTwitch && alertTool.showDiscord) &&
          <AnimationButton
            status="transparent"
            style={{
              padding: '9px 16px',
              border: 'none',
              fontFamily: 'Montserrat',
              fontSize: 14,
              marginTop: 10,
            }}
            onClick={() => setShowPlatform(true)}
          >
            <i className="fa-light fa-link" style={{ fontSize: 16, marginRight: 10 }} />
            {t(alertActivated ? 'add_another' : 'select_alert')}
          </AnimationButton>
          }
        </CreateAlertGroup>
        }
      </div>
      {showPlatform &&
      <AlertDecisionPopup
        handleClose={() => setShowPlatform(false)}
        setAlertToolTypeData={setAlertToolTypeData}
        alertTool={alertTool}
      />
      }
      {alertTool.showStyleSettings &&
      <>
        <div style={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          right: 0,
          left: 64,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0,0,0, 0.5)',
          zIndex: 100,
        }} />
        <ConfigureAlertSettings t={t} alertTool={alertTool} />
      </>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  const { alertTool } = state;
  return { alertTool };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setAlertToolTypeData,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateAlert));