import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { alertSettingsWithStreamer, getDiscordInfo } from '../../apollo/mergedQueries';
import {
  authDiscordUser as authDiscordUserMutation,
  changeAlertSettings as changeAlertSettingsMutation,
} from '../../apollo/mutations';

import PageTitle from '../../components/PageTitle/PageTitle';
import AlertSettings from '../../components/AlertSettings/AlertSettings';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import { parseQueryOfUrl } from '../../utils/helper';

const AlertsContainer = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [started, setStarted] = useState(false);
  const params = parseQueryOfUrl(props.location.search);
  const { code } = params;

  const { data, loading } = useQuery(alertSettingsWithStreamer, {
    fetchPolicy: useCacheWithExpiration('alertSettingsWithStreamer'),
  });

  const myAlertSettings = data && data.alertSettings;
  const myStreamer = data && data.getMyStreamer;
  const webSourceUrls = data && data.getAlertWebSourceUrls;
  const discordSetupProgress = data && data.getDiscordSetupProgress;

  useEffect(() => {
      if (code && !started && myStreamer) {
        setStarted(true);
        authDiscordUser({
          variables: {
            streamer: myStreamer._id,
            code,
          },
        });
      }
    },
    [code, started, myStreamer],
  );

  const [authDiscordUser] = useMutation(authDiscordUserMutation, {
    onCompleted: () => {
      makeAlert(t('success'), 'success');
    },
    onError: (data) => {
      makeAlert(data.message, 'error');
    },
    refetchQueries: () => [
      { query: alertSettingsWithStreamer },
      { query: getDiscordInfo },
    ],
  });

  const [changeAlertSettings] = useMutation(changeAlertSettingsMutation, {
    onCompleted: () => {
      makeAlert(t('success'), 'success');
    },
    onError: (data) => {
      makeAlert(data.message, 'error');
    },
    refetchQueries: () => [
      { query: alertSettingsWithStreamer },
    ],
  });

  const changeSettings = (ids, type) => {
    changeAlertSettings({
      variables: {
        ids,
        type,
      },
    });
  };

  const makeAlert = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  return (
    <>
      <PageTitle title={t('alert_settings')} />
      <AlertSettings
        alertSettings={myAlertSettings}
        loading={loading}
        discordSetupProgress={discordSetupProgress}
        changeSettings={changeSettings}
        webSourceUrls={webSourceUrls}
      />
    </>
  );
};

export default withRouter(AlertsContainer);