import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import HRNumbers from 'human-readable-numbers';
import Avatar from '@material-ui/core/Avatar';
import { useQuery } from '@apollo/react-hooks';

import { getStreamer, getSupporterMetadata } from '../../apollo/queries';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  supportArea: {
    padding: '13px 17.5px',
    ...Helpers.fillRowCenter,
    fontSize: 12,
    color: Colors.halfWhite,
    height: 'calc(100% - 26px)',
    '& span': {
      color: Colors.white,
    },
  },
  supportButton: {
    background: 'rgba(255, 255, 255, 0.06)',
    padding: 16,
    height: 30,
    ...Helpers.fillRowCenter,
    color: Colors.white,
    fontSize: 14,
    fontWeight: 500,
    cursor: 'pointer',
    transition: 'all 0.3s ease-out',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.16)',
    },
  },
}));

const TopBarStreamerButton = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { pathname, login } = props;

  const { data, loading } = useQuery(getStreamer, {
    variables: { login },
    fetchPolicy: useCacheWithExpiration('getStreamer'),
    skip: !login,
  });

  if (pathname.includes('/card-shop') || loading)
    return null;

  const streamer = data && data.streamer;

  return (
    <div style={{ borderLeft: '3px solid rgb(1, 16, 36)' }}>
      <Link
        to={`/card-shop${streamer ? `/streamer/${streamer._id}` : ''}`}
        className={classes.supportButton}
      >
        <i
          className="fa-solid fa-hand-holding-heart"
          style={{ fontSize: 20, marginRight: 10 }}
        />
        {t(streamer ? 'support_streamer' : 'support_your_streamer', { streamer: streamer && streamer.display_name })}
      </Link>
    </div>
  );
};

export default TopBarStreamerButton;