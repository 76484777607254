import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Helpers from '../../theme/Helpers';

import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  container: {
    ...Helpers.fillRow,
    ...Helpers.mainSpaceEvenly,
    flexWrap: 'wrap',
    padding: '0 145px',
  },
  item: {
    ...Helpers.fillColCenter,
    ...Helpers.mainSpaceBetween,
    border: '1px solid transparent',
    cursor: 'pointer',
    padding: 20,
    margin: '5px 0',
    '&:hover': {
      opacity: 0.8,
    },
  },
  selected: {
    border: '1px solid orange',
  },
  textBox: {
    backgroundColor: '#0E0E0E',
    padding: '5px 50px',
    borderRadius: 4,
  },
}));

const CardToolServicesDesignerFormPickStyle = (props) => {
  const classes = useStyles();
  const { activeStyle, setActiveStyle } = props;

  const styles = [
    {
      title: 'Photo',
      img: `${conf.cdn_url}/assets/template/illustration_styles/photo.png`,
    },
    {
      title: 'Realistic',
      img: `${conf.cdn_url}/assets/template/illustration_styles/realistic.png`,
    },
    {
      title: 'Cartoon',
      img: `${conf.cdn_url}/assets/template/illustration_styles/cartoon.png`,
    },
    {
      title: 'Logo',
      img: `${conf.cdn_url}/assets/template/illustration_styles/logo.png`,
    },
  ];

  return (
    <div className={classes.container}>
      {styles.map((style, index) => (
        <div
          className={[classes.item, activeStyle === index ? classes.selected : ''].join(' ')}
          key={`StyleKey${style.title}`}
          onClick={() => setActiveStyle(index)}
        >
          <div>
            <img
              style={{ margin: 5, borderRadius: 4, position: 'relative' }}
              src={style.img}
              key={`CardImageKey${index}`}
              alt={`Cards style preview ${index}`}
            />
          </div>
          <div className={classes.textBox}>
            {style.title}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardToolServicesDesignerFormPickStyle;