import React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSnackbar } from 'notistack';
import { SpeedDialAction } from '@material-ui/lab';
import {
  faArchive, faBoxOpen,
  faEye,
  faEyeSlash,
  faStore,
  faStoreSlash,
} from '@fortawesome/free-solid-svg-icons';

import { getAllInformationAboutCard } from '../../apollo/mergedQueries';
import { updateCardMetaData as updateCardMetaDataMutation } from '../../apollo/mutations';

import { getMatchName } from '../../utils/helper';
import Loading from '../../components/Loading/Loading';
import TopViewHeader from '../../components/TopViewHeader/TopViewHeader';
import CardView from '../../components/Cards/CardView';
import PageTitle from '../../components/PageTitle/PageTitle';
import ErrorMessage from '../../components/ErrorBoundary/ErrorMessage';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import AdminSpeedMenuContainer from '../Admin/AdminSpeedMenu';

const CardContainer = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const cardId = props.match.params.id;
  const viewerId = props.match.params.viewerId;
  const isCraft = props.match.params.isCraft;

  const variables = {
    id: cardId,
    viewer: viewerId,
  };
  const getAllInformationAboutCardQuery = useQuery(getAllInformationAboutCard, {
    variables,
    fetchPolicy: useCacheWithExpiration('getAllInformationAboutCard', variables),
  });
  const { loading, error, data } = getAllInformationAboutCardQuery;

  const [updateCardMetaData] = useMutation(updateCardMetaDataMutation, {
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{ query: getAllInformationAboutCard, variables }],
  });

  if (error) return <ErrorMessage error={error} />;

  const card = (data) && data.card;
  const firstRedeemer = (data) && data.getFirstRedeemedCardOfViewer;
  const myViewer = (data) && data.getMyViewer;
  const showBeta = myViewer && myViewer.streamheroes_role.includes('marketing');

  let actions = null;

  if (card) {
    let variables = {};
    const typeName = card.__typename === 'Card' ? 'card' : 'special_card';
    variables[typeName] = {
      _id: card._id,
    };
    actions = [
      {
        icon: <FontAwesomeIcon icon={card.isApproved ? faEyeSlash : faEye} />,
        name: card.isApproved ? 'Set invisible' : 'Set visible',
        action: () => {
          variables[typeName].isApproved = !card.isApproved;
          updateCardMetaData({ variables });
        },
      },
      {
        icon: <FontAwesomeIcon icon={card.is_printable ? faStoreSlash : faStore} />,
        name: card.is_printable ? 'Set not purchasable' : 'Set purchasable',
        action: () => {
          variables[typeName].is_printable = !card.is_printable;
          updateCardMetaData({ variables });
        },
      },
    ];

    if (typeName === 'card')
      actions.push({
        icon: <FontAwesomeIcon icon={!card.is_archived ? faArchive : faBoxOpen} />,
        name: !card.is_archived ? 'Set archived' : 'Set active',
        action: () => {
          variables[typeName].is_archived = !card.is_archived;

          updateCardMetaData({ variables });
        },
      });
  }

  return (
    <TopViewHeader>
      <PageTitle title={(card) && `${getMatchName(card)} - Level ${card.level} Card`} />

      {loading ?
        <Loading />
        :
        <>
          <CardView
            card={card}
            isCraft={isCraft}
            firstRedeemer={firstRedeemer}
            informationQuery={getAllInformationAboutCardQuery}
            viewerId={viewerId}
            showBeta={showBeta}
          />

          <AdminSpeedMenuContainer>
            {actions && actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                title={action.name}
                onClick={() => action.action()}
              />
            ))}
          </AdminSpeedMenuContainer>
        </>
      }
    </TopViewHeader>
  );
};

export default CardContainer;