import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { zeroPad } from 'react-countdown';

import Helpers from '../../theme/Helpers';
import { rangeArray } from '../../utils/helper';
import CustomButton from '../Button/CustomButton';

const conf = require('../../conf');

const CardToolSelectEffectStyle = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { selectedStyle, activeEffect, updateEffect } = props;
  const [show, setShow] = useState(false);
  const maxStyles = 30;
  const directory = `${conf.no_cors_cdn_url}/cards/maker/styles`;

  return (
    <div className={classes.wrapper}>
      <div>
        <img
          src={`${directory.replace(conf.no_cors_cdn_url, conf.cdn_url)}/icon-style-${zeroPad(selectedStyle)}.png?height=40`}
          alt={`Selected effect`}
        />
      </div>
      <div>
        <CustomButton onClick={() => setShow(!show)}>
          {t('all_styles')}
        </CustomButton>
      </div>
      {show &&
      <>
        <div className={[classes.triangle, classes.triangleBottomRight].join(' ')} />
        <div className={classes.stylesContainer}>
          {rangeArray(1, maxStyles)
          .map(style => (
            <img
              src={`${directory.replace(conf.no_cors_cdn_url, conf.cdn_url)}/icon-style-${zeroPad(style)}.png?height=40`}
              key={`EffectKey${style}`}
              alt={`Effect ${style}`}
              onClick={() => updateEffect(style, activeEffect)}
              className={classes.borderPlace}
              style={selectedStyle === style ? { borderColor: 'white' } : {}}
            />
          ))}
        </div>
      </>
      }
    </div>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    ...Helpers.fillRowCenter,
  },
  stylesContainer: {
    position: 'absolute',
    bottom: 65,
    right: 0,
    ...Helpers.fillRowCenter,
    flexWrap: 'wrap',
    height: 260,
    width: 340,
    padding: 10,
    overflow: 'auto',
    backgroundColor: '#AC3035',
  },
  borderPlace: {
    margin: 5,
    border: '1px solid transparent',
    borderRadius: 4,
    cursor: 'pointer',
    '&:hover': {
      borderColor: 'orange',
    },
  },
  triangle: {
    position: 'absolute',
    width: 0,
    height: 0,
  },
  triangleBottomRight: {
    bottom: 55,
    right: 65,
    borderTop: '10px solid #AC3035',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
  },
}));

export default CardToolSelectEffectStyle;