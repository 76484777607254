import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import PageTitle from '../PageTitle/PageTitle';
import Game from '../Minesweeper/Game';
import Alert from '../Alert/Alert';
import Colors from '../../theme/Colors';

const useStyles = makeStyles({
  container: {
    padding: 30,
  },
  link: {
    '& a': {
      color: Colors.primary,
      '&:hover': {
        color: Colors.lightPrimary,
      },
    },
  },
});

const ErrorMessage = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <PageTitle title={t('error_title')} />
      <Alert>
        <div>
          <b>{t('error_title')}</b><br />
          <div className={classes.link} dangerouslySetInnerHTML={{ __html: t('error_explain') }} />
          <br />
          <i>{props.error.message}</i>
        </div>
      </Alert>
      <Game />
    </div>
  );
};

export default ErrorMessage;