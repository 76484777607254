import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import CustomButton from '../Button/CustomButton';
import Colors from '../../theme/Colors';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const buttonStyle = {
  width: 40,
  height: 40,
  backgroundColor: '#888',
  color: 'black',
  verticalAlign: 'top',
  fontSize: '32px',
  fontFamily: 'Arial, Helvetica, sans-serif',
  borderLeft: '5px solid rgb(220,220,220)',
  borderTop: '5px solid rgb(220,220,220)',
  borderBottom: '5px solid #333',
  borderRight: '5px solid #333',
  display: 'inline-block',
};

const visitStyle = {
  width: 48,
  height: 48,
  backgroundColor: '#555',
  color: 'white',
  fontWeight: 'bold',
  border: '1px solid black',
  verticalAlign: 'top',
  fontSize: '32px',
  fontFamily: 'Montserrat, sans-serif',
  display: 'inline-block',
};

const Game = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [bombs, setBombs] = useState([]);
  const [flags, setFlags] = useState([]);
  const [visited, setVisited] = useState([]);
  const [lost, setLost] = useState(false);

  useEffect(() => {
    if (bombs.length === 0)
      generateBombs();
  }, [bombs]);

  const generateBombs = () => {
    setLost(false);
    let bombArr = Array(10)
    .fill(0)
    .map(() => Array(10)
    .fill(0));

    for (let i = 0; i < bombArr.length; i++) {
      let bombPos = Math.floor(Math.random() * 10);
      bombArr[i][bombPos] = 'X';
    }

    for (let i = 0; i < bombArr.length; i++) {
      for (let j = 0; j < bombArr[i].length; j++) {
        if (bombArr[i][j] !== 'X') {
          let sum = 0;

          if (i > 0 && bombArr[i - 1][j] === 'X') sum++;
          if (i < bombArr.length - 1 && bombArr[i + 1][j] === 'X') sum++;
          if (j < bombArr.length - 1 && bombArr[i][j + 1] === 'X') sum++;
          if (j > 0 && bombArr[i][j - 1] === 'X') sum++;
          if (i < bombArr.length - 1 && j > 0 && bombArr[i + 1][j - 1] === 'X') sum++;
          if (i < bombArr.length - 1 && j < bombArr.length - 1 && bombArr[i + 1][j + 1] === 'X') sum++;
          if (i > 0 && j > 0 && bombArr[i - 1][j - 1] === 'X') sum++;
          if (i > 0 && j < bombArr.length - 1 && bombArr[i - 1][j + 1] === 'X') sum++;

          bombArr[i][j] = sum;
        }
      }
    }
    setBombs(bombArr);

    const cover = Array(10)
    .fill(0)
    .map(() => Array(10)
    .fill(0));
    setVisited(cover);
    const flagCover = Array(10)
    .fill(0)
    .map(() => Array(10)
    .fill(0));
    setFlags(flagCover);
  };

  const calculateFlagsCount = () => {
    let flagsCount = 0;
    for (let i = 0; i < flags.length; i++) {
      for (let c = 0; c < flags.length; c++) {
        if (flags[i][c] === 1)
          flagsCount++;
      }
    }

    return flagsCount;
  };

  const setFlag = (event, i, j) => {
    event.preventDefault();

    if (flags[i][j] === 0 && calculateFlagsCount() === bombs.length)
      return;

    flags[i][j] = flags[i][j] === 1 ? 0 : 1;
    setFlags([...flags]);
  };

  const visitCell = (i, j) => {
    if (lost || flags[i][j] === 1)
      return;
    if (bombs[i][j] === 'X') {
      enqueueSnackbar('You lost! Play again!', { variant: 'error' });
      setLost(true);
    }
    dfsCells(i, j);
    visited[i][j] = 1;
    setVisited([...visited]);
  };

  function dfsCells (i, j) {
    if (i < 0 || i > visited.length - 1 || j < 0 || j > visited[0].length - 1 || visited[i][j] === 1 || bombs[i][j] === 'X') return;

    visited[i][j] = 1;
    if (flags[i][j] === 1) {
      flags[i][j] = 0;
      setFlags([...flags]);
    }

    setVisited([...visited]);
    if (bombs[i][j] < 1) {
      dfsCells(i + 1, j);
      dfsCells(i - 1, j);
      dfsCells(i, j + 1);
      dfsCells(i, j - 1);
    }
  }

  return (
    <div style={{ marginTop: 20, textAlign: 'center' }}>
      <h2 style={{ color: Colors.white }}>{t('minesweeper_play')}</h2>
      {bombs.map((arr, index) =>
        <div key={`BombsPlace#${index}`}>
          {arr.map((elem, i) =>
            <div
              onClick={() => visitCell(index, i)}
              onContextMenu={(event) => setFlag(event, index, i)}
              style={visited[index][i] === 0 ? buttonStyle : visitStyle}
              key={`AreaPlace#${i}`}
            >
              {visited[index][i] === 0 ?
                flags[index][i] === 1 ?
                  <FontAwesomeIcon icon={faFlag} size="sm" color={Colors.primary} />
                  :
                  null
                :
                (bombs[index][i] === 0 ? '' : bombs[index][i])
              }
            </div>,
          )}
        </div>,
      )}
      <CustomButton onClick={() => generateBombs()} style={{ marginTop: 20 }}>
        {t('minesweeper_generate_game')}
      </CustomButton>
    </div>
  );
};

export default Game;