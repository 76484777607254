import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';

import { isOrderPossible } from '../../apollo/queries';

import CustomButton from '../Button/CustomButton';
import { roundToDecimal } from '../../utils/helper';
import Helpers from '../../theme/Helpers';
import { returnMobileObj } from '../../utils/responsive';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

const useStyles = makeStyles({
  banner: {
    borderRadius: 5,
    padding: '30px 50px',
    marginBottom: 20,
    marginTop: 30,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    ...Helpers.fillColCenter,
  },
  requiredCardsWrapper: {
    background: 'linear-gradient(to top, #111111, #2d2d2d)',
    padding: 30,
    marginTop: 20,
    ...Helpers.textCenter,
    '& img': {
      marginTop: 15,
      height: 170,
      marginRight: 15,
      filter: 'grayscale(100)',
      transition: '.3s ease-in-out',
      '&:hover': {
        filter: 'none',
      },
    },
  },
});

const Offer = (props) => {
  const classes = useStyles();
  const { offer, isTabletOrMobile, offerAreaRef } = props;

  const variables = { offer: offer._id };
  const isOrderPossibleQuery = useQuery(isOrderPossible, {
    variables,
    fetchPolicy: useCacheWithExpiration('isOrderPossible', variables),
  });

  const orderPossible = (isOrderPossibleQuery && isOrderPossibleQuery.data) && isOrderPossibleQuery.data.isOrderPossible;

  const totalProductCosts = roundToDecimal(offer.products.reduce((acc, product) => acc + product.costs, 0.00));
  const country = offer.country_costs[0];

  let bannerStyles = {};
  if (offer.banner) {
    const { image, position, color } = offer.banner;
    if (image)
      bannerStyles.backgroundImage = `url(${image})`;
    if (position)
      bannerStyles.backgroundPosition = position;
    if (color)
      bannerStyles.backgroundColor = color;
  } else {
    bannerStyles = { ...Helpers.boxBasics };
  }

  return (
    <div
      className={classes.banner}
      style={{ ...bannerStyles, ...returnMobileObj(isTabletOrMobile, { padding: '10px 20px' }) }}
      ref={offerAreaRef}
    >
      <div style={{ fontSize: 36, fontWeight: 'bold', textTransform: 'uppercase' }}>
        {offer.title}
      </div>
      <div style={{ fontSize: 24, fontWeight: 'bold', textTransform: 'uppercase' }}>
        {offer.sub_title}
      </div>
      <div style={{ fontSize: 14, ...Helpers.textCenter }}>
        {offer.description}
      </div>
      <div
        className={classes.requiredCardsWrapper}
        style={returnMobileObj(isTabletOrMobile, { padding: 10 })}
      >
        <div style={{ fontSize: 16, fontWeight: 'bold', maxWidth: 650, margin: '0 auto' }}>
          {orderPossible && orderPossible.possible ? offer.possible_text : offer.not_possible_text}
        </div>
        <div>
          {offer.required_cards.map((card, index) => (
            <img
              key={`RequiredCard#${card._id}`}
              src={card.image}
              alt={`RequiredCard#${index}`}
              style={orderPossible && orderPossible.owned_card_ids.includes(card._id) ? { filter: 'none' } : {}}
            />
          ))}
        </div>
        <div style={{ fontSize: 16, margin: '10px auto 0' }}>
          Mit dem Kauf von Marc&Friends Sammelalbum/Sammelkarten unterstützt du aktiv den
          Wasserbrunnenbau in Afrika. 100% des Nettoerlöses (abzgl. Versand & USt.) werden für
          gemeinnützige Brunnenbauprojekte in Afrika gespendet. Die Spende erfolgt nach Abschluss
          der 4. Folge von Marc&Friends. Jeder Käufer erhält nach Abschluss der 4. Folge, aus
          Transparenzgründen, einen Nachweis der von Streamheroes getätigten Spende. Streamheroes
          ist keine gemeinnützige Organisation und kann keine Spendenquittungen für die Käufe
          ausstellen. Die Spende erfolgt von Streamheroes an die gemeinnützige Organisation.
        </div>
      </div>
      <div
        style={{ fontSize: 24, fontWeight: 'bold', textTransform: 'uppercase', marginTop: 20 }}
      >
        {(totalProductCosts / 100).toFixed(2)
        .toString()
        .replace('.', ',')}€ + {(country.shipping / 100).toFixed(2)
      .toString()
      .replace('.', ',')}€ Versand ({country.code})
      </div>
      <CustomButton
        status="primary"
        style={{
          marginTop: 10,
          padding: '5px 20px',
          background: 'linear-gradient(to right, #53b216, #53ff79, #53b216)',
          cursor: orderPossible && orderPossible.possible ? 'pointer' : 'not-allowed',
          opacity: orderPossible && orderPossible.possible ? 1.0 : 0.6,
        }}
        onClick={() => orderPossible && orderPossible.possible ? props.history.push(`/card-shop?offer=${offer._id}`) : null}
      >
        {offer.order_button_text}
      </CustomButton>
    </div>
  );
};

export default withRouter(Offer);