import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { bindActionCreators } from 'redux';

import { getRedeemedCardObjects } from '../../apollo/mergedQueries';
import {
  putCardsInDeck as putCardsInDeckMutation,
  removeCardsFromDeck as removeCardsFromDeckMutation,
} from '../../apollo/mutations';

import Helpers from '../../theme/Helpers';
import AnimationButton from '../Button/AnimationButton';
import {
  increaseCount,
  resetStorageBoxData,
  setStorageBoxSelectionMode,
} from '../../redux/actions';
import Colors from '../../theme/Colors';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';
import {
  addCardsToSpecificStorageBox,
  removeCardsFromSpecificStorageBox,
  updateStorageBoxes,
} from '../../utils/cacheWritings';
import InventoryStorageBoxSubMenu from '../Inventory/InventoryStorageBoxSubMenu';

const useStyles = makeStyles(() => ({
  clickableText: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Montserrat',
    color: Colors.halfWhite,
    padding: '8px 32px',
    border: 'none',
    '&:hover': {
      color: Colors.white,
    },
  },
  menu: {
    position: 'absolute',
    background: 'rgba(255, 255, 255, 0.08)',
    top: 'calc(100% + 6px)',
    borderRadius: 6,
    left: 0,
    minWidth: 252,
    zIndex: 200,
  },
}));

const TopBarStorageBox = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { storageBoxes, t, cardDecks, isInBox, cardDeck, location } = props;
  const { isSelectionMode, cards, cardObjs } = storageBoxes;
  const [openMenu, setOpenMenu] = useState(false);
  const id = location.pathname.split('/')
  .pop();

  useEffect(() => {
    if (!isSelectionMode && openMenu)
      setOpenMenu(false);
  }, [openMenu, isSelectionMode]);

  const refetchQueries = [
    {
      query: getRedeemedCardObjects,
      variables: {
        pagination: {
          page: 1,
          perPage: 25,
        },
        min_quality: 0,
        max_quality: 100,
        rarity: [],
        sorting: {
          field: 'created_at',
          order: 'DESC',
        },
        match: id && id.length === 24 ? id : null,
      },
    },
  ];

  const [putCardsInDeck, { loading: moveLoading }] = useMutation(putCardsInDeckMutation, {
    update: (cache, { data }) => {
      const { putCardsInDeck } = data;
      if (putCardsInDeck) {
        const variables = {
          min_quality: 0,
          max_quality: 100,
          rarity: [],
          sorting: {
            field: 'created_at',
            order: 'DESC',
          },
        };

        updateStorageBoxes(cache, putCardsInDeck.id, 1, increaseCount);
        let moveCards = [];
        if (isInBox)
          moveCards = removeCardsFromSpecificStorageBox(cache, cards, {
            ...variables,
            deck: cardDeck._id,
          }, increaseCount);

        addCardsToSpecificStorageBox(cache, isInBox ? moveCards : cardObjs, {
          ...variables,
          deck: putCardsInDeck._id,
        }, increaseCount);
      }
    },
    onCompleted: () => {
      enqueueSnackbar(t('success'), { variant: 'success' });
      props.resetStorageBoxData(true);
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => refetchQueries,
  });

  const [removeCardsFromDeck, { loading: removeLoading }] = useMutation(removeCardsFromDeckMutation, {
    update: (cache, { data }) => {
      const { removeCardsFromDeck } = data;
      if (removeCardsFromDeck) {
        const variables = {
          min_quality: 0,
          max_quality: 100,
          rarity: [],
          sorting: {
            field: 'created_at',
            order: 'DESC',
          },
        };

        removeCardsFromSpecificStorageBox(cache, cards, {
          ...variables,
          deck: storageBoxes.id,
        }, increaseCount);
        updateStorageBoxes(cache, storageBoxes.id, -(cards.length), increaseCount);
      }
    },
    onCompleted: () => {
      enqueueSnackbar(t('success'), { variant: 'success' });
      props.resetStorageBoxData(true);
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => refetchQueries,
  });

  const moveCardsToInventory = () => {
    setOpenMenu(false);
    removeCardsFromDeck({
      variables: {
        card_ids: cards,
      },
    });
  };

  const moveCardToStorageBox = (deckId) => {
    setOpenMenu(false);
    putCardsInDeck({
      variables: {
        deck: deckId,
        card_ids: cards,
      },
    });
  };

  const loading = removeLoading || moveLoading;

  return (
    <div style={{ ...Helpers.fillRowCenter, marginRight: 33.5, position: 'relative' }}>
      <AnimationButton
        status={isSelectionMode ? 'transparent' : 'whiteTransparency'}
        className={classes.clickableText}
        onClick={() => props.setStorageBoxSelectionMode(!isSelectionMode)}
      >
        {t(isSelectionMode ? 'cancel_selection' : 'select_cards')}
      </AnimationButton>
      {isSelectionMode &&
      <>
        <div style={{ position: 'relative', marginLeft: 18 }}>
          <AnimationButton
            status="whiteTransparency"
            style={{ padding: '8px 18px', fontSize: 14, fontWeight: 500 }}
            onClick={() => null}
            disabled={loading || (cards && cards.length === 0)}
            onMouseEnter={() => setOpenMenu(true)}
          >
            {t('move_in_storage')}
            <i
              className={loading ? 'fa-light fa-loader fa-spin' : 'fa-regular fa-chevron-right'}
              style={{ marginLeft: 8, fontSize: 14 }}
            />
          </AnimationButton>

          {openMenu &&
          <CustomBackdropFilter
            className={classes.menu}
            filter={'blur(50px)'}
            canvasFallback={true}
            html2canvasOpts={{
              allowTaint: true,
            }}
          >
            <div onMouseLeave={() => setOpenMenu(false)}>
              <InventoryStorageBoxSubMenu
                cardDecks={cardDecks}
                selectedBoxId={cardDeck}
                loading={loading}
                moveCardToStorageBox={moveCardToStorageBox}
              />
            </div>
          </CustomBackdropFilter>
          }
        </div>
        {isInBox &&
        <AnimationButton
          status="neonRed"
          style={{ padding: '8px 18px', fontSize: 14, fontWeight: 500, marginLeft: 8 }}
          onClick={() => loading ? null : moveCardsToInventory()}
          disabled={loading || (cards && cards.length === 0)}
        >
          <i
            className={loading ? 'fa-light fa-loader fa-spin' : 'fa-regular fa-minus'}
            style={{ marginRight: 8, fontSize: 14 }}
          />
          {t('move_to_inventory')}
        </AnimationButton>
        }
      </>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  const { storageBoxes } = state;
  return { storageBoxes };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setStorageBoxSelectionMode,
    resetStorageBoxData,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TopBarStorageBox));