import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

import { checkAddress as checkAddressMutation } from '../../apollo/mutations';

import { handleObjectValueChange, handleValueChange } from '../../redux/actions';
import AnimationButton from '../Button/AnimationButton';
import Loading from '../Loading/Loading';
import { logAmplitudeEvent } from '../../utils/amplitude';
import conf from '../../conf'

const CheckAddress = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    handleObjectValueChange,
    handleValueChange,
    formValues,
    setActiveStep,
    isFormFullFilled,
  } = props;

  const [checkAddress, { loading }] = useMutation(checkAddressMutation, {
    onCompleted: (data) => {
      const { checkAddress } = data;

      /*if (checkAddress.status === null && checkAddress.predictions === null) {
        enqueueSnackbar('Error with address', { variant: 'error' });
        return null;
      }*/

      // A3000 - no address variants found && A2000 - more addresses found
      if (checkAddress && checkAddress.status && !checkAddress.status.includes('A3000') && !checkAddress.status.includes('A2000')) {
        if(conf.track_amplitude) logAmplitudeEvent('Validate address', { 'Is valid': true });
        // A1100 - Address was adjusted
        if (checkAddress.status.includes('A1100')) {
          const address = checkAddress.predictions[0];
          handleObjectValueChange({
            street: address.street,
            postal_code: address.postCode,
            city: address.cityName,
          });
        }
        setEditMode(false);
        setActiveStep();
      } else {
        if(conf.track_amplitude) logAmplitudeEvent('Validate address', { 'Is valid': false });
        setShowCheckAddress(true);
      }
      window.scrollTo(0, 0);
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
  });

  const setEditMode = (value) => {
    handleValueChange('editMode', value);
  };

  const setShowCheckAddress = (value) => {
    handleValueChange('showCheckAddress', value);
  };

  const checkMyAddress = () => {
    checkAddress({
      variables: {
        country: formValues.country.toLowerCase(),
        postCode: formValues.postal_code,
        cityName: formValues.city,
        street: formValues.street,
        houseNumber: formValues.house_number,
      },
    });
  };

  return (
    <>
      {loading ?
        <Loading />
        :
        <AnimationButton
          status="neonGreen"
          onClick={checkMyAddress}
          style={{ width: '100%', padding: 14, fontFamily: 'Montserrat', fontSize: 14 }}
          disabled={!isFormFullFilled}
        >
          {t('select_payment_method')}
        </AnimationButton>
      }

    </>
  );
};

const mapStateToProps = (state) => {
  const { formValues } = state;
  return { formValues };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators(
    {
      handleObjectValueChange,
      handleValueChange,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(CheckAddress);