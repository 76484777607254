import React from 'react';
import { useTranslation } from 'react-i18next';

import ReturnBreadcrumbs from './ReturnBreadcrumbs';

const InventoryBreadcrumbs = (props) => {
  const { t } = useTranslation();
  const { streamer, booster, card } = props;

  const mainUrl = 'inventory';

  const parts = [
    {
      name: t('inventory'),
    },
    {
      name: t('overview'),
      link: `/${mainUrl + '/groups'}`,
    },
  ];

  if (streamer)
    parts.push({
      name: streamer.display_name,
      link: `/${mainUrl}/streamer/${streamer._id}`,
    });

  if (booster)
    parts.push(
      {
        name: t('booster'),
      },
      {
        name: booster.edition,
        link: `/${mainUrl}/streamer/${streamer._id}/booster/${booster._id}`,
      },
    );

  if (card)
    parts.push(
      {
        name: t('card'),
      },
      {
        name: t(`rareness_level.${card ? card.rarity : 0}`),
        link: `/${mainUrl}/streamer/${streamer._id}/booster/${booster._id}/card/${card._id}`,
      },
    );

  return (
    <ReturnBreadcrumbs parts={parts} />
  );
};

export default InventoryBreadcrumbs;