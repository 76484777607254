import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const TopViewHeader = (props) => {
  const { backgroundImage, backgroundColor, style } = props;

  const backgroundImageURL = backgroundImage ? `url("${backgroundImage}")` : null;

  const useStyles = makeStyles(() => ({
    container: {
      backgroundImage: backgroundImageURL,
      minHeight: 'calc(100vh - 145px)',
    },
    header: {
      textAlign: 'center',
      padding: '50px 0 45px 0',
      fontSize: 30,
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.container}
         style={{ ...(backgroundColor ? { backgroundColor } : {}), ...(style ? style : {}) }}>
      {props.header &&
      <Typography variant="h1" color="textPrimary" className={classes.header}>
        {props.header}
        {props.subHeader &&
        <Typography variant="body2" color="textPrimary">
          {props.subHeader}
        </Typography>
        }
      </Typography>
      }
      <div>
        {props.children}
      </div>
    </div>
  );
};

export default TopViewHeader;