import React from 'react';
import { makeStyles } from '@material-ui/core';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import SVGIcon from '../SVGIcon/SVGIcon';

import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  showIcon: {
    position: 'relative',
    height: '100%',
    width: '100%',

    '&:hover $svgIcon': {
      opacity: 1,
    },
  },
  svgIcon: {
    ...Helpers.textCenter,
    marginTop: -50,
    opacity: 0,
    transition: 'all 0.3s ease-out',
  },
  blurredItem: {
    background: 'rgba(26, 45, 88, 0.24)',
    filter: 'blur(50px)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    borderRadius: '50%',
    top: 0,
    zIndex: -1,
  },
}));

const IconWrapper = (props) => {
  const classes = useStyles();
  const { children, className, id, size = 50 } = props;

  return (
    <div className={[className, classes.showIcon].join(' ')} id={id}>
      {children}
      <div className={classes.blurredItem} />
      <div className={classes.svgIcon}>
        <SVGIcon
          size={size}
          src={`${conf.cdn_url}/icons/360-rotate.svg`}
          fill={Colors.white}
        />
      </div>
    </div>
  );
};

export default IconWrapper;