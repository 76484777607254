import React from 'react';
import moment from 'moment';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import Colors from '../../theme/Colors';

const StripeMonthlyDiagram = (props) => {
  const { t } = useTranslation();
  const { stripeData } = props;

  const months = () => {
    const months = [];
    const date = moment();
    let i = 0;
    while (i < 12) {
      months.push(date.format('MMM YYYY'));
      date.subtract(1, 'month');
      i++;
    }
    return months.reverse();
  };

  const mainDiagramColor = '#3E4B5E';
  const series = [];
  for (let i = 0; i <= 5; i++) {
    series.push({
      name: i === 0 ? t('special_card') : t('level_x_card', { level: i }),
      data: Array(12)
      .fill(0),
    });
  }

  const categories = months();

  stripeData && stripeData.sell_dates.map(data => {
    const [checkMonth, checkYear] = data.month_year.split('-');
    const compareDate = moment([parseInt(checkYear), parseInt(checkMonth) - 1]);
    const findIndex = categories.findIndex(month => month === compareDate.format('MMM YYYY'));

    if (findIndex) {
      if (data.special_sells > 0)
        series[0].data[findIndex] = data.special_sells;
      data.level_sells && data.level_sells.map((levelSell, index) => {
        if (levelSell > 0)
          series[index + 1].data[findIndex] = levelSell;

        return null;
      });
    }

    return null;
  });

  const options = {
    chart: {
      type: 'bar',
      height: 300,
      stacked: true,
      toolbar: {
        show: false,
      },
      background: Colors.containerBGColor,
      zoom: {
        enabled: true,
      },
    },
    responsive: [{
      breakpoint: 680,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0,
        },
      },
    }],
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: mainDiagramColor,
        },
      },
    },
    xaxis: {
      categories: categories,
      labels: {
        rotate: -45,
        trim: true,
        style: {
          colors: mainDiagramColor,
          cssClass: {
            color: Colors.primary,
          },
        },
      },
      axisBorder: {
        color: mainDiagramColor,
      },
      axisTicks: {
        color: mainDiagramColor,
      },
    },
    grid: {
      borderColor: '#2b2c2e',
    },
    legend: {
      position: 'right',
    },
    theme: {
      mode: 'dark',
    },
    fill: {
      opacity: 1,
    },
  };

  return (
    <div style={{ marginTop: 40 }}>
      <Chart options={options} series={series} type="bar" height={300} />
    </div>
  );
};

export default StripeMonthlyDiagram;