import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { MuiChat } from './mui/MuiChat';
import { ChatController } from './ChatController';

const MyChat = (props) => {
  const { myViewer, chat, addChatMessage } = props;
  const [chatCtl] = useState(new ChatController());
  const [type, setType] = useState('text');
  const [chatId, setChatId] = useState(null);
  const [writeAccess, setWriteAccess] = useState(true);

  const checkType = async () => {
    const authorId = chat.participants.findIndex(par => par._id === myViewer._id);

    if (type === 'text') {
      await chatCtl.setActionRequest(
        { type, always: true, addMessage: false },
        async (response) => {
          const msg = await chatCtl.addMessage({
            type,
            content: response.value,
            self: true,
            author: authorId,
          });
          addChatMessage(msg.createdAt, msg.content, msg.type);
        },
      );
    } else if (type === 'audio') {
      await chatCtl.setActionRequest(
        { type: 'audio', addMessage: false },
        async (response) => {
          console.log(response);
          await (response.audio
            ? chatCtl.addMessage({
              type: 'text',
              content: (
                <a href={window.URL.createObjectURL(response.audio)} download>Audio download</a>
              ),
              self: true,
            })
            : chatCtl.addMessage({
              type: 'text',
              content: response.value,
              self: true,
            }));
          setType('text');
        },
      );
    } else if (type === 'file') {
      await chatCtl.setActionRequest(
        {
          type: 'file',
          accept: 'image/*',
          multiple: true,
          addMessage: false,
        },
        async (response) => {
          const msg = await chatCtl.addMessage({
            type: 'image',
            images: response.files,
            self: true,
            author: authorId,
          });
          if (response.files && response.files.length > 0)
            addChatMessage(msg.createdAt, null, msg.type, response.files);
        },
      );
      setType('text');
    }
  };

  const returnMessageType = (msg) => {
    return {
      type: msg.type,
      content: msg.content,
      images: msg.images,
      self: msg.author !== null ? chat.participants[msg.author]._id === myViewer._id : false,
      author: msg.author,
      createdAt: moment.unix(msg.created_at / 1000),
      id: msg._id,
    };
  };

  const addTypedMessages = (chat) => {
    const chatCtlMessages = chatCtl.getMessages();

    if (chatCtlMessages.length > 0) {
      const messages = [];
      const updateMsg = {};
      chat.messages.map((msg, index) => {
        const isFound = chatCtlMessages.findIndex(cMsg => {
          if (!msg.createdAt)
            msg.createdAt = moment.unix(msg.created_at / 1000);
          const isSame = cMsg.createdAt.unix() === msg.createdAt.unix();

          if (isSame && !cMsg.hasOwnProperty('id'))
            updateMsg[index] = { ...cMsg, id: msg._id };

          return isSame;
        });

        if (isFound === -1)
          messages.push(returnMessageType(msg));

        return null;
      });

      chatCtl.addMessages(messages);
      if (JSON.stringify(updateMsg) !== '{}') {
        for (const [key, value] of Object.entries(updateMsg)) {
          chatCtl.updateMessage(key, value);
        }
      }
    } else {
      const messages = chat.messages.map(msg => returnMessageType(msg));
      chatCtl.setMessages(messages);
    }
  };

  const checkWriteAccess = () => {
    if (chat.status !== 'approved')
      return;

    const updatedAt = moment.unix(chat.updated_at / 1000)
    .add(2, 'd');

    if (updatedAt.isBefore(moment()))
      setWriteAccess(false);
  };

  useEffect(() => {
    checkType();
  }, [type]);

  useEffect(() => {
    if (!chatId) {
      setChatId(chat._id);
      checkWriteAccess();
    } else if (chatId !== chat._id) {
      chatCtl.clearMessages();
      setChatId(chat._id);
      checkWriteAccess();
    }

    addTypedMessages(chat);
  }, [chat]);

  return (
    <MuiChat
      chatId={chatId}
      chatController={chatCtl}
      setType={setType}
      participants={chat.participants}
      writeAccess={writeAccess}
    />
  );
};

export default MyChat;