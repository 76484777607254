import React from 'react';
import { Avatar, Link, makeStyles } from '@material-ui/core';
import moment from 'moment';
import Tilt from 'react-tilt';
import { faHistory, faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import { SRLWrapper, useLightbox } from 'simple-react-lightbox';

import Helpers from '../../theme/Helpers';
import CustomButton from '../Button/CustomButton';

const conf = require('../../conf');

const useStyles = makeStyles(() => ({
  avatar: {
    marginRight: 25,
    width: 80,
    height: 80,
    borderColor: '#A2895F',
  },
  row: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    padding: 10,
    margin: '5px 0',
  },
}));

const FinishedIllustratorJob = (props) => {
  const classes = useStyles();
  const { openLightbox } = useLightbox();
  const { job, setActiveJobBriefing } = props;

  const participant = job.participants[0];

  const lightBoxImages = [];
  if (job.file_uploads_illustrator)
    job.file_uploads_illustrator.map(img => {
      if (img)
        lightBoxImages.push({
          src: img,
          thumbnail: `${img}?height=100`,
        });
    });

  return (
    <>
      <div className={classes.row} key={`FinishedJobKey${job._id}`}>
        <div style={{ ...Helpers.fillRowCenter, padding: 10 }}>
          <Link href={`/profile/${participant.login}`}>
            <Avatar
              alt={participant.display_name}
              src={participant.avatar}
              className={classes.avatar}
            />
          </Link>
          <div>
            <Link
              href={`/profile/${participant.login}`}
              style={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: 24 }}
            >
              {participant.display_name}
            </Link>
            <div style={{ fontSize: 14 }}>
              {moment.unix(job.updated_at / 1000)
              .format('DD.MM.YY - HH:mm:ss')}
            </div>
          </div>
        </div>
        <div style={{ ...Helpers.fillRowCenter }}>
          <CustomButton
            style={{ padding: 10, minWidth: 40, marginRight: 20 }}
            onClick={() => setActiveJobBriefing(job)}
          >
            <FontAwesomeIcon icon={faInfo} />
          </CustomButton>
          <CustomButton
            style={{ padding: 10, minWidth: 40, marginRight: 10 }}
            onClick={() => props.history.push(`/design-job/${job._id}`)}
          >
            <FontAwesomeIcon icon={faHistory} />
          </CustomButton>
          {job.file_uploads_illustrator.map((img, idx) => (
            <Tilt className="Tilt" options={{ max: 20 }} key={idx}>
              <img
                src={`${img.replace(conf.no_cors_cdn_url, conf.cdn_url)}?height=120`}
                alt="Karte"
                style={{ margin: 5 }}
                onClick={() => openLightbox(idx)}
              />
            </Tilt>
          ))}
        </div>
      </div>
      <SRLWrapper elements={lightBoxImages} />
    </>
  );
};

export default withRouter(FinishedIllustratorJob);