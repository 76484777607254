import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { getIllustrationData } from '../../apollo/mergedQueries';
import {
  updateIllustrationState as updateIllustrationStateMutation,
} from '../../apollo/mutations';

import TopViewHeader from '../../components/TopViewHeader/TopViewHeader';
import PageTitle from '../../components/PageTitle/PageTitle';
import Loading from '../../components/Loading/Loading';
import ChatContainer from '../Chat/Chat';
import StreamerChatInfos from '../../components/Illustrator/StreamerChatInfos';
import Helpers from '../../theme/Helpers';
import SimpleReactLightbox from 'simple-react-lightbox';
import { getRandomNumber } from '../../utils/helper';
import { withRouter } from 'react-router-dom';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 1440,
    margin: 'auto',
    padding: 20,
    ...Helpers.fillRow,
  },
}));

const DesignJobContainer = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const id = props.match.params.id;

  const variables = {};
  if (id)
    variables.id = id;

  const { data, loading } = useQuery(getIllustrationData, {
    pollInterval: 60000,
    fetchPolicy: useCacheWithExpiration('getIllustrationData', variables),
    variables,
  });

  const [updateIllustrationState] = useMutation(updateIllustrationStateMutation, {
    onCompleted: () => {
      enqueueSnackbar(t('success'), { variant: 'success' });
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{ query: getIllustrationData }],
  });

  const designJob = (data) && data.getIllustrationData;
  const myViewer = (data) && data.getMyViewer;

  const random = getRandomNumber();

  return (
    <TopViewHeader>
      <PageTitle title={t('design_job')} />
      <div className={classes.container}>
        {loading ?
          <Loading />
          :
          <>
            <ChatContainer chat={designJob} myViewer={myViewer} />
            <SimpleReactLightbox>
              <StreamerChatInfos
                job={designJob}
                myViewer={myViewer}
                random={random}
                updateIllustrationState={updateIllustrationState}
              />
            </SimpleReactLightbox>
          </>
        }
      </div>
    </TopViewHeader>
  );
};

export default withRouter(DesignJobContainer);