import React, { useState } from 'react';
import {
  Avatar,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DialogContentText from '@material-ui/core/DialogContentText';
import { faEye, faPen, faTimesCircle, faUpload } from '@fortawesome/free-solid-svg-icons';
import { SRLWrapper, useLightbox } from 'simple-react-lightbox';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import { uploadIllustrationImage as uploadIllustrationImageMutation } from '../../apollo/mutations';
import { getMyIllustrationJobs } from '../../apollo/queries';

import Helpers from '../../theme/Helpers';
import CustomButton from '../Button/CustomButton';
import { rangeArray, uploadImageToS3 } from '../../utils/helper';
import Colors from '../../theme/Colors';
import DesignJobPopup from '../Dialog/DesignJobBriefingPopup';
import PopupButton from '../Button/PopupButton';
import Loading from '../Loading/Loading';

const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
    backgroundColor: '#0A0A0A',
    width: 300,
    ...Helpers.fillColCross,
  },
  link: {
    color: '#8837BF',
  },
  avatar: {
    marginRight: 10,
    borderColor: '#DEB973',
    width: 60,
    height: 60,
  },
  cardItem: {
    backgroundColor: '#171717',
    padding: 10,
    textTransform: 'uppercase',
    margin: '5px 0',
    fontSize: 14,
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
  },
  clickableIcon: {
    cursor: 'pointer',
    color: Colors.primary,
    '&:hover': {
      color: Colors.white,
    },
  },
  eyeClickableIcon: {
    cursor: 'pointer',
    color: '#ABDB50',
    marginLeft: 5,
    '&:hover': {
      color: Colors.white,
    },
  },
}));

const StreamerChatInfos = (props) => {
  const { openLightbox } = useLightbox();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const {
    job,
    myViewer,
    deleteIllustrationImage,
    updateIllustrationState,
    random,
  } = props;
  const [showInfos, setShowInfos] = useState(false);
  const [uploadLevel, setUploadLevel] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [status, setStatus] = useState(null);
  const [value, setValue] = useState('');

  const [uploadIllustrationImage] = useMutation(uploadIllustrationImageMutation, {
    onCompleted: (data) => {
      uploadImageToS3(data.uploadIllustrationImage, uploadImage, {
        'Content-Type': uploadImage.type,
      }, () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
      });
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{ query: getMyIllustrationJobs }],
  });

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const onIconClick = (level) => {
    const input = document.getElementById('file-input');

    if (input) {
      setUploadLevel(level);
      setUploadImage(null);
      input.click();
    }
  };

  const handleClickOpen = (status) => {
    setOpenDialog(true);
    setStatus(status);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const onChangeFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];

    uploadIllustrationImage({
      variables: {
        id: job._id,
        file: file.name,
        level: uploadLevel,
      },
    });

    setUploadLevel(null);
    setUploadImage(file);
  };

  const onDeleteClick = (level) => {
    deleteIllustrationImage({
      variables: {
        id: job._id,
        level,
      },
    });
  };

  const updateJob = (status, reason = null) => {
    const variables = {
      id: job._id,
      status,
    };
    if (status === 'rejected_open' && reason)
      variables.metadata = {
        rejection_reason: reason,
      };
    else if (status === 'dispute' && reason)
      variables.metadata = {
        dispute_description: reason,
      };

    updateIllustrationState({
      variables,
    });

    if (openDialog)
      setOpenDialog(false);
  };

  if (!job)
    return <Loading />;

  const streamer = job.participants[0];
  const isCostumer = streamer.login === myViewer.login;
  const canAccept = job.file_uploads_illustrator.length === 5 && job.status === 'to_approve';

  let illustratorImages = 0;
  const lightBoxImages = [], cardPositions = [];
  if (job.file_uploads_illustrator)
    job.file_uploads_illustrator.map(img => {
      if (img !== '') {
        lightBoxImages.push({
          src: `${img}?v=${random}`,
          thumbnail: `${img}?v=${random}&height=100`,
        });
        illustratorImages += 1;
      }

      cardPositions.push(lightBoxImages.length);
    });

  return (
    <div className={classes.container}>
      <div style={{ ...Helpers.fillRowCenter }}>
        <Avatar
          alt={streamer.display_name}
          src={streamer.avatar}
          className={classes.avatar}
        />
        <div style={{ textTransform: 'uppercase' }}>
          <div style={{ fontWeight: 'bold', fontSize: 18, color: Colors.white }}>
            {streamer.display_name}
          </div>
          <Link
            href={`https://twitch.tv/${streamer.login}`}
            className={classes.link}
            target="_blank"
          >
            {t('twitch_link')}
          </Link>
        </div>
      </div>
      <CustomButton
        onClick={() => setShowInfos(!showInfos)}
        style={{ marginTop: 20, width: '100%' }}
      >
        {t('briefing')}
      </CustomButton>
      <div style={{ marginTop: 20, width: '100%' }}>
        {rangeArray(1, 5)
        .map(lvl => {
          const isUploaded = job.file_uploads_illustrator && job.file_uploads_illustrator[lvl - 1] !== '';

          return (
            <div
              key={lvl}
              className={classes.cardItem}
              style={{ color: isUploaded ? Colors.white : Colors.gray }}
            >
              <div>
                {t('level_x_card', { level: lvl })}
                {isUploaded &&
                <FontAwesomeIcon
                  icon={faEye}
                  size="lg"
                  className={classes.eyeClickableIcon}
                  onClick={() => openLightbox(cardPositions[lvl - 1] - 1)}
                />
                }
              </div>
              {!isCostumer ?
                isUploaded ?
                  <div>
                    <FontAwesomeIcon
                      icon={faPen}
                      size="lg"
                      className={classes.clickableIcon}
                      style={{ marginRight: 5 }}
                      onClick={() => onIconClick(lvl)}
                    />
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      size="lg"
                      className={classes.clickableIcon}
                      onClick={() => onDeleteClick(lvl)}
                    />
                  </div>
                  :
                  <FontAwesomeIcon
                    icon={faUpload}
                    size="lg"
                    className={classes.clickableIcon}
                    onClick={() => onIconClick(lvl)}
                  />
                : null
              }
            </div>
          );
        })}
      </div>
      {isCostumer ?
        <>
          <CustomButton
            style={{ marginTop: 20, width: '100%' }}
            disabled={!canAccept}
            onClick={() => updateJob('approved')}
          >
            {t('accept_cards')}
          </CustomButton>
          <CustomButton
            style={{ marginTop: 20, width: '100%' }}
            disabled={!canAccept}
            onClick={() => handleClickOpen('reject')}
          >
            {t('reject_cards')}
          </CustomButton>
        </>
        :
        <>
          <CustomButton
            style={{ marginTop: 20, width: '100%' }}
            disabled={illustratorImages < 5 || job.status === 'to_approve' || job.status === 'approved'}
            onClick={() => updateJob('to_approve')}
          >
            {t('let_cards_check')}
          </CustomButton>
          <CustomButton
            style={{ marginTop: 20, width: '100%' }}
            disabled={job.status === 'dispute'}
            onClick={() => handleClickOpen('dispute')}
          >
            {t('dispute')}
          </CustomButton>
        </>
      }
      <>
        <input
          type="file"
          id="file-input"
          style={{ display: 'none' }}
          onChange={onChangeFile}
          accept="image/*"
        />
        <SRLWrapper elements={lightBoxImages} />
        {showInfos &&
        <DesignJobPopup job={job} handleClose={() => setShowInfos(false)} />
        }
        <Dialog open={openDialog} onClose={handleClose}>
          <DialogTitle>
            {t(`${status}_reason_title`)}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t(`${status}_sure_text`)}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label={t(`${status}_reason`)}
              type="text"
              onChange={handleChange}
              value={value}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <PopupButton onClick={handleClose}>
              {t('cancel')}
            </PopupButton>
            <PopupButton
              onClick={() => updateJob(status === 'reject' ? 'rejected_open' : 'dispute', value)}
            >
              {t('confirm')}
            </PopupButton>
          </DialogActions>
        </Dialog>
      </>
    </div>
  );
};

export default StreamerChatInfos;