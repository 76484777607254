import React from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { cardCollectionProgressByCard } from '../../apollo/queries';

import InventoryBoosterCardsList from '../../components/Inventory/InventoryBoosterCardsList';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

const InventoryBoosterCardsListContainer = () => {
  const { boosterId, cardId } = useParams();

  const variables = {
    id: cardId,
    boosterId,
  };
  const { data, loading } = useQuery(cardCollectionProgressByCard, {
    variables,
    fetchPolicy: useCacheWithExpiration('cardCollectionProgressByCard'),
    skip: !boosterId,
  });

  const cardCollectionProgress = data && data.cardCollectionProgressByCard;
  const boosterDonations = data && data.getBoosterDonations;
  const myViewer = data && data.getMyViewer;
  const booster = data && data.getBooster;

  return (
    <InventoryBoosterCardsList
      cardCollectionProgress={cardCollectionProgress}
      loading={loading}
      booster={booster}
      streamer={cardCollectionProgress && cardCollectionProgress.card.match_meta_data}
      boosterDonations={boosterDonations}
      myViewer={myViewer}
    />
  );
};

export default withRouter(InventoryBoosterCardsListContainer);