import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    background: '#0B1729',
    borderTop: '3px solid #011024',
    color: Colors.white,
    zIndex: 10,
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
  },
  menuItem: {
    ...Helpers.fillColCenter,
    padding: 8,
    color: Colors.halfWhite,
    fontSize: 14,
    flex: 1,

    '&.active': {
      color: Colors.white,
    },
  },
}));

const MobileMenuBar = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { pathname } = useLocation();
  const { menu, style } = props;

  return (
    <div className={classes.root} style={style}>
      {menu.map((item, index) => {
        if (!item.link)
          return null;

        const active = item.link === pathname || (item.subMenu && item.subMenu.find(subItem => subItem.link === pathname));

        return (
          <Link
            to={item.link}
            className={[classes.menuItem, active ? 'active' : ''].join(' ')}
            key={`MobileMenuKey${item.link}Index${index}`}
          >
            <i className={`fa-regular ${item.icon}`} />
            {t(item.text)}
          </Link>
        );
      })}
    </div>
  );
};

export default withRouter(MobileMenuBar);