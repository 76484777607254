import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, TextField } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import { checkCoinsCollectingProcess as checkCoinsCollectingProcessMutation } from '../../apollo/mutations';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import Alert from '../Alert/Alert';
import CrawledStreamerChecker from '../Header/CrawledStreamerChecker';
import AnimationButton from '../Button/AnimationButton';

const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
    paddingBottom: 0,
    color: Colors.white,
    ...Helpers.fillRow,
    ...Helpers.crossStart,
    ...Helpers.mainSpaceBetween,
  },
  textField: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(255, 255, 255, 0.12)',
      borderRadius: 6,
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.halfWhite,
    },
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
      fontSize: 14,
      fontFamily: 'Montserrat',
      '&::placeholder': {
        color: Colors.halfWhite,
      },
    },
  },
  checklist: {
    ...Helpers.fillRow,
    background: '#3d0f15',
    padding: 10,
  },
  header: {
    padding: 10,
    color: Colors.white,
  },
  body: {
    padding: 10,
  },
  title: {
    fontSize: 18,
    lineHeight: 1,
  },
  subTitle: {
    color: Colors.halfWhite,
    lineHeight: 1,
    fontSize: 14,
    marginTop: 6,
  },
  reason: {
    background: '#3e0f15',
    padding: 10,
    color: '#a2242e',
    margin: '10px 0',
    whiteSpace: 'normal',
  },
}));

const CollectingChecker = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [watchStatus, setWatchStatus] = useState(null);
  const [input, setInput] = useState('');

  const [checkCoinsCollectingProcess] = useMutation(checkCoinsCollectingProcessMutation, {
    onCompleted: (data) => {
      setLoading(false);
      setWatchStatus(data.checkCoinsCollectingProcess);
    },
    onError: (data) => {
      setWatchStatus(null);
      setLoading(false);
      enqueueSnackbar(data.message, { variant: 'error' });
    },
  });

  const checkStreamer = () => {
    if (input.length < 3)
      return;

    setLoading(true);
    setWatchStatus(null);
    checkCoinsCollectingProcess({ variables: { streamer: input } });
  };

  const checkpoints = [
    {
      title: t('check_status_1'),
      errorCode: -1,
      reasons: [
        {
          title: t('check_status_1_reason_1_title'),
          text: t('check_status_1_reason_1_text'),
        },
        {
          title: t('check_status_1_reason_2_title'),
          text: t('check_status_1_reason_2_text'),
        },
      ],
    },
    {
      title: t('check_status_2'),
      errorCode: -2,
      reasons: [
        {
          title: t('check_status_2_reason_1_title'),
          text: t('check_status_2_reason_1_text'),
        },
        {
          title: t('check_status_2_reason_2_title'),
          text: t('check_status_2_reason_2_text'),
        },
      ],
    },
    {
      title: t('check_status_3'),
      errorCode: -3,
      reasons: [
        {
          title: t('check_status_3_reason_1_title'),
          text: t('check_status_3_reason_1_text'),
        },
        {
          title: t('check_status_3_reason_2_title'),
          text: t('check_status_3_reason_2_text'),
        },
        {
          title: t('check_status_3_reason_3_title'),
          text: t('check_status_3_reason_3_text'),
        },
        {
          title: t('check_status_3_reason_4_title'),
          text: t('check_status_3_reason_4_text'),
        },
      ],
    },
    {
      title: t('check_status_4'),
      errorCode: -4,
      reasons: [
        {
          title: t('check_status_4_reason_1_title'),
          text: t('check_status_4_reason_1_text'),
        },
        {
          title: t('check_status_4_reason_2_title'),
          text: t('check_status_4_reason_2_text'),
        },
        {
          title: t('check_status_4_reason_3_title'),
          text: t('check_status_4_reason_3_text'),
        },
        {
          title: t('check_status_4_reason_4_title'),
          text: t('check_status_4_reason_4_text'),
        },
        {
          title: t('check_status_4_reason_5_title'),
          text: t('check_status_4_reason_5_text'),
        },
      ],
    },
  ];

  let reasons = null;
  if (watchStatus && watchStatus.code < 0)
    reasons = checkpoints.find(checkpoint => checkpoint.errorCode === watchStatus.code).reasons;

  return (
    <div style={{ background: 'rgba(255, 255, 255, 0.04)', borderRadius: 6 }}>
      <div className={classes.header}>
        <div className={classes.subTitle}>
          {t('collecting_checker_subtitle')}
        </div>
      </div>
      <Alert style={{
        boxShadow: 'none',
        background: 'rgba(225, 33, 56, 0.2)',
        color: Colors.halfWhite,
        padding: '20px 16px',
        margin: '0 10px',
        borderRadius: 6,
      }}>
        {t('check_alert_info')}
      </Alert>
      <div className={classes.body}>
        <div style={{ ...Helpers.fillRow, ...Helpers.mainSpaceBetween, ...Helpers.crossStart }}>
          <div style={{ width: '100%' }}>
            <div style={Helpers.fillRowCross}>
              <TextField
                variant="outlined"
                onChange={(event) => setInput(event.target.value)}
                value={input}
                className={classes.textField}
                placeholder={t('enter_streamer_name')}
              />
              <AnimationButton
                status="whiteTransparency"
                onClick={checkStreamer}
                disabled={loading}
                style={{ minWidth: 236, marginLeft: 8 }}
              >
                {t('check')}
              </AnimationButton>
            </div>
            {/*{(latestStreamData && latestStreamData.streamer) &&
            <div style={{ margin: '10px 0' }}>
              {t('currently_collecting', { streamer: latestStreamData.streamer.display_name })}
            </div>
            }*/}

            {watchStatus &&
            <div className={classes.checklist}>
              {checkpoints.map(checkpoint => {
                const isSuccess = watchStatus.code > 0 || (watchStatus.code < 0 && checkpoint.errorCode > watchStatus.code);

                return (
                  <div
                    key={`CheckpointKey${checkpoint.errorCode}`}
                    style={{ ...Helpers.fillRowCross, margin: '5px 0' }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/${isSuccess ? 'partnered' : 'x_icon'}.png`}
                      alt="Success"
                      style={{ margin: -20, width: 80 }}
                    />
                    <div style={{
                      marginLeft: 10,
                      color: isSuccess ? Colors.white : Colors.subTextColor,
                      fontWeight: 400,
                    }}
                    >
                      {checkpoint.title}
                    </div>
                  </div>
                );
              })}
            </div>
            }

            {reasons &&
            <div>
              {reasons.map((reason, index) => (
                <div key={`Reason${index}`} className={classes.reason}>
                  <div style={{ fontWeight: 'bold' }}>
                    {(index + 1)}. <span style={{ color: Colors.primary }}>{reason.title}</span>
                  </div>
                  <div>{reason.text}</div>
                </div>
              ))}
            </div>
            }
          </div>
        </div>

        <CrawledStreamerChecker />
      </div>
    </div>
  );
};

export default CollectingChecker;