import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import CircularProgress from '@material-ui/core/CircularProgress';

import PrettoSlider from '../Slider/PrettoSlider';
import TextField from '@material-ui/core/TextField';
import Colors from '../../theme/Colors';
import CustomButton from '../Button/CustomButton';
import Checkbox from '@material-ui/core/Checkbox';
import { returnMobileObj } from '../../utils/responsive';

const useStyles = makeStyles(theme => ({
  area: {
    paddingTop: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(3),
    color: '#636363',
  },
  textField: {
    backgroundColor: '#0e0e0e',
    padding: '5px 20px',
    marginTop: 20,
    borderRadius: 5,
    border: '1px solid #931b28',
    '& input': {
      color: Colors.subTextColor,
      fontSize: 14,
      '&::placeholder': {
        color: Colors.white,
      },
    },
    '&::before, &::after': {
      display: 'none',
    },
  },
  rightSideButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  variableClick: {
    color: Colors.white,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
}));

const StreamConfigTwitchBot = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { loading, hideButton, maxLevelCard, handleChange, parentState, isTabletOrMobile } = props;
  const { showMinLevelBot, minLevelBot, customMsgTwitch, shouldWhisper } = parentState;

  let levelRevolution = t('rareness_level.1')

  if (minLevelBot === 2) levelRevolution = t('rareness_level.2')
  else if (minLevelBot === 3) levelRevolution = t('rareness_level.3')
  else if (minLevelBot === 4) levelRevolution = t('rareness_level.4')
  else if (minLevelBot === 5) levelRevolution = t('rareness_level.5')

  const addVariable = (variable) => {
    handleChange(null, 'customMsgTwitch', customMsgTwitch + ' ' + variable);
  };

  return (
    <div
      className={classes.container}
         style={returnMobileObj(isTabletOrMobile, { padding: 10 })}
    >
      <FormControlLabel
        labelPlacement="start"
        style={{ marginLeft: 0, marginRight: 0 }}
        control={
          <Switch
            checked={showMinLevelBot}
            onChange={() => handleChange(null, 'showMinLevelBot', !showMinLevelBot)}
            value={showMinLevelBot}
          />
        }
        label={t('show_level_card_twitch_msg')}
      />
      {(showMinLevelBot && minLevelBot > 0) &&
      <>
        <div className={classes.area}>
          <FormControlLabel
            labelPlacement="start"
            style={{ marginLeft: 0, marginRight: 0 }}
            control={
              <Switch
                checked={shouldWhisper}
                onChange={() => handleChange(null, 'shouldWhisper', !shouldWhisper)}
                value={shouldWhisper} />
            }
            label={t('show_level_card_twitch_whisper')}
          />
          <Typography id="discrete-slider" variant="body1" gutterBottom style={{ paddingTop: 20 }}>
            {t('from_level_card_twitch_msg', { level: levelRevolution })}
          </Typography>
          <PrettoSlider
            value={minLevelBot}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            onChange={(event, newValue) => handleChange(event, 'minLevelBot', newValue)}
            marks
            step={1}
            min={1}
            max={maxLevelCard}
          />
          <Typography
            variant="h3"
            gutterBottom
            style={{ textTransform: 'none', marginTop: 20 }}
          >
            {t('fit_custom_twitch_msg')}
          </Typography>

          <Typography variant="body1" gutterBottom>
            {t('custom_alert_msg_variables_text')}<br />
            <span className={classes.variableClick} onClick={() => addVariable('%USER%')}>
              %USER%
            </span> = {t('custom_alert_msg_username')}<br />
            <span className={classes.variableClick} onClick={() => addVariable('%RARITY%')}>
              %RARITY%
            </span> = {t('custom_alert_msg_level')}
          </Typography>

          <TextField
            style={{ width: '100%' }}
            value={customMsgTwitch}
            InputProps={{
              classes: { root: classes.textField },
            }}
            onChange={(event) => handleChange(event, 'customMsgTwitch', event.target.value)}
            placeholder={t('custom_alert_msg_placeholder')} />
        </div>
        <div className={[classes.area, classes.rightSideButton].join(' ')}>
          {loading ?
            <CircularProgress color="secondary" />
            :
            <CustomButton status="primary" onClick={hideButton}>
              {t('test_card_twitch_msg_button')}
            </CustomButton>
          }
        </div>
      </>
      }
    </div>
  );
};

export default StreamConfigTwitchBot;