import { useQuery, useMutation } from '@apollo/react-hooks';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { authDiscordUser as authDiscordUserMutation } from '../../apollo/mutations';
import { getAlertCapsulesWithDiscordInfo } from '../../apollo/mergedQueries';

import AlertCapsules from '../../components/AlertCapsules/AlertCapsules';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import { parseQueryOfUrl } from '../../utils/helper';

const AlertCapsulesContainer = (props) => {
  const { t } = useTranslation();
  const params = parseQueryOfUrl(props.location.search);
  const [started, setStarted] = useState(false);
  const [page, setPage] = useState(2);
  const [match, setMatch] = useState(null);
  const { code, capsuleId } = params;
  const [showOnlyLive, setShowOnlyLive] = useState(false);
  const [type, setType] = useState(null);
  const [sorting, setSorting] = useState({
    field: 'created_at',
    order: 'DESC',
  });
  const perPage = 25;

  const variables = {
    sorting,
    type,
    show_only_live_streamers: showOnlyLive,
    pagination: { perPage, page: 1 },
    streamer: match,
  };

  const { data, loading, fetchMore } = useQuery(getAlertCapsulesWithDiscordInfo, {
    variables,
    fetchPolicy: useCacheWithExpiration('getAlertCapsulesWithDiscordInfo'),
  });

  const viewer = data && data.getMyViewer;

  useEffect(() => {
    if (code && !started && viewer) {
      setStarted(true);
      authDiscordUser({
        variables: {
          viewer: viewer._id,
          code,
        },
      });
    }
  }, [code, started, viewer]);

  const alertsRefetchQuery = () => [{ query: getAlertCapsulesWithDiscordInfo, variables }];

  const [authDiscordUser] = useMutation(authDiscordUserMutation, {
    refetchQueries: alertsRefetchQuery,
  });

  const alertCapsulesData = data && data.alertCapsules;
  const discordSetupProgress = data && data.getDiscordSetupProgress;
  const matches = data && data.alertCapsuleStreamers;

  return (
    <>
      <PageTitle title={t('capsules')} />
      <AlertCapsules
        t={t}
        loading={loading}
        capsules={alertCapsulesData && alertCapsulesData.capsules}
        totalCount={alertCapsulesData && alertCapsulesData.count}
        sorting={sorting}
        setSorting={setSorting}
        showOnlyLive={showOnlyLive}
        setShowOnlyLive={setShowOnlyLive}
        type={type}
        setType={setType}
        alertsRefetchQuery={alertsRefetchQuery}
        redeemCapsuleId={capsuleId}
        match={match}
        setMatch={setMatch}
        matches={matches}
        viewerAuth={discordSetupProgress && discordSetupProgress.viewer_auth}
        onLoadMore={async () => {
          setPage(page + 1);
          try {
            await fetchMore({
              variables: { ...variables, ...{ pagination: { perPage, page } } },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return {
                  alertCapsules: {
                    capsules: [...prev.alertCapsules.capsules, ...fetchMoreResult.alertCapsules.capsules],
                    __typename: prev.alertCapsules.__typename,
                  },
                };
              },
            });
          } catch {
          }
        }}
      />
    </>
  );
};

export default AlertCapsulesContainer;
