import React from 'react';
import Helmet from 'react-helmet';

const PageTitle = ({ title }) => {
  const defaultTitle = 'StreamHeroes';

  return (
    <Helmet>
      <title>{title ? `${title} - ${defaultTitle}` : defaultTitle}</title>
    </Helmet>
  );
};

export default PageTitle;