import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Div100vh from 'react-div-100vh';
import { useTranslation } from 'react-i18next';
import { Button, Switch } from '@material-ui/core';

import { changeCardVerification } from '../../redux/actions';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';

/**
 * The user must confirm that he has set the touch sensitivity to ensure good readability
 * of the card.
 *
 * @param props
 */
const TouchVerification = (props) => {
  const { t } = useTranslation();
  const { cardVerification, history, changeCardVerification } = props;
  const { touchSensitivityIsSet } = cardVerification;
  const [checked, setChecked] = useState(touchSensitivityIsSet);

  const onToggle = (checked) => {
    changeCardVerification({ touchSensitivityIsSet: checked });
    setChecked(checked);
  };

  return (
    <Div100vh style={{ ...Helpers.fillColCenter, width: '100vw', backgroundColor: Colors.white }}>
      <h1 style={{ fontWeight: 'bold' }}>
        {t('verification_touch_title')}
      </h1>
      <p>{t('verification_touch_body')}</p>
      <div style={{ ...Helpers.fillColCenter, ...Helpers.textCenter }}>
        <p>{t('verification_touch_label')}</p>
        <Switch onChange={(event) => onToggle(event.target.checked)} checked={checked} />
      </div>
      <div>
        <Button
          onClick={() => history.push('/verification/swipe')}
          disabled={!checked}
          fullWidth
          variant="contained"
          color="primary"
        >
          {t('verification_touch_button')}
        </Button>
      </div>
    </Div100vh>
  );
};

const mapStateToProps = (state) => {
  const { cardVerification } = state;
  return { cardVerification };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    changeCardVerification,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TouchVerification));