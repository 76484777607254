import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { SRLWrapper, useLightbox } from 'simple-react-lightbox';
import { useTranslation } from 'react-i18next';

import { getAllIllustrationChatImages } from '../../apollo/queries';
import CustomButton from '../Button/CustomButton';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

const AllChatImages = (props) => {
  const { openLightbox } = useLightbox();
  const { t } = useTranslation();
  const { chatId } = props;

  const variables = { id: chatId };
  const { data, loading } = useQuery(getAllIllustrationChatImages, {
    variables,
    fetchPolicy: useCacheWithExpiration('getAllIllustrationChatImages', variables),
  });

  const images = (data) && data.getAllIllustrationChatImages;

  if (loading)
    return null;

  const imagesFound = images && images.length > 0;
  const lightBoxImages = [];
  if (images)
    images.map(img => {
      lightBoxImages.push({
        src: img,
        thumbnail: `${img}?height=100`,
      });
    });

  return (
    <div>
      <CustomButton onClick={() => imagesFound ? openLightbox(0) : null}>
        {t('images')} ({imagesFound ? images.length : 0})
      </CustomButton>
      <SRLWrapper elements={lightBoxImages} />
    </div>
  );
};

export default AllChatImages;