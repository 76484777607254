import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';
import SVGIcon from '../SVGIcon/SVGIcon';
import Colors from '../../theme/Colors';
import AnimationButton from '../Button/AnimationButton';
import HoverIcon from '../Icon/HoverIcon';
import GemsPurchaseDialog from './GemsPurchaseDialog';
import { getStringPrice } from '../../utils/helper';
import GemsPayPremium from './GemsPayPremium';

import conf from '../../conf.json';

const useStyles = makeStyles((theme) => ({
  container: {
    boxShadow: '0px 10px 36px #0000003D',
    width: 400,
    background: '#142134',
    borderRadius: 4,
    '& span': {
      color: Colors.white,
    },

    [theme.breakpoints.down('md')]: {
      width: '100vw',
    },
  },
  listItem: {
    padding: '18.5px 16px',
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
}));

const GemsPayList = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { gems, onClose, gemList } = props;
  const [showBuy, setShowBuy] = useState(null);

  return (
    <>
      <div className={classes.container}>
        <div style={{
          position: 'relative',
          ...Helpers.fillColCenter,
          padding: 14,
          borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
        }}>

          <HoverIcon
            icon="fa-light fa-circle-xmark" hoverIcon="fa-solid fa-circle-xmark"
            color={Colors.white}
            style={{ fontSize: 30, position: 'absolute', right: 16, top: 18 }}
            onClick={onClose}
          />

          <div style={{ fontWeight: 600 }}>
            {t('gems_charging')}
          </div>
          <div style={{ ...Helpers.fillRowCenter, fontSize: 14 }}>
            <div>
              {t('you_have')}
            </div>
            <div style={{ margin: '0 8px' }}>
              <SVGIcon
                src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
                height={20}
                width={15}
              />
            </div>
            <div style={{ fontWeight: 600 }}>
              {t('x_gems', { count: gems })}
            </div>
          </div>
        </div>
        <div
          style={{
            padding: 16,
            fontSize: 14,
            borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
          }}>
          <div>
            <i
              className="fa-light fa-hand-holding-heart"
              style={{ fontSize: 18, marginRight: 8 }}
            />
            {t('support_streamer_with_gems')}
          </div>
          <div style={{ color: Colors.halfWhite, marginTop: 10, lineHeight: 1.5 }}>
            {t('when_you_use_gems')}
          </div>
        </div>
        <div
          style={{
            padding: '12.5px 16px',
            color: Colors.halfWhite,
            fontSize: 12,
            borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
          }}
          dangerouslySetInnerHTML={{ __html: t('prices_incl_vat') }}
        />
        {gemList && gemList.map(item => (
          <div
            key={`GemItemsKey${item._id}`}
            className={classes.listItem}
            style={item.saving ? { padding: '8.5px 16px 8px' } : {}}
          >
            <div style={{ fontSize: 14, fontWeight: 600, ...Helpers.fillRowCenter }}>
              <SVGIcon
                src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
                style={{ marginRight: 8 }}
                height={25}
                width={19}
              />
              {t('x_gems', { count: item.amount.toLocaleString() })}
            </div>
            <div style={Helpers.fillColCenter}>
              <AnimationButton
                status="neonPink"
                style={{ width: 86 }}
                onClick={() => setShowBuy(item)}
              >
                {getStringPrice(item.costs / 100)} €
              </AnimationButton>
              {item.saving &&
              <div style={{ marginTop: 5, fontSize: 12, fontWeight: 500 }}>
                {t('x_discount', { discount: item.saving })}
              </div>
              }
            </div>
          </div>
        ))}

        <GemsPayPremium onClose={onClose} />
      </div>

      {showBuy &&
      <GemsPurchaseDialog gemItem={showBuy} onClose={() => setShowBuy(null)} t={t} />
      }
    </>
  );
};

export default GemsPayList;