import React from 'react';

const Imprint = () => {
  return (
    <>
      <p>
        <h1>Imprint</h1>
      </p>
      <p>
        Streamheroes GmbH<br />
        <br />
        Triererstraße 5<br />
        66839 Schmelz<br />
        Germany<br />
        <br />
        commercial register Saarbrücken HRB 106787<br />
        <br />
        UID: DE335845977<br />
        <br />
        Managing Director: Thomas Rafelsberger, Lukas Hoffmann
      </p>
      <p>
        <b>Our contact details</b>
      </p>
      <p>
        Web: www.streamheroes.gg<br />
        <br />
        E-mail: support@streamheroes.gg<br />
        <br />
        Tel: +43 650 4989426
      </p>

      <p>
        <h2>Disclaimer</h2>
      </p>
      <p>
        This disclaimer is to be regarded as part of the Internet offer, from which reference
        was made to this website. If sections or individual terms of this statement are not
        legal or correct, the content or validity of the other parts remain uninfluenced by this
        fact.
      </p>

      <p>
        <h2>Liability for contents of this website</h2>
      </p>
      <p>
        The contents of our pages were created with great care. For the accuracy, completeness
        and timeliness of the contents, however, we cannot guarantee. As a service provider, we
        are responsible for our own content on these pages under the general laws. However, we
        are not obligated to monitor transmitted or stored third-party information or to
        investigate circumstances that indicate illegal activity. Obligations to remove or block
        the use of information under general law remain unaffected. However, liability in this
        regard is only possible from the moment of knowledge of a specific infringement. Upon
        notification of appropriate violations, we will remove this content immediately.
      </p>

      <p>
        <h2>Liability for links to third party websites</h2>
      </p>
      <p>
        Our offer contains links to external websites. We have no influence on the content of
        these external websites. Therefore, we cannot assume any liability for these external
        contents. The content of the linked pages is always the responsibility of the respective
        provider or operator of the pages. The linked pages were checked for possible legal
        violations at the time of linking. Illegal content was not recognizable at the time of
        linking. However, a permanent content control of the linked pages is not reasonable
        without concrete evidence of a violation of law. Upon notification of violations, we
        will remove such links immediately.
      </p>

      <p>
        <h2>Copyright</h2>
      </p>
      <p>
        The operators of this website endeavor to always observe the copyrights of others or to
        use self-created and license-free works. The content created by the site operators and
        works on this website are subject to copyright. Third party contributions are marked as
        such. The duplication, processing, distribution and any kind of exploitation outside the
        limits of copyright require the written consent of the respective author or creator.
        Downloads and copies of this site are for private, non-commercial use only.
      </p>
    </>
  );
};

export default Imprint;