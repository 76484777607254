import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import Helpers from '../../theme/Helpers';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';

const useStyles = makeStyles(() => ({
  badge: {
    width: 54,
    height: 69,
    ...Helpers.fillColCenter,
    fontSize: 30,
    fontWeight: 600,
    background: 'rgba(255, 255, 255, 0.12)',
    borderRadius: 6
  },
  mainTimeArea: {
    ...Helpers.fillRow,

    display: 'flex',
    gap: 26,
  },
}));

const HubSeasonCountdown = (props) => {
  const classes = useStyles();
  const { t, seasonData } = props;

  const untilMinutes = moment(parseInt(seasonData && seasonData.available_until))
  .diff(moment(), 'minutes');
  const [counter, setCounter] = useState(untilMinutes > 0 ? untilMinutes : 0);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 60000);
  }, [counter]);

  let d = 0, h = 0, m = 0;
  if (counter > 0) {
    d = Math.floor(counter / (60 * 24));
    h = Math.floor(counter % (60 * 24) / 60);
    m = Math.floor(counter % 60);
  }

  const getAreaTime = (unit, text) => {
    if (unit > 99)
      unit = 99;

    return (
      <div style={{ ...Helpers.fillColCenter }}>
        <div style={{ ...Helpers.fillRow, display: 'flex', gap: 4 }}>
          <CustomBackdropFilter
            className={classes.badge}
            filter={'blur(50px)'}
            canvasFallback={true}
            html2canvasOpts={{
              allowTaint: true,
            }}
          >
            {unit > 9 ? unit.toString()
            .charAt(0) : 0}
          </CustomBackdropFilter>
          <CustomBackdropFilter
            className={classes.badge}
            filter={'blur(50px)'}
            canvasFallback={true}
            html2canvasOpts={{
              allowTaint: true,
            }}
          >
            {unit > 9 ? unit.toString()
            .charAt(1) : unit}
          </CustomBackdropFilter>
        </div>
        <div style={{ fontSize: 12, marginTop: 2 }}>
          {text}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div style={{ paddingBottom: 17, marginRight: 18 }}>
        {t('season_ends_in')}:
      </div>
      <div className={classes.mainTimeArea}>
        {getAreaTime(d, t('days'))}
        {getAreaTime(h, t('hours'))}
        {getAreaTime(m, t('minutes'))}
      </div>
    </div>
  );
};

export default HubSeasonCountdown;