import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PagePopup from '../../components/Dialog/PagePopup';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';
import ClosePopupButton from '../Button/ClosePopupButton';
import AnimationButton from '../Button/AnimationButton';
import { resetBoosterData } from '../../redux/actions';

const useStyles = makeStyles(() => ({
  innerContainer: {
    ...Helpers.fillColCenter,
    width: '100vw',
    height: '100vh',
    color: Colors.white,
  },
  selectionArea: {
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 6,
    width: 350,
    margin: '0 25px',
    cursor: 'pointer',
    transition: 'all 0.3s ease-out',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.2)',
    },
    '& > div': {
      padding: '70px 0 38px',
      ...Helpers.fillColCenter,
    },
  },
  smallText: {
    background: 'rgba(255, 255, 255, 0.04)',
    padding: '4px 10px',
    fontSize: 12,
    color: Colors.halfWhite,
    borderRadius: 4,
    marginBottom: 30,
  },
}));

const AlertDecisionPopup = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { handleClose, setAlertToolTypeData, alertTool } = props;

  const onClick = (type) => {
    setAlertToolTypeData(type, true);
    setAlertToolTypeData('showStyleSettings', type.replace('show', '')
    .toLowerCase());
    handleClose();
  };

  return (
    <PagePopup>
      <div className={classes.innerContainer}>
        <ClosePopupButton handleClose={handleClose} style={{ right: 50, top: 50 }} />

        <div style={{ fontWeight: 500, fontSize: 26 }}>
          {t('select_where_to_post')}
        </div>
        <div style={{ color: Colors.halfWhite, marginTop: 10 }}>
          {t('choose_on_which_platform')}
        </div>

        <div style={{ marginTop: 60, ...Helpers.fillRowCenter }}>
          <CustomBackdropFilter
            className={classes.selectionArea}
            filter={'blur(50px)'}
            canvasFallback={true}
            html2canvasOpts={{
              allowTaint: true,
            }}
          >
            <div
              onClick={alertTool.showTwitch ? null : () => onClick('showTwitch')}
              style={{ opacity: alertTool.showTwitch ? 0.3 : 1 }}
            >
              <i className="fa-brands fa-twitch" style={{ fontSize: 94, color: Colors.twitch }} />

              <div style={{ margin: '45px 0 14px' }}>
                {t('post_on_twitch')}
              </div>

              <div style={{
                color: Colors.halfWhite,
                maxWidth: 302,
                fontSize: 14,
                ...Helpers.textCenter,
                marginBottom: 28,
              }}>
                {t('post_on_twitch_desc')}
              </div>

              <AnimationButton
                status="whiteTransparency"
                style={{ padding: '11px 48px', width: 'calc(100% - 96px)' }}
                disabled={alertTool.showTwitch}
              >
                {t('select')}
              </AnimationButton>
            </div>
          </CustomBackdropFilter>

          <CustomBackdropFilter
            className={classes.selectionArea}
            filter={'blur(50px)'}
            canvasFallback={true}
            html2canvasOpts={{
              allowTaint: true,
            }}
          >
            <div
              onClick={alertTool.showDiscord ? null : () => onClick('showDiscord')}
              style={{ opacity: alertTool.showDiscord ? 0.3 : 1 }}
            >
              <i className="fa-brands fa-discord" style={{ fontSize: 94, color: Colors.discord }} />

              <div style={{ margin: '45px 0 14px' }}>
                {t('post_on_discord')}
              </div>

              <div style={{
                color: Colors.halfWhite,
                maxWidth: 302,
                fontSize: 14,
                ...Helpers.textCenter,
                marginBottom: 28,
              }}>
                {t('post_on_discord_desc')}
              </div>

              <AnimationButton
                status="whiteTransparency"
                style={{ padding: '11px 48px', width: 'calc(100% - 96px)' }}
                disabled={alertTool.showDiscord}
              >
                {t('select')}
              </AnimationButton>

            </div>
          </CustomBackdropFilter>
        </div>
      </div>
    </PagePopup>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    resetBoosterData,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(AlertDecisionPopup);