import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';

import { getTypeCards, myCollectedPointsForType } from '../../apollo/queries';

import Loading from '../../components/Loading/Loading';
import ErrorMessage from '../../components/ErrorBoundary/ErrorMessage';
import Helpers from '../../theme/Helpers';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import EventStreamerCards from '../../components/Streamer/EventStreamerCards';
import { getMaximalLevelOfCards } from '../../utils/helper';

import conf from '../../conf.json';

const useStyles = makeStyles({
  container: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    flexWrap: 'wrap',
  },
});

function CategoryCardsContainer(props) {
  const classes = useStyles();
  const { game, isTabletOrMobile } = props;

  const getTypeCardsQuery = useQuery(getTypeCards, {
    variables: { game: game._id },
    fetchPolicy: useCacheWithExpiration('getTypeCards', { game: game._id }),
  });
  const myCollectedPointsQuery = useQuery(myCollectedPointsForType, {
    variables: { game: game._id },
    fetchPolicy: useCacheWithExpiration('myCollectedPointsForType', { game: game._id }),
    pollInterval: conf.poll_intervals.long_refresh,
  });
  const { loading, error, data } = getTypeCardsQuery;

  if (loading || myCollectedPointsQuery.loading) return <Loading />;
  if (error || myCollectedPointsQuery.error) return <ErrorMessage error={error} />;

  const cards = (data) && data.getTypeCards;
  const myCollectedPoints = (myCollectedPointsQuery.data) && myCollectedPointsQuery.data.viewerRedeemedPoints;

  const coinsInformation = {
    maxLevelCard: getMaximalLevelOfCards(cards),
    viewerRedeemedPoints: myCollectedPoints,
  };

  if (coinsInformation && !coinsInformation.viewerRedeemedPoints)
    coinsInformation.viewerRedeemedPoints = {
      points_season: 0,
      redeemed_points: 0,
    };

  return (
    <div className={isTabletOrMobile ? '' : classes.container}
         style={{ marginBottom: isTabletOrMobile ? 40 : 0 }}>
      <EventStreamerCards
        cards={cards}
        coinsInformation={coinsInformation}
        isTabletOrMobile={isTabletOrMobile}
      />
    </div>
  );
}

export default CategoryCardsContainer;