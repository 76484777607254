import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { getMyViewer } from '../../apollo/queries';

import AdminArea from '../../components/Admin/AdminArea';
import NotFoundPage from '../../components/NotFoundPage/NotFoundPage';
import { changeHasAdminRights } from '../../redux/actions';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import Loading from '../../components/Loading/Loading';

const AdminContainer = (props) => {
  const { main } = props;
  const { tab } = useParams();

  const { data, loading } = useQuery(getMyViewer, {
    fetchPolicy: useCacheWithExpiration('getMyViewer'),
  });

  const myViewer = (data) && data.getMyViewer;
  const roles = (myViewer) && myViewer.streamheroes_role;

  if (loading)
    return <Loading />

  if (roles && (roles.includes('admin') || roles.includes('marketing')) && !main.hasAdminRights)
    props.changeHasAdminRights(true);

  if (roles.includes('admin') || roles.includes('marketing'))
    return <AdminArea roles={roles} tab={tab} />;
  else
    return <NotFoundPage />;
};

const mapStateToProps = (state) => {
  const { main } = state;
  return { main };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    changeHasAdminRights,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminContainer));