import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import { getAllCrawledStreamersOfAViewer } from '../../apollo/queries';
import { changeStreamerForEnergyCollection as changeStreamerForEnergyCollectionMutation } from '../../apollo/mutations';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import CustomButton from '../Button/CustomButton';
import Loading from '../Loading/Loading';
import CardPopup from '../Dialog/CardPopup';
import PopupButton from '../Button/PopupButton';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import AnimationButton from '../Button/AnimationButton';

const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
    paddingBottom: 0,
    color: Colors.white,
    ...Helpers.fillRow,
    ...Helpers.crossStart,
    ...Helpers.mainSpaceBetween,
  },
  formInput: {
    width: 200,
    margin: 5,
    background: '#3d0f15',
    borderRadius: 5,
    color: '#453738',
  },
  checklist: {
    ...Helpers.fillCol,
    background: '#3d0f15',
    padding: 20,
    marginLeft: 20,
  },
  header: {
    background: 'linear-gradient(to right, #7d091c, #780c1e, #5c0d1b, #440a13)',
    padding: 20,
    position: 'relative',
    color: Colors.white,
  },
  body: {
    padding: '20px 40px',
    background: 'linear-gradient(to top right, #6d0e1e, #b40b28, #620f1c)',
    color: '#e094a1',
  },
  title: {
    fontSize: 30,
    fontFamily: 'Bebas Neue',
    lineHeight: 1,
  },
  subTitle: {
    color: '#e9a5b1',
    lineHeight: 1,
    whiteSpace: 'break-spaces',
  },
  reason: {
    background: '#3e0f15',
    padding: 10,
    color: '#a2242e',
    margin: '10px 0',
    whiteSpace: 'normal',
  },
}));

const CrawledStreamerChecker = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);

  const { data } = useQuery(getAllCrawledStreamersOfAViewer, {
    fetchPolicy: useCacheWithExpiration('getAllCrawledStreamersOfAViewer', {}, 5 * 60000),
  });
  const crawledStreamers = data && data.getAllCrawledStreamersOfAViewer;

  let possibleStreamers = [];
  if (crawledStreamers && possibleStreamers.length === 0) {
    if (crawledStreamers.possible_streamers && crawledStreamers.possible_streamers.length > 0)
      possibleStreamers = [...crawledStreamers.possible_streamers];
    if (crawledStreamers.energy_for) {
      possibleStreamers.unshift(crawledStreamers.energy_for);

      if (!value)
        setValue(crawledStreamers.energy_for._id);
    }
  }

  const [changeStreamerForEnergyCollection] = useMutation(changeStreamerForEnergyCollectionMutation, {
    onCompleted: () => {
      setLoading(false);
    },
    onError: (data) => {
      setLoading(false);
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{ query: getAllCrawledStreamersOfAViewer }],
  });

  const changeStreamer = () => {
    if (!value)
      return null;

    setLoading(true);
    changeStreamerForEnergyCollection({ variables: { streamerId: value } });
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  if (possibleStreamers.length <= 1)
    return null;

  return (
    <>
      <div style={{ padding: 0, marginTop: 18 }}>
        <AnimationButton
          status="neonGreen"
          style={{ width: '100%', borderRadius: 4 }}
          onClick={() => setDialogOpen(true)}
        >
          {t('crawled_streamer_checker_button')}
        </AnimationButton>
      </div>
      {dialogOpen &&
      <CardPopup
        handleClose={() => setDialogOpen(false)}
        style={{
          backgroundColor: 'transparent',
          width: 'auto',
          maxHeight: 'auto',
          overflow: 'auto',
          padding: 0,
          maxWidth: 500,
        }}
      >
        <div className={classes.header}>
          <FontAwesomeIcon
            icon={faTimes}
            size="2x"
            style={{ position: 'absolute', cursor: 'pointer', right: 15 }}
            onClick={() => setDialogOpen(false)}
          />
          <div className={classes.title}>
            {t('crawled_streamer_checker_title')}
          </div>
          <div className={classes.subTitle}>
            {t('crawled_streamer_checker_subtitle')}
          </div>
        </div>
        <div className={classes.body}>
          <div style={{ whiteSpace: 'break-spaces' }}>
            {t('crawled_streamer_checker_text')}
          </div>
          {loading ?
            <Loading />
            :
            <>
              {crawledStreamers &&
              <div style={{
                fontWeight: 'normal',
                marginTop: 10,
                fontStyle: 'italic',
                whiteSpace: 'break-spaces',
              }}>
                {t('current_streamer_collect_energy')} {crawledStreamers.energy_for.display_name}
              </div>
              }
              <FormControl component="fieldset" style={{ margin: '20px 0' }}>
                <FormLabel component="legend" style={{ color: Colors.white }}>
                  {t('streamer')}
                </FormLabel>
                <RadioGroup name="streamer" value={value} onChange={handleChange}>
                  {possibleStreamers.map(item =>
                    <FormControlLabel
                      value={item._id}
                      control={<Radio style={{ color: Colors.black }} />}
                      label={item.display_name}
                      key={item.login}
                    />,
                  )}
                </RadioGroup>
              </FormControl>
              <PopupButton onClick={changeStreamer}>
                {t('confirm')}
              </PopupButton>
            </>
          }
        </div>
      </CardPopup>
      }
    </>
  );
};

export default CrawledStreamerChecker;