import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';

import Colors from '../../theme/Colors';
import HoverIcon from '../Icon/HoverIcon';

const useStyles = makeStyles(() => ({
  textField: {
    width: 330,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
      borderRadius: 6,
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.halfWhite,
    },
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
      fontSize: 14,
      fontFamily: 'Montserrat',
      '&::placeholder': {
        color: Colors.white,
      },
    },
  },
  optionItem: {
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 20px)',
    padding: 10,
    cursor: 'pointer',
    transition: 'all 0.3s ease-out',
    background: 'rgba(255, 255, 255, 0.08)',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.16)',
    },
  },
  avatar: {
    width: 30,
    height: 30,
    border: 'none',
    marginRight: 8,
  },
  resultsMenu: {
    display: 'none',
    position: 'absolute',
    width: '100%',
    top: '100%',
    left: 0,
    background: 'rgba(255, 255, 255, 0.08)',
    borderRadius: 4,
    zIndex: 1,
  },
  searchArea: {
    position: 'relative',
    '&:hover $resultsMenu': {
      display: 'initial',
    },
  },
}));

const CardShopStreamerSearch = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { results, search, setSearch, loading, onClick } = props;

  return (
    <div className={classes.searchArea}>
      <TextField
        variant="outlined"
        onChange={(event) => setSearch(event.target.value)}
        value={search}
        className={classes.textField}
        placeholder={t('search_by', {
          match: t('streamer'),
        })}
        InputProps={{
          endAdornment: (
            <>
              {loading &&
              <CircularProgress color="secondary" size={20} />
              }
              {search.length > 0 &&
              <HoverIcon
                icon="fa-light fa-xmark"
                color={Colors.halfWhite}
                hoverColor={Colors.white}
                onClick={() => {
                  onClick(null);
                  setSearch('');
                }}
              />
              }
            </>
          ),
          classes: {
            input: classes.input,
          },
        }}
      />
      {results &&
      <div className={classes.resultsMenu}>
        {results.map(option => (
          <div
            className={classes.optionItem}
            onClick={() => {
              onClick(option._id);
              setSearch(option.display_name);
            }}
          >
            <Avatar
              className={classes.avatar}
              alt={option.login}
              src={option.profile_image_url}
            />
            {option.display_name}
          </div>
        ))}
      </div>
      }
    </div>
  );
};

export default CardShopStreamerSearch;