export const fragmentTypes = {
  '__schema': {
    'types': [
      {
        'kind': 'UNION',
        'name': 'Match',
        'possibleTypes': [
          {
            'name': 'Streamer',
          },
          {
            'name': 'Game',
          },
          {
            'name': 'Organisation',
          },
        ],
      },
      {
        'kind': 'UNION',
        'name': 'MatchCard',
        'possibleTypes': [
          {
            'name': 'Card',
          },
        ],
      },
    ],
  },
};