import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { getUnopenedBoosters } from '../../apollo/mergedQueries';

import PageTitle from '../../components/PageTitle/PageTitle';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import Booster from '../../components/Booster/Booster';

const BoosterContainer = () => {
  const { t } = useTranslation();
  const defaultChecked = {
    streamer: true,
    game: false,
    sh: false,
  };
  const [sorting, setSorting] = useState({
    field: 'created_at',
    order: 'DESC',
  });
  const [match, setMatch] = useState(null);
  const [page, setPage] = useState(2);
  const perPage = 25;

  const variables = {
    pagination: {
      page: 1,
      perPage,
    },
  };

  const { data, loading, fetchMore } = useQuery(getUnopenedBoosters, {
    variables,
    fetchPolicy: useCacheWithExpiration('getUnopenedBoosters'),
  });

  const booster = data && data.getUnopenedBoosters;
  const boosterCount = data && data.getUnopenedBoosterCount;

  return (
    <>
      <PageTitle title={t('booster')} />
      <Booster
        booster={booster}
        t={t}
        loading={loading}
        hasMore={boosterCount && boosterCount > booster.length}
        /*sorting={sorting}
        setSorting={setSorting}
        defaultChecked={defaultChecked}
        setMatch={setMatch}*/
        onLoadMore={async () => {
          setPage(page + 1);
          try {
            await fetchMore({
              variables: { ...variables, ...{ pagination: { perPage, page } } },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                  getUnopenedBoosters: [...prev.getUnopenedBoosters, ...fetchMoreResult.getUnopenedBoosters],
                });
              },
            });
          } catch {
          }
        }}
      />
    </>
  );
};

export default withRouter(BoosterContainer);