import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { Sticky } from 'react-sticky';

import Helpers from '../../theme/Helpers';
import AnimationButton from '../Button/AnimationButton';
import BlueWhiteSwitch from '../Switch/BlueWhiteSwitch';
import CardShopMatchFilterItem from './CardShopMatchFilterItem';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';
import CardShopFilterMatches from './CardShopFilterMatches';

const useStyles = makeStyles((theme) => ({
  item: {
    background: '#FFFFFF0A',
    borderRadius: 4,
    padding: '11px 24.5px',
    fontSize: 12,
    fontWeight: 500,
    transition: 'all 0.3s ease-out',
    border: '1px solid transparent',
    cursor: 'pointer',
    position: 'relative',
    ...Helpers.fillRowCenter,
    marginRight: 10,
    '&:hover': {
      background: '#FFFFFF1F',
    },
    '&.active': {
      background: '#FFFFFF0A',
      borderColor: '#FFFFFF29',
    },

    [theme.breakpoints.down('md')]: {
      padding: 8,
    },
  },
  container: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    background: 'rgba(255, 255, 255, 0.04)',
    padding: '16px 32px',
    overflow: 'initial',

    '& > div:first-child': {
      ...Helpers.fillRowCross
    },

    [theme.breakpoints.down('md')]: {
      ...Helpers.fillColCenter,
      padding: 8,

      '& > div:first-child': {
        display: 'grid',
        gap: 4,
        gridTemplateColumns: 'repeat(2, 1fr)',
      },

      '& > button': {
        marginTop: 4,
      },
    },
  },
}));

const CardShopMatchFilters = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    isLegacy, setIsLegacy,
    showPurchasedCards, setShowPurchasedCards,
    sorting, setSorting,
    quality, setQuality,
    rarity, setRarity,
    isSeasonal, setIsSeasonal,
    showOnlyLive, setShowOnlyLive,
    match, setMatch,
    matches,
    noPrices, noQuality, noRarity,
  } = props;

  const reset = () => {
    if (setIsLegacy)
      setIsLegacy(false);
    if (setShowPurchasedCards)
      setShowPurchasedCards(true);
    setSorting({
      field: 'created_at',
      order: 'DESC',
    });
    if (!noQuality)
      setQuality({
        min: 0,
        max: 100,
      });
    if (!noRarity)
      setRarity([]);
    if (setMatch)
      setMatch(null);
    if (setShowOnlyLive)
      setShowOnlyLive(false);
  };

  return (
    <Sticky topOffset={-65}>
      {({ style, isSticky }) =>
        <div style={{
          position: 'relative',
          ...style,
          marginTop: isSticky ? 65 : 4,
          zIndex: 10,
          width: `calc(100% - ${style.left}px)`,
        }}>
          <CustomBackdropFilter
            className={classes.container}
            filter={'blur(50px)'}
            canvasFallback={true}
            html2canvasOpts={{
              allowTaint: true,
            }}
          >
            <div>
              {matches && matches.length > 0 &&
              <CardShopFilterMatches
                list={matches}
                value={match}
                setMatch={setMatch}
                type="streamer"
                t={t}
              />
              }

              <CardShopMatchFilterItem
                mainClassName={classes.item}
                setSorting={setSorting}
                sorting={sorting}
                setRarity={setRarity}
                rarity={rarity}
                setQuality={setQuality}
                quality={quality}
                noPrices={noPrices}
                noQuality={noQuality}
                noRarity={noRarity}
              />
              {showPurchasedCards !== undefined &&
              <div className={classes.item}
                   onClick={() => setShowPurchasedCards(!showPurchasedCards)}>
                <BlueWhiteSwitch checked={showPurchasedCards} name="showPurchasedCards-check" />
                <div style={{ marginLeft: 10 }}>
                  {t('purchased_cards')}
                </div>
              </div>
              }
              {isLegacy !== undefined &&
              <div className={classes.item} onClick={() => setIsLegacy(!isLegacy)}>
                <BlueWhiteSwitch checked={isLegacy} name="isLegacy-check" />
                <div style={{ marginLeft: 10 }}>
                  {t('legacy')}
                </div>
              </div>
              }
              {isSeasonal !== undefined &&
              <div className={classes.item} onClick={() => setIsSeasonal(!isSeasonal)}>
                <BlueWhiteSwitch checked={isSeasonal} name="isSeasonal-check" />
                <div style={{ marginLeft: 10 }}>
                  {t('seasonal_cards')}
                </div>
              </div>
              }
              {showOnlyLive !== undefined &&
              <div className={classes.item} onClick={() => setShowOnlyLive(!showOnlyLive)}>
                <BlueWhiteSwitch checked={showOnlyLive} name="setShowOnlyLive-check" />
                <div style={{ marginLeft: 10 }}>
                  {t('show_only_live')}
                </div>
              </div>
              }
            </div>
            <AnimationButton
              status="transparent"
              style={{ fontFamily: 'Montserrat', fontSize: 12 }}
              onClick={reset}
            >
              <i className="fa-regular fa-arrows-rotate"
                 style={{ fontSize: 14, marginRight: 10 }} />
              {t('reset_all_filters')}
            </AnimationButton>
          </CustomBackdropFilter>
        </div>
      }
    </Sticky>
  );
};

export default CardShopMatchFilters;