import { ConnectivityStatus } from '@prismadelabs/prismaid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Fade } from '@material-ui/core';
import Div100vh from 'react-div-100vh';
import anime from 'animejs';

import { changeSwipeVerification } from '../../redux/actions';
import ScaledImage from './layout/ScaledImage';
import PositionedScaledImage from './layout/PositionedScaledImage';
import FadeChildComponents from './layout/FadeChildComponents';
import SDKSingleton from './classes/SDK';
import conf from '../../conf.json';

/**
 * Swipe area in which the graphics are displayed as well as the Prismade Labs SDK performs
 * the card verification.
 *
 * @param props
 */
const SwipeField = (props) => {
  const { t } = useTranslation();
  const { scaleFactor, swipeVerification, changeSwipeVerification } = props;
  const { swipeResults, showWarningBackground, count } = swipeVerification;
  const [redirect, setRedirect] = useState(null);
  const [showDot, setShowDot] = useState(false);
  const [showRedAlert, setShowRedAlert] = useState(false);
  const [showGreenAlert, setShowGreenAlert] = useState(false);
  const [errorCount, setErrorCount] = useState(0);

  const preDir = `${conf.cdn_url}/card-verification/swipe`;

  const sdk = SDKSingleton.getInstance().sdk;

  useEffect(() => {
    changeSwipeVerification({ networkStatus: ConnectivityStatus.ok });

    sdk.getProgressSubject()
    .subscribe((response) => {
      console.log('*) progress:', response.progress);

      changeSwipeVerification({ progress: response.progress });
    });

    sdk
    .getConnectivitySubject()
    .subscribe((response) => {
      console.log('*) connectivity response:', response.status);

      if (response.status === null) return;

      changeSwipeVerification({ networkStatus: response.status });
    });

    sdk.getDetectionSuccessSubject()
    .subscribe((response) => {
      console.log('*) nextScreen:', response.rawData);

      if (redirect) return;

      flashGreenAlert();
      setRedirect('/verification/success');
    });

    sdk.getDetectionErrorSubject()
    .subscribe((response) => {
      console.log('*) detection error:', response.description());

      if (redirect) return;

      response.hints.forEach((hint) => {
        console.log('*) hint:', hint.description());
      });

      if (errorCount >= 4) {
        flashRedAlert();
        setRedirect('/verification/failure');
      } else {
        flashRedAlert();

        if (response.hints[0]) {
          updateMessages({
            title: t('verification_swipe_title'),
            message: t('swipe:' + response.hints[0].code),
          });
        }

        setErrorCount(errorCount + 1);
      }
    });

    sdk.getInteractionSubject()
    .subscribe((response) => {
      console.log(
        '*) interaction event:',
        response.event,
        response.activeSignals,
      );

      switch (response.event) {
        case 'started':
          setShowDot(true);

          if (count === 0)
            updateMessages({
              title: t('verification_swipe_title'),
              message: t('verification_swipe_body'),
            });

          break;
        case 'changed':
          if (showWarningBackground)
            changeSwipeVerification({ showWarningBackground: false });

          break;
        case 'complete':
          setShowDot(false);

          if (showWarningBackground) {
            changeSwipeVerification({
              showWarningBackground: false,
              showInteractiveHelp: true,
            });

            updateMessages({
              title: t('verification_swipe_title'),
              message: t('verification_swipe_body'),
            });
          }

          break;
        default:
          break;
      }
    });

    const screen = document.querySelector('#swipeScreen');
    if (screen) {
      console.log('attaching sdk...');
      sdk.attachToElement(screen);
    }

    setTimeout(() => startDemoAnimation(), 1000);
  }, []);

  const updateMessages = (result) => {
    const tmp = [...swipeResults];
    result.shouldRender = tmp.length === 0;
    tmp.push({ ...result });
    changeSwipeVerification({
      swipeResults: tmp,
      count: count + 1,
    });
  };

  const flashRedAlert = () => {
    setShowRedAlert(true);
    setTimeout(() => setShowRedAlert(false), 155);
  };

  const flashGreenAlert = () => {
    setShowGreenAlert(true);
    setTimeout(() => setShowGreenAlert(false), 155);
  };

  const startDemoAnimation = () => {
    anime({
      targets: '#hologram',
      opacity: 1.0,
      duration: 500,
      easing: 'linear',
    });
    anime({
      targets: '#hologram',
      scale: 1.8,
      duration: 1000,
      direction: 'alternate',
      easing: 'easeInOutQuad',
    });
    anime({
      targets: '#edge',
      opacity: 1.0,
      duration: 500,
      easing: 'linear',
    });
    anime({
      targets: '#edge',
      scale: 1.2,
      duration: 1000,
      direction: 'alternate',
      easing: 'easeInOutQuad',
    });
    setTimeout(() => {
      anime({
        targets: '#edge',
        opacity: 0.5,
        duration: 1000,
        easing: 'linear',
        complete: () => {
          anime({
            targets: '#edge',
            opacity: 1.0,
            duration: 1000,
            loop: true,
            direction: 'alternate',
            easing: 'linear',
          });
        },
      });
    }, 2000);

    setTimeout(() => {
      anime({
        targets: '#touchPrint',
        opacity: 0.6,
        duration: 1000,
        easing: 'linear',
        complete: () => {
          anime({
            targets: '#touchPrint',
            opacity: 0.4,
            duration: 1000,
            loop: true,
            direction: 'alternate',
            easing: 'linear',
          });
        },
      });
    }, 1000);

    setTimeout(() => {
      anime({
        targets: ['#pfeilBottom', '#pfeilTop', '#pfeilLinie'],
        opacity: 1.0,
        duration: 1000,
        easing: 'linear',
      });
    }, 3000);

    setTimeout(() => {
      anime({
        targets: '#pfeilLinie',
        translateY: -(780 * scaleFactor),
        duration: 7000,
        loop: true,
        easing: 'linear',
      });
    }, 4000);

    setTimeout(() => {
      anime({
        targets: '#swiper',
        translateY: 0 - (window.innerHeight + 800 * scaleFactor), // -(screenHeight+ swiperHeight)
        duration: 4000,
        loop: true,
        easing: 'linear',
      });
    }, 6000);
  };

  if (redirect)
    return <Redirect to={redirect} />;

  return (
    <Div100vh id="swipeScreen" style={{ position: 'absolute', top: 0, left: 0, width: '100vw' }}>
      <PositionedScaledImage
        src={`${preDir}/card@2x.png`}
        id="frame"
        alt="plasticFrame"
        horizontalAlign="left"
        verticalAlign="bottom"
        style={{
          marginBottom: '0.5rem',
          marginLeft: '0.5rem',
        }}
      />
      <PositionedScaledImage
        src={`${preDir}/touch_fingerabdruck@2x.png`}
        id="touchPrint"
        alt="touchPrint"
        horizontalAlign="left"
        verticalAlign="bottom"
        horizontalOffset={-80}
        verticalOffset={-440}
        opacity={0}
      />

      <Fade in={showDot} timeout={{ enter: 100, exit: 150 }}>
        <FadeChildComponents>
          <PositionedScaledImage
            src={`${preDir}/swipe-marker@2x.png`}
            id="markerTop"
            alt="markerTop"
            horizontalAlign="left"
            verticalAlign="bottom"
            horizontalOffset={445}
            verticalOffset={-1114}
          />

          <PositionedScaledImage
            src={`${preDir}/swipe-marker@2x.png`}
            id="markerBottom"
            alt="markerBottom"
            horizontalAlign="left"
            verticalAlign="bottom"
            horizontalOffset={445}
            verticalOffset={4}
          />

          <PositionedScaledImage
            src={`${preDir}/swipe-dot@2x.png`}
            id="dot"
            alt="dot"
            horizontalAlign="left"
            verticalAlign="bottom"
            horizontalOffset={443}
            verticalOffset={-1150}
          />
        </FadeChildComponents>
      </Fade>

      <PositionedScaledImage
        src={`${preDir}/swipe-top@2x.png`}
        id="pfeilTop"
        alt="pfeilTop"
        horizontalAlign="left"
        verticalAlign="bottom"
        horizontalOffset={445}
        verticalOffset={-945}
        opacity={0}
      />

      <div
        style={{
          position: 'absolute',
          overflow: 'hidden',
          width: scaleFactor * 90 + 'px',
          height: scaleFactor * 770 + 'px',
          left: scaleFactor * 446 + 'px',
          bottom: scaleFactor * 165 + 'px',
        }}
      >
        <ScaledImage
          src={`${preDir}/swipe-linie_lang@2x.png`}
          id="pfeilLinie"
          alt="pfeilLinie"
          style={{ top: 0 }}
          opacity={0}
        />
      </div>

      <PositionedScaledImage
        src={`${preDir}/swipe-bottom@2x.png`}
        id="pfeilBottom"
        alt="pfeilBottom"
        horizontalAlign="left"
        verticalAlign="bottom"
        horizontalOffset={445}
        verticalOffset={-75}
        opacity={0}
      />

      <PositionedScaledImage
        src={`${preDir}/swipe-escalator@2x.png`}
        id="swiper"
        alt="swiper"
        horizontalAlign="left"
        verticalAlign="bottom"
        horizontalOffset={400}
        verticalOffset={790}
      />

      <Fade in={showRedAlert} timeout={{ enter: 150, exit: 200 }}>
        <FadeChildComponents>
          <Div100vh id="redAlert" style={{ opacity: 0.75, backgroundColor: 'red' }} />
        </FadeChildComponents>
      </Fade>

      <Fade in={showGreenAlert} timeout={{ enter: 150, exit: 200 }}>
        <FadeChildComponents>
          <Div100vh id="greenAlert" style={{ opacity: 0.75, backgroundColor: 'green' }} />
        </FadeChildComponents>
      </Fade>
    </Div100vh>
  );
};

const mapStateToProps = (state) => {
  const { swipeVerification } = state;
  return { swipeVerification };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    changeSwipeVerification,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SwipeField));
