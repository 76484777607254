import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeStyles, Switch, TextField } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';

import { setAlertToolTypeData } from '../../redux/actions';
import CreateAlertGroup from './CreateAlertGroup';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import CreateAlertSelect from './CreateAlertSelect';
import InsertTextVariables from './InsertTextVariables';

const useStyles = makeStyles(() => ({
  textInput: {
    background: 'transparent',
    padding: 0,
    borderRadius: 4,
    width: 'calc(100% - 24px)',
    marginTop: 6,
    fontSize: 14,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FFFFFF29',
      top: 0,
    },
    '& .MuiOutlinedInput-input': {
      padding: 12,
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FFFFFF29',
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingTop: 5,
    },
  },
  permissionsArea: {
    borderRadius: 4,
    marginTop: 16,
    background: '#FFFFFF0A',
    padding: 8,
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
  },
}));

const ConfigureAlertTwitchSettings = (props) => {
  const classes = useStyles();
  const { t, alertTool, setAlertToolTypeData } = props;
  let { twitchSettings } = alertTool;

  if (!twitchSettings)
    twitchSettings = {};

  const handleTextChange = (event) => {
    twitchSettings.text = event.target.value;

    setAlertToolTypeData('twitchSettings', twitchSettings);
  };

  const insertVariable = (variable) => {
    twitchSettings.text = (twitchSettings && twitchSettings.text ? twitchSettings.text : '') + ` {${variable}}`;

    setAlertToolTypeData('twitchSettings', twitchSettings);
  };

  return (
    <>
      <CreateAlertGroup
        title={t('text_messages')}
        t={t}
        isRequired
        description={t('text_messages_description')}
      >
        <TextField
          id="text-message"
          name="text-message"
          value={twitchSettings && twitchSettings.text ? twitchSettings.text : ''}
          onChange={handleTextChange}
          variant="outlined"
          classes={{
            root: classes.textInput,
          }}
          placeholder={t('alert_setting_placeholder_msg')}
          inputProps={{
            maxLength: 255,
          }}
        />
        <InsertTextVariables t={t} insertVariable={insertVariable} />
      </CreateAlertGroup>

      <CreateAlertGroup
        title={t('alert_sound')}
        isRequired
        t={t}
        description={t('alert_sound_desc')}
      >
        <div style={{ color: Colors.halfWhite, marginTop: 24 }}>
          {t('sound_volume')}
        </div>

        <PrettoSlider
          value={twitchSettings && twitchSettings.alert_sound ? twitchSettings.alert_sound.volume : 10}
          valueLabelDisplay="auto"
          onChange={(event, newValue) => {
            const tmpSettings = { ...twitchSettings };
            if (!tmpSettings.alert_sound)
              tmpSettings.alert_sound = {};
            tmpSettings.alert_sound.volume = newValue;

            setAlertToolTypeData('twitchSettings', tmpSettings);
          }}
          step={1}
          min={0}
          max={20}
          style={{ marginTop: 6 }}
        />
      </CreateAlertGroup>

      <CreateAlertGroup
        title={t('text_to_speech')}
        t={t}
        description={t('text_to_speech_desc')}
        showGroup={(twitchSettings && twitchSettings.permissions) && twitchSettings.permissions}
      >
        <div className={classes.permissionsArea}>
          <div style={{ ...Helpers.fillRowCross }}>
            <div style={{
              marginRight: 12,
              borderRadius: 5,
              background: 'rgba(255, 255, 255, 0.03)',
              padding: 10,
            }}>
              <i className="fa-thin fa-comment-dots" style={{ fontSize: 26 }} />
            </div>
            <div style={{ ...Helpers.fillColMain }}>
              <div style={{ fontSize: 14 }}>
                {t('allow_stream_message')}
              </div>
              <div style={{ color: Colors.halfWhite, fontSize: 12 }}>
                {t('user_can_send_text_to_speech_desc')}
              </div>
            </div>
          </div>

          <div>
            <AntSwitch
              checked={twitchSettings && twitchSettings.permissions ? twitchSettings.permissions : false}
              onChange={() => {
                const tmpSettings = { ...twitchSettings };
                tmpSettings.permissions = tmpSettings.permissions ? !tmpSettings.permissions : true;

                setAlertToolTypeData('twitchSettings', tmpSettings);
              }}
              name="permissions"
            />
          </div>
        </div>


        {(twitchSettings && twitchSettings.permissions) &&
        <>
          <CreateAlertSelect
            value={twitchSettings && twitchSettings.text_to_speech && twitchSettings.text_to_speech.voice ? twitchSettings.text_to_speech.voice : null}
            handleChange={(event) => {
              const tmpSettings = { ...twitchSettings };
              if (!tmpSettings.text_to_speech)
                tmpSettings.text_to_speech = {};
              tmpSettings.text_to_speech.voice = event.target.value;

              setAlertToolTypeData('twitchSettings', tmpSettings);
            }}
            placeholder={t('voice')}
            id="voice"
            style={{ marginTop: 16 }}
          >
            <MenuItem value="Amy">Amy ({t('female')})</MenuItem>
            <MenuItem value="Emma">Emma ({t('female')})</MenuItem>
            <MenuItem value="Ivy">Ivy ({t('female')})</MenuItem>
            <MenuItem value="Joanna">Joanna ({t('female')})</MenuItem>
            <MenuItem value="Kendra">Kendra ({t('female')})</MenuItem>
            <MenuItem value="Kimberly">Kimberly ({t('female')})</MenuItem>
            <MenuItem value="Salli">Salli ({t('female')})</MenuItem>
            <MenuItem value="Brian">Brian ({t('male')})</MenuItem>
            <MenuItem value="Joey">Joey ({t('male')})</MenuItem>
            <MenuItem value="Kevin">Kevin ({t('male')})</MenuItem>
            <MenuItem value="Matthew">Matthew ({t('male')})</MenuItem>
            <MenuItem value="Vicki">Vicki DE ({t('female')})</MenuItem>
            <MenuItem value="Hannah">Hannah DE ({t('female')})</MenuItem>
          </CreateAlertSelect>

          <div style={{ color: Colors.halfWhite, marginTop: 24 }}>
            {t('font_size')}
          </div>

          <PrettoSlider
            value={twitchSettings && twitchSettings.text_to_speech && twitchSettings.text_to_speech.font_size ? twitchSettings.text_to_speech.font_size : 16}
            valueLabelDisplay="auto"
            onChange={(event, newValue) => {
              const tmpSettings = { ...twitchSettings };
              if (!tmpSettings.text_to_speech)
                tmpSettings.text_to_speech = {};

              tmpSettings.text_to_speech.font_size = newValue;

              setAlertToolTypeData('twitchSettings', tmpSettings);
            }}
            step={1}
            min={10}
            max={30}
            style={{ marginTop: 6 }}
          />
        </>
        }
      </CreateAlertGroup>
    </>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setAlertToolTypeData,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(withRouter(ConfigureAlertTwitchSettings));

const PrettoSlider = withStyles({
  root: {
    color: Colors.white,
    height: 4,
  },
  thumb: {
    height: 22,
    width: 22,
    backgroundColor: '#0B192D',
    border: '4px solid currentColor',
    marginTop: -9,
    marginLeft: -11,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% - 2px)',
    color: '#0B192D',
  },
  track: {
    height: 4,
    borderRadius: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
  },
})(Slider);

const AntSwitch = withStyles(() => ({
  root: {
    width: 32,
    height: 18,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: Colors.white,
    '&$checked': {
      transform: 'translateX(14px)',
      color: Colors.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: 'rgba(225, 33, 56, 0.8)',
        borderColor: 'rgba(225, 33, 56, 0.8)',
      },
    },
  },
  thumb: {
    width: 14,
    height: 14,
    boxShadow: 'none',
  },
  track: {
    border: 'none',
    borderRadius: 18 / 2,
    opacity: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
  checked: {},
}))(Switch);