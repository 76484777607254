import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';

import conf from '../../conf.json';

const CardShopSHSupportChart = (props) => {
  const { t } = useTranslation();
  const { i18n } = props;
  const isGermanLang = i18n.language === 'de';

  return (
    <>
      <img
        src={`${conf.cdn_url}/assets/template/shop/sh_card_streamer_streamheroes_chart${isGermanLang ? '_de' : ''}.png`}
        alt="Chart" />
      <div style={{
        height: 1,
        width: '100%',
        background: 'rgba(255, 255, 255, 0.1)',
        marginTop: 6,
      }} />
      <div style={{ marginTop: 32, ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween }}>
        <div style={{ width: '100%', marginRight: 7 }}>
          <div>{t('streamer')}</div>
          <div style={{
            height: 4,
            background: '#A473FB',
            borderRadius: 7,
            width: '100%',
            margin: '6px 0',
          }} />
          <div style={{ color: Colors.halfWhite }}>40%</div>
        </div>
        <div style={{ width: '100%', marginLeft: 7, ...Helpers.fillCol, ...Helpers.crossEnd }}>
          <div>{t('streamheroes')}</div>
          <div style={{
            height: 4,
            background: '#E12138',
            borderRadius: 7,
            width: '100%',
            margin: '6px 0',
          }} />
          <div style={{ color: Colors.halfWhite }}>60%</div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(CardShopSHSupportChart);