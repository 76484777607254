import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import SimpleReactLightbox from 'simple-react-lightbox';
import { useTranslation } from 'react-i18next';

import { getAllIllustrationChatImages, getIllustrationData } from '../../apollo/queries';
import { getIllustrationData as mergedIllustrationData } from '../../apollo/mergedQueries';
import { addIllustrationChatMessage as addIllustrationChatMessageMutation } from '../../apollo/mutations';

import MyChat from '../../components/Chat/Chat';
import Loading from '../../components/Loading/Loading';
import Helpers from '../../theme/Helpers';
import { uploadImageToS3 } from '../../utils/helper';

const ChatContainer = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { chat, myViewer, id } = props;
  const [chatImages, setChatImages] = useState(null);

  const { data, loading } = useQuery(getIllustrationData, {
    variables: { id },
    pollInterval: 60000,
    fetchPolicy: 'network-only',
    skip: !id,
  });

  let chatById;
  if (!chat)
    chatById = (data) && data.getIllustrationData;

  const refetchQueries = [];
  if (id) {
    refetchQueries.push({ query: getIllustrationData, variables: { id } });
    refetchQueries.push({ query: getAllIllustrationChatImages, variables: { id } });
  } else {
    refetchQueries.push({ query: mergedIllustrationData });
    refetchQueries.push({
      query: getAllIllustrationChatImages,
      variables: { id: chatById ? chatById._id : null },
    });
  }

  const [addIllustrationChatMessage] = useMutation(addIllustrationChatMessageMutation, {
    onCompleted: (data) => {
      const preSignedUrls = data.addIllustrationChatMessage;

      preSignedUrls.map((signedUrl, index) => {
        const chatImg = chatImages[index];

        uploadImageToS3(signedUrl, chatImg, {
          'Content-Type': chatImg.type,
        }, () => {
          if (index === preSignedUrls.length - 1)
            enqueueSnackbar(t('success'), { variant: 'success' });
        });
        return;
      });
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => refetchQueries,
  });

  const addChatMessage = (createdAt, content, type, images = null) => {
    let imageStrings = null;
    if (images) {
      setChatImages(images);
      imageStrings = images.map(img => img.name);
    }

    addIllustrationChatMessage({
      variables: {
        id: id ? id : chat._id,
        msg: type === 'text' ? content : null,
        type,
        images: imageStrings,
        createdAt,
      },
    });
  };

  if (loading || (!chat && !chatById))
    return (
      <div style={{ width: '100%', height: '100%', ...Helpers.fillRowCenter }}>
        <Loading />
      </div>
    );

  return (
    <SimpleReactLightbox>
      <MyChat myViewer={myViewer} chat={chat ? chat : chatById} addChatMessage={addChatMessage} />
    </SimpleReactLightbox>
  );
};

export default ChatContainer;