import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { zeroPad } from 'react-countdown';
import { fabric } from 'fabric';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import Colors from '../../theme/Colors';
import { getRandomNumber, rangeArray } from '../../utils/helper';
import Helpers from '../../theme/Helpers';
import CustomTooltip from '../Tooltip/CustomTooltip';

const conf = require('../../conf');

const CardToolSelectFrame = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    canvas,
    checkIfObjectExists,
    directory,
    activeFrame,
    setCardFrame,
    setCanvasLoading,
    checkSortingOfObjects,
    fitSizeOfCardDesign,
    cuttedSize,
    selectedStyle,
    activeEffect,
  } = props;

  const defaultOptions = {
    crossOrigin: 'Anonymous',
    cropX: 35,
    cropY: 35,
  };

  const updateCard = async (frame) => {
    const designTheme = checkIfObjectExists('card-design-theme');
    setCardFrame(frame);
    setCanvasLoading(true);

    const cardLevelDesign = `${directory}/01-frames/${zeroPad(frame)}.png`;
    canvas.remove(designTheme);

    await fabric.Image.fromURL(cardLevelDesign, async (oImg) => {
      oImg.id = 'card-design-theme';
      // card background is not selectable
      oImg.selectable = false;
      oImg.width = cuttedSize.width;
      oImg.height = cuttedSize.height;
      fitSizeOfCardDesign(oImg);
      canvas.add(oImg);
      canvas.sendToBack(oImg);

      const effectObj = checkIfObjectExists('effect');
      if (effectObj) {
        canvas.remove(effectObj);
        const effectDir = `${conf.no_cors_cdn_url}/cards/maker/effects`;
        const url = `${effectDir}/style-${zeroPad(selectedStyle)}/effect-${zeroPad(activeEffect)}/${zeroPad(frame)}.png`;

        fabric.Image.fromURL(url, async (effectObj) => {
          effectObj.id = 'effect';
          effectObj.selectable = false;
          effectObj.width = cuttedSize.width;
          effectObj.height = cuttedSize.height;
          fitSizeOfCardDesign(effectObj);
          canvas.add(effectObj);
          canvas.sendToBack(effectObj);

          await redrawBackground(frame);
        }, defaultOptions);
      } else {
        await redrawBackground(frame);
      }
    }, defaultOptions);
  };

  const redrawBackground = async (frame) => {
    const bgObj = checkIfObjectExists('card-background');
    const bgSrc = bgObj.src ? bgObj.src : bgObj._originalElement.src;
    if (bgObj && !bgSrc.includes(directory)) {
      canvas.remove(bgObj);

      const random = getRandomNumber();
      await fabric.Image.fromURL(`${directory}/00-background/00.png?v=${random}`, (background) => {
        background.id = 'card-background';
        background.selectable = false;
        background.width = cuttedSize.width;
        background.height = cuttedSize.height;
        fitSizeOfCardDesign(background);
        canvas.add(background);
        canvas.sendToBack(background);
        checkSortingOfObjects(frame);
      }, defaultOptions);
    } else {
      checkSortingOfObjects(frame);
    }
  };

  return (
    <div className={classes.area}>
      <div style={{ width: 130, ...Helpers.textLeft, ...Helpers.fillRow }}>
        {t('select_frame_type')}
        <CustomTooltip title={t('select_frame_help')}>
          <div>
            <FontAwesomeIcon icon={faInfoCircle} size="lg" color={Colors.white} />
          </div>
        </CustomTooltip>
      </div>
      <div>
        {rangeArray(0, 3)
        .map(frame => (
          <img
            src={`${directory.replace(conf.no_cors_cdn_url, conf.cdn_url)}/01-frames/${zeroPad(frame)}.png?height=80`}
            key={`FrameKey${frame}`}
            alt={`Frame ${frame}`}
            style={{
              opacity: activeFrame === frame ? 1.0 : 0.5,
              margin: 5,
              cursor: 'pointer',
              borderRadius: 4,
              height: 80,
            }}
            onClick={() => updateCard(frame)}
          />
        ))}
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  area: {
    backgroundColor: '#010F22',
    padding: 20,
    marginBottom: 10,
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    color: '#747474',
  },
}));

export default CardToolSelectFrame;