import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';

import Colors from '../../theme/Colors';

const useStyles = makeStyles(() => ({
  headerText: {
    marginBottom: 32,
    color: Colors.subTextColor,
    fontSize: 15,
  },
  table: {
    minWidth: 650,
  },
  clickableLink: {
    color: '#cc1e31',
    cursor: 'pointer',
    '&:hover': {
      color: Colors.white,
    },
  },
}));

const OrderListTable = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { orders, setSelectedOrder } = props;

  const selectOrder = (order) => {
    if (order.gems) {
      window.open(order.gems.invoice, '_blank');
    } else {
      setSelectedOrder(order);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const getStatus = (order) => {
    if (order.status !== 'Payment_intended' && order.status !== 'Pending' && order.isNewVersion)
      return 'Completed';

    return order.status;
  };

  return (
    <TableContainer component={Paper} style={{ background: 'transparent' }} className="inner-wrapper">
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{
              fontFamily: 'Bebas Neue',
              fontSize: 15,
              fontWeight: 400,
            }}>
              {t('order_order')}
            </TableCell>
            <TableCell style={{
              fontFamily: 'Bebas Neue',
              fontSize: 15,
              fontWeight: 400,
            }}>
              {t('order_name')}
            </TableCell>
            <TableCell style={{
              fontFamily: 'Bebas Neue',
              fontSize: 15,
              fontWeight: 400,
            }}>
              {t('order_costs')}
            </TableCell>
            <TableCell style={{
              fontFamily: 'Bebas Neue',
              fontSize: 15,
              fontWeight: 400,
            }}>
              {t('order_status')}
            </TableCell>
            <TableCell style={{
              fontFamily: 'Bebas Neue',
              fontSize: 15,
              fontWeight: 400,
            }}>
              {t('order_ordered_on')}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {orders ? orders.map(order =>
              <TableRow key={`OrderKey#${order._id}`}>
                <TableCell style={{ fontWeight: 400 }}>
                  {order._id}
                </TableCell>
                <TableCell style={{ fontWeight: 400 }}>
                  {order.name}
                </TableCell>
                <TableCell style={{ fontWeight: 400 }}>
                  {(order.isNewVersion ? (order.total / 100) : order.total).toFixed(2)
                  .replace('.', ',')} €
                </TableCell>
                <TableCell style={{ fontWeight: 400 }}>
                  <Chip
                    label={getStatus(order)}
                    style={{
                      borderRadius: 10,
                      backgroundColor: order.status === 'Completed' || (order.status !== 'payment_intended' && order.isNewVersion) ? '#80af43' : order.status === 'Ordered' ? '#4360ae' : '#a10f27',
                    }}
                  />
                </TableCell>
                <TableCell style={{ fontWeight: 400 }}>
                  {order.date}
                </TableCell>
                <TableCell style={{ fontWeight: 400 }}>
                  <div className={classes.clickableLink} onClick={() => selectOrder(order)}>
                    {t(order.gems ? 'invoice' : 'show')}
                  </div>
                </TableCell>
              </TableRow>,
            )
            :
            <TableRow>
              <TableCell colspan="6">{t('order_no_orders')}</TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrderListTable;