import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { getMySeasonWithSeasonInfo } from '../../apollo/mergedQueries';

import ProfileRanked from '../../components/Profile/ProfileRanked';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import Loading from '../../components/Loading/Loading';

const ProfileRankedContainer = (props) => {
  const { viewer } = props;

  const { data, loading } = useQuery(getMySeasonWithSeasonInfo, {
    variables: { viewer: viewer._id },
    fetchPolicy: useCacheWithExpiration('getMySeasonWithSeasonInfo', { viewer: viewer._id }),
  });

  const seasonData = data && data.getMySeason;
  const seasonInfo = data && data.getCurrentSeason;

  return (
    loading ?
      <Loading />
      :
      <ProfileRanked seasonData={seasonData} seasonInfo={seasonInfo} />
  );
};

export default ProfileRankedContainer;