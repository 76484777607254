import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Colors from '../../theme/Colors';

const useStyles = makeStyles(() => ({
  button: {
    padding: '3px 45px',
    display: 'inline-block',
    verticalAlign: 'bottom',
    fontSize: 20,
    fontWeight: 400,
    textTransform: 'initial',
    transition: 'all 0.3s ease-out',
    border: '1px solid transparent',
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: '#7BB01C',
    borderRadius: 50,
    cursor: 'pointer',
    color: '#374617',
    '& span': {
      zIndex: 20,
    },
    '&:after': {
      background: '#fff',
      content: '" "',
      height: 155,
      left: -75,
      opacity: .2,
      position: 'absolute',
      top: -50,
      transform: 'rotate(35deg)',
      transition: 'all 550ms cubic-bezier(0.19, 1, 0.22, 1)',
      width: 50,
      zIndex: 0,
      animation: '$shine 5s infinite',
    },
    '&:hover': {
      backgroundColor: '#8CC12D',
    },
  },
  '@keyframes shine': {
    '0%': {
      left: -75,
    },
    '20%,100%': {
      left: '120%',
    },
  },
  purple: {
    backgroundColor: '#6E04FC',
    color: Colors.white,
    border: '1px solid #8133E9',
    '&:hover': {
      backgroundColor: '#7F15FD',
    },
  },
  neonPurple: {
    backgroundColor: '#8B26E8',
    color: Colors.white,
    border: 'none',
    borderRadius: 6,
    padding: 11,
    '&:hover': {
      backgroundColor: '#F306FC',
      boxShadow: '0px 0px 15px #F306FC',
    },
  },
  red: {
    backgroundColor: '#8A0922',
    color: Colors.white,
    border: '1px solid #B00B28',
    '&:hover': {
      backgroundColor: '#B00B28',
    },
  },
  neonRed: {
    backgroundColor: '#E12138',
    color: Colors.white,
    border: 'none',
    fontFamily: 'Montserrat',
    fontSize: 14,
    padding: 14,
    borderRadius: 6,
    '&:hover': {
      backgroundColor: '#F22740',
      boxShadow: '0px 0px 15px #F227407A',
    },
  },
  neonGreen: {
    backgroundColor: '#09B711',
    color: Colors.white,
    border: '1px solid #1FE325',
    padding: '10px 36px',
    borderRadius: 6,
    '&:hover': {
      backgroundColor: '#1FE325',
      boxShadow: '0px 0px 15px #1FE3257A',
    },
  },
  discordBlue: {
    background: '#4F67EE',
    color: Colors.white,
    border: 'none',
    padding: 11,
    borderRadius: 6,
    fontFamily: 'Montserrat',
    fontSize: 14,
    '&:hover': {
      boxShadow: '0px 0px 15px #4F67EE',
      background: '#4F67EE',
    },
  },
  gold: {
    background: '#DAA520',
    color: Colors.white,
    border: 'none',
    padding: 14,
    borderRadius: 6,
    '&:hover': {
      boxShadow: '0px 0px 15px #DAA520',
      background: '#DAA520',
    },
  },
  transparent: {
    backgroundColor: 'transparent',
    color: Colors.white,
    border: '1px solid rgba(255, 255, 255, 0.08)',
    padding: 11,
    borderRadius: 6,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.24)',
    },
  },
  whiteTransparency: {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    color: Colors.white,
    border: 'none',
    fontFamily: 'Montserrat',
    fontSize: 14,
    padding: 14,
    borderRadius: 6,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.24)',
    },
  },
  brown: {
    backgroundColor: '#B96707',
    color: Colors.white,
    border: '1px solid #FF8900',
    padding: '8px 35px',
    borderRadius: 50,
    '&:hover': {
      backgroundColor: '#FF8900',
    },
  },
  gray: {
    backgroundColor: '#475872',
    color: Colors.white,
    border: '1px solid #647389',
    padding: '11px 32px',
    fontSize: 14,
    fontFamily: 'Montserrat',
    borderRadius: 6,
    '&:hover': {
      backgroundColor: '#647389',
    },
  },
  whiteGray: {
    backgroundColor: 'rgba(255, 255, 255, 0.26)',
    color: Colors.white,
    border: 'none',
    padding: 14,
    fontSize: 14,
    fontFamily: 'Montserrat',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
  },
  disabled: {
    opacity: 0.5,
    cursor: 'not-allowed',
    filter: 'grayscale(100%)',
  },
  avenirNextCyr: {
    fontFamily: 'Avenir Next Cyr',
    fontSize: 14,
    padding: '8px 28px',
    fontWeight: 'bold',
  },
  neonPink: {
    backgroundColor: '#FF4067',
    color: Colors.white,
    border: 'none',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 600,
    padding: 8,
    borderRadius: 4,
    '&:hover': {
      backgroundColor: '#DD3154',
    },
  },
}));

const AnimationButton = (props) => {
  const classes = useStyles();
  const { children, onClick, style, disabled, status, font, className, href, onMouseEnter } = props;

  const buttonClasses = [classes.button];
  if (disabled)
    buttonClasses.push(classes.disabled);

  if (status)
    buttonClasses.push(classes[status]);

  if (font)
    buttonClasses.push(classes[font]);

  if (className)
    buttonClasses.push(className);

  const Tag = href ? Link : 'button';

  return (
    <Tag to={href} className={buttonClasses.join(' ')} disabled={disabled}
         onClick={disabled ? null : onClick} style={style} onMouseEnter={onMouseEnter}>
      <span>{children}</span>
    </Tag>
  );
};

AnimationButton.propTypes = {
  style: PropTypes.object,
  status: PropTypes.string,
};

AnimationButton.defaultProps = {
  style: {},
};

export default AnimationButton;