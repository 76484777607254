import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faVolumeDown,
  faVolumeUp,
  faPlayCircle,
  faStopCircle,
} from '@fortawesome/free-solid-svg-icons';
import { useDropzone } from 'react-dropzone';

import { uploadFile as uploadFileMutation } from '../../apollo/mutations';

import { rangeArray } from '../../utils/helper';
import Colors from '../../theme/Colors';
import Slider from '@material-ui/core/Slider';
import Grid from '@material-ui/core/Grid';
import Loading from '../Loading/Loading';
import CustomButton from '../Button/CustomButton';
import Helpers from '../../theme/Helpers';

const conf = require('../../conf');

const useStyles = makeStyles(theme => ({
  root: {
    color: '#636363',
    display: 'flex',
    flexDirection: 'row',
    '& > div': {
      flex: 1,
    },
  },
  area: {
    paddingTop: theme.spacing(3),
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 40,
  },
  radioGroup: {
    flexDirection: 'row',
    marginTop: -30,
  },
  formLabelLegend: {
    backgroundColor: 'transparent',
    color: Colors.white,
  },
  radioButton: {
    color: theme.palette.text.third,
  },
  dropZone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    marginBottom: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: Colors.subTextColor,
    borderStyle: 'dashed',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
}));

const useAudio = (volume) => {
  const [audio, setAudio] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [url, setUrl] = useState('');

  const toggle = () => setPlaying(!playing);
  const setURL = (url) => {
    if (audio) audio.pause();
    setPlaying(false);
    setUrl(url);
    setAudio(new Audio(url));
    setPlaying(true);
  };

  useEffect(() => {
      if (audio) {
        playing ? audio.play() : audio.pause();
      }
    },
    [playing, audio],
  );

  useEffect(() => {
    if (audio) {
      audio.volume = volume ? volume : 0.5;
      audio.addEventListener('ended', () => setPlaying(false));
      return () => {
        audio.removeEventListener('ended', () => setPlaying(false));
      };
    }
  }, [audio]);

  return [playing, url, toggle, setURL];
};

const StreamConfigAlertsSoundSettings = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { showSound, handleChange, sound, canHandleSwitch, volume } = props;
  const [playing, url, toggle, setURL] = useAudio(volume);
  const [customSound, setCustomSound] = useState(typeof sound === 'number' ? null : sound);
  const [loading, setLoading] = useState(false);

  const [uploadFile] = useMutation(uploadFileMutation, {
    onCompleted: (data) => {
      const { filename } = data.uploadFile;

      handleChange(null, 'sound', filename);
      setCustomSound(filename);
      setLoading(false);
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
      setLoading(false);
    },
  });

  const onDrop = useCallback(acceptedFiles => {
    setLoading(true);
    uploadFile({ variables: { file: acceptedFiles.pop() } });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'audio/*',
    multiple: false,
    maxSize: 4000000,
    onDrop,
  });

  const removeCustomSound = () => {
    handleChange(null, 'sound', 1);
    setCustomSound(null);
  };

  return (
    <div className={classes.root}>
      <div>
        <FormControlLabel
          labelPlacement="start"
          style={{ marginLeft: 0, marginRight: 0 }}
          disabled={!canHandleSwitch}
          control={
            <Switch
              checked={showSound}
              onChange={() => handleChange(null, 'showSound', !showSound)}
              value={showSound} />
          }
          label={t('show_alert_sound')}
        />
        {showSound &&
        <div className={classes.area}>
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.formLabelLegend}>
              {t('pick_alert_sound')}
            </FormLabel>
            <RadioGroup
              aria-label="sound"
              name="sound"
              className={classes.radioGroup}
              value={customSound ? null : sound}
              onChange={(event) => handleChange(event, 'sound', parseInt(event.target.value))}>
              {rangeArray(1, 4)
              .map(number =>
                <FormControlLabel
                  key={`SoundRadio${number}`}
                  value={number}
                  control={<Radio className={classes.radioButton} disabled={!!customSound} />}
                  label={
                    <div className={classes.row}>
                      <Typography style={{ marginRight: 10, paddingBottom: 10 }}>
                        Theme {number}
                      </Typography>
                      {playing && url.includes(`alert-sound-${number}.mp3`) ?
                        <FontAwesomeIcon icon={faStopCircle} onClick={toggle} size="2x" />
                        :
                        <FontAwesomeIcon
                          icon={faPlayCircle}
                          size="2x"
                          onClick={() =>
                            setURL(`${conf.cdn_url}/assets/dashboard/profile/sounds/alert-sound-${number}.mp3`)
                          } />
                      }
                    </div>
                  } />,
              )}
            </RadioGroup>
          </FormControl>
        </div>
        }
      </div>
      <div>
        {loading ?
          <Loading />
          :
          !customSound ?
            <div {...getRootProps({ className: 'dropzone' })} className={classes.dropZone}>
              <input {...getInputProps()} />
              <p>
                {t('want_custom_sound')}
              </p>
            </div>
            :
            <div style={{ marginBottom: 20 }}>
              <div
                style={{ ...Helpers.fillRowCross, ...Helpers.mainSpaceBetween, ...{ marginBottom: 20 } }}
              >
                {t('activated_custom_sound')}
                {playing && url.includes('user-sound-alerts') ?
                  <FontAwesomeIcon icon={faStopCircle} onClick={toggle} size="2x" />
                  :
                  <FontAwesomeIcon
                    icon={faPlayCircle}
                    size="2x"
                    onClick={() =>
                      setURL(customSound)
                    } />
                }
              </div>
              <CustomButton status="primary" onClick={() => removeCustomSound()}>
                {t('remove_custom_sound')}
              </CustomButton>
            </div>
        }
        <FormLabel component="legend" className={classes.formLabelLegend}>
          {t('volume')}
        </FormLabel>
        <Grid container spacing={2}>
          <Grid item>
            <FontAwesomeIcon icon={faVolumeDown} size="lg" />
          </Grid>
          <Grid item xs>
            <Slider
              value={volume}
              onChange={(event, newValue) => handleChange(event, 'volume', newValue)}
              color="secondary"
              aria-labelledby="discrete-slider"
              step={0.1}
              min={0.1}
              max={1.0}
            />
          </Grid>
          <Grid item>
            <FontAwesomeIcon icon={faVolumeUp} size="lg" />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default StreamConfigAlertsSoundSettings;