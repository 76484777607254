import React from 'react';
import { makeStyles } from '@material-ui/core';

import PollsContainer from '../../containers/Polls/Polls';
import KPIsContainer from '../../containers/KPIs/KPIs';
import ChangeUserContainer from '../../containers/User/ChangeUser';
import AdministrationSettingsContainer
  from '../../containers/AdministrationSettings/AdministrationSettings';
import UserRolesSettingsContainer
  from '../../containers/UserRolesSettings/UserRolesSettings';
import LicenseAdministrationContainer
  from '../../containers/AdministrationSettings/LicenseAdministration';
import CreateCardAdministrationContainer
  from '../../containers/AdministrationSettings/CreateCardAdministration';
import Colors from '../../theme/Colors';
import ReviewBoosterContainer from '../../containers/ReviewCards/ReviewBooster';
import ReviewCardsContainer from '../../containers/ReviewCards/ReviewCards';
import CheckPhysicalCardContainer from '../../containers/Admin/CheckPhysicalCardContainer';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '90vh',
    padding: '40px 20px',
    color: Colors.white,
  },
}));

const AdminArea = (props) => {
  const classes = useStyles();
  const { roles, tab } = props;

  const marketingTabs = [
    {
      key: 'polls',
      component: <PollsContainer />,
    },
    {
      key: 'kpis',
      component: <KPIsContainer />,
    },
    {
      key: 'impersonate',
      component: <ChangeUserContainer />,
    },
    /*{
      component: <StreamerDataContainer />,
    },*/
  ];

  const adminTabs = [
    {
      key: 'review',
      component: <ReviewBoosterContainer />,
    },
    {
      key: 'administration',
      component: <AdministrationSettingsContainer />,
    },
    {
      key: 'roles',
      component: <UserRolesSettingsContainer />,
    },
    {
      key: 'license',
      component: <LicenseAdministrationContainer />,
    },
    {
      key: 'create-card',
      component: <CreateCardAdministrationContainer />,
    },
    {
      key: 'check-physical',
      component: <CheckPhysicalCardContainer />,
    },
  ].concat(marketingTabs);

  const tabs = roles.includes('admin') ? adminTabs : marketingTabs;
  const activeTab = tabs.find(findTabs => findTabs.key === tab);

  return (
    <div className={classes.root}>
      {activeTab.component}
    </div>
  );
};

export default AdminArea;