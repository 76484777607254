import React from 'react';
import { useParams, withRouter } from 'react-router-dom';

import MatchBoostersContainer from '../../containers/Booster/MatchBoosters';
import Colors from '../../theme/Colors';
import ProfileOverviewContainer from '../../containers/Profile/ProfileOverview';
import ProfileShowcaseContainer from '../../containers/Profile/ProfileShowcase';
import ProfileRankedContainer from '../../containers/Profile/ProfileRanked';
import ProfileCommunityRankedContainer from '../../containers/Profile/ProfileCommunityRanked';
import ProfileEventContainer from '../../containers/Profile/ProfileEvent';
import RaffleContainer from '../../containers/Raffle/Raffle';

const ProfileBody = (props) => {
  const { viewer, streamer, isStreamer } = props;
  const { tab } = useParams();

  if (tab === undefined)
    props.history.push(`/profile/${viewer.login}/${isStreamer ? 'booster' : 'overview'}`);

  return (
    <>
      <div style={{ maxWidth: 1440, margin: '60px auto', width: '100%', color: Colors.white }}>
        {tab === 'booster' && isStreamer &&
        <MatchBoostersContainer matchData={streamer} />
        }
        {(tab === 'overview') &&
        <ProfileOverviewContainer viewer={viewer} />
        }
        {(tab === 'showcase') &&
        <ProfileShowcaseContainer viewer={viewer} />
        }
        {(tab === 'ranked') &&
        <ProfileRankedContainer viewer={viewer} />
        }
        {(tab === 'rapidlan-september-2022') &&
        <ProfileEventContainer streamer={streamer} />
        }
        {(tab === 'raffle') &&
        <RaffleContainer raffleName="RapidLan September 2022" />
        }
      </div>
      {(tab === 'leaderboard' && isStreamer) &&
      <ProfileCommunityRankedContainer streamer={streamer} />
      }
    </>
  );
};

export default withRouter(ProfileBody);