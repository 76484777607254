import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { useParams, withRouter } from 'react-router-dom';

import { getRedeemedCardObjectInformation } from '../../apollo/queries';

import ErrorMessage from '../../components/ErrorBoundary/ErrorMessage';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import CardShopMatchCard from '../../components/CardShop/CardShopMatchCard';

function CardShopMatchCardContainer() {
  const { t } = useTranslation();
  const { cardId } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const variables = { id: cardId };
  const { data, loading, error } = useQuery(getRedeemedCardObjectInformation, {
    variables,
    fetchPolicy: useCacheWithExpiration('getRedeemedCardObjectInformation', variables),
  });

  const card = data && data.getRedeemedCardObjectInformation;

  if (error) return <ErrorMessage error={error} />;

  return (
    <>
      <PageTitle title={t('card_shop')} />
      <CardShopMatchCard
        loading={loading}
        card={card}
      />
    </>
  );
}

export default withRouter(CardShopMatchCardContainer);