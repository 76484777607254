import {
  CHANGE_TOKEN,
  CHANGE_TOKEN_AND_REDIRECT,
  ADD_ACTIVITY_FEED_LIST,
  ADD_ACTIVITY_FEED_ITEM,
  ADD_CARD_TOOL_LAST_STEP,
  REMOVE_CARD_TOOL_LAST_STEP,
  RESET_CARD_TOOL_LAST_STEPS,
  CHANGE_HAS_ADMIN_RIGHTS,
  HANDLE_VALUE_CHANGE,
  REMOVE_POPUP_EVENT,
  ADD_POPUP_EVENT,
  SET_LAST_LOGIN,
  HANDLE_OBJECT_VALUE_CHANGE,
  CHANGE_CARD_VERIFICATION,
  CHANGE_SWIPE_VERIFICATION,
  INCREASE_COUNT,
  SET_CLIENT_SECRET,
  UPDATE_CARD_IN_CART,
  SET_ORDER_CART,
  UPDATE_AMOUNT_IN_PRODUCT,
  SET_PRODUCTS_CART,
  RESET_CARDS_IN_SHOP,
  UPDATE_AMOUNT_IN_STATIC,
  ADD_HERO_MAKER_ITEM,
  UPDATE_HERO_MAKER_COLORED_IMAGES,
  REMOVE_HERO_MAKER_COLORED_IMAGE,
  SET_HERO_MAKER_CURRENT_IMAGE,
  SET_HERO_MAKER_ASSETS,
  RESET_HERO_MAKER,
  SET_BODY_COLOR_HM,
  SET_LIGHT_DARK_MODE_HM,
  SET_TYPE_ASSETS_HM,
  SET_HERO_MAKER_ITEMS,
  UPDATE_AMOUNT_IN_ALBUM,
  TOGGLE_HIDE_PAGE_SCROLLBAR,
  TOGGLE_NEW_CARD_IN_SHOP,
  TOGGLE_PLAYING_BOOSTER_ANIMATION,
  SET_BOOSTER_DATA,
  SET_ACTIVE_CARD,
  SET_ALL_CARDS,
  SET_BOOSTER_ID,
  SET_BOOSTER_ALL_DATA,
  TOGGLE_EXPANDED_MENU,
  TOGGLE_RIGHT_SIDEBAR,
  TOGGLE_SHOP_CART,
  TOGGLE_SEARCH,
  TOGGLE_NOTIFICATIONS,
  TOGGLE_COLLECTING_CHECKER,
  RESET_BOOSTER_DATA,
  RESET_ALERT_TOOL_DATA,
  SET_ALERT_TOOL_TYPE_DATA,
  SET_ALERT_TOOL_ALL_DATA,
  SET_BOOSTER_ACTION_DATA,
  RESET_BOOSTER_ACTION_DATA,
  SET_CARD_ID_SHOWN,
  SET_COUPON_CODE,
  SET_INVENTORY_GROUP,
  TOGGLE_STORAGE_BOX_SELECTION_CARD,
  SET_STORAGE_BOX_ID,
  SET_STORAGE_BOX_SELECTION_MODE,
  RESET_STORAGE_BOX_DATA,
  SET_COLLECTORS_VIEW_BOOSTER,
  SET_COLLECTORS_VIEW_CARD,
  RESET_COLLECTORS_VIEW_DATA,
  SET_MARKETPLACE_ALL_DATA,
  RESET_MARKETPLACE_DATA,
  SET_COLLECTORS_VIEW_MARKETPLACE,
  SET_COLLECTORS_VIEW_ALL_CARDS,
  SET_COLLECTORS_VIEW_SHOW_CARD_INFO,
  SET_COLLECTORS_VIEW_SELECT_SELL_INFO, SET_BOOSTER_LAST_REDEEM,
} from './constants';

export const changeUserToken = userToken => (
  {
    type: CHANGE_TOKEN,
    userToken,
  }
);

export const changeUserTokenAndRedirect = (userToken, history, route) => (
  {
    type: CHANGE_TOKEN_AND_REDIRECT,
    userToken,
    history,
    route,
  }
);

export const addActivityFeedList = (activityFeedList) => (
  {
    type: ADD_ACTIVITY_FEED_LIST,
    activityFeedList,
  }
);

export const addActivityFeedItem = (activityFeedItem) => (
  {
    type: ADD_ACTIVITY_FEED_ITEM,
    activityFeedItem,
  }
);

export const resetLastSteps = () => (
  {
    type: RESET_CARD_TOOL_LAST_STEPS,
  }
);

export const addLastStep = (lastStep) => (
  {
    type: ADD_CARD_TOOL_LAST_STEP,
    lastStep,
  }
);

export const removeLastStep = () => (
  {
    type: REMOVE_CARD_TOOL_LAST_STEP,
  }
);

export const changeHasAdminRights = (hasAdminRights) => (
  {
    type: CHANGE_HAS_ADMIN_RIGHTS,
    hasAdminRights,
  }
);

export const updateCardInCart = (card, price, rCards) => (
  {
    type: UPDATE_CARD_IN_CART,
    card,
    price,
    rCards,
  }
);

export const handleValueChange = (name, value) => (
  {
    type: HANDLE_VALUE_CHANGE,
    name,
    value,
  }
);

export const handleObjectValueChange = (value) => (
  {
    type: HANDLE_OBJECT_VALUE_CHANGE,
    value,
  }
);

export const removePopupEvent = () => (
  {
    type: REMOVE_POPUP_EVENT,
  }
);

export const addPopupEvent = (event) => (
  {
    type: ADD_POPUP_EVENT,
    event,
  }
);

export const setLastLogin = (lastLogin) => (
  {
    type: SET_LAST_LOGIN,
    lastLogin,
  }
);

export const changeCardVerification = (update) => (
  {
    type: CHANGE_CARD_VERIFICATION,
    update,
  }
);

export const changeSwipeVerification = (update) => (
  {
    type: CHANGE_SWIPE_VERIFICATION,
    update,
  }
);

export const setClientSecret = (secret) => (
  {
    type: SET_CLIENT_SECRET,
    secret,
  }
);

export const setOrderCart = (newCards, cards, staticProducts, albumProducts) => (
  {
    type: SET_ORDER_CART,
    newCards,
    cards,
    staticProducts,
    albumProducts,
  }
);

export const increaseCount = () => (
  {
    type: INCREASE_COUNT,
  }
);

export const setProductsCart = (products) => (
  {
    type: SET_PRODUCTS_CART,
    products,
  }
);

export const updateAmountInProduct = (name, amount) => (
  {
    type: UPDATE_AMOUNT_IN_PRODUCT,
    amount,
    name,
  }
);

export const resetCardsInShop = () => (
  {
    type: RESET_CARDS_IN_SHOP,
  }
);

export const updateAmountInStatic = (product) => (
  {
    type: UPDATE_AMOUNT_IN_STATIC,
    product,
  }
);

export const updateAmountInAlbum = (album) => (
  {
    type: UPDATE_AMOUNT_IN_ALBUM,
    album,
  }
);

export const addHeroMakerItem = (item) => (
  {
    type: ADD_HERO_MAKER_ITEM,
    item,
  }
);

export const updateHeroMakerColoredImages = (cachedImages) => (
  {
    type: UPDATE_HERO_MAKER_COLORED_IMAGES,
    cachedImages,
  }
);

export const removeHeroMakerColoredImage = (cachedImageKey, colorsCount) => (
  {
    type: REMOVE_HERO_MAKER_COLORED_IMAGE,
    cachedImageKey,
    colorsCount,
  }
);

export const setHeroMakerCurrentImage = (currentImage) => (
  {
    type: SET_HERO_MAKER_CURRENT_IMAGE,
    currentImage,
  }
);

export const setHeroMakerAssets = (assets) => (
  {
    type: SET_HERO_MAKER_ASSETS,
    assets,
  }
);

export const setHeroMakerItems = (items) => (
  {
    type: SET_HERO_MAKER_ITEMS,
    items,
  }
);

export const resetHeroMaker = () => (
  {
    type: RESET_HERO_MAKER,
  }
);

export const setBodyColor = (bodyColor) => (
  {
    type: SET_BODY_COLOR_HM,
    bodyColor,
  }
);

export const setLightDarkMode = (mode) => (
  {
    type: SET_LIGHT_DARK_MODE_HM,
    mode,
  }
);

export const setTypeAssets = (catType, assets) => (
  {
    type: SET_TYPE_ASSETS_HM,
    catType,
    assets,
  }
);

export const toggleHidePageScrollBar = () => (
  {
    type: TOGGLE_HIDE_PAGE_SCROLLBAR,
  }
);

export const toggleNewCardInShop = (card) => (
  {
    type: TOGGLE_NEW_CARD_IN_SHOP,
    card,
  }
);

export const togglePlayingBoosterAnimation = (isPlaying) => (
  {
    type: TOGGLE_PLAYING_BOOSTER_ANIMATION,
    isPlaying,
  }
);

export const setBoosterData = (boosterName, energyCosts, startDate, endDate, boosterId = null, cards = [], boosterIsApproved = false, allData = null) => (
  {
    type: SET_BOOSTER_DATA,
    boosterName,
    energyCosts,
    startDate,
    endDate,
    boosterId,
    cards,
    boosterIsApproved,
    allData,
  }
);

export const setActiveCard = (cardId, level) => (
  {
    type: SET_ACTIVE_CARD,
    cardId,
    level,
  }
);

export const setGenericCards = (cards) => (
  {
    type: SET_ALL_CARDS,
    cards,
  }
);

export const setBoosterId = (boosterId) => (
  {
    type: SET_BOOSTER_ID,
    boosterId,
  }
);

export const setBoosterAllData = (allData) => (
  {
    type: SET_BOOSTER_ALL_DATA,
    allData,
  }
);

export const toggleExpandedMenu = (isExpanding = null) => (
  {
    type: TOGGLE_EXPANDED_MENU,
    isExpanding,
  }
);

export const toggleRightSidebar = (isExpanding = null) => (
  {
    type: TOGGLE_RIGHT_SIDEBAR,
    isExpanding,
  }
);

export const toggleShoppingCart = (toggle) => (
  {
    type: TOGGLE_SHOP_CART,
    toggle,
  }
);

export const toggleSearch = () => (
  {
    type: TOGGLE_SEARCH,
  }
);

export const toggleNotifications = () => (
  {
    type: TOGGLE_NOTIFICATIONS,
  }
);

export const toggleCollectingChecker = () => (
  {
    type: TOGGLE_COLLECTING_CHECKER,
  }
);

export const resetBoosterData = () => (
  {
    type: RESET_BOOSTER_DATA,
  }
);

export const setAlertToolTypeData = (type, data) => (
  {
    type: SET_ALERT_TOOL_TYPE_DATA,
    dataType: type,
    data,
  }
);

export const resetAlertToolData = () => (
  {
    type: RESET_ALERT_TOOL_DATA,
  }
);

export const setAlertToolAllData = (data) => (
  {
    type: SET_ALERT_TOOL_ALL_DATA,
    data,
  }
);

export const setBoosterActionData = (data) => (
  {
    type: SET_BOOSTER_ACTION_DATA,
    data,
  }
);

export const resetBoosterActionData = () => (
  {
    type: RESET_BOOSTER_ACTION_DATA,
  }
);

export const setCardIDShown = (cardID, isAlbum = false) => (
  {
    type: SET_CARD_ID_SHOWN,
    cardID,
    isAlbum,
  }
);

export const setCouponCode = (code) => (
  {
    type: SET_COUPON_CODE,
    code,
  }
);

export const setInventoryGroup = (group) => (
  {
    type: SET_INVENTORY_GROUP,
    group,
  }
);

export const toggleStorageBoxSelectionCard = (id, obj) => (
  {
    type: TOGGLE_STORAGE_BOX_SELECTION_CARD,
    id,
    obj,
  }
);

export const setStorageBoxId = (id, isMy, viewerId) => (
  {
    type: SET_STORAGE_BOX_ID,
    id,
    isMy,
    viewerId
  }
);

export const setStorageBoxSelectionMode = (mode) => (
  {
    type: SET_STORAGE_BOX_SELECTION_MODE,
    mode,
  }
);

export const resetStorageBoxData = (onlySelection = false) => (
  {
    type: RESET_STORAGE_BOX_DATA,
    onlySelection,
  }
);

export const setCollectorsViewBooster = (booster, streamer) => (
  {
    type: SET_COLLECTORS_VIEW_BOOSTER,
    booster,
    streamer,
  }
);

export const setCollectorsViewCard = (card) => (
  {
    type: SET_COLLECTORS_VIEW_CARD,
    card,
  }
);

export const setCollectorsViewMarketplace = (marketplace) => (
  {
    type: SET_COLLECTORS_VIEW_MARKETPLACE,
    marketplace,
  }
);

export const resetCollectorsViewData = () => (
  {
    type: RESET_COLLECTORS_VIEW_DATA
  }
);

export const setCollectorsViewAllCards = (isShown) => (
  {
    type: SET_COLLECTORS_VIEW_ALL_CARDS,
    isShown,
  }
);

export const setCollectorsViewShowCardInfo = (cardInfo) => (
  {
    type: SET_COLLECTORS_VIEW_SHOW_CARD_INFO,
    cardInfo,
  }
);

export const setCollectorsViewSelectSellInfo = (selectSellInfo) => (
  {
    type: SET_COLLECTORS_VIEW_SELECT_SELL_INFO,
    selectSellInfo,
  }
);

export const resetMarketplaceData = () => (
  {
    type: RESET_MARKETPLACE_DATA,
  }
);

export const setMarketplaceAllData = (data) => (
  {
    type: SET_MARKETPLACE_ALL_DATA,
    data,
  }
);

export const setBoosterLastRedeem = (lastRedeem) => (
  {
    type: SET_BOOSTER_LAST_REDEEM,
    lastRedeem,
  }
);