import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ProgressBar } from 'react-step-progress-bar';
import Avatar from '@material-ui/core/Avatar';
import { withRouter } from 'react-router-dom';

import { getLevelWord, getMatchData, preventEvent, rangeArray } from '../../utils/helper';
import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import AnimationButton from '../Button/AnimationButton';
import SVGIcon from '../SVGIcon/SVGIcon';

import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  itemArea: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    width: 282,
    cursor: 'pointer',
    position: 'relative',
    transition: 'all 0.3s ease-out',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.08)',
    },
  },
  coloredBGLine: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: 3,
    borderRadius: '6px 6px 0px 0px',
  },
  avatar: {
    width: 30,
    height: 30,
    border: 'none',
    marginRight: 6,
  },
  cardDesign: {
    position: 'relative',
    cursor: 'pointer',
    height: 288,
    width: 186,
    margin: '31.5px 0 32.5px',
    overflowY: 'hidden',
    borderRadius: 6,
  },
  rewardArea: {
    height: 81,
    width: 81, ...Helpers.fillColCenter,
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 4,
    fontSize: 12,
  },
}));

const InventoryBoosterRarityCard = (props) => {
  const classes = useStyles();
  const { cardData, t, streamer, booster, collectReward, collectLoading } = props;
  const { card } = cardData || { card: null };

  if (!card)
    return null;

  const levelWord = getLevelWord(null, card.rarity);
  const levelColor = Colors.level[levelWord];

  const {
    avatar,
    name,
  } = getMatchData({ match: streamer, matchModel: 'Streamer' }, 48, 48);

  const freeRewardBoxes = !cardData.reward ? 3 : (3 - cardData.reward.length);

  return (
    <div
      className={classes.itemArea}
      onClick={() => props.history.push(`/inventory/streamer/${streamer._id}/booster/${booster._id}/card/${card._id}`)}
    >
      <div className={classes.coloredBGLine} style={{
        background: `linear-gradient(90deg, ${levelColor}00 0%, ${levelColor} 49%, ${levelColor}00 100%)`,
      }} />

      <div
        style={{
          ...Helpers.fillRowCross,
          padding: '15px 16px 12.5px',
          borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
        }}
      >
        <Avatar
          className={classes.avatar}
          alt="Avatar"
          src={avatar}
        />
        <div>
          <div style={{
            fontSize: 16,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: 186,
          }}>
            {name}
          </div>
        </div>
      </div>

      <div style={{ ...Helpers.fillColCross }}>
        <div className={classes.cardDesign}>
          <div
            style={{
              backgroundImage: `url("${card.card_layer_image}?width=186")`,
              width: 186,
              height: `${(288 * ((100 - cardData.progress) / 100))}px`,
              mixBlendMode: 'luminosity',
              opacity: 0.15,
              backgroundPosition: 'top',
              backgroundRepeat: 'no-repeat',
            }}
          />
          <div
            style={{
              backgroundImage: `url("${card.card_layer_image}?width=186")`,
              width: 186,
              height: `${(288 * (cardData.progress / 100))}px`,
              backgroundPosition: 'bottom',
              backgroundRepeat: 'no-repeat',
            }}
          />
          {(cardData.progress > 0 && cardData.progress < 100) &&
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: `${(288 * ((100 - cardData.progress) / 100))}px`,
              right: 0,
              height: 57,
              borderRadius: '0px 0px 7px 7px',
              boxShadow: '0px -16px 20px #0000007A',
            }}
          />
          }
        </div>
      </div>

      <div
        style={{
          borderTop: '1px solid rgba(255, 255, 255, 0.08)',
          borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
          padding: '22.5px 16px',
          ...Helpers.fillColCenter,
          fontSize: 14,
        }}
      >
        <div style={{ marginBottom: 10 }}>
          {cardData.collected} / {cardData.max} {t('collected')}
        </div>

        <ProgressBar
          percent={cardData.progress}
          filledBackground={Colors.white}
          unfilledBackground="rgba(255, 255, 255, 0.12)"
          height={3}
          width="100%"
        />

        <AnimationButton
          status="whiteGray"
          style={{ marginTop: 16, width: '100%' }}
        >
          <i
            className="fa-light fa-eye"
            style={{ fontSize: 18, marginRight: 8, verticalAlign: 'bottom' }}
          />
          {t('card_overview')}
        </AnimationButton>
      </div>

      {card && card.rarity > 0 &&
      <div style={{ padding: '14.5px 15.5px 14px' }}>
        <div style={{ fontSize: 14, marginBottom: 14 }}>
          {t('rewards')}
        </div>

        <div style={{ display: 'grid', gap: 4, gridTemplateColumns: 'repeat(auto-fill, 81px)' }}>
          {cardData.reward && cardData.reward.map(reward => (
            <div className={classes.rewardArea}>
              {reward.type.toLowerCase() === 'gems' ?
                <SVGIcon
                  src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
                  height={37}
                  width={28}
                  style={{ marginBottom: 3 }}
                />
                :
                <img
                  src={`${conf.cdn_url}/assets/template/icons/xp_reward.png`}
                  alt="XP reward"
                  style={{ margin: '-5px 0' }}
                />
              }
              <div>{reward.amount} {t(`${reward.type.toLowerCase()}_reward`)}</div>
            </div>
          ))}
          {rangeArray(1, freeRewardBoxes)
          .map(idx => (
            <div className={classes.rewardArea} key={`RewardArea${card && card._id}Key${idx}`} />
          ))}
        </div>

        <AnimationButton
          status="neonGreen"
          disabled={cardData.progress < 100 || (cardData.reward && cardData.reward.length === 0) || collectLoading}
          style={{
            marginTop: 8,
            fontFamily: 'Montserrat',
            fontSize: 14,
            fontWeight: 500,
            width: '100%',
            padding: 14,
            border: 'none',
          }}
          onClick={(e) => {
            preventEvent(e);
            if (cardData.reward && !cardData.reward[0].collected)
              collectReward(card && card._id);
          }}
        >
          {collectLoading ?
            <i className="fa-light fa-loader fa-spin" style={{ fontSize: 18 }} />
            :
            t(cardData.reward && cardData.reward[0].collected ? 'reward_collected' : 'collect_reward')
          }
        </AnimationButton>
      </div>
      }
    </div>
  );
};

export default withRouter(InventoryBoosterRarityCard);