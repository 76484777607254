import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

const ScaledImage = (
  {
    src,
    id,
    alt,
    className = '',
    opacity = 1,
    style = {},
    swipeVerification,
  },
) => {
  const { scaleFactor } = swipeVerification;
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setWidth(img.width);
  }, [src]);

  return (
    <img
      src={src}
      id={id}
      alt={alt}
      className={className}
      style={{
        userSelect: 'none',
        maxWidth: 'none',
        transform: 'translateX(0) translateY(0) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1)',
        width: width * scaleFactor,
        opacity: opacity,
        ...style,
      }}
    />
  );
};

const mapStateToProps = (state) => {
  const { swipeVerification } = state;
  return { swipeVerification };
};

export default connect(mapStateToProps)(ScaledImage);
