import React from 'react';
import { useTranslation } from 'react-i18next';

import PageTitle from '../../components/PageTitle/PageTitle';
import CreateAlert from '../../components/AlertSettings/CreateAlert';

const CreateAlertContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle title={t('create_alert')} />
      <CreateAlert t={t} />
    </>
  );
};

export default CreateAlertContainer;