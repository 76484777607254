import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const CardToolHeroMakerPoses = (props) => {
  const classes = useStyles();
  const { poses, updateHero } = props;

  return (
    poses.map(pose => (
      <div key={pose._id} className={classes.poseArea} onClick={() => updateHero(pose)}>
        <img src={`${pose.image}?height=94`} alt="Hero" />
      </div>
    ))
  );
};

const useStyles = makeStyles(() => ({
  poseArea: {
    width: 63,
    height: 95,
    backgroundColor: '#000813',
    border: '1px solid #E12138',
    boxShadow: '0px 0px 10px #E12138BF',
    borderRadius: 6,
    marginRight: 15,
    marginBottom: 12,
    cursor: 'pointer',
    '&:nth-child(5n)': {
      marginRight: 5,
    },
    '&:hover': {
      borderColor: '#690915',
      boxShadow: '0px 0px 20px #690915',
    },
  },
}));

export default CardToolHeroMakerPoses;