import React from 'react';
import { useTranslation } from 'react-i18next';

import Colors from '../../theme/Colors';
import Loading from '../Loading/Loading';
import InventoryBoosterRarityCard from './InventoryBoosterRarityCard';
import StreamerInventoryHeader from './StreamerInventoryHeader';

const InventoryBoosterRarityCards = (props) => {
  const { t } = useTranslation();
  const {
    cardCollection,
    loading,
    streamer,
    booster,
    myViewer,
    collectReward,
    collectLoading,
  } = props;

  return (
    <div style={{ color: Colors.white, position: 'relative', overflow: 'hidden' }}>
      <StreamerInventoryHeader
        t={t}
        streamer={streamer}
        myViewer={myViewer}
        booster={booster}
      />

      <div className="inner-wrapper" style={{ margin: '32px auto' }}>

        {loading ?
          <div style={{ position: 'absolute', left: '50%', top: '50%' }}>
            <Loading />
          </div>
          :
          <div style={{
            display: 'grid',
            gap: 8,
            gridTemplateColumns: 'repeat(auto-fill, 282px)',
          }}>
            {cardCollection && cardCollection.map(cardData => (
              <InventoryBoosterRarityCard
                cardData={cardData}
                t={t}
                streamer={streamer}
                booster={booster}
                key={`RarityCardKey${cardData.card._id}`}
                collectLoading={collectLoading}
                collectReward={collectReward}
              />
            ))}
          </div>
        }
      </div>

    </div>
  );
};

export default InventoryBoosterRarityCards;