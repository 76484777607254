import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import GlobalSeasonInfo from '../../components/Ranking/GlobalSeasonInfo';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';

import conf from '../../conf.json';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  topBG: {
    height: 300,
    width: 'calc(100% - 3px)',
    backgroundPosition: 'center',
    marginBottom: 3,
    position: 'relative',
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,

    [theme.breakpoints.down('md')]: {
      ...Helpers.fillCol,
    },
  },
  item: {
    backgroundColor: 'rgba(255, 255, 255, 0.04)',
    padding: '40px 32px 39px 118px',
    color: Colors.halfWhite,
    position: 'relative',
    cursor: 'pointer',
    '& > div': {
      transition: 'all 0.3s ease-out',
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
      '& > div:last-child': {
        color: Colors.white,
      },
      '& > div:nth-child(2)': {
        textDecoration: 'underline',
      },
    },
  },
  bgItem: {
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    transition: 'all 0.3s ease-out',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  textArea: {
    zIndex: 1,
    position: 'relative',
    padding: 32,

    '& > div:first-child': {
      fontSize: 36,
      fontWeight: 600,
      marginBottom: 8,
    },
    '& > div:last-child': {
      maxWidth: 600,
      lineHeight: 1.875,
    },

    [theme.breakpoints.down('md')]: {
      padding: 8,

      '& > div:first-child': {
        fontSize: 18,
        marginBottom: 4,
      },
      '& > div:last-child': {
        maxWidth: '100%',
        lineHeight: 1,
      },
    },
  },
  gridArea: {
    display: 'grid',
    gap: 3,
    gridTemplateColumns: 'repeat(3, 1fr)',
    marginBottom: 20,
    width: '100%',

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  }
}));

const RankingTop = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { currentSeason, loading, season } = props;
  /*const seasonPreDir = '/assets/seasons/season_' + season;

  const items = [
    {
      title: t('awesome_season_frames'),
      desc: t('awesome_season_frames_desc'),
      href: '/hq/neon-meets-ice',
      bg: '/awesome_frames.png',
    },
    {
      title: t('gain_badges_for_profile'),
      desc: t('gain_badges_for_profile_desc'),
      href: '/hq/neon-meets-ice',
      bg: '/gain_badges.png',
    },
    {
      title: t('complete_season_collection'),
      desc: t('complete_season_collection_desc'),
      href: '/hq/neon-meets-ice',
      bg: '/complete_collection.png',
    },
  ];*/

  return (
    <>
      <div
        className={classes.topBG}
        style={{ backgroundImage: currentSeason && `url(${currentSeason.leaderboard_bg_image})` }}
      >
        <div style={{
          background: 'linear-gradient(90deg, #091828 0%, #091828B8 53%, #09182800 100%)',
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          width: '50%',
        }} />
        <div style={{
          background: 'linear-gradient(90deg, #01102400 0%, #01102457 30%, #011024 100%)',
          position: 'absolute',
          right: 0,
          left: 0,
          top: 0,
          bottom: 0,
        }} />

        <div className={classes.textArea}>
          <div>
            {t('global_leaderboard_season_x', { season: currentSeason && currentSeason.season })}
          </div>
          <div>
            {t('leaderboard_explanation')}
          </div>
        </div>

        {!loading &&
        <GlobalSeasonInfo season={currentSeason} />
        }
      </div>
      {/*<div className={classes.gridArea}>
        {items.map(item => (
          <Link to={item.href} className={classes.item}>
            <div
              className={classes.bgItem}
              style={{ backgroundImage: `url("${conf.cdn_url + seasonPreDir + item.bg}")` }}
            />

            <div style={{ fontWeight: 500, color: Colors.white }}>
              {item.title}
            </div>
            <div style={{ marginTop: 12, fontSize: 14, lineHeight: 1.57 }}>
              {item.desc} <span style={{ textDecoration: 'underline' }}>{t('learn_more')}</span>
            </div>
          </Link>
        ))}
      </div>*/}
    </>
  );
};

export default RankingTop;