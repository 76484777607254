import React, { useEffect, useState } from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { setCouponCode } from '../../redux/actions';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';

const useStyles = makeStyles(() => ({
  formInput: {
    width: 400,
    marginRight: 4,
    background: 'transparent',
    borderColor: '#FFFFFF1F',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FFFFFF1F',
      top: 0,
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FFFFFF1F',
    },
    '& .MuiFormLabel-root': {
      color: Colors.halfWhite,
    },
    '& .MuiOutlinedInput-input': {
      padding: '14px 12px',
    },
  },
  clickButton: {
    height: 43,
    width: 47,
    ...Helpers.fillRowCenter,
    borderRadius: 6,
    background: '#FFFFFF14',
    cursor: 'pointer',
    transition: 'all 0.3s ease-out',
    '&:hover': {
      background: '#FFFFFF3D',
    },
  },
}));

const OrderDiscount = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { couponCode, setCouponCode } = props;
  const [currentCode, setCurrentCode] = useState(couponCode ? couponCode : '');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const activatedCoupon = JSON.parse(localStorage.getItem('activated:coupon'));

    if (activatedCoupon && !couponCode) {
      setCouponCode(activatedCoupon.c);
      setCurrentCode(activatedCoupon.c);
    }
  }, [couponCode]);

  useEffect(() => {
    if (loading && couponCode === currentCode)
      setLoading(false);
  }, [loading, couponCode, currentCode]);

  const handleChange = (event) => {
    setCurrentCode(event.target.value);
  };

  const saveCoupon = () => {
    if (loading)
      return null;

    setCouponCode(currentCode);
    setLoading(true);
  };

  return (
    <div style={{ fontFamily: 'Montserrat', marginTop: 80 }}>
      <div>{t('have_a_discount')}</div>
      <div style={{ ...Helpers.fillRowCross, ...Helpers.crossEnd, marginTop: 10 }}>
        <TextField
          id="discount"
          name="discount"
          label={t('form_discount')}
          variant="outlined"
          onChange={handleChange}
          value={currentCode}
          classes={{
            root: classes.formInput,
          }}
        />
        <div className={classes.clickButton} onClick={saveCoupon}>
          <i className={`fa-light fa-${loading ? 'loader fa-spin' : 'arrow-right-long'}`} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { cardShop } = state;
  const { couponCode } = cardShop;
  return { couponCode };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setCouponCode,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(OrderDiscount);