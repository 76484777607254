import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';
import AnimationButton from '../Button/AnimationButton';

const useStyles = makeStyles({
  footer: {
    background: 'linear-gradient(90deg, #060211 0%, #5303B2 50%, #060211 100%)',
    borderRadius: '0px 0px 15px 15px',
    margin: '0 -22px -22px -22px',
    height: 64,
    position: 'relative',
    ...Helpers.fillRowCross,
  },
});

const HeroMakerFooter = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { value, onClick, backTab, disabled } = props;

  return (
    <div className={classes.footer}>
      {backTab &&
      <AnimationButton status="gray" onClick={backTab} style={{ marginLeft: 12 }}>
        {t('back')}
      </AnimationButton>
      }
      <AnimationButton
        status="neonGreen"
        onClick={onClick}
        disabled={disabled}
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          padding: '11px 32px',
        }}
      >
        {t(value)}
      </AnimationButton>
    </div>
  );
};

export default HeroMakerFooter;