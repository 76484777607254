import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import { getReferrals } from '../../apollo/mergedQueries';

import PageTitle from '../../components/PageTitle/PageTitle';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import ReferAFriend from '../../components/Refer/ReferAFriend';

const ReferAFriendContainer = () => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(getReferrals, {
    fetchPolicy: useCacheWithExpiration('getReferrals'),
  });

  const referrals = data && data.getReferrals;
  const myViewer = data && data.getMyViewer;

  return (
    <>
      <PageTitle title={t('referral_overview')} />
      <ReferAFriend referrals={referrals} loading={loading} myViewer={myViewer} />
    </>
  );
};

export default ReferAFriendContainer;