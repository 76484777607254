import React from 'react';

const TermsOfUseEN = () => {
  return (
    <>
      <p dir="ltr">
        These Terms of Use ("Terms of Use") govern the contractual relationship for the use of the
        services offered at <a
        href="https://www.streamheroes.gg/">https://www.streamheroes.gg</a> or via a mobile
        application ("<b>App</b>") by Streamheroes GmbH ("<b>Streamheroes</b>"), Triererstraße 5,
        66839 Schmelz, Germany, registered inthe Commercial Register of the Commercial Court of
        Saarbrücken under HRB 106787, Tel.: +43 650 4989 426, E-Mail: <a
        href="mailto:support@streamheroes.gg">support@streamheroes.gg</a>, UID-Nr.: DE 335845977.
      </p>

      <h1 dir="ltr">
        SUBJECT OF THE CONTRACT
      </h1>

      <p dir="ltr">
        Streamheroes offers its users an online platform ("<b>Platform</b>") under the website <a
        href="https://www.streamheroes.gg/">https://www.streamheroes.gg</a> or via the App,
        respectively, with which users have the opportunity to collect digital cards. The Platform
        offers users the opportunity to collect rewards when watching certain streamers who publish
        live streams via the online streaming service "<i>twitch.tv</i>" ("<b>Twitch</b>").
      </p>

      <p dir="ltr">
        Here, users log in to the Platform via the website or App using the user data of their
        Twitch user account and can collect virtual energy from certain streamers for every five
        minutes watched, which can be exchanged for virtual cards. The energy cost for a virtual
        card varies depending on the level of the card - the higher a level, the more expensive the
        card. In addition, energy can also be exchanged for virtual coins ("<i>Coins</i>"), which
        can be used to purchase certain collectibles.
      </p>

      <p dir="ltr">
        In addition, the user has the option to customize his profile provided under the Platform
        with certain information, photos or other digital content. In addition, Streamheroes
        provides rankings on the Platform, where users are placed according to the number of minutes
        viewed.
      </p>

      <p dir="ltr">
        A more detailed description of the Platform and its features can be found at <a
        href="https://app.streamheroes.gg/page/faq">https://app.streamheroes.gg/page/faq</a>.
      </p>

      <h1 dir="ltr">
        USE OF THE PLATFORM
      </h1>

      <p dir="ltr">
        Access to the use of the Platform requires a registration via Twitch. For this purpose, the
        user logs in to the Platform via the "<i>Twitch Login</i>" button with the user data that he
        received in the course of creating a user account on Twitch. The Platform can be used
        immediately after the registration has been carried out.
      </p>

      <p dir="ltr">
        By registering for the Platform pursuant to Section 2.1, the user agrees to these Terms of
        Use and to a contractual relationship with Streamheroes. This is expressly confirmed by the
        user when logging in by clicking the button "<i>Accept Terms of Use</i>". The user may at
        any time access the current version of these Terms of Use on our homepage at <a
        href="https://www.streamheroes.gg/page/terms-of-use">https://www.streamheroes.gg/page/terms-of-use</a>,
        save them permanently and print them out. The use of the Platform implies acceptance of
        these Terms of Use.
      </p>

      <p dir="ltr">
        Streamheroes grants the user the non-exclusive, limited, non-transferable and
        non-sublicensable right, limited in time to the duration of the use or contractual
        relationship, to use the Platform in its current version, including updates and other
        components, in accordance with these Terms of Use.
      </p>

      <p dir="ltr">
        The user is responsible for creating the necessary conditions for using the Platform, such
        as a sufficiently fast internet connection or other hardware or software requirements.
      </p>

      <p dir="ltr">
        The user acknowledges and agrees that these Terms of Use only apply to the use of the
        Platform and that Streamheroes has no influence whatsoever on the terms and conditions
        imposed by Twitch (as a third-party) on the users in connection with the Twitch user account
        and the use of Twitch, such as when watching streamers. It is the sole responsibility of the
        user to decide whether to create a corresponding Twitch user account to use the Platform. If
        he does so, it is his sole responsibility to comply with the associated provisions, in
        particular on the use of Twitch.
      </p>

      <p dir="ltr">
        If the Platform is accessed via the App, it is only available to owners of iOS or Android
        smartphones. Compliance with the terms and conditions of providers whose services are
        required for the use of the App (such as Apple or Google) is the sole responsibility of the
        user.
      </p>

      <p dir="ltr">
        Streamheroes can ban any user without reason or notice in order to protect our partners and
        the quality of our platform.
      </p>

      <h1 dir="ltr">
        USE RESTRICTIONS
      </h1>

      <p dir="ltr">
        The use of the Platform is available only to persons over the age of 14. If the user's age
        is between 14 and the legal age of majority in the jurisdiction of his or her residence, the
        Platform may be used only with the consent of the legal representatives (such as a parent).
      </p>

      <p dir="ltr">
        The user may only access or use the Platform for lawful and permissible purposes. In
        particular, the user shall not, by using the Platform:
        <ul>
          <li>
            infringe, misappropriate or violate the rights of Streamheroes, other users or third
            parties (such as, but not limited to, privacy rights, publicity rights, intellectual
            property rights or other proprietary rights);
          </li>
          <li>
            share or incite or encourage illegal, obscene, abusive, threatening, harassing, hateful,
            racist, sexist, homophobic or ethically objectionable content;
          </li>
          <li>
            make any falsehood, misrepresentation or misleading statement, particularly if such
            statement is intended to damage the reputation of Streamheroes;
          </li>
          <li>
            distribute illegal, unsolicited or unauthorized communications, such as, without
            limitation, unauthorized advertising, spam or chain letters;
          </li>
          <li>
            collect or gather information from other users in an unauthorized or improper manner;
            and
          </li>
          <li>
            directly or indirectly burden, impair or damage Streamheroes, its users or third
            parties.
          </li>
        </ul>
      </p>

      <p dir="ltr">
        If not expressly permitted by law, the user may not modify, distribute, copy, decompile,
        rearrange, publish, reverse engineer, reproduce or prepare derivative works based on the
        Platform (or assist others to do so). Specifically, the user is prohibited from (i)
        reverse-engineering or otherwise modifying the Platform's code (ii) introducing viruses or
        other malicious software into the Platform; (iii) obtaining or granting unauthorized access
        to the Platform to any third party; and (iv) developing and/or providing software or APIs
        (application programming interface) that function substantially like the Platform.
      </p>

      <p dir="ltr">
        The user is only permitted to use the Platform for his own, private purposes (commercial
        purposes are excluded), unless the user is at the same time a cooperation partner of the
        Platform and has accepted the corresponding conditions for such cooperation under <a
        href="https://app.streamheroes/page/cooperation-agreement">https://app.streamheroes/page/cooperation-agreement</a>.
        A transfer of the user's account to third parties is prohibited.
      </p>

      <h1 dir="ltr">
        PRICE
      </h1>

      <p dir="ltr">
        The use of the Platform is free of charge. Costs incurred in connection with the use of the
        Platform - such as costs for data transfer or the creation of the prerequisites pursuant to
        Section 2.4 - shall be borne by the user.
      </p>

      <p dir="ltr">
        For the avoidance of doubt, it is noted that digital content earned by the user through
        watched minutes of Twitch streams - such as collected energy - does not represent any real
        economic value. This content cannot and may not be transferred or exchanged for FIAT
        currencies (or other monetary benefits).
      </p>

      <p dir="ltr">
        Streamheroes offers the user the possibility to print virtual cards - upon the user's
        request - as physical cards and to purchase such physical cards against payment. The terms
        and conditions for the purchase of such physical cards (available at <a
        href="https://www.streamheroes.gg/page/terms-and-conditions">https://www.streamheroes.gg/page/terms-and-conditions</a>)
        between Streamheroes and the user shall apply separately to such orders.
      </p>

      <h1 dir="ltr">
        DURATION AND TERMINATION
      </h1>

      <p dir="ltr">
        The contractual relationship is concluded for an indefinite period.
      </p>

      <p dir="ltr">
        The user has the right to terminate the contractual relationship at any time without notice
        by deleting his account. The termination takes place by confirming the button "<i>Delete
        account</i>" in the profile management (<a
        href="https://www.streamheroes.gg/settings">https://www.streamheroes.gg/settings</a>) or via
        the user settings in the App. Alternatively, a termination can be made via the contact form
        or by e-mail at <a href="mailto:support@streamheroes.gg">support@streamheroes.gg</a>.
      </p>

      <p dir="ltr">
        Streamheroes has the right to terminate the contractual relationship subject to a notice
        period of one month. In addition, Streamheroes is entitled to terminate the contractual
        relationship for good cause at any time with immediate effect. In particular, a violation of
        the Terms of Use or the unacceptability of a further contractual relationship with the user
        shall be considered an important reason.
      </p>

      <h1 dir="ltr">
        RIGHT OF WITHDRAWAL AND ELIMINATION OF THE RIGHT OF WITHDRAWAL
      </h1>

      <p dir="ltr">
        The user may revoke his consent to the contractual relationship with Streamheroes informally
        (e.g. by e-mail or letter) within 14 days after conclusion of the contract without stating
        reasons. The revocation is to be addressed to
      </p>

      <p dir="ltr" style={{ textAlign: 'center' }}>
        Streamheroes GmbH<br />
        HRB 106787<br />
        Commercial Court Saarbrücken<br />
        Triererstraße 5, 66839 Schmelz, Germany<br />
        E-mail: <a href="mailto:support@streamheroes.gg">support@streamheroes.gg</a>
      </p>

      <p dir="ltr">
        The exercise of the right of withdrawal pursuant to this Section 6 may be made by means of
        the model withdrawal form, which can be found at the end of the Terms of Use.
      </p>

      <p dir="ltr">
        In the event of an effective withdrawal, the services received by both parties shall be
        returned and any economic benefits shall be surrendered. Obligations for reimbursement must
        be fulfilled within 30 days. The period begins for the user with the dispatch of the notice
        of withdrawal, for Streamheroes with its receipt.
      </p>

      <p dir="ltr">
        Consumers do not have the right of withdrawal if the performance of a service is started at
        the consumer's request before the expiry of the withdrawal period, in such a case, the right
        of withdrawal shall lapse after legal notice of these consequences of premature performance.
      </p>

      <p dir="ltr" style={{ fontWeight: 'bold' }}>
        The user hereby acknowledges and expressly agrees that with the first-time use of the
        Platform, the performance of services by Streamheroes will take place prior to the expiry of
        the withdrawal period and that this entails the loss of the right of withdrawal.
      </p>

      <h1 dir="ltr">
        SUPPORT AND UPDATES
      </h1>

      <p dir="ltr">
        Streamheroes offers support for the Platform via the contact form (<a
        href="https://www.streamheroes.gg/contact">https://www.streamheroes.gg/contact</a>).
      </p>

      <p dir="ltr">
        Streamheroes will provide updates and bug fixes at its sole discretion. Streamheroes is not
        obligated to develop specific updates or provide maintenance services.
      </p>

      <h1 dir="ltr">
        AVAILABILITY AND WARRANTY
      </h1>

      <p dir="ltr">
        The user has the right to assert warranty claims within the scope of the statutory warranty
        provisions. However, Streamheroes shall not provide any warranty
        <ul>
          <li>
            for a constant availability of the Platform. Downtimes due to maintenance, software
            updates and due to circumstances (such as technical problems of third parties, force
            majeure), which are not within Streamheroes' sphere of influence and therefore for which
            Streamheroes is not responsible and due to which the services of Streamheroes are not
            accessible, cannot be excluded;
          </li>

          <li>
            for errors, malfunctions or damage resulting from improper operation or the input of
            incorrect information by the user; and
          </li>

          <li>
            that the services meet the expectations of the user, in particular that success is
            achieved from the use of the Platform as well as from the use of the Platform.
          </li>
        </ul>
      </p>

      <p dir="ltr">
        The Platform is provided "<i>as is</i>" according to the current state of technology and
        "<i>as available</i>". The use of the Platform is at the risk and expense of the user.
      </p>

      <h1 dir="ltr">
        REMOVING VIRTUAL CARDS
      </h1>

      <p dir="ltr">
        It is clarified that the virtual cards that can be purchased on the Platform in exchange for
        virtual energy have been designed by the cooperation partners of the Platform and
        Streamheroes has been granted the right by such cooperation partners under separate
        agreements to offer the virtual cards for collection on the Platform.
      </p>

      <p dir="ltr">
        Under certain circumstances, it may no longer be legally possible for Streamheroes to offer
        certain virtual cards for collection. In such a case, Streamheroes reserves the right to
        remove certain virtual cards on the Platform and to delete cards that have already been
        collected and to refund to the user the corresponding virtual energy costs that the user has
        spent on the deleted cards on the Platform. Streamheroes therefore does not make any
        warranty that virtual cards currently offered or purchased will be available in the future.
      </p>

      <h1 dir="ltr">
        LIABILITY
      </h1>

      <p dir="ltr">
        Subject to Section 10.2, Streamheroes' liability based on slight negligence, provided that
        no material contractual obligations are breached, is generally excluded. Streamheroes shall
        be liable exclusively for damages based on intentional or grossly negligent conduct of
        Streamheroes.
      </p>

      <p dir="ltr">
        The limitations of liability set forth in Section 10.1 shall not apply to damages resulting
        from injury to life, body or health of persons or in the event that mandatory statutory
        provisions to the contrary apply.
      </p>

      <h1 dir="ltr">
        RIGHTS OF USE OF THE USER'S CONTENT
      </h1>

      <p dir="ltr">
        The user grants Streamheroes a worldwide, non-exclusive, royalty-free, sublicensable and
        transferable license, limited to the term of this Agreement, to use, reproduce, distribute,
        create derivative works of, display and perform the information, data and content that the
        user submits, publishes or generates on the Platform ("<b>User Content</b>"). The rights
        granted under this license are limited to the purpose that Streamheroes may operate and
        provide the Platform. This includes, for example, the display of content that the user
        uploads to his profile or the linking of the user's profile and progress to the leaderboards
        on the Platform.
      </p>

      <p dir="ltr">
        The user alone shall be responsible for the User Content; in particular, Streamheroes shall
        have no obligation to constantly monitor the legality of such content. Among other things,
        the user must ensure that the User Content does not infringe any third-party rights, such as
        in particular copyrights or other industrial property rights.
      </p>

      <p dir="ltr">
        Streamheroes reserves the right to delete User Content if Streamheroes becomes aware of the
        illegality of User Content or if the User Content violates these Terms of Use (such as the
        restrictions on use under Section 3).
      </p>

      <h1 dir="ltr">
        IMATERIAL RIGHTS
      </h1>

      <p dir="ltr">
        Except for the license granted to the user in Section 2.3, Streamheroes expressly reserves
        all rights to the Platform, including all worldwide technology, intellectual property and
        proprietary rights therein. User shall not remove, conceal or obscure any copyright notices
        or other proprietary notices of Streamheroes from the Platform.
      </p>

      <h1 dir="ltr">
        LINKS
      </h1>

      <p dir="ltr">
        The Platform may contain links to third-party websites. These are not subject to the control
        and responsibility of Streamheroes. In addition, Streamheroes assumes no liability for
        linked content and external offers.
      </p>

      <h1 dir="ltr">
        DATA PROTECTION
      </h1>

      <p dir="ltr">
        Streamheroes is obligated to comply with the provisions of the General Data Protection
        Regulation (<i>Datenschutz-Grundverordnung</i> "DSGVO") as well as any other statutory
        confidentiality obligations.
      </p>

      <p dir="ltr">
        Furthermore, Streamheroes processes the personal data required for the purpose of fulfilling
        the contract. The detailed data protection information (data protection notice) pursuant to
        Art 13 ff DSGVO is available at <a
        href="https://www.streamheroes.gg/privacy">https://www.streamheroes.gg/privacy</a>.
      </p>

      <h1 dir="ltr">
        MODIFICATION OF THE TERMS OF USE
      </h1>

      <p dir="ltr">
        Streamheroes reserves the right to amend these Terms of Use from time to time, in
        particular, but not limited to, to comply with legal provisions or requirements, to meet
        economic efficiency or to accommodate user interests. The Terms of Use, as amended from time
        to time, are available for review on the Platform.
      </p>

      <p dir="ltr">
        In the event of changes to these Terms of Use, Streamheroes shall either obtain the express
        consent to the changes by the user by means of a corresponding notice on the Platform or
        inform the user by e-mail to the last known e-mail address at least two weeks before the
        changes come into effect.
      </p>

      <p dir="ltr">
        The notice of change will include (i) the updated Terms of Use, (ii) the effective date of
        the changes, (iii) a two-week objection period, and (iv) information about the consequences
        of not objecting.
      </p>

      <p dir="ltr">
        If the user does not object to the changes, the updated Terms of Use shall be deemed
        accepted.
      </p>

      <p dir="ltr">
        Should the user object to the changes, Streamheroes shall be entitled to terminate the
        contractual relationship concerning the use of the Platform with immediate effect if a
        continuation of the contractual relationship within the framework of the amended terms of
        use is not possible or unreasonable for Streamheroes.
      </p>

      <h1 dir="ltr">
        ONLINE DISPUTE RESOLUTION
      </h1>

      <p dir="ltr">
        An online dispute resolution (ODR) platform has been set up at the European Commission to
        resolve disputes arising from online sales contracts and online service contracts. This
        gives consumers the opportunity to resolve disputes out of court first. This can be accessed
        at the link: <a href="https://webgate.ec.europa.eu/odr">https://webgate.ec.europa.eu/odr</a>.
        Streamheroes does not participate in a dispute resolution procedure before a consumer
        arbitration board.
      </p>

      <h1 dir="ltr">
        FINAL PROVISIONS
      </h1>

      <p dir="ltr">
        If any provision of these Terms of Use is or becomes invalid or this agreement contains a
        gap, this shall not affect the validity of the remaining provisions.
      </p>

      <p dir="ltr">
        These Terms of Use and all legal relations and disputes between the user and Streamheroes
        shall be governed exclusively by German law, with the exception of the conflict-of-law rules
        and the UN Convention on Contracts for the International Sale of Goods. In relation to
        consumers, this choice of law shall only apply to the extent that it does not restrict any
        mandatory statutory provisions of the state in which he has his habitual residence.
      </p>

      <p dir="ltr">
        The place of jurisdiction for all disputes arising from or in connection with these Terms of
        Use is the competent court in Steyr, Austria. If the user is a consumer under the applicable
        law and has his domicile or habitual residence in any other jurisdiction or is employed in
        any other jurisdiction, he can only be sued in deviation to the courts in whose jurisdiction
        his domicile, habitual residence or place of employment is located.
      </p>

      <p dir="ltr">
        If these Terms of Use are translated into several languages, the German version shall be the
        authoritative version.
      </p>

      <p dir="ltr" style={{ textAlign: 'center', fontWeight: 'bold' }}>
        <i>Sample cancellation form</i>
      </p>

      <p dir="ltr">
        If you want to cancel the contract, please fill out this form and send it back to<br />
      </p>

      <p dir="ltr" style={{ textAlign: 'center' }}>
        Streamheroes GmbH<br />
        HRB 106787<br />
        Commercial Court Saarbrücken<br />
        Triererstraße 5, 66839 Schmelz, Germany<br />
        E-mail: <a href="mailto:support@streamheroes.gg">support@streamheroes.gg</a>
      </p>

      <p dir="ltr">
        I hereby revoke the contract concluded by me for the use of the online services offered at
        https://www.streamheroes.gg.
      </p>

      <p dir="ltr">
        Ordered on/received on:<br />
        Name of the consumer:<br />
        Date:<br />
        Signature of the consumer (only in case of notification on paper)
      </p>
    </>
  );
};

export default TermsOfUseEN;