import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';

const conf = require('../../conf');

const useStyles = makeStyles(() => ({
  priceArea: {
    borderRadius: 6,
    background: 'rgba(255, 255, 255, 0.04)',
    ...Helpers.fillColCross,
    padding: '48px 0 50px',
  },
  headText: {
    fontSize: 36,
    lineHeight: 1.35,
    fontWeight: 500,
    ...Helpers.textCenter,
  },
}));

const RafflePrices = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const prices = [
    {
      image: '26_RareItem',
      name: 'TRILLUXE',
      info: '1x Rare Special Item gezogen auf der Rapidlan',
    },
    {
      image: '02_DrPepper',
      name: 'DRPEPPER',
      info: '5x DrPepper Gamer Bundle (10% Rabatt mit Code TRILLUXE)',
    },
    {
      image: '03_Greenforce01',
      name: 'GREENFORCE',
      info: '1x Bio Aufstrich Starter Box (25% Rabatt mit Code TRILLUXE)',
    },
    {
      image: '04_Greenforce02',
      name: 'GREENFORCE',
      info: '1x BBQ Revolution Box (25% Rabatt mit Code TRILLUXE)',
    },
    {
      image: '05_Greenforce03',
      name: 'GREENFORCE',
      info: '1x Grill Box (25% Rabatt mit Code TRILLUXE)',
    },
    {
      image: '06_Greenforce04',
      name: 'GREENFORCE',
      info: '1x Greek Starter Box (25% Rabatt mit Code TRILLUXE)',
    },
    {
      image: '07_AOC',
      name: 'AOC',
      info: '1 x AOC GAMING C27G2ZU',
    },
    {
      image: '08_Schweineaimpad',
      name: 'TRILLUXE',
      info: '5x SCHWEINEAIMPAD Schwarz',
    },
    {
      image: '09_Skinport01',
      name: 'SKINPORT',
      info: '1x Skinport Pluschee + USP-S | Caiman (Field-Tested) 26.17€',
    },
    {
      image: '10_Skinport02',
      name: 'SKINPORT',
      info: '1x Skinport Pluschee + StatTrak™ Bayonet | Marble Fade (Factory New) - €612.39',
    },
    {
      image: '11_Skinport03',
      name: 'SKINPORT',
      info: '1x Skinport Pluschee + Desert Eagle | Ocean Drive (Minimal Wear) - 31.39€',
    },
    {
      image: '12_Skinport04',
      name: 'SKINPORT',
      info: '1x Skinport Pluschee + AWP | Hyper Beast (Minimal Wear) - 55.58€',
    },
    {
      image: '13_Skinport05',
      name: 'SKINPORT',
      info: '1x Skinport Pluschee + StatTrak™ AK-47 | Frontside Misty (Field-Tested) - 30.35€',
    },
    {
      image: '14_Displate',
      name: 'DISPLATE',
      info: '40x DISPLATE M/L (25% über Partnerlink bei 1-2 und 29% Rabatt bei 3+ Displates',
    },
    {
      image: '15_Hoerluchs01',
      name: 'HOERLUCHS',
      info: '1x HL2 Gaming Edition (10% Rabatt mit Code TRILLUXE)',
    },
    {
      image: '16_Hoerluchs02',
      name: 'HOERLUCHS',
      info: '3x EasyUp (10% Rabatt mit Code TRILLUXE)',
    },
    {
      image: '17_Philips',
      name: 'PHILIPS',
      info: '3x Philips One Blade 360',
    },
    {
      image: '18_GTTG01',
      name: 'GATE TO THE GAMES',
      info: '2x Schwert & Schild Astralglanz Display (5% Rabatt mit Code TRILLUXE)',
    },
    {
      image: '19_GTTG02',
      name: 'GATE TO THE GAMES',
      info: '6x Schwert & Schild Astralglanz Top Trainer Box (5% Rabatt mit Code TRILLUXE)',
    },
    {
      image: '20_GTTG03',
      name: 'GATE TO THE GAMES',
      info: '1x Schwert & Schild Verlorener Ursprung Display (5% Rabatt mit Code TRILLUXE)',
    },
    {
      image: '21_GTTG04',
      name: 'GATE TO THE GAMES',
      info: '2x Alle 3 Pokemon Sommer Tin Boxen 2022 (5% Rabatt mit Code TRILLUXE)',
    },
    {
      image: '22_GTTG05',
      name: 'GATE TO THE GAMES',
      info: '2x PokemonGO Mewtu Top Trainer Box (5% Rabatt mit Code TRILLUXE)',
    },
    {
      image: '23_GTTG06',
      name: 'GATE TO THE GAMES',
      info: '2x PokemonGO Strahlendes Evoli Premium Kollektion (5% Rabatt mit Code TRILLUXE)',
    },
    {
      image: '24_Rapidlan',
      name: 'RAPIDLAN',
      info: '3x RAPIDLAN Jersey Custom',
    },
    {
      image: '25_BIG',
      name: 'BIG',
      info: '3x 100€ Merch Paket: 1x Hoodie, 1x Shirt, Goodies (10% Rabatt mit Code TRILLUXE)',
    },
  ];

  return (
    <div style={{ marginTop: 100 }}>

      <div className={classes.headText}>
        {t('raffle_what_to_win')}
      </div>

      <div style={{
        display: 'grid',
        gap: 26,
        marginTop: 26,
        gridTemplateColumns: 'repeat(4, 1fr)',
      }}>
        {prices.map((price, index) => (
          <div className={classes.priceArea}>
            <img
              src={`${conf.cdn_url}/Events/Trilluxe-RapidLan-September-2022/prices/trilluxe-rapidlansep2022-Price_${price.image}.png?height=200`}
              alt={`Price ${index}`}
            />
            <div style={{ fontSize: 18, fontWeight: 'bold', marginTop: 20 }}>
              {price.name}
            </div>
            <div style={{ fontSize: 14, color: '#E12138', marginTop: 6, maxWidth: 234, ...Helpers.textCenter }}>
              {price.info}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default withRouter(RafflePrices);