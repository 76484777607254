import React from 'react';
import Chart from 'react-apexcharts';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const KPIPercentDiagram = (props) => {
  const { t } = useTranslation();
  const { title, kpis } = props;
  let dataLength = 0;

  for (let kpi of kpis) {
    if (kpi && kpi.values.length > dataLength) dataLength = kpi.values.length;
  }

  if (dataLength === 0) return <div style={{ margin: 20 }}>
    {t('no_data_for')} {title}
  </div>;

  let categories = new Set();
  const series = [];

  for (let kpi of kpis) {
    if(kpi && kpi.values) {
      const data = kpi.values.map(value => Math.round(value.value * 10) / 10);
      kpi.values.map(value => {
        if (!categories.has(value.check_start)) categories.add(value.check_start);
        return null;
      });

      series.push({
        name: t(kpi._id),
        data,
      });
    }
  }

  categories = [...categories].map(category => moment.unix(category / 1000)
  .format('DD. MMM'));

  const options = {
    chart: {
      width: 1000,
      type: 'bar',
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + ' %';
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
      },
    },
    title: {
      text: title,
      align: 'left',
    },
    theme: {
      mode: 'dark',
      palette: 'palette6',
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val + ' %';
        },
      },
    },
  };

  return (
    <div style={{ padding: 10 }}>
      <Chart
        options={options}
        series={series}
        type="bar"
        width={1000} />
    </div>
  );
};

KPIPercentDiagram.propTypes = {
  kpis: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default KPIPercentDiagram;