import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

import Colors from '../../theme/Colors';

const useStyles = makeStyles(() => ({
  button: {
    fontSize: 20,
    fontWeight: 400,
    textTransform: 'initial',
    transition: '0.3s',
    fontFamily: 'Bebas Neue',
    padding: '6px 25px',
    borderRadius: 50,
    border: '1px solid #7F42F6',
    color: Colors.white,
    background: '#6222F1',
    '&:hover': {
      background: '#7F42F6',
    },
  },
  disabled: {
    filter: 'grayscale(100%)',
    cursor: 'not-allowed',
  },
}));

const SpecialRedeemButton = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { costs, onClick, disabled, isSpinner } = props;

  const buttonClasses = [classes.button];
  if (disabled)
    buttonClasses.push(classes.disabled);

  return (
    <Button
      variant="contained"
      classes={{ root: buttonClasses.join(' ') }}
      onClick={disabled ? null : onClick}
    >
      {t(isSpinner ? 'spin' : 'redeem')} <img
      src={`${process.env.PUBLIC_URL}/icons-png/energy_solo.png`}
      alt="Energy" height={28}
      style={{ margin: '0 5px 3px' }} /> {costs}
    </Button>
  );
};

export default SpecialRedeemButton;