import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Helpers from '../../theme/Helpers';
import PagePopup from '../Dialog/PagePopup';
import Colors from '../../theme/Colors';
import AnimationButton from '../Button/AnimationButton';
import { bindActionCreators } from 'redux';
import {
  resetBoosterActionData,
  setBoosterActionData, setCardIDShown,
  togglePlayingBoosterAnimation,
} from '../../redux/actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles({
  container: {
    ...Helpers.fillColCenter,
    height: '100%',
    maxWidth: 1440,
    margin: '0 auto',
    position: 'relative',
  },
  content: {
    ...Helpers.fillColCenter,
    textAlign: 'center',
  },
  title: {
    marginTop: 0,
    marginBottom: 20,
    fontSize: 26,
    fontWeight: 600,
    textTransform: 'uppercase',
    color: 'white',
  },
  body: {
    fontSize: 16,
    color: Colors.halfWhite,
    margin: 0,
    maxWidth: 560,
    '& > span': {
      color: Colors.white,
    },
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    marginTop: 60,
  },
  button: {
    fontSize: 14,
    padding: '11px 32px',
  },
  image: {
    height: '100%',
    maxHeight: 400,
    marginBottom: 20,
    borderRadius: 4,
  },
});

const DropWonDialog = (props) => {
  const classes = useStyles();
  const { t, onClose, codeType, imageUrl } = props;

  return (
    <PagePopup>
      <div className={classes.container}>
        <div className={classes.content}>
          <img src={`${imageUrl}?height=400`} alt="Drop" className={classes.image} />

          <h2 className={classes.title}>{t('got_drop_dialog_title', { codeType })}</h2>

          <p className={classes.body}
             dangerouslySetInnerHTML={{ __html: t('got_drop_dialog_body', { codeType }) }} />
        </div>

        <div className={classes.buttonContainer}>
          <AnimationButton
            status="whiteTransparency"
            onClick={onClose}
            className={classes.button}
          >
            {t('continue')}
          </AnimationButton>

          <AnimationButton
            status="neonRed"
            href="/inventory/drops"
            onClick={() => {
              props.resetBoosterActionData();
              onClose();
            }}
            className={classes.button}
          >
            {t('go_to_inventory')}
          </AnimationButton>
        </div>
      </div>
    </PagePopup>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    resetBoosterActionData,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(DropWonDialog);
