import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import OnBoardingStepFooter from './OnBoardingStepFooter';
import StreamConfigAlerts from '../StreamConfig/StreamConfigAlerts';
import OnBoardingStepHeader from './OnBoardingStepHeader';

const OnBoardingStreamAlert = (props) => {
  const { t } = useTranslation();
  const { streamer, classes, nextStep, maxLevelCard, testCardsSocket, state, handleChange, save, cards } = props;

  const [loading, setIsLoading] = useState(false);

  const hideButton = () => {
    testCardsSocket();
    setIsLoading(true);

    setTimeout(() => setIsLoading(false), 10000);
  };

  return (
    <>
      <OnBoardingStepHeader
        header={t('on_boarding_alert_head')}
        body={t('on_boarding_alert_text')}
        classes={classes}
      />

      <StreamConfigAlerts
        handleChange={handleChange}
        parentState={state}
        streamer={streamer}
        loading={loading}
        cards={cards}
        hideButton={hideButton}
        maxLevelCard={maxLevelCard} />

      <OnBoardingStepFooter nextStep={nextStep} save={save} classes={classes} />
    </>
  );
};

export default OnBoardingStreamAlert;