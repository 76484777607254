import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import ColorPicker from '../ColorPicker/ColorPicker';
import { setBodyColor, setTypeAssets } from '../../redux/actions';

const useStyles = makeStyles({
  colorArea: {
    position: 'absolute',
    top: 34,
    right: 12,
  },
});

const BodyColorSelection = (props) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const { heroMaker } = props;

  const changeColor = (color) => {
    props.setBodyColor(color);
    props.setTypeAssets('body', null);
  };

  return (
    <div className={classes.colorArea}>
      <ColorPicker
        color={heroMaker.bodyColor ? heroMaker.bodyColor : '#F9DFBC'}
        handleColor={(color) => changeColor(color)}
        disableText
        position="left"
        hasActiveColor={!!heroMaker.bodyColor}
        removeColor={() => changeColor(null)}
        tooltipText={t('body_skin_color')}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { heroMaker } = state;
  return { heroMaker };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setBodyColor,
    setTypeAssets,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(BodyColorSelection);