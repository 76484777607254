import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import { getSearchResults as getSearchResultsMutation } from '../../apollo/mutations';

import HeaderSearch from '../../components/Header/HeaderSearch';
import { replaceWidthAndHeight } from '../../utils/helper';

import conf from '../../conf.json';

const HeaderSearchContainer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const pagination = { page: 1, perPage: 5 };

  useEffect(() => {
    let timeOutId;
    if (search && search.length >= 3) {
      timeOutId = setTimeout(() => getSearchResults({
        variables: {
          pagination,
          filter: { q: search, type: conf.event_mode ? 'streamer' : '' },
        },
      }), 500);
    }
    return () => clearTimeout(timeOutId);
  }, [search]);

  const [getSearchResults, { data, loading }] = useMutation(getSearchResultsMutation, {
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
  });

  let results;

  if (data && data.getSearchResults && search && search.length > 0) {
    const { streamers, viewers, games, organisations } = data.getSearchResults;

    streamers.forEach(function (element) {
      element.type = t('search_streamer');
      element.link = '/profile/' + element.login;
      element.name = element.display_name;
    });
    viewers.forEach(function (element) {
      element.type = t('search_viewer');
      element.link = '/profile/' + element.login;
      element.name = element.display_name;
    });
    games.forEach(function (element) {
      element.type = t('search_game');
      element.link = '/hq/game-streams/' + encodeURIComponent(element.name);
      element.login = element.name;
      element.profile_image_url = replaceWidthAndHeight(element.box_art_url, 40, 40);
    });
    organisations.forEach(function (element) {
      element.type = t('search_organisation');
      element.link = '/organisation/' + element.code;
      element.login = element.code;
      element.profile_image_url = element.logo_url;
    });

    results = [...streamers, ...viewers, ...games, ...organisations];
  } else { // favourites in search
    results = [
      {
        _id: '5defeeb89ae5442dccf817d1',
        type: t('search_streamer'),
        link: '/profile/trymacs',
        login: 'trymacs',
        name: 'Trymacs',
        profile_image_url: 'https://static-cdn.jtvnw.net/jtv_user_pictures/22a56845-20d0-4e14-932e-0ec099b088eb-profile_image-300x300.png',
      },
      {
        _id: '5defef149ae5442dccf81e5e',
        type: t('search_streamer'),
        link: '/profile/dhalucard',
        login: 'dhalucard',
        name: 'Dhalucard',
        profile_image_url: 'https://static-cdn.jtvnw.net/jtv_user_pictures/38de6498-aba3-45c5-8b99-646a0472d861-profile_image-300x300.png',
      },
      {
        _id: '5defeeb99ae5442dccf8194a',
        type: t('search_streamer'),
        link: '/profile/trilluxe',
        login: 'trilluxe',
        name: 'TrilluXe',
        profile_image_url: 'https://static-cdn.jtvnw.net/jtv_user_pictures/464806d7-2c46-4064-ac2a-d28cb8e7e928-profile_image-300x300.png',
      },
      /*{
        _id: '608be11ca82a2045004a1f50',
        type: t('search_game'),
        link: '/hq/game-streams/Sea of Thieves',
        login: 'Sea of Thieves',
        name: 'Sea of Thieves',
        profile_image_url: 'https://static-cdn.jtvnw.net/ttv-boxart/Sea%20of%20Thieves-100x100.jpg',
      },*/
    ];
  }

  return (
    <HeaderSearch
      loading={loading}
      results={(results) && results}
      search={search}
      setSearch={setSearch} />
  );
};

export default HeaderSearchContainer;