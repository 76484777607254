import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';

const useStyles = makeStyles(() => ({
  endLine: {
    width: 2,
    height: 19,
    background: Colors.white,
  },
  midLine: {
    width: '100%',
    height: 2,
    background: Colors.white,
  },
}));

const FrequentLine = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <div style={Helpers.fillRowCross}>
        <div className={classes.endLine} />
        <div className={classes.midLine} />
        <div className={classes.endLine} />
      </div>
      <div style={{
        ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween,
        width: '100%',
        color: Colors.white,
        fontWeight: 300,
      }}>
        <div style={{ marginRight: 10 }}>{t('frequent')}</div>
        <div style={{ marginLeft: 10 }}>{t('rare')}</div>
      </div>
    </>
  );
};

export default FrequentLine;