import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import { setSeasonBorder as setSeasonBorderMutation } from '../../apollo/mutations';
import { getViewerAndStreamer } from '../../apollo/mergedQueries';
import { getMyViewer } from '../../apollo/queries';

import Helpers from '../../theme/Helpers';
import PagePopup from '../Dialog/PagePopup';
import ClosePopupButton from '../Button/ClosePopupButton';
import Colors from '../../theme/Colors';
import CustomAvatar from '../Avatar/CustomAvatar';
import { isUserPremium } from '../../utils/helper';

const useStyles = makeStyles({
  container: {
    ...Helpers.fillColCenter,
    maxWidth: 950,
    margin: '50vh auto 0',
    transform: 'translate(0, -50%)',
    position: 'relative',
    background: '#142134',
    borderRadius: 4,
    color: Colors.white,
  },
  head: {
    padding: '16px 32px',
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: '4px 4px 0px 0px',
    width: 'calc(100% - 64px)',
  },
  body: {
    padding: 32,
    display: 'grid',
    gap: 14,
    gridTemplateColumns: 'repeat(4, 1fr)',
    height: '60vh',
    overflowX: 'auto',
  },
  borderItem: {
    border: '1px solid #FFFFFF24',
    borderRadius: 6,
    transition: 'all 0.3s ease-out',
    ...Helpers.fillColCross,
    width: 209,
    cursor: 'pointer',
    '&:hover': {
      background: '#FFFFFF0F',
      borderColor: '#FFFFFF33',
    },
    '&.active': {
      background: '#FFFFFF1A',
      borderColor: '#FFFFFF4D',
    },
  },
  badge: {
    marginTop: 12,
    background: 'rgba(255, 255, 255, 0.04)',
    color: Colors.halfWhite,
    borderRadius: 4,
    fontSize: 12,
    padding: '4px 9px',
  },
});

const BorderSelectionDialog = ({ seasonBorders, onClose, currentBorder, viewer }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();

  const [setSeasonBorder] = useMutation(setSeasonBorderMutation, {
    onCompleted: () => {
      enqueueSnackbar(t('success'), { variant: 'success' });
      onClose();
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [
      { query: getViewerAndStreamer, variables: { login: viewer.login } },
      { query: getMyViewer },
    ],
  });

  const setBorder = (id) => {
    setSeasonBorder({ variables: { borderid: id } });
  };

  const getLeague = (league) => {
    if (['first', 'second', 'third'].includes(league))
      return 'diamond';
    else
      return league;
  };

  const { isPremiumActive } = isUserPremium(viewer);

  return (
    <PagePopup handleClose={onClose}>
      <div className={classes.container}>
        <div className={classes.head}>
          <div style={{ fontSize: 24, fontWeight: 600 }}>
            {t('border_selection')}
          </div>
          <ClosePopupButton handleClose={onClose}
                            style={{ position: 'relative', width: 30, height: 30 }} />
        </div>
        <div className={classes.body}>
          {seasonBorders && seasonBorders.map(item => (
            <div
              key={item.border._id}
              className={[classes.borderItem, currentBorder && currentBorder._id === item._id ? 'active' : ''].join(' ')}
              onClick={() => setBorder(item._id)}
            >
              <div className={classes.badge}>
                {t('season_x', { season: item.season.season })}
              </div>
              <CustomAvatar
                seasonBorder={{
                  ...item.border,
                  profile_image_url: item.match_meta_data.profile_image_url,
                }}
                rootSize={139}
                leagueSize={16}
                top={{ league: 103 }}
                isPremiumActive={isPremiumActive}
              />
              <div style={{ marginTop: 18 }}>
                {item.match_meta_data.display_name}
              </div>
              <div
                style={{ marginTop: 4, fontSize: 14, color: Colors.halfWhite, marginBottom: 26 }}>
                {t(`rank_${getLeague(item.border.league)}`)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </PagePopup>
  );
};

export default BorderSelectionDialog;
