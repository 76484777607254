import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import Slider from 'react-slick';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import { getLevelWord, rangeArray } from '../../utils/helper';

import conf from '../../conf.json';
import Loading from '../Loading/Loading';
import MarketplaceHomeListItem from './MarketplaceHomeListItem';

const useStyles = makeStyles((theme) => ({
  tabItem: {
    padding: '12px 4px',
    borderBottom: '1px solid transparent',
    marginBottom: -1,
    transition: 'all 0.3s ease-out',
    color: Colors.halfWhite,
    cursor: 'pointer',
    opacity: 0.5,
    ...Helpers.fillRow,
    '&:hover': {
      color: Colors.white,
      opacity: 1,
    },
    '&.active': {
      borderBottomColor: Colors.white,
      color: Colors.white,
      opacity: 1,
    },
  },
  arrowArea: {
    background: 'rgba(255, 255, 255, 0.02)',
    width: '100%',
    ...Helpers.fillRowCenter,
    height: 38,
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.08)',
    },
  },
  fixWidth: {
    '& .slick-track': {
      height: 'auto !important',
    },

    '& .slick-vertical .slick-slide': {
      width: '100% !important',
    },
  },
  levelListings: {
    ...Helpers.fillRowCross,
    ...Helpers.mainSpaceBetween,
    padding: '0 4px',
    borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
    fontSize: 14,

    [theme.breakpoints.down('md')]: {
      display: 'grid',
      gap: 8,
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  }
}));

const MarketplaceHomeList = (props) => {
  const { t } = useTranslation();
  const slider = useRef(null);
  const classes = useStyles();
  const {
    title,
    style,
    loading,
    rarity,
    setRarity,
    items,
    isSold = false,
  } = props;

  const getArrowArea = (isTop) => (
    <div className={classes.arrowArea} onClick={() => onSliderHandle(!isTop)}>
      <i
        className={`fa-light fa-chevron-${isTop ? 'up' : 'down'}`}
        style={{ fontSize: 10 }}
      />
    </div>
  );

  const onSliderHandle = (isNext) => {
    if (isNext)
      slider.current.slickNext();
    else
      slider.current.slickPrev();
  };

  const levelArr = rangeArray(0, 5);
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 2,
    speed: 500,
    arrows: false,
    vertical: true,
  };
  const showSliderButtons = items && items.offers && items.offers.length > 6;

  return (
    <div style={style}>
      <div style={{ fontSize: 24, fontWeight: 600 }}>{title}</div>

      <div style={{ marginTop: 18, background: 'rgba(255, 255, 255, 0.04)', borderRadius: 6 }}>
        <div className={classes.levelListings}>
          <div
            className={[classes.tabItem, rarity === null ? 'active' : ''].join(' ')}
            onClick={() => setRarity(null)}
          >
            {t('all')}
          </div>

          {[...levelArr].sort((a, b) => b - a)
          .map(level => {
            const levelColor = Colors.level[getLevelWord('Card', level)];

            return (
              <div
                className={[classes.tabItem, rarity === level ? 'active' : ''].join(' ')}
                onClick={() => setRarity(level)}
                style={{ color: levelColor }}
                key={`RarenessLevelMenu${level}`}
              >
                <img
                  src={`${conf.cdn_url}/badges/card-level/${level}.png?height=21`}
                  alt="Level badge"
                  style={{ marginRight: 6, verticalAlign: 'bottom' }}
                />
                {t(`rareness_level.${level}`)}
              </div>
            );
          })}
        </div>

        {showSliderButtons && getArrowArea(true)}
        {loading ?
          <div style={{ ...Helpers.fillColCenter, width: '100%' }}>
            <Loading />
          </div>
          :
          <div className={classes.fixWidth}>
            <Slider ref={slider} {...settings}>
              {items && items.offers.map(item => (
                <MarketplaceHomeListItem item={item} isSold={isSold} />
              ))}
            </Slider>
          </div>
        }
        {showSliderButtons && getArrowArea(false)}
      </div>
    </div>
  );
};

export default MarketplaceHomeList;