import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { SpeedDialAction } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFill, faHandshake, faHandshakeSlash } from '@fortawesome/free-solid-svg-icons';

import { getViewerAndStreamer } from '../../apollo/mergedQueries';
import {
  toggleStreamerSHPartnerStatus as toggleStreamerSHPartnerStatusMutation,
  awardStreamerEnergyToUser as awardStreamerEnergyToUserMutation,
} from '../../apollo/mutations';

import AdminSpeedMenuContainer from '../Admin/AdminSpeedMenu';

const ProfileAdminContainer = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { streamer } = props;

  const [toggleStreamerSHPartnerStatus] = useMutation(toggleStreamerSHPartnerStatusMutation, {
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{ query: getViewerAndStreamer, variables: { login: streamer.login } }],
  });
  const [awardStreamerEnergyToUser] = useMutation(awardStreamerEnergyToUserMutation, {
    onCompleted: () => {
      enqueueSnackbar('Success', { variant: 'success' });
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
  });

  const actions = streamer ? [
    {
      icon: <FontAwesomeIcon
        icon={streamer.streamheroes_type !== 'partnered' ? faHandshake : faHandshakeSlash}
      />,
      name: streamer.streamheroes_type !== 'partnered' ? 'Set partner status' : 'Remove partner status',
      action: () => toggleStreamerSHPartnerStatus({ variables: { id: streamer._id } }),
    },
    {
      icon: <FontAwesomeIcon icon={faFill} />,
      name: 'Fill the depot',
      action: () => awardStreamerEnergyToUser({ variables: { id: streamer._id, amount: 100 } }),
    },
  ] : [];

  return (
    <AdminSpeedMenuContainer>
      {actions && actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          title={action.name}
          onClick={() => action.action()}
        />
      ))}
    </AdminSpeedMenuContainer>
  );
};

export default ProfileAdminContainer;