import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient, ApolloLink, InMemoryCache } from 'apollo-boost';
import { onError } from 'apollo-link-error';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment'; // util library for date picker
import { useGLTF } from '@react-three/drei';

import { changeUserToken } from './redux/actions';
import { fragmentTypes } from './utils/fragmentTypes';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import InnerApp from './components/Layout/InnerApp';

const conf = require('./conf');

class MainApp extends React.Component {
  constructor(props) {
    super(props);
    this.checkUserToken();
  }

  checkUserToken() {
    const token = window.localStorage ? window.localStorage.getItem(conf.storage_keys.user_token) : '';

    if (token)
      this.props.changeUserToken(token);
    else
      this.props.changeUserToken(conf.api_token);
  }

  _getClient() {
    // To support result validation and accurate fragment matching on unions and interfaces,
    // a special fragment matcher called the IntrospectionFragmentMatcher must be used
    const fragmentMatcher = new IntrospectionFragmentMatcher({
      introspectionQueryResultData: fragmentTypes,
    });

    const urlOrigin = window.location.origin;
    let apiURL = conf.api_url_PROD;

    if (urlOrigin.includes('localhost'))
      apiURL = conf.api_url_LOCAL;
    else if (!urlOrigin.includes('app.'))
      apiURL = conf.api_url_DEV;

    const uploadLink = createUploadLink({
      uri: apiURL, // Apollo Server is served from port 4000
      headers: {
        'keep-alive': 'true',
      },
    });

    const afterwareLink = onError(({ networkError, graphQLErrors }) => {
      if (networkError && networkError.statusCode === 400 && graphQLErrors && graphQLErrors.length > 0 && graphQLErrors[0].code === 401) {
        this.props.changeUserToken('');
        localStorage.removeItem(conf.storage_keys.user_token);
        window.location.href = 'https://www.streamheroes.gg';
      }
    });

    const cache = new InMemoryCache({ fragmentMatcher });

    const authLink = new ApolloLink((operation, forward) => {
      // Use the setContext method to set the HTTP headers.
      operation.setContext({
        headers: {
          authorization: `Bearer ` + (window.localStorage.getItem(conf.storage_keys.user_token) || conf.api_token),
        },
      });

      // Call the next link in the middleware chain.
      return forward(operation);
    });

    // await before instantiating ApolloClient, else queries might run before the cache is persisted
    // persistCache({
    //   cache,
    //   storage: new SessionStorageWrapper(window.sessionStorage),
    // });

    // Create the apollo client
    return new ApolloClient({
      cache,
      link: afterwareLink.concat(authLink.concat(uploadLink)),
      fetchOptions: {
        mode: 'no-cors',
      },
      name: 'web-app',
      version: '1.0',
    });
  }

  render() {
    const { main } = this.props;

    const client = this._getClient();
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ApolloProvider client={client}>
          <ErrorBoundary>
            <InnerApp userToken={main.userToken} />
          </ErrorBoundary>
        </ApolloProvider>
      </MuiPickersUtilsProvider>
    );
  }
}

const mapStateToProps = (state) => {
  const { main } = state;
  return { main };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    changeUserToken,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(MainApp);

useGLTF.preload(`${conf.cdn_url}/3d-objects/booster_v1.glb`);
useGLTF.preload(`${conf.cdn_url}/3d-objects/Pack_Preview4.glb`);
useGLTF.preload(`${conf.cdn_url}/3d-objects/new_card_v.glb`);