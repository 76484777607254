import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import AnimationButton from '../Button/AnimationButton';
import { setCouponCode } from '../../redux/actions';
import ToastContent from '../Toast/ToastContent';
import Colors from '../../theme/Colors';

import conf from '../../conf.json';

const DropRedeemButton = (props) => {
  const { t, isExpired, drop, couponCode, setCouponCode } = props;
  const isCouponActivated = couponCode && couponCode === drop.code;

  useEffect(() => {
    const activatedCoupon = JSON.parse(localStorage.getItem('activated:coupon'));

    if (activatedCoupon && !couponCode)
      setCouponCode(activatedCoupon.c);
  }, [couponCode, drop.code]);

  const toggleCoupon = () => {
    if (isCouponActivated) {
      localStorage.removeItem('activated:coupon');
      setCouponCode(null);
    } else {

      toast((
        <ToastContent
          title={t('toasts.coupon_activated.title')}
          text={t('toasts.coupon_activated.text')}
          ctaText={t('go_to_shop')}
          ctaLink={`/card-shop`}
          titleColor={Colors.white}
        />
      ), {
        theme: 'xp-collected',
        autoClose: false,
        icon: () => <img src={`${conf.cdn_url}/coupon_drops/icon_sh_coupon_56x56.png`}
                         alt={t('toasts.got_alert_capsule.title')} />,
      });

      localStorage.setItem('activated:coupon', JSON.stringify({
        c: drop.code, // code
        a: drop.droppool.min_order_amount, // min amount of order
        p: drop.droppool.value, // percent of saving
      }));
      setCouponCode(drop.code);
    }
  };

  return (
    <AnimationButton
      status={isCouponActivated ? 'neonRed' : 'neonGreen'}
      onClick={toggleCoupon}
      style={{
        fontFamily: 'Montserrat',
        fontSize: 14,
        width: '100%',
        padding: 14,
        borderRadius: 6,
      }}
      disabled={isExpired}
    >
      {t(isCouponActivated ? 'remove' : 'activate')}
    </AnimationButton>
  );
};

const mapStateToProps = (state) => {
  const { cardShop } = state;
  const { couponCode } = cardShop;
  return { couponCode };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({ setCouponCode }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(DropRedeemButton);