import React from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { getRedeemedCardObjectInformation } from '../../apollo/queries';

import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import Loading from '../../components/Loading/Loading';
import Helpers from '../../theme/Helpers';
import { useQueryParams } from '../../utils/helper';
import ThreeDBoosterAnimation from '../../components/3DCard/3DBoosterAnimation';

const ThreeDBoosterAnimationContainer = (props) => {
  const queryParams = useQueryParams();
  const cardId = queryParams && queryParams.get('cardId');

  const { loading, data } = useQuery(getRedeemedCardObjectInformation, {
    variables: { id: cardId },
    fetchPolicy: useCacheWithExpiration('getRedeemedCardObjectInformation', { id: cardId }),
  });
  const route = `/item-store/booster?cardId=${cardId}`;

  const cardInformation = data && data.getRedeemedCardObjectInformation;

  return (
    <div
      style={{
        background: 'black', ...Helpers.fillRowCenter, position: 'fixed', left: 0, top: 0, right: 0,
        bottom: 0, zIndex: 100000,
      }}
      onClick={() => props.history.push(route)}
    >
      {loading ?
        <Loading />
        :
        <ThreeDBoosterAnimation cardInformation={cardInformation} route={route} />
      }
    </div>
  );
};

export default withRouter(ThreeDBoosterAnimationContainer);