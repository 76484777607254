import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { changeUserToken } from '../../redux/actions';
import AnimationButton from '../Button/AnimationButton';

const conf = require('../../conf');

const Logout = (props) => {
  const { t } = useTranslation();
  const { changeUserToken } = props;

  const logoutUser = () => {
    changeUserToken(null);
    localStorage.removeItem(conf.storage_keys.user_token);
    window.location.href = 'https://www.streamheroes.gg';
  };

  return (
    <AnimationButton
      status="neonRed"
      onClick={logoutUser}
      style={{ padding: '10px 45px' }}
    >
      {t('logout_button_text')}
    </AnimationButton>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    changeUserToken,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(Logout);