import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Colors from '../../theme/Colors';
import CustomBackdropFilter from './CustomBackdropFilter';
import SVGIcon from '../SVGIcon/SVGIcon';
import Helpers from '../../theme/Helpers';
import ShopAccessoriesItem from './ShopAccessoriesItem';

import conf from '../../conf';

const useStyles = makeStyles({
  container: {
    position: 'fixed',
    width: 400,
    right: 32,
    bottom: 0,
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '4px 4px 0px 0px',
    color: Colors.white,
    transition: 'all 0.3s ease-out',
    zIndex: 11,
  },
  head: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    padding: '11px 16px',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.1)',
    },
  },
  body: {
    transition: 'all 0.3s ease-out',
  },
  item: {
    padding: '32px 16px',
  },
});

const ShopAccessories = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { accessories } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <CustomBackdropFilter
      className={classes.container}
      filter={'blur(50px)'}
      canvasFallback={true}
      html2canvasOpts={{
        allowTaint: true,
      }}
    >
      <div
        className={classes.head}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div style={Helpers.fillRowCross}>
          <SVGIcon src={`${conf.cdn_url}/icons/logo.svg`} size={36} />
          <div style={{ marginRight: 10 }}>
            {t('official_accessories')}
          </div>
        </div>
        <div>
          <i
            className={`fa-regular fa-chevron-${isOpen ? 'down' : 'up'}`}
            style={{ fontSize: 18 }}
          />
        </div>
      </div>
      <div className={classes.body} style={{ marginBottom: isOpen ? 0 : -482 }}>
        <div style={{ background: 'rgba(255, 255, 255, 0.04)', padding: '19px 16px' }}>
          {t('you_might_also_need_this')}
        </div>

        {accessories && accessories.map((item, index) => (
          <ShopAccessoriesItem
            item={item}
            index={index}
            isLast={index === accessories.length - 1}
          />
        ))}
      </div>
    </CustomBackdropFilter>
  );
};

export default ShopAccessories;