import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Colors from '../../theme/Colors';
import Loading from '../Loading/Loading';
import HubFavouriteStreamer from '../Hub/HubFavouriteStreamer';
import HubEnergyGathering from '../Hub/HubEnergyGathering';
import ProfileOverviewRecentActivities from '../Profile/ProfileOverviewRecentActivities';
import CollectingChecker from '../CollectingChecker/CollectingChecker';
import Helpers from '../../theme/Helpers';

import conf from '../../conf.json';
import DiagramStatistics from '../Coins/DiagramStatistics';
import Symplr from '../AdSense/Symplr';
import { isUserPremium } from '../../utils/helper';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 16,
    marginTop: 17,

    // otherwise the slider is placed over the heading (and buttons in it)
    position: 'relative',
    zIndex: 1,
  },

  titleLink: {
    fontSize: 14,
    fontWeight: 500,
    color: 'white',
    textDecoration: 'none',
    border: '1px solid rgba(255, 255, 255, 0.08)',
    padding: '8px 20px',
    borderRadius: 6,
    transition: 'all 300ms ease',

    '&:hover': {
      background: 'rgba(255, 255, 255, 0.24)',
      borderColor: 'transparent',
    },
  },

  twoColumnGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
    gap: 25.5,

    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    },
  },
  headItem: {
    ...Helpers.fillColCenter,
    padding: 16,
    background: 'rgba(255, 255, 255, 0.04)',
    '& > div:first-child': {
      fontWeight: 500,
      color: Colors.halfWhite,
    },
  },
  headSmallText: {
    fontSize: 14,
    color: 'green',
    marginTop: 12,
    maxWidth: 384,
    lineHeight: 1.5,
  },
  diagramWrapper: {
    margin: '16px 0',
    padding: 20,
    backgroundColor: Colors.containerBGColor,
  },
}));

const EnergyComponent = (props) => {
  const classes = useStyles();
  const {
    t,
    loading,
    myViewer,
    todayCollectedStreamer,
    favoriteStreamer,
    refetch,
    activities,
    activitiesPrivacySettings,
    setActivitiesPrivacySettings,
    workerStatus,
    timeBasedData,
  } = props;

  const { isPremiumActive } = isUserPremium(myViewer);

  return (
    <div style={{ position: 'relative', color: Colors.white }}>
      <div style={{
        backgroundImage: `url("${conf.cdn_url}/assets/slider/item-store-slider-step-00-bg.png")`,
        backgroundSize: 'cover',
        position: 'relative',
        height: 'calc(300px - 64px)',
        ...Helpers.fillColCenter,
        padding: 32,
      }}>
        <h2 style={{ textTransform: 'uppercase', marginTop: 0, fontSize: 36, marginBottom: 8 }}>{t('energy')}</h2>
        <div
          style={{ maxWidth: 861, lineHeight: 1.6, ...Helpers.textCenter }}
          dangerouslySetInnerHTML={{__html: t('energy_page_desc')}}
        />

        {(conf.ad_area.energy && myViewer && !isPremiumActive) &&
        <div
          style={{
            position: 'absolute',
            right: 8,
            bottom: 8,
          }}
        >
          <Symplr id="streamheroes.gg_mr_1" />
        </div>
        }
      </div>
      <div style={{
        display: 'grid',
        gap: 3,
        gridTemplateColumns: 'repeat(4, 1fr)',
        marginTop: 3,
        marginBottom: 30,
      }}>
        <div className={classes.headItem}>
          <div>{t('your_energy_status')}</div>
          <div className={classes.headSmallText}>
            {workerStatus && workerStatus.active_energy_status ?
              t('active')
              :
              <span style={{ color: 'red' }}>{t('inactive')}</span>
            }
          </div>
        </div>
        <div className={classes.headItem}>
          <div>{t('active_collectors')}</div>
          <div className={classes.headSmallText}
               style={{ color: Colors.white, fontSize: 26, marginTop: 6 }}>
            {workerStatus && workerStatus.collectors.toLocaleString()}
          </div>
        </div>
        <div className={classes.headItem}>
          <div>{t('global_energy_status')}</div>
          <div className={classes.headSmallText}>
            {workerStatus && t(workerStatus.global_status)}
          </div>
        </div>
        <div className={classes.headItem}>

        </div>
      </div>

      <div className="inner-wrapper">

        {loading ?
          <Loading />
          :
          <>
            <div className={classes.twoColumnGrid} style={{ marginTop: 20 }}>
              <div>
                <div className={classes.title}>
                  {t('energy_gathering')}
                </div>

                <HubEnergyGathering t={t} myViewer={myViewer}
                                    todayCollectedStreamer={todayCollectedStreamer}
                                    refetch={refetch} />
              </div>

              <div>
                <div className={classes.title}>
                  {t('collecting_checker_title')}
                </div>

                <CollectingChecker />
              </div>
            </div>

            <div className={classes.title}>
              {t('depot_watch_statistics')}
            </div>
            <div className={classes.diagramWrapper}>
              {loading ?
                <Loading />
                :
                timeBasedData ?
                  <DiagramStatistics timeBasedData={timeBasedData} />
                  :
                  <div style={{ ...Helpers.fillColCenter, padding: 40 }}>
                    <Loading />
                    <div style={{
                      color: Colors.white,
                      fontSize: 20,
                      marginTop: 10,
                    }}>
                      {t('we_collect_data_comeback_24h')}
                    </div>
                  </div>
              }
            </div>

            {favoriteStreamer && favoriteStreamer.length > 0 &&
            <>
              <div className={classes.title}>
                {t('your_favourite_streamer')}
              </div>
              <HubFavouriteStreamer t={t} favoriteStreamer={favoriteStreamer} />
            </>
            }

            <div>
              <ProfileOverviewRecentActivities
                viewer={myViewer}
                isMe
                activities={activities}
                activePrivacySettings={activitiesPrivacySettings}
                setActivitiesPrivacySettings={setActivitiesPrivacySettings}
              />
            </div>

          </>
        }

      </div>

    </div>
  );
};

export default EnergyComponent;