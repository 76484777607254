import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';

import GameLiveStreamsListItem from './GameLiveStreamsListItem';
import Loading from '../Loading/Loading';
import Helpers from '../../theme/Helpers';
import { returnMobileObj } from '../../utils/responsive';

const useStyles = makeStyles(theme => ({
  infiniteScroll: {
    overflow: 'inherit !important',
  },
}));

const GameLiveStreamList = (props) => {
  const { entries, onLoadMore, hasMore, isTabletOrMobile } = props;
  const classes = useStyles();

  const getAllIndexes = (val) => {
    const indexes = [];
    let i;
    for (i = 0; i < entries.length; i++)
      if (entries[i]._id === val._id)
        indexes.push(i);
    return indexes;
  };

  if (!entries) {
    return null;
  }

  return (
    <InfiniteScroll
      dataLength={entries.length}
      next={onLoadMore}
      hasMore={hasMore}
      loader={<Loading />}
      className={classes.infiniteScroll}
    >
      <List style={{
        ...Helpers.fillRowMain,
        padding: 40,
        ...returnMobileObj(isTabletOrMobile, { padding: 10, zoom: 0.8 }),
      }}>
        {entries.map((item, key) => {
            if (getAllIndexes(item).length === 1) {
              return (
                <GameLiveStreamsListItem
                  key={item._id}
                  game={item}
                  index={key}
                  isTabletOrMobile={isTabletOrMobile}
                />
              );
            }
            return null;
          },
        )}
      </List>
    </InfiniteScroll>
  );
};

export default GameLiveStreamList;