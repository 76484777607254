import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

import Colors from '../../theme/Colors';
import AnimationButton from '../../components/Button/AnimationButton';

const useStyles = makeStyles(() => ({
  formInput: {
    width: 400,
    marginRight: 4,
    background: 'transparent',
    borderColor: '#FFFFFF1F',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FFFFFF1F',
      top: 0,
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FFFFFF1F',
    },
    '& .MuiFormLabel-root': {
      color: Colors.halfWhite,
    },
    '& .MuiOutlinedInput-input': {
      padding: '14px 12px',
    },
  },
}));

const CheckPhysicalCard = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { handleChange, value, onCheck, loading } = props;

  return (
    <>
      <TextField
        id="check"
        name="check"
        variant="outlined"
        value={value}
        onChange={handleChange}
        classes={{
          root: classes.formInput,
        }}
      />
      <AnimationButton status="whiteTransparency" onClick={onCheck} disabled={loading}>
        {loading ?
          <i className="fa-light fa-loader fa-spin" style={{ fontSize: 18 }} />
          :
          t('check_physical_card')
        }
      </AnimationButton>
    </>
  );
};

export default CheckPhysicalCard;