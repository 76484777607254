import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Colors from '../../theme/Colors';

const useStyles = makeStyles((theme) => ({
  link: {
    color: Colors.white,
    textDecoration: 'underline',
    transition: 'all 0.3s ease-out',
    '&:hover': {
      opacity: 0.8,
      textDecoration: 'none',
    },
  },
  positioning: {
    position: 'absolute',
    left: 32,
    top: 22,
    fontSize: 12,
    color: Colors.white,
    zIndex: 1,

    [theme.breakpoints.down('md')]: {
      left: 8,
      top: 8,
    },
  }
}));

const ReturnBreadcrumbs = (props) => {
  const classes = useStyles();
  const { parts } = props;

  return (
    <Breadcrumbs
      separator={
        <i className="fa-regular fa-chevron-right"
           style={{ color: Colors.halfWhite, fontSize: 12 }} />
      }
      aria-label="breadcrumb"
      className={classes.positioning}
    >
      {parts.map((part, index) => (
        (!part.link || index === parts.length - 1) ?
          <div
            key={`URLPartKey#${part.name}${index}`}
            style={{ color: index === parts.length - 1 ? Colors.halfWhite : Colors.white }}
          >
            {part.name}
          </div>
          :
          <Link
            to={part.link}
            key={`URLPartParamKey#${part.name}`}
            className={classes.link}
          >
            {part.name}
          </Link>
      ))}
    </Breadcrumbs>
  );
};

export default withRouter(ReturnBreadcrumbs);