import React, { useState } from 'react';

import BaseSelection from './BaseSelection';
import HeroMakerFooter from './HeroMakerFooter';

import conf from '../../conf.json';

const CharacterSelection = (props) => {
  const { footerValue, nextTab, character, setCharacter } = props;
  const selection = ['01_male_chibi', '02_female_chibi', '01_male_realistic', '01_male_chibi', '02_female_chibi', '01_male_realistic'];
  const styleNames = ['Chibi', 'Chibi', 'Realistic', 'Chibi', 'Chibi', 'Realistic'];
  const findIndex = selection.findIndex(char => character === char);
  const [index, setIndex] = useState(findIndex !== -1 ? findIndex : 0);

  const saveAndNextTab = () => {
    setCharacter(selection[index]);
    nextTab();
  };

  const assets = selection
  .map(character => `${conf.cdn_url}/heromaker/${character}/preview.png`);

  return (
    <>
      <div>
        <BaseSelection index={index} setIndex={setIndex} assets={assets} styleNames={styleNames} />
      </div>
      <HeroMakerFooter onClick={saveAndNextTab} value={footerValue} />
    </>
  );
};

export default CharacterSelection;