import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { useTranslation } from 'react-i18next';

import { multiplyEnergyCoins, replaceWidthAndHeight } from '../../utils/helper';
import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import TransferPopup from '../Dialog/TransferPopup';
import { returnMobileObj } from '../../utils/responsive';
import IOSSwitch from '../Switch/Switcher';
import AnimationButton from '../Button/AnimationButton';
import HoverIcon from '../Icon/HoverIcon';
import CustomTooltip from '../Tooltip/CustomTooltip';

import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  itemWrapper: {
    color: Colors.white,
    padding: 10,
    borderTop: '1px solid #1B2C43',
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    '&:last-child': {
      borderBottom: '1px solid #1B2C43',
    },
  },
  streamerAvatar: {
    height: 30,
    width: 30,
    borderColor: '#b81946',
    borderWidth: 1,
  },
  gameAvatar: {
    height: 30,
    width: 20,
    marginLeft: 5,
    marginRight: 5,
    borderRadius: 5,
    borderColor: '#b81946',
    borderWidth: 1,
  },
  itemPointsBody: {
    fontSize: 14,
    fontWeight: 400,
    marginRight: 10,
    ...Helpers.fillRowCenter,
  },
  icon: {
    height: 42,
    width: 21,
    background: `url(${process.env.PUBLIC_URL}/icons-png/energy.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 400%',
  },
  name: {
    marginLeft: 5,
    fontWeight: 400,
    width: 160,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  limitWarn: {
    color: '#A5252E',
    marginLeft: 5,
    ...Helpers.fillRowCross,
    cursor: 'pointer',
  },
}));

const CoinsByItemListItem = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    entry,
    myViewer,
    isTabletOrMobile,
    toggleConvertingItem,
    convertingList,
    convertMode,
    isPremiumActive,
  } = props;
  const [show, setShow] = useState(false);
  const height = 45, width = 35;

  let redirectUrl, image, name, avatarVariant, avatarClass;
  if (entry.matchModel === 'Game') {
    redirectUrl = '/hq/game-streams/' + encodeURIComponent(entry.match.name);
    image = replaceWidthAndHeight(entry.match.box_art_url, width, height);
    name = entry.match.name;
    avatarVariant = 'square';
    avatarClass = classes.gameAvatar;
  } else if (entry.matchModel === 'Streamer') {
    redirectUrl = '/profile/' + encodeURI(entry.match.login);
    image = entry.match.profile_image_url;
    name = entry.match.display_name;
    avatarVariant = 'circle';
    avatarClass = classes.streamerAvatar;
  } else if (entry.matchModel === 'Organisation') {
    redirectUrl = '/organisation/' + encodeURI(entry.match.code);
    image = entry.match.logo_url;
    name = entry.match.name;
    avatarVariant = 'circle';
    avatarClass = classes.streamerAvatar;
  }

  const coins = entry.points_season - entry.redeemed_points;
  const maxCoinsPerItem = isPremiumActive ? conf.premium_tank_size : 100;
  const limitReached = coins >= maxCoinsPerItem;
  let energyStep = limitReached ? 4 : Math.ceil(coins / (maxCoinsPerItem / 3));
  if (energyStep > 0)
    energyStep -= 1;

  return (
    <div
      className={classes.itemWrapper}
      style={{
        ...(limitReached ? { background: 'linear-gradient(to right, #05132B 0%, #25112A 45%, #25112A 65%, #05132B 100%)' } : {}),
        ...returnMobileObj(isTabletOrMobile, { padding: '10px 5px 10px 0' }),
      }}
    >
      <div style={{ ...Helpers.fillRowCenter }}>
        <Avatar
          variant={avatarVariant}
          alt="Avatar"
          src={image}
          classes={{ root: avatarClass }}
        />
        <Link
          href={redirectUrl}
          className={classes.name}
          style={returnMobileObj(isTabletOrMobile, { marginLeft: 5, width: 100 })}
        >
          {name}
        </Link>
        {(limitReached && !isTabletOrMobile) &&
        <div className={classes.limitWarn} onClick={() => props.history.push('/supporter')}>
          <CustomTooltip
            title={t(maxCoinsPerItem === 100 ? 'depot_too_much_warning' : 'depot_full_warning')}>
            <div style={{ borderRadius: 50 }}>
              <HoverIcon
                icon="fa-light fa-circle-exclamation" hoverIcon="fa-solid fa-circle-exclamation"
                color={'#A5252E'}
                style={{ fontSize: 18 }}
              />
            </div>
          </CustomTooltip>
        </div>
        }
      </div>
      <div
        style={{ ...Helpers.fillRowCenter, ...limitReached ? { color: '#cc1e31' } : {} }}
      >
        <div className={classes.itemPointsBody}>
          {multiplyEnergyCoins(coins)}/{multiplyEnergyCoins(maxCoinsPerItem)}
        </div>
        {!isTabletOrMobile &&
        <>
          <div
            className={classes.icon}
            style={{
              backgroundPosition: `0 ${energyStep * -42}px`,
            }}
          />

          <i
            className="fa-sharp fa-solid fa-arrow-right-long"
            style={{ margin: '0 10px' }}
          />
        </>
        }

        {convertMode ?
          <IOSSwitch
            checked={convertingList.findIndex(cItem => cItem._id === entry._id) !== -1}
            onChange={() => toggleConvertingItem(entry)}
            name="transferEnergy"
          />
          :
          <AnimationButton
            status="neonPurple"
            onClick={() => setShow(true)}
            style={{ padding: 8 }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/icons-png/sh_coins.png`}
              alt="SH Coins"
              height={20}
              style={{ marginBottom: -5 }}
            />
          </AnimationButton>
        }
      </div>
      {show &&
      <TransferPopup
        handleClose={() => setShow(false)}
        maxEnergy={coins}
        myViewer={myViewer}
        type={entry.matchModel.toLowerCase()}
        id={entry.match._id}
        isTabletOrMobile={isTabletOrMobile}
      />
      }
    </div>
  );
};

export default withRouter(CoinsByItemListItem);