import {
  CHANGE_HAS_ADMIN_RIGHTS,
  CHANGE_TOKEN,
  CHANGE_TOKEN_AND_REDIRECT,
} from './constants';
import conf from '../conf';

const INITIAL_STATE = {
  userToken: '',
  isLoggedIn: false,
  hasAdminRights: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
  let newState;

  if (action.type === CHANGE_TOKEN) {
    newState = action.userToken === conf.api_token ? {
      userToken: action.userToken,
      isLoggedIn: false,
    } : { userToken: action.userToken, isLoggedIn: true };
    return { ...state, ...newState };
  } else if (action.type === CHANGE_TOKEN_AND_REDIRECT) {
    action.history.push(action.route);

    newState = { userToken: action.userToken, isLoggedIn: true };
    return { ...state, ...newState };
  } else if (action.type === CHANGE_HAS_ADMIN_RIGHTS && state.hasAdminRights !== action.hasAdminRights) {
    return { ...state, hasAdminRights: action.hasAdminRights };
  } else {
    return state;
  }
};

export default userReducer;