import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Helpers from '../../theme/Helpers';
import { ordinal } from '../../utils/helper';
import Colors from '../../theme/Colors';

import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  container: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: '0px 0px 6px 6px',
    width: 'calc(100% - 32px)',
    height: 'calc(100px - 32px)',
    padding: 16,
  },
  item: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    padding: '7px 20px',
    ...Helpers.fillRowCenter,
    cursor: 'pointer',
    margin: '0 8px',
    textDecoration: 'none',
    color: Colors.white,
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.1)',
    },
  },
  avatar: {
    width: 36,
    height: 36,
    border: 'none',
    marginRight: 20,
  },
  arrowArea: {
    width: 42,
    height: 68,
    ...Helpers.fillRowCenter,
    borderRadius: 6,
    fontSize: 16,
    color: Colors.white,
    background: 'rgba(255, 255, 255, 0.06)',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.12)',
      color: Colors.white,
    },
    '&:before': {
      content: 'none',
    },
  },
}));

const RaffleMatchesSlider = (props) => {
  const classes = useStyles();
  const { t, matches } = props;

  const ArrowArea = (props) => {
    const { className, styling, onClick, icon } = props;
    return (
      <div
        className={[className, classes.arrowArea].join(' ')}
        style={styling}
        onClick={onClick}
      >
        {icon}
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1
        }
      }
    ],
    arrows: true,
    prevArrow: <ArrowArea styling={{ left: 0 }}
                          icon={<i className="fa-light fa-chevron-left" />} />,
    nextArrow: <ArrowArea styling={{ right: 0 }}
                          icon={<i className="fa-light fa-chevron-right" />} />,
  };

  return (
    <div className={classes.container}>
      <Slider {...settings} style={{ width: '100%', padding: '0 57px' }}>
        {matches && matches
        .map(match => (
          <div key={match._id}>
            <Link to={`/profile/${match.login}`} className={classes.item}>
              <div style={{ ...Helpers.fillRowCenter, width: 51, height: 54 }}>
                <Avatar
                  className={classes.avatar}
                  alt={match.name}
                  src={match.avatar}
                />
              </div>
              <div>
                <div style={{
                  fontSize: 14,
                  fontWeight: 700,
                  maxWidth: 200,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}>
                  {match.name}
                </div>
              </div>
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default withRouter(RaffleMatchesSlider);