import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import Helpers from '../../theme/Helpers';
import CustomTooltip from '../Tooltip/CustomTooltip';
import Colors from '../../theme/Colors';
import HoverIcon from '../Icon/HoverIcon';
import SetHeadRow from '../Booster/SetHeadRow';
import { detectBrowser, getMatchData } from '../../utils/helper';
import DropRedeemButton from './DropRedeemButton';
import PagePopup from '../Dialog/PagePopup';
import MiniCountdown from '../Countdown/MiniCountdown';
import HiddenElement from '../HiddenElement/HiddenElement';
import IconWrapper from '../Icon/IconWrapper';
import ThreeDWrapper from '../3DCard/3DWrapper';
import Scene from '../3DCard/Scene';
import CardViewCSSFlip from '../Cards/CardViewCSSFlip';
import CardImageDecision from '../Cards/CardImageDecision';

const useStyles = makeStyles({
  container: {
    height: '100%',
    maxWidth: 1440,
    margin: '0 auto',
  },
  content: {
    ...Helpers.fillRowCenter,
    marginTop: 193,
    position: 'relative',
  },
  contentColumn: {
    ...Helpers.fillColCenter,
    flex: 1,
  },
  detailsHeader: {
    fontSize: 36,
    textAlign: 'left',
    fontFamily: 'Bebas Neue',
    textTansform: 'uppercase',
  },
  details: {
    width: 371,
    alignSelf: 'flex-end',
  },
  id: {
    margin: '6px 0',
    opacity: 0.5,
    fontSize: 12,
  },
  hoverInfoArea: {
    background: 'rgba(225, 33, 56, 0.17)',
    color: '#E12138',
    padding: '5px 8px',
    borderRadius: 4,
    fontFamily: 'Montserrat',
    fontSize: 12,
    cursor: 'pointer',
    '&:hover': {
      background: '#E12138',
      color: Colors.white,
    },
  },
  info: {
    ...Helpers.fillRowCross,
    ...Helpers.mainSpaceBetween,
    margin: '20px 0 10px',
  },
  popupImage: {
    position: 'absolute',
    top: -130,
  },
  blurredItem: {
    background: 'rgba(26, 45, 88, 0.24)',
    filter: 'blur(50px)',
    width: 646,
    height: 646,
    position: 'absolute',
    borderRadius: '50%',
    top: -150,
    zIndex: -1,
  },
  area: {
    padding: '12px 20px',
    borderRadius: 4,
    background: 'rgba(255, 255, 255, 0.04)',
    '& > div:first-child': {
      color: Colors.halfWhite,
      fontSize: 12,
    },
  },
  countdownArea: {
    marginTop: 6,
    '& > div': {
      ...Helpers.mainStart,
    },
    '& div': {
      fontFamily: 'Montserrat',
      textTransform: 'inherit',
      color: '#FFFFFF80',
      '& > span': {
        color: Colors.white,
      },
    },
  },
  tooltipDescription: {
    color: Colors.halfWhite,
    '& > span': {
      color: Colors.white,
    },
  },
});

const DropPopup = (props) => {
  const classes = useStyles();
  const { drop, t, onClose, isExpired, endTime, now } = props;

  const collected = `Collected ${moment
  .unix(drop.created_at / 1000)
  .format('DD.MM.YYYY')}`;
  const matchData = getMatchData({}, 73, 73);

  const information = {
    front_layers: [
      {
        img_url: drop.droppool.image,
      },
    ],
    image: drop.droppool.image,
    noEffectLayer: true,
  };

  return (
    <PagePopup>
      <div className={classes.container}>
        <SetHeadRow name={matchData.name} logoUrl={matchData.avatar} disableLink
                    handleClose={onClose} url={matchData.url} subTitle={collected} />

        <div className={classes.content}>
          <div className={classes.contentColumn} />

          <div className={classes.contentColumn} style={{ top: 100 }}>
            <CardImageDecision
              card={information}
              information={information}
              isLegacy
              style={{
                height: 604,
                width: 498,
              }}
              zoom={3.5}
            />
          </div>

          <div
            className={classes.contentColumn}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'baseline',
            }}
          >
            <div className={classes.details}>
              <div className={classes.detailsHeader}>
                {drop.droppool.type === 'FreeShipping' ?
                  t('free_shipping_code')
                  :
                  <>
                    {`${drop.droppool.value}% ${t('coupon_code')}`}
                  </>
                }
              </div>

              <div className={classes.area} style={{ marginTop: 16 }}>
                <div>{t('expires_in')}</div>
                {!isExpired ?
                  <div className={classes.countdownArea}>
                    <MiniCountdown endMoment={endTime} showMore now={now} />
                  </div>
                  :
                  <div
                    style={{ color: '#E12138', marginTop: 8, fontWeight: 500 }}>{t('expired')}</div>
                }
              </div>

              <div className={classes.area} style={{ marginTop: 8 }}>
                <div style={{ marginBottom: 9 }}>
                  {t('your_coupon_code')}
                </div>

                <HiddenElement value={drop.code} hideInfo />
              </div>

              <div className={classes.info}>
                <CustomTooltip
                  title={
                    <div
                      className={classes.tooltipDescription}
                      dangerouslySetInnerHTML={{
                        __html: t(drop.droppool.type === 'FreeShipping' ?
                          'free_shipping_code_description' :
                          'coupon_code_description', { minAmount: drop.droppool.min_order_amount / 100 }),
                      }}
                    />
                  }
                >
                  <div className={classes.hoverInfoArea}>{t('requirements_to_redeem')}</div>
                </CustomTooltip>
              </div>

              <DropRedeemButton isExppired={isExpired} t={t} drop={drop} />
              <div className={classes.id}>{`ID: ${drop._id}`}</div>
            </div>
          </div>
        </div>
      </div>
    </PagePopup>
  );
};

export default DropPopup;
