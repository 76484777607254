import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

import CardShopFilterHead from '../CardShop/CardShopFilterHead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Colors from '../../theme/Colors';

const useStyles = makeStyles({
  filterItem: {
    width: 'calc(100% - 20px)',
    background: 'rgba(255, 255, 255, 0.04)',
    padding: '8px 10px',
    marginTop: 8,
    borderRadius: 6,
    '& div, & svg': {
      cursor: 'pointer',
    },
  },
  textField: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(255, 255, 255, 0.12)',
      borderRadius: 6,
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.halfWhite,
    },
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
      fontSize: 14,
      fontFamily: 'Montserrat',
      '&::placeholder': {
        color: Colors.halfWhite,
      },
    },
  },
  placeholderColor: {
    minWidth: '100px !important',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    padding: '15px !important',
    '&::placeholder': {
      color: Colors.halfWhite,
    },
  },
  endAdornment: {
    right: 10,
  },
  popupIndicator: {
    '& span': {
      '& svg': {
        '& path': {
          fill: Colors.halfWhite,
        },
      },
    },
  },
  listWrapper: {
    marginTop: 10,
    borderRadius: 10,
  },
  listArea: {
    background: 'rgba(20, 39, 64, 0.83)',
  },
  groupLabel: {
    background: 'rgba(20, 39, 64, 0.83)',
    color: Colors.white,
  },
  listOption: {
    color: '#66B7E9',
    padding: 0,
    marginLeft: -5,
    '&:hover': {
      color: Colors.white,
    },
  },
  rootFilterItem: {
    border: '1px solid rgba(255, 255, 255, 0.5)',
    borderRadius: 6,
  },
});

const AllSideFilterItem = (props) => {
  const classes = useStyles();
  const { t, checked, type, value, handleChange, setMatch, list, definedString } = props;

  const type_string = type === 'sh' || type === 'limited' ? `${type}_collection` : type;
  const isLegacy = type === 'legacy';

  const options = list && list.map((option) => {
    const firstLetter = (option.name ? option.name : option.display_name).toUpperCase();
    return {
      firstLetter: !isNaN(firstLetter.charAt(0)) ? '0-9' : firstLetter.charAt(0),
      ...option,
    };
  });

  return (
    <div className={classes.filterItem}>
      <CardShopFilterHead
        type_string={definedString || type_string}
        checked={checked}
        handleChange={handleChange}
        type={type}
        isLegacy={isLegacy}
        t={t}
      />

      {(checked && !isLegacy) &&
      <div style={{ marginTop: 20 }}>
        <Autocomplete
          id={`${type}-autocomplete`}
          options={options && options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
          groupBy={(option) => option.firstLetter}
          getOptionLabel={(option) => option.name ? option.name : option.display_name}
          onChange={(event, value) => setMatch(value ? value._id : null)}
          classes={{
            root: classes.rootFilterItem,
            clearIndicator: classes.popupIndicator,
            popupIndicator: classes.popupIndicator,
            endAdornment: classes.endAdornment,
            popper: classes.listWrapper,
            listbox: classes.listArea,
            groupLabel: classes.groupLabel,
            option: classes.listOption,
          }}
          value={list.find(item => item._id === value)}
          popupIcon={<FontAwesomeIcon icon={faCaretDown} size="xs" />}
          renderInput={(params) =>
            <TextField
              {...params}
              placeholder={t('search_by', {
                match: t(type_string)
                .toLowerCase(),
              })}
              value={value}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                classes: {
                  input: classes.placeholderColor,
                },
              }}
            />
          }
        />
      </div>
      }
    </div>
  );
};

export default AllSideFilterItem;