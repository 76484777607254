import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { replaceWidthAndHeight } from '../../utils/helper';
import PlainBoosterView from '../Booster/PlainBoosterView';
import BoosterViewContainer from '../../containers/Booster/BoosterView';
import Colors from '../../theme/Colors';

const useStyles = makeStyles((theme) => ({
  channelsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
    gap: 25.5,

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    },

    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    },

    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    },
  },
  channelCard: {
    backgroundColor: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    transition: 'all 0.3s ease-out',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  streamerProfilePic: {
    width: 40,
    height: 40,
    borderRadius: 9999,
    border: '2px solid transparent',
    outline: '2px solid #EB233B',
    marginRight: 10,
  },
  streamerName: {
    fontSize: 16,
    color: 'white',
    fontWeight: 500,
    margin: 0,
  },
  streamerSub: {
    fontSize: 12,
    color: 'rgba(255, 255, 255, 0.5)',
    margin: 0,
  },
  channelLiveImage: {
    width: '100%',
  },
  slider: {
    padding: '0 !important',
  },
  streamBadge: {
    borderRadius: 5,
    display: 'inline-flex',
    alignItems: 'center',
    padding: 6,
  },
  arrowArea: {
    padding: '18px 3px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 6,
    fontSize: 16,
    zIndex: 1,
    color: Colors.white,
    transition: 'all 300ms ease',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.12)',
      color: Colors.white,
    },
    '&:before': {
      content: 'none',
    },
  },
  boosterSliderItem: {
    color: 'white',
    cursor: 'pointer',
  },
}));

const HubLiveChannels = (props) => {
  const { liveChannels, t } = props;

  const classes = useStyles();

  const [boosterPopup, setBoosterPopup] = useState(null);

  const ArrowArea = (props) => {
    const { className, styling, onClick, icon } = props;
    return (
      <div
        className={[className, classes.arrowArea].join(' ')}
        style={styling}
        onClick={onClick}
      >
        {icon}
      </div>
    );
  };

  const languageLabel = (languageCode) => {
    const labels = {
      en: t('english'),
      de: t('german'),
    };

    return labels[languageCode] ?? languageCode;
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <ArrowArea styling={{ left: 4 }}
                          icon={<i className="fa-light fa-chevron-left" />} />,
    nextArrow: <ArrowArea styling={{ right: 4 }}
                          icon={<i className="fa-light fa-chevron-right" />} />,
    className: classes.slider,
  };

  return (
    <>
      <div className={classes.channelsGrid}>
        {liveChannels.slice(0, 4)
        .map(channel =>
          <div className={classes.channelCard} key={channel._id}>
            <Link
              to={'/profile/' + channel.streamer.login}
              style={{ display: 'flex', alignItems: 'center', padding: '16px 10.5px' }}
            >
              <img
                src={channel.streamer.profile_image_url}
                alt={channel.streamer.display_name}
                width={1}
                height={1}
                className={classes.streamerProfilePic}
              />

              <div>
                <p className={classes.streamerName}>{channel.streamer.display_name}</p>

                <p className={classes.streamerSub}>{t('streamheroes_streamer')}</p>
              </div>
            </Link>

            <div style={{ position: 'relative' }}>
              <a href={'https://twitch.tv/' + channel.streamer.login} target="_blank"
                 rel="noopener noreferrer">
                <img
                  src={replaceWidthAndHeight(channel.thumbnail_url, 341, 191)}
                  width={341}
                  height={191}
                  alt={channel.streamer.display_name}
                  className={classes.channelLiveImage}
                />
              </a>

              <div style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                gap: 6,
                padding: 10,
                pointerEvents: 'none',
                boxSizing: 'border-box',
              }}>
                <div className={classes.streamBadge} style={{ background: '#E12138' }}>
                  <div style={{
                    width: 10,
                    height: 10,
                    background: 'white',
                    borderRadius: 9999,
                    display: 'inline-block',
                    marginRight: 4,
                  }} />

                  <span style={{
                    fontSize: 12,
                    fontWeight: 600,
                    textTransform: 'uppercase',
                  }}>{t('live')}</span>
                </div>

                <div className={classes.streamBadge} style={{
                  background: 'rgba(255, 255, 255, 0.06)',
                  backdropFilter: 'blur(30px)',
                }}>
                  <span style={{
                    fontSize: 12,
                    fontWeight: 500,
                  }}>{channel.viewer_count} {t('viewer')}</span>
                </div>

                {channel.language &&
                <div className={classes.streamBadge} style={{
                  background: 'rgba(255, 255, 255, 0.06)',
                  backdropFilter: 'blur(30px)',
                }}>
                  <span style={{
                    fontSize: 12,
                    fontWeight: 500,
                  }}>{languageLabel(channel.language)}</span>
                </div>
                }
              </div>
            </div>

            <div style={{ padding: '16px 10px 10px 10px' }}>
              <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.04)', borderRadius: 6 }}>
                <Slider {...sliderSettings} style={{ width: '100%', padding: '0 57px' }}>
                  {channel.boosters && channel.boosters.map(booster =>
                    <div
                      key={booster._id}
                      className={classes.boosterSliderItem}
                      onClick={() => setBoosterPopup(booster)}
                    >
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        padding: '12px 40px',
                        transformStyle: 'preserve-3d',
                        perspective: 300,
                      }}>
                        <div style={{ width: 66, marginRight: 18, transform: 'rotateX(10deg)' }}>
                          <PlainBoosterView booster={booster}
                                            avatarUrl={channel.streamer.profile_image_url} />
                        </div>

                        <div style={{ flexGrow: 1 }}>
                          <p style={{ margin: '0 0 4px 0' }}>{t('basic_booster')}</p>

                          <p style={{
                            fontSize: 12,
                            margin: '0 0 21px 0',
                            color: 'rgba(255, 255, 255, 0.5)',
                          }}>{t('pack_has_n_cards', { number: booster.generic_cards.length })}</p>

                          <p style={{ fontSize: 12, margin: 0 }}>
                            <i className="fa-regular fa-clock" style={{ marginRight: 6 }} />

                            <span>{t('not_time_limited')}</span>
                          </p>
                        </div>
                      </div>
                    </div>,
                  )}
                </Slider>
              </div>
            </div>
          </div>,
        )}
      </div>

      {boosterPopup &&
      <BoosterViewContainer booster={boosterPopup} handleClose={() => setBoosterPopup(null)} />
      }
    </>
  );
};

export default HubLiveChannels;