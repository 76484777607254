import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import Helpers from '../../theme/Helpers';
import CustomButton from '../Button/CustomButton';

const OnBoardingEnd = (props) => {
  const { t } = useTranslation();

  const { classes, updateMyData } = props;

  const finish = () => {
    updateMyData({ variables: { update: { show_onboarding: false } } });

    props.history.push('/hq/hub');
  };

  return (
    <>
      <Typography variant="h1" className={classes.header} style={{ marginBottom: 45 }}>
        {t('on_boarding_end_head')}
      </Typography>

      <Typography variant="body1" className={classes.text}>
        {t('on_boarding_end_text')}
      </Typography>

      <div style={{ ...Helpers.fillColCenter, ...{ marginTop: 45 } }}>
        <CustomButton status="primary" onClick={finish}>
          {t('on_boarding_finish')}
        </CustomButton>
      </div>
    </>
  );
};

export default withRouter(OnBoardingEnd);