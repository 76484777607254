import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { useTranslation } from 'react-i18next';

import { getGlobalViewerLeaderboard } from '../../apollo/mergedQueries';

import Colors from '../../theme/Colors';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import ProfileCommunityRankedBodyLeaders
  from '../../components/Profile/ProfileCommunityRankedBodyLeaders';
import ProfileCommunityRankedBodyTable
  from '../../components/Profile/ProfileCommunityRankedBodyTable';
import Loading from '../../components/Loading/Loading';
import RankingTop from '../../components/Ranking/RankingTop';
import Helpers from '../../theme/Helpers';
import { isUserPremium, rangeArray } from '../../utils/helper';
import conf from '../../conf.json';
import AdSense from '../../components/AdSense/AdSense';
import Symplr from '../../components/AdSense/Symplr';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  formInput: {
    width: 'fit-content',
    zIndex: 100000,
    background: 'transparent',
    borderColor: '#FFFFFF1F',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FFFFFF1F',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FFFFFF1F',
    },
    '& .MuiFormLabel-root': {
      color: Colors.halfWhite,
    },
  },
  filled: {
    fill: Colors.white,
    zIndex: 100000,
  },
  selectRoot: {
    zIndex: 100000,
  },
}));

const ViewerSeasonRankingContainer = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const lastSeason = 1;
  const [season, setSeason] = useState(lastSeason);
  const [page, setPage] = useState(2);
  const perPage = 25;

  const { data, loading, fetchMore } = useQuery(getGlobalViewerLeaderboard, {
    variables: { pagination: { perPage, page: 1 }, season: season === lastSeason ? null : season },
    fetchPolicy: useCacheWithExpiration('getGlobalViewerLeaderboard'),
  });

  const seasonViewers = data && data.getSeasonViewerLeaderboard;
  const count = data && data.getSeasonViewerLeaderboardCount;
  const currentSeason = data && data.getCurrentSeason;
  const myViewer = data && data.getMyViewer;
  const { isPremiumActive } = isUserPremium(myViewer);

  return (
    <div style={{ color: Colors.white }}>
      <RankingTop currentSeason={currentSeason} season={season} loading={loading} />

      <div className="inner-wrapper">
        <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween }}>
          <div style={{ fontSize: 24, fontWeight: 600 }}>
            {t('leaderboard')}
          </div>

          {(conf.ad_area.ranking && myViewer && !isPremiumActive) &&
          <Symplr id="streamheroes.gg_lb_3" />
          }

          <FormControl variant="outlined" className={classes.formInput} required>
            <Select
              labelId="season-select-label"
              id="season-select"
              name="season"
              value={season}
              onChange={(event) => setSeason(parseInt(event.target.value))}
              classes={{
                icon: classes.filled,
                root: classes.selectRoot,
              }}
            >
              {rangeArray(0, 1)
              .map(season => (
                <MenuItem value={season} key={`SeasonKey${season}`}>
                  {t('season_x', { season })}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {loading ?
          <Loading />
          :
          <>
            {!isMobile &&
            <ProfileCommunityRankedBodyLeaders seasonData={seasonViewers} />
            }
            <ProfileCommunityRankedBodyTable
              seasonData={seasonViewers}
              totalCount={data && count}
              isMobile={isMobile}
              onLoadMore={async () => {
                try {
                  await fetchMore({
                    variables: {
                      pagination: { perPage, page },
                      season: season === lastSeason ? null : season,
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      setPage(page + 1);
                      if (!fetchMoreResult) return prev;
                      return Object.assign({}, prev, {
                        getSeasonViewerLeaderboard: [...prev.getSeasonViewerLeaderboard, ...fetchMoreResult.getSeasonViewerLeaderboard],
                      });
                    },
                  });
                } catch {
                }
              }}
            />
          </>
        }
      </div>
    </div>
  );
};

export default ViewerSeasonRankingContainer;