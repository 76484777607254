import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import LanguageWithTooltip from '../FlagIcon/LanguageWithTooltip';
import Helpers from '../../theme/Helpers';

const useStyles = makeStyles(() => ({
  wrapper: {
    marginBottom: 5,
    ...Helpers.fillRowCenter,
  },
}));

const TopStreamerItemLanguages = (props) => {
  const classes = useStyles();
  const { languages } = props;

  const checkLanguage = (language) => {
    if (language === 'en')
      return 'gb';

    return language;
  };

  const percentage = [];

  if (languages.length === 1)
    percentage.push(100);
  else {
    const totalMin = languages.reduce((count, lang) => lang.streamed_mins ? count + lang.streamed_mins : count + 0, 0);

    languages.map(lang => {
      percentage.push(((lang.streamed_mins / totalMin) * 100).toFixed(2));
    });
  }

  return (
    <div className={classes.wrapper}>
      {languages.map((lang, index) => (
        <div key={lang.identifier} style={{ ...Helpers.fillRowCenter }}>
          <LanguageWithTooltip
            code={checkLanguage(lang.identifier)}
            lang={lang.identifier}
          />
          {percentage.length > 1 &&
          <div style={{ margin: '0 5px', color: '#8c98a9', fontSize: 14 }}>
            {percentage[index]}%
          </div>
          }
        </div>
      ))}
    </div>
  );
};

export default TopStreamerItemLanguages;