import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CustomBackdropFilter from '../Layout/CustomBackdropFilter';

import ToggleRightSidebar from './ToggleRightSidebar';
import CustomTooltip from '../Tooltip/CustomTooltip';
import { disableSideBar } from '../../utils/helper';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    right: -64,
    top: 67,
    zIndex: 2,
    height: 'calc(100% - 67px)',
    backgroundColor: 'rgba(255, 255, 255, 0.04)',
    color: 'white',
    transition: 'all 0.3s ease-out',

    '&.root-expanded': {
      right: 0,
    },
  },
  sidebar: {
    height: '100%',
    overflowY: 'auto',
    transition: 'width 300ms ease',
    width: 64,
  },
  streamerSidebarAvatar: {
    borderRadius: 9999,
    outline: '3px solid #FFFFFF1A',
    border: '3px solid transparent',
    transition: 'all 300ms ease',

    '&:hover': {
      outline: '3px solid #E12138',
    },
  },
}));

const RightSidebar = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { extra, followedStreamers, liveChannels, location } = props;
  const { pathname } = location;

  const isExpanded = extra.expandedRightSidebar;

  if (disableSideBar(pathname) || pathname.includes('/inventory'))
    return null;

  return (
    <div className={[classes.root, isExpanded ? 'root-expanded' : ''].join(' ')}>
      <ToggleRightSidebar />

      <CustomBackdropFilter
        className={classes.sidebar}
        filter={'blur(50px)'}
        canvasFallback={true}
        html2canvasOpts={{
          allowTaint: true,
        }}
      >
        <div>
          <CustomTooltip
            title={t('streamheroes_you_follow')}
            placement="left"
          >
            <button style={{
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              width: '100%',
              border: 'none',
              color: 'white',
              fontSize: 14,
              padding: '8px 0',
              marginBottom: 14,
            }}>
              <i className="fa-regular fa-heart" />
            </button>
          </CustomTooltip>

          <div style={{ display: 'flex', gap: 14, flexDirection: 'column', alignItems: 'center' }}>
            {followedStreamers && followedStreamers.slice(0, 5)
            .map(info =>
              <CustomTooltip
                title={info.stream.streamer.display_name}
                placement="left"
                key={info.stream._id}
              >
                <Link to={`/profile/${info.stream.streamer.login}`}
                      style={{ position: 'relative' }}>
                  <img src={info.stream.streamer.profile_image_url}
                       alt={info.stream.streamer.display_name} width={40} height={40}
                       className={classes.streamerSidebarAvatar} />
                </Link>
              </CustomTooltip>,
            )}

            {followedStreamers && followedStreamers.length > 4 &&
            <div style={{
              width: 40,
              height: 40,
              backgroundColor: 'rgba(255, 255, 255, 0.02)',
              borderRadius: 6,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 12,
            }}>
              +{followedStreamers && followedStreamers.length - 4}
            </div>
            }
          </div>

          <CustomTooltip
            title={t('other_streamheroes_channels')}
            placement="left"
          >
            <button style={{
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              width: '100%',
              border: 'none',
              color: 'white',
              fontSize: 14,
              padding: '8px 0',
              marginBottom: 14,
              marginTop: 32,
            }}>
              <i className="fa-light fa-microphone" />
            </button>
          </CustomTooltip>

          <div style={{ display: 'flex', gap: 14, flexDirection: 'column', alignItems: 'center' }}>
            {liveChannels && liveChannels.slice(0, 5)
            .map(channel =>
              <CustomTooltip
                title={channel.streamer.display_name}
                placement="left"
                key={channel._id}
              >
                <Link to={`/profile/${channel.streamer.login}`} style={{ position: 'relative' }}>
                  <img src={channel.streamer.profile_image_url} alt={channel.streamer.display_name}
                       width={40} height={40} className={classes.streamerSidebarAvatar} />

                  <div style={{
                    width: 10,
                    height: 10,
                    backgroundColor: '#1FE325',
                    border: '3px solid #0B182B',
                    position: 'absolute',
                    borderRadius: 9999,
                    bottom: 0,
                    right: 0,
                  }} />
                </Link>
              </CustomTooltip>,
            )}

            {liveChannels && liveChannels.length > 4 &&
            <div style={{
              width: 40,
              height: 40,
              backgroundColor: 'rgba(255, 255, 255, 0.02)',
              borderRadius: 6,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 12,
            }}>
              +{liveChannels && liveChannels.length - 4}
            </div>
            }
          </div>
        </div>
      </CustomBackdropFilter>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { extra } = state;
  return { extra };
};

export default connect(mapStateToProps)(withRouter(RightSidebar));