import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Loading from '../../components/Loading/Loading';
import CardComponent from './CardComponent';
import PropTypes from 'prop-types';

const CardItem = (props) => {
  const { card, coinsInformation, hideProgresses, maxLevelCard, countOfLastCard, hideBadge } = props;

  const useStyles = makeStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 40,
      '& .Tilt > div': {
        opacity: card.count > 0 ? 1 : 0.66,
      },
      position: 'relative',
    },
    innerContainer: {
      padding: 10,
    },
    nextArrow: {
      position: 'absolute',
      right: -13,
      top: '36%',
      width: 25,
      height: 30,
      '& > img': {
        height: '100%',
        width: '100%',
      },
    },
  });
  const classes = useStyles();

  if (card) {
    return (
      <div className={classes.container}>
        {(card.matchCardModel === 'Card' && card.match_card.level < maxLevelCard) &&
        <div className={classes.nextArrow}>
          <img src={`${process.env.PUBLIC_URL}/icons-png/triangle.png`} alt="Next" />
        </div>
        }
        <div>
          <CardComponent
            card={card}
            coinsInformation={coinsInformation}
            showMenu={false}
            hideProgresses={hideProgresses}
            countOfLastCard={countOfLastCard}
            hideBadge={hideBadge}
          />
        </div>
      </div>
    );
  } else return (
    <Loading />
  );
};

CardItem.propTypes = {
  hideProgresses: PropTypes.bool,
};

CardItem.defaultProps = {
  hideProgresses: false,
};

export default CardItem;