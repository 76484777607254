import React from 'react';
import DuotoneIconBase from './DuotoneIconBase';

const HeadquarterIcon = (props) => {
  const { forceActive = false } = props;

  return (
    <DuotoneIconBase
      forceActive={forceActive}

      DefaultIcon={
        <svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="0 0 22 22">
          <g>
            <g>
              <g>
                <path fill="#FFFFFF" d="M7,22h-4C1.345,22,0,20.653,0,18.999V13c0-1.654,1.345-3,2.999-3h4c1.655,0,3,1.346,3,3v5.999
                                    C10,20.653,8.654,22,7,22z M2.999,12C2.449,12,2,12.448,2,13v5.999C2,19.551,2.449,20,2.999,20h4c0.551,0,1-0.449,1-1.001V13
                                    c0-0.552-0.449-1-1-1H2.999z" />
              </g>
            </g>
            <g>
              <g>
                <path fill="#FFFFFF" d="M19,12h-4c-1.654,0-3-1.346-3-3V3c0-1.654,1.346-3,3-3h4c1.654,0,3,1.346,3,3v6
                                    C22,10.654,20.654,12,19,12z M15,2c-0.552,0-1,0.449-1,1v6c0,0.551,0.448,1,1,1h4c0.551,0,1-0.449,1-1V3c0-0.551-0.449-1-1-1H15z
                                    " />
              </g>
            </g>
            <g opacity="0.5">
              <g>
                <path fill="#FFFFFF" d="M7,8h-4C1.345,8,0,6.653,0,5V3c0-1.654,1.345-3,2.999-3h4c1.655,0,3,1.346,3,3v2C10,6.653,8.654,8,7,8z
                                    M2.999,2C2.449,2,2,2.449,2,3v2C2,5.551,2.449,6,2.999,6h4C7.551,6,8,5.551,8,5V3c0-0.551-0.449-1-1-1H2.999z" />
              </g>
            </g>
            <g opacity="0.5">
              <g>
                <path fill="#FFFFFF" d="M19,22h-4c-1.654,0-3-1.347-3-3.001V17c0-1.653,1.346-3,3-3h4c1.654,0,3,1.347,3,3v1.999
                                    C22,20.653,20.654,22,19,22z M15,16c-0.552,0-1,0.449-1,1v1.999C14,19.551,14.448,20,15,20h4c0.551,0,1-0.449,1-1.001V17
                                    c0-0.551-0.449-1-1-1H15z" />
              </g>
            </g>
          </g>
        </svg>
      }
      HoverIcon={
        <svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="0 0 22 22">
          <g>
            <g>
              <g>
                <path fill="#FFFFFF" d="M7,21.999H3c-1.653,0-2.999-1.346-2.999-3v-6C0,11.346,1.346,10,3,10h4c1.655,0,3,1.346,3,2.999v6
                                    C10,20.653,8.655,21.999,7,21.999z M3,12.001C2.45,12.001,2,12.448,2,12.999v6c0,0.551,0.449,1,0.999,1h4c0.551,0,1-0.449,1-1v-6
                                    c0-0.551-0.449-0.998-1-0.998H3z" />
              </g>
            </g>
            <g>
              <g>
                <path fill="#FFFFFF" d="M18.998,12.001H15c-1.654,0-3-1.347-3-3.001V3c0-1.654,1.346-2.999,3-2.999h3.998
                                    C20.654,0.001,22,1.346,22,3v6C22,10.654,20.654,12.001,18.998,12.001z M15,2c-0.553,0-1,0.449-1,1v6c0,0.552,0.447,1,1,1h3.998
                                    C19.551,10,20,9.551,20,9V3c0-0.551-0.449-1-1.002-1H15z" />
              </g>
            </g>
            <g>
              <g>
                <path fill="#E12138" d="M7,8H3C1.346,8,0,6.654,0,5.001V3C0,1.346,1.346,0.001,3,0.001h4c1.655,0,3,1.345,3,2.999v2.001
                                    C10,6.654,8.655,8,7,8z M3,2C2.45,2,2,2.449,2,3v2.001C2,5.552,2.45,6,3,6h4c0.551,0,1-0.448,1-0.999V3c0-0.551-0.449-1-1-1H3z"
                />
              </g>
            </g>
            <g>
              <g>
                <path fill="#E12138" d="M18.998,21.999H15c-1.654,0-3-1.346-3-3V17c0-1.654,1.346-3.001,3-3.001h3.998
                                    C20.654,13.999,22,15.346,22,17v1.999C22,20.653,20.654,21.999,18.998,21.999z M15,15.999c-0.553,0-1,0.45-1,1.001v1.999
                                    c0,0.551,0.447,1,1,1h3.998c0.553,0,1.002-0.449,1.002-1V17c0-0.551-0.449-1.001-1.002-1.001H15z" />
              </g>
            </g>
          </g>
        </svg>
      }
      ActiveIcon={
        <svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="0 0 22 22">
          <g>
            <path fill="#FFFFFF" d="M7,10H3C1.346,10,0,11.345,0,13v6C0,20.654,1.346,22,3,22H7c1.654,0,3-1.346,3-3v-6
                            C10,11.345,8.655,10,7,10z" />
            <path fill="#FFFFFF" d="M18.998,0H15c-1.654,0-3,1.347-3,3v6c0,1.654,1.346,3,3,3h3.998C20.654,12,22,10.655,22,9V3
                            C22,1.347,20.654,0,18.998,0z" />
            <path fill="#E12138"
                  d="M7,0H3C1.346,0,0,1.347,0,3v2C0,6.654,1.346,8,3,8H7c1.654,0,3-1.347,3-3V3C10,1.347,8.655,0,7,0z" />
            <path fill="#E12138" d="M18.998,14H15c-1.654,0-3,1.346-3,3v2c0,1.654,1.346,3,3,3h3.998C20.654,22,22,20.654,22,19v-2
                            C22,15.346,20.654,14,18.998,14z" />
          </g>
        </svg>
      }
    />
  );
};

export default HeadquarterIcon;