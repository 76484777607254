import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { zeroPad } from 'react-countdown';

import Helpers from '../../theme/Helpers';

const useStyles = makeStyles(() => ({
  area: {
    ...Helpers.fillRowCenter,
    marginBottom: -6,
    '& > div': {
      textTransform: 'uppercase',
      fontSize: 16,
      fontFamily: 'Bebas Neue',
      margin: '0 3px',
    },
  },
}));

const MiniCountdown = (props) => {
  const classes = useStyles();
  const { endTime, showMore, now, endMoment } = props;
  const untilSeconds = (endMoment ? endMoment : moment(parseInt(endTime)))
  .diff(now ? now : moment(), 'seconds');

  const [counter, setCounter] = useState(untilSeconds > 0 ? untilSeconds : 0);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  let d = 0, h = 0, m = 0, s = 0;
  if (counter > 0) {
    d = Math.floor(counter / (3600 * 24));
    h = Math.floor(counter % (3600 * 24) / 3600);
    m = Math.floor(counter % 3600 / 60);
    s = Math.floor(counter % 60);
  }

  if (d >= 100 && !showMore)
    return null;

  return (
    <div className={classes.area}>
      {d > 0 &&
      <div>
        <span>{zeroPad(d)}</span> Days
      </div>
      }
      <div>
        <span>{zeroPad(h)}</span> Hrs
      </div>
      <div>
        <span>{zeroPad(m)}</span> Mins
      </div>
      {d === 0 &&
      <div>
        <span>{zeroPad(s)}</span> Secs
      </div>
      }
    </div>
  );
};

export default MiniCountdown;