import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Loading from '../Loading/Loading';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import { capitalize } from '../../utils/helper';

const useStyles = makeStyles(theme => ({
  itemRow: {
    ...Helpers.fillRowCross,
  },
  roleItem: {
    margin: 5,
    padding: '5px 10px',
    borderRadius: 50,
  },
  clickableIcon: {
    marginLeft: 5,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.9,
    },
  },
}));

const AdminRoleList = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { users, setRole, deleteRole, loading } = props;

  const colors = {
    admin: Colors.primary,
    marketing: Colors.secondary,
    illustrator: Colors.third,
  };

  const roleClasses = [classes.roleItem, classes.clickableIcon].join(' ');

  return (
    <div className={classes.container}>
      <div style={{ ...Helpers.fillRowCenter, marginBottom: 20 }}>
        <div className={roleClasses} style={{ backgroundColor: colors.admin, cursor: 'pointer' }}
             onClick={() => setRole('admin')}>
          Admin
        </div>
        <div className={roleClasses}
             style={{ backgroundColor: colors.marketing, cursor: 'pointer' }}
             onClick={() => setRole('marketing')}>
          Marketing
        </div>
        <div className={roleClasses}
             style={{ backgroundColor: colors.illustrator, cursor: 'pointer' }}
             onClick={() => setRole('illustrator')}>
          Illustrator
        </div>
      </div>

      {loading ?
        <Loading />
        :
        users && users.map(user => (
          <div className={classes.itemRow} key={user._id}>
            <div>
              {user.display_name}
            </div>
            <div style={Helpers.fillRowCenter}>
              {user.streamheroes_role.map(role => (
                <div
                  className={classes.roleItem}
                  style={{ backgroundColor: colors[role] }}
                  key={`UserKey${user._id}RoleKey${role}`}
                >
                  {capitalize(role)}
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    size="lg"
                    className={classes.clickableIcon}
                    onClick={() => deleteRole(user._id, role)}
                  />
                </div>
              ))}
            </div>
          </div>
        ))
      }
    </div>
  );
};

export default AdminRoleList;