import React from 'react';
import { makeStyles, Avatar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';

import { updateProfileData as updateProfileDataMutation } from '../../apollo/mutations';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  avatar: {
    background: Colors.black,
    border: 'none',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  pulse: {
    boxShadow: '0 0 0 rgba(255, 0, 0, 0.4)',
    animation: '$pulse 2s infinite',
  },
  '@keyframes pulse': {
    '0%': {
      boxShadow: '0 0 0 0 rgba(255, 0, 0, 0.4)',
    },
    '70%': {
      boxShadow: '0 0 0 75px rgba(255, 0, 0, 0)',
    },
    '100%': {
      boxShadow: '0 0 0 0 rgba(255, 0, 0, 0)',
    },
  },
  avatarArea: {
    cursor: 'pointer',
    '&:hover $editArea': {
      opacity: 1,
    },
  },
  editArea: {
    opacity: 0,
    background: 'rgba(0, 0, 0, 0.75)',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 0)',
    fontSize: 12,
    transition: 'all 0.3s ease-out',
    ...Helpers.fillColCenter,
    color: Colors.white,
    borderRadius: '50%',
  },
}));

const CustomAvatar = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    src,
    alt,
    isPulse,
    href,
    target,
    rootSize,
    seasonBorder,
    top,
    avatarSize,
    leagueSize,
    isEditable,
    onEdit,
    isPremiumActive,
  } = props;

  const [updateProfileData] = useMutation(updateProfileDataMutation, {
    onCompleted: () => {
      console.log('Avatar update - success');
    },
    onError: (data) => {
      console.error(data.message);
    },
  });

  const onClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    onEdit();
  };

  const avatarStyles = [classes.avatar];
  if (isPulse)
    avatarStyles.push(classes.pulse);

  const errorHandler = () => {
    console.log('Avatar - errorHandler');
    updateProfileData({
      variables: {
        profile_image_url: src,
      },
    });
  };

  const Tag = href && !isEditable ? Link : 'div';

  return (
    <Tag
      className={classes.avatarArea}
      to={{ pathname: href }}
      onClick={(e) => isEditable ? onClick(e) : null}
      style={{
        minWidth: rootSize,
        minHeight: rootSize,
        width: rootSize,
        height: rootSize,
        position: 'relative',
      }}
      target={target}
    >
      {src &&
      <Avatar
        className={avatarStyles.join(' ')}
        alt={alt}
        src={src}
        imgProps={{
          onError: errorHandler,
        }}
        style={{
          top: top.avatar,
          width: avatarSize,
          height: avatarSize,
        }}
      />
      }

      {isEditable &&
      <div
        className={classes.editArea}
        style={{
          top: top.avatar,
          width: avatarSize,
          height: avatarSize,
        }}
        onClick={onClick}
      >
        <i className="fa-light fa-pen" style={{ fontSize: 28, marginBottom: 5 }} />
        {t('edit_border')}
      </div>
      }
      {(seasonBorder && (seasonBorder.border_url || seasonBorder.img_url)) &&
      <>
        {isPremiumActive ?
          <video
            width={rootSize}
            height={rootSize}
            loop autoPlay muted
            style={{
              width: '100%',
              height: '100%',
              position: 'relative',
              zIndex: 1,
            }}
          >
            <source
              src={`${(seasonBorder.border_url || seasonBorder.img_url).replace('.png', '.webm')}?height=${rootSize}`}
              type="video/webm" />
            Video tag not supported.
          </video>
          :
          <div
            style={{
              width: '100%',
              height: '100%',
              background: `url("${seasonBorder.border_url || seasonBorder.img_url}?height=${rootSize}")`,
              position: 'relative',
              zIndex: 1,
            }}
          />
        }
        <Avatar
          className={classes.avatar}
          alt={alt}
          src={seasonBorder.profile_image_url}
          style={{
            top: top.league,
            width: leagueSize,
            height: leagueSize,
            zIndex: 2,
          }}
        />
      </>
      }
    </Tag>
  );
};

export default CustomAvatar;