import React, { useState } from 'react';
import { AdBlockDetectedWrapper } from 'adblock-detect-react';

import PagePopup from '../Dialog/PagePopup';
import Colors from '../../theme/Colors';
import { useTranslation } from 'react-i18next';
import Helpers from '../../theme/Helpers';
import { makeStyles } from '@material-ui/core/styles';
import AnimationButton from '../Button/AnimationButton';
import { Link, withRouter } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 600,
    marginBottom: 10,
  },
  header: {
    padding: 20,
    fontWeight: 600,
    fontSize: 24,
    background: 'rgba(255, 255, 255, 0.04)',
  },
  body: {
    padding: 20,
    position: 'relative',
    lineHeight: 1.8,
    textAlign: 'justify',
    '& > div:first-child': {
      ...Helpers.fillRow,
      ...Helpers.mainSpaceBetween,
    },
  },
  link: {
    color: 'orange',
    cursor: 'pointer',
    transition: 'all 0.3s ease-out',
    '&:hover': {
      color: 'red',
    },
  },
}));

const AdBlockDetection = (props) => {
  const classes = useStyles();
  const { marginLeft, username } = props;
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);

  return (
    <AdBlockDetectedWrapper>
      <PagePopup withBlurred style={{ left: marginLeft }}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: `calc(50% - ${marginLeft}px)`,
            background: '#142134',
            color: Colors.white,
            maxWidth: 800,
            borderRadius: 6,
            transform: `translate(-50%, -50%)`,
          }}
        >
          <div className={classes.header}>
            {t('thank_you_using_sh', { username })}
          </div>
          <div className={classes.body}>
            <div>
              <div
                style={{
                  borderRight: '0.5px solid rgba(255, 255, 255, 0.08)',
                  flex: 1,
                  paddingRight: 20,
                  ...Helpers.fillCol,
                  ...Helpers.mainSpaceBetween,
                }}
              >
                <div>
                  <div className={classes.title}>{t('deactivate_ad_block_head')}</div>
                  <div
                    style={{ fontSize: 14 }}
                    dangerouslySetInnerHTML={{ __html: t('ad_block_text') }}
                  />
                  <div
                    className={classes.link}
                    style={{ fontSize: 14 }}
                    onClick={() => setShowMore(!showMore)}
                  >
                    {t(showMore ? 'hide_solutions' : 'show_more_solutions')}
                  </div>
                </div>
                <AnimationButton
                  status="neonRed"
                  style={{ marginTop: 20, width: '100%' }}
                  onClick={() => window.location.reload()}
                >
                  {t('refresh_page')}
                </AnimationButton>
              </div>
              <div style={{
                borderLeft: '0.5px solid rgba(255, 255, 255, 0.08)',
                flex: 1,
                paddingLeft: 20,
                ...Helpers.fillCol,
                ...Helpers.mainSpaceBetween,
              }}>
                {showMore ?
                  <div
                    style={{ fontSize: 14 }}
                    dangerouslySetInnerHTML={{ __html: t('ad_block_solutions') }}
                  />
                  :
                  <>

                    <div>
                      <div className={classes.title}>{t('subscription_block_head')}</div>
                      <div
                        style={{ fontSize: 14 }}
                        dangerouslySetInnerHTML={{ __html: t('subscription_block_text') }}
                      />
                    </div>
                    <AnimationButton
                      status="gold"
                      style={{ width: '100%', fontSize: 14 }}
                      onClick={() => props.history.push('/premium')}
                    >
                      {t('i_want_to_support')}
                    </AnimationButton>
                  </>
                }
              </div>
            </div>
            <div style={{ marginTop: 20, ...Helpers.textCenter }}>
              {t('thanks_for_understanding')}
            </div>

            <Link
              to={{ pathname: 'https://help.streamheroes.gg/hc/de/requests/new' }}
              target="_blank"
              className={classes.link}
              style={{
                position: 'absolute',
                bottom: 10,
                right: 10,
                fontSize: 12,
              }}>
              {t('problems?')}
            </Link>
          </div>
        </div>
      </PagePopup>
    </AdBlockDetectedWrapper>
  );
};

export default withRouter(AdBlockDetection);