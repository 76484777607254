import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CardShopFilterHead from '../CardShop/CardShopFilterHead';
import { getLevelWord, rangeArray } from '../../utils/helper';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import conf from '../../conf.json';

const useStyles = makeStyles({
  filterItem: {
    width: 'calc(100% - 20px)',
    background: 'rgba(255, 255, 255, 0.04)',
    padding: '16px 10px',
    marginTop: 8,
    borderRadius: 6,
    '& div, & svg': {
      cursor: 'pointer',
    },
  },
  checkLine: {
    padding: 10,
    width: 'calc(100% - 20px)',
    ...Helpers.fillRowCross,
    borderRadius: 6,
    marginBottom: 6,
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.04)',
    },
    '&.active': {
      background: 'rgba(225, 33, 56, 0.1)',
    },
  },
  circle: {
    borderRadius: '50%',
    width: 24,
    height: 24,
    border: '1px solid rgba(255, 255, 255, 0.5)',
    marginRight: 18,
    transition: 'all 0.3s ease-out',
    ...Helpers.fillRowCenter,
    '&.active': {
      background: 'rgba(225, 33, 56, 0.35)',
      borderColor: 'transparent',
    },
  },
});

const RarenessFilter = (props) => {
  const classes = useStyles();
  const { t, checked, type, value, handleChange, setRareness } = props;

  const changeValue = (newValue) => {
    const tmpValue = [...value];

    if (tmpValue.includes(newValue))
      tmpValue.splice(tmpValue.indexOf(newValue), 1);
    else
      tmpValue.push(newValue);

    setRareness(tmpValue);
  };

  return (
    <div className={classes.filterItem}>
      <CardShopFilterHead
        type_string={type}
        checked={checked}
        handleChange={handleChange}
        type={type}
        t={t}
      />
      {checked &&
      <div style={{ marginTop: 17 }}>

        {[0, ...rangeArray(1, 5)]
        .reverse()
        .map(nr => {
          const levelWord = getLevelWord(nr > 0 ? 'Card' : 'SpecialCard', nr);
          const isActive = value.includes(nr);

          return (
            <div
              className={[classes.checkLine, isActive ? 'active' : ''].join(' ')}
              key={`RarenessItemKey${nr}`}
              onClick={() => changeValue(nr)}
              style={nr === 0 ? { marginBottom: 0 } : {}}
            >
              <div className={[classes.circle, isActive ? 'active' : ''].join(' ')}>
                {isActive &&
                <i className="fa-solid fa-check" style={{ color: '#E12138' }} />
                }
              </div>
              <img
                src={`${conf.cdn_url}/badges/card-level/${nr}.png?height=29`}
                alt="Level badge"
                style={{ marginRight: 6 }}
              />
              <div style={{
                color: Colors.level[levelWord],
                fontSize: 14,
              }}>
                {t(`rareness_level.${nr}`)}
              </div>
            </div>
          );
        })}

      </div>
      }
    </div>
  );
};

export default RarenessFilter;