import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getMyHeroMakerImage } from '../../apollo/queries';

import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import {
  setBodyColor,
  setHeroMakerAssets,
  setHeroMakerCurrentImage,
  setHeroMakerItems,
} from '../../redux/actions';
import HeroMakerController from '../../components/HeroMaker/HeroMakerController';

import maleChibi01Data from '../../data/01_male_chibi';
import maleRealistic01Data from '../../data/01_male_realistic';
import femaleChibi02Data from '../../data/02_female_chibi';
import conf from '../../conf';

const HeroMakerDialogContainer = (props) => {
  const { handleClose, selectedHero, viewer, refetchHeroes, posesCount } = props;
  const [tab, setTab] = useState(selectedHero ? null : 'choose_style');
  const [character, setCharacter] = useState(null);
  const [body, setBody] = useState(null);
  const [head, setHead] = useState(null);
  let serviceURL = conf.hero_maker_service_url_PROD;

  const { loading, data } = useQuery(getMyHeroMakerImage, {
    variables: { id: selectedHero },
    fetchPolicy: useCacheWithExpiration('getMyHeroMakerImage'),
    skip: !selectedHero,
  });

  useEffect(() => {
    if (selectedHero && !tab && data && data.getMyHeroMakerImage) {

      const heroMakerImage = data.getMyHeroMakerImage;
      const assets = heroMakerImage.assets;
      const bodyAsset = assets.find(asset => asset.category === '15_bodys');
      const headAsset = assets.find(asset => asset.category === '11_heads');
      setCharacter(heroMakerImage.character);
      setBody(bodyAsset.type);
      setHead(headAsset.type);

      const tmpAssets = assets.filter(asset => !['15_bodys', '11_heads'].includes(asset.category));

      props.setHeroMakerAssets(tmpAssets.map(asset => {
        return { ...asset, sorting: asset.ordering, asset: asset.category };
      }));
      props.setHeroMakerItems(tmpAssets.map(asset => {
        return { ...asset, sorting: asset.ordering, selectedIndex: asset.type };
      }));
      props.setBodyColor(bodyAsset.colors ? bodyAsset.colors[0] : null);
      props.setHeroMakerCurrentImage(heroMakerImage.image);
      setTab('choose_appearance');
    }
  }, [data, selectedHero]);

  const urlOrigin = window.location.origin;
  if (urlOrigin.includes('localhost'))
    serviceURL = conf.hero_maker_service_url_LOCAL;

  const getCategories = (character) => {
    if (!character)
      return null;

    const categories = {
      '01_male_chibi': maleChibi01Data,
      '02_female_chibi': femaleChibi02Data,
      '01_male_realistic': maleRealistic01Data,
    };

    return categories[character];
  };

  const tabs = [
    {
      key: 'choose_style',
      value: 'hero_maker_step_one',
    },
    {
      key: 'choose_body',
      value: 'hero_maker_step_two',
    },
    {
      key: 'choose_head',
      value: 'hero_maker_step_three',
    },
    {
      key: 'choose_appearance',
      value: 'hero_maker_step_four',
    },
    {
      key: 'check_hero',
      value: 'hero_maker_step_five',
    },
    {
      key: 'generation',
      value: 'hero_maker_step_six',
    },
  ];

  const nextTab = () => {
    const tabIdx = tabs.findIndex(tabItem => tabItem.key === tab);

    setTab(tabs[tabIdx + 1].key);
  };

  const backTab = () => {
    const tabIdx = tabs.findIndex(tabItem => tabItem.key === tab);

    setTab(tabs[tabIdx - 1].key);
  };

  const heroMakerCategories = getCategories(character);

  return (
    <HeroMakerController
      handleClose={handleClose}
      selectedHero={selectedHero}
      viewer={viewer}
      refetchHeroes={refetchHeroes}
      tab={tab}
      setTab={setTab}
      character={character}
      setCharacter={setCharacter}
      body={body}
      setBody={setBody}
      head={head}
      setHead={setHead}
      serviceURL={serviceURL}
      heroMakerCategories={heroMakerCategories}
      tabs={tabs}
      nextTab={nextTab}
      backTab={backTab}
      loading={loading}
      posesCount={posesCount}
    />
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setBodyColor,
    setHeroMakerAssets,
    setHeroMakerCurrentImage,
    setHeroMakerItems,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(HeroMakerDialogContainer);