import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';
import PagePopup from '../Dialog/PagePopup';
import AnimationButton from '../Button/AnimationButton';
import Colors from '../../theme/Colors';
import ClosePopupButton from '../Button/ClosePopupButton';
import SVGIcon from '../SVGIcon/SVGIcon';

import conf from '../../conf.json';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles({
  container: {
    background: '#142134',
    maxWidth: 562,
    margin: '50vh auto 0',
    transform: 'translate(0, -50%)',
    position: 'relative',
    color: Colors.white,
    borderRadius: 4,
    boxShadow: '0px 10px 36px #0000003D',
  },
});

const GemsOrderCompleteDialog = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { gems, pathname } = props;

  const onClose = () => {
    props.history.push(pathname);
  };

  return (
    <PagePopup>
      <div className={classes.container}>
        <ClosePopupButton
          handleClose={onClose}
          style={{ right: 32, top: 16, width: 28, height: 28 }}
        />

        <div style={{
          padding: '76px 32px 32px',
          background: 'rgba(255, 255, 255, 0.04)', ...Helpers.fillColCenter,
        }}>
          <SVGIcon
            src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
            height={142}
            width={108}
          />

          <div style={{ fontSize: 24, marginTop: 32 }}>
            {t('thanks_for_your_order')}
          </div>

          <div style={{ fontWeight: 600, marginTop: 6, fontSize: 36 }}>
            {t('x_gems_added', { count: gems })}
          </div>
        </div>

        <div style={{ ...Helpers.fillColCenter, padding: '32px 34.5px' }}>
          <AnimationButton
            status="neonRed"
            style={{
              border: 'none',
              fontFamily: 'Montserrat',
              fontSize: 14,
              fontWeight: 500,
              padding: 14,
              width: 265,
              marginTop: 32,
            }}
            onClick={onClose}
          >
            {t('continue')}
          </AnimationButton>
        </div>
      </div>
    </PagePopup>
  );
};

export default withRouter(GemsOrderCompleteDialog);
