import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme, makeStyles } from '@material-ui/styles';
import { Button, IconButton, MobileStepper } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { faArrowLeft, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Div100vh from 'react-div-100vh';
import { withRouter } from 'react-router-dom';

import image1 from './img/01_welcome-ID.gif';
import image2 from './img/02_placement-ID.gif';
import image3 from './img/03_swipe-ID.gif';
import Helpers from '../../theme/Helpers';
import OrientationLock from './OrientationLock';
import Colors from '../../theme/Colors';

const useStyles = makeStyles({
  root: {
    width: '100vw',
    ...Helpers.mainSpaceEvenly,
  },
});

/**
 * The tutorial steps are demonstrated
 *
 * @param props
 */
const TutorialVerification = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { history, cardVerification } = props;
  const { needsTouchSensitivity } = cardVerification;
  const [currentSlide, setCurrentSlide] = useState(0);
  const maxSlides = 3;

  const onChangeIndex = (index) => {
    setCurrentSlide(index);
  };

  const onClickStart = () => {
    if (needsTouchSensitivity)
      history.push('/verification/touchSensitivity');
    else
      history.push('/verification/swipe');

    setTimeout(() => {
      setCurrentSlide(0);
    }, 1000);
  };

  const slides = [
    {
      text: 'verification_tutorial_slide_1',
      image: image1,
    },
    {
      text: 'verification_tutorial_slide_2',
      image: image2,
    }, {
      text: 'verification_tutorial_slide_3',
      image: image3,
    },
  ];

  return (
    <OrientationLock>
      <Div100vh style={{ width: '100vw', position: 'relative', backgroundColor: Colors.white }}>
        <SwipeableViews
          enableMouseEvents
          onChangeIndex={onChangeIndex}
          index={currentSlide}
        >
          {slides.map((slide, index) => (
            <div key={`TutorialImageKey${index}`}>
              <img
                src={slide.image}
                alt={`Tutorial image ${index}`}
                style={{ width: '100vw', height: 'auto', overflow: 'hidden', objectFit: 'cover' }}
              />
            </div>
          ))}
        </SwipeableViews>

        <div style={{ position: 'absolute', top: 0, left: 0 }}>
          <IconButton
            aria-label="back to start"
            onClick={() => history.push('/verification/start')}
          >
            <FontAwesomeIcon icon={faArrowLeft} size="1x" />
          </IconButton>
        </div>

        <div style={{ position: 'absolute', bottom: 0, ...Helpers.fillColCenter }}>
          {currentSlide === (maxSlides - 1) &&
          <Button
            onClick={onClickStart}
            style={{ marginBottom: 10, padding: 10 }}
            variant="contained"
            color="primary"
            size="large"
          >
            {t('verification_tutorial_start')}
          </Button>
          }

          <div
            style={{
              backgroundColor: Colors.white,
              padding: 10,
              borderRadius: 4,
              ...Helpers.textCenter,
              margin: '0 40px 10px 20px',
            }}
            dangerouslySetInnerHTML={{ __html: t(slides[currentSlide].text) }}
          />

          <MobileStepper
            variant="dots"
            steps={maxSlides}
            position="static"
            activeStep={currentSlide}
            className={classes.root}
            nextButton={
              <Button
                onClick={() => onChangeIndex(currentSlide + 1)}
                disabled={currentSlide === (maxSlides - 1)}
              >
                {t('next')}
                <FontAwesomeIcon
                  icon={theme.direction === 'rtl' ? faChevronLeft : faChevronRight}
                  size="sm"
                  style={{ marginLeft: 5 }}
                />
              </Button>
            }
            backButton={
              <Button
                onClick={() => currentSlide === 0 ? history.push('/verification/start') : onChangeIndex(currentSlide - 1)}
                disabled={currentSlide === 0}
              >
                <FontAwesomeIcon
                  icon={theme.direction === 'rtl' ? faChevronRight : faChevronLeft}
                  size="sm"
                  style={{ marginRight: 5 }}
                />
                {t('back')}
              </Button>
            }
          />
        </div>
      </Div100vh>
    </OrientationLock>
  );
};

const mapStateToProps = (state) => {
  const { cardVerification } = state;
  return { cardVerification };
};

export default connect(mapStateToProps)(withRouter(TutorialVerification));