import React from 'react';
import { Query } from 'react-apollo';
import { useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import {
  handleMobileAppRedirect as handleMobileAppRedirectQuery
} from '../../apollo/queries';
const conf = require('../../conf');

const MobileLoginContainer = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get('token');

  useQuery(handleMobileAppRedirectQuery, {
    variables: {
      code: parseInt(token)
    },
    onCompleted: (data) => {
      if(data.handleMobileAppRedirect) {
        localStorage.setItem(conf.storage_keys.user_token, data.handleMobileAppRedirect);
        window.location.href = '/hq/hub';
      }
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
  })

  return (
    <>
    </>
  );
};

export default MobileLoginContainer;