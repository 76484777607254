import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Query } from 'react-apollo';
import Typography from '@material-ui/core/Typography';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import { getSearchResults } from '../../apollo/queries';
import { getSpecificUserToken as getSpecificUserTokenMutation } from '../../apollo/mutations';

import { replaceWidthAndHeight } from '../../utils/helper';
import ChangeUserSearch from './ChangeUserSearch';
import CustomButton from '../../components/Button/CustomButton';

const conf = require('../../conf');

const ChangeUserContainer = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState('');
  const [changeUserDisabled, setChangeUserDisabled] = useState(true);
  const [chosenUser, setChosenUser] = useState('');

  const variables = { filter: { q: search }, pagination: { page: 1, perPage: 15 } };

  const makeAlert = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  const [getSpecificUserToken] = useMutation(getSpecificUserTokenMutation, {
    onCompleted: (data) => {
      const msg = data.getSpecificUserToken ? t('token_change_success') : t('token_change_fail');

      if (data.getSpecificUserToken) {
        makeAlert(msg, 'success');
        const tmpToken = window.localStorage.getItem(conf.storage_keys.user_token);
        const newToken = data.getSpecificUserToken;
        if (tmpToken) {
          localStorage.setItem(conf.storage_keys.user_token, newToken);
          localStorage.setItem(`${conf.storage_keys.user_token}:TMP`, tmpToken);
          window.location.href = '/hq/hub';
          sessionStorage.removeItem('ttlQueries');
        } else {
          makeAlert(t('token_change_error'), 'error');
        }

      } else {
        makeAlert(msg, 'error');
      }
    },
    onError: (data) => {
      makeAlert(data.message, 'error');
    },
  });

  const submitForm = () => {
    if (!chosenUser || !chosenUser._id) {
      makeAlert(t('chosen_user_error'), 'error');
    }

    getSpecificUserToken({
      variables: {
        userId: chosenUser._id,
      },
    });
  };

  return (
    <Query query={getSearchResults} variables={variables}>
      {({ data, error, loading }) => {
        let results;

        if (data && data.getSearchResults && search && search.length > 0) {
          const { getSearchResults } = data;
          const { streamers, viewers, games } = getSearchResults;

          streamers.forEach(function (element) {
            element.type = t('search_streamer');
            element.link = '/profile/' + element.login;
          });
          viewers.forEach(function (element) {
            element.type = t('search_viewer');
            element.link = '/profile/' + element.login;
          });
          games.forEach(function (element) {
            element.type = t('search_game');
            element.link = '/hq/game-streams/' + encodeURIComponent(element.name);
            element.login = element.name;
            element.profile_image_url = replaceWidthAndHeight(element.box_art_url, 40, 40);
          });

          results = [...viewers];
        }

        return (
          <div>
            <Typography variant="h3" style={{ marginBottom: 30 }}>
              {t('impersonate_user_heading')}
            </Typography>
            <ChangeUserSearch
              loading={loading}
              results={(results) && results}
              search={search}
              setChangeUserDisabled={setChangeUserDisabled}
              setChosenUser={setChosenUser}
              setSearch={setSearch} />
            <Typography
              variant="h6"
              style={{ margin: '20px 0px', width: '400px', color: '#CE1537' }}
            >
              {t('impersonate_user_description')}
            </Typography>
            <CustomButton
              style={{ margin: '20px 0px 20px' }}
              status="secondary"
              onClick={submitForm}
              disabled={changeUserDisabled}
            >
              {t('impersonate_user')}
            </CustomButton>
          </div>
        );
      }}
    </Query>
  );
};

export default ChangeUserContainer;