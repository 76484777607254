import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { deleteAlertCapsules as deleteAlertCapsulesMutation } from '../../apollo/mutations';

import Helpers from '../../theme/Helpers';
import { getLevelWord } from '../../utils/helper';
import CircleButton from '../Button/CircleButton';
import AlertCapsuleDialog from '../Dialog/AlertCapsulePopup';
import AlertCapsuleRedeemDialog from '../Dialog/AlertCapsuleRedeemPopup';
import DiscordAuthDialog from '../Dialog/DiscordAuthPopup';
import AlertCapsuleImage from './AlertCapsuleImage';
import AlertCapsuleTooltip from './AlertCapsuleTooltip';
import Colors from '../../theme/Colors';
import AlertCapsuleRedeemButton from './AlertCapsuleRedeemButton';
import AnimationButton from '../Button/AnimationButton';

const useStyles = makeStyles({
  container: {
    cursor: 'pointer',
    '&:hover': {
      '& $rowBelow': {
        opacity: 1,
        marginTop: 5,
        marginBottom: 10,
      },
      '& $itemArea': {
        marginTop: -25,
      },
      '& $cardsArea': {
        display: 'initial',
      },
    },
  },
  itemArea: {
    ...Helpers.fillColCenter,
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: '6px',
    transition: 'all 0.3s ease-out',
  },
  capsuleDesign: {
    ...Helpers.fillColCenter,
    position: 'relative',
    maxWidth: '274px',
    margin: '19px 0',
  },
  cardsArea: {
    display: 'none',
    position: 'absolute',
    left: 'calc(100% - 20px)',
    top: '-47px',
    borderRadius: 6,
    width: 396,
    background: 'rgba(255, 255, 255, 0.08)',
    zIndex: 100,
    overflow: 'visible',
  },
  header: {
    ...Helpers.fillRowCross,
    alignSelf: 'baseline',
    margin: '16px',
    position: 'relative',
  },
  midAvatar: {
    width: 48,
    height: 48,
    border: 'none',
    marginRight: 8,
  },
  streamerName: {
    letterSpacing: '0px',
    fontWeight: 500,
  },
  collected: {
    fontSize: 12,
    color: Colors.halfWhite,
    marginTop: 2,
  },
  buttons: {
    height: 58,
    display: 'grid',
    gap: 6,
    gridTemplateColumns: 'repeat(4, 1fr)',
    margin: '12px',
    '& i': {
      fontSize: '16px',
    },
  },
  rowBelow: {
    ...Helpers.fillRowCross,
    gap: 4,
    marginTop: -41,
    marginBottom: 30,
    opacity: 0,
    transition: 'all 0.3s ease-out',
  },
  activeState: {
    width: 16,
    height: 16,
    background: '#1BBC2A',
    border: '5px solid #0B1627',
    borderRadius: 50,
    position: 'absolute',
    left: 26,
    bottom: -5,
  },
});

const AlertCapsule = (
  {
    _id,
    card,
    is_live,
    order,
    streamer,
    community,
    redeem_locked_until,
    created_at,
    t,
    type,
    alert_setting,
    viewerAuth,
    switchLastHover,
    alertsRefetchQuery,
    now,
    showRedeem = false,
  },
) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();

  const [showPopup, setShowPopup] = useState(false);
  const [showRedeemPopup, setShowRedeemPopup] = useState(false);
  const [showDiscordAuthDialog, setShowDiscordAuthDialog] = useState(false);

  const [deleteAlertCapsules, { loading: deleteLoading }] = useMutation(deleteAlertCapsulesMutation, {
    onCompleted: () => {
      enqueueSnackbar(t('success'), { variant: 'success' });
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: alertsRefetchQuery,
  });

  const deleteCapsule = () => {
    deleteAlertCapsules({ variables: { ids: [_id] } });
  };

  const allowTwitch = alert_setting && alert_setting.show_on_twitch;
  const allowDiscord = alert_setting && alert_setting.show_on_discord;
  const allowSpeech = alert_setting && alert_setting.twitch_settings && alert_setting.twitch_settings.permissions;
  const isAllowed = allowTwitch || allowDiscord;

  const collected = `Collected ${moment
  .unix(created_at / 1000)
  .format('DD.MM.YYYY')}`;

  const levelWord = getLevelWord(null, card && card.rarity);

  function redeemCapsule() {
    if (viewerAuth)
      setShowRedeemPopup(true);
    else
      setShowDiscordAuthDialog(true);
  }

  function continueWithoutDiscord() {
    setShowDiscordAuthDialog(false);
    setShowRedeemPopup(true);
  }

  if (showRedeem && !showRedeemPopup && !showDiscordAuthDialog && !showPopup) {
    redeemCapsule();
  }

  return (
    <>
      <div className={classes.container}>
        <div className={classes.itemArea} onClick={() => setShowPopup(true)}>
          <div className={classes.header}>
            <Avatar
              className={classes.midAvatar}
              alt="Avatar"
              src={streamer.profile_image_url}
            />
            {is_live &&
            <div className={classes.activeState} />
            }
            <div>
              <div className={classes.streamerName}>
                {streamer.display_name}
              </div>
              <div className={classes.collected}>{collected}</div>
            </div>
          </div>
          <div className={classes.capsuleDesign}>
            <AlertCapsuleImage level={levelWord} type={type} t={t} height={385}
                               isAllowed={isAllowed} />
            <AlertCapsuleTooltip
              className={classes.cardsArea}
              t={t}
              community={community}
              order={order}
              card={card}
              allowTwitch={allowTwitch}
              allowDiscord={allowDiscord}
              allowSpeech={allowSpeech}
              streamer={streamer}
              levelWord={levelWord}
              switchLastHover={switchLastHover}
            />
          </div>
          <div className={classes.buttons}>
            {allowTwitch &&
            <CircleButton innerCircle>
              <i className="fa-brands fa-twitch" />
            </CircleButton>
            }
            {allowDiscord &&
            <CircleButton innerCircle>
              <i className="fa-brands fa-discord" />
            </CircleButton>
            }
            {allowSpeech &&
            <>
              <CircleButton innerCircle>
                <i className="fa-light fa-waveform" />
              </CircleButton>
              <CircleButton>max.<br />255</CircleButton>
            </>
            }
          </div>
        </div>
        <div className={classes.rowBelow}>
          <AlertCapsuleRedeemButton
            t={t}
            handleClick={redeemCapsule}
            isAllowed={isAllowed}
            redeemLockedUntil={redeem_locked_until}
            now={now}
          />
          <AnimationButton
            status="whiteTransparency"
            onClick={deleteCapsule}
            style={{ padding: '14px 21px' }}
            disabled={deleteLoading}
          >
            <i
              className={`fa-light fa-${deleteLoading ? 'loader fa-spin' : 'trash-can'}`}
              style={{ fontSize: 18 }}
            />
          </AnimationButton>
        </div>
      </div>
      {showPopup && (
        <AlertCapsuleDialog
          capsuleId={_id}
          streamer={streamer}
          t={t}
          created_at={created_at}
          onClose={() => {
            history.replace({});

            setShowPopup(false);
          }}
          level={levelWord}
          capsuleType={type}
          card={card}
          order={order}
          community={community}
          allowTwitch={allowTwitch}
          allowDiscord={allowDiscord}
          allowSpeech={allowSpeech}
          onRedeem={redeemCapsule}
          redeemLockedUntil={redeem_locked_until}
          now={now}
        />
      )}
      {showRedeemPopup && (
        <AlertCapsuleRedeemDialog
          onClose={() => {
            history.replace({});

            setShowRedeemPopup(false);
          }}
          capsuleId={_id}
          t={t}
          allowTwitch={allowTwitch}
          allowDiscord={allowDiscord}
          allowSpeech={allowSpeech}
          alertsRefetchQuery={alertsRefetchQuery}
        />
      )}
      {showDiscordAuthDialog && (
        <DiscordAuthDialog
          t={t}
          onSuppressDiscord={continueWithoutDiscord}
          onClose={() => {
            history.replace({});

            setShowDiscordAuthDialog(false);
          }}
        />
      )}
    </>
  );
};

export default AlertCapsule;
