import React from 'react';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import { Helmet } from 'react-helmet-async';
import Div100vh from 'react-div-100vh';

import Helpers from '../../theme/Helpers';
import conf from '../../conf.json';

/**
 * To check if the device is in portrait mode. Runs dynamically, so no page refresh is required.
 *
 * @param props
 */
const OrientationLock = (props) => {
  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>
      <DeviceOrientation lockOrientation={'portrait'}>
        {/* Will only be in DOM in portrait */}
        <Orientation orientation="portrait" alwaysRender={false}>
          {props.children}
        </Orientation>

        {/* Will only be in DOM in landscape */}
        <Orientation orientation="landscape" alwaysRender={false}>
          <Div100vh style={{ ...Helpers.fillRowCenter }}>
            <img
              src={`${conf.cdn_url}/card-verification/rotate-device.png`}
              alt="Please rotate your device."
              style={{ maxWidth: '100%', maxHeight: '50vh' }}
            />
          </Div100vh>
        </Orientation>
      </DeviceOrientation>
    </>
  );
};

export default OrientationLock;