import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import ItemStoreBoosterPack from '../ItemStore/ItemStoreBoosterPack';
import BoosterViewContainer from '../../containers/Booster/BoosterView';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    marginBottom: -34,
    marginTop: -26,
    '& .slick-slider': {
      padding: '0 30px !important',
    }
  },
  arrowArea: {
    width: 37,
    transition: 'all 0.3s ease-out',
    height: 479,
    ...Helpers.fillRowCenter,
    borderRadius: 4,
    fontSize: 16,
    color: Colors.white,
    background: 'rgba(255, 255, 255, 0.04)',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.12)',
      color: Colors.white,
    },
    '&:before': {
      content: 'none',
    },
  },
}));

const HubNewBooster = (props) => {
  const classes = useStyles();
  const { t, latestBooster, myViewer } = props;
  const [boosterPopup, setBoosterPopup] = useState(null);

  const ArrowArea = (props) => {
    const { className, styling, onClick, icon } = props;
    return (
      <div
        className={[className, classes.arrowArea].join(' ')}
        style={styling}
        onClick={onClick}
      >
        {icon}
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 5,
    centerPadding: 30,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    arrows: true,
    prevArrow: <ArrowArea styling={{ left: 0 }}
                          icon={<i className="fa-light fa-chevron-left" />} />,
    nextArrow: <ArrowArea styling={{ right: 0 }}
                          icon={<i className="fa-light fa-chevron-right" />} />,
  };

  return (
    <>
      <div className={classes.container}>
        <Slider {...settings} style={{ width: '100%', padding: '0 49px' }}>
          {latestBooster && latestBooster
          .map(booster => (
            <div key={booster._id}>
              <ItemStoreBoosterPack
                booster={{ ...booster, match: booster.match_meta_data }}
                t={t}
                myViewer={myViewer}
                setBoosterPopup={setBoosterPopup}
              />
            </div>
          ))}
        </Slider>
      </div>
      {boosterPopup &&
      <BoosterViewContainer booster={boosterPopup} handleClose={() => setBoosterPopup(null)} />
      }
    </>
  );
};

export default withRouter(HubNewBooster);