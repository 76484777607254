import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ScaledImage from './ScaledImage';

const PositionedScaledImage = (
  {
    src,
    id,
    alt,
    opacity = 1,
    verticalAlign,
    horizontalAlign,
    verticalOffset = 0,
    horizontalOffset = 0,
    swipeVerification,
    style = {},
  },
) => {
  const { scaleFactor } = swipeVerification;
  const [horizontalPositioning, setHorizontalPositioning] = useState({});
  const [verticalPositioning, setVerticalPositioning] = useState({});

  useEffect(() => {
    // horizontal
    switch (horizontalAlign) {
      case 'left':
        setHorizontalPositioning({
          left: horizontalOffset * scaleFactor,
        });
        break;
      case 'center':
        setHorizontalPositioning({
          left: `calc(50% + (${horizontalOffset * scaleFactor}px))`,
          transform: 'translateX(-50%)',
        });
        break;
      case 'right':
        setHorizontalPositioning({
          right: 0 - horizontalOffset * scaleFactor,
        });
        break;
      default:
        console.log('no horizontalAlign defined');
        break;
    }

    switch (verticalAlign) {
      case 'top':
        setVerticalPositioning({
          top: verticalOffset * scaleFactor,
        });
        break;
      case 'center':
        setVerticalPositioning({
          top: `calc(50% + (${verticalOffset * scaleFactor}px))`,
          transform: 'translateY(-50%)',
        });
        break;
      case 'bottom':
        setVerticalPositioning({
          bottom: 0 - verticalOffset * scaleFactor,
        });
        break;
      default:
        console.log('no horizontalAlign defined');
        break;
    }
  }, [horizontalAlign, verticalAlign, horizontalOffset, verticalOffset, scaleFactor]);

  return (
    <ScaledImage
      src={src}
      id={id}
      alt={alt}
      style={{
        ...horizontalPositioning,
        ...verticalPositioning,
        position: 'absolute',
        opacity,
        ...style,
      }}
    />
  );
};

const mapStateToProps = (state) => {
  const { swipeVerification } = state;
  return { swipeVerification };
};

export default connect(mapStateToProps)(PositionedScaledImage);
