import React from 'react';
import moment from 'moment';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import distinctColors from 'distinct-colors';

import { groupBy } from '../../utils/helper';
import Colors from '../../theme/Colors';

const CompletedJobsDiagram = (props) => {
  const { t } = useTranslation();
  const { statsData } = props;

  if (statsData)
    statsData.sort((a, b) => a.date >= b.date ? 1 : -1);

  const mainDiagramColor = '#616161';
  const series = [{ name: t('completed_jobs'), data: [] }];
  const categories = [];

  statsData.map(month => {
    categories.push(month.date);
    series[0].data.push(month.count);
  });

  const options = {
    chart: {
      type: 'bar',
      height: 300,
      stacked: true,
      toolbar: {
        show: false,
      },
      background: Colors.containerBGColor,
      zoom: {
        enabled: true,
      },
    },
    responsive: [{
      breakpoint: 680,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0,
        },
      },
    }],
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: mainDiagramColor,
        },
      },
    },
    xaxis: {
      categories: categories,
      labels: {
        rotate: -45,
        trim: true,
        style: {
          colors: mainDiagramColor,
          cssClass: {
            color: Colors.primary,
          },
        },
      },
      axisBorder: {
        color: mainDiagramColor,
      },
      axisTicks: {
        color: mainDiagramColor,
      },
    },
    grid: {
      borderColor: '#2b2c2e',
    },
    legend: {
      position: 'right',
    },
    theme: {
      mode: 'dark',
    },
    fill: {
      opacity: 1,
    },
  };

  return (
    <div style={{ marginTop: 40 }}>
      {statsData && statsData.length > 0 ?
        <Chart options={options} series={series} type="bar" height={300} />
        :
        <div style={{ color: Colors.white }}>{t('no_data_available')}</div>
      }
    </div>
  );
};

export default CompletedJobsDiagram;