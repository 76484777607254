import React from 'react';
import { useTranslation } from 'react-i18next';

import OnBoardingStepFooter from './OnBoardingStepFooter';
import OnBoardingStepHeader from './OnBoardingStepHeader';
import StreamerPanel from '../StreamerPanel/StreamerPanel';

const OnBoardingPanel = (props) => {
  const { t } = useTranslation();
  const { streamer, classes, nextStep } = props;

  return (
    <>
      <OnBoardingStepHeader
        header={t('on_boarding_panel_head')}
        body={t('on_boarding_panel_text')}
        classes={classes}
      />

      <StreamerPanel streamer={streamer} />

      <OnBoardingStepFooter nextStep={nextStep} save={nextStep} classes={classes} />
    </>
  );
};

export default OnBoardingPanel;