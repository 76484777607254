import {
  INCREASE_COUNT,
  TOGGLE_COLLECTING_CHECKER,
  TOGGLE_EXPANDED_MENU,
  TOGGLE_HIDE_PAGE_SCROLLBAR,
  TOGGLE_NOTIFICATIONS,
  TOGGLE_PLAYING_BOOSTER_ANIMATION,
  TOGGLE_SEARCH,
  TOGGLE_SHOP_CART,
  TOGGLE_RIGHT_SIDEBAR, SET_INVENTORY_GROUP,
} from './constants';

const INITIAL_STATE = {
  count: 0,
  hideScroll: false,
  playedAnimation: false,
  expandedMenu: null,
  expandedRightSidebar: null,
  showShopCart: false,
  showSearch: false,
  showNotifications: false,
  showCollectingChecker: false,
  inventoryGroup: null,
};

const extraReducer = (state = INITIAL_STATE, action) => {
  if (action.type === INCREASE_COUNT) {
    return { ...state, count: state.count + 1 };
  } else if (action.type === TOGGLE_HIDE_PAGE_SCROLLBAR) {
    return { ...state, hideScroll: !state.hideScroll };
  } else if (action.type === TOGGLE_EXPANDED_MENU) {
    return {
      ...state,
      expandedMenu: action.isExpanding !== null ? action.isExpanding : !state.expandedMenu,
    };
  } else if (action.type === TOGGLE_RIGHT_SIDEBAR) {
    return {
      ...state,
      expandedRightSidebar: action.isExpanding !== null ? action.isExpanding : !state.expandedRightSidebar,
    };
  } else if (action.type === TOGGLE_PLAYING_BOOSTER_ANIMATION) {
    return {
      ...state,
      playedAnimation: action.isPlaying !== null ? action.isPlaying : !state.playedAnimation,
    };
  } else if (action.type === TOGGLE_SHOP_CART) {
    return {
      ...state,
      showShopCart: action.toggle !== undefined ? action.toggle : !state.showShopCart,
    };
  } else if (action.type === TOGGLE_SEARCH) {
    return { ...state, showSearch: !state.showSearch };
  } else if (action.type === TOGGLE_NOTIFICATIONS) {
    return { ...state, showNotifications: !state.showNotifications };
  } else if (action.type === TOGGLE_COLLECTING_CHECKER) {
    return { ...state, showCollectingChecker: !state.showCollectingChecker };
  } else if (action.type === SET_INVENTORY_GROUP) {
    return { ...state, inventoryGroup: action.group };
  } else {
    return state;
  }
};

export default extraReducer;