import React from 'react';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';

import Colors from '../../theme/Colors';
import { isUserPremium } from '../../utils/helper';

const useStyles = makeStyles(() => ({
  slideInOutArea: {
    position: 'fixed',
    top: 0,
    left: '100%',
    right: 0,
    bottom: 0,
    background: '#011024',
    color: Colors.white,
    zIndex: 129999,
    transition: 'all 0.3s ease-out',
  },
  bgShadow: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: '100%',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 100,
    cursor: 'pointer',
  },
}));

const SlideInOut = (props) => {
  const classes = useStyles();
  const { children, isOpen, extra, additionalLeft, bgAddLeft, onClose, bgZIndex, myViewer } = props;

  const isMenuExpanded = extra.expandedMenu;

  const left = (isMenuExpanded ? 173 : 67) + 245;

  return (
    <>
      {isOpen &&
      <div
        className={classes.bgShadow}
        style={{ left: `${left + (bgAddLeft || 0)}px`, zIndex: bgZIndex || 100 }}
        onClick={() => onClose ? onClose() : null}
      />
      }
      <div
        className={classes.slideInOutArea}
        style={{
          left: isOpen ? (left + (additionalLeft || 0)) : '100%',
          opacity: isOpen ? 1 : 0,
          zIndex: bgZIndex + 1 || 129999,
        }}
      >
        {isOpen && children}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { extra } = state;
  return { extra };
};

export default connect(mapStateToProps)(SlideInOut);