import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import HoverIcon from '../Icon/HoverIcon';
import SVGIcon from '../SVGIcon/SVGIcon';
import AnimationButton from '../Button/AnimationButton';
import QualityLine from '../QualityLine/QualityLine';
import InventoryBoosterCardPlaceholder from './InventoryBoosterCardPlaceholder';
import MarketplaceBuySellButton from '../Button/MarketplaceBuySellButton';
import { setCollectorsViewShowCardInfo } from '../../redux/actions';
import CardImageDecision from '../Cards/CardImageDecision';

import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  topLine: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    background: 'rgba(255, 255, 255, 0.16)',
    height: 42,
    padding: '0 16px',
    width: 'calc(100% - 32px)',
  },
  descText: {
    padding: '14px 16px',
    color: Colors.halfWhite,
    fontSize: 14,
    lineHeight: 1.43,
    '& > span': {
      color: Colors.white,
    },
  },
  greyLine: {
    height: 1,
    width: '100%',
    background: 'rgba(255, 255, 255, 0.16)',
  },
  donationItem: {
    padding: '18.5px 16px',
    width: 'calc(100% - 32px)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
    ...Helpers.fillRowCross,
  },
  avatar: {
    height: 52,
    width: 52,
    border: 'none',
    marginRight: 12,
    background: 'rgba(255, 255, 255, 0.09)',
    color: Colors.white,
    '& svg': {
      width: 30,
      height: 26,
    },
  },
}));

const InventoryBoosterCardsInfo = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    streamer,
    booster,
    boosterDonations,
    showCardInfo,
    setIsOpenGemsUnlock,
  } = props;
  const [deactivateRotation, setDeactivateRotation] = useState(false);

  const onClose = () => {
    props.setCollectorsViewShowCardInfo(null);
  };

  const getDonationItem = (donation, index, isTop = false, isFirst = false) => (
    <div className={classes.donationItem}
         key={`DonationItemKey${donation.profile_image_url + index}`}>
      <Avatar
        className={classes.avatar}
        alt="Avatar"
        src={donation.anonymous ? '' : donation.profile_image_url}
      />
      <div>
        <div style={{ fontWeight: 500, marginBottom: 6.5 }}>
          {donation.anonymous ? t('anonymous') : donation.display_name}
        </div>
        <div style={{ ...Helpers.fillRowCross }}>
          <SVGIcon
            src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
            height={18}
            width={14}
            style={{ marginRight: 5 }}
          />
          <div style={{ fontSize: 12, fontWeight: 600 }}>
            {donation.gems}
          </div>
          {(isTop || isFirst || index === 0) &&
          <>
            <div style={{
              marginLeft: 8,
              background: Colors.halfWhite,
              width: 6,
              height: 6,
              borderRadius: '50%',
            }} />
            <div style={{ color: Colors.halfWhite, fontSize: 12, marginLeft: 8 }}>
              {isTop && t('top_donation')}
              {isFirst && t('first_donation')}
              {index === 0 && t('recent_donation')}
            </div>
          </>
          }
        </div>
      </div>
    </div>
  );

  const card = showCardInfo && {
    front_layers: [{ img_url: showCardInfo.card.card_layer_image }],
  };

  return (
    <>
      <div style={{
        marginLeft: showCardInfo ? 3 : 0,
        transition: 'all 0.3s ease-out',
        width: showCardInfo ? '50%' : 0,
        opacity: showCardInfo ? 1 : 0,
      }}>
        <div className={classes.topLine}>
          <div>{t('card_overview')}</div>
          <HoverIcon
            icon="fa-light fa-circle-xmark" hoverIcon="fa-solid fa-circle-xmark"
            color={Colors.white}
            style={{ fontSize: 26 }}
            onClick={onClose}
          />
        </div>

        {showCardInfo &&
        <div style={{ ...Helpers.fillRow }}>

          <div style={{ marginRight: 1.5, width: '100%' }}>
            <div
              style={{
                background: 'rgba(255, 255, 255, 0.04)',
                padding: 16,
                height: 'calc(100vh - 62px - 6px - 42px - 232px - 32px)',
                ...Helpers.fillCol,
                ...Helpers.mainSpaceBetween,
                width: 'calc(100% - 32px)',
              }}
            >
              {showCardInfo.progress.owned_count > 0 ?
                <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                  <CardImageDecision
                    card={{
                      ...showCardInfo.card,
                      frontEffect: showCardInfo.progress.quality_layer,
                      backEffect: showCardInfo.progress.quality_layer,
                      noEffectLayer: showCardInfo.progress.quality === -1,
                    }}
                    information={card}
                    isLegacy
                    style={{
                      height: 380,
                      width: 350,
                      top: '37%',
                    }}
                    zoom={3.5}
                    size={30}
                  />
                </div>
                :
                <div style={{ width: '100%', ...Helpers.fillColCenter, paddingTop: 100 }}>
                  <InventoryBoosterCardPlaceholder width={187} height={288} />
                </div>
              }

              <div>
                <QualityLine quality={showCardInfo.progress.quality} />
              </div>
            </div>

            <div style={{ background: 'rgba(255, 255, 255, 0.04)', marginTop: 3 }}>
              <div style={{ padding: '22px 16px' }}>
                <AnimationButton
                  status="neonPink"
                  style={{ width: '100%', fontSize: 14, fontWeight: 500 }}
                  onClick={() => setIsOpenGemsUnlock(showCardInfo)}
                  disabled={showCardInfo.progress.bought || !showCardInfo.card.purchaseable}
                >
                  <div style={Helpers.fillRowCenter}>
                    <div>{t('get_with')}</div>
                    <SVGIcon
                      src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
                      height={25}
                      width={19}
                      style={{ margin: '0 8px' }}
                    />
                    <div>{t('gems')}</div>
                  </div>
                </AnimationButton>
              </div>
              <div style={Helpers.fillRowCross}>
                <div className={classes.greyLine} />
                <div style={{ margin: '0 14px' }}>{t('or')}</div>
                <div className={classes.greyLine} />
              </div>
              <div style={{ padding: '12px 16px 16px' }}>
                <AnimationButton
                  status="whiteTransparency"
                  style={{ width: '100%', fontSize: 14, fontWeight: 500 }}
                  onClick={() => props.history.push(`/item-store/booster?boosterId=${booster._id}`)}
                >
                  {t('open_streamer_booster', { streamer: streamer && streamer.display_name })}
                </AnimationButton>

                <MarketplaceBuySellButton style={{ marginTop: 6 }} item={showCardInfo} />
              </div>
            </div>
          </div>

          <div style={{ background: 'rgba(255, 255, 255, 0.04)', marginLeft: 1.5, width: '100%' }}>
            <div style={{ background: 'rgba(255, 255, 255, 0.04)' }}>
              <div style={{
                borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
                padding: '12px 0',
                ...Helpers.fillRowCenter,
                fontSize: 14,
                fontWeight: 500,
              }}>
                <div>{t('top_booster')}</div>
                <SVGIcon
                  src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
                  height={18}
                  width={14}
                  style={{ margin: '0 8.5px' }}
                />
                <div>{t('gem_donations')}</div>
              </div>
              <div
                className={classes.descText}
                dangerouslySetInnerHTML={{ __html: t('decide_price_yourself_desc') }}
              />
            </div>

            <div style={{ ...Helpers.fillColCross }}>
              {(boosterDonations && boosterDonations.all_donations && boosterDonations.all_donations.length > 0) ?
                <>
                  <div style={{
                    background: 'rgba(255, 255, 255, 0.04)',
                    borderTop: '1px solid rgba(255, 255, 255, 0.08)',
                    padding: '8px 16px',
                    ...Helpers.fillRowCross,
                    width: 'calc(100% - 32px)',
                  }}>
                    <div style={{
                      width: 46,
                      height: 46,
                      borderRadius: '50%',
                      ...Helpers.fillColCenter,
                      background: 'rgba(225, 33, 56, 0.3)',
                      marginRight: 10,
                    }}>
                      <i className="fa-regular fa-arrow-trend-up"
                         style={{ color: '#E12138', fontSize: 18 }} />
                    </div>
                    <div style={{ color: '#E12138', fontSize: 14, fontWeight: 500 }}>
                      {t('x_persons_already_supported', { count: boosterDonations.all_donations.length })}
                    </div>
                  </div>
                  {getDonationItem(boosterDonations.top_donation, -1, true)}
                  {getDonationItem(boosterDonations.first_donation, -2, false, true)}
                  {boosterDonations.all_donations.map((donation, index) => getDonationItem(donation, index))}
                </>
                :
                <div style={{ marginTop: 64, ...Helpers.fillColCross }}>
                  <div style={{
                    height: 62,
                    width: 62,
                    borderRadius: '50%', ...Helpers.fillColCenter,
                    background: 'rgba(255, 255, 255, 0.09)',
                  }}>
                    <i className="fa-solid fa-heart" style={{ fontSize: 21, color: '#E12138' }} />
                  </div>
                  <div style={{ marginTop: 16 }}>
                    {t('be_the_first_in_list')}
                  </div>
                  <div
                    style={{
                      marginTop: 8,
                      color: Colors.halfWhite,
                      lineHeight: 1.57,
                      maxWidth: 276,
                      fontSize: 14,
                      ...Helpers.textCenter,
                    }}>
                    {t('when_you_use_gems_experience')}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        }
      </div>
    </>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setCollectorsViewShowCardInfo,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(withRouter(InventoryBoosterCardsInfo));