import React, { useState } from 'react';
import { Query } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { Typography } from '@material-ui/core';

import { getSearchResults } from '../../apollo/queries';
import { deleteStreamer as deleteStreamerMutation } from '../../apollo/mutations';
import { replaceWidthAndHeight } from '../../utils/helper';

import DeleteStreamerSearch from './DeleteStreamerSearch';
import CustomButton from '../../components/Button/CustomButton';
import AdminRoleListContainer from './AdminRoleList';

const AdministrationSettings = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState('');
  const [changeUserDisabled, setChangeUserDisabled] = useState(true);
  const [chosenUser, setChosenUser] = useState('');
  const variables = { filter: { q: search }, pagination: { page: 1, perPage: 5 } };

  const makeAlert = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  const [deleteStreamer] = useMutation(deleteStreamerMutation, {
    onCompleted: (data) => {
      const msg = data.deleteStreamer ? t('delete_streamer_success') : t('delete_streamer_error');
      data.deleteStreamer ? makeAlert(msg, 'success') : makeAlert(t('delete_streamer_error'), 'error');
    },
    onError: (data) => {
      makeAlert(data.message, 'error');
    },
  });

  const submitForm = () => {
    if (!chosenUser || !chosenUser._id) {
      makeAlert('No _id found for chosen streamer!', 'error');
    } else {
      deleteStreamer({
        variables: {
          id: chosenUser._id,
        },
      });
    }
  };

  return (
    <>
      <Query query={getSearchResults} variables={variables}>
        {({ data, error, loading }) => {
          let results;

          if (data && data.getSearchResults && search && search.length > 0) {
            const { getSearchResults } = data;
            const { streamers, viewers, games } = getSearchResults;

            streamers.forEach(function (element) {
              element.type = t('search_streamer');
              element.link = '/profile/' + element.login;
            });
            viewers.forEach(function (element) {
              element.type = t('search_viewer');
              element.link = '/profile/' + element.login;
            });
            games.forEach(function (element) {
              element.type = t('search_game');
              element.link = '/hq/game-streams/' + encodeURIComponent(element.name);
              element.login = element.name;
              element.profile_image_url = replaceWidthAndHeight(element.box_art_url, 40, 40);
            });

            results = [...streamers];
          }

          return (
            <div>
              <Typography variant="h3" style={{ marginBottom: 30 }}>
                {t('delete_streamer_heading')}
              </Typography>
              <DeleteStreamerSearch
                loading={loading}
                results={(results) && results}
                search={search}
                setChangeUserDisabled={setChangeUserDisabled}
                setChosenUser={setChosenUser}
                setSearch={setSearch} />
              <CustomButton
                style={{ margin: '20px 0px 20px' }}
                status="secondary"
                onClick={submitForm}
                disabled={changeUserDisabled}
              >
                {t('delete_streamer')}
              </CustomButton>
            </div>
          );
        }}
      </Query>
      <AdminRoleListContainer />
    </>
  );
};

export default AdministrationSettings;