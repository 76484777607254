import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { getBooster } from '../../apollo/queries';

import ThreeDWrapper from '../../components/3DCard/3DWrapper';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import Loading from '../../components/Loading/Loading';
import BoosterScene from '../../components/3DCard/BoosterScene';
import { getMatchData } from '../../utils/helper';
import { updateProfileData as updateProfileDataMutation } from '../../apollo/mutations';

const ThreeDBooster = () => {
  const { boosterId } = useParams();

  const { loading, data } = useQuery(getBooster, {
    variables: { id: boosterId },
    fetchPolicy: useCacheWithExpiration('getBooster', { id: boosterId }),
  });

  const [updateProfileData] = useMutation(updateProfileDataMutation, {
    onCompleted: () => {
      console.log('Avatar update - success');
    },
    onError: (data) => {
      console.error(data.message);
    },
  });

  const errorHandler = () => {
    if (avatar) {
      console.log('Avatar - errorHandler');
      updateProfileData({
        variables: {
          profile_image_url: avatar,
        },
      });
    }
  };

  const booster = data && data.getBooster;

  if (booster)
    booster.match = booster.match_meta_data;

  const { avatar } = booster ? getMatchData(booster, 300, 300) : { avatar: null };

  try {
    return (
      <>
        <Helmet
          style={[{
            'cssText': `
            body {
                background: url("https://cdn.streamheroes.gg/3d-objects/bg_item_store.jpg") center center y-repeat fixed;
            }
        `,
          }]}
        />
        {(loading) ?
          <Loading />
          :
          <div style={{ height: '100vh' }}>
            {!booster ?
              <>Couldn't load a booster</>
              :
              <ThreeDWrapper noBackdrop>
                <ambientLight />
                <BoosterScene
                  frontImage={(booster && booster.front_image) && booster.front_image.substring(1)}
                  backImage={(booster && booster.back_image) && booster.back_image.substring(1)}
                  userLogo={avatar}
                  isCustomPack={booster && booster.is_custom_booster}
                  noAnimation
                  isOblique
                  isRotating
                />
              </ThreeDWrapper>
            }
          </div>
        }
      </>
    );
  } catch (e) {
    errorHandler();
    return (
      <div style={{ height: '100vh' }}>
        <>Couldn't load a booster</>
      </div>
    );
  }
};

export default ThreeDBooster;