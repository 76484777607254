import React from 'react';
import { makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from 'react-step-progress-bar';
import { Link } from 'react-router-dom';


import { isUserPremium, multiplyEnergyCoins } from '../../utils/helper';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';

const conf = require('../../conf');

const useStyles = makeStyles(() => ({
  tankItem: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 5,
    padding: '6px 8px',
    ...Helpers.fillRowCross,
    marginTop: 6,
  },
  tankAvatar: {
    height: 46,
    width: 46,
    border: 'none',
  },
}));

const DepotInfo = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { tanks, coinsTankInfo, viewer } = props;

  const { isPremiumActive } = isUserPremium(viewer);
  const limit = isPremiumActive ? conf.premium_tank_size : 100;
  const percentage = viewer && coinsTankInfo ? Math.round((viewer.sh_coins / coinsTankInfo.size) * 100) : 0;

  return (
    <Link to="/bank" style={{ width: 297, fontSize: 12, display: 'block', color: 'white' }}>
      <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween }}>
        <div style={{ fontWeight: 500 }}>
          {t('coin_overview')}
        </div>
        <div style={{ ...Helpers.fillRowCenter }}>
          <div>{multiplyEnergyCoins(viewer ? viewer.sh_coins : 0)}</div>
          <img src={`${conf.cdn_url}/assets/template/sh_coin.png?height=18`} alt="SH Coin"
               style={{ marginLeft: 4 }} />
        </div>
      </div>
      <div style={{ marginTop: 7 }}>
        <ProgressBar
          percent={percentage}
          filledBackground="linear-gradient(90deg, #F19205 0%, #FFFE47 100%)"
          unfilledBackground={'rgba(255, 255, 255, 0.04)'}
          height={7}
        />
      </div>
      <div
        style={{
          ...Helpers.fillRowCenter,
          ...Helpers.mainSpaceBetween,
          color: Colors.halfWhite,
          marginTop: 4,
        }}
      >
        <div>{t('depot_level')} {coinsTankInfo ? coinsTankInfo.level : 1}</div>
        <div>{multiplyEnergyCoins(coinsTankInfo ? coinsTankInfo.size : 250)} {t('max')}</div>
      </div>

      <div style={{ marginTop: 24 }}>
        <div style={{ fontWeight: 500, marginBottom: 4 }}>
          {t('most_filled_tanks')}
        </div>

        {tanks && tanks.map(tank => (
          <div className={classes.tankItem} key={tank.match.login}>
            <Avatar
              className={classes.tankAvatar}
              alt="Avatar"
              src={tank.match.profile_image_url}
            />
            <div style={{ marginLeft: 10, width: '100%' }}>

              <div style={{ ...Helpers.fillRowCross, ...Helpers.mainSpaceBetween }}>
                <div style={{ fontWeight: 500 }}>
                  {tank.match.display_name}
                </div>
                <div style={{ ...Helpers.fillRowCenter }}>
                  <div>{multiplyEnergyCoins(tank.current_energy)}</div>
                  <img
                    src={`${conf.cdn_url}/assets/template/heroMaker/energy.png?height=18`}
                    alt="Energy"
                    style={{ marginLeft: 4 }} />
                </div>
              </div>

              <div style={{ marginTop: 6 }}>
                <ProgressBar
                  percent={Math.round((tank.current_energy / limit) * 100)}
                  filledBackground="linear-gradient(90deg, #8B26E8 0%, #F306FC 100%)"
                  unfilledBackground={'rgba(255, 255, 255, 0.04)'}
                  height={7}
                />
              </div>

              <div style={{ marginTop: 3, color: Colors.halfWhite, ...Helpers.textRight }}>
                {multiplyEnergyCoins(limit)} {t('max')}
              </div>

            </div>
          </div>
        ))}
      </div>
    </Link>
  );
};

export default DepotInfo;