import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';

import { marketPlaceMyHistory } from '../../apollo/queries';

import PageTitle from '../../components/PageTitle/PageTitle';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import MarketplaceOffers from '../../components/Marketplace/MarketplaceOffers';

const MarketplaceOffersContainer = (props) => {
  const { t } = useTranslation();
  const { type } = props;
  const [page, setPage] = useState(2);
  const [isSeasonal, setIsSeasonal] = useState(false);
  const [sorting, setSorting] = useState({
    field: 'created_at',
    order: 'DESC',
  });
  const [quality, setQuality] = useState({
    min: 0,
    max: 100,
  });
  const [rarity, setRarity] = useState([]);
  const { id } = useParams();
  const perPage = 25;

  const variables = {
    pagination: { page: 1, perPage },
    match: id,
    is_seasonal: isSeasonal,
    sorting,
    rarity,
    min_quality: quality.min,
    max_quality: quality.max,
    type,
  };

  const { data, loading, fetchMore } = useQuery(marketPlaceMyHistory, {
    variables,
    fetchPolicy: useCacheWithExpiration('marketPlaceMyHistory'),
  });

  const marketHistory = data && data.marketPlaceMyHistory;

  return (
    <>
      <PageTitle title={t('marketplace')} />
      <MarketplaceOffers
        loading={loading}
        offers={marketHistory && marketHistory.offers}
        setSorting={setSorting}
        sorting={sorting}
        setRarity={setRarity}
        rarity={rarity}
        setQuality={setQuality}
        quality={quality}
        setIsSeasonal={setIsSeasonal}
        isSeasonal={isSeasonal}
        totalCount={marketHistory && marketHistory.count}
        type={type}
        variables={variables}
        onLoadMore={async () => {
          setPage(page + 1);
          try {
            await fetchMore({
              variables: { ...variables, ...{ pagination: { perPage, page } } },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return {
                  marketPlaceMyHistory: {
                    offers: [...prev.marketPlaceMyHistory.offers, ...fetchMoreResult.marketPlaceMyHistory.offers],
                    __typename: prev.marketPlaceMyHistory.__typename,
                  },
                };
              },
            });
          } catch {
          }
        }}
      />
    </>
  );
};

export default MarketplaceOffersContainer;