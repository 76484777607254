import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import CardPopup from '../Dialog/CardPopup';
import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import AnimationButton from '../Button/AnimationButton';
import { capitalize, copyToClipboard } from '../../utils/helper';
import { useSnackbar } from 'notistack';
import HoverIcon from '../Icon/HoverIcon';
import HiddenElement from '../HiddenElement/HiddenElement';

const useStyles = makeStyles(() => ({
  header: {
    background: '#0C172A',
    borderRadius: '4px 4px 0px 0px',
    padding: '19px 22px',
    color: Colors.white,
  },
  body: {
    background: '#020E20',
  },
  footer: {
    background: '#0C172A',
    ...Helpers.fillRowReverse,
    padding: 6,
    borderRadius: '0px 0px 4px 4px',
  },
  title: {},
  subTitle: {
    color: Colors.halfWhite,
    fontSize: 12,
    marginTop: 6,
  },
  container: {
    padding: 22,
  },
}));


const CopyClipboardPopup = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { handleClose, type, webSourceUrls } = props;
  const findType = webSourceUrls.find(url => url.type === type);
  const { url } = findType;

  return (
    <CardPopup
      handleClose={handleClose}
      style={{
        backgroundColor: 'transparent',
        width: 900,
        maxHeight: 'auto',
        overflow: 'hidden',
        padding: 0,
      }}
    >
      <div className={classes.header}>
        <div className={classes.title}>
          {t('copy_widget_url')}
        </div>
        <div className={classes.subTitle}>
          {t('copy_widget_url_subtitle')}
        </div>
      </div>
      <div className={classes.body}>
        <div className={classes.container}>

          <HiddenElement value={url} />

          <div style={{
            color: '#FF3141',
            fontStyle: 'italic',
            fontSize: 12,
            fontWeight: 500,
            marginBottom: 35,
          }}>
            {t('widget_url_note')}
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <AnimationButton status="whiteTransparency" onClick={handleClose}>
          {capitalize(t('close'))}
        </AnimationButton>
      </div>
    </CardPopup>
  );
};

export default CopyClipboardPopup;