import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  badge: {
    display: 'inline-block',
    padding: '.25em .4em',
    fontSize: '75%',
    fontWeight: 700,
    lineHeight: 1,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'baseline',
    borderRadius: '.25rem',
    transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    color: theme.palette.common.white,
  },
  success: {
    backgroundColor: '#28a745',
  },
  danger: {
    backgroundColor: '#dc3545',
  },
  warning: {
    color: '#212529',
    backgroundColor: '#ffc107',
  },
  info: {
    backgroundColor: '#17a2b8',
  },
}));

const Badge = (props) => {
  const classes = useStyles();
  const { children, status, style } = props;

  const statusClass = classes.hasOwnProperty(status) ? classes[status] : classes.info;

  return (
    <span className={[classes.badge, statusClass].join(' ')} style={style}>{children}</span>
  );
};

export default Badge;