import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useQuery } from '@apollo/react-hooks';

import { getOverViewData } from '../../apollo/mergedQueries';

import ProfileOverview from '../../components/Profile/ProfileOverview';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import Loading from '../../components/Loading/Loading';

import {
  setActivitiesPrivacySettings as setActivitiesPrivacySettingsMutation,
} from '../../apollo/mutations';

const ProfileOverviewContainer = (props) => {
  const { viewer } = props;

  const [setActivitiesPrivacySettings] = useMutation(setActivitiesPrivacySettingsMutation, {
    refetchQueries: () => [{ query: getOverViewData, variables: { viewer: viewer._id } }],
  });

  const { data, loading } = useQuery(getOverViewData, {
    variables: { viewer: viewer._id },
    fetchPolicy: useCacheWithExpiration('getOverViewData'),
  });

  if (loading)
    return <Loading />;

  const myViewer = data && data.getMyViewer;
  const activities = data && data.getActivities;
  const stats = data && data.getSeasonViewerLeaderboard;
  const activePrivacySettings = data && data.getActivitiesPrivacySettings;

  return (
    <ProfileOverview
      viewer={viewer}
      activities={activities}
      stats={stats && stats[0]}
      isMe={myViewer && myViewer.login === viewer.login}
      activePrivacySettings={activePrivacySettings}
      setActivitiesPrivacySettings={setActivitiesPrivacySettings}
    />
  );
};

export default ProfileOverviewContainer;