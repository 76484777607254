import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import { createTypeCard as createTypeCardMutation } from '../../apollo/mutations';

import { uploadImageToS3 } from '../../utils/helper';
import CreateCardAdministration from '../../components/Admin/CreateCardAdministration';

const CreateCardAdministrationContainer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  const [createTypeCard] = useMutation(createTypeCardMutation, {
    onCompleted: async (data) => {
      const { createTypeCard } = data;
      setLoading(false);
      setFile(null);

      if (createTypeCard && createTypeCard !== '')
        await uploadImageToS3(createTypeCard, file, {
          'Content-Type': file.type,
        });
    },
    onError: (data) => {
      setLoading(false);
      enqueueSnackbar(data.message, { variant: 'error' });
    },
  });

  const onCreate = (variables) => {
    setLoading(true);

    if (!file)
      return;

    const extension = file.name.split('.')
    .pop();
    const dir = variables.matchModel === 'Streamer' ? 'partnered' : variables.matchModel === 'Game' ? 'games' : 'organisations';
    variables.imageParams = {
      directory: `cards/${dir}/print`,
      extension,
    };
    if (variables.costs && variables.collectible_from) {
      variables.specialCardParams = {
        costs: variables.costs,
        card_name: variables.card_name,
        collectible_from: variables.collectible_from,
        collectible_until: variables.collectible_until,
        edition: variables.edition,
        sorting: variables.sorting,
      };
    }
    delete variables.costs;
    delete variables.card_name;
    delete variables.collectible_from;
    delete variables.collectible_until;
    delete variables.edition;
    delete variables.sorting;

    createTypeCard({ variables });
  };

  return (
    <CreateCardAdministration
      createCard={onCreate}
      loading={loading}
      file={file}
      setFile={setFile}
    />
  );
};

export default CreateCardAdministrationContainer;