import React from 'react';
import { makeStyles } from '@material-ui/core';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import HoverIcon from '../Icon/HoverIcon';

const useStyles = makeStyles(() => ({
  roundedIcon: {
    width: 40,
    height: 40,
    border: '1px solid #ffffff',
    borderRadius: 50,
    ...Helpers.fillRowCenter,
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    zIndex: 1,
    '& i': {
      fontSize: 18,
      color: Colors.white,
    },
    '&:hover': {
      background: Colors.white,
      '& i': {
        color: '#010F22',
      },
    },
  },
}));

const ClosePopupButton = (props) => {
  const classes = useStyles();
  const { handleClose, style } = props;

  return (
    <div
      className={classes.roundedIcon}
      style={{ position: 'absolute', right: 0, top: 0, ...style }}
      onClick={handleClose}
    >
      <HoverIcon
        icon="fa-light fa-xmark" hoverIcon="fa-regular fa-xmark"
        style={{ fontSize: 18 }}
      />
    </div>
  );
};

export default ClosePopupButton;