import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ProgressBar } from 'react-step-progress-bar';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import HRNumbers from 'human-readable-numbers';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  level: {
    width: 30,
    height: 30,
    border: '2px solid #E12138',
    ...Helpers.fillRowCenter,
    borderRadius: '50%',
    fontSize: 14,
    fontWidth: 500,
  },
  container: {
    ...Helpers.fillRowCenter,
    marginTop: 26,
    width: '100%',
    color: Colors.white,
    position: 'relative',
    '& .RSPBprogressBar, .RSPBprogression': {
      borderRadius: 0,
    },
  },
  xpInfo: {
    fontSize: 12,
    color: '#FFFFFF80',
    position: 'absolute',
    left: 40,
    top: -4,
    '& > span': {
      color: Colors.white,
    },
  },
}));

const ProfileHeaderLevel = (props) => {
  const classes = useStyles();
  const { viewerBadges } = props;

  if (!viewerBadges || (viewerBadges && viewerBadges.next_level_required_points === 0))
    return null;

  const pointsToLvl = viewerBadges.next_level_required_points - viewerBadges.current_level_required_points;
  const pointsThisLvl = viewerBadges.overall_lvl_value - viewerBadges.current_level_required_points;
  const progressPercent = Math.floor(pointsThisLvl * 100 / pointsToLvl);

  return (
    <div className={classes.container}>
      <div className={classes.xpInfo}>
        <span>{HRNumbers.toHumanString(pointsThisLvl)}</span> / {HRNumbers.toHumanString(pointsToLvl)} XP
      </div>
      <div className={classes.level}>
        {viewerBadges.overall_lvl_badge_lvl}
      </div>
      <ProgressBar
        percent={progressPercent}
        filledBackground="#E12138"
        unfilledBackground="rgba(255, 255, 255, 0.06)"
        height={6}
        width={isMobile ? '80%' : "100%"}
      />
      <div className={classes.level} style={{ borderColor: '#FFFFFF0F' }}>
        {viewerBadges.overall_lvl_badge_lvl + 1}
      </div>
    </div>
  );
};

export default ProfileHeaderLevel;