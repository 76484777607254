import React, { useRef, useMemo } from 'react';
import * as THREE from 'three';
import { useFrame, extend } from '@react-three/fiber';
import { MeshLine, MeshLineMaterial } from './meshline';

extend({ MeshLine, MeshLineMaterial });

function Fatline({ curve, width, color, speed }) {
  const material = useRef(null);
  useFrame(() => (material.current.uniforms.dashOffset.value -= speed));
  return (
    <mesh position={[0, 0, -10]}>
      <meshLine attach="geometry" points={curve} />
      <meshLineMaterial attach="material" ref={material} transparent={true} depthTest={false}
                        lineWidth={width} color={color} dashArray={0.1} dashRatio={0.9} />
    </mesh>
  );
}

function Lines({ count, colors }) {
  const lines = useMemo(
    () =>
      new Array(count).fill(0)
      .map((_, index) => {
        const pos = new THREE.Vector3(10 - Math.random() * 20, 10 - Math.random() * 20, 10 - Math.random() * 20);
        const points = new Array(30).fill(0)
        .map(() => pos.add(new THREE.Vector3(4 - Math.random() * 8, 4 - Math.random() * 8, 2 - Math.random() * 4))
        .clone());
        const curve = new THREE.CatmullRomCurve3(points).getPoints(1000);
        const speed = Math.max(0.001, 0.004 * Math.random());
        console.log(speed);
        return {
          color: colors[parseInt(colors.length * Math.random())],
          width: 0.1,
          speed: speed,
          curve,
        };
      }),
    [colors, count],
  );
  return lines.map((props, index) => <Fatline key={index} {...props} />);
}

export default Lines;