import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import { isTabletOrMobileObj, returnMobileObj } from '../../utils/responsive';

const useStyles = makeStyles(() => ({
  container: {
    background: Colors.containerBGColor,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    minHeight: 500,
  },
  innerContainer: {
    padding: 35,
    flexWrap: 'wrap',
    ...Helpers.fillRow,
    color: Colors.white,
  },
  typeHeader: {
    padding: '0 50px',
    ...Helpers.fillRow,
    ...Helpers.mainSpaceBetween,
    color: '#40536A',
    background: 'linear-gradient(to bottom, #010005, #020B14, #091428)',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    border: '2px solid #000002',
    textTransform: 'uppercase',
    fontFamily: 'Bebas Neue',
    fontSize: 20,
  },
  typeItem: {
    padding: '20px 0',
    margin: '0 20px',
    cursor: 'pointer',
    transition: 'all 0.6s ease',
    borderBottom: '1px solid transparent',
    '&:hover': {
      color: Colors.white,
    },
    '&.active': {
      color: '#FAE376',
      textShadow: '0px 0px 12px #FF8900',
      borderBottom: '1px solid #AF0C29',
    },
  },
}));

const Layout = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { categories, tab, setTab, children, style, filter, bodyStyles } = props;

  const isTabletOrMobile = useMediaQuery(isTabletOrMobileObj);

  return (
    <div className={classes.container} style={style}>
      {(categories || filter) &&
      <div className={classes.typeHeader}
           style={returnMobileObj(isTabletOrMobile, { ...Helpers.fillColCenter })}>
        <div style={Helpers.fillRow}>
          {categories && categories.map(category => (
            <div
              className={[classes.typeItem, tab === category.key ? 'active' : ''].join(' ')}
              onClick={() => category.href ? props.history.push(category.href) : setTab(category.key)}
              key={category.key}
            >
              {t(category.value)}
            </div>
          ))}
        </div>
        {filter && filter}
      </div>
      }
      <div
        className={classes.innerContainer}
        style={{
          ...(bodyStyles ? bodyStyles : {}),
          ...returnMobileObj(isTabletOrMobile, { padding: 10 }),
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default withRouter(Layout);