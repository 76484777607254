import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';

import conf from '../../conf.json';
import Colors from '../../theme/Colors';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  container: {
    border: '1px solid #FFFFFF24',
    borderRadius: 6,
    padding: '12px 25.5px 22px',
    transition: 'all 0.3s ease-out',
    cursor: 'pointer',
    ...Helpers.fillColCenter,
    '&:hover': {
      borderColor: '#FFFFFF33',
      background: '#FFFFFF0F',
    },
    '&.selected': {
      borderColor: '#09B711',
      background: '#FFFFFF1A',
    },
  },
  positiveState: {
    fontSize: 12,
    color: '#00FF55',
    background: '#00FF551C',
    borderRadius: 4,
    padding: '4px 10px',
  },
}));

const BoosterOrderItem = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { booster, isSelected, onClick } = props;

  const diffDays = booster && booster.streamer_last_ordered ? moment()
  .diff(moment.unix(booster.streamer_last_ordered / 1000), 'days') : 100;
  const isOrderable = diffDays >= 90;

  return (
    <div
      className={[classes.container, isSelected ? 'selected' : ''].join(' ')}
      onClick={() => isOrderable ? onClick() : null}
    >
      <div className={classes.positiveState} style={isOrderable ? {} : {
        color: '#E12138',
        background: '#E121381C',
      }}>
        {isOrderable ? t('orderable') : t('orderable_in_x_days', { days: 90 - diffDays })}
      </div>

      <div style={{ width: 157 }}>
        <img
          src={booster.front_image ? `${conf.cdn_url}${booster.front_image}?width=157` : 'https://via.placeholder.com/157x227'}
          alt="Booster pack"
          style={{ marginTop: 18, marginBottom: 22 }}
        />
      </div>

      <div style={{
        maxWidth: 157, ...Helpers.textCenter,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}>
        {booster.edition}
      </div>
      <div style={{
        fontSize: 14,
        color: Colors.halfWhite,
        marginTop: 4,
      }}>
        {booster.redeemable_from ? t('event_booster') : t('standard_booster')}
      </div>
    </div>
  );
};

export default BoosterOrderItem;