import React, { useState } from 'react';
import {
  FormControl, FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ImageUploader from 'react-images-upload';

import Helpers from '../../theme/Helpers';
import CustomButton from '../Button/CustomButton';
import Loading from '../Loading/Loading';

const useStyles = makeStyles(() => ({
  textField: {
    width: 250,
    margin: 10,
  },
}));

const CreateCardAdministration = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { createCard, loading, setFile, file } = props;
  const defaultValues = {
    card_name: '',
    edition: '',
    matchModel: 'Streamer',
    cardType: 'Card',
    match: '',
    level: 1,
    costs: 0,
    collectible_from: '',
    collectible_until: '',
    sorting: 0,
  };
  const [formValues, setFormValues] = useState(defaultValues);

  const fields = [
    {
      id: 'matchModel', type: 'radio', options: [
        { value: 'Streamer', label: 'Streamer' },
        { value: 'Game', label: 'Game' },
        { value: 'Organisation', label: 'Organisation' },
      ],
    },
    {
      id: 'cardType', type: 'radio', options: [
        { value: 'Card', label: 'Card' },
        { value: 'SpecialCard', label: 'SpecialCard' },
      ],
    },
    { id: 'match', type: 'text' },
    { id: 'level', type: 'number', isCard: true },
    { id: 'card_name', type: 'text', isSpecial: true },
    { id: 'edition', type: 'text', isSpecial: true },
    { id: 'costs', type: 'number', isSpecial: true, helper: 'Max 100 Energie' },
    { id: 'collectible_from', type: 'text', isSpecial: true, helper: 'YYYY-MM-DD HH:MM:SS' },
    { id: 'collectible_until', type: 'text', isSpecial: true, helper: 'YYYY-MM-DD HH:MM:SS' },
    { id: 'sorting', type: 'number', isSpecial: true },
  ];

  const handleChange = (event) => {
    const { name, value } = event.target;
    const tmpValues = { ...formValues };
    tmpValues[name] = name === 'costs' || name === 'level' || name === 'sorting' ? parseInt(value) : value;
    setFormValues(tmpValues);
  };

  const create = async () => {
    if (formValues.match === '' && formValues.match.length <= 3)
      return;

    await createCard(formValues);
    setFormValues(defaultValues);
  };

  const onDrop = async (files) => {
    if (files.length > 0)
      setFile(files[files.length - 1]);
  };

  return (
    <div style={{ ...Helpers.fillCol, marginRight: 10 }}>
      <div style={{ fontSize: 20, fontWeight: 'bold', margin: 10 }}>
        {t('card_creation_head')}
      </div>
      {loading ?
        <div style={{ margin: 10 }}>
          <Loading />
        </div>
        :
        <>
          <div style={{ ...Helpers.fillRow, flexWrap: 'wrap' }}>
            {fields.map(field => (
              (field.type === 'radio') ?
                <FormControl component="fieldset" key={field.id} style={{ marginRight: 20 }}>
                  <FormLabel component="legend">{t(field.id)}</FormLabel>
                  <RadioGroup
                    name={field.id}
                    value={formValues && formValues[field.id]}
                    onChange={handleChange}
                    style={{
                      ...Helpers.row,
                      border: '1px solid #CE1537',
                      borderRadius: 4,
                      padding: 5,
                      marginTop: 5,
                    }}
                  >
                    {field.options.map(option => (
                      <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                :
                <TextField
                  key={field.id}
                  id={field.id}
                  name={field.id}
                  label={t(field.id)}
                  variant="outlined"
                  type={field.type}
                  onChange={handleChange}
                  value={formValues && formValues[field.id]}
                  className={classes.textField}
                  helperText={field.helper && field.helper}
                  disabled={(field.isSpecial && formValues.cardType === 'Card') || (field.isCard && formValues.cardType === 'SpecialCard')}
                />
            ))}
          </div>
          <div style={{ margin: 10, ...Helpers.fillColCenter, maxWidth: 300 }}>
            {file &&
            <img
              src={URL.createObjectURL(file)}
              alt={`PreviewUploadedImage`}
              style={{ height: 310, width: 200, borderRadius: 4 }}
            />
            }
            <ImageUploader
              withIcon={false}
              buttonText={t('choose_image')}
              onChange={onDrop}
              fileSizeError={t('file_size_error')}
              fileTypeError={t('file_extension_not_supported')}
              imgExtension={['.jpg', '.png', '.jpeg']}
              maxFileSize={5000000}
              singleImage
              errorStyle={{ color: 'red' }}
              buttonStyles={{
                backgroundColor: '#cc1e31',
                borderRadius: 3,
                padding: '5px 20px',
                fontFamily: 'Bebas Neue',
                fontSize: 20,
                width: '100%',
              }}
              labelStyles={{
                color: '#6f6f6f',
                fontSize: 12,
                textTransform: 'uppercase',
              }}
              fileContainerStyle={{
                backgroundColor: 'transparent',
                padding: 0,
              }}
            />
          </div>
          <CustomButton
            style={{ margin: 10, maxWidth: 300 }}
            onClick={create}
            disabled={formValues.name === ''}
          >
            {t('create')}
          </CustomButton>
        </>
      }
    </div>
  );
};

export default CreateCardAdministration;