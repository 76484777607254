import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { getMyViewer } from '../../apollo/queries';

import Loading from '../../components/Loading/Loading';
import NotFoundPage from '../../components/NotFoundPage/NotFoundPage';
import IllustratorArea from '../../components/Illustrator/IllustratorArea';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

const IllustratorContainer = () => {
  const { data, loading, error } = useQuery(getMyViewer, {
    fetchPolicy: useCacheWithExpiration('getMyViewer'),
  });

  if (loading) return <Loading />;
  if (error) return error.message;

  const myViewer = (data) && data.getMyViewer;
  const roles = (myViewer) && myViewer.streamheroes_role;

  if (roles.includes('admin') || roles.includes('illustrator') || roles.includes('marketing'))
    return <IllustratorArea myViewer={myViewer} />;
  else
    return <NotFoundPage />;
};

export default (IllustratorContainer);