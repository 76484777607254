import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';

const useStyles = makeStyles(() => ({
  container: {
    padding: '16px 20px',
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
  },
  coloredText: {
    color: Colors.halfWhite,
  },
  valueBlock: {
    minWidth: 130,
    ...Helpers.textRight,
    color: Colors.white,
  },
  line: {
    fontSize: 16,
    fontFamily: 'Montserrat',
    fontWeight: 300,
    ...Helpers.fillRowCross,
    ...Helpers.mainSpaceBetween,
    position: 'relative',
    padding: '10px 0',
  },
}));

const CardViewDetails = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { card, informationData } = props;

  let date;

  if (informationData && informationData.created_at && !informationData.redeemed_booster)
    date = informationData.created_at
  else if (informationData && informationData.redeemed_booster)
    date = informationData.redeemed_booster.generic_booster_metadata.created_at;

  const momentCardCreation = date && moment(
     parseInt(date),
  ).format('LL');

  return (
    <div className={classes.container}>
      <div className={classes.line}>
        <div className={classes.coloredText}>
          {t('card_total_collected')}:
        </div>
        <div className={classes.valueBlock}>
          {informationData.overall_redeems && informationData.overall_redeems.toLocaleString()}
        </div>
      </div>
      <div className={classes.line}>
        <div className={classes.coloredText}>
          {t('card_collectors')}:
        </div>
        <div className={classes.valueBlock}>
          {informationData.unique_user_redeems && informationData.unique_user_redeems.toLocaleString()}
        </div>
      </div>
      <div className={classes.line}>
        <div className={classes.coloredText}>
          {t('card_collectible_since')}:
        </div>
        <div className={classes.valueBlock}>
          {momentCardCreation}
        </div>
      </div>
    </div>
  );
};

export default CardViewDetails;