import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import HRNumbers from 'human-readable-numbers';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import { replaceWidthAndHeight } from '../../utils/helper';

const useStyles = makeStyles({
  profileImage: {
    height: 80,
    width: 80,
    borderColor: Colors.black,
    backgroundColor: Colors.searchFieldColor,
    borderWidth: 4,
    marginBottom: 5,
  },
  pulse: {
    boxShadow: '0 0 0 rgba(0, 0, 0, 0.4)',
    animation: '$pulse 2s infinite',
  },
  '@keyframes pulse': {
    '0%': {
      boxShadow: '0 0 0 0 rgba(0, 0, 0, 0.4)',
    },
    '70%': {
      boxShadow: '0 0 0 15px rgba(0, 0, 0, 0)',
    },
    '100%': {
      boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
    },
  },
  circle: {
    borderRadius: '50%',
    backgroundColor: Colors.primary,
    width: 10,
    height: 10,
    marginRight: 5,
  },
  streamArea: {
    ...Helpers.fillRowCenter,
    marginTop: 5,
  },
  streamerLink: {
    color: Colors.white,
    cursor: 'pointer',
    width: 130,
    height: 150,
    ...Helpers.fillColCenter,
    '&:hover, &:hover span': {
      color: Colors.primary,
    },
    '& span': {
      fontSize: 14,
      color: Colors.subTextColor,
    },
  },
  hasOwnDesignArea: {
    '& span': {
      color: Colors.black,
    },
    '&:hover, &:hover span': {
      color: Colors.black,
    },
  },
});

const OrganisationMember = (props) => {
  const classes = useStyles();
  const { memberStream, hasOwnDesign } = props;

  const { streamer } = memberStream;

  const avatarStyles = [classes.profileImage];
  if (memberStream.started_at)
    avatarStyles.push(classes.pulse);

  const handleClick = () => {
    props.history.push(`/profile/${streamer.login}`);
  };

  const areaDesign = [classes.streamerLink];
  if (hasOwnDesign)
    areaDesign.push(classes.hasOwnDesignArea);

  return (
    <div onClick={handleClick} className={areaDesign.join(' ')}>
      <Avatar
        className={avatarStyles.join(' ')}
        alt={streamer.display_name}
        src={replaceWidthAndHeight(streamer.profile_image_url, 100, 100)}
      />
      <div>{streamer.display_name}</div>
      {memberStream.started_at &&
        <div className={classes.streamArea}>
          <div
            className={classes.circle}
            style={hasOwnDesign ? { backgroundColor: Colors.white } : {}}
          />
          <span>{HRNumbers.toHumanString(memberStream.viewer_count)}</span>
        </div>
      }
    </div>
  );
};

export default withRouter(OrganisationMember);