import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { SpeedDial } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import Colors from '../../theme/Colors';

const useStyles = makeStyles((theme) => ({
  speedDial: {
    position: 'fixed',
    '&.MuiSpeedDial-directionUp': {
      bottom: theme.spacing(3),
      right: theme.spacing(3),
    },
    '& .MuiSpeedDial-fab': {
      backgroundColor: Colors.primary,
    },
    '& > div > button': {
      backgroundColor: Colors.primary,
      '&:hover': {
        backgroundColor: Colors.secondary,
      },
    },
  },
}));

const AdminSpeedMenuContainer = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { main, children } = props;

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  return (
    main.hasAdminRights &&
    <SpeedDial
      ariaLabel="SpeedDial"
      className={classes.speedDial}
      icon={<FontAwesomeIcon icon={faCog} size="lg" color={Colors.white} />}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      direction={'up'}
    >
      {children}
    </SpeedDial>
  );
};

const mapStateToProps = (state) => {
  const { main } = state;
  return { main };
};

export default connect(mapStateToProps)(AdminSpeedMenuContainer);