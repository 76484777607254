import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Colors from '../../theme/Colors';

import Helpers from '../../theme/Helpers';

const useStyles = makeStyles({
  slider: {
    '& .slick-list': {
      borderRadius: 6,
      height: 300,
    },
    '& .slick-dots': {
      bottom: 17,

      '& li': {
        width: 'auto',
        height: 'auto',

        '&.slick-active button': {
          background: Colors.white,
        },

        '& button': {
          background: 'rgba(255, 255, 255, 0.14)',
          borderRadius: 2,
          height: 4,
          width: 33,
          padding: 0,
          '&:before': {
            content: '""',
          },
        },
      },
    },
  },
});

const ImageCarousel = (props) => {
  const classes = useStyles();
  const { images } = props;
  const { t } = useTranslation();

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className={classes.slider}>
      <Slider {...settings}>
        {images.map(image => (
          <div key={`ImageURL${image.img}`}>
            <div style={{
              backgroundImage: `url(${image.img})`,
              backgroundSize: 'cover',
              height: 300,
              ...Helpers.fillColCenter,
            }}>
              <h2 style={{ textTransform: 'uppercase', marginTop: 0 }}>{t(image.header)}</h2>
              <div style={{ width: 600, textAlign: 'center' }}>{t(image.body)}</div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageCarousel;