import React from 'react';
import { useTranslation } from 'react-i18next';

import ReturnBreadcrumbs from './ReturnBreadcrumbs';

const CardShopBreadcrumbs = (props) => {
  const { t } = useTranslation();
  const { streamer, cardText } = props;

  const mainUrl = 'card-shop';

  const parts = [
    {
      name: t('shop'),
    },
    {
      name: t('overview'),
      link: `/${mainUrl}`,
    },
  ];

  if (streamer)
    parts.push({
      name: streamer.display_name,
      link: `/${mainUrl}/streamer/${streamer._id}`,
    });

  if (cardText)
    parts.push({
      name: cardText,
    });

  return (
    <ReturnBreadcrumbs parts={parts} />
  );
};

export default CardShopBreadcrumbs;