import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import conf from '../../conf.json';
import { isMobile } from 'react-device-detect';

const AdSense = (props) => {
  const {
    className = '',
    style = { display: 'block' },
    client,
    slot,
    layout = '',
    layoutKey = '',
    format = 'auto',
    responsive = 'false',
    pageLevelAds = false,
    adTest,
    children,
    ...rest
  } = props;

  useEffect(() => {
    const p = {};
    if (pageLevelAds) {
      p.google_ad_client = client;
      p.enable_page_level_ads = true;
    }

    try {
      if (typeof window === 'object') {
        ((window).adsbygoogle = (window).adsbygoogle || []).push(p);
      }
    } catch {
      // Pass
    }
  }, [client, pageLevelAds]);

  if (isMobile)
    return null;

  return (
    <Link to={{ pathname: 'https://twitch.achievements.tv/' }} target="_blank">
      <img
        src={`https://cdn.streamheroes.gg/assets/ads/ACHV_Ad_banner_${style.width}x${style.height}.jpg`}
        alt="Achievements.gg"
        style={style}
      />
    </Link>

    /*<ins
      className={`adsbygoogle ${className}`}
      style={style}
      data-ad-client={client}
      data-ad-slot={slot}
      data-ad-layout={layout}
      data-ad-layout-key={layoutKey}
      data-ad-format={format}
      data-full-width-responsive={responsive}
      data-adtest={adTest}
      {...rest}
    >
      {children}
    </ins>*/
  );
};

export default AdSense;

AdSense.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  client: PropTypes.string.isRequired,
  slot: PropTypes.string,
  layout: PropTypes.string,
  layoutKey: PropTypes.string,
  format: PropTypes.string,
  responsive: PropTypes.string,
  pageLevelAds: PropTypes.bool,
  adTest: PropTypes.string,
  children: PropTypes.node,
};