import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';

const useStyles = makeStyles(() => ({
  item: {
    ...Helpers.fillColCenter,
    width: 180,
    color: Colors.white,
  },
  avatar: {
    backgroundColor: Colors.white,
    width: 106,
    height: 106,
  },
  text: {
    backgroundColor: Colors.white,
    height: 15,
  },
}));

/**
 * @return {null}
 */
function RowListSkeletonItem () {
  const classes = useStyles();

  return (
    <div className={classes.item}>
      <Skeleton variant="circle" className={classes.avatar} />
      <Skeleton width="60%" className={classes.text} style={{ height: 22, marginTop: 5 }} />
      <Skeleton width="30%" className={classes.text} />
    </div>
  );
}

export default RowListSkeletonItem;