import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TopBG from '../TopViewHeader/TopBG';
import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import BoosterHead from './BoosterHead';
import CardsOverview from './CardsOverview';
import { setBoosterData } from '../../redux/actions';

const useStyles = makeStyles({
  hoverIcon: {
    width: 40,
    height: 40,
    borderRadius: 6,
    ...Helpers.fillColCenter,
    transition: 'all 0.3s ease-out',
    background: '#FFFFFF14',
    cursor: 'pointer',
    '&:hover': {
      background: '#FFFFFF3D',
    },
  },
});

const BoosterManagementUpdateBooster = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { booster, isStandard, cardTool } = props;

  return (
    <TopBG
      disableBreadcrumbs
      style={{
        padding: '30px 0 20px',
        backgroundSize: 'auto',
        color: Colors.white,
      }}
    >
      <div style={{ marginBottom: 30, ...Helpers.fillRowCross }}>
        <div className={classes.hoverIcon} style={{ marginRight: 10 }}
             onClick={() => props.history.push('/streamer-settings/booster-management')}>
          <i className="fa-light fa-arrow-left" />
        </div>
        <div style={{ fontFamily: 'Bebas Neue', fontSize: 36 }}>
          {t('booster_update')}
        </div>
      </div>

      <BoosterHead isStandard={isStandard} boosterData={booster} />

      <CardsOverview
        isStandard={isStandard}
        boosterId={booster && booster.boosterId}
        cardTool={cardTool}
      />
    </TopBG>
  );
};

const mapStateToProps = (state) => {
  const { cardTool } = state;
  return { cardTool };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setBoosterData,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BoosterManagementUpdateBooster));