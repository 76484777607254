import React from 'react';
import Chart from 'react-apexcharts';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const KPIDiagram = (props) => {
  const { t } = useTranslation();
  const { title, kpis, type } = props;

  let categories = new Set();
  const series = [];
  const yTooltip = [];
  let dataLength = 0;

  for (let kpi of kpis) {
    if (kpi && kpi.values.length > dataLength) dataLength = kpi.values.length;
  }

  if (dataLength === 0) return <div style={{ margin: 20 }}>
    {t('no_data_for')} {title}
  </div>;

  for (let kpi of kpis) {
    if (kpi._id.includes('duration')) {
      yTooltip.push({
        title: {
          formatter: function (val) {
            return val + ' (s)';
          },
        },
      });
    } else {
      yTooltip.push({});
    }

    let data = kpi.values.map(value => kpi._id.includes('duration') ? Math.round(value.value / 1000) : Math.round(value.value));
    kpi.values.map(value => {
      if (!categories.has(value.check_start)) categories.add(value.check_start);
      return null;
    });

    if (data.length < dataLength) {
      const tmpData = data;
      data = Array(dataLength - data.length)
      .fill(0)
      .concat(tmpData);
    }

    series.push({
      name: t(kpi._id),
      data,
    });
  }

  categories = [...categories].map(category => moment.unix(category / 1000)
  .format('DD. MMM'));

  const options = {
    chart: {
      width: 500,
      type,
      zoom: {
        enabled: false,
      },
    },
    grid: {
      padding: {
        bottom: 30,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: type === 'line' ? 'straight' : 'smooth',
    },
    title: {
      text: title,
      align: 'left',
    },
    legend: {
      tooltipHoverFormatter: function (val, opts) {
        return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '';
      },
    },
    theme: {
      mode: 'dark',
      palette: 'palette6',
    },
    xaxis: {
      categories: categories,
    },
    tooltip: {
      y: yTooltip,
    },
  };

  return (
    <div style={{ padding: 10 }}>
      <Chart
        options={options}
        series={series}
        type={type}
        width={500} />
    </div>
  );
};

KPIDiagram.propTypes = {
  kpis: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  type: PropTypes.string,
};

KPIDiagram.defaultProps = {
  type: 'line',
};

export default KPIDiagram;