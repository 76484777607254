import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { zeroPad } from 'react-countdown';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import { rangeArray } from '../../utils/helper';
import ColorPicker from '../ColorPicker/ColorPicker';
import {
  addHeroMakerItem,
  removeHeroMakerColoredImage,
  setHeroMakerAssets,
} from '../../redux/actions';

import conf from '../../conf';

const useStyles = makeStyles({
  assetsLine: {
    ...Helpers.fillRow,
    ...Helpers.mainStart,
    flexWrap: 'wrap',
    marginTop: 15,
  },
  extraLine: {
    ...Helpers.fillRow,
    ...Helpers.mainStart,
    marginTop: 46,
  },
  assetItem: {
    borderRadius: 8,
    background: '#000712',
    border: '1px solid #051324',
    margin: '0 13px 14px 0',
    width: 102,
    height: 102,
    cursor: 'pointer',
    overflow: 'hidden',
    '&:nth-child(6n)': {
      marginRight: 0,
    },
    '&.active': {
      borderColor: '#E12138',
      boxShadow: '0px 0px 16px #E12138',
    },
    '&:hover': {
      borderColor: Colors.white,
    },
  },
});

const AppearanceItemSelection = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { category, character, heroMakerCategories, heroMaker, setHeroMakerAssets } = props;

  const [started, setStarted] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [colors, setColors] = useState(null);

  const activeCategory = heroMakerCategories[category];

  useEffect(() => {
    if (started)
      updateAssets();
  }, [selectedIndex]);

  useEffect(() => {
    const currentItem = heroMaker.items && heroMaker.items.find(item => item.category === category);
    const currentIndex = currentItem ? currentItem.selectedIndex : 0;
    const currentColors = currentItem ? currentItem.colors : null;
    setSelectedIndex(currentIndex);
    setColors(currentColors);
    setStarted(true);
  }, [category]);

  const updateColors = () => {
    let colorCount = activeCategory.colors;

    // check if it is a special item case with a different number of colors
    if (activeCategory.specialColorCases && activeCategory.specialColorCases[zeroPad(selectedIndex)] !== undefined)
      colorCount = activeCategory.specialColorCases[zeroPad(selectedIndex)];

    if (selectedIndex === 0 && activeCategory.start > 0)
      colorCount = 0;

    const currentItem = heroMaker.items && heroMaker.items.find(item => item.category === category);
    if (currentItem && currentItem.colors && currentItem.colors.length === colorCount) {
      setColors(currentItem.colors);
      return currentItem.colors;
    }

    // If the category has the same number of color slots, the array does not need to be reset
    if (!activeCategory.specialColorCases && (colors && colors.length > 0) && colorCount > 0)
      return colors;

    let tmpColors = [];

    if (colorCount > 0)
      tmpColors = Array(colorCount)
      .fill(null);

    setColors(tmpColors);
    return tmpColors;
  };

  const updateAssets = () => {
    const colors = updateColors();
    const tmpAssets = [...heroMaker.assets];
    const idx = tmpAssets.findIndex(asset => asset.asset === category);
    let updateAssets = false;

    if (selectedIndex > 0 || (selectedIndex === 0 && activeCategory.start === 0)) {
      if (idx === -1) {
        tmpAssets.push({
          asset: category,
          colors,
          type: selectedIndex,
          sorting: heroMakerCategories[category].sorting,
        });
        updateAssets = true;
      } else if (tmpAssets[idx].type !== selectedIndex) {
        tmpAssets[idx].type = selectedIndex;
        tmpAssets[idx].colors = colors;
        updateAssets = true;
      }
    } else {
      if (idx !== -1) {
        tmpAssets.splice(idx, 1);
        updateAssets = true;
      }
    }

    if (updateAssets) {
      props.removeHeroMakerColoredImage(category, colors.length);
      props.addHeroMakerItem({
        category,
        colors,
        selectedIndex,
        sorting: heroMakerCategories[category].sorting,
      });
      setHeroMakerAssets(tmpAssets);
    }
  };

  const getTooltipText = (index) => {
    const colorNames = ['primary_color', 'secondary_color', 'tertiary_color', 'fourth_color'];

    return colorNames[index];
  };

  const getReversedColorIdx = (index) => {
    if (colors.length === 4)
      return [3, 2, 1, 0][index];
    if (colors.length === 3)
      return [2, 1, 0][index];
    if (colors.length === 2)
      return [1, 0][index];
    if (colors.length === 1)
      return 0;
  };

  const changeColor = (index, color) => {
    const tmpColors = [...colors];
    tmpColors[index] = color;
    setColors(tmpColors);

    const tmpAssets = [...heroMaker.assets];
    const idx = tmpAssets.findIndex(asset => asset.asset === category);

    if (idx !== -1) {
      tmpAssets[idx].colors = tmpColors;

      props.removeHeroMakerColoredImage(category, tmpColors.length);
      props.addHeroMakerItem({
        category,
        colors: tmpColors,
        selectedIndex,
        sorting: heroMakerCategories[category].sorting,
      });
      setHeroMakerAssets(tmpAssets);
    }
  };

  return (
    <>
      <div className={classes.assetsLine}>
        {rangeArray(0, activeCategory.last)
        .map((asset, index) => {
          /*if (character === '01_male_realistic' && category === '12_clothes' && index === 4)
            return null;*/

            return (
              <div
                className={[classes.assetItem, asset === selectedIndex ? 'active' : ''].join(' ')}
                onClick={() => setSelectedIndex(asset)}
                key={`AssetItem${index}`}
              >
                {(index > 0 || (index === 0 && activeCategory.start === 0)) &&
                <img
                  src={`${conf.no_cors_cdn_url}/heromaker/${character}/00_base/${category}/preview/${zeroPad(asset)}.png`}
                  alt="Asset"
                />
                }
              </div>
            );
          },
        )}
      </div>
      <div className={classes.extraLine}>
        {colors && [...colors].reverse()
        .map((col, index) => {
          const reversedIdx = getReversedColorIdx(index);

          return (
            <ColorPicker
              color={col}
              handleColor={(color) => changeColor(reversedIdx, color)}
              disableText
              position="top"
              hasActiveColor={!!col}
              removeColor={() => changeColor(reversedIdx, null)}
              tooltipText={t(getTooltipText(index))}
            />
          );
        })}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { heroMaker } = state;
  return { heroMaker };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    addHeroMakerItem,
    removeHeroMakerColoredImage,
    setHeroMakerAssets,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(AppearanceItemSelection);