import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import ReactFlagsSelect from 'react-flags-select';
import { zeroPad } from 'react-countdown';

import Helpers from '../../theme/Helpers';
import { rangeArray } from '../../utils/helper';
import ColorSelection from '../ColorPicker/ColorSelection';
import Colors from '../../theme/Colors';

const useStyles = makeStyles(() => ({
  container: {
    ...Helpers.fillCol,
  },
  cardPlaceHolder: {
    padding: '30px 10px',
    backgroundColor: '#BB2E3D',
    borderRadius: 4,
    transform: 'rotate(-5deg)',
    marginRight: 30,
  },
  innerLvl: {
    ...Helpers.textCenter,
    textTransform: 'uppercase',
    backgroundColor: '#8A202A',
    borderRadius: '50%',
    padding: '5px 13px',
    transform: 'rotate(5deg)',
  },
  flagSelect: {
    border: '1px solid #a90b27',
    padding: 10,
    borderRadius: 3,
    marginTop: 20,
    '& .flag-options': {
      backgroundColor: '#b60b28',
      color: Colors.white,
      borderColor: Colors.wrapperDark,
      '& .flag-option:hover': {
        color: Colors.wrapperLight,
      },
    },
    '& .selected--flag--option': {
      color: 'white',
      '& .country-flag img': {
        width: 24,
        height: 24,
      },
      '& .country-label': {
        color: Colors.white,
        padding: '0 60px 0 15px',
      },
    },
    '& .arrow-down': {
      color: '#cc1e31',
    },
  },
}));

const CardToolServicesDesignerFormDetails = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { values, setValues } = props;

  const handleValue = (event, nr, type) => {
    const { value } = event.target;

    let tmpValues = { ...values };

    let changeObj = {};
    if (type === 'card')
      changeObj.request_description = value;
    else if (type === 'primary')
      changeObj.request_primary_color_info = value;
    else if (type === 'secondary')
      changeObj.request_secondary_color_info = value;
    else
      changeObj[type] = value;

    if (nr === 0) {
      tmpValues = { ...tmpValues, ...changeObj };
    } else {
      const findObjIdx = tmpValues.card_request_info ? tmpValues.card_request_info.findIndex(cardInfo => cardInfo.level === nr) : -1;

      if (findObjIdx !== -1)
        tmpValues.card_request_info[findObjIdx] = { ...tmpValues.card_request_info[findObjIdx], ...changeObj };
      else {
        if (!tmpValues.card_request_info)
          tmpValues.card_request_info = [];

        changeObj.level = nr;

        tmpValues.card_request_info.push(changeObj);
      }
    }

    setValues(tmpValues);
  };

  return (
    <div className={classes.container}>
      {rangeArray(0, 5)
      .map(nr => {
        let cardValue = '', primaryColor = '', secondaryColor = '';
        if (nr === 0) {
          if (values.request_description)
            cardValue = values.request_description;
          if (values.request_primary_color_info)
            primaryColor = values.request_primary_color_info;
          if (values.request_secondary_color_info)
            secondaryColor = values.request_secondary_color_info;
        } else if (nr > 0 && values.card_request_info) {
          const findObj = values.card_request_info.find(cardInfo => cardInfo.level === nr);

          if (findObj && findObj.request_description)
            cardValue = findObj.request_description;
        }

        return (
          <div
            style={{
              marginBottom: 20,
              paddingBottom: 20,
              ...(nr > 0 ? {
                ...Helpers.fillRowCross,
                ...Helpers.mainSpaceBetween,
              } : ''),
            }}
            key={`WishKey${nr}`}
          >
            {nr > 0 &&
            <div className={classes.cardPlaceHolder}>
              <div className={classes.innerLvl}>
                {t('lvl')}<br />
                {zeroPad(nr)}
              </div>
            </div>
            }
            <div style={{ flex: 1 }}>
              <div style={{ textTransform: 'uppercase' }}>
                {nr === 0 ?
                  <>
                    {t('check_designer_form_all_cards')}
                    <span style={{ color: Colors.primary }}>*</span>
                  </>
                  :
                  t('check_designer_form_lvl_card', { count: nr })
                }
              </div>
              <TextField
                multiline
                rows={4}
                variant="outlined"
                style={{ width: '100%' }}
                value={cardValue}
                onChange={(event) => handleValue(event, nr, 'card')}
              />
              {nr === 0 &&
              <>
                <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainEnd, marginTop: 15 }}>
                  <div style={Helpers.fillRowCenter}>
                    <div style={{ marginRight: 5, textTransform: 'uppercase' }}>
                      {t('check_designer_form_primary_color')}
                      <span style={{ color: Colors.primary }}>*</span>
                    </div>
                    <ColorSelection
                      color={primaryColor}
                      handleColor={(color) => handleValue({ target: { value: color } }, nr, 'primary')}
                    />
                  </div>
                  <div style={{ ...Helpers.fillRowCenter, marginLeft: 10 }}>
                    <div style={{ marginRight: 5, textTransform: 'uppercase' }}>
                      {t('check_designer_form_secondary_color')}
                      <span style={{ color: Colors.primary }}>*</span>
                    </div>
                    <ColorSelection
                      color={secondaryColor}
                      handleColor={(color) => handleValue({ target: { value: color } }, nr, 'secondary')}
                    />
                  </div>
                </div>
                <div style={Helpers.fillRowCross}>
                  <ReactFlagsSelect
                    countries={['US', 'DE']}
                    customLabels={{
                      'US': t('english'),
                      'DE': t('german'),
                    }}
                    className={classes.flagSelect}
                    onSelect={(code) => handleValue({ target: { value: code } }, nr, 'language')}
                    placeholder={t('select_language_placeholder')}
                  />
                </div>
              </>
              }
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CardToolServicesDesignerFormDetails;