import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cjson from 'compressed-json';
import { useQuery } from '@apollo/react-hooks';

import { areCardsOwnedByViewerByIds } from '../../apollo/queries';

import CustomTooltip from '../Tooltip/CustomTooltip';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import { deepEqual } from '../../utils/helper';
import { resetCardsInShop, setOrderCart, toggleShoppingCart } from '../../redux/actions';
import CardShopIcon from '../Icon/Duotone/CardShopIcon';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
  badge: {
    top: -11,
    right: -5,
    '& .MuiBadge-badge': {
      backgroundColor: '#E12138',
    },
  },
  addedItem: {
    animationName: '$shakeUp',
    animationDuration: '0.5s',
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease',
  },
  '@keyframes shakeUp': {
    '0%': {
      opacity: 0,
      top: -5,
    },
    '50%': {
      top: -21,
    },
    '100%': {
      opacity: 1,
      top: -11,
    },
  },
}));

const CardShopButton = (props) => {
  const { t } = useTranslation();
  // const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { cardShop } = props;
  const [checked, setChecked] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const items = (cardShop.newCards ? cardShop.newCards.length : 0) +
    (cardShop.cards ? cardShop.cards.length : 0) +
    (cardShop.static ? cardShop.static.length : 0) +
    (cardShop.albums ? cardShop.albums.length : 0);

  // Checks if the user still has all the cards available that he has put in the shopping cart
  const redeemedCards = cardShop && cardShop.cards && [].concat.apply([], cardShop.cards.map(card => card.rCards));
  const { data } = useQuery(areCardsOwnedByViewerByIds, {
    variables: { ids: redeemedCards },
    fetchPolicy: useCacheWithExpiration('areCardsOwnedByViewerByIds'),
    skip: redeemedCards && redeemedCards.length === 0,
  });
  const checkedRedeemedCards = data && data.areCardsOwnedByViewerByIds;

  if (!checked && (redeemedCards && redeemedCards.length > 0) && checkedRedeemedCards) {
    if (checkedRedeemedCards.length !== redeemedCards.length) {
      if (checkedRedeemedCards.length === 0) {
        localStorage.removeItem('cardOrder');
        props.resetCardsInShop();
      } else {
        const newCards = cardShop.cards.map(card => {
          const intersection = card.rCards.filter(element => checkedRedeemedCards.includes(element));
          return {
            ...card,
            count: intersection.length,
            rCards: intersection,
          };
        })
        .filter(card => card.count > 0);
        props.setOrderCart(cardShop.newCards, newCards, cardShop.static, cardShop.albums);
      }
      setChecked(true);
    } else {
      setChecked(true);
    }
  }

  useEffect(() => {
    try {
      const { cards, newCards, albums, isSet } = cardShop;

      const newCardOrderStore = localStorage.getItem('newCardOrder');
      const cardOrderStore = localStorage.getItem('cardOrder');
      const staticStore = localStorage.getItem('staticOrder');
      const albumStore = localStorage.getItem('albumOrder');
      const compressedNewCardsOrder = cjson.compress.toString(newCards);
      const compressedCardsOrder = cjson.compress.toString(cards);
      const compressedStaticStore = cjson.compress.toString(cardShop.static);
      const compressedAlbumsStore = cjson.compress.toString(albums);
      if (!deepEqual(cjson.decompress.fromString(newCardOrderStore), compressedNewCardsOrder) && isSet)
        localStorage.setItem('newCardOrder', compressedNewCardsOrder);
      if (!deepEqual(cjson.decompress.fromString(cardOrderStore), compressedCardsOrder) && isSet)
        localStorage.setItem('cardOrder', compressedCardsOrder);
      if (!deepEqual(cjson.decompress.fromString(staticStore), compressedStaticStore) && isSet)
        localStorage.setItem('staticOrder', compressedStaticStore);
      if (!deepEqual(cjson.decompress.fromString(albumStore), compressedAlbumsStore) && isSet)
        localStorage.setItem('albumOrder', compressedAlbumsStore);

      if ((cardOrderStore || newCardOrderStore || albumStore) && !isSet) {
        const jsonNewCardOrder = cjson.decompress.fromString(newCardOrderStore);
        const jsonCardOrder = cjson.decompress.fromString(cardOrderStore);
        const jsonStaticOrder = cjson.decompress.fromString(staticStore);
        const jsonAlbumsOrder = cjson.decompress.fromString(albumStore);
        if ((jsonCardOrder && jsonCardOrder.length > 0 && cards.length === 0) || (jsonNewCardOrder && jsonNewCardOrder.length > 0 && newCards.length === 0) || (jsonAlbumsOrder && jsonAlbumsOrder.length > 0 && albums.length === 0))
          props.setOrderCart(jsonNewCardOrder, jsonCardOrder, jsonStaticOrder, jsonAlbumsOrder);
      }
    } catch (e) {
      resetLocalStorage();
    }
  }, [cardShop]);

  const resetLocalStorage = () => {
    localStorage.removeItem('newCardOrder');
    localStorage.removeItem('cardOrder');
    localStorage.removeItem('staticOrder');
    localStorage.removeItem('albumOrder');
    props.resetCardsInShop();
  };

  useEffect(() => {
    if (items > 0) {
      setShowAnimation(true);
      setTimeout(() => setShowAnimation(false), 500);
    }
  }, [items]);

  return (
    <>
      <CustomTooltip title={t('shopping_cart')}>
        {/*<IconButton onClick={() => enqueueSnackbar(t('card_shop_temporarily_closed'), { variant: 'error' })} id="cart-button">*/}
        <IconButton onClick={() => props.toggleShoppingCart()} id="cart-button">
          <CardShopIcon />

          <Badge
            // badgeContent={0}
            badgeContent={items}
            max={99}
            color="secondary"
            showZero
            className={[classes.badge, showAnimation ? classes.addedItem : ''].join(' ')} 
          />
        </IconButton>
      </CustomTooltip>
    </>
  );
};

const mapStateToProps = (state) => {
  const { cardShop } = state;
  return { cardShop };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setOrderCart,
    resetCardsInShop,
    toggleShoppingCart,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(CardShopButton);