import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';

import { getIllustrationStats } from '../../apollo/queries';

import Loading from '../Loading/Loading';
import Helpers from '../../theme/Helpers';
import CompletedJobsDiagram from './CompletedJobsDiagram';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

const useStyles = makeStyles(() => ({
  container: {
    minHeight: 500,
    backgroundColor: '#1B1B1B',
  },
  itemBox: {
    margin: '0 20px',
    border: '1px solid #323232',
    backgroundColor: '#121212',
    borderRadius: 4,
    padding: '50px 100px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
}));

const StatisticJobs = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data, loading } = useQuery(getIllustrationStats, {
    fetchPolicy: useCacheWithExpiration('getIllustrationStats'),
  });

  const statsData = (data) && data.getIllustrationStats;

  const stats = [
    {
      key: 'completed_jobs',
      value: statsData ? statsData.completed_jobs : '',
    },
    {
      key: 'open_jobs',
      value: statsData ? statsData.open_jobs : '',
      max: 5,
    },
    {
      key: 'avg_delivery_time',
      value: statsData ? statsData.avg_delivery_time : '',
      type: 'hours',
    },
  ];

  return (
    <div className={classes.container}>
      {loading ?
        <Loading />
        :
        <div style={{ padding: 50 }}>
          <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceAround }}>
            {stats.map((stat, idx) => (
              <div key={`StatKey${idx}`} className={classes.itemBox}>
                <div style={{
                  paddingBottom: 5,
                  borderBottom: '1px solid #838383',
                  ...Helpers.textCenter,
                  fontSize: 24,
                }}>
                  {stat.value}
                  {(stat.max && stat.value <= stat.max) && `/${stat.max}`}
                  {stat.type && ` ${stat.type}`}
                </div>
                <div
                  style={{
                    color: '#838383',
                    marginTop: 5,
                    ...Helpers.textCenter,
                    fontSize: 13,
                  }}>
                  {t(stat.key)}
                </div>
              </div>
            ))}
          </div>
          <div>
            <CompletedJobsDiagram statsData={statsData && statsData.daily_jobs} />
          </div>
        </div>
      }
    </div>
  );
};

export default StatisticJobs;