import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import SimpleReactLightbox from 'simple-react-lightbox';

import { getMyIllustrationJobs, getPossibleIllustrationJobs } from '../../apollo/queries';
import {
  updateIllustrationState as updateIllustrationStateMutation,
  toggleIllustrationJobVIP as toggleIllustrationJobVIPMutation,
} from '../../apollo/mutations';

import Loading from '../Loading/Loading';
import Helpers from '../../theme/Helpers';
import Alert from '../Alert/Alert';
import OpenIllustratorJob from './OpenIllustratorJob';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

const useStyles = makeStyles(() => ({
  container: {
    minHeight: 500,
    backgroundColor: '#080808',
  },
  userArea: {
    ...Helpers.fillRowCross,
    padding: 10,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#393C43',
    },
  },
  selectedUser: {
    backgroundColor: '#393C43',
  },
  avatar: {
    border: 'none',
    marginRight: 5,
  },
  jobBody: {
    backgroundColor: '#080808',
    '& > div:nth-of-type(odd)': {
      backgroundColor: '#1B1B1B',
    },
    '& > div:nth-of-type(even)': {
      backgroundColor: '#121212',
    },
  },
}));

const OpenIllustratorJobs = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { myViewer } = props;

  const { data, loading } = useQuery(getPossibleIllustrationJobs, {
    fetchPolicy: useCacheWithExpiration('getPossibleIllustrationJobs'),
  });

  const [updateIllustrationState] = useMutation(updateIllustrationStateMutation, {
    onCompleted: (data) => {
      if (data.updateIllustrationState === -1)
        enqueueSnackbar('Zu viele Aufträge aktiv', { variant: 'error' });
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [
      { query: getPossibleIllustrationJobs },
      { query: getMyIllustrationJobs },
    ],
  });

  const [toggleIllustrationJobVIP] = useMutation(toggleIllustrationJobVIPMutation, {
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{ query: getPossibleIllustrationJobs }],
  });

  const acceptJob = (jobID) => {
    updateIllustrationState({
      variables: {
        id: jobID,
        status: 'in_progress',
      },
    });
  };

  const designJobs = (data) && data.getPossibleIllustrationJobs;

  return (
    <div className={classes.container}>
      {loading ?
        <Loading />
        :
        !designJobs || (designJobs && designJobs.length === 0) ?
          <Alert>{t('no_open_design_jobs')}</Alert>
          :
          <div className={classes.jobBody}>
            {designJobs && designJobs.map((job, index) => (
              <SimpleReactLightbox key={`OpenJobKey${job._id}`}>
                <OpenIllustratorJob
                  job={job}
                  myViewer={myViewer}
                  acceptJob={() => acceptJob(job._id)}
                  canAccept/*={index === 0}*/
                  toggleIllustrationJobVIP={toggleIllustrationJobVIP}
                />
              </SimpleReactLightbox>
            ))}
          </div>
      }
    </div>
  );
};

export default OpenIllustratorJobs;