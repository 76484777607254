import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

import Colors from '../../theme/Colors';
import Loading from '../Loading/Loading';
import GroupedInventoryAllCards from './GroupedInventoryAllCards';
import GroupedInventoryBooster from './GroupedInventoryBooster';
import StreamerInventoryHeader from './StreamerInventoryHeader';
import Helpers from '../../theme/Helpers';

const useStyles = makeStyles((theme) => ({
  gridArea: {
    display: 'grid',
    gap: 8,
    gridTemplateColumns: 'repeat(5, 1fr)',

    [theme.breakpoints.down('md')]: {
      ...Helpers.fillColCenter,
    },
  }
}));

const StreamerInventory = (props) => {
  const classes = useStyles();
  const { t, progressData, cardsData, loading, streamer, rarities, myViewer } = props;

  const now = moment();

  return (
    <div style={{ color: Colors.white, position: 'relative', overflow: 'hidden' }}>
      <StreamerInventoryHeader
        t={t}
        streamer={streamer}
        myViewer={myViewer}
      />

      <div className="inner-wrapper" style={{ margin: '32px auto' }}>
        {loading ?
          <Loading />
          :
          <div className={classes.gridArea}>
            <GroupedInventoryAllCards
              data={cardsData}
              streamer={streamer}
              rarities={rarities}
              isSmaller
            />
            {progressData && progressData.map(boosterData => (
              <GroupedInventoryBooster
                boosterData={boosterData}
                streamer={streamer}
                key={`GroupedInventoryBoosterKey${boosterData.booster._id}`}
                now={now}
              />
            ))}
          </div>
        }
      </div>
    </div>
  );
};

export default StreamerInventory;