import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { useParams, withRouter } from 'react-router-dom';

import { getRedeemedCardShopObjectsByMatch } from '../../apollo/mergedQueries';

import ErrorMessage from '../../components/ErrorBoundary/ErrorMessage';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import CardShopMatch from '../../components/CardShop/CardShopMatch';

function CardShopMatchContainer() {
  const { t } = useTranslation();
  const [page, setPage] = useState(2);
  const [isLegacy, setIsLegacy] = useState(false);
  const [isSeasonal, setIsSeasonal] = useState(false);
  const [showPurchasedCards, setShowPurchasedCards] = useState(false);
  const [sorting, setSorting] = useState({
    field: 'created_at',
    order: 'DESC',
  });
  const [quality, setQuality] = useState({
    min: 0,
    max: 100,
  });
  const [rarity, setRarity] = useState([]);
  const { id } = useParams();
  const perPage = 25;

  const variables = {
    pagination: { page: 1, perPage },
    match: id,
    is_legacy: isLegacy,
    is_seasonal: isSeasonal,
    hide_bought: !showPurchasedCards,
    sorting,
    rarity,
    min_quality: quality.min,
    max_quality: quality.max,
  };
  const { data, loading, error, fetchMore } = useQuery(getRedeemedCardShopObjectsByMatch, {
    variables,
    fetchPolicy: useCacheWithExpiration('getRedeemedCardShopObjectsByMatch', variables),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const type = isLegacy ? 'legacyCards' : 'redeemedCardObjects';
  const matches = data && data.getRedeemedCardShopObjectsByMatch;
  const streamer = data && data.streamer;
  const offer = matches && matches.offer;

  const { cards, count } = matches && matches[type] ? matches[type] : {};

  if (error) return <ErrorMessage error={error} />;

  return (
    <>
      <PageTitle title={t('card_shop')} />
      <CardShopMatch
        loading={loading}
        cards={cards && cards}
        totalCount={matches && count}
        streamer={streamer}
        offer={offer}
        setIsLegacy={setIsLegacy}
        isLegacy={isLegacy}
        setSorting={setSorting}
        sorting={sorting}
        setRarity={setRarity}
        rarity={rarity}
        setQuality={setQuality}
        quality={quality}
        setShowPurchasedCards={setShowPurchasedCards}
        showPurchasedCards={showPurchasedCards}
        setIsSeasonal={setIsSeasonal}
        isSeasonal={isSeasonal}
        onLoadMore={async () => {
          setPage(page + 1);
          try {
            await fetchMore({
              variables: { pagination: { perPage, page } },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                const obj = {
                  ...fetchMoreResult.getRedeemedCardShopObjectsByMatch,
                };
                obj[type].cards = [...prev.getRedeemedCardShopObjectsByMatch[type].cards, ...fetchMoreResult.getRedeemedCardShopObjectsByMatch[type].cards];

                return { getRedeemedCardShopObjectsByMatch: obj };
              },
            });
          } catch {
          }
        }}
      />
    </>
  );
}

export default withRouter(CardShopMatchContainer);