import React from 'react';

const CooperationAgreementDE = () => {
  return (
    <>
      <h1 dir="ltr">
        1. GELTUNGSBEREICH UND DEFINITIONEN
      </h1>

      <p dir="ltr">
        1.1 Diese Zusatzvereinbarung ("<b>VEREINBARUNG</b>") regelt das Vertragsverhältnis für die
        Kooperation zwischen der Streamheroes GmbH, Triererstraße 5, 66839 Schmelz, Deutschland,
        eingetragen im Handelsregister des Handelsgerichts Saarbrücken unter HRB 106787, Tel.: +43
        650 4989 426, E-Mail: <a href="mailto:support@streamheroes.gg">support@streamheroes.gg</a>,
        UID-Nr.: DE 335845977 ("<b>STREAMHEROES</b>") und Kooperationspartnern, die am
        Partnerprogramm der STREAMHEROES im Zusammenhang mit der Online-Plattform, wie sie unter der
        Website <a href="https://www.streamheroes.gg">https://www.streamheroes.gg</a> und über
        mobile Anwendungen gehostet wird ("<b>PLATTFORM</b>"), teilnehmen möchten (dieses
        Partnerprogramm nachfolgend die "<b>KOOPERATION</b>" und solche Kooperationspartner
        "<b>PARTNER</b>").
      </p>

      <p dir="ltr">
        1.2 Allfällige Geschäftsbedingungen des PARTNERS gelten nur, wenn sie im Einzelfall auf
        Basis
        einer individuellen Vereinbarung von STREAMHEROES anerkannt werden.
      </p>

      <p dir="ltr">
        1.3 Klarstellend wird darauf hingewiesen, dass für die Nutzung der PLATTFORM, in Ergänzung
        zu
        dieser VEREINBARUNG, die allgemeinen Nutzungsbedingungen gelten, wie sie unter <a
        href="https://app.streamheroes.gg/page/terms-of-use">https://app.streamheroes.gg/page/terms-of-use</a> in
        der jeweils geltenden Fassung zur Verfügung stehen und die der PARTNER bei der erstmaligen
        Anmeldung auf der PLATTFORM zu akzeptieren hat ("<b>NUTZUNGSBEDINGUNGEN</b>"). Bei
        Widersprüchen zwischen dieser VEREINBARUNG und den NUTZUNGSBEDINGUNGEN haben die
        Bestimmungen dieser VEREINBARUNG Vorrang.
      </p>

      <p dir="ltr">
        1.4 Sofern in dieser VEREINBARUNG auf einen "<i>Abschnitt</i>" verwiesen wird, bezieht sich
        ein solcher Verweis auf den entsprechenden Abschnitt in dieser VEREINBARUNG.
      </p>

      <h1 dir="ltr">
        2. BESCHREIBUNG DER KOOPERATION
      </h1>

      <p dir="ltr">
        2.1 STREAMHEROES bietet im Rahmen der PLATTFORM seinen Nutzern die Möglichkeit, virtuelle
        Energie ("<i>Coins</i>") zu sammeln, indem die Nutzer der PLATTFORM Streamern auf Twitch.tv
        ("<b>TWITCH</b>") zusehen. Für diese Energie können auf der PLATTFORM virtuelle Karten
        erworben werden. Eine genauere Leistungsbeschreibung findet sich in den NUTZUNGSBEDINGUNGEN
        sowie unter <a
        href="https://app.streamheroes.gg/page/faq">https://app.streamheroes.gg/page/faq</a>.
      </p>

      <p dir="ltr">
        2.2 TWITCH wiederum sieht für Personen, die auf TWITCH streamen, die Möglichkeit vor dem
        "<i>Twitch Affiliate Program</i>" beizutreten, wobei der Beitritt und die Teilnahme zu
        diesem den Bestimmungen des "<i>Twitch Affiliate Agreement</i>", wie es unter <a
        href="https://www.twitch.tv/p/de-de/legal/affiliate-agreement/">https://www.twitch.tv/p/de-de/legal/affiliate-agreement/</a
      > eingesehen werden kann ("<b>AFFILIATE AGREEMENT</b>"), unterliegt.
      </p>

      <p dir="ltr">
        2.3 STREAMHEROES bietet PARTNERN die Möglichkeit, auf der PLATTFORM virtuelle Karten zu
        entwerfen, die anschließend von den Nutzern der PLATTFORM gegen Bezahlung der – für das
        Zuschauen erworbene – virtuellen Energie gesammelt werden können. Ein Nutzer kann dann jene
        virtuellen Karten, die er auf der PLATTFORM gesammelt hat, im Webshop, wie er auf der
        PLATTFORM von STREAMHEROES zur Verfügung gestellt wird ("<b>WEBSHOP</b>"), auf Wunsch in
        physischer Form anfertigen lassen und erwerben. STREAMHEROES übernimmt in diesem
        Zusammenhang den Verkauf, die Erstellung und Abwicklung des Erwerbs der physischen Karten.
        Jede auf diese Weise angefertigte physische Karte verfügt über einen QR-Code, der direkt mit
        der gesammelten virtuellen Karte verknüpft ist und daher einzigartig ist.
      </p>

      <p dir="ltr">
        2.4 Im Rahmen der KOOPERATION räumt der PARTNER STREAMHEROES das Recht ein, die vom PARTNER
        entworfenen, virtuellen Karten (solche Karten nachfolgend "<b>VIRTUELLE KARTEN</b>") zu
        vermarkten und auf Basis dieser VIRTUELLEN KARTEN physische Karten auf Nutzerwunsch zu
        erstellen ("<b>PHYSISCHE KARTEN</b>") und an die Nutzer der PLATTFORM zu verkaufen. Im
        Gegenzug wird STREAMHEROES den PARTNER an den Umsätzen, der durch den Verkauf von PHYSISCHEN
        KARTEN erzielt wird beteiligen. Die Auszahlung der Umsätze erfolgt dabei über den
        Zahlungsanbieter "<i>Stripe</i>" ("<b>STRIPE</b>") über das Tool "<i>stripe connect</i>",
        wie es unter <a href="https://stripe.com/at/connect">https://stripe.com/at/connect</a> näher
        beschrieben wird ("<b>STRIPE CONNECT</b>").
      </p>

      <h1 dir="ltr">
        3. BEDINGUNGEN FÜR DIE KOOPERATION, ZUSTANDEKOMMEN DER VEREINBARUNG
      </h1>

      <p dir="ltr">
        3.1 Der PARTNER hat vorab eine Registrierung bei STRIPE-CONNECT vorzunehmen und dabei wahre
        und
        vollständige Angaben zu machen ("<b>REGISTRIERUNGSPROZESS</b>"). STREAMHEROES wird bei einer
        positiven Überprüfung der Angaben des PARTNERS von STRIPE benachrichtigt. Nach Erhalt einer
        solcher Benachrichtigung durch STRIPE lässt STREAMHEROES dem PARTNER eine entsprechende
        Notifikation über die bekanntgegebene E-Mail-Adresse zukommen, womit STREAMHEROES das
        Angebot auf Abschluss dieser VEREINBARUNG annimmt. Es liegt im alleinigen Ermessen von
        STREAMHEROES Angebote von PARTNERN auf Abschluss dieser VEREINBARUNG (selbst nach einer
        positiven Einschätzung von STRIPE) abzulehnen oder anzunehmen.
      </p>

      <p dir="ltr">
        3.2 Insbesondere müssen beim PARTNER folgende Bedingungen vor einer Annahme des Angebots
        gemäß Abschnitt 3.1 vorliegen:
        <ol type="a">
          <li>
            der PARTNER hat alle Bestimmungen des AFFILIATE AGREEMENTS akzeptiert und erfüllt, ist "<i>Affiliate</i>"
            im Sinne der AFFILIATE AGREEMENTS und partizipiert am "<i>Twitch Affiliate Program</i>"
            und es liegen derzeit keine Gründe oder Verfahren vor, die zu einer Beendigung der
            Teilnahme an dem "<i>Twitch Affiliate Program</i>" führen würden;
          </li>
          <li>
            der PARTNER hat sich gemäß Abschnitt 6.3 dieser VEREINBARUNG bei STRIPE erfolgreich
            registriert und alle Vorgaben, insbesondere in Verbindung mit Geldwäsche-
            und <i>Know-Your-Customer</i>-Pflichten, erfüllt;
          </li>
          <li>
            sofern der PARTNER eine natürliche Person ist, hat er das achtzehnte Lebensjahr
            vollendet;
          </li>
          <li>
            illegale, unerwünschte oder unzulässige Mitteilungen verbreiten, wie insbesondere
            unzulässige Werbung, Spam-Mails oder Kettenbriefe;
          </li>
          <li>
            sofern für die Ausübung der Tätigkeit des PARTNERS im Rahmen der KOOPERATION etwaige
            Gewerbeberechtigungen, Lizenzen oder sonstige behördliche Genehmigungen notwendig sind,
            hat der PARTNER diese erlangt; sowie
          </li>
          <li>
            der PARTNER hat die NUTZUNGSBEDINGUNGEN gelesen und diese akzeptiert;
          </li>
        </ol>
      </p>

      <p dir="ltr">
        3.3 Der PARTNER wird STREAMHEROES während der Dauer dieser VEREINBARUNG laufend über etwaige
        Änderungen in den Angaben, die er im REGISTRIERUNGSPROZESS gemacht hat, informieren. Weiters
        hat er STREAMHEROES umgehend zu benachrichtigen, sofern Grund zur Annahme besteht, dass eine
        Bedingung gemäß Abschnitt 3.2 nicht mehr vorliegt.
      </p>

      <p dir="ltr">
        3.4 Klarstellend wird festgehalten, dass diese VEREINBARUNG unter der Annahme steht, dass
        der
        PARTNER eine auf Dauer angelegte, selbständige wirtschaftliche Tätigkeit verfolgt und damit
        Unternehmer ist im Sinne des österreichischen Rechts.
      </p>

      <h1 dir="ltr">
        4. LIZENZEN AN DEN VOM PARTNER GENERIERTEN INHALTEN
      </h1>

      <p dir="ltr">
        4.1 Der PARTNER räumt STREAMHEROES eine unbefristete, weltweite, ausschließliche,
        entgeltliche,
        unterlizenzierbare und übertragbare Lizenz zur Nutzung, Reproduktion, Verbreitung,
        Erstellung abgeleiteter Werke und kommerziellen Verwertung an allen Informationen, Daten und
        Inhalten im Zusammenhang mit den VIRTUELLEN KARTEN ein, wie insbesondere, aber nicht
        ausschließlich, das Recht (i) die VIRTUELLEN KARTEN als Vorlage für die Erstellung von
        PHYSISCHEN KARTEN zu verwenden sowie (ii) den Nutzern der PLATTFORM das Sammeln und Erwerben
        solcher VIRTUELLEN KARTEN im Rahmen der Nutzung der PLATTFORM zu ermöglichen.
      </p>

      <p dir="ltr">
        4.2 STREAMHEROES ist während der Dauer dieser VEREINBARUNG berechtigt, den Namen, sowie
        etwaige
        Marken oder sonstige Kennzeichnungsmerkmale des PARTNERS für den Zweck der Förderung,
        Verbreitung und kommerziellen Verwertung der VIRTUELLEN KARTEN zu verwenden, wie
        insbesondere solche Namen, Marken oder sonstige Kennzeichnungsmerkmale auf der PLATTFORM
        anzuzeigen.
      </p>

      <h1 dir="ltr">
        5. RECHTE UND PFLICHTEN DES PARTNERS
      </h1>

      <p dir="ltr">
        5.1 Der PARTNER hat das auf die Dauer der VEREINBARUNG befristete, nicht-exklusive,
        unentgeltliche, nicht unterlizenzierbare und nicht übertragbare Recht VIRTUELLE KARTEN auf
        der PLATTFORM zu erstellen sowie diese auf der PLATTFORM zu präsentieren.
      </p>

      <p dir="ltr">
        5.2 Für die Erstellung der VIRTUELLEN KARTEN ist der PARTNER allein verantwortlich;
        insbesondere
        trifft STREAMHEROES keine Pflicht die Rechtmäßigkeit der Inhalte, die Teil solcher
        VIRTUELLEN KARTEN sind, zu kontrollieren. Der Nutzer hat unter anderem darauf zu achten,
        dass die VIRTUELLEN KARTEN keine Rechte Dritter, wie insbesondere Urheberrechte oder
        sonstige gewerbliche Schutzrechte, verletzen und der PARTNER gewährleistet, dass er zur
        Nutzung aller von ihm verwendeten Inhalte (wie, unter anderem, Bilder, Grafiken oder
        Schriftarten) im notwendigen Umfang berechtigt ist.
      </p>

      <p dir="ltr">
        5.3 STREAMHEROES behält sich das Recht vor, VIRTUELLE KARTEN mit sofortiger Wirkung von der
        PLATTFORM zu löschen, sofern STREAMHEROES Kenntnis davon erhält, dass die Inhalte, die Teil
        solcher VIRTUELLEN KARTEN sind, unrechtmäßig oder anstößig sind, die Rechte Dritter
        verletzen oder gegen die NUTZUNGSBEDINGUNGEN verstoßen.
      </p>

      <p dir="ltr">
        5.4 Der PARTNER wird jede Handlung unterlassen und in keine Situation verwickelt werden, die
        STREAMHEROES in der Öffentlichkeit in Verruf bringt, Misskredit oder negative
        Medienberichterstattung hervorruft, oder sonst das Ansehen von STREAMHEROES schadet.5.4 Der
        PARTNER wird jede Handlung unterlassen und in keine Situation verwickelt werden, die
        STREAMHEROES in der Öffentlichkeit in Verruf bringt, Misskredit oder negative
        Medienberichterstattung hervorruft, oder sonst das Ansehen von STREAMHEROES schadet.
      </p>

      <p dir="ltr">
        5.5 Der PARTNER wird STREAMHEROES hinsichtlich aller Ansprüche schad- und klaglos halten,
        die
        <ol type="a">
          <li>
            auf einer Verletzung eines wirksamen gewerblichen Schutzrechts oder Urheberrechts einer
            dritten Person durch den PARTNER im Zusammenhang mit der Erstellung der VIRTUELLEN
            KARTEN beruhen;
          </li>
          <li>
            darauf beruhen, dass der PARTNER die Bedingungen und Vorgaben gemäß Abschnitt 3.2 und
            3.3 nicht eingehalten hat bzw unwahre Angaben über das Vorliegen oder das Bestehen
            dieser Bedingungen gemacht hat; sowie
          </li>
          <li>
            aus einer Verletzung von Abschnitt 5.4 resultieren.
          </li>
        </ol>
      </p>

      <h1 dir="ltr">
        6. UMSATZBETEILIGUNG, ZAHLUNGSBEDINGUNGEN
      </h1>

      <p dir="ltr">
        6.1 Für die gemäß Abschnitt 4.1 eingeräumte Lizenz, erhält der PARTNER monatlich eine
        Beteiligung am UMSATZ aller PHYSISCHEN KARTEN in Höhe von 40 %.
      </p>

      <p>
        "<b>UMSATZ</b>" meint alle Umsätze, die STREAMHEROES von Nutzern für den Verkauf von
        PHYSISCHEN KARTEN über den WEBSHOP erhält, abzüglich (i) Rechnungs- und Zahlungsgebühren
        sowie sämtliche anderen Kosten die mit der Bezahlung in Verbindung stehen, (ii) Steuern, die
        in Hinblick auf den UMSATZ geleistet werden müssen wie etwa Mehrwertsteuern sowie (iii)
        Ausgaben für Rückgaben, Rückerstattungen, Rückbuchungen Rabatte oder sonstige Gutschriften
        in Zusammenhang mit dem Verkauf der PHYSISCHEN KARTEN.
      </p>

      <p dir="ltr">
        6.2 STREAMHEROES wird die Umsatzbeteiligung gemäß Abschnitt 6.1 für ein jeweiliges
        Kalendermonat
        via STRIPE CONNECT binnen 45 Tagen nach Ende dieses Kalendermonats in Euro an den PARTNER
        ausbezahlen, allerdings nur sofern die für das jeweilige Kalendermonat zu zahlende
        Umsatzbeteiligung mehr als EUR 50 beträgt ("<b>BETEILIGUNGSSCHWELLE</b>"). Sofern die
        BETEILIGUNGSSCHWELLE in einem (oder mehreren aufeinanderfolgenden) Kalendermonaten nicht
        erreicht wird, wird STREAMHEROES die Umsatzbeteiligung so lange auf den nächstfolgenden
        Kalendermonat vortragen, bis die Summe der vorgetragenen Umsatzbeteiligung gemeinsam mit der
        Umsatzbeteiligung in einem Kalendermonat insgesamt die BETEILIGUNGSSCHWELLE erreicht und die
        gesamte Umsatzbeteiligung anschließend an den PARTNER auszahlen.
      </p>

      <p dir="ltr">
        6.3 Der PARTNER nimmt zur Kenntnis, dass eine Auszahlung der Umsatzbeteiligung gemäß
        Abschnitt
        6.2 nur über STRIPE CONNECT stattfinden kann und der PARTNER für eine Auszahlung sich bei
        STRIPE registrieren muss. In diesem Zusammenhang nimmt der PARTNER zustimmend zur Kenntnis,
        dass für die Nutzung von STRIPE CONNECT separate Nutzungsbedingungen gelten können, auf die
        STREAMHEROES keinerlei Einfluss hat. Es obliegt allein dem PARTNER alle mit der Nutzung von
        STRIPE CONNECT verbundenen Bestimmungen, zu akzeptieren, einzuhalten und alle Vorgaben, wie
        insbesondere die im Rahmen der Registrierung auf STRIPE CONNECT einzuhaltenden Geldwäsche-
        und Know-Your-Customer-Checks, zu erfüllen.
      </p>

      <h1 dir="ltr">
        7. DAUER UND KÜNDIGUNG
      </h1>

      <p dir="ltr">
        7.1 Diese VEREINBARUNG wird auf unbestimmte Dauer geschlossen. Beide Parteien haben das
        Recht, die VEREINBARUNG jederzeit unter Einhaltung einer Kündigungsfrist von einem Monat
        schriftlich zu kündigen.
      </p>

      <p dir="ltr">
        7.2 Darüber hinaus ist jede Partei berechtigt, die VEREINBARUNG aus wichtigem Grund
        jederzeit mit sofortiger Wirkung aufzulösen, sofern eine weitere Zusammenarbeit für die
        Partei unzumutbar sein sollte. Als wichtiger Grund gilt insbesondere, aber nicht
        ausschließlich, ein Verstoß gegen die NUTZUNGSBEDINGUNGEN, oder eine Verletzung der
        Pflichten gemäß Abschnitt 3, 5.2 und 5.4.
      </p>

      <p dir="ltr">
        7.3 Diese VEREINBARUNG kann weiters von STREAMHEROES jederzeit aufgelöst werden, sofern die
        unter Abschnitt 3.2 genannten Bedingungen nicht mehr zutreffen.
      </p>

      <p dir="ltr">
        7.4 Bei Beendigung dieser VEREINBARUNG, aus welchem Grund auch immer
        <ol type="a">
          <li>
            werden die Parteien alle Unterlagen, Bücher, Aufzeichnungen, Korrespondenzen, Papiere,
            vertraulichen Informationen und anderen Informationen sonstiges Eigentum der anderen
            Partei welches sich im Besitz, in der Macht, im Gewahrsam oder in der Kontrolle der
            anderen Partei befindet, an die andere Partei zurückstellen (und diese nicht behalten,
            rekonstruieren oder an jemand anderen übergeben);
          </li>
          <li>
            wird STREAMHEROES, vorbehaltlich des nächsten Satzes, die VIRTUELLEN KARTEN sowie alle
            Namen, etwaige Marken oder sonstige Kennzeichnungsmerkmale des PARTNERS von der
            PLATTFORM entfernen und es den Nutzern der PLATTFORM nicht mehr ermöglichen, PHYSISCHE
            KARTEN zu erwerben. Bereits erworbene VIRTUELLE KARTEN, die von den Nutzern gesammelt
            wurden, bleiben auf der PLATTFORM bestehen, sind jedoch nurmehr eingeschränkt abrufbar.
          </li>
        </ol>
      </p>

      <h1 dir="ltr">
        8. HAFTUNG
      </h1>

      <p dir="ltr">
        8.1 Vorbehaltlich Abschnitt 8.2 und soweit gesetzlich zulässig, ist STREAMHEROES Haftung
        aufgrund leichter Fahrlässigkeit generell ausgeschlossen. STREAMHEROES haftet ausschließlich
        für Schäden, die auf vorsätzlichem oder grob fahrlässigem Verhalten von STREAMHEROES
        basieren.
      </p>

      <p dir="ltr">
        8.2 Die in Abschnitt 8.1 festgelegten Haftungsbeschränkungen gelten nicht für Schäden aus
        Verletzung des Lebens, des Körpers oder der Gesundheit an Personen sowie für den Fall, dass
        anders lautende zwingende gesetzliche Regelungen entgegenstehen.
      </p>

      <h1 dir="ltr">
        9. DATENSCHUTZ
      </h1>

      <p dir="ltr">
        9.1 STREAMHEROES ist verpflichtet, die Bestimmungen des Datenschutzgesetzes (DSG), der
        Datenschutz-Grundverordnung (DSGVO) sowie allfällige weitere gesetzliche
        Geheimhaltungsverpflichtungen einzuhalten.
      </p>

      <p dir="ltr">
        9.2 Ferner verarbeitet STREAMHEROES zum Zweck der Erfüllung dieser VEREINBARUNG die dafür
        erforderlichen personenbezogenen Daten. Die detaillierten datenschutzrechtlichen
        Informationen (Datenschutzmitteilung) gemäß Art 13 ff DSGVO ist unter <a
        href="https://www.streamheroes.gg/privacy">https://www.streamheroes.gg/privacy</a
      > verfügbar.
      </p>

      <h1 dir="ltr">
        10. VERTRAULICHKEIT
      </h1>

      <p dir="ltr">
        10.1 Die Parteien werden (i) den Inhalt der VEREINBARUNG und (ii) sämtliche Informationen,
        die sie im Zusammenhang mit den Verhandlungen und dem Abschluss der VEREINBARUNG erhalten
        haben, streng vertraulich behandeln, soweit diese Dokumente und Informationen nicht
        öffentlich bekannt sind oder ohne Verletzung dieser Vertraulichkeitsverpflichtung erlangt
        wurden, oder deren Offenlegung gesetzlich vorgeschrieben ist. Die Parteien sind berechtigt,
        vorstehende Informationen an aktuelle und künftige Gesellschafter, verbundene Unternehmen,
        deren/seine Organe und Mitarbeiter sowie Berater weiterzugeben, soweit diese eine übliche
        Geheimhaltungsvereinbarung abgeschlossen haben.
      </p>

      <p dir="ltr">
        10.2 Öffentliche Mitteilungen über diese VEREINBARUNG, dessen Zustandekommen und Vollzug,
        insbesondere gegenüber Medien, sind im Vorhinein zwischen den Parteien abzustimmen.
      </p>

      <h1 dir="ltr">
        11. MASSGEBLICHE MITTEILUNGEN
      </h1>

      <p dir="ltr">
        11.1 Alle Mitteilungen in Bezug auf die VEREINBARUNG bedürfen der Schriftform und sind an
        die im REGISTRIERUNGSPROZESS bekanntgegebenen Anschriften oder E-Mail-Adressen zu
        übermitteln (oder andere, von den Parteien akzeptierte, Kommunikationskanäle), sofern nicht
        nach zwingendem Recht eine andere Form erforderlich ist. E-Mails gelten als schriftliche
        Mitteilung.
      </p>

      <p dir="ltr">
        11.2 Jede Partei ist verpflichtet, der anderen Partei Änderungen ihrer Kontaktdaten
        mitzuteilen. Ansonsten gelten Mitteilungen an die letzte bekannte Anschrift bzw
        E-Mail-Adresse als wirksam übermittelt.
      </p>

      <h1 dir="ltr">
        12. SCHLUSSBESTIMMUNGEN
      </h1>

      <p dir="ltr">
        12.1 Wenn eine Bestimmung dieser VEREINBARUNG unwirksam ist oder wird oder diese
        VEREINBARUNG eine Lücke enthält, berührt dies nicht die Gültigkeit der übrigen Bestimmungen.
        Anstelle der unwirksamen Bestimmung gilt zwischen den Parteien eine wirksame Bestimmung als
        vereinbart, die der wirtschaftlichen Absicht der Parteien am nächsten kommt. Dies gilt auch
        für allfällige Lücken.
      </p>

      <p dir="ltr">
        12.2 STREAMHEROES und der PARTNER sind unabhängige Parteien. Keine Bestimmung in dieser
        VEREINBARUNG ist so auszulegen, dass eine Partei Bevollmächtigter, Mitarbeiter,
        Franchisenehmer, Joint-Venture-Partner oder gesetzlicher Vertreter der anderen Partei wird.
      </p>

      <p dir="ltr">
        12.3 Die Nichtdurchsetzung einer Bestimmung dieser VEREINBARUNG gilt nicht als Verzicht auf
        die künftige Durchsetzung dieser oder einer anderen Bestimmung der VEREINBARUNG.
      </p>

      <p dir="ltr">
        12.4 Diese VEREINBARUNG und die gesamten Rechtsbeziehungen und Streitigkeiten zwischen dem
        PARTNER und STREAMHEROES unterliegen ausschließlich österreichischem Recht mit Ausnahme der
        Verweisungsnormen und des UN-Kaufrechts. Gerichtsstand für alle Streitigkeiten aus oder in
        Zusammenhang mit dieser VEREINBARUNG ist das sachlich zuständige Gericht in Steyr,
        Österreich.
      </p>

      <p dir="ltr">
        12.5 Sofern diese VEREINBARUNG in mehrere Sprachen übersetzt werden sollte, ist die deutsche
        Version die maßgebende Version.
      </p>

      <p dir="ltr">
        12.6 Diese VEREINBARUNG gilt für den PARTNER persönlich und darf ohne vorherige schriftliche
        Zustimmung von STREAMHEROES aus keinem wie auch immer gearteten Grund (einschließlich einer
        Übertragung von Gesetzes wegen, aufgrund einer Verschmelzung, Umgründung oder infolge eines
        Erwerbs oder eines Eigentümerwechsels) abgetreten oder übertragen werden und jeder Verstoß
        gegen diese Bestimmung berechtigt STREAMHEROES, den VERTRAG aus wichtigem Grund mit
        sofortiger Wirkung zu beenden. STREAMHEROES behält sich ausdrücklich vor, die Rechte und
        Pflichten aus der VEREINBARUNG an verbundene Unternehmen im Sinne des § 189a UGB abzutreten
        und zu übertragen.
      </p>
    </>
  );
};

export default CooperationAgreementDE;