import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import { getMatchData } from '../../utils/helper';
import QualityLine from '../QualityLine/QualityLine';
import SVGIcon from '../SVGIcon/SVGIcon';
import { setMarketplaceAllData } from '../../redux/actions';

import conf from '../../conf.json';

const useStyles = makeStyles((theme) => ({
  item: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    padding: '14px 12px 15px 16px',
    cursor: 'pointer',
    transition: 'all 0.3s ease-out',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.08)',
    },
    '& > div:first-child, & > div:last-child': {
      ...Helpers.fillRowCross,
    },

    [theme.breakpoints.down('md')]: {
      ...Helpers.fillCol,

      '& > div:first-child, & > div:last-child': {
        ...Helpers.fillCol,
      },
    },
  },
  avatar: {
    width: 36,
    height: 36,
    border: 'none',
  },
}));

const MarketplaceHomeListItem = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { item, isSold } = props;

  const {
    avatar,
    name,
  } = getMatchData(item.offer_match_model_meta_data.card_template_metadata ? { match: item.offer_match_model_meta_data.card_template_metadata.match_meta_data } : {}, 36, 36);

  const openBuyDialog = () => {
    props.setMarketplaceAllData({
      offerId: item._id,
      frontHash: item.offer_match_model_meta_data.front_hash,
      price: item.price,
      avgPrice: item.avg_price,
    });
  };

  return (
    <div className={classes.item} onClick={openBuyDialog}>
      <div>
        <img
          src={`${item.offer_match_model_meta_data.front_layers[0].img_url}?height=67`}
          alt="Card"
          style={{ borderRadius: 2, marginRight: 30 }}
        />
        <div>
          {isSold &&
          <div style={{ color: Colors.halfWhite, fontSize: 12, marginBottom: 6 }}>
            {t('buyer')}:
          </div>
          }
          <div style={{ ...Helpers.fillRowCross, fontSize: 14, fontWeight: 500 }}>
            <Avatar
              className={classes.avatar}
              alt="Avatar"
              src={item.buyer ? item.buyer.profile_image_url : avatar}
              style={{ marginRight: 6 }}
            />
            {item.buyer ? item.buyer.display_name : name}
          </div>
        </div>
      </div>
      {isSold &&
      <div>
        <div style={{ color: Colors.halfWhite, fontSize: 12, marginBottom: 6 }}>
          {t('seller')}:
        </div>
        <div style={{ ...Helpers.fillRowCross, fontSize: 14, fontWeight: 500 }}>
          <Avatar
            className={classes.avatar}
            alt="Avatar"
            src={item.viewer.profile_image_url}
            style={{ marginRight: 6 }}
          />
          {item.viewer.display_name}
        </div>
      </div>
      }
      <div>
        {isSold ?
          <></>
          :
          <div style={{ width: 236 }}>
            <QualityLine quality={item.offer_match_model_meta_data.quality} />
          </div>
        }
        <div style={{ marginLeft: 27 }}>
          <div style={{ fontSize: 10, color: Colors.halfWhite }}>
            &Oslash; {t('x_gems', { count: item.avg_price })}
          </div>
          <div style={{ ...Helpers.fillRowCross, fontSize: 14, fontWeight: 500, margin: '6px 0' }}>
            <SVGIcon
              src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
              height={19}
              width={14}
              style={{ marginRight: 4 }}
            />
            {t('x_gems', { count: item.price })}
          </div>
          <div style={{ fontSize: 10, color: Colors.halfWhite }}>
            {moment.unix((isSold ? item.sold_at : item.created_at) / 1000)
            .fromNow()}
          </div>
        </div>
        <i
          className="fa-light fa-chevron-right"
          style={{ fontSize: 16, marginLeft: 14 }}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setMarketplaceAllData,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(MarketplaceHomeListItem);