import React from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { getMySeason } from '../../apollo/queries';

import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import ProfileRankedItem from './ProfileRankedItem';

const ProfileOverviewRanks = (props) => {
  const { viewer, limit = null } = props;

  const { data, loading } = useQuery(getMySeason, {
    variables: { viewer: viewer._id },
    fetchPolicy: useCacheWithExpiration('getMySeason', { viewer: viewer._id }),
  });

  let seasonData = data && data.getMySeason;

  if (loading || (seasonData && seasonData.length === 0))
    return null;

  if (limit)
    seasonData = seasonData.slice(0, limit)

  return (
    <div style={{ display: 'grid', gap: 6 }}>
      {seasonData && seasonData.map(data => (
        <ProfileRankedItem 
          key={`ProfileRankedItemKey${data.streamer._id}`} 
          data={data} 
          compact
          onClick={() => props.history.push(`/profile/${data.streamer.login}`)}  
        />
      ))}
    </div>
  );
};

export default withRouter(ProfileOverviewRanks);