import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Helpers from '../../theme/Helpers';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';
import Colors from '../../theme/Colors';
import CustomTooltip from '../Tooltip/CustomTooltip';
import { toggleRightSidebar } from '../../redux/actions';
import conf from '../../conf';

const useStyles = makeStyles(() => ({
  expandArea: {
    position: 'absolute',
    bottom: '1.5rem',
    zIndex: 100,
    height: 114,
    width: 18,
    cursor: 'pointer',
    transition: 'right, left 0.3s ease-out',
    color: Colors.halfWhite,
    right: '100%'
  },

  svg: {
    transform: 'scaleX(-1)'
  },

  svgInner: {
    fill: '#0D1D2E'
  },

  arrowWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center' 
  }
}));

const ToggleRightSidebar = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { extra } = props;

  useEffect(() => {
    if (extra.expandedRightSidebar === null) {
      const isExpanded = JSON.parse(localStorage.getItem(conf.storage_keys.right_sidebar_expanded));
    
      props.toggleRightSidebar(isExpanded !== null ? isExpanded : true);
    }
  }, [extra.expandedRightSidebar]);

  const setIsExpanded = () => {
    localStorage.setItem(conf.storage_keys.right_sidebar_expanded, JSON.stringify(!extra.expandedRightSidebar));
    props.toggleRightSidebar();
  };

  const isExpanded = extra.expandedRightSidebar;

  return (
    <CustomBackdropFilter
      className={[classes.expandArea, isExpanded ? 'sidebar-expanded' : ''].join(' ')}
      canvasFallback={true}
      html2canvasOpts={{
        allowTaint: true,
      }}
    >
      <CustomTooltip title={t(isExpanded ? 'collapse_sidebar' : 'expand_sidebar')} placement="right">
        <div
          style={{ height: '100%', width: '100%', ...Helpers.fillColCenter }}
          onClick={setIsExpanded}
        >
          <svg 
            className={classes.svg}
            width="18px" 
            height="114px" 
            viewBox="0 0 18 114"
          >
            <g transform="translate(0.000000, 0.631500)">
              <path d="M0,112.422 L0,112.422 L0,0 C0,10.008 3.92773084,16.6176 8.08099934,23.6136 C10.7738885,27.8869444 13.0311656,32.3199202 14.8317867,36.8712 C17.013501,42.8946755 18.0789116,49.1318282 17.9954512,55.392 C18.0559401,61.783097 16.9898183,68.1502152 14.8271839,74.3136 C13.0042085,79.1264037 10.7451591,83.831514 8.06719091,88.3932 C3.98910163,95.76 0.127344398,102.7104 0,112.422 Z" fill="#010F25"></path>
              <path className={classes.svgInner} d="M0,103.0535 L0,103.0535 L0,9.3685 C0,17.7085 3.27310903,23.2165 6.73416612,29.0465 C8.97824039,32.6076203 10.8593046,36.3017668 12.3598223,40.0945 C14.1779175,45.1140629 15.0657597,50.3116901 14.9962093,55.5285 C15.0466167,60.8544141 14.1581819,66.160346 12.3559866,71.2965 C10.8368405,75.3071697 8.95429922,79.228095 6.72265909,83.0295 C3.32425136,89.1685 0.106120332,94.9605 0,103.0535 Z" fill="#0D1D2E"></path>
            </g>
          </svg>

          <div className={classes.arrowWrapper}>
            <i
              className={`fa-light fa-chevron-${isExpanded ? 'right' : 'left'}`}
              style={{ fontSize: 13, marginLeft: 'auto' }}
            />
          </div>
        </div>
      </CustomTooltip>
    </CustomBackdropFilter>
  );
};

const mapStateToProps = (state) => {
  const { extra } = state;
  return { extra };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    toggleRightSidebar,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ToggleRightSidebar));