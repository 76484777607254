import React from 'react';
import { useTranslation } from 'react-i18next';

import AccordionArea from '../Accordion/AccordionArea';
import { rangeArray } from '../../utils/helper';

const FAQ = () => {
  const { t } = useTranslation();

  const faqs = {
    'general': 5,
    'profile': 5,
    'energycollection': 8,
    'cards': 10,
    'streamercards': 10,
    'purchasing': 7,
    'miscellaneous': 9,
  };

  return (
    Object.entries(faqs)
    .map(entry => {
      const [key, value] = entry;

      return (
        <>
          <div style={{ fontFamily: 'Bebas Neue', fontSize: 26, marginTop: 10 }} key={key}>
            {t(`faq_head_${key}`)}
          </div>
          {rangeArray(1, value)
          .map(faq =>
            <AccordionArea
              keq={`FAQKey#${key}#${faq}`}
              title={t(`faq_${key}_title_` + faq)}
              text={t(`faq_${key}_text_` + faq)}
            />,
          )}
        </>
      );
    })
  );
};

export default FAQ;