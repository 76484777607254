import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import Avatar from '@material-ui/core/Avatar';

import { purchaseCard as purchaseCardMutation } from '../../apollo/mutations';
import { cardCollectionProgressByCard } from '../../apollo/queries';

import Helpers from '../../theme/Helpers';
import PagePopup from '../Dialog/PagePopup';
import AnimationButton from '../Button/AnimationButton';
import Colors from '../../theme/Colors';
import ClosePopupButton from '../Button/ClosePopupButton';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';
import SVGIcon from '../SVGIcon/SVGIcon';
import { getCondition, getLevelWord, onKeyPress } from '../../utils/helper';
import GemsSlider from '../Gems/GemsSlider';

import conf from '../../conf.json';

const useStyles = makeStyles({
  container: {
    maxWidth: 562,
    margin: '50vh auto 0',
    transform: 'translate(0, -50%)',
    position: 'relative',
    color: Colors.white,
    borderRadius: 4,
    background: '#142134',
  },
  bgArea: {
    background: 'rgba(255, 255, 255, 0.08)',
    padding: '20px 32px',
    ...Helpers.fillRowCross,
    position: 'relative',
    marginTop: 3,
  },
  avatar: {
    width: 66,
    height: 66,
    border: 'none',
    marginRight: 10,
  },
  textWeight: {
    fontSize: 18,
    '& > span': {
      fontWeight: 600,
    },
  },
  table: {
    margin: '11px 0',
    width: '100%',
    '& td': {
      fontSize: 14,
      padding: '11px 0',
      '& div': {
        ...Helpers.fillRowCross,
      },
    },
    '& i': {
      fontSize: 22,
      marginRight: 8,
    },
  },
  rowLine: {
    margin: '16px 0',
    ...Helpers.fillRowCross,
    '& > div': {
      background: 'rgba(255, 255, 255, 0.04)',
    },
  },
});

const GemUnlockDialog = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const minGems = 100;
  const { t, onClose, streamer, cardCollectionProgress, myViewer, booster, cardId } = props;
  const [gems, setGems] = useState(minGems);
  const [isAnonymous, setIsAnonymous] = useState(false);

  const [purchaseCard, { loading }] = useMutation(purchaseCardMutation, {
    onCompleted: () => {
      onClose();
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [
      {
        query: cardCollectionProgressByCard,
        variables: {
          id: cardId,
          boosterId: booster._id,
        },
      },
    ],
  });

  const purchaseTheCard = () => {
    purchaseCard({
      variables: {
        cardTemplateId: cardCollectionProgress.card._id,
        rarity: cardCollectionProgress.card.rarity,
        quality: cardCollectionProgress.progress.quality,
        gems,
        anonymous: isAnonymous,
      },
    });
  };

  const onInput = (e) => {
    let value = parseInt(e.currentTarget.textContent);
    if (value < minGems)
      value = minGems;
    setGems(value);
  };
  const levelWord = getLevelWord(null, cardCollectionProgress.card.rarity);
  const levelColor = Colors.level[levelWord];
  const condition = getCondition(cardCollectionProgress.progress.quality);
  const conditionColors = {};
  for (let i = 0; i <= 4; i++) {
    conditionColors[t(`condition_level.${i}`)
    .toLowerCase()] = Colors.quality[i * 25];
  }

  return (
    <PagePopup>
      <div className={classes.container}>

        <div style={{
          position: 'relative',
          background: 'rgba(255, 255, 255, 0.04)',
          padding: '16.5px 32px',
        }}>
          <div style={{ fontSize: 24, fontWeight: 600 }}>
            {t('gem_unlock')}
          </div>

          <ClosePopupButton handleClose={onClose}
                            style={{ right: 32, top: 16, width: 30, height: 30 }} />
        </div>

        <CustomBackdropFilter
          className={classes.bgArea}
          filter={'blur(8px)'}
          canvasFallback={true}
          html2canvasOpts={{
            allowTaint: true,
          }}
        >
          <div style={{
            ...Helpers.insetZero,
            backgroundImage: `url("${streamer.profile_image_url}")`,
            mixBlendMode: 'luminosity',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            zIndex: -1,
            opacity: 0.04,
            filter: 'blur(8px)',
          }} />

          <div style={Helpers.fillRowCross}>
            <Avatar
              className={classes.avatar}
              alt="Avatar"
              src={streamer.profile_image_url}
            />
            <div className={classes.textWeight}>
              {t('support_streamer_x_with_gems', { streamer: streamer.display_name })}
            </div>
          </div>

        </CustomBackdropFilter>

        <div
          style={{ padding: '16.5px 33.5px', borderBottom: '1px solid rgba(255, 255, 255, 0.08)' }}
        >
          <div style={{ fontWeight: 500 }}>
            {t('unlock_overview')}
          </div>
          <div style={{ ...Helpers.fillRowCross, marginTop: 10 }}>
            <div style={{ marginRight: 12 }}>
              <img src={`${cardCollectionProgress.card.card_layer_image}?height=45`} alt="Card" />
            </div>
            <div>
              <div style={{ color: Colors.halfWhite, ...Helpers.fillRowCross }}>
                <div style={{ ...Helpers.fillRowCross }}>
                  <img
                    src={`${conf.cdn_url}/badges/card-level/${cardCollectionProgress.card.rarity !== null ? cardCollectionProgress.card.rarity : 5}.png?height=19`}
                    alt="Level badge"
                    style={{ marginRight: 2 }}
                  />
                  <div style={{
                    color: levelColor,
                    lineHeight: 1,
                    fontWeight: 500,
                  }}>
                    {t(cardCollectionProgress.card.rarity !== null ? `rareness_level.${cardCollectionProgress.card.rarity}` : 'legacy')}
                  </div>
                </div>
                {cardCollectionProgress.progress.quality > -1 &&
                <>
                  <div style={{ margin: '0 6px' }}>-</div>

                  <div style={{
                    color: conditionColors[t(`condition_level.${condition}`)
                    .toLowerCase()],
                    fontWeight: 500,
                  }}>
                    {t(`condition_level.${condition}`)}
                  </div>

                  <div style={{ margin: '0 6px' }}>-</div>

                  <div style={{ color: Colors.white }}>
                    {cardCollectionProgress.progress.quality}%
                  </div>
                </>
                }
              </div>
              <div style={{
                ...Helpers.fillRowCross,
                color: Colors.halfWhite,
                fontSize: 14,
                marginTop: 2,
              }}>
                {t('minimum_of')}
                <SVGIcon
                  src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
                  height={16}
                  width={12}
                  style={{ margin: '0 4px' }}
                />
                {t('x_gems_are_needed', { count: minGems })}
              </div>
            </div>
          </div>
        </div>

        <div style={{ padding: '22px 33.5px 0' }}>
          <div style={{ fontWeight: 500 }}>{t('the_benefits')}:</div>

          <table className={classes.table}>
            <tr>
              <td>
                <div>
                  <i className="fa-thin fa-circle-dollar" />
                  {t('you_support_the_streamer')}
                </div>
              </td>
              <td>
                <div>
                  <i className="fa-thin fa-rectangle-vertical" />
                  {t('you_unlock_the_card')}
                </div>
              </td>
            </tr>
            <tr>
              <td style={{ color: 'red' }} colSpan={2}>
                <div>
                  <i className="fa-thin fa-cart-circle-xmark" />
                  {t('card_not_tradeable_on_market')}
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div style={{ background: 'rgba(255, 255, 255, 0.04)', padding: '16px 32px' }}>
          <div>
            {t('select_own_amount')}:
          </div>

          <div className={classes.rowLine}>
            <div style={{
              minWidth: 46,
              height: 46, ...Helpers.fillColCenter,
              borderRadius: '2px 0px 0px 2px',
            }}>
              <SVGIcon
                src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
                height={22}
                width={16}
              />
            </div>
            <div
              style={{
                margin: '0 3px',
                fontSize: 14,
                fontWeight: 500,
                height: 46,
                minWidth: 106,
                ...Helpers.fillRowCenter,
              }}
            >
              <div
                id="gems-value"
                contentEditable
                onKeyPress={onKeyPress}
                onInput={onInput}
                suppressContentEditableWarning
              >
                {gems}
              </div>
              <div style={{ marginLeft: 3 }}>
                {t('gems')}
              </div>
            </div>
            <div style={{
              borderRadius: '0px 2px 2px 0px',
              width: 'calc(100% - 18px)',
              padding: '10px 8px 6px 10px',
            }}>
              <div style={Helpers.fillRowCross}>
                <div style={{ width: 40, height: 4, background: '#9399A2', borderRadius: 6 }} />
                <GemsSlider value={gems} setValue={(value) => setGems(value)} min={minGems}
                            max={myViewer.premium_currency > 100 ? myViewer.premium_currency : 100} />
              </div>
              <div style={{ color: Colors.halfWhite, fontSize: 10 }}>
                {t('min_x_gems', { count: minGems })}
              </div>
            </div>
          </div>

          {/*<FormControlLabel
            control={
              <Checkbox
                checked={isAnonymous}
                onChange={(event) => setIsAnonymous(event.target.checked)}
                style={{ color: Colors.halfWhite, padding: '0 9px' }}
              />
            }
            label={t('do_not_display_my_name')}
            style={{ color: Colors.halfWhite }}
          />*/}

          <AnimationButton
            status="neonPink"
            style={{
              padding: 10.32,
              width: 'calc(100% - 20.64px)',
              fontWeight: 500,
              marginTop: 16,
            }}
            disabled={gems > myViewer.premium_currency || loading}
            onClick={purchaseTheCard}
          >
            {loading ?
              <i className="fa-light fa-loader fa-spin" style={{ fontSize: 18 }} />
              :
              <div style={{ ...Helpers.fillRowCenter }}>
                {t('get_the_card_for')}
                <SVGIcon
                  src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
                  height={25}
                  width={19}
                  style={{ margin: '0 8px' }}
                />
                {t('x_gems', { count: gems })}
              </div>
            }
          </AnimationButton>
        </div>

        <div style={{
          background: 'rgba(225, 33, 56, 0.3)', ...Helpers.fillRowCenter,
          padding: 12,
          width: 'calc(100% - 24px)',
          fontSize: 14,
        }}>
          <i className="fa-solid fa-circle-info"
             style={{ color: '#E12138', fontSize: 22, marginRight: 8 }} />
          {t('gem_card_has_no_influence')}
        </div>

      </div>
    </PagePopup>
  );
};

export default GemUnlockDialog;
