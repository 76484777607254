import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';

import { getMyViewerAndStreamer } from '../../apollo/mergedQueries';

import PageTitle from '../../components/PageTitle/PageTitle';
import Loading from '../../components/Loading/Loading';
import ErrorMessage from '../../components/ErrorBoundary/ErrorMessage';
import StreamerSettingsComponent from '../../components/Streamer/StreamerSettings';

const StreamerSettingsContainer = () => {
  const { t } = useTranslation();

  const { data, loading, error } = useQuery(getMyViewerAndStreamer);

  if (error)
    return <ErrorMessage error={error} />;

  const viewer = (data) && data.getMyViewer;
  const streamer = (data) && data.getMyStreamer;

  return (
    <>
      <PageTitle title={t('streamer_settings')} />
        {loading ?
          <Loading />
          :
          <StreamerSettingsComponent viewer={viewer} streamer={streamer} />
        }
    </>
  );
};

export default StreamerSettingsContainer;