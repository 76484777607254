import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { getMySeasonBorders } from '../../apollo/queries';

import BorderSelectionDialog from '../../components/Profile/BorderSelectionDialog';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

const BorderSelectionContainer = ({ onClose, currentBorder, viewer }) => {
  const { data } = useQuery(getMySeasonBorders, {
    fetchPolicy: useCacheWithExpiration('getMySeasonBorders'),
  });

  const seasonBorders = (data) && data.getMySeasonBorders;

  return (
    <BorderSelectionDialog
      seasonBorders={seasonBorders}
      onClose={onClose}
      currentBorder={currentBorder}
      viewer={viewer}
    />
  );
};

export default BorderSelectionContainer;
