import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';

import { marketPlaceOffers } from '../../apollo/mergedQueries';

import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import InventoryMatchMarketplace from '../../components/Marketplace/InventoryMatchMarketplace';

const InventoryMatchMarketplaceContainer = (props) => {
  const { collectorsView } = props;
  const [page, setPage] = useState(2);
  const [isSeasonal, setIsSeasonal] = useState(false);
  const [sorting, setSorting] = useState({
    field: 'created_at',
    order: 'DESC',
  });
  const [quality, setQuality] = useState({
    min: 0,
    max: 100,
  });
  const [rarity, setRarity] = useState([]);
  const [match, setMatch] = useState(null);
  const perPage = 25;

  useEffect(() => {
    if (collectorsView.marketplace && (collectorsView.marketplace.quality !== quality.min || match !== collectorsView.marketplace.match)) {
      setQuality({
        min: collectorsView.marketplace.quality,
        max: collectorsView.marketplace.quality,
      });
      setRarity([collectorsView.marketplace.rarity]);
      setMatch(collectorsView.marketplace.match);
    }
  }, [collectorsView.marketplace, rarity, match, quality.min]);

  const variables = {
    pagination: { page: 1, perPage },
    match,
    is_seasonal: isSeasonal,
    sorting,
    rarity,
    min_quality: quality.min,
    max_quality: quality.max,
  };

  const { data, loading, fetchMore } = useQuery(marketPlaceOffers, {
    variables,
    fetchPolicy: useCacheWithExpiration('marketPlaceOffers', variables),
    skip: !collectorsView.marketplace,
  });

  const items = data && data.marketPlaceOffers;
  const myViewer = data && data.getMyViewer;

  return (
    <InventoryMatchMarketplace
      loading={loading}
      offers={items && items.offers}
      setSorting={setSorting}
      sorting={sorting}
      setRarity={setRarity}
      rarity={rarity}
      setQuality={setQuality}
      quality={quality}
      setIsSeasonal={setIsSeasonal}
      isSeasonal={isSeasonal}
      totalCount={items && items.count}
      myViewer={myViewer}
      onLoadMore={async () => {
        setPage(page + 1);
        try {
          await fetchMore({
            variables: { ...variables, ...{ pagination: { perPage, page } } },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              return {
                marketPlaceOffers: {
                  offers: [...prev.marketPlaceOffers.offers, ...fetchMoreResult.marketPlaceOffers.offers],
                  __typename: prev.marketPlaceOffers.__typename,
                },
              };
            },
          });
        } catch {
        }
      }}
    />
  );
};

const mapStateToProps = (state) => {
  const { collectorsView } = state;
  return { collectorsView };
};

export default connect(mapStateToProps)(InventoryMatchMarketplaceContainer);