import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import {
  putCardsInDeck as putCardsInDeckMutation,
  removeCardsFromDeck as removeCardsFromDeckMutation,
} from '../../apollo/mutations';
import { getRedeemedCardObjects } from '../../apollo/mergedQueries';

import Helpers from '../../theme/Helpers';
import {
  increaseCount,
  resetStorageBoxData, setMarketplaceAllData,
  setStorageBoxSelectionMode,
  toggleStorageBoxSelectionCard,
} from '../../redux/actions';
import Colors from '../../theme/Colors';
import {
  addCardsToSpecificStorageBox,
  removeCardsFromSpecificStorageBox,
  updateStorageBoxes,
} from '../../utils/cacheWritings';
import InventoryStorageBoxSubMenu from './InventoryStorageBoxSubMenu';
import { preventEvent } from '../../utils/helper';

const useStyles = makeStyles({
  menu: {
    position: 'absolute',
    bottom: -2,
    left: -2,
    right: -2,
    zIndex: 100,
    background: '#142134',
    borderRadius: '0px 0px 6px 6px',
  },
  subMenu: {
    position: 'absolute',
    bottom: 0,
    left: 'calc(100% + 2px)',
    background: '#142134',
    borderRadius: 6,
    width: 252,
    zIndex: 100,
  },
  menuItem: {
    width: 'calc(100% - 24px)',
    padding: 12,
    fontSize: 14,
    fontWeight: 500,
    position: 'relative',
    transition: 'all 0.3s ease-out',
    ...Helpers.textCenter,

    '&:last-child': {
      borderRadius: '0px 0px 6px 6px',
    },
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.04)',
    },
    '&.remove-item': {
      color: '#E12138',

      '&:hover': {
        color: Colors.white,
        background: '#E12138',
      },
    },
  },
});

const InventoryCardStorageBox = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const {
    t,
    card,
    cardDecks,
    openMenu,
    setOpenMenu,
    isCardForStorageBoxSelected,
    selectedBoxId,
    variables,
  } = props;
  const [openSubMenu, setOpenSubMenu] = useState(false);

  useEffect(() => {
    if (!openMenu && openSubMenu)
      setOpenSubMenu(false);
  }, [openMenu, openSubMenu]);

  const refetchQueries = [
    {
      query: getRedeemedCardObjects,
      variables: {
        pagination: {
          page: 1,
          perPage: 25,
        },
        min_quality: 0,
        max_quality: 100,
        rarity: [],
        sorting: {
          field: 'created_at',
          order: 'DESC',
        },
        match: null,
      },
    },
  ];

  const [putCardsInDeck, { loading: putLoading }] = useMutation(putCardsInDeckMutation, {
    update: (cache, { data }) => {
      const { putCardsInDeck } = data;
      if (putCardsInDeck) {
        updateStorageBoxes(cache, selectedBoxId, 1, increaseCount);
        const moveCards = removeCardsFromSpecificStorageBox(cache, [card._id], variables, increaseCount);
        addCardsToSpecificStorageBox(cache, moveCards, {
          ...variables,
          deck: putCardsInDeck._id,
        }, increaseCount);
      }
    },
    onCompleted: () => {
      enqueueSnackbar(t('success'), { variant: 'success' });
      props.resetStorageBoxData(true);
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => refetchQueries,
  });

  const [removeCardsFromDeck, { loading: removeLoading }] = useMutation(removeCardsFromDeckMutation, {
    update: (cache, { data }) => {
      const { removeCardsFromDeck } = data;
      if (removeCardsFromDeck) {
        removeCardsFromSpecificStorageBox(cache, [card._id], variables, increaseCount);
        updateStorageBoxes(cache, selectedBoxId, -1, increaseCount);
      }
    },
    onCompleted: () => {
      enqueueSnackbar(t('success'), { variant: 'success' });
      props.resetStorageBoxData(true);
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => refetchQueries,
  });

  const moveCardToInventory = () => {
    setOpenMenu(false);
    removeCardsFromDeck({
      variables: {
        deck: selectedBoxId,
        card_ids: [card._id],
      },
    });
  };

  const moveCardToStorageBox = (deckId) => {
    setOpenSubMenu(false);
    putCardsInDeck({
      variables: {
        deck: deckId,
        card_ids: [card._id],
      },
    });
  };

  const selectCard = () => {
    if (!loading) {
      setOpenMenu(false);
      setOpenSubMenu(false);
      props.toggleStorageBoxSelectionCard(card._id, card);
      props.setStorageBoxSelectionMode(true);
    }
  };

  const openSellDialog = () => props.setMarketplaceAllData({
    cardId: card.card_template,
    quality: card.quality,
  });

  const loading = putLoading || removeLoading;

  return (
    <>
      {openMenu &&
      <div className={classes.menu} onClick={preventEvent}>
        <div className={classes.menuItem} onClick={openSellDialog}>
          {t('place_on_marketplace')}
        </div>
        <div className={classes.menuItem} onClick={selectCard}>
          {loading ?
            <i className="fa-light fa-loader fa-spin" style={{ fontSize: 18 }} />
            :
            t(isCardForStorageBoxSelected ? 'deselect_card' : 'select_card')
          }
        </div>
        <div
          className={classes.menuItem}
          onMouseEnter={() => loading ? null : setOpenSubMenu(!openSubMenu)}
        >
          {loading ?
            <i className="fa-light fa-loader fa-spin" style={{ fontSize: 18 }} />
            :
            t('move_in_storage')
          }
          <i className="fa-light fa-chevron-right"
             style={{ position: 'absolute', right: 12, top: 14, fontSize: 14 }} />
        </div>
        {selectedBoxId &&
        <div
          className={[classes.menuItem, 'remove-item'].join(' ')}
          onClick={() => loading ? null : moveCardToInventory()}
        >
          <i className={loading ? 'fa-light fa-loader fa-spin' : 'fa-regular fa-minus'}
             style={{ fontSize: 14, marginRight: 8 }} />
          {t('remove_card')}
        </div>
        }
      </div>
      }
      {openSubMenu &&
      <div className={classes.subMenu}
           onClick={preventEvent} onMouseLeave={() => setOpenSubMenu(false)}
      >
        <InventoryStorageBoxSubMenu
          cardDecks={cardDecks}
          selectedBoxId={selectedBoxId}
          loading={loading}
          moveCardToStorageBox={moveCardToStorageBox}
          isLeftSided
        />
      </div>
      }
    </>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setStorageBoxSelectionMode,
    toggleStorageBoxSelectionCard,
    resetStorageBoxData,
    increaseCount,
    setMarketplaceAllData,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(InventoryCardStorageBox);