import { HANDLE_OBJECT_VALUE_CHANGE, HANDLE_VALUE_CHANGE } from './constants';

const INITIAL_STATE = {
  forename: '',
  surname: '',
  email: '',
  street: '',
  country_state: '',
  house_number: '',
  postal_code: '',
  city: '',
  country: '',
  address_suffix: '',
  editMode: true,
  acceptWrongAddress: false,
  showCheckAddress: false,
};

const formValuesReducer = (state = INITIAL_STATE, action) => {
  if (action.type === HANDLE_VALUE_CHANGE) {
    const newState = { ...state };
    newState[action.name] = action.value;

    return { ...state, ...newState };
  } else if (action.type === HANDLE_OBJECT_VALUE_CHANGE) {
    return { ...state, ...action.value };
  } else {
    return state;
  }
};

export default formValuesReducer;