import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeStyles, MenuItem, TextField } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';

import { getDiscordChannels } from '../../apollo/queries';

import { setAlertToolTypeData } from '../../redux/actions';
import CreateAlertGroup from './CreateAlertGroup';
import Helpers from '../../theme/Helpers';
import CreateAlertSelect from './CreateAlertSelect';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import Loading from '../Loading/Loading';
import InsertTextVariables from './InsertTextVariables';

const useStyles = makeStyles(() => ({
  textInput: {
    background: 'transparent',
    padding: 0,
    borderRadius: 4,
    width: 'calc(100% - 24px)',
    marginTop: 6,
    fontSize: 14,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FFFFFF29',
      top: 0,
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FFFFFF29',
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingTop: 5,
    },
    '& .MuiOutlinedInput-inputMultiline': {
      maxHeight: 300,
      overflowY: 'auto !important',
    }
  },
  bottomArea: {
    marginTop: 10,
    ...Helpers.fillRowCross,
    '& > div': {
      background: 'rgba(255, 255, 255, 0.06)',
      borderRadius: 2,
      padding: '3.5px 6px',
      marginRight: 10,
      cursor: 'pointer',
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.16)',
      },
    },
  },
}));

const ConfigureAlertDiscordSettings = (props) => {
  const classes = useStyles();
  const { t, alertTool, setAlertToolTypeData } = props;
  let { discordSettings } = alertTool;

  const { data, loading } = useQuery(getDiscordChannels, {
    fetchPolicy: useCacheWithExpiration('getDiscordChannels'),
  });

  const discordChannels = data && data.getDiscordChannels;

  if (!discordSettings)
    discordSettings = {};

  const handleTextChange = (event) => {
    discordSettings.text = event.target.value;

    setAlertToolTypeData('discordSettings', discordSettings);
  };

  const insertVariable = (variable) => {
    discordSettings.text = (discordSettings && discordSettings.text ? discordSettings.text : '') + ` {${variable}}`;

    setAlertToolTypeData('discordSettings', discordSettings);
  };

  return (
    <>
      <CreateAlertGroup
        title={t('select_channel')}
        isRequired
        t={t}
        description={t('select_channel_desc')}
      >
        {loading ?
          <Loading />
          :
          <CreateAlertSelect
            value={discordSettings && discordSettings.channel ? discordSettings.channel : null}
            handleChange={(event) => {
              discordSettings.channel = event.target.value;

              setAlertToolTypeData('discordSettings', discordSettings);
            }}
            placeholder={t('select_channel')}
            id="select-channel"
          >
            {discordChannels && discordChannels.map(channel => (
              <MenuItem id={channel.id} value={channel.id}>{channel.name}</MenuItem>
            ))}
          </CreateAlertSelect>
        }
      </CreateAlertGroup>

      <CreateAlertGroup
        title={t('text_messages')}
        t={t}
        isRequired
        description={t('text_messages_description')}
      >
        <TextField
          id="text-messages"
          name="text-messages"
          value={discordSettings && discordSettings.text ? discordSettings.text : ''}
          onChange={handleTextChange}
          variant="outlined"
          multiline
          maxRows={4}
          classes={{
            root: classes.textInput,
          }}
        />
        <InsertTextVariables t={t} insertVariable={insertVariable} />
      </CreateAlertGroup>
    </>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setAlertToolTypeData,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(withRouter(ConfigureAlertDiscordSettings));