import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { getRedeemedCardById } from '../../apollo/queries';

import Loading from '../../components/Loading/Loading';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import MyOldCard from '../../components/MyCard/MyOldCard';

const MyOldCardContainer = ({ cardId }) => {
  const [match, setMatch] = useState(null);

  const { loading, data } = useQuery(getRedeemedCardById, {
    variables: { id: cardId },
    fetchPolicy: useCacheWithExpiration('getRedeemedCardById', { id: cardId }),
  });

  const card = (data) && data.getRedeemedCardById;

  if (!match && card)
    setMatch(card.match_card.match);

  return (
    loading ?
      <Loading />
      :
      <MyOldCard card={card} matchParams={match} />
  );
};

export default MyOldCardContainer;