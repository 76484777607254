import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import CustomButton from '../Button/CustomButton';
import CustomTooltip from '../Tooltip/CustomTooltip';

const OnBoardingStart = (props) => {
  const { t } = useTranslation();

  const { classes, viewer, nextStep, makeMeAStreamHero, updateMyData } = props;

  const startSetup = () => {
    makeMeAStreamHero();
    nextStep();
  };

  const skip = (isCanceled) => {
    if (isCanceled) {
      updateMyData({ variables: { update: { show_onboarding: false } } });
    } else {
      sessionStorage.setItem('on_boarding', 'set');
    }
    props.history.push('/hq/hub');
  };

  return (
    <>
      <Typography variant="h1" className={classes.header} style={{ marginBottom: 45 }}>
        {t('on_boarding_welcome_head')}
      </Typography>

      <Typography
        variant="body1"
        className={classes.text}
        style={{ color: Colors.white, fontWeight: 'bold' }}
      >
        {t('on_boarding_welcome_user', { username: viewer.display_name })}
      </Typography>
      <Typography variant="body1" className={classes.text}>
        {t('on_boarding_welcome_works')}
      </Typography>
      <Typography variant="body1" className={classes.text}>
        {t('on_boarding_welcome_info')}
      </Typography>
      <div style={{ ...Helpers.fillColCenter, ...{ marginTop: 30 } }}>
        <CustomButton status="primary" onClick={startSetup}>
          {t('on_boarding_welcome_start_setup')}
        </CustomButton>
      </div>

      <div
        style={{ ...Helpers.fillRowCross, ...Helpers.mainSpaceBetween, ...{ marginTop: 30 } }}
      >
        <CustomTooltip title={t('on_boarding_welcome_skip')} arrow>
          <span className={classes.chip} onClick={() => skip(false)}>
            {t('skip')}
          </span>
        </CustomTooltip>
        <CustomTooltip title={t('on_boarding_welcome_cancel')} arrow>
        <span className={classes.cancel} onClick={() => skip(true)}>
            {t('cancel')}
          </span>
        </CustomTooltip>
      </div>
    </>
  );
};

export default withRouter(OnBoardingStart);