import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';

import Colors from '../../theme/Colors';
import Loading from '../Loading/Loading';
import TopBG from '../TopViewHeader/TopBG';
import Drop from './Drop';
import Helpers from '../../theme/Helpers';

const useStyles = makeStyles({
  container: {
    display: 'grid',
    gap: 9,
    width: '100%',
    gridTemplateColumns: `repeat(5, 1fr)`,
  },
});

const Drops = (props) => {
  const classes = useStyles();
  const { loading, t, drops, hasMore, onLoadMore } = props;

  const now = moment();

  return (
    <TopBG
      style={{
        padding: '54px 0',
        backgroundSize: 'auto',
        color: Colors.white,
        overflow: 'hidden',
      }}
    >
      {loading ?
        <Loading />
        :
        <div>
          {drops && drops.length > 0 ?
            <InfiniteScroll
              dataLength={drops ? drops.length : 0}
              next={onLoadMore}
              hasMore={hasMore}
              loader={<Loading />}
              style={{ width: '100%', overflow: 'initial' }}
            >
              <div className={classes.container}>
                {drops && drops.map((drop, index) => (
                  <Drop
                    key={`AlertCapsule${drop._id}`}
                    t={t}
                    switchLastHover={(index + 1) % 5 === 0}
                    now={now}
                    drop={drop}
                  />
                ))}
              </div>
            </InfiniteScroll>
            :
            <div
              style={{ ...Helpers.textCenter, margin: '30px 0', fontSize: 20, fontWeight: 'bold' }}>
              {t('no_drops_available')}
            </div>
          }
        </div>
      }
    </TopBG>
  );
};

export default Drops;
