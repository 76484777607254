import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { fabric } from 'fabric';
import { Checkbox, FormControl, FormControlLabel, MenuItem, Select } from '@material-ui/core';
import { HuePicker, AlphaPicker, MaterialPicker } from 'react-color';
import Slider from '@material-ui/core/Slider';
import hexRgb from 'hex-rgb';
import { useTranslation } from 'react-i18next';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';

const CardToolFabricObjectFilters = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { canvas, checkIfObjectExists, activeUserKey, addActionStep } = props;

  const defaultBlendColorProps = {
    color: '#00ff56',
    mode: 'add',
    alpha: 1.0,
  };
  const [isSaturationChecked, setIsSaturationChecked] = useState(false);
  const [isBlendColorChecked, setIsBlendColorChecked] = useState(false);
  const [filtersDisabled, setFiltersDisabled] = useState(true);
  const [objectId, setObjectId] = useState('');
  const [saturation, setSaturation] = useState(0.0);
  const [blendColorProps, setBlendColorProps] = useState(defaultBlendColorProps);

  useEffect(() => {
    setIsSaturationChecked(false);
    setIsBlendColorChecked(false);
    setSaturation(0.0);

    if (activeUserKey !== null && activeUserKey < 3) {
      const newOId = activeUserKey === 2 ? 'user-logo' : `user-image-${activeUserKey}`;
      setObjectId(newOId);
      setFiltersDisabled(false);

      setBlendColorProps(defaultBlendColorProps);
      const obj = checkIfObjectExists(newOId);
      if (obj.filters)
        updateFilters(obj.filters);
    } else
      setFiltersDisabled(true);
  }, [activeUserKey]);

  const blendColorModes = [
    'add',
    'diff',
    'subtract',
    'multiply',
    'screen',
    'lighten',
    'darken',
    'overlay',
    'exclusion',
    'tint',
  ];

  useImperativeHandle(ref, () => ({
    updateFilters(filters) {
      if (filters)
        updateFilters(filters);
    },
    resetUserUploadedFilter() {
      setIsSaturationChecked(false);
      setIsBlendColorChecked(false);
    },
    resetLastStep(lastStep) {
      if (lastStep.type === 'setIsSaturation') {
        setIsSaturationChecked(lastStep.data.old);
        toggleFilter('Saturation', lastStep.data.old);
      } else if (lastStep.type === 'setIsBlendColorChecked') {
        setIsBlendColorChecked(lastStep.data.old);
        toggleFilter('BlendColor', lastStep.data.old);
      } else if (lastStep.type === 'updateSaturation') {
        setSaturation(lastStep.data.old);
        applyFilterValue('Saturation', 'saturation', lastStep.data.old);
      } else if (lastStep.type === 'updateBlendColor') {
        setBlendColorProps(lastStep.data.old);
        const { color, mode, alpha } = lastStep.data.old;
        applyFilterValue('BlendColor', 'color', color);
        applyFilterValue('BlendColor', 'mode', mode);
        applyFilterValue('BlendColor', 'alpha', alpha);
      }
    },
  }));

  const toggleFilter = (type, checked) => {
    if (checked) {
      const f = fabric.Image.filters;

      if (type === 'Saturation') {
        applyFilter(new f.Saturation({ type, saturation }));
      } else if (type === 'BlendColor') {
        applyFilter(new f.BlendColor({
          ...{ type },
          ...blendColorProps,
        }));
      }
    } else {
      unapplyFilter(type);
    }
  };

  const updateFilters = (filters) => {
    filters.map(filter => {
      // clone filter
      const tmpFilter = Object.assign({}, filter);

      if (tmpFilter.type === 'Saturation') {
        setIsSaturationChecked(tmpFilter.isEnabled === undefined);
        setSaturation(tmpFilter.saturation);
      } else if (tmpFilter.type === 'BlendColor') {
        setIsBlendColorChecked(tmpFilter.isEnabled === undefined);
        delete tmpFilter.type;
        if (tmpFilter.isEnabled !== undefined)
          delete tmpFilter.isEnabled;
        setBlendColorProps(tmpFilter);
      }
      return null;
    });
  };

  const applyFilter = (newFilter) => {
    const obj = checkIfObjectExists(objectId);

    if (obj) {
      if (!obj.filters.find(objFilter => objFilter.type === newFilter.type)) {
        obj.filters.push(newFilter);
        obj.applyFilters();
        canvas.renderAll();
      }
    }
  };

  const unapplyFilter = (type) => {
    const obj = checkIfObjectExists(objectId);

    if (obj) {
      const filterIdx = obj.filters.findIndex(filter => filter.type === type);

      if (filterIdx !== undefined) {
        const tmpFilters = [];
        let i = 0;
        // to remove index with slice function is not working because it is not a normal
        // array with index
        for (const object of obj.filters) {
          if (i !== filterIdx) {
            tmpFilters.push(object);
          }
          i++;
        }
        obj.filters = tmpFilters;
        obj.applyFilters();
        canvas.renderAll();
      }
    }
  };

  const applyFilterValue = (type, prop, value) => {
    const obj = checkIfObjectExists(objectId);

    if (obj) {
      const objFilter = obj.filters.find(filter => filter.type === type);

      if (objFilter) {
        objFilter[prop] = value;
        obj.applyFilters();
        canvas.renderAll();
      }
    }
  };

  const currentColorObj = hexRgb(blendColorProps.color);
  currentColorObj.a = blendColorProps.alpha;
  currentColorObj.r = currentColorObj.red;
  currentColorObj.b = currentColorObj.blue;
  currentColorObj.g = currentColorObj.green;

  return (
    <>
      <div>
        <div style={{ ...Helpers.fillRowCross }}>
          <FormControl className={classes.formControl}>
            <FormControlLabel
              control={<Checkbox
                checked={isSaturationChecked}
                color="secondary"
                style={{ color: filtersDisabled ? 'inherit' : '#cc1e31' }}
              />}
              onChange={(event) => {
                const value = event.target.checked;
                toggleFilter('Saturation', value);
                addActionStep({
                  type: 'setIsSaturation',
                  data: {
                    old: isSaturationChecked,
                    new: value,
                    id: objectId,
                  },
                });
                setIsSaturationChecked(value);
              }}
              label={null}
              disabled={filtersDisabled}
            />
          </FormControl>
          {t('saturation')}
          <Slider
            value={saturation}
            onChange={(event, newValue) => {
              applyFilterValue('Saturation', 'saturation', newValue);
              addActionStep({
                type: 'updateSaturation',
                data: {
                  old: saturation,
                  new: newValue,
                  id: objectId,
                },
              });
              setSaturation(newValue);
            }}
            color="secondary"
            disabled={!isSaturationChecked}
            step={0.01}
            min={-1.0}
            max={1.0}
            style={{ marginLeft: 20 }}
          />
        </div>
      </div>

      <div style={{ maxWidth: 300 }}>
        <div style={{ ...Helpers.fillRowCross, ...Helpers.mainSpaceBetween }}>
          <div style={Helpers.fillRowCenter}>
            <FormControl className={classes.formControl}>
              <FormControlLabel
                control={<Checkbox
                  checked={isBlendColorChecked}
                  color="secondary"
                  style={{ color: filtersDisabled ? 'inherit' : '#cc1e31' }}
                />}
                onChange={(event) => {
                  const value = event.target.checked;
                  toggleFilter('BlendColor', value);
                  addActionStep({
                    type: 'setIsBlendColorChecked',
                    data: {
                      old: isBlendColorChecked,
                      new: value,
                      id: objectId,
                    },
                  });
                  setIsBlendColorChecked(value);
                }}
                label={null}
                disabled={filtersDisabled}
                style={{ marginRight: 0 }}
              />
            </FormControl>
            <div>
              {t('blend_color')}
            </div>
          </div>
          {isBlendColorChecked ?
            <FormControl className={classes.formControl}>
              <Select
                value={blendColorProps.mode}
                onChange={(event) => {
                  const { value } = event.target;
                  applyFilterValue('BlendColor', 'mode', value);
                  addActionStep({
                    type: 'updateBlendColor',
                    data: {
                      old: blendColorProps,
                      new: { ...blendColorProps, ...{ mode: value } },
                      id: objectId,
                    },
                  });
                  setBlendColorProps({ ...blendColorProps, ...{ mode: value } });
                }}
                disableUnderline
                classes={{
                  root: classes.textInput,
                  icon: classes.textSelectIcon,
                }}
              >
                {blendColorModes.map(mode =>
                  <MenuItem value={mode}
                            key={mode}>{mode[0].toUpperCase() + mode.slice(1)}</MenuItem>,
                )}
              </Select>
            </FormControl>
            :
            <div />
          }
        </div>

        {isBlendColorChecked &&
        <div style={{ marginTop: 10 }}>
          <HuePicker
            className={classes.huePicker}
            color={blendColorProps.color}
            onChangeComplete={(color) => {
              const { hex } = color;
              applyFilterValue('BlendColor', 'color', hex);
              addActionStep({
                type: 'updateBlendColor',
                data: {
                  old: blendColorProps,
                  new: { ...blendColorProps, ...{ color: hex } },
                  id: objectId,
                },
              });
              setBlendColorProps({ ...blendColorProps, ...{ color: hex } });
            }}
          />

          <AlphaPicker
            className={classes.alphaPicker}
            color={currentColorObj}
            onChangeComplete={(color) => {
              const { rgb } = color;
              applyFilterValue('BlendColor', 'alpha', rgb.a);
              addActionStep({
                type: 'updateBlendColor',
                data: {
                  old: blendColorProps,
                  new: { ...blendColorProps, ...{ alpha: rgb.a } },
                  id: objectId,
                },
              });
              setBlendColorProps({ ...blendColorProps, ...{ alpha: rgb.a } });
            }}
          />

          <div className={classes.materialPickerContainer}>
            <MaterialPicker
              className={classes.materialPicker}
              color={blendColorProps.color}
              onChangeComplete={(color) => {
                const { hex } = color;
                applyFilterValue('BlendColor', 'color', hex);
                addActionStep({
                  type: 'updateBlendColor',
                  data: {
                    old: blendColorProps,
                    new: { ...blendColorProps, ...{ color: hex } },
                    id: objectId,
                  },
                });
                setBlendColorProps({ ...blendColorProps, ...{ color: hex } });
              }}
            />
          </div>
        </div>
        }
      </div>
    </>
  );
});

const useStyles = makeStyles(() => ({
  textInput: {
    backgroundColor: 'transparent',
    padding: 14,
    borderRadius: 3,
    width: 100,
    textAlign: 'left',
    border: '1px solid #cc1e31',
  },
  textSelectIcon: {
    fill: Colors.white,
    right: 15,
    top: 'auto',
  },
  huePicker: {
    width: '100% !important',
  },
  alphaPicker: {
    width: '100% !important',
    marginTop: 20,
    marginBottom: 20,
  },
  materialPicker: {
    width: 'auto !important',
    height: 'auto !important',
    fontFamily: 'inherit !important',
    padding: '8px !important',
  },
  materialPickerContainer: {
    '& > div > div': {
      backgroundColor: Colors.wrapperLight + ' !important',
    },
    '& input': {
      backgroundColor: 'transparent !important',
      color: Colors.white + ' !important',
    },
  },
}));

export default CardToolFabricObjectFilters;