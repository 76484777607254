import React, { useRef } from 'react';
import { makeStyles, Grid, TextField, InputAdornment } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebookF, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import 'moment/min/locales';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 200,
  },
  textInput: {
    backgroundColor: Colors.textFieldBG,
    padding: 10,
    borderRadius: 3,
    border: '1px solid #c31e30',
    width: 500,

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  textInputUnderline: {
    '&:before': {
      borderBottom: 'none !important',
    },
    '&:after': {
      borderBottom: 'none !important',
    },
  },
  inputAdornment: {
    fontSize: 14,
    color: '#636363',
  },
  socialMediaIcon: {
    marginRight: 10,
    padding: 10,
    borderRadius: 50,
  },
  title: {
    width: 100,
    color: '#636363',
  },
  flexRowSmCol: {
    ...Helpers.fillRowCross,

    [theme.breakpoints.down('md')]: {
      ...Helpers.fillCol,
    },
  }
}));

const SocialMediaSettings = (props) => {
  const classes = useStyles();
  const { values, setValues, isTabletOrMobile } = props;
  const twitterInput = useRef(null);
  const fbInput = useRef(null);
  const instaInput = useRef(null);
  const youtubeInput = useRef(null);

  const handleChange = (event) => {
    const { target } = event;
    const newValues = { ...values };

    newValues[target.name] = target.value;
    setValues(newValues);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <div className={classes.flexRowSmCol}>
            <div className={classes.socialMediaIcon} style={{ padding: '10px 12px' }}>
              <FontAwesomeIcon icon={faTwitter} size="2x" color="#616161" />
            </div>
            {!isTabletOrMobile &&
            <div className={classes.title}>Twitter:</div>
            }
            <TextField
              id="twitter"
              inputRef={twitterInput}
              name="twitter"
              value={values.twitter}
              autoComplete="social twitter"
              onChange={handleChange}
              classes={{
                root: classes.textInput,
              }}
              InputProps={{
                startAdornment: <InputAdornment
                  disableTypography
                  position="start"
                  classes={{ root: classes.inputAdornment }}
                  onClick={() => twitterInput.current.focus()}
                >
                  https://twitter.com/
                </InputAdornment>,
                classes: {
                  underline: classes.textInputUnderline,
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.flexRowSmCol}>
            <div className={classes.socialMediaIcon} style={{ padding: '10px 15px' }}>
              <FontAwesomeIcon icon={faFacebookF} size="2x" color="#616161" />
            </div>
            {!isTabletOrMobile &&
            <div className={classes.title} style={{ marginLeft: 5 }}>Facebook:</div>
            }
            <TextField
              id="facebook"
              name="facebook"
              inputRef={fbInput}
              value={values.facebook}
              autoComplete="social facebook"
              onChange={handleChange}
              classes={{
                root: classes.textInput,
              }}
              InputProps={{
                startAdornment: <InputAdornment
                  disableTypography
                  position="start"
                  classes={{ root: classes.inputAdornment }}
                  onClick={() => fbInput.current.focus()}
                >
                  https://www.facebook.com/
                </InputAdornment>,
                classes: {
                  underline: classes.textInputUnderline,
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.flexRowSmCol}>
            <div className={classes.socialMediaIcon} style={{ padding: '10px 13px' }}>
              <FontAwesomeIcon icon={faInstagram} size="2x" color="#616161" />
            </div>
            {!isTabletOrMobile &&
            <div className={classes.title}>Instagram:</div>
            }
            <TextField
              id="instagram"
              inputRef={instaInput}
              name="instagram"
              value={values.instagram}
              autoComplete="social instagram"
              onChange={handleChange}
              classes={{
                root: classes.textInput,
              }}
              InputProps={{
                startAdornment: <InputAdornment
                  disableTypography
                  position="start"
                  classes={{ root: classes.inputAdornment }}
                  onClick={() => instaInput.current.focus()}
                >
                  https://www.instagram.com/
                </InputAdornment>,
                classes: {
                  underline: classes.textInputUnderline,
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.flexRowSmCol}>
            <div className={classes.socialMediaIcon}>
              <FontAwesomeIcon icon={faYoutube} size="2x" color="#616161" />
            </div>
            {!isTabletOrMobile &&
            <div className={classes.title}>Youtube:</div>
            }
            <TextField
              id="youtube"
              name="youtube"
              inputRef={youtubeInput}
              value={values.youtube}
              autoComplete="social youtube"
              onChange={handleChange}
              classes={{
                root: classes.textInput,
              }}
              InputProps={{
                startAdornment: <InputAdornment
                  disableTypography
                  position="start"
                  classes={{ root: classes.inputAdornment }}
                  onClick={() => youtubeInput.current.focus()}
                >
                  https://www.youtube.com/channel/
                </InputAdornment>,
                classes: {
                  underline: classes.textInputUnderline,
                },
              }}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default SocialMediaSettings;