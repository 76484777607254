import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import Helpers from '../../theme/Helpers';
import HubSeasonCountdown from '../Hub/HubSeasonCountdown';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';
import ProfileEventBoosterLine from './ProfileEventBoosterLine';

import conf from '../../conf.json';

const useStyles = makeStyles({
  head: {
    ...Helpers.fillColCenter,
    backgroundImage: `url("${conf.cdn_url}/Events/Trilluxe-RapidLan-September-2022/trilluxe-rapidlansep2022-bg.jpg")`,
    height: 505,
    width: '100%',
    borderRadius: 6,
  },
  countdownInfo: {
    marginTop: 16,
    '& > div > div:first-child': {
      display: 'none',
    },
  },
  offerArea: {
    height: 474,
    width: 1140,
    borderRadius: 8,
    position: 'relative',
  },
  headText: {
    marginTop: 80,
    marginBottom: 6,
    textTransform: 'initial',
    fontSize: 36,
    fontWeight: 600,
    ...Helpers.textCenter,
  },
  priceBanner: {
    position: 'absolute',
    right: 150,
    top: 80,
    fontSize: 42,
    lineHeight: 1.2,
    fontFamily: 'Bebas Neue',
    fontWeight: 'bold',
    '& div': {
      fontSize: 60,
      marginTop: -10,
    },
  },
  cardArea: {
    padding: '42px 28.5px',
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 8,
  },
});

const ProfileEvent = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { myViewer, currentOffers, streamer } = props;

  const findNewOffer = currentOffers.find(offer => offer.invoice_identifier === 'trilluxe_rapidlan_sep_2022_event_card');
  const bundleOffer = currentOffers.find(offer => offer.invoice_identifier === 'trilluxe_bundle_event_card');

  const cards = findNewOffer.required_booster[0].generic_cards;

  return (
    <div style={{ width: '100%', paddingTop: 32 }}>
      <div className={classes.head}>
        <img
          src={`${conf.cdn_url}/Events/Trilluxe-RapidLan-September-2022/trilluxe-rapidlansep2022-logo.png?height=267`}
          alt="Logo" />

        <div className={classes.countdownInfo}>
          <HubSeasonCountdown t={t}
                              seasonData={{ available_until: findNewOffer.finished_at }} />
        </div>
      </div>

      <h1 className={classes.headText}>
        Hol dir jetzt dein Rapidlan Album
      </h1>
      <div style={{ fontSize: 18, ...Helpers.textCenter, marginBottom: 52 }}>
        Unter allen Sammelalben gibt es 10 Godmappen, in welchen alle Karten Holo und signiert sind!
      </div>

      <ProfileEventBoosterLine index={0} streamer={streamer} currentOffer={findNewOffer}
                               myViewer={myViewer} />

      <ProfileEventBoosterLine index={1} streamer={streamer} currentOffer={bundleOffer}
                               myViewer={myViewer} />

      <h1 className={classes.headText} style={{ marginBottom: 26 }}>
        Welche Karten sind mit inbegriffen?
      </h1>

      <div style={{
        display: 'grid',
        gap: 23,
        gridTemplateColumns: 'repeat(5, 1fr)',
      }}>
        {cards && cards.map(card => (
          <CustomBackdropFilter
            className={classes.cardArea}
            filter={'blur(25px)'}
            canvasFallback={true}
            html2canvasOpts={{
              allowTaint: true,
            }}
          >
            <img src={`${card.card_layer_image}?height=319`} alt="Card"
                 style={{ borderRadius: 6 }} />
          </CustomBackdropFilter>
        ))}
      </div>
    </div>
  );
};

export default withRouter(ProfileEvent);