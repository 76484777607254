import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { useSnackbar } from 'notistack';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import i18n from '../../utils/i18n';
import StreamConfigAlertsGeneralSettings from './StreamConfigAlertsGeneralSettings';
import StreamConfigAlertsSoundSettings from './StreamConfigAlertsSoundSettings';
import StreamConfigAlertsAnimationSettings from './StreamConfigAlertsAnimationSettings';
import CustomButton from '../Button/CustomButton';
import Colors from '../../theme/Colors';
import { returnMobileObj } from '../../utils/responsive';
import Helpers from '../../theme/Helpers';
import StreamConfigAlertsShoutOutSettings from './StreamConfigAlertsShoutOutSettings';

const useStyles = makeStyles(theme => ({
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  textFieldReadOnly: {
    flexGrow: 8,
  },
  textField: {
    backgroundColor: '#0e0e0e',
    padding: '5px 20px',
    borderRadius: 5,
    border: '1px solid #931b28',
    minWidth: 320,
    '& input': {
      color: Colors.subTextColor,
      fontSize: 14,
    },
    '&::before, &::after': {
      display: 'none',
    },
  },
  tabsContainer: {
    backgroundColor: '#151515',
    fontSize: '20 !important',
    fontFamily: 'Bebas Neue',
    fontWeight: 400,
    color: '#636363',
    padding: '0 20px',
  },
}));

const StreamConfigAlerts = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { streamer, loading, hideButton, maxLevelCard, parentState, handleChange, cards, isTabletOrMobile } = props;
  const textFieldRef = useRef(null);
  const [value, setValue] = useState(0);
  const { minLevel, showMinLevelAlerts, shoutMsg, customMsg, showSound, showAnimation, sound, animation, shoutOutsActive, volume } = parentState;

  const copyToClipboard = (e) => {
    textFieldRef.current.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the the whole text selected.
    e.target.focus();
    enqueueSnackbar(t('copy_success'), { variant: 'success' });
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      {(showMinLevelAlerts && !isTabletOrMobile) &&
      <div className={classes.flexRow}>
        <TextField
          inputRef={textFieldRef}
          InputProps={{
            readOnly: true,
            classes: { root: classes.textField },
          }}
          disableUnderline
          className={classes.textFieldReadOnly}
          defaultValue={`https://alerts.streamheroes.gg/${i18n.language.slice(0, 2)}/profile/${streamer._id}`}
        />
        {
          // Logical shortcut for only displaying the button if the copy command exists
          document.queryCommandSupported('copy') &&
          <CustomButton
            status="primary"
            onClick={copyToClipboard}
            style={{
              flexGrow: 1,
              marginLeft: 10,
              marginRight: 10,
              ...returnMobileObj(isTabletOrMobile, { margin: '10px 0' }),
            }}
          >
            {t('copy_alert_url_button')}
          </CustomButton>
        }
        {loading ?
          <CircularProgress color="secondary" />
          :
          <CustomButton status="primary" onClick={hideButton} style={{ flexGrow: 1 }}>
            {t('test_card_alerts_button')}
          </CustomButton>
        }
      </div>
      }

      <Tabs
        value={value}
        onChange={handleTabChange}
        TabIndicatorProps={{
          style: {
            backgroundColor: '#151515',
          },
        }}
        variant={isTabletOrMobile ? 'fullWidth' : 'standard'}
        classes={{
          root: classes.tabsContainer,
        }}
      >
        <Tab
          label={t('header_alert_general')} {...a11yProps(0)} />
        <Tab
          label={t('header_alert_sound')} {...a11yProps(1)} />
        <Tab
          label={t('header_alert_animation')} {...a11yProps(2)} />
        <Tab
          label={t('header_alert_shout_outs')} {...a11yProps(3)} />
      </Tabs>
      <TabPanel value={value} index={0} isTabletOrMobile={isTabletOrMobile}>
        <StreamConfigAlertsGeneralSettings
          maxLevelCard={maxLevelCard}
          showMinLevelAlerts={showMinLevelAlerts}
          handleChange={handleChange}
          minLevel={minLevel}
          customMsg={customMsg}
        />
      </TabPanel>
      <TabPanel value={value} index={1} isTabletOrMobile={isTabletOrMobile}>
        <StreamConfigAlertsSoundSettings
          canHandleSwitch={showMinLevelAlerts}
          showSound={showSound}
          handleChange={handleChange}
          volume={volume}
          sound={sound}
        />
      </TabPanel>
      <TabPanel value={value} index={2} isTabletOrMobile={isTabletOrMobile}>
        <StreamConfigAlertsAnimationSettings
          canHandleSwitch={showMinLevelAlerts}
          showAnimation={showAnimation}
          handleChange={handleChange}
          cards={cards}
          animation={animation}
        />
      </TabPanel>
      <TabPanel value={value} index={3} isTabletOrMobile={isTabletOrMobile}>
        <StreamConfigAlertsShoutOutSettings
          handleChange={handleChange}
          shoutOutsActive={shoutOutsActive}
          shoutMsg={shoutMsg}
        />
      </TabPanel>
    </div>
  );
};

function TabPanel (props) {
  const { children, value, index, isTabletOrMobile, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index &&
      <Box p={3} style={returnMobileObj(isTabletOrMobile, { padding: 10 })}>
        {children}
      </Box>
      }
    </div>
  );
}

function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default StreamConfigAlerts;