import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useMutation } from '@apollo/react-hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { PayPalButton } from 'react-paypal-button-v2';

import { createGenericOrder as createGenericOrderMutation } from '../../apollo/mutations';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import OrderPay from '../Order/OrderPay';
import OrderAddress from '../Order/OrderAddress';
import AnimationButton from '../Button/AnimationButton';
import PremiumItem from './PremiumItem';
import { returnMobileObj } from '../../utils/responsive';

const useStyles = makeStyles(() => ({
  container: {
    color: Colors.white,
    ...Helpers.fillCol,
    padding: 20,
    backgroundColor: '#142134',
    borderRadius: 10,
  },
  head: {
    padding: '20px 0',
    color: Colors.white,
    fontSize: 25,
    lineHeight: 1,
  },
  premiumPackages: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
  },
  noPadding: {
    '& > div': {
      padding: 0,
    },
  },
  subArea: {
    background: '#01122B',
    color: '#A9B9D3',
    ...Helpers.textCenter,
    padding: 20,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  limitWarn: {
    backgroundColor: '#DD9D41',
    color: '#553210',
    padding: 10,
    marginBottom: 20,
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    borderRadius: 10,
  },
}));

const PremiumBuyDialog = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { formValues, tab, nextTab, backTab, status, isTabletOrMobile, countryCosts } = props;
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [paymentType, setPaymentType] = useState('paypal');
  const [isFormFullFilled, setIsFormFullFilled] = useState(false);
  const [wantsBill, setWantsBill] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [plan, setPlan] = useState(null);
  const [stripeURL, setStripeURL] = useState(null);
  const [premiumStatus, setPremiumStatus] = useState(status ? status : null);

  useEffect(() => {
    if (tab === 'payment_method') {
      const variables = getOrderObj();
      if (clientSecret)
        variables.clientSecret = clientSecret;

      createGenericOrder({
        variables,
      });
    }
  }, [tab, clientSecret]);

  const [createGenericOrder] = useMutation(createGenericOrderMutation, {
    onCompleted: (data) => {
      const { createGenericOrder } = data;
      setClientSecret(createGenericOrder.client_secret);
      setOrderId(createGenericOrder._id);
      setPlan(createGenericOrder.plan_id);
      setStripeURL(createGenericOrder.stripe_url);
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
  });

  const getOrderObj = () => {
    const billingAddress = wantsBill ? { ...formValues } : null;
    if (wantsBill) {
      billingAddress.firstname = formValues.forename.trim();
      billingAddress.lastname = formValues.surname.trim();
      billingAddress.postcode = formValues.postal_code;
      billingAddress.street = formValues.street.trim();
      billingAddress.email = formValues.email.trim();
      delete billingAddress.forename;
      delete billingAddress.surname;
      delete billingAddress.postal_code;
      delete billingAddress.editMode;
      delete billingAddress.acceptWrongAddress;
      delete billingAddress.showCheckAddress;
    }

    let premiumStatusCode = 0;
    if (premiumStatus === 'premium') premiumStatusCode = 3;
    else if (premiumStatus === 'standard') premiumStatusCode = 2;
    else if (premiumStatus === 'basic') premiumStatusCode = 1;
    else if (premiumStatus === 'black_friday') premiumStatusCode = 4;

    const subscription = {
      status: premiumStatusCode,
      success_url: window.location.origin + window.location.pathname + '?premium=success',
      cancel_url: window.location.origin + window.location.pathname + '?premium=failed',
    };

    return {
      billingAddress,
      subscription,
      accepted_wrong_address: formValues.acceptWrongAddress,
    };
  };

  const createOrderHandler = (data, actions) => {
    const subscriptionObj = {
      plan_id: plan,
      custom_id: orderId,
    };

    if (wantsBill)
      subscriptionObj.subscriber = {
        name: {
          given_name: formValues.forename,
          surname: formValues.surname,
        },
        email_address: formValues.email,
        shipping_address: {
          name: {
            full_name: formValues.forename + ' ' + formValues.surname,
          },
          address: {
            address_line_1: formValues.street + ' ' + formValues.house_number,
            admin_area_2: formValues.city,
            postal_code: formValues.postal_code,
            country_code: formValues.country,
          },
        },
      };

    return actions.subscription.create(subscriptionObj);
  };

  const nextStep = (status) => {
    setPremiumStatus(status);
    nextTab();
  };

  const onSuccess = (data, details) => {
    window.location.href = window.location.origin + window.location.pathname + '?premium=success';
  };

  const handleSubmit = async () => {
    window.location.href = stripeURL;
  };

  /**
   * Paypal onCancel mthod
   *
   * @param data
   */
  const onCancel = (data) => {
    enqueueSnackbar(t('order_canceled'), { variant: 'error' });
    setProcessing(false);
  };

  const paypalClientId = 'AaI2IfFc3AyFI7jtB-xnLauVOxhrTefgKHnnURkQZaRUh5breDra7vDbyU9cMj_JVxSU7kj6q4gP70zN';

  if (tab === 'select_package')
    return (
      <div>
        <div className={classes.premiumPackages}>
          {/*<PremiumItem index={0} isSelect setShowBuy={nextStep} />*/}
          <PremiumItem index={1} isSelect setShowBuy={nextStep} />
          {/*<PremiumItem index={2} isSelect setShowBuy={nextStep} />
          <PremiumItem index={3} isSelect setShowBuy={nextStep} />*/}
        </div>
        <div className={classes.subArea}>
          {t('premium_package_hint')}
        </div>
      </div>
    );

  if (tab === 'address_input')
    return (
      <div style={{ padding: 32 }}>
        <div className={classes.head} style={{ paddingTop: 0 }}>
          {t('order_head_title')}
        </div>
        <FormControlLabel
          control={
            <Checkbox
              checked={wantsBill}
              onChange={(event) => setWantsBill(event.target.checked)}
              style={{ color: Colors.white }}
            />
          }
          label={t('order_wants_bill')}
        />
        {wantsBill &&
        <div className={classes.noPadding} style={{ marginBottom: 20 }}>
          <OrderAddress
            isFormFullFilled={isFormFullFilled}
            setIsFormFullFilled={setIsFormFullFilled}
            isTabletOrMobile={isTabletOrMobile}
            countryCosts={countryCosts}
          />
        </div>
        }

        <div className={classes.subArea}
             style={{
               ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween,
               margin: -32,
               marginTop: 0,
             }}>
          <AnimationButton onClick={backTab} style={{ padding: '8px 65px' }}>
            {t('back')}
          </AnimationButton>
          <AnimationButton
            style={{ padding: '8px 65px' }}
            onClick={nextTab}
            disabled={wantsBill && !isFormFullFilled}
          >
            {t('next')}
          </AnimationButton>
        </div>
      </div>
    );

  if (tab === 'payment_method')
    return (
      <div style={{ padding: 32 }}>
        <div className={classes.head} style={{ paddingTop: 0 }}>
          {t('shopping_cart')}
        </div>
        <div className={classes.head} style={{ marginTop: 20 }}>
          {t('order_head_checkout')}
        </div>
        <div className={classes.noPadding}>
          <OrderPay
            paymentType={paymentType}
            setPaymentType={setPaymentType}
            checkedTerms={checkedTerms}
            setCheckedTerms={setCheckedTerms}
            noDiscountInput
          />
        </div>
        <div
          className={classes.limitWarn}
          style={returnMobileObj(isTabletOrMobile, { padding: 10 })}
        >
          <FontAwesomeIcon icon={faInfo} size="2x" style={{ minWidth: 36 }} />
          <div style={{ margin: '0 20px' }}>
            {t('paypal_waiting')}
          </div>
        </div>

        {(paymentType === 'paypal' && !processing && checkedTerms) &&
        <PayPalButton
          createSubscription={(data, actions) => createOrderHandler(data, actions)}
          onApprove={(data, actions) => onSuccess(data, actions)}
          onError={(err) => console.error('onError', err)}
          onCancel={(data) => onCancel(data)}
          options={{
            vault: true,
            clientId: paypalClientId,
            currency: 'EUR',
            disableFunding: 'sepa,giropay,ideal,mybank,p24,venmo,eps,sofort,card,credit',
          }}
        />
        }
        {paymentType === 'card' &&
        <AnimationButton
          status="neonGreen"
          disabled={processing || !checkedTerms}
          onClick={handleSubmit}
        >
          {t('next_to_payment')}
        </AnimationButton>
        }
      </div>
    );
};

const mapStateToProps = (state) => {
  const { formValues } = state;
  return { formValues };
};

export default connect(mapStateToProps)(withRouter(PremiumBuyDialog));