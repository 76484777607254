import React, { useState } from 'react';
import { makeStyles, FormControl, Checkbox, FormControlLabel } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import 'moment/min/locales';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';

import { getMyViewer, getStreamers } from '../../apollo/queries';
import {
  toggleStreamerPrivacy as toggleStreamerPrivacyMutation,
  updateMyData as updateMyDataMutation,
  requestAccountDeletionEmail as requestAccountDeletionEmailMutation,
  cancelSubscription as cancelSubscriptionMutation,
} from '../../apollo/mutations';

import i18n from '../../utils/i18n';
import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import SocialMediaSettings from './SocialMediaSettings';
import Loading from '../Loading/Loading';
import { returnMobileObj } from '../../utils/responsive';
import EmptyPopup from '../Dialog/EmptyPopup';
import CustomTooltip from '../Tooltip/CustomTooltip';
import AnimationButton from '../Button/AnimationButton';
import { isUserPremium } from '../../utils/helper';
import Logout from './Logout';

const conf = require('../../conf');

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 200,
    ...Helpers.fillRow,
    marginBottom: 30,
  },
  fieldTitle: {
    width: 166,
    color: '#636363',

    [theme.breakpoints.down('md')]: {
      width: 'fit-content',
      marginRight: 10,
    },
  },
  headerText: {
    color: Colors.white,
    fontSize: 25,
    fontFamily: 'Bebas Neue',
    fontWeight: 400,
    lineHeight: 1,
  },
  headerLine: {
    backgroundColor: '#a10f27',
    padding: '20px 30px',

    [theme.breakpoints.down('md')]: {
      padding: 8,
    },
  },
  flagSelect: {
    backgroundColor: '#0e0e0e',
    border: '1px solid #a90b27',
    padding: 10,
    borderRadius: 3,
    '& .flag-options': {
      backgroundColor: '#b60b28',
      color: Colors.white,
      borderColor: Colors.wrapperDark,
      '& .flag-option:hover': {
        color: Colors.wrapperLight,
      },
    },
    '& .selected--flag--option': {
      color: 'white',
      '& .country-flag img': {
        width: 24,
        height: 24,
      },
      '& .country-label': {
        color: '#636363',
        padding: '0 60px 0 15px',
      },
    },
    '& .arrow-down': {
      color: '#cc1e31',
    },
  },
  chip: {
    border: '2px solid #a10f27',
    borderRadius: 3,
    backgroundColor: '#101010',
    fontSize: 15,
    marginRight: 10,
    padding: '10px 15px',
    display: 'flex',
    fontFamily: 'Bebas Neue',
    fontWeight: 400,
    flexDirection: 'row',
    cursor: 'default',
    color: Colors.white,
    '&:hover': {
      borderColor: '#5e5e5e',
      backgroundColor: 'rgba(0,0,0,0.2)',
    },
  },
  bodyArea: {
    ...Helpers.fillRowCross,
    padding: 30,
    flexWrap: 'wrap',
    color: '#636363',
  },
  limitWarn: {
    backgroundColor: '#3e1c1f',
    color: '#cc1e31',
    padding: 10,
    paddingRight: 100,
    ...Helpers.fillRowCross,
  },
  removalArea: {
    background: '#B60B2840',
    border: '1px solid #600212',
    padding: '30px 55px',
    color: Colors.white,
    borderRadius: 10,
    marginBottom: 10,
    ...Helpers.fillRowCross,

    [theme.breakpoints.down('md')]: {
      ...Helpers.fillCol,
      padding: 8,
    },
  },
  mixedArea: {
    background: '#01122B',
    padding: '30px 33px',
    borderRadius: 10,
    marginBottom: 10,
    ...Helpers.fillRowCross,
  },
  badgeInfo: {
    marginLeft: 32,
    background: '#3B5982',
    padding: '7px 14px',
    paddingLeft: 12,
    color: Colors.white,
    fontSize: 14,
    position: 'relative',
    '&:before': {
      content: '" "',
      position: 'absolute',
      left: -12,
      bottom: 0,
      width: 0,
      height: 0,
      borderRight: '12px solid #3B5982',
      borderBottom: '16px solid transparent',
      borderTop: '16px solid transparent',
    },
  },
}));

const AccountSettings = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { viewer, isTabletOrMobile } = props;
  const [cookie, setCookie] = useCookies([conf.storage_keys.lang_token]);
  const [noDigest, setNoDigest] = useState(viewer.no_digest_mail);
  const [showBoosterAnimation, setShowBoosterAnimation] = useState(!viewer.booster_animation);
  const [country, setCountry] = useState(viewer.country);
  const [GQLLoading, setGQLLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [stopSubscriptionPopup, setStopSubscriptionPopup] = useState(false);

  let variables = { pagination: { perPage: 1, page: 1 } };
  if (viewer.muted_streamers.length > 0)
    variables = { ids: viewer.muted_streamers };

  const { data, loading } = useQuery(getStreamers, {
    variables,
  });
  // if muted_streamers array is empty, then set mutedStreamers null
  const mutedStreamers = (data && viewer.muted_streamers.length > 0) && data.streamers;

  const [toggleStreamerPrivacy] = useMutation(toggleStreamerPrivacyMutation, {
    onCompleted: (data) => {
      setGQLLoading(false);
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
      setGQLLoading(false);
    },
    refetchQueries: () => [{ query: getMyViewer }],
  });

  const [requestAccountDeletionEmail] = useMutation(requestAccountDeletionEmailMutation, {
    onCompleted: () => {
      setEmailSent(true);
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
  });

  const [cancelSubscription] = useMutation(cancelSubscriptionMutation, {
    onCompleted: () => {
      setStopSubscriptionPopup(false);
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{ query: getMyViewer }],
  });

  const [updateMyData] = useMutation(updateMyDataMutation, {
    onCompleted: (data) => {
      enqueueSnackbar(t('update_viewer_data_success'), { variant: 'success' });
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{ query: getMyViewer }],
  });

  const [values, setValues] = useState({
    twitter: (viewer.twitter) ? viewer.twitter : '',
    facebook: (viewer.facebook) ? viewer.facebook : '',
    instagram: (viewer.instagram) ? viewer.instagram : '',
    youtube: (viewer.youtube) ? viewer.youtube : '',
    language: (viewer.language) ? viewer.language : 'en',
  });

  const handleChange = (event) => {
    const { target } = event;
    const newValues = { ...values };

    if (target.name === 'language') { // US - en & DE - de
      const { value } = target;
      if (value === 'US' || value === 'BR') {
        target.value = target.value === 'US' ? 'en' : 'pt';
      } else {
        target.value = value.toLowerCase();
      }
    }

    newValues[target.name] = target.value;
    setValues(newValues);
  };

  const removeMutedStreamer = (streamerID) => {
    setGQLLoading(true);
    toggleStreamerPrivacy({ variables: { streamer: streamerID } });
  };

  const handleCountrySelect = (countryCode) => {
    setCountry(countryCode);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    moment.locale(lng);
  };

  const handleCheckbox = (event, type) => {
    const value = event.target.checked;

    if (type === 'no_digest_mail')
      setNoDigest(value);
    else if (type === 'booster_animation')
      setShowBoosterAnimation(value);
  };

  const saveData = () => {
    if (values.twitter !== viewer.twitter || values.facebook !== viewer.facebook || values.instagram !== viewer.instagram || values.youtube !== viewer.youtube || values.language !== viewer.language || viewer.no_digest_mail !== noDigest || viewer.country !== country || viewer.booster_animation === showBoosterAnimation) {
      if (values.language !== viewer.language) {
        changeLanguage(values.language);
        setCookie(conf.storage_keys.lang_token, values.language, { path: '/' });
      }
      values.no_digest_mail = noDigest;
      values.booster_animation = !showBoosterAnimation;
      values.country = country;

      updateMyData({ variables: { update: values } });
    } else {
      enqueueSnackbar(t('no_data_to_change'), { variant: 'warning' });
    }
  };

  let countryCode = values.language;
  if (values.language === 'en')
    countryCode = 'US';
  else if (values.language === 'pt')
    countryCode = 'BR';

  const { premiumUntil, isPremiumActive } = isUserPremium(viewer);

  return (
    <div className="inner-wrapper">
      <SocialMediaSettings
        values={values}
        setValues={setValues}
        isTabletOrMobile={isTabletOrMobile}
      />
      <div style={{ ...Helpers.fillCol, ...Helpers.mainSpaceBetween, ...{ marginTop: 30 } }}>
        <FormControl className={classes.formControl}>
          <div className={classes.fieldTitle}>
            {t('language')}:
          </div>
          <div style={Helpers.fillRowCross}>
            <ReactFlagsSelect
              countries={['US', 'DE', 'ES', 'FR', 'IT', 'PL', 'BR', 'TR']}
              customLabels={{
                'US': t('english'),
                'DE': t('german'),
                'ES': t('spanish'),
                'FR': t('french'),
                'IT': t('italian'),
                'PL': t('polish'),
                'BR': t('brazilian_portuguese'),
                'TR': t('turkish'),
              }}
              className={classes.flagSelect}
              onSelect={(code) => handleChange({ target: { name: 'language', value: code } })}
              placeholder={t('select_language_placeholder')}
              defaultCountry={(countryCode).toUpperCase()}
            />
          </div>
        </FormControl>
        <FormControl className={classes.formControl}>
          <div className={classes.fieldTitle}>
            {t('nationality')}:
          </div>
          <div style={Helpers.fillRowCross}>
            <ReactFlagsSelect
              searchable={true}
              className={classes.flagSelect}
              onSelect={handleCountrySelect}
              placeholder={t('select_language_placeholder')}
              searchPlaceholder={t('select_language_search')}
              defaultCountry={country}
            />
          </div>
        </FormControl>
        <FormControl className={classes.formControl}>
          <div className={classes.fieldTitle}>
            {t('settings_report')}:
          </div>
          <FormControlLabel
            control={<Checkbox
              checked={noDigest}
              color="default"
              onChange={(event) => handleCheckbox(event, 'no_digest_mail')}
              style={{ color: '#cc1e31' }}
            />}
            label={t('disable_weekly_report')}
            style={{ color: '#636363' }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <div className={classes.fieldTitle}>
            {t('settings_show_booster_animation')}:
          </div>
          <FormControlLabel
            control={<Checkbox
              checked={showBoosterAnimation}
              color="default"
              onChange={(event) => handleCheckbox(event, 'booster_animation')}
              style={{ color: '#cc1e31' }}
            />}
            label={t('disable_booster_animation')}
            style={{ color: '#636363' }}
          />
        </FormControl>
        <div
          style={{ marginBottom: isTabletOrMobile ? 32 : 62, ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween }}>
          <AnimationButton
            status="neonGreen"
            onClick={saveData}
            style={{
              padding: '10px 45px',
              marginRight: 10,
              fontSize: 14,
            }}
          >
            {t('save')}
          </AnimationButton>

          <Logout />
        </div>
      </div>

      <div>
        <div className={classes.headerLine}>
          <div className={classes.headerText}>
            {t('muted_streamers')}
          </div>
        </div>
        <div
          className={classes.bodyArea}
          style={returnMobileObj(isTabletOrMobile, { padding: 10 })}
        >
          {loading || GQLLoading ?
            <Loading />
            :
            (mutedStreamers && mutedStreamers.length > 0) ?
              mutedStreamers.map(streamer =>
                <div key={streamer._id} className={classes.chip}>
                  {streamer.display_name}
                  <CustomTooltip title={t('remove_muted_streamer')}>
                    <div
                      style={{ cursor: 'pointer', marginLeft: 10 }}
                      onClick={() => removeMutedStreamer(streamer._id)}
                    >
                      <FontAwesomeIcon icon={faTimesCircle} color="#a10f27" size="lg" />
                    </div>
                  </CustomTooltip>
                </div>,
              )
              :
              <div>{t('explain_anonymization')}</div>
          }
        </div>
      </div>

      {(isPremiumActive && premiumUntil) &&
      <div>
        <div className={classes.mixedArea}>
          <div style={{ marginRight: isTabletOrMobile ? 0 : 34 }}>
            <img src={`${conf.cdn_url}/assets/template/stop_support.png`} alt="Stop support" />
          </div>
          <div style={{ marginRight: isTabletOrMobile ? 0 : 90, width: isTabletOrMobile ? '100%' : 550 }}>
            <div className={classes.headerText}>
              {t('settings_support_account')}
            </div>
            <div style={{ fontSize: 14, marginTop: 15, color: '#6A92C7' }}>
              {t('settings_support_stop_sure')}
            </div>
          </div>
          <div style={{ alignSelf: 'flex-end', ...Helpers.fillRowCenter }}>
            <AnimationButton
              status="red"
              font="avenirNextCyr"
              onClick={() => setStopSubscriptionPopup(true)}
              disabled={viewer.subscription.status === 'inactive'}
            >
              {t('settings_stop_support')}
            </AnimationButton>
            <div className={classes.badgeInfo}>
              {t('settings_support_duration', { date: premiumUntil.format('LL') })}
            </div>
          </div>
        </div>
      </div>
      }

      <div className={classes.removalArea}>
        <div style={{ marginRight: isTabletOrMobile ? 0 : 55 }}>
          <FontAwesomeIcon icon={faTrash} size="2x" color={Colors.white} />
        </div>
        <div style={{ marginRight: isTabletOrMobile ? 0 : 90, width: isTabletOrMobile ? '100%' : 550 }}>
          <div className={classes.headerText}>
            {t('settings_delete_account')}
          </div>
          <div style={{ fontSize: 14, marginTop: 15 }}>
            {t('settings_delete_account_sure')}
          </div>
          <div style={{ color: '#B00B28', fontSize: 14 }}>
            {t('settings_no_restore')}
          </div>
        </div>
        <div style={{ alignSelf: 'flex-end' }}>
          <AnimationButton
            status="red"
            font="avenirNextCyr"
            onClick={() => setShowPopup(true)}
            disabled={!viewer.email}
          >
            {t('settings_delete_account')}
          </AnimationButton>
        </div>
      </div>

      {showPopup &&
      <EmptyPopup
        handleClose={() => setShowPopup(false)}
        title={t('settings_delete_account')}
        subTitle={t('settings_delete_account_sure')}
        isTabletOrMobile={isTabletOrMobile}
      >
        {emailSent ?
          <>
            <div style={{ fontWeight: 'bold' }}>
              {t('settings_delete_email_text')}
            </div>
            <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween, marginTop: 20 }}>
              <AnimationButton onClick={() => setShowPopup(false)}>
                {t('ok')}
              </AnimationButton>
            </div>
          </>
          :
          <>
            <div style={{ fontWeight: 'bold' }}>
              {t('settings_delete_account_text')}
            </div>
            <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween, marginTop: 20 }}>
              <AnimationButton status="red" onClick={() => requestAccountDeletionEmail()}>
                {t('confirm')}
              </AnimationButton>
              <AnimationButton onClick={() => setShowPopup(false)}>
                {t('cancel')}
              </AnimationButton>
            </div>
          </>
        }
      </EmptyPopup>
      }
      {stopSubscriptionPopup &&
      <EmptyPopup
        handleClose={() => setStopSubscriptionPopup(false)}
        title={t('settings_stop_subscription')}
        subTitle={t('settings_stop_subscription_sure')}
        isTabletOrMobile={isTabletOrMobile}
      >
        <div style={{ fontWeight: 'bold' }}>
          {t('settings_stop_subscription_text')}
        </div>
        <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween, marginTop: 20 }}>
          <AnimationButton status="red" onClick={() => cancelSubscription()}>
            {t('confirm')}
          </AnimationButton>
          <AnimationButton onClick={() => setStopSubscriptionPopup(false)}>
            {t('cancel')}
          </AnimationButton>
        </div>
      </EmptyPopup>
      }
    </div>
  );
};

export default AccountSettings;