import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import { getLegacyCards } from '../../apollo/mergedQueries';

import PageTitle from '../../components/PageTitle/PageTitle';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import Inventory from '../../components/Inventory/Inventory';

const LegacyInventoryContainer = (props) => {
  const { t } = useTranslation();
  const { isLegacy, setIsLegacy } = props;
  const defaultChecked = {
    streamer: true,
    game: false,
    sh: false,
  };
  const [checked, setChecked] = useState(defaultChecked);
  const [sorting, setSorting] = useState({
    field: 'created_at',
    order: 'DESC',
  });
  const [match, setMatch] = useState(null);
  const [page, setPage] = useState(1);
  const perPage = 25;

  const variables = {
    pagination: {
      page: 1,
      perPage,
    },
    unique: true,
    sorting,
    streamer: match,
    organisation: match,
    game: match,
  };

  const { data, loading, fetchMore } = useQuery(getLegacyCards, {
    variables,
    fetchPolicy: useCacheWithExpiration('getLegacyCards'),
  });

  const cards = data && data.getMyOwnedCards;
  const count = data && data.getMyOwnedCardsCount;
  const organisations = data && data.getMyOwnedCardsOrganisations;
  const streamers = data && data.getMyOwnedCardsStreamers;
  const games = data && data.getMyOwnedCardsGames;

  return (
    <>
      <PageTitle title={t('inventory')} />
      <Inventory
        cards={cards}
        t={t}
        loading={loading}
        defaultChecked={defaultChecked}
        hasUserLegacyCards
        checked={checked}
        setChecked={setChecked}
        hasMore={data && count > cards.length}
        isLegacy={isLegacy}
        setIsLegacy={setIsLegacy}
        sorting={sorting}
        setSorting={setSorting}
        setMatch={setMatch}
        itemFilters={{
          streamers,
          organisations,
          games,
        }}
        onLoadMore={async () => {
          setPage(page + 1);
          try {
            await fetchMore({
              variables: { ...variables, ...{ pagination: { perPage, page } } },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return Object.assign({}, prev, {
                  getMyOwnedCards: [...prev.getMyOwnedCards, ...fetchMoreResult.getMyOwnedCards],
                });
              },
            });
          } catch {
          }
        }}
      />
    </>
  );
};

export default LegacyInventoryContainer;