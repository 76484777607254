import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ProgressBar } from 'react-step-progress-bar';

import Helpers from '../../theme/Helpers';
import { getMatchData } from '../../utils/helper';
import Colors from '../../theme/Colors';

import conf from '../../conf.json';

const useStyles = makeStyles({
  itemArea: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    width: 282,
    cursor: 'pointer',
    position: 'relative',
    transition: 'all 0.3s ease-out',
    height: 480,
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.08)',
    },
  },
  avatar: {
    width: 122,
    height: 122,
    border: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
  },
  boosterDesign: {
    position: 'relative',
    overflow: 'hidden',
    cursor: 'pointer',
    height: 287,
    width: 208,
    marginBottom: 31.5,
  },
  footer: {
    padding: '22.5px 17px',
    ...Helpers.fillColCenter,
    fontSize: 14,
    borderTop: '1px solid rgba(255, 255, 255, 0.08)',
  },
});

const GroupedInventoryBooster = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { boosterData, streamer, now } = props;
  const { booster } = boosterData;

  const matchData = getMatchData({ match: streamer, matchModel: 'Streamer' }, 122, 122);
  const finishedAt = booster.redeemable_until && moment.unix(booster.redeemable_until / 1000);
  const isExpired = finishedAt && finishedAt.isBefore(now);
  const diffDays = finishedAt && finishedAt.diff(now, 'days');
  const logoMinPercentage = (((286 - 122) / 286) * 100) / 2;
  const logoMaxPercentage = 100 - logoMinPercentage;
  const percentageOverMin = boosterData.progress - logoMinPercentage;
  const percentageInTotalAvatar = (percentageOverMin / (logoMaxPercentage - logoMinPercentage)) * 100;

  return (
    <div
      className={classes.itemArea}
      onClick={() => props.history.push(`/inventory/streamer/${streamer._id}/booster/${booster._id}`)}
    >
      <div style={{
        padding: '18px 16px 17.5px',
        fontWeight: 500,
        borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
        width: 246,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}>
        {booster.edition}
      </div>

      <div style={{ ...Helpers.fillColCross }}>
        <div style={{ height: 23, marginTop: 8.5, marginBottom: 1 }}>
          {isExpired &&
          <div style={{
            color: Colors.black,
            padding: 4,
            fontSize: 12,
            fontWeight: 500,
            borderRadius: 3,
            background: '#FFE45C',
          }}>
            {t('booster_expired')}
          </div>
          }
          {(diffDays && diffDays > 0 && diffDays <= 7) &&
          <div style={{
            padding: 4,
            fontSize: 12,
            fontWeight: 500,
            borderRadius: 3,
            background: '#E12138',
          }}>
            {t('expires_in_x_days', { days: diffDays })}
          </div>
          }
        </div>

        <div className={classes.boosterDesign}>
          <div
            style={{
              backgroundImage: `url("${conf.cdn_url}${booster.front_image}?width=208")`,
              width: 208,
              height: `${(287 * ((100 - boosterData.progress) / 100))}px`,
              mixBlendMode: 'luminosity',
              opacity: 0.15,
              backgroundPosition: booster.is_starter_pack ? 'bottom' : 'top',
              backgroundRepeat: 'no-repeat',
            }}
          />
          <div
            style={{
              backgroundImage: `url("${conf.cdn_url}${booster.front_image}?width=208")`,
              width: 208,
              height: `${(287 * (boosterData.progress / 100))}px`,
              backgroundPosition: 'bottom',
              backgroundRepeat: 'no-repeat',
            }}
          />
          {(boosterData.progress > 0 && boosterData.progress < 100 && !booster.is_starter_pack) &&
          <div
            style={{
              position: 'absolute',
              left: 9,
              top: `${(287 * ((100 - boosterData.progress) / 100))}px`,
              right: 9,
              height: 57,
              borderRadius: '0px 0px 7px 7px',
              boxShadow: '0px -16px 20px #0000007A',
            }}
          />
          }

          {(!booster.is_starter_pack && !booster.is_custom_booster) &&
          <div className={classes.avatar} style={{ borderRadius: '50%', overflow: 'hidden' }}>
            {boosterData.progress < logoMaxPercentage &&
            <div
              style={{
                width: '100%',
                opacity: 0.15,
                mixBlendMode: 'luminosity',
                height: `${(((100 - percentageInTotalAvatar) / 100) * 122)}px`,
                background: `url("${matchData.avatar}")`,
                backgroundPosition: 'top',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100%',
              }}
            />
            }
            {boosterData.progress > logoMinPercentage &&
            <div
              style={{
                width: '100%',
                height: `${((percentageInTotalAvatar / 100) * 122)}px`,
                background: `url("${matchData.avatar}")`,
                backgroundPosition: 'bottom',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100%',
              }}
            />
            }
          </div>
          }
        </div>
      </div>

      <div className={classes.footer}>
        <div style={{ marginBottom: 10 }}>
          {boosterData.collected} / {boosterData.max} {t('collected')}
        </div>
        <ProgressBar
          percent={boosterData.progress}
          filledBackground={Colors.white}
          unfilledBackground="rgba(255, 255, 255, 0.12)"
          height={3}
          width="100%"
        />
      </div>
    </div>
  );
};

export default withRouter(GroupedInventoryBooster);