import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import { createLicense as createLicenseMutation } from '../../apollo/mutations';
import { getLicenses } from '../../apollo/queries';

import LicenseAdministration from '../../components/Admin/LicenseAdministration';
import { uploadImageToS3 } from '../../utils/helper';

const LicenseAdministrationContainer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  const { data } = useQuery(getLicenses);
  const licenses = (data && data.getLicenses) && data.getLicenses;

  const [createLicense] = useMutation(createLicenseMutation, {
    onCompleted: async (data) => {
      const { createLicense } = data;
      setLoading(false);
      setFile(null);

      if (createLicense && createLicense !== '')
        await uploadImageToS3(createLicense, file, {
          'Content-Type': file.type,
        });
    },
    onError: (data) => {
      setLoading(false);
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{ query: getLicenses }],
  });

  const onCreate = (variables) => {
    setLoading(true);

    if (file) {
      const extension = file.name.split('.')
      .pop();
      variables.logo = variables.name.toLowerCase()
      .replace(' ', '_') + `.${extension}`;
    }

    createLicense({ variables });
  };

  return (
    <LicenseAdministration
      createLicense={onCreate}
      loading={loading}
      file={file}
      setFile={setFile}
      licenses={licenses}
    />
  );
};

export default LicenseAdministrationContainer;