import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  DialogTitle,
  Dialog,
} from '@material-ui/core';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';

const useStyles = makeStyles(theme => ({
  denyButton: {
    background: theme.palette.text.third,
    display: 'block',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  whiteColor: {
    color: Colors.white,
  },
  denyDialog: {
    padding: 10,
    minWidth: 500,
    ...Helpers.boxBasics,
  },
  denyDialogTitle: {
    color: theme.palette.text.third,
  },
}));

function DenyCardsetDialog (props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { onClose, onSubmit, open } = props;
  const [rejectReason, setRejectReason] = useState('');
  // const [details, setDetails] = useState('');

  const handleClose = () => {
    onClose();
  };

  const submitForm = () => {
    onSubmit(rejectReason);
    handleClose();
  };

  const isButtonDisabled = rejectReason === '';

  return (
    <Dialog
      classes={{
        paper: classes.denyDialog,
      }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle className={classes.denyDialogTitle}>
        {t('reject_reason_header')}
      </DialogTitle>
      <FormControl required className={classes.formControl}>
        <InputLabel className={classes.whiteColor}>Reason</InputLabel>
        <Select
          value={rejectReason}
          onChange={(event) => setRejectReason(event.target.value)}
          className={classes.whiteColor}
        >
          <MenuItem value=''><em>None</em></MenuItem>
          <MenuItem value={t('reason_copyright')}>{t('reason_copyright')}</MenuItem>
          <MenuItem value={t('reason_signs')}>{t('reason_signs')}</MenuItem>
          <MenuItem value={t('reason_violence')}>{t('reason_violence')}</MenuItem>
          <MenuItem value={t('reason_incomplete')}>{t('reason_incomplete')}</MenuItem>
          <MenuItem value={t('reason_graphic')}>{t('reason_graphic')}</MenuItem>
          <MenuItem value={t('reason_sexual')}>{t('reason_sexual')}</MenuItem>
          <MenuItem value={t('reason_content')}>{t('reason_content')}</MenuItem>
        </Select>
      </FormControl>
      {/*<FormControl
        required
        className={classes.formControl}
        classes={{ root: classes.whiteColor }}
      >
        <TextField
          InputProps={{
            className: classes.whiteColor,
          }}
          InputLabelProps={{
            className: classes.whiteColor,
          }}
          FormHelperTextProps={{
            className: classes.whiteColor,
          }}
          value={details}
          required
          label={t('reason_more_details')}
          helperText={t('deny_cardset_attention')}
          multiline
          onChange={(event) => setDetails(event.target.value)}
        />
      </FormControl>*/}
      <Button
        variant="contained"
        color="primary"
        className={classes.denyButton}
        onClick={submitForm}
        disabled={isButtonDisabled}
      >
        {t('deny_cardset_submit')}
      </Button>
    </Dialog>
  );
}

export default DenyCardsetDialog;