import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { getCommunityLeaderboard } from '../../apollo/mergedQueries';

import ProfileCommunityRanked from '../../components/Profile/ProfileCommunityRanked';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

const ProfileCommunityRankedContainer = (props) => {
  const { streamer } = props;
  const [currentLeague, setCurrentLeague] = useState('bronze');
  const [page, setPage] = useState(2);
  const perPage = 25;

  const variables = {
    streamer: streamer._id,
    league: currentLeague,
    pagination: { perPage, page: 1 },
  };
  const { data, loading, fetchMore } = useQuery(getCommunityLeaderboard, {
    variables,
    fetchPolicy: useCacheWithExpiration('getCommunityLeaderboard', variables),
  });

  const seasonData = data && data.getSeasonCommunityLeaderboard;
  const seasonDataCount = data && data.getSeasonCommunityLeaderboardCount;
  const currentSeason = data && data.getCurrentSeason;
  const communityStats = data && data.getCommunityStats;

  return (
    <ProfileCommunityRanked
      seasonData={seasonData}
      seasonDataCount={seasonDataCount}
      communityStats={communityStats}
      currentLeague={currentLeague}
      setCurrentLeague={setCurrentLeague}
      season={currentSeason}
      streamer={streamer}
      totalCount={data && seasonDataCount}
      loading={loading}
      onLoadMore={async () => {
        try {
          await fetchMore({
            variables: { pagination: { perPage, page } },
            updateQuery: (prev, { fetchMoreResult }) => {
              setPage(page + 1);
              if (!fetchMoreResult) return prev;
              return Object.assign({}, prev, {
                getSeasonCommunityLeaderboard: [...prev.getSeasonCommunityLeaderboard, ...fetchMoreResult.getSeasonCommunityLeaderboard],
              });
            },
          });
        } catch {
        }
      }}
    />
  );
};

export default ProfileCommunityRankedContainer;