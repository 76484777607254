import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import { getLeagueImage } from '../../utils/helper';

import conf from '../../conf.json';

const useStyles = makeStyles((theme) => ({
  leagueItem: {
    ...Helpers.fillRowCenter,
    padding: '10px 26px',
    borderBottom: '2px solid transparent',
    cursor: 'pointer',
    opacity: 0.5,
    marginRight: 48,

    '&.active': {
      borderBottomColor: Colors.white,
      opacity: 1,
    },
    '&:hover': {
      opacity: 1,
    },
  },
  leagueSelection: {
    ...Helpers.fillRowCross,
    width: '100%',

    [theme.breakpoints.down('md')]: {
      display: 'grid',
      gap: 8,
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  }
}));

const ProfileCommunityRankedLeagueSelection = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { currentLeague, setCurrentLeague } = props;

  return (
    <>
      <div className={classes.leagueSelection}>
        {conf.league_keys.map(leagueKey => (
          <div
            className={[classes.leagueItem, currentLeague === leagueKey ? 'active' : ''].join(' ')}
            key={`LeagueKey${leagueKey}`}
            style={{ ...Helpers.fillRowCenter }}
            onClick={() => setCurrentLeague(leagueKey)}
          >
            <img src={getLeagueImage(leagueKey, 30)} alt="League graphic" style={{ marginRight: 8 }} />
            {t(`rank_${leagueKey}`)}
          </div>
        ))}
      </div>
      <div style={{ width: '100%', border: '1px solid #FFFFFF', opacity: 0.06 }} />
    </>
  );
};

export default ProfileCommunityRankedLeagueSelection;