import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import HRNumbers from 'human-readable-numbers';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import SeasonCountdown from '../Countdown/SeasonCountdown';

import conf from '../../conf';

const useStyles = makeStyles((theme) => ({
  smallText: {
    fontSize: 12,
    color: Colors.halfWhite,
  },
  statsBox: {
    ...Helpers.fillRowCenter,
  },
  countdownWrapper: {
    paddingRight: 86,
    zIndex: 1,
    position: 'relative',

    [theme.breakpoints.down('md')]: {
      paddingRight: 0,
      paddingBottom: 8,
    },
  },
  countdownArea: {
    background: 'rgba(255, 255, 255, 0.04)',
    padding: '20px 16px',
    borderRadius: 6,
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,

    '& > div:first-child': {
      marginRight: 43,
    },

    [theme.breakpoints.down('md')]: {
      ...Helpers.fillColCenter,
      padding: 8,

      '& > div:first-child': {
        marginRight: 0,
      },
    },
  }
}));

const GlobalSeasonInfo = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    season,
    redeemedBoosters = null,
    gatheredEnergy = null,
  } = props;

  return (
    <div className={classes.countdownWrapper}>
      {/*<div className={classes.countdownArea}>
        <div>
          <div style={{ marginBottom: 2 }}>
            {t('season_ends_in')}:
          </div>
          <div className={classes.smallText}>
            {t('started_at')} {season && moment.unix(season.available_from / 1000)
          .format('D.MM.Y ⦁ hh:mm A')}
          </div>
        </div>

        <div>
          <SeasonCountdown t={t} seasonData={season} />
        </div>
      </div>*/}

      <div style={{ ...Helpers.fillRowCross, ...Helpers.mainSpaceBetween, gap: 10 }}>
        <div style={{ ...Helpers.fillRowCenter }}>
          <img
            src={`${conf.cdn_url}/assets/template/heroMaker/big_energy.png?height=44`}
            alt="Energy"
            style={{ marginRight: 10 }} />

          <div>
            <div style={{ fontSize: 24, fontWeight: 500 }}>
              {(season || gatheredEnergy) && HRNumbers.toHumanString(gatheredEnergy ?? (season && season.sumEnergy))}
            </div>
            <div className={classes.smallText}>
              {t('gathered_energy')}
            </div>
          </div>
        </div>

        <div style={{ ...Helpers.fillRowCenter }}>
          <i className="fa-light fa-glass-empty" style={{ marginRight: 12, fontSize: 40 }} />

          <div>
            <div style={{ fontSize: 24, fontWeight: 500 }}>
              {(season || redeemedBoosters) && HRNumbers.toHumanString(redeemedBoosters ?? season.sumBooster)}
            </div>
            <div className={classes.smallText}>
              {t('redeemed_boosters')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(GlobalSeasonInfo);