import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CardToolSelectCardDesign from './CardToolSelectCardDesign';
import Colors from '../../theme/Colors';
import CardToolFabric from './CardToolFabric';

const CardToolFabricController = (props) => {
  const classes = useStyles();
  const { card, myViewer, fonts, level, handleNext, isSpecial } = props;
  const [activeDesign, setActiveDesign] = useState(myViewer.card_design && myViewer.card_design <= 10 ? myViewer.card_design : 1);

  const [customCardFrame, setCustomCardFrame] = useState(isSpecial ? {
    set: 1,
    setLevel: 5,
  } : null);

  const currentSet = customCardFrame ? customCardFrame.set : activeDesign;
  const currentLevel = customCardFrame ? customCardFrame.setLevel : (isSpecial ? 5 : level);

  return (
    <div className={classes.root}>
      {!isSpecial &&
      <CardToolSelectCardDesign activeDesign={activeDesign} setActiveDesign={setActiveDesign} />
      }
      <CardToolFabric
        handleNext={handleNext}
        activeStep={level}
        // previousCard={level > 1 ? cards.find(card => card.rarity === level - 1) : null}
        card={card}
        myViewer={myViewer}
        level={level}
        customCardFrame={customCardFrame}
        set={currentSet}
        setLevel={currentLevel}
        webFonts={fonts}
        isSpecialCard={isSpecial}
        setCustomCardFrame={setCustomCardFrame}
      />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    color: Colors.white,
    minHeight: 400,
    padding: 20,
    width: '100%',
  },
}));

export default CardToolFabricController;