import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Helpers from '../../theme/Helpers';
import PagePopup from '../Dialog/PagePopup';
import Colors from '../../theme/Colors';
import AnimationButton from '../Button/AnimationButton';

import conf from '../../conf';

const useStyles = makeStyles({
  container: {
    ...Helpers.fillColCenter,
    height: '100%',
    maxWidth: 1440,
    margin: '0 auto',
    position: 'relative',
  },
  content: {
    ...Helpers.fillColCenter,
    textAlign: 'center',
  },
  title: {
    marginTop: 0,
    marginBottom: 20,
    fontSize: 26,
    fontWeight: 600,
    textTransform: 'uppercase',
    color: 'white',
  },
  body: {
    fontSize: 16,
    color: Colors.halfWhite,
    margin: 0,
    maxWidth: 560,
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    marginTop: 60,
  },
  button: {
    fontSize: 14,
    padding: '11px 32px',
  },
  rankUpAnimation: {
    width: '100%',
    maxWidth: 400,
  },
  badge: {
    background: 'rgba(255, 255, 255, 0.04)',
    color: 'rgba(255, 255, 255, 0.5)',
    padding: 10,
    fontSize: 12,
    borderRadius: 6,
    marginTop: 20,
  },
});

const RankUpDialog = ({ t, onClose, streamerName, leagueName }) => {
  const classes = useStyles();

  const videoRef = useRef(null);

  const rankUpAnimations = {
    'diamond': {
      url: conf.cdn_url + '/assets/animations/rank-1.webm',
      freezeFrameTime: 8,
    },

    'platinum': {
      url: conf.cdn_url + '/assets/animations/rank-2.webm',
      freezeFrameTime: 8,
    },

    'gold': {
      url: conf.cdn_url + '/assets/animations/rank-3.webm',
      freezeFrameTime: 8,
    },

    'silver': {
      url: conf.cdn_url + '/assets/animations/rank-4.webm',
      freezeFrameTime: 8,
    },

    'bronze': {
      url: conf.cdn_url + '/assets/animations/rank-5.webm',
      freezeFrameTime: 3,
    },
  };

  const animation = rankUpAnimations[leagueName];

  useEffect(() => {
    setTimeout(() => {
      videoRef.current.play();
    }, 1000);

    videoRef.current.addEventListener('ended', () => {
      videoRef.current.style.opacity = 0;

      videoRef.current.currentTime = animation.freezeFrameTime;

      setTimeout(() => {
        videoRef.current.style.transition = 'all 500ms ease';
        videoRef.current.style.opacity = 1;
      }, 1500);
    });
  });

  return (
    <PagePopup>
      <div className={classes.container}>
        <div className={classes.content}>
          <video ref={videoRef} src={animation.url} className={classes.rankUpAnimation} />

          <h2 className={classes.title}>{t('dialogs.rank_up.heading')}</h2>

          <p className={classes.body}>
            {t('dialogs.rank_up.text', { streamerName, leagueName })}
          </p>

          <div className={classes.badge}>
            {streamerName} - <span style={{ textTransform: 'capitalize' }}>{leagueName}</span>
          </div>
        </div>

        <div className={classes.buttonContainer}>
          <AnimationButton
            status="whiteTransparency"
            onClick={onClose}
            className={classes.button}
          >
            {t('dialogs.rank_up.continue_button')}
          </AnimationButton>
        </div>
      </div>
    </PagePopup>
  );
};

export default RankUpDialog;
