import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Helpers from '../../theme/Helpers';
import AnimationButton from '../Button/AnimationButton';
import QualityLine from '../QualityLine/QualityLine';
import Colors from '../../theme/Colors';
import { addShopItem, getLevelWord, getMatchData } from '../../utils/helper';
import { toggleNewCardInShop, updateCardInCart } from '../../redux/actions';
import { logAmplitudeEvent } from '../../utils/amplitude';
import HoverCardInfoArea from '../Layout/HoverCardInfoArea';
import MergedImage from '../MergedImage/MergedImage';
import SelectCardShopPopup from '../Dialog/SelectCardShopPopup';
import NicePrice from '../Utils/NicePrice';
import conf from '../../conf';

const useStyles = makeStyles({
  itemArea: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    width: 322,
    cursor: 'pointer',
    position: 'relative',
    padding: 16,
    transition: 'all 0.3s ease-out',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.08)',
    },
  },
  midAvatar: {
    width: 30,
    height: 30,
    border: 'none',
    marginRight: 6,
  },
  imageArea: {
    position: 'relative',
    cursor: 'pointer',
    ...Helpers.fillColCenter,
    marginBottom: 32,
  },
  rowBelow: {
    ...Helpers.fillRowCross,
    marginTop: -41,
    marginBottom: 42,
    opacity: 0,
    transition: 'all 0.3s ease-out',
  },
  qualityArea: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    padding: '12px 10px',
  },
  coloredBGLine: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: 3,
    borderRadius: '6px 6px 0px 0px',
  },
  countArea: {
    position: 'absolute',
    top: -10,
    right: 5,
    width: 14,
    height: 14,
    background: '#E12138',
    boxShadow: '0px 0px 16px #0000006B',
    fontSize: 12,
    fontWeight: 500,
    padding: 8,
    ...Helpers.textCenter,
    borderRadius: '50%',
  },
  divider: {
    height: 1,
    background: 'rgba(255, 255, 255, 0.08)',
    width: 'calc(100% + 32px)',
    margin: '16.5px -16px',
  },
});

const CardShopCard = (props) => {
  const classes = useStyles();
  const { t, card, cardShop, isLegacy, switchLastHover, location, noCart } = props;
  const [showCard, setShowCard] = useState(false);

  const cardInCart = (!isLegacy && cardShop.newCards && !noCart) ? cardShop.newCards.find(csCard => csCard._id === card._id) : false;
  const shopCard = isLegacy ? cardShop.cards.find(csCard => csCard._id === card._id) : null;
  const amount = shopCard ? shopCard.count : 0;
  const cardObj = isLegacy ? card.specialcard ? card.specialcard : card.card : card;
  const matchCardType = isLegacy ? card.matchCardModel : null;
  const levelWord = getLevelWord(matchCardType, isLegacy ? (matchCardType === 'Card' ? cardObj.level : null) : cardObj.rarity);
  const levelColor = Colors.level[levelWord];
  const price = (cardObj.discounted_price ? cardObj.discounted_price : cardObj.price ? cardObj.price : cardObj.shop_price) / 100;

  if (cardInCart || shopCard)
    return null;

  const {
    avatar,
    name,
    url,
  } = getMatchData(isLegacy ? cardObj : cardObj.card_template_metadata, 48, 48);

  const sendToAmplitude = () => {
    if (conf.track_amplitude) logAmplitudeEvent('Add_Basket_Shop', {
      'Card ID': cardObj._id,
      'Owner Name': name,
      'Card Price': price,
    });
  };

  const addNewCard = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const productImage = document.getElementById(`card-image-${cardObj._id}`);
    if (!cardInCart)
      addShopItem(e, productImage);

    sendToAmplitude();

    setTimeout(() => props.toggleNewCardInShop(cardObj), 600);
  };

  const legacyAddNewCard = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowCard(!showCard);
  };

  const updateQuantity = (e, redeemedCards, newCard) => {
    sendToAmplitude();

    const newAmount = redeemedCards.length;

    if (card.count >= newAmount) {
      if (newAmount > amount) {
        const productImage = document.getElementById(`card-image-${cardObj._id}`);
        addShopItem(e, productImage);
      }

      props.updateCardInCart(newCard, price, redeemedCards);
    }
  };

  return (
    <>
      <div
        className={classes.itemArea}
        onClick={() => isLegacy || noCart ? null : props.history.push(location.pathname + '/card/' + card._id)}
        style={noCart ? { width: 168, cursor: 'default', padding: '16px 16px 10.5px' } : {}}
      >
        {!isLegacy &&
        <div className={classes.coloredBGLine} style={{
          background: levelColor,
          borderRadius: '6px 6px 0px 0px',
        }} />
        }
        <div>
          <div style={{ ...Helpers.fillRowCross, cursor: 'pointer' }}
               onClick={() => props.history.push(url)}>
            <Avatar
              className={classes.midAvatar}
              alt="Avatar"
              src={avatar}
            />
            <div>
              <div style={{
                fontSize: 16,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                width: 150,
                fontWeight: 500,
              }}>
                {name}
              </div>
            </div>
          </div>

          <div className={classes.divider}
               style={{ marginBottom: noCart ? 22.5 : 31.5, marginTop: 12.5 }} />

          <div className={classes.imageArea}
               style={noCart ? { cursor: 'default', marginBottom: 20.5 } : {}}>
            <MergedImage
              card={cardObj}
              isLegacy={isLegacy}
              height={noCart ? 224 : 288}
              borderRadius={6}
              id={`card-image-${cardObj._id}`}
            />

            {!isLegacy ?
              <HoverCardInfoArea card={card} t={t} switchLastHover={switchLastHover} />
              :
              <div
                className={classes.countArea}
                style={amount ? {
                  width: 'auto',
                  height: 'auto',
                  background: 'linear-gradient(to bottom, #8BB61E, #8EC222, #93B01A)',
                } : {}}
              >
                {amount ? `${amount}/` : ''}{card.count}
              </div>
            }
          </div>
        </div>

        {isLegacy || (card.quality && card.quality < 0) ?
          <div style={{
            ...Helpers.textCenter,
            width: 'calc(100% - 40px)',
            padding: 20,
            fontSize: 14,
            color: Colors.halfWhite,
            background: 'rgba(255, 255, 255, 0.02)',
            borderRadius: 6,
          }}>
            {t('no_quality_available')}
          </div>
          :
          <QualityLine
            quality={cardObj.quality}
            width={[13, 59, 89, 29, 10]}
            spaceBetween={2}
            condition={card.condition}
          />
        }
        {!noCart &&
        <>
          <div className={classes.divider} />

          <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween }}>
            <NicePrice price={price} />

            <div style={{ ...Helpers.fillRow }}>
              {card.ordered_at ?
                <div style={{
                  color: '#1FE325',
                  background: 'rgba(255, 255, 255, 0.04)',
                  borderRadius: 4,
                  padding: '14px 18.5px',
                }}>
                  <i className="fa-light fa-circle-check"
                     style={{ fontSize: 18, marginRight: 8 }} />
                  {t('purchased')}
                </div>
                :
                <AnimationButton
                  status={cardInCart ? 'red' : 'neonGreen'}
                  onClick={(e) => isLegacy ? legacyAddNewCard(e) : addNewCard(e)}
                  style={{
                    fontFamily: 'Montserrat',
                    fontSize: 14,
                    padding: '14px 16px',
                    borderRadius: 4,
                    fontWeight: 500,
                  }}
                >
                  <i
                    className={`fa-light fa-cart-${cardInCart ? 'minus' : 'plus'}`}
                    style={{ fontSize: 18, marginRight: 8, verticalAlign: 'bottom' }}
                  />
                  {t(cardInCart ? 'remove_from_cart' : 'add_to_cart')}
                </AnimationButton>
              }
              {!isLegacy &&
              <AnimationButton
                status="whiteTransparency"
                style={{
                  padding: '14px 13px',
                  borderRadius: 4,
                  marginLeft: 6,
                }}
              >
                <i
                  className={`fa-light fa-eye`}
                  style={{ fontSize: 18 }}
                />
              </AnimationButton>
              }
            </div>
          </div>
        </>
        }
      </div>
      {showCard &&
      <SelectCardShopPopup
        card={card}
        handleClose={() => setShowCard(null)}
        shopCard={shopCard}
        updateQuantity={updateQuantity}
      />
      }
    </>
  );
};

const mapStateToProps = (state) => {
  const { cardShop } = state;
  return { cardShop };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    updateCardInCart,
    toggleNewCardInShop,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardShopCard));