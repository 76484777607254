import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { getMyStreamerOnBoarding } from '../../apollo/mergedQueries';

import OnBoarding from '../../components/OnBoarding/OnBoarding';
import Loading from '../../components/Loading/Loading';
import PageTitle from '../../components/PageTitle/PageTitle';
import TopViewHeader from '../../components/TopViewHeader/TopViewHeader';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import { getMaximalLevelOfCards } from '../../utils/helper';
import ErrorMessage from '../../components/ErrorBoundary/ErrorMessage';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 150,
  },
  container: {
    ...Helpers.boxBasics,
    minHeight: 200,
    maxWidth: 1100,
    margin: 'auto',
    padding: 65,
    color: Colors.white,
  },
  header: {
    textTransform: 'none',
    marginBottom: 45,
  },
  logo: {
    marginTop: -154,
    marginBottom: 20,
    ...Helpers.fillColCenter,
    '& img': {
      width: 180,
    },
  },
}));

const OnBoardingContainer = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const getMyStreamerOnBoardingQuery = useQuery(getMyStreamerOnBoarding);

  if (getMyStreamerOnBoardingQuery.error)
    return <ErrorMessage error={getMyStreamerOnBoardingQuery.error} />;

  const viewer = (getMyStreamerOnBoardingQuery.data) && getMyStreamerOnBoardingQuery.data.getMyViewer;
  const streamer = (getMyStreamerOnBoardingQuery.data) && getMyStreamerOnBoardingQuery.data.getMyStreamer;
  const streamerRequirements = (getMyStreamerOnBoardingQuery.data) && getMyStreamerOnBoardingQuery.data.checkRequirementsToBecomeAStreamhero;
  const cards = (getMyStreamerOnBoardingQuery.data) && getMyStreamerOnBoardingQuery.data.getMyStreamerCards;

  let maxLevelCard = 3;
  if (cards)
    maxLevelCard = getMaximalLevelOfCards(cards);
  if (maxLevelCard < 3)
    maxLevelCard = 3;

  if (streamerRequirements && (viewer && !viewer.streamheroes_role.includes('marketing'))) {
    const { broadcaster_type } = streamerRequirements;
    if (broadcaster_type !== 'affiliate' && broadcaster_type !== 'partner') {
      props.history.push('/hq/hub');
      return null;
    }
  }

  return (
    <TopViewHeader>
      <PageTitle title={t('on_boarding')} />
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.logo}>
            <img src={process.env.PUBLIC_URL + '/setup_logo.png'} alt="StreamHeroes" />
          </div>
          {getMyStreamerOnBoardingQuery.loading ?
            <Loading />
            :
            <OnBoarding
              viewer={viewer}
              streamer={streamer}
              cards={cards}
              maxLevelCard={maxLevelCard}
            />
          }
        </div>
      </div>
    </TopViewHeader>
  );
};

export default withRouter(OnBoardingContainer);