import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import OrderForm from '../Order/OrderForm';
import OrderFormCheck from '../Order/OrderFormCheck';
import { handleValueChange } from '../../redux/actions';
import { returnMobileObj } from '../../utils/responsive';

const OrderAddress = (props) => {
  const {
    setIsFormFullFilled,
    parentHandleChange,
    countryCosts,
    isTabletOrMobile,
    handleValueChange,
    formValues,
  } = props;
  const { editMode } = formValues;

  const setEditMode = (value) => {
    handleValueChange('editMode', value);
  };

  return (
    <div style={returnMobileObj(isTabletOrMobile, { padding: 10 })}>
      {editMode ?
        <OrderForm
          countryCosts={countryCosts}
          setIsFormFullFilled={setIsFormFullFilled}
          isTabletOrMobile={isTabletOrMobile}
          parentHandleChange={(event) => parentHandleChange ? parentHandleChange(event) : null}
        />
        :
        <OrderFormCheck setEditMode={setEditMode} showButton />
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  const { formValues } = state;
  return { formValues };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators(
    {
      handleValueChange,
    }
    , dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(OrderAddress);