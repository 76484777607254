import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { getCurrentOfferWithMyViewer } from '../../apollo/mergedQueries';

import ProfileEvent from '../../components/Profile/ProfileEvent';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import Loading from '../../components/Loading/Loading';

const ProfileEventContainer = (props) => {
  const { streamer } = props;

  const { data, loading } = useQuery(getCurrentOfferWithMyViewer, {
    variables: { matchModel: 'Streamer', match: streamer._id },
    fetchPolicy: useCacheWithExpiration('getCurrentOfferWithMyViewer', {
      matchModel: 'Streamer',
      match: streamer._id,
    }),
  });

  const currentOffers = (data) && data.getCurrentOffer;
  const myViewer = (data) && data.getMyViewer;

  return (
    loading ?
      <Loading />
      :
      <ProfileEvent
        currentOffers={currentOffers}
        myViewer={myViewer}
        streamer={streamer}
      />
  );
};

export default ProfileEventContainer;