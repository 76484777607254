import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PagePopup from '../Dialog/PagePopup';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import { buyMarketPlaceItem as buyMarketPlaceItemMutation } from '../../apollo/mutations';
import { marketPlaceMyHistory } from '../../apollo/queries';
import { getMarketPlaceStats, marketPlaceOffers } from '../../apollo/mergedQueries';

import AnimationButton from '../Button/AnimationButton';
import Helpers from '../../theme/Helpers';
import SVGIcon from '../SVGIcon/SVGIcon';
import Colors from '../../theme/Colors';
import ClosePopupButton from '../Button/ClosePopupButton';
import MergedImage from '../MergedImage/MergedImage';
import { get20StepQuality, getCondition, getLevelWord } from '../../utils/helper';

import conf from '../../conf.json';

const useStyles = makeStyles({
  container: {
    maxWidth: 562,
    height: 280,
    margin: '0 auto',
    marginTop: '50vh',
    transform: 'translate(0, -50%)',
    position: 'relative',
    color: Colors.white,
    background: '#142134',
    borderRadius: 4,
    boxShadow: '0px 10px 36px #0000003D',
  },
  footerArea: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    padding: '16px 32px',
    background: '#1D2A3C',
    borderRadius: '0px 0px 4px 4px',
    borderTop: '1px solid rgba(255, 255, 255, 0.08)',
  },
  avatar: {
    width: 26,
    height: 26,
    border: 'none',
  },
  halfColorDiv: {
    color: Colors.halfWhite,
    ...Helpers.textCenter,
    fontSize: 14,
    marginTop: 10,
    '& > span': {
      color: Colors.white,
      fontWeight: 600,
    },
  },
});

const MarketplaceSmallBuyDialog = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { offerData, onClose, myViewer } = props;

  const reFetchQueries = [
    {
      query: getMarketPlaceStats,
      variables: { timeframe: 1, pagination: { page: 1, perPage: 25 }, rarity: null },
    },
    {
      query: marketPlaceOffers,
      variables: {
        pagination: { page: 1, perPage: 25 },
        is_seasonal: false,
        sorting: {
          field: 'created_at',
          order: 'DESC',
        },
        rarity: [],
        min_quality: 0,
        max_quality: 100,
      },
    },
    {
      query: marketPlaceMyHistory,
      variables: {
        pagination: { page: 1, perPage: 25 },
        is_seasonal: false,
        sorting: {
          field: 'created_at',
          order: 'DESC',
        },
        rarity: [],
        min_quality: 0,
        max_quality: 100,
        type: 'active',
      },
    },
  ];

  const [buyMarketPlaceItem, { loading: buyLoading }] = useMutation(buyMarketPlaceItemMutation, {
    onCompleted: () => {
      onClose();
      enqueueSnackbar(t('success'), { variant: 'success' });
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => reFetchQueries,
  });

  const buyOffer = (id) => {
    buyMarketPlaceItem({ variables: { id } });
  };

  const cardData = offerData && offerData.offer_match_model_meta_data;
  const { rarity, quality } = cardData;
  const levelColor = Colors.level[getLevelWord('Card', rarity)];
  const condition = getCondition(quality);

  return (
    <PagePopup handleClose={onClose} style={{ zIndex: 1000000 }}>
      <div className={classes.container}>
        <ClosePopupButton handleClose={onClose}
                          style={{ right: 32, top: 16, width: 30, height: 30 }} />

        <div style={{
          fontSize: 24,
          fontWeight: 600,
          padding: '17px 32px 16px',
          marginBottom: 3,
          background: '#1D2A3C',
        }}>
          {t('confirm_purchase')}
        </div>

        <div style={{ padding: '18px 34px' }}>
          <div style={{ marginBottom: 10 }}>
            {t('card_overview')}
          </div>
          <div style={Helpers.fillRowCross}>
            <div style={{ position: 'relative' }}>
              <MergedImage
                card={cardData}
                height={45} borderRadius={2}
                id={`card-image-${cardData._id}`}
              />
            </div>
            <img
              src={`${conf.cdn_url}/badges/card-level/${rarity}.png?height=19`}
              alt="Level badge"
              style={{ marginRight: 2, marginLeft: 12, verticalAlign: 'bottom' }}
            />
            <div style={{ color: levelColor, fontWeight: 500 }}>
              {t(`rareness_level.${rarity}`)}
            </div>
            <div style={{ margin: '0 5.5px' }}>-</div>
            <div style={{
              color: Colors.newQuality[get20StepQuality(quality) - 1],
              fontWeight: 500,
            }}>
              {t(`condition_level.${condition}`)}
            </div>
            <div style={{ margin: '0 5.5px' }}>-</div>
            <div>{quality}%</div>
          </div>
        </div>

        <div className={classes.footerArea}>

          <AnimationButton
            status="neonPink"
            style={{ width: '100%', fontSize: 14, fontWeight: 500 }}
            disabled={buyLoading}
            onClick={() => buyLoading ? null : buyOffer(offerData._id)}
          >
            {buyLoading ?
              <i className="fa-light fa-loader fa-spin" style={{ fontSize: 18 }} />
              :
              <div style={Helpers.fillRowCenter}>
                {t('buy_now_with')}
                <SVGIcon
                  src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
                  height={25}
                  width={19}
                  style={{ margin: '0 8px' }}
                />
                {t('x_gems', { count: offerData.price })}
              </div>
            }
          </AnimationButton>

          <div className={classes.halfColorDiv}
               dangerouslySetInnerHTML={{ __html: t('your_new_balance_gems', { count: myViewer.premium_currency - offerData.price }) }} />
        </div>
      </div>
    </PagePopup>
  );
};

export default MarketplaceSmallBuyDialog;
