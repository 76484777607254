import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';

import PagePopup from "../Dialog/PagePopup";
import CustomBackdropFilter from "../Layout/CustomBackdropFilter";

const useStyles = makeStyles(() => ({
    dialogContent: {
        width: '100%', 
        maxWidth: 530,
        margin: '0 auto', 
        borderRadius: 6,
        backgroundColor: 'rgba(255, 255, 255, 0.04)',
        padding: '22px 16px'
    },

    checkboxContainer: {
        margin: '-10px 0 -10px -11px'
    }
}));

const ProfilePrivacyPopup = (props) => {
    const { t, closeClickHandler, setActivitiesPrivacySettings, activePrivacySettings } = props;

    const classes = useStyles();

    const [activeSettings, setActiveSettings] = useState(activePrivacySettings);

    const settings = [
        { key: 'depotUpgraded', label: t('activity_feed_settings_depot_upgraded'), checked: false },
        { key: 'energyConvertedToCoins', label: t('activity_feed_settings_energy_converted'), checked: false },
        { key: 'redeemedBooster', label: t('activity_feed_settings_booster_redeemed'), checked: false },
        { key: 'userRankUpInSeason', label: t('activity_feed_settings_season_rank_up'), checked: false }
    ]

    const change = (updatedKey, checked) => {
        const index = activeSettings.findIndex(settingKey => settingKey === updatedKey)

        if (checked && index !== -1) {
            activeSettings.splice(index, 1)
        } else if (!checked && index === -1) {
            activeSettings.push(updatedKey)
        }

        setActiveSettings(activeSettings);

        setActivitiesPrivacySettings({ variables: { "categories": activeSettings } })
    }

    const isChecked = (key) => {
        return activeSettings.findIndex(settingKey => settingKey === key) === -1;
    }

    return (
        <PagePopup>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%', color: 'white' }}>
                <CustomBackdropFilter
                    filter={'blur(50px)'}
                    canvasFallback={true}
                    className={classes.dialogContent}
                    html2canvasOpts={{
                        allowTaint: true,
                    }}
                >  
                    <div style={{ display: 'flex', marginBottom: 32 }}>
                        <div style={{ fontSize: 16 }}>
                            <i class="fa-solid fa-arrow-rotate-right" style={{ marginRight: 10 }}></i>
                        
                            {t('activity_feed_settings')}
                        </div>

                        <div style={{ marginLeft: 'auto', fontSize: 14, color: 'rgba(255, 255, 255, 0.5)', cursor: 'pointer' }} onClick={closeClickHandler}>
                            {t('close')}
                        </div>
                    </div>

                    <div style={{ display: 'grid', gap: 4 }}>
                        { settings.map(setting => 
                            <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.05)', borderRadius: 6, padding: 10 }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            color="default"
                                            checked={isChecked(setting.key)}
                                            onChange={(event) => change(setting.key, event.target.checked)} 
                                        />
                                    }
                                    label={setting.label} 
                                    className={classes.checkboxContainer}
                                />
                            </div>
                        )}           
                    </div>             
                </CustomBackdropFilter>
            </div>
        </PagePopup>
    )
}

export default ProfilePrivacyPopup;
