import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import { getSearchResults as getSearchResultsMutation } from '../../apollo/mutations';

import CardShopStreamerSearch from '../../components/CardShop/CardShopStreamerSearch';

function CardShopStreamerSearchContainer(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { match, setMatch } = props;
  const [search, setSearch] = useState('');
  const [isChanged, setIsChanged] = useState(false);
  const pagination = { page: 1, perPage: 5 };

  useEffect(() => {
    let timeOutId;
    if (search && search.length >= 3 && isChanged) {
      timeOutId = setTimeout(() => {
        getSearchResults({
          variables: {
            pagination,
            filter: { q: search, type: 'streamer' },
          },
        });
        setIsChanged(false);
      }, 500);
    }
    return () => clearTimeout(timeOutId);
  }, [pagination, search]);

  const [getSearchResults, { data, loading }] = useMutation(getSearchResultsMutation, {
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
  });

  const changeSearch = (value) => {
    setSearch(value);
    setIsChanged(true);
  };

  const results = data && data.getSearchResults;

  return (
    <CardShopStreamerSearch
      results={results && results.streamers}
      loading={loading}
      search={search}
      setSearch={changeSearch}
      onClick={(id) => setMatch(id)}
      match={match}
    />
  );
}

export default CardShopStreamerSearchContainer;