import React, { useState } from 'react';

import StartOnBoarding from '../../components/OnBoarding/StartOnBoarding';
import ItemStoreContainer from '../ItemStore/ItemStore';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useTranslation } from 'react-i18next';

const ViewerOnBoardingContainer = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);

  const steps = [
    {
      image: '/assets/tutorial/onboarding_energy.png',
      head: 'collect_energy_watching_streams',
      body: 'collect_energy_watching_streams_description',
      marginTop: 22,
    },
    {
      head: 'open_booster_collected_energy',
      body: 'open_booster_collected_energy_description',
      marginTop: 22,
    },
    {
      image: '/assets/tutorial/support_streamer.png',
      head: 'support_streamer_with_ordering',
      body: 'support_streamer_with_ordering_description',
      marginTop: 60,
    },
    {
      image: '/assets/tutorial/depot-energy-icon.png',
      head: 'you_got_first_energy',
      marginTop: 22,
    },
  ];

  return (
    <>
      <ItemStoreContainer />
      <StartOnBoarding step={step} setStep={setStep} steps={steps} />
      <PageTitle title={t('viewer_on_boarding')} />
    </>
  );
};

export default ViewerOnBoardingContainer;