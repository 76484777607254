import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive';

import Helpers from '../../theme/Helpers';
import { isTabletOrMobileObj, returnMobileObj } from '../../utils/responsive';

const useStyles = makeStyles({
  limitWarn: {
    backgroundColor: '#DD9D41',
    color: '#553210',
    fontSize: 15,
    padding: 15,
    ...Helpers.fillRowCenter,
    ...Helpers.mainStart,
    marginTop: 20,
    borderRadius: 10,
  },
});

const EventModeMessage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery(isTabletOrMobileObj);

  return (
    <div
      className={classes.limitWarn}
      style={returnMobileObj(isTabletOrMobile, {
        ...Helpers.fillColCenter,
        borderRadius: 0,
        padding: 5,
      })}
    >
      <FontAwesomeIcon icon={faExclamationCircle} size="2x" style={{ minWidth: 36 }} />
      <div style={{ margin: isTabletOrMobile ? '0 5px 5px' : '0 20px' }}>
        <span style={{ fontFamily: 'Bebas Neue', fontSize: 20 }}>
          {t('event_mode_activated')}
        </span><br />
        {t('event_mode_text')}
      </div>
    </div>
  );
};

export default EventModeMessage;