import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Colors from '../../theme/Colors';
import Loading from '../Loading/Loading';
import GroupedInventoryStreamer from './GroupedInventoryStreamer';
import GroupedInventoryAllCards from './GroupedInventoryAllCards';
import CardShopStreamerSearchContainer from '../../containers/CardShop/CardShopStreamerSearch';
import LoadMore from '../Loading/LoadMore';
import InnerHeader from '../Layout/InnerHeader';
import { isUserPremium } from '../../utils/helper';
import Helpers from '../../theme/Helpers';

import conf from '../../conf.json';

const useStyles = makeStyles((theme) => ({
  gridArea: {
    display: 'grid',
    gap: 17.5,
    gridTemplateColumns: 'repeat(5, 1fr)',

    [theme.breakpoints.down('md')]: {
      ...Helpers.fillColCenter,
    },
  },
  streamerSearchArea: {
    marginBottom: 30,
    width: 'fit-content',
    marginTop: 20,

    [theme.breakpoints.down('md')]: {
      margin: '10px auto',
    },
  }
}));

const GroupedInventory = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { myViewer, myInventoryCards, loading, totalCount, onLoadMore, match, setMatch } = props;
  const { isPremiumActive } = isUserPremium(myViewer);

  return (
    <div style={{ color: Colors.white, position: 'relative' }}>

      <InnerHeader
        showAd={conf.ad_area.inventory && myViewer && !isPremiumActive}
        title={t('cards')}
      />

      <div className="inner-wrapper">
        <div className={classes.streamerSearchArea}>
          <CardShopStreamerSearchContainer setMatch={setMatch} match={match} />
        </div>

        {loading ?
          <Loading />
          :
          <>
            <div className={classes.gridArea}>
              <GroupedInventoryAllCards data={myInventoryCards} />
              {myInventoryCards && myInventoryCards.streamer_cards.map(data => (
                <GroupedInventoryStreamer
                  key={data.streamer ? data.streamer.login : data.card_count}
                  data={data}
                />
              ))}
            </div>

            <LoadMore count={myInventoryCards && myInventoryCards.streamer_cards.length}
                      totalCount={myInventoryCards && totalCount}
                      loading={loading} onLoadMore={onLoadMore} />
          </>
        }
      </div>

    </div>
  );
};

export default GroupedInventory;