import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useQuery } from '@apollo/react-hooks';
import Jimp from 'jimp';

import { getCardTemplateById, getRedeemedCardObjectInformation } from '../../apollo/queries';

import ThreeDWrapper from '../../components/3DCard/3DWrapper';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import Loading from '../../components/Loading/Loading';
import Scene from '../../components/3DCard/Scene';
import { useQueryParams } from '../../utils/helper';

import conf from '../../conf.json';

const ThreeDCard = () => {
  const { cardId } = useParams();
  const queryParams = useQueryParams();
  const isGeneric = queryParams && queryParams.get('isGeneric');
  const [compositedImage, setCompositedImage] = useState(null);

  const variables = { id: cardId };
  const getRedeemedCardObjectInformationQuery = useQuery(getRedeemedCardObjectInformation, {
    variables,
    fetchPolicy: useCacheWithExpiration('getRedeemedCardObjectInformation', variables),
    skip: isGeneric,
  });
  const getCardTemplateByIdQuery = useQuery(getCardTemplateById, {
    variables,
    fetchPolicy: useCacheWithExpiration('getCardTemplateById', variables),
    skip: !isGeneric,
  });

  const {
    data,
    loading,
  } = isGeneric ? getCardTemplateByIdQuery : getRedeemedCardObjectInformationQuery;
  const information = data && data[isGeneric ? 'getCardTemplateById' : 'getRedeemedCardObjectInformation'];

  const card = information && {
    image: compositedImage ? compositedImage : (information.front_layers && information.front_layers[0].physical_img_url),
    physical_card_layer_image: information.physical_card_layer_image,
    backImage: information.back_layers && information.back_layers[0].physical_img_url,
    frontEffect: (information.front_layers && information.front_layers.length > 1) && information.front_layers[1].physical_img_url,
    backEffect: (information.back_layers && information.back_layers.length > 1) && information.back_layers[1].physical_img_url,
    noEffectLayer: compositedImage || isGeneric || (information.quality && information.quality < 0),
  };

  useEffect(() => {
    if (!compositedImage && card)
      mergeWithScreenBlend(card);
  }, [card]);

  const mergeWithScreenBlend = async (card) => {
    const source = await Jimp.read(card.image.replace(conf.cdn_url, conf.no_cors_cdn_url));
    const target = await Jimp.read(`https://s3.eu-central-1.wasabisys.com/streamheroes-card-manager/${card.frontEffect}`);

    source.composite(target, 0, 0, {
      mode: Jimp.BLEND_SCREEN
    });

    const dataUri = await source.getBase64Async(Jimp.MIME_PNG);

    setCompositedImage(dataUri);
  };

  return (
    <>
      <Helmet
        style={[{
          'cssText': `
            body {
                background: url("https://cdn.streamheroes.gg/3d-objects/bg_item_store.jpg") center center y-repeat fixed;
            }
        `,
        }]}
      />
      {loading ?
        <Loading />
        :
        <div style={{ height: '100vh' }}>
          <ThreeDWrapper noBackdrop>
            <Scene card={card} withLight noAnimation isOblique isRotating />
          </ThreeDWrapper>
        </div>
      }
    </>
  );
};

export default ThreeDCard;