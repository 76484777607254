import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { BrowserRouter as Router } from 'react-router-dom';
import Theme from './styling/theme';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import { CookiesProvider } from 'react-cookie';
import { HelmetProvider } from 'react-helmet-async';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { hotjar } from 'react-hotjar';

import MaintenanceProvider from './components/MaintenanceProvider/MaintenanceProvider';
import reducer from './redux/reducer';
import MainApp from './MainApp';
import GAListener from './listeners/GAListener';

const store = createStore(reducer);

function App() {
  // Get DoNotTrack user preference
  // Deactivate tracking by default to users with DNT
  const dnt = navigator.doNotTrack || window.doNotTrack || navigator.msDoNotTrack;
  const checkDNT = process.env.NODE_ENV === 'test' || dnt === '1' || dnt === 'yes';

  const stripePromise = loadStripe('pk_live_51IQUgjKnqB30luzq9xoUTqteHqFdBSaDTSOsXYbe1ia7lzThoK5oCNOo2HoLOQf2MkrWPPFvT7r4ixM7UHP4X88H00VC4E62sB');

  hotjar.initialize(3086998, 6);

  return (
    <HelmetProvider>
      <ThemeProvider theme={Theme}>
        <MaintenanceProvider>
          <Provider store={store}>
            <CookiesProvider>
              <Elements stripe={stripePromise}>
                <Router>
                  {/* GoogleAnalytics event provider and route change detector */}
                  <GAListener language={navigator.language} trackOptIn={!checkDNT}>
                    <SnackbarProvider maxSnack={3}>
                      <MainApp />
                    </SnackbarProvider>
                  </GAListener>
                </Router>
              </Elements>
            </CookiesProvider>
          </Provider>
        </MaintenanceProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
