import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { zeroPad } from 'react-countdown';
import PropTypes from 'prop-types';

const conf = require('../../conf');

const CardToolCardDesignItem = (props) => {
  const classes = useStyles();
  const { cardDesign, level, size, marginLeft } = props;

  const directory = `${conf.cdn_url}/cards/maker/sets/set${zeroPad(cardDesign)}`;
  const levelDir = `${directory}/Level-${level}`;

  return (
    <div className={classes.itemArea}>
      <img
        src={`${levelDir}/00-background/00.png?height=${size}`}
        alt={'CardDesign0' + cardDesign + '-Background'}
        height={size} />
      <img
        src={`${levelDir}/01-frames/00.png?height=${size}`}
        alt={`CardDesign${zeroPad(cardDesign)}-Level0${level}`}
        style={{ position: 'absolute', zIndex: 1, marginLeft }}
        height={size} />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  itemArea: {
    position: 'relative',
    margin: '0 5px',
    '& img': {
      borderRadius: 4,
    },
  },
}));

CardToolCardDesignItem.propTypes = {
  size: PropTypes.number,
  marginLeft: PropTypes.number,
};

CardToolCardDesignItem.defaultProps = {
  size: 120,
  marginLeft: -80,
};

export default CardToolCardDesignItem;