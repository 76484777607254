import React from 'react';
import { adopt } from 'react-adopt';
import { Query } from 'react-apollo';
import { makeStyles } from '@material-ui/core/styles';
import { getUpvotyToken } from '../../apollo/queries';
import Loading from '../../components/Loading/Loading';
import UpvotyWidget from '../../utils/react-upvoty/index';
import { useMediaQuery } from 'react-responsive';
import { isTabletOrMobileObj, returnMobileObj } from '../../utils/responsive';

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: '30px 128px 0px 128px',
    textAlign: 'center',
  },
  upvotyWidget: {
    display: 'inline-block',
  },
}));

const FeatureContainer = () => {
  const classes = useStyles();

  const isTabletOrMobile = useMediaQuery(isTabletOrMobileObj);

  const Composed = adopt({
    getUpvotyTokenQuery: ({ render }) => (
      <Query query={getUpvotyToken}>
        {render}
      </Query>
    ),
  });

  return (
    <Composed>
      {({ getUpvotyTokenQuery }) => {
        if (getUpvotyTokenQuery.loading) return <Loading />;
        const upvotyToken = (getUpvotyTokenQuery.data) && getUpvotyTokenQuery.data.getUpvotyToken;

        return (
          <div
            className={classes.wrapper}
            style={returnMobileObj(isTabletOrMobile, { padding: 20 })}
          >
            <UpvotyWidget
              ssoToken={upvotyToken}
              baseUrl={'streamheroes.upvoty.com'}
              boardHash={'63506afb00730dc7a10c7585e4f5ab39fb68c539e1a94304b5d3bd23793c7ac6'}
            />
          </div>
        );
      }}
    </Composed>
  );
};

export default FeatureContainer;