import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Helpers from '../../theme/Helpers';
import QualityLine from '../QualityLine/QualityLine';
import Colors from '../../theme/Colors';
import { getLevelWord, getMatchData } from '../../utils/helper';
import HoverCardInfoArea from '../Layout/HoverCardInfoArea';
import { logAmplitudeEvent } from '../../utils/amplitude';
import MergedImage from '../MergedImage/MergedImage';
import AnimationButton from '../Button/AnimationButton';
import {
  setCardIDShown,
  setMarketplaceAllData,
  toggleStorageBoxSelectionCard,
} from '../../redux/actions';
import InventoryCardStorageBox from './InventoryCardStorageBox';
import conf from '../../conf'

const useStyles = makeStyles((theme) =>({
  container: {
    '&:hover': {
      '& $rowBelow': {
        opacity: 1,
        marginTop: 5,
        marginBottom: 0,
      },
      '& $itemArea': {
        marginTop: 0,
      },
      '& $imageArea > div': {
        display: 'initial',
      },
    },
  },
  itemArea: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    width: 230,
    marginTop: 25,
    cursor: 'pointer',
    position: 'relative',
    padding: 20,
    transition: 'all 0.3s ease-out',
    border: '2px solid transparent',

    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      padding: 8,
    },
  },
  rowBelow: {
    ...Helpers.fillRowCross,
    gap: 4,
    marginTop: -41,
    marginBottom: 25,
    opacity: 0,
    transition: 'all 0.3s ease-out',

    [theme.breakpoints.down('md')]: {
      opacity: 1,
      marginTop: 5,
      marginBottom: 0,
    },
  },
  midAvatar: {
    width: 48,
    height: 48,
    border: 'none',
    marginRight: 8,
  },
  imageArea: {
    position: 'relative',
    cursor: 'pointer',
    margin: '17px 0 20px',
    ...Helpers.fillColCenter,
  },
  coloredBGLine: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: 3,
    borderRadius: '6px 6px 0px 0px',
  },
  countArea: {
    position: 'absolute',
    top: -10,
    right: -10,
    width: 14,
    height: 14,
    background: '#E12138',
    boxShadow: '0px 0px 16px #0000006B',
    fontSize: 12,
    fontWeight: 500,
    padding: 8,
    ...Helpers.textCenter,
    borderRadius: '50%',
  },
}));

const InventoryCard = (props) => {
  const classes = useStyles();
  const {
    t,
    card,
    switchLastHover,
    isLegacy,
    cardDecks,
    storageBoxes,
    notMy,
    variables,
    sellOnly,
  } = props;
  const { cards, isSelectionMode } = storageBoxes;
  const [openMenu, setOpenMenu] = useState(false);

  if (isLegacy)
    card.rarity = card.match_card.level ? card.match_card.level : 0;

  const levelWord = getLevelWord(null, card.rarity);
  const levelColor = Colors.level[levelWord];
  const isCardForStorageBoxSelected = cards && cards.length > 0 && cards.includes(card._id);

  const inspectCard = () => {
    if (conf.track_amplitude) logAmplitudeEvent('CardView', {
      'Card ID': card._id,
      'Owner Name': name,
    });
    
    props.setCardIDShown(card._id);
  };

  const openSellDialog = () => props.setMarketplaceAllData({
    rCard: card,
    cardId: card.card_template,
    quality: card.quality,
  });

  const {
    avatar,
    name,
    url,
  } = getMatchData(isLegacy ? card.match_card : card.card_template_metadata, 48, 48);

  return (
    <>
      <div className={classes.container} onMouseLeave={() => openMenu ? setOpenMenu(false) : null}>
        <div
          className={classes.itemArea}
          onClick={() => isSelectionMode ? props.toggleStorageBoxSelectionCard(card._id, card) : inspectCard()}
          style={isCardForStorageBoxSelected ? {
            boxShadow: 'inset 0px 0px 26px #09B7119C',
            borderColor: '#09B711',
          } : (isSelectionMode ? { borderColor: 'rgba(255, 255, 255, 0.25)' } : {})}
        >

          {openMenu &&
          <div style={{
            background: 'rgba(0, 0, 0, 0.5)',
            borderRadius: 6,
            position: 'absolute',
            left: -2,
            right: -2,
            top: -2,
            bottom: -2,
            zIndex: 10,
          }} />
          }

          <div>
            {(isCardForStorageBoxSelected || isSelectionMode) &&
            <i className={`fa-solid fa-circle${isCardForStorageBoxSelected ? '-check' : ''}`}
               style={{
                 position: 'absolute',
                 right: 12,
                 top: 12,
                 fontSize: 22,
                 transition: 'all 0.3s ease-out',
                 color: isCardForStorageBoxSelected ? '#09B711' : 'rgba(255, 255, 255, 0.25)',
               }} />
            }

            {!isLegacy &&
            <div className={classes.coloredBGLine} style={{
              background: `linear-gradient(90deg, ${levelColor}00 0%, ${levelColor} 49%, ${levelColor}00 100%)`,
            }} />
            }
            <div style={Helpers.fillRowCross}
                 onClick={() => isSelectionMode ? null : props.history.push(url)}>
              <Avatar
                className={classes.midAvatar}
                alt="Avatar"
                src={avatar}
              />
              <div>
                <div style={{
                  fontSize: 16,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  width: 150,
                }}>
                  {name}
                </div>
              </div>
            </div>

            <div
              className={classes.imageArea}
              style={(isLegacy || (card.quality && card.quality < 0)) ? { marginBottom: 8 } : {}}
            >
              <MergedImage isLegacy={isLegacy} card={isLegacy ? card.match_card : card} height={318}
                           borderRadius={8} />
              {isLegacy &&
              <div className={classes.countArea}>
                {card.count}
              </div>
              }

              {!openMenu &&
              <HoverCardInfoArea card={card} t={t} switchLastHover={switchLastHover} />
              }
            </div>

            <div
              style={{ marginBottom: (isLegacy || (card.quality && card.quality < 0)) ? -12 : -18 }}>
              {isLegacy || (card.quality && card.quality < 0) ?
                <div style={{
                  ...Helpers.textCenter,
                  width: 'calc(100% - 40px)',
                  padding: 20,
                  fontSize: 14,
                  color: Colors.halfWhite,
                  background: 'rgba(255, 255, 255, 0.02)',
                  borderRadius: 6,
                }}>
                  {t('no_quality_available')}
                </div>
                :
                <QualityLine quality={card.quality} spaceBetween={1} />
              }
            </div>
          </div>

          <InventoryCardStorageBox
            t={t}
            card={card}
            cardDecks={cardDecks}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            isCardForStorageBoxSelected={isCardForStorageBoxSelected}
            selectedBoxId={storageBoxes.id}
            variables={variables}
          />
        </div>

        {!notMy &&
        <div className={classes.rowBelow}>
          <AnimationButton
            status="whiteTransparency"
            onClick={inspectCard}
            style={{
              fontFamily: 'Montserrat',
              fontSize: 14,
              fontWeight: 500,
              width: '100%',
              padding: 14,
            }}
          >
            {t('inspect_card')}
          </AnimationButton>

          <AnimationButton
            status="whiteTransparency"
            onClick={() => setOpenMenu(!openMenu)}
            style={{ padding: '14px 21px' }}
          >
            <i className="fa-light fa-ellipsis-vertical" style={{ fontSize: 18 }} />
          </AnimationButton>
        </div>
        }
        {sellOnly &&
        <div className={classes.rowBelow}>
          <AnimationButton
            status="whiteTransparency"
            onClick={openSellDialog}
            style={{
              fontFamily: 'Montserrat',
              fontSize: 14,
              fontWeight: 500,
              width: '100%',
              padding: 14,
            }}
          >
            {t('place_on_marketplace')}
          </AnimationButton>
        </div>
        }
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { storageBoxes } = state;
  return { storageBoxes };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    toggleStorageBoxSelectionCard,
    setMarketplaceAllData,
    setCardIDShown,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InventoryCard));