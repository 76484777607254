import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import Loading from '../Loading/Loading';
import ItemStoreBoostersGroup from '../ItemStore/ItemStoreBoostersGroup';

const MatchBoosters = (props) => {
  const { t } = useTranslation();
  const { loading, boosters, myViewer, redeemBooster, redeemLoading } = props;

  const now = moment();
  const availableBoosters = [], expiredBoosters = [];
  if (boosters) {
    for (let booster of boosters) {
      const isEvent = booster.redeemable_from && booster.redeemable_until;
      const finishedAt = booster.redeemable_until && moment.unix(booster.redeemable_until / 1000);
      if (isEvent) {
        const startedAt = moment.unix(booster.redeemable_from / 1000);
        if (startedAt.isBefore(now) && finishedAt.isAfter(now))
          availableBoosters.push(booster);
        else
          expiredBoosters.push(booster);
      } else {
        if (finishedAt && finishedAt.isBefore(now))
          expiredBoosters.push(booster);
        else
          availableBoosters.push(booster);
      }
    }
  }

  return (
    loading ?
      <Loading />
      :
      <>
        <ItemStoreBoostersGroup
          boosters={availableBoosters}
          t={t}
          withCount
          headText={t('available_card_booster')}
          loading={loading}
          myViewer={myViewer}
          redeemBooster={redeemBooster}
          redeemLoading={redeemLoading}
        />
        {(expiredBoosters && expiredBoosters.length > 0) &&
        <ItemStoreBoostersGroup
          boosters={expiredBoosters}
          t={t}
          headText={t('expired_card_booster')}
          loading={loading}
          myViewer={myViewer}
          redeemBooster={redeemBooster}
          redeemLoading={redeemLoading}
        />
        }
      </>
  );
};

export default MatchBoosters;