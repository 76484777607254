import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import SVGIcon from '../SVGIcon/SVGIcon';

import conf from '../../conf.json';

const useStyles = makeStyles((theme) => ({
  supportButton: {
    background: 'rgba(255, 255, 255, 0.06)',
    padding: 16,
    height: 30,
    ...Helpers.fillRowCenter,
    color: Colors.white,
    fontSize: 14,
    fontWeight: 500,
    cursor: 'pointer',
    transition: 'all 0.3s ease-out',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.16)',
    },

    [theme.breakpoints.down('md')]: {
      padding: '16px 10px',
    },
  },
}));

const TopBarSHButton = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { pathname } = props;

  if (pathname.includes('/card-shop') || pathname.includes('/premium'))
    return null;

  return (
    <div style={{ borderLeft: '3px solid rgb(1, 16, 36)' }}>
      <Link
        to="/premium"
        className={classes.supportButton}
      >
        <SVGIcon
          src={`${conf.cdn_url}/icons/logo.svg`}
          height={35}
          width={33}
          style={{ marginRight: 8 }}
        />
        {t('support_sh')}
      </Link>
    </div>
  );
};

export default TopBarSHButton;