import { withStyles } from '@material-ui/styles';
import Slider from '@material-ui/core/Slider';
import React from 'react';

const PrettoSlider = withStyles(() => ({
  root: {
    color: '#a10f27',
    height: 8,
    width: 400,
  },
  thumb: {
    height: 24,
    width: 24,
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  mark: {
    backgroundColor: 'transparent',
  },
  markActive: {
    backgroundColor: 'transparent',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
}))(Slider);

export default PrettoSlider;