import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { connect } from 'react-redux';
import Link from '@material-ui/core/Link';

import Colors from '../../theme/Colors';
import { hideForPathURL, useQueryParams } from '../../utils/helper';
import Helpers from '../../theme/Helpers';
import { isTabletOrMobileObj, returnMobileObj } from '../../utils/responsive';
import EventPopupWrapper from '../Dialog/EventPopupWrapper';
import PremiumThankYou from '../Premium/PremiumThankYou';
import HoverIcon from '../Icon/HoverIcon';
import Symplr from '../AdSense/Symplr';

import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  footer: {
    maxWidth: 1440,
    margin: '20px auto 0',
  },
  innerFooter: {
    backgroundColor: Colors.headerFooter,
    fontSize: 13,
    padding: '14px 60px',
    textTransform: 'uppercase',
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    flexWrap: 'wrap',
  },
  mobileInnerFooter: {
    ...Helpers.fillColMain,
    ...Helpers.crossStart,
    position: 'relative',
  },
  list: {
    ...Helpers.fillCol,
  },
  copyrightText: {
    fontSize: 14,
  },
  textSeparator: {
    padding: '0px 5px 0px 5px',
  },
  footerLink: {
    color: Colors.footerLinkColor,
    textDecoration: 'none',
    cursor: 'pointer',
    margin: '5px 0',
    fontSize: 14,
    '&:hover': {
      color: Colors.white,
    },
  },
  underArea: {
    padding: 20,
    ...Helpers.fillRowCenter,
    color: '#2D3C55',
    position: 'relative',
  },
  brandText: {
    paddingLeft: 20,
    borderLeft: '1px solid #ABABAB',
    marginLeft: 20,
    height: 50,
    fontSize: 18,
    ...Helpers.fillColCenter,
    '& > div:hover': {
      cursor: 'pointer',
      color: Colors.white,
    },
  },
  bannerFooter: {
    position: 'fixed',
    bottom: 0,
    height: 62,
    zIndex: 100,
    background: '#E1213880',
    backdropFilter: 'blur(50px)',
    color: Colors.white,
    '& > div': {
      margin: '0 auto',
      maxWidth: 1440,
      height: '100%',
      ...Helpers.fillRowCenter,
      ...Helpers.mainSpaceBetween,
    },
  },
  goToButton: {
    background: '#F8D005',
    borderRadius: 4,
    padding: '14px 52px',
    color: '#011024',
    cursor: 'pointer',
    textDecoration: 'none',
    fontSize: 14,
    fontWeight: '600',
    transition: 'all 0.3s ease-out',
    '&:hover': {
      boxShadow: '0px 0px 15px #F8D0057A',
    },
  },
  spanBold: {
    '& > span': {
      fontWeight: '700',
    },
  },
}));

/**
 * @return {null}
 */
function Footer(props) {
  const isTabletOrMobile = useMediaQuery(isTabletOrMobileObj);
  const { t } = useTranslation();
  const classes = useStyles();
  const { popupEvents, marginLeft, marginRight, myViewer, isPremiumActive } = props;
  const [showPremium, setShowPremium] = useState(false);
  const [hideNews, setHideNews] = useState(false);
  const queryParams = useQueryParams();
  const premium = queryParams.get('premium');

  const showFooterNotification = false;

  useEffect(() => {
    if (premium && premium === 'success' && !showPremium)
      setShowPremium(true);
  }, [premium]);

  if (hideForPathURL(props.location.pathname) || props.location.pathname.includes('/card-shop/streamer/'))
    return null;

  const mainClasses = [classes.innerFooter];
  if (isTabletOrMobile)
    mainClasses.push(classes.mobileInnerFooter);

  return (
    <>
      {(conf.ad_area.footer && myViewer && !isPremiumActive) &&
      <div style={{ ...Helpers.fillRowCenter, marginTop: 20 }}>
        <Symplr id="streamheroes.gg_lb_2" />
      </div>
      }
      <div
        className={classes.footer}
        style={returnMobileObj(isTabletOrMobile, { padding: '0 10px' })}
      >
        <div className={mainClasses.join(' ')}>
          <div className={classes.list}>
            <Link
              className={classes.footerLink}
              href="/page/faq"
              underline="none"
              style={isTabletOrMobile ? { marginRight: 0, marginBottom: 10 } : {}}
            >
              {t('faq')}
            </Link>
            <a
              className={classes.footerLink}
              href="https://discord.gg/WJE6b8M"
              target="_blank"
              rel="noopener noreferrer"
              style={isTabletOrMobile ? { marginRight: 0 } : {}}
            >
              Discord
            </a>
            <a
              className={classes.footerLink}
              href="https://instagram.com/streamheroesgg"
              target="_blank"
              rel="noopener noreferrer"
              style={isTabletOrMobile ? { marginRight: 0 } : {}}
            >
              Instagram
            </a>
          </div>
          <div className={classes.list}>
            <a
              className={classes.footerLink}
              href="https://twitter.com/streamheroes"
              target="_blank"
              rel="noopener noreferrer"
              style={isTabletOrMobile ? { marginRight: 0 } : {}}
            >
              Twitter
            </a>
            <a
              className={classes.footerLink}
              href="https://facebook.com/streamheroesgg"
              target="_blank"
              rel="noopener noreferrer"
              style={isTabletOrMobile ? { marginRight: 0 } : {}}
            >
              Facebook
            </a>
            <a
              className={classes.footerLink}
              href="https://www.youtube.com/channel/UC3g9YeDY-yWAEhs5pH-QOjg"
              target="_blank"
              rel="noopener noreferrer"
              style={isTabletOrMobile ? { marginRight: 0 } : {}}
            >
              Youtube
            </a>
          </div>
          <div className={classes.list}>
            <Link
              className={classes.footerLink}
              href="/request-feature"
              underline="none"
              style={isTabletOrMobile ? { marginRight: 0, marginBottom: 10 } : {}}
            >
              {t('feature')}
            </Link>
            <a
              className={classes.footerLink}
              href="https://help.own3d.tv/hc/en-us/requests/new"
              target="_blank"
              rel="noopener noreferrer"
              style={isTabletOrMobile ? { marginRight: 0, marginBottom: 10 } : {}}
            >
              {t('customer_support')}
            </a>
          </div>
          <div className={classes.list}>
            <Link
              className={classes.footerLink}
              href="/page/terms-and-conditions"
              underline="none"
              style={isTabletOrMobile ? { marginRight: 0, marginBottom: 10 } : {}}
            >
              {t('terms_conditions')}
            </Link>
            <a
              className={classes.footerLink}
              href="https://www.streamheroes.gg/privacy"
              target="_blank"
              rel="noopener noreferrer"
              style={isTabletOrMobile ? { marginRight: 0, marginBottom: 10 } : {}}
            >
              {t('privacy_policy')}
            </a>
            <Link
              className={classes.footerLink}
              href="/page/imprint"
              underline="none"
              style={isTabletOrMobile ? { marginRight: 0, marginBottom: 10 } : {}}
            >
              {t('imprint')}
            </Link>
          </div>
        </div>
        <div className={classes.underArea}>
          <div className={classes.copyrightText}>
            {t('footer_copyright', {
              year: moment()
              .year(),
            })}
          </div>
          <div className={classes.copyrightText} style={{ position: 'absolute', right: 20 }}>
            {t('footer_part_of')}
            <Link href="https://stream.tv" underline="none" target="_blank">
              <img
                src={`${conf.cdn_url}/assets/template/stream-tv-logo.png`}
                alt="Stream.TV"
                style={{ marginLeft: 5 }}
              />
            </Link>
          </div>
        </div>
      </div>
      {popupEvents && popupEvents.events.length > 0 &&
      <EventPopupWrapper event={popupEvents.events[0]} />
      }
      {showPremium &&
      <PremiumThankYou handleClose={() => setShowPremium(false)} />
      }

      {(!sessionStorage.getItem('news_read') && !hideNews && !isTabletOrMobile && showFooterNotification) &&
      <div className={classes.bannerFooter} style={{ left: marginLeft, right: marginRight }}>
        <div>
          <div style={Helpers.fillRowCross}>
            <img src={`${conf.cdn_url}/assets/template/extension_logo.png`} alt="Extension logo"
                 style={{ marginRight: 10 }} />
            <div className={classes.spanBold}
                 dangerouslySetInnerHTML={{ __html: t('new_feature_footer_text') }} />
          </div>

          <div style={Helpers.fillRowCross}>
            <Link
              /*href={i18n.language === 'de' ? 'https://blog.streamheroes.gg/de/werbeanzeigen-supporter-subscription/' : 'https://blog.streamheroes.gg/the-streamheroes-supporter-subscription-ads/'}*/
              href="/premium"
              // target="_blank"
              underline="none"
              className={classes.goToButton}
            >
              {/*{t('go_to_blogpost')}*/}
              {t('new_feature_footer_button')}
            </Link>
            <HoverIcon
              icon="fa-light fa-xmark" hoverIcon="fa-regular fa-xmark"
              hoverColor={Colors.white} color={Colors.halfWhite}
              style={{ fontSize: 18, marginLeft: 20 }}
              onClick={() => {
                sessionStorage.setItem('news_read', 'true');
                setHideNews(true);
              }}
            />
          </div>
        </div>
      </div>
      }
    </>
  );
}

const mapStateToProps = (state) => {
  const { popupEvents } = state;
  return { popupEvents };
};

export default connect(mapStateToProps)(withTranslation()(withRouter(Footer)));