import React from 'react';
import { makeStyles, TableContainer } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import HRNumbers from 'human-readable-numbers';

import Helpers from '../../theme/Helpers';
import CustomAvatar from '../Avatar/CustomAvatar';
import Colors from '../../theme/Colors';
import LoadMore from '../Loading/LoadMore';

import conf from '../../conf.json';
import { isUserPremium } from '../../utils/helper';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles({
  rankItem: {
    width: '100%',
    border: '1px solid transparent',
    '&:nth-child(odd)': {
      background: 'rgba(255, 255, 255, 0.04)',
    },
    '& td': {
      height: 67,
      fontSize: 14,
      '&:first-child': {
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
      },
      '&:last-child': {
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
      },
    },
  },
  headRow: {
    '& td': {
      padding: '20px 0',
      color: Colors.halfWhite,
      fontSize: 12,
    },
  },
  aHover: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

const ProfileCommunityRankedBodyTable = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { seasonData, onLoadMore, totalCount, isMobile } = props;

  if (seasonData && seasonData.length === 0)
    return (
      <div style={{ marginTop: 32, marginLeft: 26 }}>{t('no_league_member')}</div>
    );

  // don't show the table if no other is there
  if (!seasonData || (seasonData && seasonData.length < 4))
    return null;

  const getPremiumState = (viewer) => {
    const { isPremiumActive } = isUserPremium(viewer);

    return isPremiumActive;
  }

  const displayGatheredEnergy = !!seasonData[0]?.gathered_energy;
  const displayCollectors = !!seasonData[0]?.unique_collectors;

  return (
    <div style={{ marginTop: 8, width: '100%' }}>
      <TableContainer>
      <table style={{
        width: '100%',
        borderSpacing: 0,
        padding: '0 16px',
        background: 'rgba(255, 255, 255, 0.04)',
      }}>
        <thead>
        <tr className={classes.headRow}>
          <td style={{ ...Helpers.textCenter, width: 60 }}>{t('rank')}</td>
          <td style={{ width: 63 }} />
          <td>{t('name')}</td>
          {displayCollectors &&
          <td>{t('collectors')}</td>
          }
          {displayGatheredEnergy &&
          <td style={{ ...Helpers.textCenter }}>{t('gathered_energy')}</td>
          }
          <td style={{ ...Helpers.textCenter }}>{t('redeemed_boosters')}</td>
          <td style={{ ...Helpers.textCenter }}>
            {t('ranked_points')}
          </td>
        </tr>
        </thead>
        <tbody>
        {seasonData && seasonData.slice(isMobile ? 0 : 3)
        .map(data => (
          (data.viewer || data.streamer) &&
          <tr
            key={`SeasonItemKey${(data.viewer || data.streamer).login}`}
            className={classes.rankItem}
          >
            <td style={{ ...Helpers.textCenter, fontSize: 20, fontWeight: 500 }}>
              {data.position}
            </td>
            <td
              style={{ cursor: 'pointer' }}
              onClick={() => props.history.push(`/profile/${(data.viewer || data.streamer).login}`)}
            >
              <CustomAvatar
                seasonBorder={data.viewer && data.viewer.season_border}
                rootSize={47}
                avatarSize={34}
                leagueSize={6}
                src={(data.viewer || data.streamer).profile_image_url}
                alt={(data.viewer || data.streamer).display_name}
                top={{ avatar: 9, league: 36 }}
                isPremiumActive={data.viewer && getPremiumState(data.viewer)}
              />
            </td>
            <td
              className={classes.aHover}
              style={{ cursor: 'pointer' }}
              onClick={() => props.history.push(`/profile/${(data.viewer || data.streamer).login}`)}
            >
              {(data.viewer || data.streamer).display_name}
            </td>
            {displayCollectors &&
            <td>
              <i className="fa-light fa-user" style={{ fontSize: 18, marginRight: 8 }} />
              {data.unique_collectors.toLocaleString()}
            </td>
            }
            {displayGatheredEnergy &&
            <td style={{ ...Helpers.textCenter }}>
              <img
                src={`${conf.cdn_url}/assets/template/heroMaker/energy.png?height=18`}
                alt="Energy"
                style={{ marginRight: 5, verticalAlign: 'middle' }} />

              {HRNumbers.toHumanString(data.gathered_energy)}
            </td>
            }
            <td style={{ ...Helpers.textCenter }}>
              <img
                src={`${conf.cdn_url}/assets/seasons/season_1/SH_Season1_BoosterPack_Icon_18x26.png?height=18`}
                alt="Booster pack"
                style={{ marginRight: 5, verticalAlign: 'middle' }} />

              {data.redeemed_booster.toLocaleString()}
            </td>
            <td style={{ ...Helpers.textCenter }}>
              <span style={{
                background: 'rgba(255, 255, 255, 0.04)',
                padding: '6px 10px',
                borderRadius: 4,
              }}>
                {(data.points || data.redeemed_booster * 5).toLocaleString()}
              </span>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
      </TableContainer>

      {onLoadMore !== undefined &&
      <LoadMore
        count={seasonData && seasonData.length}
        totalCount={seasonData && totalCount}
        onLoadMore={onLoadMore}
      />
      }
    </div>
  );
};

export default withRouter(ProfileCommunityRankedBodyTable);