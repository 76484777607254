import React from 'react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import { makeStyles } from '@material-ui/core/styles';

import Colors from '../../theme/Colors';

const useStyles = makeStyles(() => ({
  headLine: {
    color: Colors.white,
  },
  body: {
    background: 'transparent',
    color: '#4B90CF',
    marginTop: 10,
    boxShadow: '0px 2px 1px -1px #1B2C43',
  },
  expandStyling: {
    color: '#4B90CF !important',
    fill: '#4B90CF !important',
  },
}));

const AccordionArea = (props) => {
  const classes = useStyles();
  const { title, text } = props;

  return (
    <Accordion square classes={{
      root: classes.body,
    }}>
      <AccordionSummary
        expandIcon={<FontAwesomeIcon icon={faChevronDown} size="sm" color={Colors.white} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        classes={{
          root: classes.headLine,
          expandIcon: classes.expandStyling,
        }}
      >
        <div>{title}</div>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ paddingTop: 10 }} dangerouslySetInnerHTML={{ __html: text }} />
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionArea;