import React, { useEffect, useRef, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import { useAnimations, useGLTF, useTexture } from '@react-three/drei';
import * as THREE from 'three';
import { LoopOnce } from 'three';

import { rangeArray } from '../../utils/helper';

import conf from '../../conf.json';

const CardObject = (props) => {
  const {
    frontImage,
    backImage,
    frontEffect,
    backEffect,
    isTestMode,
    isRotating,
    noAnimation,
    isOblique,
    noWhiteCard,
    noAbrasion,
    noEffectLayer,
    loopAnimation,
  } = props;
  // Set up state for the hovered and active state
  const [hovered, setHover] = useState([false, false, false, false, false]);
  const [clicked, setClicked] = useState(false);

  // This reference will give us direct access to the mesh
  const group = useRef();
  // load all textures
  const [cardFrontSide, cardBackSide, frontEffectTexture, backEffectTexture] = useTexture([
    frontImage.includes('data:image') ? frontImage : `${conf.cdn_url + frontImage}`,
    `${conf.cdn_url + backImage}`,
    frontEffect,
    backEffect,
    /*`https://streamheroes-image-cdn.s3.amazonaws.com/3d-objects/sticker_test2.png`,
    `https://streamheroes-image-cdn.s3.amazonaws.com/3d-objects/Cami_ueber_rand_schauen.png`,*/
  ]);
  const { nodes, animations } = useGLTF(`${conf.cdn_url}/3d-objects/new_card_v.glb`);
  const { actions } = useAnimations(animations, group);

  useEffect(() => {
    // actions['ArmatureAction'].play();

    if (!noAnimation && actions) {
      setTimeout(() => {
        for (const key of Object.keys(actions)) {
          if (actions[key]) {
            // If clampWhenFinished is set to true the animation will automatically be paused on its last frame.
            actions[key].clampWhenFinished = true;

            if (loopAnimation) {
              actions[key].timeScale = 0.5;
              actions[key].play();
            } else {
              actions[key].play()
              .halt();

              // Sets the loop mode and the number of repetitions. This method can be chained.
              // LoopOnce - playing the clip once
              actions[key].setLoop(LoopOnce, 1)
              .reset()
              .play();
            }
          }
        }
      }, 400);
    }
  });

  useFrame(() => {
    if (isRotating && !clicked)
      group.current.rotation.y += 0.01;
  });

  const setHoverIdx = (idx, value) => {
    const tmpHover = [false, false, false, false, false];
    tmpHover[idx] = value;
    setHover(tmpHover);
  };

  /**
   * Must be scaled based on the image size, otherwise the image would always be drawn quadratically
   *
   * @param img
   * @returns {[number, number]}
   */
  const scaleImageSizeForCoords = (img) => {
    const { image } = img;
    const { width, height } = image;

    if (width === height)
      return [0.75, 0.75];
    else if (width > height) {
      const per = (height * 100) / width;
      const val = (per * 0.75) / 100;
      return [0.75, val];
    } else if (height > width) {
      const per = (width * 100) / height;
      const val = (per * 0.75) / 100;
      return [val, 0.75];
    }
  };

  // vertical flip
  cardFrontSide.flipY = false;
  cardBackSide.flipY = false;
  if (!noEffectLayer) {
    frontEffectTexture.flipY = false;
    backEffectTexture.flipY = false;
    // horizontal flip
    backEffectTexture.wrapS = THREE.RepeatWrapping;
    backEffectTexture.repeat.x = -1;
  }
  // horizontal flip
  cardBackSide.wrapS = THREE.RepeatWrapping;
  cardBackSide.repeat.x = -1;

  // position and rotation for the background card image
  const bgRotation = [0, 3.1416, 0];
  const bgPosition = [0, 0, -0.005];
  const scale = 3.8;
  const stickerPositions = [[-0.7, 1.2], [0.7, 1.2], [0, 0], [-0.7, -1.2], [0.7, -1.2]];
  const rotations = [0.3, -0.7, -0.2, 0.6, 1];

  return (
    <group
      ref={group}
      {...props}
      dispose={null}
      rotation={[isOblique ? -0.4 : 0, 0, 0]}
      onClick={(e) => setClicked(true)}
      onPointerLeave={(e) => setClicked(false)}
    >
      <group name="Card001" rotation={[Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Card001_1.geometry} scale={scale}>
          <meshStandardMaterial map={cardBackSide} />
        </mesh>
        {!noEffectLayer &&
        <mesh geometry={nodes.Card001_1.geometry} scale={scale}>
          <meshStandardMaterial map={backEffectTexture} transparent={true} />
        </mesh>
        }
        <mesh geometry={nodes.Card001_2.geometry} scale={scale}>
          <meshStandardMaterial map={cardFrontSide} />
        </mesh>
        {!noEffectLayer &&
        <mesh geometry={nodes.Card001_2.geometry} scale={scale}>
          <meshStandardMaterial map={frontEffectTexture} transparent={true} />
        </mesh>
        }
        <mesh geometry={nodes.Card001_3.geometry} scale={scale}>
          <meshStandardMaterial color="white" />
        </mesh>
      </group>
    </group>
    /*<group
      ref={group}
      rotation={[isOblique ? -0.4 : 0, 0, 0]}
      onClick={(e) => setClicked(true)}
      onPointerLeave={(e) => setClicked(false)}
    >
      <group scale={scale}>
        <group rotation={bgRotation} position={bgPosition}>
          <mesh
            geometry={geometry}
            <meshStandardMaterial map={cardBackSide} />
          </mesh>
          {!noAbrasion &&
          <mesh
            geometry={geometry}
          >
            <meshStandardMaterial map={backEffectTexture} transparent={true} />
          </mesh>
          }
        </group>
        <group>
          <mesh geometry={geometry}>
            <meshStandardMaterial map={cardFrontSide} />
          </mesh>
          {!noAbrasion &&
          <mesh geometry={geometry}>
            <meshStandardMaterial map={frontEffectTexture} transparent={true} depthWrite={false} />
          </mesh>
          }
        </group>
        {!noWhiteCard &&
        <WhiteCardObject geometry={geometry} />
        }
      </group>
      {isTestMode && rangeArray(0, 4)
      .map(i => (
          <>
            <mesh
              key={`Sticker${i}`}
              scale={hovered[i] ? 1.2 : 1}
              position={[...stickerPositions[i], 0.0002]}
              rotation={[0, 0, rotations[i]]}
              onPointerOver={(event) => setHoverIdx(i, true)}
              onPointerOut={(event) => setHoverIdx(i, false)}
            >
              <planeGeometry args={scaleImageSizeForCoords(i === 1 ? camiImage : stickerImage)} />
              <meshStandardMaterial map={i === 1 ? camiImage : stickerImage} alphaTest={0.5}
                                    transparent={true} />
            </mesh>
            {hovered[i] &&
            <mesh
              key={`HoverSticker${i}`}
              scale={1.225}
              position={[...stickerPositions[i], 0.0001]}
              rotation={[0, 0, rotations[i]]}
            >
              <planeGeometry args={scaleImageSizeForCoords(i === 1 ? camiImage : stickerImage)} />
              <meshStandardMaterial map={i === 1 ? camiImage : stickerImage} alphaTest={0.5}
                                    color="red"
                                    transparent={true} />
            </mesh>
            }
          </>
        ),
      )}
    </group>*/
  );
};

export default CardObject;