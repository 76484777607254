import {
  SET_BOOSTER_ACTION_DATA, RESET_BOOSTER_ACTION_DATA, SET_CARD_ID_SHOWN, SET_BOOSTER_LAST_REDEEM,
} from './constants';

const INITIAL_STATE = {
  openFunction: null,
  matchType: null,
  name: null,
  availableBoosters: null,
  cardIdShown: null,
  isAlbum: false,
  lastRedeem: null,
};

const boosterActionReducer = (state = INITIAL_STATE, action) => {
  if (action.type === SET_BOOSTER_ACTION_DATA) {
    return { ...state, ...action.data };
  } else if (action.type === SET_CARD_ID_SHOWN) {
    return { ...state, cardIdShown: action.cardID, isAlbum: action.isAlbum };
  } else if (action.type === SET_BOOSTER_LAST_REDEEM) {
    return { ...state, lastRedeem: action.lastRedeem };
  } else if (action.type === RESET_BOOSTER_ACTION_DATA) {
    return INITIAL_STATE;
  } else {
    return state;
  }
};

export default boosterActionReducer;