import moment from 'moment';

export const useCacheWithExpiration = (key, variables = {}, expiration = 1000 * 60 * 5) => {
  let fetchPolicy;

  try {
    let ttlQueries = JSON.parse(sessionStorage.getItem('ttlQueries'));
    if (ttlQueries === null)
      ttlQueries = {};

    if (Object.keys(variables).length > 0)
      key += `(${JSON.stringify(variables)})`;

    const lastFetch = ttlQueries[key];

    if (lastFetch === undefined || moment()
    .diff(moment(lastFetch)) > expiration) {
      try {
        ttlQueries[key] = moment()
        .toISOString();

        fetchPolicy = 'network-only';
        sessionStorage.setItem('ttlQueries', JSON.stringify(ttlQueries));
      } catch (e) {
        console.warn(`Failed to save time of last fetch for ${key}`);
      }
    } else {
      fetchPolicy = '';
    }
  } catch (e) {
    fetchPolicy = 'network-only';
  }

  return fetchPolicy;
};