import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Helpers from '../../theme/Helpers';
import {
  setCollectorsViewMarketplace, setCollectorsViewSelectSellInfo,
  setMarketplaceAllData,
} from '../../redux/actions';
import { preventEvent } from '../../utils/helper';
import Colors from '../../theme/Colors';

const useStyles = makeStyles(() => ({
  marketButton: {
    border: '1px solid rgba(255, 255, 255, 0.08)',
    padding: '14px 18px',
    width: '100%',
    ...Helpers.fillRowCenter,
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 500,
    transition: 'all 0.3s ease-out',
    position: 'relative',

    '&:hover': {
      background: 'rgba(255, 255, 255, 0.08)',
    },
    '&.left': {
      borderRadius: '3px 0px 0px 3px',
      borderRightWidth: 0.5,
    },
    '&.right': {
      borderRadius: '0px 3px 3px 0px',
      borderLeftWidth: 0.5,
    },
    '&.disabled': {
      color: 'rgba(255, 255, 255, 0.3)',
      cursor: 'not-allowed',
      '&:hover': {
        background: 'none',
      },
    },
  },
  badge: {
    background: '#FF4067',
    borderRadius: '50%',
    width: 20,
    height: 20,
    ...Helpers.fillRowCenter,
    position: 'absolute',
    fontSize: 12,
    top: -5,
    right: -5,
    color: Colors.white,
  },
}));

const MarketplaceBuySellButton = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { item, style = {}, withText = true } = props;

  const openSellDialog = () => {
    const obj = {
      cardId: item.card._id,
      quality: item.progress.quality,
    };

    if (item.progress.owned_count === 1)
      props.setMarketplaceAllData(obj);
    else
      props.setCollectorsViewSelectSellInfo(obj);
  };

  return (
    <div style={{ ...Helpers.fillRowCenter, ...style }} onClick={(e) => preventEvent(e)}>
      <div
        className={[classes.marketButton, 'left'].join(' ')}
        onClick={() => props.setCollectorsViewMarketplace({
          quality: item.progress.quality,
          rarity: item.card.rarity,
          match: item.card.match_meta_data._id,
        })}
        style={withText ? { padding: 14 } : {}}
      >
        <i className="fa-light fa-arrow-up-right"
           style={{ fontSize: 18, marginRight: withText ? 8 : 0 }} />
        {withText && t('buy_on_market')}
        {item.progress.marketplace_listings ?
          <div className={classes.badge}>
            {item.progress.marketplace_listings > 9 ? '+9' : item.progress.marketplace_listings}
          </div> : <></>
        }
      </div>
      <div
        className={[
          classes.marketButton,
          'right',
          item.progress.sellable ? '' : 'disabled',
        ].join(' ')}
        onClick={item.progress.sellable ? openSellDialog : null}
        style={withText ? { padding: 14 } : {}}
      >
        <i className="fa-light fa-arrow-down-right"
           style={{ fontSize: 18, marginRight: withText ? 8 : 0 }} />
        {withText && t('sell_on_market')}
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setMarketplaceAllData,
    setCollectorsViewMarketplace,
    setCollectorsViewSelectSellInfo,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(MarketplaceBuySellButton);