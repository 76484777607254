import React, { useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';

import OrderFormCheck from '../Order/OrderFormCheck';
import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import EmptyPopup from '../Dialog/EmptyPopup';
import AnimationButton from '../Button/AnimationButton';

const CheckAddressPopup = (props) => {
  const { t } = useTranslation();
  const { setShowCheckAddress, setEditMode, setAcceptWrongAddress, isTabletOrMobile } = props;
  const [checked, setChecked] = useState(false);

  return (
    <EmptyPopup
      handleClose={() => setShowCheckAddress(false)}
      title={t('form_check_wrong_address_title')}
      subTitle={t('form_check_wrong_address_subtitle')}
      isTabletOrMobile={isTabletOrMobile}
    >
      <div style={{ marginBottom: 20, fontWeight: 'bold' }}>{t('order_check_address')}</div>
      <OrderFormCheck />
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={(event) => setChecked(event.target.checked)}
            style={{ color: Colors.white }}
          />
        }
        label={t('order_wrong_address_checkbox')}
      />
      <div style={{
        ...Helpers.fillRowCenter,
        ...Helpers.mainSpaceBetween,
        marginTop: 20,
        flexWrap: 'wrap',
      }}>
        <AnimationButton
          disabled={!checked}
          onClick={() => {
            setAcceptWrongAddress(true);
            setEditMode(false);
            setShowCheckAddress(false);
          }}
        >
          {t('confirm')}
        </AnimationButton>
        <AnimationButton onClick={() => setShowCheckAddress(false)}>
          {t('cancel')}
        </AnimationButton>
      </div>
    </EmptyPopup>
  );
};

export default CheckAddressPopup;