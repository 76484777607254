import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { zeroPad } from 'react-countdown';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/react-hooks';

import { sendBoosterToApproval as sendBoosterToApprovalMutation } from '../../apollo/mutations';
import { getAllBooster } from '../../apollo/queries';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import { getRandomInt, multiplyEnergyCoins } from '../../utils/helper';
import AnimationButton from '../Button/AnimationButton';
import BoosterCreatePopup from './BoosterCreatePopup';
import BoosterPopup from '../Dialog/BoosterPopup';
import HoverIcon from '../Icon/HoverIcon';

import conf from '../../conf.json';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles({
  box: {
    padding: '30px 44px',
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
  },
  rowLine: {
    padding: '14.5px 0',
    borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    '& > div:first-child': {
      color: Colors.halfWhite,
    },
  },
});

const BoosterHead = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { isStandard, boosterData } = props;
  const [showEdit, setShowEdit] = useState(false);
  const [showBoosterSelection, setShowBoosterSelection] = useState(false);
  const {
    boosterName,
    energyCosts,
    startDate,
    endDate,
    boosterIsApproved,
    boosterId,
    cards,
  } = boosterData;

  const [sendBoosterToApproval, { loading }] = useMutation(sendBoosterToApprovalMutation, {
    onCompleted: (data) => {
      const { success, error_code } = data.sendBoosterToApproval;
      if (success) {
        enqueueSnackbar(t('success'), { variant: 'success' });
        props.history.push('/streamer-settings/booster-management');
      } else {
        let errorMsg = '';
        if (error_code === 1)
          errorMsg = 'to_less_special_cards';
        else if (error_code === 2)
          errorMsg = 'level_and_special_mixed';
        else if (error_code === 3)
          errorMsg = 'not_all_rarities';
        else if (error_code === 4)
          errorMsg = 'rarities_not_same';

        enqueueSnackbar(t(`error_msg.${errorMsg}`), { variant: 'error' });
      }
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [
      { query: getAllBooster },
    ],
  });

  const sendToApproval = () => {
    sendBoosterToApproval({ variables: { id: boosterId } });
  };

  const random = getRandomInt(19);
  const readyToApprove = boosterData.allData && boosterData.allData.ready_to_approve;
  const boosterImage = boosterData.allData ? boosterData.allData.front_image : `/booster-packs/new/sh-base-booster-pack-${zeroPad(random)}.png`;

  return (
    <>
      <div style={{ ...Helpers.fillRow }}>
        <div className={classes.box}
             style={{ marginRight: 8, ...Helpers.fillColCenter, position: 'relative' }}>
          <div>
            {t('booster_preview')}
          </div>
          <div style={{ color: Colors.halfWhite, fontSize: 12, marginTop: 8, marginBottom: 27 }}>
            {t(boosterIsApproved ? 'booster_approved' : 'booster_not_approved')}
          </div>

          <img
            src={`${conf.cdn_url + boosterImage}?height=227`}
            alt="Boosterpack design"
          />

          {(!boosterIsApproved && !readyToApprove) &&
          <HoverIcon
            icon="fa-light fa-pencil" hoverIcon="fa-solid fa-pencil"
            color={Colors.white}
            style={{ position: 'absolute', top: 130, right: 57 }}
            onClick={() => setShowBoosterSelection(true)}
          />
          }
        </div>

        <div className={classes.box} style={{ marginLeft: 8, width: '100%', padding: '20px 32px' }}>
          <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween, marginBottom: 60 }}>
            <div>
              {boosterName}
            </div>

            <div>
              {(!boosterIsApproved && !readyToApprove) &&
              <>
                <AnimationButton
                  status="whiteTransparency"
                  style={{ padding: 11, marginRight: 8, fontSize: 18 }}
                  onClick={() => setShowEdit(true)}
                  disabled={loading}
                >
                  <i className="fa-light fa-pencil" />
                </AnimationButton>

                <AnimationButton
                  status="neonGreen"
                  style={{ fontFamily: 'Montserrat', fontSize: 14, marginRight: -12 }}
                  onClick={loading ? null : sendToApproval}
                  disabled={isStandard ? cards && (cards.length === 0 || cards.length % 5 !== 0) : cards && (cards.length < 3)}
                >
                  {loading ?
                    <i className="fa-light fa-loader fa-spin" style={{ fontSize: 18 }} />
                    :
                    t('send_to_approval')
                  }
                </AnimationButton>
              </>
              }
            </div>
          </div>

          <div className={classes.rowLine}>
            <div>{t('cards_in_booster')}:</div>
            <div>{cards && cards.length ? cards.length : 0}</div>
          </div>
          <div className={classes.rowLine}>
            <div>{t('energy_costs')}:</div>
            <div style={Helpers.fillRowCross}>
              <img
                src={`${conf.cdn_url}/assets/template/heroMaker/energy.png?height=18`}
                alt="Energy"
                style={{ marginRight: 8 }} />
              <div>{multiplyEnergyCoins(isStandard ? 3 : energyCosts)}</div>
            </div>
          </div>
          {(!isStandard && startDate) &&
          <div className={classes.rowLine} style={{ borderBottom: 'none' }}>
            <div>{t('start_end_date')}:</div>
            <div>
              {startDate.format('LL')} {endDate && `- ${endDate.format('LL')}`}
            </div>
          </div>
          }
        </div>
      </div>

      {showEdit &&
      <BoosterCreatePopup
        isStandard={isStandard}
        handleClose={() => setShowEdit(false)}
        editMode
      />
      }
      {showBoosterSelection &&
      <BoosterPopup
        handleClose={() => setShowBoosterSelection(false)}
        boosterId={boosterId}
      />
      }
    </>
  );
};

export default withRouter(BoosterHead);