import React from 'react';
import { ClickAwayListener, makeStyles, TableBody } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import Colors from '../../theme/Colors';
import CustomButton from '../Button/CustomButton';
import Helpers from '../../theme/Helpers';
import { calculateVAT } from '../../utils/helper';
import { getOrderInvoice } from '../../apollo/queries';
import OrderSupportContact from '../Order/OrderSupportContact';

const conf = require('../../conf');

const useStyles = makeStyles(() => ({
  statusArea: {
    ...Helpers.fillRow,
    ...Helpers.crossStart,
    ...Helpers.mainSpaceBetween,
    background: '#040F21',
    color: Colors.white,
    padding: 40,
    ...Helpers.textCenter,
  },
  statusText: {
    textTransform: 'uppercase',
    '& span': {
      color: '#D68D3C',
    },
  },
  table: {
    '& .MuiTableCell-root': {
      borderBottomColor: '#1E2B41',
    },
    '& th': {
      color: '#3A537D',
      textTransform: 'uppercase',
    },
  },
}));

const OrderInfo = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { order, setSelectedOrder } = props;

  const { data } = useQuery(getOrderInvoice, {
    variables: { id: order._id },
    skip: (order.status !== 'Completed' && order.status !== 'Payment_completed') || !order.invoice,
  });

  const orderURL = data && data.getOrderInvoice;

  const resetOrder = () => {
    setSelectedOrder(null);
  };

  const updatePrice = (price) => {
    const newPrice = order.isNewVersion ? price / 100 : price;

    return newPrice.toFixed(2)
    .replace('.', ',');
  };

  const redirectToBill = () => {
    if (orderURL)
      window.open(orderURL, '_blank');
  };

  const steps = [
    {
      image: 'https://cdn.streamheroes.gg/assets/step_cart.png',
      alt: 'Cart',
      text: t('step_cart'),
      success: order.status === 'Ripped' || order.status === 'Payment_completed' || order.status === 'Sent_to_fulfillment' || order.status === 'Completed',
      time: order.state_history && order.state_history.find(state => state.name === 'payment_completed' || state.name === 'sent_to_fulfillment'),
    },
    {
      image: 'https://cdn.streamheroes.gg/assets/step_printing.png',
      alt: 'Print',
      text: t('step_printing'),
      success: order.status === 'Ripped' || order.status === 'Completed',
      time: order.state_history && order.state_history.find(state => state.name === 'ripped'),
    },
    {
      image: 'https://cdn.streamheroes.gg/assets/step_shipping.png',
      alt: 'Shipping',
      text: t('step_shipping'),
      success: order.status === 'Completed',
      time: order.state_history && order.state_history.find(state => state.name === 'completed'),
    },
    {
      image: 'https://cdn.streamheroes.gg/assets/step_delivered.png',
      alt: 'Delivered',
      text: t('step_delivered'),
      success: false,
    },
  ];
  const isTreePlanted = order.plantedTreeURL;

  const orderDateUnix = moment(order.date)
  .valueOf();
  const freeAlbumTime = {
    start: 1639522800000,
    end: 1642201200000,
  };
  const freeAlbumIsActivated = orderDateUnix >= freeAlbumTime.start && orderDateUnix < freeAlbumTime.end;

  return (
    <ClickAwayListener onClickAway={() => setSelectedOrder(null)}>
      <div style={{ ...Helpers.fillRow, ...Helpers.mainSpaceBetween }} className="inner-wrapper">
        <div style={{ flexGrow: 1, marginRight: 25 }}>
          <div style={{
            display: 'flex',
            justifyContent: ((orderURL && order.status === 'Completed') || isTreePlanted) ? 'space-between' : 'flex-end',
            marginBottom: 30,
            color: Colors.white,
          }}>
            <CustomButton onClick={resetOrder} style={{ borderRadius: 5 }}>
              {t('back')}
            </CustomButton>
            {(orderURL && order.status === 'Completed') &&
            <CustomButton onClick={redirectToBill} style={{ borderRadius: 5 }}>
              {t('invoice')}
            </CustomButton>
            }
            {isTreePlanted &&
            <CustomButton
              status="green"
              style={{
                borderRadius: 5,
                color: Colors.white, ...Helpers.fillRowCenter,
              }}
              onClick={() => window.open(order.plantedTreeURL, '_blank')}
            >
              <img
                src={`${conf.cdn_url}/assets/template/icons/plant_a_tree_icon_white.png?height=20`}
                alt="Plant a tree icon"
                style={{ marginBottom: 3, marginRight: 5 }}
              />
              {t('show_certificate')}
            </CustomButton>
            }
          </div>

          <div
            style={{
              ...Helpers.fillRowCross,
              color: '#6F7F99', ...Helpers.mainEnd,
              marginBottom: 20,
            }}>
            <div style={{ marginRight: 40 }}>
              {t('order_ordered_on')} {order.date}
            </div>
            <div>
              {t('order_order_nr')} #{order._id}
            </div>
          </div>

          <div className={classes.statusArea}>
            {steps.map((step, index) => (
              <>
                <div key={step.alt} style={{ opacity: step.success ? 1.0 : 0.5 }}>
                  <img src={step.image} alt={step.alt} />
                  <div
                    className={classes.statusText}
                    dangerouslySetInnerHTML={{ __html: t(step.text) }}
                  />
                  {step.time &&
                  <div>{moment(parseInt(step.time.created_at))
                  .format('LLL')}</div>
                  }
                </div>
                {index < steps.length - 1 &&
                <img
                  style={{
                    opacity: steps[index + 1].success ? 1.0 : 0.5,
                    margin: 'auto 0',
                    paddingBottom: order.isNewVersion ? 70 : 50,
                  }}
                  src="https://cdn.streamheroes.gg/assets/white_big_arrow.png"
                  alt="Arrow"
                />
                }
              </>
            ))}
          </div>

          <TableContainer style={{ background: '#051329', marginTop: 40 }}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 600 }}>
                    Articlenumber
                  </TableCell>
                  <TableCell style={{ fontWeight: 600 }} colSpan="2">
                    {t('order_product')}
                  </TableCell>
                  <TableCell style={{ fontWeight: 600 }} align="right">
                    {t('form_amount')}
                  </TableCell>
                  <TableCell style={{ fontWeight: 600 }} colSpan="2" align="right">
                    {t('order_costs')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {freeAlbumIsActivated &&
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <img
                      src="https://cdn.streamheroes.gg/assets/shop/product_album.png?height=60"
                      alt="Collectors album"
                      style={{ borderRadius: 4 }}
                    />
                  </TableCell>
                  <TableCell style={{ fontWeight: 400, color: 'rgb(233, 153, 34)' }}>
                    {t('free')}: {t('collectors_album')}
                  </TableCell>
                  <TableCell style={{ fontWeight: 400, color: 'rgb(233, 153, 34)' }} align="right">
                    1x
                  </TableCell>
                  <TableCell style={{ fontWeight: 400, color: 'rgb(233, 153, 34)' }}>
                    0,00 €
                  </TableCell>
                  <TableCell style={{ fontWeight: 400, color: 'rgb(233, 153, 34)' }} align="right">
                    0,00 €
                  </TableCell>
                </TableRow>
                }
                {order.packins && order.packins.map((product, index) =>
                  <TableRow key={`PackinIdxKey#${index}`}>
                    <TableCell style={{ fontWeight: 400, color: '#8697B3' }}>
                      {product.product_id._id}
                    </TableCell>
                    <TableCell>
                      <img
                        src={`${product.product_id.image}?height=60`}
                        alt="Packin"
                        style={{ borderRadius: 4 }}
                      />
                    </TableCell>
                    <TableCell style={{ fontWeight: 400 }}>
                      {t(product.product_id.t_string)}
                    </TableCell>
                    <TableCell style={{ fontWeight: 400 }} align="right">
                      {product.quantity}x
                    </TableCell>
                    <TableCell style={{ fontWeight: 400 }}>
                      {updatePrice(product.unit_price)} €
                    </TableCell>
                    <TableCell style={{ fontWeight: 400 }} align="right">
                      {updatePrice(product.quantity * product.unit_price)} €
                    </TableCell>
                  </TableRow>,
                )}
                {order.albums && order.albums.map((album, index) =>
                  <TableRow key={`AlbumIdxKey#${index}`}>
                    <TableCell />
                    <TableCell>
                      {(album.image && album.image !== '') &&
                      <img
                        src={`${album.image}?height=60`}
                        alt="Album"
                        style={{ borderRadius: 4 }}
                      />
                      }
                    </TableCell>
                    <TableCell style={{ fontWeight: 400 }}>
                      {t(album.name)}
                    </TableCell>
                    <TableCell style={{ fontWeight: 400 }} align="right">
                      {album.quantity}x
                    </TableCell>
                    <TableCell style={{ fontWeight: 400 }}>
                      {updatePrice(album.unit_price)} €
                    </TableCell>
                    <TableCell style={{ fontWeight: 400 }} align="right">
                      {updatePrice(album.quantity * album.unit_price)} €
                    </TableCell>
                  </TableRow>,
                )}
                {order.products.map((product, index) =>
                  <TableRow key={`ProductIdxKey#${index}`}>
                    <TableCell style={{ fontWeight: 400, color: '#8697B3' }}>
                      {product.product_id}
                    </TableCell>
                    <TableCell>
                      {product.processing &&
                      <img
                        src={`${product.processing.design.url}?height=60`}
                        alt="Card"
                        style={{ borderRadius: 4 }}
                      />
                      }
                    </TableCell>
                    <TableCell style={{ fontWeight: 400 }}>
                      {product.name}
                    </TableCell>
                    <TableCell style={{ fontWeight: 400 }} align="right">
                      {product.quantity}x
                    </TableCell>
                    <TableCell style={{ fontWeight: 400 }}>
                      {updatePrice(product.unit_price)} €
                    </TableCell>
                    <TableCell style={{ fontWeight: 400 }} align="right">
                      {updatePrice(product.quantity * product.unit_price)} €
                    </TableCell>
                  </TableRow>,
                )}
                <TableRow>
                  <TableCell style={{ fontWeight: 400 }} colSpan="5" align="right">
                    {t('order_shipping_title')}
                  </TableCell>
                  <TableCell style={{ fontWeight: 400 }} align="right">
                    {updatePrice(order.ship_cost)} €
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan="5" align="right">
                    {t('order_total')}
                  </TableCell>
                  <TableCell align="right">
                    {updatePrice(order.total)} €
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 400 }} colSpan="5" align="right">
                    {t('order_vat_percent', { percent: order.tax_rate })}
                  </TableCell>
                  <TableCell style={{ fontWeight: 400 }} align="right">
                    {updatePrice(calculateVAT(order.total, order.tax_rate))} €
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <OrderSupportContact />
      </div>
    </ClickAwayListener>
  );
};

export default withRouter(OrderInfo);