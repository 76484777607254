import React from 'react';
import { withRouter } from 'react-router-dom';
import { BrowserView, isBrowser } from 'react-device-detect';

import Loading from '../../components/Loading/Loading';
import MobileViewComponent from '../MobileView/MobileView';
import { useQueryParams } from '../../utils/helper';

const conf = require('../../conf');

const Login = (props) => {
  const queryParams = useQueryParams();
  const referral = queryParams && queryParams.get('state');
  const urlOrigin = window.location.origin;
  let baseUrl = conf.base_url;
  const isPreview = !urlOrigin.includes('app.');

  if (isPreview)
    baseUrl = conf.base_url_PREVIEW;

  if (isBrowser) {
    const userToken = window.localStorage.getItem(conf.storage_keys.user_token);
    if (userToken)
      props.history.push('/hq/hub');
    else {
      const twitchConf = conf.twitch;
      const redirectURI = `${baseUrl}/handle-login`;
      const clientId = isPreview ? twitchConf.preview_client_id : twitchConf.client_id;
      // The server returns this string to you in your redirect URI
      const additional = referral && referral.length > 4 ? `&state=${referral}` : '';

      window.location.href = `${twitchConf.oauth_url}?client_id=${clientId}&redirect_uri=${redirectURI}&response_type=code&scope=${twitchConf.scopes}${additional}`;
    }
  }

  return (
    <>
      <BrowserView>
        <Loading />
      </BrowserView>
      <MobileViewComponent />
    </>
  );
};

export default withRouter(Login);