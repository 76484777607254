import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { connect } from 'react-redux';

import { getTypeCards } from '../../apollo/queries';
import { getMaximalLevelOfCards } from '../../utils/helper';

import Loading from '../../components/Loading/Loading';
import Cards from '../../components/Cards/Cards';
import ErrorMessage from '../../components/ErrorBoundary/ErrorMessage';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import CardsOpenGraphHeader from '../../components/Cards/CardsOpenGraphHeader';
import moment from 'moment';

function CardsContainer(props) {
  const {
    streamer,
    organisation,
    isSpecial,
    myCollectedPoints,
    isArchived,
    specialTabs,
    setSpecialTabs,
    specialTab,
    setSpecialTab,
    streamerOrganisation,
    setTab,
  } = props;
  const [started, setStarted] = useState(false);

  let variables = {};
  if (streamer)
    variables.streamer = streamer._id;
  else if (organisation)
    variables.organisation = organisation._id;

  if (isArchived)
    variables.filter = {
      isArchived: true,
    };

  const getTypeCardsQuery = useQuery(getTypeCards, {
    variables,
    fetchPolicy: useCacheWithExpiration('getTypeCards', variables),
  });
  const { loading, error, data } = getTypeCardsQuery;

  if (loading) return <Loading />;
  if (error) return <ErrorMessage error={error} />;

  let cards = (data) && data.getTypeCards;
  const maxLevelCard = getMaximalLevelOfCards(cards);

  const coinsInformation = {
    maxLevelCard,
    viewerRedeemedPoints: myCollectedPoints,
  };

  if (cards && cards.length > 2 && (streamer || organisation)) {
    if (isSpecial) {
      cards.sort((a, b) => (b.match_card.collectible_until - a.match_card.collectible_until));
      if (!started)
        setStarted(true);
    } else if (isArchived)
      cards.sort((a, b) => (a.match_card.created_at - b.match_card.created_at));
    else {
      cards.sort((a, b) => (a.match_card.level - b.match_card.level));
      if (!started) {
        setStarted(true);
        const now = moment()
        .unix();

        const specialCards = cards.filter(card => card && card.match_card && card.match_card.edition);

        specialCards.map(({ match_card }) => {
          if (match_card.collectible_from && match_card.collectible_until && match_card.edition) {
            const startedAt = parseInt(match_card.collectible_from) / 1000;
            const finishedAt = parseInt(match_card.collectible_until) / 1000;

            if (startedAt < now && finishedAt > now) {
              setTab('special');
              setSpecialTab(match_card.edition);
            }
          }

          return null;
        });
      }
    }
  }

  if (cards) {
    const tmpSpecialTabs = new Set();
    cards = cards.filter(card => {
      if (isSpecial && card.match_card.edition && !specialTabs)
        tmpSpecialTabs.add(card.match_card.edition);

      if (isSpecial)
        if (specialTab)
          return card.match_card.edition === specialTab;
        else
          return card.matchCardModel === 'SpecialCard';
      else
        return card.matchCardModel === 'Card';
    });

    if (isSpecial)
      cards.sort((a, b) => (a.match_card.sorting > b.match_card.sorting) ? 1 : -1);

    if (tmpSpecialTabs.size > 0) {
      const tmpArray = Array.from(tmpSpecialTabs);
      if (!specialTab)
        setSpecialTab(tmpArray[0]);
      setSpecialTabs(tmpArray.map(tab => {
        return {
          key: tab,
          value: tab,
        };
      }));
    }
  }

  if (coinsInformation && !coinsInformation.viewerRedeemedPoints)
    coinsInformation.viewerRedeemedPoints = {
      points_season: 0,
      redeemed_points: 0,
    };

  return (
    <>
      <CardsOpenGraphHeader cards={cards} streamer={streamer} organisation={organisation} />
      <Cards
        cards={cards}
        coinsInformation={coinsInformation}
        organisation={streamerOrganisation ? streamerOrganisation : null}
        hideProgresses={isArchived}
        maxLevelCard={maxLevelCard}
      />
    </>
  );
}

CardsContainer.propTypes = {
  streamer: PropTypes.object,
  isSpecial: PropTypes.bool,
};

CardsContainer.defaultProps = {
  isSpecial: false,
};

const mapStateToProps = (state) => {
  const { extra } = state;
  return { extra };
};

export default connect(mapStateToProps)(CardsContainer);