import * as React from 'react';
import PropTypes from 'prop-types';
import ISO6391 from 'iso-639-1';

import CustomTooltip from '../Tooltip/CustomTooltip';
import RoundedFlagIcon from './RoundedFlagIcon';

const LanguageWithTooltip = (props) => {
  const { code, lang, size } = props;

  const language = ISO6391.getName(lang);

  return (
    <CustomTooltip title={language ? language : '...'}>
      <div>
        <RoundedFlagIcon code={code} size={size} withMargin={false} />
      </div>
    </CustomTooltip>
  );
};

LanguageWithTooltip.propTypes = {
  code: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  size: PropTypes.string,
};

LanguageWithTooltip.defaultProps = {
  size: 'lg',
};

export default LanguageWithTooltip;