import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputAdornment, TextField } from '@material-ui/core';

import CardShopFilterHead from '../CardShop/CardShopFilterHead';
import QualityLine from '../QualityLine/QualityLine';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';

const useStyles = makeStyles({
  filterItem: {
    width: 'calc(100% - 20px)',
    background: 'rgba(255, 255, 255, 0.04)',
    padding: '16px 10px',
    marginTop: 8,
    borderRadius: 6,
    '& div, & svg': {
      cursor: 'pointer',
    },
  },
  textField: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(255, 255, 255, 0.12)',
      borderRadius: 6,
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.halfWhite,
    },
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
      fontSize: 14,
      fontFamily: 'Montserrat',
      '&::placeholder': {
        color: Colors.halfWhite,
      },
    },
    '& .MuiOutlinedInput-notchedOutline legend': {
      color: 'green',
    },
  },
});

const QualityFilter = (props) => {
  const classes = useStyles();
  const { t, checked, type, value, handleChange, setQuality } = props;

  const changeValue = (isMin, newValue) => {
    const tmpValue = { ...value };
    let intValue = parseInt(newValue);

    if (isMin && intValue >= value.max)
      intValue = value.max - 1;
    if (!isMin && intValue <= value.min)
      intValue = value.min + 1;

    if (intValue > 100)
      intValue = 100;
    else if (intValue < 0)
      intValue = 0;

    tmpValue[isMin ? 'min' : 'max'] = intValue;

    setQuality(tmpValue);
  };

  return (
    <div className={classes.filterItem}>
      <CardShopFilterHead
        type_string={type}
        checked={checked}
        handleChange={handleChange}
        type={type}
        t={t}
      />
      {checked &&
      <div style={{ marginTop: 25 }}>
        <QualityLine
          quality={[value.min, value.max]}
          spaceBetween={2}
          width={[19, 94, 144, 46, 19]}
          hasNoText
          changeValue={changeValue}
          setQuality={setQuality}
        />

        <div style={{ ...Helpers.fillRow, marginTop: 12 }}>
          <TextField
            variant="outlined"
            onChange={(event) => changeValue(true, event.target.value)}
            value={value.min}
            className={classes.textField}
            style={{ marginRight: 5 }}
            label={t('from')}
            inputProps={{
              type: 'number',
              min: 0,
              max: 100,
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
          />

          <TextField
            variant="outlined"
            onChange={(event) => changeValue(false, event.target.value)}
            value={value.max}
            className={classes.textField}
            style={{ marginLeft: 5 }}
            label={t('to')}
            inputProps={{
              type: 'number',
              min: 0,
              max: 100,
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
          />
        </div>
      </div>
      }
    </div>
  );
};

export default QualityFilter;