import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { isOrderPossible } from '../../apollo/queries';

import Helpers from '../../theme/Helpers';
import AnimationButton from '../Button/AnimationButton';
import ItemStoreBoosterPack from '../ItemStore/ItemStoreBoosterPack';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

import conf from '../../conf.json';

const useStyles = makeStyles({
  head: {
    ...Helpers.fillColCenter,
    backgroundImage: `url("${conf.cdn_url}/Events/Trilluxe-RapidLan-September-2022/trilluxe-rapidlansep2022-bg.jpg")`,
    height: 505,
    width: '100%',
    borderRadius: 6,
  },
  countdownInfo: {
    marginTop: 16,
    '& > div > div:first-child': {
      display: 'none',
    },
  },
  offerArea: {
    height: 474,
    width: 1140,
    borderRadius: 8,
    position: 'relative',
  },
  headText: {
    margin: '100px 0 52px',
    textTransform: 'initial',
    fontSize: 36,
    fontWeight: 600,
    ...Helpers.textCenter,
  },
  priceBanner: {
    position: 'absolute',
    right: 150,
    top: 80,
    fontSize: 42,
    lineHeight: 1.2,
    fontFamily: 'Bebas Neue',
    fontWeight: 'bold',
    '& div': {
      fontSize: 60,
      marginTop: -10,
    },
  },
  cardArea: {
    padding: '42px 28.5px',
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 8,
  },
});

const ProfileEventBoosterLine = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { myViewer, currentOffer, streamer, index } = props;

  const variables = { offer: currentOffer._id };
  const { data } = useQuery(isOrderPossible, {
    variables,
    fetchPolicy: useCacheWithExpiration('isOrderPossible', variables),
  });

  const orderPossible = (data) && data.isOrderPossible;
  const isPossible = orderPossible && orderPossible.possible;

  const offerStarted = moment.unix(currentOffer.started_at / 1000);
  const offerEnded = moment.unix(currentOffer.finished_at / 1000);
  const booster = currentOffer.required_booster[0];
  booster.match = streamer;
  const costs = currentOffer ? currentOffer.products.reduce((acc, product) => acc + (product.costs / 100), 0.00) : 0.00;

  return (
    <div style={{ ...Helpers.fillRowCross, marginTop: index === 1 ? 50 : 0 }}>
      {index === 1 &&
      <>
        <ItemStoreBoosterPack booster={{
          ...currentOffer.required_booster[0],
          match: streamer,
        }} t={t} myViewer={myViewer} />
        <ItemStoreBoosterPack booster={{
          ...currentOffer.required_booster[1],
          match: streamer,
        }} t={t} myViewer={myViewer} />
      </>
      }

      <div
        className={classes.offerArea}
        style={{
          backgroundImage: `url("${conf.cdn_url}/Events/Trilluxe-RapidLan-September-2022/trilluxe-rapidlansep2022-sales-banner${index > 0 ? '2' : ''}.jpg")`,
          backgroundPosition: 'right',
        }}
      >
        {index === 0 &&
        <div className={classes.priceBanner}>
          {/*{t('offer_limited_to', { count: currentOffer.limit_overall })}<br />*/}
          {t('offer_limited_to', { count: 1000 })}<br />
          <div>{costs && costs.toLocaleString()}€</div>
        </div>
        }

        <div style={{ padding: '0 47px', height: '100%', ...Helpers.fillColMain }}>
          {index === 1 ?
            <>
              <div style={{
                fontSize: 60,
                fontFamily: 'Bebas Neue',
                fontWeight: 600,
                margin: 0,
                textShadow: '2px 2px #000000',
              }}>
                TRILLUXE BUNDLE
              </div>
              <div style={{
                fontSize: 42,
                fontFamily: 'Bebas Neue',
                fontWeight: 600,
                margin: 0,
                marginTop: -8,
                textShadow: '2px 2px #000000',
              }}>
                HOLO PACK + RAPIDLAN PACK
              </div>
              <div style={{
                fontSize: 60,
                fontFamily: 'Bebas Neue',
                lineHeight: 1.2,
                fontWeight: 600,
                margin: 0,
                textShadow: '2px 2px #000000',
              }}>
                {costs && costs.toLocaleString()}€
              </div>
            </>
            :
            <div
              style={{
                fontSize: 60,
                fontFamily: 'Bebas Neue',
                lineHeight: 1,
                fontWeight: 700,
                margin: 0,
              }}
              dangerouslySetInnerHTML={{ __html: t('offer_watch_stream_info') }}
            />
          }

          <div style={{
            fontSize: 24,
            fontFamily: 'Bebas Neue',
            lineHeight: 2.5,
            fontWeight: 700,
            margin: 0,
            textShadow: index === 1 ? '2px 2px #000000' : '',
          }}>
            {t('offer_only_available', {
              start: offerStarted.format('L'),
              end: offerEnded.format('L'),
            })}
          </div>

          <AnimationButton
            status="neonGreen"
            style={{
              padding: 14,
              width: 'calc(349px - 28px)',
              marginTop: 31,
              fontFamily: 'Montserrat',
              fontSize: 14,
            }}
            disabled={!isPossible}
            onClick={() => isPossible ? props.history.push(`/card-shop/streamer/${streamer._id}?offer=${currentOffer._id}`) : null}
          >
            <i className="fa-light fa-cart-shopping" style={{ marginRight: 8, fontSize: 18 }} />
            {t(currentOffer.order_button_text)}
          </AnimationButton>
        </div>
      </div>

      {index === 0 &&
      <ItemStoreBoosterPack booster={booster} t={t} myViewer={myViewer} switchLastHover />
      }
    </div>
  );
};

export default withRouter(ProfileEventBoosterLine);