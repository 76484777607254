import React from 'react';

import ItemStoreBoostersGroup from './ItemStoreBoostersGroup';

const ItemStoreBoostersCompact = (props) => {
  const {
    boosters,
    t,
    // isActive,
    loading,
    trendingBoosters,
    myViewer,
    starterPacks,
    tab,
  } = props;

  return (
    <div style={{
      // marginLeft: isActive ? 408 : 78,
      width: '100%',
      marginTop: -16,
      transition: 'all 0.6s ease-out',
    }}>
      {tab === 'starterpack' &&
      <ItemStoreBoostersGroup
        boosters={starterPacks}
        t={t}
        headText={t('event_starter_pack')}
        loading={loading}
        myViewer={myViewer}
      />
      }

      {tab === 'booster' &&
      <ItemStoreBoostersGroup
        boosters={boosters}
        t={t}
        withCount
        headText={t('available_card_booster')}
        loading={loading}
        myViewer={myViewer}
      />
      }

      {tab === 'top-10-booster' &&
      <ItemStoreBoostersGroup
        boosters={trendingBoosters}
        t={t}
        headText={t('top_10_most_trending_booster')}
        loading={loading}
        myViewer={myViewer}
      />
      }
    </div>
  );
};

export default ItemStoreBoostersCompact;