import React from 'react';
import { Slider, withStyles } from '@material-ui/core';

const EnergySlider = (props) => {
  const { costs, setCosts } = props;

  return (
    <StyledEnergySlider
      value={costs}
      onChange={(event, newValue) => setCosts(newValue)}
      max={100}
      min={1}
    />
  );
};

const StyledEnergySlider = withStyles({
  root: {
    color: 'rgba(255, 255, 255, 0.04)',
    height: 7,
    margin: 0,
  },
  thumb: {
    height: 20,
    width: 20,
    background: '#FFFFFF',
    marginTop: -6,
    marginLeft: -5,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {},
  track: {
    height: 7,
    borderRadius: 40,
    background: 'linear-gradient(90deg, #8B26E8 0%, #F306FC 100%)',
  },
  rail: {
    height: 7,
    borderRadius: 40,
    opacity: 1,
  },
})(Slider);

export default EnergySlider;