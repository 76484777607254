import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, withRouter } from 'react-router-dom';
import moment from 'moment';

import PageTitle from '../PageTitle/PageTitle';
import HeaderLine from '../TopViewHeader/HeaderLine';
import Colors from '../../theme/Colors';
import TopBG from '../TopViewHeader/TopBG';
import Imprint from './Imprint';
import Competition from './Competition';
import FAQ from './FAQ';
import TermsAndConditionsEN from './TermsAndConditionsEN';
import TermsAndConditionsDE from './TermsAndConditionsDE';
import TermsOfUseEN from './TermsOfUseEN';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import TermsOfUseDE from './TermsOfUseDE';
import CooperationAgreementDE from './CooperationAgreementDE';
import CooperationAgreementEN from './CooperationAgreementEN';

const useStyles = makeStyles(() => ({
  innerContainer: {
    backgroundColor: '#01132B',
    position: 'relative',
    padding: 30,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    color: '#4B90CF',
    textAlign: 'justify',
    '& h1, h2': {
      color: Colors.white,
      fontSize: 26,
      fontFamily: 'Bebas Neue',
      fontWeight: 400,
    },
    '& h2': {
      fontSize: 20,
    },
    '& a': {
      color: Colors.primary,
      '&:hover': {
        color: Colors.secondary,
      },
    },
  },
}));

const OnePages = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { page } = useParams();

  const lang = moment.locale();
  const isGerman = lang.includes('de');

  const pages = [
    {
      key: 'imprint',
      value: t('imprint'),
      component: <Imprint />,
      lastUpdate: 'Last update 09.12.2019',
    },
    {
      key: 'terms-and-conditions',
      value: t('terms_conditions'),
      component: isGerman ? <TermsAndConditionsDE /> : <TermsAndConditionsEN />,
      lastUpdate: 'Last update 11.01.2022',
    },
    {
      key: 'terms-of-use',
      value: t('terms_of_use'),
      component: isGerman ? <TermsOfUseDE /> : <TermsOfUseEN />,
      lastUpdate: 'Last update 11.01.2022',
    },
    {
      key: 'cooperation-agreement',
      value: t('cooperation_agreement'),
      component: isGerman ? <CooperationAgreementDE /> : <CooperationAgreementEN />,
      lastUpdate: 'Last update 11.01.2022',
    },
    {
      key: 'faq',
      value: t('faq'),
      component: <FAQ />,
      lastUpdate: 'Last update 09.12.2019',
    },
    {
      key: 'competition',
      value: t('competition'),
      component: <Competition />,
      lastUpdate: 'Last update 07.06.2021',
    },
  ];
  const activePage = pages.find(item => item.key === page);

  if (!activePage)
    return <NotFoundPage />;

  return (
    <>
      <PageTitle title={activePage.value} />
      <TopBG
        style={{
          padding: '40px 0 20px',
          backgroundSize: 'auto',
        }}
      >
        <HeaderLine
          title={activePage.value}
          subTitle={activePage.lastUpdate && activePage.lastUpdate}
        />
        <div style={{ marginTop: 50 }}>
          <div className={classes.innerContainer}>
            {activePage.component}
          </div>
        </div>
      </TopBG>
    </>
  );
};

export default withRouter(OnePages);