import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from 'react-step-progress-bar';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { isMobile } from 'react-device-detect';

import { getTypeAndTimeBasedCoins } from '../../apollo/mergedQueries';
import { upgradeTankSize as upgradeTankSizeMutation } from '../../apollo/mutations';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import AnimationButton from '../Button/AnimationButton';
import { isUserPremium, multiplyEnergyCoins } from '../../utils/helper';
import Symplr from '../AdSense/Symplr';
import DepotUpgradeTooltip from './DepotUpgradeTooltip';

import conf from '../../conf.json';

const useStyles = makeStyles((theme) => ({
  safeContainer: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    position: 'relative',
    height: 'calc(300px - 20px)',
    padding: 10,
    gap: 20,

    [theme.breakpoints.down('md')]: {
      ...Helpers.fillCol,
      height: 'fit-content',
      padding: 8,
      gap: 8,
    },
  },
  safeItem: {
    backgroundColor: '#010B1A80',
    border: '1px solid #051E3E',
    borderRadius: 10,
    padding: 10,
    gap: 10,
    width: 'fit-content',
    color: Colors.white,
    ...Helpers.fillRow,
  },
  title: {
    fontSize: 18,
    lineHeight: 1,
  },
  subTitle: {
    color: '#79859a',
    fontSize: 16,
    lineHeight: 1,
    marginBottom: 10,
  },
  questProgressBar: {
    width: '100%',
    position: 'relative',
    '& > div': {
      minWidth: '100%',
      borderRadius: 50,
    },
    '& .RSPBprogressBarText': {
      ...Helpers.textCenter,
      zIndex: 1,
      position: 'relative',
      paddingTop: 8,
      fontSize: 14,
    },
    '& .RSPBprogression': {
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 0,
    },
  },
}));

const CoinsBank = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();
  const { myViewer, coinsTankInfo } = props;

  const maxCoins = coinsTankInfo ? coinsTankInfo.size : 100;
  const currentCoins = myViewer ? myViewer.sh_coins : 0;
  const currentPercentage = Math.round((currentCoins / maxCoins) * 100);
  const { isPremiumActive } = isUserPremium(myViewer);
  const currentDepotLevel = coinsTankInfo ? coinsTankInfo.level : 1;
  const startDate = moment()
  .subtract(1, 'months')
  .format('YYYY-MM-DD');

  const [upgradeTankSize] = useMutation(upgradeTankSizeMutation, {
    onCompleted: () => {
      enqueueSnackbar(t('success'));
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{
      query: getTypeAndTimeBasedCoins,
      variables: { pagination: { perPage: 25, page: 1 }, startDate },
    }],
  });
  let canUpgradeTank = false;

  if (coinsTankInfo && coinsTankInfo.upgrade_costs <= currentCoins)
    canUpgradeTank = true;

  return (
    <div className={classes.safeContainer}>
      {!isMobile &&
      <div style={{ position: 'absolute', left: '67%', bottom: 0 }}>
        <img
          src={`${conf.cdn_url}/assets/template/chars/bankengineer.png?height=270`}
          alt="Bank engineer"
          // style={{ transform: 'scaleX(-1)' }}
        />
      </div>
      }

      <div style={{ flex: 1 }}>
        <div className={classes.safeItem}>
          <div style={{ ...Helpers.fillColCenter, ...Helpers.mainSpaceBetween }}>
            <img
              src={`${conf.cdn_url}/assets/template/tresor/depot-level-${currentDepotLevel}.png?height=150`}
              alt="Depot"
            />
            <div className={classes.title}>
              {t('depot_sh_depot')}
            </div>
            <div className={classes.subTitle} style={{ marginTop: 10 }}>
              {t('depot_level_depot', { level: currentDepotLevel })}
            </div>
            <div className={classes.questProgressBar}>
              <ProgressBar
                percent={currentPercentage}
                filledBackground={'linear-gradient(to right, #86b819, #dbff29)'}
                unfilledBackground={'linear-gradient(to right, #42630a, #373e0c)'}
                height={35}
                text={`${multiplyEnergyCoins(currentCoins)}/${multiplyEnergyCoins(maxCoins)}`}
              />
            </div>
          </div>
          {coinsTankInfo && coinsTankInfo.level < 9 &&
          <div>
            <div style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', right: 0, top: 0 }}>
                <DepotUpgradeTooltip />
              </div>
            </div>
            <div style={{ ...Helpers.fillColCenter, ...Helpers.mainSpaceBetween }}>
              <img
                src={`${conf.cdn_url}/assets/template/tresor/depot-update-level-${currentDepotLevel}-${currentDepotLevel + 1}.png?height=150`}
                alt="Depot upgrade"
              />
              <div className={classes.title}>
                {t('upgrade_to_level', { level: coinsTankInfo.level + 1 })}
              </div>
              <div className={classes.subTitle} style={{ marginTop: 10 }}>
                {t('depot_add_capacity', { coins: coinsTankInfo ? multiplyEnergyCoins(coinsTankInfo.next_level_size - coinsTankInfo.size) : multiplyEnergyCoins(150) })}
              </div>
              <AnimationButton
                status="gold"
                onClick={upgradeTankSize}
                disabled={!canUpgradeTank}
                style={{ padding: '8px 14px', fontSize: 14 }}
              >
                {t('upgrade')} <img
                src={`${process.env.PUBLIC_URL}/icons-png/sh_coins.png`}
                alt="SH Coins" height={18}
                style={{ margin: '0 5px', verticalAlign: 'text-bottom' }}
              /> {coinsTankInfo ? multiplyEnergyCoins(coinsTankInfo.upgrade_costs) : multiplyEnergyCoins(50)}
              </AnimationButton>
            </div>
          </div>
          }
        </div>
      </div>

      <div
        style={{
          maxWidth: 600, ...Helpers.fillCol, flex: 1,
        }}
      >
        <div style={{
          ...Helpers.textCenter,
          fontSize: 24,
          fontWeight: 700,
          textTransform: 'uppercase',
        }}>
          {t('depot_collect_sh_coins')}
        </div>
        <div
          style={{ marginTop: 20, ...Helpers.textCenter }}
          dangerouslySetInnerHTML={{ __html: t('depot_collect_sh_coins_desc') }}
        />
      </div>

      <div style={{ flex: 1 }}>
        {(conf.ad_area.bank && myViewer && !isPremiumActive) &&
        <Symplr id="streamheroes.gg_mr_1" />
        }
      </div>
    </div>
  );
};

export default CoinsBank;