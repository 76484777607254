import React from 'react';
import { makeStyles } from '@material-ui/core';

import CustomBackdropFilter from '../Layout/CustomBackdropFilter';
import ProfileCommunityRankedLeagueSelection from './ProfileCommunityRankedLeagueSelection';
import Colors from '../../theme/Colors';
import ProfileCommunityRankedBodyTable from './ProfileCommunityRankedBodyTable';
import ProfileCommunityRankedBodyLeaders from './ProfileCommunityRankedBodyLeaders';
import Loading from '../Loading/Loading';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles({
  blurredContainer: {
    width: '100%',
    background: 'rgba(255, 255, 255, 0.04)',
    color: Colors.white,
  },
});

const ProfileCommunityRankedBody = (props) => {
  const classes = useStyles();
  const {
    seasonData,
    currentLeague,
    setCurrentLeague,
    totalCount,
    onLoadMore,
    loading,
  } = props;

  return (
    <CustomBackdropFilter
      className={classes.blurredContainer}
      filter={'blur(30px)'}
      canvasFallback={true}
      html2canvasOpts={{
        allowTaint: true,
      }}
    >
      <div style={{ margin: '0 auto', maxWidth: 1440, paddingTop: 10, paddingBottom: 80 }}>
        <ProfileCommunityRankedLeagueSelection
          currentLeague={currentLeague}
          setCurrentLeague={setCurrentLeague}
        />
        {loading ?
          <Loading />
          :
          <>
            {!isMobile &&
            <ProfileCommunityRankedBodyLeaders
              seasonData={seasonData}
              currentLeague={currentLeague}
            />
            }
            <ProfileCommunityRankedBodyTable
              seasonData={seasonData}
              totalCount={totalCount}
              onLoadMore={onLoadMore}
              isMobile={isMobile}
            />
          </>
        }
      </div>
    </CustomBackdropFilter>
  );
};

export default ProfileCommunityRankedBody;