import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import AnimationButton from '../Button/AnimationButton';
import { copyToClipboard } from '../../utils/helper';
import SVGIcon from '../SVGIcon/SVGIcon';
import ReferAFriendLine from './ReferAFriendLine';

import conf from '../../conf.json';
import HRNumbers from 'human-readable-numbers';
import Loading from '../Loading/Loading';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
  header: {
    background: 'rgba(255, 255, 255, 0.04)',
    height: 'calc(343px - 130px)',
    padding: '65px 0',
  },
  innerWidth: {
    width: 1153,
    margin: '0 auto',
  },
  headerLine: {
    marginTop: 3,
    ...Helpers.fillRowCross,
    '& > div': {
      flex: 1,
      background: 'rgba(255, 255, 255, 0.04)',

      '& > div': {
        width: 510,
        padding: '33px 64px',
        ...Helpers.fillRowCross,
      },
    },
  },
  bigCircle: {
    width: 84,
    height: 84,
    borderRadius: '50%',
    background: 'rgba(255, 255, 255, 0.12)',
    ...Helpers.fillRowCenter,
    marginRight: 16,
  },
  smallText: {
    fontSize: 12,
    color: Colors.halfWhite,
    marginBottom: 6,
  },
  bigText: {
    fontSize: 24,
    fontWeight: 500,
  },
  advantageItem: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 4,
    padding: '20px 16px',
    ...Helpers.fillRowCross,
    '& img': {
      borderRadius: 4,
    },
  },
  table: {
    width: '100%',
    borderSpacing: 0,
    '& thead th': {
      fontWeight: 400,
      fontSize: 12,
      color: Colors.halfWhite,
      padding: '20px 16px',
    },
    '& tbody tr:nth-child(odd)': {
      background: 'rgba(255, 255, 255, 0.04)',
      borderRadius: 6,
    },
    '& tbody td': {
      fontWeight: 500,
      fontSize: 14,
      padding: '24px 16px',
    },
  },
}));

const ReferAFriend = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { loading, referrals, myViewer } = props;

  const referralURL = `${conf.base_url}/referral/${myViewer && myViewer.login}`;
  let totalGems = 0;

  if (referrals) {
    let i;
    for (i = 0; i < referrals.length; i++) {
      if (referrals[i].reward_one_collected)
        totalGems += 10;
      if (referrals[i].reward_two_collected)
        totalGems += 20;
    }
  }

  return (
    <div style={{ color: Colors.white, position: 'relative' }}>
      <div className={classes.header}>
        <div className={classes.innerWidth}>
          <div style={{ fontSize: 26, fontWeight: 600, marginBottom: 32 }}>
            {t('refer_a_friend')}
          </div>

          <div style={{
            color: '#FFFFFF99',
            textTransform: 'uppercase',
            fontSize: 12,
            fontWeight: 500,
            marginBottom: 8,
          }}>
            {t('your_referral_link')}
          </div>

          <div style={Helpers.fillRowCross}>
            <div style={{
              marginRight: 10,
              background: 'rgba(255, 255, 255, 0.01)',
              border: '1px solid #FFFFFF1F',
              borderRadius: 4,
              padding: '8px 16px',
              fontSize: 14,
              flex: 1,
            }}>
              {referralURL}
            </div>

            <AnimationButton
              status="neonRed"
              style={{ fontSize: 14, fontWeight: 600, padding: '8px 16px' }}
              onClick={() => {
                copyToClipboard(referralURL);
                enqueueSnackbar(t('copy_success'), { variant: 'success' });
              }}
            >
              <i className="fa-regular fa-copy" style={{ fontSize: 16, marginRight: 6 }} />
              {t('copy_the_link')}
            </AnimationButton>
          </div>

          <div style={{
            color: '#FFFFFF99',
            fontSize: 14,
            borderTop: '1px solid rgba(255, 255, 255, 0.2)',
            paddingTop: 22.5,
            marginTop: 32.5,
          }}>
            {t('refer_a_friend_desc')}
          </div>
        </div>
      </div>

      <div className={classes.headerLine}>
        <div style={{ marginRight: 1.5 }}>
          <div style={{ marginLeft: 'auto' }}>
            <div className={classes.bigCircle}>
              <i className="fa-light fa-calendar" style={{ fontSize: 24 }} />
            </div>

            <div>
              <div className={classes.smallText}>
                {t('total_referred_friends')}
              </div>
              <div className={classes.bigText}>
                {referrals && referrals.length}
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginLeft: 1.5 }}>
          <div style={{ marginRight: 'auto' }}>
            <div className={classes.bigCircle} style={{ background: 'rgba(225, 33, 56, 0.3)' }}>
              <SVGIcon
                src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
                height={29}
                width={22}
              />
            </div>

            <div>
              <div className={classes.smallText}>
                {t('total_earned_gems')}
              </div>
              <div className={classes.bigText}>
                {totalGems}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: 48 }} className={classes.innerWidth}>

        <div className={classes.bigText} style={{ fontWeight: 600 }}>{t('what_you_get')}</div>

        <div style={{ fontWeight: 300, marginTop: 6, marginBottom: 16, color: Colors.halfWhite }}>
          {t('what_you_get_desc')}
        </div>

        <div style={{
          display: 'grid',
          gap: 17,
          gridTemplateColumns: 'repeat(3, 1fr)',
        }}>
          <div className={classes.advantageItem}>
            <img src="https://via.placeholder.com/155x87" alt="Placeholder" />
            <div style={{ marginLeft: 16 }}>
              <div style={{ fontSize: 14, fontWeight: 'bold' }}>
                {t('get_free_months')}
              </div>
              <div style={{ fontSize: 14, marginTop: 4, color: '#FFFFFF99' }}>
                {t('get_free_months_desc')}
              </div>
            </div>
          </div>

          <div className={classes.advantageItem}>
            <img src="https://via.placeholder.com/155x87" alt="Placeholder" />
            <div style={{ marginLeft: 16 }}>
              <div style={{ fontSize: 14, fontWeight: 'bold' }}>
                {t('gain_credits')}
              </div>
              <div style={{ fontSize: 14, marginTop: 4, color: '#FFFFFF99' }}>
                {t('gain_credits_desc')}
              </div>
            </div>
          </div>
          <div className={classes.advantageItem}>
            <img src="https://via.placeholder.com/155x87" alt="Placeholder" />
            <div style={{ marginLeft: 16 }}>
              <div style={{ fontSize: 14, fontWeight: 'bold' }}>
                {t('feature_3')}
              </div>
              <div style={{ fontSize: 14, marginTop: 4, color: '#FFFFFF99' }}>
                {t('feature_3_desc')}
              </div>
            </div>
          </div>

        </div>

      </div>

      <div style={{ marginTop: 64 }} className={classes.innerWidth}>

        <div className={classes.bigText} style={{ fontWeight: 600 }}>{t('your_referral_list')}</div>

        <div style={{ fontWeight: 300, marginTop: 6, marginBottom: 16, color: Colors.halfWhite }}>
          {t('your_referral_list_desc')}
        </div>

        <div style={{
          background: 'rgba(255, 255, 255, 0.05)',
          borderRadius: 4,
          width: '100%',
          padding: '20px 17px',
        }}>
          {loading ?
            <div style={{ width: '100%', ...Helpers.fillColCenter }}>
              <Loading />
            </div>
            :
            <table className={classes.table}>
              <thead>
              <tr>
                <th align="left">{t('invited')}</th>
                <th align="left">{t('name')}</th>
                <th>{t('min_one_month_old')}</th>
                <th>{t('x_energy_collected', { count: HRNumbers.toHumanString(30000) })}</th>
                <th>{t('bought_or_subscribed')}</th>
                <th>{t('claim_your_reward')}</th>
              </tr>
              </thead>

              <tbody>
              {referrals && referrals.map(referral => (
                <ReferAFriendLine key={`ReferAFriendLineKey${referral._id}`} referral={referral} />
              ))}
              </tbody>
            </table>
          }
        </div>

      </div>

    </div>
  );
};

export default ReferAFriend;