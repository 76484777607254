import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import CustomTooltip from '../Tooltip/CustomTooltip';
import HoverIcon from '../Icon/HoverIcon';
import { ProgressBar } from 'react-step-progress-bar';

const useStyles = makeStyles({
  container: {
    marginTop: 10,
    color: Colors.halfWhite,
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 300,
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
  },
});

const CardShopPlantATree = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { price } = props;

  const isPlanted = price >= 20;
  const percentage = Math.round((price / 20) * 100);

  return (
    <div className={classes.container}>
      <div style={Helpers.fillRowCross}>
        <CustomTooltip title={t('plant_a_tree_info')}>
          <div style={{ marginRight: 8 }}>
            <HoverIcon
              icon="fa-light fa-circle-info" hoverIcon="fa-solid fa-circle-info"
              color="rgba(255, 255, 255, 0.5)" hoverColor={Colors.white}
              style={{ fontSize: 18 }}
            />
          </div>
        </CustomTooltip>
        {t('plant_a_tree')}
      </div>

      <div>
        {isPlanted ?
          <i
            className="fa-solid fa-circle-check"
            style={{ fontSize: 18, color: '#09B711' }}
          />
          :
          <div style={{ width: 129 }}>
            <ProgressBar
              percent={percentage}
              filledBackground={Colors.white}
              unfilledBackground={'rgba(255, 255, 255, 0.12)'}
              height={4}
            />
          </div>
        }
      </div>
    </div>
  );
};

export default CardShopPlantATree;