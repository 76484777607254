import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import AnimationButton from '../Button/AnimationButton';
import Helpers from '../../theme/Helpers';
import NoPoses from './NoPoses';
import Loading from '../Loading/Loading';
import Poses from './Poses';

import conf from '../../conf.json';

const useStyles = makeStyles({
  innerContainer: {
    position: 'relative',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    width: '100%',
  },
  banner: {
    width: '100%',
    height: 150,
    borderRadius: 8,
    backgroundImage: `url("${conf.cdn_url}/assets/template/heroMaker/sh-banner-heromaker-big.png")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  bannerText: {
    fontSize: 36,
    fontFamily: 'Bebas Neue',
    lineHeight: '38px',
    textShadow: '0px 0px 20px #F22740',
    '& span': {
      color: '#FAE376',
      fontSize: 30,
      lineHeight: '32px',
    },
  },
  mainArea: {
    marginTop: 16,
    background: '#01122B',
    padding: '28px 32px',
  },
  itemList: {
    marginTop: 20,
  },
});

const HeroMaker = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { loading, poses, showCreation, setSelectedHero } = props;

  return (
    <div className={classes.innerContainer}>
      <div className={classes.banner}>
        <div
          style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween, padding: '38px 32px' }}>
          <div
            className={classes.bannerText}
            dangerouslySetInnerHTML={{ __html: t('create_yourself_as_a_hero') }}
          />
          <AnimationButton status="neonGreen" onClick={showCreation}>
            {t('create_your_hero_now')}
          </AnimationButton>
        </div>
      </div>
      <div className={classes.mainArea}>
        <div style={{ fontSize: 26, fontFamily: 'Bebas Neue', lineHeight: '28px' }}>
          {t('my_hero_poses')}
        </div>
        <div className={classes.itemList}>
          {loading ?
            <Loading />
            :
            poses && poses.length > 0 ?
              <Poses poses={poses} setSelectedHero={setSelectedHero} showCreation={showCreation} />
              :
              <NoPoses showCreation={showCreation} />
          }
        </div>
      </div>
    </div>
  );
};

export default HeroMaker;