import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Colors from '../../theme/Colors';

const useStyles = makeStyles({
  filterItem: {
    marginRight: 10,
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    fontSize: 12,
    '& div, & svg': {
      cursor: 'pointer',
    },
    '& > div > div > div': {
      paddingRight: '28px !important',
    },
  },
  textField: {
    width: '100%',
    fontSize: 12,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
      fontSize: 12,
      fontFamily: 'Montserrat',
      '&::placeholder': {
        color: Colors.halfWhite,
      },
    },
  },
  placeholderColor: {
    minWidth: '180px !important',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: 12,
    padding: '11px !important',
    '&::placeholder': {
      fontSize: 12,
      color: Colors.halfWhite,
    },
  },
  endAdornment: {
    right: 10,
  },
  popupIndicator: {
    '& span': {
      '& svg': {
        '& path': {
          fill: Colors.halfWhite,
        },
      },
    },
  },
  listWrapper: {
    marginTop: 10,
    borderRadius: 10,
  },
  listArea: {
    background: 'rgba(20, 39, 64, 0.83)',
  },
  groupLabel: {
    background: 'rgba(20, 39, 64, 0.83)',
    color: Colors.primary,
  },
  listOption: {
    color: Colors.white,
    padding: 0,
    marginLeft: -5,
    fontSize: 12,
    '&:hover': {
      color: Colors.halfWhite,
    },
  },
  rootFilterItem: {
    border: 'none',
  },
});

const CardShopFilterMatches = (props) => {
  const classes = useStyles();
  const { t, type, value, setMatch, list } = props;

  const type_string = type === 'sh' || type === 'limited' ? `${type}_collection` : type;

  const options = list && list.map((option) => {
    const firstLetter = (option.name ? option.name : option.display_name).toUpperCase();
    return {
      firstLetter: !isNaN(firstLetter.charAt(0)) ? '0-9' : firstLetter.charAt(0),
      ...option,
    };
  });

  return (
    <div className={classes.filterItem}>
      <Autocomplete
        id={`${type}-autocomplete`}
        options={options && options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
        groupBy={(option) => option.firstLetter}
        getOptionLabel={(option) => option.name ? option.name : option.display_name}
        onChange={(event, value) => setMatch(value ? value._id : null)}
        classes={{
          root: classes.rootFilterItem,
          clearIndicator: classes.popupIndicator,
          popupIndicator: classes.popupIndicator,
          endAdornment: classes.endAdornment,
          popper: classes.listWrapper,
          listbox: classes.listArea,
          groupLabel: classes.groupLabel,
          option: classes.listOption,
        }}
        value={list.find(item => item._id === value)}
        // popupIcon={<FontAwesomeIcon icon={faCaretDown} size="xs" />}
        popupIcon={<i className={`fa-light fa-chevron-down`}
                      style={{ fontSize: 14, color: Colors.white }} />}
        renderInput={(params) =>
          <TextField
            {...params}
            placeholder={t('search_by', {
              match: t(type_string)
              .toLowerCase(),
            })}
            value={value}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              classes: {
                input: classes.placeholderColor,
              },
            }}
          />
        }
      />
    </div>
  );
};

export default CardShopFilterMatches;