import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { changeUserToken } from './redux/actions';

import GlobalRoute from './components/GlobalRoute/GlobalRoute';
import GameLiveStreams from './containers/GameLiveStreams/GameLiveStreams';
import LiveStreamsByGame from './containers/LiveStreamsByGame/LiveStreamsByGame';
import Login from './components/Login/Login';
import HandleLogin from './components/Login/HandleLogin';
import NotFoundPage from './components/NotFoundPage/NotFoundPage';
import PlayVideo from './components/PlayVideo/PlayVideo';
import CardContainer from './containers/Cards/Card';
import RankingContainer from './containers/Ranking/Ranking';
import OnBoardingContainer from './containers/OnBoarding/OnBoarding';
import CoinsContainer from './containers/Coins/Coins';
import DevLoginContainer from './containers/DevLogin/DevLogin';
import MobileLoginContainer from './containers/MobileLogin/MobileLogin';
import FeatureContainer from './containers/Feature/Feature';
import BugContainer from './containers/Bug/Bug';
import GoToContainer from './containers/GoTo/GoTo';
import AdminContainer from './containers/Admin/Admin';
import OrganisationContainer from './containers/Organisation/Organisation';
import Limit from './components/Limit/Limit';
import ScheduleCalendly from './components/OnBoarding/ScheduleCalendly';
import OrderThankYou from './components/Order/OrderThankYou';
import ViewerOnBoardingContainer from './containers/OnBoarding/ViewerOnBoarding';
import DesignJobContainer from './containers/DesignJob/DesignJob';
import IllustratorContainer from './containers/Illustrator/Illustrator';
import DesignJobCreationContainer from './containers/DesignJob/DesignJobCreation';
import OpenVerification from './components/CardVerification/OpenVerification';
import StartVerification from './components/CardVerification/StartVerification';
import NoticeVerification from './components/CardVerification/NoticeVerification';
import TutorialVerification from './components/CardVerification/TutorialVerification';
import TouchVerification from './components/CardVerification/TouchVerification';
import SwipeVerification from './components/CardVerification/SwipeVerification';
import MyCardContainer from './containers/MyCard/MyCard';
import OnePages from './components/OnePages/OnePages';
import PremiumContainer from './containers/Premium/Premium';
import ConfirmDeletionContainer from './containers/ConfirmDeletion/ConfirmDeletion';
import ThreeDTest from './components/3DCard/3DTest';
import ThreeDCard from './containers/3DCard/3DCard';
import ThreeDBoosterAnimationContainer from './containers/3DCard/3DBoosterAnimation';
import ItemStoreContainer from './containers/ItemStore/ItemStore';
import LegacySwitcherContainer from './containers/Inventory/LegacySwitcher';
import CountdownContainer from './containers/Countdown/Countdown';
import UserAcceptTermsContainer from './containers/AcceptTerms/UserAcceptTerms';
import ThreeDBooster from './containers/3DCard/3DBooster';
import ProfileContainer from './containers/Profile/Profile';
import HubContainer from './containers/Hub/Hub';
import CyberMeetsFireContainer from './containers/Seasons/CyberMeetsFire';
import StreamerSettingsContainer from './containers/Settings/StreamerSettings';
import CapsulesContainer from './containers/AlertCapsules/AlertCapsules';
import NeonMeetsIceContainer from './containers/Seasons/NeonMeetsIce';
import MarketplaceContainer from './containers/Marketplace/Marketplace';
import ReferAFriendContainer from './containers/Refer/ReferAFriend';
import AccountSettingsContainer from './containers/Settings/AccountSettings';
import OrderList from './containers/Order/OrderList';
import LegacyCardShopSwitcherContainer from './containers/CardShop/LegacyCardShopSwitcher';
import CardShopMatchContainer from './containers/CardShop/CardShopMatch';
import CardShopMatchCardContainer from './containers/CardShop/CardShopMatchCard';
import CardShopMatchAlbumContainer from './containers/CardShop/CardShopMatchAlbum';
import CardShopPayContainer from './containers/CardShop/CardShopPay';
import EnergyContainer from './containers/Energy/Energy';
import InventoryBoosterRarityCardsContainer
  from './containers/Inventory/InventoryBoosterRarityCards';
import InventoryBoosterCardsListContainer from './containers/Inventory/InventoryBoosterCardsList';

const conf = require('./conf');

const authMiddleware = {
  isAuthenticated: false,
  authenticate(cb) {
    if (window && window.localStorage && window.localStorage.getItem(conf.storage_keys.user_token))
      this.isAuthenticated = true;
  },
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    authMiddleware.isAuthenticated
      ? <Component {...props} />
      : <span>{window.location.href = 'https://www.streamheroes.gg'}</span>
  )} />
);

const Routes = (props) => {
  authMiddleware.authenticate();

  /*const confirmedLogin = localStorage.getItem('SH:CONFIRM:LOGIN');
  const untilSeconds = moment(confirmedLogin ? 1645288200000 : 1645290000000)
  .diff(moment(), 'seconds');
  if (!window.location.href.includes('login') && !window.location.href.includes('/countdown') && untilSeconds > 0)
    window.location.href = authMiddleware.isAuthenticated ? 'https://app.streamheroes.gg/countdown' : 'https://www.streamheroes.gg';*/


  const OpenRoute = ({ component: Component, ...rest }) => {
    if (!authMiddleware.isAuthenticated && !props.main.userToken)
      props.changeUserToken(conf.api_token);

    return (
      <Route {...rest} render={(props) => (
        <Component {...props} />
      )} />
    );
  };

  const HandleLoginRoute = ({ component: Component, ...rest }) => {
    if (!authMiddleware.isAuthenticated && props.main.userToken === conf.api_token) {
      return (
        <Route {...rest} render={(props) => (
          <Component {...props} />
        )} />
      );
    } else
      return null;
  };

  return (
    <div style={{ width: '100%', margin: '0 auto' }}>
      <Switch hideNavBar={true}>
        {/* Redirects */}
        <Redirect exact from="/dashboard" to="/hq/hub" />
        <Redirect exact from="/streamer/:name" to="/profile/:name" />
        <Redirect exact from="/viewer/:name" to="/profile/:name" />
        <Redirect exact from="/referral/:streamer" to="/login?state=:streamer" />

        <PrivateRoute exact path="/" component={GlobalRoute} />
        <HandleLoginRoute exact path="/handle-login" component={HandleLogin} />
        <OpenRoute exact path="/login" component={Login} />
        <OpenRoute exact path="/limit" component={Limit} />
        <OpenRoute exact path="/delete-account/:token" component={ConfirmDeletionContainer} />
        <OpenRoute exact path="/profile/:name/:tab?" component={ProfileContainer} />
        <OpenRoute exact path="/organisation/:name" component={OrganisationContainer} />
        <OpenRoute exact path="/card/:id/:viewerId?/:isCraft?" component={CardContainer} />
        <OpenRoute exact path="/page/:page" component={OnePages} />
        <OpenRoute exact path="/dev-login" component={DevLoginContainer} />
        <OpenRoute exact path="/mobile-login" component={MobileLoginContainer} />
        <OpenRoute exact path="/3d-test" component={ThreeDTest} />
        <OpenRoute exact path="/3d-card/:cardId" component={ThreeDCard} />
        <OpenRoute exact path="/3d-booster/:boosterId" component={ThreeDBooster} />
        <OpenRoute exact path="/booster-animation" component={ThreeDBoosterAnimationContainer} />
        <OpenRoute exact path="/bone-animation" component={ThreeDBoosterAnimationContainer} />
        <PrivateRoute exact path="/on-boarding" component={OnBoardingContainer} />
        <PrivateRoute exact path="/on-tour" component={ViewerOnBoardingContainer} />
        <PrivateRoute exact path="/accept-terms" component={UserAcceptTermsContainer} />
        <PrivateRoute exact path="/hq/hub" component={HubContainer} />
        <PrivateRoute exact path="/hq/cyber-meets-fire" component={CyberMeetsFireContainer} />
        <PrivateRoute exact path="/hq/neon-meets-ice" component={NeonMeetsIceContainer} />
        <PrivateRoute exact path="/hq/game-streams" component={GameLiveStreams} />
        <PrivateRoute exact path="/hq/game-streams/:name" component={LiveStreamsByGame} />
        <PrivateRoute exact path="/bank" component={CoinsContainer} />
        <PrivateRoute exact path="/inventory/alert-capsules" component={CapsulesContainer} />
        <PrivateRoute exact path="/inventory/:tab/:id?" component={LegacySwitcherContainer} />
        <PrivateRoute exact path="/inventory/streamer/:streamerId/booster/:boosterId" component={InventoryBoosterRarityCardsContainer} />
        <PrivateRoute exact path="/inventory/streamer/:streamerId/booster/:boosterId/card/:cardId" component={InventoryBoosterCardsListContainer} />
        <PrivateRoute exact path="/settings/account" component={AccountSettingsContainer} />
        <PrivateRoute exact path="/settings/refer-a-friend" component={ReferAFriendContainer} />
        <PrivateRoute exact path="/settings/orders" component={OrderList} />
        <PrivateRoute exact path="/marketplace/:tab" component={MarketplaceContainer} />
        <PrivateRoute exact path="/streamer-settings/:tab/:type?/:id?"
                      component={StreamerSettingsContainer} />
        <PrivateRoute exact path="/ranking/:tab" component={RankingContainer} />
        <PrivateRoute exact path="/play-video/:cardId/:viewerId" component={PlayVideo} />
        <PrivateRoute exact path="/quest-reward/:type/:cardId?/:viewerId?" component={PlayVideo} />
        <PrivateRoute exact path="/play-video/:type" component={PlayVideo} />
        <PrivateRoute exact path="/request-feature" component={FeatureContainer} />
        <PrivateRoute exact path="/admin/:tab" component={AdminContainer} />
        <PrivateRoute exact path="/report-bug" component={BugContainer} />
        <PrivateRoute exact path="/go/:key" component={GoToContainer} />
        <PrivateRoute exact path="/schedule-partner-call" component={ScheduleCalendly} />
        <PrivateRoute exact path="/stripe-thank-you" component={OrderThankYou} />
        {/* <PrivateRoute exact path="/card-shop" component={LegacyCardShopSwitcherContainer} />
        <PrivateRoute exact path="/card-shop/streamer/:id" component={CardShopMatchContainer} />
        <PrivateRoute exact path="/card-shop/streamer/:id/card/:cardId"
                      component={CardShopMatchCardContainer} />
        <PrivateRoute exact path="/card-shop/streamer/:id/album/:albumId"
                      component={CardShopMatchAlbumContainer} />
        <PrivateRoute exact path="/card-shop-pay" component={CardShopPayContainer} /> */}
        <PrivateRoute exact path="/design-job/:id?" component={DesignJobContainer} />
        <PrivateRoute exact path="/illustrator" component={IllustratorContainer} />
        <PrivateRoute exact path="/premium" component={PremiumContainer} />
        <PrivateRoute exact path="/create-design-job" component={DesignJobCreationContainer} />
        <PrivateRoute exact path="/item-store/:tab?" component={ItemStoreContainer} />
        <OpenRoute exact path="/my-card/:id" component={MyCardContainer} />
        <PrivateRoute exact path="/verification/" component={OpenVerification} />
        <PrivateRoute exact path="/verification/start" component={StartVerification} />
        <PrivateRoute exact path="/verification/notice" component={NoticeVerification} />
        <PrivateRoute exact path="/verification/tutorial" component={TutorialVerification} />
        <PrivateRoute exact path="/verification/touchSensitivity" component={TouchVerification} />
        <PrivateRoute exact path="/verification/swipe" component={SwipeVerification} />
        <PrivateRoute exact path="/countdown" component={CountdownContainer} />
        <PrivateRoute exact path="/energy" component={EnergyContainer} />
        <PrivateRoute path="*" component={NotFoundPage} code={404} />
      </Switch>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { main } = state;
  return { main };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    changeUserToken,
  }, dispatch)
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));