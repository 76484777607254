import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Colors from '../../theme/Colors';
import Tilt from 'react-tilt';
import ShinyButton from '../Button/ShinyButton';
import Helpers from '../../theme/Helpers';
import Avatar from '@material-ui/core/Avatar';
import HRNumbers from 'human-readable-numbers';
import Link from '@material-ui/core/Link';
import moment from 'moment';
import TopStreamerItemLanguages from './TopStreamerItemLanguages';
import TopStreamerItemGame from './TopStreamerItemGame';
import { returnMobileObj } from '../../utils/responsive';

const useStyles = makeStyles(() => ({
  container: {
    background: Colors.containerBGColor,
    padding: 20,
    ...Helpers.fillRowCenter,
    color: Colors.white,
    margin: '20px 0',
    flexWrap: 'wrap',
  },
  profileWrapper: {
    ...Helpers.fillColCenter,
    padding: '20px 40px 20px 20px',
    width: 260,
  },
  cardsWrapper: {
    ...Helpers.fillRow,
    ...Helpers.mainSpaceBetween,
    flexWrap: 'wrap',
    flex: 1,
  },
  avatar: {
    display: 'inline-block',
    borderColor: Colors.borderColor,
    backgroundColor: Colors.streamAvatarBorder,
    borderWidth: 2,
    zIndex: 2,
    width: 80,
    height: 80,
    margin: '10px 0',
  },
}));

const TopStreamerItem = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { topStreamerItem, isTabletOrMobile } = props;
  const { cards, streamer, collected_cards_count } = topStreamerItem;

  cards.sort((a, b) => a.level - b.level);

  return (
    <div className={classes.container} style={returnMobileObj(isTabletOrMobile, { padding: 5 })}>
      <div className={classes.profileWrapper}>
        <div
          style={{ fontSize: 34, fontWeight: 'bold', textTransform: 'uppercase', lineHeight: 1 }}
        >
          {streamer.display_name}
        </div>
        <div style={{ color: '#8c98a9', fontSize: 14 }}>
          {t('registered_since')} {moment.unix(streamer.created_at / 1000)
        .format('DD.MM.YYYY')}
        </div>
        <Avatar
          className={classes.avatar}
          alt={streamer.display_name}
          src={streamer.profile_image_url}
        />
        <TopStreamerItemLanguages languages={streamer.languages_streamed} />
        <div style={{ fontSize: 28, fontWeight: 'bold' }}>
          <img
            src={process.env.PUBLIC_URL + '/icons-png/cardredicon.png'}
            alt="Cards icon"
            style={{ verticalAlign: 'text-bottom', marginRight: 10 }}
          />
          {HRNumbers.toHumanString(collected_cards_count)}
        </div>
        <TopStreamerItemGame games={streamer.games_streamed} />
        <Link href={`/profile/${streamer.login}`} underline="none">
          <ShinyButton>
            {t('visit_profile')}
          </ShinyButton>
        </Link>
      </div>
      <div
        className={classes.cardsWrapper}
        style={returnMobileObj(isTabletOrMobile, { ...Helpers.fillRowCenter, zoom: 0.5 })}
      >
        {cards.map(card => (
          <Link href={`/card/${card._id}`} key={card._id}>
            <Tilt className="Tilt" options={{ max: 20 }}>
              <img
                src={`${card.image}?height=300`}
                alt="Card"
                style={{ borderRadius: 4, ...returnMobileObj(isTabletOrMobile, { margin: 5 }) }}
              />
            </Tilt>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default TopStreamerItem;