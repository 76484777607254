import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CoinsByItem from './CoinsByItem';
import { isUserPremium } from '../../utils/helper';
import Helpers from '../../theme/Helpers';

const useStyles = makeStyles((theme) => ({
  gridArea: {
    display: 'grid',
    gap: 24,
    gridTemplateColumns: 'repeat(3, 1fr)',
    marginTop: 10,

    [theme.breakpoints.down('md')]: {
      ...Helpers.fillCol,
      gap: 8,
    },
  },
}));

const pages = {
  game: 1,
  streamer: 1,
  organisation: 1,
};

const CoinsTypesList = (props) => {
  const classes = useStyles();
  const {
    tabs,
    myViewer,
    loading,
    fetchMore,
    convertMode,
    toggleConvertingItem,
    convertingList,
  } = props;

  const { isPremiumActive } = isUserPremium(myViewer);

  return (
    <div className={classes.gridArea}>
      {tabs && tabs.map((tab => (
        <CoinsByItem
          loading={loading}
          entries={tab.entries}
          title={tab.value}
          totalCount={tab.totalCount}
          myViewer={myViewer}
          toggleConvertingItem={toggleConvertingItem}
          convertingList={convertingList}
          convertMode={convertMode}
          isPremiumActive={isPremiumActive}
          onLoadMore={async () => {
            pages[tab.type.toLowerCase()] += 1;
            try {
              await fetchMore({
                variables: {
                  type: tab.type,
                  pagination: { perPage: 25, page: pages[tab.type.toLowerCase()] },
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  const obj = {};
                  obj[tab.objKey] = {
                    points: [...prev[tab.objKey].points, ...fetchMoreResult[tab.objKey].points],
                    count: prev[tab.objKey].count,
                    __typename: prev[tab.objKey].__typename,
                  };
                  return obj;
                },
              });
            } catch {
            }
          }}
        />
      )))}
    </div>
  );
};

export default CoinsTypesList;