import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';
import { getMatchData, multiplyEnergyCoins } from '../../utils/helper';
import SetHeadRow from './SetHeadRow';
import VerticalPageScrollMenu from '../VerticalPageScrollMenu/VerticalPageScrollMenu';
import BoosterCardsView from './BoosterCardsView';
import BoosterPackView from './BoosterPackView';
import { logAmplitudeEvent } from '../../utils/amplitude';
import { isMobile } from 'react-device-detect';
import conf from '../../conf.json';

const useStyles = makeStyles((theme) => ({
  body: {
    maxWidth: 1440,
    margin: '0 auto',
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    fontFamily: 'Montserrat',
    transition: 'all 0.3s ease-out',
    position: 'relative',

    [theme.breakpoints.down('md')]: {
      margin: 0,
      maxWidth: '100%',
    },
  },
  footerArea: {
    position: 'absolute',
    bottom: 16,
    left: '50%',
    transform: 'translate(-50%, 0)',
    ...Helpers.textCenter,
    textShadow: '0px 0px 26px #FFFFFFB8',
    fontWeight: 200,
    fontSize: 14,
    fontFamily: 'Montserrat',
    transition: 'all 0.3s ease-out',
    cursor: 'pointer',
    '&:hover': {
      bottom: 22,
    },
  },
  middlePart: {
    position: 'absolute',
    top: '50%',
    width: '100%',
    height: 698,
    // transition: 'all 0.3s ease-out',
    ...Helpers.fillRowCenter,
    animationName: '$toTheUp',
    animationDuration: '0.5s',
  },
  '@keyframes toTheUp': {
    '0%': {
      marginTop: '100%',
    },
    '100%': {
      marginTop: '0%',
    },
  },
  toTheDown: {
    animationName: '$toTheDown',
  },
  '@keyframes toTheDown': {
    '0%': {
      marginTop: '-100%',
    },
    '100%': {
      marginTop: '0%',
    },
  },
}));

const BoosterView = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { handleClose, booster, redeemBooster } = props;
  const [step, setStep] = useState(1);
  const [noScroll, setNoScroll] = useState(false);

  useEffect(() => {
    if (step === 2) {
      const cardsScrollList = document.getElementById('cards-scroll-list');
      if (cardsScrollList) {
        cardsScrollList.addEventListener('mouseover', () => setNoScroll(true));
        cardsScrollList.addEventListener('mouseout', () => setNoScroll(false));
      }
    }
  }, [step]);

  useEffect(() => {
    const scrollableElement = document.body;
    const threeJSArea = document.getElementById('three-js-area');
    scrollableElement.addEventListener('wheel', checkWheel);
    if (threeJSArea) {
      threeJSArea.addEventListener('mouseover', () => setNoScroll(true));
      threeJSArea.addEventListener('mouseout', () => setNoScroll(false));
    }

    return () => scrollableElement.removeEventListener('wheel', checkWheel);
  });

  const logBoosterForAmplitude = () => {
    if (conf.track_amplitude)
      logAmplitudeEvent('showContent_Boosterview', {
        'Booster ID': booster._id,
        'Owner Name': matchData.name,
        'Booster costs': costs,
        'Booster unit type': isEnergy ? 'Energy' : 'SH Coins',
      });
  };

  const matchCardType = 'Card';
  const isEnergy = !!booster.energy_costs;
  const costs = multiplyEnergyCoins(isEnergy ? booster.energy_costs : booster.coin_costs);
  const matchData = getMatchData(booster, 300, 300);

  const steps = [
    <BoosterPackView
      costs={costs}
      booster={booster}
      cardsLength={booster.generic_cards.length}
      frontImage={booster.front_image && booster.front_image.substring(1)}
      backImage={booster.back_image && booster.back_image.substring(1)}
      matchData={matchData}
      redeemBooster={redeemBooster}
      isMobile={isMobile}
    />,
    <BoosterCardsView booster={booster} matchData={matchData} costs={costs} redeemBooster={redeemBooster} isMobile={isMobile} />,
  ];

  const checkWheel = (event) => {
    if (noScroll)
      return;

    if (event.wheelDelta > 0) {
      logBoosterForAmplitude();

      if (step > 1)
        setStep(step - 1);
    } else {
      if (step < steps.length)
        setStep(step + 1);
    }
  };

  return (
    <div style={isMobile ? {} : { maxWidth: 1440, margin: '0 auto' }}>
      <VerticalPageScrollMenu
        length={steps.length}
        activeIdx={step}
        setIdx={(idx) => {
          logBoosterForAmplitude();
          setStep(idx);
        }}
      />
      <SetHeadRow name={matchData.name} matchCardType={matchCardType} logoUrl={matchData.avatar}
                  handleClose={handleClose} booster={booster} url={matchData.url} />
      <div className={classes.body}>

        {steps.map((stepElement, index) => (
          step === (index + 1) ?
            <div className={[classes.middlePart, step === 1 ? classes.toTheDown : ''].join(' ')}>
              {stepElement}
            </div>
            : null
        ))}

      </div>
      <div
        className={classes.footerArea}
        style={{ opacity: step === 1 ? 1 : 0 }}
        onClick={() => {
          logBoosterForAmplitude();
          setStep(step + 1);
        }}
      >
        {t('scroll_down_to_see_all_cards')}<br />
        <i className="fa-light fa-chevron-down" style={{ marginTop: 4 }} />
      </div>
    </div>
  );
};

export default BoosterView;