import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import Tilt from 'react-tilt';
import { useMutation } from '@apollo/react-hooks';

import {
  removeHeroMakerImage as removeHeroMakerImageMutation,
  duplicateHeroMakerImage as duplicateHeroMakerImageMutation,
} from '../../apollo/mutations';
import { getMyHeroMakerImages } from '../../apollo/queries';

import Helpers from '../../theme/Helpers';
import AnimationButton from '../Button/AnimationButton';

import conf from '../../conf.json';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
  poseItem: {
    background: `url("${conf.cdn_url}/assets/template/heroMaker/pose_bg.png")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: 234,
    height: 362,
    ...Helpers.fillRowCenter,
    position: 'relative',
    marginBottom: 43,
  },
  middleArea: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    ...Helpers.fillColCenter,
  },
});

const Poses = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { poses, setSelectedHero, showCreation } = props;
  const [hoverItem, setHoverItem] = useState(null);

  const [removeHeroMakerImage] = useMutation(removeHeroMakerImageMutation, {
    onCompleted: () => {
      enqueueSnackbar(t('success'), { variant: 'success' });
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{ query: getMyHeroMakerImages }],
  });

  const [duplicateHeroMakerImage] = useMutation(duplicateHeroMakerImageMutation, {
    onCompleted: () => {
      enqueueSnackbar(t('success'), { variant: 'success' });
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{ query: getMyHeroMakerImages }],
  });

  const deletePose = (id) => {
    removeHeroMakerImage({ variables: { id } });
  };

  const duplicatePose = (id) => {
    duplicateHeroMakerImage({ variables: { id } });
  };

  return (
    <div style={{
      position: 'relative', ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween,
      flexWrap: 'wrap',
    }}>
      {poses.map(pose => (
        <Tilt className="Tilt" options={{ max: 5 }} key={pose._id}>
          <div
            className={classes.poseItem}
            onMouseEnter={() => setHoverItem(pose._id)}
            onMouseLeave={() => setHoverItem(null)}
          >
            <img src={`${pose.image}?height=340`} alt="Pose" />
            <div
              className={classes.middleArea}
              style={{ display: hoverItem === pose._id ? 'initial' : 'none' }}
            >
              <AnimationButton
                onClick={() => {
                  showCreation(true);
                  setSelectedHero(pose._id);
                }}
                status="neonGreen"
                style={{
                  borderRadius: 50,
                  padding: '8px 36px',
                  width: 131,
                  marginBottom: 8,
                  fontSize: 15,
                }}
              >
                {t('edit')}
              </AnimationButton>
              <AnimationButton
                status="brown"
                style={{
                  width: 131,
                  fontSize: 15,
                }}
                onClick={() => duplicatePose(pose._id)}
              >
                {t('duplicate')}
              </AnimationButton>
              <AnimationButton
                status="red"
                style={{
                  borderRadius: 50,
                  width: 131,
                  padding: '8px 36px',
                  marginTop: 8,
                  fontSize: 15,
                }}
                onClick={() => deletePose(pose._id)}
              >
                {t('delete')}
              </AnimationButton>
            </div>
          </div>
        </Tilt>
      ))}
    </div>
  );
};

export default Poses;