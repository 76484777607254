import React from 'react';

import MobileMenuBar from './MobileMenuBar';

const MobileSubFooterBar = (props) => {
  const { subMenu } = props;

  return (
    <MobileMenuBar menu={subMenu} style={{ bottom: 51 }} />
  );
};

export default MobileSubFooterBar;