import React, { useState } from 'react';
import { Query } from 'react-apollo';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { checkRequirementsToBecomeAStreamhero } from '../../apollo/queries';

import Loading from '../Loading/Loading';
import Colors from '../../theme/Colors';
import ErrorMessage from '../ErrorBoundary/ErrorMessage';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import AnimationButton from '../Button/AnimationButton';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  headerText: {
    marginBottom: 32,
    color: Colors.subTextColor,
    fontSize: 15,
  },
  link: {
    '& a': {
      color: Colors.white,
      '&:hover': {
        color: Colors.subTextColor,
      },
    },
  },
}));

const PotentialStreamhero = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { makeMeAStreamHero, viewer } = props;
  const [agree, setAgree] = useState(false);

  const handleChange = (event) => {
    setAgree(event.target.checked);
  };

  return (
    <>
      <Typography variant="h6" className={classes.headerText}>
        {t('can_become_streamhero_heading')}
      </Typography>
      <Query
        query={checkRequirementsToBecomeAStreamhero}
        fetchPolicy={useCacheWithExpiration('checkRequirementsToBecomeAStreamhero')}
      >
        {({ data, loading, error }) => {
          if (loading) return <Loading />;
          if (error) return <ErrorMessage error={error} />;
          const reqResult = (data) && data.checkRequirementsToBecomeAStreamhero;

          let canBecomeStreamhero = false;
          if (reqResult && (reqResult.broadcaster_type === 'affiliate' || reqResult.broadcaster_type === 'partner'))
            canBecomeStreamhero = true;

          if (viewer && viewer.streamheroes_role && viewer.streamheroes_role.includes('marketing'))
            canBecomeStreamhero = true;

          return (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <span>
                    {canBecomeStreamhero ?
                      t('can_become_streamhero')
                      :
                      t('can_not_become_streamhero')
                    }
                  </span>
                </Grid>
              </Grid>
              {canBecomeStreamhero &&
              <>
                <FormControlLabel
                  control={<Checkbox
                    checked={agree}
                    onChange={handleChange}
                    name="agreeCooperation"
                    style={{ color: '#315AA5' }}
                  />}
                  label={
                    <span
                      className={classes.link}
                      dangerouslySetInnerHTML={{ __html: t('accept_cooperation') }}
                    />
                  }
                  style={{ color: '#4D77AB' }}
                />
                <AnimationButton
                  onClick={makeMeAStreamHero}
                  disabled={!agree}
                  style={{ marginTop: 20, padding: '10px 36px' }}
                >
                  {t('can_become_streamhero_button_text')}
                </AnimationButton>
              </>
              }
            </>
          );
        }}
      </Query>
    </>
  );

};

export default PotentialStreamhero;