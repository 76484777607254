import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { detectBrowser } from '../../utils/helper';
import IconWrapper from '../Icon/IconWrapper';
import ThreeDWrapper from '../3DCard/3DWrapper';
import Scene from '../3DCard/Scene';
import CardViewCSSFlip from './CardViewCSSFlip';
import Helpers from '../../theme/Helpers';
import BlueWhiteSwitch from '../Switch/BlueWhiteSwitch';
import Colors from '../../theme/Colors';

const useStyles = makeStyles(() => ({
  absoluteBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer',
  },
}));

const CardImageDecision = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { information, isLegacy, card, style, size, zoom, id } = props;
  const [show3D, setShow3D] = useState(false);
  const [deactivateRotation, setDeactivateRotation] = useState(false);

  return (
    <>
      <div className={classes.absoluteBox} style={style} id={id}>
        <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween, color: Colors.white }}>
          {detectBrowser() !== 'Firefox' &&
          <div style={Helpers.fillRowCross}>
            <BlueWhiteSwitch
              checked={show3D}
              onChange={() => setShow3D(!show3D)}
              name="show-3d"
            />
            <div style={{ marginLeft: 10 }}>
              {t('show_3d')}
            </div>
          </div>
          }
          <div style={Helpers.fillRowCross}>
            <div style={{ marginRight: 10 }}>
              {t('deactivate_rotation')}
            </div>
            <BlueWhiteSwitch
              checked={deactivateRotation}
              onChange={() => setDeactivateRotation(!deactivateRotation)}
              name="deactivate-rotation-check"
            />
          </div>
        </div>

        {show3D ?
          <IconWrapper size={size}>
            <ThreeDWrapper noBackdrop zoom={zoom}>
              <Scene
                card={card}
                isRotating={!deactivateRotation}
                isOblique={!deactivateRotation}
                noWhiteCard
                withLight
                noAnimation
              />
            </ThreeDWrapper>
          </IconWrapper>
          :
          <CardViewCSSFlip
            information={information}
            isLegacy={isLegacy}
            deactivateRotation={deactivateRotation}
            width={style.width - 100}
            height={style.height - 100}
          />
        }
      </div>
    </>
  );
};

export default CardImageDecision;