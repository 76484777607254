import React from 'react';
import { makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';

import Helpers from '../../theme/Helpers';
import { getLeagueImage, getMatchData } from '../../utils/helper';

import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  item: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    padding: 16,
    ...Helpers.fillRowCross,
    ...Helpers.mainSpaceBetween,
    marginTop: 8,
  },
  avatar: {
    width: 56,
    height: 56,
    border: 'none',
    marginRight: 10,
  },
  miniAvatar: {
    width: 12,
    height: 12,
    border: 'none',
    position: 'absolute',
    top: 7,
    left: 3,
  },
}));

const ProfileOverviewRecentActivity = (props) => {
  const classes = useStyles();
  const { activity, viewer } = props;

  const matchData = getMatchData(activity);

  if (['energyConvertedToCoins', 'depotUpgraded'].includes(activity.action_type)) {
    matchData.avatar = viewer.profile_image_url;
    matchData.name = viewer.display_name;
  }

  return (
    <div className={classes.item}>
      <div style={{ ...Helpers.fillRowCross }}>
        {activity.action_type !== 'userRankUpInSeason' &&
          <Avatar
            className={classes.avatar}
            alt="Avatar image"
            src={matchData.avatar}
          />
        }

        {activity.action_type === 'userRankUpInSeason' &&
          <img 
            src={getLeagueImage(activity.action)} 
            alt={activity.action} 
            style={{width: 56, height: 56, marginRight: 10}}  
          />
        }

        <div>
          <div>{activity.action_type === 'userRankUpInSeason' ? viewer.display_name : matchData.name}</div>
          <div style={{ fontSize: 12, color: 'rgba(255, 255, 255, 0.5)' }}>
            {moment.unix(activity.created_at / 1000)
            .format('MMM DD, YYYY ⦁ h:mm A')}
          </div>
        </div>
      </div>
      <div
        style={{
          padding: '6px 12px',
          borderRadius: 6,
          background: 'rgba(255, 255, 255, 0.04)',
          fontSize: 14,
          ...Helpers.fillRowCenter,
        }}
      >
        {activity.action_type === 'redeemedBooster' &&
        <>
          <img
            src={`${conf.cdn_url}/booster-packs/new/sh-base-booster-pack-02.png?height=26`}
            alt="Booster pack"
          />
          <div style={{ margin: 4 }}>
            +{activity.action_amount ?? 1}
          </div>
        </>
        }
        {activity.action_type === 'depotUpgraded' &&
        <>
          <div style={{
            width: 0,
            height: 0,
            borderLeft: '5px solid transparent',
            borderRight: '5px solid transparent',
            borderBottom: '5px solid #1FE325',
            marginRight: 8,
          }} />

          <img
            src={`${conf.cdn_url}/assets/activities/depot_upgrade.png?height=26`}
            alt="Booster pack"
          />
        </>
        }
        {activity.action_type === 'energyConvertedToCoins' &&
        <>
          <img
            src={`${conf.cdn_url}/assets/activities/energy_convert.png?height=26`}
            alt="Booster pack"
          />
          <div style={{ margin: 4 }}>
            +{activity.action_amount}
          </div>
        </>
        }
        {activity.action_type === 'userRankUpInSeason' &&
        <>
          <div style={{
            width: 0,
            height: 0,
            borderLeft: '5px solid transparent',
            borderRight: '5px solid transparent',
            borderBottom: '5px solid #1FE325',
            marginRight: 8,
          }} />

          <img
            src={activity.match.profile_image_url}
            alt="League badge"
            style={{width: 27, height: 'auto', marginRight: 8}}
            height={1}
            width={1}
          />

          <span>{activity.match.display_name}</span>
          <span>&nbsp;-&nbsp;</span>
          <span style={{textTransform: 'capitalize'}}>{activity.action}</span>
        </>
        }
      </div>
    </div>
  );
};

export default ProfileOverviewRecentActivity;