import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';

import Loading from '../Loading/Loading';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  mainPost: {
    width: '50%',
    transition: 'all 0.3s ease-out',
    boxSizing: 'border-box',
    borderRadius: 6,
    background: 'linear-gradient(180deg, #0B192D00 0%, #0A182C00 7%, #0A182C00 18%, #0A182BCC 37%, #0A182B 100%)',
    position: 'relative',
    padding: 32,
    textDecoration: 'none',
    cursor: 'pointer',
    color: Colors.white,
    ...Helpers.fillCol,
    ...Helpers.mainEnd,
    '&:hover': {
      background: 'linear-gradient(180deg, #172D4A00 0%, #172D4A00 7%, #172D4A00 18%, #172D4ACC 37%, #172D4A 100%)',
    },
  },
  smallPost: {
    background: 'rgba(255, 255, 255, 0.04)',
    position: 'relative',
    textDecoration: 'none',
    padding: 10,
    color: Colors.white,
    marginBottom: 26,
    cursor: 'pointer',
    borderRadius: 6,
    ...Helpers.fillRowCross,
    transition: 'all 0.3s ease-out',

    '&:last-child': {
      marginBottom: 0,
    },

    '&:hover': {
      background: 'rgba(255, 255, 255, 0.12)',
    },
  },
  timeStamp: {
    color: Colors.halfWhite,
    fontSize: 12,
  },
  tagArea: {
    padding: '8px 20px',
    background: 'rgba(255, 255, 255, 0.1)',
    fontSize: 14,
    fontWeight: 500,
    borderRadius: 5,
    marginRight: 14,
  },
  twoLineText: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

const HubNewsBoard = (props) => {
  const classes = useStyles();
  const { t, i18n } = props;
  const [posts, setPosts] = useState(null);

  useEffect(() => {
    if (!posts) {
      const lang = ['de', 'en'].includes(i18n.language) ? i18n.language : 'en';

      axios.get(`https://blog.streamheroes.gg/ghost/api/v3/content/posts/?key=a4d042279fc617d34dbb72ff0f&limit=4&include=tags&filter=tag:${lang}`)
      .then(res => {
        if (res.data.posts)
          setPosts(res.data.posts);
      });
    }
  }, [posts]);

  return (
    posts ?
      <>
        {posts.length > 0 &&
        <div style={{ display: 'flex', gap: 26 }}>
          {!isMobile &&
          <Link to={{ pathname: posts[0].url }} target="_blank" className={classes.mainPost}>
            <div style={{
              position: 'absolute',
              left: 0,
              borderRadius: 6,
              top: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url("${posts[0].feature_image}")`,
              backgroundSize: 'cover',
              zIndex: -1,
              backgroundPosition: 'center',
              opacity: 0.9,
            }} />

            <div style={{ fontSize: 36, fontWeight: 600, marginBottom: 28 }}>
              {posts[0].title}
            </div>

            <div style={{ marginBottom: 32 }} className={classes.twoLineText}>
              {posts[0].excerpt}
            </div>

            <div style={{ ...Helpers.fillRowCross }}>
              {posts[0].primary_tag &&
              <div className={classes.tagArea}>
                {posts[0].primary_tag.name}
              </div>
              }

              <div className={classes.timeStamp}>
                {t('posted_at', {
                  date: moment(posts[0].published_at)
                  .format('LL'),
                })}
              </div>
            </div>
          </Link>
          }

          <div style={{ width: isMobile ? '100%' : '50%' }}>

            {posts.map((post, index) => {
              if (index === 0 && !isMobile)
                return null;

              return (
                <Link to={{ pathname: post.url }} target="_blank" className={classes.smallPost}
                      key={`PostKey${post.id}`}>
                  {!isMobile &&
                  <img
                    src={post.feature_image}
                    alt={post.title}
                    style={{
                      borderRadius: 6,
                      backgroundImage: `url("${post.feature_image}")`,
                      objectFit: 'cover',
                      objectPosition: 'center',
                      width: 206,
                      height: 116,
                      flexShrink: 0,
                      marginRight: 16,
                    }}
                  />
                  }

                  <i className="fa-regular fa-chevron-right"
                     style={{
                       position: 'absolute',
                       right: 16,
                       top: '50%',
                       transform: 'translate(0, -50%)',
                     }} />

                  <div>
                    <div style={{
                      fontSize: 18,
                      fontWeight: 500,
                      marginBottom: 12,
                      whiteSpace: isMobile ? 'normal' : 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      maxWidth: isMobile ? '100%' : 440,
                    }}>
                      {post.title}
                    </div>

                    <div style={{ ...Helpers.fillRowCross }}>
                      {post.primary_tag &&
                      <div className={classes.tagArea}>
                        {post.primary_tag.name}
                      </div>
                      }

                      <div className={classes.timeStamp}>
                        {t('posted_at', {
                          date: moment(post.published_at)
                          .format('LL'),
                        })}
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}

          </div>
        </div>
        }
      </>
      :
      <Loading />
  );
};

export default withTranslation()(HubNewsBoard);