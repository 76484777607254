import React from 'react';
import { StickyContainer } from 'react-sticky';

import Colors from '../../theme/Colors';
import CardShopMatchFilters from '../CardShop/CardShopMatchFilters';
import Loading from '../Loading/Loading';
import LoadMore from '../Loading/LoadMore';
import MarketplaceMarketItem from './MarketplaceMarketItem';
import { bindActionCreators } from 'redux';
import { setCollectorsViewMarketplace } from '../../redux/actions';
import { connect } from 'react-redux';
import SlideInOut from '../Layout/SlideInOut';
import Helpers from '../../theme/Helpers';
import HoverIcon from '../Icon/HoverIcon';
import TopBarGems from '../Menu/TopBarGems';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  topArea: {
    background: 'rgba(255, 255, 255, 0.04)',
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    height: 62,
  },
}));

const InventoryMatchMarketplace = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    offers,
    loading,
    onLoadMore,
    totalCount,
    myViewer,
    sorting, setSorting,
    quality, setQuality,
    rarity, setRarity,
    isSeasonal, setIsSeasonal,
  } = props;

  const onClose = () => {
    props.setCollectorsViewMarketplace(null);
  };

  return (
    <SlideInOut
      isOpen={!!offers}
      additionalLeft={31}
      onClose={onClose}
      myViewer={myViewer}
    >
      <div style={{ color: Colors.white, position: 'relative' }}>
        <div className={classes.topArea}>
          <div style={{ ...Helpers.fillRowCenter }}>
            <HoverIcon
              icon="fa-thin fa-xmark"
              color={Colors.white}
              hoverColor={Colors.halfWhite}
              style={{ fontSize: 17, marginRight: 16, marginLeft: 24 }}
              onClick={onClose}
            />
            <div style={{ lineHeight: 1.6875 }}>
              {t('marketplace')}
            </div>
          </div>

          <TopBarGems viewer={myViewer} />
        </div>

        <StickyContainer>

          <CardShopMatchFilters
            setSorting={setSorting}
            sorting={sorting}
            setRarity={setRarity}
            rarity={rarity}
            setQuality={setQuality}
            quality={quality}
            setIsSeasonal={setIsSeasonal}
            isSeasonal={isSeasonal}
          />

          <div
            className="inner-wrapper hide-scrollbar"
            style={{ margin: '0 auto', overflowY: 'auto', maxHeight: 'calc(100vh - 144px)' }}
          >
            {loading ?
              <Loading />
              :
              <>
                <div style={{
                  display: 'grid',
                  gap: '26px 8px',
                  gridTemplateColumns: 'repeat(4, 1fr)',
                }}>
                  {offers && offers.map((offer) => (
                    <MarketplaceMarketItem item={offer} myViewer={myViewer} />
                  ))}
                </div>

                <LoadMore count={offers && offers.length} totalCount={offers && totalCount}
                          loading={loading} onLoadMore={onLoadMore} />
              </>
            }
          </div>

        </StickyContainer>

      </div>
    </SlideInOut>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setCollectorsViewMarketplace,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(InventoryMatchMarketplace);