import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Helpers from '../../theme/Helpers';
import CustomTooltip from '../Tooltip/CustomTooltip';
import Colors from '../../theme/Colors';

const useStyles = makeStyles({
  item: {
    background: '#202C3D',
    borderRadius: 6,
    padding: 12.5,
    position: 'relative',
    marginTop: 10,
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    fontFamily: 'Montserrat',
  },
});

const CardShopSideCartAlbum = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { album, removeAlbum } = props;

  return (
    <div className={classes.item}>
      <div style={{ ...Helpers.fillRowCenter }}>
        <div style={{ fontSize: 22, fontWeight: 400 }}>
          {album.amount}
        </div>
        <div style={{ marginLeft: 15, marginRight: 10 }}>
          <img src={`${album.products[0].image}?width=38`} alt="Product" />
        </div>
        <div>
          <div style={{ fontWeight: 400 }}>
            {t(`${album.translation_template_code}_album_title`)}
          </div>
          <div style={{ fontWeight: 300, fontSize: 14, color: Colors.halfWhite }}>
            {t(`${album.translation_template_code}_album_info`)}
          </div>
        </div>
      </div>

      <div style={Helpers.fillRowCross}>
        <div style={{ marginRight: 18 }}>
          <div style={{ fontSize: 14, color: Colors.halfWhite, fontWeight: 300 }}>
            {t('costs')}
          </div>
          <div style={{ fontWeight: 400 }}>
            {((album.discounted_price ? album.discounted_price : album.products[0].costs) / 100).toFixed(2)
            .toString()
            .replace('.', ',')} €
          </div>
        </div>
        <CustomTooltip title={t('delete')}>
          <i
            className="fa-light fa-xmark"
            onClick={() => removeAlbum(album)}
            style={{ fontSize: 18, cursor: 'pointer', marginBottom: 20 }}
          />
        </CustomTooltip>
      </div>
    </div>
  );
};

export default CardShopSideCartAlbum;