import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { confirmAccountDeletion as confirmAccountDeletionMutation } from '../../apollo/mutations';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import PageTitle from '../../components/PageTitle/PageTitle';
import TopViewHeader from '../../components/TopViewHeader/TopViewHeader';
import { changeUserToken } from '../../redux/actions';

const conf = require('../../conf');

const useStyles = makeStyles({
  limitText: {
    textAlign: 'center',
    color: Colors.primary,
    textTransform: 'none',
    padding: 5,
  },
  box: {
    ...Helpers.boxBasics,
    padding: 10,
    width: '50%',
    margin: '0 auto 0',
  },
});

const ConfirmDeletionContainer = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { token } = useParams();

  const [confirmAccountDeletion] = useMutation(confirmAccountDeletionMutation, {
    onCompleted: () => {
      props.changeUserToken(null);
      localStorage.removeItem(conf.storage_keys.user_token);
      window.location.href = 'https://www.streamheroes.gg';
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
  });

  useEffect(() => {
    confirmAccountDeletion({ variables: { token } });
  }, [token]);

  return (
    <TopViewHeader>
      <PageTitle title="Delete account" />
      <div style={{ padding: 10 }}>
        <div className={classes.box}>
          <Typography variant="h3" className={classes.limitText}>
            <span>{t('delete_account_confirmation_text')}</span>
          </Typography>
        </div>

      </div>
    </TopViewHeader>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    changeUserToken,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(ConfirmDeletionContainer);