import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Avatar from '@material-ui/core/Avatar';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import HoverIcon from '../Icon/HoverIcon';
import CustomTooltip from '../Tooltip/CustomTooltip';
import { logAmplitudeEvent } from '../../utils/amplitude';
import { copyToClipboard } from '../../utils/helper';

import conf from '../../conf.json';

const useStyles = makeStyles((theme) => ({
  headRow: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    color: Colors.white,
    marginTop: 65,
    '& > div': {
      flex: 1,
    },

    [theme.breakpoints.down('md')]: {
      marginTop: 8,
    },
  },
  roundedIcon: {
    width: 40,
    height: 40,
    border: '1px solid #ffffff',
    borderRadius: 50,
    ...Helpers.fillRowCenter,
    cursor: 'pointer',
    transition: 'all 0.3s ease-out',
    '&:hover': {
      background: Colors.white,
      '& i': {
        color: '#010F22',
      },
    },
  },
  avatar: {
    width: 73,
    height: 73,
    border: 'none',
    marginRight: 14,
  },
}));

const SetHeadRow = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { t } = useTranslation();
  const { name, logoUrl, information, booster, matchCardType, rarity, handleClose, url, subTitle, disableLink } = props;

  const shareLink = () => {
    if (information)
      if (conf.track_amplitude)
        logAmplitudeEvent('share_CardView', {
          'Card ID': information._id,
          'Owner Name': name,
        });

    copyToClipboard(`${conf.base_url}/${information ? `inventory/cards?cardId=${information._id}` : `item-store/booster?boosterId=${booster._id}`}`);
    enqueueSnackbar(t('copy_success'), { variant: 'success' });
  };

  return (
    <div className={classes.headRow}>
      <div />
      <div
        style={{ ...Helpers.fillRowCenter, cursor: 'pointer' }}
        onClick={() => props.history.push(url)}
      >
        {(booster && booster.starter_pack_logo_url) ?
          <img
            src={`${booster.starter_pack_logo_url}?height=73`}
            alt="Starter pack logo"
            height={73}
          />
          :
          <>
            <div>
              <Avatar
                className={classes.avatar}
                alt="Avatar"
                src={logoUrl}
              />
            </div>
            <div>
              {name &&
              <div style={{ fontFamily: 'Bebas Neue', fontSize: 44, lineHeight: 1 }}>
                {name}
              </div>
              }
              {(matchCardType || subTitle) &&
              <div style={{ fontFamily: 'Montserrat', fontSize: 16, fontWeight: 300 }}>
                {(matchCardType && matchCardType === 'Card') || (rarity && rarity >= 1) ?
                  t('base_card_set')
                  :
                  !subTitle && t('special_card_set')
                }
                {subTitle && subTitle}
              </div>
              }
            </div>
          </>
        }
      </div>
      <div>
        <div style={{ ...Helpers.fill, ...Helpers.mainEnd }}>
          {!disableLink &&
          <CustomTooltip title={t('copy_link_to_clipboard')}>
            <div className={classes.roundedIcon} onClick={shareLink}>
              <HoverIcon
                icon="fa-light fa-link" hoverIcon="fa-regular fa-link"
                style={{ fontSize: 18 }}
              />
            </div>
          </CustomTooltip>
          }
          <div className={classes.roundedIcon} style={{ marginLeft: 10 }}
               onClick={handleClose}>
            <HoverIcon
              icon="fa-light fa-xmark" hoverIcon="fa-regular fa-xmark"
              style={{ fontSize: 18 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SetHeadRow);