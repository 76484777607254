import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { getInformationAboutCard, getRedeemedCardObjectInformation } from '../../apollo/queries';

import Loading from '../../components/Loading/Loading';
import ErrorMessage from '../../components/ErrorBoundary/ErrorMessage';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import PagePopup from '../../components/Dialog/PagePopup';
import CardViewDialog from '../../components/Cards/CardViewDialog';
import CardViewSpaceAction from '../../components/Cards/CardViewSpaceAction';

const CardViewDialogContainer = (props) => {
  const { card, handleClose, isLegacy } = props;

  const match_card = card.match_card ? card.match_card : card;

  const variables = { id: match_card._id };
  const { data, loading, error } = useQuery(getRedeemedCardObjectInformation, {
    variables,
    fetchPolicy: useCacheWithExpiration('getRedeemedCardObjectInformation', variables),
    skip: isLegacy,
  });
  const getInformationAboutCardQuery = useQuery(getInformationAboutCard, {
    variables: { id: (card.match_card ? card.match_card : card)._id },
    fetchPolicy: useCacheWithExpiration('getInformationAboutCard', variables),
    skip: !isLegacy,
  });

  if (error || getInformationAboutCardQuery.error) return <ErrorMessage error={error} />;

  let information = (data) && data.getRedeemedCardObjectInformation;
  const myViewer = (data) && data.getMyViewer;
  const oldInfo = (getInformationAboutCardQuery.data) && getInformationAboutCardQuery.data.getInformationAboutCard;

  if (oldInfo) {
    information = {
      _id: match_card._id,
      rarity: null,
      overall_collected: oldInfo.totalCollected,
      unique_collectors: oldInfo.totalCollectors,
      opened_as_number: oldInfo.myFirstCard.crafted_as_number,
      card_template_metadata: {
        match: oldInfo.match,
      },
      price: match_card.shop_price,
      viewer: {
        display_name: oldInfo.myFirstCard.viewer.display_name,
      },
      front_layers: [{
        img_url: match_card.image,
        physical_img_url: match_card.image,
      }],
      created_at: match_card.created_at,
    };
  }

  const isMyCard = myViewer && information && myViewer.login === information.viewer.login;

  return (
    <PagePopup withBlurred>
      {(loading || getInformationAboutCardQuery.loading) ?
        <Loading />
        :
        <>
          <CardViewDialog card={card} handleClose={handleClose} information={information}
                          isLegacy={isLegacy} isMyCard={isMyCard} myViewer={myViewer} />
          <CardViewSpaceAction information={information} />
        </>
      }
    </PagePopup>
  );
};

export default CardViewDialogContainer;