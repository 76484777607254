import {
  ADD_POPUP_EVENT,
  REMOVE_POPUP_EVENT,
  SET_LAST_LOGIN,
} from './constants';

const INITIAL_STATE = {
  events: [],
  showLastLogin: true,
  showEventAllCollected: true,
};

const popupEventReducer = (state = INITIAL_STATE, action) => {
  if (action.type === ADD_POPUP_EVENT) {
    let showEventAllCollected = state.showEventAllCollected;
    if (action.event.key === 'all-event-cards-collected') {
      if (state.showEventAllCollected)
        showEventAllCollected = false;
      else
        return state;
    }

    const tmpEvents = [...state.events];
    tmpEvents.push(action.event);

    return { ...state, events: tmpEvents, showEventAllCollected };
  } else if (action.type === REMOVE_POPUP_EVENT) {
    const tmpEvents = [...state.events];
    tmpEvents.shift();

    return { ...state, events: tmpEvents };
  } else if (action.type === SET_LAST_LOGIN) {
    return { ...state, showLastLogin: action.lastLogin };
  } else {
    return state;
  }
};

export default popupEventReducer;