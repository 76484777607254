import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import InventoryCard from './InventoryCard';
import InventoryColumnCard from './InventoryColumnCard';
import LoadMore from '../Loading/LoadMore';
import Helpers from '../../theme/Helpers';

const useStyles = makeStyles((theme) => ({
  cardsContainer: {
    width: '100%',
    display: 'grid',
    overflow: 'hidden',
    gap: '0 16px',
    gridTemplateColumns: 'repeat(auto-fill, 274px)',
    transition: 'all 0.6s ease-out',

    [theme.breakpoints.down('md')]: {
      ...Helpers.fillColCenter,
      marginTop: -25,
      gap: '16px 0',
    },
  },
}));

const InventoryCards = (props) => {
  const classes = useStyles();
  const { cards, t, isActive, onLoadMore, totalCount, isLegacy, cardDecks, notMy, variables, sellOnly } = props;
  const [direction, setDirection] = useState('row');

  return (
    <>
      <div className={classes.cardsContainer}>
        {cards && cards.map((card, index) => (
          direction === 'row' ?
            <InventoryCard
              key={`RowCard${card._id}`}
              card={card}
              t={t}
              switchLastHover={(index + 1) % (isActive ? 4 : 5) === 0}
              isLegacy={isLegacy}
              cardDecks={cardDecks}
              notMy={notMy}
              sellOnly={sellOnly}
              variables={variables}
            />
            :
            <InventoryColumnCard
              key={`ColumnCard${card._id}`}
              card={card}
              t={t}
              isLegacy={isLegacy}
            />
        ))}
      </div>

      {onLoadMore !== undefined &&
      <LoadMore
        count={cards && cards.length}
        totalCount={cards && totalCount}
        onLoadMore={onLoadMore}
      />
      }
    </>
  );
};

export default InventoryCards;