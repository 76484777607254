import {
  SET_ORDER_CART,
  SET_CLIENT_SECRET,
  UPDATE_CARD_IN_CART,
  SET_PRODUCTS_CART,
  UPDATE_AMOUNT_IN_PRODUCT,
  RESET_CARDS_IN_SHOP,
  UPDATE_AMOUNT_IN_STATIC,
  UPDATE_AMOUNT_IN_ALBUM, TOGGLE_NEW_CARD_IN_SHOP, SET_COUPON_CODE,
} from './constants';
import { getMatchName } from '../utils/helper';

const INITIAL_STATE = {
  products: [],
  isProductsSet: false,
  cards: [],
  newCards: [],
  static: [],
  albums: [],
  isSet: false,
  clientSecret: null,
  couponCode: null,
};

const cardShopReducer = (state = INITIAL_STATE, action) => {
  if (action.type === UPDATE_AMOUNT_IN_PRODUCT) {
    const tmpProducts = [...state.products];
    const { amount, name } = action;
    const findIdx = tmpProducts.findIndex(tmpProduct => tmpProduct.name === name);
    tmpProducts[findIdx].amount = amount;

    return { ...state, products: tmpProducts, isProductsSet: true };
  } else if (action.type === UPDATE_AMOUNT_IN_STATIC) {
    const tmpStatic = [...state.static];
    const { product } = action;
    const { amount, _id } = product;
    const findIdx = tmpStatic.findIndex(tmpProduct => tmpProduct._id === _id);
    if (findIdx === -1)
      tmpStatic.push(product);
    else {
      if (amount > 0)
        tmpStatic[findIdx].amount = amount;
      else
        tmpStatic.splice(findIdx, 1);
    }

    return { ...state, static: tmpStatic };
  } else if (action.type === UPDATE_AMOUNT_IN_ALBUM) {
    const tmpAlbums = [...state.albums];
    const { album } = action;
    const { amount, _id } = album;
    const findIdx = tmpAlbums.findIndex(tmpAlbum => tmpAlbum._id === _id);
    if (findIdx === -1)
      tmpAlbums.push(album);
    else {
      if (amount > 0)
        tmpAlbums[findIdx].amount = amount;
      else
        tmpAlbums.splice(findIdx, 1);
    }

    return { ...state, albums: tmpAlbums, isSet: true };
  } else if (action.type === UPDATE_CARD_IN_CART) {
    const tmpCards = [...state.cards];
    const { card, rCards, price } = action;
    let { _id, image, match } = card;
    const findIdx = tmpCards.findIndex(tmpCard => tmpCard._id === _id);
    const count = rCards.length;
    if (count > 0) {
      if (findIdx === -1) {
        const productName = `${getMatchName(card)}: ${card.__typename === 'Card' ? `Level ${card.level}` : card.card_name}`;

        if (!match)
          match = { // Streamheroes Viewer
            _id: '5deff43ff26ce73b223e994d',
            login: 'streamheroes',
            display_name: 'Streamheroes',
            profile_image_url: 'https://static-cdn.jtvnw.net/jtv_user_pictures/fa77cfe3-b7dd-443f-8ba8-594c93740805-profile_image-300x300.png',
            __typename: 'Viewer',
          };

        tmpCards.push({
          _id,
          name: productName,
          count,
          price,
          image,
          rCards, // redeemed card ids
          match,
        });
      } else {
        tmpCards[findIdx].count = rCards.length;
        tmpCards[findIdx].rCards = rCards;
      }
    } else {
      if (findIdx !== -1) tmpCards.splice(findIdx, 1);
    }

    return { ...state, cards: tmpCards, isSet: true };
  } else if (action.type === SET_PRODUCTS_CART) {
    const newProducts = action.products.map(product => {
      return { ...product, amount: product.unlimited ? 0 : 1 };
    });

    return { ...state, products: newProducts, isProductsSet: true };
  } else if (action.type === TOGGLE_NEW_CARD_IN_SHOP) {
    const tmpCards = state.newCards;
    const { card } = action;

    const findIdx = tmpCards.findIndex(tmpCard => tmpCard._id === card._id);
    if (findIdx === -1)
      tmpCards.push(card);
    else
      tmpCards.splice(findIdx, 1);

    return { ...state, newCards: tmpCards, isSet: true };
  } else if (action.type === SET_ORDER_CART) {
    return {
      ...state,
      newCards: action.newCards,
      cards: action.cards,
      static: action.staticProducts,
      albums: action.albumProducts,
      isSet: true,
    };
  } else if (action.type === SET_CLIENT_SECRET) {
    return { ...state, clientSecret: action.secret };
  } else if (action.type === SET_COUPON_CODE) {
    return { ...state, couponCode: action.code };
  } else if (action.type === RESET_CARDS_IN_SHOP) {
    return {
      ...state,
      clientSecret: null,
      newCards: [],
      cards: [],
      static: [],
      albums: [],
      isSet: false,
      couponCode: null,
    };
  } else {
    return state;
  }
};

export default cardShopReducer;