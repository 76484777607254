import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Helpers from '../../theme/Helpers';
import AnimationButton from '../Button/AnimationButton';
import NumberInput from '../Inputs/NumberInput';
import { logAmplitudeEvent } from '../../utils/amplitude';
import { updateAmountInStatic } from '../../redux/actions';
import Colors from '../../theme/Colors';
import conf from '../../conf'

const ShopAccessoriesItem = (props) => {
  const { t } = useTranslation();
  const { item, index, isLast, cardShop } = props;
  const [quantity, setQuantity] = useState(1);

  const staticProduct = cardShop.static && cardShop.static.find(staticProduct => staticProduct._id === item._id);
  const amount = staticProduct && staticProduct.amount ? staticProduct.amount : 0;

  const updateQuantity = () => {
    if (conf.track_amplitude) logAmplitudeEvent('Add Product to Shopping Cart', {
      'Product ID': item._id,
      'Product Name': item.name,
      'Product Count': item.amount,
      'Product Price': item.unit_price,
    });

    props.updateAmountInStatic({ ...item, amount: amount + quantity });
  };

  return (
    <div
      style={{
        padding: '32px 16px',
        borderBottom: isLast ? 'none' : '1px solid rgba(255, 255, 255, 0.08)',
      }}
    >
      <div style={{ ...Helpers.fillRowMain, ...Helpers.mainSpaceBetween }}>
        <div style={{ ...Helpers.fillRowMain }}>
          <div style={{
            background: 'rgba(255, 255, 255, 0.04)',
            width: 90,
            height: 90,
            borderRadius: 4,
            ...Helpers.fillColCenter,
            marginRight: 12,
          }}>
            <img src={`${item.image}?height=${index === 0 ? 66 : 60}`} alt="Product" />
          </div>
          <div style={{ maxWidth: 160 }}>
            <div style={{ lineHeight: 1.5 }}>{t(item.t_string)}</div>
            <div style={{ fontSize: 14, color: Colors.halfWhite, marginTop: 4 }}>
              {t(item.description)}
            </div>
          </div>
        </div>
        <div>
          {((item.discounted_price ? item.discounted_price : item.unit_price) / 100).toFixed(2)
          .toString()
          .replace('.', ',')
          } €
        </div>
      </div>

      <div style={{ marginTop: 12, ...Helpers.fillRowCenter }}>
        <div style={{ marginRight: 4 }}>
          <NumberInput
            value={quantity}
            max={100}
            onChange={(quantity) => setQuantity(quantity)}
          />
        </div>
        <AnimationButton
          status="neonGreen"
          onClick={updateQuantity}
          style={{
            width: '100%',
            fontFamily: 'Montserrat',
            fontSize: 14,
            padding: '14px 16px',
            borderRadius: 4,
            fontWeight: 500,
            border: 'none',
          }}
        >
          <i
            className="fa-light fa-cart-plus"
            style={{ fontSize: 18, marginRight: 8, verticalAlign: 'bottom' }}
          />
          {t('add_to_cart')}
        </AnimationButton>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { cardShop } = state;
  return { cardShop };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({ updateAmountInStatic }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(ShopAccessoriesItem);