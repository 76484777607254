import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import Helpers from '../../theme/Helpers';
import SVGIcon from '../SVGIcon/SVGIcon';
import AnimationButton from '../Button/AnimationButton';

import conf from '../../conf.json';
import Colors from '../../theme/Colors';
import HoverIcon from '../Icon/HoverIcon';

const useStyles = makeStyles(() => ({
  list: {
    marginTop: 13,
    padding: '0 16px',
    '& li': {
      margin: '6.5px 0',
      color: Colors.halfWhite,
      '&::marker': {
        color: Colors.white,
        width: 7,
        height: 7,
      },
      '& span': {
        color: Colors.white,
        fontWeight: 700,
      },
    },
  },
}));

const GemsPayPremium = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(true);
  const { onClose } = props;

  return (
    <div style={{ background: 'rgba(225, 33, 56, 0.3)', borderRadius: '0px 0px 4px 4px' }}>
      <div
        style={{
          padding: '18px 16px',
          ...Helpers.fillRowCenter,
          ...Helpers.mainSpaceBetween,
          borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
        }}>
        <div style={{ fontSize: 14, fontWeight: 600, ...Helpers.fillRowCross }}>
          <SVGIcon
            src={`${conf.cdn_url}/icons/logo.svg`}
            height={35}
            width={33}
            style={{ marginRight: 8 }}
          />
          {t('sh_premium')}
        </div>

        <AnimationButton
          status="neonPink"
          style={{ width: 86 }}
          onClick={() => {
            onClose();
            props.history.push('/premium');
          }}
        >
          {t('get_now')}
        </AnimationButton>
      </div>
      <div style={{ padding: '10.5px 16px', fontSize: 12 }}>
        <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween, cursor: 'pointer' }}
             onClick={() => setIsOpen(!isOpen)}>
          <div style={{ fontWeight: 500 }}>
            {t('what_you_get')}
          </div>
          <div>
            <HoverIcon
              icon={`fa-light fa-chevron-${isOpen ? 'down' : 'up'}`}
              color={Colors.white}
              hoverColor={Colors.halfWhite}
              style={{ fontSize: 15, color: Colors.white }}
            />
          </div>
        </div>

        {isOpen &&
        <ul className={classes.list}>
          <li>
            <div style={Helpers.fillRow}>
              <SVGIcon
                src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
                height={14}
                width={10}
                style={{ marginRight: 3 }}
              />
              <div dangerouslySetInnerHTML={{ __html: t('premium_info_1', { count: 300 }) }} />
            </div>
          </li>
          <li dangerouslySetInnerHTML={{ __html: t('premium_info_2') }} />
          <li dangerouslySetInnerHTML={{ __html: t('premium_info_3') }} />
          <li dangerouslySetInnerHTML={{ __html: t('premium_info_4') }} />
          <li>...</li>
        </ul>
        }
      </div>
    </div>
  );
};

export default withRouter(GemsPayPremium);