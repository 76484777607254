import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useQuery } from '@apollo/react-hooks';
import TextField from '@material-ui/core/TextField';
import { Link } from '@material-ui/core';

import { getShortURL } from '../../apollo/queries';

import Helpers from '../../theme/Helpers';
import Loading from '../Loading/Loading';
import CustomButton from '../Button/CustomButton';
import Colors from '../../theme/Colors';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

const conf = require('../../conf');

const useStyles = makeStyles(() => ({
  textField: {
    backgroundColor: '#0e0e0e',
    padding: '5px 20px',
    borderRadius: 5,
    border: '1px solid #931b28',
    minWidth: 320,
    '& input': {
      color: Colors.subTextColor,
      fontSize: 14,
    },
    '&::before, &::after': {
      display: 'none',
    },
  },
}));

const StreamerPanel = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { streamer } = props;
  const classes = useStyles();
  const textFieldRef = React.useRef(null);
  const variables = { url: `/profile/${streamer.login}` };
  const { data, loading } = useQuery(getShortURL, {
    variables,
    fetchPolicy: useCacheWithExpiration('getShortURL', variables),
  });

  const shortnerLink = (data) && data.shortnerLink;

  const image = process.env.PUBLIC_URL + '/start_collecting.png';

  const copyToClipboard = (e) => {
    textFieldRef.current.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the the whole text selected.
    e.target.focus();

    enqueueSnackbar(t('copy_success'), { variant: 'success' });
  };

  return (
    <div style={{ ...Helpers.fillColCenter, paddingTop: 20 }}>
      <img src={image} alt="Start collecting" />

      {loading ?
        <Loading />
        :
        <>
          <TextField
            inputRef={textFieldRef}
            disableUnderline
            InputProps={{
              readOnly: true,
              classes: { root: classes.textField },
            }}
            style={{ margin: '10px 0' }}
            defaultValue={`${conf.base_url}/go/${shortnerLink.key}`}
          />
          <Link
            href={image}
            download
            style={{ textDecoration: 'none' }}
            onClick={copyToClipboard}
          >
            <CustomButton status="primary" style={{ marginTop: 15, marginBottom: 15 }}>
              {t('on_boarding_download_now')}
            </CustomButton>
          </Link>
        </>
      }
    </div>
  );
};

export default StreamerPanel;