import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Jimp from 'jimp';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import { setCardPublicStatus as setCardPublicStatusMutation } from '../../apollo/mutations';
import { getRedeemedCardObjectInformation } from '../../apollo/queries';

import CardViewDetails from '../../components/Cards/CardViewDetails';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import CardViewTimeline from '../../components/Cards/CardViewTimeline';
import AnimationButton from '../../components/Button/AnimationButton';
import CustomTooltip from '../../components/Tooltip/CustomTooltip';
import QualityLine from '../../components/QualityLine/QualityLine';
import CardExplanationContainer from '../CardExplanation/CardExplanation';
import SetHeadRow from '../Booster/SetHeadRow';
import HoverIcon from '../Icon/HoverIcon';
import { isUserPremium } from '../../utils/helper';
import { logAmplitudeEvent } from '../../utils/amplitude';
import AdSense from '../AdSense/AdSense';
import CardImageDecision from './CardImageDecision';

import conf from '../../conf';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(() => ({
  headLine: {
    width: '100%',
    height: 8,
    background: 'linear-gradient(90deg, #EFDA4900 0%, #EFDA49 52%, #EFDA4900 100%)',
    marginBottom: 60,
  },
  body: {
    marginTop: 82,
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    maxWidth: 1440,
    margin: '0 auto',
  },
  cardImage: {
    borderRadius: 4,
  },
  hoverIcon: {
    border: '1px solid white',
    borderRadius: 50,
    padding: '0px 9px 2px',
    cursor: 'pointer',
    '&:hover': {
      background: Colors.white,
      '& svg': {
        color: '#010F22',
      },
    },
  },
  supportArea: {
    background: 'rgba(225, 33, 56, 0.17)',
    color: '#E12138',
    padding: '5px 8px',
    borderRadius: 4,
    fontFamily: 'Montserrat',
    fontSize: 12,
    cursor: 'pointer',
    '&:hover': {
      background: '#E12138',
      color: Colors.white,
    },
  },
  box: {
    padding: '16px 20px',
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    marginBottom: 8,
  },
  linkText: {
    color: Colors.white,
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const CardViewDialogBody = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const {
    levelColor,
    name,
    handleClose,
    logoUrl,
    information,
    isLegacy,
    matchURL,
    isMyCard,
    myViewer,
  } = props;
  const [showExplanation, setShowExplanation] = useState(false);
  const [compositedImage, setCompositedImage] = useState(null);

  const [setCardPublicStatus, { loading }] = useMutation(setCardPublicStatusMutation, {
    onCompleted: () => {
      enqueueSnackbar(t('success'), { variant: 'success' });
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [
      { query: getRedeemedCardObjectInformation, variables: { id: information._id } },
    ],
  });

  const buyItem = () => {
    window.location.href = '/card-shop';
  };

  const setPublicStatus = () => {
    setCardPublicStatus({ variables: { id: information._id } });
  };

  const card = information && {
    image: compositedImage ? compositedImage : (information.front_layers && information.front_layers[0].physical_img_url),
    physical_card_layer_image: information.physical_card_layer_image,
    backImage: information.back_layers && information.back_layers[0].physical_img_url,
    frontEffect: (information.front_layers && information.front_layers.length > 1) && information.front_layers[1].physical_img_url,
    backEffect: (information.back_layers && information.back_layers.length > 1) && information.back_layers[1].physical_img_url,
    noEffectLayer: compositedImage || isLegacy || (information.quality && information.quality < 0),
  };
  const { isPremiumActive } = isUserPremium(myViewer);

  useEffect(() => {
    if (!compositedImage && card)
      mergeWithScreenBlend(card);
  }, [card]);

  const mergeWithScreenBlend = async (card) => {
    const source = await Jimp.read(card.image.replace(conf.cdn_url, conf.no_cors_cdn_url));
    const target = await Jimp.read(`https://s3.eu-central-1.wasabisys.com/streamheroes-card-manager/${card.frontEffect}`);

    source.composite(target, 0, 0, {
      mode: Jimp.BLEND_SCREEN,
    });

    const dataUri = await source.getBase64Async(Jimp.MIME_PNG);

    setCompositedImage(dataUri);
  };

  information.overall_redeems = information.overall_collected;
  information.unique_user_redeems = information.unique_collectors;

  return (
    <>
      {showExplanation &&
      <CardExplanationContainer handleClose={() => setShowExplanation(false)} />
      }

      <div className={classes.headLine} style={{
        background: `linear-gradient(90deg, ${levelColor}00 0%, ${levelColor} 52%, ${levelColor}00 100%)`,
      }} />

      <div style={{ maxWidth: 1440, margin: '0 auto' }}>
        <SetHeadRow name={name} information={information} rarity={information.rarity}
                    logoUrl={logoUrl} url={matchURL}
                    handleClose={handleClose} />
      </div>
      <div className={classes.body}>
        {!isMobile &&
        <div
          style={{ position: 'absolute', left: 16, top: '50%', transform: 'translate(0, -50%)' }}
        >
          <CardViewTimeline isLegacy={isLegacy} information={{ ...information, events: [] }} />
        </div>
        }

        {(conf.ad_area.card_view && myViewer && !isPremiumActive) &&
        <AdSense
          client={conf.google_adsense}
          style={{
            position: 'absolute',
            left: 400,
            top: '50%',
            transform: 'translate(0, -50%)',
            width: 300,
            height: 600,
          }}
          format=""
        />
        }

        {!isMobile &&
        <CardImageDecision
          card={card}
          information={information}
          isLegacy={isLegacy}
          style={{
            height: 604,
            width: 498,
          }}
          zoom={3.5}
        />
        }

        <div style={{ width: 371, marginLeft: 'auto' }}>
          <div style={{ marginBottom: 16, ...Helpers.fillRowCross }}>
            <div style={{ marginRight: 12 }}>
              <img
                src={`${conf.cdn_url}/badges/card-level/${information.rarity !== null ? information.rarity : 5}.png?height=43`}
                alt="Level badge"
              />
            </div>
            <div style={{
              color: levelColor,
              fontFamily: 'Bebas Neue',
              fontSize: 36,
              lineHeight: 1,
            }}>
              {t(information.rarity !== null ? `rareness_level.${information.rarity}` : 'legacy')}
            </div>
          </div>

          <div className={classes.box}>
            {isLegacy || (information.quality && information.quality < 0) ?
              <div style={{ color: Colors.halfWhite }}>
                {t('no_quality_available')}
              </div>
              :
              <>
                <QualityLine quality={information.quality} spaceBetween={2}
                             width={[19, 94, 145, 46, 19]} condition={information.condition} />
                <div style={{
                  marginTop: 12,
                  ...Helpers.fillRowCross,
                  ...Helpers.mainSpaceBetween,
                  fontFamily: 'Montserrat',
                  fontSize: 14,
                  fontWeight: 300,
                }}
                >
                  <div style={{ color: Colors.halfWhite }}>
                    {t('need_more_infos_about_quality')}
                  </div>
                  <div
                    className={classes.linkText}
                    onClick={() => {
                      if (conf.track_amplitude) logAmplitudeEvent('learnMore_CardView');
                      setShowExplanation(true);
                    }}
                  >
                    {t('learn_more')}
                  </div>
                </div>
              </>
            }
          </div>

          {information.redeemed_booster &&
          <div className={classes.box} style={Helpers.fillRowCross}>
            <div style={{ marginRight: 13 }}>
              {information.redeemed_booster.generic_booster_metadata &&
              <img
                src={`${conf.cdn_url}${information.redeemed_booster.generic_booster_metadata.front_image}?height=64`}
                alt="Booster pack" />
              }
            </div>
            <div>
              {name &&
              <div style={{
                fontFamily: 'Montserrat',
                fontSize: 16,
                color: Colors.white,
                fontWeight: 300,
              }}>
                {name}
              </div>
              }
              <div style={{
                fontFamily: 'Montserrat',
                fontSize: 14,
                color: Colors.halfWhite,
                marginTop: 5,
                fontWeight: 300,
              }}>
                {information.rarity >= 1 ?
                  t('base_card_set')
                  :
                  t('special_card_set')
                }
              </div>
            </div>
          </div>
          }

          {information.season_id &&
          <div className={classes.box} style={Helpers.fillRowCross}>
            <div style={{ marginRight: 13 }}>
              <img
                src={information.season_id.logo}
                alt="Season Logo"
                style={{ width: 44 }} />
            </div>
            <div>
              <div style={{
                fontSize: 16,
                color: Colors.white,
                fontWeight: 300,
                textTransform: 'capitalize',
              }}>
                {t('season')} {information.season_id.season}
              </div>
              <div style={{
                fontSize: 14,
                color: 'rgba(255, 255, 255, 0.5)',
                marginTop: 5,
                fontWeight: 300,
              }}>
                {information.season_id.name}
              </div>
            </div>
          </div>
          }

          <CardViewDetails card={card} informationData={information} />

          <div style={{
            ...Helpers.fillRowCross,
            ...Helpers.mainSpaceBetween,
            position: 'relative',
            marginTop: 22,
          }}>

            {name &&
            <CustomTooltip title={t('buying_support_info')}>
              <div className={classes.supportArea}>
                {t('support_match', { match: name })}
              </div>
            </CustomTooltip>
            }

            <div>
              <CustomTooltip title={t('card_order_explanation')}>
                <div style={{ borderRadius: 50 }}>
                  <HoverIcon
                    icon="fa-light fa-circle-info" hoverIcon="fa-solid fa-circle-info"
                    color="rgba(255, 255, 255, 0.5)" hoverColor={Colors.white}
                    style={{ fontSize: 18 }}
                  />
                </div>
              </CustomTooltip>
            </div>
          </div>

          <div>
            <img
              src={information.front_layers[0].img_url}
              id={`card-view-image-${information._id}`}
              alt="Path"
              style={{ opacity: 0, position: 'absolute', left: 0, top: 0, zIndex: -1 }}
            />

            <div style={{ marginTop: 10, ...Helpers.fillRowCross }}>
              <AnimationButton
                status={'neonGreen'}
                onClick={buyItem}
                // disabled
                style={{
                  fontFamily: 'Montserrat',
                  fontSize: 14,
                  padding: 11,
                  width: '100%',
                  borderRadius: 6,
                  marginRight: isLegacy ? 0 : 3,
                }}
              >
                <i
                  className="fa-light fa-cart-shopping"
                  style={{ marginRight: 8, fontSize: 14 }}
                />
                {t('go_to_card_shop')}
              </AnimationButton>

              {(!isLegacy && isMyCard) &&
              <AnimationButton
                status={'gray'}
                onClick={loading ? null : () => setPublicStatus()}
                style={{
                  fontFamily: 'Montserrat',
                  padding: 11,
                  width: 50,
                  borderRadius: 6,
                  marginLeft: 3,
                }}
              >
                {loading ?
                  <i className="fa-light fa-loader fa-spin" style={{ fontSize: 18 }} />
                  :
                  <i
                    className={`fa-light fa-heart${information.is_public ? '-crack' : ''}`}
                    style={{ fontSize: 18 }}
                  />
                }
              </AnimationButton>
              }
            </div>
          </div>

          <div style={{
            marginTop: 6,
            fontSize: 12,
            fontFamily: 'Montserrat',
            color: Colors.halfWhite,
            fontWeight: 300,
          }}>
            {t('card_id')} {information._id}
          </div>
        </div>
      </div>
    </>
  );
};

export default CardViewDialogBody;