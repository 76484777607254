import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from '@material-ui/core';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import RafflePrices from './RafflePrices';
import RaffleTop from './RaffleTop';
import RaffleMatchesSlider from './RaffleMatchesSlider';
import RaffleEventOverview from './RaffleEventOverview';

const useStyles = makeStyles(() => ({
  container: {
    color: Colors.white,
  },
  legalBox: {
    ...Helpers.textCenter,
    padding: 40,
  },
  link: {
    color: Colors.white,
    textDecoration: 'none',
    '&:hover': {
      color: Colors.subTextColor,
    },
  },
}));

const Raffle = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { raffleWithMyTickets } = props;
  const { raffle } = raffleWithMyTickets;

  if (!raffle)
    return (
      <h1 style={{ ...Helpers.textCenter, ...{ color: '#ed2025' } }}>
        Currently there is no active raffle!
      </h1>
    );

  // raffle_how_to_get_tickets

  return (
    <div className={classes.container}>
      <RaffleTop raffle={raffle} />

      <RaffleMatchesSlider t={t} matches={raffle.matches} />

      <RaffleEventOverview t={t} totalTickets={raffleWithMyTickets.tickets_cards} matches={raffle.matches} />

      <RafflePrices />

      <div className={classes.legalBox}>
        <Link href="/page/competition" target="_blank" className={classes.link}>
          <small>{t('raffle_legal_notice')}</small>
        </Link>
      </div>
    </div>
  );
};

export default withRouter(Raffle);