import gql from 'graphql-tag';

export const gamesWithLiveStreamsCount = gql`
    query gamesWithLiveStreamsCount($pagination: Pagination) {
        gamesWithLiveStreamsCount(pagination: $pagination) {
            _id
            count_streams
            game {
                name
                box_art_url
            }
        }
    }
`;

export const getLiveStreamByStreamer = gql`
    query liveStreamByStreamer($login: String!) {
        liveStreamByStreamer(login: $login) {
            _id
            title
            thumbnail_url
            viewer_count
            started_at
            streamer {
                _id
                login
                display_name
                profile_image_url
                view_count
                twitch_id
                streamheroes_type
                broadcaster_type
                description
                follower_count
                edition_to_show
                set_badges
                sh_viewer_since
                organisation {
                  _id
                  name
                  code
                  logo_url
                  main_channel
                }
                created_at
                deleted
            }
            game {
                _id
                name
                box_art_url
            }
        }
    }
`;

export const getViewer = gql`
    query viewer($login: String) {
        viewer(login: $login) {
            _id
            login
            display_name
            profile_image_url
            facebook
            youtube
            twitter
            instagram
            created_at
            country
            set_badges
            premium_banner
            premium_frame
            subscription_tier
            subscription {
                valid_until
                tier
                subscription_id
                status
            }
        }
    }
`;

export const getStreamer = gql`
    query streamer($id: ID, $login: String, $twitch_id: Int) {
        streamer(id: $id, login: $login, twitch_id: $twitch_id) {
            _id
            login
            display_name
            streamheroes_type
            twitch_id
            profile_image_url
            stream_config {
                sound
                animation
                min_level_alerts
                min_level_bot
                custom_msg
                custom_msg_twitch
                should_whisper
                volume
            }
        }
    }
`;

export const getMyStreamer = gql`
    query getMyStreamer {
        getMyStreamer {
            _id
            login
            display_name
            streamheroes_type
            twitch_id
            booster_design
            profile_image_url
            stream_config {
                sound
                animation
                min_level_alerts
                min_level_bot
                custom_msg
                custom_msg_twitch
                should_whisper
                volume
                max_events
                event_types
                styles {
                    background_color
                    color
                    font_size
                    font_family
                    animation
                }
            }
        }
    }
`;

export const getSearchResults = gql`
    query getSearchResults($filter: Filter, $pagination: Pagination) {
        getSearchResults(filter: $filter, pagination: $pagination) {
            viewers {
                _id
                login
                display_name
                twitch_id
                profile_image_url
            }
            streamers {
                _id
                login
                display_name
                twitch_id
                profile_image_url
            }
            games {
                _id
                name
                box_art_url
            }
            organisations {
                _id
                name
                code
                logo_url
            }
        }
    }
`;

export const getMyViewer = gql`
    query getMyViewer {
        getMyViewer {
            _id
            login
            display_name
            profile_image_url
            email
            facebook
            youtube
            language
            twitter
            instagram
            created_at
            twitch_id
            no_digest_mail
            country
            streamheroes_role
            card_design
            show_onboarding
            muted_streamers
            streamer_dashboard
            booster_animation
            set_badges
            card_set_design {
                isActive
                cards {
                    set
                    setLevel
                    cardLevel
                }
            }
            max_coins_size
            sh_coins
            premium_banner
            premium_frame
            subscription_tier
            subscription {
                valid_until
                tier
                subscription_id
                status
                sidekick_link
            }
        }
    }
`;

export const hasUserFullEnergyTanks = gql`
    query hasUserFullEnergyTanks {
        hasUserFullEnergyTanks
    }
`;

export const handleTwitchLogin = gql`
    query handleTwitchLogin($code: String!, $referrer: String, $platform: String, $sidekickToken: String, $isPreview: Boolean) {
        handleTwitchLogin(code: $code, referrer: $referrer, platform: $platform, sidekickToken: $sidekickToken, isPreview: $isPreview) {
            token
            first_login
            error
        }
    }
`;

export const gameViewerLeaderBoard = gql`
    query gameViewerLeaderBoard($groupBy:String!, $game:ID, $pagination: Pagination, $filter: Filter) {
        gameViewerLeaderBoard(game:$game, groupBy:$groupBy, pagination: $pagination, filter: $filter) {
            game {
                name
            }
            viewer {
                _id
                display_name
                login
                profile_image_url
                country
            }
            seq
        }
    }
`;

export const organisationViewerLeaderBoard = gql`
    query organisationViewerLeaderBoard($groupBy: String!, $organisation: ID, $pagination: Pagination, $filter: Filter) {
        organisationViewerLeaderBoard(organisation: $organisation, groupBy: $groupBy, pagination: $pagination, filter: $filter) {
            organisation {
                name
            }
            viewer {
                _id
                display_name
                login
                profile_image_url
                country
            }
            seq
        }
    }
`;

export const getMyNotifications = gql`
    query getMyNotifications($sorting: Sorting) {
        getMyNotifications(sorting: $sorting) {
            _id
            title
            body
            type
            created_at
            is_read
            web_rel_url
        }
    }
`;

export const myCollectedPointsForType = gql`
    query viewerRedeemedPoints($streamer: ID, $organisation: ID, $game: ID) {
        viewerRedeemedPoints(streamer: $streamer, organisation: $organisation, game: $game) {
            _id
            points_season
            redeemed_points_season
            redeemed_points
            match {
                ... on Streamer {
                    _id
                }
                ... on Organisation {
                    _id
                }
                ... on Game {
                    _id
                }
            }
        }
    }
`;

export const getMyRedeemedCards = gql`
    query getMyRedeemedCards($streamer: ID, $organisation: ID, $sorting: Sorting, $pagination: Pagination, $filter: Filter, $ids_in_showcase: [ID]) {
        getMyRedeemedCards(streamer: $streamer, organisation: $organisation, sorting: $sorting, pagination: $pagination, filter: $filter, ids_in_showcase: $ids_in_showcase) {
            cards_count
            streamer_organisation {
                 main_channel
                 logo_url
            }
            streamers {
                _id
                display_name
            }
            organisations {
                _id
                name
            }
            levels
            cards {
                _id
                count
                matchCardModel
                match_card {
                    ... on Card {
                        _id
                        level
                        image
                        matchModel
                        created_at
                        edition
                        match {
                            ... on Streamer {
                                _id
                                display_name
                                login
                            }
                            ... on Game {
                                _id
                                name
                            }
                            ... on Organisation {
                                _id
                                name
                                code
                            }
                        }
                    }
                    ... on SpecialCard {
                        _id
                        card_name
                        image
                        costs
                        limit
                        max_count_per_viewer
                        created_at
                        collectible_from
                        collectible_until
                        matchModel
                        edition
                        match {
                            ... on Streamer {
                                _id
                                display_name
                                login
                            }
                            ... on Game {
                                _id
                                name
                            }
                            ... on Organisation {
                                _id
                                name
                                code
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const getTypeCards = gql`
    query getTypeCards($streamer: ID, $organisation: ID, $game: ID, $filter: Filter) {
        getTypeCards(streamer: $streamer, organisation: $organisation, game: $game, filter: $filter) {
            _id
            count
            matchCardModel
            match_card {
                ... on Card {
                    _id
                    level
                    image
                    matchModel
                    created_at
                    is_archived
                    matchId
                }
                ... on SpecialCard {
                    _id
                    card_name
                    image
                    costs
                    limit
                    max_count_per_viewer
                    created_at
                    collectible_from
                    collectible_until
                    matchModel
                    sorting
                    edition
                    matchId
                }
            }
        }
    }
`;

export const gameWithLiveStreamsCount = gql`
    query gameWithLiveStreamsCount($name: String!) {
        gameWithLiveStreamsCount(name: $name) {
            _id
            name
            box_art_url
            count_streams
        }
    }
`;

export const getWatchedLiveStreams = gql`
    query getWatchedLiveStreams {
        getWatchedLiveStreams {
            _id
            title
            language
            thumbnail_url
            viewer_count
            has_cards
            streamer {
                _id
                login
                display_name
                profile_image_url
                broadcaster_type
            }
            game {
                _id
                name
                box_art_url
            }
            cards {
                _id
                level
                image
                count_created
            }
            special_cards {
                _id
                image
                count_created
            }
        }
    }
`;

export const getLiveStreamsOfStreamersWithCards = gql`
    query getLiveStreamsOfStreamersWithCards($pagination: Pagination, $withCardObjects: Boolean) {
        getLiveStreamsOfStreamersWithCards(pagination: $pagination, withCardObjects: $withCardObjects) {
            _id
            title
            language
            thumbnail_url
            viewer_count
            has_cards
            streamer {
                _id
                login
                display_name
                profile_image_url
            }
            game {
                _id
                name
                box_art_url
            }
            boosters {
                _id
                front_image
                edition
                is_custom_booster
                
                generic_cards {
                    _id
                    card_layer_image
                    rarity
                }
            }
        }
    }
`;

export const getSocialMediaOfAUser = gql`
    query viewer($twitch_id: Int) {
        viewer(twitch_id: $twitch_id) {
            facebook
            youtube
            twitter
            instagram
        }
    }
`;

export const getLatestPlayedGamesOfAStreamer = gql`
    query getLatestPlayedGamesOfAStreamer($id: ID!, $pagination: Pagination, $sorting: Sorting) {
        getLatestPlayedGamesOfAStreamer(id: $id, pagination: $pagination, sorting: $sorting) {
            first_streamed
            last_streamed
            game {
                twitch_id
                name
                box_art_url
            }
        }
    }
`;

export const getMyStreamerCards = gql`
    query getMyStreamerCards {
        getMyStreamerCards {
            _id
            level
            image
            migrationImage
            matchModel
            created_at
            isApproved
            isRejected
            isDraft
            version
            match {
                ... on Streamer {
                    _id
                    display_name
                    login
                }
            }
        }
    }
`;

export const getCard = gql`
    query card($id: ID!) {
        card(id: $id) {
            ... on Card {
                _id
                level
                image
                matchModel
                created_at
                match {
                    ... on Streamer {
                        _id
                        display_name
                        login
                    }
                    ... on Game {
                        _id
                        name
                    }
                    ... on Organisation {
                        _id
                        name
                        code
                    }
                }
            }
            ... on SpecialCard {
                _id
                image
                matchModel
                created_at
                match {
                    ... on Streamer {
                        _id
                        display_name
                        login
                    }
                    ... on Game {
                        _id
                        name
                    }
                    ... on Organisation {
                        _id
                        name
                        code
                    }
                }
            }
        }
    }
`;

export const getBooster = gql`
    query getBooster($id: ID!) {
        getBooster(id: $id) {
            _id
            front_image
            back_image
            redeemable_from
            redeemable_until
            energy_costs
            edition
            is_custom_booster
            match_meta_data {
                ... on Streamer {
                    _id
                    login
                    display_name
                    profile_image_url
                }
                ... on Game {
                    _id
                    name
                    box_art_url
                }
                ... on Organisation {
                    _id
                    name
                    code
                    logo_url
                }
                ... on GenericMatch {
                    _id
                    name
                    image_url
                }
            }
        }
    }
`;

export const getInformationAboutCard = gql`
    query getInformationAboutCard($id: ID!) {
        getInformationAboutCard(id: $id) {
            totalCollected
            totalCollectors
            myFirstCard {
                _id
                crafted_as_number
                events {
                    type
                    key
                    params
                }
                viewer {
                    _id
                    display_name
                }
                created_at
            }
            match {
                ... on Streamer {
                    _id
                    login
                    display_name
                    profile_image_url
                    booster_design
                }
                ... on Game {
                    _id
                    name
                    box_art_url
                }
                ... on Organisation {
                    _id
                    name
                    code
                    logo_url
                }
            }
        }
    }
`;

export const getShowcaseCards = gql`
    query getShowcaseCards($viewer: ID, $sorting: Sorting) {
        getShowcaseCards(viewer: $viewer, sorting: $sorting) {
            _id
            showcase_index
            matchCardModel
            match_card {
                ... on Card {
                    _id
                    level
                    image
                    matchModel
                    created_at
                    match {
                        ... on Streamer {
                            _id
                            display_name
                            login
                        }
                        ... on Game {
                            _id
                            name
                        }
                        ... on Organisation {
                            _id
                            name
                            code
                        }
                    }
                }
                ... on SpecialCard {
                    _id
                    image
                    matchModel
                    created_at
                    match {
                        ... on Streamer {
                            _id
                            display_name
                            login
                        }
                        ... on Game {
                            _id
                            name
                        }
                        ... on Organisation {
                            _id
                            name
                            code
                        }
                    }
                }
            }
        }
    }
`;

export const getMyViewerRankingWithPosition = gql`
    query getMyViewerRankingWithPosition($filter: Filter) {
        getMyViewerRankingWithPosition(filter: $filter) {
            _id
            total_points
            position
            card_count
            viewer {
                _id
                display_name
                login
                profile_image_url
                country
            }
        }
    }
`;

export const checkRequirementsToBecomeAStreamhero = gql`
    query checkRequirementsToBecomeAStreamhero {
        checkRequirementsToBecomeAStreamhero {
            _id
            login
            display_name
            broadcaster_type
        }
    }
`;

export const getHeaderKPIs = gql`
    query getHeaderKPIs {
        getHeaderKPIs {
            coins_today
            rank_points
            amount_cards
            current_level
            current_level_badge_pic_url
            next_level_required_points
            current_level_required_points
            leaderboard_rank
        }
    }
`;

export const getViewerRankingsCount = gql`
    query getViewerRankingsCount($filter: Filter) {
        getViewerRankingsCount(filter: $filter)
    }
`;

export const getUpvotyToken = gql`
    query getUpvotyToken {
        getUpvotyToken
    }
`;

export const getCardsToApprove = gql`
    query getCardsToApprove{
        getCardsToApprove {
            _id
            cards {
                _id
                level
                image
                migrationImage
                migrationDpi
                isApproved
                isRejected
                version
                dpi
            }
            streamer {
                _id
                login
                profile_image_url
                display_name
            }
        }
    }
`;

export const getAchievements = gql`
    query achievements($userId: ID) {
        achievements(userId: $userId) {
            isComplete
            percentComplete
            achievementId
            earnedAchievementId
            achievement {
                id
                applicationId
                name
                description
                meta {
                    icon
                    created
                }
                resources {
                    criteria {
                        id
                        applicationId
                        name
                        description
                        key
                    }
                }
            }
        }
    }
`;

export const getURLByShortKey = gql`
    query urlByKey($key: String!) {
        urlByKey(key: $key) {
            _id
            url
            key
        }
    }
`;

export const getShortURL = gql`
    query shortnerLink($url: String!) {
        shortnerLink(url: $url) {
            _id
            url
            key
        }
    }
`;

export const streamerLeaderBoard = gql`
    query getStreamerLeaderboard($streamer: ID!, $pagination: Pagination, $filter: Filter, $timeframe: String) {
        getStreamerLeaderboard(streamer: $streamer, pagination: $pagination, filter: $filter, timeframe: $timeframe) {
            entries {
                seq
                pos
                updated_at
                viewer {
                    _id
                    login
                    profile_image_url
                    display_name
                    country
                    is_anonymous
                    subscription_tier
                    premium_frame
                }
            }
            count
        }
    }
`;

export const getMyStreamerLeaderBoardPosition = gql`
    query getMyStreamerLeaderBoardPosition($streamer: ID!, $filter: Filter) {
        getMyStreamerLeaderBoardPosition(streamer: $streamer, filter: $filter) {
            seq
            position
            viewer {
                _id
                login
                profile_image_url
                display_name
                country
                is_anonymous
            }
        }
    }
`;

export const gameLeaderBoard = gql`
    query getGameLeaderboard($game: ID!, $pagination: Pagination, $filter: Filter, $timeframe: String) {
        getGameLeaderboard(game: $game, pagination: $pagination, filter: $filter, timeframe: $timeframe) {
            entries {
                seq
                pos
                matchModel
                updated_at
                match {
                    ... on Streamer {
                        _id
                        login
                        profile_image_url
                        display_name
                    }
                    ... on Game {
                        _id
                        name
                        box_art_url
                    }
                    ... on Organisation {
                        _id
                        name
                        code
                        logo_url
                    }
                }
                viewer {
                    _id
                    login
                    profile_image_url
                    display_name
                    country
                    subscription_tier
                    premium_frame
                }
            }
            count
        }
    }
`;

export const getViewerRankingPositions = gql`
    query getViewerRankingPositions($viewer: ID, $matchModel: String, $sorting: [Sorting]) {
        getViewerRankingPositions(viewer: $viewer, matchModel: $matchModel, sorting: $sorting) {
            seq
            pos
            matchModel
            updated_at
            match {
                ... on Streamer {
                    _id
                    login
                    profile_image_url
                    display_name
                }
                ... on Game {
                    _id
                    name
                    box_art_url
                }
                ... on Organisation {
                    _id
                    name
                    code
                    logo_url
                }
            }
        }
    }
`;

export const getPolls = gql`
    query getPolls {
        getPolls {
            _id
            title
            answers
            is_published
            type
            target
            created_at
            published_at
            finished_at
            count_answers
        }
    }
`;

export const getAnswersByPoll = gql`
    query getAnswersByPoll($poll: ID!) {
        getAnswersByPoll(poll: $poll) {
            _id
            answers
            annotation
        }
    }
`;

export const getNextAvailablePoll = gql`
    query getNextAvailablePoll {
        getNextAvailablePoll {
            count
            poll {
                _id
                title
                answers
                finished_at
                type
                target
            }
        }
    }
`;

export const getAllBadges = gql`
    query getAllBadges {
        getAllBadges {
            viewer {
                category
                badges {
                    unique_id
                    title
                    pic_url
                    description
                    is_set
                }
            }
            streamer {
                category
                badges {
                    unique_id
                    title
                    pic_url
                    description
                    is_set
                }
            }
        }
    }
`;

export const getLatestStreamUpdates = gql`
    query getLatestStreamUpdates($pagination: Pagination) {
        getLatestStreamUpdates(pagination: $pagination) {
            _id
            seq
            streamer {
                _id
                 login
                 display_name
            }
            updated_at
        }
    }
`;

export const getQuestProgress = gql`
    query getQuestProgress {
        getQuestProgress {
            daily
            daily_reward_collected
            daily_title
            daily_text
            weekly
            weekly_title
            weekly_text
            weekly_reward_collected
            game {
                name
            }
            streamer {
                login
            }
        }
    }
`;

export const getTasks = gql`
    query getTasks($streamer_only: Boolean) {
        getTasks(streamer_only: $streamer_only) {
            redeemed {
                internal_id
                type
                title
                text
                progress
                action_url
            }
            not_redeemed {
                internal_id
                type
                title
                text
                progress
                action_url
            }
        }
    }
`;

export const getAllActiveCards = gql`
    query getAllActiveCards($pagination: Pagination, $sorting: Sorting, $level: Int, $streamer: ID, $organisation: ID, $game: ID, $includeCards: Boolean) {
        getAllActiveCards(pagination: $pagination, sorting: $sorting, level: $level, streamer: $streamer, organisation: $organisation, game: $game, includeCards: $includeCards) {
            count
            entries {
                _id
                streamer {
                    _id
                    display_name
                    login
                    profile_image_url
                    booster_design
                }
                collected_count
                cards {
                    ... on Card {
                        _id
                        level
                        image
                    }
                }
                special_cards {
                    ... on SpecialCard {
                        _id
                        image
                    }
                }
            }
        }
    }
`;

export const getStatsForTimeFrame = gql`
    query getStatsForTimeFrame($startDate: String, $endDate: String) {
       getStatsForTimeframe(startDate: $startDate, endDate: $endDate) {
           _id
           values {
               value
               check_start
           }
       }
    }
`;

export const getStreamers = gql`
    query streamers($ids: [ID], $pagination: Pagination) {
        streamers(ids: $ids, pagination: $pagination) {
            _id
            login
            display_name
            profile_image_url
        }
    }
`;

export const getMyEmotes = gql`
    query getMyEmotes {
        getMyEmotes {
            emotes {
                code
                id
            }
            subscriber_badges {
                title
                image_url_4x
            }
            bits_badges {
                title
                image_url_4x
            }
        }
    }
`;

export const getMyCardsWithStatistics = gql`
    query getMyCardsWithStatistics($filter: Filter) {
        getMyCardsWithStatistics(filter: $filter) {
            cards {
                card {
                    _id
                    level
                    image
                    matchModel
                    created_at
                }
                amount_collected
                unique_collectors
            }
        }
    }
`;

export const getStreamerTopViewersByPoints = gql`
    query getStreamerTopViewersByPoints {
        getStreamerTopViewersByPoints {
            viewer {
                _id
                display_name
                profile_image_url
                login
                country
            }
            points_season
        }
    }
`;

export const getStreamerTopViewersByCards = gql`
    query getStreamerTopViewersByCards {
        getStreamerTopViewersByCards {
            viewer {
                _id
                display_name
                profile_image_url
                login
                country
            }
            seq
        }
    }
`;

export const getStreamerTopViewedOtherStreamers = gql`
    query getStreamerTopViewedOtherStreamers {
        getStreamerTopViewedOtherStreamers {
            match {
                ... on Streamer {
                    _id
                    display_name
                    profile_image_url
                    login
                }
            }
            points_season
        }
    }
`;

export const getStreamerTopViewedGames = gql`
    query getStreamerTopViewedGames {
        getStreamerTopViewedGames {
            match {
                ... on Game {
                    _id
                    name
                    box_art_url
                }
            }
            points_season
        }
    }
`;

export const getStreamerStreamInformation = gql`
    query getStreamerStreamInformation {
        getStreamerStreamInformation {
            date
            avgPercent
            penetration
        }
    }
`;

export const getLastActivities = gql`
    query getLastActivities {
        getLastActivities {
            _id
            type
            random_t
            created_at
            streamer {
                _id
                login
                display_name
            }
            viewer {
                _id
                login
                display_name
            }
            card {
                _id
                level
            }
            game {
                _id
                name
            }
        }
    }
`;

export const getFeaturedStream = gql`
    query getFeaturedStream {
        getFeaturedStream {
            _id
            feature_start
            feature_end
            language
            stream {
                _id
                streamer {
                    _id
                    login
                    display_name
                }
            }
        }
    }
`;

export const getOrganisation = gql`
    query organisation($code: String!) {
        organisation(code: $code) {
            _id
            name
            code
            logo_url
            website_url
            main_channel
            twitter
            youtube
            instagram
            facebook
            status
            follower_count
        }
    }
`;

export const organisationLeaderBoard = gql`
    query getOrganisationLeaderboard($organisation: ID!, $pagination: Pagination, $filter: Filter) {
        getOrganisationLeaderboard(organisation: $organisation, pagination: $pagination, filter: $filter) {
            entries {
                seq
                pos
                updated_at
                viewer {
                    _id
                    login
                    profile_image_url
                    display_name
                    country
                }
            }
            count
        }
    }
`;

export const getRaffleWithMyTickets = gql`
    query getRaffleWithMyTickets($raffle_name: String) {
        getRaffleWithMyTickets(raffle_name: $raffle_name) {
            tickets_redeemed_cards
            tickets_crafted_cards
            tickets_cards
            tickets_social
            raffle {
                name
                start
                end
                matches {
                    match
                    matchModel
                    isLive
                    viewer_count
                    title
                    thumbnail_url
                    game {
                        name
                    }
                    name
                    login
                    avatar
                }
            }
        }
    }
`;

export const getDetailedStreamerData = gql`
    query getDetailedStreamerData($sorting: Sorting, $pagination: Pagination, $filter: Filter) {  
        getDetailedStreamerData(sorting: $sorting, pagination: $pagination, filter: $filter) {
            streamerData {
                streamer {
                    _id
                    display_name
                    twitch_id
                }
                viewer {
                    created_at
                }
                token {
                    usage
                    history
                }
                streamData {
                    time_in_m
                    avg_viewers
                    avg_sh_viewers
                    language
                }
                cards {
                    count
                    created
                    count_lvl1
                }
                coins {
                    count
                }
            }
            length
        }
    }
`;

export const getAffiliateData = gql`
    query getAffiliateData($viewer: ID, $startDate: String, $endDate: String) {
        getAffiliateData(viewer: $viewer, startDate: $startDate, endDate: $endDate) {
            _id
            values {
                date
                new
                extended
            }
        }
    }
`;

export const getCards = gql`
    query cards($pagination: Pagination, $sorting: Sorting, $streamer: ID, $organisation: ID, $game: ID) {
        cards(pagination: $pagination, sorting: $sorting, streamer: $streamer, organisation: $organisation, game: $game) {
            _id
            level
            image
            matchModel
            match {
                ... on Streamer {
                    _id
                    display_name
                    login
                }
                ... on Game {
                    _id
                    name
                }
                ... on Organisation {
                    _id
                    name
                    code
                }
            }
        }
    }
`;

export const getCollectedCoinsAmount = gql`
    query getCollectedCoinsAmount($days: Int) {
        getCollectedCoinsAmount(days: $days)
    }
`;

export const getDashboardCards = gql`
    query getDashboardCards($streamerOrgaCardsMixed: Boolean) {
        getDashboardCards(streamerOrgaCardsMixed: $streamerOrgaCardsMixed) {
            top {
                _id
                matchCardModel
                match_card {
                    ... on Card {
                        _id
                        level
                        image
                        matchModel
                        created_at
                        match {
                            ... on Streamer {
                                _id
                                display_name
                                login
                            }
                            ... on Game {
                                _id
                                name
                            }
                            ... on Organisation {
                                _id
                                name
                                code
                            }
                        }
                    }
                    ... on SpecialCard {
                        _id
                        image
                        created_at
                        matchModel
                        match {
                            ... on Streamer {
                                _id
                                display_name
                                login
                            }
                            ... on Game {
                                _id
                                name
                            }
                            ... on Organisation {
                                _id
                                name
                                code
                            }
                        }
                    }
                }
            }
            mixed {
                _id
                matchCardModel
                match_card {
                    ... on Card {
                        _id
                        level
                        image
                        matchModel
                        created_at
                        match {
                            ... on Streamer {
                                _id
                                display_name
                                login
                            }
                            ... on Game {
                                _id
                                name
                            }
                            ... on Organisation {
                                _id
                                name
                                code
                            }
                        }
                    }
                    ... on SpecialCard {
                        _id
                        image
                        created_at
                        matchModel
                        match {
                            ... on Streamer {
                                _id
                                display_name
                                login
                            }
                            ... on Game {
                                _id
                                name
                            }
                            ... on Organisation {
                                _id
                                name
                                code
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const getTabsBadges = gql`
    query getTabsBadges {
        getTabsBadges {
            questBadge
            taskBadge
            pollBadge
        }
    }
`;

export const lastCrawledStreamByViewer = gql`
    query lastCrawledStreamByViewer {
        lastCrawledStreamByViewer {
            streamer {
                display_name
                login
            }
        }
    }
`;

export const getCurrentOffer = gql`
    query getCurrentOffer($match: ID, $matchModel: String) {
        getCurrentOffer(match: $match, matchModel: $matchModel) {
            _id
            order_button_text
            invoice_identifier
            limit_overall
            started_at
            finished_at
            products {
                costs
            }
            required_booster {
                _id
                generic_cards {
                    _id
                    card_layer_image
                }
            }
        }
    }
`;

export const isOrderPossible = gql`
  query isOrderPossible($offer: ID!) {
      isOrderPossible(offer: $offer) {
          possible
          message
          offer_amount_available
      }
  }
`;

export const getOffer = gql`
  query getOffer($id: ID!) {
      getOffer(id: $id) {
          _id
          title
          invoice_identifier
          promo_title
          promo_sub_title
          promo_image
          info
          limit_per_user
          required_booster {
              _id
              generic_cards {
                  _id
                  card_layer_image
              }
          }
          products {
              name
              image
              desc_first_line
              desc_second_line
              costs
          }
          country_costs {
              name
              code
              shipping
              vat_percent
          }
          match {
              ... on Streamer {
                  _id
                  login
                  display_name
                  profile_image_url
              }
          }
      }
  }
`;

export const getMyRedeemedCardTypeCounts = gql`
  query getMyRedeemedCardTypeCounts {
      getMyRedeemedCardTypeCounts {
          streamer
          category
          organisation
      }
  }
`;

export const getAllOrders = gql`
  query getAllOrders {
      getAllOrders {
          orders {
              _id
              order_id
              order_date
              order_status_id
              payment_method
              ship_cost
              offer_id {
                  _id
                  title
              }
              order_products {
                  discount_percent
                  quantity
                  unit_price
                  name
                  tax_rate
              }
          }
          genericOrders {
              _id
              ship_cost
              total_cost
              created_at
              email
              tree_plant_url
              payment_provider
              offer_id {
                  _id
                  title
              }
              status
              state_history {
                  name
                  created_at
              }
              fulfillment_id
              packins {
                  product_id {
                      _id
                      t_string
                      image
                  }
                  unit_price
                  quantity
              }
              card_products {
                  product_id
                  discount_percent
                  quantity
                  unit_price
                  name
                  tax_rate
                  processing {
                      design {
                          url
                      }
                  }
              }
              offer_products {
                  discount_percent
                  quantity
                  unit_price
                  name
                  tax_rate
                  image
              }
              premium_offer
              premium_amount
              invoice
          }
          cardOrders {
              _id
              payment_reference
              payment_provider
              tax_rate
              queue
              status
              ship_cost
              receipt_note
              created_at
              fulfillment_id
              state_history {
                  name
                  created_at
              }
              packins {
                  product_id {
                      _id
                      t_string
                      image
                  }
                  unit_price
                  quantity
              }
              products {
                  product_id
                  discount_percent
                  quantity
                  unit_price
                  name
                  tax_rate
                  processing {
                      design {
                          url
                      }
                  }
              }
          }
      }
  }
`;

export const getHeroOrZeroData = gql`
  query getHeroOrZeroData($status: String!) {
      getHeroOrZeroData(status: $status) {
          match_card
          image
      }
  }
`;

export const getFavoriteCardsWithProgress = gql`
  query getFavoriteCardsWithProgress {
      getFavoriteCardsWithProgress {
          owned
          progress
      }
  }
`;

export const getDailyLoginStreak = gql`
    query getDailyLoginStreak {
        getDailyLoginStreak {
            first_login_today
            login_streak
        }
    }
`;

export const getPossibleUserBadges = gql`
    query getPossibleUserBadges($isStreamer: Boolean) {
        getPossibleUserBadges(isStreamer: $isStreamer) {
            category
            progress {
                current
                maximum
            }
            badges {
                unique_id
                title
                pic_url
                description
                is_set
            }
        }
    }
`;

export const handleMobileAppRedirect = gql`
    query handleMobileAppRedirect($code: Int!) {
        handleMobileAppRedirect(code: $code)
    }
`;

export const getCardsByGroupId = gql`
    query getCardsByGroupId($groupId: ID!) {
        getCardsByGroupId(groupId: $groupId) {
            image
            level
            probability
            sh_coins_cost
            additional_costs {
                matchModel
                match {
                    ... on Game {
                        name
                        box_art_url
                    }
                }
                amount_needed
                amount_owned
            }
        }
    }
`;

export const getAllCrawledStreamersOfAViewer = gql`
    query getAllCrawledStreamersOfAViewer {
        getAllCrawledStreamersOfAViewer {
            energy_for {
                _id
                login
                display_name
                profile_image_url
            }
            possible_streamers {
                _id
                login
                display_name
                profile_image_url
            }
        }
    }
`;

export const getTopStreamers = gql`
    query getTopStreamers($pagination: Pagination) {
        getTopStreamers(pagination: $pagination) {
            streamer {
                _id
                login
                display_name
                profile_image_url
                created_at
                languages_streamed {
                    identifier
                    streamed_mins
                }
                games_streamed {
                    game {
                        twitch_id
                        name
                        box_art_url
                    }
                    streamed_mins
                }
            }
            collected_cards_count
            cards {
                ... on Card {
                    _id
                    level
                    image
                }
            }
        }
    }
`;

export const getPossibleIllustrationJobs = gql`
    query getPossibleIllustrationJobs {
        getPossibleIllustrationJobs {
            _id
            request_description
            request_primary_color_info
            request_secondary_color_info
            file_uploads_user
            illustration_style
            status
            is_vip
            language
            card_request_info {
                level
                request_description
            }
            participants {
                _id
                login
                display_name
                avatar
                role
            }
            created_at
        }
    }
`;

export const getMyIllustrationJobs = gql`
    query getMyIllustrationJobs {
        getMyIllustrationJobs {
            _id
            request_description
            request_primary_color_info
            request_secondary_color_info
            file_uploads_user
            illustration_style
            status
            created_at
            updated_at
            card_request_info {
                level
                request_description
            }
            file_uploads_illustrator
            participants {
                _id
                login
                display_name
                avatar
                role
            }
        }
    }
`;

export const getIllustrationData = gql`
    query getIllustrationData($id: ID) {
        getIllustrationData(id: $id) {
            _id
            request_description
            request_primary_color_info
            request_secondary_color_info
            card_request_info {
                level
                request_description
            }
            finished_at
            file_uploads_user
            file_uploads_illustrator
            created_at
            updated_at
            messages {
                _id
                type
                content
                author 
                created_at
                images
            }
            participants {
                _id
                role
                login
                display_name
                avatar
            }
            state_history {
                name
                created_at
            }
        }
    }
`;

export const getAllIllustrationChatImages = gql`
    query getAllIllustrationChatImages($id: ID!) {
        getAllIllustrationChatImages(id: $id)
    }
`;

export const hasUserActiveIllustrationJob = gql`
    query hasUserActiveIllustrationJob {
        hasUserActiveIllustrationJob
    }
`;

export const getMyIllustrations = gql`
    query getMyIllustrations($status: String) {
        getMyIllustrations(status: $status) {
            _id
            file_uploads_illustrator
            request_description
            request_primary_color_info
            request_secondary_color_info
            illustration_style
            created_at
            card_request_info {
                level
                request_description
            }
            finished_at
            participants {
                _id
                role
                login
                display_name
                avatar
            }
            updated_at
        }
    }
`;

export const getIllustrationStats = gql`
    query getIllustrationStats {
        getIllustrationStats {
            completed_jobs
            open_jobs
            avg_delivery_time
            daily_jobs {
                date
                count
            }
        }
    }
`;

export const canCreateIllustrationJob = gql`
    query canCreateIllustrationJob {
        canCreateIllustrationJob {
            card_amount_collected
            twitch_partner
            sh_partner
            viewer_average
        }
    }
`;

export const getAllUsersByRole = gql`
    query getAllUsersByRole($role: String!) {
        getAllUsersByRole(role: $role) {
            _id
            login
            display_name
            streamheroes_role
        }
    }
`;

export const getLayerObjectsByType = gql`
    query getLayerObjectsByType($type: String!) {
        getLayerObjectsByType(type: $type) {
            _id
            type
            image
        }
    }
`;

export const getTodayCollectedStreamer = gql`
    query getTodayCollectedStreamer {
        getTodayCollectedStreamer {
            _id
            seq
            energy
            streamer {
                _id
                 login
                 display_name
                 profile_image_url
            }
            updated_at
        }
    }
`;

export const getRedeemedCardById = gql`
    query getRedeemedCardById($id: ID!) {
        getRedeemedCardById(id: $id) {
            _id
            crafted_as_number
            was_ordered
            created_at
            events {
                type
                key
                params
            }
            viewer {
                _id
                login
                display_name
            }
            matchCardModel
            match_card {
                ... on Card {
                    _id
                    level
                    image
                    matchModel
                    created_at
                    match {
                        ... on Streamer {
                            _id
                            display_name
                            login
                            profile_image_url
                        }
                        ... on Game {
                            _id
                            name
                            box_art_url
                        }
                        ... on Organisation {
                            _id
                            name
                            code
                            logo_url
                        }
                    }
                }
                ... on SpecialCard {
                    _id
                    card_name
                    image
                    matchModel
                    created_at
                    match {
                        ... on Streamer {
                            _id
                            display_name
                            login
                        }
                        ... on Game {
                            _id
                            name
                        }
                        ... on Organisation {
                            _id
                            name
                            code
                        }
                    }
                }
            }
        }
    }
`;

export const getMyPrintableCards = gql`
    query getMyPrintableCards ($streamer: ID, $organisation: ID, $sorting: Sorting, $pagination: Pagination, $filter: Filter, $card_type: String) {
        getMyPrintableCards(streamer: $streamer, organisation: $organisation, sorting: $sorting, pagination: $pagination, filter: $filter, card_type: $card_type) {
            cards_count
            streamer_organisation {
                 main_channel
                 logo_url
            }
            streamers {
                _id
                display_name
            }
            organisations {
                _id
                name
            }
            levels
            cards {
                _id
                count
                matchCardModel
                match_card {
                    ... on Card {
                        _id
                        level
                        image
                        is_printable
                        matchModel
                        created_at
                        layers {
                            type
                        }
                        match {
                            ... on Streamer {
                                _id
                                display_name
                                login
                                profile_image_url
                            }
                            ... on Game {
                                _id
                                name
                                box_art_url
                            }
                            ... on Organisation {
                                _id
                                name
                                code
                                logo_url
                            }
                        }
                    }
                    ... on SpecialCard {
                        _id
                        card_name
                        image
                        costs
                        limit
                        max_count_per_viewer
                        created_at
                        collectible_from
                        collectible_until
                        matchModel
                        match {
                            ... on Streamer {
                                _id
                                display_name
                                login
                                profile_image_url
                            }
                            ... on Game {
                                _id
                                name
                                box_art_url
                            }
                            ... on Organisation {
                                _id
                                name
                                code
                                logo_url
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const getMyOrderableCards = gql`
    query ($streamer: ID, $organisation: ID, $sorting: Sorting, $pagination: Pagination, $filter: Filter, $card_type: String) {
        getMyOrderableCards(streamer: $streamer, organisation: $organisation, sorting: $sorting, pagination: $pagination, filter: $filter, card_type: $card_type) {
            count
            cards {
                _id
                count
                card {
                    _id
                    image
                    level
                    shop_price
                    discount_percent
                    discounted_price
                    layers {
                        type
                    }
                }
                specialcard {
                    _id
                    image
                    card_name
                    costs
                    shop_price
                    discount_percent
                    discounted_price
                    collectible_from
                }
            }
            pack_ins {
                _id
                name
                t_string
                image
                info
                description
                unit_price
                discount_percent
                discounted_price
            }
        }
    }
`;

export const getOrderInvoice = gql`
    query getOrderInvoice ($id: ID!) {
        getOrderInvoice(id: $id)
    }
`;

export const getLicenses = gql`
    query getLicenses {
        getLicenses {
            _id
            name
            logo
            address
            shares
            login
            created_at
            updated_at
        }
    }
`;

export const getMyStreamAlertCredits = gql`
  query getMyStreamAlertCredits {
      getMyStreamAlertCredits {
          _id
          streamer {
              _id
              login
              display_name
              stream_config {
                  min_level_alerts
                  shotOutsActive
              }
          }
          cards {
              _id
              level
              image
          }
          special_cards {
              _id
              card_name
              image
          }
          stream {
              viewer_count
          }
      }
  }
`;

export const getCardsForShowcase = gql`
    query getCardsForShowcase ($type: String!, $pagination: Pagination, $sorting: Sorting) {
        getCardsForShowcase(type: $type, pagination: $pagination, sorting: $sorting) {
            count
            cards {
                ... on Card {
                    _id
                    image
                    created_at
                }
                ... on SpecialCard {
                    _id
                    image
                    created_at
                }
            }
        }
    }
`;

export const getSidekickedViewers = gql`
    query getSidekickedViewers {
        getSidekickedViewers {
            viewer {
                display_name
            }
            created_at
        }
    }
`;

export const getMyHeroMakerImages = gql`
  query getMyHeroMakerImages {
      getMyHeroMakerImages {
          _id
          image
      }
  }
`;

export const getMyHeroMakerImagesCount = gql`
  query getMyHeroMakerImagesCount {
      getMyHeroMakerImagesCount
  }
`;

export const getMyHeroMakerImage = gql`
  query getMyHeroMakerImage ($id: ID!) {
      getMyHeroMakerImage (id: $id) {
          _id
          character
          pose
          image
          assets {
              type
              category
              colors
              ordering
          }
      }
  }
`;

export const getCardEvents = gql`
  query getCardEvents {
      getCardEvents {
          _id
          name
          cards {
              _id
          }
          started_at
          finished_at
      }
  }
`;

export const getCardEventsForReview = gql`
  query getCardEventsForReview {
      getCardEventsForReview {
          _id
          name
          cards {
              _id
              image
              card_name
          }
          viewer {
              _id
              display_name
              login
              profile_image_url
          }
          started_at
          finished_at
      }
  }
`;

export const getCardEvent = gql`
  query getCardEvent ($id: ID!) {
      getCardEvent (id: $id) {
          _id
          name
          cards {
              _id
              image
              costs
              isDraft
              card_name
          }
          started_at
          finished_at
          status
      }
  }
`;

export const areCardsOwnedByViewerByIds = gql`
  query areCardsOwnedByViewerByIds ($ids: [ID]!) {
      areCardsOwnedByViewerByIds (ids: $ids)
  }
`;

export const getAllCollectionsProgress = gql`
  query getAllCollectionsProgress ($type: String!) {
      getAllCollectionsProgress(type: $type) {
          name
          progress
      }
  }
`;

export const getRedeemedCardObjectInformation = gql`
  query getRedeemedCardObjectInformation ($id: ID!) {
      getMyViewer {
          _id
          login
          subscription {
              valid_until
              tier
              subscription_id
              status
          }
      }
      getRedeemedCardObjectInformation(id: $id) {
          _id
          viewer {
              _id
              display_name
              login
          }
          season_id {
              name
              logo
              season
          }
          front_layers {
              img_url
              physical_img_url
              type
          }
          back_layers {
              img_url
              physical_img_url
              type
          }
          redeemed_booster {
              generic_booster_metadata {
                  edition
                  front_image
                  back_image
                  is_custom_booster
                  created_at
              }
          }
          rarity
          condition
          quality
          front_image_url
          back_image_url
          created_at
          price
          opened_as_number
          card_template
          card_template_metadata {
              match {
                  ... on Streamer {
                      _id
                      login
                      display_name
                      profile_image_url
                  }
                  ... on Game {
                      _id
                      name
                      box_art_url
                  }
                  ... on Organisation {
                      _id
                      name
                      logo_url
                  }
                  ... on GenericMatch {
                      _id
                      name
                      image_url
                  }
              }
          }
          overall_collected
          unique_collectors
          is_public
      }
  }
`;

export const giveFreeEnergy = gql`
  query giveFreeEnergy {
      giveFreeEnergy {
          _id
          display_name
          profile_image_url
      }
  }
`;

export const getBoosterContent = gql`
  query getBoosterContent ($id: ID!) {
      getBoosterContent(id: $id) {
          _id
          front_image
          back_image
          match_model
          redeemable_from
          redeemable_until
          unique_user_redeems
          overall_redeems
          edition
          sorting
          energy_costs
          coin_costs
          created_at
          is_starter_pack
          starter_pack_logo_url
          is_custom_booster
          generic_cards {
              _id
              card_layer_image
              physical_card_layer_image
              rarity
              count_owned
          }
      }
  }
`;

export const getCountdownMessage = gql`
  query getCountdownMessage {
      getCountdownMessage {
          msg
          timestamp
      }
  }
`;

export const getAllBooster = gql`
  query getAllBooster {
      getAllBooster {
          approvedBooster {
              _id
              front_image
              edition
              energy_costs
              redeemable_from
              redeemable_until
              generic_cards {
                  _id
                  card_layer_image
                  rarity
              }
          }
          unapprovedBooster {
              _id
              front_image
              edition
              energy_costs
              redeemable_from
              redeemable_until
              ready_to_approve
              generic_cards {
                  _id
                  card_layer_image
                  rarity
              }
          }
      }
  }
`;

export const getUnapprovedBoosterTemplate = gql`
  query getUnapprovedBoosterTemplate ($id: ID!) {
      getUnapprovedBoosterTemplate(id: $id) {
          ready_to_approve
          generic_cards {
              _id
              card_layer_image
              physical_card_layer_image
              rarity
          }
      }
  }
`;

export const getCardTemplateById = gql`
  query getCardTemplateById ($id: ID!) {
      getCardTemplateById(id: $id) {
          _id
          card_layer_image
          physical_card_layer_image
      }
  }
`;

export const getBadgeLevels = gql`
  query getBadgeLevels ($viewer: ID) {
      getBadgeLevels(viewer: $viewer) {
            overall_lvl_value
            overall_lvl_badge_lvl
            next_level_required_points
            current_level_required_points
        }
  }
`;

export const getMySeason = gql`
  query getMySeason ($viewer: ID) {
      getMySeason(viewer: $viewer) {
          streamer {
              _id
              login
              display_name
              profile_image_url
          }
          league
          position
          points
      }
  }
`;

export const getSeasonStreamerLeaderboard = gql`
  query getSeasonStreamerLeaderboard ($pagination: Pagination) {
      getSeasonStreamerLeaderboard(pagination: $pagination) {
          streamer {
              _id
              login
              display_name
              profile_image_url
          }
          redeemed_booster
          position
      }
  }
`;

export const getPublicCards = gql`
  query getPublicCards ($id: ID) {
      getPublicCards(id: $id) {
          _id
          front_layers {
              type
              img_url
              physical_img_url
          }
          back_layers {
              type
              img_url
              physical_img_url
          }
          rarity
          condition
          quality
          front_image_url
          is_public
          card_template
          card_template_metadata {
              match {
                  ... on Streamer {
                      _id
                      login
                      display_name
                      profile_image_url
                  }
                  ... on Game {
                      _id
                      name
                      box_art_url
                  }
                  ... on Organisation {
                      _id
                      name
                      logo_url
                  }
                  ... on GenericMatch {
                      _id
                      name
                      image_url
                  }
              }
          }
      }
  }
`;

export const getBoostersToApprove = gql`
    query getBoostersToApprove {
        getBoostersToApprove {
            _id
            front_image
            redeemable_from
            redeemable_until
            edition
            energy_costs
            generic_cards {
                _id
                card_layer_image
                physical_card_layer_image
                rarity
            }
            match {
                ... on Streamer {
                      _id
                      login
                      display_name
                      profile_image_url
                  }
                  ... on Game {
                      _id
                      name
                      box_art_url
                  }
                  ... on Organisation {
                      _id
                      name
                      logo_url
                  }
                  ... on GenericMatch {
                      _id
                      name
                      image_url
                  }
            }
        }
    }
`;

export const alertSettings = gql`
  query alertSettings {
      alertSettings {
          _id
          type
          active
          min_card_quality
          max_card_quality
          card_rarity
          purchase_min_amount_cards
          community_rank_up
          show_on_twitch
          show_on_discord
          problems_with_sending
          discord_settings {
              channel
              text
          }
          twitch_settings {
              text
              alert_sound {
                  sound
                  volume
              }
              permissions
              text_to_speech {
                  voice
                  font_size
              }
          }
      }
  }
`;

export const getDiscordChannels = gql`
  query getDiscordChannels {
      getDiscordChannels {
          id
          guild_id
          name
          type
      }
  }
`;

export const alertCapsules = gql`
    query alertCapsules {
        alertCapsules {
            _id
            type
            card {
                _id {
                    _id
                    front_layers {
                        img_url
                    }
                }
                rarity
                quality
            }
            order {
                _id {
                    _id
                    total_cost
                    card_products {
                        product_id
                        processing {
                            design {
                                url
                            }
                        }
                    }
                }
                amount_in_cent
                cards_of_streamer
            }
            streamer {
                _id
                display_name 
                profile_image_url
            },
            community {
                    league
                    league_string
            },
            used
            created_at
            alert_setting {
                show_on_twitch
                show_on_discord
            }
        }
    }
`;

export const getSupporterMetadata = gql`
  query getSupporterMetadata {
      getSupporterMetadata {
          supporters
          highlighted {
              login
              profile_image_url
          }
      }
  }
`;

export const packIns = gql`
  query packIns {
      packIns {
          _id
          name
          unit_price
          discount_percent
          discounted_price
          description
          info
          image
          t_string
          quantity
      }
  }
`;

export const getPublicDecks = gql`
  query getPublicDecks($id: ID!) {
      getPublicDecks(id: $id) {
          _id
          name
          max_cards
          sort_order
          image
          card_count
          updated_at
          rarities
          active
      }
  }
`;

export const getPremiumOffers = gql`
  query getPremiumOffers {
      getPremiumOffers {
          _id
          offer_identifier
          amount
          costs
          saving
      }
  }
`;

export const cardCollectionProgressByBooster = gql`
  query cardCollectionProgressByBooster($id: ID!, $streamerId: ID) {
      cardCollectionProgressByBooster(id: $id) {
          card {
              _id
              card_layer_image
              rarity
          }
          max
          collected
          progress
          reward {
              type
              amount
              collected
          }
      }
      getBooster(id: $id) {
          _id
          edition
      }
      streamer(id: $streamerId) {
          _id
          login
          display_name
          profile_image_url
      }
      getMyViewer {
          _id
          premium_currency
          subscription {
              valid_until
              tier
              subscription_id
              status
          }
      }
  }
`;

export const cardCollectionProgressByCard = gql`
  query cardCollectionProgressByCard($id: ID!, $boosterId: ID!) {
      cardCollectionProgressByCard(id: $id, boosterId: $boosterId) {
          card {
              _id
              card_layer_image
              physical_card_layer_image
              rarity
              purchaseable
              match_meta_data {
                  ... on Streamer {
                      _id
                      login
                      display_name
                      profile_image_url
                  }
                  ... on GenericMatch {
                      _id
                  }
              }
          }
          progress {
              sellable
              owned_count
              quality
              bought
              quality_layer
              marketplace_listings
              owned_seasons {
                  name
                  logo
                  season
              }
          }
      }
      getBooster(id: $boosterId) {
          _id
          edition
      }
      getMyViewer {
          _id
          premium_currency
          subscription {
              valid_until
              tier
              subscription_id
              status
          }
      }
      getBoosterDonations(boosterId: $boosterId) {
          all_donations {
              display_name
              gems
              profile_image_url
              created_at
              anonymous
          }
          first_donation {
              display_name
              gems
              profile_image_url  
              created_at
              anonymous
          }
          top_donation {
              display_name
              gems
              profile_image_url
              created_at
              anonymous
          }
      }
  }
`;

export const getMySeasonBorders = gql`
  query getMySeasonBorders {
      getMySeasonBorders {
          _id
          season {
              season
          }
          border {
              league
              img_url
          }
          match_meta_data {
              display_name
              profile_image_url
          }
      }
  }
`;

export const marketPlaceMyHistory = gql`
  query marketPlaceMyHistory($type: String!, $match: ID, $min_quality: Int, $max_quality: Int, $rarity: [Int], $sorting: Sorting, $pagination: Pagination, $is_seasonal: Boolean) {
      marketPlaceMyHistory(type: $type, match: $match, min_quality: $min_quality, max_quality: $max_quality, rarity: $rarity, sorting: $sorting, pagination: $pagination, is_seasonal: $is_seasonal) {
          count
          offers {
              _id
              price
              created_at
              active_until
              avg_price
              sold_at
              offer_match_model_meta_data {
                  ... on RedeemedCardObjectInformationObject {
                      _id
                      front_image_url
                      front_hash
                      front_layers {
                          img_url
                          type
                      }
                      card_template_metadata {
                          match_meta_data {
                              ... on Streamer {
                                  login
                                  display_name
                                  profile_image_url
                              }
                          }
                      }
                      quality
                      rarity
                      season_id {
                          logo
                      }
                  }
              }
              viewer {
                  _id
                  login
                  display_name
                  profile_image_url
              }
              buyer {
                  _id
                  login
                  display_name
                  profile_image_url
              }
          }
      }
  }
`;

export const redeemedCardObjectsByCardTemplateWithQuality = gql`
    query redeemedCardObjectsByCardTemplateWithQuality($card_template_id: ID!, $quality: Int!, $pagination: Pagination) {
        redeemedCardObjectsByCardTemplateWithQuality(card_template_id: $card_template_id, quality: $quality, pagination: $pagination) {
            count
            cards {
                _id
                rarity
                condition
                quality
                front_image_url
                back_image_url
                card_template
                front_layers {
                    img_url
                    physical_img_url
                    type
                }
                back_layers {
                    img_url
                    physical_img_url
                    type
                }
                redeemed_booster {
                    generic_booster_metadata {
                        edition
                    }
                }
                overall_collected
                unique_collectors
                created_at
                card_template_metadata {
                    match {
                        ... on Streamer {
                            _id
                            login
                            display_name
                            profile_image_url
                        }
                        ... on Game {
                            _id
                            name
                            box_art_url
                        }
                        ... on Organisation {
                            _id
                            name
                            logo_url
                        }
                        ... on GenericMatch {
                            _id
                            name
                            image_url
                        }
                    }
                }
            }
        }
        getMyViewer {
            _id
            subscription {
                valid_until
                tier
                subscription_id
                status
            }
      }
    }
`;



export const getShippingInformation = gql`
  query getShippingInformation {
      getShippingInformation {
          country
          code
          shipping
          vat_percent
      }
  }
`;

