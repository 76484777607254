import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

const Symplr = (props) => {
  const { id } = props;
  const { pathname } = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!isMobile) {
      if (initialized) {
        if (typeof window.buildSlotsSymplr === 'function') {
          try {
            console.log('call buildSlotsSymplr');
            window.buildSlotsSymplr();
          } catch (e) {
            console.error(e);
          }
        }
      } else
        setInitialized(true);
    }
  }, [pathname]);

  if (isMobile)
    return null;

  return (
    <div id={id}></div>
  );
};

export default Symplr;

Symplr.propTypes = {
  id: PropTypes.string.isRequired,
};