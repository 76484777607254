import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import { getViewerAndStreamer } from '../../apollo/mergedQueries';

import NotFoundPage from '../../components/NotFoundPage/NotFoundPage';
import Loading from '../../components/Loading/Loading';
import PageTitle from '../../components/PageTitle/PageTitle';
import ErrorMessage from '../../components/ErrorBoundary/ErrorMessage';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import Profile from '../../components/Profile/Profile';
import ProfileAdminContainer from './ProfileAdminMenu';

const ProfileContainer = () => {
  const { name } = useParams();

  const variables = { login: name.toLowerCase() };
  const { data, loading, error } = useQuery(getViewerAndStreamer, {
    variables,
    fetchPolicy: useCacheWithExpiration('getViewerAndStreamer', variables),
  });

  if (loading) return <Loading />;
  if (error) return <ErrorMessage error={error} />;

  const profile = data;

  if (!profile.viewer && profile.streamer)
    profile.viewer = profile.streamer;

  if (!profile.viewer)
    return <NotFoundPage />;

  return (
    <>
      <PageTitle title={profile.viewer.display_name} />
      <Profile profile={profile} />
      {profile.streamer &&
      <ProfileAdminContainer streamer={profile.streamer} />
      }
    </>
  );
};

export default ProfileContainer;