import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';

const useStyles = makeStyles(() => ({
  lineArea: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 4,
    height: 56,
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    marginTop: 6,
  },
  logoArea: {
    width: 100,
    height: '100%',
    borderRadius: '4px 0px 0px 4px',
    ...Helpers.fillRowCenter,
  },
  stylingArea: {
    background: 'rgba(255, 255, 255, 0.06)',
    padding: '4px 6px',
    cursor: 'pointer',
  },
  groupStyling: {
    ...Helpers.fillRowCenter,
    paddingRight: 12,
    position: 'relative',
    '&:hover': {
      '& $stylingArea': {
        background: 'rgba(255, 255, 255, 0.1)',
      },
    },
  },
  menuArea: {
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 4,
    padding: 6,
    position: 'absolute',
    right: 12,
    top: 31,
    width: 121,
    zIndex: 2,
  },
  menuItem: {
    padding: '7px 6px',
    cursor: 'pointer',
    width: 'calc(100% - 12px)',
    transition: 'all 0.3s ease-out',
    borderRadius: 2,
    '&:hover': {
      background: 'rgba(225, 33, 56, 0.16)',
      color: '#E12138',
    },
  },
}));

const AlertChannelLine = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isTwitch, setAlertToolTypeData } = props;
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className={classes.lineArea}>
      <div style={{ ...Helpers.fillRowCenter, height: '100%' }}>
        <div
          className={classes.logoArea}
          style={isTwitch ? { background: Colors.twitch } : { background: Colors.discord }}
        >
          <i className={`fa-brands fa-${isTwitch ? 'twitch' : 'discord'}`}
             style={{ fontSize: 34 }} />
        </div>
        <div style={{ padding: 12, fontSize: 14 }}>
          {t(isTwitch ? 'twitch' : 'discord')}
        </div>
      </div>

      <div className={classes.groupStyling} onClick={() => setShowMenu(!showMenu)}>
        <div
          className={classes.stylingArea}
          style={{ marginRight: 2, borderRadius: '2px 0px 0px 2px' }}
        >
          {t('style_settings')}
        </div>
        <div className={classes.stylingArea} style={{ borderRadius: '0px 2px 2px 0px' }}>
          <i className="fa-solid fa-caret-down" style={{ fontSize: 14 }} />
        </div>

        {showMenu &&
        <CustomBackdropFilter
          className={classes.menuArea}
          filter={'blur(30px)'}
          canvasFallback={true}
          html2canvasOpts={{
            allowTaint: true,
          }}
        >
          <div
            className={classes.menuItem}
            onClick={() => setAlertToolTypeData('showStyleSettings', isTwitch ? 'twitch' : 'discord')}
          >
            <i className="fa-light fa-pencil"
               style={{ fontSize: 13, marginRight: 11, marginBottom: 2 }} />
            {t('edit')}
          </div>
          <div
            className={classes.menuItem}
            onClick={() => setAlertToolTypeData(isTwitch ? 'showTwitch' : 'showDiscord', false)}
          >
            <i className="fa-light fa-link-slash" style={{ fontSize: 13, marginRight: 10 }} />
            {t('remove')}
          </div>
        </CustomBackdropFilter>
        }
      </div>
    </div>
  );
};

export default AlertChannelLine;