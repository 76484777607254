import { createMuiTheme } from '@material-ui/core/styles';

import Colors from '../theme/Colors';

const THEME = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    'fontFamily': '"Montserrat", "Roboto", "Avenir Next Cyr", sans-serif',
    'fontSize': 14,
    'fontWeightLight': 300,
    'fontWeightRegular': 400,
    'fontWeightMedium': 500,
    h1: {
      fontSize: 28,
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: 24,
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: 15,
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: 16,
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: 14,
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    h6: {
      fontSize: 12,
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    subtitle1: {},
    subtitle2: {
      fontSize: '0.75rem',
      fontWeight: 600,
    },
    body1: {},
    body2: {
      fontSize: '0.875rem',
      fontWeight: 600,
    },
    caption: {
      fontSize: '0.6rem',
      textTransform: 'uppercase',
      color: 'rgb(87,87,87)',
    }
  },
  palette: {
    background: {
      default: Colors.background,
      shSecond: 'red',
    },
    text: {
      primary: Colors.white,
      secondary: Colors.white,
      third: Colors.primary,
      fourth: Colors.secondary,
      fifth: Colors.third,
      gray: Colors.gray,
    },
  },
  overrides: {
    MuiCard: {
      root: {
        margin: '0px 25px 25px 25px',
        border: '10px solid rgb(15,15,15)',
        borderRadius: 10,
        width: 200,
        boxShadow: '0 0 15px 2px rgb(25,25,25)',
        display: 'inline-block',
      },
    },
    MuiCardMedia: {
      root: {
        height: 250,
      },
    },
    MuiCardContent: {
      root: {
        backgroundColor: 'rgb(15,15,15)',
        color: Colors.white,
        minHeight: 60,
        maxHeight: 60,
        padding: 0,
      },
    },
    MuiAutocomplete: {
      paper: {
        backgroundColor: Colors.wrapperLight,
        color: Colors.white,
      },
      groupLabel: {
        backgroundColor: Colors.wrapperLight,
        color: Colors.primary,
      },
    },
    MuiMenu: {
      paper: {
        backgroundColor: '#01132B',
        color: Colors.white,
      },
    },
    MuiAvatar: {
      root: {
        border: '3px solid white',
      },
    },
    MuiTypography: {
      colorPrimary: {
        color: Colors.white,
      },
      colorSecondary: {
        color: Colors.primary,
      },
    },
    MuiSelect: {
      root: {
        border: '1px solid #ced4da',
      },
    },
    MuiFormLabel: {
      root: {
        paddingHorizontal: 5,
        '&$focused': {
          color: Colors.primary,
        },
      },
    },
    MuiMobileStepper: {
      dot: {
        backgroundColor: Colors.white,
      },
      dotActive: {
        backgroundColor: Colors.primary,
      },
    },
    MuiStepIcon: {
      root: {
        color: Colors.primary,
        '&$active': {
          color: Colors.primary,
        },
        '&$completed': {
          color: Colors.primary,
        },
      },
    },
    MuiGrid: {
      root: {
        scrollbarWidth: 'thin',
        scrollbarColor: 'rgb(207, 22, 53) rgb(66, 66, 66)',
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: Colors.primary,
        },
      },
      notchedOutline: {
        borderColor: Colors.primary,
      },
    },
    MuiFilledInput: {
      underline: {
        '&:after': {
          borderBottomColor: Colors.primary,
        },
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottomColor: Colors.primary,
        },
        '&:before': {
          borderBottomColor: Colors.white,
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#F22740',
      },
    },
    MuiTooltip: {
      popper: {
        zIndex: 1000000,
        pointerEvents: 'auto',
        /*
          "will-change: transform" destroys the backdrop blur effect of child elements

          Probably related to this bug in Chromium:
          https://bugs.chromium.org/p/chromium/issues/detail?id=1194050
        */
        willChange: 'auto !important'
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: Colors.primary,
        backgroundColor: '#00050D',
      },
      dayLabel: {
        color: Colors.white,
      },
      transitionContainer: {
        color: Colors.white,
      },
    },
    MuiPickersDay: {
      day: {
        color: Colors.white,
      },
      daySelected: {
        backgroundColor: Colors.primary,
      },
      current: {
        color: Colors.black,
      },
      dayDisabled: {
        color: Colors.gray,
      },
    },
    MuiPickersYear: {
      root: {
        color: Colors.black,
      },
      yearSelected: {
        color: Colors.primary,
      },
    },
    MuiPickersClockNumber: {
      clockNumber: {
        color: Colors.white,
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: Colors.primary,
      },
      clock: {
        backgroundColor: '#00050D',
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: Colors.primary,
      },
      thumb: {
        backgroundColor: Colors.primary,
        borderColor: Colors.primary,
      },
      noPoint: {
        backgroundColor: Colors.primary,
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: Colors.primary,
      },
    },
    MuiTabs: {
      root: {
        backgroundColor: '#a10f27',
        color: Colors.white,
        fontWeight: 'bold',
      },
      indicator: {
        backgroundColor: '#a10f27',
      },
    },
    MuiTimelineItem: {
      missingOppositeContent: {
        '&:before': {
          flex: 'none',
        },
      },
    },
    MuiTimelineDot: {
      root: {
        padding: 8,
      },
    },
    MuiDialogTitle: {
      root: {
        background: 'linear-gradient(to right, #7d091c, #780c1e, #5c0d1b, #440a13)',
        color: Colors.white,
        borderRadius: 0,
      },
    },
    MuiDialog: {
      paper: {
        background: 'linear-gradient(to top right, #6d0e1e, #b40b28, #620f1c)',
        color: '#e094a1',
      },
      root: {
        zIndex: '13000 !important',
      },
    },
    MuiPopover: {
      root: {
        zIndex: '13000 !important',
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: '',
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        backgroundColor: '#01122B',
      },
    },
    MuiDialogActions: {
      root: {
        backgroundColor: '#122041',
        '& .MuiButton-label': {
          color: Colors.white,
        },
      },
    },
    MuiTab: {
      root: {
        fontSize: '25px !important',
        fontFamily: 'Bebas Neue',
        fontWeight: 400,
        textTransform: 'inherit',
        padding: '15px 0',
        '&:hover': {
          color: Colors.white,
          opacity: 1.0,
        },
        '&$selected': {
          color: Colors.white,
        },
      },
      labelIcon: {
        paddingTop: 15,
      },
    },
  },
});

export default THEME;