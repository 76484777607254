import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';
import MiniCountdown from '../Countdown/MiniCountdown';
import ProfileRankedItem from './ProfileRankedItem';
import Colors from '../../theme/Colors';
import moment from 'moment';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles({
  itemBox: {
    background: 'rgba(255, 255, 255, 0.04)',
    padding: '20px 26px',
    borderRadius: 6,
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
  },
  smallText: {
    fontSize: 12,
    color: 'rgba(255, 255, 255, 0.5)',
  },
  countdownArea: {
    marginLeft: -3,
    '& div': {
      fontSize: 18,
      color: 'rgba(255, 255, 255, 0.5)',
      textTransform: 'inherit',
      fontFamily: 'Montserrat',
    },
    '& span': {
      color: Colors.white,
      textShadow: 'none',
    },
  },
});

const ProfileRanked = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { seasonData, seasonInfo } = props;

  return (
    <>
      <div className={classes.itemBox}>
        <div>
          <div className={classes.smallText} style={{ marginBottom: 4 }}>
            {t('season_start')} {moment.unix(seasonInfo.available_from / 1000)
          .format('D.MM.Y ⦁ hh:mm A')}
          </div>
          <div style={{ fontSize: 26, fontWeight: 500 }}>
            {t('season_x', { season: seasonInfo.season })}
          </div>
        </div>
        {/*<div>
          <div className={classes.smallText} style={{ marginBottom: 6 }}>
            {t('season_ends_in')}:
          </div>
          <div className={classes.countdownArea}>
            <MiniCountdown endTime={seasonInfo.available_until} />
          </div>
        </div>*/}
      </div>

      <div style={{ display: 'grid', gap: 14, marginTop: 14 }}>
        {seasonData && seasonData.map(data => (
          <ProfileRankedItem
            key={`ProfileRankedItemKey${data.streamer._id}`}
            data={data}
            compact={isMobile}
          />
        ))}
        {seasonData && seasonData.length === 0 &&
        <div className={classes.itemBox} style={{ color: '#E12138', background: '#E121381C' }}>
          {t('not_ranked_yet')}
        </div>
        }
      </div>
    </>
  );
};

export default ProfileRanked;