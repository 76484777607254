import React from 'react';
import { Switch, withStyles } from '@material-ui/core';

import Colors from '../../theme/Colors';

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '& + $track': {
      background: 'linear-gradient(to bottom, #65728B 48%, #5C6981 49%, #505C71 50%, #454F62 51%, #3E4759 52%, #3B4555 53%)',
      opacity: 1,
      border: 'none',
    },
    '&$checked': {
      transform: 'translateX(16px)',
      color: Colors.white,
      '& + $track': {
        background: 'linear-gradient(to bottom, #97D71E 0%, #96D81E 50%, #57B81D 50%, #83CE1C 75%, #96D81E 100%)',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#9AD720',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default IOSSwitch;