import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { isBrowser } from 'react-device-detect';
import Typography from '@material-ui/core/Typography';

import Helpers from '../../theme/Helpers';
import LeaderBoardList from '../Leaderboard/LeaderBoardList';
import PropTypes from 'prop-types';
import Alert from '../Alert/Alert';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    background: 'transparent',
  },
  innerPaper: {
    paddingTop: 10,
  },
  rightWrapper: {
    marginRight: isBrowser ? theme.spacing(4) : 0,
  },
}));

const CardViewTopCollectors = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { informationData, isTime } = props;

  return (
    <Paper className={`${classes.paper} ${classes.rightWrapper}`}>
      <div className={classes.innerPaper}>
        {informationData.firstCollectors.length > 0 ?
          <LeaderBoardList entries={informationData.firstCollectors} isTime />
          :
          <Typography variant="caption" color="secondary" gutterBottom>
            {t('no_collectors_yet')}
          </Typography>
        }
      </div>
    </Paper>
  );
};

CardViewTopCollectors.propTypes = {
  isTime: PropTypes.bool,
};

CardViewTopCollectors.defaultProps = {
  isTime: false,
};

export default CardViewTopCollectors;