import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';
import { replaceWidthAndHeight } from '../../utils/helper';
import CustomTooltip from '../Tooltip/CustomTooltip';

const useStyles = makeStyles(() => ({
  wrapper: {
    ...Helpers.fillColCenter,
    marginTop: 5,
  },
}));

const TopStreamerItemGame = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { games } = props;

  if (!games || (games && games.length === 0))
    return null;

  games.sort((a, b) => b.streamed_mins - a.streamed_mins);
  const game = [...games].shift(); // clone because of new load after lazy loading
  const imgSrc = replaceWidthAndHeight(game.game.box_art_url, 40, 55);

  return (
    <div className={classes.wrapper}>
      <div style={{ color: '#8c98a9', fontSize: 14 }}>
        {t('top_streamed_category')}
      </div>
      <CustomTooltip title={game.game.name}>
        <div style={{ marginTop: 5 }}>
          <Link href={'/hq/game-streams/' + encodeURIComponent(game.game.name)}>
            <img src={imgSrc} alt={game.game.name} style={{ borderRadius: 4 }} />
          </Link>
        </div>
      </CustomTooltip>
    </div>
  );
};

export default TopStreamerItemGame;