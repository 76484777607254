import React, { useEffect, useState } from 'react';
import { Avatar, makeStyles } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import SimpleReactLightbox from 'simple-react-lightbox';

import { getMyIllustrationJobs } from '../../apollo/queries';
import {
  deleteIllustrationImage as deleteIllustrationImageMutation,
  updateIllustrationState as updateIllustrationStateMutation,
} from '../../apollo/mutations';

import Loading from '../Loading/Loading';
import Helpers from '../../theme/Helpers';
import ChatContainer from '../../containers/Chat/Chat';
import StreamerChatInfos from './StreamerChatInfos';
import Colors from '../../theme/Colors';
import { getRandomNumber } from '../../utils/helper';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

const useStyles = makeStyles(() => ({
  container: {
    ...Helpers.fillRow,
    minHeight: 500,
  },
  userArea: {
    ...Helpers.fillRowCross,
    padding: 10,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#393C43',
    },
  },
  selectedUser: {
    backgroundColor: '#393C43',
  },
  avatar: {
    borderColor: Colors.black,
    marginRight: 5,
    width: 60,
    height: 60,
  },
  jobBody: {
    flexGrow: 10,
    backgroundColor: '#212226',
    ...Helpers.fillRow,
  },
  allJobsArea: {
    width: 250,
    backgroundColor: '#131313',
    padding: 5,
  },
}));

const MyIllustratorJobs = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { myViewer } = props;
  const [activeJob, setActiveJob] = useState(null);

  const { data, loading } = useQuery(getMyIllustrationJobs, {
    fetchPolicy: 'network-only',
  });

  const [deleteIllustrationImage] = useMutation(deleteIllustrationImageMutation, {
    onCompleted: () => {
      enqueueSnackbar(t('success'), { variant: 'success' });
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{ query: getMyIllustrationJobs }],
  });

  const [updateIllustrationState] = useMutation(updateIllustrationStateMutation, {
    onCompleted: () => {
      enqueueSnackbar(t('success'), { variant: 'success' });
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{ query: getMyIllustrationJobs }],
  });

  const designJobs = (data) && data.getMyIllustrationJobs;

  useEffect(() => {
    if (activeJob) {
      const findJob = designJobs.find(job => job._id === activeJob._id);
      setActiveJob(findJob);
    }
  }, [designJobs]);

  const random = getRandomNumber();

  return (
    <div className={classes.container}>
      {loading ?
        <Loading />
        :
        <>
          <div className={classes.allJobsArea}>
            {designJobs && designJobs.map((job) => {
              const participant = job.participants[0];

              return (
                <div
                  className={[classes.userArea, activeJob && job._id === activeJob._id ? classes.selectedUser : ''].join(' ')}
                  onClick={() => setActiveJob(job)}
                  key={`MyJobKey${job._id}`}
                >
                  <Avatar
                    alt={participant.display_name}
                    src={participant.avatar}
                    className={classes.avatar}
                  />
                  {participant.display_name}
                </div>
              );
            })}
          </div>
          <div className={classes.jobBody}>
            {activeJob &&
            <>
              <ChatContainer id={activeJob._id} myViewer={myViewer} />
              <SimpleReactLightbox>
                <StreamerChatInfos
                  job={activeJob}
                  myViewer={myViewer}
                  random={random}
                  deleteIllustrationImage={deleteIllustrationImage}
                  updateIllustrationState={updateIllustrationState}
                />
              </SimpleReactLightbox>
            </>
            }
          </div>
        </>
      }
    </div>
  );
};

export default MyIllustratorJobs;