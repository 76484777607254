import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/react-hooks';

import { getProfileHeaderData } from '../../apollo/mergedQueries';
import { toggleViewerBadge as toggleViewerBadgeMutation } from '../../apollo/mutations';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import CustomTooltip from '../Tooltip/CustomTooltip';
import EmptyPopup from './EmptyPopup';
import AnimationButton from '../Button/AnimationButton';

const useStyles = makeStyles(() => ({
  badgesContainer: {
    marginBottom: 30,
    maxWidth: 800,
    display: 'grid',
    gap: 10,
    gridTemplateColumns: 'repeat(5, 1fr)',
  },
  badgeItem: {
    position: 'relative',
    cursor: 'pointer',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    padding: '12px 10px 8px',
  },
  isUsed: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/badges/badge-red.png)`,
    cursor: 'not-allowed',
  },
  selectedIcon: {
    position: 'absolute',
    width: 60,
    margin: -30,
    bottom: 7,
    left: '50%',
  },
  selectedBadge: {
    background: '#560d19',
    color: Colors.white,
    padding: 10,
    fontSize: 20,
    marginBottom: 20,
    fontFamily: 'Bebas Neue',
    ...Helpers.fillRowCross,
  },
  category: {
    border: '1px solid white',
    borderRadius: 3,
    marginBottom: 10,
  },
  tabMenu: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
    ...Helpers.fillRow,
    fontSize: 14,
    color: Colors.halfWhite,
    marginBottom: 40,

    '& > div': {
      marginRight: 32,
      paddingBottom: 10.5,
      borderBottom: '1px solid transparent',
      marginBottom: -1,
      cursor: 'pointer',
      transition: 'all 0.3s ease-out',

      '&.active': {
        borderColor: Colors.white,
        color: Colors.white,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
}));

/**
 * @return {null}
 */
function BadgePopup(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();
  const { handleClose, showBadges, categoryBadges, viewer, seasonBadges } = props;
  const [selectedBadge, setSelectedBadge] = useState(showBadges.badge ? showBadges.badge : null);
  const [activeTab, setActiveTab] = useState(showBadges.badge && showBadges.badge.isSeason ? 1 : 0);

  const [toggleViewerBadge] = useMutation(toggleViewerBadgeMutation, {
    onCompleted: () => {
      enqueueSnackbar(t('success'), { variant: 'success' });
      handleClose();
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{
      query: getProfileHeaderData,
      variables: {
        viewer: viewer._id,
        login: viewer.login,
        season: 0,
      },
    }],
  });

  const toggleBadge = () => {
    if (selectedBadge)
      toggleViewerBadge({
        variables: {
          id: selectedBadge.vId,
          index: showBadges.idx,
        },
      });
  };

  const viewBadge = (badge, progressCurrent, isSeason = false) => {
    const isReached = badge.active;
    const isSet = badge.isSet;
    const isSelected = selectedBadge && selectedBadge.vId === badge.vId;
    const badgeStyles = [classes.badgeItem];
    if (isSet)
      badgeStyles.push(classes.isUsed);

    return (
      <CustomTooltip
        title={isSeason ?
          t('season_badge_desc', badge.metaData && {
            name: badge.metaData.streamer.display_name,
            league: t(`rank_${badge.metaData.season.league}`),
          }) :
          t(badge.description, { infoData: ` (${progressCurrent})` })
        }
        key={`BadgeKey${badge._id}`}
      >
        <div
          className={badgeStyles.join(' ')}
          style={isReached ?
            (isSelected ? { backgroundImage: `url(${process.env.PUBLIC_URL}/badges/badge-green.png)` } : {}) :
            {
              filter: 'brightness(35%)',
              opacity: 0.9,
            }}
        >
          <img
            src={`${badge.image_url}?width=100`}
            width={100}
            alt={badge.title}
            onClick={() => (isReached && !isSet) ? setSelectedBadge(badge) : null}
          />
          {isSelected &&
          <img
            className={classes.selectedIcon}
            src={`${process.env.PUBLIC_URL}/icons-png/selected.png`}
            alt="Selected"
          />
          }
        </div>
      </CustomTooltip>
    );
  };

  const tabs = ['standard_menu', 'season_0', 'season_1'];

  return (
    <EmptyPopup
      handleClose={handleClose}
      title={t('badge_title')}
      subTitle={t('badge_subtitle')}
    >
      <div className={classes.tabMenu}>
        {tabs.map((tab, index) => (
          <div
            className={activeTab === index ? 'active' : ''}
            onClick={() => {
              if (activeTab !== index) {
                setActiveTab(index);
                setSelectedBadge(null);
              }
            }}
            key={`BadgeTab${tab}`}
          >
            {t(tab)}
          </div>
        ))}
      </div>
      <div className={classes.badgesContainer}>
        {activeTab === 0 && categoryBadges.map(badgeGroup =>
          <div key={`BadgeKey${badgeGroup.group}`} className={classes.category}>
            {badgeGroup.badges.map(badge => {
              /*const progressCurrent = (findGroup && findGroup.progress) ?
                (findGroup.progress.current > findGroup.progress.maximum ? `${findGroup.progress.maximum}+` : findGroup.progress.current) : 0;*/
              /*if (isSelected)
                selectedBadge.progress = progressCurrent;*/
              const progressCurrent = null;

              return viewBadge(badge, progressCurrent);
            })}
          </div>,
        )}
        {activeTab >= 1 && seasonBadges.map(seasonBadge => {
          if (seasonBadge.badge_template_id.category !== `Season${activeTab - 1}`)
            return null;

          const progressCurrent = null;
          const badge = {
            ...seasonBadge.badge_template_id,
            vId: seasonBadge._id,
            isSet: seasonBadge.showcase_position !== null,
            metaData: seasonBadge.metadata,
          };

          return (
            <div key={`SeasonBadgeKey${seasonBadge._id}`} className={classes.category}>
              {viewBadge(badge, progressCurrent, true)}
            </div>
          );
        })}
      </div>

      {selectedBadge !== null &&
      <div className={classes.selectedBadge}>
        <img
          src={`${selectedBadge.image_url}?width=100`}
          width={100}
          alt={selectedBadge.title}
          style={{ margin: '-20px 0', marginRight: 20 }}
        />
        {selectedBadge.metaData ?
          t('season_badge_desc', {
            name: selectedBadge.metaData.streamer.display_name,
            league: t(`rank_${selectedBadge.metaData.season.league}`),
          }) :
          t(selectedBadge.description, { infoData: ` (${selectedBadge.progress})` })
        }
      </div>
      }

      <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween, marginTop: 40 }}>
        {selectedBadge ?
          <AnimationButton onClick={toggleBadge}>
            {t('remove')}
          </AnimationButton>
          :
          <div />
        }
        <AnimationButton onClick={toggleBadge} disabled={!selectedBadge}>
          {t('confirm')}
        </AnimationButton>
      </div>
    </EmptyPopup>
  );
}

export default BadgePopup;