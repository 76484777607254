import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SocialMediaSettings from '../Settings/SocialMediaSettings';
import OnBoardingStepFooter from './OnBoardingStepFooter';
import OnBoardingStepHeader from './OnBoardingStepHeader';

const OnBoardingSocialMedia = (props) => {
  const { t } = useTranslation();
  const { viewer, updateMyData, classes, nextStep } = props;

  const [values, setValues] = useState({
    twitter: (viewer.twitter) ? viewer.twitter : '',
    facebook: (viewer.facebook) ? viewer.facebook : '',
    instagram: (viewer.instagram) ? viewer.instagram : '',
    youtube: (viewer.youtube) ? viewer.youtube : '',
  });

  const save = () => {
    updateMyData({ variables: { update: values } });

    nextStep();
  };

  return (
    <>
      <OnBoardingStepHeader
        header={t('on_boarding_social_head')}
        body={t('on_boarding_social_text')}
        classes={classes}
      />

      <div style={{ marginTop: 40 }}>
        <SocialMediaSettings values={values} setValues={setValues} />
      </div>

      <OnBoardingStepFooter nextStep={nextStep} save={save} classes={classes} />
    </>
  );
};

export default OnBoardingSocialMedia;