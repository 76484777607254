import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import { removeUserRole as removeUserRoleMutation } from '../../apollo/mutations';
import { getAllUsersByRole } from '../../apollo/queries';

import AdminRoleList from '../../components/Admin/AdminRoleList';
import Loading from '../../components/Loading/Loading';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

const AdminRoleListContainer = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [role, setRole] = useState('admin');

  const { data, loading } = useQuery(getAllUsersByRole, {
    variables: { role },
    fetchPolicy: useCacheWithExpiration('getAllUsersByRole', { role }),
  });
  const users = data && data.getAllUsersByRole;

  const makeAlert = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  const [removeUserRole] = useMutation(removeUserRoleMutation, {
    onCompleted: () => {
      makeAlert(t('success'), 'success');
    },
    onError: (data) => {
      makeAlert(data.message, 'error');
    },
    refetchQueries: () => [{ query: getAllUsersByRole, variables: { role } }],
  });

  const deleteRole = (id, role) => {
    removeUserRole({
      variables: {
        userId: id,
        role,
      },
    });
  };

  return (

    <AdminRoleList users={users} setRole={setRole} deleteRole={deleteRole} loading={loading} />
  );
};

export default AdminRoleListContainer;