import React from 'react';
import List from '@material-ui/core/List';
import { makeStyles, Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';

import {
  markMyNotificationsRead as markMyNotificationsReadMutation,
} from '../../apollo/mutations';
import { getMyNotifications } from '../../apollo/queries';

import NotificationsItem from './NotificationsItem';

const useStyles = makeStyles(() => ({
  list: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingTop: 0,
    paddingBottom: 1,
    overflow: 'hidden',
  },
  topRow: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const Notifications = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  let { notifications, haveUnread } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [markMyNotificationsRead] = useMutation(markMyNotificationsReadMutation, {
    onCompleted: () => {
      makeAlert(t('notifications_read_success'), 'success');
    },
    onError: (data) => {
      makeAlert(data.message, 'error');
    },
    refetchQueries: [
      {
        query: getMyNotifications,
        variables: {
          sorting: {
            field: 'created_at',
            order: 'DESC',
          },
        },
      },
    ],
  });

  const makeAlert = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  return (
    <div>
      <div className={classes.topRow}>
        {(haveUnread) &&
        <Button
          style={{ margin: '10px 10px 0' }}
          variant="contained"
          onClick={markMyNotificationsRead}>
          {t('mark_all_notifications_read')}
        </Button>
        }
      </div>
      <List className={classes.list}>
        {notifications.slice(0, 5)
        .map((item) => (
          <NotificationsItem key={item._id} notification={item} />
        ))}
      </List>
    </div>
  );
};

export default Notifications;