import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { withRouter } from 'react-router-dom';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import { getMatchData } from '../../utils/helper';
import MergedImage from '../MergedImage/MergedImage';

const useStyles = makeStyles({
  itemArea: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    width: 322,
    position: 'relative',
    padding: 16,
    transition: 'all 0.3s ease-out',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.08)',
    },
  },
  midAvatar: {
    width: 30,
    height: 30,
    border: 'none',
    marginRight: 6,
  },
  imageArea: {
    position: 'relative',
    cursor: 'pointer',
    ...Helpers.fillColCenter,
    marginBottom: 32,
  },
  coloredBGLine: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: 3,
    borderRadius: '6px 6px 0px 0px',
    background: Colors.level.special,
  },
  divider: {
    height: 1,
    background: 'rgba(255, 255, 255, 0.08)',
    width: 'calc(100% + 32px)',
    margin: '16.5px -16px',
  },
});

const CardShopAlbumCard = (props) => {
  const classes = useStyles();
  const { t, card } = props;

  const {
    avatar,
    name,
    url,
  } = getMatchData(card, 48, 48);

  return (
    <>
      <div className={classes.itemArea}>
        <div className={classes.coloredBGLine} />
        <div>
          <div style={Helpers.fillRowCross} onClick={() => props.history.push(url)}>
            <Avatar
              className={classes.midAvatar}
              alt="Avatar"
              src={avatar}
            />
            <div>
              <div style={{
                fontSize: 16,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                width: 150,
                fontWeight: 500,
              }}>
                {name}
              </div>
            </div>
          </div>

          <div className={classes.divider} style={{ marginBottom: 31.5, marginTop: 12.5 }} />

          <div className={classes.imageArea}>
            <MergedImage
              card={card}
              height={349}
              borderRadius={6}
              isLegacy
              id={`card-image-${card._id}`}
            />
          </div>
        </div>

        <div className={classes.divider} />

        <div style={{ color: '#1FE325', fontSize: 14 }}>
          <i className="fa-light fa-circle-check"
             style={{ fontSize: 16, marginRight: 6 }} />
          {t('card_included_in_album')}
        </div>
      </div>
    </>
  );
};

export default withRouter(CardShopAlbumCard);