import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { getAllOrders } from '../../apollo/queries';

import Loading from '../../components/Loading/Loading';
import OrderListTable from '../../components/Settings/OrderListTable';
import OrderInfo from '../../components/Settings/OrderInfo';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

const OrderList = () => {
  const { t } = useTranslation();
  const [selectedOrder, setSelectedOrder] = useState(null);

  const { data, loading } = useQuery(getAllOrders, {
    fetchPolicy: useCacheWithExpiration('getAllOrders'),
  });
  const orders = [];

  if (data && data.getAllOrders) {
    const allOrders = data.getAllOrders;

    allOrders.orders.map(order => {
      const taxRate = order.order_products[0].tax_rate;
      const total = order.order_products.reduce((acc, product) => acc + (product.unit_price * product.quantity), 0.00) + order.ship_cost;

      const isNewVersion = moment(parseInt(order.order_date))
      .isAfter('2021-05-09');

      orders.push({
        _id: order._id,
        id: order.order_id,
        date: moment(parseInt(order.order_date))
        .format('LLL'),
        status: order.order_status === 'payment_pending' ? 'Pending' : order.order_status_id === 3 ? 'Completed' : 'Payment_completed',
        pay_method: order.payment_method === 3 ? 'paypal' : 'unknown',
        name: order.offer_id && order.offer_id.title,
        products: order.order_products,
        ship_cost: order.ship_cost,
        tax_rate: taxRate,
        invoice: false,
        total,
        isNewVersion,
      });
    });

    allOrders.cardOrders.map(order => {
      const products = [];
      const taxRate = order.products[0].tax_rate;
      if (products.length === 0) {
        order.products.map(product => {
          const findProductIdx = products.findIndex(oProduct => oProduct.processing.design.url === product.processing.design.url);

          if (findProductIdx === -1) {
            const countProducts = order.products.filter(oProduct => oProduct.processing.design.url === product.processing.design.url);

            product.quantity = countProducts.length;
            products.push(product);
          }
        });
      }
      const total = products.reduce((acc, product) => acc + (product.unit_price * product.quantity), 0.00) + order.ship_cost;

      const isNewVersion = moment(parseInt(order.created_at))
      .isAfter('2021-05-09');

      orders.push({
        _id: order._id,
        id: order.payment_reference,
        date: moment(parseInt(order.created_at))
        .format('LLL'),
        status: order.status.charAt(0)
        .toUpperCase() + order.status.slice(1),
        pay_method: order.payment_provider,
        name: t('card_order'),
        state_history: order.state_history,
        products,
        ship_cost: order.ship_cost,
        tax_rate: taxRate,
        invoice: true,
        packins: order.packins,
        total,
        isNewVersion,
        fulfillment_id: order.fulfillment_id,
      });
    });

    allOrders.genericOrders.map(order => {
      const products = [];
      const taxRate = order.card_products.length > 0 && order.card_products[0].tax_rate;
      if (products.length === 0) {
        order.card_products.map(product => {
          const findProductIdx = products.findIndex(oProduct => oProduct.processing.design.url === product.processing.design.url);

          if (findProductIdx === -1) {
            const countProducts = order.card_products.filter(oProduct => oProduct.processing.design.url === product.processing.design.url);

            product.quantity = countProducts.length;
            products.push(product);
          }
        });
      }
      const total = order.total_cost;

      const isNewVersion = moment(parseInt(order.created_at))
      .isAfter('2021-05-09');

      orders.push({
        _id: order._id,
        id: null,
        date: moment(parseInt(order.created_at))
        .format('LLL'),
        status: order.status.charAt(0)
        .toUpperCase() + order.status.slice(1),
        pay_method: order.payment_provider,
        name: order.offer_products && order.offer_products.length > 0 ? order.offer_products[0].name : (order.card_products.length === 0 ? order.premium_offer ? t('x_gems_buy', {count: order.premium_amount}) : t('supporter') : t('card_order')),
        state_history: order.state_history,
        products,
        ship_cost: order.ship_cost,
        tax_rate: taxRate,
        invoice: true,
        packins: order.packins,
        albums: order.offer_products,
        total,
        isNewVersion,
        fulfillment_id: order.fulfillment_id,
        plantedTreeURL: order.tree_plant_url,
        gems: order.premium_offer ? {
          id: order.premium_offer,
          amount: order.premium_amount,
          invoice: order.invoice,
        } : null,
      });
    });
  }

  const sortedArray = orders.sort((a, b) => moment(b.date)
  .diff(a.date));

  return (
    loading ?
      <Loading />
      :
      selectedOrder ?
        <OrderInfo key={`SelectedOrder${selectedOrder._id}`} order={selectedOrder}
                   setSelectedOrder={setSelectedOrder} />
        :
        <OrderListTable orders={sortedArray} setSelectedOrder={setSelectedOrder} />
  );
};

export default OrderList;