import React from 'react';

const CooperationAgreementEN = () => {
  return (
    <>
      <h1 dir="ltr">
        1. SCOPE AND DEFINITIONS
      </h1>

      <p dir="ltr">
        1.1 This supplementary Agreement ("<b>Agreement</b>") governs the contractual relationship
        for the cooperation between Streamheroes GmbH, Triererstraße 5, 66839 Schmelz, Germany,
        registered in the Commercial Register of the Commercial Court of Saarbrücken under HRB
        106787, Tel.: +43 650 4989 426, e-mail: <a
        href="mailto:support@streamheroes.gg">support@streamheroes.gg</a>, UID no.: DE 335845977
        ("<b>Streamheroes</b>") and cooperation partners who wish to participate in the
        Streamheroes' partner program in connection with the online platform as hosted at the
        website <a href="https://www.streamheroes.gg">https://www.streamheroes.gg</a> and via mobile
        applications ("<b>Platform</b>") (this partner program hereinafter the "<b>Cooperation</b>"
        and such cooperation Partners "<b>Partners</b>").
      </p>

      <p dir="ltr">
        1.2 Any terms and conditions of the Partners shall only apply if they are accepted by
        Streamheroes in each case on the basis of an individual agreement.
      </p>

      <p dir="ltr">
        1.3 By way of clarification, it is pointed out that for the use of the Platform, in addition
        to this Agreement, the general terms and conditions of use apply, as they are available
        at <a
        href="https://app.streamheroes.gg/page/terms-of-use">https://app.streamheroes.gg/page/terms-of-use</a> in
        the currently valid version and which the Partner must accept when registering on the
        Platform for the first time ("Terms of Use"). In the event of any conflict between this
        Agreement and the Terms of Use, the provisions of this Agreement shall prevail.
      </p>

      <p dir="ltr">
        1.4 Where reference is made in this Agreement to a "<i>Section</i>", such reference shall be
        to the relevant Section in this Agreement.
      </p>

      <h1 dir="ltr">
        2. DESCRIPTION OF THE COOPERATION
      </h1>

      <p dir="ltr">
        2.1 As part of the Platform, Streamheroes offers its users the opportunity to collect
        virtual energy ("<i>Coins</i>") by watching Platform users stream on Twitch.tv
        ("<b>Twitch</b>"). Virtual cards can be purchased on the Platform in exchange for this
        energy. A more detailed description of the service can be found in the Terms of Use and
        at <a href="https://app.streamheroes.gg/page/faq">https://app.streamheroes.gg/page/faq</a>.
      </p>

      <p dir="ltr">
        2.2 Twitch, in turn, provides for individuals streaming on Twitch the possibility to join
        the "<i>Twitch Affiliate Program</i>," and joining and participating in the program is
        subject to the terms of the "Twitch Affiliate Agreement" as viewed at <a
        href="https://www.twitch.tv/p/de-de/legal/affiliate-agreement/">https://www.twitch.tv/p/de-de/legal/affiliate-agreement/</a> ("<b>Affiliate
        Agreement</b>").
      </p>

      <p dir="ltr">
        2.3 Streamheroes offers Partners the possibility to design virtual cards on the Platform,
        which can be subsequently collected by the users of the Platform against payment of the
        virtual energy, which is gathered by watching. A user can then have those virtual cards
        collected on the Platform printed and can purchase them in physical form in the web store as
        provided on the Platform by Streamheroes ("<b>Webshop</b>"), if desired. In this regard,
        Streamheroes shall handle the sale, printing process and execution with regards to the
        purchase of the physical cards. Each physical card made in this way has a QR code directly
        linked to the virtual card collected and is therefore unique.
      </p>

      <p dir="ltr">
        2.4 Within the framework of the Cooperation, the Partner grants Streamheroes the right to
        market the virtual cards designed by the Partner (such cards hereinafter "<b>Virtual
        Cards</b>") and to create physical cards on the basis of such Virtual Cards upon user
        request ("<b>Physical Cards</b>") and to sell them to the users of the Platform. In return,
        Streamheroes will pay the Partner a share of the revenues generated by the sale of Physical
        Cards. In this regard, the payment of the sales will be made by the payment provider
        "Stripe" ("<b>Stripe</b>") via the tool "<i>stripe connect</i>", as described in more detail
        at <a href="https://stripe.com/at/connect">https://stripe.com/at/connect</a> ("<b>Stripe
        Connect</b>").
      </p>

      <h1 dir="ltr">
        3. CONDITIONS FOR COOPERATION, EXECUTION OF THIS AGREEMENT
      </h1>

      <p dir="ltr">
        3.1 The Partner shall register with Stripe Connect in advance, providing true and complete
        information ("<b>Registration Process</b>"). Streamheroes shall be notified by Stripe upon
        positive verification of Partner's information. Upon receipt of such notification by Stripe,
        Streamheroes shall send a notification to the Partner via the disclosed email address,
        whereby Streamheroes accepts the offer to enter into this Agreement. Streamheroes shall have
        the sole discretion to reject or accept offers from Partners to enter into this Agreement
        (even after a positive assessment by Stripe).
      </p>

      <p dir="ltr">
        3.2 In particular, the following conditions must be met by the Partner prior to any
        acceptance of the offer pursuant to Section 3.1:
        <ol type="a">
          <li>
            The Partner has accepted and fulfilled with all terms and conditions of the Affiliate
            Agreement, is an "<i>Affiliate</i>" as defined by the Affiliate Agreement and
            participates in the "<i>Twitch Affiliate Program</i>" and there are currently no reasons
            or procedures that would result in termination of participation in the "<i>Twitch
            Affiliate Program</i>";
          </li>
          <li>
            The Partner has successfully registered with Stripe in accordance with Section 6.3 of
            this Agreement and has complied with all requirements, in particular in connection with
            money laundering and <i>know-your-customer obligations</i>;
          </li>
          <li>
            If the Partner is a natural person, he has reached the age of eighteen;
          </li>
          <li>
            If any business permits, licenses or other official approvals are required for the
            performance of Partner's activities under the Cooperation, the Partner has obtained
            them; and
          </li>
          <li>
            the Partner has read and accepted the Terms of Use.
          </li>
        </ol>
      </p>

      <p dir="ltr">
        3.3 During the duration of this Agreement, the Partner shall keep Streamheroes informed of
        any changes in the information provided by the Partner in the Registration Process.
        Furthermore, he shall notify Streamheroes immediately if there is reason to believe that a
        condition pursuant to Section 3.2 is no longer fulfilled.
      </p>

      <p dir="ltr">
        3.4 It is clarified that this Agreement is subject to the assumption that the Partner
        pursues a permanent, independent economic activity and is therefore an entrepreneur
        (<i>Unternehmer</i>) under Austrian law.
      </p>

      <h1 dir="ltr">
        4. LICENSES TO THE CONTENT GENERATED BY THE PARTNER
      </h1>

      <p dir="ltr">
        4.1 The Partner grants Streamheroes a perpetual, worldwide, exclusive, royalty-free,
        sublicensable and transferable license to use, reproduce, distribute, create derivative
        works of and commercially exploit all information, data and content related to the Virtual
        Cards, such as, but not limited to, the right (i) to use the Virtual Cards as a template for
        the creation of Physical Cards, and (ii) to allow users of the Platform to collect and
        acquire such Virtual Cards in the course of using the Platform.
      </p>

      <p dir="ltr">
        4.2 Streamheroes shall be entitled, during the term of this Agreement, to use the name, as
        well as any trademarks or other identifying features of the Partner for the purpose of
        promoting, distributing and commercially exploiting the Virtual Cards, such as, in
        particular, displaying such names, trademarks or other identifying features on the Platform.
      </p>

      <h1 dir="ltr">
        5. RIGHTS AND DUTIES OF THE PARTNER
      </h1>

      <p dir="ltr">
        5.1 The Partner shall have the non-exclusive, royalty-free, non-sublicensable and
        non-transferable right, limited to the term of the Agreement, to create Virtual Cards on the
        Platform and to present them on the Platform.
      </p>

      <p dir="ltr">
        5.2 The Partner shall be solely responsible for the creation of the Virtual Cards; in
        particular, Streamheroes shall have no obligation to control the legality of the contents
        that are part of such Virtual Cards. Among other things, the user shall ensure that the
        Virtual Cards do not infringe any third-party rights, such as, in particular, copyrights or
        other industrial property rights, and the Partner shall ensure that he is entitled to use
        all content used by him (such as, among other things, images, graphics or fonts) to the
        extent necessary.
      </p>

      <p dir="ltr">
        5.3 Streamheroes reserves the right to delete Virtual Cards from the Platform with immediate
        effect if Streamheroes becomes aware that the content that is part of such Virtual Cards is
        unlawful or offensive, infringes the rights of third parties or violates the Terms of Use.
      </p>

      <p dir="ltr">
        5.4 The Partner shall refrain from any action and shall not become involved in any situation
        that brings Streamheroes into public disrepute, causes discredit or negative media coverage,
        or otherwise damages the reputation of Streamheroes.
      </p>

      <p dir="ltr">
        5.5 The Partner shall indemnify and hold Streamheroes harmless with respect to any and all
        claims that may be
        <ol type="a">
          <li>
            based on an infringement of an effective industrial property right or intellectual
            property right of a third person by the Partner in connection with the creation of the
            Virtual Cards;
          </li>
          <li>
            based on the fact that the Partner has not complied with the conditions and requirements
            set forth in Sections 3.2 and 3.3 or has made false statements about the existence of
            such conditions; and
          </li>
          <li>
            result from a violation of Section 5.4.
          </li>
        </ol>
      </p>

      <h1 dir="ltr">
        6. REVENUE SHARING, PAYMENT TERMS
      </h1>

      <p dir="ltr">
        6.1 For the license granted in accordance with Section 4.1, the Partner shall receive a
        monthly participation in the Sales of all Physical Cards in the amount of 40%.
      </p>

      <p dir="ltr">
        "<b>Sales</b>" means all revenues received by Streamheroes from users for the sale of
        Physical Cards through the Webshop, less (i) billing and payment fees and any other costs
        associated with payment, (ii) taxes required to be paid with respect to Sales such as value
        added taxes, and (iii) expenses for returns, refunds, chargebacks discounts or other credits
        associated with the sale of Physical Cards.
      </p>

      <p dir="ltr">
        6.2 Streamheroes shall pay the revenue share pursuant to Section 6.1 for a respective
        calendar month to the Partner via Stripe Connect within 45 days after the end of such
        calendar month in Euro, but only if the revenue share to be paid for the respective calendar
        month is more than EUR 50 ("<b>Threshold</b>"). If the Threshold is not reached in one (or
        more) consecutive calendar months, Streamheroes shall carry forward the revenue share to the
        next calendar month until the sum carried forward together with the revenue share in a
        calendar month reaches the Threshold in total and then pay the entire Threshold to the
        Partner.
      </p>

      <p dir="ltr">
        6.3 The Partner acknowledges that a payout of the revenue shares pursuant to Section 6.2 can
        only take place via Stripe Connect and that the Partner must register with Stripe for a
        payout. In this context, the Partner acknowledges and agrees that separate terms of use may
        apply to the use of Stripe Connect, over which Streamheroes has no influence whatsoever. It
        is the sole responsibility of the Partner to accept and comply with all provisions related
        to the use of Stripe Connect and to fulfill all requirements, such as, in particular, the
        money laundering and <i>know-your-customer checks</i> to be complied with as part of the
        registration on Stripe Connect.
      </p>

      <h1 dir="ltr">
        7. DURATION AND TERMINATION
      </h1>

      <p dir="ltr">
        7.1 This Agreement is concluded for an indefinite period. Both parties have the right to
        terminate the Agreement at any time by giving written notice in compliance with the notice
        period of one month.
      </p>

      <p dir="ltr">
        7.2 In addition, each party shall be entitled to terminate the Agreement for good cause at
        any time with immediate effect, if further cooperation should be unacceptable for the party.
        Good cause shall be in particular, but not exclusively, a breach of the Terms of Use, or a
        breach of the obligations under Section 3, 5.2 and 5.4.
      </p>

      <p dir="ltr">
        7.3 This Agreement may further be terminated by Streamheroes at any time if the conditions
        set forth in Section 3.2 no longer apply.
      </p>

      <p dir="ltr">
        7.4 Upon termination of this Agreement, for any reason whatsoever
        <ol type="a">
          <li>
            the parties will return to the other party (and will not retain, reconstruct or transfer
            to anyone else) all documents, books, records, correspondence, papers, confidential
            information and other property of the other party which is in the possession, power,
            custody or control of the other party;
          </li>
          <li>
            Streamheroes shall, subject to the next sentence, remove the Virtual Cards and all
            names, any trademarks or other identifying features of the Partner from the Platform and
            shall no longer allow users of the Platform to acquire Physical Cards. Previously
            purchased Virtual Cards collected by users will remain on the Platform, but will have
            limited accessibility.
          </li>
        </ol>
      </p>

      <h1 dir="ltr">
        RÜCKTRITTSRECHT VON STREAMHEROES UND UNBERECHTIGTER RÜCKTRITT DES KÄUFERS
      </h1>

      <p dir="ltr">
        Bei Annahmeverzug (siehe Abschnitt 4) oder anderen wichtigen Gründen, wie insbesondere dem
        Zahlungsverzug des Kunden, ist STREAMHEROES nach Setzen einer angemessenen, mindestens 2
        Wochen umfassenden, Nachfrist zum Rücktritt des Vertrags berechtigt, sofern er von beiden
        Seiten noch nicht zur Gänze erfüllt wurde.
      </p>

      <p dir="ltr">
        Tritt der Kunde – ohne dazu berechtigt zu sein – vom Vertrag zurück oder begehrt er seine
        Aufhebung, so hat STREAMHEROES die Wahl, auf das Erfüllen des Vertrages zu bestehen oder der
        Aufhebung des Vertrags zuzustimmen.
      </p>

      <h1 dir="ltr">
        8. LIABILITY
      </h1>

      <p dir="ltr">
        8.1 To the extent permitted by law and subject to Section 8.2, Streamheroes liability due to
        slight negligence is generally excluded. Streamheroes shall be liable exclusively for
        damages based on intentional or grossly negligent conduct of Streamheroes.
      </p>

      <p dir="ltr">
        8.2 The limitations of liability set forth in Section 8.1 shall not apply to damages
        resulting from injury to life, body or health of persons or in the event that mandatory
        statutory provisions to the contrary apply.
      </p>

      <h1 dir="ltr">
        9. DATA PROTECTION
      </h1>

      <p dir="ltr">
        9.1 Streamheroes is obligated to comply with the provisions of the Data Protection Act
        (<i>Datenschutzgesetz</i> "DSG"), the General Data Protection Regulation
        (<i>Datenschutzgrundverordnung</i> "DSGVO") as well as any other statutory confidentiality
        obligations.
      </p>

      <p dir="ltr">
        9.2 Furthermore, Streamheroes processes the personal data required for the purpose of
        fulfilling this Agreement. The detailed data protection information (data protection notice)
        pursuant to Art 13 ff DSGVO is available at <a
        href="https://www.streamheroes.gg/privacy">https://www.streamheroes.gg/privacy</a>.
      </p>

      <h1 dir="ltr">
        10. CONFIDENTIALITY
      </h1>

      <p dir="ltr">
        10.1 The Parties shall keep (i) the contents of the Agreement and (ii) all information
        obtained in connection with the negotiations and conclusion of the Agreement strictly
        confidential to the extent that such documents and information are not publicly known or
        have been obtained without breach of this confidentiality obligation or their disclosure is
        required by law. The Parties are entitled to disclose the foregoing information to current
        and future shareholders, affiliates, their/its officers and employees, and consultants, to
        the extent that they have entered into a customary confidentiality Agreement.
      </p>

      <p dir="ltr">
        10.2 Public communications about this Agreement, its conclusion and execution, in particular
        to the media, shall be agreed in advance between the Parties.
      </p>

      <h1 dir="ltr">
        11. RELEVANT NOTIFICATIONS
      </h1>

      <p dir="ltr">
        11.1 All notices with respect to the Agreement shall be in writing and shall be delivered to
        the addresses or email addresses disclosed in the Registration Process (or other
        communication channels accepted by the parties), unless another form is required by
        mandatory law. E-mails shall be deemed to be written communications.
      </p>

      <p dir="ltr">
        12.2 Each party is obliged to notify the other party of any changes in its contact details.
        Otherwise, notices sent to the last known address or e-mail address shall be deemed to have
        been effectively transmitted.
      </p>

      <h1 dir="ltr">
        12. FINAL PROVISIONS
      </h1>

      <p dir="ltr">
        12.1 If any provision of this Agreement is or becomes invalid or this Agreement contains a
        gap, this shall not affect the validity of the remaining provisions. Instead of the invalid
        provision, the parties shall agree on a valid provision that comes closest to the economic
        intention of the parties. This shall also apply to any loopholes.
      </p>

      <p dir="ltr">
        12.2 Streamheroes and the Partner are independent parties. Nothing in this Agreement shall
        be construed to make either party an agent, employee, franchisee, joint venture partner or
        legal representative of the other party.
      </p>

      <p dir="ltr">
        12.3 Failure to enforce any provision of this Agreement shall not be deemed a waiver of
        future enforcement of that or any other provision of the Agreement.
      </p>

      <p dir="ltr">
        12.4 This Agreement and all legal relations and disputes between the Partner and
        Streamheroes shall be governed exclusively by Austrian law with the exception of the
        conflict of law rules and the UN Convention on Contracts for the International Sale of
        Goods. The place of jurisdiction for all disputes arising from or in connection with this
        Agreement shall be the competent court in Steyr, Austria.
      </p>

      <p dir="ltr">
        12.5 If this Agreement should be translated into several languages, the German version shall
        be the authoritative version.
      </p>

      <p dir="ltr">
        12.6 This Agreement shall apply to the Partner personally and may not be assigned or
        transferred for any reason whatsoever (including transfer by operation of law, by reason of
        a merger, reorganization or as a result of an acquisition or change of ownership) without
        the prior written consent of Streamheroes, and any breach of this provision shall entitle
        Streamheroes to terminate the Agreement with immediate effect for cause. Streamheroes
        expressly reserves the right to assign and transfer the rights and obligations under the
        Agreement to affiliated companies within the meaning of Section 189a of the Austrian
        Commercial Code (Unternehmensgesetzbuch, "UGB").
      </p>
    </>
  );
};

export default CooperationAgreementEN;