import { PrismaSDK } from '@prismadelabs/prismaid';

const conf = require('../../../conf');

class SDKSingleton {
  static instance;

  constructor () {
    if (SDKSingleton.instance)
      throw new Error('Error - use SDKSingleton.getInstance()');
    this.sdk = new PrismaSDK(conf.prismade_id);
    this.sdk.setTwoFingerHoldingMode(true);
    // FIXME: set Languange if wanted
    // changeLanguage(this.sdk.getFinalSDKLanguage());
  }

  static getInstance () {
    SDKSingleton.instance = SDKSingleton.instance || new SDKSingleton();
    return SDKSingleton.instance;
  }
}

export default SDKSingleton;
