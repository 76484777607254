import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';
import { rangeArray } from '../../utils/helper';
import Colors from '../../theme/Colors';
import CustomTooltip from '../Tooltip/CustomTooltip';

const conf = require('../../conf');

const CardToolUserImages = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    canvas,
    checkIfObjectExists,
    uploadedImages,
    removeSpecificIndexOfImages,
    addActionStep,
    imageDPI,
    calculateAllImagesDPI,
    setActiveUserKey,
    activeUserKey,
    uploadedLogo,
    setUploadedLogo,
    setActiveHero,
    activeHero,
  } = props;

  const onUserImageDelete = (index) => {
    const object = checkIfObjectExists(`user-image-${uploadedImages[index].key}`);
    if (object) {
      if (activeUserKey === uploadedImages[index].key)
        setActiveUserKey(null);
      removeSpecificIndexOfImages(index);
      onDelete(object);
    }
  };

  const onLogoDelete = () => {
    const object = checkIfObjectExists('user-logo');
    if (object) {
      onDelete(object);
      setUploadedLogo(null);
    }
  };

  const onHeroDelete = () => {
    const object = checkIfObjectExists('active-hero');
    if (object) {
      onDelete(object);
      setActiveHero(null);
    }
  };

  const onDelete = (object) => {
    addActionStep({
      type: 'deleteObject',
      data: {
        old: object,
        id: object.id,
      },
    });
    canvas.remove(object);
    canvas.requestRenderAll();
    calculateAllImagesDPI();
  };

  /**
   * If the user lose the active uploaded user image, he can click twice to set the uploaded user
   * image active
   */
  const onDoubleClickHandler = (objId) => {
    const obj = checkIfObjectExists(objId);
    if (canvas && obj) {
      canvas.setActiveObject(obj);
      // important
      canvas.requestRenderAll();
    }
  };

  return (
    <>
      <div style={{ marginBottom: 10, ...Helpers.fillRowCenter, ...Helpers.mainEnd }}>
        <CustomTooltip title={t('uploaded_images_help')}>
          <div>
            <FontAwesomeIcon icon={faInfoCircle} size="lg" color={Colors.white} />
          </div>
        </CustomTooltip>
      </div>
      <div className={classes.wrapper}>
        {rangeArray(0, 1)
        .map(idx => (
          <div
            className={classes.item}
            key={`UserImageItem${idx}`}
            style={(activeUserKey === idx ? { borderColor: Colors.white } : {})}
          >
            {uploadedImages.length > idx ?
              <>
                <CustomTooltip title={t('remove_selected_element')}>
                  <div>
                    <FontAwesomeIcon
                      className={classes.clickableIcon}
                      icon={faTrash}
                      color={Colors.white}
                      onClick={() => onUserImageDelete(idx)}
                    />
                  </div>
                </CustomTooltip>
                <img
                  src={`${uploadedImages[idx].value.replace(conf.no_cors_cdn_url, conf.cdn_url)}?height=110`}
                  style={{ maxWidth: 80 }}
                  alt={`User graphic`}
                  onDoubleClick={() => onDoubleClickHandler(`user-image-${uploadedImages[idx].key}`)}
                />
              </>
              :
              <div>{t('motive')} {idx + 1}</div>
            }
          </div>
        ))}
        <div
          className={classes.item}
          style={(activeUserKey === 2 ? { borderColor: Colors.white } : {})}
        >
          {uploadedLogo ?
            <>
              <CustomTooltip title={t('remove_selected_element')}>
                <div>
                  <FontAwesomeIcon
                    className={classes.clickableIcon}
                    icon={faTrash}
                    color={Colors.white}
                    onClick={() => onLogoDelete()}
                  />
                </div>
              </CustomTooltip>
              <img
                src={`${uploadedLogo.replace(conf.no_cors_cdn_url, conf.cdn_url)}?height=110`}
                style={{ maxWidth: 80 }}
                alt={`Logo graphic`}
                onDoubleClick={() => onDoubleClickHandler('user-logo')}
              />
            </>
            :
            <div>{t('logo')}</div>
          }
        </div>
        <div
          className={classes.item}
          style={(activeUserKey === 3 ? { borderColor: Colors.white } : {})}
        >
          {activeHero ?
            <>
              <CustomTooltip title={t('remove_selected_element')}>
                <div>
                  <FontAwesomeIcon
                    className={classes.clickableIcon}
                    icon={faTrash}
                    color={Colors.white}
                    onClick={() => onHeroDelete()}
                  />
                </div>
              </CustomTooltip>
              <img
                src={`${activeHero.replace(conf.no_cors_cdn_url, conf.cdn_url)}?height=110`}
                style={{ maxWidth: 80 }}
                alt={`hero graphic`}
                onDoubleClick={() => onDoubleClickHandler('active-hero')}
              />
            </>
            :
            <div>{t('hero')}</div>
          }
        </div>
      </div>
      {(imageDPI) &&
      <div style={{ marginTop: 10 }}>
        {(imageDPI >= 300) ?
          <div style={{ color: Colors.success }}>{t('card_tool_perfect_dpi')}</div>
          :
          (imageDPI > 160) ?
            <div style={{ color: Colors.gold }}>{t('card_tool_ok_dpi')}</div>
            :
            <div style={{ color: Colors.error }}>{t('card_tool_bad_dpi')}</div>
        }
      </div>
      }
    </>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
  },
  item: {
    backgroundColor: '#010F22',
    border: '1px solid #647389',
    borderRadius: 4,
    width: 80,
    height: 110,
    padding: 10,
    ...Helpers.fillRowCenter,
    position: 'relative',
    color: '#647389',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  clickableIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
  },
}));

export default CardToolUserImages;