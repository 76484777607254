import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Avatar from '@material-ui/core/Avatar';
import { withRouter } from 'react-router-dom';

import Helpers from '../../theme/Helpers';
import AnimationButton from '../Button/AnimationButton';
import { dec2rom, getLeagueImage, indicator } from '../../utils/helper';
import Colors from '../../theme/Colors';

const useStyles = makeStyles({
  itemBox: {
    background: 'rgba(255, 255, 255, 0.04)',
    paddingRight: 22,
    overflow: 'hidden',
    borderRadius: 6,
    ...Helpers.fillRowCenter,
    gap: 20,
    position: 'relative',
  },
  item: {
    flex: props => props.compact ? 0 : 1,
  },
  smallText: {
    fontSize: 14,
    color: 'rgba(255, 255, 255, 0.5)',
  },
  rankText: {
    fontSize: props => props.compact ? 14 : 26,
    color: Colors.white,
    marginRight: 4,
  },
  streamerName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 150,
    fontSize: props => props.compact ? 14 : 16,
  },
  avatar: {
    width: props => props.compact ? 22 : 41,
    height: props => props.compact ? 22 : 41,
    border: 'none',
    marginRight: 12,
  },
  rankArea: {
    ...Helpers.fillRowCross,
    clipPath: 'polygon(0 0, 100% 0, 72% 100%, 0% 100%)',
    position: 'relative',
    padding: props => props.compact ? '12px 35px 12px 22px' : '12px 100px 12px 22px',
    flexGrow: 0,
  },
});

const ProfileRankedItem = (props) => {
  const { data, compact = false } = props;

  const classes = useStyles({
    compact,
  });

  const { t } = useTranslation();
  const { streamer } = data;

  const leagueImage = getLeagueImage(data.league, compact ? 40 : 133);

  return (
    <div className={classes.itemBox}>
      <div className={classes.rankArea}>
        <div
          style={{ ...Helpers.insetZero, zIndex: -1, filter: 'blur(16px)' }}
        >
          <div style={{
            ...Helpers.insetZero,
            backgroundImage: `url("${streamer.profile_image_url}")`,
          }} />
          <div style={{ ...Helpers.insetZero, background: 'rgba(0, 16, 36, 0.45)' }} />
        </div>

        {leagueImage &&
        <img src={leagueImage} width={compact ? 40 : 133} alt="Ranking league" />
        }

        {!compact &&
        <div style={{ marginLeft: 18 }}>
          <div className={classes.smallText} style={{ marginBottom: 4 }}>
            {t('rank')}
          </div>
          <div>{t(`rank_${data.league}`)} {dec2rom(streamer.rank_level)}</div>
        </div>
        }
      </div>

      <div
        className={classes.item}
        style={{ ...Helpers.fillRowCross, flexGrow: 1 }}
      >
        <Avatar
          className={classes.avatar}
          alt="Avatar"
          src={streamer.profile_image_url}
          onClick={() => props.history.push(`/profile/${streamer.login}/leaderboard`)}
          style={{ cursor: 'pointer' }}
        />
        <div
          onClick={() => props.history.push(`/profile/${streamer.login}/leaderboard`)}
          style={{ cursor: 'pointer' }}
        >
          {!compact &&
          <div className={classes.smallText}>{t('streamer')}</div>
          }

          <div className={classes.streamerName}>
            {streamer.display_name}
          </div>
        </div>
      </div>

      <div className={classes.item}>
        <span className={classes.rankText}>
          {data.position}
        </span>

        <span className={classes.smallText}>{indicator(data.position)}</span>
      </div>

      {!compact &&
      <>
        <div className={classes.item}>
          <div className={classes.smallText} style={{ marginBottom: 4 }}>
            {t('ranked_points')}
          </div>
          <div>{data.points}</div>
        </div>

        <AnimationButton
          status="whiteTransparency"
          onClick={() => props.history.push(`/profile/${streamer.login}/leaderboard`)}
          style={{ marginRight: 4 }}
        >
          {t('view_more')}
        </AnimationButton>
      </>
      }
    </div>
  );
};

export default withRouter(ProfileRankedItem);