import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';

const PopupButton = (props) => {
  const { onClick, children, style, backgroundPosition, disabled } = props;

  const useStyles = makeStyles(() => ({
    button: {
      background: `url(${process.env.PUBLIC_URL}/buttons/popup.png)`,
      backgroundSize: '100% 200%',
      backgroundRepeat: 'no-repeat',
      padding: '10px 100px',
      color: Colors.white,
      textDecoration: 'none',
      fontSize: 24,
      ...Helpers.textCenter,
      fontFamily: 'Bebas Neue',
      cursor: 'pointer',
      '&:hover': {
        backgroundPosition: backgroundPosition ? backgroundPosition : '0 -49px',
      },
    },
    disabled: {
      filter: 'grayscale(100%)',
      cursor: 'not-allowed',
    },
  }));

  const classes = useStyles();

  const buttonClasses = [classes.button];
  if (disabled)
    buttonClasses.push(classes.disabled);

  return (
    <div className={buttonClasses.join(' ')} onClick={disabled ? null : onClick} style={style}>
      {children}
    </div>
  );
};

PopupButton.propTypes = {
  style: PropTypes.object,
};

PopupButton.defaultProps = {
  style: {},
};

export default PopupButton;