import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import HoverIcon from '../Icon/HoverIcon';
import { copyToClipboard } from '../../utils/helper';
import { useSnackbar } from 'notistack';

const HiddenElement = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { value, hideInfo } = props;
  const [isHidden, setIsHidden] = useState(true);

  const onClick = () => {
    copyToClipboard(value);
    enqueueSnackbar(t('success'), { variant: 'success' });
  };

  return (
    <div style={{ ...Helpers.fillRowCross, color: Colors.white }}>
      <div style={{
        ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween,
        border: '1px solid rgba(255, 255, 255, 0.04)',
        borderRadius: 4,
        width: '100%',
        padding: 11,
        position: 'relative',
      }}>
        <div style={{
          fontSize: 12,
          textShadow: isHidden ? '0 0 16px white' : 'none',
          color: isHidden ? 'transparent' : Colors.white,
        }}>
          {value}
        </div>
        {isHidden && !hideInfo &&
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: 12,
            cursor: 'pointer',
          }}
          onClick={() => setIsHidden(false)}
        >
          <i className="fa-light fa-lock" style={{ marginRight: 6, fontSize: 13 }} />
          {t('click_to_copy')}
        </div>
        }
        <HoverIcon
          icon="fa-solid fa-eye"
          color={Colors.white}
          hoverColor={Colors.halfWhite}
          style={{ fontSize: 13 }}
          onClick={() => setIsHidden(!isHidden)}
        />
      </div>
      <HoverIcon
        icon="fa-light fa-copy"
        color={Colors.white}
        hoverColor={Colors.halfWhite}
        style={{ marginLeft: 13 }}
        onClick={onClick}
      />
    </div>
  );
};

export default HiddenElement;