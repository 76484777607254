import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

import Helpers from '../../theme/Helpers';
import CustomTooltip from '../Tooltip/CustomTooltip';
import Colors from '../../theme/Colors';
import ProfileOverviewRecentActivity from './ProfileOverviewRecentActivity';
import ProfilePrivacyPopup from './ProfilePrivacyPopup';

const useStyles = makeStyles(() => ({
  badgeBox: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    padding: 16,
    ...Helpers.fillRowCross,
    ...Helpers.mainSpaceBetween,
    marginTop: 8,
  },
  openText: {
    fontSize: 14,
    color: 'rgba(255, 255, 255, 0.5)',
    cursor: 'pointer',
    transition: 'all 0.3s ease-out',
    '&:hover': {
      color: Colors.white,
    },
  },
}));

const ProfileOverviewRecentActivities = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { activities, viewer, isMe, setActivitiesPrivacySettings, activePrivacySettings } = props;
  const [privacyDialogVisible, setPrivacyDialogVisible] = useState(false);

  return (
    <>
      {
        privacyDialogVisible &&
        <ProfilePrivacyPopup 
          t={t} 
          closeClickHandler={() => setPrivacyDialogVisible(false) }
          activePrivacySettings={activePrivacySettings}
          setActivitiesPrivacySettings={setActivitiesPrivacySettings}
        />
      }

      <div style={{
        ...Helpers.fillRowCenter,
        ...Helpers.mainSpaceBetween,
        marginTop: 17,
        marginBottom: 10,
      }}>
        <div>
          <i className="fa-regular fa-arrow-rotate-right" style={{ marginRight: 10 }} />
          {t('recent_activities')}
        </div>
        {isMe &&
        <CustomTooltip title={t('privacy_settings_description')} arrow>
          <div className={classes.openText} onClick={(e) => setPrivacyDialogVisible(true)}>
            <i className="fa-regular fa-lock-keyhole-open" style={{ marginRight: 8 }} />
            {t('privacy_settings')}
          </div>
        </CustomTooltip>
        }
      </div>

      {activities && activities.sort((a, b) => b.created_at - a.created_at).map(activity => (
        <ProfileOverviewRecentActivity activity={activity} viewer={viewer} key={`ActivityKey${activity.created_at}`} />
      ))}
    </>
  );
};

export default ProfileOverviewRecentActivities;