import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { replaceWidthAndHeight } from '../../utils/helper';
import Colors from '../../theme/Colors';
import { returnMobileObj } from '../../utils/responsive';

const useStyles = makeStyles({
  card: {
    cursor: 'pointer',
    backgroundColor: '#333333',
    color: Colors.white,
    width: 195,
    margin: 15,
    borderRadius: 0,
    transition: '1s',
    '& img': {
      filter: 'grayscale(100%)',
      transition: '1s',
    },
    '&:hover': {
      backgroundColor: '#b60c29',
      '& img': {
        filter: 'none',
      },
    },
  },
  cardImage: {
    height: 270,
    width: 195,
  },
  cardText: {
    padding: '15px 20px',
    textTransform: 'uppercase',
    fontSize: 15,
    fontWeight: 'bold',
  },
});

const GameLiveStreamListItem = (props) => {
  const classes = useStyles();
  const { game, isTabletOrMobile } = props;
  const height = 270, width = 195;
  const imgSrc = replaceWidthAndHeight(game.game.box_art_url, width, height);

  const redirectToGame = () => {
    props.history.push('/hq/game-streams/' + encodeURIComponent(game.game.name));
  };

  return (
    <div
      className={classes.card}
      onClick={redirectToGame}
      style={returnMobileObj(isTabletOrMobile, { margin: 8 })}
    >
      <img src={imgSrc} alt={game.game.name} className={classes.cardImage} />
      <div className={classes.cardText}>
        {game.game.name}
      </div>
    </div>
  );
};

export default withRouter(GameLiveStreamListItem);