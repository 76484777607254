import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import { getAllBadges, getMyNotifications } from '../../apollo/queries';
import { setNotificationRead as setNotificationReadMutation } from '../../apollo/mutations';

import EventPopup from './EventPopup';
import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import { getRandomInt } from '../../utils/helper';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import { returnMobileObj } from '../../utils/responsive';
import Typography from '@material-ui/core/Typography';
import CustomButton from '../Button/CustomButton';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { removePopupEvent } from '../../redux/actions';
import { connect } from 'react-redux';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const conf = require('../../conf');

const useStyles = makeStyles(() => ({
  area: {
    color: Colors.white,
    ...Helpers.fillColCenter,
    '& > img': {
      margin: '20px 0',
      transform: 'rotate(5deg)',
    },
  },
  headTitle: {
    fontFamily: 'Bebas Neue',
    fontSize: 50,
    margin: 0,
    zIndex: 2,
  },
  subTitle: {
    fontSize: 24,
    marginBottom: 10,
    color: Colors.pink,
    zIndex: 2,
  },
  video: {
    position: 'absolute',
    zIndex: 1,
  },
  XPBadge: {
    position: 'absolute',
    fontWeight: 'bold',
    left: '50%',
    top: '50%',
    zIndex: 2,
    width: 134,
    height: 146,
    transform: 'translate(-50%, -50%)',
    color: Colors.white,
    backgroundImage: `url("${process.env.PUBLIC_URL}/badges/xp_icon_big.png")`,
    ...Helpers.textCenter,
    ...Helpers.fillRowCenter,
  },
  headerTitle: {
    textTransform: 'none',
    fontFamily: 'Bebas Neue',
    fontSize: 30,
    fontWeight: 400,
    lineHeight: 1,
    '& > span': {
      color: '#361215',
    },
  },
  header: {
    background: '#7BB01C',
    padding: 10,
    fontFamily: 'Bebas Neue',
    fontSize: 30,
    color: Colors.white,
    ...Helpers.textCenter,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  body: {
    padding: 30,
    background: '#01132B',
    color: '#5C7291',
    position: 'relative',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  bodyContent: {
    margin: '40px 0',
    fontFamily: 'Bebas Neue',
    fontSize: 20,
    '& > div': {
      color: Colors.white,
      fontSize: 40,
      marginBottom: 15,
    },
    '& > div:not(:first-child)': {
      marginTop: 30,
    },
    '& > div:last-child': {
      marginBottom: 0,
    },
    '& span > span': {
      color: Colors.white,
    },
  },
  imageArea: {
    border: '1px solid #08111F',
    padding: '5px 15px',
    borderRadius: '50%',
    background: 'radial-gradient(#18213a, #0c121f)',
    boxShadow: '0 0 10px 10px #131c37',
  },
}));

const EventPopupWrapper = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { event, removePopupEvent } = props;
  const video = useRef(null);
  const fgVideo = useRef(null);
  const getAllBadgesQuery = useQuery(getAllBadges, {
    skip: event.key !== 'new-badge',
    fetchPolicy: useCacheWithExpiration('getAllBadges'),
  });

  const [setNotificationRead] = useMutation(setNotificationReadMutation, {
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{
      query: getMyNotifications,
      variables: { sorting: { field: 'created_at', order: 'DESC' } },
    }],
  });

  useEffect(() => {
    if (video.current) {
      let url, fgUrl;
      if (event.key === 'card' || event.key === 'sh-card') {
        url = `${conf.cdn_url}/assets/animations/sh-roll-alert-bg-lvl${event.value.level ? event.value.level : 3}.webm`;
        if ((event.key === 'sh-card' && event.value.withEffect) || event.key === 'card')
          fgUrl = `${conf.cdn_url}/assets/animations/sh-roll-alert-fg-lvl${event.value.level ? event.value.level : 3}.webm`;
      } else if (event.key === 'new-badge') {
        const random = getRandomInt(3);
        url = `${conf.cdn_url}/assets/animations/reward-alert-v${random}.webm`;
      } else if (event.key === 'xp-boost') {
        url = `${conf.cdn_url}/assets/animations/sh-roll-xp-bg.webm`;
      }
      video.current.src = url;
      const promise = video.current.play();

      if (promise !== undefined) {
        promise.then(_ => {
          // Autoplay started!
        })
        .catch(error => {
          // Autoplay was prevented.
        });
      }

      if (fgUrl && fgVideo.current) {
        fgVideo.current.src = fgUrl;
        const promise = fgVideo.current.play();

        if (promise !== undefined) {
          promise.then(_ => {
            // Autoplay started!
          })
          .catch(error => {
            // Autoplay was prevented.
          });
        }
      }
    }
  });

  const handleClosingEvent = () => {
    if (event.value.notificationId)
      setNotificationRead({ variables: { id: event.value.notificationId } });
  };

  let output;
  if (event.key === 'card') {
    const { value } = event;
    const { match } = value;

    output =
      <>
        <video loop className={classes.video} ref={video} />
        <div style={{ position: 'relative', zIndex: 2 }}>
          <div className={classes.area}>
            <div className={classes.headTitle}>
              {t('new_card')}
            </div>
            <div className={classes.subTitle}>
              {match.display_name ? match.display_name : match.name} {value.level && <>Lvl {value.level}</>}
            </div>
            <div>
              <img
                style={{ margin: '15px 0', transform: 'rotate(6.4deg)', borderRadius: 4 }}
                src={`${value.image}?height=310`}
                alt={match.login ? match.login : match.name}
              />
            </div>
          </div>
        </div>
        <video loop className={classes.video} ref={fgVideo} style={{ zIndex: 3 }} />
      </>;
  } else if (event.key === 'sh-card') {
    const { value } = event;

    output =
      <>
        <video loop className={classes.video} ref={video} />
        <div style={{ position: 'relative', zIndex: 2 }}>
          <div className={classes.area}>
            <div className={classes.headTitle}>
              {t('new_card')} {value.level && <>Lvl {value.level}</>}
            </div>
            <div style={{ width: 201, height: 345 }}>
              <img
                style={{ margin: '15px 0', transform: 'rotate(6.4deg)', borderRadius: 4 }}
                src={`${value.image}?height=310`}
                alt="SH Card"
              />
            </div>
          </div>
        </div>
        <video loop className={classes.video} ref={fgVideo} style={{ zIndex: 3 }} />
      </>;
  } else if (event.key === 'new-level') {
    const { value } = event;

    output =
      <div className={classes.area}>
        <div className={classes.headTitle}>
          {t('level_up')}
        </div>
        <div className={classes.subTitle}>
          {t('level', { level: value.level })}
        </div>
        <div>
          <img
            src={value.userImage}
            alt="User image"
            style={{
              width: 200,
              height: 200,
              margin: '30px 0',
              borderRadius: '50%',
              border: '3px solid #1a071d',
              background: '#1a071d',
              boxShadow: '0 0 0 10px #b60b28',
            }}
          />
        </div>
      </div>;
  } else if (event.key === 'new-badge') {
    const { value } = event;
    const { data } = getAllBadgesQuery;
    let imgUrl;
    if (data && data.getAllBadges) {
      const badges = data.getAllBadges;

      const type = value.title.includes('viewer_') ? 'viewer' : 'streamer';
      badges[type].map(badgeCategory => badgeCategory.badges.map(badge => {
        if (badge.title === value.title.replace('_notification_title', ''))
          imgUrl = badge.pic_url;
      }));
    }

    output =
      <div className={classes.area}>
        <div className={classes.headTitle}>
          {t('new_badge')}
        </div>
        <div className={classes.subTitle}>
          {t(value.title)}
        </div>
        <div style={{ position: 'relative' }}>
          <video
            loop
            className={classes.video}
            ref={video}
            style={{
              top: 0,
              left: 0,
              transform: 'translate(-34.3%, -32.3%)',
              width: 740,
              height: 740,
            }}
          />
          {imgUrl &&
          <img
            src={`${imgUrl}?height=200`}
            alt="Badge image"
            style={{
              margin: '30px 0',
            }}
          />
          }
        </div>
      </div>;
  } else if (event.key === 'xp-boost') {
    const { value } = event;

    output =
      <>
        <video loop className={classes.video} ref={video} />
        <div className={classes.XPBadge}>
          {value} XP
        </div>
        <div style={{ height: 500 }} />
      </>;
  } else if (event.key === 'all-event-cards-collected') {
    const { value } = event;
    const { isTabletOrMobile, title, image, content } = value;

    output = (
      <div style={{
        boxShadow: '0px 11px 59px 0px rgba(0, 0, 0, 0.56)',
        position: 'relative',
        marginBottom: 40,
      }}>
        <FontAwesomeIcon
          icon={faTimes}
          size="2x"
          color={Colors.white}
          style={{ position: 'absolute', right: 10, top: 10, cursor: 'pointer' }}
          onClick={removePopupEvent}
        />
        <div
          className={classes.header}
          style={returnMobileObj(isTabletOrMobile, {
            ...Helpers.column,
            ...Helpers.crossStart,
            padding: 5,
          })}
        >
          <div>
            <Typography
              variant="h1"
              className={classes.headerTitle}
              style={returnMobileObj(isTabletOrMobile, { fontSize: 24 })}
            >
              {t(title)}
            </Typography>
          </div>
        </div>

        <div
          className={classes.body}
          style={returnMobileObj(isTabletOrMobile, { padding: 10 })}
        >
          <div
            style={{
              ...{ ...Helpers.fillColCenter, ...Helpers.textCenter },
              ...Helpers.mainSpaceBetween,
              ...(returnMobileObj(isTabletOrMobile, { ...Helpers.column })),
            }}
          >
            <div className={classes.imageArea}>
              <img
                src={image} alt={title}
                style={returnMobileObj(isTabletOrMobile, { width: '80%' })}
              />
            </div>
            <div
              className={classes.bodyContent}
              dangerouslySetInnerHTML={{ __html: t(content) }}
            />

            <CustomButton
              status="green"
              onClick={() => {
                props.history.push(`/card-shop?offer=${conf.offer_id}`);
                removePopupEvent();
              }}
              style={{ padding: '0px 30px' }}
            >
              {t('next')}
            </CustomButton>
          </div>
        </div>
      </div>
    );
  }

  return (
    <EventPopup
      handleClose={handleClosingEvent}
      hideConfirm={event.key === 'all-event-cards-collected'}
    >
      {output}
    </EventPopup>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    removePopupEvent,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(withRouter(EventPopupWrapper));