import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import ImageCarousel from '../Carousel/ImageCarousel';
import TopBG from '../TopViewHeader/TopBG';
import Colors from '../../theme/Colors';
import Loading from '../Loading/Loading';
import Helpers from '../../theme/Helpers';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';

import conf from '../../conf.json';
import AnimationButton from '../Button/AnimationButton';
import BoosterManagementBooster from './BoosterManagementBooster';
import BoosterDecisionPopup from './BoosterDecisionPopup';
import BoosterOrderPopup from './BoosterOrderPopup';

const useStyles = makeStyles({
  orderLine: {
    background: 'rgba(0, 255, 85, 0.11)',
    borderRadius: 6,
    padding: 20,
    margin: '20px 0',
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
  },
  addNewItem: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    width: 246,
    height: 356,
    margin: '20px 8px',
    cursor: 'pointer',
    ...Helpers.fillColCenter,
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.08)',
    },
  },
});

const BoosterManagement = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { boosters, loading, approvedBooster, countryCosts } = props;
  const [showNewBooster, setShowNewBooster] = useState(false);
  const [showBoosterOrdering, setShowBoosterOrdering] = useState(false);

  const images = [
    {
      img: `${conf.cdn_url}/assets/slider/item-store-slider-step-00-bg.png`,
      header: 'booster_management_slider1_header',
      body: 'booster_management_slider1_body',
    },
    {
      img: `${conf.cdn_url}/assets/slider/item-store-slider-step-00-bg.png`,
      header: 'booster_management_slider2_header',
      body: 'booster_management_slider2_body',
    },
  ];

  return (
    <>
      <TopBG
        disableBreadcrumbs
        style={{
          padding: '30px 0 20px',
          backgroundSize: 'auto',
          color: Colors.white,
        }}
      >
        <div style={{ fontFamily: 'Bebas Neue', fontSize: 36, marginBottom: 30 }}>
          {t('booster_creation')}
        </div>

        <ImageCarousel images={images} />

        {loading ?
          <Loading />
          :
          (approvedBooster && approvedBooster.length > 0) &&
          <CustomBackdropFilter
            className={classes.orderLine}
            filter={'blur(50px)'}
            canvasFallback={true}
            html2canvasOpts={{
              allowTaint: true,
            }}
          >
            <div>
              <div>
                {t('order_your_physical_booster')}
              </div>
              <div style={{ fontSize: 14, color: Colors.halfWhite }}>
                {t('select_the_booster_you_want_to_order')}
              </div>
            </div>

            <AnimationButton
              status="whiteTransparency"
              style={{ padding: '11px 48px' }}
              onClick={() => setShowBoosterOrdering(true)}
            >
              {t('order_your_booster')}
            </AnimationButton>
          </CustomBackdropFilter>
        }

        <div style={{ ...Helpers.fillRowCross, flexWrap: 'wrap', margin: '0 -8px' }}>
          {boosters && boosters.map((booster) => (
            <BoosterManagementBooster key={`BoosterKey${booster._id}`} booster={booster} />
          ))}
          <div className={classes.addNewItem} onClick={() => setShowNewBooster(true)}>
            <div style={{
              background: 'rgba(255, 255, 255, 0.06)',
              width: 40,
              height: 40,
              ...Helpers.fillColCenter,
              borderRadius: 6,
            }}>
              <i className="fa-regular fa-plus" />
            </div>
            <div style={{ fontSize: 14, marginTop: 12 }}>
              {t('add_new_booster')}
            </div>
          </div>
        </div>
      </TopBG>
      {showNewBooster &&
      <BoosterDecisionPopup handleClose={() => setShowNewBooster(false)} />
      }
      {showBoosterOrdering &&
      <BoosterOrderPopup
        booster={approvedBooster}
        handleClose={() => setShowBoosterOrdering(false)}
        countryCosts={countryCosts}
      />
      }
    </>
  );
};

export default BoosterManagement;