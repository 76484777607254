import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';

import { marketPlaceOffers } from '../../apollo/mergedQueries';

import PageTitle from '../../components/PageTitle/PageTitle';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import MarketplaceMarket from '../../components/Marketplace/MarketplaceMarket';

const MarketplaceMarketContainer = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(2);
  const [isSeasonal, setIsSeasonal] = useState(false);
  const [sorting, setSorting] = useState({
    field: 'created_at',
    order: 'DESC',
  });
  const [quality, setQuality] = useState({
    min: 0,
    max: 100,
  });
  const [rarity, setRarity] = useState([]);
  const [match, setMatch] = useState(null);
  const { id } = useParams();
  const perPage = 25;

  const variables = {
    pagination: { page: 1, perPage },
    match: match ? match : (id ? id : null),
    is_seasonal: isSeasonal,
    sorting,
    rarity,
    min_quality: quality.min,
    max_quality: quality.max,
  };

  const { data, loading, fetchMore } = useQuery(marketPlaceOffers, {
    variables,
    fetchPolicy: useCacheWithExpiration('marketPlaceOffers', variables),
  });

  const items = data && data.marketPlaceOffers;
  const myViewer = data && data.getMyViewer;
  const matches = data && data.marketPlaceOffersMatches;

  return (
    <>
      <PageTitle title={t('marketplace')} />
      <MarketplaceMarket
        loading={loading}
        offers={items && items.offers}
        setSorting={setSorting}
        sorting={sorting}
        setRarity={setRarity}
        rarity={rarity}
        setQuality={setQuality}
        quality={quality}
        setIsSeasonal={setIsSeasonal}
        isSeasonal={isSeasonal}
        totalCount={items && items.count}
        myViewer={myViewer}
        matches={matches}
        match={match}
        setMatch={setMatch}
        onLoadMore={async () => {
          setPage(page + 1);
          try {
            await fetchMore({
              variables: { ...variables, ...{ pagination: { perPage, page } } },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return {
                  marketPlaceOffers: {
                    offers: [...prev.marketPlaceOffers.offers, ...fetchMoreResult.marketPlaceOffers.offers],
                    __typename: prev.marketPlaceOffers.__typename,
                  },
                };
              },
            });
          } catch {
          }
        }}
      />
    </>
  );
};

export default MarketplaceMarketContainer;