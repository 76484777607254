import React from 'react';
import { Query } from 'react-apollo';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';

import { getStatsForTimeFrame } from '../../apollo/queries';

import Loading from '../../components/Loading/Loading';
import KPIs from '../../components/KPIs/KPIs';

const KPIsContainer = () => {
  const { t } = useTranslation();

  const [startDate, handleStartDate] = React.useState(
    moment()
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .subtract(15, 'days'),
  );
  const [endDate, handleEndDate] = React.useState(
    moment()
    .hour(23)
    .minute(59)
    .second(59)
    .subtract(1, 'days'),
  );

  return (
    <>
      <DatePicker
        style={{ marginRight: 20 }}
        disableFuture
        autoOk
        format="DD.MM.YYYY"
        value={startDate}
        onChange={handleStartDate}
        maxDate={endDate}
        label={t('start_date')}
      />
      <DatePicker
        disableFuture
        autoOk
        format="DD.MM.YYYY"
        value={endDate}
        onChange={handleEndDate}
        minDate={startDate}
        maxDate={moment()
        .subtract(1, 'days')}
        label={t('end_date')}
      />
      <Query query={getStatsForTimeFrame} variables={{
        startDate,
        endDate,
      }}>
        {({ data, loading, error }) => {
          if (loading) return <Loading />;
          if (error) return error.message;

          const stats = (data) && data.getStatsForTimeframe;

          return (
            <KPIs stats={stats} />
          );
        }}
      </Query>
    </>
  );
};

export default KPIsContainer;