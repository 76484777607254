import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { getMyViewerAndStreamer } from '../../apollo/mergedQueries';
import { makeMeAStreamhero as makeMeAStreamHeroMutation } from '../../apollo/mutations';

import AlreadyStreamhero from './AlreadyStreamhero';
import PotentialStreamhero from './PotentialStreamhero';

const StreamerSettings = (props) => {
  const { streamer, viewer } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [makeMeAStreamHero] = useMutation(makeMeAStreamHeroMutation, {
    onCompleted: () => {
      enqueueSnackbar(t('make_me_a_streamhero_success'), { variant: 'success' });
      window.dataLayer.push({
        event: 'Sign up as a Streamer',
      });
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{ query: getMyViewerAndStreamer }],
  });

  return (
    <div>
      {streamer ?
        <AlreadyStreamhero streamer={streamer} makeMeAStreamHero={makeMeAStreamHero} />
        :
        <PotentialStreamhero makeMeAStreamHero={makeMeAStreamHero} viewer={viewer} />
      }
    </div>
  );
};

export default StreamerSettings;