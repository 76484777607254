import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import AnimationButton from '../Button/AnimationButton';
import CustomTooltip from '../Tooltip/CustomTooltip';
import { getLeagueImage, getLevelWord, toNearest } from '../../utils/helper';
import { setAlertToolAllData } from '../../redux/actions';

import conf from '../../conf.json';
import { Checkbox } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  badge: {
    padding: '2px 6px',
    borderRadius: 2,
    display: 'inline-block',
    fontWeight: 500,
    '&.green': {
      color: '#15CC90',
      background: 'rgba(21, 204, 144, 0.08)',
    },
    '&.red': {
      color: '#FF3141',
      background: 'rgba(255, 49, 65, 0.08)',
    },
  },
  editButton: {
    border: 'none',
    padding: 8,
    fontSize: 13.5,
  },
  checkboxRoot: {
    padding: 0,
    marginLeft: 18,
  }
}));

const AlertGroupLine = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { alertSetting, selectedSettings, setSelectedSettings, leagueKeys } = props;

  const getActiveState = (isActive, isYesNo = false) => (
    <div className={[classes.badge, isActive ? 'green' : 'red'].join(' ')}>
      {t(isYesNo ? (isActive ? 'yes' : 'no') : (isActive ? 'active' : 'inactive'))}
    </div>
  );

  const getRarityState = (rarity, hasNext) => {
    const levelColor = Colors.level[getLevelWord('Card', rarity)];

    return (
      <div style={{ color: levelColor }} key={`RarityState${rarity}`}>
        <img
          src={`${conf.cdn_url}/badges/card-level/${rarity}.png?height=18`}
          alt="Level badge"
          style={{ marginRight: 6, verticalAlign: 'bottom' }}
        />
        {t(`rareness_level.${rarity}`)}
        {hasNext && <span style={{ color: Colors.white, marginRight: 8 }}>,</span>}
      </div>
    );
  };

  const getLeagueState = (league, hasNext) => {
    if (!leagueKeys)
      return null;

    const leagueKey = leagueKeys[league - 1];

    return (
      <div key={`LeagueState${league}`}>
        <img src={getLeagueImage(leagueKey, 18)} alt="League graphic"
             style={{ marginRight: 6, verticalAlign: 'bottom' }} />
        {t(`rank_${leagueKey}`)}
        {hasNext && <span style={{ color: Colors.white, marginRight: 8 }}>,</span>}
      </div>
    );
  };

  const updateAlert = () => {
    props.history.push('/streamer-settings/alerts/update');

    props.setAlertToolAllData({
      id: alertSetting._id,
      active: alertSetting.active,
      type: alertSetting.type,
      cardsAmount: alertSetting.purchase_min_amount_cards,
      cardRarity: alertSetting.card_rarity,
      communityRankUp: alertSetting.community_rank_up,
      minQuality: alertSetting.min_card_quality,
      maxQuality: alertSetting.max_card_quality,
      showTwitch: alertSetting.show_on_twitch,
      showDiscord: alertSetting.show_on_discord,
      twitchSettings: alertSetting.twitch_settings,
      discordSettings: alertSetting.discord_settings,
      // showStyleSettings: null,
    });
  };

  const handleChange = () => {
    const tmpSelected = [...selectedSettings];
    const index = tmpSelected.findIndex(cItem => cItem === alertSetting._id);
    if (index === -1)
      tmpSelected.push(alertSetting._id);
    else
      tmpSelected.splice(index, 1);

    setSelectedSettings(tmpSelected);
  };

  return (
    <tr>
      <td>
        <Checkbox
          checked={selectedSettings.includes(alertSetting._id)}
          onChange={handleChange}
          classes={{
            root: classes.checkboxRoot
          }}
        />
      </td>
      <td>
        <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceEvenly }}>
          <CustomTooltip title={t('edit')}>
            <div>
              <AnimationButton
                status="transparent"
                className={classes.editButton}
                onClick={updateAlert}
              >
                <i className="fa-light fa-pencil" style={{ fontSize: 16 }} />
              </AnimationButton>
            </div>
          </CustomTooltip>
        </div>
      </td>
      <td>
        {getActiveState(alertSetting.active)}
      </td>
      {alertSetting.purchase_min_amount_cards &&
      <td>
        {alertSetting.purchase_min_amount_cards}
      </td>
      }
      {alertSetting.community_rank_up && alertSetting.community_rank_up.length > 0 &&
      <td>
        <div style={{ ...Helpers.fillRow }}>
          {alertSetting.community_rank_up.map((league, index) => getLeagueState(league, index < alertSetting.community_rank_up.length - 1))}
        </div>
      </td>
      }
      {alertSetting.card_rarity && alertSetting.card_rarity.length > 0 &&
      <td>
        <div style={{ ...Helpers.fillRow }}>
          {alertSetting.card_rarity.map((rarity, index) => getRarityState(rarity, index < alertSetting.card_rarity.length - 1))}
        </div>
      </td>
      }
      {(alertSetting.min_card_quality && alertSetting.max_card_quality) &&
      <td>
        <div style={{ ...Helpers.fillRowCross }}>
          <div style={{ color: Colors.quality[toNearest(alertSetting.min_card_quality, 25)] }}>
            {alertSetting.min_card_quality}%
          </div>
          <div
            style={{
              marginLeft: alertSetting.min_card_quality > 10 ? 32 : 40,
              marginRight: alertSetting.max_card_quality > 10 ? 32 : 40,
            }}
          >
            -
          </div>
          <div style={{ color: Colors.quality[toNearest(alertSetting.max_card_quality, 25)] }}>
            {alertSetting.max_card_quality}%
          </div>
        </div>
      </td>
      }
      <td>{getActiveState(alertSetting.show_on_twitch, true)}</td>
      <td>{getActiveState(alertSetting.show_on_discord, true)}</td>
    </tr>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setAlertToolAllData,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(withRouter(AlertGroupLine));