import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import QualityLine from '../QualityLine/QualityLine';

import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  valueBox: {
    width: 250,
    height: 102,
    borderRadius: 6,
    background: 'rgba(255, 255, 255, 0.1)',
    ...Helpers.fillColCenter,
    marginBottom: 8,
  },
}));

const CardExplanationCompact = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <div style={{ fontSize: 20, fontWeight: 500, color: Colors.white, marginBottom: 12 }}>
        1. {t('how_the_values_are_created')}
      </div>
      <div style={{
        fontSize: 16,
        fontWeight: 200,
        color: Colors.halfWhite,
        marginBottom: 42,
      }}>
        {t('how_the_values_are_created_body')}
      </div>
      <div style={{ fontSize: 16, fontWeight: 400, color: Colors.white, marginBottom: 14 }}>
        1.1 {t('value_composition')}
      </div>
      <div style={{
        marginBottom: 34, ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween,
        color: Colors.white, fontWeight: 300,
      }}>
        <div>
          <div className={classes.valueBox}>
            <img
              src={`${conf.cdn_url}/badges/card-level/5.png?height=29`}
              alt="Level badge"
            />
          </div>
          <div style={Helpers.textCenter}>
            {t('rareness')}
          </div>
        </div>

        <div style={{ marginTop: -25 }}>
          <i className="fa-solid fa-plus" style={{ fontSize: 21 }} />
        </div>

        <div>
          <div className={classes.valueBox}>
            <div style={{ marginTop: 10, width: '95%' }}>
              <QualityLine quality={82} spaceBetween={1} width={[9, 36, 55, 18, 6]} hasNoText />
            </div>
          </div>
          <div style={Helpers.textCenter}>
            {t('quality')}
          </div>
        </div>
      </div>
      <div style={{
        fontSize: 16,
        fontWeight: 200,
        color: Colors.halfWhite,
      }}>
        {t('value_composition_body')}
      </div>
    </>
  );
};

export default CardExplanationCompact;