import amplitude from 'amplitude-js';

export const logAmplitudeEvent = (name, eventProperties = null) => {
  try {
    amplitude.getInstance()
    .logEvent(name, eventProperties);
  } catch (err) {
  }
};

export const logAmplitudeRevenue = (productId, price, quantity, eventProperties = null) => {
  try {
    let revenue = new amplitude.Revenue().setProductId(productId)
    .setPrice(price)
    .setQuantity(quantity)
    .setEventProperties(eventProperties);
    amplitude.getInstance()
    .logRevenueV2(revenue);
  } catch (err) {
  }
};