import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';

const SVGIcon = (props) => {
  const { src, size, fill, hoverFill, style, height, width } = props;

  const cssClasses = {
    svgIcon: {
      fill,
      width: size || width,
      height: size || height,
    },
  };

  if (hoverFill)
    cssClasses.svgIcon['&:hover'] = {
      fill: hoverFill,
    };

  const useStyles = makeStyles(() => (cssClasses));

  const classes = useStyles();

  return (
    <ReactSVG
      style={{ ...{ height: size || height }, ...style }}
      src={src}
      beforeInjection={svg => {
        svg.classList.add(classes.svgIcon);
      }}
    />
  );
};

SVGIcon.propTypes = {
  style: PropTypes.object,
};

SVGIcon.defaultProps = {
  style: {},
};

export default SVGIcon;