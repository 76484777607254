import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { isBrowser } from 'react-device-detect';

import { getCurrentOffer } from '../../apollo/queries';
import { myCollectedPointsForTypeWithMyViewer } from '../../apollo/mergedQueries';

import CardsContainer from '../../containers/Cards/Cards';
import OrganisationMember from './OrganisationMember';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import Offer from '../Offer/Offer';
import TopBG from '../TopViewHeader/TopBG';
import OrganisationHeader from './OrganisationHeader';
import { returnMobileObj } from '../../utils/responsive';
import HeaderLine from '../TopViewHeader/HeaderLine';
import DropdownMenuWithButton from '../DropdownMenu/DropdownMenuWithButton';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

import conf from '../../conf.json';

const useStyles = makeStyles({
  wrapper: {
    color: Colors.white,
    padding: '0 60px',
    maxWidth: 1440,
    margin: '0 auto',
    marginTop: 20,
  },
  middleContainer: {
    marginTop: 30,
    padding: 20,
    ...Helpers.boxBasics,
    ...Helpers.fillRow,
    textAlign: 'center',
    flexWrap: 'wrap',
    overflow: 'hidden',
    transition: 'all 0.5s ease-out',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  expandButton: {
    backgroundColor: Colors.wrapperLight,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    padding: 5,
    ...Helpers.textCenter,
    cursor: 'pointer',
    marginBottom: 20,
    '&:hover': {
      backgroundColor: Colors.wrapperDark,
    },
  },
  headerLine: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
  },
  buttonArea: {
    ...Helpers.fillRowCenter,
    position: 'relative',
  },
  cardsContainer: {
    marginTop: 40,
    background: Colors.grayBlackGradient,
    '& .MuiCard-root': {
      margin: '0 25px 20px',
      padding: 15,
    },
    '& .special-redeem-button': {
      width: 190,
    },
  },
  mobileContainer: {
    '& .MuiCard-root': {
      margin: '0 0 20px',
      padding: 10,
    },
  },
});

const Organisation = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { organisation, memberStreams, main, isTabletOrMobile } = props;

  const [tab, setTab] = useState(organisation.edition_to_show ? 'special' : 'organisation');
  const [specialTab, setSpecialTab] = useState(organisation.edition_to_show);
  const [specialTabs, setSpecialTabs] = useState(null);
  const [expandMembers, setExpandMembers] = useState(false);
  const { isLoggedIn } = main;

  const { data } = useQuery(myCollectedPointsForTypeWithMyViewer, {
    variables: { organisation: organisation._id },
    pollInterval: 240000,
    fetchPolicy: useCacheWithExpiration('myCollectedPointsForTypeWithMyViewer', { organisation: organisation._id }),
    skip: !isLoggedIn || !organisation,
  });

  const getCurrentOfferQuery = useQuery(getCurrentOffer, {
    variables: { matchModel: 'Organisation', match: organisation._id },
    fetchPolicy: useCacheWithExpiration('getCurrentOffer', {
      matchModel: 'Organisation',
      match: organisation._id,
    }),
    skip: !conf.offer_exists,
  });

  const myCollectedPoints = (data) && data.viewerRedeemedPoints;
  const myViewer = (data) && data.getMyViewer;
  const currentOffer = (getCurrentOfferQuery.data) && getCurrentOfferQuery.data.getCurrentOffer;

  const memberAreaStyles = {};
  if (organisation.hasOwnDesign)
    memberAreaStyles.backgroundColor = '#ed2024';
  if (!isBrowser)
    memberAreaStyles.padding = 10;
  if (expandMembers)
    memberAreaStyles.height = 'auto';
  else {
    memberAreaStyles.minHeight = 100;
    memberAreaStyles.maxHeight = 335;
  }

  const tabs = [
    {
      value: t('cards_organisation'),
      key: 'organisation',
    },
    {
      value: t('cards_special'),
      key: 'special',
    },
    {
      value: t('cards_archived'),
      key: 'archive',
    },
  ];

  const cardsContainerClasses = [classes.cardsContainer];
  if (isTabletOrMobile)
    cardsContainerClasses.push(classes.mobileContainer);

  return (
    <>
      <TopBG
        background={`${conf.cdn_url}/assets/template/purple_bg.png`}
        isTabletOrMobile={isTabletOrMobile}
      >
        <OrganisationHeader
          organisation={organisation}
          isTabletOrMobile={isTabletOrMobile}
          myCollectedPoints={myCollectedPoints}
          myViewer={myViewer}
        />
      </TopBG>
      <div
        className={classes.wrapper}
        style={returnMobileObj(isTabletOrMobile, { padding: '0 10px' })}
      >
        <div
          className={classes.headerLine}
          style={returnMobileObj(isTabletOrMobile, { ...Helpers.fillCol, ...Helpers.crossStart })}
        >
          <HeaderLine
            title={t('organisation_collect_cards', { organisation: organisation.name })}
            subTitle={t('organisation_collect_cards_subtitle')}
            isTabletOrMobile={isTabletOrMobile}
          />
          <div
            className={classes.buttonArea}
            style={returnMobileObj(isTabletOrMobile, { margin: '0 auto' })}
          >
            {(specialTabs && tab === 'special') &&
            <DropdownMenuWithButton
              tabs={specialTabs}
              startTab={specialTab ? specialTab : specialTabs[0].key}
              onClick={(key) => setSpecialTab(key)}
            />
            }
            <DropdownMenuWithButton
              tabs={tabs}
              startTab={organisation.edition_to_show ? 'special' : 'organisation'}
              onClick={(key) => setTab(key)}
            />
          </div>
        </div>
        <div
          className={cardsContainerClasses.join(' ')}
          style={returnMobileObj(isTabletOrMobile, { zoom: 0.8, marginTop: 10 })}
        >
          <CardsContainer
            streamer={null}
            organisation={organisation}
            isSpecial={tab === 'special'}
            isArchived={tab === 'archive'}
            myCollectedPoints={myCollectedPoints}
            specialTab={specialTab}
            setSpecialTab={setSpecialTab}
            specialTabs={specialTabs}
            setSpecialTabs={setSpecialTabs}
            setTab={setTab}
          />
        </div>
        {currentOffer && <Offer offer={currentOffer} />}
        <div
          className={classes.middleContainer}
          style={memberAreaStyles}
        >
          {memberStreams.map(memberStream =>
            <OrganisationMember
              key={`OrganisationMember#${memberStream._id}`}
              memberStream={memberStream}
              hasOwnDesign={organisation.hasOwnDesign}
            />,
          )}
        </div>
        {memberStreams.length > 5 &&
        <div
          className={classes.expandButton}
          onClick={() => setExpandMembers(!expandMembers)}
        >
          {expandMembers ? t('shrink_area') : t('expand_area')}
        </div>
        }
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { main } = state;
  return { main };
};

export default connect(mapStateToProps)(Organisation);