import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  iconDefault: {
    width: '100%',
  },
  iconHover: {
    width: '100%',
  },
  iconActive: {
    width: '100%',
  },

  wrapper: {
    '& $iconDefault': {
      display: 'block',
    },
    '& $iconHover': {
      display: 'none',
    },
    '& $iconActive': {
      display: 'none',
    },
    '&:hover': {
      '& $iconDefault': {
        display: 'none',
      },
      '& $iconHover': {
        display: 'block',
      },
      '& $iconActive': {
        display: 'none',
      },
    },
    '&:active': {
      '& $iconDefault': {
        display: 'none',
      },
      '& $iconHover': {
        display: 'none',
      },
      '& $iconActive': {
        display: 'block',
      },
    },
  },

  wrapperActive: {
    '& $iconDefault': {
      display: 'none',
    },
    '& $iconHover': {
      display: 'none',
    },
    '& $iconActive': {
      display: 'block',
    },
  },
}));


const DuotoneIconBase = (props) => {
  const {
    forceActive = false,
    DefaultIcon,
    HoverIcon,
    ActiveIcon,
  } = props;

  const classes = useStyles();

  return (
    <div className={forceActive ? classes.wrapperActive : classes.wrapper}>
      {React.cloneElement(
        DefaultIcon,
        { className: classes.iconDefault },
      )}

      {React.cloneElement(
        HoverIcon,
        { className: classes.iconHover },
      )}

      {React.cloneElement(
        ActiveIcon,
        { className: classes.iconActive },
      )}
    </div>
  );
};

export default DuotoneIconBase;