import React from 'react';
import DuotoneIconBase from './DuotoneIconBase';

const LeaderboardIcon = (props) => {
  const { forceActive = false } = props;

  return (
    <DuotoneIconBase
      forceActive={forceActive}

      DefaultIcon={
        <svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="0 0 22 22">
          <g>
            <g opacity="0.5">
              <path fill="#FFFFFF" d="M4,9V8.642C2.933,7.317,2.247,5.714,2.056,4H4V2H1C0.448,2,0,2.448,0,3c0,3.588,1.75,6.929,4.682,8.992
                                C4.25,11.084,4,10.071,4,9z" />
            </g>
            <path opacity="0.5" fill="#FFFFFF" d="M21,2h-3v2h1.945C19.756,5.716,19.07,7.319,18,8.645V9c0,1.068-0.246,2.078-0.678,2.985
                            C20.252,9.922,22,6.592,22,3C22,2.448,21.553,2,21,2z" />
            <path opacity="0.5" fill="#FFFFFF" d="M11,16c-0.34,0-0.672-0.033-1-0.08V21c0,0.553,0.447,1,1,1c0.552,0,1-0.447,1-1v-5.08
                            C11.672,15.967,11.34,16,11,16z" />
            <g>
              <path fill="#FFFFFF" d="M11,16c-3.859,0-7-3.141-7-7V1c0-0.553,0.448-1,1-1h12c0.553,0,1,0.447,1,1v8C18,12.859,14.861,16,11,16z
                                M6,2v7c0,2.756,2.244,5,5,5c2.757,0,5-2.243,5-5V2H6z" />
            </g>
            <g>
              <path fill="#FFFFFF" d="M16,22H6c-0.552,0-1-0.447-1-1c0-0.552,0.448-0.999,1-0.999h10c0.553,0,1,0.447,1,0.999
                                C17,21.553,16.553,22,16,22z" />
            </g>
          </g>
        </svg>
      }
      HoverIcon={
        <svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="0 0 22 22">
          <g>
            <g>
              <path fill="#E12138" d="M4,9.001V8.644C2.933,7.319,2.247,5.714,2.056,4.001H4v-2h-3C0.449,2.001,0,2.449,0,3
                                c0,3.59,1.75,6.93,4.681,8.994C4.25,11.084,4,10.072,4,9.001z" />
            </g>
            <path fill="#E12138" d="M21,2.001h-3v2h1.945C19.754,5.717,19.07,7.32,18,8.645v0.356c0,1.069-0.248,2.078-0.678,2.985
                            C20.252,9.922,22,6.592,22,3C22,2.449,21.553,2.001,21,2.001z" />
            <path fill="#E12138" d="M11,16c-0.34,0-0.672-0.033-1-0.078V21c0,0.553,0.447,1,1,1s1-0.447,1-1v-5.078
                            C11.672,15.967,11.34,16,11,16z" />
            <g>
              <path fill="#FFFFFF" d="M11,16c-3.859,0-7-3.139-7-6.999v-8c0-0.552,0.448-1,1-1h12c0.553,0,1,0.448,1,1v8
                                C18,12.861,14.859,16,11,16z M6,2.001v7C6,11.758,8.244,14,11,14c2.757,0,5-2.242,5-4.999v-7H6z" />
            </g>
            <g>
              <path fill="#FFFFFF" d="M16,22H6c-0.552,0-1-0.447-1-1c0-0.551,0.448-0.998,1-0.998h10c0.553,0,1,0.447,1,0.998
                                C17,21.553,16.553,22,16,22z" />
            </g>
          </g>
        </svg>
      }
      ActiveIcon={
        <svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="0 0 22 22">
          <g>
            <path fill="#E12138" d="M16.531,12.333c-0.324,0-0.641-0.156-0.834-0.446c-0.307-0.46-0.182-1.08,0.279-1.387
                            c2.238-1.488,3.676-3.869,3.969-6.5H17c-0.553,0-1-0.447-1-1s0.447-1,1-1h4c0.553,0,1,0.448,1,1c0,3.692-1.838,7.12-4.916,9.165
                            C16.912,12.278,16.721,12.333,16.531,12.333z" />
            <g>
              <path fill="#E12138" d="M5.408,12.292c-0.193,0-0.387-0.056-0.559-0.172C1.813,10.069,0,6.659,0,3c0-0.553,0.448-1,1-1h4
                                C5.552,2,6,2.447,6,3S5.552,4,5.001,4H2.056c0.291,2.606,1.708,4.973,3.914,6.463c0.457,0.309,0.577,0.931,0.269,1.389
                                C6.045,12.138,5.729,12.292,5.408,12.292z" />
            </g>
            <g>
              <path fill="#E12138"
                    d="M11,22c-0.552,0-1-0.448-1-1.001V15c0-0.553,0.447-1,1-1s1,0.447,1,1v5.999C12,21.552,11.553,22,11,22z" />
            </g>
            <g>
              <g>
                <path fill="#FFFFFF" d="M16,22H6c-0.552,0-0.999-0.448-0.999-1.001c0-0.552,0.447-1,0.999-1h10c0.553,0,1,0.448,1,1
                                    C17,21.552,16.553,22,16,22z" />
              </g>
              <path fill="#FFFFFF"
                    d="M17,0H5.001C4.448,0,4,0.447,4,1v8c0,3.86,3.141,7,7,7s7-3.14,7-7V1C18,0.447,17.553,0,17,0z" />
            </g>
          </g>
        </svg>
      }
    />
  );
};

export default LeaderboardIcon;