import React from 'react';
import { useParams } from 'react-router-dom';

import MarketplaceHomeContainer from './MarketplaceHome';
import MarketplaceMarketContainer from './MarketplaceMarket';
import MarketplaceOffersContainer from './MarketplaceOffers';

const MarketplaceContainer = () => {
  const { tab } = useParams();

  return (
    <>
      {(tab === 'home') &&
      <MarketplaceHomeContainer />
      }
      {(tab === 'marketplace') &&
      <MarketplaceMarketContainer />
      }
      {(tab === 'active-offers') &&
      <MarketplaceOffersContainer type="active" />
      }
      {(tab === 'purchased-offers') &&
      <MarketplaceOffersContainer type="purchased" />
      }
      {(tab === 'sold-offers') &&
      <MarketplaceOffersContainer type="sold" />
      }
    </>
  );
};

export default MarketplaceContainer;