import React from 'react';
import { adopt } from 'react-adopt';
import { Query } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { streamerLeaderBoard, gameLeaderBoard } from '../../apollo/queries';

import LeaderBoardList from '../../components/Leaderboard/LeaderBoardList';
import { rangeArray } from '../../utils/helper';
import ListSkeletonItem from '../../components/Skeletons/ListSkeletonItem';
import RankingLeaders from '../../components/Ranking/RankingLeaders';
import Colors from '../../theme/Colors';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 20,
  },
  timeContainer: {
    fontSize: 16,
    textAlign: 'center',
    color: Colors.subTextColor,
    fontWeight: 500,
    marginBottom: 10,
  },
  noData: {
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
}));

const SpecificRankingContainer = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { streamer, game, country } = props;
  const perPage = 50;
  let page = 1;

  let query, variables, key;
  if (streamer) {
    query = streamerLeaderBoard;
    key = 'getStreamerLeaderboard';
    variables = {
      streamer: streamer._id,
    };
  } else if (game) {
    query = gameLeaderBoard;
    key = 'getGameLeaderboard';
    variables = {
      game: game._id,
    };
  }

  if (country && country.length > 0) {
    variables.filter = { country };
  }

  const Composed = adopt({
    getLeaderBoardQuery: ({ render }) => (
      <Query query={query} variables={{ ...variables, ...{ pagination: { perPage, page: 1 } } }}>
        {render}
      </Query>
    ),
  });

  return (
    <Composed>
      {({ getLeaderBoardQuery }) => {
        const rankings = (getLeaderBoardQuery.data) && getLeaderBoardQuery.data[key];

        return (
          <div className={classes.container}>
            {(!getLeaderBoardQuery.loading && rankings) &&
            <RankingLeaders entries={rankings.entries.slice(0, 3)} />
            }
            {getLeaderBoardQuery.loading ?
              <>
                <div className={classes.myPosition}>
                  <Typography variant="h3">{t('your_rank_position')}</Typography>
                  <ListSkeletonItem rounded subText />
                </div>
                {rangeArray(1, 10)
                .map(item =>
                  <ListSkeletonItem rounded subText key={`SpecificRankingSkeletonKey${item}`} />,
                )}
              </>
              :
              <>
                {rankings.count > 0 ?
                  <LeaderBoardList
                    entries={rankings.entries}
                    hasMore={(rankings) && rankings.count > rankings.entries.length}
                    isGlobalRanking
                    onlyXP
                    onLoadMore={async () => {
                      page += 1;
                      variables.pagination = {
                        perPage,
                        page,
                      };
                      try {
                        await getLeaderBoardQuery.fetchMore({
                          variables,
                          updateQuery: (prev, { fetchMoreResult }) => {
                            if (!fetchMoreResult) return prev;
                            const obj = {};
                            obj[key] = {
                              entries: [...prev[key].entries, ...fetchMoreResult[key].entries],
                              time: prev[key].time,
                              count: prev[key].count,
                              __typename: prev[key].__typename,
                            };

                            return obj;
                          },
                        });
                      } catch {}
                    }}
                  />
                  :
                  <div className={classes.noData}>{t('no_data_available')}</div>
                }
              </>
            }
          </div>
        );
      }}
    </Composed>
  );
};

export default SpecificRankingContainer;