import React from 'react';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import CardShopMatchCardRightSide from './CardShopMatchCardRightSide';
import CardShopMatchCardLeftSide from './CardShopMatchCardLeftSide';

const CardShopMatchCard = (props) => {
  const { loading, card, isLegacy } = props;

  return (
    <div style={{ color: Colors.white, ...Helpers.fillRow }} id="card-shop-card-view">
      <CardShopMatchCardLeftSide loading={loading} card={card} isLegacy={isLegacy} />

      <CardShopMatchCardRightSide loading={loading} card={card} isLegacy={isLegacy} />
    </div>
  );
};

export default CardShopMatchCard;