import React from 'react';
import { useTranslation } from 'react-i18next';

import ReturnBreadcrumbs from './ReturnBreadcrumbs';

const MarketplaceBreadcrumbs = (props) => {
  const { t } = useTranslation();
  const { route, tKey } = props;

  const mainUrl = 'card-shop';

  const parts = [
    {
      name: t('marketplace'),
    },
    {
      name: tKey,
      link: `/${mainUrl}/${route}`,
    },
  ];

  return (
    <ReturnBreadcrumbs parts={parts} />
  );
};

export default MarketplaceBreadcrumbs;