/**
 * This file contains the application's colors.
 *
 * Define color here instead of duplicating them throughout the components.
 * That allows to change them more easily later on.
 */

export default {
  transparent: 'transparent',
  white: '#ffffff',
  black: '#000000',
  gray: '#808080',
  gold: '#e8d393',
  neonGreen: '#79df58',
  neonBlue: '#3cb9ee',
  neonPurple: '#b69ae9',
  pink: '#e9a5b1',
  gray5: '#0D0D0D',
  conifer: '#A7D548',

  halfWhite: 'rgba(255, 255, 255, 0.5)',

  level: {
    legendary: '#EFDA49',
    unique: '#FE2424',
    rare: '#20C1FE',
    uncommon: '#CB8637',
    common: '#ACBABB',
    special: '#FF0068',
  },

  quality: {
    100: '#039C45',
    75: '#50F4AD',
    50: '#FFE45C',
    25: '#FE8674',
    0: '#FF2900',
  },

  newQuality: [
    '#FF0000', // 1
    '#FB4C2A',
    '#FA6548',
    '#FC7258',
    '#FE8674',
    '#FF8B33',
    '#FFA733',
    '#FFC233',
    '#FFDD33',
    '#FFF349', // 10
    '#FFF564',
    '#FFF783',
    '#F6F092',
    '#FDF8AE',
    '#A8F3C1',
    '#8FF9B2',
    '#87FBAE',
    '#9EFFBF',
    '#14DF6C',
    '#039C45', // 20
  ],

  primary: '#CE1537',
  secondary: '#E97724',
  third: '#8413F9',

  lightPrimary: '#ed3d55',
  lightSecondary: '#ef612c',
  lightThird: '#9937fe',

  borderColor: '#b81946',
  headerTextColor: '#3cb8ee',
  header: '#040C1F',
  headerHover: '#ffc826',
  headerFooter: '#030813',
  footerLinkColor: '#454B57',
  headerIconColor: '#60666f',
  wrapperDark: '#212529',
  wrapperLight: '#292E34',
  streamInfoBGColor: '#2D333A',
  streamAvatarBorder: '#282D33',
  subTextColor: '#7B8491',
  onBoardingTextColor: '#AEB7C4',
  tabHeaderBG: '#18181b',
  textFieldBG: '#0e0e0e',
  searchFieldBG: '#ffffff',
  searchFieldColor: '#4F555E',
  background: '#202328',
  roundedBG: '#909294',
  positionBG: '#3f454d',
  dotBG: '#303032',
  badgeBG: '#eb1e44',
  progressBarBG: '#909294',
  progressBarStepBG: '#565c64',
  redeemBarBG: '#f7ba21',
  craftBarBG: '#fa5239',
  accomplishedProgress: '#f7ba21',
  accomplishedPrimaryProgress: '#eb4275',
  unFilledProgress: '#40444b',
  dailyQuest: '#ab5dfa',
  weeklyQuest: '#ed3c54',
  onBoardingColor: '#ee2451',
  containerBGColor: '#010814',
  containerHoverBGColor: '#040F1E',

  headerButton: 'linear-gradient(to bottom, #801c2c 0%, #7f1a2b 50%, #711727 50%, #711625 100%)',
  headerRightMenu: 'linear-gradient(to bottom, #a10f27 0%, #a10f27 50%, #b20c28 50%, #b60b28 100%)',
  redProgressGradient: 'linear-gradient(to left, #ed3d55, #CE1537)',
  purpleProgressGradient: 'linear-gradient(to left, #9937fe, #8413f9)',
  boxBasicsGradient: 'linear-gradient(to left, #292e34, #40444b)',
  grayBlackGradient: 'linear-gradient(to bottom, rgba(11, 11, 11, 0.6), rgba(33, 33, 33, 0.6))',
  blueBoxGradient: 'linear-gradient(to bottom, #051223, #111E2F)',

  firstPlace: '#fece3a',
  secondPlace: '#e34181',
  thirdPlace: '#d756ee',

  firstPlaceHover: '#f7ac2c',
  secondPlaceHover: '#f35073',
  thirdPlaceHover: '#cd40ef',

  orangeRedGradient: 'linear-gradient(to bottom left, #ef612c 30%, #d01a36)',
  orangeGradient: 'linear-gradient(to left, #f69e06, #f67706)',
  purpleGradient: 'linear-gradient(to bottom left, #9937fe 30%, #8413f9)',
  redGradient: 'linear-gradient(to bottom left, #ed3d55 30%, #ce1537)',

  firstPlaceGradient: 'linear-gradient(to top, #fbc836, #f7b619, #e79014, #c86405 100%)',
  secondPlaceGradient: 'linear-gradient(to bottom, #d11a3c 0%, #e74682 25%, #f94798)',
  thirdPlaceGradient: 'linear-gradient(to top, #ff7fe8, #da55ff 75%, #9813d6 100%)',

  firstPlaceBorderGradient: 'linear-gradient(white, white), radial-gradient(circle at top left, #c86405, #f7ac2c)',
  secondPlaceBorderGradient: 'linear-gradient(white, white), radial-gradient(circle at top left, #ce1537, #f35073)',
  thirdPlaceBorderGradient: 'linear-gradient(white, white), radial-gradient(circle at top left, #9814d6, #cd40ef)',

  success: '#28a745',
  error: '#ff0000',

  facebook: '#3b5998',
  twitter: '#00aced',
  discord: '#4F67EE',
  twitch: '#A570FB',
};
