import React from 'react';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Avatar from '@material-ui/core/Avatar';
import { useTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Alert from '../Alert/Alert';
import Helpers from '../../theme/Helpers';
import CardItem from './CardItem';
import Colors from '../../theme/Colors';

const useStyles = makeStyles({
  container: {
    ...Helpers.fillRowMain,
  },
  streamerAvatar: {
    height: 45,
    width: 45,
    borderColor: Colors.roundedBG,
  },
});

const Cards = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { cards, coinsInformation, organisation, hideProgresses, maxLevelCard } = props;

  if (cards && cards.length > 0) {
    return (
      <List className={classes.container}>
        {cards.map((item, index) => (
            <CardItem
              key={item._id}
              card={item}
              countOfLastCard={item.match_card.level && item.match_card.level > 1 && !item.match_card.is_archived ? cards[index - 1].count : null}
              coinsInformation={coinsInformation}
              sorting={{}}
              hideProgresses={hideProgresses}
              maxLevelCard={maxLevelCard}
            />
          ),
        )}
      </List>
    );
  } else {
    if (organisation) {
      return (
        <Link
          to={`/organisation${organisation.main_channel}`}
          style={{ cursor: 'pointer', color: Colors.white, textDecoration: 'none' }}
        >
          <Alert
            style={{ ...{ marginBottom: 40 }, ...Helpers.fillRowCross, ...Helpers.mainSpaceBetween }}
          >
            {t('organisation_has_cards')}
            <Avatar
              variant="circle"
              alt="Avatar"
              src={organisation.logo_url}
              classes={{ root: classes.streamerAvatar }}
            />
          </Alert>
        </Link>
      );
    } else {
      return (
        <Typography
          variant="h6"
          color="secondary"
          gutterBottom
          style={{ ...Helpers.textCenter, padding: 40, fontSize: 30 }}
        >
          {t('no_cards')}
        </Typography>
      );
    }
  }
};

Cards.propTypes = {
  hideProgresses: PropTypes.bool,
};

Cards.defaultProps = {
  hideProgresses: false,
};

export default withRouter(Cards);