import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/react-hooks';

import { updateMyData as updateMyDataMutation } from '../../apollo/mutations';
import { getMyViewerAndStreamer } from '../../apollo/mergedQueries';

import CardToolSelectCardDesignItem from './CardToolSelectCardDesignItem';
import Helpers from '../../theme/Helpers';
import { rangeArray } from '../../utils/helper';
import CardToolCardDesignItem from './CardToolCardDesignItem';
import Colors from '../../theme/Colors';

const CardToolSelectCardSetDesign = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const {
    activeDesign,
    setActiveDesign,
    cardDesigns,
  } = props;

  const [updateMyData] = useMutation(updateMyDataMutation, {
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{ query: getMyViewerAndStreamer }],
  });

  return (
    <div className={classes.wrapper}>
      <div className={classes.selectText}>
        <div className={classes.innerText}>{t('select_design')}</div>
      </div>
      <div className={classes.selectContainer}>
        {cardDesigns.map(item => (
          <CardToolSelectCardDesignItem
            key={'SelectCardDesign' + item}
            isActive={activeDesign === item}
            cardDesign={item}
            setActive={() => {
              setActiveDesign(item);
              updateMyData({ variables: { update: { card_design: item } } });
            }}
          />
        ))}
      </div>
      <div style={{ ...Helpers.fillRowCenter }}>
        {rangeArray(1, 5)
        .map(card => (
          <div key={'SelectedCardDesign' + card}>
            <div style={{ ...Helpers.textCenter, marginBottom: 3 }}>
              {t('level_x', { level: card })}
            </div>
            <CardToolCardDesignItem
              level={card}
              cardDesign={activeDesign}
              size={230}
              marginLeft={-154}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    ...Helpers.fillRowCenter,
    backgroundColor: Colors.containerBGColor,
    padding: 20,
    marginBottom: 20,
  },
  selectContainer: {
    marginRight: 20,
    height: 260,
    padding: 5,
    backgroundColor: '#121212',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    flex: 1,
  },
  selectText: {
    backgroundColor: '#0B0B0B',
    width: 85,
    height: 270,
    ...Helpers.fillRowCenter,
  },
  innerText: {
    transform: 'rotate(-90deg)',
    textTransform: 'uppercase',
    color: '#3F3F3F',
  },
}));

export default CardToolSelectCardSetDesign;