import { useEffect } from 'react';
import { ErrorCode } from '../enums/ErrorCode';

/**
 * If the page is refreshed, it will be redirected back to the beginning of the verification process.
 *
 * @param history
 * @param invocationIsValid
 * @param changeCardVerification
 */
export function useInvocationCheck (history, invocationIsValid, changeCardVerification) {
  useEffect(() => {
    if (!invocationIsValid) {
      changeCardVerification({ errorCode: ErrorCode.InvalidInvocation });
      history.push('/verification/');
    }
  }, []);
}
