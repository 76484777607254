import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/react-hooks';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { TextField } from '@material-ui/core';

import { useAlertCapsule as useAlertCapsuleMutation } from '../../apollo/mutations';
import { getAlertCapsulesWithDiscordInfo } from '../../apollo/mergedQueries';

import PagePopup from '../Dialog/PagePopup';
import Helpers from '../../theme/Helpers';
import CircleButton from '../Button/CircleButton';
import AnimationButton from '../Button/AnimationButton';

const useStyles = makeStyles({
  container: {
    ...Helpers.fillColCenter,
    height: '100%',
  },
  title: {
    ...Helpers.fillColCenter,
  },
  header: {
    fontSize: 26,
    margin: 0,
  },
  subHeader: {
    opacity: 0.5,
    margin: '10px',
  },
  content: {
    ...Helpers.fillColCenter,
    marginTop: 24,
    background: 'rgba(255, 255, 255, 0.06)',
    padding: 20,
    width: 794,
    borderRadius: '6px',
    '& fieldset': {
      background: 'rgba(255, 255, 255, 0.04)',
      border: '1px solid rgba(255, 255, 255, 0.04)',
    },
  },
  indicators: {
    ...Helpers.fillRowCenter,
    padding: '10px 20px',
    width: '100%',
    background: 'rgba(255, 255, 255, 0.04)',
    margin: '12px 0',
    borderRadius: 6,
    boxSizing: 'border-box',
  },
  circleLabeled: {
    ...Helpers.fillRowCross,
    width: '25%',
    fontSize: '12px',
    '& > div': {
      marginRight: 10,
      fontSize: 16,
      width: 44,
      height: 44,
      minWidth: 44,
      minHeight: 44,
    },
  },
});

const AlertCapsuleRedeemDialog = (
  {
    t,
    allowTwitch,
    allowDiscord,
    allowSpeech,
    capsuleId,
    onClose,
    alertsRefetchQuery
  },
) => {
  const classes = useStyles();
  const [message, setMessage] = useState('');

  const [alertCapsuleFunction, { loading }] = useMutation(useAlertCapsuleMutation, {
      onCompleted: () => {
        onClose();
      },
      refetchQueries: alertsRefetchQuery,
    },
  );

  function redeemBtnClick() {
    alertCapsuleFunction({
      variables: {
        id: capsuleId,
        tts_message: message,
      },
    });
  }

  function handleInputChange({ target }) {
    setMessage(target.value);
  }

  return (
    <PagePopup>
      <div className={classes.container}>
        <ClickAwayListener onClickAway={onClose}>
          <span>
            <div className={classes.title}>
              <h1 className={classes.header}>{t('type_capsule_message')}</h1>
              <p className={classes.subHeader}>
                {t('type_capsule_message_description')}
              </p>
            </div>
            <div className={classes.content}>
              {allowSpeech &&
              <TextField
                variant="outlined"
                multiline
                rows={6}
                onChange={handleInputChange}
                placeholder={t('capsule_message_placeholder')}
                style={{ width: '100%', fontSize: 14, border: 'none' }}
              />
              }
              <div className={classes.indicators}>
                {allowTwitch && (
                  <div className={classes.circleLabeled}>
                    <CircleButton>
                      <i className="fa-brands fa-twitch" />
                    </CircleButton>
                    {t('twitch_alert')}
                  </div>
                )}
                {allowDiscord && (
                  <div className={classes.circleLabeled}>
                    <CircleButton>
                      <i className="fa-brands fa-discord" />
                    </CircleButton>
                    {t('discord_alert')}
                  </div>
                )}
                {allowSpeech &&
                <>
                  <div className={classes.circleLabeled}>
                    <CircleButton>
                      <i className="fa-light fa-waveform" />
                    </CircleButton>
                    {t('text_to_speech')}
                  </div>
                  <div className={classes.circleLabeled}>
                    <CircleButton>
                      <i className="fa-light fa-font-case" />
                    </CircleButton>
                    {t('max_symbols')}
                  </div>
                </>
                }
              </div>
              <AnimationButton
                status={'neonRed'}
                onClick={loading ? null : redeemBtnClick}
                disabled={loading}
                style={{
                  width: 236,
                }}
              >
                <i
                  className="fa-light fa-plus"
                  style={{
                    fontSize: 18,
                    marginRight: 10,
                    verticalAlign: 'bottom',
                  }}
                />
                {t('redeem')}
              </AnimationButton>
            </div>
          </span>
        </ClickAwayListener>
      </div>
    </PagePopup>
  );
};

export default AlertCapsuleRedeemDialog;
