import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';

import { getMyViewer } from '../../apollo/queries';

import PageTitle from '../../components/PageTitle/PageTitle';
import Loading from '../../components/Loading/Loading';
import TopBG from '../../components/TopViewHeader/TopBG';
import AccountSettings from '../../components/Settings/AccountSettings';
import { useMediaQuery } from 'react-responsive';
import { isTabletOrMobileObj } from '../../utils/responsive';

const AccountSettingsContainer = () => {
  const { t } = useTranslation();

  const { data, loading } = useQuery(getMyViewer);

  const viewer = (data) && data.getMyViewer;
  const isTabletOrMobile = useMediaQuery(isTabletOrMobileObj);

  return (
    <>
      <PageTitle title={t('settings_tab_account')} />
      <TopBG>
        {loading ?
          <Loading />
          :
          <AccountSettings viewer={viewer} isTabletOrMobile={isTabletOrMobile} />
        }
      </TopBG>
    </>
  );
};

export default AccountSettingsContainer;