import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import TopViewHeader from '../../components/TopViewHeader/TopViewHeader';
import PageTitle from '../../components/PageTitle/PageTitle';
import CardToolServicesDesignerForm from '../../components/CardTool/CardToolServicesDesignerForm';
import HeaderLine from '../../components/TopViewHeader/HeaderLine';
import { isTabletOrMobileObj } from '../../utils/responsive';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 1440,
    margin: 'auto',
    paddingTop: 20,
  },
}));

const DesignJobCreationContainer = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isTabletOrMobile = useMediaQuery(isTabletOrMobileObj);

  return (
    <TopViewHeader>
      <PageTitle title={t('create_design_job')} />
      <div className={classes.container}>

        <HeaderLine
          title={t('card_illustrations')}
          subTitle={t('brief_your_card')}
          isTabletOrMobile={isTabletOrMobile}
        />

        <CardToolServicesDesignerForm />
      </div>
    </TopViewHeader>
  );
};

export default DesignJobCreationContainer;