import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import { Avatar } from '@material-ui/core';

import { getLoggedInOrganisationEventHeader } from '../../apollo/mergedQueries';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import TopBG from '../TopViewHeader/TopBG';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import Layout from '../Layout/Layout';
import { getMaximalLevelOfCards, isUserPremium } from '../../utils/helper';
import CardsOpenGraphHeader from '../Cards/CardsOpenGraphHeader';
import Cards from '../Cards/Cards';
import EventStreamerCards from '../Streamer/EventStreamerCards';
import OrganisationMember from './OrganisationMember';

import conf from '../../conf.json';

const useStyles = makeStyles({
  breadcrumbsArea: {
    backgroundColor: Colors.black,
    padding: '22px 0',
    '& > div': {
      maxWidth: 1440,
      margin: '0 auto',
    },
    '& nav': {
      marginBottom: '0 !important',
    },
  },
  headTrio: {
    ...Helpers.fillRow,
    ...Helpers.mainSpaceBetween,
    color: Colors.white,
    marginBottom: -5,
    '& > div': {
      width: '33.3%',
    },
  },
  bigAvatar: {
    position: 'absolute',
    top: 80,
    left: 78,
    width: 170,
    height: 170,
    borderColor: '#1a071d',
    borderWidth: 2,
    boxShadow: '0 0 0 13px #B60B28',
    background: 'linear-gradient(to left bottom, #f3133b, #781023)',
  },
  container: {
    background: Colors.containerBGColor,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    minHeight: 500,
  },
  expandButton: {
    backgroundColor: Colors.wrapperLight,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    padding: 5,
    ...Helpers.textCenter,
    cursor: 'pointer',
    marginBottom: 20,
    '&:hover': {
      backgroundColor: Colors.wrapperDark,
    },
  },
  featureBanner: {
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: 418,
    position: 'relative',
    ...Helpers.fillRow,
    ...Helpers.mainSpaceBetween,
    marginTop: -33,
    paddingTop: 33,
  },
  checkedRow: {
    ...Helpers.fillRowCross,
    margin: '14px 0',
    fontSize: 15,
    '& > img': {
      marginRight: 14,
    },
  },
  priceBanner: {
    position: 'absolute',
    top: 50,
    left: '50%',
    fontFamily: 'Bebas Neue',
    fontSize: 36,
    lineHeight: 0.9,
    paddingLeft: 120,
    textShadow: '2px 2px 4px #000000',
    '& > span': {
      fontSize: 20,
      verticalAlign: 'top',
    },
  },
  headText: {
    fontFamily: 'Bebas Neue',
    fontSize: 20,
    lineHeight: 1,
    ...Helpers.fillRowCenter,
  },
  bodyContainer: {
    padding: '30px 38px',
    backgroundColor: '#051329',
    minHeight: 300,
    borderRadius: 10,
  },
  eventButton: {
    backgroundColor: '#09B71180',
    border: '1px solid #1FE325',
    borderRadius: 4,
    fontFamily: 'Bebas Neue',
    fontSize: 20,
    padding: '20px 50px',
    width: 'fit-content',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#09B711BF',
    },
  },
});

const EventOrganisation = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { organisation, isTabletOrMobile, eventConf, memberStreams } = props;
  const [tab, setTab] = useState(organisation.edition_to_show ? organisation.edition_to_show : 'official');
  const [expandMembers, setExpandMembers] = useState(false);
  const { language } = navigator;

  const variables = {
    organisation: organisation._id,
    offerId: conf.offer_id && conf.offer_id[organisation.code],
  };
  const { data } = useQuery(getLoggedInOrganisationEventHeader, {
    variables,
    fetchPolicy: useCacheWithExpiration('getLoggedInOrganisationEventHeader', variables),
    pollInterval: 240000,
  });

  const myCollectedPoints = (data) && data.viewerRedeemedPoints;
  const currentOffer = (data) && data.getOffer;
  const isOrderPossibleData = (data) && data.isOrderPossible;
  const myViewer = (data) && data.getMyViewer;
  const myViewerPremium = isUserPremium(myViewer);
  const energyLimit = myViewerPremium.isPremiumActive ? conf.premium_tank_size : 100;
  let cards = (data) && data.getTypeCards;
  const isOrderPossible = isOrderPossibleData && isOrderPossibleData.possible;
  const ownedCards = isOrderPossibleData && isOrderPossibleData.owned_card_ids.length;
  const energy = myCollectedPoints ? (myCollectedPoints.points_season - myCollectedPoints.redeemed_points_season) : 0;
  const costs = currentOffer && (currentOffer.products && currentOffer.products[0].costs / 100);
  const maxLevelCard = getMaximalLevelOfCards(cards);
  const isSpecial = tab !== 'official';

  const coinsInformation = {
    maxLevelCard,
    viewerRedeemedPoints: myCollectedPoints,
  };

  if (coinsInformation && !coinsInformation.viewerRedeemedPoints)
    coinsInformation.viewerRedeemedPoints = {
      points_season: 0,
      redeemed_points: 0,
    };

  if (cards) {
    cards = cards.filter(card => {
      if (isSpecial)
        if (tab)
          return card.match_card.edition === tab;
        else
          return card.matchCardModel === 'SpecialCard';
      else
        return card.matchCardModel === 'Card';
    });

    if (isSpecial)
      cards.sort((a, b) => (a.match_card.sorting - b.match_card.sorting));
    else
      cards.sort((a, b) => (a.match_card.level - b.match_card.level));
  }

  const categories = [
    {
      key: '2021',
      value: 'Christmas Calendar 2021',
    },
  ];

  return (
    <TopBG
      background={`${conf.cdn_url}/${eventConf.bg}`}
      isTabletOrMobile={isTabletOrMobile}
      disableBreadcrumbs
      style={{
        padding: 0,
        minHeight: 958,
        backgroundSize: 'auto',
        backgroundColor: '#041022',
      }}
    >
      <div className={classes.headTrio}>
        <div>
          <Avatar
            className={classes.bigAvatar}
            alt={organisation.code}
            src={organisation.logo_url}
          />
        </div>
        <div style={{ ...Helpers.fillColCross, marginTop: 120, marginLeft: -120 }}>
          <img src={`${conf.cdn_url}/${eventConf.logo}`} alt="Logo" />
        </div>
        <div>
          {eventConf.char &&
          <img
            src={`${conf.cdn_url}/${eventConf.char}`}
            alt="Char"
            style={{ marginLeft: -10, marginTop: 90 }}
          />
          }
        </div>
      </div>

      <Layout tab={tab} setTab={setTab} categories={categories}>
        <div style={{ width: '100%' }}>
          {(currentOffer && tab === currentOffer.edition_to_show) &&
          <div
            className={classes.featureBanner}
            style={{ background: `url("${conf.cdn_url}/${eventConf.adBanner}")` }}
          >
            <div style={{ padding: '42px 0 0 59px' }}>
              <div style={{ fontSize: 56, fontFamily: 'Bebas Neue', lineHeight: 0.9 }}>
                Stream schauen,<br />Karten sammeln, <br />Heft bestellen.
              </div>
              <div style={{ fontSize: 20, fontFamily: 'Bebas Neue', lineHeight: 1 }}>
                Nur erhältlich vom 22. November bis zum 10. Jänner 2022
              </div>
              <div style={{ marginTop: 25 }}>
                <div className={classes.checkedRow}>
                  <img
                    src={`${conf.cdn_url}/assets/event/checkmark_${ownedCards > 0 ? 'filled' : 'empty'}.png`}
                    alt="Checker circle"
                  />
                  Sammel deine erste Karte
                </div>
                <div className={classes.checkedRow}>
                  <img
                    src={`${conf.cdn_url}/assets/event/checkmark_${ownedCards === currentOffer.required_cards.length ? 'filled' : 'empty'}.png`}
                    alt="Checker circle"
                  />
                  <div>
                    Sammel alle Karten {ownedCards}/{currentOffer.required_cards.length}
                  </div>
                </div>
              </div>
              <div style={{ ...Helpers.fillRowCenter }}>
                <div
                  className={classes.eventButton}
                  style={isOrderPossible ? {} : {
                    filter: 'grayscale(100)',
                    cursor: 'not-allowed',
                  }}
                  onClick={() => isOrderPossible ? props.history.push(`/card-shop?offer=${currentOffer._id}`) : null}
                >
                  {currentOffer.order_button_text}
                </div>
              </div>
            </div>

            <div className={classes.priceBanner}>
              Stark limitiert<br />
              {costs && costs.toLocaleString(language)} <span>Euro</span>
            </div>

          </div>
          }
          <div
            className={classes.bodyContainer}
            style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
          >
            {conf.event_raffle_on_page &&
            <div
              style={{ cursor: 'pointer', marginBottom: 20 }}
              onClick={() => props.history.push('/raffle')}
            >
              <img
                src={`${conf.cdn_url}/assets/event/trilluxe-rapidlan/raffle-banner-TRILLUXE.jpg`}
                alt="Raffle"
              />
            </div>
            }
            <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween }}>
              <div className={classes.headText}>{tab.replace('_', ' ')} Sammelkarten</div>
              <div
                style={{ background: '#020812', padding: '3px 18px', ...Helpers.fillRowCenter }}
              >
                {(currentOffer && tab === currentOffer.edition_to_show) &&
                <div className={classes.headText} style={{ marginRight: 48 }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/icons-png/cardredicon.png`}
                    alt="Watch"
                    style={{ marginRight: 7, height: 25 }}
                  />
                  {ownedCards} / {currentOffer.required_cards.length} Karten freigeschalten
                </div>
                }
                <div className={classes.headText}>
                  <img
                    src={`${process.env.PUBLIC_URL}/icons-png/energy_solo.png`}
                    alt="Watch"
                    style={{ marginRight: 11, height: 25 }}
                  />
                  {energy} / {energyLimit}
                </div>
              </div>
            </div>
            <p>
              Wir müssen euch sagen, es weihnachtet sehr!<br />
              Und das auch bei unseren Partnern, denn in Zusammenarbeit mit ihnen gibt es zwischen
              dem 22.11. und 10.01.2022 den exklusiven Streamheroes Adventskalender. Du willst ihn
              dir noch rechtzeitig sichern? Ganz einfach: Schaue einem beliebigen Streamer der
              Organisation "Christmas-Calendar" zu. Alle Teilnehmer findest du ganz unten auf der
              Seite. Schalte mit der Organisation Energie alle 24 Karten frei und bestelle dir
              eines von 1.000 limitierten Heften direkt nach Hause. Zwischen dem 01.12. und 24.12.
              wird täglich eine neue Karte digital revealed, welche du auch Zuhause dann auspacken
              kannst.
            </p>

            <div style={{ padding: '30px 0' }}>
              {(isSpecial) ?
                <EventStreamerCards
                  cards={cards}
                  coinsInformation={coinsInformation}
                  isTabletOrMobile={isTabletOrMobile}
                />
                :
                <>
                  <CardsOpenGraphHeader cards={cards} organisation={organisation} />
                  <Cards
                    cards={cards}
                    coinsInformation={coinsInformation}
                    maxLevelCard={maxLevelCard}
                  />
                </>
              }
            </div>
          </div>

          <div
            className={classes.bodyContainer}
            style={{ marginTop: 24, ...Helpers.fillRow, flexWrap: 'wrap' }}
          >
            {memberStreams.map(memberStream =>
              <OrganisationMember
                key={`OrganisationMember#${memberStream._id}`}
                memberStream={memberStream}
                hasOwnDesign={organisation.hasOwnDesign}
              />,
            )}
          </div>
          {memberStreams.length > 5 &&
          <div
            className={classes.expandButton}
            onClick={() => setExpandMembers(!expandMembers)}
          >
            {expandMembers ? t('shrink_area') : t('expand_area')}
          </div>
          }
        </div>
      </Layout>
    </TopBG>
  );
};

export default withRouter(EventOrganisation);