import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { withRouter } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactSVG } from 'react-svg';
import { useMediaQuery } from 'react-responsive';

import Colors from '../../theme/Colors';
import { isLaptopObj, returnLaptopObj } from '../../utils/responsive';

const useStyles = makeStyles(theme => ({
  root: {
    width: 400,
    margin: 'auto',
  },
  optionItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  optionAvatar: {
    marginRight: 5,
    borderColor: Colors.roundedBG,
  },
  textInput: {
    border: 'none',
    backgroundColor: Colors.searchFieldBG,
    color: Colors.searchFieldColor,
    padding: '6px 30px',
    borderRadius: 50,
    width: 400,
    fontSize: 14,
  },
  textInputUnderline: {
    '&:before': {
      borderBottom: 'none !important',
    },
    '&:after': {
      borderBottom: 'none !important',
    },
  },
  input: {
    color: Colors.searchFieldColor,
    padding: '4px 0 !important',
    fontSize: 14,
    fontWeight: 'normal',
  },
  searchIcon: {
    width: 14,
    height: 14,
    fill: Colors.searchFieldColor,
  },
}));

const DeleteUserSearch = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { results, loading, search, setSearch, setChangeUserDisabled, setChosenUser } = props;

  const isLaptop = useMediaQuery(isLaptopObj);

  const options = (results) ? results : [];

  const setConfirmUserChange = (ev, option) => {
    setChangeUserDisabled(false);
    setChosenUser(option);
  };

  return (
    <div className={classes.root} style={returnLaptopObj(isLaptop, { width: 200 })}>
      <Autocomplete
        id="search-field"
        freeSolo
        options={options}
        groupBy={option => option.type}
        value={search}
        getOptionLabel={option => option.login ? option.login : option}
        renderOption={option => (
          <div
            className={classes.optionItem}
            onClick={(ev) => setConfirmUserChange(ev, option)}
          >
            <Avatar
              className={classes.optionAvatar}
              alt={option.login}
              src={option.profile_image_url}
            />
            {option.login}
          </div>
        )}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={`${t('search')} ...`}
            fullWidth
            size="small"
            classes={{
              root: classes.textInput,
            }}
            style={returnLaptopObj(isLaptop, { width: 200 })}
            inputProps={{
              ...params.inputProps,
              onChange: e => {
                setSearch(e.target.value || '');
                params.inputProps.onChange(e);
              },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  {loading ?
                    <CircularProgress color="inherit" size={20} />
                    :
                    <ReactSVG
                      style={{ height: 14 }}
                      src={process.env.PUBLIC_URL + '/icons/search.svg'}
                      beforeInjection={svg => {
                        svg.classList.add(classes.searchIcon);
                      }}
                    />
                  }
                </>
              ),
              classes: {
                input: classes.input,
                underline: classes.textInputUnderline,
              },
            }} />
        )}
      />
    </div>
  );
};

export default withRouter(DeleteUserSearch);