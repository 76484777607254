import React from 'react';
import BackdropFilter from 'react-backdrop-filter';

import { detectBrowser } from '../../utils/helper';

const CustomBackdropFilter = (props) => {
  const { children, className, filter, canvasFallback, html2canvasOpts } = props;

  return (
    detectBrowser() !== 'Firefox' ?
      <BackdropFilter
        className={className}
        filter={filter}
        canvasFallback={canvasFallback}
        html2canvasOpts={html2canvasOpts}
      >
        {children}
      </BackdropFilter>
      :
      <div className={className}>
        {children}
      </div>
  );
};

export default CustomBackdropFilter;