import React from 'react';
import { makeStyles } from '@material-ui/core';

import Loading from '../Loading/Loading';
import StorageBox from '../Inventory/StorageBox';
import Helpers from '../../theme/Helpers';

const useStyles = makeStyles((theme) => ({
  gridArea: {
    display: 'grid',
    gap: 18,
    gridTemplateColumns: 'repeat(5, 1fr)',

    [theme.breakpoints.down('md')]: {
      ...Helpers.fillColCenter,
      gap: 8,
    },
  },
}));

const ProfileShowcase = (props) => {
  const classes = useStyles();
  const { cardDecks, t, loading, viewer } = props;

  return (
    loading ?
      <Loading />
      :
      <div className={classes.gridArea}>
        {cardDecks.map(box => (
          <StorageBox key={box._id} storageBox={box} t={t} notMy viewer={viewer} />
        ))}
      </div>
  );
};

export default ProfileShowcase;