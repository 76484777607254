import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import CustomTooltip from '../Tooltip/CustomTooltip';
import HoverIcon from '../Icon/HoverIcon';
import CustomBackdropFilter from './CustomBackdropFilter';

const useStyles = makeStyles({
  bluredContainer: {
    background: 'rgba(255, 255, 255, 0.05)',
    width: 'calc(100% - 20px)',
    color: Colors.white,
    padding: 10,
    marginTop: 16,
    fontFamily: 'Montserrat',
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    borderRadius: 6,
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.1)',
    },
  },
  countItem: {
    background: 'rgba(225, 33, 56, 0.21)',
    borderRadius: 6,
    height: 44,
    width: 44,
    ...Helpers.fillColCenter,
    fontWeight: 500,
    fontSize: 18,
    color: '#E12138',
    marginRight: 16,
  },
  hoverText: {
    color: Colors.white,
    '& span:first-child': {
      color: '#F306FC',
    },
    '& span:last-child': {
      color: '#FFFE47',
    },
  },
});

const GroupHeadLine = (props) => {
  const classes = useStyles();
  const { t, withCount, count, text, isActive, setIsActive, tooltipText } = props;

  return (
    <div onClick={() => setIsActive(!isActive)}>
      <CustomBackdropFilter
        className={classes.bluredContainer}
        filter={'blur(50px)'}
        canvasFallback={true}
        html2canvasOpts={{
          allowTaint: true,
        }}
      >
        <div style={{ ...Helpers.fillRowCross, paddingLeft: withCount ? 0 : 10 }}>
          {withCount &&
          <div className={classes.countItem}>{count}</div>
          }
          {text}
        </div>
        <div style={{ ...Helpers.fillRowCross, padding: 10 }}>
          <CustomTooltip
            title={
              <p className={classes.hoverText}
                 dangerouslySetInnerHTML={{ __html: t(tooltipText) }}
              />
            }
          >
            <div style={{ borderRadius: 50 }}>
              <HoverIcon
                icon="fa-light fa-circle-info" hoverIcon="fa-solid fa-circle-info"
                color="rgba(255, 255, 255, 0.5)" hoverColor={Colors.white}
                style={{ fontSize: 18 }}
              />
            </div>
          </CustomTooltip>
          <i className={`fa-regular fa-${isActive ? 'minus' : 'plus'}`}
             style={{ marginLeft: 14, fontSize: 18 }} />
        </div>
      </CustomBackdropFilter>
    </div>
  );
};

export default GroupHeadLine;