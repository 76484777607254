import React from 'react';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { makeStyles, Grid, TextField, InputAdornment } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebookF, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import 'moment/min/locales';

import {
  handleDevLogin as handleDevLoginMutation
} from '../../apollo/mutations';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import CustomButton from '../../components/Button/CustomButton';
const conf = require('../../conf');

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 200,
  },
  textInput: {
    backgroundColor: Colors.textFieldBG,
    padding: 10,
    borderRadius: 3,
    border: '1px solid #c31e30',
    width: 500,
  },
  textInputUnderline: {
    '&:before': {
      borderBottom: 'none !important',
    },
    '&:after': {
      borderBottom: 'none !important',
    },
  },
  inputAdornment: {
    fontSize: 14,
    color: '#636363',
  },
  title: {
    width: 100,
    color: '#636363',
  },
  loginWrapper: {
    padding: 50
  }
}));

const DevLoginContainer = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const newValues = {};
  const { enqueueSnackbar } = useSnackbar();

  const [handleDevLogin] = useMutation(handleDevLoginMutation, {
    onCompleted: (data) => {
      if(data.handleDevLogin) {
        localStorage.setItem(conf.storage_keys.user_token, data.handleDevLogin);
        window.location.href = '/hq/hub';
      }
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
  });

  const handleChange = (event) => {
    const { target } = event;
    newValues[target.name] = target.value;
  };

  const devLogin = () => {
    handleDevLogin({
      variables: {
        login: newValues.login,
        password: newValues.password
      }
    });
  }

  return (
    <>
      <Grid container spacing={2} className={classes.loginWrapper}>
        <Grid item xs={12} sm={12}>
          <div style={Helpers.fillRowCross}>
            <div className={classes.title}>Login:</div>
            <TextField
              id="login"
              name="login"
              onChange={handleChange}
              classes={{
                root: classes.textInput,
              }}
              InputProps={{
                startAdornment: <InputAdornment
                  disableTypography
                  position="start"
                  classes={{ root: classes.inputAdornment }}
                >
                </InputAdornment>,
                classes: {
                  underline: classes.textInputUnderline,
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          <div style={Helpers.fillRowCross}>
            <div className={classes.title}>Password:</div>
            <TextField
              id="password"
              name="password"
              onChange={handleChange}
              classes={{
                root: classes.textInput,
              }}
              InputProps={{
                startAdornment: <InputAdornment
                  disableTypography
                  position="start"
                  classes={{ root: classes.inputAdornment }}
                >
                </InputAdornment>,
                classes: {
                  underline: classes.textInputUnderline,
                },
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          <CustomButton status="primary" onClick={devLogin} >
            Login
          </CustomButton>
        </Grid>
      </Grid>
    </>
  );
};

export default DevLoginContainer;