import React, { useCallback, useState } from 'react';
import { Button, TextField, makeStyles } from '@material-ui/core';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme) => ({
  container: {
    flex: '1 1 auto',
    display: 'flex',
    '& > *': {
      flex: '1 1 auto',
      minWidth: 0,
    },
    '& > * + *': {
      marginLeft: theme.spacing(1),
    },
    '& :last-child': {
      flex: '0 1 auto',
    },
  },
}));

export function MuiTextInput ({ chatController, actionRequest }) {
  const classes = useStyles();
  const chatCtl = chatController;
  const [value, setValue] = useState(actionRequest.defaultValue);

  const setResponse = useCallback(() => {
    if (value) {
      const res = { type: 'text', value };
      chatCtl.setActionResponse(actionRequest, res);
      setValue('');
    }
  }, [actionRequest, chatCtl, value]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.nativeEvent.isComposing)
        return;

      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        setResponse();
      }
    },
    [setResponse],
  );

  const sendButtonText = actionRequest.sendButtonText ? actionRequest.sendButtonText : 'Send';

  return (
    <div className={classes.container}>
      <TextField
        placeholder={actionRequest.placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        autoFocus
        multiline
        inputProps={{ onKeyDown: handleKeyDown }}
        variant="outlined"
        rowsMax={10}
      />
      <Button
        type="button"
        onClick={setResponse}
        disabled={!value}
        variant="contained"
        color="primary"
        startIcon={<FontAwesomeIcon icon={faPaperPlane} size="lg" />}
      >
        {sendButtonText}
      </Button>
    </div>
  );
}