import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';

import conf from '../../conf.json';

const useStyles = makeStyles({
  eventButton: {
    backgroundColor: '#09B71180',
    border: '1px solid #1FE325',
    borderRadius: 4,
    minWidth: 200,
    ...Helpers.textCenter,
    fontFamily: 'Bebas Neue',
    fontSize: 20,
    padding: '20px 50px',
    width: 'fit-content',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#09B711BF',
    },
  },
  campFeature: {
    backgroundRepeat: 'no-repeat',
    width: '100%',
    position: 'relative',
    height: 451,
    marginTop: -33,
    paddingTop: 33,
    ...Helpers.fillRow,
    ...Helpers.mainSpaceBetween,
  },
  checkedRow: {
    ...Helpers.fillRowCross,
    margin: '14px 0',
    fontSize: 15,
    '& > img': {
      marginRight: 14,
    },
  },
  priceBanner: {
    position: 'absolute',
    top: 50,
    left: '50%',
    paddingLeft: 120,
    fontFamily: 'Bebas Neue',
    fontSize: 36,
    lineHeight: 0.9,
    textShadow: '2px 2px 4px #000000',
  },
});

const OfferInfo = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    offer,
    isOrderPossible,
    withGallery,
    openLightbox,
    isTabletOrMobile,
    eventConfig,
  } = props;

  const { language } = navigator;
  const costs = offer && (offer.products && offer.products[0].costs / 100);
  const ownedCards = isOrderPossible && isOrderPossible.owned_card_ids.length;
  const isPossible = isOrderPossible && isOrderPossible.possible;

  return (
    <div
      className={classes.campFeature}
      style={{
        backgroundImage: `url("${conf.cdn_url}/${eventConfig.adBanner}")`,
        ...(isTabletOrMobile ? {
          background: `url("${conf.cdn_url}/${eventConfig.mobileAdBanner}")`,
          height: 298,
          marginTop: 0,
          paddingTop: 0,
          marginBottom: 10,
        } : {}),
      }}
    >
      <div style={{ padding: isTabletOrMobile ? 20 : '42px 0 0 59px' }}>
        <div style={{
          fontSize: isTabletOrMobile ? 30 : 56,
          fontFamily: 'Bebas Neue',
          lineHeight: 0.9,
        }}
             dangerouslySetInnerHTML={{ __html: t('offer_head_text') }} />
        {!eventConfig.bg.includes('/sot/') &&
        <div
          style={{ fontSize: isTabletOrMobile ? 16 : 20, fontFamily: 'Bebas Neue', lineHeight: 1 }}
          dangerouslySetInnerHTML={{ __html: t('offer_sub_head_text') }} />
        }
        <div style={{ marginTop: 25 }}>
          <div className={classes.checkedRow}>
            <img
              src={`${conf.cdn_url}/assets/event/checkmark_${ownedCards > 0 ? 'filled' : 'empty'}.png`}
              alt="Checker circle"
            />
            <span dangerouslySetInnerHTML={{ __html: t('offer_first_quest') }} />
          </div>
          <div className={classes.checkedRow}>
            <img
              src={`${conf.cdn_url}/assets/event/checkmark_${ownedCards === offer.required_cards.length ? 'filled' : 'empty'}.png`}
              alt="Checker circle"
            />
            <div dangerouslySetInnerHTML={{
              __html: t('offer_main_quest', {
                my: ownedCards,
                max: offer.required_cards.length,
              }),
            }} />
          </div>
        </div>
        <div style={{ ...Helpers.fillRowCenter }}>
          <div
            className={classes.eventButton}
            style={{
              ...(isPossible ? {} : {
                filter: 'grayscale(100)',
                cursor: 'not-allowed',
              }),
              ...(isTabletOrMobile ? { padding: '15px 25px' } : {}),
            }}
            onClick={() => isPossible ? props.history.push(`/card-shop?offer=${offer._id}`) : null}
          >
            {t(offer.order_button_text)}
          </div>
          {withGallery &&
          <div
            className={classes.eventButton}
            style={{
              padding: 10,
              height: 44,
              width: 44,
              marginLeft: 5, ...Helpers.fillColCenter,
            }}
            onClick={() => openLightbox(0)}
          >
            <FontAwesomeIcon icon={faImages} size="lg" />
          </div>
          }
        </div>
      </div>

      {!isTabletOrMobile &&
      <div className={classes.priceBanner}>
        <span dangerouslySetInnerHTML={{ __html: t('offer_price_text') }} /> <br />
        {costs && costs.toLocaleString(language)} <span>Euro</span>
      </div>
      }

    </div>
  );
};

export default withRouter(OfferInfo);