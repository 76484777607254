import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Div100vh from 'react-div-100vh';

import Helpers from '../../../theme/Helpers';
import Colors from '../../../theme/Colors';

import conf from '../../../conf.json';

const StartLayout = (props) => {
  const { t } = useTranslation();
  const { history, cardVerification } = props;
  const { screenIsBigEnough } = cardVerification;

  const nextStep = () => {
    if (screenIsBigEnough)
      history.push('/verification/tutorial');
    else
      history.push('/verification/notice');
  };

  return (
    <Div100vh
      style={{
        backgroundImage: `url(${conf.cdn_url}/card-verification/bg/bg1.png)`,
        ...Helpers.fillColCenter,
        ...Helpers.mainSpaceBetween,
        ...Helpers.textCenter,
        backgroundColor: Colors.white,
      }}>
      <div>
        <p style={{ fontWeight: 'bold', fontSize: 20, padding: '0 40px' }}>
          {t('verification_start_title')}
        </p>
        <img src={`${conf.cdn_url}/card-verification/id-card.png`} alt="PrismaID card" style={{ width: '70%', maxWidth: 554 }} />
      </div>
      <div style={{ paddingBottom: 20 }}>
        <Button onClick={nextStep} variant="contained" color="primary" size="large">
          {t('verification_start_button')}
        </Button>
      </div>
    </Div100vh>
  );
};

const mapStateToProps = (state) => {
  const { cardVerification } = state;
  return { cardVerification };
};

export default connect(mapStateToProps)(withRouter(StartLayout));