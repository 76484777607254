import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { bindActionCreators } from 'redux';

import {
  createAlertSetting as createAlertSettingMutation,
  updateAlertSetting as updateAlertSettingMutation,
  testAlertSettings as testAlertSettingsMutation,
} from '../../apollo/mutations';
import { alertSettings } from '../../apollo/queries';

import Helpers from '../../theme/Helpers';
import AnimationButton from '../Button/AnimationButton';
import { resetAlertToolData } from '../../redux/actions';
import CopyClipboardPopup from '../Dialog/CopyClipboardPopup';

const TopBarAlertSetting = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { pathname, alertTool, t, alertWebSourceUrls } = props;
  const [showPopup, setShowPopup] = useState(false);

  const [updateAlertSetting, updateData] = useMutation(updateAlertSettingMutation, {
    onCompleted: () => {
      onCompletedAction();
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [
      { query: alertSettings },
    ],
  });

  const [createAlertSetting, createData] = useMutation(createAlertSettingMutation, {
    onCompleted: () => {
      onCompletedAction();
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [
      { query: alertSettings },
    ],
  });

  const [testAlertSettings, testData] = useMutation(testAlertSettingsMutation, {
    onCompleted: () => {
      enqueueSnackbar(t('success'), { variant: 'success' });
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
  });

  const onCompletedAction = () => {
    enqueueSnackbar(t('success'), { variant: 'success' });
    props.resetAlertToolData();
    props.history.push('/streamer-settings/alerts');
  };

  const moveToCreation = () => {
    props.resetAlertToolData();
    props.history.push('/streamer-settings/alerts/create');
  };

  const saveAlertSettings = (isPublish = false) => {
    if (alertTool.discordSettings)
      delete alertTool.discordSettings.__typename;
    if (alertTool.twitchSettings) {
      delete alertTool.twitchSettings.__typename;
      if (alertTool.twitchSettings.alert_sound)
        delete alertTool.twitchSettings.alert_sound.__typename;
      if (alertTool.twitchSettings.text_to_speech)
        delete alertTool.twitchSettings.text_to_speech.__typename;
    }

    let { twitchSettings, minQuality, maxQuality, type } = alertTool;
    if (!twitchSettings)
      twitchSettings = {
        text: '',
        permissions: false,
      };
    if (!twitchSettings.text_to_speech)
      twitchSettings.text_to_speech = {
        voice: 'Joey',
        font_size: 16,
      };
    if (!twitchSettings.alert_sound)
      twitchSettings.alert_sound = {
        sound: '',
        volume: 10,
      };

    const input = {
      active: isPublish,
      type: alertTool.type,
      purchase_min_amount_cards: parseInt(alertTool.cardsAmount),
      card_rarity: alertTool.cardRarity,
      community_rank_up: alertTool.communityRankUp,
      min_card_quality: type === 'BoosterOpen' ? (minQuality ? minQuality : 1) : null,
      max_card_quality: type === 'BoosterOpen' ? (maxQuality ? maxQuality : 100) : null,
      show_on_twitch: alertTool.showTwitch,
      show_on_discord: alertTool.showDiscord,
      twitch_settings: twitchSettings,
      discord_settings: alertTool.discordSettings,
    };

    const variables = { alert_setting: input };
    if (alertTool.id) {
      variables.alert_setting._id = alertTool.id;
      updateAlertSetting({ variables });
    } else
      createAlertSetting({ variables });
  };

  const loading = (updateData && updateData.loading) || (createData && createData.loading);

  return (
    <>
      {pathname === '/streamer-settings/alerts' &&
      <>
        <AnimationButton
          status="transparent"
          style={{
            fontSize: 14,
            fontFamily: 'Montserrat',
            fontWeight: 500,
            padding: '8px 30px',
            marginRight: 8,
          }}
          onClick={moveToCreation}
        >
          {t('add_variation')}
        </AnimationButton>
        <AnimationButton
          status="neonRed"
          style={{
            fontSize: 14,
            fontFamily: 'Montserrat',
            fontWeight: 500,
            padding: '8px 30px',
            marginRight: 8,
          }}
          disabled={testData.loading}
          onClick={() => testAlertSettings()}
        >
          {testData.loading ?
            <i className="fa-light fa-loader fa-spin" style={{ fontSize: 18 }} />
            :
            t('test_alerts')
          }
        </AnimationButton>
        <AnimationButton
          status="whiteTransparency"
          style={{ marginRight: 24, padding: '8px 6px' }}
          onClick={() => setShowPopup(true)}
        >
          <i className="fa-light fa-link" style={{ fontSize: 18 }} />
        </AnimationButton>
      </>
      }
      {(alertTool.type && pathname.includes('/streamer-settings/alerts/')) &&
      <div style={{ ...Helpers.fillRowCross }}>
        <AnimationButton
          status="whiteTransparency"
          style={{ marginRight: 8, padding: '8px 10px' }}
          disabled={(!alertTool.cardsAmount && !alertTool.cardRarity && !alertTool.minQuality && !alertTool.communityRankUp) || loading}
          onClick={() => saveAlertSettings()}
        >
          {loading ?
            <i className="fa-light fa-loader fa-spin" style={{ fontSize: 18 }} />
            :
            t('save')
          }
        </AnimationButton>
        <AnimationButton
          status="neonRed"
          style={{ padding: '8px 10px', marginRight: 24 }}
          disabled={(!alertTool.showTwitch && !alertTool.showDiscord && !alertTool.discordSettings && !alertTool.twitchSettings) || loading}
          onClick={() => saveAlertSettings(true)}
        >
          {loading ?
            <i className="fa-light fa-loader fa-spin" style={{ fontSize: 18 }} />
            :
            t('save_publish')
          }
        </AnimationButton>
      </div>
      }
      {showPopup &&
      <CopyClipboardPopup handleClose={() => setShowPopup(false)} type="all"
                          webSourceUrls={alertWebSourceUrls} />
      }
    </>
  );
};

const mapStateToProps = (state) => {
  const { alertTool } = state;
  return { alertTool };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    resetAlertToolData,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TopBarAlertSetting));