import React from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import Loading from '../Loading/Loading';
import CardShopAlbumCard from './CardShopAlbumCard';

const CardShopMatchCardLeftSideMenuCards = (props) => {
  const { t } = useTranslation();
  const { loading, offer } = props;

  const settings = {
    className: 'center',
    centerMode: true,
    dots: false,
    speed: 500,
    centerPadding: '4px',
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    infinite: false,
    initialSlide: 1,
  };

  return (
    loading ?
      <Loading />
      :
      offer &&
      <Slider {...settings}>
        {offer.required_booster.map(booster => booster.generic_cards.map(card => (
          <CardShopAlbumCard
            t={t}
            card={{ ...card, match: offer.match, image: card.card_layer_image }}
          />
        )))}
      </Slider>
  );
};

export default CardShopMatchCardLeftSideMenuCards;