import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import HRNumbers from 'human-readable-numbers';
import Avatar from '@material-ui/core/Avatar';
import { useQuery } from '@apollo/react-hooks';

import { getSupporterMetadata } from '../../apollo/queries';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

const useStyles = makeStyles(() => ({
  supportArea: {
    padding: '13px 17.5px',
    ...Helpers.fillRowCenter,
    fontSize: 12,
    color: Colors.halfWhite,
    height: 'calc(100% - 26px)',
    '& span': {
      color: Colors.white,
    },
  },
  avatar: {
    height: 26,
    width: 26,
    borderWidth: 4,
    borderColor: 'rgba(255, 255, 255, 0.2)',
    zIndex: 0,
  },
}));

const TopBarSupporter = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { data, loading } = useQuery(getSupporterMetadata, {
    fetchPolicy: useCacheWithExpiration('getSupporterMetadata'),
  });

  const supporterData = data && data.getSupporterMetadata;

  if (loading || !supporterData)
    return null;

  return (
    <div style={{ borderLeft: '3px solid rgb(1, 16, 36)' }}>
      <div className={classes.supportArea}>
        <div style={{ marginRight: 12, ...Helpers.fillRowCenter }}>
          {supporterData.highlighted.map((item, index) => (
            <Avatar
              key={`TopBarSupporterKey${item.profile_image_url + index}`}
              className={classes.avatar}
              style={{ marginLeft: index > 0 ? -18 : 0, zIndex: index }}
              alt="Avatar"
              src={item.profile_image_url}
            />
          ))}
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: t('x_users_supported_sh', { count: HRNumbers.toHumanString(supporterData.supporters) }) }} />
      </div>
    </div>
  );
};

export default TopBarSupporter;