import React, { useState } from 'react';
import {
  makeStyles,
  Table, TableBody, TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import generator from 'generate-password';
import CryptoJS from 'crypto-js';
import ImageUploader from 'react-images-upload';

import Helpers from '../../theme/Helpers';
import CustomButton from '../Button/CustomButton';
import Loading from '../Loading/Loading';

const useStyles = makeStyles(() => ({
  textField: {
    width: 250,
    margin: 10,
  },
}));

const LicenseAdministration = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { createLicense, loading, licenses, setFile, file } = props;
  const defaultValues = {
    name: '',
    address: '',
    login: '',
    password: '',
    cryptPassword: '',
    shares: 0,
  };
  const [formValues, setFormValues] = useState(defaultValues);

  const fields = [
    { id: 'name', type: 'text' },
    { id: 'address', type: 'text' },
    { id: 'shares', type: 'number' },
    { id: 'login', type: 'text' },
    { id: 'password', type: 'text', readOnly: true },
  ];

  const handleChange = (event) => {
    const { name, value } = event.target;
    const tmpValues = { ...formValues };
    tmpValues[name] = name === 'shares' ? parseInt(value) : value;
    setFormValues(tmpValues);
  };

  const create = async () => {
    if (formValues.password === '' || formValues.name === '')
      return;

    await createLicense({ ...formValues, password: formValues.cryptPassword });
    setFormValues(defaultValues);
  };

  const generatePassword = () => {
    const password = generator.generate({
      length: 10,
      numbers: true,
      symbols: true,
      excludeSimilarCharacters: true,
      strict: true,
    });
    const cryptPassword = CryptoJS.SHA256(password)
    .toString(CryptoJS.enc.Base64);

    setFormValues({ ...formValues, password, cryptPassword });
  };

  const onDrop = async (files) => {
    if (files.length > 0)
      setFile(files[files.length - 1]);
  };

  return (
    <div style={{ ...Helpers.fillRow, ...Helpers.mainSpaceBetween }}>
      <div style={{ ...Helpers.fillCol, marginRight: 10 }}>
        <div style={{ fontSize: 20, fontWeight: 'bold', margin: 10 }}>
          {t('license_creation_head')}
        </div>
        {loading ?
          <div style={{ margin: 10 }}>
            <Loading />
          </div>
          :
          <>
            {fields.map(field => (
              <TextField
                key={field.id}
                id={field.id}
                name={field.id}
                label={t(field.id)}
                variant="outlined"
                type={field.type}
                onChange={handleChange}
                value={formValues && formValues[field.id]}
                className={classes.textField}
                InputProps={{
                  readOnly: field.readOnly,
                }}
              />
            ))}
            <div style={{ margin: 10, ...Helpers.fillColCenter }}>
              {file &&
              <img
                src={URL.createObjectURL(file)}
                alt={`PreviewUploadedImage`}
                style={{ maxHeight: 100, maxWidth: 100, width: 'auto', height: 'auto' }}
              />
              }
              <ImageUploader
                withIcon={false}
                buttonText={t('choose_image')}
                onChange={onDrop}
                fileSizeError={t('file_size_error')}
                fileTypeError={t('file_extension_not_supported')}
                imgExtension={['.jpg', '.png', '.jpeg']}
                maxFileSize={5000000}
                singleImage
                errorStyle={{ color: 'red' }}
                buttonStyles={{
                  backgroundColor: '#cc1e31',
                  borderRadius: 3,
                  padding: '5px 20px',
                  fontFamily: 'Bebas Neue',
                  fontSize: 20,
                  width: '100%',
                }}
                labelStyles={{
                  color: '#6f6f6f',
                  fontSize: 12,
                  textTransform: 'uppercase',
                }}
                fileContainerStyle={{
                  backgroundColor: 'transparent',
                  padding: 0,
                }}
              />
            </div>
            <CustomButton style={{ margin: 10 }} onClick={generatePassword}>
              {t('generate_new_password')}
            </CustomButton>
            <CustomButton
              style={{ margin: 10 }}
              onClick={create}
              disabled={formValues.password === '' || formValues.name === ''}
            >
              {t('create')}
            </CustomButton>
          </>
        }
      </div>
      <div style={{ marginLeft: 10 }}>
        <div style={{ fontSize: 20, fontWeight: 'bold', margin: 10 }}>
          {t('license_list_head')}
        </div>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>{t('name')}</TableCell>
                <TableCell>{t('address')}</TableCell>
                <TableCell>{t('shares')}</TableCell>
                <TableCell>{t('login')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {licenses && licenses.map((license) => (
                <TableRow key={license._id}>
                  {license.logo &&
                  <TableCell>
                    <img src={license.logo} alt="Logo" style={{ height: 40, width: 'auto' }} />
                  </TableCell>
                  }
                  <TableCell colSpan={license.logo ? 1 : 2}>
                    {license.name}
                  </TableCell>
                  <TableCell>
                    {license.address}
                  </TableCell>
                  <TableCell>
                    {license.shares} %
                  </TableCell>
                  <TableCell>
                    {license.login}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default LicenseAdministration;