import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getMyHeroMakerImages } from '../../apollo/queries';

import HeroMaker from '../../components/HeroMaker/HeroMaker';
import HeroMakerDialogContainer from './HeroMakerDialog';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import { resetHeroMaker } from '../../redux/actions';
import TopBG from '../../components/TopViewHeader/TopBG';
import Colors from '../../theme/Colors';

const HeroMakerContainer = (props) => {
  const { viewer } = props;
  const [showCreation, setShowCreation] = useState(false);
  const [selectedHero, setSelectedHero] = useState(null);

  const { loading, data, refetch } = useQuery(getMyHeroMakerImages, {
    fetchPolicy: useCacheWithExpiration('getMyHeroMakerImages'),
  });

  const poses = data && data.getMyHeroMakerImages;

  return (
    <TopBG disableBreadcrumbs style={{ color: Colors.white }}>
      <HeroMaker
        loading={loading}
        poses={poses}
        showCreation={() => setShowCreation(true)}
        setSelectedHero={setSelectedHero}
      />
      {showCreation &&
      <HeroMakerDialogContainer
        handleClose={() => {
          setShowCreation(false);
          if (selectedHero) {
            setSelectedHero(null);
            props.resetHeroMaker();
          }
        }}
        posesCount={poses ? poses.length : 0}
        selectedHero={selectedHero}
        viewer={viewer}
        refetchHeroes={refetch}
      />
      }
    </TopBG>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    resetHeroMaker,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(HeroMakerContainer);