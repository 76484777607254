import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import { collectQuestReward as collectQuestRewardMutation } from '../../apollo/mutations';
import { getHubDashboard } from '../../apollo/mergedQueries';

import HubQuestItem from './HubQuestItem';
import Helpers from '../../theme/Helpers';
import { multiplyEnergyCoins } from '../../utils/helper';

const useStyles = makeStyles((theme) => ({
  twoColumnGrid: {
    ...Helpers.fillRowCross,
    gap: 12,

    '& > div': {
      flex: 1,

      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },

    [theme.breakpoints.down('md')]: {
      ...Helpers.fillCol,
      gap: '8px 0',
    },
  },
}));

const HubQuest = (props) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const {
    t,
    questProgress,
    myViewer,
    collectCoinsInfo,
    collectCoins,
    collectLoading,
    isPremiumActive,
  } = props;

  const [collectQuestReward, { loading }] = useMutation(collectQuestRewardMutation, {
    onCompleted: (data) => {
      const { collectQuestReward } = data;
      enqueueSnackbar(t('complete_quest_success'), { variant: 'success' });
      if (collectQuestReward.coins > 0) {
        props.history.push('/quest-reward/coins');
      } else if (collectQuestReward.cards && collectQuestReward.cards.length > 0) {
        const { cards } = collectQuestReward;
        props.history.push(`/quest-reward/cards/${cards[0].match_card._id}/${cards[0].viewer._id}`);
      }
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{
      query: getHubDashboard,
      variables: { pagination: { page: 1, perPage: 10 } },
    }],
  });

  const completeQuest = (type) => {
    collectQuestReward({ variables: { type } });
  };

  questProgress.coins = collectCoinsInfo.amount_to_collect > 0 ? 100 : 0;
  questProgress.coins_title = 'daily_coins_quest_title';
  questProgress.coins_text = 'daily_coins_quest_text';
  questProgress.coins_count = multiplyEnergyCoins(collectCoinsInfo.amount_to_collect);
  questProgress.coins_last_time = collectCoinsInfo.last_collected_reward;
  questProgress.coins_reward_collected = collectCoinsInfo.amount_to_collect === 0;

  return (
    <div className={classes.twoColumnGrid}>
      <HubQuestItem t={t} questProgress={questProgress} type="coins" loading={collectLoading}
                    completeQuest={collectCoins} myViewer={myViewer} isPremiumActive={isPremiumActive} />
      <HubQuestItem t={t} questProgress={questProgress} type="daily" loading={loading}
                    completeQuest={completeQuest} myViewer={myViewer} />
      <HubQuestItem t={t} questProgress={questProgress} type="weekly" loading={loading}
                    completeQuest={completeQuest} myViewer={myViewer} />
    </div>
  );
};

export default withRouter(HubQuest);