import React from 'react';
import { StickyContainer } from 'react-sticky';

import Colors from '../../theme/Colors';
import ImageCarousel from '../Carousel/ImageCarousel';
import Helpers from '../../theme/Helpers';
import ItemStoreBoostersCompact from './ItemStoreBoostersCompact';

import conf from '../../conf.json';
import { isUserPremium } from '../../utils/helper';
import Symplr from '../AdSense/Symplr';
import AdSense from '../AdSense/AdSense';

const ItemStore = (props) => {
  const {
    boosters,
    t,
    loading,
    trendingBoosters,
    myViewer,
    starterPacks,
    tab,
  } = props;

  const images = [
    {
      img: `${conf.cdn_url}/assets/slider/item-store-slider-step-00-bg.png`,
      header: 'item_store_slider1_header',
      body: 'item_store_slider1_body',
    }, {
      img: `${conf.cdn_url}/assets/slider/item-store-slider-step-02-bg.png`,
      header: 'item_store_slider2_header',
      body: 'item_store_slider2_body',
    },
  ];

  const { isPremiumActive } = isUserPremium(myViewer);

  return (
    <div style={{ position: 'relative', color: Colors.white }}>

      <div style={{ position: 'relative' }}>
        <ImageCarousel images={images} />

        {(conf.ad_area.item_store && myViewer && !isPremiumActive) &&
        <div
          style={{
            position: 'absolute',
            right: 8,
            bottom: 8,
          }}
        >
          <Symplr id="streamheroes.gg_mr_1" />
        </div>
        }
      </div>

      <div className="inner-wrapper">
        <StickyContainer style={{ marginTop: 20, ...Helpers.fillRow, minHeight: 400 }}>

          <ItemStoreBoostersCompact t={t} boosters={boosters}
                                    tab={tab}
                                    trendingBoosters={trendingBoosters} loading={loading}
                                    myViewer={myViewer}
                                    starterPacks={starterPacks} />
        </StickyContainer>
      </div>

    </div>
  );
};

export default ItemStore;