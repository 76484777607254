import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import { zeroPad } from 'react-countdown';

const useStyles = makeStyles(() => ({
  mainTimeArea: {
    ...Helpers.fillRowCenter,
    color: Colors.white,
    display: 'flex',
    gap: 20,
  },
}));

const SeasonCountdown = (props) => {
  const classes = useStyles();
  const { t, seasonData } = props;

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const untilMinutes = seasonData && moment(parseInt(seasonData.available_until))
    .diff(moment(), 'minutes');
    setCounter(untilMinutes > 0 ? untilMinutes : 0);
  }, [seasonData]);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 60000);
  }, [counter]);

  let d = 0, h = 0, m = 0;
  if (counter > 0) {
    d = Math.floor(counter / (60 * 24));
    h = Math.floor(counter % (60 * 24) / 60);
    m = Math.floor(counter % 60);
  }

  const getAreaTime = (unit, text) => {
    if (unit > 99)
      unit = 99;

    return (
      <div style={{ ...Helpers.fillColCenter }}>
        <div style={{ fontSize: 24, fontWeight: 500 }}>
          {zeroPad(unit)}
        </div>
        <div style={{
          fontSize: 10,
          marginTop: 2,
          color: Colors.halfWhite,
          textTransform: 'uppercase',
        }}>
          {text}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.mainTimeArea}>
      {getAreaTime(d, t('days'))}
      <div style={{ fontSize: 24, fontWeight: 500 }}>:</div>
      {getAreaTime(h, t('hours'))}
      <div style={{ fontSize: 24, fontWeight: 500 }}>:</div>
      {getAreaTime(m, t('minutes'))}
    </div>
  );
};

export default SeasonCountdown;