import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faUserSecret } from '@fortawesome/free-solid-svg-icons';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import { replaceWidthAndHeight } from '../../utils/helper';
import FlagIcon from '../FlagIcon/FlagIcon';
import { returnMobileObj } from '../../utils/responsive';
import CustomTooltip from '../Tooltip/CustomTooltip';
import SupporterRank from '../Ranking/SupporterRank';

const useStyles = makeStyles(() => ({
  timeContainer: {
    color: '#79859a',
  },
  container: {
    ...Helpers.fillCol,
    ...Helpers.mainSpaceEvenly,
    marginTop: 30,
    padding: 20,
  },
  avatar: {
    width: 100,
    height: 100,
    borderRadius: 50,
    border: '3px solid #656970',
    transition: 'all 0.3s ease',
  },
  item: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    color: Colors.white,
    textDecoration: 'none',
    transition: 'all 0.3s ease',
    position: 'relative',
    padding: 14,
    borderRadius: 10,
  },
  flag: {
    margin: 'auto 5px',
    border: '1px solid #501427',
  },
  firstFlag: {
    position: 'absolute',
    left: 10,
    bottom: 15,
    border: '1px solid #501427',
    zIndex: 5,
  },
  position: {
    color: Colors.white,
    fontFamily: 'Bebas Neue',
    fontSize: 25,
    marginRight: 15,
  },
  firstPosition: {
    position: 'absolute',
    right: 15,
    bottom: -30,
    color: '#040F22',
    fontSize: 100,
    fontWeight: 'bold',
    fontFamily: 'Bebas Neue',
  },
  title: {
    textOverflow: 'ellipsis',
    width: '100%',
    whiteSpace: 'nowrap',
    maxWidth: 275,
    overflow: 'hidden',
    position: 'relative',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  points: {
    color: '#79859a',
  },
  anonymousArea: {
    border: '2px solid #a90b27',
    borderRadius: 100,
    width: 80,
    height: 80,
    marginRight: 20,
    ...Helpers.textCenter,
    color: Colors.white + ' !important',
    transition: 'all 0.3s ease',
  },
  anonymousFlag: {
    position: 'absolute',
    bottom: 40,
    right: 29,
    width: 20,
    height: 20,
    color: Colors.white + ' !important',
    '&:hover': {
      color: Colors.secondary + ' !important',
    },
  },
  headTitle: {
    fontFamily: 'Bebas Neue',
    fontSize: 34,
    lineHeight: 1,
  },
  headSubTitle: {
    color: '#79859a',
    lineHeight: 1,
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    marginBottom: 20,
  },
}));

/**
 * @return {null}
 */
function LeaderBoardRowList(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { entries, time, type, title, isTabletOrMobile } = props;

  if (!entries || (entries && entries.length === 0)) {
    return null;
  }

  let keyForListItem = '', subKey = '', isGame = false;

  if (entries[0].viewer) {
    keyForListItem = 'viewer';
  } else if (entries[0].match) {
    keyForListItem = 'match';
    if (entries[0].matchModel === 'Game')
      isGame = true;
  }

  let lastRefresh = null;
  if (time) lastRefresh = moment.unix(time / 1000)
  .format('DD.MM.YY HH:mm:ss');
  else {
    const latestTime = Math.max.apply(Math, entries.map(function (o) {
      return o.updated_at;
    }));
    if (!isNaN(latestTime))
      lastRefresh = moment.unix(latestTime / 1000)
      .format('DD.MM.YY HH:mm:ss');
  }
  const { language } = navigator;

  return (
    <>
      <div
        className={classes.container}
        style={returnMobileObj(isTabletOrMobile, { padding: 10, zoom: 0.8, marginTop: 10 })}
      >
        <div className={classes.headTitle}>
          {type && <div>{type}</div>}
          {title && title}
        </div>
        <div className={classes.headSubTitle}>
          {type && t('top_collector')}
          {lastRefresh &&
          <div>{t('last_updated')} {lastRefresh}</div>
          }
        </div>
        {entries.map((item, index) => {
          if (item[keyForListItem]) {
            const object = keyForListItem === 'entry' ? item.entry[subKey] : item[keyForListItem];
            const imgUrl = isGame ? replaceWidthAndHeight(object.box_art_url, 80, 100) : object.profile_image_url;
            const name = isGame ? object.name : object.display_name;
            const link = isGame ? `/hq/game-streams/${object.name}` : `/${object.__typename.toLowerCase()}/${object.login}`;
            const premiumFrame = !isGame && object.premium_frame;

            const isAnonymous = isGame ? false : (object.login === 'anonymous' && object.profile_image_url === '');

            return (
              <Link
                to={isAnonymous ? '#' : link}
                key={object._id}
                className={classes.item}
                style={index % 2 === 0 ? { background: index === 0 ? '#030B18' : '#041022' } : {}}
              >
                <div style={{ ...Helpers.fillRowCenter }}>
                  <div className={index > 0 ? classes.position : classes.firstPosition}>
                    #{index + 1}
                  </div>
                  {(index === 0) ?
                    isAnonymous ?
                      <div className={classes.anonymousArea}>
                        <FontAwesomeIcon icon={faUserSecret} size="3x" style={{ marginTop: 13 }} />
                      </div>
                      :
                      <>
                        {premiumFrame &&
                        <div>
                          <img src={`${premiumFrame}?height=115`}
                               style={{ position: 'absolute', top: -2, left: -7 }} alt="Frame" />
                        </div>
                        }
                        <img
                          src={imgUrl}
                          alt="Avatar"
                          className={classes.avatar}
                          style={{
                            ...(isGame ? { borderRadius: 5, width: 60, height: 80 } : {
                              width: 80,
                              height: 80,
                            }),
                            marginRight: 10,
                            borderColor: premiumFrame ? 'transparent' : '#a90b27',
                            borderWidth: 2,
                            zIndex: 1,
                          }}
                        />
                      </>
                    : null
                  }
                  {((subKey === 'viewer' || keyForListItem === 'viewer') && object.country && !isAnonymous) &&
                  <div className={index === 0 ? classes.firstFlag : classes.flag}>
                    <FlagIcon code={object.country.toLowerCase()} />
                  </div>
                  }
                  {isAnonymous &&
                  <div className={classes.anonymousFlag}>
                    <CustomTooltip title={t('muted_streamer_of_viewer')}>
                      <div style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon icon={faInfoCircle} size="sm" />
                      </div>
                    </CustomTooltip>
                  </div>
                  }
                  <div>
                    <div style={Helpers.fillRowCenter}>
                      <div className={classes.title} style={index === 0 ? { fontSize: 34 } : {}}>
                        {name}
                      </div>
                      {!isGame &&
                      <div style={{ marginLeft: 5, zIndex: 10 }}>
                        <SupporterRank subscription={object.subscription} />
                      </div>
                      }
                    </div>
                    {index === 0 &&
                    <div className={classes.points}>
                      {t('points_x', { count: keyForListItem === 'entry' ? item.entry.seq.toLocaleString(language) : item.seq.toLocaleString(language) })}
                    </div>
                    }
                  </div>
                </div>
                {index > 0 &&
                <div className={classes.points}>
                  {t('points_x', { count: keyForListItem === 'entry' ? item.entry.seq.toLocaleString(language) : item.seq.toLocaleString(language) })}
                </div>
                }
              </Link>
            );
          }
          return null;
        })}
      </div>
    </>
  );
}

LeaderBoardRowList.propTypes = {
  entries: PropTypes.arrayOf(PropTypes.object),
};

export default LeaderBoardRowList;