import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import ProfileShowcase from '../../components/Profile/ProfileShowcase';
import { getPublicDecks } from '../../apollo/queries';

const ProfileShowcaseContainer = (props) => {
  const { t } = useTranslation();
  const { viewer } = props;

  const { data, loading } = useQuery(getPublicDecks, {
    variables: { id: viewer._id },
    fetchPolicy: useCacheWithExpiration('getPublicDecks', { id: viewer._id }),
  });

  const cardDecks = data && data.getPublicDecks;

  return (
    <ProfileShowcase cardDecks={cardDecks} t={t} loading={loading} viewer={viewer} />
  );
};

export default withRouter(ProfileShowcaseContainer);