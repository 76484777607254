import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import { fabric } from 'fabric';

import { getMyHeroMakerImages } from '../../apollo/queries';

import Helpers from '../../theme/Helpers';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import AnimationButton from '../Button/AnimationButton';
import Loading from '../Loading/Loading';
import CardToolHeroMakerPoses from './CardToolHeroMakerPose';

import conf from '../../conf.json';

const CardToolHeroMaker = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    setCanvasLoading,
    canvas,
    checkIfObjectExists,
    setActiveHero,
    fitSizeOfUploadedImage,
    checkSortingOfObjects,
    setActiveUserKey,
    addActionStep,
    calculateAllImagesDPI,
  } = props;

  const { loading, data } = useQuery(getMyHeroMakerImages, {
    fetchPolicy: useCacheWithExpiration('getMyHeroMakerImages'),
  });

  const poses = data && data.getMyHeroMakerImages;

  const updateHero = async (hero) => {
    const imgUrl = hero.image.replace(conf.cdn_url, conf.no_cors_cdn_url);
    setActiveHero(imgUrl);

    const heroObj = checkIfObjectExists('active-hero');
    if (heroObj)
      canvas.remove(heroObj);

    setCanvasLoading(true);
    await fabric.Image.fromURL(imgUrl, (heroObj) => {
      heroObj.id = 'active-hero';
      heroObj.onSelect = () => setActiveUserKey(3);
      fitSizeOfUploadedImage(heroObj);

      addActionStep({ type: 'addActiveHero' });
      canvas.add(heroObj);
      canvas.sendToBack(heroObj);
      checkSortingOfObjects();
      calculateAllImagesDPI();
    }, {
      crossOrigin: 'Anonymous',
    });
  };

  return (
    <div
      className={classes.wrapper}
      style={(poses && poses.length > 0) ?
        {
          ...Helpers.fillColCross,
          backgroundImage: `url("${conf.cdn_url}/assets/template/heroMaker/hero-maker-card-editor-banner-2.png")`,
          height: 228,
          paddingBottom: 0,
        } : {}}
    >
      {loading ?
        <Loading />
        :
        (poses && poses.length > 0) ?
          <>
            <div style={{ ...Helpers.fillRowCross, ...Helpers.mainSpaceBetween, width: '100%' }}>
              <div style={{ fontSize: 26, fontFamily: 'Bebas Neue' }}>{t('hero_maker_poses')}</div>
              <AnimationButton
                status="red"
                style={{
                  borderRadius: 50,
                  padding: '8px 20px',
                  fontSize: 12,
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                }}
                onClick={() => props.history.push('/streamer-settings/hero-maker')}
              >
                {t('create_more')}
              </AnimationButton>
            </div>
            <div className={classes.posesArea}>
              <CardToolHeroMakerPoses poses={poses} updateHero={updateHero} />
            </div>
          </>
          :
          <>
            <div style={{ fontSize: 26, fontFamily: 'Bebas Neue' }}>{t('hero_maker')}</div>
            <AnimationButton
              status="red"
              style={{
                borderRadius: 50,
                padding: '8px 26px',
                fontSize: 12,
                fontFamily: 'Montserrat',
                fontWeight: 'bold',
              }}
              onClick={() => props.history.push('/streamer-settings/hero-maker')}
            >
              {t('create_your_hero_now')}
            </AnimationButton>
          </>
      }
    </div>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    ...Helpers.fillColCenter,
    height: 144,
    padding: 15,
    backgroundImage: `url("${conf.cdn_url}/assets/template/heroMaker/hero-maker-card-editor-banner.png")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: 8,
    marginBottom: 16,
  },
  posesArea: {
    marginTop: 10,
    height: 184,
    width: '100%',
    ...Helpers.fillRowCross,
    ...Helpers.mainSpaceBetween,
    ...Helpers.crossStart,
    flexWrap: 'wrap',
    overflowY: 'auto',
  },
}));

export default withRouter(CardToolHeroMaker);