import React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import {
  getMarketPlaceCardData,
  getMarketPlaceOffer,
  getMarketPlaceStats,
  marketPlaceOffers,
} from '../../apollo/mergedQueries';
import { marketPlaceMyHistory } from '../../apollo/queries';
import {
  buyMarketPlaceItem as buyMarketPlaceItemMutation,
  createMarketplaceOffer as createMarketplaceOfferMutation,
} from '../../apollo/mutations';

import MarketplaceBuySellDialog from '../../components/Marketplace/MarketplaceBuySellDialog';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import { resetMarketplaceData } from '../../redux/actions';

const MarketplaceBuySellDialogContainer = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { marketplace } = props;
  const rCard = marketplace.rCard;

  const { data: cardData, loading: cardLoading } = useQuery(getMarketPlaceCardData, {
    variables: {
      days: 7,
      quality: marketplace.quality,
      id: marketplace.cardId,
      pagination: { page: 1, perPage: 25 },
    },
    fetchPolicy: useCacheWithExpiration('getMarketPlaceCardData'),
    skip: marketplace.offerId,
  });

  const { data: offerData, loading: offerLoading } = useQuery(getMarketPlaceOffer, {
    variables: {
      days: 7,
      front_hash: marketplace.frontHash,
      id: marketplace.offerId,
      pagination: { page: 1, perPage: 25 },
    },
    fetchPolicy: useCacheWithExpiration('getMarketPlaceOffer'),
    skip: marketplace.cardId,
  });

  const reFetchQueries = [
    {
      query: getMarketPlaceStats,
      variables: { timeframe: 1, pagination: { page: 1, perPage: 25 }, rarity: null },
    },
    {
      query: marketPlaceOffers,
      variables: {
        pagination: { page: 1, perPage: 25 },
        is_seasonal: false,
        sorting: {
          field: 'created_at',
          order: 'DESC',
        },
        rarity: [],
        min_quality: 0,
        max_quality: 100,
      },
    },
    {
      query: marketPlaceMyHistory,
      variables: {
        pagination: { page: 1, perPage: 25 },
        is_seasonal: false,
        sorting: {
          field: 'created_at',
          order: 'DESC',
        },
        rarity: [],
        min_quality: 0,
        max_quality: 100,
        type: 'active',
      },
    },
  ];

  const onReset = () => {
    props.resetMarketplaceData();
  };

  const [buyMarketPlaceItem, { loading: buyLoading }] = useMutation(buyMarketPlaceItemMutation, {
    onCompleted: () => {
      enqueueSnackbar(t('success'), { variant: 'success' });
      onReset();
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => reFetchQueries,
  });

  const buyOffer = (id) => {
    buyMarketPlaceItem({ variables: { id } });
  };

  const [createMarketplaceOffer, { loading: sellLoading }] = useMutation(createMarketplaceOfferMutation, {
    onCompleted: () => {
      enqueueSnackbar(t('success'), { variant: 'success' });
      onReset();
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => reFetchQueries,
  });

  const sellOffer = (offer) => {
    createMarketplaceOffer({ variables: { offer } });
  };

  const offerMarketData = offerData && offerData.marketPlaceOffer;
  const cardInfosData = cardData && cardData.marketPlaceCardData;
  const totalData = offerData ? offerData : cardData ? cardData : null;
  const salesHistory = totalData && totalData.marketPlaceProductSalesHistory;
  const listingCoupons = totalData && totalData.marketPlaceListingCoupons;
  const myViewer = offerData && offerData.getMyViewer;

  return (
    <MarketplaceBuySellDialog
      offerData={cardInfosData ? { offer_match_model_meta_data: rCard ? rCard : cardInfosData } : offerMarketData}
      salesHistory={salesHistory}
      loading={cardLoading || offerLoading}
      buyLoading={buyLoading}
      sellLoading={sellLoading}
      buyOffer={buyOffer}
      sellOffer={sellOffer}
      price={marketplace.price}
      avgPrice={marketplace.avgPrice}
      resetData={onReset}
      myViewer={myViewer}
      listingCoupons={listingCoupons}
    />
  );
};

const mapStateToProps = (state) => {
  const { collectorsView } = state;
  return { collectorsView };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    resetMarketplaceData,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(MarketplaceBuySellDialogContainer);