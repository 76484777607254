import React from 'react';
import { SRLWrapper } from 'simple-react-lightbox';

const MuiImageMessage = (props) => {
  const { message } = props;
  const { images } = message;

  return (
    <SRLWrapper>
      {images.map((f, index) => {
        const url = typeof f === 'string' ? f : URL.createObjectURL(f);
        const altName = typeof f === 'string' ? `Image ${index}` : f.name;

        return (
          <a href={url} key={index}>
            <img
              src={url}
              alt={altName}
              style={{ maxWidth: 200, maxHeight: 200, margin: '0 5px' }}
            />
          </a>
        );
      })}
    </SRLWrapper>
  );
};

export default MuiImageMessage;