import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableCell } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';

import Colors from '../../theme/Colors';
import AnimationButton from '../Button/AnimationButton';
import conf from '../../conf.json';
import SVGIcon from '../SVGIcon/SVGIcon';
import Helpers from '../../theme/Helpers';

const useStyles = makeStyles({
  table: {
    fontSize: 14,

    '& td,th': {
      borderColor: '#1F2D4C',
      padding: '16.5px 32px',
    },
    '& tbody td': {
      fontWeight: 400,
      color: '#7B8598',
    },
    '& tr:last-child td': {
      borderBottom: 'none',
    },
  },
});

const PremiumFeatureList = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isSubscriber, setShowBuy } = props;

  const features = [
    {
      title: t('higher_energy_depot'),
      desc: t('higher_energy_depot_info'),
      low: true, medium: true, high: true, event: true,
      withGemIcon: true,
    },
    {
      title: t('profile_banner'),
      desc: t('profile_banner_info'),
      low: true, medium: true, high: true, event: true,
    },
    {
      title: t('hero_frame'),
      desc: t('hero_frame_info'),
      low: true, medium: true, high: true, event: true,
    },
    {
      title: t('hero_badge'),
      desc: t('hero_badge_info'),
      low: true, medium: true, high: true, event: true,
    },
    {
      title: t('monthly_free_shipping'),
      desc: t('monthly_free_shipping_info'),
      low: true, medium: true, high: true, event: true,
    },
    {
      title: t('invite_sidekick'),
      desc: t('invite_sidekick_info'),
      low: true, medium: true, high: true, event: true,
    },
    {
      title: t('booking_period'),
      desc: t('booking_period_desc'),
      low: 1, medium: 3, high: 12, event: 1,
    },
    {
      title: t('costs'),
      desc: t('costs_premium_desc'),
      low: '6.99 €/m', medium: '5.33 €/m', high: '4.00 €/m', event: '3.60 €/m',
      totalLow: 6.99, totalMed: 15.99, totalHigh: 47.99, totalEvent: 3.60,
    },
  ];

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              {t('premium_packages')}
            </TableCell>
            {/*<TableCell align="center" style={{ width: 110 }}>
              {t('black_friday_offer')}
            </TableCell>*/}
            <TableCell align="center" style={{ width: 110 }}>
              {t('basic')}
            </TableCell>
            {/*<TableCell align="center" style={{ width: 110 }}>
              {t('standard')}
            </TableCell>
            <TableCell align="center" style={{ width: 110 }}>
              {t('premium')}
            </TableCell>*/}
          </TableRow>
        </TableHead>
        <TableBody>
          {features.map((feature, index) => (
            <TableRow key={feature.title}>
              <TableCell style={{ fontWeight: 500, color: Colors.white }}>
                {t(`premium_feature_list_${index + 1}_1`)}
              </TableCell>
              <TableCell>
                {feature.withGemIcon ?
                  <div style={{ ...Helpers.fillRow }}>
                    <div style={{ whiteSpace: 'nowrap' }}>
                      {t(`premium_feature_list_${index + 1}_2_1`)}
                    </div>
                    <SVGIcon
                      src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
                      height={20}
                      width={15}
                      style={{ margin: '0 3px', verticalAlign: 'bottom' }}
                    />
                    <div>{t(`premium_feature_list_${index + 1}_2_2`)}</div>
                  </div>
                  :
                  t(`premium_feature_list_${index + 1}_2`)
                }
              </TableCell>
              {/*<TableCell align="center">
                {typeof feature.event === 'boolean' ?
                  <i
                    className={`fa-solid fa-circle-${feature.event ? 'check' : 'xmark'}`}
                    style={{ color: feature.event ? Colors.white : '#FF2D57', fontSize: 18 }}
                  />
                  :
                  feature.event.toLocaleString()
                }
                {feature.totalEvent &&
                <>
                  <br />
                  {feature.totalEvent.toLocaleString(undefined, { minimumFractionDigits: 2 })} €
                </>
                }
              </TableCell>*/}
              <TableCell align="center">
                {typeof feature.low === 'boolean' ?
                  <i
                    className={`fa-solid fa-circle-${feature.low ? 'check' : 'xmark'}`}
                    style={{ color: feature.low ? Colors.white : '#FF2D57', fontSize: 18 }}
                  />
                  :
                  feature.low.toLocaleString()
                }
                {feature.totalLow &&
                <>
                  <br />
                  {feature.totalLow} €
                </>
                }
              </TableCell>
              {/*<TableCell align="center">
                {typeof feature.medium === 'boolean' ?
                  <i
                    className={`fa-solid fa-circle-${feature.medium ? 'check' : 'xmark'}`}
                    style={{ color: feature.medium ? Colors.white : '#FF2D57', fontSize: 18 }}
                  />
                  :
                  feature.medium.toLocaleString()
                }
                {feature.totalMed &&
                <>
                  <br />
                  {feature.totalMed} €
                </>
                }
              </TableCell>
              <TableCell align="center">
                {typeof feature.high === 'boolean' ?
                  <i
                    className={`fa-solid fa-circle-${feature.high ? 'check' : 'xmark'}`}
                    style={{ color: feature.high ? Colors.white : '#FF2D57', fontSize: 18 }}
                  />
                  :
                  feature.high.toLocaleString()
                }
                {feature.totalHigh &&
                <>
                  <br />
                  {feature.totalHigh} €
                </>
                }
              </TableCell>*/}
            </TableRow>
          ))}
          <TableRow>
            <TableCell colspan={2} />
            {/*<TableCell>
              <AnimationButton
                status="red"
                style={{
                  padding: '14px 15.5px',
                  fontSize: 14,
                  border: 'none',
                  minWidth: 150,
                  borderRadius: 6,
                }}
                disabled={isSubscriber}
                onClick={() => isSubscriber ? null : setShowBuy('black_friday')}
              >
                <i className="fa-light fa-cart-plus" style={{ fontSize: 18, marginRight: 8 }} />
                {t('black_friday_offer')}
              </AnimationButton>
            </TableCell>*/}
            <TableCell>
              <AnimationButton
                status="brown"
                style={{
                  padding: '14px 15.5px',
                  fontSize: 14,
                  border: 'none',
                  minWidth: 105,
                  borderRadius: 6,
                }}
                disabled={isSubscriber}
                onClick={() => isSubscriber ? null : setShowBuy('basic')}
              >
                <i className="fa-light fa-cart-plus" style={{ fontSize: 18, marginRight: 8 }} />
                {t('basic')}
              </AnimationButton>
            </TableCell>
            {/*<TableCell>
              <AnimationButton
                status="gray"
                style={{
                  padding: '14px 15.5px',
                  fontSize: 14,
                  border: 'none',
                  minWidth: 105,
                  borderRadius: 6,
                }}
                disabled={isSubscriber}
                onClick={() => isSubscriber ? null : setShowBuy('standard')}
              >
                <i className="fa-light fa-cart-plus" style={{ fontSize: 18, marginRight: 8 }} />
                {t('standard')}
              </AnimationButton>
            </TableCell>
            <TableCell>
              <AnimationButton
                status="gold"
                style={{ padding: '14px 15.5px', fontSize: 14, border: 'none', minWidth: 105 }}
                disabled={isSubscriber}
                onClick={() => isSubscriber ? null : setShowBuy('premium')}
              >
                <i className="fa-light fa-cart-plus" style={{ fontSize: 18, marginRight: 8 }} />
                {t('premium')}
              </AnimationButton>
            </TableCell>*/}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PremiumFeatureList;