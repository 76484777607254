import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import TopStreamerItem from './TopStreamerItem';
import Loading from '../Loading/Loading';

const TopStreamer = (props) => {
  const { topStreamer, isTabletOrMobile, hasMore, onLoadMore } = props;

  return (
    <InfiniteScroll
      dataLength={topStreamer.length}
      next={onLoadMore}
      hasMore={hasMore}
      loader={<Loading />}
    >
      {topStreamer.map(item => (
        <TopStreamerItem
          topStreamerItem={item}
          key={item.streamer._id}
          isTabletOrMobile={isTabletOrMobile}
        />
      ))}
    </InfiniteScroll>
  );
};

export default TopStreamer;