import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';

import CustomButton from '../Button/CustomButton';
import Colors from '../../theme/Colors';

const AlreadyStreamhero = (props) => {
  const { t } = useTranslation();
  const { streamer, makeMeAStreamHero } = props;

  const streamHeroesType = streamer.streamheroes_type;

  return (
    streamHeroesType === 'imported' ?
      <>
        <Typography
          variant="h6"
          style={{ marginBottom: 32, color: Colors.subTextColor, fontSize: 15 }}
        >
          {t('streamer_is_imported_settings_header')}
        </Typography>
        <Typography variant="body1" color="primary" gutterBottom>
          {t('streamer_is_imported_settings_text')}
        </Typography>
        <CustomButton status="primary" onClick={makeMeAStreamHero}>
          {t('streamer_is_imported_button_text')}
        </CustomButton>
      </>
      :
      (streamer && (streamer.broadcaster_type === 'affiliate' || streamer.broadcaster_type === 'partner')) &&
      <Alert
        severity="info"
        color="error"
        style={{ marginBottom: 20 }}
        action={
          <Button color="inherit" size="small" onClick={() => props.history.push('/on-boarding')}>
            {t('on_boarding_start_setup_button')}
          </Button>
        }
      >
        {t('on_boarding_start_setup')}
      </Alert>
  );
};

export default withRouter(AlreadyStreamhero);