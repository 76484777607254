import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Button } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import moment from 'moment';

import {
  createPoll as createPollMutation,
  updatePoll as updatePollMutation,
} from '../../apollo/mutations';
import { getPolls } from '../../apollo/queries';

import PollFormAnswerList from './PollFormAnswerList';
import CustomTooltip from '../Tooltip/CustomTooltip';

const useStyles = makeStyles(theme => ({
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  button: {
    marginRight: 10,
  },
}));

const PollForm = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { poll, status, setStatus, setPoll } = props;

  const [createPoll] = useMutation(createPollMutation, {
    onCompleted: (data) => {
      enqueueSnackbar(t('create_poll_success'), { variant: 'success' });
      actionPoll('view', data.createPoll);
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{ query: getPolls }],
  });

  const [updatePoll] = useMutation(updatePollMutation, {
    onCompleted: (data) => {
      enqueueSnackbar(t('update_poll_success'), { variant: 'success' });
      actionPoll('view', data.updatePoll);
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{ query: getPolls }],
  });

  const [values, setValues] = useState({
    question: (poll && poll.title) ? poll.title : '',
    target: (poll && poll.target) ? poll.target : 'viewer',
    type: (poll && poll.type) ? poll.type : 'single',
    publishedAt: (poll && poll.published_at) ? moment.unix(poll.published_at / 1000) : null,
    finishedAt: (poll && poll.finished_at) ? moment.unix(poll.finished_at / 1000) : null,
    answers: (poll && poll.answers) ? poll.answers : [],
    answer: '',
  });

  const handleChange = (event) => {
    const { target } = event;
    const newValues = { ...values };

    newValues[target.name] = target.value;
    setValues(newValues);
  };

  const handleDateChange = (date, key) => {
    const newValues = { ...values };

    newValues[key] = date;
    setValues(newValues);
  };

  const addAnswer = (value) => {
    if (!values.answers.includes(value)) {
      const newValues = { ...values };
      newValues.answers.push(value);
      newValues.answer = '';
      setValues(newValues);
    } else {
      enqueueSnackbar(t('duplicate_answer_error'), { variant: 'error' });
    }
  };

  const deleteAnswer = (index) => {
    const newValues = { ...values };
    newValues.answers.splice(index, 1);
    setValues(newValues);
  };

  const actionPoll = (status, poll) => {
    setPoll(poll);
    setStatus(status);
  };

  const saveData = (isPublish) => {
    if (values.answers.length < 2 || values.question === '') {
      enqueueSnackbar(t('create_poll_form_error'), { variant: 'error' });
      return;
    }

    const saveObject = {
      title: values.question,
      answers: values.answers,
      is_published: isPublish,
      published_at: values.publishedAt,
      finished_at: values.finishedAt,
      type: values.type,
      target: values.target,
    };

    if (poll)
      updatePoll({ variables: { poll: poll._id, poll_input: saveObject } });
    else
      createPoll({ variables: { poll: saveObject } });
  };

  return (
    <React.Fragment>
      <div className={classes.flexRow}>
        <IconButton
          aria-label="back"
          color="secondary"
          onClick={() => actionPoll('list', null)}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </IconButton>
        <Typography variant="h3" gutterBottom style={{ marginBottom: 0 }}>
          {status === 'new' ? t('new_poll') : t('edit_poll')}
        </Typography>
      </div>
      <Grid container spacing={3}>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            color="secondary"
            id="question"
            name="question"
            label={t('polls_table_question')}
            value={values.question}
            onChange={handleChange}
            fullWidth
            multiline
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            color="secondary"
            id="answer"
            name="answer"
            onChange={handleChange}
            label={t('polls_add_answer')}
            value={values.answer}
            fullWidth
            helperText={t('poll_answer_field_helper')}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                ev.preventDefault();
                addAnswer(ev.target.value);
              }
            }}
          />

          <Typography variant="body1" gutterBottom style={{ marginBottom: 0, marginTop: 5 }}>
            {t('polls_table_answers')}*
          </Typography>

          <PollFormAnswerList answers={values.answers} deleteAnswer={deleteAnswer} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <DateTimePicker
            clearable
            ampm={false}
            value={values.publishedAt}
            disablePast
            format="DD.MM.YYYY HH:mm"
            onChange={(date) => handleDateChange(date, 'publishedAt')}
            label={t('polls_table_published_at')}
            showTodayButton
            minutesStep={5}
            helperText={t('poll_publish_field_helper')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateTimePicker
            clearable
            ampm={false}
            value={values.finishedAt}
            disablePast
            format="DD.MM.YYYY HH:mm"
            onChange={(date) => handleDateChange(date, 'finishedAt')}
            label={t('polls_table_finished_at')}
            minutesStep={5}
            helperText={t('poll_finish_field_helper')}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl component="fieldset" required>
            <FormLabel component="legend">{t('polls_table_target')}</FormLabel>
            <RadioGroup
              aria-label="target"
              name="target"
              value={values.target}
              onChange={handleChange}
            >
              <FormControlLabel value="viewer" control={<Radio />} label="Viewer" />
              <FormControlLabel value="streamer" control={<Radio />} label="Streamer" />
            </RadioGroup>
          </FormControl>
        </Grid>
        {values.answers.length > 1 &&
        <Grid item xs={12} sm={6}>
          <FormControl component="fieldset" required>
            <FormLabel component="legend">{t('polls_table_type')}</FormLabel>
            <RadioGroup
              aria-label="type"
              name="type"
              value={values.type}
              onChange={handleChange}
            >
              <FormControlLabel
                value="single"
                control={<Radio />}
                label={t('polls_form_one_option')} />
              <FormControlLabel
                value="multi"
                control={<Radio />}
                label={t('polls_form_more_options')} />
            </RadioGroup>
          </FormControl>
        </Grid>
        }

        <Grid item xs={12}>
          <Typography variant="body2" gutterBottom>
            * ... {t('required')}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => saveData(true)}
            className={classes.button}>
            {(poll && poll.is_published) ? t('save') : t('poll_publish')}
          </Button>
          {(!poll || !poll.is_published) &&
          <CustomTooltip title={t('poll_caching_text')}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => saveData(false)}
              className={classes.button}>
              {t('poll_caching')}
            </Button>
          </CustomTooltip>
          }
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => actionPoll('list', null)}
            className={classes.button}>
            {t('cancel')}
          </Button>
        </Grid>

      </Grid>
    </React.Fragment>
  );
};

export default PollForm;