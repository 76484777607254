import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';

import { redeemedCardObjectsByCardTemplateWithQuality } from '../../apollo/queries';

import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import MarketplaceSelectRedeemedCardToSell
  from '../../components/Marketplace/MarketplaceSelectRedeemedCardToSell';

const MarketplaceSelectRedeemedCardToSellContainer = (props) => {
  const { collectorsView } = props;
  const [page, setPage] = useState(2);
  const perPage = 25;

  const variables = {
    pagination: { page: 1, perPage },
    card_template_id: collectorsView.selectSellInfo && collectorsView.selectSellInfo.cardId,
    quality: collectorsView.selectSellInfo && collectorsView.selectSellInfo.quality,
  };

  const { data, loading, fetchMore } = useQuery(redeemedCardObjectsByCardTemplateWithQuality, {
    variables,
    fetchPolicy: useCacheWithExpiration('redeemedCardObjectsByCardTemplateWithQuality', variables),
    skip: !collectorsView.selectSellInfo,
  });

  const redeemedCardData = data && data.redeemedCardObjectsByCardTemplateWithQuality;
  const myViewer = data && data.getMyViewer;

  return (
    <MarketplaceSelectRedeemedCardToSell
      loading={loading}
      myViewer={myViewer}
      cards={redeemedCardData && redeemedCardData.cards}
      totalCount={redeemedCardData && redeemedCardData.count}
      onLoadMore={async () => {
        setPage(page + 1);
        try {
          await fetchMore({
            variables: { ...variables, ...{ pagination: { perPage, page } } },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              return {
                redeemedCardObjectsByCardTemplateWithQuality: {
                  cards: [...prev.redeemedCardObjectsByCardTemplateWithQuality.cards, ...fetchMoreResult.redeemedCardObjectsByCardTemplateWithQuality.cards],
                  __typename: prev.redeemedCardObjectsByCardTemplateWithQuality.__typename,
                },
              };
            },
          });
        } catch {
        }
      }}
    />
  );
};

const mapStateToProps = (state) => {
  const { collectorsView } = state;
  return { collectorsView };
};

export default connect(mapStateToProps)(MarketplaceSelectRedeemedCardToSellContainer);