import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import CustomTooltip from '../Tooltip/CustomTooltip';
import { getLevelWord, getMatchData, toNearest } from '../../utils/helper';
import SelectCardShopPopup from '../Dialog/SelectCardShopPopup';
import { connect } from 'react-redux';

const useStyles = makeStyles({
  item: {
    background: '#202C3D',
    borderRadius: 6,
    padding: '10px 13px',
    position: 'relative',
    marginTop: 10,
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    fontFamily: 'Montserrat',
  },
  smallText: {
    fontSize: 12,
    color: '#626F84',
    width: 120,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  coloredBGLine: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: 3,
    borderRadius: '6px 6px 0px 0px',
  },
});

const CardShopSideCartCard = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { card, updateQuantity, cardShop, toggleNewCard, isRemovable } = props;
  const [showCard, setShowCard] = useState(null);

  const isLegacy = !!updateQuantity;
  const newCardsMatchData = !isLegacy ? getMatchData(card.card_template_metadata) : null;
  const [matchName, cardName] = isLegacy ? card.name.split(':') : ['', ''];
  const name = isLegacy ? matchName : newCardsMatchData.name;

  const levelColor = card.rarity !== null ? Colors.level[getLevelWord(null, card.rarity)] : '';

  return (
    <>
      <div className={classes.item}>
        {!isLegacy &&
        <div className={classes.coloredBGLine} style={{
          background: `linear-gradient(0deg, ${levelColor}00 0%, ${levelColor} 52%, ${levelColor}00 100%)`,
        }} />
        }
        <div style={Helpers.fillRowCenter}>
          <img
            src={`${isLegacy ? card.image : card.front_layers[0].img_url}?height=48`}
            alt="Card"
            style={{ borderRadius: 2 }}
          />
          <div style={{ marginLeft: 10 }}>
            <div>{name}</div>
            {!isLegacy && (card.condition !== null && card.condition >= 0) ?
              <div style={{ fontSize: 14, ...Helpers.fillRowCross }}>
                <div style={{ color: Colors.halfWhite, fontWeight: 300 }}>
                  {t(`condition_level.${card.condition}`)}
                </div>
                <div style={{ color: Colors.quality[toNearest(card.quality, 25)], marginLeft: 8 }}>
                  {card.quality}%
                </div>
              </div>
              :
              <div style={{ fontSize: 14, color: Colors.halfWhite, fontWeight: 300 }}>
                {cardName}
              </div>
            }
          </div>
        </div>
        <div style={Helpers.fillRowCross}>
          <div style={{ marginRight: isRemovable ? 18 : 0 }}>
            <div style={{ fontSize: 14, color: Colors.halfWhite, fontWeight: 300 }}>
              {t('costs')}
            </div>
            <div style={{ fontWeight: 400 }}>
              {(isLegacy ? card.price : (card.price / 100)).toFixed(2)
              .toString()
              .replace('.', ',')} €
            </div>
          </div>
          {isRemovable &&
          <div style={{ ...Helpers.fillColCenter, ...Helpers.mainSpaceBetween, height: '100%' }}>
            <CustomTooltip title={t('delete')}>
              <i
                className="fa-light fa-xmark"
                onClick={() => toggleNewCard ? toggleNewCard(card) : updateQuantity([], card)}
                style={{ fontSize: 18, cursor: 'pointer', marginBottom: isLegacy ? 10 : 20 }}
              />
            </CustomTooltip>
            {isLegacy &&
            <CustomTooltip title={t('delete')}>
              <i
                className="fa-light fa-cog"
                onClick={() => setShowCard(card)}
                style={{ fontSize: 18, cursor: 'pointer' }}
              />
            </CustomTooltip>
            }
          </div>
          }
        </div>
      </div>
      {showCard &&
      <SelectCardShopPopup
        card={showCard}
        handleClose={() => setShowCard(null)}
        shopCard={isLegacy && cardShop.cards.find(card => card._id === showCard._id)}
        updateQuantity={updateQuantity}
      />
      }
    </>
  );
};

const mapStateToProps = (state) => {
  const { cardShop } = state;
  return { cardShop };
};

export default connect(mapStateToProps)(CardShopSideCartCard);