import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEdit } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';

import { firstCharUpperCase } from '../../utils/helper';

import Badge from '../Badge/Badge';
import PollViewAnswers from './PollViewAnswers';

const useStyles = makeStyles(theme => ({
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  button: {
    marginRight: 10,
  },
}));

const PollView = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { poll, setPoll, setStatus } = props;

  const actionPoll = (status, poll) => {
    setPoll(poll);
    setStatus(status);
  };

  const isPollFinished = poll.finished_at ? moment()
  .isAfter(moment.unix(poll.finished_at / 1000)) : false;

  return (
    <>
      <div className={classes.flexRow}>
        <IconButton
          aria-label="back"
          color="secondary"
          onClick={() => actionPoll('list', null)}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </IconButton>
        <Typography variant="h3" gutterBottom style={{ marginBottom: 0 }}>
          {t('view_poll')}
        </Typography>
        {(poll.count_answers === 0 && (!isPollFinished || (!poll.is_published && isPollFinished))) &&
        <IconButton
          aria-label="edit"
          color="primary"
          onClick={() => actionPoll('edit', poll)}
        >
          <FontAwesomeIcon icon={faEdit} />
        </IconButton>
        }
      </div>
      <Grid container spacing={3}>

        <Grid item xs={12}>
          <Badge status={poll.is_published ? 'success' : 'danger'}>
            {poll.is_published ? t('polls_table_published') : t('polls_table_not_published')}
          </Badge>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom style={{ marginBottom: 0 }}>
            {t('polls_table_question')}
          </Typography>

          <Typography variant="body2" gutterBottom>
            {poll.title}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom style={{ marginBottom: 0 }}>
            {t('polls_table_answers')}
          </Typography>

          {poll.answers.map(answer =>
            <Typography key={`AnswerKey${answer}`} variant="body2" gutterBottom>
              {answer}
            </Typography>,
          )}
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom style={{ marginBottom: 0 }}>
            {t('polls_table_published_at')}
          </Typography>

          <Typography variant="body2" gutterBottom>
            {poll.published_at ? moment.unix(poll.published_at / 1000)
            .format('DD.MM.YY HH:mm:ss') : ''}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom style={{ marginBottom: 0 }}>
            {t('polls_table_finished_at')}
          </Typography>

          <Typography variant="body2" gutterBottom>
            {poll.finished_at ? moment.unix(poll.finished_at / 1000)
            .format('DD.MM.YY HH:mm:ss') : t('polls_table_endless')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom style={{ marginBottom: 0 }}>
            {t('polls_table_created_at')}
          </Typography>

          <Typography variant="body2" gutterBottom>
            {moment.unix(poll.created_at / 1000)
            .format('DD.MM.YY HH:mm:ss')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom style={{ marginBottom: 0 }}>
            {t('polls_table_target')}
          </Typography>

          <Typography variant="body2" gutterBottom>
            {firstCharUpperCase(poll.target)}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom style={{ marginBottom: 0 }}>
            {t('polls_table_type')}
          </Typography>

          <Typography variant="body2" gutterBottom>
            {poll.type === 'single' ? t('polls_form_one_option') : t('polls_form_more_options')}
          </Typography>
        </Grid>

        <PollViewAnswers poll={poll} />

      </Grid>
    </>
  );
};

export default PollView;