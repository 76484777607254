import React from 'react';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';

import CoinsByItemListItem from './CoinsByItemListItem';
import { rangeArray } from '../../utils/helper';
import ListSkeletonItem from '../Skeletons/ListSkeletonItem';
import { returnMobileObj } from '../../utils/responsive';
import LoadMore from '../Loading/LoadMore';

const useStyles = makeStyles(() => ({
  wrapper: {
    maxHeight: 500,
    minHeight: 500,
    overflow: 'auto',
  },
}));

const CoinsByItem = (props) => {
  const classes = useStyles();
  const {
    entries,
    loading,
    onLoadMore,
    myViewer,
    isTabletOrMobile,
    toggleConvertingItem,
    convertingList,
    convertMode,
    isPremiumActive,
    totalCount,
    title,
  } = props;

  return (
    <div>
      <div style={{ fontSize: 18, marginBottom: 5 }}>{title}</div>

      <List className={classes.wrapper} style={returnMobileObj(isTabletOrMobile, { padding: 5 })}>
        {loading ?
          rangeArray(1, 7)
          .map(item =>
            <ListSkeletonItem rounded key={`StreamerSkeletonKey${item}`} />,
          )
          :
          entries && entries.length > 0 ? entries.map((item) => (
            <CoinsByItemListItem
              key={item.match._id}
              entry={item}
              myViewer={myViewer}
              isTabletOrMobile={isTabletOrMobile}
              toggleConvertingItem={toggleConvertingItem}
              convertingList={convertingList}
              convertMode={convertMode}
              isPremiumActive={isPremiumActive}
            />
          )) : null
        }
      </List>
      <LoadMore
        count={entries && entries.length}
        totalCount={entries && totalCount}
        onLoadMore={onLoadMore}
      />
    </div>
  );
};

export default CoinsByItem;