import React from 'react';
import { Badge, Box, Grow, Link, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { faPaintBrush, faUser, faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import Colors from '../../../theme/Colors';
import Helpers from '../../../theme/Helpers';
import MuiImageMessage from './MuiImageMessage';

const useStyles = makeStyles(() => ({
  avatar: {
    border: 'none',
  },
  date: {
    color: Colors.gray,
    fontSize: 12,
    margin: '0 5px',
  },
}));

const MuiMessage = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { id, message, participants } = props;
  const { self } = message;

  if (message.deletedAt)
    return <div id={id} />;

  const author = message.author !== null ? participants[message.author] : false;
  const isSystem = !author;

  const l = self ? '20%' : 0;
  const r = self ? 0 : isSystem ? 0 : '20%';
  const bgColor = self ? 'primary.main' : 'background.paper';
  const color = self ? 'text.primary' : isSystem ? 'text.gray' : 'text.third';
  const justifyContent = self ? 'flex-end' : isSystem ? 'center' : 'flex-start';
  const link = author && `/${author.role !== 'customer' ? 'viewer' : 'streamer'}/${author.login}`;

  const getAuthorIcon = (authorRole) => {
    if (authorRole === 'illustrator')
      return faPaintBrush;
    else if (authorRole === 'staff')
      return faUserSecret;
    else
      return faUser;
  };

  const getAvatar = () => (
    <Link href={link} style={{
      ...Helpers.fill,
      ...Helpers.crossStart,
      ...(self ? { marginLeft: 10 } : { marginRight: 10 }),
    }}>
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={<FontAwesomeIcon icon={getAuthorIcon(author.role)} color={Colors.white} />}
      >
        <Avatar
          alt="Avatar"
          src={author.avatar}
          className={classes.avatar}
        />
      </Badge>
    </Link>
  );

  return (
    <Grow in>
      <Box
        id={id}
        flex="0 0 auto"
        my={1}
        pl={l}
        pr={r}
        display="flex"
        justifyContent={justifyContent}
      >
        {(!self && !isSystem) && getAvatar()}
        <div>
          <div
            style={{ ...Helpers.fillRow, ...Helpers.crossEnd, ...(self ? Helpers.rowReverse : Helpers.row) }}>
            {isSystem ?
              <div style={{ color: Colors.white }}>
                {t('system_chat')}
              </div>
              :
              <Link href={link} style={{ color: Colors.white }}>
                {author.display_name}
              </Link>
            }
            <Typography variant="body1" className={classes.date}>
              {message.createdAt
              .format('lll')}
            </Typography>
          </div>
          <Box
            minWidth={0}
            py={1}
            px={1}
            bgcolor={bgColor}
            color={color}
            borderRadius={4}
            boxShadow={2}
          >
            <Typography
              component="div"
              variant="body1"
              style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}
            >
              {message.type === 'text' ?
                message.content && message.content.includes('system_message') ?
                  t(message.content) : message.content
                :
                <MuiImageMessage message={message} participants={participants} />
              }
            </Typography>
          </Box>
        </div>
        {(self && !isSystem) && getAvatar()}
      </Box>
    </Grow>
  );
};

export default MuiMessage;