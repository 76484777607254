import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';
import './custom.css';

import Helpers from '../../theme/Helpers';
import { isUserPremium, multiplyEnergyCoins } from '../../utils/helper';
import Colors from '../../theme/Colors';

import conf from '../../conf.json';

const useStyles = makeStyles((theme) => ({
  container: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    width: 'calc(100% - 20px)',
    height: 'calc(366px - 20px)',
    padding: 10,
  },
  badge: {
    ...Helpers.fillRowCenter,
    padding: '10px 20px',
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 5,
  },
  area: {
    background: 'rgba(255, 255, 255, 0.04)',
    ...Helpers.fillColCenter, width: '100%',
  },
  avatar: {
    border: 'none',
    width: 58,
    height: 58,

    [theme.breakpoints.down('md')]: {
      width: 40,
      height: 40,
      marginTop: -11,
    },
  },
  energyAvatar: {
    width: 88,
    height: 88,
    flexShrink: 0,

    [theme.breakpoints.down('md')]: {
      width: 55,
      height: 55,
    },
  }
}));

const HubEnergyGathering = (props) => {
  const classes = useStyles();
  const { t, todayCollectedStreamer, myViewer, refetch } = props;

  const [nextEnergyIn, setNextEnergyIn] = useState(null);

  const gatheredEnergy = todayCollectedStreamer ? todayCollectedStreamer.reduce((acc, streamer) => acc + streamer.seq, 0) : 0;
  const currentStreamer = todayCollectedStreamer && todayCollectedStreamer.length > 0 && todayCollectedStreamer[0];
  const { isPremiumActive } = isUserPremium(myViewer);
  const limit = isPremiumActive ? conf.premium_tank_size : 100;
  const percentage = currentStreamer ? Math.round((currentStreamer.energy / limit) * 100) : 0;
  const totalMinutes = gatheredEnergy * 5;
  const h = Math.floor(totalMinutes % (60 * 24) / 60);
  const m = Math.floor(totalMinutes % 60);

  useEffect(() => {
    if (currentStreamer) {
      const interval = setInterval(() => {
        const now = moment();
        const then = moment.unix(currentStreamer.updated_at / 1000)
        .add(5, 'm');

        const minutes = then.diff(now, 'minutes');
        const seconds = then.diff(now, 'seconds') - minutes * 60;

        if (minutes === 0 && seconds === 0)
          refetch();

        if (seconds < 0) {
          setNextEnergyIn(null);
          return;
        }

        setNextEnergyIn(`${minutes}m ${seconds}s`);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [currentStreamer]);

  return (
    <div className={classes.container}>
      <div style={{
        width: '100%',
        height: 128,
        background: 'rgba(255, 255, 255, 0.04)',
        borderRadius: 6,
        display: 'flex',
        position: 'relative',
      }}>
        <div style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: Math.min(percentage, 100) + '%',
          height: '100%',
          background: 'rgb(243, 6, 252, 0.1)',
        }} />

        <div style={{
          padding: '20px 16px',
          boxSizing: 'border-box',
          borderRadius: '6px 0px 0px 6px',
          flexGrow: 1,
          ...Helpers.fillRowCross,
        }}>
          <>
            <div className={['energy-avatar', classes.energyAvatar].join(' ')}>
              <CircularProgressbarWithChildren
                value={percentage}
                strokeWidth={4}
                styles={buildStyles({
                  pathColor: '#F306FC',
                  trailColor: 'rgba(255, 255, 255, 0.1)',
                })}
              >
                {currentStreamer && <Avatar
                  className={classes.avatar}
                  alt="Avatar"
                  src={currentStreamer.streamer.profile_image_url}
                />}
              </CircularProgressbarWithChildren>
            </div>
            <div style={{ marginLeft: 18 }}>
              <div style={{ fontWeight: 500 }}>
                {currentStreamer?.streamer?.display_name ?? t('not_collecting_energy')}
              </div>
              {currentStreamer && currentStreamer.updated_at && nextEnergyIn &&
              <div style={{ marginTop: 4, color: Colors.halfWhite, fontSize: 14 }}>
                {t('next_energy_in', { date: nextEnergyIn })}
              </div>
              }
            </div>
          </>
        </div>
        <div style={{
          padding: 15,
          ...Helpers.fillColCenter, ...Helpers.crossEnd,
        }}>
          {currentStreamer &&
          <div style={{
            borderRadius: 16,
            background: 'rgba(255, 255, 255, 0.08)',
            whiteSpace: 'nowrap',
            padding: '8px 30px', ...Helpers.fillRowCenter,
          }}>
            <div style={{ marginRight: 5 }}>
              + {multiplyEnergyCoins(currentStreamer ? currentStreamer.seq : 0)}
            </div>
            <img
              src={`${conf.cdn_url}/assets/template/heroMaker/big_energy.png?height=21`}
              alt="Energy" />
          </div>
          }
        </div>
      </div>

      <div style={{ marginTop: 11, ...Helpers.fillRow, height: 208, width: '100%' }}>
        <div className={classes.area} style={{ marginRight: 5.5 }}>
          <i className="fa-brands fa-twitch" style={{ fontSize: 40 }} />
          <div style={{ marginTop: 16, fontSize: 20, fontWeight: 500 }}>
            {totalMinutes > 0 ?
              <>
                {h > 0 ? `${h}h` : ''} {m > 0 ? `${m}m` : ''}
              </>
              :
              '-'
            }
          </div>
          <div style={{ marginTop: 4, fontSize: 14, color: Colors.halfWhite }}>
            {t('watched_stream_time')
            .toLowerCase()}
          </div>
        </div>
        <div className={classes.area} style={{ marginLeft: 5.5 }}>
          <img
            src={`${conf.cdn_url}/assets/template/heroMaker/big_energy.png?height=41`}
            alt="Energy" />
          <div style={{ marginTop: 16, fontSize: 20, fontWeight: 500 }}>
            {multiplyEnergyCoins(gatheredEnergy)}
          </div>
          <div style={{ marginTop: 4, fontSize: 14, color: Colors.halfWhite }}>
            {t('gathered_energy')
            .toLowerCase()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(HubEnergyGathering);