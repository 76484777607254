import React, { useMemo } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import HRNumbers from 'human-readable-numbers';
import { Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import TwitchButton from '../Button/TwitchButton';
import CustomButton from '../Button/CustomButton';

const useStyles = makeStyles({
  container: {
    margin: '10px 0',
    padding: 16,
    width: 418,
    cursor: 'pointer',
    background: '#051329',
    borderRadius: 20,
    display: 'inline-block',
    transition: '0.3s',
    textDecoration: 'none',
    '&:hover': {
      background: Colors.containerHoverBGColor,
    },
  },
  innerContainer: {
    ...Helpers.fillRowCross,
  },
  avatar: {
    display: 'inline-block',
    borderColor: Colors.borderColor,
    backgroundColor: Colors.streamAvatarBorder,
    borderWidth: 2,
    zIndex: 2,
    width: 80,
    height: 80,
    marginRight: 20,
  },
  title: {
    fontSize: 34,
    fontFamily: 'Bebas Neue',
    color: Colors.white,
    lineHeight: 1,
    maxWidth: 300,
    ...Helpers.textLeft,
  },
  subTitle: {
    color: '#79859a',
    lineHeight: 1,
    fontSize: 15,
    ...Helpers.textLeft,
    textTransform: 'capitalize',
  },
  cardsCount: {
    color: Colors.white,
    fontWeight: 'bold',
    fontSize: 14,
    ...Helpers.textLeft,
  },
  countTitle: {
    textTransform: 'uppercase',
    fontSize: 11,
    color: Colors.subTextColor,
  },
  levelCardContainer: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    position: 'relative',
    height: 210,
    margin: '10px 0',
  },
  level1Card: {
    height: 151,
    position: 'absolute',
    left: '1%',
  },
  level2Card: {
    height: 151,
    position: 'absolute',
    right: '1%',
  },
  level3Card: {
    height: 176,
    position: 'absolute',
    left: '15%',
    zIndex: 3,
  },
  level4Card: {
    height: 176,
    position: 'absolute',
    right: '15%',
    zIndex: 3,
  },
  level5Card: {
    height: 202,
    position: 'absolute',
    left: '35%',
    zIndex: 5,
  },
});

const LiveStreamsByGameItemWithCards = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { stream } = props;

  const { cards, streamer } = stream;

  const openTwitchLink = (event) => {
    event.preventDefault();
    window.open(`https://www.twitch.tv/${streamer.login}`, '_blank');
  };

  const cardsToRender = useMemo(() => {
    return cards
    .slice(0, 5)
    .sort((cardA, cardB) => cardA.rarity - cardB.rarity);
  }, [cards]);

  const renderCard = (card, level) => {
    let imgParams = '?height=';

    switch (level) {
      case 4:
      case 3:
        imgParams += '176';
        break;

      case 2:
      case 1:
        imgParams += '151';
        break;

      case 5:
      default:
        imgParams += '202';
        break;
    }

    return (
      <img
        key={`CardID#${card._id}`}
        className={classes[`level${level}Card`]}
        src={`${card.card_layer_image}${imgParams}`}
        alt={`StreamerCardLevel${card.rarity}`}
        style={{ borderRadius: 4 }}
      />
    );
  };

  const renderCards = () => {
    if (!cardsToRender || cardsToRender.length === 0)
      return null;

    return cardsToRender.map((card, index) =>
      renderCard(card, index + 1),
    );
  };

  return (
    <Link
      className={[classes.container, 'stream-item'].join(' ')}
      to={'/profile/' + encodeURI(streamer.login)}
    >
      <div className={classes.innerContainer}>
        <Avatar
          className={classes.avatar}
          alt={streamer.display_name}
          src={streamer.profile_image_url}
        />
        <div>
          <Typography component="div" noWrap={true} className={classes.title}>
            {streamer.display_name}
          </Typography>
          {streamer.streamheroes_type !== 'imported' &&
          <Typography component="div" noWrap={true} className={classes.subTitle}>
            Streamheroes Streamer
          </Typography>
          }
        </div>
      </div>

      <div className={classes.levelCardContainer}>
        {renderCards()}
      </div>

      <div>
        {stream.viewer_count !== null &&
        <div style={{
          ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween,
          color: Colors.white,
          marginTop: 37,
        }}>
          <div style={{ ...Helpers.fillRowCross, color: '#cc1e31', fontSize: 24, fontWeight: 700 }}>
            <FontAwesomeIcon icon={faEye} color="#cc1e31" style={{ marginRight: 5 }} />
            {HRNumbers.toHumanString(stream.viewer_count)}
          </div>

          <CustomButton style={{ borderRadius: 50, padding: '5px 30px' }}>
            {t('cards')}
          </CustomButton>
          <TwitchButton onClick={openTwitchLink} text={t('stream_watch_now')} />
        </div>
        }
      </div>
    </Link>
  );
};

export default withRouter(LiveStreamsByGameItemWithCards);