import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

import EmptyPopup from '../Dialog/EmptyPopup';
import AnimationButton from '../Button/AnimationButton';
import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';

const conf = require('../../conf');

const useStyles = makeStyles(() => ({
  imageArea: {
    background: `url("${conf.cdn_url}/assets/template/premium/purple_popup.png")`,
    backgroundPosition: 'center',
    width: 432,
    height: 431,
    position: 'relative',
    margin: '-120px -30px -80px',
  },
}));

const PremiumThankYou = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { handleClose } = props;

  return (
    <EmptyPopup title={t('premium_thank_you')} handleClose={handleClose}>
      <div style={{
        ...Helpers.fillColCenter,
        ...Helpers.mainSpaceBetween,
        marginBottom: 40,
      }}>
        <div className={classes.imageArea}>
          <img
            src={`${conf.cdn_url}/assets/template/premium/mask_popup.png`}
            alt="Mask"
            style={{ position: 'absolute', top: 145, left: 91, zIndex: 2 }}
          />
          <img
            src={`${conf.cdn_url}/assets/template/premium/card_popup.png`}
            alt="Card"
            style={{ position: 'absolute', top: 140, left: 158 }}
          />
        </div>
        <div
          style={{ width: 300, ...Helpers.textCenter }}>
          <div
            style={{ fontFamily: 'Bebas Neue', fontSize: 36, color: Colors.white, lineHeight: 1 }}>
            {t('premium_now_supporter')}
          </div>
          <div style={{ fontSize: 15, color: '#668CBF' }}>
            {t('premium_now_supporter_text')}
          </div>
        </div>
      </div>

      <div style={Helpers.fillColCenter}>
        <AnimationButton onClick={handleClose} style={{ padding: '8px 100px' }}>
          {t('lets_go')}
        </AnimationButton>
      </div>
    </EmptyPopup>
  );
};

export default PremiumThankYou;