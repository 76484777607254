import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import List from '@material-ui/core/List';
import { useQuery } from '@apollo/react-hooks';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getGamesAndLiveStreams } from '../../apollo/mergedQueries';

import GameLiveStreamsList from '../../components/GameLiveStreamsList/GameLiveStreamsList';
import TopViewHeader from '../../components/TopViewHeader/TopViewHeader';
import PageTitle from '../../components/PageTitle/PageTitle';
import { rangeArray } from '../../utils/helper';
import GameSkeletonItem from '../../components/Skeletons/GameSkeletonItem';
import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import HeaderLine from '../../components/TopViewHeader/HeaderLine';
import ShinyButton from '../../components/Button/ShinyButton';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  header: {
    color: 'white',
    marginBottom: 40,
  },
  headerLine: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
  },
}));

const GameLiveStreams = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [hasCards, setHasCards] = useState(false);
  const perPage = 24;

  const variables = {
    pagination: {
      page: 1,
      perPage,
    },
    streamfilter: {
      has_cards: hasCards,
    },
  };
  const getGamesAndLiveStreamsQuery = useQuery(getGamesAndLiveStreams, {
    variables,
    fetchPolicy: useCacheWithExpiration('getGamesAndLiveStreams', variables),
    pollInterval: conf.poll_intervals.long_refresh,
  });
  const countOfGamesWithLiveStreams = (getGamesAndLiveStreamsQuery.data) && getGamesAndLiveStreamsQuery.data.countOfGamesWithLiveStreams;
  const gamesWithLiveStreamsCount = (getGamesAndLiveStreamsQuery.data) && getGamesAndLiveStreamsQuery.data.gamesWithLiveStreamsCount;

  return (
    <>
      <PageTitle title={t('streams')} />
      <TopViewHeader>
        <div style={{
          padding: '45px 0 45px',
          maxWidth: 1440,
          margin: '0 auto',
        }}>
          <div
            className={classes.headerLine}
          >
            <HeaderLine
              title={t('which_game_collect')}
              subTitle={t('which_game_collect')}
            />
            <div style={{
              ...Helpers.fillRowCenter,
              flexWrap: 'wrap',
            }}>
              <ShinyButton onClick={() => setHasCards(!hasCards)}>
                <FontAwesomeIcon
                  icon={hasCards ? faCheck : faTimes}
                  size="sm"
                  style={{ marginRight: 3, marginBottom: 1 }} /> {t('has_cards')}
              </ShinyButton>
            </div>
          </div>
          <div style={{
            marginTop: 30,
            background: Colors.containerBGColor,
          }}>
            {getGamesAndLiveStreamsQuery.loading ?
              <List style={Helpers.fillRowMain}>
                {rangeArray(1, 9)
                .map(item =>
                  <GameSkeletonItem key={`GameSkeletonKey${item}`} />,
                )}
              </List>
              :
              <GameLiveStreamsList
                entries={gamesWithLiveStreamsCount}
                hasMore={(gamesWithLiveStreamsCount) && countOfGamesWithLiveStreams > gamesWithLiveStreamsCount.length}
                onLoadMore={async () => {
                  try {
                    await getGamesAndLiveStreamsQuery.fetchMore({
                      variables: {
                        pagination: {
                          perPage,
                          page: page + 1,
                        },
                      },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) return prev;
                        setPage(page + 1);
                        return Object.assign({}, prev, {
                          gamesWithLiveStreamsCount: [...prev.gamesWithLiveStreamsCount, ...fetchMoreResult.gamesWithLiveStreamsCount],
                        });
                      },
                    });
                  } catch {
                  }
                }}
              />
            }
          </div>
        </div>
      </TopViewHeader>
    </>
  );
};

export default GameLiveStreams;