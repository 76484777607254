import moment from 'moment';
import axios from 'axios';
import HRNumbers from 'human-readable-numbers';
import { useLocation } from 'react-use';
import cjson from 'compressed-json';
import amplitude from 'amplitude-js';

import { logAmplitudeRevenue } from './amplitude';

const conf = require('../conf');

/**
 * Replace width and height placeholder in given source string
 *
 * @param source
 * @param width
 * @param height
 * @returns string
 */
export function replaceWidthAndHeight(source, width, height) {
  if (!source) {
    return '';
  }
  return source.replace('{width}', width)
  .replace('{height}', height);
}

export function parseQueryOfUrl(url) {
  let regex = /[?&]([^=#]+)=([^&#]*)/g,
    params = {},
    match;
  while ((match = regex.exec(url))) {
    params[match[1]] = match[2];
  }

  return params;
}

export function getLevelWord(cardType, level = null) {
  if (cardType === 'Card' || !cardType) {
    if (level === 0)
      return 'special';
    else if (level === 1)
      return 'common';
    else if (level === 2)
      return 'uncommon';
    else if (level === 3)
      return 'rare';
    else if (level === 4)
      return 'unique';
    else if (level === 5)
      return 'legendary';
  } else
    return 'special';
}

export function getCondition(quality) {
  let condition = 0;
  if (quality > 10) {
    if (quality >= 11 && quality <= 30)
      condition = 1;
    else if (quality >= 31 && quality <= 70)
      condition = 2;
    else if (quality >= 71 && quality <= 90)
      condition = 3;
    else if (quality >= 91 && quality <= 100)
      condition = 4;
  }

  return condition;
}

export function get20StepQuality(quality) {
  return Math.ceil(quality / 5);
}

/**
 *
 * @param cards
 * @returns {number}
 */
export function getMaximalLevelOfCards(cards) {
  let maxLevel = 0;

  if (cards && cards.length > 0) {
    if (cards[0].matchCardModel)
      cards = cards.filter(card => card.matchCardModel === 'Card');

    const highestLvlCard = cards.reduce((highest, card) => {
      if (!card.match_card) card.match_card = card;

      return ((highest.match_card && highest.match_card.level) || 0) > card.match_card.level ? highest : card;
    }, {});
    maxLevel = highestLvlCard.match_card ? highestLvlCard.match_card.level : 0;
  }

  return maxLevel;
}

/**
 * Use the string's .replace method with a regex of \D, which is a shorthand character class that matches all non-digits
 *
 * @param src
 * @returns {number}
 */
export function getLastCharOfSourceUrl(src) {
  return parseInt(src.replace('mp3', '')
  .replace(/\D/g, ''));
}

/**
 * Returns an array of numbers between a start number and an end number incremented
 * sequentially by a fixed number(step), beginning with either the start number or
 * the end number depending on which is greater.
 *
 * @param {number} start (Required: The start number.)
 * @param {number} end (Required: The end number. If end is less than start,
 *  then the range begins with end instead of start and decrements instead of increment.)
 * @param {number} step (Optional: The fixed increment or decrement step. Defaults to 1.)
 *
 * @return {array} (An array containing the range numbers.)
 *
 * @throws {TypeError} (If any of start, end and step is not a finite number.)
 * @throws {Poll} (If step is not a positive number.)
 */
export function rangeArray(start, end, step = 1) {
  // Test that the first 3 arguments are finite numbers.
  // Using Array.prototype.every() and Number.isFinite().
  const allNumbers = [start, end, step].every(Number.isFinite);

  // Throw an error if any of the first 3 arguments is not a finite number.
  if (!allNumbers) {
    throw new TypeError('range() expects only finite numbers as arguments.');
  }

  // Ensure the step is always a positive number.
  if (step <= 0) {
    throw new Error('step must be a number greater than 0.');
  }

  // When the start number is greater than the end number,
  // modify the step for decrementing instead of incrementing.
  if (start > end) {
    step = -step;
  }

  // Determine the length of the array to be returned.
  // The length is incremented by 1 after Math.floor().
  // This ensures that the end number is listed if it falls within the range.
  const length = Math.floor(Math.abs((end - start) / step)) + 1;

  // Fill up a new array with the range numbers
  // using Array.from() with a mapping function.
  // Finally, return the new array.
  return Array.from(Array(length), (x, index) => start + index * step);
}

/**
 * Group
 *
 * @param xs
 * @param key
 * @returns {*}
 */
export function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function firstCharUpperCase(text) {
  return text.replace(/^\w/, c => c.toUpperCase());
}

export function subToLongString(text, length) {
  return text.length > length ? text.substr(0, length) + '\u2026' : text;
}

export function hideForPathURL(path) {
  // insert all Urls, where the header should not be shown
  const hideForUrls = [
    'play-video',
    'quest-reward',
    'login',
    'handle-login',
    'on-boarding',
    'verification',
    'on-tour',
    '3d-test',
    '3d-card',
    '3d-booster',
    'booster-animation',
    'card-shop-pay',
    'countdown',
    'accept-terms',
  ];

  const urlParts = path.split('/');
  let hideHeader = false;
  hideForUrls.forEach(hidingUrl => {
    if (urlParts.includes(hidingUrl)) {
      hideHeader = true;
    }
  });

  return hideHeader;
}

export function getRandomNumber(max = 10000) {
  return Math.random() * max;
}

export function getMatchName(card, onlyUrl = false) {
  if (!card.match)
    return 'Streamheroes';

  const { match } = card;
  const matchModel = card.matchModel ? card.matchModel : match.__typename;

  if (matchModel === 'Streamer')
    return onlyUrl ? match.login : (match.display_name ? match.display_name : match.login);
  else if (matchModel === 'Organisation')
    return onlyUrl ? match.code : match.name;
  else if (matchModel === 'Game')
    return onlyUrl ? decodeURIComponent(match.name) : match.name;
}

export function getMatchData(card, width, height) {
  if (!card || !card.match || card.match.__typename === 'GenericMatch')
    return {
      url: '/bank',
      name: 'Streamheroes',
      code: 'sh',
      avatar: 'https://static-cdn.jtvnw.net/jtv_user_pictures/fa77cfe3-b7dd-443f-8ba8-594c93740805-profile_image-300x300.png',
      type: 'Streamheroes',
    };

  const { match } = card;
  const matchModel = card.matchModel ? card.matchModel : match.__typename;

  if (matchModel === 'Streamer')
    return {
      code: match.login,
      name: match.display_name,
      avatar: match.profile_image_url,
      url: `/profile/${match.login}`,
      type: 'Streamer',
    };
  else if (matchModel === 'Organisation')
    return {
      code: match.code,
      name: match.name,
      avatar: match.logo_url,
      url: `/organisation/${match.code}`,
      type: 'Organisation',
    };
  else if (matchModel === 'Game')
    return {
      code: match.code,
      name: match.name,
      avatar: replaceWidthAndHeight(match.box_art_url, width, height),
      url: `/hq/game-streams/${encodeURIComponent(match.name)}`,
      type: 'Game',
    };
  else if (matchModel === 'GenericMatch')
    return {
      code: null,
      name: match.name,
      avatar: match.image_url === 'blank' ? 'https://static-cdn.jtvnw.net/jtv_user_pictures/fa77cfe3-b7dd-443f-8ba8-594c93740805-profile_image-300x300.png' : match.image_url,
      url: null,
      type: 'GenericMatch',
    };
}

/**
 *
 * @param user
 * @returns {{premiumUntil: moment.Moment, isPremiumActive: boolean}|{premiumUntil: null, isPremiumActive: boolean}}
 */
export function isUserPremium(user) {
  if (user && user.subscription) {
    const premiumUntil = moment(parseInt(user.subscription.valid_until));
    const isPremiumActive = premiumUntil.isAfter();
    return { premiumUntil, isPremiumActive };
  }

  return { premiumUntil: null, isPremiumActive: false };
}

export function roundToDecimal(value) {
  return Math.round(value * 100) / 100;
}

export function calculateVAT(total, percent) {
  const net_costs = total / (1 + (percent / 100));
  return roundToDecimal(total - net_costs);
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max)) + 1;
}

export function rand(min, max) {
  return Math.floor(Math.random() * Math.floor(max - min)) + min;
}

export function roundTo10Below(n) {
  return Math.floor(n / Math.pow(multiplyEnergyCoins(10, true), 1)) * Math.pow(multiplyEnergyCoins(10, true), 1);
}

export function getHourDiffFromNow(date) {
  return moment()
  .utc()
  .diff(moment.utc(parseInt(date)), 'hours');
}

export function saveUserInfo(userInfo) {
  window.localStorage.setItem(conf.storage_keys.user_info, JSON.stringify({
    created_at: userInfo.created_at,
    cards_owned: userInfo.cards_owned ? userInfo.cards_owned.total : 0,
  }));
}

export function getUserInfo() {
  const userInfo = window.localStorage.getItem(conf.storage_keys.user_info);
  return userInfo ? JSON.parse(userInfo) : null;
}

export function skipQueryByAccountAge() {
  const userInfo = getUserInfo();

  return userInfo && conf.event_mode && getHourDiffFromNow(userInfo.created_at) < 24;
}

export function skipQueryByOwnedCards() {
  const userInfo = getUserInfo();

  return !!(userInfo && conf.event_mode && !userInfo.cards_owned);
}

export function capitalize(s) {
  if (typeof s !== 'string') return '';
  return s.charAt(0)
  .toUpperCase() + s.slice(1);
}

/**
 * Upload image to s3 directly, because load balancer allow file max size of 1 MB only
 *
 * @param signedUrl
 * @param file
 * @param headers
 * @param callback
 */
export function uploadImageToS3(signedUrl, file, headers, callback = null) {
  axios.put(signedUrl, file, { headers })
  .then(() => {
    if (callback)
      callback();
  })
  .catch(error => {
    console.log('ERROR ' + error);
  });
}

export function getScaleFactor(ppi, dpr) {
  // scaleFactor = DEVICE_DPI / DEVICE_DPR / (326 / 2)
  // divide by 2, because @2 images are used
  return ppi / dpr / (326 / 2) / 2;
}

/**
 *
 *
 * @param t
 * @param checked
 * @returns {{
 *    totalSuccess: boolean,
 *    checkpoints: [{ title: string, isSuccess: boolean }]
 * }}
 */
export function checkCheckpoints(t, checked) {
  const checkpoints = [
    {
      title: t('check_collected_cards', { cards: HRNumbers.toHumanString(10000) }),
      isSuccess: checked && checked.card_amount_collected > 10000,
    },
    {
      title: t('check_partner'),
      isSuccess: checked && (checked.twitch_partner || checked.sh_partner),
    },
    {
      title: t('check_average_viewer', { count: 50 }),
      isSuccess: checked && (checked.viewer_average > 50),
    },
  ];
  let totalSuccess = false;
  if (checked)
    checkpoints.map(check => {
      if (check.isSuccess)
        totalSuccess = true;
    });

  return { totalSuccess, checkpoints };
}

export function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

/**
 * During shallow equality check of objects you get the list of properties (using Object.keys())
 * of both objects, then check the properties� values for equality.
 *
 * @param object1
 * @param object2
 * @returns {boolean}
 */
export function shallowEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length)
    return false;

  for (let key of keys1) {
    if (object1[key] !== object2[key])
      return false;
  }

  return true;
}

/**
 * The deep equality is similar to the shallow equality, but with one difference. During the
 * shallow check, if the compared properties are objects, a recursive shallow equality check is
 * performed on these nested objects.
 *
 * @param object1
 * @param object2
 * @returns {boolean}
 */
export function deepEqual(object1, object2) {
  if (!object1 || !object2)
    return false;

  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length)
    return false;

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      areObjects && !deepEqual(val1, val2) ||
      !areObjects && val1 !== val2
    )
      return false;
  }

  return true;
}

export const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export function getCountryCosts() {
  return [
    {
      name: 'Deutschland',
      code: 'DE',
      shipping: 3.20,
      vat_percent: 19,
    },
    {
      name: 'Österreich',
      code: 'AT',
      shipping: 4.10,
      vat_percent: 20,
    },
    {
      name: 'Schweiz',
      code: 'CH',
      shipping: 4.10,
      vat_percent: 0,
    },
    {
      name: 'Luxemburg',
      code: 'LU',
      shipping: 4.10,
      vat_percent: 17,
    },
  ];
}

/**
 *
 * @param item
 */
export const downloadImage = (item) => {
  const fileName = item.split(/(\\|\/)/g)
  .pop();

  const image = new Image();
  image.crossOrigin = 'anonymous';
  image.src = item.replace(conf.cdn_url, conf.no_cors_cdn_url);
  image.onload = function () {

    // use canvas to load image
    const canvas = document.createElement('canvas');
    canvas.width = this.naturalWidth;
    canvas.height = this.naturalHeight;
    canvas.getContext('2d')
    .drawImage(this, 0, 0);

    // grab the blob url
    let blob;
    if (image.src.indexOf('.jpg') > -1)
      blob = canvas.toDataURL('image/jpeg');
    else if (image.src.indexOf('.png') > -1)
      blob = canvas.toDataURL('image/png');
    else if (image.src.indexOf('.gif') > -1)
      blob = canvas.toDataURL('image/gif');
    else
      blob = canvas.toDataURL('image/png');

    // create link, set href to blob
    const a = document.createElement('a');
    a.title = fileName;
    a.href = blob;
    a.style.display = 'none';
    a.setAttribute('download', fileName);
    a.setAttribute('target', '_blank');
    document.body.appendChild(a);

    // click item
    a.click();
    document.body.removeChild(a);
  };
};

const isObject = (object) => {
  return object != null && typeof object === 'object';
};

export const getStringBetween = (str, start, end) => {
  const result = str.match(new RegExp(start + '(.*)' + end));

  return result[1];
};

export const multiplyEnergyCoins = (value, noString = false) => {
  const response = value * conf.multiply_coins_energy_factor;

  return noString ? response : response.toLocaleString();
};

export const divideEnergyCoins = (value) => {
  return value / conf.multiply_coins_energy_factor;
};

/**
 * Make flying image over top&left position with transition to card shop icon position
 *
 * @param e
 * @param productImage
 */
export const addShopItem = (e, productImage) => {
  const speed = 500,
    curveDelay = 300,
    width = 32,
    cart = document.querySelector('#cart-button'),
    countBadge = document.querySelector('#cart-button .MuiBadge-badge'),
    position = 'fixed'; // or absolute

  countBadge.classList.remove('addedCount');

  let btnY =
      position === 'fixed'
        ? e.currentTarget.getBoundingClientRect().top
        : e.currentTarget.offsetTop,
    btnX =
      position === 'fixed'
        ? e.currentTarget.getBoundingClientRect().left
        : e.currentTarget.offsetLeft,
    flyingProduct = productImage.cloneNode();

  flyingProduct.classList.add('flying-object');
  flyingProduct.style.position = position;
  flyingProduct.style.top = `${btnY}px`;
  flyingProduct.style.left = `${btnX}px`;
  flyingProduct.style.opacity = '1';
  flyingProduct.style.transition = `all ${speed / 1000}s ease, top ${(speed +
    curveDelay) /
  1000}s ease, left ${speed / 1000}s ease, transform ${speed /
  1000}s ease ${(speed - 10) / 1000}s`;

  document.body.appendChild(flyingProduct);

  flyingProduct.style.top = `${cart.offsetTop + cart.offsetHeight - width}px`;
  // flyingBtn.style.left = `${cart.offsetLeft + cart.offsetWidth - width}px`;
  // top line not working - working solution -> 40px is the width of the cart button
  // left side of the window - 1440px (max content width) to the cart shop button
  flyingProduct.style.left = `${window.innerWidth - 50}px`;
  flyingProduct.style.height = `${width}px`;
  flyingProduct.style.width = `${width}px`;
  flyingProduct.style.transform = 'scale(0)';
  flyingProduct.style['z-index'] = 1000000000;

  setTimeout(() => flyingProduct.remove(), speed * 1.5);
};

export const toNearest = (num, frac) => {
  return Math.floor(num / frac) * frac;
};

export const getAllMatches = () => {
  const newCardOrderStore = localStorage.getItem('newCardOrder');
  const jsonNewCardOrder = cjson.decompress.fromString(newCardOrderStore);
  const cardOrderStore = localStorage.getItem('cardOrder');
  const jsonCardOrder = cjson.decompress.fromString(cardOrderStore);
  const matches = [];

  jsonNewCardOrder.forEach(el => {
    const {
      avatar,
      name,
    } = getMatchData(el.card_template_metadata, 300, 300);

    // check that streamer already is in the array
    if (matches.findIndex(match => match.display_name === name) === -1)
      matches.push({
        display_name: name,
        image: avatar,
      });
  });

  jsonCardOrder.forEach(el => {
    let match;

    if (el.image.indexOf('/sh/') > -1)
      match = 'StreamHeroes';
    else if (el.image.indexOf('/games/') > -1)
      match = el.match.name;
    else
      match = el.match.display_name;

    if (matches.findIndex(match => match.display_name === match) === -1)
      matches.push({
        display_name: match,
        image: replaceWidthAndHeight(el.image, 300, 300),
      });
  });

  return matches;
};

export const completeOrderWithAmplitude = (t) => {
  const newCardOrderStore = localStorage.getItem('newCardOrder');
  const jsonNewCardOrder = cjson.decompress.fromString(newCardOrderStore);
  const cardOrderStore = localStorage.getItem('cardOrder');
  const jsonCardOrder = cjson.decompress.fromString(cardOrderStore);
  const albumStore = localStorage.getItem('albumOrder');
  const jsonAlbum = cjson.decompress.fromString(albumStore);
  const staticStore = localStorage.getItem('staticOrder');
  const jsonStaticOrder = cjson.decompress.fromString(staticStore);
  let overallAmount = 0;
  let purchasedNewCards = 0;
  let purchasedCards = 0;
  let purchasedAlbums = 0;
  const matches = [];

  jsonNewCardOrder.forEach(el => {
    const {
      avatar,
      name,
      type,
    } = getMatchData(el.card_template_metadata, 300, 300);

    // check that streamer already is in the array
    if (matches.findIndex(match => match.display_name === name) === -1)
      matches.push({
        display_name: name,
        image: avatar,
      });

    overallAmount += el.price;
    purchasedNewCards += 1;

    logAmplitudeRevenue(el._id, el.price, 1, {
      '_id': el._id,
      'Product Name': el.name,
      'Product Count': 1,
      'Product Price': el.price,
      'Product Owner': name,
      'Product Owner type': type,
      'Product Type': 'Card', // in the future we should distinguish between card types here
    });
  });

  jsonCardOrder.forEach(el => {
    let matchType;
    let match;

    if (el.image.indexOf('/sh/') > -1) {
      matchType = 'StreamHeroes';
      match = 'StreamHeroes';
    } else if (el.image.indexOf('/games/') > -1) {
      matchType = 'Game';
      match = el.match.name;
    } else {
      matchType = 'Streamer';
      match = el.match.display_name;
    }

    if (matches.findIndex(match => match.display_name === match) === -1)
      matches.push({
        display_name: match,
        image: replaceWidthAndHeight(el.image, 300, 300),
      });

    overallAmount += el.count * el.price;
    purchasedCards += el.count;

    logAmplitudeRevenue(el._id, el.price, el.count, {
      '_id': el._id,
      'Product Name': el.name,
      'Product Count': el.count,
      'Product Price': el.price,
      'Product Owner': match,
      'Product Owner type': matchType,
      'Product Type': 'Card', // in the future we should distinguish between card types here
    });
  });

  jsonStaticOrder.forEach(el => {
    const price = (el.discounted_price ? el.discounted_price : el.unit_price) / 100;
    overallAmount += el.amount * price;

    logAmplitudeRevenue(el._id, price, el.amount, {
      '_id': el._id,
      'Product Name': el.name,
      'Product Count': el.amount,
      'Product Price': price,
      'Product Type': el.name,
    });
  });

  jsonAlbum.forEach(el => {
    const price = (el.discounted_price ? el.discounted_price : el.unit_price) / 100;
    const match = el.url.split('/')
    .pop();

    overallAmount += el.amount * price;
    purchasedAlbums += el.amount;

    logAmplitudeRevenue(el._id, price, el.amount, {
      '_id': el._id,
      'Product Name': t(el.name),
      'Product Count': el.amount,
      'Product Price': price,
      'Product Owner': match,
      'Product Owner type': el.type,
      'Product Type': 'Album',
    });
  });

  const shippingCosts = localStorage.getItem('SH:SHIPPING:COSTS');

  logAmplitudeRevenue('Shipping Fee', shippingCosts, 1, {
    'Product Name': 'Shipping Fee',
    'Product Count': 1,
    'Product Price': shippingCosts,
    'Product Type': 'Shipping Fee',
  });
  overallAmount += shippingCosts;

  const identify = new amplitude.Identify().add('Total Revenue', overallAmount)
  .add('Purchased New Cards', purchasedNewCards)
  .add('Purchased Cards', purchasedCards)
  .add('Purchased Albums', purchasedAlbums);
  amplitude.getInstance()
  .identify(identify);
  window.dataLayer.push({
    event: 'Purchase Completed',
    variables: {
      orderValue: overallAmount,
    },
  });

  return matches;
};

export const detectBrowser = () => {
  if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
    return 'Opera';
  } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
    return 'Chrome';
  } else if (navigator.userAgent.indexOf('Safari') !== -1) {
    return 'Safari';
  } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
    return 'Firefox';
  } else if ((navigator.userAgent.indexOf('MSIE') !== -1) || (!!document.documentMode === true)) {
    return 'IE';//crap
  } else {
    return 'Unknown';
  }
};

/**
 * This function converts numbers from the decimal system           *
 * correctly to the Roman system. The Roman letters are:            *
 * I = 1, V = 5, X = 10, L = 50, C = 100, D = 500, M = 1000         *
 * That means: DXX = 520, MMMMCLVI = 4156                           *
 * If a letter with a lower value stays in front of a higher        *
 * one, the value of the lower one will be substracted from         *
 * the other.                                                       *
 * That means: IV = 4 (5 - 1 = 4), MCM = 1900 (1000 + (1000 - 100)) *
 **/
export const dec2rom = (decimal) => {
  let dec = '' + decimal;  // Converts the decimal "dec" to a string
  while (dec.substr(0, 1) === '0') {
    dec = dec.substring(1, dec.length);
  }  // Remove zeroes in front to avoid converting to the octal system
  let num = parseInt(dec);  // Converting string to decimal
  let thousand = 0;  // Counts, how often we substracted 1000 from the decimal
  while (num > 1000) {
    num -= 1000;
    thousand++;
  }  // Required to lower the number
  dec = num + '';  // Overwrites "dec" with the new - under circumstances lower - number
  let rN = '';  // Prepare empty string (the result)
  const adds = ['I', 'X', 'C', 'M'];  // These are letters which can be placed next to eacht other (8 = "VIII"), sorted asc.
  const noAdds = ['V', 'L', 'D'];  // These letters cannot be put next to each other ("VV" does not exist), sorted asc.
  const length = dec.length;  // Length of the string

  for (let i = 0; i < length; i++) {  // This loop will extract each digit from the decimal and handle its "translation"
    const d = parseInt(dec.substr(i, 1));  // Actual digit of "dec"
    const pos = length - 1 - i;  // Reverse position in arrays
    if (d === 0) { // The Romans did not know the zero
      continue;
    } else if ((d === 1) || (d === 2) || (d === 3)) { // Letters, that are added (like "III")
      for (let j = 0; j < d; j++) {
        rN += adds[pos];
      }  // A loop that addes 1, 2 or 3 times the given letter (like "III", "XXX")
    } else if (d === 4) {
      rN += adds[pos] + noAdds[pos];
    }  // Adds two letters, if the digit is "4"; in case of 4: "IV"
    else if (d === 5) {// Simply add the non-addable signs ("V", "L", "D")
      rN += noAdds[pos];
    } else if ((d === 6) || (d === 7) || (d === 8)) { // Comparable to case "1", "2" or "3"
      rN += noAdds[pos];  // Sign, that is used in case "5"
      for (let k = 5; k < d; k++) {
        rN += adds[pos];
      }  // 1, 2 or 3 the given letter (like "III", "XXX")
    } else if (d === 9) {
      rN += adds[pos] + adds[pos + 1];
    }  // Comparable to "4", but only with addable letters
  }

  for (let m = 0; m < thousand; m++) {
    rN = 'M' + rN;
  }  // At beginning, we substracted "thousand" times 1000; now, we add them

  return rN.toUpperCase();  // Writes the Roman string in capital letters in the Roman text input
};

/**
 * Module to provide the English ordinal letters following a numeral.
 *
 * @param i
 * @returns {string}
 */
export const ordinal = (i) => {
  if (typeof i !== 'number') throw new TypeError('Expected Number, got ' + (typeof i) + ' ' + i);

  if (!Number.isFinite(i)) return i;

  return i + indicator(i);
};

/**
 * To get just the indicator
 *
 * @param i
 * @returns {string}
 */
export const indicator = (i) => {
  i = Math.abs(i);
  const cent = i % 100;
  if (cent >= 10 && cent <= 20) return 'th';
  const dec = i % 10;
  if (dec === 1) return 'st';
  if (dec === 2) return 'nd';
  if (dec === 3) return 'rd';
  return 'th';
};

export const getLeaderboardRankImage = (position, height = 22) => {
  if (!position || position > 3) return `${conf.cdn_url}/assets/seasons/rank-icon-4.png?height=${height}`;

  return `${conf.cdn_url}/assets/seasons/rank-icon-${position}.png?height=${height}`;
};

export const getLeagueImage = (league, width = 200) => {
  return `${conf.cdn_url}/assets/seasons/${league}.png?width=${width}`;
};

export const getLeagueBadgeImage = (league, width = 200) => {
  let imageUrl = conf.cdn_url;

  switch (league) {
    case 'diamond':
      imageUrl += '/assets/seasons/Badges/SH_Season0_Badges-LEAGUE-I.png';
      break;

    case 'platinum':
      imageUrl += '/assets/seasons/Badges/SH_Season0_Badges-LEAGUE-II.png';
      break;

    case 'gold':
      imageUrl += '/assets/seasons/Badges/SH_Season0_Badges-LEAGUE-III.png';
      break;

    case 'silver':
      imageUrl += '/assets/seasons/Badges/SH_Season0_Badges-LEAGUE-IV.png';
      break;

    case 'bronze':
      imageUrl += '/assets/seasons/Badges/SH_Season0_Badges-LEAGUE-V.png';
      break;

    default:
      break;
  }

  return imageUrl + '?width=' + width;
};

export const decodeJWT = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+')
  .replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64)
  .split('')
  .map(function (c) {
    return '%' + ('00' + c.charCodeAt(0)
    .toString(16)).slice(-2);
  })
  .join(''));

  return JSON.parse(jsonPayload);
};

export const getTimeDiff = (date1, date2) => {
  let milliseconds = date2.getTime() - date1.getTime();

  const days = Math.floor(milliseconds / 1000 / 60 / 60 / 24);

  milliseconds -= days * 1000 * 24 * 60 * 60;

  const hours = Math.floor(milliseconds / 1000 / 60 / 60);

  milliseconds -= hours * 1000 * 60 * 60;

  const minutes = Math.floor(milliseconds / 1000 / 60);

  milliseconds -= minutes * 1000 * 60;

  const seconds = Math.floor(milliseconds / 1000);

  milliseconds -= seconds * 1000;

  return {
    days,
    hours,
    minutes,
    seconds,
    milliseconds,
  };
};

export const getRightAddressInput = (formValues) => {
  const address = { ...formValues };
  address.firstname = formValues.forename.trim();
  address.lastname = formValues.surname.trim();
  address.postcode = formValues.postal_code;
  address.street = formValues.street.trim();
  address.email = formValues.email.trim();
  delete address.forename;
  delete address.surname;
  delete address.postal_code;
  delete address.editMode;
  delete address.acceptWrongAddress;
  delete address.showCheckAddress;

  return address;
};

/**
 * Transform base 64 string to File object
 *
 * @param dataURI
 * @param fileName
 * @returns {File}
 */
export const dataURItoFile = (dataURI, fileName) => {
  let arr = dataURI.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--)
    u8arr[n] = bstr.charCodeAt(n);

  return new File([u8arr], fileName, { type: mime });
};

export const getStringPrice = (price) => {
  return price.toFixed(2)
  .toString()
  .replace('.', ',');
};

export const preventEvent = (ev) => {
  ev.stopPropagation();
  ev.preventDefault();
};

/**
 * Disable space and enter events. Disable all chars without numbers
 * @param e
 * @param maxLength
 */
export const onKeyPress = (e, maxLength = null) => {
  const x = e.charCode || e.keyCode;
  if (isNaN(String.fromCharCode(e.which)) || x === 13 || x === 32) e.preventDefault();
  const currentTextLength = e.target.outerText.length;
  if (maxLength && currentTextLength > maxLength) e.preventDefault();
};

export const disableSideBar = (pathname) => {
  return pathname.includes('/card-shop') || pathname.includes('/premium') || pathname.includes('/energy') || pathname.includes('/bank');
}