import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import Helpers from '../../theme/Helpers';
import PagePopup from '../Dialog/PagePopup';
import Colors from '../../theme/Colors';
import AnimationButton from '../Button/AnimationButton';
import InventoryCard from '../Inventory/InventoryCard';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';
import { capitalize } from '../../utils/helper';

const useStyles = makeStyles(() => ({
  blurContainer: {
    maxWidth: 1180,
    margin: '118px auto 0',
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
    borderRadius: 6,
    padding: '23px 32px',
    position: 'relative',
    color: Colors.white,
  },
  blurFooter: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainEnd,
    backgroundColor: 'rgba(255, 255, 255, 0.03)',
    borderRadius: '0px 0px 6px 6px',
    padding: '17px 32px',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
}));

const StarterPackCardsDialog = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { cards, handleClose, isAlbum } = props;

  const goToRoute = (route) => {
    handleClose();
    props.history.push(route);
  };

  return (
    <PagePopup>
      <CustomBackdropFilter
        className={classes.blurContainer}
        filter={'blur(50px)'}
        canvasFallback={true}
        html2canvasOpts={{
          allowTaint: true,
        }}
      >
        <div>{t('cards_added')}</div>
        <div style={{ marginTop: 8, color: '#50F4AD', fontSize: 14, marginBottom: 26 }}>
          <i className="fa-solid fa-circle-check"
             style={{ fontSize: 18, color: '#50F4AD', marginRight: 6 }} />
          {t('cards_added_count', { count: cards && cards.length })}
        </div>

        <div style={{
          width: '100%',
          display: 'grid',
          overflow: 'auto',
          gap: 16,
          gridTemplateColumns: 'repeat(4, 1fr)',
          height: 600,
        }}>
          {cards && cards.map((card, index) => (
            <InventoryCard
              key={`StarterPackCardKey${card._id}`}
              card={card}
              t={t}
              switchLastHover={(index + 1) % 4 === 0}
            />
          ))}
        </div>

        <CustomBackdropFilter
          className={classes.blurFooter}
          filter={'blur(50px)'}
          canvasFallback={true}
          html2canvasOpts={{
            allowTaint: true,
          }}
        >
          <AnimationButton
            status="whiteTransparency"
            onClick={() => goToRoute('/inventory/cards')}
          >
            {t('go_to_inventory')}
          </AnimationButton>
          {isAlbum ?
            <AnimationButton
              status="neonGreen"
              style={{ marginLeft: 8, fontFamily: 'Montserrat', fontSize: 14 }}
              onClick={() => goToRoute(`/card-shop/streamer/${cards && cards[0].card_template_metadata.match._id}`)}
            >
              {t('order_booklet_now')}
            </AnimationButton>
            :
            <AnimationButton
              status="whiteTransparency"
              style={{ marginLeft: 8 }}
              onClick={() => handleClose()}
            >
              {capitalize(t('close'))}
            </AnimationButton>
          }
        </CustomBackdropFilter>
      </CustomBackdropFilter>
    </PagePopup>
  );
};

export default withRouter(StarterPackCardsDialog);