import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, withRouter } from 'react-router-dom';

import StripeContainer from '../../containers/Stripe/Stripe';
import StreamerSettings from '../Settings/StreamerSettings';
import BoosterManagementContainer from '../../containers/BoosterManagement/BoosterManagement';
import HeroMakerContainer from '../../containers/HeroMaker/HeroMaker';
import BoosterManagementCreateBooster from '../BoosterManagement/BoosterManagementCreateBooster';
import BoosterManagementUpdateBooster
  from '../../containers/BoosterManagement/BoosterManagementUpdateBooster';
import CardToolFabricContainer from '../../containers/CardTool/CardToolFabric';
import AlertsContainer from '../../containers/Settings/AlertsContainer';
import CreateAlertContainer from '../../containers/Settings/CreateAlertContainer';
import DiscordRolesContainer from '../../containers/Settings/DiscordRolesContainer';
import TopBG from '../TopViewHeader/TopBG';

const useStyles = makeStyles(() => ({
  innerContainer: {
    position: 'relative',
    width: '100%',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    color: '#4B90CF',
  },
}));

const StreamerSettingsComponent = (props) => {
  const classes = useStyles();
  const { tab, type } = useParams();
  const { viewer, streamer } = props;

  const categories = [
    {
      key: 'streamer',
      component: <StreamerSettings streamer={streamer} viewer={viewer} />,
    },
    {
      key: 'roles',
      component: <DiscordRolesContainer />,
      fullWidth: true,
    },
    {
      key: 'alerts',
      component: <AlertsContainer />,
      fullWidth: true,
    },
    {
      key: 'alerts',
      type: 'create',
      component: <CreateAlertContainer />,
    },
    {
      key: 'alerts',
      type: 'update',
      component: <CreateAlertContainer />,
    },
    {
      key: 'stripe',
      component: <StripeContainer />,
    },
    {
      key: 'booster-management',
      component: <BoosterManagementContainer />,
    },
    {
      key: 'booster-management',
      type: 'create',
      component: <BoosterManagementCreateBooster />,
    },
    {
      key: 'booster-management',
      type: 'update',
      component: <BoosterManagementUpdateBooster />,
    },
    {
      key: 'booster-management',
      type: 'card-tool',
      component: <CardToolFabricContainer />,
    },
    {
      key: 'hero-maker',
      component: <HeroMakerContainer viewer={viewer} />,
    },
  ];

  const activePage = categories.find(item => item.key === tab && item.type === type);

  return (
    <div className={classes.innerContainer}>
      {activePage.fullWidth ?
        activePage.component
        :
        <TopBG>
          {activePage.component}
        </TopBG>
      }
    </div>
  );
};

export default withRouter(StreamerSettingsComponent);