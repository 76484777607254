import React from 'react';
import { OrbitControls } from '@react-three/drei';

import BoosterObject from './BoosterObject';

const BoosterScene = (props) => {
  const { noControls, frontImage, backImage, userLogo, isRotating, isOblique, isCustomPack } = props;

  return (
    <>
      <BoosterObject
        frontImage={frontImage}
        backImage={backImage}
        userLogo={userLogo}
        isRotating={isRotating}
        isOblique={isOblique}
        isCustomPack={isCustomPack}
      />
      {!noControls &&
      <OrbitControls minDistance={3} maxDistance={8} makeDefault />
      }
    </>
  );
};

export default BoosterScene;