import React, { useEffect, useState } from 'react';

const Lights = (props) => {
  const { startLight, setStartLight } = props;
  const [intensity, setIntensity] = useState(0.0);

  useEffect(() => {
    if (!startLight) {

      if (intensity >= 2.0) {
        setStartLight(true);
      } else
        setTimeout(() => setIntensity(intensity + 0.05), 100);
    }
  }, [startLight, intensity]);

  return (
    <>
      <ambientLight intensity={0.1} />
      <spotLight position={[5, 0, 15]} intensity={startLight ? 2 : intensity} penumbra={1}
                 angle={0.35}
                 color="white" />
    </>
  );
};

export default Lights;