import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import Colors from '../../theme/Colors';

const useStyles = makeStyles(() => ({
  filled: {
    fill: Colors.white,
  },
  selectRoot: {
    borderColor: '#FFFFFF00',
    fontSize: 14,
    padding: 12,
  },
  formInput: {
    width: '100%',
    marginTop: 6,
    zIndex: 100000,
    background: 'transparent',
    borderColor: '#FFFFFF1F',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FFFFFF1F',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FFFFFF1F',
    },
    '& .MuiFormLabel-root': {
      color: Colors.halfWhite,
    },
  },
  menu: {
    zIndex: '130000 !important',
  },
}));

const CreateAlertSelect = (props) => {
  const classes = useStyles();
  const { value, handleChange, children, placeholder, id, disabled, style } = props;

  return (
    <FormControl variant="outlined" className={classes.formInput} required style={style && style}>
      <Select
        id={id}
        value={value}
        onChange={handleChange}
        displayEmpty
        disabled={disabled}
        renderValue={
          value !== null ?
            undefined
            :
            () => <div style={{ color: Colors.halfWhite, fontSize: 14 }}>{placeholder}</div>
        }
        MenuProps={{ className: classes.menu }}
        classes={{
          icon: classes.filled,
          root: classes.selectRoot,
        }}
      >
        {children}
      </Select>
    </FormControl>
  );
};

export default CreateAlertSelect;