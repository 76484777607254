import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Helpers from '../../theme/Helpers';

import conf from '../../conf.json';

const useStyles = makeStyles({
  badge: {
    width: 58,
    height: 58,
    background: 'rgba(255, 255, 255, 0.04)',
    ...Helpers.fillColCenter,
    borderRadius: '50%',
  },
  badges: {
    alignSelf: 'flex-end',
    display: 'grid',
    gap: 6,
    gridTemplateColumns: 'repeat(4, 1fr)',
    position: 'absolute',
    bottom: 12,
    left: 12,
    right: 12,
  },
});

const RarityBadges = (props) => {
  const classes = useStyles();
  const { data, areMore, count, isSmaller } = props;

  if (!data.rarities)
    return null;

  return (
    <div
      className={classes.badges}
      style={{
        ...areMore ? { left: 20, right: 20, gridTemplateColumns: 'repeat(5, 1fr)' } : {},
        ...isSmaller ? { left: 16, right: 15, gap: 4 } : {},
      }}
    >
      {[...data.rarities.sort()].reverse()
      .slice(0, data.rarities.length > count ? (count - 1) : count)
      .map(rarity => (
        <div
          className={classes.badge}
          key={`StreamerKey${data.streamer && data.streamer.login}Rarity${rarity}`}
          style={isSmaller ? { width: 47, height: 47 } : {}}
        >
          <img
            src={`${conf.cdn_url}/badges/card-level/${rarity}.png?height=${isSmaller ? 24 : 29}`}
            alt="Level badge"
          />
        </div>
      ))}
      {data.rarities.length > count &&
      <div className={classes.badge} style={isSmaller ? { width: 47, height: 47 } : {}}>
        +{data.rarities.length - (count - 1)}
      </div>
      }
    </div>
  );
};

export default RarityBadges;