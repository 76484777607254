import {
  RESET_MARKETPLACE_DATA, SET_MARKETPLACE_ALL_DATA,
} from './constants';

const INITIAL_STATE = {
  offerId: null,
  cardId: null,
  frontHash: null,
  quality: null,
  price: null,
  avgPrice: null,
  rCard: null,
};

const marketplaceReducer = (state = INITIAL_STATE, action) => {
  if (action.type === SET_MARKETPLACE_ALL_DATA) {
    return { ...state, ...action.data };
  } else if (action.type === RESET_MARKETPLACE_DATA) {
    return INITIAL_STATE;
  } else {
    return state;
  }
};

export default marketplaceReducer;