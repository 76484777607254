import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { getScaleFactor } from '../../utils/helper';
import { changeCardVerification, changeSwipeVerification } from '../../redux/actions';
import Loading from '../Loading/Loading';
import SDKSingleton from './classes/SDK';

/**
 * Possible requirements as well as scaling factor are found out and set
 *
 * @param props
 */
const OpenVerification = (props) => {
  const { changeCardVerification, changeSwipeVerification } = props;
  const [scaleIsReady, setScaleIsReady] = useState(false);
  const sdk = SDKSingleton.getInstance().sdk;

  useEffect(() => {
    changeCardVerification({ invocationIsValid: true });

    sdk.getInitialisationSubject()
    .subscribe((response) => {
      if (response.ppi) {
        const scale = getScaleFactor(response.ppi, response.devicePixelRatio);

        if (!Number.isNaN(scale)) {
          changeSwipeVerification({ scaleFactor: scale });
          console.log('scaleFactor', scale);
        }
      } else {
        changeCardVerification({ deviceIsSupported: false });
        console.log('device_not_supported');
      }

      if (response.deviceSupport) {
        console.log(response.deviceSupport);

        if (response.deviceSupport.requirements)
          console.log(response.deviceSupport.requirements);
      }

      if (response.deviceSupport?.requirements?.includes('touchsensitivity'))
        changeCardVerification({ needsTouchSensitivity: true });

      // wait a little, to allow for possible (but not necessary usability subject response)
      setTimeout(() => {
        setScaleIsReady(true);
      }, 10);
    });

    sdk.getUsabilitySubject()
    .subscribe((response) => {
      if (response.event === 'device_not_supported') {
        changeCardVerification({ deviceIsSupported: false });
        console.log('device_not_supported');
      }

      if (response.event === 'browser_not_supported') {
        changeCardVerification({ browserIsSupported: false });
        console.log('browser_not_supported');
      }

      if (response.event === 'display_too_small_displacement') {
        changeCardVerification({ screenIsBigEnough: false });
        console.log('display_too_small_displacement');
      }

      if (response.event === 'display_small_should_add_to_home') {
        changeCardVerification({ screenIsBigEnough: false });
        console.log('display_small_should_add_to_home');
      }
    });
  }, [sdk]);

  if (scaleIsReady)
    return <Redirect to="/verification/start" />;

  return (
    <Loading />
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    changeCardVerification,
    changeSwipeVerification,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(OpenVerification);