import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks';

import {
  getViewerRankingsWithCount,
  getViewerRankingsWithCountAndLeaders,
} from '../../apollo/mergedQueries';

import LeaderBoardList from '../../components/Leaderboard/LeaderBoardList';
import { rangeArray } from '../../utils/helper';
import ListSkeletonItem from '../../components/Skeletons/ListSkeletonItem';
import RankingLeaders from '../../components/Ranking/RankingLeaders';
import TopBG from '../../components/TopViewHeader/TopBG';
import HeaderLine from '../../components/TopViewHeader/HeaderLine';
import Loading from '../../components/Loading/Loading';
import Helpers from '../../theme/Helpers';
import LeaderBoardRowList from '../../components/Leaderboard/LeaderBoardRowList';
import Colors from '../../theme/Colors';
import { returnMobileObj } from '../../utils/responsive';
import eventConfig from '../../event.data.json';
import conf from '../../conf.json';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import MyRanking from '../../components/Ranking/MyRanking';

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 6,
  },
  headerLine: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
  },
  leaderWrapper: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    ...Helpers.crossEnd,
    flexWrap: 'wrap',
    '& > div > div': {
      background: 'rgba(0, 0, 0, 0.5)',
      '& > div:first-child': {
        color: Colors.white,
      },
    },
  },
}));

const ViewerRankingContainer = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { filters } = props;
  const [page, setPage] = useState(1);
  const perPage = conf.event_mode ? 200 : 100;
  // const queryFilter = filters.country && filters.country.length > 0 ? filters.country : {};
  const sorting = {
    field: 'total_points',
    order: 'DESC',
  };

  const getViewerRankingsWithCountAndLeadersQuery = useQuery(getViewerRankingsWithCountAndLeaders, {
    variables: {
      sorting,
      pagination: {
        page: 1,
        perPage,
      },
      // filter: queryFilter,
    },
    fetchPolicy: useCacheWithExpiration('getViewerRankingsWithCountAndLeaders'),
    skip: conf.event_mode,
  });
  const getViewerRankingsWithCountQuery = useQuery(getViewerRankingsWithCount, {
    variables: {
      sorting,
      pagination: {
        page: 1,
        perPage,
      },
      // filter: queryFilter,
    },
    fetchPolicy: useCacheWithExpiration('getViewerRankingsWithCount'),
    skip: conf.event_mode,
  });
  let executedQuery = getViewerRankingsWithCountAndLeadersQuery;

  if (getViewerRankingsWithCountQuery.data)
    executedQuery = getViewerRankingsWithCountQuery;

  const { data, loading, fetchMore } = executedQuery;
  const rankings = conf.event_mode ? eventConfig.data.getViewerRankings : (data) && data.getViewerRankings;
  const rankingsCount = conf.event_mode ? 200 : (data) && data.getViewerRankingsCount;

  let leaders = [];
  if (rankings)
    leaders = rankings.slice(0, 3);

  return (
    <>
      <div style={{
        maxWidth: 1440,
        margin: '45px auto 0',
      }}>
        <div className={classes.container}>
          {(!loading && rankings) &&
          <RankingLeaders entries={leaders} />
          }
        </div>
        <div className={classes.container}>
          {loading ?
            rangeArray(1, 10)
            .map(item =>
              <ListSkeletonItem rounded subText key={`RankingSkeletonKey${item}`} />,
            )
            :
            rankings &&
            <LeaderBoardList
              entries={rankings}
              hasMore={(rankings) && rankingsCount > rankings.length}
              onLoadMore={async () => {
                try {
                  await fetchMore({
                    variables: {
                      sorting,
                      pagination: {
                        perPage,
                        page: page + 1,
                      },
                      // filter: queryFilter,
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev;
                      setPage(page + 1);
                      return Object.assign({}, prev, {
                        getViewerRankings: [...prev.getViewerRankings, ...fetchMoreResult.getViewerRankings],
                      });
                    },
                  });
                } catch {
                }
              }}
            />
          }
        </div>
      </div>
    </>
  );
};

export default ViewerRankingContainer;