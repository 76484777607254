import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import Avatar from '@material-ui/core/Avatar';

import Helpers from '../../theme/Helpers';
import Scene from '../3DCard/Scene';
import ThreeDWrapper from '../3DCard/3DWrapper';
import Colors from '../../theme/Colors';
import MiniCountdown from './MiniCountdown';
import AnimationButton from '../Button/AnimationButton';

const useStyles = makeStyles(() => ({
  container: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    width: '100vw',
    height: '100vh',
  },
  transparentBox: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    padding: '16px 20px',
    color: Colors.white,
  },
  absBox: {
    height: 604,
    width: 498,
    position: 'absolute',
    cursor: 'pointer',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  countdownArea: {
    '& > div': {
      ...Helpers.mainStart,
      marginBottom: 0,
      '& > div': {
        fontSize: 40,
        marginRight: 14,
      },
    },
  },
  clickButton: {
    height: 43,
    width: 47,
    ...Helpers.fillRowCenter,
    borderRadius: 6,
    background: '#FFFFFF14',
    cursor: 'pointer',
    transition: 'all 0.3s ease-out',
    '&:hover': {
      background: '#FFFFFF3D',
    },
  },
  formInput: {
    width: '100%',
    marginRight: 4,
    background: 'transparent',
    borderColor: '#FFFFFF1F',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FFFFFF1F',
      top: 0,
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FFFFFF1F',
    },
    '& .MuiFormLabel-root': {
      color: Colors.halfWhite,
    },
    '& .MuiOutlinedInput-input': {
      padding: '14px 12px',
    },
  },
  avatar: {
    width: 40,
    height: 40,
    border: 'none',
  },
  authorLine: {
    ...Helpers.fillRowCross,
    color: Colors.white,
    '& > span': {
      color: Colors.halfWhite,
      marginLeft: 5,
      fontSize: 14,
    },
  },
  scrollableArea: {
    height: 500,
    overflow: 'auto',
    paddingRight: 5,

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(255, 255, 255, 0.16)',
      borderRadius: 2,
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgb(13, 25, 46)',
      boxShadow: 'none',
    },
  },
}));

const CountdownPage = (props) => {
  const classes = useStyles();
  const { messages } = props;
  const [code, setCode] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const confirmedLogin = localStorage.getItem('SH:CONFIRM:LOGIN');
  const endTime = confirmedLogin ? 1645288200000 : 1645290000000;

  useEffect(() => {
    const objDiv = document.getElementById('scrollArea');
    objDiv.scrollTop = objDiv.scrollHeight;
  }, [messages]);

  const confirmCode = () => {
    if (code === 'password') {
      localStorage.setItem('SH:CONFIRM:LOGIN', 'true');
      enqueueSnackbar('Successfully activated early access', { variant: 'success' });
      window.location.reload();
    }

    setCode('');
  };

  const untilSeconds = moment(endTime)
  .diff(moment(), 'seconds');

  if (untilSeconds <= 0)
    props.history.push('/');

  const card = {
    image: '/partnerpackages_generated/HollaDieWaldfee2022/holladiewaldfee-02-normal-shabby-35.png',
    backImage: '/partnerpackages_generated/backside/shabby-35.png',
  };

  return (
    <div className={classes.container}>
      <div style={{
        maxWidth: 1440,
        width: '100%',
        margin: '0 auto',
        ...Helpers.fillRowCenter,
        ...Helpers.mainSpaceBetween,
      }}>
        <div
          style={{ position: 'absolute', left: 16, top: '50%', transform: 'translate(0, -50%)' }}>
          <div id="scrollArea" className={classes.scrollableArea}>
            <div style={Helpers.fillColMain}>
              {messages && messages.map(message => (
                <div key={message.msg} style={{ ...Helpers.fillRow, margin: '5px 0 ' }}>
                  <div style={{ marginRight: 10 }}>
                    <Avatar
                      variant="circle"
                      alt="Avatar"
                      src="https://static-cdn.jtvnw.net/jtv_user_pictures/fa77cfe3-b7dd-443f-8ba8-594c93740805-profile_image-300x300.png"
                      classes={{ root: classes.avatar }}
                    />
                  </div>
                  <div style={{ width: 380 }}>
                    <div className={classes.authorLine}>
                      Streamheroes System <span>{moment.unix(message.timestamp)
                    .format('lll')}</span>
                    </div>
                    <div style={{
                      background: 'rgba(255, 255, 255, 0.08)',
                      borderRadius: 6,
                      padding: 8,
                      marginTop: 4,
                      color: Colors.white,
                    }}>
                      {message.msg}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* TODO: change with CardImageDecision */}
        <div className={classes.absBox}>
          <ThreeDWrapper noBackdrop>
            <Scene card={card} isRotating isOblique noWhiteCard withLight noControls
                   loopAnimation />
          </ThreeDWrapper>
        </div>

        <div style={{ width: 371, marginLeft: 'auto' }}>
          <div style={{ fontFamily: 'Bebas Neue', fontSize: 36, color: Colors.white }}>
            UPDATE 2.0 CARD REVOLUTION
          </div>

          <div className={classes.transparentBox} style={{ marginTop: 16 }}>
            <div style={{ fontSize: 14, marginBottom: 6 }}>
              The final reveal is in
            </div>
            <div className={classes.countdownArea}>
              <MiniCountdown endTime={endTime} />
            </div>
          </div>

          <div className={classes.transparentBox} style={{ marginTop: 8 }}>
            <div style={{ fontSize: 14, marginBottom: 10 }}>
              Grab your early access!
            </div>

            <div style={{ ...Helpers.fillRowCross, ...Helpers.crossEnd, marginTop: 10 }}>
              <TextField
                id="code"
                name="code"
                label="Enter code here"
                variant="outlined"
                value={code}
                onChange={(event) => setCode(event.target.value)}
                classes={{
                  root: classes.formInput,
                }}
              />
              <div
                className={classes.clickButton}
                onClick={confirmedLogin ? null : confirmCode}
                style={confirmedLogin ? { background: 'rgba(9, 183, 17, 0.5)' } : {}}
              >
                <i className={`fa-light fa-${confirmedLogin ? 'check' : 'arrow-right'}`} />
              </div>
            </div>

            <div style={{ fontSize: 14, marginTop: 20, color: Colors.halfWhite }}>
              Get more information about early access and how to get the code on our discord!
            </div>
          </div>

          <AnimationButton
            status="discordBlue"
            style={{ width: '100%', marginTop: 16 }}
            onClick={() => window.open('https://discord.gg/WJE6b8M', '_blank')}
          >
            <i className="fa-brands fa-discord"
               style={{ marginRight: 8, fontSize: 18, verticalAlign: 'bottom' }} /> Go to
            Discord
          </AnimationButton>
        </div>
      </div>
    </div>
  );
};

export default withRouter(CountdownPage);