import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const useStyles = makeStyles(() => ({
  allPage: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 10,
    backgroundColor: 'rgba(0, 0, 0, .6)',
  },
  container: {
    position: 'absolute',
    backgroundColor: '#292e34',
    zIndex: 150000,
    maxHeight: '75%',
    padding: '20px 0',
    width: '70%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflowY: 'auto',
    animationName: '$popup',
    animationDuration: '0.5s',
  },
  '@keyframes popup': {
    '0%': {
      top: '-50%',
    },
    '100%': {
      top: '50%',
    },
  },
}));

const CardPopup = (props) => {
  const classes = useStyles();
  const { children, handleClose, style } = props;

  return (
    <div className={classes.allPage}>
      <ClickAwayListener onClickAway={handleClose ? handleClose : null}>
        <div className={classes.container} style={style ? style : {}}>
          {children}
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default CardPopup;