import { ADD_ACTIVITY_FEED_ITEM, ADD_ACTIVITY_FEED_LIST } from './constants';

const INITIAL_STATE = {
  list: [],
};

const activityFeedReducer = (state = INITIAL_STATE, action) => {
  let newState;

  if (action.type === ADD_ACTIVITY_FEED_LIST) {
    if (state.list.length === 0) {
      newState = { list: action.activityFeedList.reverse() };
    } else {
      const addList = [];
      for (const activityFeedItem of action.activityFeedList.reverse()) {
        const findIndex = state.list.findIndex(item => item._id === activityFeedItem._id);

        if (findIndex === -1) {
          addList.push(activityFeedItem);
        }
      }
      if (addList.length > 0) {
        const tmpFeedList = [...state.list].concat(addList);
        newState = { list: tmpFeedList };
      }
    }

    return { ...state, ...newState };
  } else if (action.type === ADD_ACTIVITY_FEED_ITEM) {
    const findItem = state.list.find(item => item._id === action.activityFeedItem._id);

    if (!findItem) {
      const tmpFeedList = [...state.list];
      tmpFeedList.push(action.activityFeedItem);

      newState = { list: tmpFeedList };
    }
    return { ...state, ...newState };
  } else {
    return state;
  }
};

export default activityFeedReducer;