import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { TextField } from '@material-ui/core';

import { updateDeckName as updateDeckNameMutation } from '../../apollo/mutations';
import { getCardDecks } from '../../apollo/mergedQueries';

import Helpers from '../../theme/Helpers';
import PagePopup from '../Dialog/PagePopup';
import AnimationButton from '../Button/AnimationButton';
import Colors from '../../theme/Colors';
import ClosePopupButton from '../Button/ClosePopupButton';
import RarityBadges from './RarityBadges';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';

import conf from '../../conf.json';

const useStyles = makeStyles({
  container: {
    background: '#091828',
    maxWidth: 924,
    margin: '50vh auto 0',
    transform: 'translate(0, -50%)',
    position: 'relative',
    color: Colors.white,
    borderRadius: 4,

    '& > div:last-child > div': {
      border: 'none !important',

      '& > div': {
        padding: 0,
      },
    },
  },
  subText: {
    marginTop: 2,
    fontSize: 14,
    marginBottom: 20,
    color: Colors.halfWhite,
    '& span': {
      color: Colors.white,
      fontWeight: 500,
    },
  },
  textField: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(255, 255, 255, 0.12)',
      borderRadius: 6,
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.halfWhite,
    },
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
      fontSize: 14,
      fontFamily: 'Montserrat',
      '&::placeholder': {
        color: Colors.halfWhite,
      },
    },
  },
  badgesArea: {
    height: 58,
    marginTop: -42,
    width: 250,
    position: 'relative',
    '& > div': {
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  bgArea: {
    background: 'rgba(255, 255, 255, 0.08)',
    padding: 20, ...Helpers.fillColCenter,
    borderRadius: '4px 4px 0px 0px',
    position: 'relative',
  }
});

const RenameStorageBoxDialog = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t, onClose, storageBox } = props;
  const [name, setName] = useState('');

  const [updateDeckName, { loading }] = useMutation(updateDeckNameMutation, {
    onCompleted: () => {
      setName('');
      onClose();
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [
      { query: getCardDecks },
    ],
  });

  const renameDeck = () => {
    updateDeckName({
      variables: {
        deck: storageBox._id,
        name,
      },
    });
  };

  const image = `${conf.cdn_url}/assets/template/storage_boxes/SH_StorageBox_Design_01_Normal.png?height=238`;

  return (
    <PagePopup>
      <div className={classes.container}>

        <ClosePopupButton handleClose={onClose} style={{ right: 16, top: 16 }} />

        <CustomBackdropFilter
          className={classes.bgArea}
          filter={'blur(8px)'}
          canvasFallback={true}
          html2canvasOpts={{
            allowTaint: true,
          }}
        >
          <div style={{
            ...Helpers.insetZero,
            backgroundImage: `url("${image}")`,
            mixBlendMode: 'luminosity',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            zIndex: -1,
            opacity: 0.12,
            filter: 'blur(8px) grayscale(1)',
          }} />

          <img src={image} alt="Storage box" />

          <div className={classes.badgesArea}>
            <RarityBadges count={4}
                          data={{ ...storageBox, streamer: { login: storageBox._id } }} />
          </div>
        </CustomBackdropFilter>

        <div style={{ background: 'rgba(255, 255, 255, 0.04)', padding: 32, marginTop: 3 }}>
          <div style={{ fontSize: 24 }}>
            {t('enter_new_storage_box_name')}
          </div>
          <div
            className={classes.subText}
            dangerouslySetInnerHTML={{ __html: t('current_storage_box_name_is', { name: storageBox.name }) }}
          />

          <TextField
            variant="outlined"
            onChange={(event) => setName(event.target.value)}
            value={name}
            placeholder={t('enter_box_name')}
            className={classes.textField}
            style={{ marginRight: 5 }}
          />

        </div>

        <div style={{ ...Helpers.fillRowReverse, padding: '16px 32px' }}>

          <AnimationButton
            status="whiteTransparency"
            onClick={renameDeck}
            disabled={loading || name.length < 2}
          >
            {loading ?
              <i className="fa-light fa-loader fa-spin" style={{ fontSize: 18 }} />
              :
              t('rename_box')
            }
          </AnimationButton>
        </div>
      </div>
    </PagePopup>
  );
};

export default RenameStorageBoxDialog;
