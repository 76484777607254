import React from 'react';

const MergedImage = (props) => {
  const { card, isLegacy, height, borderRadius, id } = props;

  return (
    <>
      <img
        src={`${isLegacy ? card.image : (card.front_image_url || card.front_layers[0].img_url)}?height=${height}`}
        alt="Card"
        height={height}
        style={{ borderRadius }}
        id={id}
      />
      {(!isLegacy && !card.front_image_url && card.front_layers && card.front_layers.length > 1) &&
      <img
        src={`https://s3.eu-central-1.wasabisys.com/streamheroes-card-manager/${card.front_layers[1].img_url}?height=${height}`}
        alt="Card condition"
        height={height}
        style={{
          borderRadius,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          mixBlendMode: 'screen'
        }}
      />
      }
    </>
  );
};

export default MergedImage;