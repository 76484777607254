import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    right: 0,
    top: 68,
    minWidth: 300,
    borderRadius: 5,
    padding: 10,
    color: Colors.white,
    background: '#b60b28',
    zIndex: 100,
  },
  arrow: {
    width: 0,
    height: 0,
    display: 'block',
    position: 'absolute',
    right: 24,
    top: -9,
    borderLeft: '11px solid transparent',
    borderBottom: '11px solid #b60b28',
    borderRight: '11px solid transparent',
  },
  wrapper: {
    backgroundColor: '#701626',
    padding: 10,
    marginTop: 10,
    fontWeight: 'normal',
  },
  row: {
    ...Helpers.fillRowCross,
    ...Helpers.mainSpaceBetween,
    color: '#d58b99',
    cursor: 'pointer',
    '&:hover': {
      color: Colors.white,
    },
  },
}));

const DropdownMenu = (props) => {
  const classes = useStyles();
  const { rows, style, title, arrowStyle, onClick, onClickAway } = props;

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div className={classes.container} style={style}>
        <div className={classes.arrow} style={arrowStyle} />
        <div>
          {title &&
          <div style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            {title}
          </div>
          }
          <div className={classes.wrapper}>
            {rows && rows.map((item) =>
              <div key={item.key} className={classes.row} onClick={() => onClick(item.key)}>
                {item.value}
              </div>,
            )}
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
};

DropdownMenu.propTypes = {
  style: PropTypes.object,
  arrowStyle: PropTypes.object,
};

DropdownMenu.defaultProps = {
  style: {},
  arrowStyle: {},
};

export default DropdownMenu;