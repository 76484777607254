import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Tilt from 'react-tilt';
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import { Helmet } from 'react-helmet';
import { useQuery } from '@apollo/react-hooks';
import { useMediaQuery } from 'react-responsive';

import { getAllActiveCards } from '../../apollo/queries';
import { getMatchName } from '../../utils/helper';

import Loading from '../Loading/Loading';
import CardViewSocial from './CardViewSocial';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import CardViewDetails from './CardViewDetails';
import CardViewTopCollectors from './CardViewTopCollectors';
import HeaderLine from '../TopViewHeader/HeaderLine';
import { isTabletOrMobileObj } from '../../utils/responsive';
import ShinyButton from '../Button/ShinyButton';
import TopBG from '../TopViewHeader/TopBG';
import Alert from '../Alert/Alert';
import CustomButton from '../Button/CustomButton';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

const useStyles = makeStyles(() => ({
  imageContainer: {
    ...Helpers.fillColCenter,
    padding: 20,
    flex: 1,
  },
  redeemedArea: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    color: Colors.white,
    marginRight: 30,
  },
  smallAvatar: {
    marginRight: 10,
    width: 32,
    height: 32,
  },
  activeCard: {
    cursor: 'pointer',
  },
  smallCard: {
    filter: 'grayscale(100%)',
    margin: 5,
    transition: '.3s ease-in-out',
    '&:hover': {
      filter: 'none',
    },
  },
  headerLine: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    marginBottom: 16,
  },
  buttonArea: {
    ...Helpers.fillRowCenter,
    position: 'relative',
  },
  titles: {
    padding: 20,
    paddingBottom: 0,
    textTransform: 'uppercase',
  },
  title: {
    fontSize: 34,
    fontFamily: 'Bebas Neue',
    lineHeight: 1,
    color: Colors.white,
  },
  subTitle: {
    color: '#79859a',
    lineHeight: 1,
  },
  collectorsDetails: {
    background: 'rgba(0, 0, 0, 0.7)',
    '& > div': {
      margin: 0,
      '& > div': {
        padding: 0,
      },
    },
    '& ul': {
      backgroundColor: 'transparent !important',
    },
  },
  cardDetails: {
    background: 'rgba(0, 0, 0, 0.7)',
    '& > div': {
      backgroundColor: 'transparent',
      padding: 20,
      '& > div': {
        borderColor: Colors.halfWhite,
        '& > div:first-child': {
          color: Colors.halfWhite,
        },
      },
    },
  },
}));

const CardView = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [ratio, setRatio] = useState({
    popularity: 0.5,
    rarity: 0.5,
  });
  const { card, informationQuery, viewerId, firstRedeemer, isCraft, showBeta } = props;
  const { loading } = informationQuery;
  const { match, matchModel } = card;
  const informationData = (informationQuery.data) && informationQuery.data.getInformationAboutCard;
  let cardHeader = null;

  const isTabletOrMobile = useMediaQuery(isTabletOrMobileObj);

  const getAllStreamerCardsQuery = useQuery(getAllActiveCards, {
    variables: { streamer: match && match._id, includeCards: true },
    fetchPolicy: useCacheWithExpiration('getViewerDashboard', {
      streamer: match && match._id,
      includeCards: true,
    }),
    skip: !match || matchModel !== 'Streamer',
  });
  const getAllOrganisationCardsQuery = useQuery(getAllActiveCards, {
    variables: { organisation: match && match._id, includeCards: true },
    fetchPolicy: useCacheWithExpiration('getViewerDashboard', {
      organisation: match && match._id,
      includeCards: true,
    }),
    skip: !match || matchModel !== 'Organisation',
  });
  const getAllGameCardsQuery = useQuery(getAllActiveCards, {
    variables: { game: match && match._id, includeCards: true },
    fetchPolicy: useCacheWithExpiration('getViewerDashboard', {
      game: match && match._id,
      includeCards: true,
    }),
    skip: !match || matchModel !== 'Game',
  });

  const getAllStreamerCards = (getAllStreamerCardsQuery.data) && getAllStreamerCardsQuery.data.getAllActiveCards && getAllStreamerCardsQuery.data.getAllActiveCards.entries[0];
  const getAllOrganisationCards = (getAllOrganisationCardsQuery.data) && getAllOrganisationCardsQuery.data.getAllActiveCards && getAllOrganisationCardsQuery.data.getAllActiveCards.entries[0];
  const getAllGameCards = (getAllGameCardsQuery.data) && getAllGameCardsQuery.data.getAllActiveCards && getAllGameCardsQuery.data.getAllActiveCards.entries[0];

  const cardsLoading = getAllStreamerCardsQuery.loading || getAllOrganisationCardsQuery.loading || getAllGameCardsQuery.loading;

  const changeRatio = (popularity) => {
    setRatio({ popularity, rarity: 1.0 - popularity });
  };

  let allCards;
  if (matchModel === 'Streamer')
    allCards = getAllStreamerCards;
  else if (matchModel === 'Organisation')
    allCards = getAllOrganisationCards;
  else if (matchModel === 'Game')
    allCards = getAllGameCards;

  let totalCards = [];
  if (allCards && allCards.cards)
    totalCards = totalCards.concat(allCards.cards)
    .sort((a, b) => (a.level - b.level));
  if (allCards && allCards.special_cards)
    totalCards = totalCards.concat(allCards.special_cards);

  if (card && card._id) {
    cardHeader = (
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@OWN3D" />
        <meta name="twitter:image"
              content={`https://streamheroes-image-cdn.s3.amazonaws.com/social-share-images/tw_card_${card._id}.jpg`} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title"
              content={`Level ${card.level} card of ${getMatchName(card)}`} />
        <meta property="og:description"
              content={`Get your card of ${getMatchName(card)} today!`} />
        <meta property="og:image"
              content={`https://streamheroes-image-cdn.s3.amazonaws.com/social-share-images/fb_card_${card._id}.jpg`} />
      </Helmet>
    );
  }

  let redeemedDate;
  if (firstRedeemer) redeemedDate = moment.unix(firstRedeemer.created_at / 1000)
  .format('l');

  let linkUrl = '';
  if (match) {
    if (matchModel === 'Game')
      linkUrl += '/hq/game-streams/';
    else
      linkUrl += '/' + matchModel.toLowerCase() + '/';
  }

  linkUrl += getMatchName(card, true);

  const result = card.popularity * ratio.popularity + card.rarity * ratio.rarity;
  const rating = Math.round((result + Number.EPSILON) * 100) / 100;

  return (
    <>
      <TopBG>
        <div className={classes.headerLine}>
          <HeaderLine
            title={getMatchName(card)}
            subTitle={(card && card.__typename === 'Card') ? `Level ${card.level} Card` : null}
            isTabletOrMobile={isTabletOrMobile}
          />
          {cardHeader}
          <div className={classes.buttonArea}>
            {(firstRedeemer && !isCraft) && // show only if it was not after a craft
            <div>
              <div className={classes.redeemedArea}>
                <Avatar
                  className={classes.smallAvatar}
                  alt={firstRedeemer.viewer.display_name}
                  src={firstRedeemer.viewer.profile_image_url} />
                {t('first_redeemer', {
                  user: firstRedeemer.viewer.display_name,
                  date: redeemedDate,
                })}
              </div>
            </div>
            }
            {card.is_archived &&
            <Alert style={{ padding: '7px 15px', marginTop: 7 }}>
              {t('archived_card_explain')}
            </Alert>
            }
            {match &&
            <ShinyButton onClick={() => props.history.push(linkUrl)}>
              {t('go_to_profile', { name: getMatchName(card) })}
            </ShinyButton>
            }
          </div>
        </div>

        <div style={{
          ...Helpers.fillRowMain,
          ...Helpers.mainSpaceBetween,
          ...Helpers.crossStart,
          flexWrap: 'wrap',
        }}>
          <div>
            <div>
              <div className={classes.cardDetails}>
                <div className={classes.titles} style={{ paddingBottom: 0 }}>
                  <div className={classes.title}>{t('card_details')}</div>
                  <div className={classes.subTitle}>{t('statistics')}</div>
                </div>
                {loading ?
                  <Loading />
                  :
                  <CardViewDetails card={card} informationData={informationData} />
                }
              </div>
              {(isCraft && viewerId) && // show only after craft
              <CardViewSocial card={card} viewerId={viewerId} />
              }
            </div>
          </div>
          <div className={classes.imageContainer}>
            {cardsLoading ?
              <Loading />
              :
              <>
                <div style={{ ...Helpers.fillRowCenter, marginBottom: 10, flexWrap: 'wrap' }}>
                  <Tilt className="Tilt" options={{ max: 20 }}>
                    <img
                      src={`${card.image}?height=324`}
                      alt={`CardImage${card._id}`}
                      style={{ borderRadius: 4 }}
                    />
                  </Tilt>
                </div>
                <div style={{ ...Helpers.fillRowCenter, flexWrap: 'wrap', marginTop: 16 }}>
                  {totalCards.map(item => (
                    <Link
                      to={`/card/${item._id}`}
                      key={`CardRow${item._id}`}
                      className={classes.smallCard}
                      style={card._id === item._id ? { filter: 'none' } : {}}
                    >
                      <Tilt className="Tilt" options={{ max: 20 }}>
                        <img
                          src={`${item.image}?height=100`}
                          alt={`CardImage${item._id}`}
                          style={{ borderRadius: 4 }}
                        />
                      </Tilt>
                    </Link>
                  ))}
                </div>
              </>
            }
          </div>
          <div>
            <div className={classes.collectorsDetails}>
              <div className={classes.titles}>
                <div className={classes.title}>{t('first_collector')}</div>
                <div className={classes.subTitle}>{t('faster_than_light')}</div>
              </div>
              {loading ?
                <Loading />
                :
                <CardViewTopCollectors informationData={informationData} isTime />
              }
            </div>
          </div>
        </div>
        {showBeta &&
        <div style={{ color: Colors.white }}>
          <div><b>Popularity:</b> {card.popularity}</div>
          <div><b>Rarity:</b> {card.rarity}</div>
          <div><b>Rating:</b> {rating}</div>
          <div style={{ marginTop: 5 }}>
            <CustomButton onClick={() => changeRatio(0.85)}>
              85:15
            </CustomButton>
            <CustomButton onClick={() => changeRatio(0.8)} style={{ margin: '0 5px' }}>
              80:20
            </CustomButton>
            <CustomButton onClick={() => changeRatio(0.75)}>
              75:25
            </CustomButton>
            <CustomButton onClick={() => changeRatio(0.7)} style={{ margin: '0 5px' }}>
              70:30
            </CustomButton>
            <CustomButton onClick={() => changeRatio(0.65)}>
              65:35
            </CustomButton>
            <CustomButton onClick={() => changeRatio(0.6)} style={{ margin: '0 5px' }}>
              60:40
            </CustomButton>
            <CustomButton onClick={() => changeRatio(0.55)}>
              55:45
            </CustomButton>
            <CustomButton onClick={() => changeRatio(0.5)} style={{ margin: '0 5px' }}>
              50:50
            </CustomButton>
          </div>
        </div>
        }
      </TopBG>
    </>
  );
};

export default withRouter(CardView);