import gql from 'graphql-tag';

export const getStreamerAndShowcaseCards = gql`
    query getStreamerAndShowcaseCards($viewer: ID, $sorting: Sorting, $login: String) {
        getShowcaseCards(viewer: $viewer, sorting: $sorting, login: $login) {
            _id
            showcase_index
            matchCardModel
            match_card {
                ... on Card {
                    _id
                    level
                    image
                    matchModel
                    created_at
                    match {
                        ... on Streamer {
                            _id
                            display_name
                            login
                        }
                        ... on Game {
                            _id
                            name
                        }
                        ... on Organisation {
                            _id
                            name
                            code
                        }
                    }
                }
                ... on SpecialCard {
                    _id
                    image
                    matchModel
                    created_at
                    match {
                        ... on Streamer {
                            _id
                            display_name
                            login
                        }
                        ... on Game {
                            _id
                            name
                        }
                        ... on Organisation {
                            _id
                            name
                            code
                        }
                    }
                }
            }
        }
        streamer(login: $login) {
            _id
            broadcaster_type
        }
    }
`;

export const getDetailsAboutViewerAndBadgeLevels = gql`
    query getDetailsAboutViewerAndBadgeLevels($viewer: ID) {
        getDetailsAboutViewer(viewer: $viewer) {
            rank_position
            total_coins
            total_cards
        }
        getAllBadges {
            viewer {
                category
                badges {
                    unique_id
                    title
                    pic_url
                    description
                    is_set
                }
            }
            streamer {
                category
                badges {
                    unique_id
                    title
                    pic_url
                    description
                    is_set
                }
            }
        }
        getBadgeLevels(viewer: $viewer) {
            overall_lvl_value
            overall_lvl_badge_lvl
            next_level_required_points
            current_level_required_points
        }
    }
`;

export const getBestRankingPositions = gql`
    query getBestRankingPositions($viewer: ID, $sorting: [Sorting]) {
        streamerPositions: getViewerRankingPositions(viewer: $viewer, matchModel: "Streamer", sorting: $sorting) {
            seq
            pos
            matchModel
            updated_at
            match {
                ... on Streamer {
                    _id
                    login
                    profile_image_url
                    display_name
                }
            }
        }
        gamePositions: getViewerRankingPositions(viewer: $viewer, matchModel: "Game", sorting: $sorting) {
            seq
            pos
            matchModel
            updated_at
            match {
                ... on Game {
                    _id
                    name
                    box_art_url
                }
            }
        }
        organisationPositions: getViewerRankingPositions(viewer: $viewer, matchModel: "Organisation", sorting: $sorting) {
            seq
            pos
            matchModel
            updated_at
            match {
                ... on Organisation {
                    _id
                    name
                    code
                    logo_url
                }
            }
        }
    }
`;

export const getFavouriteRankingPositions = gql`
    query getViewerRankingPositions($viewer: ID, $sorting: [Sorting]) {
        streamerPositions: getViewerRankingPositions(viewer: $viewer, matchModel: "Streamer", sorting: $sorting) {
            seq
            pos
            matchModel
            updated_at
            match {
                ... on Streamer {
                    _id
                    login
                    profile_image_url
                    display_name
                }
            }
        }
        gamePositions: getViewerRankingPositions(viewer: $viewer, matchModel: "Game", sorting: $sorting) {
            seq
            pos
            matchModel
            updated_at
            match {
                ... on Game {
                    _id
                    name
                    box_art_url
                }
            }
        }
    }
`;

export const getLocalStreamerHeader = gql`
    query getLocalStreamerHeader($twitch_id: Int, $streamerId: ID!, $sorting: Sorting) {
        viewer(twitch_id: $twitch_id) {
            _id
            facebook
            youtube
            instagram
            twitter
        }
        getStreamerProfileMetadata(streamerId: $streamerId) {
            collected_cards_past
            collected_cards_present
            collectors_past
            collectors_present
            card_level_past
            card_level_present
            greatest_fan {
                viewer {
                    _id
                    login
                    display_name
                    profile_image_url
                }
                cards
                energy
            }
            collected_cards_1d_past
            collected_cards_1d_present
            collected_cards_7d_past
            collected_cards_7d_present
            collected_cards_30d_past
            collected_cards_30d_present
            collectors_1d_past
            collectors_1d_present
            collectors_7d_past
            collectors_7d_present
            collectors_30d_past
            collectors_30d_present
        }
        cards(streamer: $streamerId, sorting: $sorting) {
            _id
            level
            image
            is_archived
        }
    }
`;

export const getStreamerHeader = gql`
    query getStreamerHeader($id: ID!, $twitch_id: Int) {
        getCollectedCardsAmountOfAStreamer(id: $id) {
            amountOfCardsCollected
        }
        viewer(twitch_id: $twitch_id) {
            _id
            facebook
            youtube
            instagram
            twitter
        }
        getAllBadges {
            viewer {
                category
                badges {
                    unique_id
                    title
                    pic_url
                    description
                    is_set
                }
            }
            streamer {
                category
                badges {
                    unique_id
                    title
                    pic_url
                    description
                    is_set
                }
            }
        }
    }
`;

export const getLoggedInHeader = gql`
    query getLoggedInHeader($streamer: ID, $organisation: ID, $game: ID) {
        viewerRedeemedPoints(streamer: $streamer, organisation: $organisation, game: $game) {
            _id
            viewer {
                login
            }
            points_season
            redeemed_points_season
            redeemed_points
            match {
                ... on Streamer {
                    _id
                }
                ... on Organisation {
                    _id
                }
            }
        }
        getMyViewer {
            _id
            muted_streamers
            login
            subscription {
                valid_until
                tier
                subscription_id
                status
            }
        }
    }
`;

export const getLoggedInEventHeaderWithNoOffer = gql`
    query getLoggedInEventHeaderWithNoOffer($streamer: ID, $id: ID!) {
        viewerRedeemedPoints(streamer: $streamer) {
            _id
            viewer {
                login
            }
            points_season
            redeemed_points_season
            redeemed_points
            match {
                ... on Streamer {
                    _id
                }
                ... on Organisation {
                    _id
                }
            }
        }
        getCollectedCardsAmountOfAStreamer(id: $id) {
            amountOfCardsCollected
        }
        getTypeCards(streamer: $streamer) {
            _id
            count
            matchCardModel
            match_card {
                ... on Card {
                    _id
                    level
                    image
                    matchModel
                    created_at
                    is_archived
                    matchId
                }
                ... on SpecialCard {
                    _id
                    card_name
                    image
                    costs
                    limit
                    max_count_per_viewer
                    created_at
                    collectible_from
                    collectible_until
                    matchModel
                    edition
                    matchId
                    sorting
                }
            }
        }
    }
`;

export const getLoggedInEventHeader = gql`
    query getLoggedInEventHeader($streamer: ID, $id: ID!, $offerId: ID!, $raffle_name: String!) {
        viewerRedeemedPoints(streamer: $streamer) {
            _id
            viewer {
                login
            }
            points_season
            redeemed_points_season
            redeemed_points
            match {
                ... on Streamer {
                    _id
                }
                ... on Organisation {
                    _id
                }
            }
        }
        getRaffleWithMyTickets(raffle_name: $raffle_name) { 
            tickets_cards
            tickets_redeemed_cards
            tickets_crafted_cards
        }
        getCollectedCardsAmountOfAStreamer(id: $id) {
            amountOfCardsCollected
        }
        isOrderPossible(offer: $offerId) {
            possible
            owned_booster_ids
            message
        }
        getOffer(id: $offerId) {
            _id
            title
            sub_title
            description
            possible_text
            order_button_text
            edition_to_show
            required_booster
            required_cards {
                ... on SpecialCard {
                    _id
                    image
                }
                ... on Card {
                    _id
                    image
                }
            }
            products {
                costs
            }
        }
        getTypeCards(streamer: $streamer) {
            _id
            count
            matchCardModel
            match_card {
                ... on Card {
                    _id
                    level
                    image
                    matchModel
                    created_at
                    is_archived
                    matchId
                }
                ... on SpecialCard {
                    _id
                    card_name
                    image
                    costs
                    limit
                    max_count_per_viewer
                    created_at
                    collectible_from
                    collectible_until
                    matchModel
                    edition
                    matchId
                    sorting
                }
            }
        }
    }
`;

export const getLoggedInOrganisationEventHeader = gql`
    query getLoggedInOrganisationEventHeader($organisation: ID, $offerId: ID!) {
        viewerRedeemedPoints(organisation: $organisation) {
            _id
            viewer {
                login
            }
            points_season
            redeemed_points_season
            redeemed_points
            match {
                ... on Streamer {
                    _id
                }
                ... on Organisation {
                    _id
                }
            }
        }
        getMyViewer {
            _id
            login
            subscription {
                valid_until
                tier
                subscription_id
                status
            }
        }
        isOrderPossible(offer: $offerId) {
            possible
            owned_card_ids
        }
        getOffer(id: $offerId) {
            _id
            title
            sub_title
            description
            possible_text
            order_button_text
            edition_to_show
            required_cards {
                ... on SpecialCard {
                    _id
                    image
                }
                ... on Card {
                    _id
                    image
                }
            }
            products {
                costs
            }
        }
        getTypeCards(organisation: $organisation) {
            _id
            count
            matchCardModel
            match_card {
                ... on Card {
                    _id
                    level
                    image
                    matchModel
                    created_at
                    is_archived
                    matchId
                }
                ... on SpecialCard {
                    _id
                    card_name
                    image
                    costs
                    limit
                    max_count_per_viewer
                    created_at
                    collectible_from
                    collectible_until
                    matchModel
                    edition
                    matchId
                    sorting
                }
            }
        }
    }
`;

export const getOfferWithData = gql`
    query getOfferWithData($offerId: ID!) {
        isOrderPossible(offer: $offerId) {
            possible
            owned_card_ids
        }
        getOffer(id: $offerId) {
            _id
            title
            sub_title
            description
            possible_text
            order_button_text
            edition_to_show
            required_cards {
                ... on SpecialCard {
                    _id
                    image
                }
                ... on Card {
                    _id
                    image
                }
            }
            products {
                costs
            }
        }
    }
`;

export const getLoggedInMobileEventHeader = gql`
    query getLoggedInMobileEventHeader($streamer: ID, $offerId: ID!) {
        viewerRedeemedPoints(streamer: $streamer) {
            _id
            viewer {
                login
            }
            points_season
            redeemed_points_season
            redeemed_points
            match {
                ... on Streamer {
                    _id
                }
                ... on Organisation {
                    _id
                }
            }
        }
        isOrderPossible(offer: $offerId) {
            possible
            owned_card_ids
        }
        getOffer(id: $offerId) {
            _id
            title
            sub_title
            description
            possible_text
            order_button_text
            edition_to_show
            required_cards {
                ... on SpecialCard {
                    _id
                    image
                }
                ... on Card {
                    _id
                    image
                }
            }
            products {
                costs
            }
        }
        getTypeCards(streamer: $streamer) {
            _id
            count
            matchCardModel
            match_card {
                ... on Card {
                    _id
                    level
                    image
                    matchModel
                    created_at
                    is_archived
                    matchId
                }
                ... on SpecialCard {
                    _id
                    card_name
                    image
                    costs
                    limit
                    max_count_per_viewer
                    created_at
                    collectible_from
                    collectible_until
                    matchModel
                    edition
                    matchId
                }
            }
        }
    }
`;

export const getStreamerLeaderboards = gql`
    query getStreamerLeaderboards($streamer: ID!, $pagination: Pagination) {
        allTime: getStreamerLeaderboard(streamer: $streamer, pagination: $pagination) {
            entries {
                seq
                pos
                updated_at
                viewer {
                    _id
                    login
                    profile_image_url
                    display_name
                    country
                    is_anonymous
                    subscription_tier
                    premium_frame
                }
            }
            count
        }
        monthly: getStreamerLeaderboard(streamer: $streamer, pagination: $pagination, timeframe: "monthly") {
            entries {
                seq
                pos
                updated_at
                viewer {
                    _id
                    login
                    profile_image_url
                    display_name
                    country
                    is_anonymous
                    subscription_tier
                    premium_frame
                }
            }
            count
        }
        weekly: getStreamerLeaderboard(streamer: $streamer, pagination: $pagination, timeframe: "weekly") {
            entries {
                seq
                pos
                updated_at
                viewer {
                    _id
                    login
                    profile_image_url
                    display_name
                    country
                    is_anonymous
                    subscription_tier
                    premium_frame
                }
            }
            count
        }
    }
`;

export const getGameLeaderboards = gql`
    query getGameLeaderboards($game: ID!, $pagination: Pagination, $filter: Filter, $groupBy: String!) {
        allTime: getGameLeaderboard(game: $game, pagination: $pagination) {
            entries {
                seq
                pos
                updated_at
                viewer {
                    _id
                    login
                    profile_image_url
                    display_name
                    country
                    subscription_tier
                    premium_frame
                }
            }
            count
        }
        timeBased: gameViewerLeaderBoard(game: $game, groupBy: $groupBy, pagination: $pagination, filter: $filter) {
            seq
            game {
                name
            }
            viewer {
                _id
                display_name
                login
                profile_image_url
                country
                subscription_tier
                premium_frame
            }
        }
    }
`;

export const getOrganisationLeaderboards = gql`
    query getOrganisationLeaderboards($organisation: ID!, $pagination: Pagination, $filter: Filter, $groupBy: String!) {
        allTime: getOrganisationLeaderboard(organisation: $organisation, pagination: $pagination) {
            entries {
                seq
                pos
                updated_at
                viewer {
                    _id
                    login
                    profile_image_url
                    display_name
                    country
                    subscription_tier
                    premium_frame
                }
            }
            count
        }
        timeBased: organisationViewerLeaderBoard(organisation: $organisation, groupBy: $groupBy, pagination: $pagination, filter: $filter) {
            seq
            organisation {
                name
            }
            viewer {
                _id
                display_name
                login
                profile_image_url
                country
                subscription_tier
                premium_frame
            }
        }
    }
`;

export const getMyViewerAndStreamer = gql`
    query getMyViewerAndStreamer {
        getMyViewer {
            _id
            login
            display_name
            profile_image_url
            email
            facebook
            youtube
            language
            twitter
            instagram
            created_at
            twitch_id
            no_digest_mail
            country
            streamheroes_role
            card_design
            show_onboarding
            muted_streamers
            streamer_dashboard
            booster_animation
            subscription {
                valid_until
                tier
                subscription_id
                status
            }
        }
        getMyStreamer {
            _id
            login
            display_name
            streamheroes_type
            twitch_id
            stream_config {
                sound
                animation
                min_level_alerts
                min_level_bot
                shot_msg
                custom_msg
                custom_msg_twitch
                shotOutsActive
                should_whisper
                volume
                max_events
                event_types
                styles {
                    background_color
                    color
                    font_size
                    font_family
                    animation
                }
            }
        }
    }
`;

export const getOrganisationAndMembersWithLiveStream = gql`
    query getOrganisationAndMembersWithLiveStream($code: String!) {
        organisation(code: $code) {
            _id
            name
            code
            logo_url
            website_url
            main_channel
            twitter
            youtube
            instagram
            facebook
            status
            created_at
            follower_count
            edition_to_show
        }
        getOrganisationMembersWithLiveStream(code: $code) {
            _id
            viewer_count
            started_at
            streamer {
                _id
                login
                display_name
                profile_image_url
                view_count
            }
        }
    }
`;

export const getOrganisationHeader = gql`
    query getOrganisationHeader($id: ID!) {
        getCollectedCardsAmountOfAnOrganisation(id: $id) {
            amountOfCardsCollected
        }
    }
`;

export const getMyOfferPossibility = gql`
    query getMyOfferPossibility($id: ID!) {
        getMyViewer {
            _id
            email
        }
        isOrderPossible(offer: $id) {
          possible
          message
          offer_amount_available
        }
        getOffer(id: $id) {
            _id
            title
            invoice_identifier
            promo_title
            promo_sub_title
            promo_image
            info
            shipping_info
            limit_per_user
            products {
                name
                desc_first_line
                desc_second_line
                costs
                unlimited
                image
            }
            country_costs {
                name
                code
                shipping
                vat_percent
            }
        }
    }
`;

export const getMyStreamerOnBoarding = gql`
    query getMyStreamerOnBoarding {
        getMyViewer {
            _id
            login
            display_name
            facebook
            youtube
            language
            twitter
            instagram
            created_at
            twitch_id
            country
            card_design
            streamheroes_role
        }
        getMyStreamer {
            _id
            login
            display_name
            streamheroes_type
            twitch_id
            stream_config {
                sound
                animation
                min_level_alerts
                min_level_bot
                custom_msg
                custom_msg_twitch
                should_whisper
                volume
                max_events
                event_types
                styles {
                    background_color
                    color
                    font_size
                    font_family
                    animation
                }
            }
        }
        checkRequirementsToBecomeAStreamhero {
            _id
            login
            display_name
            broadcaster_type
        }
        getMyStreamerCards {
            _id
            level
            image
            migrationImage
            matchModel
            created_at
            isApproved
            isRejected
            isDraft
            version
            match {
                ... on Streamer {
                    _id
                    display_name
                    login
                }
            }
        }
    }
`;

export const getLiveStreamsByGameNameWithCount = gql`
    query getLiveStreamsByGameNameWithCount($game: String!, $sorting: Sorting, $pagination: Pagination) {
        liveStreamsByGameName(game: $game, sorting: $sorting, pagination: $pagination) {
            _id
            title
            language
            thumbnail_url
            viewer_count
            has_cards
            streamer {
                _id
                login
                display_name
                profile_image_url
                broadcaster_type
            }
            game {
                _id
                name
                box_art_url
            }
            cards {
                card_layer_image
                rarity
            }
        }
        gameWithLiveStreamsCount(name: $game) {
            _id
            name
            box_art_url
            count_streams
        }
    }
`;

export const getGamesAndLiveStreams = gql`
    query getGamesAndLiveStreams($pagination: Pagination, $streamfilter: StreamFilter) {
        countOfGamesWithLiveStreams
        gamesWithLiveStreamsCount(pagination: $pagination, streamfilter: $streamfilter) {
            _id
            count_streams
            game {
                name
                box_art_url
            }
        }
    }
`;

export const getProfileHeaderData = gql`
    query getProfileHeaderData($viewer: ID, $login: String!, $season: [Int!]) {
        getMyViewer {
            _id
            login
            muted_streamers
            season_border {
                _id
                border_url
                profile_image_url
            }
            subscription {
                valid_until
                tier
                subscription_id
                status
            }
        }
        getBadgeLevels(viewer: $viewer) {
            overall_lvl_value
            overall_lvl_badge_lvl
            next_level_required_points
            current_level_required_points
        }
        liveStreamByStreamer(login: $login) {
            _id
            started_at
        }
        getAllViewerShowcaseBadges(viewer: $viewer) {
            _id
            badge_template_id {
                _id
                category
                name
                translation_string
                image_url
                active
                title
                description
            }
            showcase_position
            metadata {
                streamer {
                    _id
                    display_name
                }
                season {
                    season
                    league
                }
            }
        }
        getAllViewerBadges(viewer: $viewer) {
            _id
            badge_template_id {
                _id
                category
                title
                description
                translation_string
                image_url
                active
                group
            }
            showcase_position
        }
        getAllViewerSeasonBadges(viewer: $viewer, season: $season) {
            _id
            badge_template_id {
                _id
                category
                name
                translation_string
                image_url
                active
            }
            showcase_position
            metadata {
                streamer {
                    _id
                    display_name
                }
                season {
                    season
                    league
                }
            }
        }
        badgeTemplates {
            _id
            category
            name
            translation_string
            image_url
            group
            title
            description
        }
    }
`;

export const getMyViewerStreamerAndBadges = gql`
    query getMyViewerStreamerAndBadges {
        getMyViewer {
            _id
            login
            display_name
            profile_image_url
            language
            created_at
            twitch_id
            no_digest_mail
            country
            streamheroes_role
            card_design
            show_onboarding
            muted_streamers
            streamer_dashboard
            set_badges
            created_at
            facebook
            twitter
            youtube
            instagram
            premium_banner
            premium_frame
            subscription_tier
            cards_owned {
                total
            }
            subscription {
                valid_until
                tier
                subscription_id
                status
            }
        }
        getMyStreamer {
            _id
            login
            display_name
            streamheroes_type
            twitch_id
            profile_image_url
            created_at
            set_badges
        }
        getBadgeLevels {
            overall_lvl_value
            overall_lvl_badge_lvl
            next_level_required_points
            current_level_required_points
        }
        getAllBadges {
            viewer {
                category
                badges {
                    unique_id
                    title
                    pic_url
                    description
                    is_set
                }
            }
            streamer {
                category
                badges {
                    unique_id
                    title
                    pic_url
                    description
                    is_set
                }
            }
        }
    }
`;

export const getCommunityLeaderboard = gql`
    query getCommunityLeaderboard ($streamer: ID!, $league: String, $pagination: Pagination) {
        getCommunityStats(streamer: $streamer) {
            redeemed_booster
            gathered_energy
            unique_collectors
            position
        }
        getSeasonCommunityLeaderboardCount(streamer: $streamer, league: $league)
        getSeasonCommunityLeaderboard(streamer: $streamer, league: $league, pagination: $pagination) {
            viewer {
                login
                display_name
                profile_image_url
                season_border {
                    border_url
                    profile_image_url
                }
                subscription {
                    valid_until
                    tier
                    subscription_id
                    status
                }
            }
            redeemed_booster
            gathered_energy
            position
            points
        }
        getCurrentSeason {
            name
            season
            description
            bg_image
            logo
            available_from
            available_until
            sumBooster
            sumEnergy
        }
    }
`;

export const getGlobalLeaderboard = gql`
  query getGlobalLeaderboard ($pagination: Pagination, $season: Int) {
      getSeasonStreamerLeaderboard(pagination: $pagination, season: $season) {
          streamer {
              _id
              login
              display_name
              profile_image_url
          }
          redeemed_booster
          gathered_energy
          unique_collectors
          position
          points
      }
      getSeasonStreamerLeaderboardCount(season: $season)
      getCurrentSeason(season: $season) {
          name
          season
          description
          leaderboard_bg_image
          logo
          available_from
          available_until
          sumBooster
          sumEnergy
      }
      getMyViewer {
          _id
          subscription {
              valid_until
              tier
              subscription_id
              status
          }
      }
  }
`;

export const getGlobalViewerLeaderboard = gql`
  query getGlobalViewerLeaderboard ($pagination: Pagination, $season: Int) {
      getSeasonViewerLeaderboard(pagination: $pagination, season: $season) {
          viewer {
              _id
              login
              display_name
              profile_image_url
              season_border {
                  border_url
                  profile_image_url
              }
              subscription {
                  valid_until
                  tier
                  subscription_id
                  status
              }
          }
          redeemed_booster
          gathered_energy
          position
          points
      }
      getSeasonViewerLeaderboardCount(season: $season)
      getCurrentSeason(season: $season) {
          name
          season
          description
          leaderboard_bg_image
          logo
          available_from
          available_until
          sumBooster
          sumEnergy
      }
      getMyViewer {
          _id
          subscription {
              valid_until
              tier
              subscription_id
              status
          }
      }
  }
`;

export const getHubDashboard = gql`
    query getHubDashboard($pagination: Pagination) {
        getMyViewer {
            _id
            login
            display_name
            profile_image_url
            language
            created_at
            twitch_id
            no_digest_mail
            country
            streamheroes_role
            card_design
            show_onboarding
            created_at
            max_coins_size
            premium_banner
            premium_frame
            subscription_tier
            subscription {
                valid_until
                tier
                subscription_id
                status
            }
        }
        getCurrentSeason {
            name
            season
            description
            bg_image
            available_from
            available_until
        }
        getCollectCoinReward {
            last_collected_reward
            amount_to_collect
        }
        getSeasonStreamerLeaderboard (pagination: $pagination) {
            position
            streamer {
                _id
                login
                profile_image_url
                display_name
            }
        }
        getQuestProgress {
            daily
            daily_reward_collected
            daily_title
            daily_text
            daily_game {
                name
            }
            daily_streamer {
                login
            }
            weekly
            weekly_title
            weekly_text
            weekly_reward_collected
            weekly_game {
                name
            }
            weekly_streamer {
                login
            }
        }
        getLatestBooster (pagination: $pagination) {
            _id
            front_image
            match_model
            edition
            energy_costs
            coin_costs
            user_energy
            user_coins
            created_at
            is_starter_pack
            starter_pack_logo_url
            is_custom_booster
            match_model
            match_meta_data {
                ... on Streamer {
                    _id
                    login
                    display_name
                    profile_image_url
                }
                ... on Game {
                    _id
                    name
                    box_art_url
                }
                ... on Organisation {
                    _id
                    name
                    code
                    logo_url
                }
                ... on GenericMatch {
                    _id
                    name
                    image_url
                }
            }
            generic_cards {
                _id
                card_layer_image
            }
        }
        getTodayCollectedStreamer {
            _id
            seq
            energy
            streamer {
                _id
                 login
                 display_name
                 profile_image_url
            }
            updated_at
        }
        getFavoriteStreamer(pagination: $pagination) {
            amount
            watchtimeinminutes
            streamer {
                _id
                login
                display_name
                profile_image_url
            }
        }
    }
`;

export const getMyViewerStreamer = gql`
    query getMyViewerStreamer {
        getMyViewer {
            _id
            streamer_dashboard
            streamheroes_role
            max_coins_size
            sh_coins
        }
        getMyStreamer {
            _id
        }
    }
`;

export const getViewerDashboard = gql`
    query getViewerDashboard($total: Boolean) {
        getHeaderKPIs(total: $total) {
            total_coins
            amount_cards
            current_level
            leaderboard_rank
        }
        getSHCoinsTankInformation {
            level
        }
    }
`;

export const getCollection = gql`
    query getCollection($streamer: ID, $organisation: ID, $game: ID, $sorting: Sorting, $pagination: Pagination, $filter: Filter, $unique: Boolean) {
        getMyOwnedCardsCount(unique: $unique, streamer: $streamer, organisation: $organisation, game: $game, filter: $filter)
        getMyOwnedCards(streamer: $streamer, organisation: $organisation, game: $game, sorting: $sorting, pagination: $pagination, filter: $filter) {
            _id
            count
            matchCardModel
            match_card {
                ... on Card {
                    _id
                    level
                    image
                    matchModel
                    created_at
                    matchId
                }
                ... on SpecialCard {
                    _id
                    card_name
                    image
                    costs
                    limit
                    max_count_per_viewer
                    created_at
                    collectible_from
                    collectible_until
                    matchModel
                    matchId
                }
            }
        }
        getMyRedeemableCards {
            ... on Card {
                _id
                level
                image
                matchModel
                created_at
                owned
                matchId
                energy_for_redeem
            }
            ... on SpecialCard {
                _id
                image
                collectible_from
                collectible_until
                created_at
                matchModel
                matchId
                costs
                energy_for_redeem
            }
        }
        getMyCraftableCards {
            ... on Card {
                _id
                level
                image
                matchModel
                created_at
                matchId
                cards_for_craft
            }
        }
        getMyRedeemedCardTypeCounts {
            streamer
            category
            organisation
            total
            unique
            sh
        }
        getMyOwnedCardsOrganisations {
            _id
            name
        }
        getMyOwnedCardsStreamers {
            _id
            login
            display_name
        }
        getMyOwnedCardsGames {
            _id
            name
        }
        getMyOwnedCardsLevels 
    }
`;

export const getLegacyCards = gql`
    query getLegacyCards($streamer: ID, $organisation: ID, $game: ID, $sorting: Sorting, $pagination: Pagination, $filter: Filter, $unique: Boolean) {
        getMyOwnedCardsCount(unique: $unique, streamer: $streamer, organisation: $organisation, game: $game, filter: $filter)
        getMyOwnedCards(streamer: $streamer, organisation: $organisation, game: $game, sorting: $sorting, pagination: $pagination, filter: $filter) {
            _id
            count
            matchCardModel
            match_card {
                ... on Card {
                    _id
                    level
                    image
                    matchModel
                    created_at
                    matchId
                    shop_price
                    match {
                        ... on Streamer {
                            _id
                            login
                            display_name
                            profile_image_url
                        }
                        ... on Game {
                            _id
                            name
                            box_art_url
                        }
                        ... on Organisation {
                            _id
                            name
                            logo_url
                        }
                    }
                }
                ... on SpecialCard {
                    _id
                    card_name
                    image
                    costs
                    limit
                    max_count_per_viewer
                    created_at
                    collectible_from
                    collectible_until
                    matchModel
                    matchId
                    shop_price
                    match {
                        ... on Streamer {
                            _id
                            login
                            display_name
                            profile_image_url
                        }
                        ... on Game {
                            _id
                            name
                            box_art_url
                        }
                        ... on Organisation {
                            _id
                            name
                            logo_url
                        }
                    }
                }
            }
        }
        getMyOwnedCardsOrganisations {
            _id
            name
        }
        getMyOwnedCardsStreamers {
            _id
            login
            display_name
        }
        getMyOwnedCardsGames {
            _id
            name
        }
    }
`;

export const getTypeAndTimeBasedCoins = gql`
    query getTypeAndTimeBasedCoins($startDate: String!, $pagination: Pagination) {
        streamerCoins: showAllRedeemablePoints(pagination: $pagination, type: "Streamer") {
            count
            points {
                _id
                points_season
                redeemed_points
                matchModel
                match {
                    ... on Streamer {
                        _id
                        login
                        display_name
                        profile_image_url
                    }
                    ... on Game {
                        _id
                        name
                        box_art_url
                    }
                    ... on Organisation {
                        _id
                        name
                        code
                        logo_url
                    }
                }
            }
        }
        gameCoins: showAllRedeemablePoints(pagination: $pagination, type: "Game") {
            count
            points {
                _id
                points_season
                redeemed_points
                matchModel
                match {
                    ... on Streamer {
                        _id
                        login
                        display_name
                        profile_image_url
                    }
                    ... on Game {
                        _id
                        name
                        box_art_url
                    }
                    ... on Organisation {
                        _id
                        name
                        code
                        logo_url
                    }
                }
            }
        }
        organisationCoins: showAllRedeemablePoints(pagination: $pagination, type: "Organisation") {
            count
            points {
                _id
                points_season
                redeemed_points
                matchModel
                match {
                    ... on Streamer {
                        _id
                        login
                        display_name
                        profile_image_url
                    }
                    ... on Game {
                        _id
                        name
                        box_art_url
                    }
                    ... on Organisation {
                        _id
                        name
                        code
                        logo_url
                    }
                }
            }
        }
        timeBasedStreamViewers(startDate: $startDate) {
            _id
            seq
            date
            streamer {
                _id
                login
                profile_image_url
                display_name
            }
        }
        getMyViewer {
            _id
            sh_coins
            max_coins_size
            subscription {
                valid_until
                tier
                status
            }
        }
        getSHCoinsTankInformation {
            level
            size
            upgrade_costs
            next_level_size
        }
    }
`;

export const getOnlyTypeCoins = gql`
    query getOnlyTypeCoins($pagination: Pagination) {
        streamerCoins: showAllRedeemablePoints(pagination: $pagination, type: "Streamer") {
            count
            points {
                _id
                points_season
                redeemed_points
                matchModel
                match {
                    ... on Streamer {
                        _id
                        login
                        display_name
                        profile_image_url
                    }
                    ... on Game {
                        _id
                        name
                        box_art_url
                    }
                    ... on Organisation {
                        _id
                        name
                        code
                        logo_url
                    }
                }
            }
        }
        gameCoins: showAllRedeemablePoints(pagination: $pagination, type: "Game") {
            count
            points {
                _id
                points_season
                redeemed_points
                matchModel
                match {
                    ... on Streamer {
                        _id
                        login
                        display_name
                        profile_image_url
                    }
                    ... on Game {
                        _id
                        name
                        box_art_url
                    }
                    ... on Organisation {
                        _id
                        name
                        code
                        logo_url
                    }
                }
            }
        }
        organisationCoins: showAllRedeemablePoints(pagination: $pagination, type: "Organisation") {
            count
            points {
                _id
                points_season
                redeemed_points
                matchModel
                match {
                    ... on Streamer {
                        _id
                        login
                        display_name
                        profile_image_url
                    }
                    ... on Game {
                        _id
                        name
                        box_art_url
                    }
                    ... on Organisation {
                        _id
                        name
                        code
                        logo_url
                    }
                }
            }
        }
        getMyViewer {
            _id
            sh_coins
            max_coins_size
            subscription {
                valid_until
                tier
                subscription_id
                status
            }
        }
        getSHCoinsTankInformation {
            level
            size
            upgrade_costs
            next_level_size
        }
    }
`;

export const getMyStreamerCardAndViewer = gql`
    query getMyStreamerCardAndViewer {
        getMyStreamerCards {
            _id
            level
            image
            migrationImage
            dpi
            migrationDpi
            matchModel
            created_at
            isApproved
            isRejected
            isDraft
            version
            is_printable
        }
        getCardEvents {
            _id
            name
            cards {
                _id
                image
            }
            started_at
            finished_at
            status
        }
        getMyStreamer {
            _id
            booster_design
        }
        getMyViewer {
            _id
            login
            display_name
            profile_image_url
            created_at
            twitch_id
            card_design
            card_set_design {
                isActive
                cards {
                    set
                    setLevel
                    cardLevel
                }
            }
        }
    }
`;

export const getAllInformationAboutCard = gql`
    query getAllInformationAboutCard($id: ID!, $viewer: ID) {
        card(id: $id) {
            ... on Card {
                _id
                level
                image
                matchModel
                created_at
                isApproved
                is_printable
                is_archived
                popularity
                rarity
                match {
                    ... on Streamer {
                        _id
                        display_name
                        login
                    }
                    ... on Game {
                        _id
                        name
                    }
                    ... on Organisation {
                        _id
                        name
                        code
                    }
                }
            }
            ... on SpecialCard {
                _id
                image
                matchModel
                created_at
                popularity
                isApproved
                is_printable
                rarity
                rating
                match {
                    ... on Streamer {
                        _id
                        display_name
                        login
                    }
                    ... on Game {
                        _id
                        name
                    }
                    ... on Organisation {
                        _id
                        name
                        code
                    }
                }
            }
        }
        getInformationAboutCard(id: $id) {
            firstCollectors {
                _id
                created_at
                viewer {
                    _id
                    display_name
                    login
                    profile_image_url
                    country
                }
            }
            totalCollected
            totalCollectors
            myFirstCard {
                _id
                crafted_as_number
                events {
                    type
                    key
                    params
                }
                created_at
            }
        }
        getFirstRedeemedCardOfViewer(card: $id, viewer: $viewer) {
            _id
            created_at
            viewer {
                _id
                display_name
                login
                profile_image_url
                country
            }
        }
        getMyViewer {
            _id
            streamheroes_role
        }
    }
`;

export const getMyPrintableCardsWithViewer = gql`
    query getMyPrintableCardsWithViewer($streamer: ID, $organisation: ID, $sorting: Sorting, $pagination: Pagination, $filter: Filter) {
        getMyPrintableCards(streamer: $streamer, organisation: $organisation, sorting: $sorting, pagination: $pagination, filter: $filter) {
            cards_count
            streamer_organisation {
                 main_channel
                 logo_url
            }
            streamers {
                _id
                display_name
            }
            organisations {
                _id
                name
            }
            levels
            cards {
                _id
                count
                matchCardModel
                match_card {
                    ... on Card {
                        _id
                        level
                        image
                        is_printable
                        matchModel
                        created_at
                        match {
                            ... on Streamer {
                                _id
                                display_name
                                login
                            }
                            ... on Game {
                                _id
                                name
                            }
                            ... on Organisation {
                                _id
                                name
                                code
                            }
                        }
                    }
                    ... on SpecialCard {
                        _id
                        card_name
                        image
                        costs
                        limit
                        max_count_per_viewer
                        created_at
                        collectible_from
                        collectible_until
                        matchModel
                        match {
                            ... on Streamer {
                                _id
                                display_name
                                login
                            }
                            ... on Game {
                                _id
                                name
                            }
                            ... on Organisation {
                                _id
                                name
                                code
                            }
                        }
                    }
                }
            }
        }
        getMyViewer {
            _id
            display_name
            email
            country
        }
    }
`;

export const getLiveStreamsOfStreamersWithCardsAndCount = gql`
    query getLiveStreamsOfStreamersWithCardsAndCount($pagination: Pagination, $withCardObjects: Boolean) {
        getLiveStreamsOfStreamersWithCardsCount
        getLiveStreamsOfStreamersWithCards(pagination: $pagination, withCardObjects: $withCardObjects) {
            _id
            title
            language
            thumbnail_url
            viewer_count
            has_cards
            streamer {
                _id
                login
                display_name
                profile_image_url
            }
            game {
                _id
                name
                box_art_url
            }
            boosters {
                front_image
                edition
                is_custom_booster
                
                generic_cards {
                    _id
                    card_layer_image
                    count_owned
                    rarity
                }
            }
        }
    }
`;

export const getSwipeData = gql`
  query getSwipeData {
      generateSwipeCard {
          _id
          likes
          dislikes
          matchModel
          match {
              ... on Streamer {
                  _id
                  display_name
                  login
                  profile_image_url
              }
          }
          image
          level
          count_created
          created_at
      }
      getSwipeInformation {
          daily_swipes
          favourites_count
          likes_count
      }
  }
`;

export const getAllAndPossibleBadges = gql`
    query getAllAndPossibleBadges($isStreamer: Boolean) {
        getPossibleUserBadges(isStreamer: $isStreamer) {
            category
            progress {
                current
                maximum
            }
            badges {
                unique_id
                title
                pic_url
                description
                is_set
            }
        }
        getAllBadges {
            viewer {
                category
                badges {
                    unique_id
                    title
                    pic_url
                    description
                    is_set
                }
            }
            streamer {
                category
                badges {
                    unique_id
                    title
                    pic_url
                    description
                    is_set
                }
            }
        }
    }
`;

export const getViewerRankingsWithCountAndLeaders = gql`
    query getViewerRankingsWithCountAndLeaders($sorting: Sorting, $pagination: Pagination, $filter: Filter) {
        getViewerRankings(sorting: $sorting, pagination: $pagination, filter: $filter) {
            _id
            total_points
            card_count
            overall_level
            last_ranking_position
            viewer {
                _id
                display_name
                login
                profile_image_url
                country
                subscription_tier
                premium_frame
                subscription {
                    tier
                }
            }
        }
        getViewerRankingsCount(filter: $filter)
        getLastWeeksLeaders {
            identifier
            leaders {
                viewer {
                    _id
                    login
                    display_name
                    profile_image_url
                    country
                    subscription_tier
                    premium_frame
                    subscription {
                        tier
                    }
                }
                seq
                position
            }
        }
        getMyViewerRankingWithPosition(filter: $filter) {
            total_points
            position
            card_count
            viewer {
                _id
                login
                display_name
                profile_image_url
                country
                subscription_tier
                subscription {
                    tier
                }
            }
            overall_level
            last_ranking_position
        }
    }
`;

export const getViewerRankingsWithCount = gql`
    query getViewerRankingsWithCount($sorting: Sorting, $pagination: Pagination, $filter: Filter) {
        getViewerRankings(sorting: $sorting, pagination: $pagination, filter: $filter) {
            _id
            total_points
            card_count
            overall_level
            last_ranking_position
            viewer {
                _id
                display_name
                login
                profile_image_url
                country
                subscription_tier
            }
        }
        getViewerRankingsCount(filter: $filter)
        getMyViewerRankingWithPosition(filter: $filter) {
            total_points
            position
            card_count
            viewer {
                _id
                country
                subscription_tier
            }
            overall_level
            last_ranking_position
        }
    }
`;

export const getTasksAndNextPoll = gql`
    query getTasksAndNextPoll($streamer_only: Boolean) {
        getNextAvailablePoll {
            count
            poll {
                _id
                title
                answers
                finished_at
                type
                target
            }
        }
        getTasks(streamer_only: $streamer_only) {
            redeemed {
                internal_id
                type
                title
                text
                progress
                action_url
            }
            not_redeemed {
                internal_id
                type
                title
                text
                progress
                action_url
            }
        }
    }
`;

export const getSHCardsAndLevels = gql`
    query getSHCardsAndLevels($event_name: String!) {
        getSHCards(event_name: $event_name) {
            _id
            sh_coins_cost
            no_effect_cards {
                _id
                image
                owned
                level
            }
            effect_cards {
                _id
                image
                owned
                level
            }
            collectible_from
            collectible_until
            event_name
            additional_costs {
                matchModel
                match {
                    ... on Game {
                        name
                        box_art_url
                    }
                }
                amount_owned
                amount_needed
            }
        }
        getSHCollectionLevels(event_name: $event_name)
        getMyViewer {
            _id
            sh_coins
        }
    }
`;

export const getAllMyRedeemableCardsOfCard = gql`
    query getAllMyRedeemableCardsOfCard($cardId: ID!, $pagination: Pagination) {
        getAllMyRedeemableCardsOfCard(cardId: $cardId, pagination: $pagination) {
            _id
            crafted_as_number
            vendor_lock
            is_showcase
            user_lock
            created_at
        }
        getAllMyRedeemableCardsOfCardCount(cardId: $cardId)
        card(id: $cardId) {
            ... on Card {
                _id
                level
                image
                matchModel
                match {
                    ... on Streamer {
                        _id
                        display_name
                        login
                        profile_image_url
                    }
                    ... on Game {
                        _id
                        name
                        box_art_url
                    }
                    ... on Organisation {
                        _id
                        name
                        code
                        logo_url
                    }
                }
            }
            ... on SpecialCard {
                _id
                card_name
                image
                matchModel
                match {
                    ... on Streamer {
                        _id
                        display_name
                        login
                        profile_image_url
                    }
                    ... on Game {
                        _id
                        name
                        box_art_url
                    }
                    ... on Organisation {
                        _id
                        name
                        code
                        logo_url
                    }
                }
            }
        }
    }
`;

export const getIllustrationData = gql`
    query getIllustrationData($id: ID) {
        getIllustrationData(id: $id) {
            _id
            request_description
            request_primary_color_info
            request_secondary_color_info
            card_request_info {
                level
                request_description
            }
            finished_at
            file_uploads_user
            file_uploads_illustrator
            status
            created_at
            updated_at
            illustration_style
            messages {
                _id
                type
                content
                author 
                created_at
                images
            }
            participants {
                _id
                role
                login
                display_name
                avatar
            }
            state_history {
                name
                created_at
            }
        }
        getMyViewer {
            _id
            login
        }
    }
`;

export const getEventAlertInformation = gql`
    query {
        hasUserFullEnergyTanks
    }
`;

export const getAlertInformation = gql`
    query {
        getSwipeInformation {
            daily_swipes
        }
        hasUserFullEnergyTanks
        getMyViewer {
            _id
            stripe {
                account
                status
            }
        }
        getMyStreamer {
            _id
        }
    }
`;

export const getAlertWithOrderInformation = gql`
    query {
        getSwipeInformation {
            daily_swipes
        }
        hasUserFullEnergyTanks
        getMyViewer {
            _id
            stripe {
                account
                status
            }
        }
        getMyStreamer {
            _id
        }
    }
`;

export const getHeaderInformation = gql`
    query getHeaderInformation {
        hasUserActiveIllustrationJob
        hasUserUnreadIllustrationMessages
    }
`;

export const getStripeInformation = gql`
    query getStripeInformation ($pagination: Pagination) {
        getMyViewer {
            _id
            login
            stripe {
                account
                status
            }
        }
        getMyStreamer {
            _id
            streamheroes_type
        }
        getMyStreamerCards {
            _id
            level
            image
            created_at
            isApproved
            isRejected
            isDraft
            version
            count_created
        }
        getStripeTransferData(pagination: $pagination) {
            lifetime_balance
            transferable_balance
            lifetime_cards_sold
            orders_count
            sell_dates {
                month_year
                level_sells
                special_sells
            }
            card_orders {
                card_levels
                order_date
                streamer_share
            }
            digital_sales {
                month_day
                cards
            }
        }
    }
`;

export const checkOtherTypes = gql`
    query checkOtherTypes($name: String!) {
        organisation(code: $name) {
            _id
            code
        }
        game(name: $name) {
            _id
            name
        }
    }
`;

export const myCollectedPointsForTypeWithMyViewer = gql`
    query viewerRedeemedPoints($streamer: ID, $organisation: ID, $game: ID) {
        viewerRedeemedPoints(streamer: $streamer, organisation: $organisation, game: $game) {
            _id
            points_season
            redeemed_points_season
            redeemed_points
            match {
                ... on Streamer {
                    _id
                }
                ... on Organisation {
                    _id
                }
                ... on Game {
                    _id
                }
            }
        }
        getMyViewer {
            _id
            subscription {
                valid_until
                tier
                subscription_id
                status
                sidekick_link
            }
        }
    }
`;

export const getMyOrderableTypes = gql`
    query ($streamer: ID, $organisation: ID, $sorting: Sorting, $pagination: Pagination, $filter: Filter, $card_type: String) {
        getMyOrderableCards(streamer: $streamer, organisation: $organisation, sorting: $sorting, pagination: $pagination, filter: $filter, card_type: $card_type) {
            count
            cards {
                _id
                count
                card {
                    _id
                    image
                    level
                    shop_price
                    discount_percent
                    discounted_price
                    created_at
                    layers {
                        type
                    }
                    match {
                        ... on Streamer {
                            _id
                            display_name
                            profile_image_url
                        }
                        ... on Organisation {
                            _id
                            name
                            logo_url
                        }
                        ... on Game {
                            _id
                            name
                            box_art_url
                        }
                    }
                }
                specialcard {
                    _id
                    image
                    card_name
                    costs
                    shop_price
                    discount_percent
                    discounted_price
                    collectible_from
                    created_at
                    match {
                        ... on Streamer {
                            _id
                            display_name
                            profile_image_url
                        }
                        ... on Organisation {
                            _id
                            name
                            logo_url
                        }
                        ... on Game {
                            _id
                            name
                            box_art_url
                        }
                    }
                }
            }
            pack_ins {
                _id
                name
                t_string
                image
                info
                description
                unit_price
                discount_percent
                discounted_price
            }
        }
        getMyOrderableOffers {
            _id
            title
            collectible_from
            collectible_until
            product_image
            type
            url
            cards_collected
            cards_needed
            unit_price
            discount_percent
            discounted_price
            offer_amount_available
            message
            translation_template_code
        }
    }
`;

export const getMobileMenuData = gql`
    query getHeaderData {
        getMyViewer {
            _id
            login
            display_name
            profile_image_url
            streamheroes_role
            sh_coins
            premium_banner
            premium_frame
            max_coins_size
            premium_currency
            subscription_tier
            subscription {
                valid_until
                tier
                subscription_id
                status
                sidekick_link
            }
        }
        getRedeemableBoosterCount
    }
`;

export const getHeaderData = gql`
    query getHeaderData {
        getMyViewer {
            _id
            login
            display_name
            profile_image_url
            streamheroes_role
            sh_coins
            premium_banner
            premium_frame
            max_coins_size
            premium_currency
            subscription_tier
            subscription {
                valid_until
                tier
                subscription_id
                status
                sidekick_link
            }
        }
        getFollowedStreamer {     
            stream {
                _id
                streamer {
                    _id
                    profile_image_url
                    display_name
                    login
                }
            }
        }
        getMyStreamer {
            _id
        }
        getSHCoinsTankInformation {
            level
            size
        }
        getAlertWebSourceUrls {
            type
            url
        }
        getRedeemableBoosterCount
        getStreamerTanks {
            current_energy
            match {
                ...on Streamer {
                    login
                    display_name
                    profile_image_url
                }
            }
        }
        getCardDecks {
            _id
            name
            max_cards
            sort_order
            card_count
            active
        }
    }
`;

export const getMyViewerAndShippingInformation = gql`
    query getMyViewerAndShippingInformation {
        getMyViewer {
            _id
            email
            subscription_tier
            subscription {
                valid_until
                tier
                subscription_id
                status
                sidekick_link
            }
        }
        getShippingInformation {
            country
            code
            shipping
            vat_percent
        }
    }
`;

export const getRedeemableBoosters = gql`
  query getRedeemableBoosters($streamer: String, $game: String, $sorting: Sorting) {
      getRedeemableBoosters(streamer: $streamer, game: $game, sorting: $sorting) {
          boosters {
              _id
              front_image
              back_image
              match {
                  ... on Streamer {
                      _id
                      login
                      display_name
                      profile_image_url
                  }
                  ... on Game {
                      _id
                      name
                      box_art_url
                  }
                  ... on Organisation {
                      _id
                      name
                      code
                      logo_url
                  }
                  ... on GenericMatch {
                      _id
                      name
                      image_url
                  }
              }
              match_model
              redeemable_from
              redeemable_until
              unique_user_redeems
              overall_redeems
              edition
              sorting
              energy_costs
              user_energy
              coin_costs
              user_coins
              created_at
              is_custom_booster
              starter_pack_logo_url
              is_starter_pack
              generic_cards {
                  _id
                  card_layer_image
                  physical_card_layer_image
                  rarity
              }
          }
      }
      getMyViewer {
          _id
          max_coins_size
          subscription {
              valid_until
              tier
              subscription_id
              status
              sidekick_link
          }
      }
  }
`;

export const getTrendingBoosters = gql`
  query getTrendingBoosters {
      getTrendingBoosters {
          _id
          front_image
          back_image
          match {
              ... on Streamer {
                  _id
                  login
                  display_name
                  profile_image_url
              }
              ... on Game {
                  _id
                  name
                  box_art_url
              }
              ... on Organisation {
                  _id
                  name
                  code
                  logo_url
              }
              ... on GenericMatch {
                  _id
                  name
                  image_url
              }
          }
          match_model
          redeemable_from
          redeemable_until
          edition
          energy_costs
          user_energy
          coin_costs
          user_coins
          is_custom_booster
          starter_pack_logo_url
          is_starter_pack
          generic_cards {
              _id
              card_layer_image
              physical_card_layer_image
              rarity
          }
      }
      getMyViewer {
          _id
          max_coins_size
          subscription {
              valid_until
              tier
              subscription_id
              status
              sidekick_link
          }
      }
  }
`;

export const getTypeBoostersWithMyViewer = gql`
    query getTypeBoosters($match: ID!, $owned_cards: Boolean) {
        getTypeBoosters(match: $match, owned_cards: $owned_cards) {
            _id
            front_image
            back_image
            redeemable_from
            redeemable_until
            unique_user_redeems
            overall_redeems
            edition
            energy_costs
            user_energy
            coin_costs
            user_coins
            is_custom_booster
            starter_pack_logo_url
            is_starter_pack
            generic_cards {
                _id
                card_layer_image
                physical_card_layer_image
                rarity
                count_owned
            }
            match {
                ... on Streamer {
                    _id
                    login
                    display_name
                    profile_image_url
               }
                ... on Game {
                    _id
                    name
                    box_art_url
                }
                ... on Organisation {
                    _id
                    name
                    code
                    logo_url
                }
                ... on GenericMatch {
                    _id
                    name
                    image_url
                }
            }
        }
        getMyViewer {
            _id
            max_coins_size
            subscription {
                valid_until
                tier
                subscription_id
                status
                sidekick_link
            }
        }
    }
`;

export const getRedeemedCardObjects = gql`
    query getRedeemedCardObjects($match: ID, $min_quality: Int, $max_quality: Int, $rarity: [Int], $sorting: Sorting, $pagination: Pagination) {
        getRedeemedCardObjects(match: $match, min_quality: $min_quality, max_quality: $max_quality, rarity: $rarity, sorting: $sorting, pagination: $pagination) {
            count
            cards {
                _id
                rarity
                condition
                quality
                front_image_url
                back_image_url
                card_template
                front_layers {
                    img_url
                    physical_img_url
                    type
                }
                back_layers {
                    img_url
                    physical_img_url
                    type
                }
                redeemed_booster {
                    generic_booster_metadata {
                        edition
                    }
                }
                overall_collected
                unique_collectors
                created_at
                card_template_metadata {
                    match {
                        ... on Streamer {
                            _id
                            login
                            display_name
                            profile_image_url
                        }
                        ... on Game {
                            _id
                            name
                            box_art_url
                        }
                        ... on Organisation {
                            _id
                            name
                            logo_url
                        }
                        ... on GenericMatch {
                            _id
                            name
                            image_url
                        }
                    }
                }
            }
        }
        getMyViewer {
            _id
            subscription {
                valid_until
                tier
                subscription_id
                status
                sidekick_link
            }
        }
        getShopItemFilters {
            streamers {
                _id
                display_name
            }
            games {
                _id
                name
            }
            organisations {
                _id
                name
            }
            generic {
                _id
                name
            }
        }
        hasUserLegacyCards
        getCardDecks {
            _id
            name
            max_cards
            sort_order
            image
            card_count
            updated_at
            active
        }
    }
`;

export const getShopItems = gql`
    query getShopItems($match: ID, $min_quality: Int, $max_quality: Int, $rarity: [Int], $sorting: Sorting, $pagination: Pagination) {
        getShopItems(match: $match, min_quality: $min_quality, max_quality: $max_quality, rarity: $rarity, sorting: $sorting, pagination: $pagination) {
            count
            cards {
                _id
                price
                discounted_price
                discount_percent
                quality
                rarity
                condition
                front_layers {
                    img_url
                    physical_img_url
                    type
                }
                back_layers {
                    img_url
                    physical_img_url
                    type
                }
                card_template
                card_template_metadata {
                    match {
                        ... on Streamer {
                            _id
                            login
                            display_name
                            profile_image_url
                        }
                    }
                }
            }
            offers {
                _id
                title
                offer_amount_ordered
                offer_amount_available
                collectible_from
                collectible_until
                product_image
                type
                url
                unit_price
                discount_percent
                discounted_price
                message
                translation_template_code
                products {
                    name
                    desc_first_line
                    desc_second_line
                    costs
                    image
                }
            }
            pack_ins {
                _id
                name
                t_string
                image
                info
                description
                unit_price
                discount_percent
                discounted_price
            }
        }
        getShopItemFilters {
            streamers {
                _id
                display_name
            }
            games {
                _id
                name
            }
            organisations {
                _id
                name
            }
            generic {
                _id
                name
            }
        }
        hasUserLegacyCards
    }
`;

export const getViewerAndStreamer = gql`
    query viewer($login: String) {
        viewer(login: $login) {
            _id
            login
            display_name
            profile_image_url
            facebook
            youtube
            twitter
            instagram
            created_at
            set_badges
            premium_banner
            premium_frame
            subscription_tier
            subscription {
                valid_until
                tier
                subscription_id
                status
            }
            season_border {
                _id
                border_url
                profile_image_url
            }
        }
        streamer(login: $login) {
            _id
            login
            display_name
            profile_image_url
            streamheroes_type
            twitch_id
            created_at
            sh_viewer_since
        }
    }
`;

export const getOverViewData = gql`
    query getOverViewData($viewer: ID) {
        getActivitiesPrivacySettings
        getMyViewer {
            _id
            login
        }
        getSeasonViewerLeaderboard(viewer: $viewer) {
            redeemed_booster
            position
            gathered_energy
        }
        getActivities(viewer: $viewer) {
            action_type
            action
            action_amount
            action_cost
            action_cost_type
            created_at
            match {
                ...on Streamer {
                    login
                    display_name
                    profile_image_url
                }
                ... on GenericMatch {
                    _id
                    name
                    image_url
                }
            }
        }
    }
`;

export const getMySeasonWithSeasonInfo = gql`
    query getMySeason ($viewer: ID) {
        getMySeason(viewer: $viewer) {
            streamer {
                _id
                login
                display_name
                profile_image_url
            }
            league
            position
            points
        }
        getCurrentSeason {
            season
            available_from
            available_until
        }
    }
`;

export const getAllBoosterWithShippingInformation = gql`
    query getAllBoosterWithShippingInformation {
        getAllBooster {
            approvedBooster {
                _id
                front_image
                edition
                energy_costs
                redeemable_from
                redeemable_until
                streamer_last_ordered
                generic_cards {
                    _id
                    card_layer_image
                    rarity
                }
            }
            unapprovedBooster {
                _id
                front_image
                edition
                energy_costs
                redeemable_from
                redeemable_until
                ready_to_approve
                generic_cards {
                    _id
                    card_layer_image
                    rarity
                }
            }
        }
        getShippingInformation {
            country
            code
            shipping
            vat_percent
        }
    }
`;

export const getCurrentOfferWithMyViewer = gql`
    query getCurrentOffer($match: ID, $matchModel: String) {
        getMyViewer {
            _id
            max_coins_size
            subscription {
                valid_until
            }
        }
        getCurrentOffer(match: $match, matchModel: $matchModel) {
            _id
            order_button_text
            invoice_identifier
            limit_overall
            started_at
            finished_at
            products {
                costs
            }
            required_booster {
                _id
                energy_costs
                coin_costs
                user_energy
                user_coins
                redeemable_from
                redeemable_until
                front_image
                starter_pack_logo_url
                edition
                is_starter_pack
                is_custom_booster
                generic_cards {
                    _id
                    card_layer_image
                    rarity
                    physical_card_layer_image
                    count_owned
                }
            }
        }
    }
`;

export const alertSettingsWithStreamer = gql`
  query alertSettingsWithStreamer {
      alertSettings {
          _id
          type
          active
          min_card_quality
          max_card_quality
          card_rarity
          purchase_min_amount_cards
          community_rank_up
          show_on_twitch
          show_on_discord
          problems_with_sending
          discord_settings {
              channel
              text
          }
          twitch_settings {
              text
              alert_sound {
                  sound
                  volume
              }
              permissions
              text_to_speech {
                  voice
                  font_size
              }
          }
      }
      getAlertWebSourceUrls {
          type
          url
      }
      getMyStreamer {
          _id
      }
      getDiscordSetupProgress {
          bot_invited
          viewer_auth
          streamer_auth
      }
  }
`;

export const getDiscordInfo = gql`
  query getDiscordInfo {
      getDiscordServer {
          claimed
          serverid
          roles {
              bronze
              silver
              gold
              platinum
              diamond
          }
      }
      getDiscordSetupProgress {
          bot_invited
          viewer_auth
          streamer_auth
      }
      getDiscordRoles {
          id
          name
          mentionable
      }
  }
`;

export const getUnopenedBoosters = gql`
    query getUnopenedBoosters($pagination: Pagination) {
        getUnopenedBoosterCount
        getUnopenedBoosters(pagination: $pagination) {
            _id
            generic_booster {
              _id
              front_image
              edition
              is_custom_booster
              starter_pack_logo_url
              is_starter_pack
              match_meta_data {
                  ... on Streamer {
                      _id
                      login
                      display_name
                      profile_image_url
                  }
                  ... on Game {
                      _id
                      name
                      box_art_url
                  }
                  ... on Organisation {
                      _id
                      name
                      code
                      logo_url
                  }
                  ... on GenericMatch {
                      _id
                      name
                      image_url
                  }
              }
              match_model
              generic_cards {
                  _id
                  card_layer_image
                  rarity
              }
            }
        }
    }
`;

export const getAlertCapsulesWithDiscordInfo = gql`
    query getAlertCapsulesWithDiscordInfo($streamer: ID, $type: String, $show_only_live_streamers: Boolean, $sorting: Sorting, $pagination: Pagination) {
        alertCapsules(streamer: $streamer, type: $type, show_only_live_streamers: $show_only_live_streamers, sorting: $sorting, pagination: $pagination) {
            count
            capsules {
                _id
                type
                is_live
                card {
                    card {
                        _id
                        front_layers {
                            img_url
                        }
                    }
                    rarity
                    quality
                }
                order {
                    order {
                        _id
                        total_cost
                        card_products {
                            product_id
                            processing {
                                design {
                                    url
                                }
                            }
                        }
                    }
                    amount_in_cent
                    cards_of_streamer
                }
                streamer {
                    _id
                    login
                    display_name
                    profile_image_url
                },
                community {
                    league
                    league_string
                },
                used
                redeem_locked_until
                created_at
                alert_setting {
                    show_on_twitch
                    show_on_discord
                    twitch_settings {
                        permissions
                    }
                }
                viewer {
                    _id
                }
            }
        }
        alertCapsuleStreamers {
            _id
            login
            display_name
            profile_image_url
        }
        getMyViewer {
            _id
        }
        getDiscordSetupProgress {
            viewer_auth
        }
    }
`;

export const getMyDrops = gql`
    query getMyDrops ($pagination: Pagination) {
        getMyDrops(pagination: $pagination) {
            _id
            redeemable_until
            droppool {
                type
                value
                min_order_amount
                image
            }
            created_at
            code
        }
        getMyDropsCount
    }
`;

export const getRedeemedCardShopObjectsByMatch = gql`
  query getRedeemedCardShopObjectsByMatch ($match: ID!, $min_quality: Int, $max_quality: Int, $rarity: [Int], $is_legacy: Boolean, $hide_bought: Boolean, $is_seasonal: Boolean, $sorting: Sorting, $pagination: Pagination)  {
      getRedeemedCardShopObjectsByMatch (match: $match, min_quality: $min_quality, max_quality: $max_quality, rarity: $rarity, is_legacy: $is_legacy, hide_bought: $hide_bought, is_seasonal: $is_seasonal, sorting: $sorting, pagination: $pagination) {
          redeemedCardObjects {
              count
              cards {
                  _id
                  ordered_at
                  price
                  discounted_price
                  discount_percent
                  quality
                  rarity
                  condition
                  front_layers {
                      img_url
                      physical_img_url
                      type
                  }
                  back_layers {
                      img_url
                      physical_img_url
                      type
                  }
              }
          }
          legacyCards {
              count
              cards {
                  _id
                  count
                  card {
                      _id
                      image
                      level
                      shop_price
                      discount_percent
                      discounted_price
                      match {
                          ... on Streamer {
                              _id
                              login
                              display_name
                              profile_image_url
                          }
                          ... on Game {
                              _id
                              name
                              box_art_url
                          }
                          ... on Organisation {
                              _id
                              name
                              logo_url
                          }
                      }
                  }
                  specialcard {
                      _id
                      image
                      card_name
                      costs
                      shop_price
                      discount_percent
                      discounted_price
                      collectible_from
                      match {
                          ... on Streamer {
                              _id
                              login
                              display_name
                              profile_image_url
                          }
                          ... on Game {
                              _id
                              name
                              box_art_url
                          }
                          ... on Organisation {
                              _id
                              name
                              logo_url
                          }
                      }
                  }
              }
          }
          offer {
              _id
              title
              sub_title
              description
              translation_template_code
              products {
                  image
                  costs
              }
          }
      }
      streamer (id: $match) {
          _id
          login
          display_name
          profile_image_url
      }
  }
`;



export const getMyShopCardsByMatch = gql`
  query getMyShopCardsByMatch ($match: ID, $pagination: Pagination) {
      getMyShopCardsByMatch(match: $match, pagination: $pagination) {
          streamer_count
          streamer_cards {
              is_event
              streamer {
                  _id
                  login
                  display_name
                  profile_image_url
              }
              card_count
              rarities
          }
      }
      getMyViewer {
          _id
          subscription {
              valid_until
              tier
              subscription_id
              status
          }
      }
      recentTweets { 
          id
          text
          user_profile_img
          images
      }
      getShopBoosterPacks {
          fivePack {
              _id
              price
              rarity
              quality
              condition
              front_image_url
              front_layers {
                  img_url
                  physical_img_url
                  type
              }
              card_template_metadata {
                  _id
                  match_meta_data {
                      ...on Streamer {
                          _id
                          login
                          display_name
                          profile_image_url
                      }
                  }
              }
          }
          tenPack {
              _id
              price
              rarity
              quality
              condition
              rarity
              quality
              front_image_url
              front_layers {
                  img_url
                  physical_img_url
                  type
              }
              card_template_metadata {
                  _id
                  match_meta_data {
                      ...on Streamer {
                          _id
                          login
                          display_name
                          profile_image_url
                      }
                  }
              }
          }
          fifteenPack {
              _id
              price
              rarity
              quality
              condition
              rarity
              quality
              front_image_url
              front_layers {
                  img_url
                  physical_img_url
                  type
              }
              card_template_metadata {
                  _id
                  match_meta_data {
                      ...on Streamer {
                          _id
                          login
                          display_name
                          profile_image_url
                      }
                  }
              }
          }
          twentyPack {
              _id
              price
              rarity
              quality
              condition
              rarity
              quality
              front_image_url
              front_layers {
                  img_url
                  physical_img_url
                  type
              }
              card_template_metadata {
                  _id
                  match_meta_data {
                      ...on Streamer {
                          _id
                          login
                          display_name
                          profile_image_url
                      }
                  }
              }
          }
          twentyfivePack {
              _id
              price
              rarity
              quality
              condition
              rarity
              quality
              front_image_url
              front_layers {
                  img_url
                  physical_img_url
                  type
              }
              card_template_metadata {
                  _id
                  match_meta_data {
                      ...on Streamer {
                          _id
                          login
                          display_name
                          profile_image_url
                      }
                  }
              }
          }
      }
  }
`;

export const getStreamerCardsAndBoosterProgresses = gql`
    query getStreamerCardsAndBoosterProgresses($match: ID!) {
        cardCollectionProgressByMatch(match: $match) {
            booster {
                _id
                front_image
                redeemable_until
                edition
                is_starter_pack
                is_custom_booster
            }
            max
            collected
            progress
        }
        getMyInventoryCardsByMatch(match: $match) {
            all_cards {
                card_count
                streamer_count
            }
            streamer_cards {
                streamer {
                    _id
                    login
                    display_name
                    profile_image_url
                }
                card_count
                rarities
            }
        }
        getMyViewer {
            _id
            subscription {
                valid_until
                tier
                subscription_id
                status
            }
        }
    }
`;

export const getCardsFromDeck = gql`
  query getCardsFromDeck($deck: ID, $min_quality: Int, $max_quality: Int, $rarity: [Int], $sorting: Sorting) {
      getCardDecks {
          _id
          name
          max_cards
          sort_order
          image
          card_count
          updated_at
          rarities
          is_public
          active
      }
      getCardsFromDeck(deck: $deck, min_quality: $min_quality, max_quality: $max_quality, rarity: $rarity, sorting: $sorting) {
          _id
          rarity
          condition
          quality
          front_image_url
          back_image_url
          front_layers {
              img_url
              physical_img_url
              type
          }
          back_layers {
              img_url
              physical_img_url
              type
          }
          card_template_metadata {
              match {
                  ... on Streamer {
                      _id
                      login
                      display_name
                      profile_image_url
                  }
                  ... on Game {
                      _id
                      name
                      box_art_url
                  }
                  ... on Organisation {
                      _id
                      name
                      logo_url
                  }
              }
          }
      }
      getMyViewer {
          _id
          subscription {
              valid_until
              tier
              subscription_id
              status
          }
      }
  }
`;

export const getPublicCardsFromDeck = gql`
  query getCardsFromDeck($id: ID!, $deck: ID, $min_quality: Int, $max_quality: Int, $rarity: [Int], $sorting: Sorting) {
      getPublicDecks(id: $id) {
          _id
          name
          max_cards
          sort_order
          image
          card_count
          updated_at
          rarities
          active
      }
      getCardsFromDeck(deck: $deck, min_quality: $min_quality, max_quality: $max_quality, rarity: $rarity, sorting: $sorting) {
          _id
          rarity
          condition
          quality
          front_image_url
          back_image_url
          front_layers {
              img_url
              physical_img_url
              type
          }
          back_layers {
              img_url
              physical_img_url
              type
          }
          card_template_metadata {
              match {
                  ... on Streamer {
                      _id
                      login
                      display_name
                      profile_image_url
                  }
                  ... on Game {
                      _id
                      name
                      box_art_url
                  }
                  ... on Organisation {
                      _id
                      name
                      logo_url
                  }
              }
          }
      }
      getMyViewer {
          _id
          subscription {
              valid_until
              tier
              subscription_id
              status
          }
      }
  }
`;

export const getMarketPlaceStats = gql`
  query marketPlaceStatsByTimeFrame($timeframe: Int!, $rarity: Int, $pagination: Pagination) {
      marketPlaceStatsByTimeFrame(timeframe: $timeframe) {
          trades
          gems
          streamers
      }
      getMyViewer {
          _id
          subscription {
              valid_until
              tier
              subscription_id
              status
          }
      }
      marketPlaceOffersListed(rarity: $rarity, pagination: $pagination) {
          count
          offers {
              _id
              price
              created_at
              active_until
              avg_price
              offer_match_model_meta_data {
                  ... on RedeemedCardObjectInformationObject {
                      _id
                      front_image_url
                      front_hash
                      front_layers {
                          img_url
                      }
                      card_template_metadata {
                          match_meta_data {
                              ... on Streamer {
                                  login
                                  display_name
                                  profile_image_url
                              }
                          }
                      }
                      quality
                      rarity
                  }
              }
          }
      }
      marketPlaceOffersSold(rarity: $rarity, pagination: $pagination) {
          count
          offers {
              price
              created_at
              sold_at
              avg_price
              offer_match_model_meta_data {
                  ... on RedeemedCardObjectInformationObject {
                      _id
                      front_image_url
                      front_hash
                      front_layers {
                          img_url
                      }
                      quality
                      rarity
                  }
              }
              viewer {
                  _id
                  login
                  display_name
                  profile_image_url
              }
              buyer {
                  _id
                  login
                  display_name
                  profile_image_url
              }
          }
      }
  }
`;

export const getMarketPlaceOffer = gql`
  query getMarketPlaceOffer($id: ID!, $days: Int!, $front_hash: String, $pagination: Pagination) {
      marketPlaceOffer(id: $id) {
          _id
          price
          created_at
          active_until
          viewer {
              _id
              login
              display_name
              profile_image_url
          }
          offer_match_model_meta_data {
              ...on RedeemedCardObjectInformationObject {
                  _id
                  front_layers {
                      img_url
                      physical_img_url
                  }
                  back_layers {
                      img_url
                      physical_img_url
                  }
                  front_image_url
                  back_image_url
                  quality
                  rarity
                  condition
                  card_template_metadata {
                      match_meta_data {
                          ...on Streamer {
                              login
                              display_name
                              profile_image_url
                          }
                      }
                  }
              }
          }
          avg_price
          sold_at
          overall_collected
          unique_collectors
          edition
      }
      getMyViewer {
          _id
          premium_currency
      }
      marketPlaceListingCoupons
      marketPlaceProductSalesHistory(days: $days, front_hash: $front_hash, pagination: $pagination) {
          count
          offers {
              price
              created_at
              sold_at
              avg_price
              offer_match_model_meta_data {
                  ... on RedeemedCardObjectInformationObject {
                      _id
                      front_image_url
                      front_layers {
                          img_url
                          type
                      }
                      quality
                      rarity
                  }
              }
              viewer {
                  login
                  display_name
                  profile_image_url
              }
              buyer {
                  login
                  display_name
                  profile_image_url
              }
          }
      }
  }
`;

export const getMarketPlaceCardData = gql`
  query getMarketPlaceCardData($id: ID!, $quality: Int!, $days: Int!, $pagination: Pagination) {
      marketPlaceCardData(id: $id, quality: $quality) {
          _id
          front_layers {
              img_url
              physical_img_url
          }
          back_layers {
              img_url
              physical_img_url
          }
          redeemed_booster {
              generic_booster_metadata {
                  edition
              }
          }
          front_image_url
          back_image_url
          quality
          rarity
          condition
          overall_collected
          unique_collectors
          created_at
          card_template_metadata {
              match_meta_data {
                  ...on Streamer {
                      login
                      display_name
                      profile_image_url
                  }
                  ... on GenericMatch {
                      _id
                      name
                      image_url
                  }
              }
          }
      }
      marketPlaceListingCoupons
      marketPlaceProductSalesHistory(days: $days, id: $id, quality: $quality, pagination: $pagination) {
          count
          offers {
              price
              created_at
              sold_at
              avg_price
              offer_match_model_meta_data {
                  ... on RedeemedCardObjectInformationObject {
                      _id
                      front_image_url
                      front_layers {
                          img_url
                          type
                      }
                      quality
                      rarity
                  }
              }
              viewer {
                  login
                  display_name
                  profile_image_url
              }
              buyer {
                  login
                  display_name
                  profile_image_url
              }
          }
      }
  }
`;



export const marketPlaceOffers = gql`
  query marketPlaceOffers($match: ID, $min_quality: Int, $max_quality: Int, $rarity: [Int], $sorting: Sorting, $pagination: Pagination, $is_seasonal: Boolean) {
      marketPlaceOffers(match: $match, min_quality: $min_quality, max_quality: $max_quality, rarity: $rarity, sorting: $sorting, pagination: $pagination, is_seasonal: $is_seasonal) {
          count
          offers {
              _id
              price
              created_at
              active_until
              avg_price
              offer_match_model_meta_data {
                  ... on RedeemedCardObjectInformationObject {
                      _id
                      front_image_url
                      front_hash
                      front_layers {
                          img_url
                          type
                      }
                      card_template_metadata {
                          match_meta_data {
                              ... on Streamer {
                                  login
                                  display_name
                                  profile_image_url
                              }
                          }
                      }
                      quality
                      rarity
                      season_id {
                          logo
                      }
                  }
              }
              buyer {
                  _id
                  login
                  display_name
                  profile_image_url
              }
              viewer {
                  _id
                  login
                  display_name
                  profile_image_url
              }
          }
      }
      getMyViewer {
          _id
          premium_currency
          subscription {
              valid_until
              tier
              subscription_id
              status
          }
      }
      marketPlaceOffersMatches {
          ...on Streamer {
              _id
              login
              display_name
              profile_image_url
          }
          ...on GenericMatch {
              _id
              name
              image_url
          }
      }
  }
`;

export const getReferrals = gql`
  query getReferrals{
      getReferrals {
          _id
          reward_one_fulfilled
          reward_one_collected
          reward_two_fulfilled
          reward_two_collected
          is_at_least_one_month_twitch_user
          viewer {
              _id
              login
              display_name
              profile_image_url
          }
          created_at
      } 
      getMyViewer {
          _id
          login
      }
  }
`;



export const getEnergyDashboard = gql`
    query getEnergyDashboard($startDate: String!, $pagination: Pagination) {
        getMyViewer {
            _id
            subscription {
                valid_until
                tier
                subscription_id
                status
            }
        }
        getTodayCollectedStreamer {
            _id
            seq
            energy
            streamer {
                _id
                 login
                 display_name
                 profile_image_url
            }
            updated_at
        }
        getFavoriteStreamer(pagination: $pagination) {
            amount
            watchtimeinminutes
            streamer {
                _id
                login
                display_name
                profile_image_url
            }
        }
        workerStatus {
            active_energy_status
            collectors
            global_status
            global_ranking
        }
        timeBasedStreamViewers(startDate: $startDate) {
            _id
            seq
            date
            streamer {
                _id
                login
                profile_image_url
                display_name
            }
        }
        getActivitiesPrivacySettings
        getActivities(pagination: $pagination) {
            action_type
            action
            action_amount
            action_cost
            action_cost_type
            created_at
            match {
                ...on Streamer {
                    login
                    display_name
                    profile_image_url
                }
                ... on GenericMatch {
                    _id
                    name
                    image_url
                }
            }
        }
    }
`;

export const getMyInventoryCardsByMatch = gql`
  query getMyInventoryCardsByMatch ($match: ID, $pagination: Pagination) {
      getMyInventoryCardsByMatch(match: $match, pagination: $pagination) {
          all_cards {
              card_count
              streamer_count
          }
          streamer_cards {
              streamer {
                  _id
                  login
                  display_name
                  profile_image_url
              }
              card_count
              rarities
          }
      }
      getMyViewer {
          _id
          subscription {
              valid_until
              tier
              subscription_id
              status
          }
      }
  }
`;

export const getCardDecks = gql`
  query getCardDecks {
      getCardDecks {
          _id
          name
          max_cards
          sort_order
          image
          card_count
          updated_at
          rarities
          is_public
          active
      }
      getMyViewer {
          _id
          subscription {
              valid_until
              tier
              subscription_id
              status
          }
      }
  }
`;