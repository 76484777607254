import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';
import HubSeasonCountdown from '../Hub/HubSeasonCountdown';

const conf = require('../../conf');

const useStyles = makeStyles(() => ({
  head: {
    ...Helpers.fillColCenter,
    backgroundImage: `url("${conf.cdn_url}/Events/Trilluxe-RapidLan-September-2022/trilluxe-rapidlansep2022-bg.jpg")`,
    height: 505,
    width: '100%',
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  countdownInfo: {
    marginTop: 16,
    '& > div > div:first-child': {
      display: 'none',
    },
  },
}));

const RaffleTop = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { raffle } = props;

  return (
    <div className={classes.head}>
      <img
        src={`${conf.cdn_url}/Events/Trilluxe-RapidLan-September-2022/trilluxe-rapidlansep2022-raffle-logo.png?height=267`}
        alt="Raffle Logo"
      />

      <div className={classes.countdownInfo}>
        <HubSeasonCountdown
          t={t}
          seasonData={{ available_until: raffle.end }}
        />
      </div>
    </div>
  );
};

export default RaffleTop;