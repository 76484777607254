import React from 'react';
import Chart from 'react-apexcharts';

const PollViewDiagram = (props) => {
  const { poll, answers } = props;

  const labels = [], series = [];
  for (let i = 0; i < poll.answers.length; i++) {
    labels.push(poll.answers[i]);
    series.push(0);
  }

  answers.map(item => {
    item.answers.map(answer => {
      series[answer] += 1;
      return null;
    });
    return null;
  });

  const options = {
    chart: {
      id: 'apexchart-example',
      width: 380,
      type: 'pie',
    },
    labels: labels,
  };

  return (
    <Chart
      style={{ backgroundColor: 'white', marginBottom: 30 }}
      options={options}
      series={series}
      type="pie"
      width={500} />
  );
};

export default PollViewDiagram;