import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { makeStyles } from '@material-ui/core';

import CardShopTweet from './CardShopTweet';
import ArrowButton from '../Button/ArrowButton';
import Helpers from '../../theme/Helpers';

const useStyles = makeStyles((theme) => ({
  area: {
    width: 'calc(100% - 200px)',
    background: 'rgba(255, 255, 255, 0.04)',
    padding: '92px 100px',

    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 16px)',
      padding: 8,
    },
  }
}));

const CardShopTweets = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const slider = useRef(null);
  const { tweets } = props;

  const onSliderHandle = (isNext) => {
    if (isNext)
      slider.current.slickNext();
    else
      slider.current.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 500,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className={classes.area}>
      <div className="inner-wrapper" style={{ margin: '0 auto' }}>
        <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween, marginBottom: 16 }}>
          <div style={{ fontSize: 24, fontWeight: 600 }}>
            {t('proud_owners_supporters')}
          </div>

          <div style={{ ...Helpers.fillRow }}>
            <ArrowButton
              direction="left"
              onClick={() => onSliderHandle(false)}
            />
            <ArrowButton
              direction="right"
              onClick={() => onSliderHandle(true)}
            />
          </div>
        </div>

        <Slider ref={slider} {...settings}>
          {tweets && tweets.map(tweet => (
            <CardShopTweet tweet={tweet} key={tweet.text} />
          ))}
        </Slider>

      </div>
    </div>
  );
};

export default CardShopTweets;