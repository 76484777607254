import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import HRNumbers from 'human-readable-numbers';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import AnimationButton from '../Button/AnimationButton';
import StripeMonthlyDiagram from './StripeMonthlyDiagram';
import { returnMobileObj } from '../../utils/responsive';
import Loading from '../Loading/Loading';
import StripeReferImage from './StripeReferImage';

import conf from '../../conf.json';
import LoadMore from '../Loading/LoadMore';

const useStyles = makeStyles(() => ({
  container: {
    color: '#A8BEE4',
    ...Helpers.fillRow,
    ...Helpers.mainSpaceBetween,
  },
  header: {
    fontSize: 26,
    fontFamily: 'Bebas Neue',
    color: Colors.white,
    lineHeight: 1,
  },
  subHeader: {
    color: '#30466C',
  },
  headWrapper: {
    backgroundImage: `url("${conf.cdn_url}/assets/template/tresor.jpg")`,
    backgroundSize: 'cover',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: 20,
    marginBottom: 30,
    position: 'relative',
    ...Helpers.fillRow,
    ...Helpers.mainEnd,
    overflow: 'hidden',
  },
  infoBox: {
    ...Helpers.textCenter,
    border: '1px solid #071128',
    backgroundColor: '#030B19',
    borderRadius: 10,
    padding: 20,
  },
  translatedCard: {
    marginRight: -30,
    transform: 'rotate(10deg)',
    position: 'relative',
  },
  sellRow: {
    backgroundColor: '#051329',
    color: Colors.white,
    borderRadius: 10,
    padding: '12px 15px',
    marginTop: 40,
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
  },
  sellText: {
    ...Helpers.textLeft,
    '& span': {
      color: Colors.conifer,
      fontWeight: 'bold',
    },
  },
  noDeck: {
    margin: '0 140px',
    backgroundImage: `url("${conf.cdn_url}/assets/nothing_icon.png")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    ...Helpers.fillColCenter,
    height: 180,
    fontFamily: 'Bebas Neue',
    fontSize: 26,
    color: '#32548F',
  },
  noCardsSold: {
    backgroundImage: `url("${conf.cdn_url}/assets/notransactions_ico.png")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: 250,
    color: '#32548F',
    ...Helpers.fillColCenter,
    fontWeight: 'bold',
    fontSize: 20,
  },
  table: {
    '& th': {
      borderBottomColor: '#1F2C41',
      padding: 16,
      backgroundColor: '#01132B',
    },
    '& td': {
      borderBottomColor: '#1F2C41',
    },
  },
  diagramContainer: {
    backgroundColor: '#010814',
    minHeight: 100,
    margin: -29.4,
    marginTop: 30,
    padding: 30,
  },
  link: {
    cursor: 'pointer',
    color: Colors.conifer,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  tabItem: {
    padding: '10px 16px',
    color: Colors.halfWhite,
    transition: 'all 0.3s ease-out',
    borderBottom: '1px solid transparent',
    marginBottom: -1,
    fontSize: 14,
    cursor: 'pointer',
    '&.active': {
      color: Colors.white,
      borderBottomColor: Colors.white,
    },
    '&:hover': {
      color: Colors.white,
    },
  },
}));

const Stripe = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState(0);
    const {
      myViewer,
      myStreamer,
      onCreate,
      cards,
      isTabletOrMobile,
      loading,
      stripeData,
      redirectToPayouts,
      onLoadMore,
      payLoading,
      payOut,
      totalCount,
    } = props;

    const hasCards = cards && cards.length > 0;
    const totalCards = hasCards ? cards.reduce((acc, card) => acc + card.count_created, 0) : 0;
    const stripeEnabled = myViewer && myViewer.stripe && myViewer.stripe.status === 'payouts_enabled';
    const cardSellsExist = stripeData && stripeData.card_orders;
    const digitalSalesExist = stripeData && stripeData.digital_sales;
    const totalAmount = stripeData ? stripeData.transferable_balance : 0;
    const cardPer = activeTab === 0 ? (myStreamer && myStreamer.streamheroes_type === 'partnered' ? 50 : 40) : 55;
    const tabs = ['card_sales', 'gem_transactions'];
    const typeSalesExists = activeTab === 0 ? cardSellsExist : digitalSalesExist;

    const updatePrice = (price) => (price / 100).toFixed(2)
    .toString()
    .replace('.', ',');

    return (
      <>
        <div
          className={classes.headWrapper}
          style={returnMobileObj(isTabletOrMobile, { padding: 5 })}
        >
          {!isTabletOrMobile &&
          <div style={{ position: 'absolute', left: 50, bottom: -4 }}>
            <img
              src={`${conf.cdn_url}/assets/template/chars/bankengineer.png?height=400`}
              alt="Bank engineer"
              style={{ transform: 'scaleX(-1)' }}
            />
          </div>
          }
          <div className={classes.infoBox}>
            <div className={classes.header}>
              {t('your_cards')}
            </div>
            <div style={{ color: '#7B8598', marginBottom: 40 }}>
              {hasCards ? t('collected_x', { count: HRNumbers.toHumanString(totalCards) }) : t('here_could_be_your_deck')}
            </div>
            {hasCards ?
              <div style={{
                margin: isTabletOrMobile ? '5px 15px' : '20px 60px',
                marginRight: isTabletOrMobile ? 22.5 : 90,
              }}>
                {cards.map((card, index) => (
                  <img
                    src={`${card.image}?height=165`}
                    alt="Card"
                    key={card._id}
                    className={classes.translatedCard}
                    style={{ zIndex: (cards.length - index) }}
                  />
                ))}
              </div>
              :
              loading ?
                <div className={classes.noDeck} style={{ minWidth: 200 }}>
                  <Loading />
                </div>
                :
                <div
                  className={classes.noDeck}
                  dangerouslySetInnerHTML={{ __html: t('stripe_here_is_nothing') }}
                />
            }
            {hasCards ?
              <div className={classes.sellRow}>
                {stripeEnabled ?
                  <>
                    <div
                      className={classes.sellText}
                      dangerouslySetInnerHTML={{ __html: t('sell_x', { count: stripeData && stripeData.lifetime_cards_sold ? stripeData.lifetime_cards_sold : 0 }) }}
                    />
                    <div style={{ fontSize: 26, fontFamily: 'Bebas Neue', color: Colors.conifer }}>
                      +{updatePrice(stripeData && stripeData.lifetime_balance ? stripeData.lifetime_balance : 0)} €
                    </div>
                  </>
                  :
                  <div style={{ ...Helpers.textCenter, width: '100%' }}>
                    {t('connect_to_stripe_first')}
                  </div>
                }
              </div>
              :
              !loading &&
              <div>
                <AnimationButton
                  style={{ padding: '10px 50px', marginTop: 30 }}
                  onClick={() => props.history.push('/streamer-settings/booster-management/card-tool')}
                >
                  {t('go_to_card_tool')}
                </AnimationButton>
              </div>
            }
          </div>
        </div>
        <div
          className={classes.container}
          style={returnMobileObj(isTabletOrMobile, { ...Helpers.column })}
        >
          <div style={{
            marginRight: isTabletOrMobile ? 0 : 15,
            width: isTabletOrMobile ? '100%' : '55%',
          }}>
            <div style={{
              width: '100%', ...Helpers.fillRowCross,
              borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
            }}>
              {tabs.map((tab, index) => (
                <div
                  className={[classes.tabItem, index === activeTab ? 'active' : ''].join(' ')}
                  onClick={() => setActiveTab(index)}
                >
                  {t(tab)}
                </div>
              ))}
            </div>

            <TableContainer style={{ maxHeight: 645 }}>
              <Table stickyHeader aria-label="simple table" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className={classes.header}>{t('stripe_order_header')}</div>
                      <div className={classes.subHeader}>{t('stripe_order_sub_header')}</div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.header}>
                        {stripeData ? updatePrice(totalAmount) : '0,00'} €
                      </div>
                      <div className={classes.subHeader}>{t('your_credit')}</div>
                    </TableCell>
                    <TableCell colSpan={2} align="right">
                      {payLoading ?
                        <Loading />
                        :
                        <AnimationButton
                          onClick={payOut}
                          style={{ padding: isTabletOrMobile ? 5 : '10px 50px' }}
                          disabled={(totalAmount / 100) < 100}
                        >
                          {t('stripe_pay_out_now')}
                        </AnimationButton>
                      }
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(!typeSalesExists || loading) ?
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        {loading ?
                          <div className={classes.noCardsSold}>
                            <Loading />
                          </div>
                          :
                          <div
                            className={classes.noCardsSold}
                            dangerouslySetInnerHTML={{ __html: t('stripe_no_cards_sold', { count: cardPer }) }}
                          />
                        }
                      </TableCell>
                    </TableRow>
                    :
                    <>
                      <TableRow>
                        <TableCell
                          colSpan={stripeEnabled ? 2 : 4}
                          align="left"
                          style={{ backgroundColor: '#0E1B30', padding: '10px 16px' }}
                        >
                          <div style={{ fontWeight: 400, color: Colors.conifer }}>
                            {t('stripe_partner_percentage', { count: cardPer })}
                          </div>
                        </TableCell>
                        {stripeEnabled &&
                        <TableCell
                          colSpan={2}
                          align="right"
                          style={{ backgroundColor: '#0E1B30', padding: '10px 16px' }}
                        >
                          <div className={classes.link} onClick={redirectToPayouts}>
                            {t('stripe_show_payouts')}
                          </div>
                        </TableCell>
                        }
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {t('order_date')}
                        </TableCell>
                        {activeTab === 0 &&
                        <TableCell>
                          {t('order_shares')}
                        </TableCell>
                        }
                        <TableCell colSpan={activeTab === 0 ? 2 : 3} align="right">
                          {t('order_sold_cards')}
                        </TableCell>
                      </TableRow>
                    </>
                  }
                  {(stripeData && activeTab === 0) && stripeData.card_orders.map(order => (
                    <TableRow key={order.order_date}>
                      <TableCell style={{ fontWeight: 400 }}>
                        {moment.unix(order.order_date / 1000)
                        .format('L')}
                      </TableCell>
                      <TableCell style={{ fontWeight: 400 }}>
                        {updatePrice(order.streamer_share)} €
                      </TableCell>
                      <TableCell colSpan={2} align="right" style={{ fontWeight: 400 }}>
                        <div style={{ lineHeight: 1 }}>
                          {t('cards_sold_x', { count: order.card_levels.length })}
                          <img
                            src={`${conf.cdn_url}/assets/silver_card_icon.png`}
                            alt="Silver card icon"
                            style={{ marginLeft: 10, verticalAlign: 'middle' }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                  {(stripeData && activeTab === 1) && stripeData.digital_sales.map(order => (
                    <TableRow key={order.month_day}>
                      <TableCell style={{ fontWeight: 400 }}>
                        {moment.unix(order.month_day / 1000)
                        .format('L')}
                      </TableCell>
                      <TableCell colSpan={2} align="right" style={{ fontWeight: 400 }}>
                        <div style={{ lineHeight: 1 }}>
                          {t('cards_sold_x', { count: order.cards })}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <LoadMore
                count={stripeData && (activeTab === 0 ? stripeData.card_orders.length : stripeData.digital_sales.length)}
                totalCount={cards && totalCount}
                loading={loading} onLoadMore={onLoadMore} />
            </TableContainer>
          </div>

          <div style={{
            marginLeft: isTabletOrMobile ? 0 : 15,
            width: isTabletOrMobile ? '100%' : '45%',
          }}>
            {stripeEnabled ?
              <>
                <div style={{ paddingLeft: isTabletOrMobile ? 5 : 20 }}>
                  <div className={classes.header}>{t('stripe_promote_header')}</div>
                  <div className={classes.subHeader}>{t('stripe_payment_sub_header')}</div>
                </div>

                <div className={classes.infoBox} style={{ marginTop: 30 }}>
                  <StripeReferImage myViewer={myViewer} cards={cards} />
                </div>
              </>
              :
              <>
                <div style={{ paddingLeft: isTabletOrMobile ? 5 : 20 }}>
                  <div className={classes.header}>{t('stripe_payment_header')}</div>
                  <div className={classes.subHeader}>{t('stripe_payment_sub_header')}</div>
                </div>

                <div className={classes.infoBox} style={{ margin: '30px 0' }}>
                  {loading ?
                    <div style={{ minHeight: 300, ...Helpers.fillColCenter }}>
                      <Loading />
                    </div>
                    :
                    <>
                      <img
                        src={`${conf.cdn_url}/assets/stripe_logo.png`}
                        alt="Stripe logo"
                        style={{ marginTop: 40, marginBottom: 20 }}
                      />

                      <p style={{ color: '#37568B', ...Helpers.textLeft }}>
                        {t('stripe_connect_text')}
                      </p>

                      {myViewer && myViewer.stripe && myViewer.stripe.status === 'finished_onboarding' ?
                        <>
                          <p style={{ color: '#37568B', ...Helpers.textLeft, marginTop: 15 }}>
                            {t('stripe_finished_onboarding')}
                          </p>
                          <AnimationButton
                            onClick={onCreate}
                            style={{ marginTop: 15, padding: '10px 50px', width: '100%' }}
                          >
                            {t('continue_stripe_process')}
                          </AnimationButton>
                        </>
                        :
                        <AnimationButton
                          onClick={onCreate}
                          style={{ marginTop: 15, padding: '10px 50px', width: '100%' }}
                        >
                          {t(myViewer && !myViewer.stripe ? 'create_stripe_account' : 'continue_stripe_process')}
                        </AnimationButton>
                      }
                    </>
                  }
                </div>

                {/*!loading &&
              <div>
                <div
                  style={{
                    paddingLeft: isTabletOrMobile ? 5 : 20,
                    paddingBottom: 20,
                    borderBottom: '2px solid #1F2C41',
                  }}>
                  <div className={classes.header}>{t('stripe_requirements_header')}</div>
                  <div className={classes.subHeader}>{t('stripe_requirements_sub_header')}</div>
                </div>

                <div style={{ paddingLeft: isTabletOrMobile ? 5 : 20, paddingTop: 15 }}>
                  <CheckpointsList checkpoints={checkpoints} />
                </div>
              </div>
              */}
              </>
            }
          </div>
        </div>

        <div className={classes.diagramContainer}>
          <div>
            <div className={classes.header}>{t('stripe_sale_statistics_header')}</div>
            <div className={classes.subHeader}>{t('stripe_sale_statistics_sub_header')}</div>
          </div>

          {loading ?
            <Loading />
            :
            <StripeMonthlyDiagram stripeData={stripeData} />
          }
        </div>
      </>
    );
  }
;

export default withRouter(Stripe);