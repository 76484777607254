import React, { useCallback, useState } from 'react';
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { faFolder, faPaperclip, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Colors from '../../../theme/Colors';

const useStyles = makeStyles((theme) => ({
  container: {
    flex: '1 1 auto',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      flex: '0 0 auto',
      maxWidth: '100%',
    },
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  buttons: {
    display: 'flex',
    '& > *': {
      flex: '1 1 auto',
      minWidth: 0,
    },
    '& > * + *': {
      marginLeft: theme.spacing(1),
    },
  },
}));

export function MuiFileInput ({ chatController, actionRequest }) {
  const classes = useStyles();
  const chatCtl = chatController;
  const [files, setFiles] = useState([]);

  const handleFileChange = useCallback(
    (fileList) => {
      // Convert FileList to File[]
      const fileArray = [];
      if (fileList) {
        for (let i = 0; i < fileList.length; i += 1) {
          const file = fileList.item(i);
          if (file) {
            fileArray.push(file);
          }
        }
      }
      setFiles(fileArray);
    },
    [],
  );

  const setResponse = useCallback(() => {
    if (files.length > 0) {
      const value = files.map((f) => f.name)
      .toString();
      const res = { type: 'file', value, files };
      chatCtl.setActionResponse(actionRequest, res);
    }
  }, [actionRequest, chatCtl, files]);

  const sendButtonText = actionRequest.sendButtonText
    ? actionRequest.sendButtonText
    : 'Send';

  return (
    <div className={classes.container}>
      <List>
        {files.map((f) => (
          <div key={`${f.name}-${f.size}`}>
            <Divider />
            <ListItem key={f.name}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faPaperclip} size="lg" color={Colors.white} />
              </ListItemIcon>
              <Typography style={{ overflowWrap: 'break-word', minWidth: 0, color: Colors.white }}>
                {f.name}
              </Typography>
            </ListItem>
          </div>
        ))}
      </List>
      <div className={classes.buttons}>
        <Button
          disabled={false}
          component="label"
          variant="contained"
          color="primary"
          startIcon={<FontAwesomeIcon icon={faFolder} size="lg" />}
        >
          Select file
          <input
            type="file"
            accept={actionRequest.accept}
            multiple={actionRequest.multiple}
            onChange={(e) => handleFileChange(e.target.files)}
            style={{ display: 'none' }}
          />
        </Button>
        <Button
          type="button"
          onClick={setResponse}
          disabled={files.length === 0}
          variant="contained"
          color="primary"
          startIcon={<FontAwesomeIcon icon={faPaperPlane} size="lg" />}
        >
          {sendButtonText}
        </Button>
      </div>
    </div>
  );
}