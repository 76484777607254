import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';

import AnimationButton from '../Button/AnimationButton';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';

import conf from '../../conf.json';
import NicePrice from '../Utils/NicePrice';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';

const useStyles = makeStyles({
  itemWrapper: {
    background: 'rgba(255, 255, 255, 0.04)',
    padding: 16,
    backdropFilter: 'blur(50px)',
    '& > div > div > div': {
      justifyContent: 'flex-start !important',
    },
  },
  savingsArea: {
    position: 'absolute',
    top: 10,
    right: 10,
    backgroundColor: '#E12138',
    color: Colors.white,
    borderRadius: 3,
    fontSize: 12,
    padding: 4,
  },
});

const PremiumItem = (props) => {
  const classes = useStyles();
  const { index, setShowBuy, isSelect, isSubscriber } = props;
  const { t } = useTranslation();

  const items = [
    {
      title: t('black_friday_offer'),
      status: 'black_friday',
      image: 'red.png',
      dose: t('your_monthly_dose'),
      months: 1,
      price: 3.60,
      savings: 49,
      buttonStatus: 'red',
    },
    {
      title: t('basic'),
      status: 'basic',
      image: 'mask_big_bronze.png',
      dose: t('your_monthly_dose'),
      months: 1,
      price: 6.99,
      buttonStatus: 'brown',
    },
    {
      title: t('standard'),
      status: 'standard',
      image: 'mask_big_silver.png',
      dose: t('your_quarterly_dose'),
      months: 3,
      price: 5.33,
      savings: 23,
      buttonStatus: 'gray',
    },
    {
      title: t('premium'),
      status: 'premium',
      image: 'mask_big_gold.png',
      dose: t('your_yearly_dose'),
      months: 12,
      price: 4.00,
      savings: 43,
      buttonStatus: 'gold',
    },
  ];
  const { title, image, months, price, dose, status, savings, buttonStatus } = items[index];

  return (
    <div style={{ overflow: 'hidden', position: 'relative', borderRadius: 6 }}>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
          backgroundImage: `url("${conf.cdn_url}/assets/template/${image}")`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '300%',
          backgroundPosition: 'center',
          opacity: 0.5,
        }}
      />

      <CustomBackdropFilter
        className={classes.itemWrapper}
        filter={'blur(50px)'}
        canvasFallback={true}
        html2canvasOpts={{
          allowTaint: true,
        }}
      >
        <div style={{ fontSize: 24, fontWeight: 600 }}>
          {title}
        </div>
        <div style={{ color: Colors.halfWhite, marginTop: 4, fontSize: 14 }}>
          {dose}
        </div>
        {savings &&
        <div className={classes.savingsArea}>
          {t('save_per', { per: savings })}
        </div>
        }
        <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween, marginTop: 10 }}>
          <div>
            <NicePrice price={price} />
            <div style={{ color: Colors.halfWhite }}>{t('month_x', { count: months })}</div>
          </div>
          <AnimationButton
            status={buttonStatus}
            style={{ padding: '14px 12px', fontSize: 14, border: 'none', borderRadius: 6 }}
            onClick={() => isSubscriber ? null : setShowBuy(status)}
            disabled={isSubscriber}
          >
            {t(isSelect ? 'premium_select_package' : 'get_x', { package: title })}
          </AnimationButton>
        </div>
      </CustomBackdropFilter>
    </div>
  );
};

export default PremiumItem;