import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';

const useStyles = makeStyles({
  menuItem: {
    width: 'calc(100% - 24px)',
    padding: 12,
    fontSize: 14,
    fontWeight: 500,
    position: 'relative',
    transition: 'all 0.3s ease-out',
    ...Helpers.textCenter,

    '&:last-child': {
      borderRadius: '0px 0px 6px 6px',
    },
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.04)',
    },
    '&.remove-item': {
      color: '#E12138',

      '&:hover': {
        color: Colors.white,
        background: '#E12138',
      },
    },
  },
});

const InventoryStorageBoxSubMenu = (props) => {
  const classes = useStyles();
  const {
    loading,
    cardDecks,
    selectedBoxId,
    moveCardToStorageBox,
    isLeftSided = false,
  } = props;

  return (
    cardDecks && cardDecks.map(box => (
      ((selectedBoxId && selectedBoxId !== box._id) || !selectedBoxId) &&
      <div
        className={classes.menuItem}
        style={{
          ...(isLeftSided ? { ...Helpers.textLeft, fontWeight: 400 } : {}),
          ...(!box.active ? { color: Colors.halfWhite, cursor: 'not-allowed' } : {})
        }}
        key={box._id}
        onClick={() => (loading || !box.active) ? null : moveCardToStorageBox(box._id)}
      >
        {box.name} <span style={{ marginLeft: 10, fontSize: 12, color: Colors.halfWhite }}>
              ({box.card_count}/{box.max_cards})
            </span>
      </div>
    )));
};

export default InventoryStorageBoxSubMenu;