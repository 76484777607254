import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import ReactPlayer from 'react-player';

import { rangeArray } from '../../utils/helper';
import Colors from '../../theme/Colors';

const conf = require('../../conf');

const useStyles = makeStyles(theme => ({
  root: {
    color: '#636363',
    display: 'flex',
    flexDirection: 'row',
    '& > div': {
      flex: 1,
    },
  },
  area: {
    paddingTop: theme.spacing(3),
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
  },
  radioGroup: {
    flexDirection: 'row',
    marginTop: 20,
  },
  formLabelLegend: {
    backgroundColor: 'transparent',
    color: Colors.white,
  },
  radioButton: {
    color: theme.palette.text.third,
  },
}));

const StreamConfigAlertsAnimationSettings = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { showAnimation, handleChange, animation, canHandleSwitch, cards } = props;

  let showCard;
  if (!cards || (cards && cards.length === 0)) {
    showCard = {
      image: 'https://cdn.streamheroes.gg/assets/fallback-card.png',
      match: {
        display_name: 'G2 eSports',
      },
    };
  } else {
    showCard = cards[0];
  }

  return (
    <div className={classes.root}>
      <div>
        <FormControlLabel
          labelPlacement="start"
          style={{ marginLeft: 0, marginRight: 0 }}
          disabled={!canHandleSwitch}
          control={
            <Switch
              checked={showAnimation}
              onChange={() => handleChange(null, 'showAnimation', !showAnimation)}
              value={showAnimation} />
          }
          label={t('show_alert_animation')}
        />
        {showAnimation &&
        <div className={classes.area}>
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.formLabelLegend}>
              {t('pick_alert_animation')}
            </FormLabel>
            <RadioGroup
              aria-label="animation"
              name="animation"
              className={classes.radioGroup}
              value={animation}
              onChange={(event) => handleChange(event, 'animation', parseInt(event.target.value))}>
              {rangeArray(1, 3)
              .map(number =>
                <FormControlLabel
                  key={`AnimationRadio${number}`}
                  value={number}
                  control={<Radio className={classes.radioButton} />}
                  label={
                    <div className={classes.row}>
                      <Typography style={{ marginRight: 10 }}>
                        Theme {number}
                      </Typography>
                    </div>
                  } />,
              )}
            </RadioGroup>
          </FormControl>
        </div>
        }
      </div>
      <div>
        {(showAnimation && animation) &&
        <div style={{ position: 'relative' }}>
          <ReactPlayer
            url={`${conf.cdn_url}/assets/dashboard/profile/animations/streamheroes-twitchalert${animation}.webm`}
            playing
            loop
            width="100%"
            height="100%" />
          <img
            src={showCard.image}
            alt={showCard.match.display_name}
            style={{
              position: 'absolute',
              top: 'calc(50% - 90px)',
              right: 'calc(50% - 60px)',
              width: '120px',
            }}
          />
        </div>
        }
      </div>
    </div>
  );
};

export default StreamConfigAlertsAnimationSettings;