import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import Loading from '../Loading/Loading';
import CraftButton from '../Button/CraftButton';

const useStyles = makeStyles(() => ({
  progressBar: {
    width: 200,
    margin: '0 auto',
    textAlign: 'center',
  },
}));

function CardCraftProgress (props) {
  const classes = useStyles();

  const { craftNewLevelCard, loading, countOfLastCard } = props;

  const isAvailable = countOfLastCard >= 6;

  return (
    countOfLastCard !== null &&
    <div className={[classes.progressBar, 'center-button'].join(' ')}>
      {(loading) ?
        <Loading style={{ marginBottom: -20, width: 25, height: 30 }} />
        :
        <div style={{ marginTop: -20 }}>
          <CraftButton
            onClick={craftNewLevelCard}
            cards={countOfLastCard}
            costs={6}
            disabled={!isAvailable}
          />
        </div>
      }
    </div>
  );
}

CardCraftProgress.propTypes = {
  craftNewLevelCard: PropTypes.func.isRequired,
};

export default CardCraftProgress;