import React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useParams, withRouter } from 'react-router-dom';

import { cardCollectionProgressByBooster } from '../../apollo/queries';
import { collectCardCollectionReward as collectCardCollectionRewardMutation } from '../../apollo/mutations';

import InventoryBoosterRarityCards from '../../components/Inventory/InventoryBoosterRarityCards';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

const InventoryBoosterRarityCardsContainer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { boosterId, streamerId } = useParams();

  const variables = { id: boosterId, streamerId };
  const { data, loading } = useQuery(cardCollectionProgressByBooster, {
    variables,
    fetchPolicy: useCacheWithExpiration('cardCollectionProgressByBooster'),
    skip: !boosterId,
  });

  const [collectCardCollectionReward, { loading: collectLoading }] = useMutation(collectCardCollectionRewardMutation, {
    onCompleted: () => {
      enqueueSnackbar(t('success'), { variant: 'success' });
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [
      { query: cardCollectionProgressByBooster, variables },
    ],
  });

  const collectReward = (id) => {
    collectCardCollectionReward({ variables: { id } });
  };

  const cardCollection = data && data.cardCollectionProgressByBooster;
  const myViewer = data && data.getMyViewer;
  const booster = data && data.getBooster;
  let streamer = data && data.streamer;

  if (!streamer)
    streamer = {
      _id: '6202877609cbfc52b858fc3c',
      login: 'streamheroes',
      display_name: 'Streamheroes',
      profile_image_url: 'https://static-cdn.jtvnw.net/jtv_user_pictures/fa77cfe3-b7dd-443f-8ba8-594c93740805-profile_image-300x300.png',
    };

  return (
    <InventoryBoosterRarityCards
      cardCollection={cardCollection}
      loading={loading}
      booster={booster}
      streamer={streamer}
      myViewer={myViewer}
      collectLoading={collectLoading}
      collectReward={collectReward}
    />
  );
};

export default withRouter(InventoryBoosterRarityCardsContainer);