import React from 'react';
import DuotoneIconBase from './DuotoneIconBase';

const DepotIcon = (props) => {
  const { forceActive = false } = props;

  return (
    <DuotoneIconBase
      forceActive={forceActive}

      DefaultIcon={
        <svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="-2 -2 26 26">
          <g>
            <path fill="#FFFFFF" d="M18,16.064V17c0,1.224-3.117,3-8,3c-4.884,0-8-1.776-8-3v-0.936C0.74,15.229,0,14.176,0,13v4
                            c0,2.851,4.299,5,10,5s10-2.149,10-5v-4C20,14.176,19.26,15.229,18,16.064z" />
            <g opacity="0.5">
              <g>
                <path fill="#FFFFFF" d="M12,10C6.299,10,2,7.851,2,5s4.299-5,10-5s10,2.149,10,5S17.701,10,12,10z M12,2C7.116,2,4,3.777,4,5
                                    c0,1.224,3.116,3,8,3c4.883,0,8-1.776,8-3C20,3.777,16.883,2,12,2z" />
              </g>
            </g>
            <path fill="#FFFFFF" d="M20,8.064V9c0,1.224-3.117,3-8,3c-4.884,0-8-1.776-8-3V8.064C2.74,7.229,2,6.176,2,5v4
                            c0,2.851,4.299,5,10,5s10-2.149,10-5V5C22,6.176,21.26,7.229,20,8.064z" />
            <g opacity="0.5">
              <path fill="#FFFFFF" d="M10,18c-5.701,0-10-2.149-10-5c0-1.434,1.075-2.73,3.026-3.649c0.5-0.234,1.096-0.021,1.331,0.479
                                c0.235,0.499,0.021,1.095-0.479,1.331C2.702,11.715,2,12.402,2,13c0,1.224,3.116,3,8,3c4.883,0,8-1.776,8-3
                                c0-0.213-0.084-0.429-0.256-0.659c-0.328-0.443-0.236-1.069,0.207-1.399c0.443-0.329,1.068-0.237,1.398,0.206
                                C19.781,11.728,20,12.351,20,13C20,15.851,15.701,18,10,18z" />
            </g>
          </g>
        </svg>
      }
      HoverIcon={
        <svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="-2 -2 26 26">
          <g>
            <path fill="#FFFFFF" d="M18,16.064v0.935C18,18.224,14.883,20,10,20c-4.884,0-8-1.776-8-3.001v-0.935c-1.259-0.836-2-1.889-2-3.065
                            v4C0,19.851,4.299,22,10,22c5.701,0,10-2.149,10-5.001v-4C20,14.176,19.26,15.229,18,16.064z" />
            <g>
              <path fill="#E12138" d="M10,18c-5.701,0-10-2.149-10-5.001c0-1.434,1.075-2.729,3.027-3.648c0.5-0.235,1.096-0.021,1.331,0.479
                                c0.235,0.5,0.021,1.096-0.479,1.331C2.703,11.714,2,12.401,2,12.999C2,14.224,5.116,16,10,16c4.883,0,8-1.776,8-3.001
                                c0-0.212-0.084-0.428-0.254-0.659c-0.33-0.443-0.238-1.068,0.205-1.399c0.443-0.329,1.068-0.236,1.4,0.207
                                c0.43,0.58,0.648,1.203,0.648,1.852C20,15.851,15.701,18,10,18z" />
            </g>
            <g>
              <g>
                <path fill="#E12138" d="M12,10C6.299,10,2,7.85,2,5c0-2.85,4.299-5,10-5c5.701,0,10,2.149,10,5C22,7.85,17.701,10,12,10z M12,2
                                    C7.116,2,4,3.777,4,5c0,1.224,3.116,3,8,3c4.883,0,8-1.777,8-3C20,3.777,16.883,2,12,2z" />
              </g>
            </g>
            <path fill="#FFFFFF" d="M20,8.064V9c0,1.224-3.117,3-8,3c-4.884,0-8-1.777-8-3V8.064C2.741,7.229,2,6.176,2,5v4
                            c0,2.851,4.299,5,10,5c5.701,0,10-2.149,10-5V5C22,6.176,21.26,7.229,20,8.064z" />
          </g>
        </svg>
      }
      ActiveIcon={
        <svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="-2 -2 26 26">
          <g>
            <path fill="#FFFFFF"
                  d="M0,13v3.064V17c0,2.851,4.299,5,10,5s10-2.149,10-5v-0.936V13H0z" />
            <g>
              <g>
                <path fill="#E12138"
                      d="M10,18c-5.701,0-10-2.149-10-5s4.299-5,10-5s10,2.149,10,5S15.701,18,10,18z" />
              </g>
            </g>
            <path fill="#FFFFFF" d="M2,5v3.064V9c0,2.851,4.299,5,10,5s10-2.149,10-5V8.064V5H2z" />
            <g>
              <g>
                <path fill="#E12138"
                      d="M12,10C6.299,10,2,7.851,2,5s4.299-5,10-5s10,2.149,10,5S17.701,10,12,10z" />
              </g>
            </g>
          </g>
        </svg>

      }
    />
  );
};

export default DepotIcon;