import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import TopBG from '../TopViewHeader/TopBG';
import Colors from '../../theme/Colors';
import Loading from '../Loading/Loading';
import ItemStoreBoosterPack from '../ItemStore/ItemStoreBoosterPack';
import Helpers from '../../theme/Helpers';

const Booster = (props) => {
  const { booster, loading, t, hasMore, onLoadMore } = props;

  return (
    <TopBG
      style={{
        padding: '30px 0 20px',
        backgroundSize: 'auto',
        color: Colors.white,
      }}
    >
      {loading ?
        <Loading />
        :
        <div>
          {booster && booster.length > 0 ?
            <InfiniteScroll
              dataLength={booster ? booster.length : 0}
              next={onLoadMore}
              hasMore={hasMore}
              loader={<Loading />}
              style={{ width: '100%', overflowX: 'hidden' }}
            >
              <div style={{
                ...Helpers.fillRowCross,
                flexWrap: 'wrap',
                margin: '4px -8px 0',
              }}>
                {booster.map(booster => (
                  <ItemStoreBoosterPack
                    booster={{
                      ...booster.generic_booster,
                      _id: booster._id,
                      match: booster.generic_booster.match_meta_data,
                    }}
                    t={t}
                    inInventory
                  />))}
              </div>
            </InfiniteScroll>
            :
            <div
              style={{ ...Helpers.textCenter, margin: '30px 0', fontSize: 20, fontWeight: 'bold' }}>
              {t('no_unopened_boosters')}
            </div>
          }
        </div>
      }
    </TopBG>
  );
};

export default Booster;