import {
  SET_STORAGE_BOX_SELECTION_MODE,
  SET_STORAGE_BOX_ID,
  TOGGLE_STORAGE_BOX_SELECTION_CARD, RESET_STORAGE_BOX_DATA,
} from './constants';

const INITIAL_STATE = {
  id: null,
  cards: [],
  cardObjs: [],
  isSelectionMode: false,
  isMy: false,
  viewerId: null,
};

const storageBoxesReducer = (state = INITIAL_STATE, action) => {
  if (action.type === SET_STORAGE_BOX_SELECTION_MODE) {
    return {
      ...state,
      isSelectionMode: action.mode,
      cards: !action.mode ? [] : state.cards,
    };
  } else if (action.type === SET_STORAGE_BOX_ID) {
    return { ...state, id: action.id, isMy: action.isMy, viewerId: action.viewerId };
  } else if (action.type === TOGGLE_STORAGE_BOX_SELECTION_CARD) {
    const tmpCards = [...state.cards];
    const tmpCardObjs = [...state.cardObjs];
    const { id, obj } = action;

    if (tmpCards.includes(id)) {
      const findIdx = tmpCards.findIndex(cardId => cardId === id);
      tmpCards.splice(findIdx, 1);
      tmpCardObjs.splice(findIdx, 1);
    } else{
      tmpCards.push(id);
      tmpCardObjs.push(obj);
    }

    return { ...state, cards: tmpCards, cardObjs: tmpCardObjs };
  } else if (action.type === RESET_STORAGE_BOX_DATA) {
    return { ...INITIAL_STATE, id: action.onlySelection ? state.id : null };
  } else {
    return state;
  }
};

export default storageBoxesReducer;