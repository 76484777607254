import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import amplitude from 'amplitude-js';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import { getHeaderData } from '../../apollo/mergedQueries';
import { getLiveStreamsOfStreamersWithCards } from '../../apollo/queries';

import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import { logAmplitudeEvent } from '../../utils/amplitude';
import Menu from '../../components/Menu/Menu';

import conf from '../../conf';

const MenuContainer = () => {
  const { pathname } = useLocation();
  const [isAmplitudeSend, setIsAmplitudeSend] = useState(false);

  const liveChannelsVariables = {
    pagination: { page: 1, perPage: 5 },
    withCardObjects: true
  };

  const { data } = useQuery(getHeaderData, {
    fetchPolicy: useCacheWithExpiration('getHeaderData'),
  });

  const { data: liveChannelsData } = useQuery(getLiveStreamsOfStreamersWithCards, {
    variables: liveChannelsVariables,
    fetchPolicy: useCacheWithExpiration('getLiveStreamsOfStreamersWithCards', liveChannelsVariables),
  });

  useEffect(() => {
    if (isAmplitudeSend) {
      const amplitudeInfo = (window.localStorage) && window.localStorage.getItem(conf.storage_keys.amplitude);
      const todayFormat = moment()
      .format('DD.MM.YYYY');
      let sendEvent = false;

      if (amplitudeInfo) {
        if (todayFormat !== amplitudeInfo)
          sendEvent = true;
      } else
        sendEvent = true;

      if (sendEvent) {
        window.localStorage.setItem(conf.storage_keys.amplitude, todayFormat);
        if (conf.track_amplitude) logAmplitudeEvent('Active on Streamheroes');
      }
    }
  }, [pathname]);

  const viewer = data && data.getMyViewer;
  const streamer = data && data.getMyStreamer;
  const tanks = data && data.getStreamerTanks;
  const coinsTankInfo = data && data.getSHCoinsTankInformation;
  const redeemableBoosterCount = data && data.getRedeemableBoosterCount;
  const alertWebSourceUrls = data && data.getAlertWebSourceUrls;
  const followedStreamers = (data) && data.getFollowedStreamer;
  const cardDecks = (data) && data.getCardDecks;

  // split into separate query because the backend cannot process authed and un-authed queries simultaneously 
  const liveChannels = (liveChannelsData) && liveChannelsData.getLiveStreamsOfStreamersWithCards;

  if (viewer && !isAmplitudeSend && conf.track_amplitude) {
    setIsAmplitudeSend(true);
    amplitude.getInstance()
    .init('f41da54b9bec9e2e8e11778e893ba860', viewer._id, { apiEndpoint: 'data.streamheroes.gg' });
    logAmplitudeEvent('Visit StreamHeroes');

    const firstLogin = localStorage.getItem(conf.storage_keys.first_login);
    if (firstLogin && firstLogin === 'true')
      localStorage.removeItem(conf.storage_keys.first_login);
  }

  return (
    <Menu
      viewer={viewer}
      streamer={streamer}
      redeemableBoosterCount={redeemableBoosterCount}
      coinsTankInfo={coinsTankInfo}
      tanks={tanks}
      followedStreamers={followedStreamers}
      liveChannels={liveChannels}
      alertWebSourceUrls={alertWebSourceUrls}
      cardDecks={cardDecks}
    />
  );
};

export default MenuContainer;