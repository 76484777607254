import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';

const useStyles = makeStyles(() => ({
  button: {
    fontSize: 18,
    fontFamily: 'Bebas Neue',
    background: `url(${process.env.PUBLIC_URL}/buttons/main/btn-bg.png)`,
    backgroundRepeat: 'repeat',
    minWidth: 100,
    marginRight: 17,
    marginLeft: 47,
    height: 60,
    position: 'relative',
    maxWidth: '100%',
    color: Colors.white,
    cursor: 'pointer',
    '&::before': {
      content: `''`,
      left: -47,
      top: 0,
      width: 47,
      height: '100%',
      position: 'absolute',
      background: `url(${process.env.PUBLIC_URL}/buttons/main/btn-before.png)`,
    },
    '&::after': {
      content: `''`,
      right: -17,
      top: 0,
      width: 17,
      height: '100%',
      position: 'absolute',
      background: `url(${process.env.PUBLIC_URL}/buttons/main/btn-after.png)`,
    },
    '&:hover': {
      opacity: 0.9,
    },
  },
  innerPadding: {
    padding: '23px 10px',
    ...Helpers.textCenter,
  },
}));

const ShinyButton = (props) => {
  const classes = useStyles();
  const { children, onClick, style, innerStyles, disabled } = props;

  return (
    <div
      className={classes.button}
      style={{ ...style, ...(disabled ? { filter: 'grayscale(100%)' } : {}) }}
      onClick={disabled ? null : onClick}
    >
      <div className={classes.innerPadding} style={innerStyles}>
        {children}
      </div>
    </div>
  );
};

ShinyButton.propTypes = {
  style: PropTypes.object,
  innerStyles: PropTypes.object,
};

ShinyButton.defaultProps = {
  style: {},
  innerStyles: {},
};

export default ShinyButton;