import {
  ADD_CARD_TOOL_LAST_STEP,
  REMOVE_CARD_TOOL_LAST_STEP, RESET_BOOSTER_DATA,
  RESET_CARD_TOOL_LAST_STEPS,
  SET_ACTIVE_CARD,
  SET_ALL_CARDS,
  SET_BOOSTER_ALL_DATA,
  SET_BOOSTER_DATA,
  SET_BOOSTER_ID,
} from './constants';

const INITIAL_STATE = {
  lastSteps: [],
  boosterName: '',
  energyCosts: 3,
  startDate: null,
  endDate: null,
  boosterId: null,
  boosterIsApproved: false,
  cards: [],
  activeCardId: null,
  cardToolLevel: null,
  allData: null,
};

const cardToolReducer = (state = INITIAL_STATE, action) => {
  let newState;

  if (action.type === RESET_CARD_TOOL_LAST_STEPS) {
    newState = { lastSteps: [] };
    return { ...state, ...newState };
  } else if (action.type === ADD_CARD_TOOL_LAST_STEP) {
    let tmpFeedList = [...state.lastSteps];
    const { lastStep } = action;

    if (lastStep.type === 'addUserImage') {
      let findIndex = tmpFeedList.findIndex(item => item.type === lastStep.type);

      if (findIndex !== -1) {
        tmpFeedList.splice(findIndex, 1);
        // Remove all movements of the old user image in the step list
        findIndex = tmpFeedList.findIndex(item => item.type === 'moveObject' && item.data.id === 'user-image');
        while (findIndex !== -1) {
          tmpFeedList.splice(findIndex, 1);
          findIndex = tmpFeedList.findIndex(item => item.type === 'moveObject' && item.data.id === 'user-image');
        }
      }
    }

    if ((tmpFeedList.length > 0 && JSON.stringify(lastStep) !== JSON.stringify(tmpFeedList[tmpFeedList.length - 1])) || tmpFeedList.length === 0)
      tmpFeedList.push(lastStep);

    newState = { lastSteps: tmpFeedList };
    return { ...state, ...newState };
  } else if (action.type === REMOVE_CARD_TOOL_LAST_STEP) {
    const tmpArray = [...state.lastSteps];
    tmpArray.pop();

    newState = { lastSteps: tmpArray };
    return { ...state, ...newState };
  } else if (action.type === SET_BOOSTER_DATA) {
    if (action.boosterName.length < 5)
      return null;

    return {
      ...state,
      boosterName: action.boosterName,
      energyCosts: action.energyCosts,
      startDate: action.startDate,
      endDate: action.endDate,
      boosterId: action.boosterId,
      cards: action.cards,
      boosterIsApproved: action.boosterIsApproved,
      allData: action.allData,
    };
  } else if (action.type === SET_ACTIVE_CARD) {
    return { ...state, activeCardId: action.cardId, cardToolLevel: action.level };
  } else if (action.type === SET_ALL_CARDS) {
    return { ...state, cards: action.cards };
  } else if (action.type === SET_BOOSTER_ID) {
    return { ...state, boosterId: action.boosterId };
  } else if (action.type === SET_BOOSTER_ALL_DATA) {
    return { ...state, allData: { ...state.allData, ...action.allData } };
  } else if (action.type === RESET_BOOSTER_DATA) {
    return { ...state, boosterName: '', energyCosts: 3, startDate: null, endDate: null };
  } else {
    return state;
  }
};

export default cardToolReducer;