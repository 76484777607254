import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import MarketplaceBreadcrumbs from '../Breadcrumbs/MarketplaceBreadcrumbs';
import SVGIcon from '../SVGIcon/SVGIcon';
import MarketplaceHomeList from './MarketplaceHomeList';
import AnimationButton from '../Button/AnimationButton';
import { setCollectorsViewAllCards } from '../../redux/actions';
import { isUserPremium } from '../../utils/helper';
import InnerHeader from '../Layout/InnerHeader';

import conf from '../../conf.json';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  iconArea: {
    transition: 'all 0.3s ease-out',
    ...Helpers.insetZero,
    zIndex: -1,
    filter: 'blur(6px)',
    background: '#011024',
    opacity: 0.12,
  },
  headerLineItem: {
    ...Helpers.fillRowCross,
    height: 200,
    background: 'rgba(255, 255, 255, 0.04)',
    transition: 'all 0.3s ease-out',
    '& div': {
      transition: 'all 0.3s ease-out',
    },
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.08)',
    },
  },
  tabItem: {
    transition: 'all 0.3s ease-out',
    padding: '12px 16px 11px',
    borderBottom: '1px solid transparent',
    color: Colors.halfWhite,
    cursor: 'pointer',
    marginBottom: -1,
    '&:hover': {
      color: Colors.white,
    },
    '&.active': {
      color: Colors.white,
      borderBottomColor: Colors.white,
    },
  },
  valueItem: {
    background: 'rgba(255, 255, 255, 0.02)',
    padding: '32px 30px',
    ...Helpers.fillRowCross,

    '& > div:first-child': {
      background: 'rgba(255, 255, 255, 0.12)',
      width: 84,
      height: 84,
      borderRadius: '50%',
      marginRight: 16,
      ...Helpers.fillColCenter,
    },
  },
  gridArea: {
    display: 'grid',
    gap: 3,
    gridTemplateColumns: 'repeat(3, 1fr)',
    marginTop: 3,

    [theme.breakpoints.down('md')]: {
      ...Helpers.fillCol,
    },
  },
  listings: {
    ...Helpers.fillRow,
    ...Helpers.mainSpaceBetween,
    marginTop: 32,

    [theme.breakpoints.down('md')]: {
      ...Helpers.fillCol,
    },
  }
}));

const MarketplaceHome = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    timeframe,
    setTimeframe,
    marketPlaceStats,
    loading,
    rarity,
    setRarity,
    listedItems,
    soldItems,
    myViewer,
  } = props;

  const headerLineItems = [
    {
      icon: `${conf.cdn_url}/assets/template/shop/sh_cardshop_icon_support.png`,
      headText: 'earn_gems_by_trading',
      desc: 'earn_gems_by_trading_desc',
    },
    {
      icon: `${conf.cdn_url}/assets/template/shop/sh_cardshop_icon_physicalcards.png`,
      headText: 'support_streamer_with_trading',
      desc: 'support_streamer_with_trading_desc',
    },
    {
      icon: `${conf.cdn_url}/assets/template/shop/sh_cardshop_icon_capsule.png`,
      headText: 'complete_your_collection',
      desc: 'complete_your_collection_desc',
    },
  ];

  const timeFrameTabs = [
    { key: 'last_24h', value: 1 },
    { key: 'last_7_days', value: 7 },
    { key: 'last_30_days', value: 30 },
  ];

  const { isPremiumActive } = isUserPremium(myViewer);

  return (
    <div style={{ color: Colors.white, position: 'relative' }}>
      {!isMobile &&
      <MarketplaceBreadcrumbs tKey={t('home')} route="home" />
      }

      <InnerHeader
        showAd={conf.ad_area.marketplace && myViewer && !isPremiumActive}
        title={t('redeem_collect_trade')}
        desc={t('redeem_collect_trade_desc')}
      >
        <div style={{ marginTop: 16, ...Helpers.fillRowCross }}>
          <AnimationButton
            status="neonRed"
            style={{ marginRight: 10, padding: '14px 32px' }}
            onClick={() => props.setCollectorsViewAllCards(true)}
          >
            {t('get_your_card_on_market')}
          </AnimationButton>
        </div>
      </InnerHeader>

      <div className={classes.gridArea}>
        {headerLineItems.map(item => (
          <div className={classes.headerLineItem}>
            <div style={{
              width: 168,
              height: '100%',
              ...Helpers.fillRowCenter,
              position: 'relative',
              overflow: 'hidden',
            }}>
              <div className={classes.iconArea}>
                <div style={{
                  backgroundImage: `url("${item.icon}")`,
                  backgroundSize: '100%',
                  backgroundPosition: 'center',
                  position: 'absolute',
                  top: '-48%',
                  left: '-50%',
                  width: '195%',
                  height: '195%',
                }} />
              </div>
              <img src={item.icon} alt="Icon" />
            </div>

            <div style={{ ...Helpers.fillColMain, padding: 32 }}>
              <div style={{ fontWeight: 500 }}>
                {t(item.headText)}
              </div>
              <div style={{
                fontSize: 14,
                color: Colors.halfWhite,
                marginTop: 12,
                maxWidth: 384,
                lineHeight: 1.5,
              }}>
                {t(item.desc)}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="inner-wrapper" style={{ margin: '60px auto' }}>
        {!isMobile &&
        <div style={{ background: 'rgba(255, 255, 255, 0.04)', borderRadius: 6, width: '100%' }}>
          <div style={{
            ...Helpers.fillRowCross,
            borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
          }}>
            {timeFrameTabs.map(item => (
              <div
                className={[classes.tabItem, timeframe === item.value ? 'active' : ''].join(' ')}
                onClick={() => setTimeframe(item.value)}
              >
                {t(item.key)}
              </div>
            ))}
          </div>
          <div style={{ display: 'grid', gap: 3, gridTemplateColumns: 'repeat(3, 1fr)' }}>
            <div className={classes.valueItem}>
              <div>
                <i className="fa-light fa-chart-line-up" style={{ fontSize: 24 }} />
              </div>
              <div>
                <div style={{ color: Colors.halfWhite, fontSize: 12 }}>
                  {t('total_card_trades')}
                </div>
                <div style={{ fontSize: 24, fontWeight: 500, marginTop: 6 }}>
                  {!loading && marketPlaceStats ? marketPlaceStats.trades : '-'}
                </div>
              </div>
            </div>
            <div className={classes.valueItem}>
              <div style={{ background: 'rgba(225, 33, 56, 0.3)' }}>
                <SVGIcon
                  src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
                  height={29}
                  width={22}
                />
              </div>
              <div>
                <div style={{ color: Colors.halfWhite, fontSize: 12 }}>
                  {t('total_gem_volume')}
                </div>
                <div style={{ fontSize: 24, fontWeight: 500, marginTop: 6 }}>
                  {!loading && marketPlaceStats ? marketPlaceStats.gems : '-'}
                </div>
              </div>
            </div>

            <div className={classes.valueItem}>
              <div style={{ background: 'rgba(164, 115, 251, 0.3)' }}>
                <i className="fa-light fa-microphone" style={{ fontSize: 24 }} />
              </div>
              <div>
                <div style={{ color: Colors.halfWhite, fontSize: 12 }}>
                  {t('supported_streamer')}
                </div>
                <div style={{ fontSize: 24, fontWeight: 500, marginTop: 6 }}>
                  {!loading && marketPlaceStats ? marketPlaceStats.streamers : '-'}
                </div>
              </div>
            </div>
          </div>
        </div>
        }

        <div className={classes.listings}>

          <MarketplaceHomeList
            style={{ marginRight: isMobile ? 0 : 8, width: '100%' }}
            title={t('recently_listed')}
            rarity={rarity}
            setRarity={setRarity}
            loading={loading}
            items={listedItems}
          />

          <MarketplaceHomeList
            style={{ marginLeft: isMobile ? 0 : 8, width: '100%' }}
            title={t('recently_sold')}
            rarity={rarity}
            setRarity={setRarity}
            loading={loading}
            items={soldItems}
            isSold
          />

        </div>

        <div style={{ width: '100%', ...Helpers.fillRowCenter, marginTop: 32 }}>
          <AnimationButton
            status="whiteTransparency"
            onClick={() => props.history.push('/marketplace/marketplace')}
            style={{ padding: '14px 113px' }}
          >
            {t('view_more_on_marketplace')}
          </AnimationButton>
        </div>

      </div>

    </div>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setCollectorsViewAllCards,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(withRouter(MarketplaceHome));