import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { PayPalButton } from 'react-paypal-button-v2';
import moment from 'moment';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import {
  resetCardsInShop, toggleNewCardInShop,
  updateAmountInAlbum,
  updateAmountInStatic,
  updateCardInCart,
} from '../../redux/actions';
import CardShopSupporters from './CardShopSupporters';
import CardShopPlantATree from './CardShopPlantATree';
import CardShopSideCartCard from './CardShopSideCartCard';
import AnimationButton from '../Button/AnimationButton';
import CheckAddress from '../Order/CheckAddress';
import CardShopSideCartStatic from './CardShopSideCartStatic';
import Loading from '../Loading/Loading';
import HoverIcon from '../Icon/HoverIcon';
import CardShopSideCartAlbum from './CardShopSideCartAlbum';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';

import conf from '../../conf.json';
import CardShopDiscount from './CardShopDiscount';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    color: Colors.white,
    position: 'relative',
  },
  cardInfo: {
    color: Colors.black,
    ...Helpers.textCenter,
    '& > span': {
      color: Colors.white,
    },
  },
  goForPay: {
    marginTop: 20,
    borderRadius: 4,
    backgroundColor: '#88AE3C',
    color: '#374617',
    padding: '10px 20px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    cursor: 'pointer',
    ...Helpers.textCenter,
    '&:hover': {
      backgroundColor: '#99BF4D',
    },
  },
  matchText: {
    width: 120,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
  },
  amount: {
    textTransform: 'uppercase',
    color: '#506991',
  },
  clickableIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: Colors.white,
    },
  },
  mobileCartFooter: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#88AE3C',
    color: '#374617',
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    padding: 10,
    zIndex: 1000,
  },
  itemCount: {
    backgroundColor: '#374617',
    color: '#88AE3C',
    fontWeight: 'bold',
    padding: '5px 10px',
    borderRadius: 50,
  },
  footerArea: {
    background: '#1B2638',
    margin: '0 -20px',
    padding: 20,
    borderRadius: '0 0 6px 6px',
    width: '100%',

    [theme.breakpoints.down('md')]: {
      margin: 0,
      marginTop: 10,
      padding: 8,
    },
  },
  innerFooter: {
    background: '#232F41',
    padding: '20px 16px',
    borderRadius: 6,
    marginBottom: 15,
  },
  scrollingArea: {
    overflowY: 'auto',
    maxHeight: 330,
    paddingRight: 10,
    marginRight: -10,

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(255, 255, 255, 0.16)',
      borderRadius: 2,
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: '#172132',
      boxShadow: 'none',
    },
  },
  smallText: {
    fontSize: 12,
    color: Colors.halfWhite,
    fontWeight: 300,
    marginBottom: 2,
  },
  feedbackLine: {
    ...Helpers.fillRow,
    ...Helpers.mainSpaceBetween,
    '& > div': {
      flex: 1,
      padding: '8px 16px',
      marginBottom: 8,
      '&:first-child': {
        marginRight: 4,
      },
      '&:last-child': {
        marginLeft: 4,
      },
    },
  },
  darkBox: {
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,.6)',
    top: 0,
    left: 0,
    right: 0,
    bottom: 4,
    zIndex: 1500,
    borderRadius: 3,
    cursor: 'not-allowed',
  },
}));

const CardShopSideCart = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    cardShop,
    shipCosts,
    activeStep,
    setActiveStep,
    isFormFullFilled,
    checkedTerms,
    processing,
    paymentType,
    disabled,
    onlySideMenu,
    toggleMenu,
    createOrderHandler,
    onSuccess,
    loading,
    onCancel,
    handleSubmit,
  } = props;
  let { total, discount } = props;

  const resetLocalStorage = () => {
    localStorage.removeItem('cardOrder');
    localStorage.removeItem('newCardOrder');
    localStorage.removeItem('staticOrder');
    localStorage.removeItem('albumOrder');
    props.resetCardsInShop();
  };

  try {
    if (!total) {
      total = 0;
      if (cardShop.newCards)
        total += cardShop.newCards.reduce((acc, card) => acc + (card.price / 100), 0.00);
      if (cardShop.cards)
        total += cardShop.cards.reduce((acc, card) => acc + (card.price * card.count), 0.00);
      if (cardShop.static)
        total += cardShop.static.reduce((acc, product) => acc + (((product.discounted_price ? product.discounted_price : product.unit_price) * product.amount) / 100), 0.00);
      if (cardShop.albums) //TODO: change albums costs
        total += cardShop.albums.reduce((acc, album) => acc + (((album.discounted_price ? album.discounted_price : album.products[0].costs) * album.amount) / 100), 0.00);
    }
  } catch (e) {
    resetLocalStorage();
  }
  let totalCards = 0;
  if (cardShop.newCards)
    totalCards += cardShop.newCards ? cardShop.newCards.length : 0;
  if (cardShop.cards)
    totalCards += cardShop.cards ? cardShop.cards.reduce((acc, card) => acc + card.count, 0) : 0;
  const minCardsOrder = 5;
  const orderId = localStorage.getItem('SH:ORDER:ID');

  const toggleNewCard = (card) => {
    props.toggleNewCardInShop(card);
  };

  const updateQuantity = (redeemedCards, card = null) => {
    props.updateCardInCart(card, 0.0, redeemedCards);
  };

  const removeStatic = (product) => {
    props.updateAmountInStatic({ ...product, amount: 0 });
  };

  const removeAlbum = (album) => {
    props.updateAmountInAlbum({ ...album, amount: 0 });
  };

  const goToPayPage = () => {
    handleSubmit();
  };

  const activatedCoupon = JSON.parse(localStorage.getItem('activated:coupon'));

  if (activatedCoupon && !discount) {
    discount = {
      percent: activatedCoupon.p,
      minAmount: activatedCoupon.a,
      amount_saved: activatedCoupon.p !== 0 ? (total * 100) * (activatedCoupon.p / 100) : null,
    };
    total -= discount.amount_saved / 100;
  }

  /*const now = moment()
  .valueOf();
  const freeAlbumTime = {
    start: 1639522800000,
    end: 1642201200000,
  };
  const freeAlbumIsActivated = now >= freeAlbumTime.start && now < freeAlbumTime.end;*/

  // const paypalClientId = 'AaI2IfFc3AyFI7jtB-xnLauVOxhrTefgKHnnURkQZaRUh5breDra7vDbyU9cMj_JVxSU7kj6q4gP70zN';

  return (
    <>
      <div className={classes.container}>
        <div style={{ ...Helpers.fillColCenter, ...Helpers.mainSpaceBetween, height: '100%' }}>

          <div style={{ width: '100%', marginBottom: (discount && shipCosts) ? -30 : 0 }}>
            <div
              style={{
                ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween,
                marginBottom: onlySideMenu ? 40 : 18,
              }}>
              <div style={{
                fontFamily: 'Bebas Neue',
                fontSize: 36,
                lineHeight: 1,
              }}>
                {t(onlySideMenu ? 'shopping_cart' : 'order_details')}
              </div>
              {onlySideMenu &&
              <HoverIcon
                icon="fa-thin fa-circle-xmark" hoverIcon="fa-solid fa-circle-xmark"
                style={{ fontSize: 36, marginTop: -10 }}
                onClick={() => toggleMenu()}
              />
              }
            </div>
            <div className={classes.scrollingArea} style={onlySideMenu ? { maxHeight: isMobile ? 200 : 480 } : {}}>
              {/*{(((cardShop.cards && cardShop.cards.length > 0) || (cardShop.static && cardShop.static.length > 0) || (cardShop.albums && cardShop.albums.length > 0)) && freeAlbumIsActivated) &&
                  <div
                    style={{
                      ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween,
                      marginTop: 10, backgroundColor: 'rgb(233, 153, 34)', borderRadius: 8,
                    }}
                  >
                    <div className={classes.amount} style={{ color: Colors.white }}>1x</div>
                    <div style={{ width: 44, height: 68, ...Helpers.fillColCenter }}>
                      <img
                        src="https://cdn.streamheroes.gg/assets/shop/product_album.png?width=44"
                        alt="Collectors album" />
                    </div>
                    <div>
                      <CustomTooltip title={`${t('free')}: ${t('collectors_album')}`}>
                        <div
                          className={classes.matchText}>{t('free')}: {t('collectors_album')}</div>
                      </CustomTooltip>
                    </div>
                    <div>0,00 €</div>
                  </div>
                  }*/}

              {(cardShop.newCards && cardShop.newCards.length > 0) &&
              <div style={{ fontFamily: 'Montserrat', fontSize: 14, fontWeight: 400 }}>
                {t('card_order_overview')}
              </div>
              }
              {cardShop.newCards && cardShop.newCards.map(card =>
                <CardShopSideCartCard
                  key={`CardShopItemKey${card._id}`}
                  card={card}
                  toggleNewCard={toggleNewCard}
                  isRemovable={!activeStep || activeStep < 2}
                />,
              )}

              {(cardShop.cards && cardShop.cards.length > 0) &&
              <div style={{
                fontFamily: 'Montserrat',
                fontSize: 14,
                fontWeight: 400,
                marginTop: cardShop.newCards && cardShop.newCards.length > 0 ? 20 : 0,
              }}>
                {t('legacy_card_order_overview')}
              </div>
              }
              {cardShop.cards && cardShop.cards.map(card =>
                <CardShopSideCartCard
                  key={`CardShopItemKey${card._id}`}
                  card={card}
                  updateQuantity={updateQuantity}
                  isRemovable={!activeStep || activeStep < 2}
                />,
              )}

              {(cardShop.static && cardShop.static.length > 0) &&
              <div
                style={{ fontFamily: 'Montserrat', fontSize: 14, fontWeight: 400, marginTop: 20 }}>
                {t('streamheroes_additions')}
              </div>
              }
              {cardShop.static && cardShop.static.map(product =>
                <CardShopSideCartStatic
                  key={`StaticShopItemKey${product.name}`}
                  product={product}
                  removeStatic={removeStatic}
                />,
              )}

              {(cardShop.albums && cardShop.albums.length > 0) &&
              <div
                style={{ fontFamily: 'Montserrat', fontSize: 14, fontWeight: 400, marginTop: 20 }}>
                {t('album_order_overview')}
              </div>
              }
              {cardShop.albums && cardShop.albums.map(album =>
                <CardShopSideCartAlbum
                  key={`CardShopItemKey$${album._id}`}
                  album={album}
                  removeAlbum={removeAlbum}
                />,
              )}
            </div>
          </div>

          <CustomBackdropFilter
            className={classes.footerArea}
            filter={'blur(50px)'}
            canvasFallback={true}
            html2canvasOpts={{
              allowTaint: true,
            }}
          >

            {activeStep === 2 &&
            <div className={classes.feedbackLine}>
              <CustomBackdropFilter
                className={classes.innerFooter}
                filter={'blur(50px)'}
                canvasFallback={true}
                html2canvasOpts={{
                  allowTaint: true,
                }}
              >
                <div className={classes.smallText}>{t('order_number')}</div>
                <div style={{ fontSize: 14 }}>
                  {orderId ? orderId : 'xxxx'}
                </div>
              </CustomBackdropFilter>
              <CustomBackdropFilter
                className={classes.innerFooter}
                filter={'blur(50px)'}
                canvasFallback={true}
                html2canvasOpts={{
                  allowTaint: true,
                }}
              >
                <div className={classes.smallText}>{t('date')}</div>
                <div style={{ fontSize: 14 }}>
                  {moment()
                  .format('LL')}
                </div>
              </CustomBackdropFilter>
            </div>
            }

            <CustomBackdropFilter
              className={classes.innerFooter}
              filter={'blur(50px)'}
              canvasFallback={true}
              html2canvasOpts={{
                allowTaint: true,
              }}
            >
              {totalCards > 0 &&
              <CardShopSupporters
                cards={[...(cardShop.newCards ? cardShop.newCards : []), ...cardShop.cards]} />
              }
              <CardShopPlantATree price={total} />

              {shipCosts > 0 &&
              <div
                style={{
                  ...Helpers.fillRowCenter,
                  ...Helpers.mainSpaceBetween,
                  marginTop: 17,
                  fontSize: 14,
                }}>
                <div style={{ color: Colors.halfWhite, fontWeight: 300 }}>
                  {t('order_shipping_title')}
                </div>
                <div>
                  {(shipCosts / 100).toFixed(2)
                  .toString()
                  .replace('.', ',')} €
                </div>
              </div>
              }

              <CardShopDiscount discount={discount} total={total} />

              {total > 0 &&
              <div
                style={{
                  ...Helpers.fillRowCenter,
                  ...Helpers.mainSpaceBetween,
                  marginTop: 17,
                  fontFamily: 'Montserrat',
                }}>
                <div style={{ fontSize: 14, color: Colors.halfWhite, fontWeight: 300 }}>
                  {t('order_total_tax_incl')}
                </div>
                <div style={{ ...Helpers.fillRowCenter }}>
                  <div style={{
                    fontSize: 22,
                    color: discount && discount.amount_saved ? '#E12138' : Colors.white,
                  }}>
                    {total.toFixed(2)
                    .toString()
                    .replace('.', ',')} €
                  </div>
                  {(discount && discount.amount_saved) &&
                  <>
                    <div style={{
                      color: Colors.halfWhite,
                      textDecoration: 'line-through',
                      marginLeft: 8,
                    }}>
                      {((total * 100 + discount.amount_saved) / 100).toFixed(2)
                      .toString()
                      .replace('.', ',')} €
                    </div>
                    {discount.percent > 0 &&
                    <div style={{
                      marginLeft: 11,
                      borderRadius: 3,
                      background: '#E12138',
                      fontSize: 12,
                      padding: 4,
                    }}>
                      -{discount.percent}%
                    </div>
                    }
                  </>
                  }
                </div>
              </div>
              }

            </CustomBackdropFilter>

            {onlySideMenu &&
            <div style={{ ...Helpers.fillCol }}>
              <AnimationButton
                status="neonGreen"
                disabled={(totalCards < minCardsOrder && (cardShop.albums && cardShop.albums.length === 0))}
                onClick={() => props.history.push('/card-shop-pay')}
                style={{
                  width: '100%',
                  padding: 14,
                  fontSize: 14,
                  marginBottom: 10,
                  fontFamily: 'Montserrat',
                }}
              >
                {totalCards < minCardsOrder && (cardShop.albums && cardShop.albums.length === 0) ?
                  <div
                    className={classes.cardInfo}
                    dangerouslySetInnerHTML={{
                      __html: t('cards_buy_text', {
                        cards: totalCards,
                        minCards: minCardsOrder,
                      }),
                    }}
                  />
                  :
                  <>
                    <i className="fa-light fa-cart-shopping"
                       style={{ marginRight: 8, fontSize: 18 }} />
                    {t('go_to_checkout')}
                  </>
                }
              </AnimationButton>
              <AnimationButton
                status="whiteTransparency"
                onClick={() => toggleMenu(false)}
                style={{ width: '100%', fontSize: 14, marginBottom: 20 }}
              >
                {t('continue_shopping')}
                <i className="fa-light fa-arrow-right-long"
                   style={{ marginLeft: 8, fontSize: 18 }} />
              </AnimationButton>
            </div>
            }

            {activeStep === 0 &&
            <CheckAddress setActiveStep={setActiveStep} isFormFullFilled={isFormFullFilled} />
            }

            {(activeStep === 1) &&
            <>
              {processing ?
                <Loading />
                :
                paymentType === 'paypal' ?
                  <div style={{ position: 'relative' }}>
                    {(!checkedTerms || loading) &&
                    <div className={classes.darkBox} />
                    }
                    <PayPalButton
                      createOrder={(data, actions) => createOrderHandler(data, actions)}
                      onSuccess={(data, details) => onSuccess(data, details)}
                      onError={(err) => console.error('onError', err)}
                      onCancel={(data) => onCancel(data)}
                      options={{
                        clientId: conf.paypal_client_id,
                        currency: 'EUR',
                        disableFunding: 'sepa,giropay,ideal,mybank,p24,venmo,eps,sofort,card,credit',
                      }}
                    />
                  </div>
                  :
                  <AnimationButton
                    status="neonGreen"
                    disabled={!checkedTerms || processing || (disabled && paymentType === 'card')}
                    onClick={goToPayPage}
                    style={{ width: '100%', padding: 14, fontSize: 14 }}
                  >
                    {t('pay_now')}
                  </AnimationButton>
              }
            </>
            }

            {activeStep < 2 &&
            <div style={{
              marginTop: 10,
              fontFamily: 'Montserrat',
              fontSize: 12,
              fontWeight: 300,
              ...Helpers.textCenter,
              color: Colors.halfWhite,
              marginBottom: 11,
            }}>
              <i className="fa-regular fa-lock" style={{ marginRight: 6 }} />
              {t('your_data_secure_transfer')}
            </div>
            }
          </CustomBackdropFilter>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { cardShop } = state;
  return { cardShop };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    updateCardInCart,
    resetCardsInShop,
    updateAmountInStatic,
    updateAmountInAlbum,
    toggleNewCardInShop,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardShopSideCart));