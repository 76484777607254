import React from 'react';
import DuotoneIconBase from './DuotoneIconBase';

const CardShopIcon = (props) => {
  const { forceActive = false } = props;

  return (
    <DuotoneIconBase
      forceActive={forceActive}

      DefaultIcon={
        <svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="0 0 22 22">
          <g>
            <g opacity="0.5">
              <path fill="#FFFFFF" d="M14,11c-0.552,0-1.001-0.448-1.001-1V4c0-1.103-0.896-2-2-2C9.897,2,9,2.897,9,4v6c0,0.553-0.448,1-1,1
                                s-1-0.448-1-1V4c0-2.206,1.795-4,4-4c2.207,0,4,1.795,4,4v6C14.999,10.553,14.552,11,14,11z" />
            </g>
            <g>
              <rect x="9" y="6" fill="#FFFFFF" width="3.999" height="2" />
              <path fill="#FFFFFF" d="M19.992,18.934L19.277,8.923C19.162,7.312,17.753,6,16.137,6h-1.138v2h1.138
                                c0.579,0,1.104,0.489,1.146,1.065l0.715,10.011c0.018,0.25-0.061,0.484-0.221,0.656C17.615,19.904,17.389,20,17.138,20H4.863
                                c-0.251,0-0.478-0.096-0.64-0.268c-0.16-0.172-0.239-0.406-0.22-0.656L4.717,9.065C4.759,8.489,5.283,8,5.863,8H7V6H5.863
                                C4.247,6,2.838,7.312,2.722,8.923L2.007,18.934c-0.058,0.811,0.21,1.578,0.753,2.162C3.303,21.678,4.05,22,4.863,22h12.275
                                c0.813,0,1.559-0.322,2.102-0.904C19.782,20.512,20.051,19.744,19.992,18.934z" />
            </g>
          </g>
        </svg>
      }
      HoverIcon={
        <svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="0 0 22 22">
          <g>
            <g>
              <g>
                <path fill="#FFFFFF" d="M17.137,21.999H4.863c-0.813,0-1.56-0.32-2.103-0.904c-0.543-0.583-0.811-1.352-0.753-2.161L2.723,8.923
                                    C2.838,7.312,4.247,6,5.863,6h10.273c1.615,0,3.025,1.312,3.141,2.923l0.715,10.011c0.058,0.81-0.21,1.578-0.754,2.161
                                    C18.695,21.679,17.949,21.999,17.137,21.999z M5.863,8c-0.58,0-1.104,0.488-1.146,1.066l-0.715,10.01
                                    c-0.019,0.25,0.061,0.483,0.221,0.655c0.161,0.172,0.388,0.268,0.64,0.268h12.273c0.252,0,0.479-0.096,0.639-0.268
                                    s0.239-0.405,0.221-0.655l-0.714-10.01C17.24,8.488,16.716,8,16.137,8H5.863z" />
              </g>
            </g>
            <g>
              <path fill="#E12138" d="M14,11c-0.553,0-1-0.448-1-1V4.001C13,2.897,12.102,2,11,2C9.897,2,9.001,2.897,9.001,4.001V10
                                c0,0.553-0.448,1-1.001,1c-0.552,0-1-0.448-1-1V4.001c0-2.206,1.795-4,4-4s3.999,1.794,3.999,4V10C14.999,10.553,14.553,11,14,11z
                                " />
            </g>
          </g>
        </svg>
      }
      ActiveIcon={
        <svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="0 0 22 22">
          <g>
            <g>
              <path fill="none"
                    d="M11,2.001c-1.103,0-1.999,0.897-1.999,2v2H13V4C13,2.898,12.102,2.001,11,2.001z" />
              <path fill="#FFFFFF" d="M19.992,18.934l-0.715-10.01C19.162,7.312,17.752,6,16.137,6h-1.138v4c0,0.553-0.446,1-0.999,1
                                s-1-0.448-1-1V6H9.001v4c0,0.553-0.448,1-1.001,1c-0.552,0-1-0.448-1-1V6H5.863C4.247,6,2.838,7.312,2.723,8.923l-0.715,10.01
                                c-0.058,0.811,0.21,1.578,0.753,2.162C3.304,21.678,4.05,22,4.863,22h12.273c0.813,0,1.559-0.322,2.102-0.904
                                C19.782,20.512,20.05,19.744,19.992,18.934z" />
            </g>
            <path fill="#E12138" d="M11,0C8.795,0,7,1.795,7,4v2v4c0,0.553,0.448,1,1,1c0.553,0,1.001-0.448,1.001-1V6V4
                            c0-1.103,0.896-2,1.999-2c1.102,0,2,0.897,2,2v2v4c0,0.553,0.447,1,1,1s0.999-0.448,0.999-1V6V4C14.999,1.795,13.205,0,11,0z" />
          </g>
        </svg>
      }
    />
  );
};

export default CardShopIcon;