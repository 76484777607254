import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';
import { getLeaderboardRankImage, indicator } from '../../utils/helper';
import AnimationButton from '../Button/AnimationButton';
import ProfileCommunityRankedBody from './ProfileCommunityRankedBody';
import SeasonInfo from '../Ranking/SeasonInfo';

const useStyles = makeStyles((theme) => ({
  blurredContainer: {
    background: 'rgba(255, 255, 255, 0.06)',
    borderRadius: 9,
    padding: '8px 0',
    ...Helpers.fillColCenter,
    marginRight: 27,
    minWidth: 191,

    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
  },
  wrapper: {
    ...Helpers.fillRowCross,
    maxWidth: 1440,
    margin: '0 auto 60px',
    width: '100%',
    color: Colors.white,

    [theme.breakpoints.down('md')]: {
      ...Helpers.fillCol,
    },
  }
}));

const ProfileCommunityRanked = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    streamer,
    seasonData,
    season,
    currentLeague,
    setCurrentLeague,
    communityStats,
    totalCount,
    onLoadMore,
    loading,
    topUser,
  } = props;

  const headerCtaButton = ( 
    <AnimationButton
      status="whiteTransparency"
      onClick={() => props.history.push('/ranking/streamer')}
    >
      {t('view_global_leaderboard')}
    </AnimationButton>
  )

  return (
    <>
      <div className={classes.wrapper}>
        <CustomBackdropFilter
          className={classes.blurredContainer}
          filter={'blur(30px)'}
          canvasFallback={true}
          html2canvasOpts={{
            allowTaint: true,
          }}
        >
          <div
            style={{
              fontSize: 12,
              textTransform: 'uppercase',
              color: 'rgba(255, 255, 255, 0.5)',
              marginBottom: 18,
              marginTop: 8,
            }}
          >
            {t('community_placement')}
          </div>
          <img src={getLeaderboardRankImage(communityStats && communityStats.position, 100)} alt="League trophy" />
          <div style={{ ...Helpers.fillRowCenter, marginTop: 5 }}>
            <div style={{ marginRight: 4, fontSize: 36, fontWeight: 600 }}>
              {communityStats && communityStats.position}
            </div>
            <div>{communityStats && indicator(communityStats.position)}</div>
          </div>
        </CustomBackdropFilter>

        {communityStats &&
        <SeasonInfo
          streamer={streamer}
          season={season}
          redeemedBoosters={communityStats.redeemed_booster}
          gatheredEnergy={communityStats.gathered_energy}
          collectors={communityStats.unique_collectors}
          button={headerCtaButton}
        />
        }
      </div>

      <ProfileCommunityRankedBody
        seasonData={seasonData}
        currentLeague={currentLeague}
        setCurrentLeague={setCurrentLeague}
        totalCount={totalCount}
        onLoadMore={onLoadMore}
        loading={loading}
        topUser={topUser}
      />
    </>
  );
};

export default withRouter(ProfileCommunityRanked);