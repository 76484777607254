import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import Helpers from '../../theme/Helpers';
import PagePopup from '../Dialog/PagePopup';
import AnimationButton from '../Button/AnimationButton';
import CardShopMatchCardRightSideStreamerItem
  from '../CardShop/CardShopMatchCardRightSideStreamerItem';
import { getMatchData } from '../../utils/helper';
import Colors from '../../theme/Colors';
import CardShopMatchLineItems from '../CardShop/CardShopMatchLineItems';
import TopBarSupporter from '../Menu/TopBarSupporter';
import ClosePopupButton from '../Button/ClosePopupButton';

const useStyles = makeStyles({
  container: {
    background: '#091828',
    maxWidth: 924,
    margin: '50vh auto 0',
    transform: 'translate(0, -50%)',
    position: 'relative',
    color: Colors.white,
    borderRadius: 4,

    '& > div:last-child > div': {
      border: 'none !important',

      '& > div': {
        padding: 0,
      },
    },
  },
});

const SupportDialog = (props) => {
  const classes = useStyles();
  const { t, onClose, streamer, watchedMin } = props;

  const {
    avatar,
    name,
  } = getMatchData({ match: streamer, matchModel: 'Streamer' }, 116, 116);
  const hours = Math.round(watchedMin / 60);

  return (
    <PagePopup>
      <div className={classes.container}>

        <ClosePopupButton handleClose={onClose} style={{ right: 16, top: 16 }} />

        <CardShopMatchCardRightSideStreamerItem
          name={name}
          avatar={avatar}
          isBigger
          betweenText={t('you_watched_stream_long', { hours })}
          style={{ borderRadius: '4px 4px 0px 0px' }}
        />

        <div
          style={{ ...Helpers.fillRowCenter, ...Helpers.crossStretch, height: 357, marginTop: 3 }}>
          <CardShopMatchLineItems streamer={streamer} />
        </div>

        <div
          style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween, padding: '16px 32px' }}>

          <TopBarSupporter />
          <div />

          <AnimationButton
            status="neonRed"
            href={`/card-shop/streamer/${streamer._id}`}
            onClick={onClose}
            className={classes.button}
          >
            {t('go_to_streamer_shop')}
          </AnimationButton>
        </div>
      </div>
    </PagePopup>
  );
};

export default withRouter(SupportDialog);
