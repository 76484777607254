import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Helpers from '../../theme/Helpers';
import ItemStoreBoosterPack from './ItemStoreBoosterPack';

const useStyles = makeStyles((theme) => ({
  container: {
    ...Helpers.fillRowCross,
    flexWrap: 'wrap',
    margin: '7px -8px 0',

    [theme.breakpoints.down('md')]: {
      ...Helpers.fillCol,
      margin: 0,
    },
  },
}));

const ItemStoreBoosterPacks = (props) => {
  const classes = useStyles();
  const { t, boosters, myViewer } = props;

  return (
    <div className={classes.container}>
      {boosters && boosters.map((booster, index) => (
        <ItemStoreBoosterPack
          key={`BoosterPack${booster._id}`}
          booster={booster}
          t={t}
          myViewer={myViewer}
          switchLastHover={(index + 1) % 5 === 0}
        />
      ))}
    </div>
  );
};

export default ItemStoreBoosterPacks;