import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import { rangeArray } from '../../utils/helper';
import Helpers from '../../theme/Helpers';

import conf from '../../conf.json';
import AnimationButton from '../Button/AnimationButton';

const useStyles = makeStyles({
  noHero: {
    border: '4px solid #11213961',
    borderRadius: 8,
    ...Helpers.fillColCenter,
  },
  middleArea: {
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
    width: '100%',
    ...Helpers.textCenter,
  },
  infoText: {
    fontSize: 16,
    fontWeight: 'bold',
    '& span': {
      fontSize: 14,
      color: '#355681',
      fontWeight: 'normal',
    },
  },
});

const NoPoses = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { showCreation } = props;

  return (
    <div style={{ position: 'relative', ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween }}>
      {rangeArray(1, 5)
      .map(item => (
        <div className={classes.noHero} key={item}>
          <img
            src={`${conf.cdn_url}/assets/template/heroMaker/hero-placeholder.png`}
            alt="Hero placeholder"
          />
        </div>
      ))}
      <div className={classes.middleArea}>
        <div className={classes.infoText}>
          {t('no_heroes_available')}<br />
          <span>{t('create_your_hero_text')}</span>
        </div>

        <AnimationButton status="neonGreen" style={{ marginTop: 14 }} onClick={showCreation}>
          {t('create_your_hero_now')}
        </AnimationButton>
      </div>
    </div>
  );
};

export default NoPoses;