import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';

import Loading from '../Loading/Loading';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  table: {
    width: '100%',
    '& thead': {
      '& th': {
        color: Colors.halfWhite,
        fontSize: 12,
        fontWeight: 400,
        ...Helpers.textLeft,
        textTransform: 'uppercase',
        padding: '16.5px 0px',
        borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
      },
    },

    '& tbody': {
      '& td': {
        fontSize: 14,
        color: Colors.white,
        padding: '16.5px 0px',
        borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
        '& span': {
          color: Colors.halfWhite,
        }
      },

      '& tr:last-child > td': {
        borderBottom: 'none',
        paddingBottom: 0,
      },
    },
  },
}));

const CardShopMatchCardLeftSideMenuHistory = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { loading, card, isLegacy } = props;

  const currentMoment = card && moment.unix(card.created_at / 1000);

  if (card)
    card.events = [{
      date: currentMoment.format('LL ⦁ LT'),
      user: card.viewer.display_name,
      action: t('card_shop_creation_open', { number: card.opened_as_number ? card.opened_as_number.toLocaleString() : 'x' }),
    }];

  return (
    loading ?
      <Loading />
      :
      card &&
      <table className={classes.table}>
        <thead>
        <tr>
          <th>{t('date')} / {t('time')}</th>
          <th>{t('action')}</th>
          <th style={Helpers.textRight}>{t('user')}</th>
        </tr>
        </thead>
        <tbody>
        {card && card.events.map(event => (
          <tr>
            <td>{event.date}</td>
            <td dangerouslySetInnerHTML={{ __html: event.action }} />
            <td style={Helpers.textRight}>{event.user}</td>
          </tr>
        ))}
        </tbody>
      </table>
  );
};

export default CardShopMatchCardLeftSideMenuHistory;