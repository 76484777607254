import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

import Colors from '../../theme/Colors';

const useStyles = makeStyles(() => ({
  item: {
    backgroundColor: Colors.streamInfoBGColor,
    color: Colors.white,
    margin: '10px 8px',
    border: '5px solid ' + Colors.background,
    width: 214,
    borderRadius: 5,
  },
  media: {
    height: 296,
    width: 214,
    backgroundColor: Colors.gray,
  },
  textArea: {
    padding: '25px 20px',
    maxHeight: 40,
    minHeight: 40,
  },
  text: {
    backgroundColor: Colors.white,
    height: 20,
  },
}));

/**
 * @return {null}
 */
function GameSkeletonItem () {
  const classes = useStyles();

  return (
    <div className={classes.item}>
      <Skeleton variant="rect" className={classes.media} />
      <div className={classes.textArea}>
        <Skeleton className={classes.text} />
      </div>
    </div>
  );
}

export default GameSkeletonItem;