import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxHeight: 400,
    float: 'left',
    position: 'relative',
    overflowY: 'auto',
    marginBottom: 30,
  },
}));

const PollViewAnnotations = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { answers } = props;

  const annotations = answers.filter(item => item.annotation && item.annotation.length > 0);

  if (annotations.length === 0) {
    return t('no_data_available');
  }

  return (
    <List dense className={classes.root}>
      {annotations.map(item =>
        <ListItem alignItems="flex-start"
        >
          - <ListItemText
            secondary={item.annotation}
          />
        </ListItem>)
      }
    </List>
  );
};

export default PollViewAnnotations;