import React from 'react';
import DuotoneIconBase from './DuotoneIconBase';

const ItemStoreIcon = (props) => {
  const { forceActive = false } = props;

  return (
    <DuotoneIconBase
      forceActive={forceActive}

      DefaultIcon={
        <svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="-2 -2 26 26">
          <g>
            <g>
              <path fill="#FFFFFF" d="M11,20.164c-0.846,0-1.643-0.318-2.241-0.895L1.1,11.889c-1.189-1.147-1.455-3.155-0.604-4.572L2.39,4.165
                                c0.784-1.306,2.592-2.329,4.116-2.329h8.99c1.523,0,3.332,1.023,4.115,2.329l1.895,3.152c0.85,1.417,0.584,3.424-0.605,4.572
                                l-7.656,7.381C12.643,19.846,11.847,20.164,11,20.164z M6.505,3.835c-0.831,0-1.974,0.648-2.401,1.359L2.211,8.347
                                c-0.372,0.618-0.242,1.601,0.277,2.101l7.657,7.382c0.225,0.217,0.528,0.334,0.854,0.334s0.63-0.117,0.855-0.334l7.656-7.382
                                c0.52-0.5,0.65-1.482,0.278-2.101l-1.893-3.153c-0.428-0.71-1.572-1.359-2.401-1.359H6.505z" />
            </g>
            <path opacity="0.5" fill="#FFFFFF" d="M20.958,8.675h-7.593l4.546-4.679c0.385-0.396,0.377-1.029-0.02-1.414
                            s-1.029-0.375-1.414,0.02L11,8.241L5.521,2.603c-0.384-0.396-1.017-0.405-1.413-0.02c-0.396,0.385-0.405,1.018-0.02,1.414
                            l4.545,4.679H1.043c-0.553,0-1,0.448-1,1.001c0,0.552,0.447,0.999,1,0.999h19.915c0.553,0,1-0.447,1-0.999
                            C21.958,9.124,21.511,8.675,20.958,8.675z" />
          </g>
        </svg>
      }
      HoverIcon={
        <svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="-2 -2 26 26">
          <g>
            <g>
              <path fill="#E12138" d="M20.958,8.676h-7.592l4.546-4.679c0.385-0.396,0.375-1.028-0.021-1.414
                                c-0.396-0.385-1.028-0.375-1.414,0.021L11,8.241L5.522,2.604C5.138,2.208,4.505,2.198,4.108,2.583
                                c-0.396,0.386-0.405,1.018-0.02,1.414l4.545,4.679h-7.59c-0.553,0-1,0.447-1,1s0.446,1,1,1h19.915c0.553,0,1-0.448,1-1
                                S21.511,8.676,20.958,8.676z" />
            </g>
            <g>
              <path fill="#FFFFFF" d="M11,20.165c-0.846,0-1.643-0.318-2.242-0.896l-7.657-7.38c-1.189-1.147-1.455-3.156-0.605-4.572
                                L2.39,4.166c0.783-1.307,2.591-2.331,4.115-2.331h8.991c1.523,0,3.331,1.024,4.114,2.331l1.895,3.152
                                c0.85,1.416,0.585,3.424-0.604,4.572l-7.658,7.38C12.643,19.847,11.848,20.165,11,20.165z M6.505,3.836
                                c-0.83,0-1.974,0.647-2.4,1.358L2.211,8.348c-0.372,0.618-0.242,1.601,0.278,2.101l7.656,7.38
                                c0.226,0.217,0.528,0.337,0.854,0.337c0.327,0,0.63-0.12,0.854-0.337l7.658-7.38c0.52-0.5,0.648-1.483,0.277-2.101l-1.893-3.153
                                c-0.428-0.711-1.571-1.358-2.4-1.358H6.505z" />
            </g>
          </g>
        </svg>

      }
      ActiveIcon={
        <svg version="1.1" x="0px" y="0px" width="22px" height="22px" viewBox="-2 -2 26 26">
          <g>
            <g>
              <path fill="#FFFFFF" d="M16.991,2.123c-0.5-0.184-1.012-0.287-1.496-0.287h-8.99c-0.484,0-0.997,0.104-1.496,0.287L11,8.246
                                L16.991,2.123z" />
              <path fill="#FFFFFF" d="M1.101,11.89l7.657,7.379c0.598,0.578,1.395,0.896,2.242,0.896s1.644-0.317,2.243-0.896l7.656-7.379
                                c0.354-0.343,0.623-0.76,0.809-1.214H0.292C0.478,11.13,0.748,11.547,1.101,11.89z" />
              <path fill="#FFFFFF" d="M3.239,3.173c-0.341,0.3-0.634,0.635-0.849,0.992L0.496,7.317C0.248,7.73,0.097,8.197,0.035,8.676h8.588
                                L3.239,3.173z" />
              <path fill="#FFFFFF" d="M21.966,8.676c-0.063-0.479-0.213-0.945-0.462-1.358L19.61,4.165c-0.215-0.357-0.508-0.692-0.85-0.992
                                l-5.383,5.503H21.966z" />
            </g>
            <path fill="#E12138" d="M21.966,8.676h-8.588l5.383-5.503c-0.509-0.448-1.127-0.815-1.77-1.05L11,8.246L5.009,2.123
                            c-0.643,0.234-1.261,0.602-1.771,1.05l5.384,5.503H0.035c-0.089,0.672,0.001,1.372,0.257,2h21.416
                            C21.964,10.048,22.054,9.348,21.966,8.676z" />
          </g>
        </svg>
      }
    />
  );
};

export default ItemStoreIcon;