import React from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CustomTooltip from '../Tooltip/CustomTooltip';
import { multiplyEnergyCoins } from '../../utils/helper';

const DepotUpgradeTooltip = () => (
  <CustomTooltip
    title={
      <ul style={{ margin: 0, padding: '10px 10px 10px 20px' }}>
        <li><b>Level
          1</b>: {multiplyEnergyCoins(100)} | <b>Upgrade</b>: {multiplyEnergyCoins(50)}
        </li>
        <li><b>Level
          2</b>: {multiplyEnergyCoins(250)} | <b>Upgrade</b>: {multiplyEnergyCoins(125)}
        </li>
        <li><b>Level
          3</b>: {multiplyEnergyCoins(500)} | <b>Upgrade</b>: {multiplyEnergyCoins(300)}
        </li>
        <li><b>Level
          4</b>: {multiplyEnergyCoins(750)} | <b>Upgrade</b>: {multiplyEnergyCoins(550)}
        </li>
        <li><b>Level
          5</b>: {multiplyEnergyCoins(1000)} | <b>Upgrade</b>: {multiplyEnergyCoins(900)}
        </li>
        <li><b>Level
          6</b>: {multiplyEnergyCoins(2000)} | <b>Upgrade</b>: {multiplyEnergyCoins(1500)}
        </li>
        <li><b>Level
          7</b>: {multiplyEnergyCoins(3500)} | <b>Upgrade</b>: {multiplyEnergyCoins(2500)}
        </li>
        <li><b>Level
          8</b>: {multiplyEnergyCoins(6000)} | <b>Upgrade</b>: {multiplyEnergyCoins(5000)}
        </li>
        <li><b>Level 9</b>: {multiplyEnergyCoins(10000)} | <b>MAX UPGRADE</b></li>
      </ul>
    }
  >
    <div>
      <FontAwesomeIcon icon={faInfoCircle} size="2x" />
    </div>
  </CustomTooltip>
);

export default DepotUpgradeTooltip;