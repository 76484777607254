import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';
import { withRouter } from 'react-router-dom';

import Colors from '../../theme/Colors';

const conf = require('../../conf');

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    margin: 0,
    padding: 0,
    zIndex: 10000,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}));

const PlayVideo = (props) => {
  const classes = useStyles();

  const cardId = props.match.params.cardId;
  const viewerId = props.match.params.viewerId;
  const type = props.match.params.type;

  if ((!cardId || !viewerId) && !type) {
    props.history.push('/hq/hub');
    return null;
  }

  let video = 'cards/streamheroes-upgrade-transition.mp4', redirectUrl,
    styles = { backgroundColor: Colors.black };
  if (type && type !== 'raffle') {
    if (type === 'coins') {
      video = 'assets/animations/sh-xp-reward.webm';
      redirectUrl = '/hq/hub';
    } else if (type === 'cards') {
      video = 'assets/animations/sh-quest-reward.webm';
      redirectUrl = `/card/${cardId}/${viewerId}/true`;
    }
    styles = { backgroundColor: '#000000' };
  } else if (type && type === 'raffle') {
    video = 'assets/angelcamp-transition.webm';
    redirectUrl = '/raffle';
  } else {
    redirectUrl = `/card/${cardId}/${viewerId}/true`;
  }

  const redirect = () => {
    props.history.push(redirectUrl);
  };

  return (
    <div className={classes.container} onClick={redirect} style={styles}>
      <ReactPlayer
        url={`${conf.cdn_url}/${video}`}
        playing
        width="100%"
        height="100%"
        onEnded={redirect} />
    </div>
  );
};

export default withRouter(PlayVideo);