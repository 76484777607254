import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';
import HeaderLine from '../TopViewHeader/HeaderLine';
import CardToolSelectCardSetDesign from './CardToolSelectCardSetDesign';
import { rangeArray } from '../../utils/helper';

const CardToolSelectCardDesign = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { activeDesign, setActiveDesign } = props;
  const cardDesigns = rangeArray(1, 26);

  return (
    <div className={classes.root}>
      <div className={classes.headerLine}>
        <HeaderLine
          title={t('select_your_design_theme')}
          subTitle={t('select_your_design_theme')}
        />
      </div>
      <CardToolSelectCardSetDesign
        activeDesign={activeDesign}
        setActiveDesign={setActiveDesign}
        cardDesigns={cardDesigns}
      />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 20,
  },
  headerLine: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    marginBottom: 20,
  },
}));

export default CardToolSelectCardDesign;