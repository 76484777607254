import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { getUnapprovedBoosterTemplate } from '../../apollo/queries';
import {
  removeCardFromUnapprovedBooster,
  removeCardFromUnapprovedBooster as removeCardFromUnapprovedBoosterMutation,
} from '../../apollo/mutations';

import Helpers from '../../theme/Helpers';
import { getLevelWord, getRandomNumber, rangeArray } from '../../utils/helper';
import Colors from '../../theme/Colors';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import { setActiveCard, setGenericCards } from '../../redux/actions';

import conf from '../../conf.json';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
  box: {
    ...Helpers.fillColCenter,
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    padding: '16px 50px',
    transition: 'all 0.3s ease-out',
    cursor: 'pointer',
    '& div': {
      transition: 'all 0.3s ease-out',
    },
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.08)',
    },
  },
  cardButton: {
    background: 'rgba(255, 255, 255, 0.08)',
    borderRadius: 6,
    ...Helpers.fillColCenter,
    padding: 14,
    width: '100%',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.16)',
    },
  },
  hoverableBox: {
    cursor: 'pointer',
    margin: 8,
    transition: 'all 0.3s ease-out',
    '&:hover': {
      '& $box': {
        background: 'rgba(255, 255, 255, 0.08)',
        '& div': {
          transition: 'all 0.3s ease-out',
          opacity: '1 !important',
        },
      },
      '& $belowRow': {
        opacity: 1,
        marginTop: 4,
      },
    },
  },
  belowRow: {
    marginTop: -40,
    ...Helpers.fillRowCross,
    width: '100%',
    opacity: 0,
    transition: 'all 0.3s ease-out',
  },
  hoverLine: {
    marginTop: 50,
    padding: '23px 20px',
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    transition: 'all 0.3s ease-out',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.09)',
    },
  },
}));

const CardsOverview = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { isCreation, boosterId, cardTool } = props;
  let { isStandard } = props;
  const [show, setShow] = useState(true);
  const typeStepCount = isStandard ? 5 : 3;
  const [cardsCount, setCardsCount] = useState(typeStepCount);
  const [started, setStarted] = useState(false);

  const { data } = useQuery(getUnapprovedBoosterTemplate, {
    variables: { id: boosterId && boosterId },
    fetchPolicy: useCacheWithExpiration('getUnapprovedBoosterTemplate'),
    skip: !boosterId,
  });

  const [removeCardFromUnapprovedBooster] = useMutation(removeCardFromUnapprovedBoosterMutation, {
    onCompleted: () => {
      enqueueSnackbar(t('success'), { variant: 'success' });
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{
      query: getUnapprovedBoosterTemplate,
      variables: { id: boosterId && boosterId },
    }],
  });

  const removeCard = (boosterId, cardId) => {
    removeCardFromUnapprovedBooster({
      variables: {
        booster: boosterId,
        card: cardId,
      },
    });
  };

  const booster = data && data.getUnapprovedBoosterTemplate;
  const cards = booster ? booster.generic_cards : (cardTool && cardTool.cards);
  const randomNumber = getRandomNumber();

  if (cardTool && cardTool.boosterIsApproved && cardTool.cards)
    isStandard = cardTool.cards[0].rarity > 0;

  useEffect(() => {
    const dbCardsLength = cards && cards.length;

    if (dbCardsLength && !started) {
      if (!isStandard && dbCardsLength > cardsCount)
        setCardsCount(dbCardsLength);
      else if (isStandard) {
        // create for every rarity card a count with 0
        const levelCount = new Array(5).fill(0);

        // Count every rarity card
        for (let i = 0; i < dbCardsLength; i++)
          levelCount[cards[i].rarity - 1] += 1;

        // The Math.max() function returns the largest value of numbers
        const getHighestCount = Math.max(...levelCount);

        // If one level card is counted higher than one, set more card spots for the standard booster
        if (getHighestCount > 1)
          setCardsCount(getHighestCount * 5);
      }
      setStarted(true);
    }
  }, [cards, cardsCount, isStandard, started]);

  useEffect(() => {
    if (cards && (cardTool && cardTool.cards) && cards.length !== cardTool.cards.length)
      props.setGenericCards(cards);
  }, [cardTool, cards]);

  const goToCardTool = (cardId, level) => {
    props.setActiveCard(cardId, level);

    props.history.push('/streamer-settings/booster-management/card-tool');
  };

  const getRightLevelCard = (index, level) => {
    const allCards = cards.filter(card => card.rarity === level);

    return allCards[index > 5 ? Math.ceil(index / 5) - 1 : 0];
  };

  return (
    <>
      <div className={classes.hoverLine} onClick={() => setShow(!show)}>
        <div>
          {t('upload_your_base_cards')} <span
          style={{
            color: Colors.halfWhite,
            fontSize: 14,
          }}>
          ({cards ? cards.length : 0}/{cardsCount})
          </span>
        </div>

        <div>
          <i className={`fa-regular fa-${show ? 'minus' : 'plus'}`} />
        </div>
      </div>

      {show &&
      <div style={{
        ...Helpers.fillRowCross,
        flexWrap: 'wrap',
        marginTop: 30,
        margin: '30px -8px 0',
      }}>
        {rangeArray(1, cardsCount)
        .map(index => {
          let level = index;
          if (level > 5) {
            level = level % 5;
            if (level === 0)
              level = 5;
          }

          const card = cards ? (isStandard ? getRightLevelCard(index, level) : cards[level - 1]) : null;

          return (
            <div className={classes.hoverableBox} key={`CardImage${level + index}`}>
              <div className={classes.box}>
                <div style={{
                  color: Colors.halfWhite,
                  fontSize: 12,
                  padding: '4px 10px',
                  background: 'rgba(255, 255, 255, 0.04)',
                  borderRadius: 4,
                }}>
                  {t('select_your_card')}
                </div>

                {card ?
                  <img
                    src={`${card.card_layer_image}?height=270&v=${parseInt(randomNumber)}`}
                    alt="Card"
                    style={{ borderRadius: 4, margin: '31px -15px 0' }}
                  />
                  :
                  <>
                    <img
                      src={`${conf.cdn_url}/assets/template/booster_management/level_placeholder/${isStandard ? level : 0}.png`}
                      alt="Placeholder level"
                      style={{ marginBottom: 16, marginTop: 66 }}
                    />
                    <div style={{
                      color: Colors.level[getLevelWord('Card', isStandard ? level : 0)],
                      textTransform: 'uppercase',
                      fontSize: 20,
                      fontWeight: 700,
                      opacity: 0.5,
                    }}>
                      {t(`rareness_level.${isStandard ? level : 0}`)}
                    </div>
                    <div
                      style={{ marginBottom: 32, color: '#ACBABB', fontSize: 14, fontWeight: 500 }}>
                      {t('card')}
                    </div>
                  </>
                }
              </div>
              {((booster && !booster.ready_to_approve) || isCreation) &&
              <div className={classes.belowRow}>
                <div
                  className={classes.cardButton}
                  onClick={() => goToCardTool(card ? card._id : null, isStandard ? level : 0)}
                >
                  {t('card_editor')}
                </div>
                {card &&
                <div
                  className={classes.cardButton}
                  onClick={() => removeCard(boosterId, card._id)}
                  style={{ marginLeft: 4, width: 45 }}
                >
                  <i className="fa-regular fa-trash" />
                </div>
                }
              </div>
              }
            </div>
          );
        })}
        {(booster || isCreation) &&
        <>
          {isStandard ?
            <div
              className={classes.box}
              style={{ padding: 30, height: 296 }}
              onClick={() => setCardsCount(cardsCount + 5)}
            >
              <i className="fa-regular fa-plus" style={{ marginBottom: 8 }} />
              <div style={{ fontSize: 12, width: 70, ...Helpers.textCenter }}>
                {t('add_5_more_cards')}
              </div>
            </div>
            :
            <div
              className={classes.box}
              style={{ color: Colors.halfWhite, height: 324, width: 145, margin: 8 }}
              onClick={() => setCardsCount(cardsCount + 1)}
            >
              <i className="fa-regular fa-plus" style={{
                marginBottom: 12,
                padding: '12px 12.5px',
                background: 'rgba(255, 255, 255, 0.06)',
                borderRadius: 6,
              }} />
              <div style={{ fontSize: 14 }}>
                {t('add_card')}
              </div>
            </div>
          }
        </>
        }
      </div>
      }
    </>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setActiveCard,
    setGenericCards,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(withRouter(CardsOverview));