import React from 'react';
import { Query } from 'react-apollo';
import { makeStyles } from '@material-ui/core/styles';

import { getUpvotyToken } from '../../apollo/queries';
import Loading from '../../components/Loading/Loading';
import UpvotyWidget from '../../utils/react-upvoty/index';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import EventModeMessage from '../../components/EventMode/EventModeMessage';

import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  wrapper: {
    textAlign: 'center',
    padding: '30px 0',
  },
  upvotyWidget: {
    display: 'inline-block',
  },
  container: {
    maxWidth: 1444,
    margin: '0 auto',
  },
}));

const BugContainer = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {conf.event_mode && <EventModeMessage />}
      <Query query={getUpvotyToken} fetchPolicy={useCacheWithExpiration('getUpvotyToken')}>
        {({ data, loading }) => {
          if (loading) return <Loading />;
          const upvotyToken = (data) && data.getUpvotyToken;

          return (
            <div className={classes.wrapper}>
              <UpvotyWidget
                ssoToken={upvotyToken}
                baseUrl={'streamheroes.upvoty.com'}
                boardHash={'2d2ce5ce82166c449a8767ece18881e30561cb26cd5ac0a76da4cac216fd8f78'}
              />
            </div>
          );
        }}
      </Query>
    </div>
  );
};

export default BugContainer;