import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import CustomButton from '../Button/CustomButton';

const conf = require('../../conf');

const useStyles = makeStyles(theme => ({
  containerHint: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.text.fourth,
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const NotLoggedInHint = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { pathname } = props.location;

  const check = ['/login', '/limit', '/handle-login'].includes(pathname);

  if (!window.localStorage.getItem(conf.storage_keys.user_token) && !check && !pathname.includes('/3d-booster') && !pathname.includes('/3d-card')) {
    return (
      <div className={classes.containerHint}>
        {t('start_compete')}
        <CustomButton onClick={() => props.history.push('/login')}>
          {t('start_now')}
        </CustomButton>
      </div>
    );
  } else {
    return null;
  }
};

export default withRouter(NotLoggedInHint);