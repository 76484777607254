import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import OrderSummarySupporter from './OrderSummarySupporter';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';

import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  linkArea: {
    ...Helpers.fillRowMain,
    ...Helpers.mainSpaceBetween,
    width: 733,
    padding: '26px 16px',
    background: 'rgba(255, 255, 255, 0.04)',
    color: Colors.white,
    borderRadius: 7,
    marginTop: 10,
  },
  headArea: {
    ...Helpers.fillRow,
    marginBottom: 48,
    height: 299,
    width: '100%',
  },
  greenBox: {
    ...Helpers.fillColCenter,
    background: 'rgba(9, 183, 17, 0.13)',
    borderRadius: 6,
    margin: '0 10px',
    width: 375,
    ...Helpers.textCenter,
  },
  transparentButton: {
    padding: '14px 16px',
    color: Colors.white,
    borderRadius: 6,
    textDecoration: 'none',
    transition: 'all 0.3s ease-out',
    fontSize: 14,
    fontWeight: 300,
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.08)',
    },
  },
  treeBox: {
    position: 'relative',
    background: `linear-gradient(0deg, #011024 0%, #011024C2 31%, #01102400 57%, #01102400 100%), rgba(9, 183, 17, 0.13)`,
    padding: 18,
    width: 218,
    borderRadius: 6,
    ...Helpers.fillColCenter,
    ...Helpers.mainEnd,
    zIndex: 1,
  },
  forestBG: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `url("${conf.cdn_url}/assets/template/shop/forest.png")`,
    opacity: 0.38,
    zIndex: -1,
  },
}));

const OrderSummary = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { summaryMatches, total } = props;

  const links = [
    {
      title: 'inventory',
      desc: 'go_to_your_inventory',
      linkText: 'go_to_inventory',
      link: '/inventory',
    },
    {
      title: 'item_store',
      desc: 'buy_more_items',
      linkText: 'buy_items',
      link: '/item-store/booster',
    },
    {
      title: 'card_shop',
      desc: 'not_enough_cards_ordered',
      linkText: 'order_more_cards',
      link: '/card-shop',
    },
  ];

  const plantedATree = total !== null && total >= 20;

  return (
    <>
      <div className={classes.headArea}>

        <div
          className={classes.treeBox}
          style={plantedATree ? {} : {
            background: `linear-gradient(0deg, #FF0000 0%, #FF000080 31%, #FF000000 57%, #FF000000 100%), #FF000059`,}}
        >
          <div className={classes.forestBG} />
          <i className={`fa-solid fa-${plantedATree ? 'trees' : 'wheat-slash'}`} style={{ fontSize: 120 }} />

          <div style={{
            fontSize: 12,
            marginTop: 34,
            fontWeight: 300,
            color: Colors.halfWhite,
          }}>
            {t(plantedATree ? 'you_have' : 'you_dont_have')}
          </div>
          <div style={{ marginTop: 2 }}>
            {t('planted_a_tree')}
          </div>
        </div>

        <div className={classes.greenBox}>
          <div style={{ borderRadius: '50%', background: Colors.white, width: 47, height: 47 }}>
            <i className="fa-solid fa-circle-check" style={{ fontSize: 47, color: '#09B711' }} />
          </div>
          <div style={{
            fontFamily: 'Bebas Neue',
            fontSize: 36,
            lineHeight: 1,
            color: '#09B711',
            textShadow: '0px 0px 18px #29FF3296',
            marginTop: 14,
          }}>
            {t('order_successful')}
          </div>
          <div style={{ fontSize: 14, marginTop: 3 }}>
            {t('order_process_immediately')}
          </div>
        </div>

        <OrderSummarySupporter matches={summaryMatches} />
      </div>

      <div style={{ ...Helpers.fillColCenter }}>
        {links.map(link => (
          <CustomBackdropFilter
            className={classes.linkArea}
            filter={'blur(50px)'}
            canvasFallback={true}
            html2canvasOpts={{
              allowTaint: true,
            }}
            key={`LinkArea${link.title}`}
          >
            <div style={{ marginLeft: 4 }}>
              <div style={{ marginBottom: 4 }}>
                {t(link.title)}
              </div>
              <div style={{ fontSize: 14, color: Colors.halfWhite, fontWeight: 300 }}>
                {t(link.desc)}
              </div>
            </div>
            <Link to={link.link} className={classes.transparentButton}>
              {t(link.linkText)}
              <i className="fa-light fa-chevron-right" style={{ marginLeft: 8 }} />
            </Link>
          </CustomBackdropFilter>
        ))}
      </div>
    </>
  );
};

export default OrderSummary;