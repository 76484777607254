import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { Link } from '@material-ui/core';

import CustomTooltip from '../Tooltip/CustomTooltip';
import Helpers from '../../theme/Helpers';
import { getRandomInt, replaceWidthAndHeight } from '../../utils/helper';
import Colors from '../../theme/Colors';
import HoverIcon from '../Icon/HoverIcon';

const useStyles = makeStyles({
  container: {
    color: Colors.halfWhite,
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 300,
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
  },
  avatarArea: {
    ...Helpers.fillRowCross,
    flexWrap: 'wrap',
    marginRight: -5,
  },
  avatarClass: {
    width: 31,
    height: 31,
    border: '5px solid #232F41',
  },
  circle: {
    borderRadius: '50%',
    width: 41,
    height: 41,
    background: '#343A52',
    cursor: 'pointer',
    ...Helpers.fillRowCenter,
  },
});

const CardShopSupporters = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { cards } = props;

  const matches = cards && cards.map(card => card.card_template_metadata ? card.card_template_metadata.match : card.match);
  const filteredList = [...new Set(matches.map(JSON.stringify))].map(JSON.parse);

  const getAllOtherStreamers = () => {
    let returnValue = '';
    for (let i = 4; i < filteredList.length; i++) {
      const { name } = getDataOfMatchType(filteredList[i]);
      returnValue += name;
      if (i <= filteredList.length - 1)
        returnValue += '<br />';
    }

    return returnValue;
  };

  const getDataOfMatchType = (match) => {
    let href = '', logo = '', name = '', type = '';
    if (!match) {
      href = '/profile/streamheroesgg';
      name = 'Streamheroes';
      logo = 'https://static-cdn.jtvnw.net/jtv_user_pictures/fa77cfe3-b7dd-443f-8ba8-594c93740805-profile_image-300x300.png';
      type = 'SH';
    } else {
      type = match.__typename;
    }

    if (type === 'Streamer' || type === 'Viewer') {
      href = (match.__typename === 'Streamer' ? '/profile/' : '/profile/') + match.login;
      logo = match.profile_image_url;
      name = match.display_name;
    } else if (type === 'Organisation') {
      href = '/organisation/' + match.code;
      logo = match.logo_url;
      name = match.name;
    } else if (type === 'Game') {
      href = '/hq/game-streams/' + decodeURIComponent(match.name);
      logo = replaceWidthAndHeight(match.box_art_url, 31, 31);
      name = match.name;
    }

    return { href, logo, name };
  };
  const maxItems = filteredList.length < 5 ? filteredList.length - 1 : 4;

  return (
    <div className={classes.container}>
      <div style={{ ...Helpers.fillRowCross, maxWidth: 250 }}>
        <CustomTooltip title={t('buying_support_info')}>
          <div style={{ marginRight: 8 }}>
            <HoverIcon
              icon="fa-light fa-circle-info" hoverIcon="fa-solid fa-circle-info"
              color={Colors.halfWhite} hoverColor={Colors.white}
              style={{ fontSize: 18 }}
            />
          </div>
        </CustomTooltip>
        {t('you_support_following')}
      </div>
      <div className={classes.avatarArea}>
        {filteredList.slice(0, filteredList.length > 5 ? 4 : 5)
        .map((match, index) => {
          const { href, logo, name } = getDataOfMatchType(match);

          /*const type = match.__typename;

          let href = '', logo = '', name = '';
          if (type === 'Streamer' || type === 'Viewer') {
            href = (match.__typename === 'Streamer' ? '/profile/' : '/profile/') + match.login;
            logo = match.profile_image_url;
            name = match.display_name;
          } else if (type === 'Organisation') {
            href = '/organisation/' + match.code;
            logo = match.logo_url;
            name = match.name;
          } else if (type === 'Game') {
            href = '/hq/game-streams/' + decodeURIComponent(match.name);
            logo = replaceWidthAndHeight(match.box_art_url, 31, 31);
            name = match.name;
          }*/

          return (
            <CustomTooltip title={name} key={match ? match._id : getRandomInt(100)}>
              <Link href={href} target="_blank">
                <Avatar
                  variant="circular"
                  alt={name}
                  src={logo}
                  classes={{ root: classes.avatarClass }}
                  style={{ zIndex: 5 - index, marginRight: index === maxItems ? 0 : -15 }}
                />
              </Link>
            </CustomTooltip>
          );
        })}
        {(filteredList && filteredList.length > 5) &&
        <CustomTooltip
          title={<span dangerouslySetInnerHTML={{ __html: getAllOtherStreamers() }} />}>
          <div className={classes.circle}>
            +{filteredList.length - 4}
          </div>
        </CustomTooltip>
        }
      </div>
    </div>
  );
};

export default CardShopSupporters;