import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from 'react-step-progress-bar';

import AnimationButton from '../Button/AnimationButton';
import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';

const LoadMore = (props) => {
  const { t } = useTranslation();
  const { count, totalCount, onLoadMore, loading } = props;
  const [oldCount, setOldCount] = useState(count);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoading && oldCount !== count) {
      setOldCount(count);
      setIsLoading(false);
    }
  }, [oldCount, isLoading, count]);

  const hasMore = count < totalCount;
  const percentage = Math.round((count / totalCount) * 100);

  return (
    <div style={{ width: '100%', ...Helpers.fillColCenter, marginTop: 68 }}>
      <div style={{ fontSize: 12, marginBottom: 12.5 }}>
        {t('show_x_of_y', { count: hasMore ? count : totalCount, totalCount })}
      </div>

      <div style={{ width: 208 }}>
        <ProgressBar
          percent={percentage}
          filledBackground={Colors.white}
          unfilledBackground={'rgba(255, 255, 255, 0.12)'}
          height={4}
        />
      </div>

      {hasMore &&
      <AnimationButton
        status="transparent"
        style={{
          marginTop: 28.5,
          fontFamily: 'Montserrat',
          fontSize: 14,
          width: 208,
          borderColor: Colors.white,
        }}
        onClick={() => {
          setIsLoading(true);
          onLoadMore();
        }}
        disabled={isLoading || loading}
      >
        {isLoading ?
          <i className="fa-light fa-loader fa-spin" style={{ fontSize: 16 }} />
          :
          t('show_more')
        }
      </AnimationButton>
      }
    </div>
  );
};

export default LoadMore;