import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';

import { getRandomInt } from '../../utils/helper';

const conf = require('../../conf');

const useStyles = makeStyles(() => ({
  hex: {
    width: 44,
    height: 38,
    position: 'relative',
  },
  video: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}));

const ProfileOverviewBadgeVideo = (props) => {
  const classes = useStyles();
  const { badge } = props;
  const videoRef = useRef(null);

  useEffect(() => playAnimation());

  const playAnimation = () => {
    const random = getRandomInt(10);
    setTimeout(() => {
      if (videoRef.current) {
        const promise = videoRef.current.play();

        if (promise !== undefined) {
          promise.then(_ => {
            // Autoplay started!
          })
          .catch(error => {
            //playAnimation();
            // Autoplay was prevented.
          });
        }
      }
    }, random * 1000);
  };

  const random = getRandomInt(2);

  return (
    <>
      <div className={classes.hex}>
        <video loop className={classes.video} ref={videoRef}>
          <source
            src={`${conf.cdn_url}/assets/animations/badge-lightsweep${random === 1 ? '' : random}.webm`}
            type="video/webm"
          />
        </video>
        <img src={`${badge.image_url}?height=38`} alt="Badge" />
      </div>
    </>
  );
};

export default ProfileOverviewBadgeVideo;