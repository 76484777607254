import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { getHubDashboard, getHeaderData } from '../../apollo/mergedQueries';
import { getMyViewer, getLiveStreamsOfStreamersWithCards } from '../../apollo/queries';
import {
  updateMyData as updateMyDataMutation,
  collectCoinReward as collectCoinRewardMutation,
} from '../../apollo/mutations';

import i18n from '../../utils/i18n';
import PageTitle from '../../components/PageTitle/PageTitle';
import ErrorMessage from '../../components/ErrorBoundary/ErrorMessage';
import { saveUserInfo } from '../../utils/helper';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import { logAmplitudeEvent } from '../../utils/amplitude';
import Hub from '../../components/Hub/Hub';

const conf = require('../../conf');

const HubContainer = () => {
  const { t } = useTranslation();
  const [cookie, setCookie] = useCookies([conf.storage_keys.lang_token]);
  const [isPushed, setIsPushed] = useState(false);

  const variables = {
    pagination: { page: 1, perPage: 10 },
  };
  const { data, loading, error, refetch } = useQuery(getHubDashboard, {
    variables,
    fetchPolicy: useCacheWithExpiration('getHubDashboard', variables),
  });
  const liveChannelsVariables = {
    pagination: { page: 1, perPage: 4 },
    withCardObjects: true,
  };
  const {
    data: liveChannelsData,
    loading: liveChannelsLoading,
    error: liveChannelsError,
  } = useQuery(getLiveStreamsOfStreamersWithCards, {
    variables: liveChannelsVariables,
    fetchPolicy: useCacheWithExpiration('getLiveStreamsOfStreamersWithCards', liveChannelsVariables),
  });

  const [updateMyData] = useMutation(updateMyDataMutation, {
    refetchQueries: () => [{ query: getMyViewer }],
  });

  const [collectCoinReward, { loading: collectLoading }] = useMutation(collectCoinRewardMutation, {
    refetchQueries: () => [{ query: getHubDashboard, variables }, { query: getHeaderData }],
  });

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const changeCountry = (country) => {
    updateMyData({ variables: { update: { country } } });
    if (conf.track_amplitude) logAmplitudeEvent('User set country', {
      country,
    });
  };

  const collectCoins = () => {
    collectCoinReward();
  };

  const userInfo = (data) && data.getMyViewer;
  const seasonData = (data) && data.getCurrentSeason;
  const seasonLeaders = (data) && data.getSeasonStreamerLeaderboard;
  const latestBooster = (data) && data.getLatestBooster;
  const questProgress = (data) && data.getQuestProgress;
  const todayCollectedStreamer = (data) && data.getTodayCollectedStreamer;
  const favoriteStreamer = (data) && data.getFavoriteStreamer;
  const collectCoinsInfo = (data) && data.getCollectCoinReward;

  // split into separate query because the backend cannot process authed and un-authed queries simultaneously 
  const liveChannels = (liveChannelsData) && liveChannelsData.getLiveStreamsOfStreamersWithCards;

  useEffect(() => {
    if (userInfo) {
      if (userInfo.language) {
        if (window.localStorage.getItem(conf.storage_keys.lang_token) !== userInfo.language) {
          changeLanguage(userInfo.language);
          if (cookie[conf.storage_keys.lang_token] !== userInfo.language) {
            setCookie(conf.storage_keys.lang_token, userInfo.language, { path: '/' });
          }
        }
      }

      if (!userInfo.country && !isPushed) {
        axios.get(`https://ipapi.co/country/?key=${conf.ipapi_key}`)
        .then((response) => {
          if (response && response.data) {
            changeCountry(response.data);
            setIsPushed(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }

      saveUserInfo(userInfo);
    }
  }, [userInfo]);

  if (error || liveChannelsError)
    return <ErrorMessage error={error ?? liveChannelsError} />;

  return (
    <>
      <PageTitle title={t('hub')} />

      <Hub
        loading={loading || liveChannelsLoading}
        t={t}
        seasonData={seasonData}
        seasonLeaders={seasonLeaders}
        latestBooster={latestBooster}
        liveChannels={liveChannels}
        questProgress={questProgress}
        myViewer={userInfo}
        todayCollectedStreamer={todayCollectedStreamer}
        refetch={refetch}
        favoriteStreamer={favoriteStreamer}
        collectCoinsInfo={collectCoinsInfo}
        collectCoins={collectCoins}
        collectLoading={collectLoading}
      />
    </>
  );
};

export default HubContainer;