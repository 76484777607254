import {
  RESET_COLLECTORS_VIEW_DATA,
  SET_COLLECTORS_VIEW_ALL_CARDS,
  SET_COLLECTORS_VIEW_BOOSTER,
  SET_COLLECTORS_VIEW_CARD,
  SET_COLLECTORS_VIEW_MARKETPLACE,
  SET_COLLECTORS_VIEW_SELECT_SELL_INFO,
  SET_COLLECTORS_VIEW_SHOW_CARD_INFO,
} from './constants';

const INITIAL_STATE = {
  booster: null,
  streamer: null,
  card: null,
  marketplace: null,
  showAll: false,
  showCardInfo: null,
  selectSellInfo: null,
};

const collectorsViewReducer = (state = INITIAL_STATE, action) => {
  if (action.type === SET_COLLECTORS_VIEW_BOOSTER) {
    return { ...state, booster: action.booster, streamer: action.streamer };
  } else if (action.type === SET_COLLECTORS_VIEW_CARD) {
    return { ...state, card: action.card };
  } else if (action.type === SET_COLLECTORS_VIEW_MARKETPLACE) {
    return { ...state, marketplace: action.marketplace };
  } else if (action.type === SET_COLLECTORS_VIEW_ALL_CARDS) {
    return { ...state, showAll: action.isShown };
  } else if (action.type === SET_COLLECTORS_VIEW_SHOW_CARD_INFO) {
    return { ...state, showCardInfo: action.cardInfo };
  } else if (action.type === SET_COLLECTORS_VIEW_SELECT_SELL_INFO) {
    return { ...state, selectSellInfo: action.selectSellInfo };
  } else if (action.type === RESET_COLLECTORS_VIEW_DATA) {
    return { ...INITIAL_STATE };
  } else {
    return state;
  }
};

export default collectorsViewReducer;