import React from 'react';
import Typography from '@material-ui/core/Typography';

const OnBoardingStepHeader = (props) => {
  const { header, body, classes } = props;

  return (
    <>
      <Typography variant="h1" className={classes.header}>
        {header}
      </Typography>

      <Typography variant="body1" className={classes.text}>
        {body}
      </Typography>
    </>
  );
};

export default OnBoardingStepHeader;