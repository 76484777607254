import React from 'react';
import { Avatar, makeStyles } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';

import { giveFreeEnergy } from '../../apollo/queries';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import { multiplyEnergyCoins } from '../../utils/helper';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import Loading from '../Loading/Loading';

import conf from '../../conf';

const useStyles = makeStyles(() => ({
  itemArea: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    padding: 16,
    width: 492,
    borderRadius: 6,
    background: 'rgba(255, 255, 255, 0.04)',
    color: Colors.white,
    marginTop: 4,
  },
  avatar: {
    width: 30,
    height: 30,
    border: '1px solid #F306FC',
    marginRight: 6,
  },
}));

const StartOnBoardingEnergyGifts = () => {
  const classes = useStyles();

  const { data, loading } = useQuery(giveFreeEnergy, {
    fetchPolicy: useCacheWithExpiration('giveFreeEnergy'),
  });

  const streamers = data && data.giveFreeEnergy;

  return (
    <div>
      {loading ?
        <div style={{ ...Helpers.fillColCenter }}>
          <Loading />
        </div>
        :
        streamers && streamers.map(streamer => (
          <div className={classes.itemArea} key={`FreeGiftStreamer${streamer._id}`}>
            <div style={{ ...Helpers.fillRowCenter }}>
              <Avatar
                className={classes.avatar}
                alt={streamer.display_name}
                src={streamer.profile_image_url}
              />
              <div>{streamer.display_name}</div>
            </div>

            <div>
              + {multiplyEnergyCoins(36)}

              <img
                src={`${conf.cdn_url}/assets/template/heroMaker/energy.png?height=18`} alt="Energy"
                style={{ marginLeft: 4, verticalAlign: 'text-top' }} />
            </div>
          </div>
        ))
      }
    </div>
  );
};

export default StartOnBoardingEnergyGifts;