import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getLevelWord } from '../../utils/helper';
import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import Loading from '../Loading/Loading';
import InventoryBoosterCardsListSlider from './InventoryBoosterCardsListSlider';
import InventoryBoosterCardsInfo from './InventoryBoosterCardsInfo';
import GemUnlockDialog from './GemUnlockDialog';
import StreamerInventoryHeader from './StreamerInventoryHeader';

const useStyles = makeStyles(() => ({
  menu: {
    background: 'rgba(255, 255, 255, 0.04)',
    width: 'calc(100% - 24px)',
    padding: '0 12px',
    ...Helpers.fillRowCross,
  },
  menuItem: {
    padding: 12,
    color: Colors.halfWhite,
    transition: 'all 0.3s ease-out',
    cursor: 'pointer',
    borderBottom: '1px solid transparent',
    fontSize: 14,

    '&:hover': {
      color: Colors.white,
    },
    '&.active': {
      color: Colors.white,
      borderBottomColor: Colors.white,
    },
  },
}));

const InventoryBoosterCardsList = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    collectorsView,
    cardCollectionProgress,
    loading,
    booster,
    myViewer,
    boosterDonations,
  } = props;
  let { streamer } = props;
  const [isOpenGemsUnlock, setIsOpenGemsUnlock] = useState(null);
  const { card } = cardCollectionProgress || { card: null };
  const cardLevel = card ? card.rarity : 0;
  const [cardList, setCardList] = useState('all');
  const { showCardInfo } = collectorsView;

  const levelWord = getLevelWord(null, cardLevel);
  const levelColor = Colors.level[levelWord];

  const cardListStyles = ['all', 'owned', 'missing'];

  if (!streamer || streamer.__typename === 'GenericMatch')
    streamer = {
      _id: '6202877609cbfc52b858fc3c',
      login: 'streamheroes',
      display_name: 'Streamheroes',
      profile_image_url: 'https://static-cdn.jtvnw.net/jtv_user_pictures/fa77cfe3-b7dd-443f-8ba8-594c93740805-profile_image-300x300.png',
    };

  return (
    <>
      <div style={{ color: Colors.white, position: 'relative', overflow: 'hidden' }}>
        <StreamerInventoryHeader
          t={t}
          streamer={streamer}
          myViewer={myViewer}
          booster={booster}
          card={card}
        />

        <div className="inner-wrapper" style={{ margin: '32px auto' }}>

          {loading ?
            <div style={{ position: 'absolute', left: '50%', top: '50%' }}>
              <Loading />
            </div>
            :
            <div style={{ ...Helpers.fillRow, marginTop: 3 }}>
              <div
                style={{ width: showCardInfo ? '50%' : '100%', transition: 'all 0.3s ease-out' }}
              >
                <div className={classes.menu}>
                  {cardListStyles.map(cardListStyle => (
                    <div
                      className={[classes.menuItem, cardList === cardListStyle ? 'active' : ''].join(' ')}
                      key={`MenuItemStyle${cardListStyle}`}
                      onClick={() => setCardList(cardListStyle)}
                    >
                      {t(`${cardListStyle}_cards`)}
                    </div>
                  ))}
                </div>

                <div style={{ ...Helpers.fillRowCenter }}>
                  <InventoryBoosterCardsListSlider
                    cardCollectionProgress={cardCollectionProgress}
                    cardListStyle={cardList}
                    card={card}
                    showCardInfo={showCardInfo}
                    setIsOpenGemsUnlock={setIsOpenGemsUnlock}
                  />
                </div>
              </div>
              <InventoryBoosterCardsInfo
                streamer={streamer}
                booster={booster}
                boosterDonations={boosterDonations}
                showCardInfo={showCardInfo}
                setIsOpenGemsUnlock={setIsOpenGemsUnlock}
              />
            </div>
          }
        </div>
      </div>
      {isOpenGemsUnlock &&
      <GemUnlockDialog
        t={t}
        streamer={streamer}
        cardCollectionProgress={isOpenGemsUnlock}
        onClose={() => setIsOpenGemsUnlock(null)}
        myViewer={myViewer}
        booster={booster}
        cardId={card._id}
      />
      }
    </>
  );
};

const mapStateToProps = (state) => {
  const { collectorsView } = state;
  return { collectorsView };
};

export default connect(mapStateToProps)(InventoryBoosterCardsList);