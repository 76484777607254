import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';

import { getBoostersToApprove } from '../../apollo/queries';
import { approveOrDenyBooster as approveOrDenyBoosterMutation } from '../../apollo/mutations';

import DenyCardsetDialog from './DenyCardsetDialog';
import Helpers from '../../theme/Helpers';

import conf from '../../conf.json';

const ActionButtons = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { streamer, cards, booster } = props;
  const [open, setOpen] = useState(false);

  const makeAlert = (msg, type) => {
    enqueueSnackbar(msg, { variant: type });
  };

  const openDenyDialog = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [approveOrDenyBooster] = useMutation(approveOrDenyBoosterMutation, {
    onCompleted: (data) => {
      const { success, error_code } = data.approveOrDenyBooster;

      if (success)
        makeAlert(t('review_successful'), 'success');
      else {
        let errorMsg = '';
        if (error_code === 1)
          errorMsg = 'to_less_special_cards';
        else if (error_code === 2)
          errorMsg = 'level_and_special_mixed';
        else if (error_code === 3)
          errorMsg = 'not_all_rarities';
        else if (error_code === 4)
          errorMsg = 'rarities_not_same';

        makeAlert(t(`error_msg.${errorMsg}`), 'error');
      }
    },
    onError: (data) => {
      makeAlert(data.message, 'error');
    },
    refetchQueries: () => [{ query: getBoostersToApprove }],
  });

  const approveCardSet = () => {
    approveOrDenyBooster({ variables: { id: booster._id, approve: true } });
  };

  const viewUser = () => {
    window.open(`${conf.base_url_PROD}/profile/${streamer.login}`, '_blank');
  };

  const denyCardset = (rejectReason) => {
    approveOrDenyBooster({
      variables: {
        id: booster._id,
        approve: false,
        rejectReason,
      },
    });
  };

  return (
    <div style={Helpers.fillRowCenter}>
      <Button
        variant="contained"
        color="primary"
        onClick={approveCardSet}
        style={{ marginRight: 15 }}
      >
        {t('accept_card_set')}
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={openDenyDialog}
        style={{ marginRight: 15 }}
      >
        {t('deny_cardset')}
      </Button>
      <Button
        variant="contained"
        onClick={viewUser}
      >
        {t('review_view_profile')}
      </Button>
      {cards &&
      <DenyCardsetDialog open={open} onClose={handleClose} onSubmit={denyCardset} cards={cards} />
      }
    </div>
  );
};

export default withRouter(ActionButtons);