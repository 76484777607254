import moment from 'moment';

export class ChatController {
  constructor (options) {
    this.defaultOptions = {
      delay: 300,
    };
    this.emptyAction = {
      request: { type: 'empty' },
      responses: [],
      onResponsed: [],
    };
    this.state = {
      options: { ...this.defaultOptions, ...options },
      messages: [],
      action: this.emptyAction,
      actionHistory: [],
      onMessagesChanged: [],
      onActionChanged: [],
    };
    this.defaultActionRequest = {
      always: false,
      addMessage: true,
    };
  }

  addMessages (messages) {
    return new Promise((resolve) => {
      this.state.messages = this.state.messages.concat(messages);
      this.callOnMessagesChanged();
      resolve(true);
    });
  }

  addMessage (message) {
    return new Promise((resolve) => {
      setTimeout(() => {
        message.createdAt = moment();
        this.state.messages.push(message);
        this.callOnMessagesChanged();

        resolve(message);
      }, this.state.options.delay);
    });
  }

  updateMessage (index, message) {
    if (message !== this.state.messages[index]) {
      const { createdAt } = this.state.messages[index];
      this.state.messages[index] = message;
      this.state.messages[index].createdAt = createdAt;
    }

    this.state.messages[index].updatedAt = moment();
    this.callOnMessagesChanged();
  }

  removeMessage (index) {
    this.state.messages[index].deletedAt = moment();
    this.callOnMessagesChanged();
  }

  getMessages () {
    return this.state.messages;
  }

  setMessages (messages) {
    this.clearMessages();
    this.state.messages = [...messages];
    this.callOnMessagesChanged();
  }

  clearMessages () {
    this.state.messages = [];
    this.callOnMessagesChanged();
  }

  callOnMessagesChanged () {
    this.state.onMessagesChanged.map((h) => h(this.state.messages));
  }

  addOnMessagesChanged (callback) {
    this.state.onMessagesChanged.push(callback);
  }

  removeOnMessagesChanged (callback) {
    const idx = this.state.onMessagesChanged.indexOf(callback);
    this.state.onActionChanged[idx] = () => {};
  }

  setActionRequest (request, onResponse) {
    const action = {
      request: { ...this.defaultActionRequest, ...request },
      responses: [],
      onResponsed: [],
    };

    // See setActionResponse method
    return new Promise((resolve, reject) => {
      if (!request.always) {
        const returnResponse = (response) => {
          if (!response.error)
            resolve(response);
          else
            reject(response.error);
        };
        action.onResponsed.push(returnResponse);
      }

      if (onResponse)
        action.onResponsed.push(onResponse);

      this.state.action = action;
      this.state.actionHistory.push(action);
      this.callOnActionChanged(action.request);

      if (request.always)
        resolve({ type: 'text', value: 'dummy' });
    });
  }

  cancelActionRequest () {
    this.state.action = this.emptyAction;
    this.callOnActionChanged(this.emptyAction.request);
  }

  getActionRequest () {
    const { request, responses } = this.state.action;
    if (!request.always && responses.length > 0)
      return undefined;

    return request;
  }

  async setActionResponse (request, response) {
    const { request: origReq, responses, onResponsed } = this.state.action;
    if (request !== origReq)
      throw new Error('Invalid action.');
    if (!request.always && onResponsed.length === 0)
      throw new Error('onResponsed is not set.');

    responses.push(response);
    this.callOnActionChanged(request, response);

    if (request.addMessage)
      await this.addMessage({
        type: 'text',
        content: response.value,
        self: true,
      });

    onResponsed.map((h) => h(response));
  }

  getActionResponses () {
    return this.state.action.responses;
  }

  callOnActionChanged (request, response) {
    this.state.onActionChanged.map((h) => h(request, response));
  }

  addOnActionChanged (callback) {
    this.state.onActionChanged.push(callback);
  }

  removeOnActionChanged (callback) {
    const idx = this.state.onActionChanged.indexOf(callback);
    this.state.onActionChanged[idx] = () => {};
  }
}