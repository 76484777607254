import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import List from '@material-ui/core/List';

import LiveStreamsByGameItem from './LiveStreamsByGameItem';
import Loading from '../Loading/Loading';
import Helpers from '../../theme/Helpers';
import LiveStreamsByGameItemWithCards from './LiveStreamsByGameItemWithCards';
import { returnMobileObj } from '../../utils/responsive';

const LiveStreamsByGame = (props) => {
  const { streams, onLoadMore, hasMore, isTabletOrMobile } = props;

  if (!streams)
    return null;

  return (
    <InfiniteScroll
      dataLength={streams.length}
      next={onLoadMore}
      hasMore={hasMore}
      loader={<Loading />}
    >
      <List
        style={{ ...Helpers.fillRowMain, ...Helpers.mainSpaceBetween, ...returnMobileObj(isTabletOrMobile, { zoom: 0.8 }) }}
      >
        {streams.map((item) =>
          item.cards && item.cards.length > 0 ?
            <LiveStreamsByGameItemWithCards key={item._id} stream={item} />
            :
            <LiveStreamsByGameItem key={item._id} stream={item} />,
        )}
      </List>
    </InfiniteScroll>
  );
};

export default LiveStreamsByGame;