import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import Loading from '../Loading/Loading';
import SpecialRedeemButton from '../Button/SpecialRedeemButton';

const useStyles = makeStyles(() => ({
  progressBar: {
    width: 200,
    margin: '0 auto',
    textAlign: 'center',
  },
}));

/**
 * @return {null}
 */
function CardRedeemProgress(props) {
  const classes = useStyles();

  const { card, redeemCard, coinsInformation, loading } = props;
  let { match_card, matchCardModel } = card;

  if (!coinsInformation || !coinsInformation.viewerRedeemedPoints)
    return null;
  const { viewerRedeemedPoints } = coinsInformation;

  const totalRedeemedPoints = viewerRedeemedPoints.points_season - viewerRedeemedPoints.redeemed_points;

  if (matchCardModel === undefined)
    matchCardModel = match_card.__typename;
  const costs = matchCardModel === 'Card' ? 6 : match_card.costs;

  const isAvailable = totalRedeemedPoints >= costs;

  return (
    ((match_card.level && match_card.level === 1 && matchCardModel === 'Card') || matchCardModel === 'SpecialCard') &&
    <div className={[classes.progressBar, 'center-button'].join(' ')}>
      {(loading) ?
        <Loading style={{ marginTop: -20, width: 25, height: 30 }} />
        :
        <div style={{ marginTop: -20 }}>
          <SpecialRedeemButton onClick={redeemCard} energy={totalRedeemedPoints} costs={costs}
                        disabled={!isAvailable} />
        </div>
      }
    </div>
  );
}

CardRedeemProgress.propTypes = {
  card: PropTypes.object.isRequired,
  redeemCard: PropTypes.func.isRequired,
};

export default CardRedeemProgress;