import React, { useState } from 'react';

import GroupHeadLine from '../Layout/GroupHeadLine';
import ItemStoreBoosterPacks from './ItemStoreBoosterPacks';
import Loading from '../Loading/Loading';

const ItemStoreBoostersGroup = (props) => {
  const {
    boosters,
    t,
    withCount,
    headText,
    loading,
    myViewer,
  } = props;
  const [isActive, setIsActive] = useState(true);

  return (
    <>
      <GroupHeadLine
        t={t}
        withCount={withCount}
        count={boosters && boosters.length}
        text={headText}
        setIsActive={setIsActive}
        isActive={isActive}
        tooltipText="booster_explanation"
      />

      {loading ?
        <Loading />
        :
        <ItemStoreBoosterPacks
          t={t}
          boosters={boosters}
          myViewer={myViewer}
        />
      }
    </>
  );
};

export default ItemStoreBoostersGroup;