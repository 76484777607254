import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import makeStyles from '@material-ui/core/styles/makeStyles';

import PremiumBuyDialog from '../../components/Premium/PremiumBuyDialog';
import { isTabletOrMobileObj } from '../../utils/responsive';
import EmptyPopup from '../../components/Dialog/EmptyPopup';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import { useQuery } from '@apollo/react-hooks';
import { getShippingInformation } from '../../apollo/queries';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import PagePopup from '../../components/Dialog/PagePopup';
import ClosePopupButton from '../../components/Button/ClosePopupButton';

const useStyles = makeStyles(() => ({
  container: {
    background: '#142134',
    maxWidth: 798,
    margin: '50vh auto 0',
    transform: 'translate(0, -50%)',
    position: 'relative',
    color: Colors.white,
    borderRadius: 4,
  },
  tabArea: {
    ...Helpers.fillRow,
    backgroundColor: '#010814',
    color: '#41537B',
    padding: '0 16px',
    fontSize: 18,
    fontFamily: 'Bebas Neue',
    '& > div': {
      padding: 16,
      cursor: 'pointer',
      '&:hover': {
        color: Colors.white,
      },
    },
  },
  activeTab: {
    color: '#FBFA7F',
    textShadow: '0px 0px 12px #FF8900',
    borderBottom: '1px solid #B60B28',
  },
}));

const PremiumBuyDialogContainer = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { handleClose, status } = props;
  const [tab, setTab] = useState(status ? 'address_input' : 'select_package');

  const isTabletOrMobile = useMediaQuery(isTabletOrMobileObj);

  const { data } = useQuery(getShippingInformation, {
    fetchPolicy: useCacheWithExpiration('getShippingInformation'),
  });

  const countryCosts = data && data.getShippingInformation;

  const tabs = [
    {
      key: 'select_package',
      value: 'premium_select_package',
    },
    {
      key: 'address_input',
      value: 'premium_address_input',
    },
    {
      key: 'payment_method',
      value: 'premium_payment_method',
    },
  ];

  const nextTab = () => {
    const tabIdx = tabs.findIndex(tabItem => tabItem.key === tab);

    setTab(tabs[tabIdx + 1].key);
  };

  const backTab = () => {
    const tabIdx = tabs.findIndex(tabItem => tabItem.key === tab);

    setTab(tabs[tabIdx - 1].key);
  };
  const activeIdx = tabs.findIndex(tabItem => tabItem.key === tab);

  return (
    <PagePopup handleClose={() => tab !== 'address_input' ? handleClose() : null}>

      <div className={classes.container} style={{ width: isTabletOrMobile ? '90%' : 798 }}>
        <ClosePopupButton handleClose={handleClose} style={{ right: 8, top: 8 }} />
        <div className={classes.tabArea}>
          {tabs.map((item, index) => (
            <div
              key={item.key}
              onClick={() => index <= activeIdx ? setTab(item.key) : null}
              className={item.key === tab ? classes.activeTab : ''}
            >
              {t(item.value)}
            </div>
          ))}
        </div>
        <PremiumBuyDialog
          status={status}
          nextTab={nextTab}
          backTab={backTab}
          tab={tab}
          isTabletOrMobile={isTabletOrMobile}
          countryCosts={countryCosts}
        />
      </div>
    </PagePopup>
  );
};

export default PremiumBuyDialogContainer;