import React from 'react';
import { StickyContainer } from 'react-sticky';

import Colors from '../../theme/Colors';
import InventoryCards from './InventoryCards';
import Loading from '../Loading/Loading';
import CardShopMatchFilters from '../CardShop/CardShopMatchFilters';

const Inventory = (props) => {
  const {
    cards,
    hasUserLegacyCards,
    t,
    loading,
    onLoadMore,
    totalCount,
    quality, setQuality,
    rareness, setRareness,
    isLegacy, setIsLegacy,
    sorting, setSorting,
    cardDecks,
  } = props;

  return (
    <StickyContainer style={{ color: Colors.white, position: 'relative' }}>
        <CardShopMatchFilters
          setIsLegacy={setIsLegacy}
          isLegacy={hasUserLegacyCards ? isLegacy : undefined}
          setSorting={setSorting}
          sorting={sorting}
          setRarity={setRareness}
          rarity={rareness}
          setQuality={setQuality}
          quality={quality}
        />

        <div className="inner-wrapper" style={{ margin: '39px auto' }}>
          {loading ?
            <Loading />
            :
            <InventoryCards
              totalCount={totalCount}
              onLoadMore={onLoadMore}
              cards={cards}
              isLegacy={isLegacy}
              cardDecks={cardDecks}
              t={t}
            />
          }
        </div>

    </StickyContainer>
  );
};

export default Inventory;