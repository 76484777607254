import React from 'react';

import Helpers from '../../theme/Helpers';

const InventoryBoosterCardPlaceholder = (props) => {
  const { height, width } = props;

  return (
    <div style={{
      width,
      height,
      borderRadius: 6,
      background: 'rgba(255, 255, 255, 0.02)',
      ...Helpers.fillRowCenter,
    }}>
      <i
        className="fa-light fa-circle-question"
        style={{ fontSize: 38 }}
      />
    </div>
  );
};

export default InventoryBoosterCardPlaceholder;