import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';

import { getBoostersToApprove } from '../../apollo/queries';

import Loading from '../../components/Loading/Loading';
import Helpers from '../../theme/Helpers';
import { getRandomNumber } from '../../utils/helper';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import ReviewBooster from '../../components/ReviewCards/ReviewBooster';

const useStyles = makeStyles(() => ({
  container: {
    ...Helpers.boxBasics,
    minWidth: 1400,
  },
  noCards: {
    padding: 50,
    textAlign: 'center',
    fontSize: 24,
  },
}));

const ReviewBoosterContainer = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const randomNumber = getRandomNumber();

  const { data, loading } = useQuery(getBoostersToApprove, {
    fetchPolicy: useCacheWithExpiration('getBoostersToApprove'),
  });

  const boosterToApprove = (data) && data.getBoostersToApprove;

  return (
    <div className={classes.container}>
      {loading ?
        <Loading />
        :
        boosterToApprove && boosterToApprove.length > 0 ?
          <ReviewBooster booster={boosterToApprove} randomNumber={randomNumber} />
          :
          <Typography
            variant="h6"
            color="secondary"
            gutterBottom
            className={classes.noCards}
          >
            {t('no_cards_to_review')}
          </Typography>
      }
    </div>
  );
};

export default ReviewBoosterContainer;