import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import MiniCountdown from '../Countdown/MiniCountdown';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';
import DropPopup from './DropPopup';
import DropRedeemButton from './DropRedeemButton';

const useStyles = makeStyles({
  container: {
    cursor: 'pointer',
    '&:hover': {
      '& $rowBelow': {
        opacity: 1,
        marginTop: 5,
        marginBottom: 10,
      },
      '& $itemArea': {
        marginTop: -25,
      },
      '& $tooltipArea': {
        display: 'initial',
      },
    },
  },
  itemArea: {
    ...Helpers.fillColCenter,
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: '6px',
    transition: 'all 0.3s ease-out',
    padding: 12,
    width: 250,
  },
  designBody: {
    ...Helpers.fillColCenter,
    position: 'relative',
    maxWidth: '250px',
    margin: '22px 0 12px',
  },
  designFooter: {
    padding: 18,
    height: 42,
    ...Helpers.fillColCenter,
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 4,
    width: 'calc(100% - 36px)',
    '& > div:first-child': {
      color: Colors.halfWhite,
      fontSize: 12,
    },
  },
  header: {
    ...Helpers.fillColCenter,
    width: '100%',
    alignSelf: 'baseline',
    position: 'relative',
    marginTop: 10,
  },
  streamerName: {
    letterSpacing: '0px',
    fontWeight: 500,
  },
  collected: {
    fontSize: 12,
    color: Colors.halfWhite,
    marginTop: 2,
  },
  buttons: {
    height: 58,
    display: 'grid',
    gap: 6,
    gridTemplateColumns: 'repeat(4, 1fr)',
    margin: '12px',
    '& i': {
      fontSize: '16px',
    },
  },
  rowBelow: {
    ...Helpers.fillRowCross,
    marginTop: -41,
    marginBottom: 30,
    opacity: 0,
    transition: 'all 0.3s ease-out',
  },
  countdownArea: {
    marginTop: 6,
    '& > div': {
      ...Helpers.mainStart,
    },
    '& div': {
      fontFamily: 'Montserrat',
      textTransform: 'inherit',
      color: '#FFFFFF80',
      '& > span': {
        color: Colors.white,
      },
    },
  },
  tooltipContainer: {
    padding: '16px 16px 12px',
  },
  inspectDetails: {
    marginTop: 8,
    width: '100%',
    fontSize: 12,
    fontWeight: 300,
    ...Helpers.fillRowCenter,
  },
  switchLeftRight: {
    left: 'initial !important',
    right: 'calc(100% - 20px)',
  },
  tooltipArea: {
    display: 'none',
    position: 'absolute',
    left: 'calc(100% - 20px)',
    top: '55px',
    borderRadius: 6,
    width: 396,
    background: 'rgba(255, 255, 255, 0.08)',
    zIndex: 100,
    overflow: 'visible',
  },
  midAvatar: {
    width: 44,
    height: 44,
    border: 'none',
    marginRight: 10,
  },
  sponsoredArea: {
    ...Helpers.fillRowCross,
    background: 'rgba(255, 255, 255, 0.04)',
    padding: 10,
  },
  tooltipDescription: {
    color: Colors.halfWhite,
    marginTop: 12,
    fontSize: 14,
    marginBottom: 18,
    '& > span': {
      color: Colors.white,
    },
  },
});

const Drop = (props) => {
  const classes = useStyles();
  const { drop, now, switchLastHover, t } = props;

  const [showPopup, setShowPopup] = useState(false);

  const collected = `Collected ${moment
  .unix(drop.created_at / 1000)
  .format('DD.MM.YYYY')}`;
  const endTime = moment(parseInt(drop.redeemable_until));

  const isExpired = !endTime.isAfter(now);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.itemArea} onClick={() => setShowPopup(true)}>
          <div className={classes.header}>
            <div className={classes.streamerName}>
              {drop.droppool.type === 'FreeShipping' ?
                t('free_shipping_code')
                :
                <>
                  {`${drop.droppool.value}% ${t('coupon_code')}`}
                </>
              }
            </div>
            <div className={classes.collected}>{collected}</div>
          </div>
          <div className={classes.designBody}>
            <img src={`${drop.droppool.image}?height=292`} style={{ borderRadius: 4 }} alt="Drop" />

            <CustomBackdropFilter
              className={[classes.tooltipArea, switchLastHover ? classes.switchLeftRight : ''].join(' ')}
              filter={'blur(50px)'}
              canvasFallback={true}
              html2canvasOpts={{
                allowTaint: true,
              }}
            >
              <div className={classes.tooltipContainer}>
                <div style={{ fontSize: 18, fontWeight: 600 }}>
                  {t(drop.droppool.type === 'FreeShipping' ? 'free_shipping_code' : 'coupon_code')}
                </div>
                <div
                  className={classes.tooltipDescription}
                  dangerouslySetInnerHTML={{
                    __html: t(drop.droppool.type === 'FreeShipping' ?
                      'free_shipping_code_description' :
                      'coupon_code_description', { minAmount: drop.droppool.min_order_amount / 100 }),
                  }}
                />

                <div className={classes.sponsoredArea}>
                  <Avatar
                    className={classes.midAvatar}
                    alt="Avatar"
                    src="https://static-cdn.jtvnw.net/jtv_user_pictures/fa77cfe3-b7dd-443f-8ba8-594c93740805-profile_image-300x300.png"
                  />
                  <div>
                    <div style={{
                      fontSize: 10,
                      fontWeight: 300,
                      color: Colors.halfWhite,
                    }}>
                      {t('sponsored_by')}
                    </div>
                    <div className={classes.streamerName}>
                      Streamheroes
                    </div>
                  </div>
                </div>

                <div className={classes.inspectDetails}>
                  {t('inspect_coupon')}
                </div>
              </div>
            </CustomBackdropFilter>

          </div>
          <div className={classes.designFooter}>
            <div>{t('expires_in')}</div>
            {!isExpired ?
              <div className={classes.countdownArea}>
                <MiniCountdown endMoment={endTime} showMore now={now} />
              </div>
              :
              <div style={{ color: '#E12138', marginTop: 8, fontWeight: 500 }}>{t('expired')}</div>
            }
          </div>
        </div>
        <div className={classes.rowBelow}>
          <DropRedeemButton isExppired={isExpired} t={t} drop={drop} />
        </div>
      </div>
      {showPopup && (
        <DropPopup
          t={t}
          onClose={() => setShowPopup(false)}
          drop={drop}
          now={now}
          isExpired={isExpired}
          endTime={endTime}
        />
      )}
    </>
  );
};

export default Drop;
