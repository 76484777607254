import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import AnimationButton from '../Button/AnimationButton';
import NumberInput from '../Inputs/NumberInput';

const ItemStoreBoosterPackRedeem = (props) => {
  const {
    t,
    booster,
    makeAction,
    boosterAction,
    loading,
    quantity, quantityChange,
    eventIsRunning,
    now,
    isEvent,
    isInactive,
  } = props;
  const { lastRedeem } = boosterAction;
  const [counter, setCounter] = useState(null);

  useEffect(() => {
    if (lastRedeem && lastRedeem >= now.unix() && counter === null)
      setCounter(3);
  }, [lastRedeem]);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const isEnergy = !!booster.energy_costs;
  const maxAvailable = Math.floor((isEnergy ? booster.user_energy / booster.energy_costs : booster.user_coins / booster.coin_costs));

  return (
    <>
      <NumberInput
        value={quantity}
        max={maxAvailable > 50 ? 50 : maxAvailable}
        onChange={quantityChange}
      />

      <AnimationButton
        status={isEnergy ? 'neonPurple' : 'gold'}
        onClick={loading ? null : makeAction}
        disabled={isEvent && !eventIsRunning && counter}
        style={{
          fontFamily: 'Montserrat',
          fontSize: 14,
          width: '100%',
          padding: 14,
        }}
      >
        {(loading || counter) ?
          <>
            <i className="fa-light fa-loader fa-spin"
               style={{ fontSize: 18, marginRight: counter ? 10 : 0 }} />
            {counter ? t('x_seconds', { count: counter }) : ''}
          </>
          :
          <>
            {!isInactive &&
            <i
              className="fa-light fa-plus"
              style={{ fontSize: 18, marginRight: 10, verticalAlign: 'bottom' }}
            />
            }
            {t(isInactive ? (isEnergy ? 'collect_energy' : 'collect_coins') : 'redeem')}
          </>
        }
      </AnimationButton>
    </>
  );
};

const mapStateToProps = (state) => {
  const { boosterAction } = state;
  return { boosterAction };
};

export default connect(mapStateToProps)(ItemStoreBoosterPackRedeem);