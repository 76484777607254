import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';

import { getStreamer } from '../../apollo/queries';

import Helpers from '../../theme/Helpers';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';
import Colors from '../../theme/Colors';
import NotificationsContainer from '../../containers/Notifications/Notifications';
import HeaderSearchContainer from '../../containers/Header/HeaderSearch';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import { isUserPremium } from '../../utils/helper';
import Symplr from '../AdSense/Symplr';

import conf from '../../conf.json';

const menuConf = require('../../menu');

const useStyles = makeStyles(() => ({
  root: {
    width: 242,
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: -181,
    color: Colors.white,
    transition: 'all 0.3s ease-out',
    overflow: 'initial',
    borderLeft: '3px solid #011024',
    borderRight: '3px solid #011024',
    background: 'transparent',
  },
  expandedRoot: {
    left: 64,
    background: 'rgba(255, 255, 255, 0.04)',

    '&.menuExpanded': {
      left: 170,
    },
  },
  menuItem: {
    padding: '10px 14px',
    borderRadius: 5,
    fontSize: 14,
    display: 'flex',
    marginTop: 4,
    fontWeight: 400,
    color: Colors.white,
    width: 'calc(100% - 28px)',
    cursor: 'pointer',
    transition: 'all 0.3s ease-out',
    textDecoration: 'none',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.04)',
    },
    '&.active': {
      color: '#E12138',
      background: 'rgb(225, 33, 56, 0.16)',
      fontWeight: 500,
    },
  },
}));

const Sidebar = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { location, extra, streamer, roles, viewer } = props;

  const { isPremiumActive } = isUserPremium(viewer);
  const isMenuExpanded = extra.expandedMenu;
  const isSearchActive = extra.showSearch;
  const areNotificationsActive = extra.showNotifications;

  const urlParts = location.pathname.split('/');

  // query only on profile url
  const { data } = useQuery(getStreamer, {
    variables: { login: urlParts[2] },
    fetchPolicy: useCacheWithExpiration('getHeaderData'),
    skip: urlParts.length < 3 || urlParts[1] !== 'profile',
  });

  const viewedStreamer = data && data.streamer;
  let subItem;
  if (urlParts.length > 2)
    subItem = urlParts.pop();

  const menuItem = menuConf[urlParts[1]];

  if (!menuItem || (menuItem && !menuItem.submenu))
    return null;

  const isAdmin = roles && roles.includes('admin');
  const isMarketing = roles && !roles.includes('marketing');

  return (
    <CustomBackdropFilter
      className={[classes.root, classes.expandedRoot, isMenuExpanded ? 'menuExpanded' : ''].join(' ')}
      filter={'blur(50px)'}
      canvasFallback={true}
      html2canvasOpts={{
        allowTaint: true,
      }}
    >
      {(menuItem || isSearchActive) &&
      <div style={{ padding: '19px 14px' }}>
        <div style={{ fontSize: 20, fontWeight: 600, paddingBottom: 19 }}>
          {t(isSearchActive ? 'search' : (areNotificationsActive ? 'notifications' : menuItem.name))}
        </div>

        {areNotificationsActive &&
        <NotificationsContainer />
        }

        {isSearchActive &&
        <HeaderSearchContainer />
        }

        {!isSearchActive && !areNotificationsActive && menuItem.submenu && menuItem.submenu.map((submenu, index) => {
          if (submenu.isAdmin && !isAdmin)
            return null;
          if (submenu.isMarketing && (!isMarketing && !isAdmin))
            return null;

          return (
            <div
              style={{ paddingTop: index === 0 ? 10 : 32, fontWeight: 600, ...Helpers.fillCol }}
              key={`SubMenuKey${submenu.name}`}
            >
              <div style={{
                fontSize: 12,
                color: '#828794',
                textTransform: 'uppercase',
                paddingBottom: 6,
              }}>
                {submenu.name_is_login_name && urlParts.length > 2 ? urlParts[2] : t(submenu.name)}
              </div>

              {submenu.items.map(item => {
                // hide if the user has no streamer account
                if (item.only_streamer && !viewedStreamer)
                  return null;
                if (item.matches && viewedStreamer && !item.matches.includes(viewedStreamer.login))
                  return null;
                // hide if the logged in user has no streamer account
                if (item.is_streamer && !streamer)
                  return null;

                return (
                  <Link
                    className={[classes.menuItem, subItem && subItem === item.key ? 'active' : ''].join(' ')}
                    to={`${(menuItem.no_same_url && urlParts.length > 2 ? [...urlParts].slice(0, 2) : urlParts).join('/')}/${item.key}`}
                    key={`LinkItemKey${item.key}`}
                  >
                    {t(item.name)}

                    {(item.badge) &&
                    <div style={{
                      display: 'inline-block',
                      background: '#e12138',
                      borderRadius: 4,
                      fontSize: 12,
                      padding: '1px 5px',
                      marginLeft: 'auto',
                      color: 'white',
                    }}
                    >
                      {t(item.badge)}
                    </div>
                    }
                  </Link>
                );

              })}
            </div>
          );
        })}
      </div>
      }

      {(conf.ad_area.sidebar && viewer && !isPremiumActive) &&
      <div style={{ ...Helpers.fillColCenter, marginTop: isSearchActive ? 200 : -5 }}>
        <Symplr id="streamheroes.gg_sky_1" />
      </div>
      }
    </CustomBackdropFilter>
  );
};

const mapStateToProps = (state) => {
  const { extra } = state;
  return { extra };
};

export default connect(mapStateToProps)(withRouter(Sidebar));