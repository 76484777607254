import React from 'react';
import { Helmet } from 'react-helmet';

function CardsOpenGraphHeader(props) {
  const { streamer, organisation, cards } = props;
  let openGraphHeader = null;

  if (cards && cards.length > 2 && (streamer || organisation)) {
    const imageName = streamer ? `streamer_${streamer._id}` : `organisation_${organisation._id}`;
    const name = streamer ? (streamer.display_name ? streamer.display_name : streamer.login) : organisation.name;

    openGraphHeader = <Helmet>
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@OWN3D" />
      <meta name="twitter:image"
            content={`https://streamheroes-image-cdn.s3.amazonaws.com/social-share-images/tw_${imageName}.jpg`} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:title"
            content={`StreamHero ${name}`} />
      <meta property="og:description"
            content={`Start collecting cards of ${name} today!`} />
      <meta property="og:image"
            content={`https://streamheroes-image-cdn.s3.amazonaws.com/social-share-images/fb_${imageName}.jpg`} />
    </Helmet>;
  }

  return openGraphHeader;
}

export default CardsOpenGraphHeader;