import React, { useEffect, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';

import CreateAlertGroup from './CreateAlertGroup';
import Colors from '../../theme/Colors';
import CreateAlertSelect from './CreateAlertSelect';
import AnimationButton from '../Button/AnimationButton';
import { getLeagueImage, rangeArray } from '../../utils/helper';
import HoverIcon from '../Icon/HoverIcon';
import Helpers from '../../theme/Helpers';

import conf from '../../conf.json';

const CreateAlertCommunityRankUp = (props) => {
  const { t, alertTool, setAlertToolTypeData } = props;
  const { communityRankUp } = alertTool;
  const [selectionCount, setSelectionCount] = useState(communityRankUp && communityRankUp.length > 0 ? communityRankUp.length : 1);
  const [leagueKeys, setLeagueKeys] = useState(null);

  useEffect(() => {
    if (!leagueKeys)
      setLeagueKeys([...conf.league_keys].reverse());
  }, [leagueKeys]);

  const handleChange = (event, index) => {
    const { target } = event;

    const tmpLeagues = communityRankUp ? [...communityRankUp] : [];
    if (tmpLeagues[index])
      tmpLeagues[index] = parseInt(target.value);
    else
      tmpLeagues.push(parseInt(target.value));

    setAlertToolTypeData('communityRankUp', tmpLeagues);
  };

  const increaseSelections = () => {
    if (selectionCount < leagueKeys.length)
      setSelectionCount(selectionCount + 1);
  };

  const deleteSelection = (index) => {
    let tmpLeagues = [...communityRankUp];
    if (selectionCount <= 1) {
      tmpLeagues = null;
    } else {
      tmpLeagues.splice(index, 1);
      setSelectionCount(selectionCount - 1);
    }
    setAlertToolTypeData('communityRankUp', tmpLeagues);
  };

  const getLeagueItems = (index) => {
    if (!leagueKeys)
      return null;

    return (rangeArray(1, leagueKeys.length)
    .reverse()
    .map(value => (
      communityRankUp && communityRankUp.includes(value) && communityRankUp[index] !== value ?
        null
        :
        <MenuItem value={value} key={value}>
          <img
            src={getLeagueImage(leagueKeys[value - 1], 24)}
            alt="League badge"
            style={{ verticalAlign: 'bottom', marginRight: 3 }}
          />
          {t(`rank_${leagueKeys[value - 1]}`)}
        </MenuItem>
    )));
  };

  return (
    <CreateAlertGroup
      title={t('ranking_alert')}
      t={t}
      isRequired
      description={t('ranking_alert_description')}
    >
      {rangeArray(1, selectionCount)
      .map(idx => (
        <div style={{ ...Helpers.fillRowCross }} key={`LeagueSelectIDX${idx}`}>
          <CreateAlertSelect
            value={(communityRankUp && communityRankUp[idx - 1]) && communityRankUp[idx - 1]}
            handleChange={(event) => handleChange(event, idx - 1)}
            placeholder={t('select_rank')}
            id={`league-${idx - 1}`}
          >
            {getLeagueItems(idx - 1)}
          </CreateAlertSelect>
          <HoverIcon
            icon="fa-light fa-xmark"
            color={Colors.halfWhite}
            hoverColor={Colors.white}
            style={{ fontSize: 14, marginLeft: 14 }}
            onClick={() => deleteSelection(idx - 1)}
          />
        </div>
      ))}

      <AnimationButton
        status="transparent"
        style={{
          padding: '9px 16px',
          border: 'none',
          fontFamily: 'Montserrat',
          fontSize: 14,
          marginTop: 14,
        }}
        onClick={increaseSelections}
        disabled={leagueKeys && selectionCount >= leagueKeys.length}
      >
        <i className="fa-light fa-plus" style={{ fontSize: 16, marginRight: 10 }} />
        {t('add_rank')}
      </AnimationButton>
    </CreateAlertGroup>
  );
};

export default CreateAlertCommunityRankUp;