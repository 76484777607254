import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';
import Div100vh from 'react-div-100vh';

import { ErrorCode } from './enums/ErrorCode';
import OrientationLock from './OrientationLock';
import StartLayout from './layout/Start';
import Colors from '../../theme/Colors';

const NoticeVerification = (props) => {
  const { t } = useTranslation();
  const { history, cardVerification } = props;
  const { errorCode } = cardVerification;
  const [errorMessage, setErrorMessage] = useState({
    title: '',
    body: '',
  });

  useEffect(() => {
    console.log(errorCode);
    switch (errorCode) {
      case ErrorCode.UnsupportedDevice:
        setErrorMessage({
          title: t('notice_invalidDevice_title'),
          body: t('notice_invalidDevice_body'),
        });
        break;
      case ErrorCode.UnsupportedBrowser:
        setErrorMessage({
          title: t('notice_invalidBrowser_title'),
          body: t('notice_invalidBrowser_body'),
        });
        break;
      case ErrorCode.ScreenTooSmall:
        setErrorMessage({
          title: t('notice_screenTooSmall_title'),
          body: t('notice_screenTooSmall_body'),
          button: {
            title: t('notice_proceedAnyway'),
            action: redirectToTutorial,
          },
        });
        break;
      case ErrorCode.InvalidInvocation:
        setErrorMessage({
          title: t('notice_invalidLink_title'),
          body: t('notice_invalidLink_body'),
        });
        break;
      default:
        break;
    }
  }, [errorCode]);

  const redirectToTutorial = () => {
    history.push('/verification/tutorial');
  };

  const button = errorMessage.button?.title ? (
    <Button onClick={errorMessage.button?.action} color="primary">
      {errorMessage.button.title}
    </Button>
  ) : (
    ''
  );

  return (
    <OrientationLock>
      <Div100vh style={{ width: '100vw', backgroundColor: Colors.white }}>
        <StartLayout />
        <Dialog
          open={errorMessage.title !== ''}
          aria-labelledby="error-dialog-title"
          aria-describedby="error-dialog-description"
        >
          <DialogTitle id="error-dialog-title">{errorMessage.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="error-dialog-description">
              {errorMessage.body}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {button}
          </DialogActions>
        </Dialog>
      </Div100vh>
    </OrientationLock>
  );
};

const mapStateToProps = (state) => {
  const { cardVerification } = state;
  return { cardVerification };
};

export default connect(mapStateToProps)(withRouter(NoticeVerification));