import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';

const useStyles = makeStyles(() => ({
  socialShareButton: {
    cursor: 'pointer',
    width: 80,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    color: 'white',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

/**
 * @return {null}
 */
function SocialShareButtons (props) {
  const classes = useStyles();
  const { url } = props;

  const size = 48;

  return (
    <div className={classes.container}>
      <FacebookShareButton url={url} className={classes.socialShareButton}>
        <FacebookIcon size={size} />
        Post it!
      </FacebookShareButton>
      <TwitterShareButton
        url={url}
        className={classes.socialShareButton}
        hashtags={['streamheroes']}
      >
        <TwitterIcon size={size} />
        Tweet it!
      </TwitterShareButton>
    </div>
  );
}

export default SocialShareButtons;