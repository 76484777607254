import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { useParams, withRouter } from 'react-router-dom';

import { getStreamerCardsAndBoosterProgresses } from '../../apollo/mergedQueries';

import PageTitle from '../../components/PageTitle/PageTitle';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import StreamerInventory from '../../components/Inventory/StreamerInventory';
import { bindActionCreators } from 'redux';
import { resetCollectorsViewData } from '../../redux/actions';
import { connect } from 'react-redux';

const StreamerInventoryContainer = ({ resetCollectorsViewData }) => {
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      resetCollectorsViewData();
    };
  }, [resetCollectorsViewData]);

  const variables = { match: id };
  const { data, loading } = useQuery(getStreamerCardsAndBoosterProgresses, {
    variables,
    fetchPolicy: useCacheWithExpiration('getStreamerCardsAndBoosterProgresses', variables),
  });

  const progressData = data && data.cardCollectionProgressByMatch;
  const cardsData = data && data.getMyInventoryCardsByMatch;
  const myViewer = data && data.getMyViewer;
  let streamer = cardsData && cardsData.streamer_cards[0].streamer;
  const rarities = cardsData && cardsData.streamer_cards[0].rarities;

  if (!streamer)
    streamer = {
      _id: '6202877609cbfc52b858fc3c',
      login: 'streamheroes',
      display_name: 'Streamheroes',
      profile_image_url: 'https://static-cdn.jtvnw.net/jtv_user_pictures/fa77cfe3-b7dd-443f-8ba8-594c93740805-profile_image-300x300.png',
    };

  return (
    <>
      <PageTitle title={t('streamer_inventory')} />
      <StreamerInventory
        progressData={progressData}
        cardsData={cardsData}
        streamer={streamer}
        rarities={rarities}
        t={t}
        loading={loading}
        myViewer={myViewer}
      />
    </>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    resetCollectorsViewData,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(withRouter(StreamerInventoryContainer));