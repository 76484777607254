import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/react-hooks';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zeroPad } from 'react-countdown';

import { getMyStreamerCardAndViewer } from '../../apollo/mergedQueries';
import { updateOneCardSet as updateOneCardSetMutation } from '../../apollo/mutations';

import CardPopup from '../Dialog/CardPopup';
import Colors from '../../theme/Colors';
import PopupButton from '../Button/PopupButton';
import Helpers from '../../theme/Helpers';
import { rangeArray } from '../../utils/helper';
import AnimationButton from '../Button/AnimationButton';

const conf = require('../../conf');

const useStyles = makeStyles(() => ({
  header: {
    background: '#F22740',
    borderRadius: '15px 15px 0px 0px',
    padding: 16,
    color: Colors.white,
    position: 'relative',
  },
  body: {
    padding: 20,
    background: '#01122B',
    position: 'relative',
  },
  footer: {
    background: '#122041',
    ...Helpers.fillRowCenter,
    padding: 12,
    borderRadius: '0px 0px 15px 15px',
  },
  title: {
    fontSize: 30,
    fontFamily: 'Bebas Neue',
    lineHeight: 1,
  },
  subTitle: {
    color: '#e9a5b1',
    lineHeight: 1,
  },
  framesContainer: {
    ...Helpers.fillRowCenter,
    flexWrap: 'wrap',
    maxWidth: 800,
    overflow: 'auto',
    height: 407,
  },
  cardSetContainer: {
    ...Helpers.fillRowCenter,
    flexWrap: 'wrap',
  },
  cardItem: {
    position: 'relative',
    margin: 5,
    border: '3px solid transparent',
    cursor: 'pointer',
    padding: 5,
    '&:hover': {
      borderColor: Colors.secondary,
    },
  },
  backgroundItem: {
    position: 'absolute',
    left: 5,
    top: 5,
  },
}));

function FramePopup(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    handleClose,
    isTabletOrMobile,
    cardDesigns,
    cardSetDesign,
    cardLevel,
    isSpecialCard,
    customCardFrame,
    setCustomCardFrame,
  } = props;
  const preSelectedFrame = cardSetDesign && cardSetDesign.cards.find(card => card.cardLevel === cardLevel);
  const [selectedFrame, setSelectedFrame] = useState(customCardFrame ? customCardFrame : (preSelectedFrame ? preSelectedFrame : null));

  const directory = `${conf.cdn_url}/cards/maker/sets`;

  const [updateOneCardSet] = useMutation(updateOneCardSetMutation, {
    onCompleted: () => {
      handleClose();
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{ query: getMyStreamerCardAndViewer }],
  });

  const confirm = () => {
    if (selectedFrame)
      if (isSpecialCard) {
        setCustomCardFrame({ set: selectedFrame.set, setLevel: selectedFrame.setLevel });
        handleClose();
      } else
        updateOneCardSet({
          variables: {
            cardLevel,
            set: selectedFrame.set,
            setLevel: selectedFrame.setLevel,
          },
        });
  };

  const selectFrame = (set, setLevel) => {
    setSelectedFrame({
      set,
      setLevel,
    });
  };

  return (
    <CardPopup
      handleClose={handleClose}
      style={{
        backgroundColor: 'transparent',
        width: 'auto',
        maxHeight: 'auto',
        overflow: 'auto',
        padding: 0,
      }}
    >
      <div className={classes.header}>
        <FontAwesomeIcon
          icon={faTimes}
          size="2x"
          style={{ position: 'absolute', right: isTabletOrMobile ? 10 : 20, cursor: 'pointer' }}
          onClick={handleClose}
        />
        <div className={classes.title}>
          {t('select_frame_title')}
        </div>
        <div className={classes.subTitle}>
          {t('select_frame_subtitle')}
        </div>
      </div>
      <div className={classes.body}>
        <div className={classes.framesContainer}>
          {cardDesigns.map(cardDesign => (
            <div key={`CardSet${cardDesign}`} className={classes.cardSetContainer}>
              {rangeArray(1, 5)
              .map(level => (
                <div
                  key={`CardSet${cardDesign}CardLvl${level}`}
                  className={classes.cardItem}
                  onClick={() => selectFrame(cardDesign, level)}
                  onDoubleClick={confirm}
                  style={(selectedFrame && selectedFrame.set === cardDesign && selectedFrame.setLevel === level ? { borderColor: Colors.white } : {})}
                >
                  <img
                    src={`${directory}/set${zeroPad(cardDesign)}/Level-${level}/01-frames/00.png?height=140`}
                    alt="Card frame"
                    style={{ position: 'relative', zIndex: 1 }}
                  />
                  <img
                    src={`${directory}/set${zeroPad(cardDesign)}/Level-${level}/00-background/00.png?height=140`}
                    alt="Card Background"
                    className={classes.backgroundItem}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className={classes.footer}>
        <AnimationButton status="neonGreen" onClick={confirm} disabled={!selectedFrame}>
          {t('choose_design')}
        </AnimationButton>
      </div>
    </CardPopup>
  );
}

export default FramePopup;