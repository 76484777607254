import React from 'react';

const TermsAndConditionsEN = () => {
  return (
    <>
      <p dir="ltr">
        Diese Geschäftsbedingungen ("<b>AGB</b>") regeln die Vertragsverhältnisse zwischen dem
        Kunden und der
      </p>

      <p dir="ltr" style={{ textAlign: 'center' }}>
        Streamheroes GmbH ("<b>Streamheroes</b>")<br />
        Triererstraße 5, 66839 Schmelz, Deutschland<br />
        Handelsregister Saarbrücken, HRB 106787<br />
        Tel.: +43 650 4989 426<br />
        E-Mail: <a href="mailto:support@streamheroes.gg">support@streamheroes.gg</a><br />
        UID No.: DE335845977
      </p>

      <p dir="ltr">
        für Produkte bzw Leistungen, die von STREAMHEROES unter dem unter <a
        href="https://app.streamheroes.gg/card-shop">https://app.streamheroes.gg/card-shop</a> verfügbaren
        Webshop ("<b>WEBSHOP</b>") angeboten, erzeugt, geliefert bzw erbracht werden. Die für die
        Bestellung gültige Version der AGB ist die, die zum Zeitpunkt der Bestellung auf <a
        href="https://app.streamheroes.gg/card-shop">https://app.streamheroes.gg/card-shop</a> veröffentlicht
        ist. Davon abweichende Bedingungen des Kunden gelten nicht, es sei denn, STREAMHEROES stimmt
        der Geltung schriftlich zu.
      </p>

      <p dir="ltr">
        Es wird darauf hingewiesen, dass der WEBSHOP unter <a
        href="https://app.streamheroes.gg/card-shop">https://app.streamheroes.gg/card-shop</a> Bestandteil
        einer Online-Plattform ist, die STREAMHEROES auf <a
        href="https://app.streamheroes.gg">https://app.streamheroes.gg</a> Nutzern zur Verfügung
        stellt (die "<b>PLATTFORM</b>"). Für die Nutzung der PLATTFORM gelten, in Ergänzung zu
        diesen AGB, die allgemeinen Nutzungsbedingungen, wie sie unter <a
        href="https://app.streamheroes.gg/page/terms-of-use">https://app.streamheroes.gg/page/terms-of-use</a> in
        der jeweils geltenden Fassung zur Verfügung stehen und die der Kunde bei der erstmaligen
        Anmeldung auf der PLATTFORM zu akzeptieren hat. Bei Widersprüchen zwischen den AGB und den
        allgemeinen Nutzungsbedingungen haben die Bestimmungen dieser AGB Vorrang.
      </p>

      <h1 dir="ltr">
        GEGENSTAND DES VERTRAGS
      </h1>

      <p dir="ltr">
        STREAMHEROES bietet seinen Nutzern auf der PLATTFORM die Möglichkeit digitale Karten zu
        sammeln, deren Design von Kooperationspartnern der PLATTFORM entworfen wurden. Die Nutzer
        haben – als Kunden des WEBSHOPS - die Möglichkeit, virtuelle Karten, die sie auf der
        PLATTFORM gesammelt haben, über den WEBSHOP als physische Karten anzufertigen. STREAMHEROES
        wird in einem solchen Fall die entsprechenden Karten auf Kundenwunsch anfertigen und
        drucken. Klarstellend wird festgehalten, dass der Kunde keine physischen Karten anfertigen
        kann, die er nicht virtuell erworben wird.
      </p>

      <p dir="ltr">
        Die Angebote im WEBSHOP richten sich ausschließlich an Verbraucher im Sinne des jeweils
        anwendbaren Rechts. Ein Erwerb der angebotenen Waren zu kommerziellen Zwecken (zB ein
        kommerzieller Weiterverkauf solcher Karten) ist ausdrücklich untersagt.
      </p>

      <h1 dir="ltr">
        ANGEBOTE UND VERTRAGSABSCHLUSS
      </h1>

      <p dir="ltr">
        Alle Angebote im WEBSHOP sind freibleibend.
      </p>

      <p dir="ltr">
        Bei der Bestellung hat der Kunde die gewünschte Ware auszuwählen, die für die Ausführung der
        Bestellung notwendigen Daten (wie insbesondere Name, Lieferadresse und E-Mail-Adresse)
        anzugeben sowie die Versand- und Bezahlungsmethode anzugeben; anschließend kann er diese
        Angaben überprüfen. Durch das anschließende Anklicken des Buttons "<i>Zahlungspflichtig
        bestellen</i>" gibt der Kunde ein verbindliches Angebot zum Abschluss eines Kaufvertrags mit
        STREAMHEROES ab. STREAMHEROES ist nicht verpflichtet dieses Angebot anzunehmen. Der Kunde
        ist – vorbehaltlich eines gesetzlichen Rücktrittsrechts – für zwei Werktage an das Angebot
        gebunden.
      </p>

      <p dir="ltr">
        STREAMHEROES bestätigt den Erhalt des Angebots durch ein automatisch erstelltes E-Mail an
        die vom Kunden bekannt gegebene E-Mail-Adresse. Dieses Bestätigungsmail ist noch keine
        Annahme des Angebots des Kunden. Sofern der Kunde Fehler im Bestätigungsmail finden sollte,
        wird er STREAMHEROES hierüber so rasch wie möglich informieren.
      </p>

      <p dir="ltr">
        STREAMHEROES kann das Angebot dadurch annehmen, dass der Vertragsabschluss in einem weiteren
        E-Mail bestätigt oder die gekaufte Ware an den Kunden zugesendet wird ("<i>tatsächliches
        Entsprechen</i>").
      </p>

      <h1 dir="ltr">
        LIEFERBEDINGUNGEN
      </h1>

      <p dir="ltr">
        STREAMHEROS ist erst zur Leistungsausführung verpflichtet, sobald der Kunde all seinen
        Verpflichtungen, insbesondere der Zahlung des Kaufpreises, nachgekommen ist. Die Bestellung
        wird daher erst nach festgestelltem Zahlungseingang an die Druckproduktion übergeben. Die
        bestellte Ware wird sodann nach Maßgabe der Produktionsmöglichkeiten umgehend produziert und
        nach Fertigstellung an die vom Kunden bekannt gegebene Lieferadresse versandt.
      </p>

      <p dir="ltr">
        Es gelten die auf der Website des WEBSHOP unter dem Abschnitt „<i>Versandkosten und
        Lieferbedingungen</i>“ angeführten Lieferfristen. Dabei handelt es sich um Zirkaangaben.
        Sollte es bei einem bestimmten Produkt abweichende Lieferzeiten geben, wird STREAMHEROES den
        Kunden hierüber beim Bestellungsvorgang informieren.
      </p>

      <p dir="ltr">
        Sollte STREAMHEROES durch höhere Gewalt (zB Streik, Naturkatastrophen, Covid-19 Krise) oder
        sonstige nicht von STREAMHEROES zu vertretende Umstände an der Einhaltung der Lieferfristen
        gehindert sein (zB Streiks), wird STREAMHEROES den Kunden ehest möglich darüber informieren.
        Die Lieferfrist verlängert sich um die Dauer dieser Ereignisse.
      </p>

      <h1 dir="ltr">
        ANNAHMEVERZUG
      </h1>

      <p dir="ltr">
        Hat der Kunde die Ware nicht wie vereinbart übernommen, ist er im Annahmeverzug.
        STREAMHEROES ist in einem solchen Fall berechtigt, entweder auf Vertragserfüllung zu
        bestehen oder nach Setzung einer angemessenen, mindestens 2 Wochen umfassenden, Nachfrist,
        vom Vertrag zurückzutreten und die Ware anderweitig zu verwerten.
      </p>

      <h1 dir="ltr">
        PREISE, VERSANDKOSTEN, ZAHLUNGSBEDINGUNGEN
      </h1>

      <p dir="ltr">
        Die im WEBSHOP angeführten Preise verstehen sich in EUR und beinhalten alle Steuern, nicht
        jedoch die Versandkosten. Sofern der Kunde seinen Wohnsitz in einem Land haben sollte, in
        dem EUR nicht die Staatswährung sein sollte, erfolgt eine Umrechnung auf die entsprechende
        Staatswährung des Kunden zum Zeitpunkt der Bestellung. Die Versandkosten sind ausführlich im
        Abschnitt „<i>Versandkosten und Lieferbedingungen</i>“ auf der Website im WEBSHOP angegeben.
        Es gelten die jeweils im Bestellzeitpunkt angeführten Beträge. STREAMHEROES wird den Kunden
        nochmals über die Preise, etwaige Gebühren oder Steuern und Versandkosten in der
        Bestellzusammenfassung vor dem Abschluss der Bestellung informieren.
      </p>

      <p dir="ltr">
        Die von STREAMHEROES akzeptierten Zahlungsmethoden und etwaige damit verbundene Gebühren
        sind ausführlich im Abschnitt „<i>Zahlungsmethoden</i>“ auf der Website des WEBSHOP
        angegeben.
      </p>

      <p dir="ltr">
        Sofern mit dem Kunden nichts Abweichendes vereinbart wird, ist der Rechnungsbetrag sofort
        bei Vertragsschluss zur Zahlung fällig. Der Kaufvertrag entsteht bei Annahme durch
        tatsächliches Entsprechen mit dem Zugang der Ware bzw bei Annahme durch E-Mail durch Zugang
        des E-Mails. Im Fall des Verzugs ist STREAMHEROES berechtigt, gesetzliche Verzugszinsen
        geltend zu machen. Darüber hinaus sind STREAMHEROES die entstehenden Mahn- und
        Inkassospesen, soweit sie zur zweckentsprechenden Rechtsverfolgung notwendig sind, zu
        ersetzen.
      </p>

      <h1 dir="ltr">
        EIGENTUMSVORBEHALT
      </h1>

      <p dir="ltr">
        Alle von STREAMHEROES gelieferten Waren bleiben bis zur vollständigen Bezahlung des Entgelts
        im Eigentum von STREAMHEROES.
      </p>

      <h1 dir="ltr">
        WIDERRUFSRECHT UND ENTFALL DES RÜCKTRITTSRECHTS
      </h1>

      <p dir="ltr">
        Der Kunde kann seine Zustimmung zum Vertragsverhältnis mit STREAMHEROES formlos (etwa mit
        Brief oder E-Mail) ohne Angabe von Gründen widerrufen. Der Widerruf ist zu richten an
      </p>

      <p dir="ltr" style={{ textAlign: 'center' }}>
        Streamheroes GmbH<br />
        Triererstraße 5, 66839 Schmelz, Deutschland<br />
        Handelsregister Saarbrücken, HRB 106787<br />
        E-Mail: <a href="mailto:support@streamheroes.gg">support@streamheroes.gg</a>
      </p>

      <p dir="ltr">
        Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem der Kunde oder ein vom Kunden
        benannter Dritter, der nicht der Beförderer ist, die Waren in Besitz genommen haben bzw hat.
        Zur Wahrung der Widerrufsfrist reicht es aus, dass der Kunde die Mitteilung über die
        Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absendet.
      </p>

      <p dir="ltr">
        Die Ausübung des Widerrufsrechts gemäß diesem Abschnitt 7 kann mittels des
        Muster-Widerrufsformulars erfolgen, welches sich am Ende der AGB befindet.
      </p>

      <p dir="ltr">
        Wenn der Kunde seine Vertragserklärung oder einen bereits zustande gekommenen Vertrag
        widerruft, hat STREAMHEROES alle Zahlungen, die vom Kunden an STREAMHEROES geleistet wurden
        unverzüglich und – vorbehaltlich des nächsten Satzes - spätestens binnen vierzehn Tagen ab
        dem Tag zurückzuzahlen, an dem die Mitteilung über den Widerruf des Vertrags bei
        STREAMHEROES eingegangen ist. STREAMHEROES kann die Rückzahlung solange verweigern, bis die
        gelieferten Waren wieder bei STREAMHEROES eingelangt sind oder der Kunde einen Nachweis
        erbracht hat, dass die Waren an STREAMHEROES zurückgesandt wurden, je nachdem, welches der
        frühere Zeitpunkt ist.
      </p>

      <p dir="ltr">
        Für die Rückzahlung verwendet STREAMHEROES dasselbe Zahlungsmittel, das bei der
        ursprünglichen Transaktion verwendet wurde, es sei denn, mit dem Kunden wurde ausdrücklich
        etwas anderes vereinbart.
      </p>

      <p dir="ltr">
        Der Kunde hat die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab
        dem Tag, an dem der Kunde STREAMHEROES über den Widerruf dieses Vertrags unterrichtet, an
      </p>

      <p dir="ltr" style={{ textAlign: 'center' }}>
        Streamheroes GmbH<br />
        Triererstraße 5, 66839 Schmelz, Deutschland
      </p>

      <p dir="ltr">
        zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn der Kunde die Waren vor Ablauf
        der Frist von vierzehn Tagen absendet. Die Rücksendekosten sind vom Kunden zu tragen.
      </p>

      <p dir="ltr">
        Für einen etwaigen Wertverlust der Waren muss der Kunde aufkommen, wenn dieser Wertverlust
        auf einen zur Prüfung der Beschaffenheit und Eigenschaften der Waren nicht notwendigen
        Umgang des Kunden mit den Waren zurückzuführen ist.
      </p>

      <p dir="ltr" style={{ fontWeight: 'bold' }}>
        Der Kunde nimmt zur Kenntnis, dass ein Widerrufsrecht nicht bei Verträgen zur Lieferung von
        Waren besteht, die nach Kundenspezifikationen angefertigt wurden oder eindeutig auf die
        persönlichen Bedürfnisse des Kunden zugeschnitten sind. Die physischen Karten, die über den
        WEBSHOP vertrieben werden, sind einmalige Anfertigungen, die durch einen QR-Code und die
        Verknüpfung mit der gesammelten, virtuellen Karte ausschließlich auf den jeweiligen Kunden
        zugeschnitten sind. Aus diesem Grund handelt es sich bei solchen physischen Karten um Waren,
        die nach Kundenspezifikationen angefertigt wurden oder eindeutig auf die persönlichen
        Bedürfnisse des Kunden zugeschnitten sind und dem Kunden steht kein Widerrufsrecht beim
        Erwerb solcher physischen Karten über den WEBSHOP zu.
      </p>

      <h1 dir="ltr">
        RÜCKTRITTSRECHT VON STREAMHEROES UND UNBERECHTIGTER RÜCKTRITT DES KÄUFERS
      </h1>

      <p dir="ltr">
        Bei Annahmeverzug (siehe Abschnitt 4) oder anderen wichtigen Gründen, wie insbesondere dem
        Zahlungsverzug des Kunden, ist STREAMHEROES nach Setzen einer angemessenen, mindestens 2
        Wochen umfassenden, Nachfrist zum Rücktritt des Vertrags berechtigt, sofern er von beiden
        Seiten noch nicht zur Gänze erfüllt wurde.
      </p>

      <p dir="ltr">
        Tritt der Kunde – ohne dazu berechtigt zu sein – vom Vertrag zurück oder begehrt er seine
        Aufhebung, so hat STREAMHEROES die Wahl, auf das Erfüllen des Vertrages zu bestehen oder der
        Aufhebung des Vertrags zuzustimmen.
      </p>

      <h1 dir="ltr">
        GEWÄHRLEISTUNG, TECHNISCHE ABWEICHUNGEN UND HAFTUNG
      </h1>

      <p dir="ltr">
        Die Gewährleistung richtet sich nach den gesetzlichen Regeln.
      </p>

      <p dir="ltr">
        Der Kunde nimmt zur Kenntnis, dass es aufgrund von branchenüblichen Produktionsabläufen oder
        der Natur der verwendeten Materialien bei der Vertragserfüllung zu geringfügigen
        Abweichungen gegenüber den Angaben zu den Produkteigenschaften im WEBSHOP kommen kann.
        Soweit solche Abweichungen dem Stand der Technik entsprechen, branchenüblich und/oder für
        den Kunden zumutbar sind, wie etwa hinsichtlich Papierstärke, Abmessung, Gestaltung, Farbe
        oder ähnlichen Merkmalen, gelten solche Abweichungen als ausdrücklich akzeptiert und nicht
        als "Mangel" im Sinne der anwendbaren gewährleistungsrechtlichen Regelungen. Eine
        detaillierte Beschreibung solcher branchenüblichen Abweichungen findet sich unter Abschnitt
        „<i>Kartenmaße</i>“ auf der Website des WEBSHOPS.
      </p>

      <p dir="ltr">
        Vorbehaltlich Abschnitt 9.4, ist STREAMHEROES Haftung aufgrund leichter Fahrlässigkeit,
        sofern keine wesentlichen Vertragspflichten verletzt wurden, generell ausgeschlossen.
        STREAMHEROES haftet ausschließlich für Schäden, die auf vorsätzlichem oder grob fahrlässigem
        Verhalten von STREAMHEROES basieren.
      </p>

      <p dir="ltr">
        Die in Abschnitt 9.3 festgelegten Haftungsbeschränkungen gelten nicht für Schäden aus
        Verletzung des Lebens, des Körpers oder der Gesundheit an Personen sowie für den Fall, dass
        anders lautende zwingende gesetzliche Regelungen entgegenstehen.
      </p>

      <h1 dir="ltr">
        IMMATERIALGÜTERRECHTE DRITTER PERSONEN AN DEN KARTENMOTIVEN
      </h1>

      <p dir="ltr">
        Der Kunde nimmt zur Kenntnis, dass die Erstellung der virtuellen Karten, die Basis für die
        im WEBSHOP zu erwerbenden, physischen Karten sind, in der alleinigen Verantwortung der
        Kooperationspartner der PLATTFORM liegen. STREAMHEROES ist in diesem Zusammenhang nicht zur
        Prüfung auf Verletzungen von etwaigen Urheberrechten, sonstigen gewerblichen Schutzrechten
        oder anderen Rechter Dritter verpflichtet und STREAMHEROES trifft keine Haftung im Falle
        solcher Verletzungen.
      </p>

      <p dir="ltr">
        Sofern bei dem Kunden oder STREAMHEROES vor Erfüllung der Bestellung ein begründeter
        Verdacht besteht oder entsteht, dass durch das Druckmotiv und/oder die Ausführung des
        Auftrags Rechte Dritter oder gesetzliche Vorschriften verletzt werden, hat jede Partei die
        andere umgehend davon zu informieren und kann – nach vorheriger Prüfung der Sachlage durch
        STREAMHEROES binnen angemessener Frist - mit sofortiger Wirkung vom Auftrag zurückzutreten.
        STREAMHEROES kann bis zur Klärung der Sachlage die Erfüllung auszusetzen wobei die
        Lieferfristen bis zur Klärung entsprechend verlängert werden.
      </p>

      <h1 dir="ltr">
        DATENSCHUTZ, ADRESSÄNDERUNGEN
      </h1>

      <p dir="ltr">
        STREAMHEROES ist verpflichtet, die der Datenschutz-Grundverordnung (DSGVO) sowie allfällige
        weitere gesetzliche Geheimhaltungsverpflichtungen einzuhalten.
      </p>

      <p dir="ltr">
        Ferner verarbeitet STREAMHEROES zum Zweck der Vertragserfüllung die dafür erforderlichen
        personenbezogenen Daten. Die detaillierten datenschutzrechtlichen Informationen
        (Datenschutzmitteilung) gemäß Art 13 ff DSGVO sind unter <a
        href="https://www.streamheroes.gg/privacy">https://www.streamheroes.gg/privacy</a> verfügbar.
      </p>

      <p dir="ltr">
        Der Kunde ist verpflichtet, Änderungen seiner Wohn- bzw Lieferadresse bekanntzugeben,
        solange das vertragsgegenständliche Rechtsgeschäft nicht beiderseitig vollständig erfüllt
        ist. Wird die Mitteilung unterlassen, so gelten Erklärungen auch dann als zugegangen, falls
        sie an die zuletzt vom Kunden bekanntgegebene Adresse gesendet werden. Lieferungen gelten
        dann als schuldbefreiend zugegangen, wenn sie an die vom Kunden bekanntgegebene
        Lieferadresse geliefert oder mangels Zustellbarkeit beim Zustelldienst hinterlegt wurden.
      </p>

      <h1 dir="ltr">
        ONLINE-STREITBEILEGUNG
      </h1>

      <p dir="ltr">
        Zur Beilegung von Streitigkeiten aus Online-Kaufverträgen und
        Online-Dienstleistungsverträgen ist bei der Europäischen Kommission eine
        Online-Streitbeilegungs(OS)-Plattform eingerichtet. Verbrauchern gibt dies die Möglichkeit,
        Streitigkeiten zunächst außergerichtlich zu klären. Diese ist unter dem Link: <a
        href="https://webgate.ec.europa.eu/odr">https://webgate.ec.europa.eu/odr</a> aufrufbar.
        STREAMHEROES nimmt nicht an einem Streitbeilegungsverfahren vor einer
        Verbraucherschlichtungsstelle teil.
      </p>

      <h1 dir="ltr">
      ZAHLUNG & KÜNDIGUNG
      </h1>

      <h2 dir="ltr">
        1. Zahlung
      </h2>

      <p dir="ltr">
        1.1 Für die hierin gewährten Rechte wird Ihnen zu Beginn jeder Abonnementperiode eine Abonnementgebühr in der Höhe berechnet, die im Abonnementbereich auf der Website angegeben ist. Streamheroes erstattet keine gezahlten Abonnementgebühren, es sei denn, dies ist in diesem Vertrag ausdrücklich vorgesehen.
      </p>

      <p dir="ltr">
        1.2 Die Abonnementgebühr versteht sich inklusive Mehrwertsteuer.
      </p>

      <p dir="ltr">
        1.3 Streamheroes kann die Abonnementgebühr von Zeit zu Zeit ändern und wird Ihnen solche Änderungen im Voraus mitteilen. Preisänderungen werden frühestens zu Beginn des auf das Änderungsdatum folgenden Abonnementzeitraums wirksam. Wenn Sie mit der Preisänderung nicht einverstanden sind, können Sie Ihr Abonnement jederzeit vor einer solchen Preisänderung kündigen.
      </p>

      <h2 dir="ltr">
        2. Termination
      </h2>

      <p dir="ltr">
        2.1 Dieser Vertrag tritt in Kraft, sobald Sie die hier dargelegten Bedingungen akzeptieren, und verlängert sich automatisch um die von Ihnen beim Kauf eines Abonnements angegebenen Zeiträume (jeder dieser Zeiträume ist ein "Abonnementzeitraum"), bis er von Ihnen oder von Streamheroes vor dem Ende des aktuellen Abonnementzeitraums gekündigt wird.
      </p>

      <p dir="ltr">
        2.2 Soweit nach geltendem Recht zulässig und sofern hierin nicht anders angegeben, erklären Sie sich damit einverstanden, dass Streamheroes Ihnen gegenüber keine Haftung oder Verantwortung übernimmt und dass Streamheroes keine Beträge zurückerstattet, die Sie bereits gezahlt haben, wenn Sie diesen Vertrag kündigen oder wenn Streamheroes diesen Vertrag kündigt oder Ihren Zugang in Übereinstimmung mit den hierin enthaltenen Bestimmungen aussetzt.
      </p>

      <p dir="ltr">
        2.3 Wenn Sie Ihren Wohnsitz in einem Land der Europäischen Union haben und ein Abonnement erworben haben, haben Sie das Recht, das Abonnement zu kündigen, indem Sie das hier zu findende Rücktrittsformular ausfüllen und über dieses Webformular absenden und eine vollständige Rückerstattung innerhalb von vierzehn (14) Tagen nach dem Kauf erhalten (die "Bedenkzeit"), jedoch nur, wenn Sie keine Dienstleistung/keine Leistung des Produkts in Anspruch genommen haben. Das bedeutet, dass Sie begonnen haben, das Abonnement zu konsumieren.
      </p>

      <p dir="ltr">
        2.4 Zur Klarstellung: Wenn Sie diesen Vertrag nach Ablauf der Bedenkzeit (sofern zutreffend) kündigen, erstattet Streamheroes keine bereits von Ihnen gezahlten Abonnementgebühren.
      </p>

      <h1 dir="ltr">
        SCHLUSSBESTIMMUNGEN
      </h1>

      <p dir="ltr">
        Wenn eine Bestimmung dieser AGB unwirksam ist oder wird oder dieser Vertrag eine Lücke
        enthält, berührt dies nicht die Gültigkeit der übrigen Bestimmungen.
      </p>

      <p dir="ltr">
        Diese AGB und die gesamten Rechtsbeziehungen und Streitigkeiten zwischen dem Kunden und
        STREAMHEROES unterliegen ausschließlich österreichischem Recht mit Ausnahme der
        Verweisungsnormen und des UN-Kaufrechts. Gegenüber Verbrauchern gilt diese Rechtswahl nur
        insoweit, als dadurch keine zwingenden gesetzlichen Bestimmungen des Staates, in dem er
        seinen gewöhnlichen Aufenthalt hat, eingeschränkt werden.
      </p>

      <p dir="ltr">
        Gerichtsstand für alle Streitigkeiten aus oder in Zusammenhang mit diesen AGB ist das
        sachlich zuständige Gericht in Steyr, Österreich. Ist der Kunde Verbraucher im Sinne des
        anwendbaren Rechts und hat er seinen Wohnsitz oder gewöhnlichen Aufenthalt im Inland oder
        ist im Inland beschäftigt, kann er nur abweichend vor jenen Gerichten geklagt werden, in
        deren Sprengel sein Wohnsitz, sein gewöhnlicher Aufenthalt oder sein Beschäftigungsort
        liegt.
      </p>

      <p dir="ltr">
        Der Vertragstext wird nicht gespeichert. STREAMHEROES empfiehlt, die Bestellbestätigung und
        diese AGB zu archivieren.
      </p>

      <p dir="ltr">
        Sofern diese AGB in mehrere Sprachen übersetzt werden sollten, ist die deutsche Version die
        maßgebende Version.
      </p>

      <p dir="ltr" style={{ textAlign: 'center', fontWeight: 'bold' }}>
        <i>Muster-Widerrufsformular</i>
      </p>

      <p dir="ltr">
        Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden
        Sie es zurück an
      </p>

      <p dir="ltr" style={{ textAlign: 'center' }}>
        Streamheroes GmbH<br />
        Triererstraße 5, 66839 Schmelz, Deutschland<br />
        Handelsregister Saarbrücken, HRB 106787<br />
        E-Mail: <a href="mailto:support@streamheroes.gg">support@streamheroes.gg</a>
      </p>

      <p dir="ltr">
        Hiermit widerrufe ich den von mir abgeschlossenen Vertrag über den Kauf der folgenden Waren
        die unter https://app.streamheroes.gg/card-shop angeboten werden: [Beschreibung der Waren]
      </p>

      <p dir="ltr">
        Bestellt am/erhalten am: <br />
        Name des Verbrauchers:<br />
        Datum:<br />
        Unterschrift des Verbrauchers (nur bei Mitteilung auf Papier)
      </p>
    </>
  );
};

export default TermsAndConditionsEN;