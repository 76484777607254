import React from 'react';
import { Slider, withStyles } from '@material-ui/core';

const GemsSlider = (props) => {
  const { value, setValue, max = 100, min = 1 } = props;

  return (
    <StyledGemsSlider
      value={value}
      onChange={(event, newValue) => setValue(newValue)}
      max={max}
      step={10}
      min={min}
    />
  );
};

const StyledGemsSlider = withStyles({
  root: {
    color: 'rgba(255, 255, 255, 0.08)',
    height: 4,
    margin: 0,
    padding: '6px 0',
  },
  thumb: {
    height: 18,
    width: 18,
    background: '#FFFFFF',
    marginTop: -7,
    marginLeft: -6,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {},
  track: {
    height: 4,
    borderRadius: 6,
    background: '#FF4067',
  },
  rail: {
    height: 4,
    borderRadius: 6,
    opacity: 1,
  },
})(Slider);

export default GemsSlider;