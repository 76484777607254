import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import conf from '../../conf.json';

const TopBG = (props) => {
  const { background, style, char, extra } = props;

  let additionalStyle = {};
  if (extra.hideScroll)
    additionalStyle = {
      overflow: 'hidden',
      height: '100vh',
      marginTop: '-50vh',
      transition: 'all 0.6s ease-out',
    };

  if (background)
    additionalStyle.backgroundImage = `url(${background})`;

  const useStyles = makeStyles(() => ({
    topHeader: {
      // backgroundImage: `url(${background})`,
      padding: '20px 0 60px',
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
      ...additionalStyle,
    },
    innerWrapper: {
      maxWidth: 1440,
      margin: '0 auto',
      position: 'relative',
      padding: 0,
    },
    character: {
      position: 'absolute',
      right: 60,
      bottom: -60,
      height: 550,
    },
  }));

  const classes = useStyles();

  return (
    <div
      className={classes.topHeader}
      style={{
        ...style ? style : {},
      }}
    >
      <div className={classes.innerWrapper}>
        {props.children}
        {char &&
        <img
          src={`${conf.cdn_url}/assets/template/chars/${char}.png`}
          alt="Character"
          className={classes.character}
        />
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { extra } = state;
  return { extra };
};

export default connect(mapStateToProps)(TopBG);