import React from 'react';
import { withRouter } from 'react-router-dom';
import { StickyContainer } from 'react-sticky';

import Colors from '../../theme/Colors';
import CardShopMatchFilters from '../CardShop/CardShopMatchFilters';
import Loading from '../Loading/Loading';
import LoadMore from '../Loading/LoadMore';
import MarketplaceMarketItem from './MarketplaceMarketItem';

const MarketplaceMarket = (props) => {
  const {
    offers,
    loading,
    onLoadMore,
    totalCount,
    myViewer,
    matches,
    sorting, setSorting,
    quality, setQuality,
    rarity, setRarity,
    isSeasonal, setIsSeasonal,
    match, setMatch,
  } = props;

  return (
    <div style={{ color: Colors.white, position: 'relative' }}>

      <StickyContainer>

        <CardShopMatchFilters
          setSorting={setSorting}
          sorting={sorting}
          setRarity={setRarity}
          rarity={rarity}
          setQuality={setQuality}
          quality={quality}
          setIsSeasonal={setIsSeasonal}
          isSeasonal={isSeasonal}
          matches={matches}
          match={match}
          setMatch={setMatch}
        />

        <div className="inner-wrapper" style={{ margin: '35px auto' }}>
          {loading ?
            <Loading />
            :
            <>
              <div style={{
                display: 'grid',
                gap: '26px 8px',
                gridTemplateColumns: 'repeat(4, 1fr)',
              }}>
                {offers && offers.map((offer) => (
                  <MarketplaceMarketItem item={offer} myViewer={myViewer} />
                ))}
              </div>

              <LoadMore count={offers && offers.length} totalCount={offers && totalCount}
                        loading={loading} onLoadMore={onLoadMore} />
            </>
          }
        </div>

      </StickyContainer>

    </div>
  );
};

export default withRouter(MarketplaceMarket);