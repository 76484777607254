import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Echo from 'laravel-echo';
import { useTranslation } from 'react-i18next';
import Pusher from 'pusher-js';
import 'react-toastify/dist/ReactToastify.css';

import ToastContent from '../../components/Toast/ToastContent';
import { decodeJWT } from '../../utils/helper';
import Colors from '../../theme/Colors';
import FirstAlertCapsuleDialog from '../Dialog/FirstAlertCapsuleDialog';
import RankUpDialog from '../Dialog/RankUpDialog';
import DropWonDialog from '../Drops/DropWonDialog';
import SupportDialog from '../Dialog/SupportDialog';

const conf = require('../../conf');

const ToastManagement = (props) => {
  const { userToken } = props;
  const { t } = useTranslation();

  const [echoClient, setEchoClient] = useState(null);

  const [messageDialog, setMessageDialog] = useState(null);

  const connectWebSocket = (token) => {
    if (!token) return;

    const decodedJWT = decodeJWT(token);

    const options = {
      broadcaster: 'pusher',
      key: conf.websocket_key,
      wsHost: conf.websocket_host,
      wsPort: 443,
      enabledTransports: ['ws', 'wss'],
      authEndpoint: conf.websocket_auth_endpoint,
      disableStats: true,
      forceTLS: true,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    };

    const client = new Pusher(options.key, options);

    const echo = new Echo({
      ...options,
      client,
    });

    echo.private(`User-${decodedJWT._id}`)
    .listen('.leagueRankUp', (e) => {
      setMessageDialog(
        <RankUpDialog
          t={t}
          streamerName={e.data.streamer_name}
          leagueName={e.data.rank}
          onClose={() => setMessageDialog(null)}
        />,
      );
    })
    .listen('.firstBoosterToday', (e) => {
      const toastContent = (
        <ToastContent
          title={t('toasts.first_booster_today.title')}
          text={t('toasts.first_booster_today.text', { streamerName: e.data.streamer_name })}
        />
      );

      const toastIcon = <img src={`${conf.cdn_url}/assets/notifications/comm-challenge-trophy.png`}
                             alt={t('toasts.first_booster_today.title')} />;

      toast(toastContent, {
        theme: 'xp-collected',
        icon: () => toastIcon,
      });
    })
    .listen('.toast', (e) => {
      const { icon, toast_type, toast_data, message } = e.data;
      let toastContent, toastIcon, theme, autoClose;

      switch (toast_type) {
        case 'alertCapsule': {
          if (toast_data.capsule_count === 1) {
            setMessageDialog(
              <FirstAlertCapsuleDialog
                t={t}
                streamerName={toast_data.streamer_name}
                capsuleImageUrl={icon}
                onClose={() => setMessageDialog(null)}
              />,
            );

            return;
          } else {
            theme = 'dark';
            autoClose = false;
            toastContent = (
              <ToastContent
                title={t('toasts.got_alert_capsule.title')}
                text={t('toasts.got_alert_capsule.text', { streamerName: toast_data.streamer_name })}
                ctaText={t('use_now')}
                ctaLink={`/inventory/alert-capsules?capsuleId=${toast_data.capsule_id}`}
                titleColor={Colors.white}
              />
            );
            toastIcon = <img src={icon} alt={t('toasts.got_alert_capsule.title')} />;
          }

          break;
        }
        case 'dropVoucher': {
          setMessageDialog(
            <DropWonDialog
              t={t}
              codeType={toast_data.droppool.type === 'FreeShipping' ?
                t('free_shipping_code')
                :
                `${toast_data.droppool.value}% ${t('coupon_code')}`
              }
              imageUrl={icon}
              onClose={() => setMessageDialog(null)}
            />,
          );

          return;
        }
        case 'support_popup': {
          setMessageDialog(
            <SupportDialog
              t={t}
              streamer={toast_data.streamer}
              watchedMin={toast_data.amount_watched_in_min}
              onClose={() => setMessageDialog(null)}
            />,
          );

          return;
        }
        case 'receivedPremiumCurrency': {
          theme = 'dark';
          autoClose = false;
          toastContent = (
            <ToastContent
              title={message}
              titleColor={Colors.white}
            />
          );
          toastIcon = <img src={icon} alt="Premium receive icon" />;

          break;
        }

        default:
          return;
      }

      toast(toastContent, {
        theme,
        autoClose,
        icon: () => toastIcon,
      });
    });

    setEchoClient(echo);
  };

  useEffect(() => {
    if (!echoClient)
      connectWebSocket(userToken);
  }, [userToken, echoClient]);

  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_CENTER} autoClose={8000} />

      {messageDialog}
    </>
  );
};

export default ToastManagement;