import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import LegacyInventoryContainer from './LegacyInventory';
import InventoryContainer from './Inventory';
import NotFoundPage from '../../components/NotFoundPage/NotFoundPage';
import BoosterContainer from '../Booster/Booster';
import GroupedInventoryContainer from './GroupedInventory';
import DropsContainer from '../Drops/Drops';
import StorageBoxesContainer from './StorageBoxes';
import { resetStorageBoxData } from '../../redux/actions';
import StreamerInventoryContainer from './StreamerInventory';

const LegacySwitcherContainer = ({ resetStorageBoxData }) => {
  const { tab } = useParams();
  const [isLegacy, setIsLegacy] = useState(false);

  useEffect(() => {
    return () => {
      if (tab === 'cards')
        resetStorageBoxData();
    };
  });

  const tabs = ['cards', 'booster', 'groups', 'drops', 'boxes', 'streamer'];

  if (!tabs.includes(tab))
    return <NotFoundPage />;

  if (tab === 'cards')
    return (
      !isLegacy ?
        <InventoryContainer isLegacy={isLegacy} setIsLegacy={setIsLegacy} />
        :
        <LegacyInventoryContainer isLegacy={isLegacy} setIsLegacy={setIsLegacy} />
    );

  if (tab === 'booster')
    return <BoosterContainer />;

  if (tab === 'streamer')
    return <StreamerInventoryContainer />;

  if (tab === 'groups')
    return <GroupedInventoryContainer />;

  if (tab === 'drops')
    return <DropsContainer />;

  if (tab === 'boxes')
    return <StorageBoxesContainer />;
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    resetStorageBoxData,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(withRouter(LegacySwitcherContainer));