import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import Loading from '../Loading/Loading';
import NicePrice from '../Utils/NicePrice';
import AnimationButton from '../Button/AnimationButton';
import CardShopCard from './CardShopCard';
import { toggleNewCardInShop } from '../../redux/actions';

import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  tabItem: {
    padding: '22px 16px',
    color: Colors.halfWhite,
    borderBottom: '1px solid transparent',
    marginBottom: -1,
    transition: 'all 0.3s ease-out',
    fontSize: 14,
    cursor: 'pointer',
    '&:hover': {
      color: Colors.white,
    },
    '&.active': {
      color: Colors.white,
      borderBottomColor: Colors.white,
    },
  },
  arrowArea: {
    width: 30,
    height: 383,
    ...Helpers.fillRowCenter,
    borderRadius: 4,
    fontSize: 16,
    color: Colors.white,
    transition: 'all 0.3s ease-out',
    background: 'rgba(255, 255, 255, 0.04)',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.12)',
      color: Colors.white,
    },
    '&:before': {
      content: 'none',
    },
  },
  avatar: {
    width: 30,
    height: 30,
    marginRight: 6,
    border: 'none',
  },
}));

const CardShopSupporterBundle = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { loading, shopBoosterPacks } = props;
  const [activeTab, setActiveTab] = useState(0);

  const ArrowArea = (props) => {
    const { className, styling, onClick, icon } = props;
    return (
      <div
        className={[className, classes.arrowArea].join(' ')}
        style={styling}
        onClick={onClick}
      >
        {icon}
      </div>
    );
  };

  const updateQuantity = () => {
    shopBoosterPacks[tabs[activeTab]].map(card => {
      props.toggleNewCardInShop({
        ...card,
        card_template_metadata: { match: card.card_template_metadata.match_meta_data },
      });
      return null;
    });
  };

  const tabs = [
    'fivePack',
    'tenPack',
    'fifteenPack',
    'twentyPack',
    'twentyfivePack',
  ];

  const cards = shopBoosterPacks && shopBoosterPacks[tabs[activeTab]];
  const streamers = shopBoosterPacks && shopBoosterPacks.fivePack.map(item => item.card_template_metadata.match_meta_data);
  const price = cards ? cards.reduce((acc, card) => acc + (card.price || 219), 0) : 0;

  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 5,
    centerPadding: 30,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    arrows: true,
    prevArrow: <ArrowArea styling={{ left: 0 }}
                          icon={<i className="fa-light fa-chevron-left" />} />,
    nextArrow: <ArrowArea styling={{ right: 0 }}
                          icon={<i className="fa-light fa-chevron-right" />} />,
  };

  return (
    <div style={{ background: 'rgba(255, 255, 255, 0.04)', borderRadius: 6 }}>
      <div style={{
        borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
        ...Helpers.fillRowCross,
        padding: '0 16px',
      }}>
        {tabs.map((tab, index) => (
          shopBoosterPacks && shopBoosterPacks[tabs[index]] && shopBoosterPacks[tabs[index]].length > 0 &&
          <div
            className={[classes.tabItem, activeTab === index ? 'active' : ''].join(' ')}
            onClick={() => setActiveTab(index)}
          >
            {5 * (index + 1)} {t('cards')}
          </div>
        ))}
      </div>
      <div style={{ padding: '32.5px 16px' }}>
        {loading ?
          <Loading />
          :
          <div style={{ ...Helpers.fillRowCross, width: '100%' }}>
            <img
              src={`${conf.cdn_url}/assets/template/shop/card_pack_${5 * (activeTab + 1)}.png?height=452`}
              alt="Booster pack"
              style={{ marginLeft: 24 }}
            />

            <div style={{ marginLeft: 40.5, width: 'calc(100% - 297px)' }}>
              <div
                style={{ marginBottom: 24, ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween }}>
                <div style={{ fontSize: 24, fontWeight: 600 }}>
                  {t('these_are_your_best_cards', { count: 5 * (activeTab + 1) })}
                </div>
                <div style={{ fontSize: 14, color: '#1FE325' }}>
                  <i className="fa-light fa-circle-check"
                     style={{ fontSize: 16, marginRight: 8 }} />
                  {t('every_card_purchase_supports_streamer')}
                </div>
              </div>

              <Slider {...settings} style={{ width: '100%', padding: '0 40px' }}>
                {cards && cards.map((card) => (
                  <CardShopCard
                    key={card._id}
                    card={{
                      ...card,
                      card_template_metadata: {
                        match: card.card_template_metadata.match_meta_data,
                        matchModel: card.card_template_metadata.match_meta_data.__typename,
                      },
                    }}
                    t={t}
                    noCart
                  />
                ))}
              </Slider>
            </div>
          </div>
        }
      </div>
      <div
        style={{
          borderTop: '1px solid rgba(255, 255, 255, 0.08)',
          ...Helpers.fillRowCenter,
          ...Helpers.mainSpaceBetween,
          padding: 16,
        }}
      >
        <div style={{ ...Helpers.fillRowCenter, marginLeft: 16 }}>
          <i
            className="fa-light fa-hand-holding-heart"
            style={{ fontSize: 22, marginRight: 10 }}
          />
          <div style={{ color: Colors.halfWhite, fontSize: 14, ...Helpers.fillRowCenter }}>
            <div style={{ marginRight: 10 }}>{t('support_streamers_with_buying_start')}</div>
            {streamers && streamers.map((streamer, index) => (
              <div style={{
                color: Colors.white, ...Helpers.fillRowCenter,
                marginRight: index < streamers.length - 1 ? 6 : 10,
                fontWeight: 500,
              }}>
                <Avatar
                  className={classes.avatar}
                  alt={streamer.display_name}
                  src={streamer.profile_image_url}
                />
                {streamer.display_name + (index < streamers.length - 1 ? ',' : '')}
              </div>
            ))}
            <div>{t('support_streamers_with_buying_end')}</div>
          </div>
        </div>

        <div style={{ ...Helpers.fillRowCenter }}>
          <NicePrice price={price / 100} />
          <AnimationButton
            status={'neonGreen'}
            onClick={updateQuantity}
            style={{
              fontFamily: 'Montserrat',
              fontSize: 14,
              padding: '14px 16px',
              borderRadius: 4,
              fontWeight: 500,
              marginLeft: 32,
            }}
          >
            <i
              className={`fa-light fa-cart-plus`}
              style={{ fontSize: 18, marginRight: 8, verticalAlign: 'bottom' }}
            />
            {t('add_to_cart')}
          </AnimationButton>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    toggleNewCardInShop,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(CardShopSupporterBundle);