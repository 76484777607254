import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputAdornment, makeStyles, Radio, TextField } from '@material-ui/core';

import Helpers from '../../theme/Helpers';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';
import QualityLine from '../QualityLine/QualityLine';
import Colors from '../../theme/Colors';
import { getLevelWord, preventEvent, rangeArray } from '../../utils/helper';
import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  menu: {
    position: 'absolute',
    top: 'calc(100% + 8px)',
    left: 0,
    padding: '28px 32px',
    background: 'rgba(24, 34, 53, 0.95)',
    zIndex: 1,
    fontSize: 14,
    borderRadius: 4,
    width: 'calc(258px - 64px)',
    '& .radio-item': {
      marginBottom: 14,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    '&.bigger-menu': {
      width: 'fit-content',
    },
  },
  radioButton: {
    padding: 0,
  },
  textField: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(255, 255, 255, 0.12)',
      borderRadius: 6,
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.halfWhite,
    },
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
      fontSize: 14,
      fontFamily: 'Montserrat',
      '&::placeholder': {
        color: Colors.halfWhite,
      },
    },
    '& .MuiOutlinedInput-notchedOutline legend': {
      color: 'green',
    },
  },
  checkLine: {
    ...Helpers.fillRowCross,
    ...Helpers.mainSpaceBetween,
    marginBottom: 11,
  },
  circle: {
    borderRadius: '50%',
    width: 24,
    height: 24,
    border: '1px solid rgba(255, 255, 255, 0.5)',
    marginRight: 18,
    transition: 'all 0.3s ease-out',
    ...Helpers.fillRowCenter,
    '&.active': {
      borderColor: Colors.white,
    },
  },
}));

const CardShopMatchFilterItem = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    mainClassName,
    sorting, setSorting,
    quality, setQuality,
    rarity, setRarity,
    noPrices, noQuality, noRarity,
  } = props;
  const [clickedMenu, setIsClickedMenu] = useState(null);

  const handleMenuClick = (value) => {
    if (clickedMenu !== value)
      setIsClickedMenu(value);
    else
      setIsClickedMenu(null);
  };

  const changeIndex = (index) => {
    setSorting(sortingOptions[index].sorting);
  };

  const changeQualityValue = (isMin, newValue) => {
    const tmpValue = { ...quality };
    let intValue = parseInt(newValue);

    if (isMin && intValue >= quality.max)
      intValue = quality.max - 1;
    if (!isMin && intValue <= quality.min)
      intValue = quality.min + 1;

    if (intValue > 100)
      intValue = 100;
    else if (intValue < 0)
      intValue = 0;

    tmpValue[isMin ? 'min' : 'max'] = intValue;

    setQuality(tmpValue);
  };

  const changeRarityValue = (newValue) => {
    const tmpValue = [...rarity];

    if (tmpValue.includes(newValue))
      tmpValue.splice(tmpValue.indexOf(newValue), 1);
    else
      tmpValue.push(newValue);

    setRarity(tmpValue);
  };

  const sortingOptions = [
    { key: 'last_added', sorting: { field: 'created_at', order: 'DESC' } },
    { key: 'first_added', sorting: { field: 'created_at', order: 'ASC' } },
  ];

  if (!noRarity)
    sortingOptions.push(
      { key: 'lowest_rarity', sorting: { field: 'rarity', order: 'ASC' } },
      { key: 'highest_rarity', sorting: { field: 'rarity', order: 'DESC' } },
    );

  if (!noQuality)
    sortingOptions.push(
      { key: 'lowest_quality', sorting: { field: 'quality', order: 'ASC' } },
      { key: 'highest_quality', sorting: { field: 'quality', order: 'DESC' } },
    );

  if (!noPrices)
    sortingOptions.push(
      { key: 'lowest_price', sorting: { field: 'price', order: 'ASC' } },
      { key: 'highest_price', sorting: { field: 'price', order: 'DESC' } },
    );

  const activeIndex = sortingOptions.findIndex(sort => sort.sorting.field === sorting.field && sort.sorting.order === sorting.order);

  return (
    <>
      <div className={[mainClassName, clickedMenu === 'sorting' ? 'active' : ''].join(' ')}
           onClick={() => handleMenuClick('sorting')}>
        {t('sort_by')}
        <i className={`fa-light fa-chevron-${clickedMenu === 'sorting' ? 'up' : 'down'}`}
           style={{ marginLeft: 10, fontSize: 14 }} />

        {clickedMenu === 'sorting' &&
        <CustomBackdropFilter
          className={classes.menu}
          filter={'blur(50px)'}
          canvasFallback={true}
          html2canvasOpts={{
            allowTaint: true,
          }}
        >
          {sortingOptions.map((sortingItem, index) => (
            <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween }}
                 onClick={preventEvent} className="radio-item">
              <div onClick={() => changeIndex(index)}>
                {t(sortingItem.key)}
              </div>
              <div>
                <Radio
                  checked={activeIndex === index}
                  onChange={() => changeIndex(index)}
                  value="paypal"
                  name="sorting"
                  color="default"
                  classes={{ root: classes.radioButton }}
                />
              </div>
            </div>
          ))}
        </CustomBackdropFilter>
        }
      </div>

      {!noQuality &&
      <div className={[mainClassName, clickedMenu === 'quality' ? 'active' : ''].join(' ')}
           onClick={() => handleMenuClick('quality')}>
        {t('quality')}
        <i className={`fa-light fa-chevron-${clickedMenu === 'quality' ? 'up' : 'down'}`}
           style={{ marginLeft: 10, fontSize: 14 }} />

        {clickedMenu === 'quality' &&
        <CustomBackdropFilter
          className={[classes.menu, 'bigger-menu'].join(' ')}
          filter={'blur(50px)'}
          canvasFallback={true}
          html2canvasOpts={{
            allowTaint: true,
          }}
        >
          <div onClick={preventEvent} style={{ width: 'calc(600px - 64px)' }}>
            <div style={{ marginBottom: 12, fontSize: 14, fontWeight: 500 }}>
              {t('select_the_range')}
            </div>
            <QualityLine
              quality={[quality.min, quality.max]}
              hasNoText
              changeValue={changeQualityValue}
              setQuality={setQuality}
            />

            <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween, marginTop: 12 }}
                 onClick={preventEvent}>
              <TextField
                variant="outlined"
                onChange={(event) => changeQualityValue(true, event.target.value)}
                value={quality.min}
                className={classes.textField}
                style={{ marginRight: 5 }}
                label={t('from')}
                inputProps={{
                  type: 'number',
                  min: 0,
                  max: 100,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
              />

              <TextField
                variant="outlined"
                onChange={(event) => changeQualityValue(false, event.target.value)}
                value={quality.max}
                className={classes.textField}
                style={{ marginLeft: 5 }}
                label={t('to')}
                inputProps={{
                  type: 'number',
                  min: 0,
                  max: 100,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
              />
            </div>
          </div>
        </CustomBackdropFilter>
        }
      </div>
      }

      {!noRarity &&
      <div className={[mainClassName, clickedMenu === 'rarity' ? 'active' : ''].join(' ')}
           onClick={() => handleMenuClick('rarity')}>
        {t('rarity')}
        <i className={`fa-light fa-chevron-${clickedMenu === 'rarity' ? 'up' : 'down'}`}
           style={{ marginLeft: 10, fontSize: 14 }} />

        {clickedMenu === 'rarity' &&
        <CustomBackdropFilter
          className={classes.menu}
          filter={'blur(50px)'}
          canvasFallback={true}
          html2canvasOpts={{
            allowTaint: true,
          }}
        >
          <div onClick={preventEvent}>
            {[0, ...rangeArray(1, 5)]
            .reverse()
            .map(nr => {
              const levelWord = getLevelWord(nr > 0 ? 'Card' : 'SpecialCard', nr);
              const isActive = rarity.includes(nr);

              return (
                <div
                  className={classes.checkLine}
                  key={`RarenessItemKey${nr}`}
                  onClick={() => changeRarityValue(nr)}
                  style={nr === 0 ? { marginBottom: 0 } : {}}
                >
                  <div style={{ ...Helpers.fillRowCross }}>
                    <img
                      src={`${conf.cdn_url}/badges/card-level/${nr}.png?height=29`}
                      alt="Level badge"
                      style={{ marginRight: 6 }}
                    />
                    <div style={{
                      color: Colors.level[levelWord],
                      fontSize: 14,
                    }}>
                      {t(`rareness_level.${nr}`)}
                    </div>
                  </div>

                  <div className={[classes.circle, isActive ? 'active' : ''].join(' ')}>
                    {isActive &&
                    <i className="fa-solid fa-check" style={{ color: Colors.white }} />
                    }
                  </div>
                </div>
              );
            })}
          </div>
        </CustomBackdropFilter>
        }
      </div>
      }
    </>
  );
};

export default CardShopMatchFilterItem;