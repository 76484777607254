import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  flipCard: {
    backgroundColor: 'transparent',
    width: 324,
    height: 500,
    perspective: 1000,
    cursor: 'pointer',
    margin: '40px auto 0',
  },
  flipCardInner: {
    position: 'relative',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    transformStyle: 'preserve-3d',
  },
  animateCard: {
    animationName: '$flipCard',
    animationDuration: '12s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  '@keyframes flipCard': {
    'from': {
      transform: 'rotateY(0deg)',
    },
    'to': {
      transform: 'rotateY(360deg)',
    },
  },
  flipCardSide: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden',
    '& img': {
      borderRadius: 8,
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translate(-50%, 0)',
    },
  },
  flipCardBack: {
    transform: 'rotateY(180deg)',
  },
}));

const CardViewCSSFlip = (props) => {
  const classes = useStyles();
  const { information, isLegacy, deactivateRotation, width, height } = props;

  const effectURL = 'https://s3.eu-central-1.wasabisys.com/streamheroes-card-manager';

  const card = {
    image: information.front_layers[0].img_url,
    backImage: information.back_layers ? information.back_layers[0].img_url : `${conf.cdn_url}/cards/SH-Backside-Edding-Cut.jpg`,
    frontEffect: information.front_layers.length > 1 && `${effectURL}/${information.front_layers[1].img_url}`,
    backEffect: (information.back_layers && information.back_layers.length > 1) && `${effectURL}/${information.back_layers[1].img_url}`,
    noEffectLayer: isLegacy || (information.quality && information.quality < 0),
  };

  return (
    <div className={classes.flipCard} style={{ width, height }}>
      <div
        className={[classes.flipCardInner, deactivateRotation ? '' : classes.animateCard].join(' ')}>
        <div className={classes.flipCardSide}>
          <div style={{ position: 'relative' }}>
            <img
              src={`${card.image}?height=${height}`}
              alt="Card front side" />
            {!card.noEffectLayer &&
            <img
              src={`${card.frontEffect}`}
              alt="Card front effect"
              style={{ mixBlendMode: 'screen', height }}
            />
            }
          </div>
        </div>
        <div className={[classes.flipCardSide, classes.flipCardBack].join(' ')}>
          <div style={{ position: 'relative' }}>
            <img
              src={`${card.backImage}?height=${height}`}
              alt="Card back side" />
            {!card.noEffectLayer &&
            <img
              src={`${card.backEffect}`}
              alt="Card back effect"
              style={{ mixBlendMode: 'screen', height }}
            />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardViewCSSFlip;