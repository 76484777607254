import React, { useState } from 'react';

const HoverIcon = (props) => {
  const [hovered, setHovered] = useState(false);
  const { icon, hoverIcon, color, hoverColor, style, onClick } = props;

  const toggleHover = () => setHovered(!hovered);

  const styles = { cursor: 'pointer', transition: 'all 0.3s ease-out', ...(style ? style : {}) };
  if (color)
    styles.color = hovered ? hoverColor : color;

  return (
    <i
      className={hovered ? (hoverIcon ? hoverIcon : icon) : icon}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      style={styles}
      onClick={onClick ? onClick : null}
    />
  );
};

export default HoverIcon;