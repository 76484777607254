import React from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import { withRouter } from 'react-router-dom';

import MenuContainer from '../../containers/Menu/Menu';
import CardShopCartSideMenu from '../CardShop/CardShopCartSideMenu';
import PopupArea from './PopupArea';
import ToastManagement from '../Toast/ToastManagement';
import ShopAccessoriesContainer from '../../containers/CardShop/ShopAccessories';
import InnerFooterApp from './InnerFooterApp';
import MobileMenuContainer from '../../containers/Menu/MobileMenu';

const conf = require('../../conf');

const InnerApp = (props) => {
  const { userToken, location } = props;
  const { pathname } = location;

  const isWebViewForApp = pathname.includes('/3d-');

  return (
    <>
      {(userToken && userToken !== conf.api_token) &&
      <>
        {(isBrowser || isWebViewForApp) &&
        <MenuContainer />
        }
        {isMobile &&
        <MobileMenuContainer />
        }

        {/*<HeaderContainer />*/}
        {/*<AlertsContainer />*/}
        <CardShopCartSideMenu />
        <PopupArea pathname={pathname} />
        <ShopAccessoriesContainer />

        <ToastManagement userToken={userToken} />
      </>
      }
      <InnerFooterApp isMobile={isMobile} userToken={userToken} pathname={pathname} />
    </>
  );
};

export default withRouter(InnerApp);