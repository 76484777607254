import React from 'react';
import { withRouter } from 'react-router-dom';

import { hideForPathURL } from '../../utils/helper';
import MobileTopBar from './MobileTopBar';
import MobileFooterBar from './MobileFooterBar';

const MobileMenuComponent = (props) => {
  const { viewer, location, streamer } = props;

  if (hideForPathURL(location.pathname))
    return null;

  return (
    <>
      <MobileTopBar viewer={viewer} />
      <MobileFooterBar streamer={streamer} />
    </>
  );
};

export default withRouter(MobileMenuComponent);