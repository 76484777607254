import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import PageTitle from '../PageTitle/PageTitle';
import TopBG from '../TopViewHeader/TopBG';

const useStyles = () => ({
  errorText: {
    textAlign: 'center',
    fontSize: 30,
  },
});

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch (error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
  }

  render () {
    const { classes } = this.props;

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <TopBG disableBreadcrumbs>
          <PageTitle title="An error occured" />
          <Typography variant="h5" gutterBottom color="primary" className={classes.errorText}>
            An unexpected error has occurred
          </Typography>
        </TopBG>
      );
    }
    return this.props.children;
  }
}

export default withStyles(useStyles)(ErrorBoundary);