import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Helpers from '../../theme/Helpers';
import CardViewDialogContainer from '../../containers/Cards/CardViewDialog';

const useStyles = makeStyles({
  levelCardContainer: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    flexWrap: 'wrap',
  },
  cardItem: {
    cursor: 'pointer',
    '&:hover': {
      filter: 'grayscale(50%)',
    },
  },
});

const GameCards = (props) => {
  const classes = useStyles();
  const { cards } = props;
  const [cardViewOpen, setCardViewOpen] = useState(null);

  return (
    <>
      <div className={classes.levelCardContainer}>
        {cards && cards.length > 0 && cards.slice(0, 5) // cut to max 5 cards if there are more cards
        .map(card => (
          <div
            onClick={() => setCardViewOpen(card)}
            key={`CardID#${card._id}`}
            className={classes.cardItem}
          >
            <img
              src={`${card.image}?height=170`}
              alt={`CategoryCardLevel${card.level}`}
              style={{ margin: 5, borderRadius: 4 }}
            />
          </div>
        ))}
      </div>

      {cardViewOpen &&
      <CardViewDialogContainer card={cardViewOpen} handleClose={() => setCardViewOpen(null)} />
      }
    </>
  );
};

export default GameCards;