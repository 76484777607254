import React from 'react';
import { makeStyles } from '@material-ui/core';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: 65,
    left: 0,
    right: 0,
    background: '#0B1729',
    borderBottom: '3px solid #011024',
    zIndex: 100,
    ...Helpers.fillColCenter,

    '& > a': {
      padding: '16px 0',
      color: Colors.white,
    },
  },
}));

const MobileDynamicMenu = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { viewer, onClick } = props;

  const menu = [
    {
      key: 'profile',
      link: viewer && `/profile/${viewer.login}/overview`,
    },
    {
      key: 'settings',
      link: '/settings/account',
    },
    {
      key: 'settings_tab_orders',
      link: '/settings/orders',
    },
    {
      key: 'discord',
      href: 'https://discord.gg/WJE6b8M',
    },
  ];

  return (
    <div className={classes.root}>
      {menu.map(item => (
        <Link
          to={item.link ? item.link : { pathname: item.href }}
          target={item.href ? '_blank' : ''}
          key={`MobileDynamicMenuKey${item.key}`}
          onClick={onClick}
        >
          {t(item.key)}
        </Link>
      ))}
    </div>
  );
};

export default MobileDynamicMenu;