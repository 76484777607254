import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';

const useStyles = makeStyles(() => ({
  colorPicker: {
    backgroundColor: Colors.wrapperLight + ' !important',
    zIndex: 100,
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  colorSquare: {
    width: 16,
    height: 16,
    margin: '0 2px',
    transition: 'all 100ms ease 0s',
    border: '1px solid #515151',
    cursor: 'pointer',
    '&:hover': {
      borderColor: Colors.white,
    },
  },
  selectedSquare: {
    borderWidth: 2,
    borderColor: Colors.white,
  },
}));

const ColorSelection = (props) => {
  const classes = useStyles();
  const { color, handleColor } = props;

  const colors = ['#364415', '#789537', '#BBA440', '#C9572D', '#DC384B', '#DC3DE4', '#1A2BE9', '#4B97EB', '#77EEE0'];

  return (
    <div style={{ ...Helpers.fillRowCenter }}>
      {colors.map(col => (
        <div
          className={[classes.colorSquare, color === col ? classes.selectedSquare : ''].join(' ')}
          style={{ backgroundColor: col }}
          key={`ColorSelKey${col}`}
          onClick={() => handleColor(col)}
        />
      ))}
    </div>
  );
};

export default ColorSelection;