import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Colors from '../../theme/Colors';
import OpenIllustratorJobs from './OpenIllustratorJobs';
import MyIllustratorJobs from './MyIllustratorJobs';
import FinishedIllustratorJobs from './FinishedIllustratorJobs';
import StatisticJobs from './StatisticJobs';
import TabsArea from '../Tabs/TabsArea';

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 1440,
    margin: '0 auto',
    color: Colors.white,
  },
}));

const IllustratorArea = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { myViewer } = props;

  const tabs = [
    {
      label: t('active_design_jobs'),
      component: <MyIllustratorJobs myViewer={myViewer} />,
    },
    {
      label: t('new_design_jobs'),
      component: <OpenIllustratorJobs myViewer={myViewer} />,
    },
    {
      label: t('finished_design_jobs'),
      component: <FinishedIllustratorJobs />,
    },
    {
      label: t('statistics'),
      component: <StatisticJobs />,
    },
  ];

  return (
    <div className={classes.container}>
      <TabsArea tabs={tabs} />
    </div>
  );
};

export default IllustratorArea;