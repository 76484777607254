import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import OnBoardingStepFooter from './OnBoardingStepFooter';
import StreamConfigTwitchBot from '../StreamConfig/StreamConfigTwitchBot';
import OnBoardingStepHeader from './OnBoardingStepHeader';

const OnBoardingChatBot = (props) => {
  const { t } = useTranslation();
  const { classes, nextStep, maxLevelCard, testCardsSocket, state, handleChange, save } = props;

  const [loading, setIsLoading] = useState(false);

  const hideButton = () => {
    testCardsSocket();
    setIsLoading(true);

    setTimeout(() => setIsLoading(false), 10000);
  };

  return (
    <>
      <OnBoardingStepHeader
        header={t('on_boarding_chat_head')}
        body={t('on_boarding_chat_text')}
        classes={classes}
      />

      <StreamConfigTwitchBot
        maxLevelCard={maxLevelCard}
        handleChange={handleChange}
        loading={loading}
        hideButton={hideButton}
        parentState={state} />

      <OnBoardingStepFooter nextStep={nextStep} save={save} classes={classes} />
    </>
  );
};

export default OnBoardingChatBot;