import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import PageTitle from '../../components/PageTitle/PageTitle';
import PagePopup from '../../components/Dialog/PagePopup';
import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import HorizontalPageScrollMenu from '../HorizontalPageMenu/HorizontalPageScrollMenu';
import AnimationButton from '../Button/AnimationButton';
import BoosterScene from '../3DCard/BoosterScene';
import ThreeDWrapper from '../3DCard/3DWrapper';
import StartOnBoardingEnergyGifts from './StartOnBoardingEnergyGifts';

import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  container: {
    ...Helpers.fillColCenter,
    width: '100vw',
    height: '100vh',
  },
}));

const StartOnBoarding = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { steps, step, setStep } = props;
  const activeStep = steps[step];

  const isNotLast = step < (steps.length - 1);

  return (
    <PagePopup>
      <div className={classes.container}>
        {!activeStep.image ?
          <div style={{ height: 438 }}>
            <ThreeDWrapper noBackdrop>
              <BoosterScene
                frontImage="booster-packs/new/sh-base-booster-pack-07.png"
                backImage="booster-packs/new/sh-base-booster-pack-07-back.png"
                userLogo="https://static-cdn.jtvnw.net/jtv_user_pictures/c285a6bd-79be-43a8-87eb-427602125bad-profile_image-300x300.png"
                isRotating
                isOblique
                noControls
              />
            </ThreeDWrapper>
          </div>
          :
          <img src={conf.cdn_url + activeStep.image} alt="Big energy" />
        }

        <div style={{
          fontSize: 20,
          fontWeight: 500,
          color: Colors.white,
          marginTop: activeStep.marginTop,
          width: isNotLast ? 'auto' : 306,
          ...Helpers.textCenter,
          marginBottom: isNotLast ? 0 : 20,
        }}>
          {t(activeStep.head)}
        </div>
        {isNotLast ?
          <>
            <div
              style={{
                fontWeight: 200,
                color: Colors.halfWhite,
                marginTop: 12,
                maxWidth: 720,
                ...Helpers.textCenter,
                marginBottom: 52,
              }}
            >
              {t(activeStep.body)}
            </div>
            <HorizontalPageScrollMenu
              length={steps.length - 1}
              activeIdx={step + 1}
              setIdx={(idx) => setStep(idx - 1)}
            />
          </>
          :
          <StartOnBoardingEnergyGifts />
        }
        <AnimationButton
          status="whiteTransparency"
          style={{ marginTop: isNotLast ? 34 : 32, width: 279 }}
          onClick={() => isNotLast ? setStep(step + 1) : props.history.push('/item-store/booster')}
        >
          {t(step <= 1 ? 'next' : 'start_collecting')}
        </AnimationButton>
      </div>
    </PagePopup>
  );
};

export default withRouter(StartOnBoarding);