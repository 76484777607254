import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import ThreeDWrapper from '../../components/3DCard/3DWrapper';
import AnimationScene from '../../components/3DCard/AnimationScene';
import VideoScene from '../../components/3DCard/VideoScene';

const ThreeDBoosterAnimation = (props) => {
  const { cardInformation, route } = props;
  const [startVideo, setStartVideo] = useState(false);

  return (
    <div style={{ height: 1080, position: 'relative', width: 1920 }}>
      {/*<div style={{ height: 900, position: 'relative', width: 1440 }}>*/}
      <ThreeDWrapper
        frameloop="always"
        noBackdrop
        withVideo
        startVideo={() => setStartVideo(true)}
      >
        <AnimationScene cardInformation={cardInformation} />
      </ThreeDWrapper>
      {startVideo && <VideoScene route={route} />}
    </div>
  );
};

export default withRouter(ThreeDBoosterAnimation);