import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { SketchPicker } from 'react-color';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';

const CardToolFabricFilters = forwardRef((props, ref) => {
  const classes = useStyles();

  const {
    canvas,
    checkIfObjectExists,
    checkTextWidthLength,
    addActionStep,
    objectId,
    label,
    disabled,
    fonts,
  } = props;

  const [togglePicker, setTogglePicker] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [textFont, setTextFont] = useState('Oswald');
  const [textColor, setTextColor] = useState(Colors.white);

  useImperativeHandle(ref, () => ({
    updateFilters (filters) {
      if (canvas && canvas.getObjects().length > 0) {
        handleFont(filters.text.isBold, 'font-weight', true);
        handleFont(filters.text.isItalic, 'font-style', true);
        handleFont(filters.text.fontFamily, 'font-family', true);
        handleFont(filters.text.color, 'text-color', true);
      }
    },
    updateText (textChange) {
      if (canvas && canvas.getObjects().length > 0)
        handleFont(textChange.value, textChange.type, true);
    },
  }));

  const handleFont = (value, type, skipActionStep = false) => {
    const textObject = checkIfObjectExists(objectId);
    let old;

    if (textObject) {
      if (type === 'font-family') {
        old = textObject.fontFamily;
        setTextFont(value);
        textObject.set('fontFamily', value);
        if (objectId === 'user-text')
          checkTextWidthLength(textObject);
      } else if (type === 'text-color') {
        old = textObject.fill;
        setTextColor(value);
        textObject.set('fill', value);
      } else if (type === 'font-weight') {
        old = textObject.fontWeight;
        setIsBold(value);
        textObject.set('fontWeight', value ? 'bold' : 'normal');
        if (objectId === 'user-text')
          checkTextWidthLength(textObject);
      } else if (type === 'font-style') {
        old = textObject.fontStyle;
        setIsItalic(value);
        textObject.set('fontStyle', value ? 'italic' : '');
      }

      if (!skipActionStep)
        addActionStep({
          type,
          data: {
            old,
            new: value,
          },
        });
      canvas.requestRenderAll();
    }
  };

  const togglePickerArea = () => {
    setTogglePicker(!togglePicker);
  };

  return (
    <div style={{
      ...Helpers.selfStretch, ...Helpers.colCross, ...Helpers.textCenter,
      marginBottom: 10,
    }}>
      <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween, color: '#747474' }}>
        <div style={{ width: 130, ...Helpers.textLeft }}>
          {label}
        </div>

        <FormControl className={classes.formControl}>
          <Select
            value={textFont}
            onChange={(event) => handleFont(event.target.value, 'font-family')}
            disableUnderline
            disabled={disabled}
            classes={{
              root: classes.textInput,
              icon: classes.textSelectIcon,
              disabled: classes.disabled,
            }}
          >
            {fonts.map(font =>
              <MenuItem
                value={font}
                key={font}
                style={{ fontFamily: font }}
              >
                {font}
              </MenuItem>,
            )}
          </Select>
        </FormControl>

        <div
          className={[classes.typeButton, (disabled ? classes.disabled : '')].join(' ')}
          style={{ fontStyle: 'italic', backgroundColor: isItalic ? '#cc1e31' : 'transparent' }}
          onClick={() => disabled ? null : handleFont(!isItalic, 'font-style')}
        >
          i
        </div>
        <div
          className={[classes.typeButton, (disabled ? classes.disabled : '')].join(' ')}
          style={{ fontWeight: 'bold', backgroundColor: isBold ? '#cc1e31' : 'transparent' }}
          onClick={() => disabled ? null : handleFont(!isBold, 'font-weight')}
        >
          B
        </div>

        <div style={{ position: 'relative' }}>
          <div
            className={[classes.colorCircle, (disabled ? classes.disabled : '')].join(' ')}
            onClick={() => disabled ? null : togglePickerArea()}
          >
            <div
              className={classes.innerCircle}
              style={{ boxShadow: textColor + ' 0px 0px 0px 22px inset' }}
            />
          </div>

          {togglePicker &&
          <ClickAwayListener onClickAway={togglePickerArea}>
            <SketchPicker
              className={classes.colorPicker}
              color={textColor}
              onChangeComplete={(color) => handleFont(color.hex, 'text-color')}
              disableAlpha
            />
          </ClickAwayListener>
          }
        </div>
      </div>
    </div>
  );
});

const useStyles = makeStyles(() => ({
  textInput: {
    backgroundColor: 'transparent',
    padding: 10,
    borderRadius: 4,
    width: 100,
    textAlign: 'left',
    border: '1px solid #cc1e31',
  },
  textSelectIcon: {
    fill: '#cc1e31',
    right: 10,
    top: '50%',
    transform: 'translate(0, -50%)',
  },
  disabled: {
    borderColor: Colors.gray + ' !important',
    cursor: 'not-allowed !important',
    color: Colors.gray + ' !important',
    '&:hover': {
      opacity: '1.0 !important',
      transform: 'scale(1.0) !important',
    },
  },
  colorPicker: {
    backgroundColor: Colors.wrapperLight + ' !important',
    zIndex: 100,
    position: 'absolute',
    bottom: 44,
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  colorCircle: {
    width: 41,
    height: 41,
    transform: 'scale(1)',
    transition: 'transform 100ms ease 0s',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  innerCircle: {
    background: 'transparent',
    height: '100%',
    width: '100%',
    position: 'relative',
    outline: 'none',
    boxShadow: 'rgb(244, 67, 54) 0px 0px 0px 22px inset',
    transition: 'box-shadow 100ms ease 0s',
    borderRadius: 4,
  },
  huePicker: {
    width: '100% !important',
  },
  alphaPicker: {
    width: '100% !important',
    marginTop: 20,
  },
  typeButton: {
    border: '1px solid #cc1e31',
    borderRadius: 4,
    padding: 10,
    width: 20.5,
    height: 20.5,
    color: Colors.white,
    cursor: 'pointer',
    ...Helpers.textCenter,
    '&:hover': {
      opacity: 0.7,
    },
  },
}));

export default CardToolFabricFilters;