import React, { useEffect } from 'react';
import { makeStyles, Radio } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { CardElement } from '@stripe/react-stripe-js';
import { withRouter } from 'react-router-dom';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';
import OrderDiscount from './OrderDiscount';

import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  darkBox: {
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,.6)',
    top: -3,
    left: -3,
    right: -3,
    bottom: -3,
    zIndex: 150,
    borderRadius: 3,
    cursor: 'not-allowed',
  },
  payBox: {
    borderRadius: 4,
    padding: '15px 10px',
    width: '100%',
  },
  formInput: {
    width: 432,
    marginBottom: 8,
    background: 'transparent',
    borderColor: '#FFFFFF1F',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FFFFFF1F',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FFFFFF1F',
    },
    '& .MuiFormLabel-root': {
      color: Colors.halfWhite,
    },
  },
  link: {
    '& a': {
      color: Colors.white,
      '&:hover': {
        color: Colors.subTextColor,
      },
    },
  },
  paymentMethodArea: {
    borderRadius: 6,
    padding: 16,
    overflow: 'hidden',
    ...Helpers.fillRow,
    ...Helpers.mainSpaceBetween,
    background: 'rgba(255, 255, 255, 0.04)',
    fontFamily: 'Montserrat',
    transition: 'all 0.3s ease-out',
    position: 'relative',
    '&.active': {
      background: 'rgba(255, 255, 255, 0.16)',
    },
  },
  radioButton: {
    padding: 0,
    marginRight: 14,
  },
  cardInfoArea: {
    background: 'rgba(255, 255, 255, 0.005)',
    padding: 16,
    marginTop: 8,
    ...Helpers.fillRow,
    borderRadius: 6,
    width: 'calc(100% - 32px)',
  },
  euText: {
    fontSize: 14,
    color: '#FFFFFF80',
    fontWeight: 200,
    lineHeight: 1.5,
    fontFamily: 'Montserrat',
    '& span': {
      fontWeight: 400,
      color: Colors.white,
    },
  },
  formLabel: {
    zIndex: 10,
    position: 'relative',
  },
}));

const OrderPay = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    checkedTerms, setCheckedTerms,
    paymentType, setPaymentType,
    setDisabled, noDiscountInput,
    stripe, elements, noDeliveryInfo,
  } = props;

  useEffect(() => {
    if (stripe && elements)
      setDisabled(false);
  }, [stripe, elements]);

  const handleRadioChange = (event) => {
    setPaymentType(event.target.value);
  };

  const handleChange = (event) => {
    setCheckedTerms(event.target.checked);
  };

  const handleCardChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empURLSearchParamsty);
    if (event.error)
      enqueueSnackbar(`Payment failed ${event.error.message}`, { variant: 'error' });
  };

  const cardOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#c4f0ff',
        fontSize: '16px',
        color: Colors.white,
        border: '1px solid #FFFFFF1F',
        lineHeight: '3',
        backgroundColor: 'rgba(255, 255, 255, 0.01)',

        '::placeholder': {
          color: Colors.halfWhite,
        },
      },
      invalid: {
        color: Colors.error,
      },
    },
  };

  return (
    <div style={{ position: 'relative' }}>
      {!noDiscountInput &&
      <div style={{
        fontFamily: 'Montserrat',
        fontSize: 14,
        color: Colors.halfWhite,
        alignSelf: 'flex-start',
        marginBottom: 30,
      }}>
        {t('select_your_payment')}
      </div>
      }

      <div
        className={[classes.paymentMethodArea, paymentType === 'paypal' ? 'active' : ''].join(' ')}
      >
        <div style={{ width: '100%' }}>
          <FormControlLabel
            value="paypal"
            control={
              <Radio
                checked={paymentType === 'paypal'}
                onChange={handleRadioChange}
                value="paypal"
                name="paymentMethod"
                color="default"
                classes={{ root: classes.radioButton }}
              />
            }
            label={t('paypal')}
            style={{ margin: 0 }}
            classes={{ root: classes.formLabel }}
          />
          <div style={{
            color: Colors.halfWhite,
            fontSize: 14,
            fontWeight: 300,
            marginLeft: 40,
            marginTop: 12,
          }}>
            {t('paypal_info')}
          </div>
        </div>
        <div style={{ position: 'absolute', right: 16, top: 14 }}>
          <img src={`${conf.cdn_url}/assets/template/shop/paypal.png`} alt="PayPal" />
        </div>
      </div>

      <div
        className={[classes.paymentMethodArea, paymentType === 'card' ? 'active' : ''].join(' ')}
        style={{ marginTop: 14 }}
      >
        <div style={{ width: '100%' }}>
          <FormControlLabel
            value="card"
            control={
              <Radio
                checked={paymentType === 'card'}
                onChange={handleRadioChange}
                value="card"
                name="paymentMethod"
                color="default"
                classes={{ root: classes.radioButton }}
              />
            }
            label={t('credit_card')}
            style={{ margin: 0, fontFamily: 'Montserrat' }}
            classes={{ root: classes.formLabel }}
          />

          <div style={{
            marginTop: paymentType === 'card' ? 16 : elements ? -150 : -100, ...Helpers.fillCol,
            width: '100%',
            opacity: paymentType === 'card' ? 1 : 0,
            transition: 'all 0.3s ease-out',
          }}>
            {elements &&
            <CardElement
              id="card-element"
              onChange={handleCardChange}
              options={cardOptions}
            />
            }

            <CustomBackdropFilter
              className={classes.cardInfoArea}
              filter={'blur(50px)'}
              canvasFallback={true}
              html2canvasOpts={{
                allowTaint: true,
              }}
            >
              <i
                className="fa-solid fa-circle-exclamation"
                style={{ fontSize: 18, color: '#E12138', marginRight: 16 }}
              />
              <div
                className={classes.euText}
                dangerouslySetInnerHTML={{ __html: t('card_payments_eu') }}
              />
            </CustomBackdropFilter>
          </div>
        </div>
        <div style={{ position: 'absolute', right: 16, top: 14 }}>
          <img src={`${conf.cdn_url}/assets/template/shop/credit_card.png`} alt="Cards" />
        </div>
      </div>

      <FormControlLabel
        control={<Checkbox
          checked={checkedTerms}
          onChange={handleChange}
          name="checkTerms"
          style={{ color: '#FFFFFF80' }}
        />}
        label={
          <span
            className={classes.link}
            dangerouslySetInnerHTML={{ __html: t('terms_checkout') }}
          />
        }
        style={{ color: '#FFFFFF80', marginTop: 28 }}
      />

      {!noDeliveryInfo &&
      <div
        style={{ background: '#E1213880', padding: 10, borderRadius: 6 }}
        dangerouslySetInnerHTML={{ __html: t('order_delivery_info') }}
      />
      }

      {!noDiscountInput &&
      <OrderDiscount />
      }
    </div>
  );
};

export default withRouter(OrderPay);