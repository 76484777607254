import React, { useEffect, useState } from 'react';
import { zeroPad } from 'react-countdown';

import BaseSelection from './BaseSelection';
import HeroMakerFooter from './HeroMakerFooter';
import { rangeArray } from '../../utils/helper';

import conf from '../../conf';
import { connect } from 'react-redux';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { removeHeroMakerColoredImage, setTypeAssets } from '../../redux/actions';

const HeadSelection = (props) => {
  const {
    character,
    body,
    head,
    nextTab,
    backTab,
    setHead,
    footerValue,
    heroMaker,
    setTypeAssets,
    serviceURL,
  } = props;
  const [index, setIndex] = useState(head ? head : 0);

  useEffect(() => {
    if (!heroMaker.bodyColor) {
      const tmpAssets = rangeArray(0, 4)
      .map(asset => `${conf.cdn_url}/heromaker/${character}/00_base/11_heads/preview/${zeroPad(body)}/${zeroPad(asset)}.png`);
      setTypeAssets('head', tmpAssets);
    } else {
      setTypeAssets('head', null);
      fetchHeads();
    }
  }, [heroMaker.bodyColor, character]);

  useEffect(() => {
    props.removeHeroMakerColoredImage('11_heads', 1);
  }, [index]);

  const fetchHeads = async () => {
    await axios.post(`${serviceURL}/get-heroMaker-b64-assets`, {
      character,
      body,
      bodyColor: heroMaker.bodyColor,
    })
    .then((response) => {
      setTypeAssets('head', response.data);
    })
    .catch((error) => {
      console.log(error);
    });
  };

  const saveAndNextTab = () => {
    setHead(index);
    nextTab();
  };

  return (
    <>
      <div>
        <BaseSelection index={index} assets={heroMaker.headAssets} setIndex={setIndex} />
      </div>
      <HeroMakerFooter
        onClick={saveAndNextTab}
        value={footerValue}
        backTab={backTab}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { heroMaker } = state;
  return { heroMaker };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setTypeAssets,
    removeHeroMakerColoredImage,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(HeadSelection);