import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: 5,
    padding: '3px 45px',
    fontSize: 20,
    fontWeight: 400,
    textTransform: 'initial',
    transition: 'all 0.3s ease',
    fontFamily: 'Bebas Neue',
    border: '1px solid transparent',
  },
  primary: {
    backgroundColor: Colors.primary,
    color: Colors.white,
    borderColor: '#DB343E',
    '&:hover': {
      backgroundImage: Colors.orangeRedGradient,
      backgroundColor: Colors.primary,
    },
  },
  outline: {
    // border: '1px solid ' + Colors.primary,
    backgroundColor: 'transparent',
    color: Colors.white,
    borderColor: '#DB343E',
    '&:hover': {
      backgroundColor: Colors.primary,
    },
  },
  secondary: {
    backgroundColor: Colors.secondary,
    color: Colors.white,
    '&:hover': {
      backgroundImage: Colors.orangeGradient,
      backgroundColor: Colors.secondary,
    },
  },
  third: {
    backgroundColor: Colors.third,
    color: Colors.white,
    '&:hover': {
      backgroundImage: Colors.purpleGradient,
      backgroundColor: Colors.third,
    },
  },
  green: {
    backgroundColor: '#7BB01C',
    color: '#374617',
    '&:hover': {
      backgroundColor: '#8CC12D',
    },
  },
  gold: {
    backgroundImage: 'linear-gradient(to right, #8a764f, #f3de9b, #8a764f)',
    color: Colors.white,
    '&:hover': {
      ...Helpers.boxBasics,
    },
  },
  custom: {
    backgroundImage: 'linear-gradient(to right, #ead089, #945c48)',
    color: Colors.white,
    '&:hover': {
      ...Helpers.boxBasics,
      opacity: 0.9,
    },
  },
  white: {
    background: Colors.white,
    padding: '5px 60px',
    color: '#d64e15',
    boxShadow: '2px 2px 0px 0px #a7fe72',
    '&:hover': {
      boxShadow: '2px 2px 0px 0px #50ddef',
    },
  },
  disabled: {
    opacity: 0.5,
  },
}));

const CustomButton = (props) => {
  const classes = useStyles();
  const { children, status, onClick, style, disabled } = props;

  const statusClass = classes.hasOwnProperty(status) ? classes[status] : classes.primary;
  const buttonClasses = [classes.button, statusClass];
  if (disabled)
    buttonClasses.push(classes.disabled);

  return (
    <Button
      variant="contained"
      classes={{ root: buttonClasses.join(' ') }}
      style={style}
      onClick={disabled ? null : onClick}
    >
      {children}
    </Button>
  );
};

CustomButton.propTypes = {
  style: PropTypes.object,
};

CustomButton.defaultProps = {
  style: {},
};

export default CustomButton;