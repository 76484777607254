import React, { useState } from 'react';
import { Avatar, makeStyles } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import TopBarGems from './TopBarGems';
import SVGIcon from '../SVGIcon/SVGIcon';

import conf from '../../conf';
import CardShopButton from '../Header/CardShopButton';
import MobileDynamicMenu from './MobileDynamicMenu';

const useStyles = makeStyles(() => ({
  root: {
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    background: '#0B1729',
    borderBottom: '3px solid #011024',
    color: Colors.white,
    zIndex: 100,
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
  },
  avatarRoot: {
    height: 36,
    width: 36,
    borderWidth: 1,
  },
  placeArea: {
    color: Colors.white,
    borderLeft: '3px solid rgb(1, 16, 36)',
    height: 62,
    width: 62,
    ...Helpers.fillColCenter,
  },
}));

const MobileTopBar = (props) => {
  const classes = useStyles();
  const { viewer } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <div className={classes.root}>
        <div style={{ padding: '0 8px' }}>
          <Link to="/hq/hub">
            <SVGIcon src={`${conf.cdn_url}/icons/logo.svg`} size={36} />
          </Link>
        </div>

        <div style={{ ...Helpers.fillRowCenter }}>
          <Link to="/premium" className={classes.placeArea}>
            <i className="fa-thin fa-hands-holding-heart" style={{ fontSize: 19 }} />
          </Link>
          <TopBarGems viewer={viewer} />
          {viewer &&
          <div className={classes.placeArea} onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <Avatar
              alt="Avatar"
              src={viewer.profile_image_url}
              classes={{ root: classes.avatarRoot }}
            />
          </div>
          }
          <div className={classes.placeArea}>
            <CardShopButton />
          </div>
        </div>
      </div>
      {isMenuOpen &&
      <MobileDynamicMenu viewer={viewer} onClick={() => setIsMenuOpen(!isMenuOpen)} />
      }
    </>
  );
};

export default MobileTopBar;