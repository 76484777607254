import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CardElement } from '@stripe/react-stripe-js';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { PayPalButton } from 'react-paypal-button-v2';

import { createPremiumOrder as createPremiumOrderMutation } from '../../apollo/mutations';

import AnimationButton from '../Button/AnimationButton';
import Loading from '../Loading/Loading';

import conf from '../../conf.json';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles({
  darkBox: {
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,.6)',
    top: 0,
    left: 0,
    right: 0,
    bottom: 4,
    zIndex: 1500,
    borderRadius: 3,
    cursor: 'not-allowed',
  },
});

const GemsPay = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const {
    t,
    stripe,
    elements,
    gemItem,
    checkedTerms,
    disabled,
    paymentType,
    location,
    onClose,
  } = props;
  const [orderId, setOrderId] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [paypalLoading, setPaypalLoading] = useState(false);

  const [createPremiumOrder] = useMutation(createPremiumOrderMutation, {
    onCompleted: (data) => {
      const { createPremiumOrder } = data;
      setClientSecret(createPremiumOrder.client_secret);
      setOrderId(createPremiumOrder._id);

      /*const { status_code } = createPremiumOrder;
      if (status_code) {
        let msgKey = '';
        if (status_code === 1)
          msgKey = 'error_msg.coupon_not_found';
        else if (status_code === 2)
          msgKey = 'error_msg.coupon_min_amount_not_reached';

        enqueueSnackbar(t(msgKey), { variant: 'error' });
      }*/
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
  });

  useEffect(() => {
    if (checkedTerms)
      createPremiumOrder({
        variables: {
          premiumOfferId: gemItem._id,
        },
      });
  }, [checkedTerms, gemItem, createPremiumOrder]);

  const createOrderHandler = (data, actions) => {
    if (!orderId)
      return null;

    setPaypalLoading(true);

    return actions.order.create({
      redirect_urls: {
        return_url: `${conf.base_url + url}`,
      },
      purchase_units: [{
        amount: {
          currency_code: 'EUR',
          value: (gemItem.costs / 100).toFixed(2),
        },
        description: t('order_cards'),
        invoice_id: orderId,
      }],
    });
  };

  const onSuccess = (data) => {
    setProcessing(true);
    let redirect = url;

    if (data.status === 'COMPLETED')
      redirect += `?redirect_status=succeeded&gems=${gemItem.amount}`;

    onClose();
    props.history.push(redirect);
  };

  /**
   * Paypal onCancel method
   *
   */
  const onCancel = () => {
    enqueueSnackbar(t('order_canceled'), { variant: 'error' });
    setProcessing(false);
    setPaypalLoading(false);
  };

  const handleSubmit = async () => {
    if (!stripe || !elements)
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;

    setProcessing(true);

    const payload = await getPaymentPayload();

    if (payload.error) {
      enqueueSnackbar(`Payment failed ${payload.error.message}`, { variant: 'error' });
      setProcessing(false);
    } else {
      const { status } = payload.paymentIntent;
      setProcessing(false);
      onClose();
      props.history.push(`${url}?redirect_status=${status}&gems=${gemItem.amount}`);
    }
  };

  const getPaymentPayload = async () => {
    if (paymentType === 'card')
      return stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });
  };

  const url = location.pathname;

  return (
    processing ?
      <Loading />
      :
      paymentType === 'paypal' ?
        <div style={{ position: 'relative' }}>
          {(!checkedTerms || paypalLoading) &&
          <div className={classes.darkBox} />
          }
          <PayPalButton
            createOrder={(data, actions) => createOrderHandler(data, actions)}
            onSuccess={(data, details) => onSuccess(data, details)}
            onError={(err) => console.error('onError', err)}
            onCancel={(data) => onCancel(data)}
            options={{
              clientId: conf.paypal_client_id,
              currency: 'EUR',
              disableFunding: 'sepa,giropay,ideal,mybank,p24,venmo,eps,sofort,card,credit',
            }}
          />
        </div>
        :
        <AnimationButton
          status="neonGreen"
          style={{
            border: 'none',
            fontFamily: 'Montserrat',
            fontSize: 14,
            fontWeight: 500,
            padding: '14px 32px',
          }}
          disabled={disabled || !checkedTerms}
          onClick={handleSubmit}
        >
          {t('order_now')}
        </AnimationButton>
  );
};

export default withRouter(GemsPay);
