import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import CardToolCardDesignItem from './CardToolCardDesignItem';
import FramePopup from '../Dialog/FramePopup';
import { rangeArray } from '../../utils/helper';
import Helpers from '../../theme/Helpers';
import CustomTooltip from '../Tooltip/CustomTooltip';
import Colors from '../../theme/Colors';

const CardToolSelectSpecialCustomCard = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  let { cardFrame, setCustomCardFrame } = props;
  const [showSelection, setShowSelection] = useState(false);

  const cardDesigns = rangeArray(1, 25);

  return (
    <>
      <div className={classes.area}>
        <div style={{ width: 130, ...Helpers.textLeft, ...Helpers.fillRow }}>
          {t('select_frame')}
          <CustomTooltip title={t('select_frame_subtitle')}>
            <div style={{ marginLeft: 5 }}>
              <FontAwesomeIcon icon={faInfoCircle} size="lg" color={Colors.white} />
            </div>
          </CustomTooltip>
        </div>
        <div className={classes.clickAndHoverItem} onClick={() => setShowSelection(true)}>
          {cardFrame ?
            <CardToolCardDesignItem
              level={cardFrame.setLevel}
              cardDesign={cardFrame.set}
              size={80}
              marginLeft={-54}
            />
            :
            <div>
              <FontAwesomeIcon icon={faExclamationTriangle} size="10x" color={Colors.primary} />
              <div style={{ marginTop: 10, fontWeight: 'bold' }}>
                {t('no_custom_card_frame_selected')}
              </div>
            </div>
          }
        </div>
      </div>
      {showSelection &&
      <FramePopup
        handleClose={() => setShowSelection(false)}
        cardDesigns={cardDesigns}
        isSpecialCard
        customCardFrame={cardFrame}
        setCustomCardFrame={setCustomCardFrame}
      />
      }
    </>
  );
};

const useStyles = makeStyles(() => ({
  area: {
    backgroundColor: '#010F22',
    padding: 20,
    marginBottom: 10,
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    color: '#747474',
  },
  clickAndHoverItem: {
    cursor: 'pointer',
    marginTop: 20,
    '&:hover': {
      opacity: 0.8,
    },
  },
}));

export default CardToolSelectSpecialCustomCard;