import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import { getDiscordInfo } from '../../apollo/mergedQueries';
import { updateDiscordServerRoleIds as updateDiscordServerRoleIdsMutation } from '../../apollo/mutations';

import PageTitle from '../../components/PageTitle/PageTitle';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import DiscordRoles from '../../components/AlertSettings/DiscordRoles';
import Loading from '../../components/Loading/Loading';

const DiscordRolesContainer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { data, loading } = useQuery(getDiscordInfo, {
    fetchPolicy: useCacheWithExpiration('getDiscordInfo'),
  });

  const [updateDiscordServerRoleIds, updateData] = useMutation(updateDiscordServerRoleIdsMutation, {
    onCompleted: () => {
      enqueueSnackbar(t('success'), { variant: 'success' });
    },
    onError: (data) => {
      enqueueSnackbar(data.message, { variant: 'error' });
    },
    refetchQueries: () => [{ query: getDiscordInfo }],
  });

  const updateServerRoles = (roleSet) => {
    delete roleSet.__typename;

    updateDiscordServerRoleIds({
      variables: {
        discordServerRoleIds: roleSet,
      },
    });
  };

  const roles = data && data.getDiscordRoles;
  const discordSetupProgress = data && data.getDiscordSetupProgress;
  const discordServerInfo = data && data.getDiscordServer;

  return (
    <>
      <PageTitle title={t('discord_roles')} />
      {loading ?
        <Loading />
        :
        <DiscordRoles
          roles={roles}
          discordServerInfo={discordServerInfo}
          discordSetupProgress={discordSetupProgress}
          updateServerRoles={updateServerRoles}
          loading={updateData.loading}
        />
      }
    </>
  );
};

export default DiscordRolesContainer;