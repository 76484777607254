import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import 'moment/min/locales';

import en from '../translations/en';
import de from '../translations/de';
import es from '../translations/es';
import fr from '../translations/fr';
import it from '../translations/it';
import pl from '../translations/pl';
import tr from '../translations/tr';
import ptbr from '../translations/ptbr';

const conf = require('../conf');

i18n
.use(LanguageDetector)
.use(initReactI18next)
.init({
  // we init with resources
  resources: {
    en: {
      translations: en,
    },
    de: {
      translations: de,
    },
    es: {
      translations: es,
    },
    fr: {
      translations: fr,
    },
    it: {
      translations: it,
    },
    pl: {
      translations: pl,
    },
    tr: {
      translations: tr,
    },
    pt: {
      translations: ptbr,
    },
  },
  fallbackLng: 'en',
  debug: false,
  detection: {
    lookupCookie: conf.storage_keys.lang_token,
  },

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: ".",

  interpolation: {
    escapeValue: false,
  },
});

moment.locale(i18n.language);

export default i18n;
