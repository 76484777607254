import React from 'react';
import { useTranslation } from 'react-i18next';

import CustomTooltip from '../Tooltip/CustomTooltip';

const conf = require('../../conf');

const SupporterRank = (props) => {
  const { t } = useTranslation();
  const { subscription, showNone } = props;

  let premiumMask = {
    title: 'premium_no_supporter',
    img: 'mask_unfilled',
  };
  const subscriptionTier = subscription && subscription.tier;

  if (subscriptionTier) {
    if (subscriptionTier === 'Gold')
      premiumMask = {
        title: 'premium_supporter',
        img: 'goldmask',
      };
    else if (subscriptionTier === 'Silver')
      premiumMask = {
        title: 'standard_supporter',
        img: 'mask_silver',
      };
    else if (subscriptionTier === 'Bronze' || subscriptionTier === 'Sidekick')
      premiumMask = {
        title: 'basic_supporter',
        img: 'mask_bronze',
      };
  }

  if (!showNone && premiumMask.img === 'mask_unfilled')
    return null;

  return (
    <CustomTooltip title={t(premiumMask.title)}>
      <img
        src={`${conf.cdn_url}/assets/template/${premiumMask.img}.png`}
        alt={t(premiumMask.title)}
      />
    </CustomTooltip>
  );
};

export default SupporterRank;