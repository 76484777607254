import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Helpers from '../../theme/Helpers';
import CircleButton from '../Button/CircleButton';
import QualityLine from '../QualityLine/QualityLine';
import Avatar from '@material-ui/core/Avatar';
import { getLeagueImage } from '../../utils/helper';
import Colors from '../../theme/Colors';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';

const useStyles = makeStyles({
  content: {
    ...Helpers.fillRowCenter,
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    marginTop: 10,
    padding: 10,
    height: 77,
    fontSize: 14,
    position: 'relative',
  },
  indicatorsContainer: {
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    marginTop: 12,
    padding: 10,
    height: 100,
    fontSize: 14,
    ...Helpers.fillRow,
    flexWrap: 'wrap',
  },
  miniCardMock: {
    ...Helpers.fillRowCenter,
    background: 'rgba(255, 255, 255, 0.08)',
    height: 77,
    width: 52,
    minWidth: 52,
    borderRadius: 3,
  },
  cardsPrice: {
    ...Helpers.fillColCenter,
    width: '100%',
    fontSize: 16,
    fontWeight: 600,
    '& span': {
      fontSize: 12,
      fontWeight: 300,
      color: Colors.halfWhite,
    },
  },
  capsuleDetails: {
    marginTop: 8,
    width: '100%',
    fontSize: 12,
    opacity: 0.5,
    ...Helpers.fillRowCenter,
  },
  communityContent: {
    ...Helpers.fillRowCenter,
    width: '100%',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  upRankArea: {
    position: 'absolute',
    left: 32,
    bottom: 0,
    width: 22,
    height: 22,
    borderRadius: '50%',
    background: 'rgba(255, 255, 255, 0.26)',
    ...Helpers.fillRowCenter,
  },
  midAvatar: {
    width: 54,
    height: 54,
    border: 'none',
    marginRight: 12,
  },
  circleLabeled: {
    ...Helpers.fillRowCross,
    width: '50%',
    fontSize: '12px',
    fontWeight: 300,
    '& > div': {
      marginRight: 10,
      fontSize: '16px',
      width: 44,
      height: 44,
      minWidth: 44,
      minHeight: 44,
    },
  },
  coloredBGLine: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: 3,
    borderRadius: '6px 6px 0px 0px',
  },
});

const AlertCapsuleDetails = (
  {
    community,
    card,
    order,
    t,
    allowTwitch,
    allowDiscord,
    allowSpeech,
    streamer,
    style,
    levelWord,
  },
) => {
  const classes = useStyles();

  function getContent() {
    if (community) {
      return (
        <div className={classes.communityContent}>
          <Avatar
            className={classes.midAvatar}
            alt="Avatar"
            src={getLeagueImage(community.league_string, 54)}
          />
          {`${streamer.display_name} - ${t(community.league_string)}`}
          <CustomBackdropFilter
            className={classes.upRankArea}
            filter={'blur(50px)'}
            canvasFallback={true}
            html2canvasOpts={{
              allowTaint: true,
            }}
          >
            <i className="fa-solid fa-triangle" style={{ fontSize: 9, color: '#1FE325' }} />
          </CustomBackdropFilter>
        </div>
      );
    } else if (order) {
      const amount_in_cent = order.amount_in_cent.toString();
      const value =
        amount_in_cent.slice(0, -2) + ',' + amount_in_cent.slice(-2);
      const cards = order.order.card_products;

      return (
        <>
          {cards &&
          cards
          .slice(0, 3)
          .map((card, index) => (
            <img
              key={card.product_id}
              src={`${card.processing.design.url}?height=77`}
              alt="Card"
              style={{ borderRadius: 3, marginRight: 4, marginLeft: index === 0 ? -2 : 0 }}
            />
          ))}
          {cards.length <= 3 ? null : (
            <div className={classes.miniCardMock}> + {cards.length - 3}</div>
          )}
          <div className={classes.cardsPrice}>
            <span>{t('purchased')}</span>
            {value} €
          </div>
        </>
      );
    } else if (card) {
      const imageUrl = card.card && card.card.front_layers[0].img_url;
      const levelColor = Colors.level[levelWord];
      return (
        <>
          <div
            className={classes.coloredBGLine}
            style={{
              background: `linear-gradient(90deg, ${levelColor}00 0%, ${levelColor} 49%, ${levelColor}00 100%)`,
            }}
          />
          {imageUrl ? (
            <img
              src={`${imageUrl}?height=77`}
              alt="Card"
              style={{ borderRadius: 3, margin: '0 0 -2px -2px' }}
            />
          ) : (
            <div className={classes.miniCardMock} />
          )}
          <div
            style={{
              display: 'block',
              width: '100%',
              padding: '0 6px 0 16px',
            }}
          >
            <QualityLine
              quality={card.quality}
              spaceBetween={1}
            />
          </div>
        </>
      );
    }
  }

  return (
    <div style={style}>
      <div className={classes.content}>{getContent()}</div>
      <div className={classes.indicatorsContainer}>
        {allowTwitch && (
          <div className={classes.circleLabeled}>
            <CircleButton>
              <i className="fa-brands fa-twitch" />
            </CircleButton>
            {t('twitch_alert')}
          </div>
        )}
        {allowDiscord && (
          <div className={classes.circleLabeled}>
            <CircleButton>
              <i className="fa-brands fa-discord" />
            </CircleButton>
            {t('discord_alert')}
          </div>
        )}
        {allowSpeech &&
        <>
          <div className={classes.circleLabeled}>
            <CircleButton>
              <i className="fa-light fa-waveform" />
            </CircleButton>
            {t('text_to_speech')}
          </div>
          <div className={classes.circleLabeled}>
            <CircleButton>
              <i className="fa-light fa-font-case" />
            </CircleButton>
            {t('max_symbols')}
          </div>
        </>
        }
        {(!allowTwitch && !allowDiscord) &&
        <div
          style={{ color: Colors.halfWhite, fontSize: 14, ...Helpers.textCenter, marginTop: 30 }}>
          {t('streamer_disabled_alert')}
        </div>
        }
      </div>
    </div>
  );
};

export default AlertCapsuleDetails;
