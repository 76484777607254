import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isMobile } from 'react-device-detect';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import Loading from '../Loading/Loading';
import GroupedInventoryStreamer from '../Inventory/GroupedInventoryStreamer';
import CardShopSHSupportChart from './CardShopSHSupportChart';
import CardShopPhysicalCard from './CardShopPhysicalCard';
import CardShopBreadcrumbs from '../Breadcrumbs/CardShopBreadcrumbs';
import CardShopStreamerSearchContainer from '../../containers/CardShop/CardShopStreamerSearch';
import CardShopTweets from './CardShopTweets';
import CardShopSupporterBundle from './CardShopSupporterBundle';
import { isUserPremium } from '../../utils/helper';
import InnerHeader from '../Layout/InnerHeader';

import conf from '../../conf.json';

const useStyles = makeStyles((theme) => ({
  innerWrapper: {
    maxWidth: 1440,
    margin: '100px auto',
  },
  iconArea: {
    transition: 'all 0.3s ease-out',
    ...Helpers.insetZero,
    zIndex: -1,
    filter: 'blur(6px)',
    background: '#011024',
    opacity: 0.12,
  },
  headerLineItem: {
    ...Helpers.fillRowCross,
    height: 200,
    background: 'rgba(255, 255, 255, 0.04)',
    transition: 'all 0.3s ease-out',
    cursor: 'pointer',
    '& div': {
      transition: 'all 0.3s ease-out',
    },
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.08)',
      '& > div:last-child': {
        '& > div:first-child': {
          textDecoration: 'underline',
        },
        '& > div:last-child': {
          color: Colors.white + ' !important',
        },
      },
    },
  },
  gridArea: {
    display: 'grid',
    gap: 3,
    gridTemplateColumns: 'repeat(3, 1fr)',
    marginTop: 3,

    [theme.breakpoints.down('md')]: {
      ...Helpers.fillCol,
    },
  },
  titleLine: {
    ...Helpers.fillRowCenter,
    ...Helpers.mainSpaceBetween,
    marginBottom: 27,

    [theme.breakpoints.down('md')]: {
      ...Helpers.fillCol,
    },
  },
  supportArea: {
    width: 'calc(100% - 140px)',
    background: 'rgba(255, 255, 255, 0.04)',
    padding: 70,

    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 16px)',
      padding: 8,
    },
  }
}));

const CardShop = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    matches,
    loading,
    onLoadMore,
    hasMore,
    match,
    setMatch,
    tweets,
    myViewer,
    shopBoosterPacks,
  } = props;

  const headerLineItems = [
    {
      icon: `${conf.cdn_url}/assets/template/shop/sh_cardshop_icon_support.png`,
      headText: 'support_streamer_buying_cards',
      desc: 'support_streamer_buying_cards_desc',
      anchor: '#support-area',
    },
    {
      icon: `${conf.cdn_url}/assets/template/shop/sh_cardshop_icon_physicalcards.png`,
      headText: 'get_physical_card_home',
      desc: 'get_physical_card_home_desc',
      anchor: '#card-explanation',
    },
    {
      icon: `${conf.cdn_url}/assets/template/shop/sh_cardshop_icon_capsule.png`,
      headText: 'get_alert_capsule_streamer',
      desc: 'get_alert_capsule_streamer_desc',
      anchor: '#support-area',
    },
  ];

  const scrollTo = (anchor) => {
    const element = document.querySelector(anchor);
    const topPos = element.getBoundingClientRect().top + window.scrollY;

    window.scrollTo({
      top: topPos, // scroll so that the element is at the top of the view
      behavior: 'smooth', // smooth scroll
    });
  };

  const { isPremiumActive } = isUserPremium(myViewer);

  return (
    <div style={{ color: Colors.white, position: 'relative' }}>
      {!isMobile &&
      <CardShopBreadcrumbs />
      }

      <InnerHeader
        showAd={conf.ad_area.card_shop && myViewer && !isPremiumActive}
        title={t('sh_better_support')}
        desc={t('sh_better_support_desc')}
      />

      <div className={classes.gridArea}>
        {headerLineItems.map(item => (
          <div className={classes.headerLineItem} onClick={() => scrollTo(item.anchor)}>
            <div style={{
              width: 168,
              height: '100%',
              ...Helpers.fillRowCenter,
              position: 'relative',
              overflow: 'hidden',
            }}>
              <div className={classes.iconArea}>
                <div style={{
                  backgroundImage: `url("${item.icon}")`,
                  backgroundSize: '100%',
                  backgroundPosition: 'center',
                  position: 'absolute',
                  top: '-48%',
                  left: '-50%',
                  width: '195%',
                  height: '195%',
                }} />
              </div>
              <img src={item.icon} alt="Icon" />
            </div>

            <div style={{ ...Helpers.fillColMain, padding: 32 }}>
              <div style={{ fontWeight: 500 }}>
                {t(item.headText)}
              </div>
              <div style={{
                fontSize: 14,
                color: Colors.halfWhite,
                marginTop: 12,
                maxWidth: 384,
                lineHeight: 1.5,
              }}>
                {t(item.desc)}
              </div>
              <div
                style={{ fontSize: 14, color: Colors.halfWhite, marginTop: 32, fontWeight: 500 }}
              >
                {t('learn_more')}
                <i className="fa-regular fa-chevron-right"
                   style={{ fontSize: 14, marginLeft: 8 }} />
              </div>
            </div>
          </div>
        ))}
      </div>

      {!isMobile &&
      <div className={classes.innerWrapper}>
        <div style={{ fontSize: 24, fontWeight: 600, marginBottom: 27 }}>
          {t('ultimate_streamer_supporter_bundle')}
        </div>
        <CardShopSupporterBundle loading={loading} shopBoosterPacks={shopBoosterPacks} />
      </div>
      }

      <div className={classes.innerWrapper}>
        <div className={classes.titleLine}>
          <div style={{ fontSize: 24, fontWeight: 600 }}>
            {t('which_streamer_support')}
          </div>

          <CardShopStreamerSearchContainer setMatch={setMatch} match={match} />
        </div>
        {loading ?
          <Loading />
          :
          <InfiniteScroll
            dataLength={matches ? matches.streamer_cards.length : 0}
            next={onLoadMore}
            hasMore={hasMore}
            loader={<Loading />}
            style={{ width: '100%', overflowX: 'hidden' }}
          >
            <div style={{
              display: 'grid',
              gap: 8,
              gridTemplateColumns: 'repeat(4, 1fr)',
            }}>
              {matches && matches.streamer_cards.map(data => (
                <GroupedInventoryStreamer
                  key={data.streamer ? data.streamer.login : data.card_count}
                  data={data}
                  isBigger
                  isCardShop
                />
              ))}
            </div>
          </InfiniteScroll>
        }
      </div>

      <div id="support-area" className={classes.supportArea}>
        <div className={classes.innerWrapper} style={{ margin: '0 auto' }}>
          <CardShopSHSupportChart />
        </div>
      </div>

      <div
        id="card-explanation"
        className={classes.innerWrapper}
        style={{ ...Helpers.fillRowMain, ...Helpers.mainSpaceBetween, marginBottom: 0 }}
      >
        <CardShopPhysicalCard />
      </div>

      <CardShopTweets tweets={tweets} />

    </div>
  );
};

export default CardShop;