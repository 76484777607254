import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Helpers from '../../theme/Helpers';
import { getMatchData } from '../../utils/helper';
import Colors from '../../theme/Colors';
import AnimationButton from '../Button/AnimationButton';
import { toggleNewCardInShop, updateAmountInAlbum, updateCardInCart } from '../../redux/actions';
import NicePrice from '../Utils/NicePrice';
import CardShopMatchCardRightSideStreamerItem from './CardShopMatchCardRightSideStreamerItem';
import { useQuery } from '@apollo/react-hooks';
import { isOrderPossible } from '../../apollo/queries';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

const useStyles = makeStyles(() => ({
  avatar: {
    border: 'none',
    height: 82,
    width: 82,
    marginRight: 16,
  },
  streamerItem: {
    background: 'rgba(255, 255, 255, 0.04)',
    width: 'calc(100% - 64px)',
    padding: '22px 32px',
    ...Helpers.fillRowCross,
    marginRight: 3,
    position: 'relative',
    overflow: 'hidden',
  },
}));

const CardShopMatchCardRightSide = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { loading, card, offer, isLegacy, cardShop } = props;

  const variables = { offer: offer && offer._id };
  const { data } = useQuery(isOrderPossible, {
    variables,
    fetchPolicy: useCacheWithExpiration('isOrderPossible', variables),
    skip: !offer,
  });

  const orderPossible = (data) && data.isOrderPossible;

  const infoSteps = [
    {
      icon: 'hand-holding-heart',
      title: 'support_the_streamer',
      desc: 'support_the_streamer_desc',
    },
    {
      icon: 'card-heart',
      title: 'your_own_physical_card',
      desc: 'your_own_physical_card_desc',
    },
    {
      icon: 'earth-europe',
      title: 'international_delivery',
      desc: 'international_delivery_desc',
    },
  ];

  const {
    avatar,
    name,
  } = getMatchData(isLegacy ? card.match_card : card ? card.card_template_metadata : (offer ? offer : {}), 82, 82);
  const cardObj = isLegacy ? (card.specialcard ? card.specialcard : card.card) : card;
  const offerCosts = offer && offer.products.reduce((acc, product) => acc + product.costs, 0);
  const price = cardObj ? (cardObj.discounted_price ? cardObj.discounted_price : cardObj.price) / 100 : 0;
  const cardInCart = (!isLegacy && cardShop.newCards && card) ? cardShop.newCards.find(csCard => csCard._id === card._id) : false;

  const parentElem = document.getElementById('card-shop-card-view');

  const buyAlbum = () => {
    const albumProduct = cardShop.albums && cardShop.albums.find(albumProduct => albumProduct._id === offer._id);
    const amount = albumProduct && albumProduct.amount ? albumProduct.amount : 0;

    props.updateAmountInAlbum({
      ...offer,
      amount: amount + 1,
      name: offer.title,
    });
  };

  return (
    <div style={{
      width: `${parentElem ? `${parentElem.clientWidth * 0.333}px` : '33.3%'}`,
      position: 'fixed',
      top: 65,
      right: 0,
    }}>

      <CardShopMatchCardRightSideStreamerItem
        name={name}
        avatar={avatar}
        loading={loading}
        offer={offer}
      />

      <div style={{
        background: 'rgba(255, 255, 255, 0.04)',
        marginTop: 3,
        padding: 32,
        height: '100vh',
      }}>

        <div
          style={{ border: '1px solid rgba(255, 255, 255, 0.08)', borderRadius: 4, padding: 20 }}
        >
          {infoSteps.map((step, index) => (
            <>
              <div style={{ ...Helpers.fillRow, ...Helpers.crossStart }}>
                <div style={{ minWidth: 24, ...Helpers.fillRowCenter, marginRight: 14 }}>
                  <i className={`fa-light fa-${step.icon}`} style={{ fontSize: 22 }} />
                </div>
                <div style={{ fontSize: 14 }}>
                  <div style={{ fontWeight: 500 }}>
                    {t(step.title)}
                  </div>
                  <div style={{ color: Colors.halfWhite, marginTop: 4 }}>
                    {t(step.desc)}
                  </div>
                </div>
              </div>
              {index < infoSteps.length - 1 &&
              <div style={{
                margin: '19.5px 0',
                width: '100%',
                height: 1,
                background: 'rgba(255, 255, 255, 0.08)',
              }} />
              }
            </>
          ))}
        </div>

        <div style={{ marginTop: 25, fontSize: 14 }}>
          <div style={{ fontWeight: 500 }}>
            {t('description')}
          </div>
          <div style={{ color: Colors.halfWhite, marginTop: 4 }}
               dangerouslySetInnerHTML={{ __html: t('description_desc') }} />
        </div>

        <div style={{
          marginTop: 20,
          fontSize: 14,
          ...Helpers.fillRowCenter,
          ...Helpers.mainSpaceBetween,
          padding: 12,
          background: 'rgba(255, 255, 255, 0.04)',
          borderRadius: 4,
        }}>
          <div>
            {t('price')}
          </div>

          <NicePrice price={card ? price : offer && (offerCosts / 100)} mainFS={46} supFS={18}
                     eurFS={26}
                     eurML={6} />
        </div>

        {card && card.ordered_at ?
          <div style={{
            color: '#1FE325',
            background: 'rgba(255, 255, 255, 0.04)',
            borderRadius: 4,
            padding: '14px 18.5px',
            marginTop: 6,
          }}>
            <i className="fa-light fa-circle-check"
               style={{ fontSize: 18, marginRight: 8 }} />
            {t('purchased')}
          </div>
          :
          <AnimationButton
            status={cardInCart ? 'red' : 'neonGreen'}
            onClick={() => card ? props.toggleNewCardInShop(cardObj) : offer ? buyAlbum() : null}
            disabled={orderPossible && !orderPossible.possible}
            style={{
              width: '100%',
              fontFamily: 'Montserrat',
              fontSize: 14,
              padding: '14px 16px',
              borderRadius: 4,
              fontWeight: 500,
              marginTop: 6,
            }}
          >
            <i
              className={`fa-light fa-cart-${cardInCart ? 'minus' : 'plus'}`}
              style={{ fontSize: 18, marginRight: 8, verticalAlign: 'bottom' }}
            />
            {t(cardInCart ? 'remove_from_cart' : 'add_to_cart')}
          </AnimationButton>
        }

        {(card || offer) &&
        <div style={{ marginTop: 4, fontSize: 12, color: Colors.halfWhite }}>
          {t('card_id')} {card && card._id}{offer && offer._id}
        </div>
        }

      </div>

    </div>
  );
};

const mapStateToProps = (state) => {
  const { cardShop } = state;
  return { cardShop };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    updateCardInCart,
    updateAmountInAlbum,
    toggleNewCardInShop,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(CardShopMatchCardRightSide);