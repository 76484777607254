import React from 'react';

import PollsList from './PollsList';
import PollForm from './PollForm';
import PollView from './PollView';

const Polls = (props) => {
  const [status, setStatus] = React.useState('list');
  const [poll, setPoll] = React.useState(null);

  const { polls } = props;

  return (
    <React.Fragment>
      {status === 'list' &&
      <PollsList polls={polls} setStatus={setStatus} setPoll={setPoll} />
      }
      {(status === 'edit' || status === 'new') &&
      <PollForm poll={poll} status={status} setStatus={setStatus} setPoll={setPoll} />
      }
      {status === 'view' &&
      <PollView poll={poll} setStatus={setStatus} setPoll={setPoll} />
      }
    </React.Fragment>
  );
};

export default Polls;