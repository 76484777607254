import React from 'react';
import { useTranslation } from 'react-i18next';

import Colors from '../../theme/Colors';
import QualityLine from '../QualityLine/QualityLine';

const CardExplanationQuality = () => {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ fontSize: 20, fontWeight: 500, color: Colors.white, marginBottom: 12 }}>
        3. {t('the_quality')}
      </div>

      <div
        style={{
          fontSize: 16,
          fontWeight: 200,
          color: Colors.halfWhite,
          marginBottom: 30,
        }}
      >
        {t('the_quality_body')}
      </div>

      <QualityLine quality={82} spaceBetween={4} width={[50, 206, 303, 101, 41]} />

      <div style={{
        fontSize: 16,
        fontWeight: 200,
        color: Colors.halfWhite,
        marginTop: 20,
      }}>
        {t('the_quality_body_2')}
      </div>

    </>
  );
};

export default CardExplanationQuality;