import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useQuery } from '@apollo/react-hooks';

import Loading from '../Loading/Loading';
import SocialShareButtons from '../SocialMediaLinks/SocialShareButtons';
import { getShortURL } from '../../apollo/queries';
import Colors from '../../theme/Colors';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';

const conf = require('../../conf');

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    backgroundImage: `url(${process.env.PUBLIC_URL}/social_media_bg.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    padding: '20px 30px',
    color: Colors.white,
    whiteSpace: 'normal',
    maxWidth: 402,
    borderRadius: 8,
    marginTop: 20,
  },
  headTitle: {
    color: '#b60b28',
    fontSize: 30,
    fontFamily: 'Bebas Neue',
    fontWeight: 400,
  },
}));

const CardViewSocial = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { card, viewerId } = props;
  const variables = { url: `/card/${card._id}/${viewerId}` };
  const { data } = useQuery(getShortURL, {
    variables,
    fetchPolicy: useCacheWithExpiration('getShortURL', variables),
  });

  return (
    <>
      <div className={classes.container}>
        <Typography variant="h3" className={classes.headTitle}>
          {t('social_share_title')}
        </Typography>
        <div style={{ fontSize: 14, color: Colors.halfWhite }}>
          {t('social_share_body', { streamer: card.match.display_name })}
        </div>
      </div>
      <div style={{ marginTop: 10 }}>
        {data && data.shortnerLink ?
          <SocialShareButtons url={`${conf.base_url}/go/${data.shortnerLink.key}`} />
          :
          <Loading />
        }
      </div>
    </>
  );
};

export default CardViewSocial;