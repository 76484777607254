import { getCardsFromDeck, getCardDecks } from '../apollo/mergedQueries';

export const removeCardsFromSpecificStorageBox = (cache, cards, variables, callMethod) => {
  try {
    const returnCards = [];
    const existingGetCardsFromDeck = cache.readQuery({
      query: getCardsFromDeck,
      variables,
    });

    if (existingGetCardsFromDeck && variables) {
      const newData = { ...existingGetCardsFromDeck };
      for (let i = 0; i < cards.length; i++) {
        const findIndex = newData.getCardsFromDeck.findIndex(card => card._id === cards[i]);
        returnCards.push(newData.getCardsFromDeck[findIndex]);
        newData.getCardsFromDeck.splice(findIndex, 1);
      }

      cache.writeQuery({
        query: getCardsFromDeck,
        variables,
        data: newData,
      });

      callMethod();

      return returnCards;
    }
  } catch (e) {
    console.error(e);
  }
};

export const updateStorageBoxes = (cache, boxId, card_count_difference, callMethod) => {
  try {
    const existingGetCardDecks = cache.readQuery({
      query: getCardDecks,
    });

    if (existingGetCardDecks) {
      const newData = { ...existingGetCardDecks };
      const findIndex = newData.getCardDecks.findIndex(box => box._id === boxId);
      newData.getCardDecks[findIndex].card_count += card_count_difference;

      cache.writeQuery({
        query: getCardDecks,
        data: newData,
      });

      callMethod();
    }
  } catch (e) {
  }
};

export const addCardsToSpecificStorageBox = (cache, cards, variables, callMethod) => {
  try {
    const existingGetCardsFromDeck = cache.readQuery({
      query: getCardsFromDeck,
      variables,
    });

    if (existingGetCardsFromDeck && variables) {
      const newData = { ...existingGetCardsFromDeck };
      newData.getCardsFromDeck = [...newData.getCardsFromDeck, ...cards];

      cache.writeQuery({
        query: getCardsFromDeck,
        variables,
        data: newData,
      });

      callMethod();
    }
  } catch (e) {
  }
};