import * as React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import FlagIcon from './FlagIcon';
import Colors from '../../theme/Colors';

const useStyles = makeStyles(theme => ({
  roundedFlag: {
    borderRadius: '50%',
    border: '2px solid ' + Colors.roundedBG,
  },
  withMargin: {
    marginLeft: 8,
    marginRight: 8,
  },
}));

const RoundedFlagIcon = (props) => {
  const classes = useStyles();
  const { code, size, withMargin } = props;

  const styles = [classes.roundedFlag];
  if (withMargin) styles.push(classes.withMargin);

  return <FlagIcon
    code={code.toLowerCase()}
    size={size}
    squared
    className={styles.join(' ')}
  />;
};

RoundedFlagIcon.propTypes = {
  code: PropTypes.string.isRequired,
  size: PropTypes.string,
  withMargin: PropTypes.bool,
};

RoundedFlagIcon.defaultProps = {
  size: '2x',
  withMargin: true,
};

export default RoundedFlagIcon;