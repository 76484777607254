import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CardPopup from '../Dialog/CardPopup';
import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import CustomTooltip from '../Tooltip/CustomTooltip';
import { downloadImage } from '../../utils/helper';
import CustomButton from '../Button/CustomButton';

const useStyles = makeStyles(() => ({
  header: {
    background: 'linear-gradient(to right, #7d091c, #780c1e, #5c0d1b, #440a13)',
    padding: 20,
    color: Colors.white,
    position: 'relative',
    paddingRight: 50,
  },
  body: {
    padding: 20,
    background: 'linear-gradient(to top right, #6d0e1e, #b40b28, #620f1c)',
    color: '#e094a1',
  },
  title: {
    fontSize: 30,
    fontFamily: 'Bebas Neue',
    lineHeight: 1,
  },
  subTitle: {
    color: '#e9a5b1',
    lineHeight: 1,
  },
  container: {
    marginBottom: 30,
    maxWidth: 800,
  },
  colorSquare: {
    width: 30,
    height: 30,
    borderRadius: 4,
  },
  description: {
    border: '1px solid #404040',
    borderRadius: 4,
    padding: 10,
    margin: 5,
    backgroundColor: '#121212',
  },
  imageItem: {
    maxWidth: 200,
    maxHeight: 200,
    border: '1px solid white',
    padding: 5,
    margin: 5,
    borderRadius: 4,
  },
}));

/**
 * @return {null}
 */
function DesignJobPopup (props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { handleClose, job } = props;

  const getStyle = (style) => {
    if (style === 0)
      return 'Photo';
    else if (style === 1)
      return 'Realistic';
    else if (style === 2)
      return 'Cartoon';
    else if (style === 3)
      return 'Logo';
  };

  const downloadAll = (upics) => {
    for (let i in upics)
      downloadImage(upics[i]);
  };

  const description = [
    {
      level: 0,
      request_description: job.request_description,
      request_primary_color_info: job.request_primary_color_info,
      request_secondary_color_info: job.request_secondary_color_info,
    },
    ...job.card_request_info,
  ];

  return (
    <CardPopup
      handleClose={handleClose}
      style={{
        backgroundColor: 'transparent',
        width: 'auto',
        maxHeight: 'auto',
        overflow: 'auto',
        padding: 0,
      }}
    >
      <div className={classes.header}>
        <FontAwesomeIcon
          icon={faTimes}
          size="2x"
          style={{ position: 'absolute', right: 20, cursor: 'pointer' }}
          onClick={handleClose}
        />
        <div className={classes.title}>
          {t('design_job_briefing_title')}
        </div>
        <div className={classes.subTitle}>
          {t('design_job_briefing_subtitle')}
        </div>
      </div>
      <div className={classes.body}>
        <div className={classes.container}>
          <div className={classes.description}>
            <b>{t('polls_table_created_at')}:</b> {moment.unix(job.created_at / 1000)
          .format('DD.MM.YYYY HH:mm')}<br />
            <b>{t('selected_style')}:</b> {getStyle(job.illustration_style)}<br />
            <b>{t('language')}:</b> {job.language && job.language === 'US' ? 'EN' : job.language}
          </div>
          <div>
            {description.map(desc => (
              <div
                className={classes.description}
                key={`JobPopupDescriptionKey${job._id}Lvl${desc.level}`}
              >
                <div style={{ ...Helpers.fillRowCenter, ...Helpers.mainSpaceBetween }}>
                  <div
                    style={{
                      textTransform: 'uppercase',
                      fontWeight: 'bold',
                      width: 200,
                      fontSize: 18,
                    }}
                  >
                    {desc.level === 0 ?
                      t('description')
                      :
                      <>
                        {t('level0_x', { level: desc.level })} <span style={{ color: '#878686' }}>
                        {t('card')}
                      </span>
                      </>
                    }
                  </div>
                  <div style={Helpers.fillRowCenter}>
                    {(desc.request_primary_color_info) &&
                    <CustomTooltip title={desc.request_primary_color_info}>
                      <div
                        className={classes.colorSquare}
                        style={{ backgroundColor: desc.request_primary_color_info }}
                      />
                    </CustomTooltip>
                    }
                    {(desc.request_secondary_color_info) &&
                    <CustomTooltip title={desc.request_secondary_color_info}>
                      <div
                        className={classes.colorSquare}
                        style={{ backgroundColor: desc.request_secondary_color_info }}
                      />
                    </CustomTooltip>
                    }
                  </div>
                </div>
                <div style={{ color: '#7A7A7A', flex: 1, marginTop: 10, fontSize: 13 }}>
                  {desc.request_description && desc.request_description}
                </div>
              </div>
            ))}
          </div>
          {job.file_uploads_user &&
          <>
            <CustomButton
              onClick={() => downloadAll(job.file_uploads_user)}
              style={{ margin: 5 }}
            >
              {t('download_all_user_images')}
            </CustomButton>
            <div style={{ ...Helpers.fillRowCross, ...Helpers.mainSpaceBetween, flexWrap: 'wrap' }}>
              {job.file_uploads_user.map((img, index) =>
                <img
                  key={`PreviewUploaded${index}`}
                  src={img}
                  alt={`PreviewUploadedImage`}
                  className={classes.imageItem}
                />,
              )}
            </div>
          </>
          }
        </div>
      </div>
    </CardPopup>
  );
}

export default DesignJobPopup;