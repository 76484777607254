import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';

import Helpers from '../../theme/Helpers';
import Colors from '../../theme/Colors';
import GrantDiscordAccess from './GrantDiscordAccess';
import { getLeagueImage, rangeArray } from '../../utils/helper';
import CreateAlertSelect from './CreateAlertSelect';
import AnimationButton from '../Button/AnimationButton';

import conf from '../../conf.json';
import HoverIcon from '../Icon/HoverIcon';

const useStyles = makeStyles(() => ({
  table: {
    color: Colors.white,
    width: '100%',
    fontSize: 14,
    '& thead': {
      backdropFilter: 'blur(50px)',
      background: 'rgba(255, 255, 255, 0.04)',
      '& th': {
        padding: 8,
        ...Helpers.textLeft,
        color: Colors.halfWhite,
        fontWeight: 400,
      },
    },
    '& tbody': {
      '& td': {
        padding: '12px 8px',
        width: '50%',
        borderBottom: '1px solid rgba(255, 255, 255, 0.08)',
      },
    },
  },
}));

const DiscordRoles = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    discordServerInfo,
    roles,
    discordSetupProgress,
    updateServerRoles,
    loading,
  } = props;
  const [rolesSet, setRolesSet] = useState(discordServerInfo && discordServerInfo.roles ? discordServerInfo.roles : {});

  const getLeagueState = (leagueKey) => (
    <div>
      <img
        src={getLeagueImage(leagueKey, 24)}
        alt="League graphic"
        style={{ marginRight: 3, verticalAlign: 'bottom' }}
      />
      {t(`rank_${leagueKey}`)}
    </div>
  );

  const changeLeagueRole = (value, league) => {
    const tmpRoleSet = { ...rolesSet };
    tmpRoleSet[league] = value;

    setRolesSet(tmpRoleSet);
  };

  const ranks = 5;

  return (
    <>
      <GrantDiscordAccess discordSetupProgress={discordSetupProgress} />

      <div style={{ width: '100%', ...Helpers.fillRowReverse }}>
        <AnimationButton
          status="neonRed"
          style={{ padding: '8px 10px', margin: '10px 0' }}
          disabled={loading || !roles}
          onClick={() => updateServerRoles(rolesSet)}
        >
          {loading ?
            <i className="fa-light fa-loader fa-spin" style={{ fontSize: 18 }} />
            :
            t('save_publish')
          }
        </AnimationButton>
      </div>

      <table className={classes.table}>
        <thead>
        <tr>
          <th>{t('rank_type')}</th>
          <th>{t('role')}</th>
        </tr>
        </thead>
        <tbody>
        {rangeArray(1, ranks)
        .map(rank => {
          const leagueKey = conf.league_keys[rank - 1];

          return (
            <tr key={rank}>
              <td>
                {getLeagueState(leagueKey)}
              </td>
              <td>
                <div style={{ ...Helpers.fillRowCenter }}>
                  <CreateAlertSelect
                    value={rolesSet && rolesSet[leagueKey]}
                    handleChange={(event) => changeLeagueRole(event.target.value, leagueKey)}
                    disabled={!roles}
                    id={`role-${rank}`}
                    style={{ marginTop: 0 }}
                  >
                    {roles && roles.map(role => (
                      <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
                    ))}
                  </CreateAlertSelect>
                  <HoverIcon
                    icon="fa-thin fa-xmark"
                    color={Colors.white}
                    hoverColor={Colors.halfWhite}
                    style={{ fontSize: 17, marginLeft: 16 }}
                    onClick={() => changeLeagueRole(null, leagueKey)}
                  />
                </div>
              </td>
            </tr>
          );
        })}
        </tbody>
      </table>
    </>
  );
};

export default DiscordRoles;