import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { StickyContainer } from 'react-sticky';

import Colors from '../../theme/Colors';
import Helpers from '../../theme/Helpers';
import AllSideFilters from '../Filter/AllSideFilters';
import Loading from '../Loading/Loading';
import TopBG from '../TopViewHeader/TopBG';
import AlertCapsule from './AlertCapsule';
import SortingLine from '../Filter/SortingLine';
import moment from 'moment';
import LoadMore from '../Loading/LoadMore';
import CardShopMatchFilters from '../CardShop/CardShopMatchFilters';

const useStyles = makeStyles({
  cardsContainer: {
    display: 'grid',
    gap: 9,
    width: '100%',
    transition: 'all 0.6s ease-out',
    marginTop: 70,
  },
  menuButton: {
    width: 40,
    height: 40,
    borderRadius: 6,
    background: '#FFFFFF14',
    ...Helpers.fillRowCenter,
    transition: 'all 0.3s ease-out',
    cursor: 'pointer',
    '&.active': {
      background: '#E12138',
    },
    '&:hover': {
      background: '#FFFFFF3D',
    },
  },
});

const AlertCapsules = (props) => {
  const classes = useStyles();
  const {
    loading,
    t,
    capsules,
    sorting,
    setSorting,
    viewerAuth,
    showOnlyLive,
    setShowOnlyLive,
    redeemCapsuleId,
    alertsRefetchQuery,
    onLoadMore,
    match, setMatch,
    matches,
    totalCount,
  } = props;

  const now = moment();

  return (
    <div
      style={{
        color: Colors.white,
        overflow: 'hidden',
      }}
    >
      <StickyContainer style={{ minHeight: 800 }}>
        <CardShopMatchFilters
          setSorting={setSorting}
          sorting={sorting}
          matches={matches}
          match={match}
          setMatch={setMatch}
          showOnlyLive={showOnlyLive}
          setShowOnlyLive={setShowOnlyLive}
          noPrices noQuality noRarity
        />

        {loading ?
          <Loading />
          :
          <div className="inner-wrapper">
            <div
              className={classes.cardsContainer}
              style={{ gridTemplateColumns: 'repeat(5, 1fr)' }}
            >
              {capsules.map((caps, index) => (
                <AlertCapsule
                  showRedeem={caps._id === redeemCapsuleId}
                  key={`AlertCapsule${caps._id}`}
                  t={t}
                  viewerAuth={viewerAuth}
                  switchLastHover={(index + 1) % 4 === 0 || (index + 1) % 5 === 0}
                  now={now}
                  alertsRefetchQuery={alertsRefetchQuery}
                  {...caps}
                />
              ))}
            </div>

            <LoadMore count={capsules && capsules.length} totalCount={capsules && totalCount}
                      loading={loading} onLoadMore={onLoadMore} />
          </div>
        }
      </StickyContainer>
    </div>
  );
};

export default AlertCapsules;
