import React from 'react';
import { makeStyles } from '@material-ui/core';

import Colors from '../../theme/Colors';
import { rangeArray } from '../../utils/helper';
import Helpers from '../../theme/Helpers';

const useStyles = makeStyles(() => ({
  circle: {
    width: 10,
    height: 10,
    borderRadius: 50,
    marginRight: 12,
    background: 'rgba(255, 255, 255, 0.25)',
    transition: 'all 0.3s ease-out',
    cursor: 'pointer',
  },
  activeCircle: {
    background: Colors.white,
  },
}));

const HorizontalPageScrollMenu = (props) => {
  const classes = useStyles();
  const { length, activeIdx, setIdx } = props;

  return (
    <div style={Helpers.fillRowCenter}>
      {rangeArray(1, length)
      .map(idx => (
        <div
          key={`CircleKey${idx}`}
          className={[classes.circle, activeIdx === idx ? classes.activeCircle : ''].join(' ')}
          style={idx === length ? { marginRight: 0 } : {}}
          onClick={() => setIdx(idx)}
        />
      ))}
    </div>
  );
};

export default HorizontalPageScrollMenu;