import React from 'react';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';

import NotLoggedInHint from '../Header/NotLoggedInHint';
import Routes from '../../routes';
import Footer from '../Footer/Footer';
import { getMyViewer } from '../../apollo/queries';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import { disableSideBar, isUserPremium } from '../../utils/helper';
import AdBlockDetection from '../AdBlockDetection/AdBlockDetection';

const InnerFooterApp = (props) => {
  const { userToken, extra, pathname, isMobile } = props;

  const { data } = useQuery(getMyViewer, {
    fetchPolicy: useCacheWithExpiration('getMyViewer'),
  });
  const myViewer = data && data.getMyViewer;
  const { isPremiumActive } = isUserPremium(myViewer);

  const disableMenu = pathname.includes('/card-shop-pay') || isMobile;
  const isCardShopRoute = disableSideBar(pathname);
  const marginLeft = disableMenu ? 0 : ((extra.expandedMenu ? 173 : 67) + (!isCardShopRoute ? 245 : 0));
  const marginRight = disableMenu ? 0 : (extra.expandedRightSidebar && !isCardShopRoute ? 64 : 0);
  const marginTop = disableMenu ? 0 : 65;
  const transition = disableMenu ? '' : 'all 0.3s ease-out';

  return (
    <>
      <div style={{ marginLeft, marginRight, transition, marginTop }}>
        {/*<EventHint />*/}
        <NotLoggedInHint />
        {(userToken) && <Routes />}
        <Footer
          marginLeft={marginLeft}
          marginRight={marginRight}
          myViewer={myViewer}
          isPremiumActive={isPremiumActive}
          isMobile={isMobile}
        />
      </div>
      {/*<CheckLastLoginContainer />*/}
      {(!isPremiumActive && !pathname.includes('/premium') && !pathname.includes('/admin')) &&
      <AdBlockDetection marginLeft={marginLeft} username={myViewer && myViewer.display_name} />
      }
    </>
  );
};

const mapStateToProps = (state) => {
  const { extra } = state;
  return { extra };
};

export default connect(mapStateToProps)(InnerFooterApp);