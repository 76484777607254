import React from 'react';
import { Switch, withStyles } from '@material-ui/core';

import Colors from '../../theme/Colors';

const BlueWhiteSwitch = withStyles((theme) => ({
  root: {
    width: 34,
    height: 18,
    padding: 0,
    margin: 0,
  },
  switchBase: {
    padding: 2,
    '& + $track': {
      background: 'rgba(255, 255, 255, 0.1)',
      opacity: 1,
      border: 'none',
    },
    '&$checked': {
      transform: 'translateX(16px)',
      color: Colors.white,
      '& + $track': {
        background: '#FFFFFF',
        opacity: 1,
        border: 'none',
      },
      '& $thumb': {
        background: '#0C172A',
      },
    },
  },
  thumb: {
    width: 14,
    height: 14,
  },
  track: {
    borderRadius: 18 / 2,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default BlueWhiteSwitch;