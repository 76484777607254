import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Helpers from '../../theme/Helpers';
import CustomButton from '../Button/CustomButton';

const OnBoardingStepFooter = (props) => {
  const { t } = useTranslation();
  const { nextStep, save, classes, hide } = props;

  return (
    <div
      style={{ ...Helpers.fillRowCross, ...Helpers.mainSpaceBetween, ...{ marginTop: 30 } }}
    >
      <span className={classes.chip} onClick={nextStep}>
        {t('skip')}
      </span>

      <CustomButton status="primary" onClick={save} style={hide ? { display: 'none' } : {}}>
        {t('on_boarding_save_next')}
      </CustomButton>
    </div>
  );
};

OnBoardingStepFooter.propTypes = {
  hide: PropTypes.bool,
};

OnBoardingStepFooter.defaultProps = {
  hide: false,
};

export default OnBoardingStepFooter;