import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Helpers from '../../theme/Helpers';
import SetFooterRow from './SetFooterRow';
import Colors from '../../theme/Colors';
import { getLevelWord } from '../../utils/helper';
import CardImageDecision from '../Cards/CardImageDecision';

import conf from '../../conf.json';

const useStyles = makeStyles(() => ({
  box: {
    padding: '16px 20px',
    background: 'rgba(255, 255, 255, 0.04)',
    borderRadius: 6,
    marginBottom: 8,
  },
  topRow: {
    fontSize: 14,
    color: Colors.halfWhite,
    fontWeight: 300,
  },
  itemRow: {
    cursor: 'pointer',
    fontWeight: 300,
    '& > td:first-child': {
      paddingRight: 8,
    },
    '& > td': {
      marginBottom: 6,
    },
    '& img': {
      verticalAlign: 'inherit',
    },
    '& img.card-row-image': {
      filter: 'grayscale(1)',
      opacity: 0.2,
      borderRadius: 2,
    },
    '&:hover': {
      '& img.card-row-image': {
        filter: 'none',
        opacity: 1,
      },

      '& td:not(:first-child)': {
        background: 'rgba(255, 255, 255, 0.08)',
      },
    },
  },
  levelCell: {
    padding: '12px 10px',
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    width: 170,
  },
  inventoryCell: {
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    width: 115,
  },
  activeCell: {
    background: 'rgba(255, 255, 255, 0.08)',
  },
  scrollableTable: {
    height: 320,
    overflow: 'auto',
    scrollbarColor: 'black white !important',
    paddingRight: 6,

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(255, 255, 255, 0.16)',
      borderRadius: 2,
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgb(13, 25, 46)',
      boxShadow: 'none',
    },
  },
}));

const BoosterCardsView = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { costs, booster, matchData, redeemBooster, isMobile } = props;
  const cards = booster.generic_cards.sort((a, b) => a.rarity ? (a.rarity < b.rarity ? 1 : -1) : (a._id < b._id ? 1 : -1));
  const [activeCard, setActiveCard] = useState(cards[0]);

  return (
    <>
      {!isMobile &&
      <CardImageDecision
        id="three-js-area"
        card={{ ...activeCard, noEffectLayer: true }}
        information={{
          front_layers: [
            { img_url: activeCard.card_layer_image },
          ],
        }}
        isLegacy
        style={{
          height: 604,
          width: 498,
        }}
        zoom={3.5}
      />
      }

      <div style={{ width: 371, marginLeft: 'auto' }}>
        <div style={{ marginBottom: 16, ...Helpers.fillRow, alignItems: 'baseline' }}>
          <div style={{
            fontFamily: 'Bebas Neue',
            fontSize: 36,
            lineHeight: 1,
            marginRight: 8,
          }}>
            {t('booster_contents')}
          </div>
        </div>

        <div className={classes.box}>
          <div className={classes.scrollableTable} id="cards-scroll-list">
            <table style={{ borderSpacing: '0 6px' }}>
              <tr className={classes.topRow}>
                <td />
                <td>
                  {t('rareness')}
                </td>
                <td>
                  {t('in_inventory')}
                </td>
              </tr>
              {cards && cards.map(card => {
                const isActiveRow = activeCard._id === card._id;

                return (
                  <tr className={classes.itemRow} key={card._id}
                      onClick={() => setActiveCard(card)}>
                    <td style={{ paddingRight: 8 }}>
                      <img className="card-row-image" src={`${card.card_layer_image}?height=51`}
                           alt="Card"
                           style={{
                             ...isActiveRow ? { opacity: 1, filter: 'none' } : {},
                             maxWidth: 'fit-content',
                           }} />
                    </td>
                    <td
                      className={[classes.levelCell, isActiveRow ? classes.activeCell : ''].join(' ')}
                      style={{ color: Colors.level[getLevelWord(card.rarity === 0 ? 'SpecialCard' : 'Card', card.rarity)] }}
                    >
                      <img
                        src={`${conf.cdn_url}/badges/card-level/${card.rarity}.png?height=27`}
                        alt="Level badge"
                        style={{ marginRight: 8 }}
                      />
                      {t(`rareness_level.${card.rarity}`)}
                    </td>
                    <td
                      className={[classes.inventoryCell, isActiveRow ? classes.activeCell : ''].join(' ')}
                    >
                      {card.count_owned ? card.count_owned : 0}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>

        <SetFooterRow costs={costs} booster={booster} matchData={matchData}
                      redeemBooster={redeemBooster} />
      </div>
    </>
  );
};

export default BoosterCardsView;