import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Colors from '../../theme/Colors';
import { rangeArray } from '../../utils/helper';

import conf from '../../conf.json';

const useStyles = makeStyles((theme) => ({
   priceItem: {
      background: 'rgba(255, 255, 255, 0.04)',
      borderRadius: 6,
      position: 'relative',
   },
   pricesArea: {
      display: 'grid',
      margin: '32px 0',
      gridTemplateColumns: 'repeat(6, 1fr)',
      gap: 26,
      '& > div:first-child': {
         gridArea: '1 / 1 / 2 / 7',
      },
      '& > div:nth-child(2)': {
         gridArea: '2 / 1 / 3 / 4',
      },
      '& > div:nth-child(3)': {
         gridArea: '2 / 4 / 3 / 7',
      },
      '& > div:nth-child(4)': {
         gridArea: '3 / 1 / 4 / 3',
      },
      '& > div:nth-child(5)': {
         gridArea: '3 / 3 / 4 / 5',
      },
      '& > div:nth-child(6)': {
         gridArea: '3 / 5 / 4 / 7',
      },
      '& > div:nth-child(7)': {
         gridArea: '4 / 1 / 5 / 3',
      },
      '& > div:nth-child(8)': {
         gridArea: '4 / 3 / 5 / 5',
      },
      '& > div:nth-child(9)': {
         gridArea: '4 / 5 / 5 / 7',
      },

     [theme.breakpoints.down('md')]: {
       gridTemplateColumns: 'repeat(1, 1fr)',

       '& > div': {
         gridArea: 'auto !important',
       }
     },
   },
}));

const NeonMeetsIcePrices = (props) => {
  const classes = useStyles();

  const { t } = props;

  const prices = t('landing_pages.neon_meets_ice.prices.prices', { returnObjects: true });
  const priceGraphics = [
     `${conf.cdn_url}/assets/seasons/landingpage/prices/SH_Season1_Landingpage_Price_01_EMP2000.png`,
     `${conf.cdn_url}/assets/seasons/landingpage/prices/SH_Season1_Landingpage_Price_02_EMP150.png`,
     `${conf.cdn_url}/assets/seasons/landingpage/prices/SH_Season1_Landingpage_Price_03_EMP100.png`,
     `${conf.cdn_url}/assets/seasons/landingpage/prices/SH_Season1_Landingpage_Price_04_Sony55ZollTV.png`,
     `${conf.cdn_url}/assets/seasons/landingpage/prices/SH_Season1_Landingpage_Price_05_TeufelCinebar.png`,
     `${conf.cdn_url}/assets/seasons/landingpage/prices/SH_Season1_Landingpage_Price_06_Anker.png`,
     `${conf.cdn_url}/assets/seasons/landingpage/prices/SH_Season1_Landingpage_Price_07_Govee.png`,
     `${conf.cdn_url}/assets/seasons/landingpage/prices/SH_Season1_Landingpage_Price_08_Flipline.png`,
     `${conf.cdn_url}/assets/seasons/landingpage/prices/SH_Season1_Landingpage_Price_08_Balui.png`,
  ];

  return (
    <section style={{ textAlign: 'center', marginTop: 100 }}>
      <h1 style={{
        fontSize: 36,
        fontWeight: 500,
        margin: '0 0 4px 0',
        lineHeight: 1,
      }}>
        {t('landing_pages.neon_meets_ice.prices.title')}
      </h1>

      <p style={{
        fontSize: 24,
        color: 'rgba(255, 255, 255, 0.5)',
        margin: '0 0 23px 0',
      }}>
        {t('landing_pages.neon_meets_ice.prices.sub_title')}
      </p>

      <p style={{
        margin: '0 auto',
        maxWidth: 720,
      }}>
        {t('landing_pages.neon_meets_ice.prices.description')}
      </p>

      <div className={classes.pricesArea}>

        <div className={classes.priceItem}>
          <div style={{
            background: `url("${conf.cdn_url}/assets/seasons/landingpage/prices/EMP_BG.png")`,
            height: 301,
            borderRadius: '6px 6px 0px 0px',
            backgroundSize: 'contain',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            zIndex:-1,
          }} />

          <div style={{ margin: '13px 0 47px' }}>
            <img src={priceGraphics[0]} alt="Price" />
          </div>

          <div style={{ padding: 32 }}>
            <h3 style={{
              fontSize: 18,
              fontWeight: 600,
              margin: '0 0 5px 0',
              lineHeight: 1,
              textTransform: 'uppercase',
            }}>
              {prices[0].title}
            </h3>

            <p style={{
              fontSize: 14,
              color: '#E12138',
              margin: '0 0 18px 0',
              fontWeight: 500,
            }}>
              {prices[0].promotion}
            </p>

            <p style={{
              margin: '0 auto',
              maxWidth: 308,
              fontSize: 14,
              color: Colors.halfWhite,
            }}>
              {prices[0].text}
            </p>
          </div>
        </div>

        {rangeArray(1, 2)
        .map(item => (
          <div className={classes.priceItem}>
            <div style={{
              background: `url("${conf.cdn_url}/assets/seasons/landingpage/prices/EMP_BG_small.png")`,
              height: 301,
              borderRadius: '6px 6px 0px 0px',
              backgroundSize: 'contain',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: -1,
            }} />

            <div style={{ margin: '25px 0 23px' }}>
              <img src={priceGraphics[item]} alt="Price" />
            </div>

            <div style={{ padding: 44 }}>
              <h3 style={{
                fontSize: 18,
                fontWeight: 600,
                margin: '0 0 5px 0',
                lineHeight: 1,
                textTransform: 'uppercase',
              }}>
                {prices[item].title}
              </h3>

              <p style={{
                fontSize: 14,
                color: '#E12138',
                fontWeight: 500,
              }}>
                {prices[item].promotion}
              </p>
            </div>
          </div>
        ))}

        {rangeArray(3, 8)
        .map(item => (
          <div className={classes.priceItem} >
            <div style={{ margin: '48px 0 20px' }}>
              <img src={priceGraphics[item]} alt="Price" />
            </div>

            <div style={{ padding: 32 }}>
              <h3 style={{
                fontSize: 18,
                fontWeight: 600,
                margin: '0 0 5px 0',
                lineHeight: 1,
                textTransform: 'uppercase',
              }}>
                {prices[item].title}
              </h3>

              <p style={{
                fontSize: 14,
                color: '#E12138',
                fontWeight: 500,
              }}>
                {prices[item].promotion}
              </p>
            </div>
          </div>
        ))}

      </div>
    </section>
  );
};

export default NeonMeetsIcePrices;